<template>
	<div id="holiday-event-header-button" class="holiday-event-header-button" :style="`${`background-color: ${PROMO_COLOR}; font-family: ${PROMO_FONT}; border: ${PROMO_BORDER}; color: ${PROMO_TEXT_COLOR}; font-size: ${PROMO_FONT_SIZE}; font-weight: ${PROMO_FONT_WEIGHT};`}`" @click="goToFeedOffer">
		<MIcon :icon="PROMO_ICON_NAME" :width="PROMO_ICON_WIDTH" :height="PROMO_ICON_HEIGHT" :color="PROMO_ICON_COLOR" />
		<div :style="`margin: ${PROMO_HEADER_TEXT_MARGIN};`">{{ $t('components.eventHeaderButton') }}</div>
	</div>
</template>

<script setup lang="ts">
import MIcon from '@/components/atoms/MIcon.vue';
import router from '@/router';
import {
	PROMO_COLOR,
	PROMO_ICON_COLOR,
	PROMO_ICON_HEIGHT,
	PROMO_ICON_NAME,
	PROMO_ICON_WIDTH,
	PROMO_FONT,
	PROMO_BORDER,
	PROMO_TEXT_COLOR,
	PROMO_FONT_SIZE,
	PROMO_FONT_WEIGHT,
	PROMO_HEADER_TEXT_MARGIN
} from '@/hooks/PromoHooks';
import { useBooleanFlagStore } from '@/stores/booleanFlag';

const { setIsPromoBtnClicked } = useBooleanFlagStore();

const goToFeedOffer = () => {
	setIsPromoBtnClicked(true);
	router.push('/order/publication/cart');
};
</script>

<style scoped lang="scss">
.holiday-event-header-button {
	display: flex;
	align-items: center;
	font-size: 14px;
	letter-spacing: 0.5px;
	gap: 5px;
	background-color: #CB8184;
	color: $white-100;
	font-weight: 900;
	padding: 0 10px;
	border-radius: 8px;
	margin-right: 10px;
	cursor: pointer;
	transition: opacity 0.3s;
	
	&:hover {
		opacity: 0.5;
	}
}
</style>
