import BaseApi from '@/api/BaseApi';
import type {
	IWebsiteRequestCreateResponse,
	IWebsiteRequestForm,
	WebsiteRequestsListInterface
} from '@/models/WebsiteRequestModel';

export default class WebsiteRequestApi extends BaseApi {
	async websiteRequestsList () {
		return this.get<Array<WebsiteRequestsListInterface>>({
			url: 'api/v1/website-request',
		});
	};

	async deleteWebsiteRequest (uuid:string) {
		return this.delete({
			url: `api/v1/website-request/${uuid}`,
		});
	};

	async submitWebsiteRequest (uuid:string) {
		return this.put<any, number>({
			url: `api/v1/website-request/${uuid}/finish`,
		});
	};

	async createRequestForNewDomain (params: IWebsiteRequestForm) {
		return this.post<IWebsiteRequestForm, IWebsiteRequestCreateResponse>({
			url: 'api/v1/website-request',
			data: params,
		});
	};
};
