import BaseApi from '@/api/BaseApi';
import type {
	IResetChangePasswordForm,
	IResetPasswordForm,
	LoginRequestInterface,
	LoginResponseInterface,
	RegisterRequestInterface,
	UserInterface
} from '@/models/AuthModel';

export default class AuthApi extends BaseApi {
    async login (params: LoginRequestInterface) {
        return this.post<LoginRequestInterface, LoginResponseInterface>({
            url: 'api/v1/auth/login',
            data: {
                email: params.email,
                password: params.password,
            },
        });
    };

    async me () {
        return this.get<UserInterface>({
            url: 'api/v1/auth/me',
        });
    };

    async register (params: RegisterRequestInterface) {
        return this.post<RegisterRequestInterface, any>({
            url: 'api/v1/auth/register',
            data: params,
        });
    };

    async refreshToken () {
        return this.post<any, any>({
            url: 'api/v1/auth/refresh',
        });
    };

    async resetPassword (params: IResetPasswordForm) {
        return this.post<IResetPasswordForm, any>({
            url: 'api/v1/auth/recovery/password/init',
	        data: params,
        });
    };

    async resetChangePassword (params: IResetChangePasswordForm) {
        return this.post<IResetChangePasswordForm, any>({
            url: 'api/v1/auth/recovery/password',
	        data: params,
        });
    };
};
