<template>
	<div class="order-change-status">
		<div class="order-change-status__label">{{ label }}:</div>
		<v-radio-group v-model="decision" inline>
			<v-radio id="approve" :label="$t('order.orderFlow.approve')" :value="DECISION_APPROVE" />
			<v-radio id="reject" :label="$t('order.orderFlow.report')" :value="DECISION_REJECT" />
		</v-radio-group>
		<Form :validation-schema="schema" @submit="saveDecision" v-show="decision === DECISION_REJECT">
			<MTextArea
				id="order-change-status-decision"
				v-model="rejectNote"
				name="rejectNote"
				label-text-required
				:separate-label="$t('order.orderFlow.reports')"
			/>
			<MButton
				id="second-save-decision"
				normal
				blue400
				:label="$t('order.orderFlow.save')"
				:loading="loading"
				:loading-color="loadingColor"
			/>
		</Form>
		<MButton
			v-show="decision === DECISION_APPROVE"
			id="save-decision"
			normal
			blue400
			:label="$t('order.orderFlow.save')"
			@click="saveDecision"
			:loading="loading"
			:loading-color="loadingColor"
		/>
	</div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { DECISION_APPROVE, DECISION_REJECT } from '@/hooks/OrderHooks';
import MButton from '@/components/atoms/MButton.vue';
import MTextArea from '@/components/atoms/MTextArea.vue';

interface IOwnProps {
	label: string
	loading?: boolean
	loadingColor?: string
}
interface IProps extends IOwnProps {
}

defineProps<IProps>();
const emit = defineEmits(['changeStatusAction']);

const { t } = useI18n();

const schema:any = yup.object({
	rejectNote: yup.string().required(`${t('order.orderFlow.validation.rejectNoteRequired')}`).label('rejectNote'),
});

const decision = ref<string>(DECISION_APPROVE);
const rejectNote = ref<string|null>(null);

const saveDecision = () => {
	emit('changeStatusAction', decision.value === DECISION_APPROVE, rejectNote.value);
};
</script>

<style scoped lang="scss">
.order-change-status {
	margin-top: 20px;

	&__label {
		font-size: 14px;
		font-weight: 500;
		color: $primary-400;
	}
	
	&:deep(.v-selection-control--inline .v-label) {
		font-size: 12px;
		color: $primary-400;
		opacity: 1;
	}
	
	&:deep(.v-selection-control-group--inline) {
		margin-top: 5px;
		margin-left: -10px;
	}
	
	&:deep(.m-btn) {
		padding: 25px 45px !important;
	}
}
</style>
