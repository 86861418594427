import { defineStore } from 'pinia';
import type { CountryInterface } from '@/models/CountryModel';
import type { CurrencyInterface } from '@/models/CurrencyModel';
import type { LanguageInterface } from '@/models/LanguageModel';
import type { ICampaignsListItem } from '@/models/CampaignModel';

type DictionaryType = {
	countries: Array<CountryInterface>
	currencies: Array<CurrencyInterface>
	languages: Array<LanguageInterface>
	campaigns: Array<ICampaignsListItem>
}

export const useDictionaryStore = defineStore('useDictionaryStore', {
	state: ():DictionaryType => ({
		countries: [],
		currencies: [],
		languages: [],
		campaigns: [],
	}),
	actions: {
		setCountries (countriesValue: Array<CountryInterface>) {
			countriesValue.sort((a, b) => {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			});
			this.countries = countriesValue;
		},
		setCurrencies (currenciesValue: Array<CurrencyInterface>) {
			this.currencies = currenciesValue;
		},
		setLanguages (languagesValue: Array<LanguageInterface>) {
			languagesValue.sort((a, b) => {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			});
			this.languages = languagesValue;
		},
		setCampaigns (campaignsValue: Array<any>) {
			campaignsValue.sort((a, b) => {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			});
			this.campaigns = campaignsValue;
		},
	},
});
