<template>
	<div class="advanced-filters" :class="{'advanced-filters--animation': isOpenAdvancedFilters}">
		<div class="advanced-filters__top-wrapper">
			<div class="advanced-filters__top-wrapper--search">
				<MTextField
					id="filter-search"
					v-model="advancedFilters.search"
					name="search"
					:placeholder="$t('order.publication.searchDomain')"
					clearable
				/>
			</div>
			<div class="advanced-filters__top-wrapper--coupon" :class="{'advanced-filters__top-wrapper--coupon--holiday': IS_PROMO_ACTIVE}">
				<MTextField
					id="filter-coupon"
					v-model="advancedFilters.coupon"
					name="coupon"
					:placeholder="$t('order.publication.coupon')"
					type="text"
					clearable
					:persistent-clear="null != advancedFilters.coupon"
					:is-custom-clear="IS_PROMO_ACTIVE"
				>
					<template #customClear>
						<MTooltip without-btn>
							<template v-slot:title>
								<MIcon icon="close" width="16" height="16" @click="clearCoupon" />
							</template>
							<template v-slot:content>
								<div>{{ $t('components.holidayAdvancedFilterClose') }}</div>
							</template>
						</MTooltip>
					</template>
				</MTextField>
				<div v-if="IS_PROMO_ACTIVE" id="holiday-code" class="advanced-filters__holiday-wrapper" :style="`${`background-color: ${PROMO_COLOR}; border: ${PROMO_BORDER};`}`" @click="addHolidayCode">
					<MTooltip without-btn>
						<template v-slot:title>
							<MIcon :icon="PROMO_ICON_NAME" :width="PROMO_ICON_WIDTH" :height="PROMO_ICON_HEIGHT" :color="PROMO_ICON_COLOR" />
						</template>
						<template v-slot:content>
							<div>{{ $t('components.holidayAdvancedFilterPin') }}</div>
						</template>
					</MTooltip>
				</div>
			
			</div>
			<div class="advanced-filters__filters-counter-wrapper">{{ $t('order.publication.activeFilters') }}: {{ activeFiltersCounter }}</div>
			<div @click="clearFilters" class="advanced-filters__clear-filters-wrapper" id="clear-filters">{{ $t('order.publication.clear') }}</div>
			<MButton
				id="btn-expand-advanced-filters"
				big
				red400
				:label="$t('order.publication.advancedFilters')"
				@click="toggleAdvancedFilters"
			>
				<MIcon v-if="!isOpenAdvancedFilters" icon="arrow-down" class="advanced-filters__arrow" />
				<MIcon v-if="isOpenAdvancedFilters" icon="arrow-down" class="advanced-filters__arrow advanced-filters__arrow--rotate" />
			</MButton>
		</div>
		
		<div v-if="isOpenAdvancedFilters" class="advanced-filters__filter-group-title">{{ $t('order.publication.basic') }}</div>
		<div class="advanced-filters__group-wrapper">
			<div class="advanced-filters__group-item-wrapper">
				<MTextField
					id="filter-min-price"
					v-model="advancedFilters.min_price"
					name="minPrice"
					:placeholder="$t('order.publication.minPrice')"
					type="number"
					min="0"
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MTextField
					id="filter-max_price"
					v-model="advancedFilters.max_price"
					name="maxPrice"
					:placeholder="$t('order.publication.maxPrice')"
					type="number"
					min="0"
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MAutocomplete
					id="filter-language"
					v-model="advancedFilters.language_ids"
					name="languages"
					:placeholder="$t('order.publication.chooseLanguage')"
					variant="outlined"
					:get-items="languages"
					item-title="name"
					item-value="id"
					multiple
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MAutocomplete
					id="filter-country"
					v-model="advancedFilters.countries_ids"
					name="country"
					:placeholder="$t('order.publication.country')"
					variant="outlined"
					:get-items="countries"
					item-title="name"
					item-value="id"
					multiple
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MAutocomplete
					id="filter-categories"
					v-model="advancedFilters.categories_ids"
					name="categories"
					:placeholder="$t('order.publication.categories')"
					variant="outlined"
					:get-items="categoriesList"
					item-title="slug"
					item-value="id"
					multiple
					clearable
				/>
			</div>
			<div v-show="isOpenAdvancedFilters" class="advanced-filters__group-item-wrapper">
				<MCheckbox
					id="filter-do-follow"
					v-model="advancedFilters.do_follow"
					name="doFollow"
					:label="$t('order.publication.showDoFollow')"
					:color="secondary400"
				/>
			</div>
			<div v-show="isOpenAdvancedFilters" class="advanced-filters__group-item-wrapper">
				<MAutocomplete
					id="filter-anchor-types"
					v-model="advancedFilters.anchor_types"
					name="anchorTypes"
					:placeholder="$t('order.publication.anchorTypesPlaceholder')"
					variant="outlined"
					:get-items="anchorTypesList"
					item-title="name"
					item-value="id"
					multiple
					clearable
				/>
			</div>
			<div v-show="isOpenAdvancedFilters" class="advanced-filters__group-item-wrapper">
				<MCheckbox
					id="filter-is-trusted"
					v-model="advancedFilters.is_trusted"
					name="isTrusted"
					:label="$t('order.publication.showTrusted')"
					:color="secondary400"
				/>
			</div>
			<div v-show="isOpenAdvancedFilters" class="advanced-filters__group-item-wrapper">
				<MCheckbox
					id="filter-fast-delivery"
					v-model="advancedFilters.fast_delivery"
					name="fastDelivery"
					:label="$t('order.publication.fastText')"
					:color="secondary400"
				/>
			</div>
			<div v-show="isOpenAdvancedFilters" class="advanced-filters__group-item-wrapper">
				<MCheckbox
					id="filter-has-discount"
					v-model="advancedFilters.has_discount"
					name="hasDiscount"
					:label="$t('order.publication.showPromotions')"
					:color="secondary400"
				/>
			</div>
		</div>
		
		<div v-if="isOpenAdvancedFilters" class="advanced-filters__filter-group-title">{{ $t('order.publication.marketing') }}</div>
		<div v-if="isOpenAdvancedFilters" class="advanced-filters__group-wrapper">
			<div class="advanced-filters__group-item-wrapper">
				<MAutocomplete
					id="filter-article-disclosure"
					v-model="advancedFilters.article_disclosure"
					name="articleDisclosure"
					:placeholder="$t('order.publication.disclosure')"
					variant="outlined"
					:get-items="disclosures"
					item-title="name"
					item-value="slug"
					multiple
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MAutocomplete
					id="filter-max-links-amount"
					v-model="advancedFilters.links_max_amount"
					name="maxLinksAmount"
					:placeholder="$t('order.publication.maxLinksAmount')"
					variant="outlined"
					:get-items="maxLinksAmountList"
					item-title="name"
					item-value="slug"
					multiple
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MAutocomplete
					id="filter-additional-promotion"
					v-model="advancedFilters.promo"
					name="additionalPromotion"
					:placeholder="$t('order.publication.additionalPublicationPromotionPlaceholder')"
					variant="outlined"
					:get-items="promoList"
					item-title="name"
					item-value="id"
					multiple
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MCheckbox
					id="filter-publication-on-main-page"
					v-model="advancedFilters.is_publication_on_main_page"
					name="publicationOnMainPage"
					:label="$t('order.publication.publicationOnMainPageText')"
					:color="secondary400"
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MTextField
					id="filter-days-promotion-on-main-page"
					v-model="advancedFilters.days_promotion_on_main_page"
					name="daysPromotionOnMainPage"
					:placeholder="$t('order.publication.daysPromotionOnMainPage')"
					type="number"
					min="0"
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MAutocomplete
					id="filter-publication-period"
					v-model="advancedFilters.publication_period"
					name="publicationPeriod"
					:placeholder="$t('order.publication.publicationDurability')"
					variant="outlined"
					:get-items="publicationDurabilityList"
					item-title="name"
					item-value="slug"
					multiple
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MCheckbox
					id="filter-write-content"
					v-model="advancedFilters.write_content"
					name="writeContent"
					:label="$t('order.publication.writtenByPublisherText')"
					:color="secondary400"
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MCheckbox
					id="filter-is-allowed-promotion-loans"
					v-model="advancedFilters.is_allowed_promotion_loans"
					name="isAllowedPromotionLoans"
					:label="$t('order.publication.showLoans')"
					:color="secondary400"
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MCheckbox
					id="filter-is-allowed-promotion-bookmakers"
					v-model="advancedFilters.is_allowed_promotion_bookmakers"
					name="isAllowedPromotionBookmakers"
					:label="$t('order.publication.showBookmakers')"
					:color="secondary400"
				/>
			</div>
		</div>
		
		<div v-if="isOpenAdvancedFilters" class="advanced-filters__filter-group-title">{{ $t('order.publication.technicalSEO') }}</div>
		<div v-if="isOpenAdvancedFilters" class="advanced-filters__group-wrapper">
			<div class="advanced-filters__group-item-wrapper">
				<MTextField
					id="filter-min-domain-rating"
					v-model="advancedFilters.min_domain_rating"
					name="minDomainRating"
					:placeholder="$t('order.publication.minDomainRating')"
					type="number"
					min="0"
					step="0.1"
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MTextField
					id="filter-max-domain-rating"
					v-model="advancedFilters.max_domain_rating"
					name="maxDomainRating"
					:placeholder="$t('order.publication.maxDomainRating')"
					type="number"
					min="0"
					step="0.1"
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MTextField
					id="filter-min-trust-flow"
					v-model="advancedFilters.min_trust_flow"
					name="minTrustFlow"
					:placeholder="$t('order.publication.minTrustFlow')"
					type="number"
					min="0"
					step="0.1"
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MTextField
					id="filter-max-trust-flow"
					v-model="advancedFilters.max_trust_flow"
					name="maxTrustFlow"
					:placeholder="$t('order.publication.maxTrustFlow')"
					type="number"
					min="0"
					step="0.1"
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MTextField
					id="filter-min-organic-traffic"
					v-model="advancedFilters.min_organic_traffic"
					name="minOrganicTraffic"
					:placeholder="$t('order.publication.minOrganicTraffic')"
					type="number"
					min="0"
					step="0.1"
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MTextField
					id="filter-max-organic-traffic"
					v-model="advancedFilters.max_organic_traffic"
					name="maxOrganicTraffic"
					:placeholder="$t('order.publication.maxOrganicTraffic')"
					type="number"
					min="0"
					step="0.1"
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MTextField
					id="filter-min-refers-domains"
					v-model="advancedFilters.min_refers_domains"
					name="minRefersDomains"
					:placeholder="$t('order.publication.minRefersDomains')"
					type="number"
					min="0"
					step="0.1"
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MTextField
					id="filter-max-refers-domains"
					v-model="advancedFilters.max_refers_domains"
					name="maxRefersDomains"
					:placeholder="$t('order.publication.maxRefersDomains')"
					type="number"
					min="0"
					step="0.1"
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MTextField
					id="filter-min-backlinks"
					v-model="advancedFilters.min_backlinks_live"
					name="minBacklinks"
					:placeholder="$t('order.publication.minBacklinks')"
					type="number"
					min="0"
					step="0.1"
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MTextField
					id="filter-max-backlinks"
					v-model="advancedFilters.max_backlinks_live"
					name="maxBacklinks"
					:placeholder="$t('order.publication.maxBacklinks')"
					type="number"
					min="0"
					step="0.1"
					clearable
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MCheckbox
					id="filter-https"
					v-model="advancedFilters.https"
					name="https"
					:label="$t('order.publication.httpsText')"
					:color="secondary400"
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MCheckbox
					id="filter-exclude-owned"
					v-model="advancedFilters.exclude_owned"
					name="alreadyPurchased"
					:label="$t('order.publication.showAlreadyPurchased')"
					:color="secondary400"
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MButton
					id="open-excluded-dialog"
					normal
					red400
					:label="$t('order.publication.excludeDomains')"
					@click="openExcludedDialog"
				/>
			</div>
			<div class="advanced-filters__group-item-wrapper">
				<MButton
					id="open-included-dialog"
					normal
					red400
					:label="$t('order.publication.includedDomains')"
					@click="openIncludedDialog"
				/>
			</div>
		</div>
		
		<MDialog
			:width="700"
			:is-close-dialog-action="isCloseExcludedDialog"
			:is-open-dialog-action="isOpenExcludedDialog"
			:content-title="$t('order.publication.excludeDomains')"
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<div class="advanced-filters__autocomplete-wrapper">
					<div class="advanced-filters__selected-domains-info">{{ $t('order.publication.selectedDomainsInfo') }}</div>
					<div class="advanced-filters__drop-wrapper">
						<div @dragover="dragover" @dragleave="dragleave" @drop="drop($event, true)">
							<div class="advanced-filters__drop-wrapper--input-wrapper">
								<label for="assetsFieldHandle">
									<div class="advanced-filters__drop-text">{{ $t('articles.uploadDropMessage') }} <span>(.txt, .docx)</span></div>
								</label>
								<input
									type="file"
									name="excludeFile"
									id="advanced-filters-exclude"
									@change="onChangeFile($event, true)"
									ref="file"
									accept=".txt,.docx"
									multiple
								/>
								<div class="advanced-filters__file-btn">{{ $t('order.orderFlow.selectFile') }}</div>
							</div>
						</div>
					</div>
					<div class="advanced-filters__autocomplete-dialog-wrapper">
						<div class="advanced-filters__select-all-wrapper">
							<MCheckbox id="select-all-excluded-items" v-model="selectAllExcludedItems" name="selectAllExcludedItems" :color="secondary400" />
							<div v-if="!selectAllExcludedItems" class="advanced-filters__select-all-text">{{ $t('order.publication.selectAll') }}</div>
							<div v-if="selectAllExcludedItems" class="advanced-filters__select-all-text">{{ $t('order.publication.unselectAll') }}</div>
						</div>
						<MAutocomplete
							id="filter-exclude-domains"
							v-model="advancedFilters.exclude_ids"
							name="excludeDomains"
							:placeholder="$t('order.publication.selectDomains')"
							variant="outlined"
							:get-items="excludedDomainsItems"
							item-title="url"
							item-value="id"
							return-object
							exclude-include
							no-filter
							chips
							multiple
							:loading="loading"
							@update:search="searchExcludedInputValue"
						/>
					</div>
					<div class="advanced-filters__selected-domains-wrapper">
						<div class="advanced-filters__selected-text">{{ $t('order.publication.selectedDomains') }}:</div>
						<div class="advanced-filters__selected-number">{{ advancedFilters?.exclude_ids.length }}</div>
					</div>
					<div v-if="currentSearchedExcludedWebsites.length > 0" class="advanced-filters__selected-website-wrapper">
						<div v-for="item in currentSearchedExcludedWebsites" :key="item.id" class="advanced-filters__selected-website">
							<div>{{ item.url }}</div>
							<div @click="deleteExcludedWebsite(item)" class="advanced-filters__remove-website-btn">
								<MIcon icon="close" height="14" width="14" />
							</div>
						</div>
					</div>
					<div class="advanced-filters__selected-domains-btn-wrapper">
						<MButton
							id="exclude-clear"
							normal
							red400
							:label="$t('order.publication.clear')"
							@click="clearExcludedDomainsAction"
						/>
						<MButton
							id="exclude-save"
							normal
							green400
							:label="$t('order.publication.save')"
							@click="saveExcludedDomainsAction"
						/>
					</div>
				</div>
			</template>
		</MDialog>
		
		<MDialog
			:width="700"
			:is-close-dialog-action="isCloseIncludedDialog"
			:is-open-dialog-action="isOpenIncludedDialog"
			:content-title="$t('order.publication.includedDomains')"
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<div class="advanced-filters__autocomplete-wrapper">
					<div class="advanced-filters__selected-domains-info">{{ $t('order.publication.selectedDomainsInfo') }}</div>
					<div class="advanced-filters__drop-wrapper">
						<div @dragover="dragover" @dragleave="dragleave" @drop="drop($event, false)">
							<div class="advanced-filters__drop-wrapper--input-wrapper">
								<label for="assetsFieldHandle">
									<div class="advanced-filters__drop-text">{{ $t('articles.uploadDropMessage') }} <span>(.txt, .docx)</span></div>
								</label>
								<input
									type="file"
									name="excludeFile"
									id="advanced-filters-exclude"
									@change="onChangeFile($event, false)"
									ref="file"
									accept=".txt,.docx"
									multiple
								/>
								<div class="advanced-filters__file-btn">{{ $t('order.orderFlow.selectFile') }}</div>
							</div>
						</div>
					</div>
					<div class="advanced-filters__autocomplete-dialog-wrapper">
						<div class="advanced-filters__select-all-wrapper">
							<MCheckbox id="select-all-excluded-items" v-model="selectAllIncludedItems" name="selectAllExcludedItems" :color="secondary400" />
							<div v-if="!selectAllIncludedItems" class="advanced-filters__select-all-text">{{ $t('order.publication.selectAll') }}</div>
							<div v-if="selectAllIncludedItems" class="advanced-filters__select-all-text">{{ $t('order.publication.unselectAll') }}</div>
						</div>
						<MAutocomplete
							id="filter-include-domains"
							v-model="advancedFilters.include_ids"
							name="excludeDomains"
							:placeholder="$t('order.publication.selectDomains')"
							variant="outlined"
							:get-items="includedDomainsItems"
							item-title="url"
							item-value="id"
							return-object
							exclude-include
							no-filter
							chips
							multiple
							:loading="loading"
							@update:search="searchIncludedInputValue"
						/>
					</div>
					<div class="advanced-filters__selected-domains-wrapper">
						<div class="advanced-filters__selected-text">{{ $t('order.publication.selectedDomains') }}:</div>
						<div class="advanced-filters__selected-number">{{ advancedFilters?.include_ids.length }}</div>
					</div>
					<div v-if="currentSearchedIncludedWebsites.length > 0" class="advanced-filters__selected-website-wrapper">
						<div v-for="item in currentSearchedIncludedWebsites" :key="item.id" class="advanced-filters__selected-website">
							<div>{{ item.url }}</div>
							<div @click="deleteIncludedWebsite(item)" class="advanced-filters__remove-website-btn">
								<MIcon icon="close" height="14" width="14" />
							</div>
						</div>
					</div>
					<div class="advanced-filters__selected-domains-btn-wrapper">
						<MButton
							id="include-clear"
							normal
							red400
							:label="$t('order.publication.clear')"
							@click="clearIncludedDomainsAction"
						/>
						<MButton
							id="include-save"
							normal
							green400
							:label="$t('order.publication.save')"
							@click="saveIncludedDomainsAction"
						/>
					</div>
				</div>
			</template>
		</MDialog>
	</div>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, ref, watch } from 'vue';
import { useDictionaryStore } from '@/stores/dictionary';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import {
	PUBLICATION_PERIOD_12_MONTHS,
	PUBLICATION_PERIOD_24_MONTHS,
	PUBLICATION_PERIOD_BELOW_12_MONTHS,
	PUBLICATION_PERIOD_LIFETIME,
} from '@/hooks/OrderHooks';
import {
	IS_PROMO_ACTIVE,
	PROMO_CODE,
	PROMO_COLOR,
	PROMO_ICON_COLOR,
	PROMO_ICON_HEIGHT,
	PROMO_ICON_NAME,
	PROMO_ICON_WIDTH,
	PROMO_BORDER
} from '@/hooks/PromoHooks';
import mammoth from 'mammoth';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { TAdvancedFilters } from '@/models/OrderCreateModel';
import type { IAllWebsiteListItem, ICategoriesList } from '@/models/WebsiteModel';
import type { IElasticDomainRequest } from '@/models/OrderModel';
import WebsiteApi from '@/api/v1/WebsiteApi';
import MTextField from '@/components/atoms/MTextField.vue';
import MButton from '@/components/atoms/MButton.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MCheckbox from '@/components/atoms/MCheckbox.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MDialog from '@/components/atoms/MDialog.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';

const websiteApi = new WebsiteApi();

interface IOwnProps {
	modelValue?: TAdvancedFilters
}
interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();
const emit = defineEmits(['update:modelValue', 'clearExcludedWebsites', 'clearIncludedWebsites', 'clearFilters']);
const { t } = useI18n();
const { languages, countries } = useDictionaryStore();
const { secondary400 } = variables;

const advancedFilters:any = computed({
	get() {
		return props.modelValue;
	},
	set(value) {
		emit('update:modelValue', value);
	}
});

const activeFiltersCounter = ref<number>(0);
const loading = ref<boolean>(false);
const isOpenAdvancedFilters = ref<boolean>(false);
const isCloseExcludedDialog = ref<boolean>(true);
const isCloseIncludedDialog = ref<boolean>(true);
const isOpenExcludedDialog = ref<boolean>(false);
const isOpenIncludedDialog = ref<boolean>(false);
const selectAllExcludedItems = ref<boolean>(false);
const selectAllIncludedItems = ref<boolean>(false);
const categoriesList = ref<Array<ICategoriesList>>([]);
const anchorTypesList = ref<Array<any>>([
	{
		name: `${t('order.publication.brand')}`,
		id: 1
	},
	{
		name: `${t('order.publication.generic')}`,
		id: 2
	},
	{
		name: `${t('order.publication.exactMatch')}`,
		id: 3
	},
]);
const disclosures = ref<Array<any>>([
	{
		name: `${t('order.publication.discretion')}`,
		slug: 'Discretion'
	},
	{
		name: `${t('order.publication.add-sponsored')}`,
		slug: 'Add sponsored'
	},
	{
		name: `${t('order.publication.not-add-sponsored')}`,
		slug: 'Not add sponsored'
	},
	{
		name: `${t('order.publication.other')}`,
		slug: 'Other'
	},
]);
const maxLinksAmountList = ref<Array<any>>([
	{
		name: '1',
		slug: 1
	},
	{
		name: '2',
		slug: 2
	},
	{
		name: '3+',
		slug: 3
	},
]);
const publicationDurabilityList = ref<Array<any>>([
	{
		name: `${t('order.publication.below12Months')}`,
		slug: PUBLICATION_PERIOD_BELOW_12_MONTHS
	},
	{
		name: `${t('order.publication.12Months')}`,
		slug: PUBLICATION_PERIOD_12_MONTHS
	},
	{
		name: `${t('order.publication.24Months')}`,
		slug: PUBLICATION_PERIOD_24_MONTHS
	},
	{
		name: `${t('order.publication.lifetime')}`,
		slug: PUBLICATION_PERIOD_LIFETIME
	},
]);
const promoList = ref<Array<any>>([
	{
		name: `${t('order.publication.facebook')}`,
		id: 1
	},
	{
		name: `${t('order.publication.twitter')}`,
		id: 2
	},
	{
		name: `${t('order.publication.newsletter')}`,
		id: 3
	},
]);
const excludedDomainsItems = ref<Array<any>>([]);
const includedDomainsItems = ref<Array<any>>([]);
const currentSearchedExcludedWebsites = ref<Array<any>>([]);
const currentSearchedIncludedWebsites = ref<Array<any>>([]);
const searchExcludedTimeout = ref<any>(undefined);
const searchIncludedTimeout = ref<any>(undefined);
const gtm = {
	pushEvent(event:string, name:string, value:any) {
		if (null != window.dataLayer) {
			window.dataLayer.push({
				event: event,
				[name]: value
			});
		}
	}
};

const categoryList = async() => {
	try {
		const result = await websiteApi.categoriesList();
		if (!result.isSuccess) {
			toast.error(`${t('order.publication.toast.somethingWentWrong')}`);
			return;
		}
		categoriesList.value = result.payload;
		categoriesList.value.forEach(elem => {
			elem.slug = `${t(`categories.${elem.slug}`)}`;
		});
	} catch (e) {
		toast.error(`${t('order.publication.toast.somethingWentWrong')}`);
		return;
	}
};
categoryList();

const allWebsitesList = async(val:string, isExcludedWebsites:boolean, isFileAdded?: boolean) => {
	loading.value = true;
	try {
		const websitesArray:Array<string> = val.split(' ');
		const params = {
			search: websitesArray,
		};
		const result = await websiteApi.allWebsiteList(params);
		if (!result.isSuccess) {
			loading.value = false;
			return;
		}
		if (!isExcludedWebsites) {
			includedDomainsItems.value = result.payload;
			includedDomainsItems.value = includedDomainsItems.value.map(elem => {
				return {
					url: elem.url,
					id: elem.id,
				};
			});
			if (isFileAdded) {
				selectAllIncludedItems.value = true;
			} else {
				selectAllIncludedItems.value = false;
			}
		} else {
			excludedDomainsItems.value = result.payload;
			excludedDomainsItems.value = excludedDomainsItems.value.map(elem => {
				return {
					url: elem.url,
					id: elem.id,
				};
			});
			if (isFileAdded) {
				selectAllExcludedItems.value = true;
			} else {
				selectAllExcludedItems.value = false;
			}
		}
	} catch (e) {
		loading.value = false;
		return;
	}
	loading.value = false;
};

const searchExcludedInputValue = (val:string) => {
	if (val) {
		clearTimeout(searchExcludedTimeout.value);
	}
	searchExcludedTimeout.value = setTimeout(() => {
		allWebsitesList(val, true);
	}, 700);
};
const searchIncludedInputValue = (val:string) => {
	if (val) {
		clearTimeout(searchIncludedTimeout.value);
	}
	searchIncludedTimeout.value = setTimeout(() => {
		allWebsitesList(val, false);
	}, 700);
};
const clearFilters = () => {
	emit('clearFilters', true);
};
const toggleAdvancedFilters = () => {
	isOpenAdvancedFilters.value = !isOpenAdvancedFilters.value;
};
const openExcludedDialog = () => {
	isCloseExcludedDialog.value = false;
	isOpenExcludedDialog.value = true;
};
const openIncludedDialog = () => {
	isCloseIncludedDialog.value = false;
	isOpenIncludedDialog.value = true;
};

const closeDialogWhenNoTargetPressed = () => {
	isCloseExcludedDialog.value = true;
	isCloseIncludedDialog.value = true;
	isOpenExcludedDialog.value = false;
	isOpenIncludedDialog.value = false;
};

const deleteExcludedWebsite = (website:IAllWebsiteListItem) => {
	const index = advancedFilters.value.exclude_ids.indexOf(website);
	advancedFilters.value.exclude_ids.splice(index, 1);
};
const clearExcludedDomainsAction = () => {
	emit('clearExcludedWebsites', true);
	selectAllExcludedItems.value = false;
};
const saveExcludedDomainsAction = () => {
	isOpenExcludedDialog.value = false;
	isCloseExcludedDialog.value = true;
};

const deleteIncludedWebsite = (website:IAllWebsiteListItem) => {
	const index = advancedFilters.value.include_ids.indexOf(website);
	advancedFilters.value.include_ids.splice(index, 1);
};
const clearIncludedDomainsAction = () => {
	emit('clearIncludedWebsites', true);
	selectAllIncludedItems.value = false;
};
const saveIncludedDomainsAction = () => {
	isOpenIncludedDialog.value = false;
	isCloseIncludedDialog.value = true;
};

const setCounter = (filters:IElasticDomainRequest|any) => {
	activeFiltersCounter.value = 0;
	Object.entries(filters).forEach(entry => {
		const [key] = entry;
		if ((filters[key] != null && filters[key] !== '' && filters[key] !== false && !(filters[key] instanceof Array)) || (filters[key] instanceof Array && filters[key].length > 0)) {
			activeFiltersCounter.value += 1;
		}
	});
};
setCounter(advancedFilters.value);

const setCurrentExcludedIncludedWebsites = (filters:IElasticDomainRequest) => {
	if (null != filters?.exclude_ids) {
		currentSearchedExcludedWebsites.value = filters?.exclude_ids;
	}
	if (null != filters?.include_ids) {
		currentSearchedIncludedWebsites.value = filters?.include_ids;
	}
};
setCurrentExcludedIncludedWebsites(advancedFilters.value);

watch((advancedFilters), (newVal:any, oldVal) => {
	setCounter(newVal);
	setCurrentExcludedIncludedWebsites(newVal);
	
	sendEventsToGoogleTagManager(newVal);
},{
	deep: true,
});
watch(([selectAllExcludedItems, selectAllIncludedItems]), (val) => {
	if (val[0]) {
		excludedDomainsItems.value.forEach(elem => {
			const filteredExcludeWebsites = advancedFilters.value?.exclude_ids.find((elems:any) => elems.id === elem.id);
			if (null == filteredExcludeWebsites) {
				advancedFilters.value.exclude_ids.push(elem);
			}
		});
	}
	if (!val[0]) {
		excludedDomainsItems.value.forEach(elem => {
			const filteredExcludeWebsites = advancedFilters.value?.exclude_ids.find((elems:any) => elems.id === elem.id);
			if (null != filteredExcludeWebsites) {
				const index = advancedFilters.value.exclude_ids.indexOf(filteredExcludeWebsites);
				advancedFilters.value.exclude_ids.splice(index, 1);
			}
		});
	}
	if (val[1]) {
		includedDomainsItems.value.forEach(elem => {
			const filteredIncludeWebsites = advancedFilters.value?.include_ids.find((elems:any) => elems.id === elem.id);
			if (null == filteredIncludeWebsites) {
				advancedFilters.value.include_ids.push(elem);
			}
		});
	}
	if (!val[1]) {
		includedDomainsItems.value.forEach(elem => {
			const filteredIncludeWebsites = advancedFilters.value.include_ids.find((elems:any) => elems.id === elem.id);
			if (null != filteredIncludeWebsites) {
				const index = advancedFilters.value.include_ids.indexOf(filteredIncludeWebsites);
				advancedFilters.value.include_ids.splice(index, 1);
			}
		});
	}
},{
	deep: true,
});

const sendEventsToGoogleTagManager = (advancedFiltersForm:any) => {
	if (null != advancedFiltersForm.language_ids && advancedFiltersForm.language_ids.length > 0) {
		advancedFiltersForm.language_ids.forEach((elem:any) => {
			const language = languages.find((elems:any) => elems.id === elem);
			if (null != language) {
				gtm.pushEvent('Publication - language filter', 'language', language.name);
			}
		});
	}
	
	if (null != advancedFiltersForm.min_price && '' !== advancedFiltersForm.min_price) {
		gtm.pushEvent('Publication - min price filter', 'min_price', advancedFiltersForm.min_price);
	}
	
	if (null != advancedFiltersForm.max_price && '' !== advancedFiltersForm.max_price) {
		gtm.pushEvent('Publication - max price filter', 'max_price', advancedFiltersForm.max_price);
	}
	
	if (null != advancedFiltersForm.countries_ids && advancedFiltersForm.countries_ids.length > 0) {
		advancedFiltersForm.countries_ids.forEach((elem:any) => {
			const country = countries.find((elems:any) => elems.id === elem);
			if (null != country) {
				gtm.pushEvent('Publication - country filter', 'country', country.name);
			}
		});
	}
	
	if (null != advancedFiltersForm.min_domain_rating && '' !== advancedFiltersForm.min_domain_rating) {
		gtm.pushEvent('Publication - min domain rating filter', 'min_domain_rating', advancedFiltersForm.min_domain_rating);
	}
	
	if (null != advancedFiltersForm.max_domain_rating && '' !== advancedFiltersForm.max_domain_rating) {
		gtm.pushEvent('Publication - max domain rating filter', 'max_domain_rating', advancedFiltersForm.max_domain_rating);
	}
	
	if (null != advancedFiltersForm.min_trust_flow && '' !== advancedFiltersForm.min_trust_flow) {
		gtm.pushEvent('Publication - min trust flow filter', 'min_trust_flow', advancedFiltersForm.min_trust_flow);
	}
	
	if (null != advancedFiltersForm.max_trust_flow && '' !== advancedFiltersForm.max_trust_flow) {
		gtm.pushEvent('Publication - max trust flow filter', 'max_trust_flow', advancedFiltersForm.max_trust_flow);
	}
	
	if (null != advancedFiltersForm.min_organic_traffic && '' !== advancedFiltersForm.min_organic_traffic) {
		gtm.pushEvent('Publication - min organic traffic filter', 'min_organic_traffic', advancedFiltersForm.min_organic_traffic);
	}
	
	if (null != advancedFiltersForm.max_organic_traffic && '' !== advancedFiltersForm.max_organic_traffic) {
		gtm.pushEvent('Publication - max organic traffic filter', 'max_organic_traffic', advancedFiltersForm.max_organic_traffic);
	}
	
	if (null != advancedFiltersForm.min_refers_domains && '' !== advancedFiltersForm.min_refers_domains) {
		gtm.pushEvent('Publication - min refers domains filter', 'min_refers_domains', advancedFiltersForm.min_refers_domains);
	}
	
	if (null != advancedFiltersForm.max_refers_domains && '' !== advancedFiltersForm.max_refers_domains) {
		gtm.pushEvent('Publication - max refers domains filter', 'max_refers_domains', advancedFiltersForm.max_refers_domains);
	}
	
	if (null != advancedFiltersForm.min_backlinks_live && '' !== advancedFiltersForm.min_backlinks_live) {
		gtm.pushEvent('Publication - min backlinks filter', 'min_backlinks_live', advancedFiltersForm.min_backlinks_live);
	}
	
	if (null != advancedFiltersForm.max_backlinks_live && '' !== advancedFiltersForm.max_backlinks_live) {
		gtm.pushEvent('Publication - max backlinks filter', 'max_backlinks_live', advancedFiltersForm.max_backlinks_live);
	}
	
	if (null != advancedFiltersForm.article_disclosure && advancedFiltersForm.article_disclosure.length > 0) {
		advancedFiltersForm.article_disclosure.forEach((elem:any) => {
			gtm.pushEvent('Publication - article disclosure filter', 'article_disclosure', elem);
		});
	}
	
	if (null != advancedFiltersForm.categories_ids && advancedFiltersForm.categories_ids.length > 0) {
		advancedFiltersForm.categories_ids.forEach((elem:any) => {
			const category = categoriesList.value.find((elems:any) => elems.id === elem);
			if (null != category) {
				gtm.pushEvent('Publication - category filter', 'category', category.name);
			}
		});
	}
	
	if (null != advancedFiltersForm.do_follow && !advancedFiltersForm.do_follow) {
		gtm.pushEvent('Publication - dofollow filter', 'do_follow', advancedFiltersForm.do_follow);
	}
	
	if (null != advancedFiltersForm.is_trusted && !advancedFiltersForm.is_trusted) {
		gtm.pushEvent('Publication - is trusted filter', 'is_trusted', advancedFiltersForm.is_trusted);
	}
	
	if (null != advancedFiltersForm.fast_delivery && !advancedFiltersForm.fast_delivery) {
		gtm.pushEvent('Publication - fast delivery filter', 'fast_delivery', advancedFiltersForm.fast_delivery);
	}
	
	if (null != advancedFiltersForm.has_discount && !advancedFiltersForm.has_discount) {
		gtm.pushEvent('Publication - has discount filter', 'has_discount', advancedFiltersForm.has_discount);
	}
	
	if (null != advancedFiltersForm.is_publication_on_main_page && !advancedFiltersForm.is_publication_on_main_page) {
		gtm.pushEvent('Publication - publication on main page filter', 'is_publication_on_main_page', advancedFiltersForm.is_publication_on_main_page);
	}
	
	if (null != advancedFiltersForm.days_promotion_on_main_page && !advancedFiltersForm.days_promotion_on_main_page) {
		gtm.pushEvent('Publication - days promotion on main page filter', 'days_promotion_on_main_page', advancedFiltersForm.days_promotion_on_main_page);
	}
	
	if (null != advancedFiltersForm.write_content && !advancedFiltersForm.write_content) {
		gtm.pushEvent('Publication - write content filter', 'write_content', advancedFiltersForm.write_content);
	}
	
	if (null != advancedFiltersForm.is_allowed_promotion_loans && !advancedFiltersForm.is_allowed_promotion_loans) {
		gtm.pushEvent('Publication - allowed loans filter', 'is_allowed_promotion_loans', advancedFiltersForm.is_allowed_promotion_loans);
	}
	if (null != advancedFiltersForm.is_allowed_promotion_bookmakers && !advancedFiltersForm.is_allowed_promotion_bookmakers) {
		gtm.pushEvent('Publication - allowed bookmakers filter', 'is_allowed_promotion_bookmakers', advancedFiltersForm.is_allowed_promotion_bookmakers);
	}
	
	if (null != advancedFiltersForm.https && !advancedFiltersForm.https) {
		gtm.pushEvent('Publication - https filter', 'https', advancedFiltersForm.https);
	}
	
	if (null != advancedFiltersForm.exclude_owned && !advancedFiltersForm.exclude_owned) {
		gtm.pushEvent('Publication - exclude owned filter', 'exclude_owned', advancedFiltersForm.exclude_owned);
	}
	
	if (null != advancedFiltersForm.anchor_types && advancedFiltersForm.anchor_types.length > 0) {
		advancedFiltersForm.anchor_types.forEach((elem:any) => {
			const anchor = anchorTypesList.value.find((elems:any) => elems.id === elem);
			if (null != anchor) {
				gtm.pushEvent('Publication - anchor filter', 'anchor', anchor.name);
			}
		});
	}
	
	if (null != advancedFiltersForm.promo && advancedFiltersForm.promo.length > 0) {
		advancedFiltersForm.promo.forEach((elem:any) => {
			const promotion = promoList.value.find((elems:any) => elems.id === elem);
			if (null != promotion) {
				gtm.pushEvent('Publication - promo filter', 'promo', promotion.name);
			}
		});
	}
	
	if (null != advancedFiltersForm.links_max_amount && advancedFiltersForm.links_max_amount.length > 0) {
		advancedFiltersForm.links_max_amount.forEach((elem:any) => {
			gtm.pushEvent('Publication - links max amount filter', 'links_max_amount', elem);
		});
	}
	
	if (null != advancedFiltersForm.publication_period && advancedFiltersForm.publication_period.length > 0) {
		advancedFiltersForm.publication_period.forEach((elem:any) => {
			gtm.pushEvent('Publication - period filter', 'publication_period', elem);
		});
	}
	
	if (null != advancedFiltersForm.exclude_ids && advancedFiltersForm.exclude_ids.length > 0) {
		advancedFiltersForm.exclude_ids.forEach((elem:any) => {
			const excludeDomain = excludedDomainsItems.value.find((elems:any) => elems.id === elem);
			if (null != excludeDomain) {
				gtm.pushEvent('Publication - exclude domains filter', 'exclude_domains', excludeDomain.url);
			}
		});
	}
	
	if (null != advancedFiltersForm.include_ids && advancedFiltersForm.include_ids.length > 0) {
		advancedFiltersForm.include_ids.forEach((elem:any) => {
			const includeDomain = includedDomainsItems.value.find((elems:any) => elems.id === elem);
			if (null != includeDomain) {
				gtm.pushEvent('Publication - include domains filter', 'include_domains', includeDomain.url);
			}
		});
	}
};

const readTextFile = (file:any, isExcludeDomainAction:boolean) => {
	const reader = new FileReader();
	reader.onload = (e:any) => {
		const fileContent = e.target.result;
		const re = /\b(?:https?:\/\/)?(?:[a-zA-Z0-9-]+\.)+(?![a-zA-Z0-9-]+\/)[a-zA-Z]{2,6}(?:\/[^\s]*)?\b/gm;
		allWebsitesList(fileContent.match(re).join(' '), isExcludeDomainAction, true);
	};
	reader.onerror = (e:any) => {
	};
	reader.readAsText(file);
};

const readDocxFile = (file:any, isExcludeDomainAction:boolean) => {
	const reader = new FileReader();
	reader.onload = (e:any) => {
		const arrayBuffer = e.target.result;
		mammoth.extractRawText({arrayBuffer: arrayBuffer})
			.then((result:any) => {
				const fileContent = result.value;
				const re = /\b(?:https?:\/\/)?(?:[a-zA-Z0-9-]+\.)+(?![a-zA-Z0-9-]+\/)[a-zA-Z]{2,6}(?:\/[^\s]*)?\b/gm;
				allWebsitesList(fileContent.match(re).join(' '), isExcludeDomainAction, true);
			})
			.catch((e:any) => {
			});
	};
	reader.onerror = (e:any) => {
	};
	reader.readAsArrayBuffer(file);
};

const onChangeFile = (event:any, isExcludeDomainAction:boolean) => {
	const files = event.target.files;
	
	if (files.length > 0) {
		const file = files[0];
		const fileType = file.type;
		
		if (fileType === "text/plain") {
			readTextFile(file, isExcludeDomainAction);
		} else if (fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
			readDocxFile(file, isExcludeDomainAction);
		} else if (fileType === "application/msword") {
			toast.error(`${t('order.publication.toast.errorDocFormat')}`);
		} else {
			toast.error(`${t('order.publication.toast.errorUnsupportedFormat')}` + `${fileType}`);
		}
	}
};
const dragover = (event:any) => {
	event.preventDefault();
	// Add some visual fluff to show the user can drop its files
	if (!event.currentTarget.classList.contains('advanced-filters__bg-green-300')) {
		event.currentTarget.classList.remove('advanced-filters__bg-gray-100');
		event.currentTarget.classList.add('advanced-filters__bg-green-300');
	}
};
const dragleave = (event:any) => {
	// Clean up
	event.currentTarget.classList.add('advanced-filters__bg-gray-100');
	event.currentTarget.classList.remove('advanced-filters__bg-green-300');
};
const drop = (event:any, isExcludeDomainAction:boolean) => {
	event.preventDefault();
	event.currentTarget.classList.add('advanced-filters__bg-gray-100');
	event.currentTarget.classList.remove('advanced-filters__bg-green-300');
	
	const files = event.dataTransfer.files;
	
	if (files.length > 0) {
		const file = files[0];
		const fileType = file.type;
		
		if (fileType === "text/plain") {
			readTextFile(file, isExcludeDomainAction);
		} else if (fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
			readDocxFile(file, isExcludeDomainAction);
		} else if (fileType === "application/msword") {
			toast.error(`${t('order.publication.toast.errorDocFormat')}`);
		} else {
			toast.error(`${t('order.publication.toast.errorUnsupportedFormat')}` + `${fileType}`);
		}
	}
};

const addHolidayCode = () => {
	advancedFilters.value.coupon = PROMO_CODE;
};

const clearCoupon = () => {
	advancedFilters.value.coupon = null;
};
</script>

<style scoped lang="scss">
.advanced-filters {
	background-color: $white-100;
	padding: 20px;
	
	&--animation {
		height: max-content;
		overflow-y: hidden;
		animation: show 0.5s;
		
		@keyframes show {
			from {
				height: 267px;
			}
			
			to {
				height: 1000px;
			}
		}
	}
	
	&__top-wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 15px;
		
		&--search {
			width: 100%;
			
			@include media-breakpoint-up(sm) {
				width: 31%;
			}
			
			@include media-breakpoint-up(xl) {
				width: 32%;
			}
		}
		
		&--coupon {
			display: flex;
			align-items: center;
			width: 100%;
			
			@include media-breakpoint-up(sm) {
				width: 21%;
			}
			
			@include media-breakpoint-up(xl) {
				width: 23%;
			}
			
			@include media-breakpoint-up(xxl) {
				width: 25%;
			}
			
			&--holiday {
				&:deep(.m-input__label) {
					color: $secondary-400;
				}
				
				&:deep(.v-field__clearable) {
					color: $secondary-400;
				}
				
			}
		}
	}
	
	&__holiday-wrapper {
		background-color: #CB8184;
		border-radius: 8px;
		width: 53px;
		height: 43px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.9;
		}
		
		&:deep(.m-tooltip) {
			margin-bottom: -4px;
		}
	}
	
	&__black9-wrapper {
		background-color: $black;
		border-radius: 8px;
		padding: 9px 10px 4px;
		margin-left: 10px;
		margin-bottom: 2px;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__arrow {
		margin-left: 5px;
		transform: rotateX(0deg);
		animation: rotateClose 0.5s;
		
		@keyframes rotateClose {
			from {
				transform: rotateX(180deg);
			}
			
			to {
				transform: rotateX(0deg);
			}
		}
		
		&--rotate {
			transform: rotateX(180deg);
			animation: rotateOpen 0.5s;
			
			@keyframes rotateOpen {
				from {
					transform: rotateX(0deg);
				}
				
				to {
					transform: rotateX(180deg);
				}
			}
		}
	}
	
	&__filters-counter-wrapper {
		margin-left: auto;
		color: $green-400;
		font-weight: 700;
		font-size: 14px;
	}
	
	&__clear-filters-wrapper {
		color: $secondary-400;
		font-size: 14px;
		font-weight: 600;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__filter-group-title {
		font-size: 22px;
		font-weight: 700;
		margin-bottom: 10px;
		color: $secondary-400;
	}
	
	&__group-wrapper {
		display: flex;
		gap: 10px;
		flex-wrap: wrap;
		margin-top: 10px;
		
		@include media-breakpoint-up(xl) {
			gap: 13px;
		}
		
		&:deep(.m-checkbox) {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		
		&:deep(.m-input) {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		
		&:deep(.m-autocomplete) {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	
	&__group-item-wrapper {
		width: 100%;
		
		@include media-breakpoint-up(sm) {
			width: 48%;
		}
		
		@include media-breakpoint-up(lg) {
			width: 32%;
		}
		
		@include media-breakpoint-up(xl) {
			width: 19%;
		}
		
		&:deep(.v-selection-control .v-label) {
			font-size: 15px;
		}
	}
	
	&__autocomplete-wrapper {
		&:deep(.v-text-field--enclosed .v-input__prepend-inner) {
			margin-top: auto;
		}
		
		&:deep(.theme--light.v-chip:not(.v-chip--active)) {
			display: none;
		}
	}
	
	&__select-all-wrapper {
		font-size: $size-10;
		width: 50px;
		text-align: center;
		margin-top: -8px;
		
		&:deep(.m-checkbox .v-input__details) {
			display: none;
		}
	}
	
	&__select-all-text {
		margin-left: -4px;
		margin-top: -16px;
	}
	
	&__selected-domains-info {
		margin-bottom: 20px;
	}
	
	&__autocomplete-dialog-wrapper {
		display: flex;
		
		&:deep(.m-autocomplete) {
			width: 100%;
		}
	}
	
	&__selected-domains-wrapper {
		display: flex;
		gap: 10px;
		align-items: center;
		margin-top: 15px;
	}
	
	&__selected-text {
		font-size: 14px;
		font-weight: 700;
		color: $grey-400;
	}
	
	&__selected-number {
		font-size: 14px;
		font-weight: 700;
		color: $white-100;
		padding: 8px 12px;
		background-color: $secondary-400;
		border-radius: 50%;
	}
	
	&__selected-website-wrapper {
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}
	
	&__selected-website {
		background-color: $primary-400;
		color: $white-100;
		font-weight: 700;
		padding: 5px 8px;
		border-radius: 12px;
		display: flex;
		gap: 10px;
	}
	
	&__remove-website-btn {
		cursor: pointer;
		margin-top: 2px;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__selected-domains-btn-wrapper {
		display: flex;
		gap: 10px;
		margin-top: 15px;
	}
	
	&__drop-wrapper {
		border: 2px dashed $grey-300;
		border-radius: 12px;
		margin-bottom: 10px;
		
		&--input-wrapper {
			padding: 15px;
			height: 100px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			
			input[type='file'] {
				position: absolute;
				z-index: 1;
				top: 70%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 158px;
				height: 40px;
				opacity: 0;
				cursor: pointer;
			}
			
			label {
				margin-bottom: 20px;
			}
		}
	}
	
	&__file-btn {
		background-color: $primary-400;
		cursor: pointer;
		border-radius: 8px;
		color: $white-100;
		font-size: 14px;
		font-weight: 600;
		padding-top: 9px;
		text-align: center;
		position: absolute;
		top: 70%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 158px;
		height: 40px;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__drop-text {
		font-size: 12px;
		color: $grey-300;
	}
	
	&__bg-green-300 {
		background-color: $green-300;
		padding: 20px;
		border-radius: 12px;
		
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
	}
	
	&__bg-gray-100 {
		background-color: $grey-200;
		padding: 20px;
		border-radius: 12px;
		
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
	}
	
	&:deep(.m-checkbox) {
		width: auto;
	}
	
	&:deep(.m-checkbox .v-selection-control__input) {
		color: $primary-400;
	}
	
	&:deep(.m-checkbox .v-selection-control--dirty .v-selection-control__input) {
		color: $secondary-400;
	}
	
	&:deep(.v-input__details) {
		display: none;
	}
}

:deep(.v-chip.v-chip--density-default) {
	display: none;
}
</style>
