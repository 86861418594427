import BaseApi from '@/api/BaseApi';
import type { CouponListModelInterface, CouponsCreateEditModelInterface } from '@/models/CouponsModel';

export default class CouponsApi extends BaseApi {
	async getCouponsList () {
		return this.get<CouponListModelInterface|any>({
			url: '/api/v1/coupons',
		});
	};

	async createCoupon (params: CouponsCreateEditModelInterface) {
		return this.post<CouponsCreateEditModelInterface, any>({
			url: '/api/v1/coupons',
			data: params,
		});
	};

	async editCurrentCoupon (couponUuid: Array<string>|string, params: CouponsCreateEditModelInterface) {
		return this.put<CouponsCreateEditModelInterface, any>({
			url: `/api/v1/coupons/${couponUuid}`,
			data: params,
		});
	};

	async deleteCurrentCoupon (couponUuid: string) {
		return this.delete({
			url: `/api/v1/coupons/${couponUuid}`,
		});
	};

	async getCoupon (couponUuid: string|Array<string>) {
		return this.get<CouponListModelInterface>({
			url: `/api/v1/coupons/${couponUuid}`,
		});
	};
};
