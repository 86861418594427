<template>
    <div class="heading-label">
        <div class="heading-label__text-wrapper">
            <div v-if="withoutIcon">
                <slot name="textBefore"></slot>
            </div>
	        <MIcon v-if="icon" :icon="icon" :height="height" :width="width" :color="color" />
            <MHexagonIcon v-if="!icon && !withoutIcon" :letter="letter" small :color="color" :bgc="bgc" />
            <div class="heading-label__text">
                {{ text }}
            </div>
        </div>
        <div class="heading-label__modal" v-if="modal">
            <v-dialog
                transition="dialog-bottom-transition"
                max-width="900"
            >
                <template v-slot:activator="{ props }">
                    <v-btn
                        class="heading-label__modal-btn"
                        v-bind="props"
                    >
                        <MHexagonIcon letter="?" small :color="white" />
                        <span class="heading-label__modal-btn--text">{{ $t('components.needHelp') }}</span>
                    </v-btn>
                </template>
                <template v-slot:default="{ isActive }">
                    <v-card>
                        <v-card-actions class="justify-end heading-label__modal-close">
                            <v-btn @click="isActive.value = false">
                                {{ $t('components.close') }} <MIcon icon="close-bold" :color="white" height="25" width="25" />
                            </v-btn>
                        </v-card-actions>
                        <v-toolbar class="heading-label__modal-title-wrapper" color="primary" dark>
                            <MIcon v-if="modalIcon" :icon="modalIcon" :width="modalIconWidth" :height="modalIconHeight" :color="modalIconColor" />
                            <div class="heading-label__modal-title">{{ modalTitle }}</div>
                            <div class="heading-label__modal-line"></div>
                        </v-toolbar>
                        <v-card-text>
                            <div class="heading-label__text-content">{{ modalText }}<slot></slot></div>
                        </v-card-text>
                    </v-card>
                </template>
            </v-dialog>
        </div>
        <slot name="beforeBtn"></slot>
        <MButton
            :id="btnId"
            v-if="btn"
            :icon="btnIcon"
            :color="btnIconColor"
            :width="btnIconWidth"
            :height="btnIconHeight"
            :label="btnText"
            big
            :red400="red400"
            :blue400="blue400"
            :green400="green400"
            :disabled="btnDisabled"
            @click="isButtonClick"
        />
        <div v-if="!btn && switchBtn" class="heading-label__switch-btn-wrapper">
            <MButton
                v-show="firstSwitchBtn"
                :icon="btnIcon"
                :color="btnIconColor"
                :width="btnIconWidth"
                :height="btnIconHeight"
                :label="btnText"
                big
                :red400="red400"
                :blue400="blue400"
                :green400="green400"
                :grey400="grey400"
                :disabled="btnDisabled"
                @click="isButtonClick"
            />
            <MButton
                v-show="secondSwitchBtn"
                :icon="secondSwitchBtnIcon"
                :color="secondSwitchBtnIconColor"
                :width="secondSwitchBtnIconWidth"
                :height="secondSwitchBtnIconHeight"
                :label="secondSwitchBtnLabel"
                big
                :red400="secondSwitchBtnRed400"
                :blue400="secondSwitchBtnBlue400"
                :green400="secondSwitchBtnGreen400"
                :grey400="secondSwitchBtnGrey400"
                :disabled="secondBtnDisabled"
                @click="isSecondButtonClick"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { defineProps, withDefaults } from 'vue';
import variables from '@/assets/scss/modules/variables.module.scss';
import MIcon from '@/components/atoms/MIcon.vue';
import type { TIcon } from '@/components/atoms/MIcon.vue';
import MHexagonIcon from '@/components/atoms/MHexagonIcon.vue';
import MButton from '@/components/atoms/MButton.vue';

interface IMIconProps {
    icon?: TIcon
    modalIcon?: TIcon
    modalIconWidth?: string
    modalIconHeight?: string
    modalIconColor?: string
    height?: string
    width?: string
    color?: string
}

interface IMHexagonIconProps {
    letter?: string|null
    bgc?: string
}

interface IMButtonProps {
    secondSwitchBtnIcon?: TIcon
    secondSwitchBtnIconColor?: string
    secondSwitchBtnIconWidth?: string
    secondSwitchBtnIconHeight?: string
    secondSwitchBtnLabel?: string
    secondSwitchBtnRed400?: boolean
    secondSwitchBtnBlue400?: boolean
    secondSwitchBtnGreen400?: boolean
	secondSwitchBtnGrey400?: boolean
    btnIcon?: TIcon
    btnText?: string
    btnId?: string|null
    btnIconColor?: string
    btnIconWidth?: string
    btnIconHeight?: string
    red400?: boolean
    blue400?: boolean
    green400?: boolean
	grey400?: boolean
	btnDisabled?: boolean
	secondBtnDisabled?: boolean
    isButtonClick?: Function
	isSecondButtonClick?: Function
}
interface IOwnProps {
    text: string
    modal?: boolean
    btn?: boolean
    modalTitle?: string
    modalText?: string
    switchBtn?: boolean
    firstSwitchBtn?: boolean
    secondSwitchBtn?: boolean
    withoutIcon?: boolean
}
interface IProps extends IOwnProps, IMButtonProps, IMIconProps, IMHexagonIconProps {
}

withDefaults(defineProps<IProps>(), {
	withoutIcon: false,
	height: '32',
	width: '29'
});

const { white } = variables;
</script>

<style scoped lang="scss">
.heading-label {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: $white-100;
    padding: 10px;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        padding: 20px;
    }

    &__text-wrapper {
        display: flex;
        align-items: center;

        @include media-breakpoint-up(md) {
            margin-right: auto;
        }
    }

    &__text {
        font-size: $size-29;
        margin-left: 20px;
    }

    &__modal {
        display: flex;
        margin: 10px 0 20px;
	    
	    &:deep(.v-btn__content) {
		    text-transform: none;
		    letter-spacing: 0;
		    font-weight: $weight-700;
	    }
	    
	    &:deep(.v-btn--variant-elevated) {
		    box-shadow: none;
	    }
    }

    &__modal-btn {
        &:hover {
            opacity: 0.5;
        }

        &--text {
            margin-left: 10px;
            text-transform: none;
            font-size: $size-19;
        }
    }

    &__modal-close {
        position: absolute;
        right: 0;
        z-index: 9;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.5;
        }
	    
	    &:deep(.v-btn__content) {
		    color: $white-100;
		    text-transform: none;
		    letter-spacing: 0;
		    font-weight: $weight-700;
	    }
    }

    &__modal-title-wrapper {
        box-shadow: none;
	    
	    &:deep(.v-toolbar__content) {
		    background-color: $secondary-400 !important;
		    display: flex;
		    flex-direction: column;
		    height: auto !important;
		    padding-top: 20px;
	    }
    }

    &__modal-title {
        color: $white-100;
        font-size: $size-18;
        font-weight: $weight-700;
        margin-top: 20px;
    }

    &__modal-line {
        margin-top: 10px;
        width: 95%;
        height: 2px;
        background-color: $secondary-600;
        opacity: 0.5;

        @include media-breakpoint-up(md) {
            width: 79%;
        }

        @include media-breakpoint-up(lg) {
            width: 86%;
        }
    }
	
	&__text-content {
		color: $white-100;
		font-weight: 700;
		font-size: 18px;
	}
	
	&__switch-btn-wrapper {
		display: flex;
		gap: 10px;
	}
}

:deep(.v-dialog) {
	border-radius: 25px !important;
	
	@include media-breakpoint-up(lg) {
		max-width: 900px !important;
	}
}

.v-application .primary {
    background-color: transparent !important;
    color: $secondary-400 !important;
}

.v-btn--is-elevated {
    box-shadow: none;
}

.theme--light.v-btn:hover::before,
.theme--light.v-btn:focus::before {
    opacity: 0;
}

.v-btn--is-elevated:active {
    box-shadow: none;
}

.v-application .text-h2 {
    padding: 110px 0 0 !important;
    font-size: $size-14 !important;
    color: $white-100 !important;
    font-weight: $weight-700;
    line-height: 1.7 !important;

    @include media-breakpoint-up(sm) {
        font-size: $size-18 !important;
    }
}

.v-dialog > .v-overlay__content > .v-card > .v-card-text {
	background-color: $secondary-400 !important;
}
</style>
