<template>
	<div class="password-validation-to-do-list">
        <span class="password-validation-to-do-list--item" :class="{'password-validation-to-do-list--correct': isSpecialCharacter}">
            {{ $t('validation.specialCharacter') }}
            <MIcon v-show="isSpecialCharacter" icon="tick" width="12" height="12" :color="green400" />
            <MIcon v-show="!isSpecialCharacter" icon="close" width="10" height="10" :color="secondary400" />
        </span>
		<span class="password-validation-to-do-list--item" :class="{'password-validation-to-do-list--correct': isUpperCharacter}">
            {{ $t('validation.specialUppercase') }}
            <MIcon v-show="isUpperCharacter" icon="tick" width="12" height="12" :color="green400" />
            <MIcon v-show="!isUpperCharacter" icon="close" width="10" height="10" :color="secondary400" />
        </span>
		<span class="password-validation-to-do-list--item" :class="{'password-validation-to-do-list--correct': isNumberCharacter}">
            {{ $t('validation.specialNumber') }}
            <MIcon v-show="isNumberCharacter" icon="tick" width="12" height="12" :color="green400" />
            <MIcon v-show="!isNumberCharacter" icon="close" width="10" height="10" :color="secondary400" />
        </span>
		<span class="password-validation-to-do-list--item" :class="{'password-validation-to-do-list--correct': min8Character}">
            {{ $t('validation.specialMinChar') }}
            <MIcon v-show="min8Character" icon="tick" width="12" height="12" :color="green400" />
            <MIcon v-show="!min8Character" icon="close" width="10" height="10" :color="secondary400" />
        </span>
	</div>
</template>

<script setup lang="ts">
import { ref, defineProps, watch } from 'vue';
import variables from '@/assets/scss/modules/variables.module.scss';
import MIcon from '@/components/atoms/MIcon.vue';

interface IOwnProps {
	password: string|null
}
interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();
const { secondary400, green400 } = variables;

const isSpecialCharacter = ref(false);
const isUpperCharacter = ref(false);
const isNumberCharacter = ref(false);
const min8Character = ref(false);

const checkPasswordValidation = () => {
	min8Character.value = false;
	isUpperCharacter.value = false;
	isNumberCharacter.value = false;
	isSpecialCharacter.value = false;
	
	if (null != props.password && 8 < props.password.length) {
		min8Character.value = true;
	}
	
	if (null != props.password && props.password.match(/(?=.*[A-Z])/)) {
		isUpperCharacter.value = true;
	}
	
	if (null != props.password && props.password.match(/(?=.*\d)/)) {
		isNumberCharacter.value = true;
	}
	
	if (null != props.password && props.password.match(/(?=.*\W)/)) {
		isSpecialCharacter.value = true;
	}
};

watch(([props]), (val) => {
	if (val) {
		checkPasswordValidation();
	}
}, {
	deep:true,
});
</script>

<style scoped lang="scss">
.password-validation-to-do-list {
	display: flex;
	flex-direction: column;
	gap: 3px;
	margin-bottom: 10px;
	color: $secondary-400;
	
	&--item {
		display: flex;
		align-items: center;
		gap: 3px;
	}
	
	&--correct {
		color: $green-400;
	}
}
</style>
