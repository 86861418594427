import BaseApi from '@/api/BaseApi';

export default class MonolithGoogleApi extends BaseApi {
	async googleLogin () {
		return this.post<any, any>({
			url: '/api/auth/login/google',
			config: {
				useMonolithApi: true,
			},
		});
	};
};
