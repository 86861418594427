import.meta.glob('@/i18n/*.json');

const i18nMessages:any = {};

const importMessages:any = import.meta.glob('@/i18n/*.json', { eager: true });

Object.keys(importMessages).forEach((path:any) => {
	// Extract language code from the filename (e.g., 'en.json' -> 'en')
	const langCode = path.split('/').pop().replace('.json', '');

	// Import the JSON file content
	i18nMessages[langCode] = importMessages[path].default;
});

export default i18nMessages;
