import dayjs, { Dayjs } from 'dayjs';
import { getStrPersisted } from '@/common/utils/LocalStorage'

export function setLocale () {
    const locale = window.navigator.language || 'en-gb'
    dayjs.locale(locale)
}

export function getLocale () {
    const locale = getStrPersisted('locale') || 'en-gb'
    return locale
}

export function dateFormat (format: string) {
    switch (format) {
        case 'time':
            return 'LT'
        case 'timeWithSeconds':
            return 'LTS'
        case 'numericalDate':
            return 'L'
        case 'numericalDateTime':
            return 'L LTS'
        case 'longDate':
            return 'dddd LL'
        case 'longDateTime':
            return 'LLLL'
        case 'shortDate':
            return 'ddd ll'
        case 'shortDateTime':
            return 'lll'
        default:
            return 'L'
    }
}

export function displayDate ({ date, format } : { date: string | Dayjs, format: string }) {
    if (null == date) return '-'

    switch (format) {
        case 'time':
            return dayjs.utc(date).local().format('LT')
        case 'timeWithSeconds':
            return dayjs.utc(date).local().format('LTS')
        case 'numericalDate':
            return dayjs.utc(date).local().format('L')
        case 'numericalDateTime':
            return dayjs.utc(date).local().format('L LTS')
        case 'longDate':
            return dayjs.utc(date).local().format('dddd LL')
        case 'longDateTime':
            return dayjs.utc(date).local().format('LLLL')
        case 'shortDate':
            return dayjs.utc(date).local().format('ddd ll')
        case 'shortDateTime':
            return dayjs.utc(date).local().format('lll')
        default:
            return dayjs.utc(date).local().format('L')
    }
}
