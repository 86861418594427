import { defineStore } from 'pinia';
import type {
	ActionHeadersTableSettingsPayload,
	ActionSortTableSettingsPayload,
	ActionTableSettingPayload,
	TableSettingsInterface,
	ActionPaginationTableSettingsPayload,
	ActionFiltersTableSettingsPayload
} from '@/models/TableSettingsModel';

type TableSettingsType = {
	'articles-list': TableSettingsInterface|null
	'articles-list-v2': TableSettingsInterface|null
	'publications-list': TableSettingsInterface|null
	'offers-list': TableSettingsInterface|null
	'campaigns-list': TableSettingsInterface|null
	'orders-list': TableSettingsInterface|null
	'carts-list': TableSettingsInterface|null
	'transactions-history': TableSettingsInterface|null
	'websites-list': TableSettingsInterface|null
	'website-offers-list': TableSettingsInterface|null
	'affiliates-list': TableSettingsInterface|null
	'order-groups-list': TableSettingsInterface|null
}

export const useTableSettingsStore = defineStore('useTableSettingsStore', {
	state: ():TableSettingsType|any => ({
		'articles-list': {
			id: null,
			uuid: null,
			table_definition_id: null,
			user_id: null,
			sort_column: 'updated',
			sort_order: 'desc',
			page: 1,
			per_page: 10,
			filters: {
				status: null,
				search: null,
				type: null,
				agencyAccount: null,
				locale: null,
			},
			columns: {
				active: [],
				inactive: []
			},
			created_at: null,
			updated_at: null
		},
		'articles-list-v2': {
			id: null,
			uuid: null,
			table_definition_id: null,
			user_id: null,
			sort_column: 'updated',
			sort_order: 'desc',
			page: 1,
			per_page: 10,
			filters: {
				search: null,
				type: null,
				agencyAccount: null,
				locale: null,
			},
			columns: {
				active: [],
				inactive: []
			},
			created_at: null,
			updated_at: null
		},
		'publications-list': {
			id: null,
			uuid: null,
			table_definition_id: null,
			user_id: null,
			sort_column: 'created_at',
			sort_order: 'desc',
			page: 1,
			per_page: 10,
			filters: {
				status: null,
				search: null,
				from: null,
				to: null,
				agencyAccount: null,
				locale: null,
			},
			columns: {
				active: [],
				inactive: []
			},
			created_at: null,
			updated_at: null
		},
		'offers-list': {
			id: null,
			uuid: null,
			table_definition_id: null,
			user_id: null,
			sort_column: null,
			sort_order: null,
			page: 1,
			per_page: 10,
			filters: {
				search: '',
				languages_ids: null,
				min_price: null,
				max_price: null,
				countries_ids: null,
				min_domain_rating: null,
				max_domain_rating: null,
				min_trust_flow: null,
				max_trust_flow: null,
				min_organic_traffic: null,
				max_organic_traffic: null,
				categories_ids: null,
				is_allowed_promotion_cbd: null,
				is_allowed_promotion_loans: null,
				is_allowed_promotion_bookmakers: null,
				is_trusted: null,
				article_disclosure: null,
				has_discount: null,
				exclude_owned: null,
				do_follow: null,
				exclude_ids: [],
				include_ids: [],
				publication_period: null,
				write_content: null,
				https: null,
				links_max_amount: null,
				is_publication_on_main_page: null,
				promo_facebook: null,
				days_promotion_on_main_page: null,
				fast_delivery: null,
				locale: null,
			},
			columns: {
				active: [],
				inactive: []
			},
			created_at: null,
			updated_at: null
		},
		'campaigns-list': {
			id: null,
			uuid: null,
			table_definition_id: null,
			user_id: null,
			sort_column: null,
			sort_order: null,
			page: 1,
			per_page: 10,
			filters: {
				agencyAccount: null,
				search: null,
				locale: null,
			},
			columns: {
				active: [],
				inactive: []
			},
			created_at: null,
			updated_at: null
		},
		'orders-list': {
			id: null,
			uuid: null,
			table_definition_id: null,
			user_id: null,
			sort_column: null,
			sort_order: null,
			page: 1,
			per_page: 10,
			filters: {
				agencyAccount: null,
				locale: null,
			},
			columns: {
				active: [],
				inactive: []
			},
			created_at: null,
			updated_at: null
		},
		'carts-list': {
			id: null,
			uuid: null,
			table_definition_id: null,
			user_id: null,
			sort_column: null,
			sort_order: null,
			page: 1,
			per_page: 10,
			filters: {
				agencyAccount: null,
				locale: null,
			},
			columns: {
				active: [],
				inactive: []
			},
			created_at: null,
			updated_at: null
		},
		'transactions-history': {
			id: null,
			uuid: null,
			table_definition_id: null,
			user_id: null,
			sort_column: null,
			sort_order: null,
			page: 1,
			per_page: 10,
			filters: {
				agencyAccount: null,
				locale: null,
			},
			columns: {
				active: [],
				inactive: []
			},
			created_at: null,
			updated_at: null
		},
		'websites-list': {
			id: null,
			uuid: null,
			table_definition_id: null,
			user_id: null,
			sort_column: 'websites.updated_at',
			sort_order: 'desc',
			page: 1,
			per_page: 10,
			filters: {
				agencyAccount: null,
				search: null,
				status: null,
				languages_ids: null,
				locale: null,
			},
			columns: {
				active: [],
				inactive: []
			},
			created_at: null,
			updated_at: null
		},
		'website-offers-list': {
			id: null,
			uuid: null,
			table_definition_id: null,
			user_id: null,
			sort_column: 'website_offers.updated_at',
			sort_order: 'desc',
			page: 1,
			per_page: 10,
			filters: {
				agencyAccount: null,
				search: null,
				status: null,
				locale: null,
			},
			columns: {
				active: [],
				inactive: []
			},
			created_at: null,
			updated_at: null
		},
		'affiliates-list': {
			id: null,
			uuid: null,
			table_definition_id: null,
			user_id: null,
			sort_column: null,
			sort_order: null,
			page: 1,
			per_page: 10,
			filters: {},
			columns: {
				active: [],
				inactive: []
			},
			created_at: null,
			updated_at: null
		},
		'order-groups-list': {
			id: null,
			uuid: null,
			table_definition_id: null,
			user_id: null,
			sort_column: 'updated_at',
			sort_order: 'desc',
			page: 1,
			per_page: 10,
			filters: {
				search: null,
				agencyAccount: null,
				campaign: null,
				languageId: null,
				orderType: null,
				status: null,
				locale: null,
			},
			columns: {
				active: [],
				inactive: []
			},
			created_at: null,
			updated_at: null
		},
	}),
	actions: {
		set (payload: ActionTableSettingPayload|any) {
			if (null == payload.settings) {
				this[payload.name] = null;
				return;
			}
			this[payload.name] = payload.settings;
			if (null != payload.settings.columns) {
				this[payload.name].columns = JSON.parse(payload.settings.columns);
			} else {
				this[payload.name].columns = {
					active: [],
					inactive: [],
				}
			}

			if (null != this[payload.name].sort_column) {
				this[payload.name].sort_column = payload.settings.sort_column;
			}

			if (null != this[payload.name].sort_order) {
				this[payload.name].sort_order = payload.settings.sort_order;
			}

			if (null != payload.settings.filters) {
				this[payload.name].filters = JSON.parse(payload.settings.filters);
			} else {
				this[payload.name].filters = {};
			}
		},
		setSort (payload: ActionSortTableSettingsPayload) {
			this[payload.name].sort_column = payload.sortBy;
			this[payload.name].sort_order = payload.sortDesc;
		},
		setHeaders (payload: ActionHeadersTableSettingsPayload) {
			this[payload.name].columns = payload.headers;
		},
		setPagination (payload: ActionPaginationTableSettingsPayload) {
			this[payload.name].page = payload.page;
			this[payload.name].per_page = payload.perPage;
		},
		setFilters (payload: ActionFiltersTableSettingsPayload) {
			this[payload.name].filters = payload.filters;
		},
	}
});
