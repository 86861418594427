<template>
	<div class="order-action-box">
		<div class="order-action-box__title">{{ $t('order.orderFlow.actions') }}</div>
		<div class="order-action-box__items-wrapper">
			<div v-for="item in items" :key="item.title">
				<div
					v-if="props.status !== item.status"
					class="order-action-box__item"
					:class="{
					'order-action-box__item--grey': props.status !== item.status && props.status !== RESIGNED_STATUS && checkItemStatusPriority(item.status) > statusNumber,
					'order-action-box__item--green': (props.status !== item.status && props.status !== RESIGNED_STATUS && checkItemStatusPriority(item.status) < statusNumber) || props.status === FINISHED_STATUS,
				}"
				>
					{{ item.title }}
				</div>
				<div
					v-if="props.status === item.status"
					id="action-btn"
					class="order-action-box__item order-action-box__item--link"
					:class="{'order-action-box__item--yellow': props.status === item.status}"
					@click="scrollToAction"
				>
					{{ item.title }}
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineProps, ref, defineEmits, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { ADVERTISER_ID, PUBLISHER_ID, MODERATOR_ID, CONTENT_WRITER_ID } from '@/hooks/UserHooks';
import {
	ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE,
	ORDER_TYPE_ARTICLE,
	ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE,
	ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE,
	CONTENT_IN_PROGRESS_STATUS,
	RESIGNED_STATUS,
	FINISHED_STATUS,
	CONTENT_SUBMITTED_STATUS,
	CONTENT_DISAPPROVED_STATUS,
	PUBLICATION_ASSIGNED_STATUS,
	PUBLICATION_IN_PROGRESS_STATUS,
	PUBLICATION_REPORT_COMMENT_STATUS,
	PUBLICATION_SUBMITTED_STATUS,
	PUBLICATION_DISAPPROVED_STATUS
} from '@/hooks/OrderFlowHooks';
interface IOwnProps {
	status: string
	userRoleId: number
	orderType: string
}
interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();
const emit = defineEmits(['scrollToAction']);

const { t } = useI18n();

const items = ref<Array<any>>([]);
const statusNumber = ref<number>(0);
const setActionItems = () => {
	// Wpisuje dla danego usera i jego roli oraz typu zamowienia w jakich statusach spodziewam sie danych itemow
	// musze podać wartość statusu w którym spodziewam się akcji do wykonania dla danego usera oraz typu zamówienia
	if (props.userRoleId === ADVERTISER_ID) {
		if (props.orderType === ORDER_TYPE_ARTICLE) {
			items.value = [
				{
					title: `${t('order.orderFlow.waitingForArticle')}`,
					status: CONTENT_IN_PROGRESS_STATUS,
				},
				{
					title: `${t('order.orderFlow.acceptArticle')}`,
					status: CONTENT_SUBMITTED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixArticleByContentWriter')}`,
					status: CONTENT_DISAPPROVED_STATUS,
				},
			];
		}
		if (props.orderType === ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE) {
			items.value = [
				{
					title: `${t('order.orderFlow.waitingForApproveArticle')}`,
					status: PUBLICATION_ASSIGNED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixArticle')}`,
					status: PUBLICATION_REPORT_COMMENT_STATUS,
				},
				{
					title: `${t('order.orderFlow.acceptPublication')}`,
					status: PUBLICATION_SUBMITTED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixPublicationByPublisher')}`,
					status: PUBLICATION_DISAPPROVED_STATUS,
				},
			];
		}
		if (props.orderType === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE) {
			items.value = [
				{
					title: `${t('order.orderFlow.waitingForArticle')}`,
					status: CONTENT_IN_PROGRESS_STATUS,
				},
				{
					title: `${t('order.orderFlow.acceptArticle')}`,
					status: CONTENT_SUBMITTED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixArticleByContentWriter')}`,
					status: CONTENT_DISAPPROVED_STATUS,
				},
				{
					title: `${t('order.orderFlow.waitingForApproveArticle')}`,
					status: PUBLICATION_ASSIGNED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixArticle')}`,
					status: PUBLICATION_REPORT_COMMENT_STATUS,
				},
				{
					title: `${t('order.orderFlow.waitingForPublication')}`,
					status: PUBLICATION_IN_PROGRESS_STATUS,
				},
				{
					title: `${t('order.orderFlow.acceptPublication')}`,
					status: PUBLICATION_SUBMITTED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixPublicationByPublisher')}`,
					status: PUBLICATION_DISAPPROVED_STATUS,
				},
			];
		}
		if (props.orderType === ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE) {
			items.value = [
				{
					title: `${t('order.orderFlow.waitingForArticle')}`,
					status: CONTENT_IN_PROGRESS_STATUS,
				},
				{
					title: `${t('order.orderFlow.acceptArticle')}`,
					status: CONTENT_SUBMITTED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixArticleByContentWriter')}`,
					status: CONTENT_DISAPPROVED_STATUS,
				},
				{
					title: `${t('order.orderFlow.waitingForPublication')}`,
					status: PUBLICATION_IN_PROGRESS_STATUS,
				},
				{
					title: `${t('order.orderFlow.acceptPublication')}`,
					status: PUBLICATION_SUBMITTED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixPublicationByPublisher')}`,
					status: PUBLICATION_DISAPPROVED_STATUS,
				},
			];
		}
	}
	
	if (props.userRoleId === CONTENT_WRITER_ID) {
		if (props.orderType === ORDER_TYPE_ARTICLE || props.orderType === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE) {
			items.value = [
				{
					title: `${t('order.orderFlow.writeArticle')}`,
					status: CONTENT_IN_PROGRESS_STATUS,
				},
				{
					title: `${t('order.orderFlow.waitingForApproveArticle')}`,
					status: CONTENT_SUBMITTED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixArticle')}`,
					status: CONTENT_DISAPPROVED_STATUS,
				},
			];
		}
	}
	
	if (props.userRoleId === PUBLISHER_ID) {
		if (props.orderType === ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE) {
			items.value = [
				{
					title: `${t('order.orderFlow.acceptArticle')}`,
					status: PUBLICATION_ASSIGNED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixArticleByContentWriter')}`,
					status: PUBLICATION_REPORT_COMMENT_STATUS,
				},
				{
					title: `${t('order.orderFlow.addLinks')}`,
					status: PUBLICATION_IN_PROGRESS_STATUS,
				},
				{
					title: `${t('order.orderFlow.waitingForApprovePublication')}`,
					status: PUBLICATION_SUBMITTED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixPublication')}`,
					status: PUBLICATION_DISAPPROVED_STATUS,
				},
			];
		}
		if (props.orderType === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE) {
			items.value = [
				{
					title: `${t('order.orderFlow.acceptArticle')}`,
					status: PUBLICATION_ASSIGNED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixArticleByContentWriter')}`,
					status: PUBLICATION_REPORT_COMMENT_STATUS,
				},
				{
					title: `${t('order.orderFlow.addLinks')}`,
					status: PUBLICATION_IN_PROGRESS_STATUS,
				},
				{
					title: `${t('order.orderFlow.waitingForApprovePublication')}`,
					status: PUBLICATION_SUBMITTED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixPublication')}`,
					status: PUBLICATION_DISAPPROVED_STATUS,
				},
			];
		}
		if (props.orderType === ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE) {
			items.value = [
				{
					title: `${t('order.orderFlow.writeArticle')}`,
					status: CONTENT_IN_PROGRESS_STATUS,
				},
				{
					title: `${t('order.orderFlow.waitingForApproveArticle')}`,
					status: CONTENT_SUBMITTED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixArticle')}`,
					status: CONTENT_DISAPPROVED_STATUS,
				},
				{
					title: `${t('order.orderFlow.addLinks')}`,
					status: PUBLICATION_IN_PROGRESS_STATUS,
				},
				{
					title: `${t('order.orderFlow.waitingForApprovePublication')}`,
					status: PUBLICATION_SUBMITTED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixPublication')}`,
					status: PUBLICATION_DISAPPROVED_STATUS,
				},
			];
		}
	}
	if (props.userRoleId === MODERATOR_ID) {
		if (props.orderType === ORDER_TYPE_ARTICLE) {
			items.value = [
				{
					title: `${t('order.orderFlow.writeArticle')}`,
					status: CONTENT_IN_PROGRESS_STATUS,
				},
				{
					title: `${t('order.orderFlow.acceptArticle')}`,
					status: CONTENT_SUBMITTED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixArticle')}`,
					status: CONTENT_DISAPPROVED_STATUS,
				},
			];
		}
		if (props.orderType === ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE) {
			items.value = [
				{
					title: `${t('order.orderFlow.acceptArticle')}`,
					status: PUBLICATION_ASSIGNED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixArticle')}`,
					status: PUBLICATION_REPORT_COMMENT_STATUS,
				},
				{
					title: `${t('order.orderFlow.addLinks')}`,
					status: PUBLICATION_IN_PROGRESS_STATUS,
				},
				{
					title: `${t('order.orderFlow.acceptPublication')}`,
					status: PUBLICATION_SUBMITTED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixPublication')}`,
					status: PUBLICATION_DISAPPROVED_STATUS,
				},
			];
		}
		if (props.orderType === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE) {
			items.value = [
				{
					title: `${t('order.orderFlow.writeArticle')}`,
					status: CONTENT_IN_PROGRESS_STATUS,
				},
				{
					title: `${t('order.orderFlow.acceptArticle')}`,
					status: CONTENT_SUBMITTED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixArticle')}`,
					status: CONTENT_DISAPPROVED_STATUS,
				},
				{
					title: `${t('order.orderFlow.acceptArticle')}`,
					status: PUBLICATION_ASSIGNED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixArticle')}`,
					status: PUBLICATION_REPORT_COMMENT_STATUS,
				},
				{
					title: `${t('order.orderFlow.addLinks')}`,
					status: PUBLICATION_IN_PROGRESS_STATUS,
				},
				{
					title: `${t('order.orderFlow.acceptPublication')}`,
					status: PUBLICATION_SUBMITTED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixPublication')}`,
					status: PUBLICATION_DISAPPROVED_STATUS,
				},
			];
		}
		if (props.orderType === ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE) {
			items.value = [
				{
					title: `${t('order.orderFlow.writeArticle')}`,
					status: CONTENT_IN_PROGRESS_STATUS,
				},
				{
					title: `${t('order.orderFlow.acceptArticle')}`,
					status: CONTENT_SUBMITTED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixArticle')}`,
					status: CONTENT_DISAPPROVED_STATUS,
				},
				{
					title: `${t('order.orderFlow.addLinks')}`,
					status: PUBLICATION_IN_PROGRESS_STATUS,
				},
				{
					title: `${t('order.orderFlow.acceptPublication')}`,
					status: PUBLICATION_SUBMITTED_STATUS,
				},
				{
					title: `${t('order.orderFlow.fixPublication')}`,
					status: PUBLICATION_DISAPPROVED_STATUS,
				},
			];
		}
	}
};
setActionItems();

const checkStatusPriority = () => {
	// Sprawdzam w jakim statusie obecnie jest zamowienie i przypisuje mu wartość liczbową
	const CONTENT_IN_PROGRESS_PRIORITY_NUMBER = 1;
	const CONTENT_SUBMITTED_PRIORITY_NUMBER = 2;
	const CONTENT_DISAPPROVED_PRIORITY_NUMBER = 3;
	const PUBLICATION_ASSIGNED_PRIORITY_NUMBER = 4;
	const PUBLICATION_REPORT_COMMENT_PRIORITY_NUMBER = 5;
	const PUBLICATION_IN_PROGRESS_PRIORITY_NUMBER = 6;
	const PUBLICATION_SUBMITTED_PRIORITY_NUMBER = 7;
	const PUBLICATION_DISAPPROVED_PRIORITY_NUMBER = 8;
	
	if (props.status === CONTENT_IN_PROGRESS_STATUS) {
		statusNumber.value = CONTENT_IN_PROGRESS_PRIORITY_NUMBER;
	}
	if (props.status === CONTENT_SUBMITTED_STATUS) {
		statusNumber.value = CONTENT_SUBMITTED_PRIORITY_NUMBER;
	}
	if (props.status === CONTENT_DISAPPROVED_STATUS) {
		statusNumber.value = CONTENT_DISAPPROVED_PRIORITY_NUMBER;
	}
	if (props.status === PUBLICATION_ASSIGNED_STATUS) {
		statusNumber.value = PUBLICATION_ASSIGNED_PRIORITY_NUMBER;
	}
	if (props.status === PUBLICATION_IN_PROGRESS_STATUS) {
		statusNumber.value = PUBLICATION_IN_PROGRESS_PRIORITY_NUMBER;
	}
	if (props.status === PUBLICATION_REPORT_COMMENT_STATUS) {
		statusNumber.value = PUBLICATION_REPORT_COMMENT_PRIORITY_NUMBER;
	}
	if (props.status === PUBLICATION_SUBMITTED_STATUS) {
		statusNumber.value = PUBLICATION_SUBMITTED_PRIORITY_NUMBER;
	}
	if (props.status === PUBLICATION_DISAPPROVED_STATUS) {
		statusNumber.value = PUBLICATION_DISAPPROVED_PRIORITY_NUMBER;
	}
};
checkStatusPriority();
const checkItemStatusPriority = (status:string) => {
	// Sprawdzam w jakim statusie obecnie jest item i przypisuje mu wartość liczbową
	const CONTENT_IN_PROGRESS_PRIORITY_NUMBER = 1;
	const CONTENT_SUBMITTED_PRIORITY_NUMBER = 2;
	const CONTENT_DISAPPROVED_PRIORITY_NUMBER = 3;
	const PUBLICATION_ASSIGNED_PRIORITY_NUMBER = 4;
	const PUBLICATION_REPORT_COMMENT_PRIORITY_NUMBER = 5;
	const PUBLICATION_IN_PROGRESS_PRIORITY_NUMBER = 6;
	const PUBLICATION_SUBMITTED_PRIORITY_NUMBER = 7;
	const PUBLICATION_DISAPPROVED_PRIORITY_NUMBER = 8;
	
	if (status === CONTENT_IN_PROGRESS_STATUS) {
		return CONTENT_IN_PROGRESS_PRIORITY_NUMBER;
	}
	if (status === CONTENT_SUBMITTED_STATUS) {
		return CONTENT_SUBMITTED_PRIORITY_NUMBER;
	}
	if (status === CONTENT_DISAPPROVED_STATUS) {
		return CONTENT_DISAPPROVED_PRIORITY_NUMBER;
	}
	if (status === PUBLICATION_ASSIGNED_STATUS) {
		return PUBLICATION_ASSIGNED_PRIORITY_NUMBER;
	}
	if (status === PUBLICATION_IN_PROGRESS_STATUS) {
		return PUBLICATION_IN_PROGRESS_PRIORITY_NUMBER;
	}
	if (status === PUBLICATION_REPORT_COMMENT_STATUS) {
		return PUBLICATION_REPORT_COMMENT_PRIORITY_NUMBER;
	}
	if (status === PUBLICATION_SUBMITTED_STATUS) {
		return PUBLICATION_SUBMITTED_PRIORITY_NUMBER;
	}
	if (status === PUBLICATION_DISAPPROVED_STATUS) {
		return PUBLICATION_DISAPPROVED_PRIORITY_NUMBER;
	}
	return CONTENT_IN_PROGRESS_PRIORITY_NUMBER;
};

const scrollToAction = () => {
	emit('scrollToAction');
};

watch(([props]), (val) => {
	if (val) {
		setActionItems();
		checkStatusPriority();
	}
}, {
	deep: true,
});
</script>

<style scoped lang="scss">
.order-action-box {
	background-color: $white-100;
	border-radius: 8px;
	padding: 16px;
	box-shadow: 10px 10px 40px rgba(0, 0, 0, .1);
	
	&__title {
		font-size: 20px;
		font-weight: 500;
		color: $primary-400;
		margin-bottom: 32px;
	}
	
	&__items-wrapper {
		height: 226px;
		overflow-y: scroll;
		padding-right: 16px;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	
	&__item {
		border: 1px solid $grey-300;
		border-radius: 8px;
		padding: 13px 8px 13px 45px;
		font-size: 12px;
		font-weight: 300;
		color: $primary-400;
		position: relative;
		
		&--link {
			display: flex;
			text-decoration: none;
			cursor: pointer;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}
		
		&:before {
			content: '';
			width: 16px;
			height: 16px;
			border-radius: 50%;
			background-color: $secondary-400;
			position: absolute;
			top: 50%;
			left: 15px;
			transform: translate(0, -50%);
		}
		
		&--yellow {
			&:before {
				background-color: $yellow-500;
			}
		}
		
		&--grey {
			&:before {
				background-color: $grey-300;
			}
		}
		
		&--green {
			&:before {
				background-color: $green-400;
			}
		}
	}
	
	::-webkit-scrollbar {
		width: 6px;
	}
	
	::-webkit-scrollbar-thumb {
		background-color: $primary-400;
		border-radius: 6px;
	}
	
	::-webkit-scrollbar-track {
		background-color: $grey-200;
	}
}
</style>
