<template>
	<div class="affiliate-program">
		<HeadingLabel
			class="affiliate-program__heading-label"
			icon="people-pulling"
			width="25"
			height="29"
			:text="$t('profile.affiliateProgram')"
			switch-btn
			:first-switch-btn="null != user && (isPublisher(user) || checkIsUserJoinAffiliateProgram(user))"
			green400
			:btn-text="$t('reflinks.withdraw')"
			:is-button-click="goToWithdrawal"
			:second-switch-btn="null != user && checkIsUserJoinAffiliateProgram(user)"
			second-switch-btn-red400
			:is-second-button-click="goToTransfer"
			:second-switch-btn-label="$t('reflinks.transfer')"
		/>
		
		<HeadingLabel
			class="affiliate-program__heading-label"
			icon="money"
			width="25"
			height="29"
			:text="$t('reflinks.affiliateProgramHistoryTransaction')"
		/>
		<HistoryPaymentTable is-affiliate-program />
		
		<HeadingLabel
			class="affiliate-program__heading-label affiliate-program__heading-label--transfer"
			icon="money"
			width="25"
			height="29"
			:text="$t('reflinks.affiliateTransferBetweenAccount')"
		/>
		<TransferBetweenAccountTable />
	</div>
</template>

<script setup lang="ts">
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { checkIsUserJoinAffiliateProgram, isPublisher } from '@/hooks/UserHooks';
import HistoryPaymentTable from '@/components/organisms/HistoryPayment/HistoryPaymentTable.vue';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import TransferBetweenAccountTable from '@/views/AffiliateProgram/components/TransferBetweenAccountTable.vue';

const { user } = storeToRefs(useUserStore());

const goToWithdrawal = () => {
	router.push('/withdrawal/create');
};
const goToTransfer = () => {
	router.push('/transfer');
};
</script>

<style scoped lang="scss">
.affiliate-program {
	&__heading-label {
		margin-bottom: 3px;
		
		&--transfer {
			margin-top: 3px;
		}
	}
}
</style>
