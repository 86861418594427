import { createPinia } from 'pinia';
import { useBooleanFlagStore } from '@/stores/booleanFlag';
import { useUserStore } from '@/stores/user';
import AuthApi from '@/api/v1/AuthApi';
import type { UserInterface } from '@/models/AuthModel';

const pinia = createPinia();
const booleanFlagStore = useBooleanFlagStore(pinia);
const userStore = useUserStore(pinia);

const authApi = new AuthApi();

export function isModerator (user: UserInterface) {
    return 4 === user.active_role_id;
}

export function isContentWriter (user: UserInterface) {
    return 5 === user.active_role_id;
}

export function isPublisher (user: UserInterface) {
    return 3 === user.active_role_id;
}

export function isAdvertiser (user: UserInterface) {
    return 2 === user.active_role_id;
}

export function isAdmin (user: UserInterface) {
    return 1 === user.active_role_id;
}

export const ADMIN_ID = 1;
export const ADVERTISER_ID = 2;
export const PUBLISHER_ID = 3;
export const MODERATOR_ID = 4;
export const CONTENT_WRITER_ID = 5;

// Testing users
export const TESTING_MODERATOR = 'automation.moderator@marketin9.com';
export const TESTING_CONTENT_WRITER = 'automation.content@marketin9.com';
export const TESTING_PUBLISHER_PLN = 'automation.publisher@marketin9.com';
export const TESTING_PUBLISHER_EUR = 'automation.publisher.eur@marketin9.com';
export const TESTING_ADVERTISER_PLN = 'automation.advertiser@marketin9.com';
export const TESTING_ADVERTISER_PLN_REFLINK = 'automation.advertiser-reflink-user@marketin9.com';
export const TESTING_ADVERTISER_EUR = 'automation.advertiser.eur@marketin9.com';
export const TESTING_ADVERTISER_EUR_REFLINK = 'automation.advertiser-reflink-user-eur@marketin9.com';

export const isAutomationUser = (user: UserInterface) => {
	if (
		null != user &&
		user.email !== TESTING_MODERATOR &&
		user.email !== TESTING_CONTENT_WRITER &&
		user.email !== TESTING_ADVERTISER_PLN &&
		user.email !== TESTING_ADVERTISER_PLN_REFLINK &&
		user.email !== TESTING_ADVERTISER_EUR &&
		user.email !== TESTING_ADVERTISER_EUR_REFLINK &&
		user.email !== TESTING_PUBLISHER_PLN &&
		user.email !== TESTING_PUBLISHER_EUR
	) {
		return true;
	}
	return false;
};

export const checkIsUserJoinAffiliateProgram = (user: UserInterface) => {
	return null != user.reflink_hash && '' !== user.reflink_hash;
};

export const AFFILIATE_ACCOUNT_ROLE_ID = 7;

export const checkIsUserIsAgencyMember = (user: UserInterface) => {
	return null != user.agency_id;
};

export const refreshUser = async() => {
	try {
		const result = await authApi.me();
		if (!result.isSuccess) {
			window.location.reload();
			return;
		}
		const currentWallet = result.payload.accounts.find(elem => elem.role_id === result.payload.active_role_id);
		const affiliateWallet = result.payload.accounts.find(elem => elem.role_id === AFFILIATE_ACCOUNT_ROLE_ID);

		return {
			current_wallet: currentWallet,
			affiliate_wallet: affiliateWallet,
			user: result.payload,
		}
	} catch (e) {
		window.location.reload();
		return;
	}
};
