import BaseApi from '@/api/BaseApi';
import type {
	IPaymentHistoryList,
	IPaymentInfoResponse,
	IPaymentRequest,
	IPaymentsMethodsResponse,
	IPaymentsResponse
} from '@/models/PaymentModel';
import type { TPaginate } from '@/models/GlobalModel';

export default class PaymentApi extends BaseApi {
	async paymentHistoryList (page: number, perPage: number, roleId: number|null|undefined, userId?: number|string|null, isAffiliateProgram?: boolean, isDashboardView?: boolean) {
		return this.get<IPaymentHistoryList>({
			url: `api/v1/payments/history?page=${page}&per_page=${perPage}${roleId ? '&role_id=' + roleId : ''}${userId ? '&user=' + userId : ''}${isAffiliateProgram ? '&affiliate_program=1' : ''}${isDashboardView ? '&preview=1' : ''}`,
		});
	};

	async pay (orderUuid: string|Array<string>, params: IPaymentRequest|undefined) {
		return this.post<IPaymentRequest, IPaymentsResponse>({
			url: `api/v1/payments/${orderUuid}/processing`,
			data: params,
		});
	};

	async paymentMethods (orderUuid: string|Array<string>, params: IPaymentRequest|undefined) {
		return this.post<IPaymentRequest, Array<IPaymentsMethodsResponse>>({
			url: `api/v1/payments/${orderUuid}/methods`,
			data: params,
		});
	};

	async paymentInfo (page: number, perPage: number, orderId: string|null,) {
		let url = `api/v1/payments/info?page=${page}&per_page=${perPage}`;

		if (orderId != null) {
			url += `&order_id=${orderId}`;
		}
		return this.get<TPaginate<IPaymentInfoResponse>>({
			url: url,
		});
	};
};
