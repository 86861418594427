<template>
	<div class="add-new-article">
		<HeadingLabel
			class="add-new-article__heading-label"
			icon="clipboard"
			width="32"
			height="29"
			:text="$t('articles.addNewArticle')"
			modal
			modal-icon="step-plus"
			modal-icon-width="70"
			modal-icon-height="71"
			:modal-icon-color="white"
			:modal-title="$t('articles.addNewArticle')"
		>
			<div class="add-new-article__modal-text">
				{{ $t('articles.helpDescription1') }}
			</div>
			<div class="add-new-article__modal-text">
				{{ $t('articles.helpDescription2') }}
			</div>
		</HeadingLabel>
		
		<div class="add-new-article__title">{{ $t('articles.title') }}</div>
		
		<div class="add-new-article__link-position-wrapper">
			<MButton to="/article/create/write" class="add-new-article__link-wrapper" id="write-article">
				<div class="add-new-article__link-item-wrapper">
					<div class="add-new-article__img-wrapper">
						<img src="~@/assets/images/write-an-article.jpg" alt="write an article">
					</div>
					<div class="add-new-article__link-text">
						{{ $t('articles.writeArticle') }}
					</div>
				</div>
			</MButton>
			
			<MButton to="/article/create/upload" class="add-new-article__link-wrapper" id="upload-article-from-disk">
				<div class="add-new-article__link-item-wrapper">
					<div class="add-new-article__img-wrapper">
						<img src="~@/assets/images/upload-an-article.jpg" alt="write an article">
					</div>
					<div class="add-new-article__link-text">{{ $t('articles.uploadArticleFromFile') }}</div>
				</div>
			</MButton>
			
			<div class="add-new-article__link-wrapper" id="upload-article-from-google-link" @click="openUploadFromGoogleLink">
				<div class="add-new-article__link-item-wrapper">
					<div class="add-new-article__img-wrapper">
						<img src="~@/assets/images/upload-article-by-google-link.png" alt="write an article">
					</div>
					<div class="add-new-article__link-text">{{ $t('articles.uploadArticleFromGoogleLink') }}</div>
				</div>
			</div>
			
			<MButton to="/article/create/upload/google-drive" class="add-new-article__link-wrapper" id="upload-article-from-google">
				<div class="add-new-article__link-item-wrapper">
					<div class="add-new-article__img-wrapper">
						<img src="~@/assets/images/upload-google-document.jpg" alt="write an article">
					</div>
					<div class="add-new-article__link-text">{{ $t('articles.uploadArticleGoogleDrive') }}</div>
				</div>
			</MButton>
		</div>
		
		<MDialog
			:width="700"
			:is-close-dialog-action="isCloseUploadFromLinkDialog"
			:is-open-dialog-action="isOpenUploadFromLinkDialog"
			:content-title="$t('articles.uploadArticleFromGoogleLink')"
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<div>
					<MAutocomplete
						id="upload-google-link-language"
						name="articleLanguage"
						v-model="articleLanguage"
						label-text-required
						:get-items="languages"
						variant="outlined"
						item-title="name"
						item-value="id"
						:label="$t('articles.language')"
						:placeholder="$t('articles.language')"
						clearable
					/>
					<MTextField
						id="input-link"
						v-model="link"
						name="email"
						label-text-required
						:separate-label="$t('articles.googleLink')"
						:placeholder="$t('articles.googleLink')"
						clearable
					/>
					<div class="add-new-article__dialog-btn-wrapper">
						<MButton
							id="upload-article-google-submit"
							width100
							green400
							normal
							:label="$t('articles.upload')"
							:loading="googleLinkLoading"
							:disabled="null == link || '' === link || null == articleLanguage"
							@click="uploadFromGoogleLinkAction"
						/>
						<MButton
							id="upload-article-google-close-dialog"
							width100
							blue400
							normal
							:label="$t('components.close')"
							@click="closeDialogWhenNoTargetPressed"
						/>
					</div>
				</div>
			</template>
		</MDialog>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useDictionaryStore } from '@/stores/dictionary';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import router from '@/router';
import ArticleApi from '@/api/v1/ArticleApi';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { IUploadArticleByGoogleLinkRequest } from '@/models/ArticlesModel';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MButton from '@/components/atoms/MButton.vue';
import MDialog from '@/components/atoms/MDialog.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';

const { languages } = useDictionaryStore();
const { t } = useI18n();
const { white } = variables;

const isCloseUploadFromLinkDialog = ref<boolean>(true);
const isOpenUploadFromLinkDialog = ref<boolean>(false);
const openUploadFromGoogleLink = () => {
	isOpenUploadFromLinkDialog.value = true;
	isCloseUploadFromLinkDialog.value = false;
};
const closeDialogWhenNoTargetPressed = () => {
	isCloseUploadFromLinkDialog.value = true;
	isOpenUploadFromLinkDialog.value = false;
};

const articleApi = new ArticleApi();
const articleLanguage = ref<number | null>(null);
const link = ref<string | null>(null);
const googleLinkLoading = ref<boolean>(false);
const uploadFromGoogleLinkAction = async () => {
	googleLinkLoading.value = true;
	
	const request:IUploadArticleByGoogleLinkRequest = {
		campaign_id: null,
		language_id: articleLanguage.value,
		url: link.value,
		title: null,
		publisher_note: null,
		footer_id: null,
	};
	try {
		const result = await articleApi.uploadArticleGoogleLink(request);
		if (!result.isSuccess) {
			toast.error(`${t('articles.toast.errorUploadArticleFromGoogleLink')}`);
			googleLinkLoading.value = false;
			return;
		}
		await closeDialogWhenNoTargetPressed();
		await router.push('/article');
		await toast.success(`${t('articles.toast.successUploadArticleFromGoogleLink')}`);
	} catch (e) {
		toast.error(`${t('articles.toast.errorUploadArticleFromGoogleLink')}`);
		googleLinkLoading.value = false;
		return;
	}
	googleLinkLoading.value = false;
};
</script>

<style scoped lang="scss">
.add-new-article {
	&__heading-label {
		margin-bottom: 5px;
	}
	
	&__modal-text {
		line-height: 1.4;
		
		@include media-breakpoint-up(md) {
			padding: 0 40px;
		}
		
		@include media-breakpoint-up(lg) {
			padding: 0 50px;
		}
		
		&:last-child {
			margin: 30px 0;
			
			@include media-breakpoint-up(lg) {
				margin: 30px 0 50px;
			}
		}
	}
	
	&__title {
		font-size: 15px;
		background-color: $white-100;
		padding: 10px 20px;
		
		@include media-breakpoint-up(md) {
			padding: 30px 25px;
		}
	}
	
	&__link-position-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		
		@include media-breakpoint-up(lg) {
			flex-direction: row;
			flex-wrap: wrap;
			gap: 10px;
		}
		
		a {
			text-decoration: none;
		}
		
		&:deep(.m-btn) {
			box-shadow: none;
		}
	}
	
	&__link-wrapper {
		margin-top: 10px;
		height: auto;
		cursor: pointer;
		width: 100%;
		transition: opacity 0.3s;
		
		@include media-breakpoint-up(lg) {
			margin-top: 20px;
			width: 49%;
		}
		
		@include media-breakpoint-up(xl) {
			width: 24%;
		}
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__link-item-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		background-color: $white-100;
		padding: 40px 10px;
		min-height: 421.5px;
		border-radius: 8px;
		
		@include media-breakpoint-up(sm) {
			padding: 40px 10px;
		}
		
		&--btn {
			letter-spacing: 0;
		}
	}
	
	&__img-wrapper {
		width: 233px;
		height: 240px;
		
		img {
			width: 100%;
		}
	}
	
	&__link-text {
		font-size: 27px;
		font-weight: 700;
		color: $secondary-400;
		margin-top: 10px;
		word-break: break-word;
		text-align: center;
		
		&--btn {
			margin-top: 15px;
		}
	}
	
	&__link-description {
		color: $primary-400;
		margin-top: 5px;
		
		&--btn {
			font-size: 12px;
			margin-top: 9px;
		}
	}
	
	&__dialog-btn-wrapper {
		display: flex;
		justify-content: space-between;
		margin-top: 6px;
	}
}
</style>
