<template>
	<div class="article-preview">
		<MProgressBar v-show="loading" indeterminate height="2" />
		
		<div class="article-preview__wrapper">
			<div class="article-preview__title">{{ articleDetails.title }}</div>
			<div v-html="articleDetails.content"></div>
			<img v-if="articleDetails.images.length > 0" :src="articleDetails.images[0].url" alt="article preview marketin9" class="article-preview__img" />
		</div>
		
		<MButton
			if="article-review-back"
			normal
			width100
			blue400
			:label="$t('articles.back')"
			@click="goBack"
		/>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import type { IArticleDetailsResponse } from '@/models/ArticlesModel';
import ArticleApi from '@/api/v1/ArticleApi';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import MButton from '@/components/atoms/MButton.vue';

const articleApi = new ArticleApi();

const { t } = useI18n();

const articleDetails = ref<IArticleDetailsResponse|any>({
	title: null,
	content: null,
	images: [
		{
			url: undefined,
		},
	],
});
const articleUuid = ref<string|Array<string>>('');
const loading = ref<boolean>(false);

if (null != router.currentRoute.value.params.articleUuid) {
	articleUuid.value = router.currentRoute.value.params.articleUuid;
}
const getArticleDetails = async() => {
	loading.value = true;
	try {
		const result = await articleApi.articleDetails(articleUuid.value);
		if (!result.isSuccess) {
			loading.value = false;
			toast.error(`${t('order.orderFlow.toast.errorGetArticleDetails')}`);
			return;
		}
		articleDetails.value.title = result.payload.title;
		articleDetails.value.content = result.payload.content;
		articleDetails.value.images = result.payload.images;
	} catch (e) {
		loading.value = false;
		toast.error(`${t('order.orderFlow.toast.errorGetArticleDetails')}`);
		return;
	}
	loading.value = false;
};
getArticleDetails();

const goBack = () => {
	router.push('/article');
};
</script>

<style scoped lang="scss">
.article-preview {
	&__wrapper {
		background-color: $white-100;
		border-radius: 12px;
		padding: 15px;
		margin-bottom: 20px;
	}
	
	&__title {
		font-size: 24px;
		font-weight: 700;
		margin-bottom: 20px;
	}
	
	&__img {
		margin-top: 10px;
		max-width: 100%;
		max-height: 100%;
	}
}
</style>
