import { defineStore } from 'pinia';
import type { UserInterface } from '@/models/AuthModel';
import * as Sentry from "@sentry/vue";

type UserType = {
	user: UserInterface|null
	userCurrencySymbol: string|null
	userWalletValue: number|null
	userAffiliateWalletValue: number|null
	isActiveCart: boolean
}

export const useUserStore = defineStore('useUserStore', {
	state: ():UserType => ({
		user: null,
		userCurrencySymbol: null,
		userWalletValue: null,
		userAffiliateWalletValue: null,
		isActiveCart: false,
	}),
	actions: {
		setUser (userValue: UserInterface|null) {
			this.user = userValue;
			Sentry.setUser({
				username: (this.user?.first_name || '') + ' ' + (this.user?.last_name || ''),
				email: this.user?.email || '',
				id: this.user?.id || '',
			});
		},
		setUserCurrencySymbol (currencySymbol: string|null) {
			this.userCurrencySymbol = currencySymbol;
		},
		setUserWalletValue (walletValue: number|null) {
			this.userWalletValue = walletValue;
		},
		setUserAffiliateWalletValue (affiliateWalletValue: number|null) {
			this.userAffiliateWalletValue = affiliateWalletValue;
		},
		setIsActiveCart (isActiveCartValue: boolean) {
			this.isActiveCart = isActiveCartValue;
		},
	},
});
