<template>
	<div class="statistics-publisher">
		<div class="statistics-publisher__wrapper">
			<div class="statistics-publisher__diagram-wrapper">
				<div class="statistics-publisher__diagram-top-wrapper">
					<div class="statistics-publisher__diagram-title">{{ $t('dashboard.netSum') }}</div>
				</div>
				<div class="statistics-publisher__diagram-bottom-wrapper">
					<div class="statistics-publisher__diagram-footer-wrapper">
						<div class="statistics-publisher__select-wrapper">
							<div>{{ $t('dashboard.show') }}:</div>
							<DatePickerWithMenu :max-date="todayDate" @emitDates="getStatistics" />
						</div>
						<div class="statistics-publisher__csv-wrapper">
							<MTooltip without-btn :max-width="309.5">
								<template v-slot:title>
									<MIcon icon="csv-file" width="20" height="20" @click="downloadCsvAction" />
								</template>
								<template v-slot:content>
									<div>{{ $t('dashboard.downloadStatistics') }}</div>
								</template>
							</MTooltip>
						</div>
					</div>
					<div class="statistics-publisher__chart-wrapper">
						<MChart v-if="chartData && chartOptions" chart-id="publisher-statistics" :chart-options="chartOptions" :chart-data="chartData" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { toast } from 'vue3-toastify';
import dayjs from 'dayjs';
import variables from '@/assets/scss/modules/variables.module.scss';
import DashboardApi from '@/api/v1/DashboardApi';
import {
	POSITIVE_TRENDING,
	NEGATIVE_TRENDING,
	CONSTANTS_TRENDING,
} from '@/hooks/StatisticsHook';
import type { IDashboardBestOffers, IDashboardCounterStatistics } from '@/models/DashboardPublisherModel';
import MChart from '@/components/atoms/MChart.vue';
import MButton from '@/components/atoms/MButton.vue';
import DatePickerWithMenu from '@/components/molecules/DatePickerWithMenu.vue';
import { WEBSITE_TYPE } from '@/hooks/DashboardActionItemsHooks';
import { DISAPPROVED } from '@/hooks/WebsitesHooks';
import MIcon from '@/components/atoms/MIcon.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';

const emit = defineEmits(['orderCountStats, bestOffers, statistics']);
const { t } = useI18n();
const { secondary200 } = variables;
const { user, userCurrencySymbol } = storeToRefs(useUserStore());
const dashboardApi = new DashboardApi();

const loading = ref<boolean>(false);
const todayDate = ref<string>(dayjs().format('YYYY-MM-DD'));
const statisticsCounterClientsItems = ref<IDashboardCounterStatistics|null>(null);
const statisticsCounterOrderItems = ref<IDashboardCounterStatistics|null>(null);
const statisticsCounterSalaryItems = ref<IDashboardCounterStatistics|null>(null);
const statisticsCounterAverageSalaryItems = ref<IDashboardCounterStatistics|null>(null);
const statisticsCounterDomainsItems = ref<IDashboardCounterStatistics|null>(null);
const bestOffersItems = ref<Array<IDashboardBestOffers>|any>(null);
const toDateCsv = ref<string>(dayjs().format('YYYY-MM-DD'));
const fromDateCsv = ref<string>(dayjs().subtract(30, 'days').format('YYYY-MM-DD'));

const chartData = ref<any>({
	labels: [''], // wartości na osi x (przyjmuje string/number)
	datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
		{
			label: '', // Nazwa wyświetlanej grupy
			type: 'bar', // Typ wykresu słupkowego
			data: [''], // wartości na osi y
			backgroundColor: secondary200, // Zmiana koloru słupków
		},
	],
});
const chartOptions = ref<any>({
	responsive: true,
	plugins: {
		legend: {
			display: false, // Wyswietlanie/Chowanie tytułu wykresu
			text: 'Tytuł legendy', // Twoj tytul legendy
		},
		title: {
			display: false, // Wyswietlanie/Chowanie tytułu wykresu
			text: 'Tytuł wykresu', // Twoj tytul wykresu
		},
		tooltip: {
			callbacks: {
				label: function (tooltipItem: any) {
					let label = tooltipItem.dataset.data[tooltipItem.dataIndex] || ''; // Customowe ustawienie 2 miejsc po przeciunku w tooltipie (mozna zrobic console.loga z tooltipItem po najechaniu sie pokaze)
					if (null != label) {
						return `${t('dashboard.netSumChart')}: ` + (+label).toFixed(2) + ` ${userCurrencySymbol.value}`;
					}
					return `${t('dashboard.netSumChart')}: ` + '0.00' + ` ${userCurrencySymbol.value}`;
				},
			},
		},
	},
	scales: {
		x: {
			grid: {
				display: false, // Chowanie lini osi x
			},
		},
		y: {
			grid: {
				display: true, // Chowanie lini osi y
			},
			ticks: {
				callback: function (value:number) {
					return Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ` ${userCurrencySymbol.value}`; // Formatowanie do dwóch miejsc po przecinku
				},
			},
		},
	},
});

const getStatistics = async(to:string, from:string) => {
	loading.value = true;
	emit('orderCountStats', loading.value, null);
	emit('bestOffers', null, true);
	emit('statistics', null, null, null, null, null, true);
	let availableFromDate = from;
	if (dayjs(from).diff(dayjs(user.value?.created_at), 'days') < 0) {
		availableFromDate = dayjs(user.value?.created_at).format('DD-MM-YYYY');
	}
	toDateCsv.value = to;
	fromDateCsv.value = availableFromDate;
	if (null != user.value) {
		const diff = dayjs(to).diff(from, 'days');
		try {
			const result = await dashboardApi.publisherDashboard(user.value.id, to, availableFromDate, diff > 30 ? true : false);
			if (!result.isSuccess) {
				toast.error(`${t('dashboard.toast.errorGetStatistics')}`);
				loading.value = false;
				return;
			}
			emit('orderCountStats', false, result.payload);
			let keys:Array<string> = [];
			let values:Array<string|number> = [];
			keys = Object.keys(result.payload.chart);
			values = Object.values(result.payload.chart);
			const changeStringToNumbers:Array<number> = [];
			values.forEach(elem => {
				changeStringToNumbers.push(+elem / 100);
			});
			chartData.value = {
				labels: keys, // wartości na osi x (przyjmuje string/number)
				datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
					{
						label: '', // Nazwa wyświetlanej grupy
						type: 'bar', // Typ wykresu słupkowego
						data: changeStringToNumbers, // wartości na osi y
						backgroundColor: secondary200, // Zmiana koloru słupków
					},
				],
			};
			statisticsCounterClientsItems.value = {
				currency_symbol: userCurrencySymbol.value,
				percent: trendingNumber(null != result.payload.total_clients ? result.payload.total_clients : 0, null != result.payload.past_total_clients ? result.payload.past_total_clients : 0),
				type: trendingDirection(null != result.payload.total_clients ? result.payload.total_clients : 0, null != result.payload.past_total_clients ? result.payload.past_total_clients : 0),
				counter: result.payload.total_clients,
			};
			statisticsCounterOrderItems.value = {
				currency_symbol: userCurrencySymbol.value,
				percent: trendingNumber(null != result.payload.total_orders ? result.payload.total_orders : 0, null != result.payload.past_total_orders ? result.payload.past_total_orders : 0),
				type: trendingDirection(null != result.payload.total_orders ? result.payload.total_orders : 0, null != result.payload.past_total_orders ? result.payload.past_total_orders : 0),
				counter: result.payload.total_orders,
			};
			statisticsCounterSalaryItems.value = {
				currency_symbol: userCurrencySymbol.value,
				percent: trendingNumber(null != result.payload.total_income ? (result.payload.total_income / 100) : 0, null != result.payload.past_total_income ? (result.payload.past_total_income / 100) : 0),
				type: trendingDirection(null != result.payload.total_income ? result.payload.total_income : 0, null != result.payload.past_total_income ? result.payload.past_total_income : 0),
				counter: null != result.payload.total_income ? (result.payload.total_income / 100).toFixed(2) : 0,
			};
			statisticsCounterAverageSalaryItems.value = {
				currency_symbol: userCurrencySymbol.value,
				percent: trendingNumber(null != result.payload.average_income ? (result.payload.average_income / 100) : 0, null != result.payload.past_average_income ? (result.payload.past_average_income / 100) : 0),
				type: trendingDirection(null != result.payload.average_income ? result.payload.average_income : 0, null != result.payload.past_average_income ? result.payload.past_average_income : 0),
				counter: null != result.payload.average_income ? (result.payload.average_income / 100).toFixed(2) : 0,
			};
			statisticsCounterDomainsItems.value = {
				currency_symbol: userCurrencySymbol.value,
				percent: trendingNumber(null != result.payload.total_domains ? result.payload.total_domains : 0, null != result.payload.past_total_domains ? result.payload.past_total_domains : 0),
				type: trendingDirection(null != result.payload.total_domains ? result.payload.total_domains : 0, null != result.payload.past_total_domains ? result.payload.past_total_domains : 0),
				counter: result.payload.total_domains,
			};
			emit('statistics', statisticsCounterClientsItems.value, statisticsCounterOrderItems.value, statisticsCounterSalaryItems.value, statisticsCounterAverageSalaryItems.value,  statisticsCounterDomainsItems.value, false);
			
			const bestOffers = result.payload.best_offers;
			if (bestOffers.length > 0) {
				bestOffersItems.value = bestOffers.slice(0, 5);
				emit('bestOffers', bestOffersItems.value, false);
			} else {
				bestOffersItems.value = bestOffers;
				emit('bestOffers', bestOffersItems.value, false);
			}
		} catch (e) {
			toast.error(`${t('dashboard.toast.errorGetStatistics')}`);
			loading.value = false;
			return;
		}
	}
	
	loading.value = false;
};
const trendingNumber = (currentNumber:number, pastNumber:number) => {
	if (currentNumber === pastNumber) {
		const zero = 0;
		return zero.toFixed(0);
	}
	const zeroChangeToOne = 1;
	const percentageDifference = (Math.max(currentNumber, pastNumber) / (Math.min(currentNumber, pastNumber) === 0 ? zeroChangeToOne : Math.min(currentNumber, pastNumber)) * 100).toFixed(0);
	return percentageDifference;
};
const trendingDirection = (currentNumber:number, pastNumber:number) => {
	if (currentNumber > pastNumber) {
		return POSITIVE_TRENDING;
	}
	if (pastNumber > currentNumber) {
		return NEGATIVE_TRENDING;
	}
	return CONSTANTS_TRENDING;
};

const downloadCsvAction = async() => {
	if (null != user.value) {
		const diff = dayjs(toDateCsv.value).diff(fromDateCsv.value, 'days');
		try {
			const result = await dashboardApi.getStatistics(user.value.id, toDateCsv.value, fromDateCsv.value, diff > 30 ? true : false);
			if (!result.isSuccess) {
				toast.error(`${t('dashboard.toast.errorGetCSV')}`);
				return;
			}
			const blob = new Blob([result.payload], { type: 'text/csv' });
			const url = URL.createObjectURL(blob);
			
			const link = document.createElement('a');
			link.href = url;
			link.download = 'statistics.csv'; // Nazwa pliku do pobrania
			link.click();
			
			URL.revokeObjectURL(url);
		} catch (e) {
			toast.error(`${t('dashboard.toast.errorGetCSV')}`);
			return;
		}
	}
};
</script>

<style scoped lang="scss">
.statistics-publisher {
	width: 100%;
	
	&__title {
		font-size: 24px;
		font-weight: 700;
		color: $primary-400;
		margin-top: 24px;
	}
	
	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		
		@include media-breakpoint-up(xl) {
			flex-direction: row;
			gap: 0;
		}
	}
	
	&__diagram-wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	
	&__diagram-top-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	&__diagram-bottom-wrapper {
		width: 100%;
		background-color: $white-100;
		margin-top: 20px;
		padding: 15px;
		border-radius: 10px;
		
		@include media-breakpoint-up(xxl) {
			height: 342px + 95px + 20px;
			max-width: 800px;
		}
	}
	
	&__chart-wrapper {
		width: 100%;
		
		@include media-breakpoint-up(xxl) {
			max-height: 342px + 45px;
		}
	}
	
	&__diagram-title {
		color: $primary-400;
		font-size: 24px;
		font-weight: 700;
		
		&--white-bgc {
			background-color: $white-100;
			padding: 10px 15px;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
			position: relative;
		}
	}
	
	&__select-wrapper {
		display: flex;
		align-items: center;
		gap: 5px;
	}
	
	&__loader {
		position: absolute;
		top: 100%;
		left: 0;
	}
	
	&__csv-wrapper {
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__diagram-footer-wrapper {
		margin-bottom: 10px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}
	
	&__hover {
		width: max-content;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
}
</style>
