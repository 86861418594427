<template>
	<div class="privacy">
		<BeforeLoginLayout>
			<template v-slot:default>
				<div>
					<img src="~@/assets/images/logo-reverse.png" alt="marketing logo" class="privacy__logo">
					<div class="privacy__title">{{ $t('static.privacyPolicy.privacyPolicy') }}</div>
					<div class="privacy__language-wrapper">
						<div class="footer__language-label">{{ $t('auth.chooseLanguage') }}</div>
						<div class="privacy__language-list">
							<MMenu offset="0 0">
								<template v-slot:menuActivator>
									<div class="privacy__language-list">
										<div class="privacy__flag">
											<MFlag :country-code="localeLanguage" />
										</div>
										<MIcon class="privacy__arrow" icon="arrow-down" />
									</div>
								</template>
								<template v-slot:menuItems>
									<MButton @click="switchLanguage('pl')">
										<MFlag country-code="pl" />
									</MButton>
									<MButton @click="switchLanguage('en')">
										<MFlag country-code="gb" />
									</MButton>
								</template>
							</MMenu>
						</div>
					</div>
					<div class="privacy__text-wrapper">
						<div>{{ $t('static.privacyPolicy.privacyPolicyDescription1') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyDescription2') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyDescription3') }} help@marketin9.com</div>
						<div class="privacy__subtitle">{{ $t('static.privacyPolicy.privacyPolicyP1') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP11') }}</div>
						<div>
							<div>{{ $t('static.privacyPolicy.privacyPolicyP11Description11') }}</div>
							<div>{{ $t('static.privacyPolicy.privacyPolicyP11Description12') }}</div>
							<div>{{ $t('static.privacyPolicy.privacyPolicyP11Description13') }}</div>
							<div>{{ $t('static.privacyPolicy.privacyPolicyP11Description14') }}</div>
							<div>{{ $t('static.privacyPolicy.privacyPolicyP11Description15') }}</div>
						</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP11Description2') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP12') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP13') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP14') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP15') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP16') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP17') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP18') }}</div>
						<div class="privacy__subtitle">{{ $t('static.privacyPolicy.privacyPolicyP2') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP21') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP22') }}</div>
						<div class="privacy__subtitle">{{ $t('static.privacyPolicy.privacyPolicyP3') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP31') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP32') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP33') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP34') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP35') }} help@marketin9.com.</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP36') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP37') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP38') }}</div>
						<ul>
							<li class="privacy__list-item">{{ $t('static.privacyPolicy.privacyPolicyP381') }}</li>
							<li class="privacy__list-item">{{ $t('static.privacyPolicy.privacyPolicyP382') }}</li>
							<li class="privacy__list-item">{{ $t('static.privacyPolicy.privacyPolicyP383') }}</li>
							<li class="privacy__list-item">{{ $t('static.privacyPolicy.privacyPolicyP384') }}</li>
							<li class="privacy__list-item">{{ $t('static.privacyPolicy.privacyPolicyP385') }}</li>
							<li class="privacy__list-item">{{ $t('static.privacyPolicy.privacyPolicyP386') }}</li>
						</ul>
						<div class="privacy__subtitle">{{ $t('static.privacyPolicy.privacyPolicyP4') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP41') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP42') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP43') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP44') }}</div>
						<div class="privacy__subtitle">{{ $t('static.privacyPolicy.privacyPolicyP5') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP51') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP52') }}</div>
						<div class="privacy__subtitle">{{ $t('static.privacyPolicy.privacyPolicyP6') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP61') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP62') }}</div>
						<div class="privacy__list-item">1. {{ $t('static.privacyPolicy.privacyPolicyP621') }} <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a></div>
						<div class="privacy__list-item">2. {{ $t('static.privacyPolicy.privacyPolicyP622') }} <a href="https://www.facebook.com/policy.php" target="_blank">https://www.facebook.com/policy.php</a></div>
						<div class="privacy__list-item">3. {{ $t('static.privacyPolicy.privacyPolicyP623') }} <a href="https://www.hotjar.com/legal/policies/privacy" target="_blank">https://www.hotjar.com/legal/policies/privacy</a></div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP63') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP64') }}</div>
						<div class="privacy__subtitle">{{ $t('static.privacyPolicy.privacyPolicyP7') }}</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP71') }} <a href="https://www.marketin9.com/contact-us/" target="_blank">{{ $t('static.privacyPolicy.privacyPolicyP711') }}</a> {{ $t('static.privacyPolicy.privacyPolicyP712') }} help@marketin9.com, {{ $t('static.privacyPolicy.privacyPolicyP713') }}</div>
						<div>
							<div>{{ $t('static.privacyPolicy.privacyPolicyP11Description11') }}</div>
							<div>{{ $t('static.privacyPolicy.privacyPolicyP11Description12') }}</div>
							<div>{{ $t('static.privacyPolicy.privacyPolicyP11Description13') }}</div>
							<div>{{ $t('static.privacyPolicy.privacyPolicyP11Description14') }}</div>
							<div>{{ $t('static.privacyPolicy.privacyPolicyP11Description15') }}</div>
						</div>
						<div>{{ $t('static.privacyPolicy.privacyPolicyP72') }}</div>
					</div>
					<MButton
						id="go-back"
						normal
						blue400
						@click="goBack"
						:label="$t('components.back')"
					/>
				</div>
			</template>
		</BeforeLoginLayout>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@/stores/session';
import { switchLanguage } from '@/hooks/LocaleHooks';
import BeforeLoginLayout from '@/views/Layout/components/BeforeLoginLayout.vue';
import MButton from '@/components/atoms/MButton.vue';
import MFlag from '@/components/atoms/MFlag.vue';
import MMenu from '@/components/atoms/MMenu.vue';
import MIcon from '@/components/atoms/MIcon.vue';

const { locale } = storeToRefs(useSessionStore());

const localeLanguage = ref<string>('');
if ('us' === locale.value) {
	localeLanguage.value = 'en';
} else if (locale.value != null) {
	localeLanguage.value = locale.value;
} else {
	localeLanguage.value = 'pl';
}

const goBack = () => {
	router.push('/');
};
</script>

<style scoped lang="scss">
.privacy {
	&__logo {
		max-width: 224px;
		display: flex;
		margin: 20px auto;
	}
	
	&__title {
		font-size: 29px;
		font-weight: 700;
		color: $primary-400;
		text-align: center;
	}
	
	&__language-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	
	&__language-label {
		margin-right: -30px;
	}
	
	&__language-list {
		display: flex;
		align-items: center;
	}
	
	&__flag {
		margin-bottom: 5px;
		margin-right: 5px;
	}
	
	&__arrow {
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__text-wrapper {
		display: flex;
		flex-direction: column;
		gap: 15px;
		font-size: 13px;
	}
	
	&__subtitle {
		font-weight: 700;
	}
	
	&__list-item {
		margin-left: 40px;
	}
	
	&:deep(.before-login-page__background) {
		height: 100%;
		object-fit: cover;
	}
	
	&:deep(.before-login-page__wrapper) {
		margin: 110px 0;
	}
}
</style>
