<template>
	<div class="contadu">
		<HeadingLabel
			class="contadu__heading-label"
			icon="arrow-trend-up"
			width="32"
			height="29"
			:text="$t('contadu.contadu')"
		/>
		
		<MProgressBar v-show="loading" indeterminate height="2" />
		<div class="contadu__wrapper">
			<div class="contadu__main-switch-wrapper">
				<div class="contadu__text">{{ $t('contadu.status') }}</div>
				<div class="contadu__switch-wrapper">
					<div class="contadu__text contadu__text--uppercase">{{ $t('contadu.off') }}</div>
					<MSwitch
						id="contadu-status"
						v-model="status"
						@change="save(true)"
					/>
					<div class="contadu__text contadu__text--uppercase">{{ $t('contadu.on') }}</div>
				</div>
			</div>
			<div class="contadu__limit-wrapper">
				<div class="contadu__text">{{ $t('contadu.optimalizationLimit') }}</div>
				<MTextField
					id="contadu-limit"
					name="contaduLimit"
					v-model="contaduLimit"
					type="number"
					step="1"
					min="0"
				/>
				<MButton
					id="contadu-limit-save-btn"
					normal
					red400
					:label="$t('contadu.save')"
					@click="save(false)"
					:loading="loading"
				/>
			</div>
			<div class="contadu__available-wrapper">
				<div class="contadu__text">{{ $t('contadu.optimalizationAvailable') }}:</div>
				<div class="contadu__used">{{ availableOptimalization }}</div>
			</div>
		</div>
		
		<ContaduHistoryTable />
	</div>
</template>

<script setup lang="ts">
import { defineProps, ref, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
import ContaduApi from '@/api/v1/ContaduApi';
import UserApi from '@/api/v1/UserApi';
import { toast } from 'vue3-toastify';
import type { UserInterface } from '@/models/AuthModel';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MSwitch from '@/components/atoms/MSwitch.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MButton from '@/components/atoms/MButton.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import ContaduHistoryTable from '@/views/Profile/components/ContaduHistoryTable.vue';

interface IOwnProps {
	currentUser: UserInterface
}

interface IProps extends IOwnProps {
}

const contaduApi = new ContaduApi();
const userApi = new UserApi();

const props = defineProps<IProps>();
const emit = defineEmits(['userDetails']);

const { t } = useI18n();

const status = ref<boolean>(false);
const loading = ref<boolean>(false);
const availableOptimalization = ref<number>(0);
const contaduLimit = ref<number|null>(props.currentUser.contadu_limit);

if (null != props.currentUser.contadu_limit && 0 !== props.currentUser.contadu_limit) {
	status.value = true;
}
const contaduCounter = async() => {
	try {
		const result = await contaduApi.availableOptimalizations(props.currentUser.uuid);
		if (!result.isSuccess) {
			toast.error(`${t('contadu.toast.limit')}`);
			return;
		}
		availableOptimalization.value = result.payload;
	} catch (e) {
		toast.error(`${t('contadu.toast.limit')}`);
		return;
	}
};
contaduCounter();
const save = async(isStatusChange:boolean) => {
	loading.value = true;
	if (null == contaduLimit.value) {
		loading.value = false;
		return;
	}
	const contaduForm:any = {
		contadu_limit: +contaduLimit.value,
	};
	if (isStatusChange) {
		if (status.value) {
			contaduForm.contadu_limit = 10;
		} else {
			contaduForm.contadu_limit = 0;
		}
	}
	try {
		const result = await userApi.updateProfile(contaduForm, props.currentUser.uuid);
		if (!result.isSuccess) {
			toast.error(`${t('contadu.toast.saveError')}`);
			loading.value = false;
			return;
		}
		toast.success(`${t('contadu.toast.saveSuccess')}`);
		await contaduCounter();
		emit('userDetails');
	} catch (e) {
		toast.error(`${t('contadu.toast.saveError')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
</script>

<style scoped lang="scss">
.contadu {
	&__heading-label {
		padding-bottom: 10px;
	}
	
	&__wrapper {
		background-color: $white-100;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		text-align: center;
		gap: 20px;
		margin-top: 3px;
		margin-bottom: 3px;
		padding: 20px;
		
		@include media-breakpoint-up(md) {
			flex-direction: row;
		}
	}
	
	&__main-switch-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	
	&__switch-wrapper {
		display: flex;
		align-items: center;
		gap: 15px;
		
		&:deep(.v-input__details) {
			display: none;
		}
	}
	
	&__text {
		font-size: 14px;
		font-weight: 700;
		color: $grey-400;
		width: max-content;
		
		&--uppercase {
			text-transform: uppercase;
		}
	}
	
	&__limit-wrapper {
		display: flex;
		align-items: center;
		gap: 5px;
		min-width: 300px;
		
		&:deep(.v-input__details) {
			display: none;
		}
		
		&:deep(.m-input) {
			width: 150px;
		}
	}
	
	&__available-wrapper {
		display: flex;
		align-items: center;
		gap: 5px;
	}
	
	&__used {
		font-size: 16px;
		font-weight: 700;
		color: $primary-400;
	}
}
</style>
