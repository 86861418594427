import { defineStore } from 'pinia';
type SessionType = {
	locale: string|null
	active_role: number|null
	version: string|null
	maintenance_mode: boolean|null
}
export const useSessionStore = defineStore('useSessionStore', {
	state: ():SessionType => ({
		locale: null,
		active_role: null,
		version: null,
		maintenance_mode: null,
	}),
	actions: {
		setLocale (localeValue:string|null) {
			this.locale = localeValue;
		},
		setActiveRole (activeRoleValue: number|null) {
			this.active_role = activeRoleValue;
		},
		setVersion (versionValue: string|null) {
			this.version = versionValue;
		},
		setMaintenanceMode (maintenanceMode: boolean|null) {
			this.maintenance_mode = maintenanceMode;
		},
	},
});
