import BaseApi from '@/api/BaseApi';
import type {
	IChangePasswordForm,
	IConsentsForm,
	INotificationForm,
	IUpdateAddressRequest,
	IUpdateProfileRequest,
	UserInterface
} from '@/models/AuthModel';
import type { IDiscountGroupList } from '@/models/DiscountGroupsModel';

export default class UserApi extends BaseApi {
	async usersList (usersSearch: string|null) {
		return this.get<any>({
			url: `/api/v1/users?filter=${usersSearch}`,
		});
	};

	async userInfo (uuid: string|Array<string>) {
		return this.get<UserInterface>({
			url: `/api/v1/users/profile/${uuid}`,
		});
	};

	async contentWritersList () {
		return this.get<Array<UserInterface>>({
			url: '/api/v1/users/roles/5',
		});
	};

	async userGroupList () {
		return this.get<Array<IDiscountGroupList>>({
			url: '/api/v1/discount-groups',
		});
	};

	async updateProfile (params: IUpdateProfileRequest, userUuid?: string|null) {
		let url = '/api/v1/users/profile';

		if (null != userUuid) {
			url += `/${userUuid}`;
		}
		return this.put<IUpdateProfileRequest, any>({
			url: url,
			data: params,
		});
	};

	async updateAddress (params: IUpdateAddressRequest, userUuid:string) {
		return this.put<IUpdateAddressRequest, boolean>({
			url: `api/v1/users/profile/address/${userUuid}`,
			data: params,
		});
	};

	async editNotifications (params: INotificationForm, uuid: string|null) {
		return this.put<INotificationForm, boolean>({
			url: `/api/v1/users/profile/${uuid}/notifications`,
			data: params,
		});
	};

	async editTerms (params: IConsentsForm, uuid: string|null) {
		return this.put<IConsentsForm, any>({
			url: `/api/v1/users/profile/${uuid}/terms`,
			data: {
				'terms-and-condition': params['terms-and-condition'],
				'marketing-agreement': params['marketing-agreement'],
				'partners-agreement': params['partners-agreement'],
			},
		});
	};

	async changePassword (params: IChangePasswordForm, uuid: string|null) {
		return this.put<IChangePasswordForm, boolean>({
			url: `/api/v1/users/profile/${uuid}/reset-password`,
			data: params,
			config: {
				suppressError: true,
			},
		});
	};
};
