<template>
	<div class="history-payment">
		<MProgressBar v-show="loading" indeterminate height="2" />
		<CartHeader
			v-if="null != user && user.agency_id == null && ADVERTISER_ID === user.active_role_id"
			:title="$t('order.deposit.title')"
			:btn-order-title="$t('order.deposit.addNow')"
			wallet-cart
			:disabled="amount <= 0 || isNaN(amount)"
			@addAmount="addAmount"
			:go-to-basket="goToBasket"
		/>
		<HeadingLabel
			class="history-payment__heading-label"
			icon="credit-card"
			width="32"
			height="29"
			:text="$t('order.deposit.paymentHistory')"
			switch-btn
			:first-switch-btn="null != user && (isPublisher(user) || checkIsUserJoinAffiliateProgram(user))"
			green400
			:btn-text="$t('reflinks.withdraw')"
			:is-button-click="goToWithdrawal"
			:second-switch-btn="null != user && checkIsUserJoinAffiliateProgram(user)"
			second-switch-btn-red400
			:is-second-button-click="goToTransfer"
			:second-switch-btn-label="$t('reflinks.transfer')"
		/>
		<HistoryPaymentTable />
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import router from '@/router';
import { useUserStore } from '@/stores/user';
import { ADVERTISER_ID, checkIsUserJoinAffiliateProgram, isPublisher } from '@/hooks/UserHooks';
import OrderApi from '@/api/v1/OrderApi';
import type { IDepositRequest } from '@/models/OrderModel';
import CartHeader from '@/components/molecules/CartHeader.vue';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import HistoryPaymentTable from '@/components/organisms/HistoryPayment/HistoryPaymentTable.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';

const orderApi = new OrderApi();

const { user } = storeToRefs(useUserStore());

const amount = ref<number>(0);
const loading = ref<boolean>(false);

const addAmount = (val:number) => {
	amount.value = val;
};

const goToBasket = async() => {
	loading.value = true;
	try {
		const request:IDepositRequest = {
			amount: amount.value * 100,
		};
		
		const result = await orderApi.sendDepositRequest(request);
		if (!result.isSuccess) {
			loading.value = false;
			return;
		}
		await router.push(`/basket/${result.payload.order}`);
	} catch (e) {
		loading.value = false;
		return;
	}
	loading.value = false;
};

const goToWithdrawal = () => {
	router.push('/withdrawal/create');
};
const goToTransfer = () => {
	router.push('/transfer');
};
</script>

<style scoped lang="scss">
.history-payment {
	&__heading-label {
		margin-top: 10px;
		margin-bottom: 2px;
	}
}
</style>
