<template>
	<div>
		<MLoading />
	</div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useGoogleStore } from '@/stores/google';
import router from '@/router';
import MLoading from '@/components/atoms/MLoading.vue';

const { setGoogleToken } = useGoogleStore();

setGoogleToken(router.currentRoute.value.fullPath.split('access_token=')[1].split('&')[0]);
onMounted(async() => {
	const { googleToken } = useGoogleStore();
	
	if (null != googleToken) {
		await localStorage.setItem('googleToken', googleToken);
		await router.push('/article/create/upload/google-drive');
	}
});
</script>

<style scoped lang="scss">

</style>
