import BaseApi from '@/api/BaseApi';
import type { GetCurrencyResponseInterface } from '@/models/CurrencyModel';

export default class CurrencyApi extends BaseApi {
    async getCurrencies () {
        return this.get<GetCurrencyResponseInterface>({
            url: '/api/v1/currencies',
        });
    };
};
