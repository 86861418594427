<template>
	<div class="offer-list-table">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:items-per-page="options.itemsPerPage"
			:page="options.page"
			:sort-by="options.sortBy"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
			v-model="selected"
			show-select
			return-object
		>
			<template #loading>
				<div class="offer-list-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #top>
				<div class="offer-list-table__top-wrapper">
					<div class="offer-list-table__search">
						<MTextField
							id="offers-search"
							v-model="search"
							name="search"
							variant="outlined"
							label=""
							:placeholder="$t('offers.search')"
							clearable
						/>
					</div>
					<div class="offer-list-table__status">
						<MAutocomplete
							id="offers-status"
							v-if="statusList"
							v-model="status"
							:get-items="statusList"
							clearable
							variant="outlined"
							item-title="name"
							item-value="slug"
							:placeholder="$t('offers.selectStatusPlaceholder')"
							label=""
						/>
					</div>
					<div v-if="null != user && user.is_agency" class="offer-list-table__account">
						<MAutocomplete
							id="offers-dependent-account"
							v-model="dependentAccountId"
							:get-items="dependentAccountList"
							clearable
							variant="outlined"
							item-title="email"
							item-value="id"
							:placeholder="$t('offers.selectAccountPlaceholder')"
							label=""
						/>
					</div>
					
					<div v-if="null != WEBSITE_OFFERS_LIST && !isModeratorDashboard" class="offer-list-table__table-settings-wrapper">
						<TableSettingsMenuWithModal :is-cleared-table-settings-action="isClearedTableSettingsAction" :table-name="WEBSITE_OFFERS_LIST" @headersMove="headersMoveAction" :on-clear-action="clearTableSettingsAction" />
					</div>
				</div>
			</template>
			
			<template #item.website_name="elem">
				<div v-if="null != elem.item.raw.website_name" class="m9-data-table-align-left">{{ elem.item.raw.website_name }}</div>
				<div v-else>-</div>
			</template>
			
			<template #item.websites.url="elem">
				<div v-if="null != elem.item.raw.url && '' !== elem.item.raw.url" class="m9-data-table-align-left">{{ elem.item.raw.url }}</div>
				<div v-else>-</div>
			</template>
			
			<template #item.duration_id="elem">
				<div v-if="null != elem.item.raw.duration_id">{{ $t(`dictionary.period.${elem.item.raw.duration_id}`) }}</div>
				<div v-else>-</div>
			</template>
			
			<template #item.name="elem">
				<div v-if="null != elem.item.raw.name" class="m9-data-table-align-left">{{ elem.item.raw.name }}</div>
				<div v-else>-</div>
			</template>
			
			<template #item.price="elem">
				<div class="m9-data-table-align-price">
					<div v-if="null != elem.item.raw.price">{{ (elem.item.raw.price).toFixed(2) }} {{ changeCurrencyIdToSymbol(elem.item.raw.currency_id) }}</div>
					<div v-else>0.00</div>
				</div>
			</template>
			
			<template #item.days_promotion_on_main_page="elem">
				<div v-if="null != elem.item.raw.days_promotion_on_main_page">{{ elem.item.raw.days_promotion_on_main_page }}</div>
				<div v-else>0</div>
			</template>
			
			<template #item.write_content="elem">
				<div v-if="1 === elem.item.raw.write_content">
					<MIcon icon="tick" :color="green400" />
				</div>
				<div v-if="0 === elem.item.raw.write_content">
					<MIcon icon="close" :color="secondary400"/>
				</div>
			</template>
			
			<template #item.do_follow="elem">
				<div v-if="1 === elem.item.raw.do_follow">
					<MIcon icon="tick" :color="green400" />
				</div>
				<div v-if="0 === elem.item.raw.do_follow">
					<MIcon icon="close" :color="secondary400"/>
				</div>
			</template>
			
			<template #item.publications_finished="elem">
				<div v-if="null != elem.item.raw.publications_finished || null != elem.item.raw.new_publications_finished">{{ elem.item.raw.publications_finished + elem.item.raw.new_publications_finished  }}</div>
				<div v-else>-</div>
			</template>
			
			<template #item.status="elem">
				<div v-if="elem.item.raw.status === APPROVED" class="offer-list-table__status-item offer-list-table__status-item--approved">{{ $t('offers.approved') }}</div>
				<div v-if="elem.item.raw.status === DISAPPROVED" class="offer-list-table__status-item offer-list-table__status-item--disapproved">{{ $t('offers.disapproved') }}</div>
				<div v-if="elem.item.raw.status === SUBMITTED_MODERATION" class="offer-list-table__status-item offer-list-table__status-item--submitted-moderation">{{ $t('offers.submittedModeration') }}</div>
				<div v-if="elem.item.raw.status === RESUBMITTED_MODERATION" class="offer-list-table__status-item offer-list-table__status-item--submitted-moderation">{{ $t('offers.resubmittedModeration') }}</div>
				<div v-if="elem.item.raw.status === SKETCH" class="offer-list-table__status-item offer-list-table__status-item--sketch">{{ $t('offers.sketch') }}</div>
			</template>
			
			<template #item.sum_price="elem">
				<div v-for="item in elem.item.raw.incomes" :key="item.id">
					<div class="m9-data-table-align-price">{{ (item.total_earn / 100).toFixed(2) }} {{ changeCurrencyIdToSymbol(item.base_currency_id) }}</div>
				</div>
				<div v-if="elem.item.raw.incomes.length === 0">-</div>
			</template>
			
			<template #item.created_at="elem">
				<div v-if="null != elem.item.raw.created_at">{{ dateFormat(elem.item.raw.created_at) }}</div>
			</template>
			
			<template #item.actions="elem">
				<div class="offer-list-table__action-margin">
					<MMenu location="left" is-side-menu>
						<template v-slot:menuActivator>
							<div class="offer-list-table__action-activator-wrapper">
								<MIcon icon="dots" class="offer-list-table__action-activator" />
								<div v-if="elem.item.raw.status === DISAPPROVED" class="offer-list-table__notification">1</div>
							</div>
						</template>
						<template v-slot:menuItems>
							<div class="offer-list-table__actions-wrapper">
								<div class="offer-list-table__action-activator-wrapper">
									<div @click="offerDetailsAction(elem.item.raw.uuid)" @auxclick="offerDetailsAction(elem.item.raw.uuid, true)" id="offer-details" class="offer-list-table__actions-item" :class="{'offer-list-table__actions-item--notification': elem.item.raw.status === DISAPPROVED}">{{ $t('offers.offerDetails') }}</div>
									<div v-if="elem.item.raw.status === DISAPPROVED" class="offer-list-table__notification offer-list-table__notification--menu">1</div>
								</div>
								<div @click="editOfferAction(elem.item.raw.uuid)" id="offer-edit" class="offer-list-table__actions-item">{{ $t('offers.editOffer') }}</div>
								<div @click="duplicateOfferAction(elem.item.raw.uuid, elem.item.raw.website_id)" id="offer-duplicate" class="offer-list-table__actions-item">{{ $t('offers.duplicateOffer') }}</div>
								<div @click="openDialog(elem.item.raw.uuid)" id="offer-duplicate-2" class="offer-list-table__actions-item">{{ $t('offers.duplicateOffer2') }}</div>
								<div @click="openDeleteDialog(elem.item.raw.uuid, elem.item.raw.name)" id="offer-delete" class="offer-list-table__actions-item">{{ $t('offers.deleteOffer') }}</div>
							</div>
						</template>
					</MMenu>
				</div>
			</template>
			
			<template #footer.prepend>
				<div v-if="items.length > 0" class="offer-list-table__prepent-wrapper">
					<div class="offer-list-table__mass-delete-wrapper" :class="{'offer-list-table__mass-delete-wrapper--hover': selected.length !== 0}">
						<MTooltip without-btn>
							<template v-slot:title>
								<MIcon id="offers-open-mass-delete-dialog" icon="trash" :color="selected.length === 0 ? grey400 : black" @click="openMassDeleteDialog" />
							</template>
							<template v-slot:content>
								<div>{{ $t('websites.massDelete') }}</div>
							</template>
						</MTooltip>
					</div>
					<div class="offer-list-table__csv-btn">
						<MTooltip without-btn>
							<template v-slot:title>
								<MIcon id="csv-btn" icon="csv-file" width="20" height="20" @click="downloadToCsv" />
							</template>
							<template v-slot:content>
								<div>{{ $t('components.downloadCsv') }}</div>
							</template>
						</MTooltip>
					</div>
				</div>
			</template>
		</v-data-table-server>
		
		<MDialog
			:is-close-dialog-action="isCloseDeleteDialog"
			:is-open-dialog-action="isOpenDeleteDialog"
			:content-title="$t('offers.deleteTitle')"
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<div class="offer-list-table__dialog-wrapper">
					<div class="offer-list-table__dialog-text">{{ $t('offers.deleteDescription') }} {{ offerName }}</div>
					<div class="offer-list-table__dialog-btn-wrapper">
						<MButton id="offer-table-close-modal-action" normal width100 blue400 :label="$t('components.close')" @click="closeDialogWhenNoTargetPressed" />
						<MButton id="offer-table-delete-action" normal width100 red400 :label="$t('components.delete')" @click="deleteAction" />
					</div>
				</div>
			</template>
		</MDialog>
		
		<MDialog
			:is-close-dialog-action="isCloseDialog"
			:is-open-dialog-action="isOpenDialog"
			:content-title="$t('offers.selectDomain')"
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<div class="offer-list-table__dialog-wrapper">
					<div class="offer-list-table__dialog-text">{{ $t('offers.selectDomainWhereYouWantDuplicateOffer') }}</div>
					<Form :validation-schema="duplicateOfferSchema" @submit="duplicateSimilarOfferAction">
						<div>
							<MAutocomplete
								id="offer-domain"
								v-model="domain"
								v-model:search="domainInputValue"
								name="offerDomain"
								:get-items="domainList"
								item-title="name"
								item-value="id"
								:label="$t('offers.domain')"
								label-required
								:placeholder="$t('offers.selectDomain')"
								variant="outlined"
								clearable
							/>
						</div>
						<div class="offer-list-table__dialog-btn-wrapper">
							<MButton id="create-edit-offer-close-modal-action" normal blue400 :label="$t('components.close')" @click="closeDialogWhenNoTargetPressed" />
							<MButton id="duplicate-similar-offer-action" type="submit" normal green400 :label="$t('offers.createSimilarOffer')" />
						</div>
					</Form>
				</div>
			</template>
		</MDialog>
		
		<MDialog
			:is-close-dialog-action="isCloseMassDialog"
			:is-open-dialog-action="isOpenMassDialog"
			:content-title="$t('websites.massDelete')"
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<div class="offer-list-table__dialog-wrapper">
					<div class="offer-list-table__dialog-text">{{ $t('offers.deleteDescriptionMass') }}</div>
					<div class="offer-list-table__domain-list">
						<div v-for="(offer, index) in selected" :key="index">
							<div>{{ offer.url }},</div>
						</div>
					</div>
					<div class="offer-list-table__dialog-btn-wrapper">
						<MButton id="offer-table-close-modal-action" normal blue400 width100 :label="$t('components.close')" @click="closeDialogWhenNoTargetPressed" />
						<MButton id="offer-table-delete-action" normal red400 width100 :label="$t('components.delete')" @click="deleteMassAction" />
					</div>
				</div>
			</template>
		</MDialog>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { defineEmits, ref, watch } from 'vue';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useTableSettingsStore } from '@/stores/tableSettings';
import { useUserStore } from '@/stores/user';
import { useDomainStore } from '@/stores/domain';
import { useDictionaryStore } from '@/stores/dictionary';
import { useSessionStore } from '@/stores/session';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import variables from '@/assets/scss/modules/variables.module.scss';
import { exportTableToCsv } from '@/hooks/ExportCsvHook';
import { clearTableSettings, editTableSettings, WEBSITE_OFFERS_LIST } from '@/hooks/TableSettingsHooks';
import { isAdmin, isModerator } from '@/hooks/UserHooks';
import { SUBMITTED_MODERATION, APPROVED, DISAPPROVED, RESUBMITTED_MODERATION, SKETCH } from '@/hooks/WebsitesHooks';
import { ROUTE_DASHBOARD } from '@/hooks/RoutingHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import { dateFormat } from '@/hooks/DataHooks';
import AgencyApi from '@/api/v1/AgencyApi';
import WebsiteApi from '@/api/v1/WebsiteApi';
import OffersApi from '@/api/v1/OffersApi';
import type { TableSettingsInterface, TableSettingsRequestInterface } from '@/models/TableSettingsModel';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { AgencyItemListInterface } from '@/models/AgencyModel';
import type { IDuplicateOfferForm, IOfferListItem, IMassDeleteOffersRequest } from '@/models/OfferModel';
import type { IWebsiteListItems } from '@/models/WebsiteModel';
import MTextField from '@/components/atoms/MTextField.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import TableSettingsMenuWithModal from '@/components/organisms/TableSettingsMenuWithModal.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MMenu from '@/components/atoms/MMenu.vue';
import MDialog from '@/components/atoms/MDialog.vue';
import MButton from '@/components/atoms/MButton.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';

const { t } = useI18n();
const emit = defineEmits(['itemsCounter']);
const { user } = storeToRefs(useUserStore());
const { setSort, setHeaders, setFilters, setPagination } = useTableSettingsStore();
const { domainName } = storeToRefs(useDomainStore());
const { white, green400, secondary400, grey400, black } = variables;
const { currencies } = useDictionaryStore();
const { locale } = storeToRefs(useSessionStore());

const agencyApi = new AgencyApi();
const offersApi = new OffersApi();
const websiteApi = new WebsiteApi();

const loading = ref<boolean>(false);
const items = ref<Array<IOfferListItem>|any>([]);
const total = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const search = ref<string|null>(null);
const searchTimeout = ref<any>(undefined);
const status = ref<string|null>(null);
const selected = ref<Array<number>|any>([]);
const isCloseMassDialog = ref<boolean>(true);
const isOpenMassDialog = ref<boolean>(false);
const statusList = ref<Array<any>>([
	{
		id: 0,
		name: `${t('offers.sketch')}`,
		slug: SKETCH
	},
	{
		id: 1,
		name: `${t('offers.submittedModeration')}`,
		slug: SUBMITTED_MODERATION
	},
	{
		id: 2,
		name: `${t('offers.resubmittedModeration')}`,
		slug: RESUBMITTED_MODERATION
	},
	{
		id: 3,
		name: `${t('offers.approved')}`,
		slug: APPROVED
	},
	{
		id: 4,
		name: `${t('offers.disapproved')}`,
		slug: DISAPPROVED
	},
]);
let headers = ref<Array<TableHeaderInterface>|any>([]);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});

const isClearedTableSettingsAction = ref<boolean>(false);
let firstLoadPage:boolean = true;
let isClearAction:boolean = false;
let isFilterAction:boolean = false;
let isCloseDialog = ref<boolean>(true);
let isCloseDeleteDialog = ref<boolean>(true);
let isOpenDialog = ref<boolean>(false);
let isOpenDeleteDialog = ref<boolean>(false);
const offerUuid = ref<string|null>(null);
const offerName = ref<string|null>(null);
const domainInputValue = ref<string>('');
const domainInputValueInterval = ref<any>(undefined);
const domain = ref<number|null>(null);
const domainList = ref<Array<IWebsiteListItems>>([]);

const localeLanguage = ref('');
if ('us' === locale.value) {
	localeLanguage.value = 'en';
} else if (locale.value != null) {
	localeLanguage.value = locale.value;
} else {
	localeLanguage.value = 'pl';
}

const duplicateOfferSchema:any = yup.object({
	offerDomain: yup.string().required(`${t('offers.validation.offerDomain')}`).label('offerDomain'),
});

const isModeratorDashboard = ref<boolean>(false);
if (null != user.value && isModerator(user.value) && router.currentRoute.value.name === ROUTE_DASHBOARD) {
	isModeratorDashboard.value = true;
}
const openDeleteDialog = (uuid:string, name:string) => {
	offerUuid.value = uuid;
	offerName.value = name;
	isCloseDeleteDialog.value = false;
	isOpenDeleteDialog.value = true;
};
const openDialog = (itemOfferUuid:string) => {
	offerUuid.value = itemOfferUuid;
	isCloseDialog.value = false;
	isOpenDialog.value = true;
};

const openMassDeleteDialog = () => {
	if (selected.value.length === 0) {
		return;
	}
	isCloseMassDialog.value = false;
	isOpenMassDialog.value = true;
};

const closeDialogWhenNoTargetPressed = () => {
	isCloseDialog.value = true;
	isCloseDeleteDialog.value = true;
	isCloseMassDialog.value = true;
	isOpenDialog.value = false;
	isOpenDeleteDialog.value = false;
	isOpenMassDialog.value = false;
};

const defaultHeaders = () => {
	const result:Array<TableHeaderInterface> = [
		{
			title: `${t('offers.domainName')}`,
			align: 'center',
			sortable: true,
			key: 'website_name',
			width: '270px'
		},
		{
			title: `${t('offers.url')}`,
			align: 'center',
			sortable: true,
			key: 'websites.url',
			width: '300px'
		}
	]
	
	if (null != user.value && (isModerator(user.value) || isAdmin(user.value) || user.value?.is_agency)) {
		result.push(
			{
				title: `${t('offers.account')}`,
				align: 'center',
				sortable: true,
				key: 'email',
				width: '120px'
			},
		)
	}
	
	result.push(...[
		{
			title: `${t('offers.offerName')}`,
			align: 'center',
			sortable: true,
			key: 'name',
			width: '250px'
		},
		{
			title: `${t('offers.price')}`,
			align: 'center',
			sortable: true,
			key: 'price',
			width: '120px'
		},
	]);
	
	if (null != user.value && (isModerator(user.value) || isAdmin(user.value) || user.value?.is_agency)) {
		result.push(
			{
				title: `${t('offers.period')}`,
				align: 'center',
				sortable: false,
				key: 'duration_id',
				width: '150px'
			},
		)
	}
	
	result.push(...[
		{
			title: `${t('offers.promotionOnMainPageHeaders')}`,
			align: 'center',
			sortable: true,
			key: 'days_promotion_on_main_page',
			width: '200px'
		},
		{
			title: `${t('offers.isArticleToWrite')}`,
			align: 'center',
			sortable: true,
			key: 'write_content',
			width: '130px'
		},
		{
			title: `${t('offers.links')}`,
			align: 'center',
			sortable: true,
			key: 'do_follow',
			width: '80px'
		},
		{
			title: `${t('offers.publicationSold')}`,
			align: 'center',
			sortable: true,
			key: 'publications_finished',
			width: '80px'
		},
		{
			title: `${t('offers.status')}`,
			align: 'center',
			sortable: true,
			key: 'status',
			width: '200px'
		},
		{
			title: `${t('offers.earnings')}`,
			align: 'center',
			sortable: true,
			key: 'sum_price',
			width: '120px'
		},
		{
			title: `${t('offers.actions')}`,
			sortable: false,
			align: 'center',
			key: 'actions',
			width: '70px'
		},
	]);
	
	return result
};
headers.value = defaultHeaders();
const defaultInactiveHeaders = () => {
	const result:Array<TableHeaderInterface> = [
		{
			title: `${t('offers.createdAt')}`,
			sortable: false,
			align: 'center',
			key: 'created_at',
			width: '70px'
		},
	];
	
	return result;
};

const websiteList = async (search?:string) => {
	loading.value = true;
	
	try {
		const result = await websiteApi.websiteList(1, 50, null, null, '' !== search && null != search ? search : null);
		
		if (!result.isSuccess) {
			toast.error(`${t('websites.toast.errorGetWebsiteList')}`);
			loading.value = false;
			return;
		}
		
		domainList.value = result.payload.data;
	} catch (e) {
		toast.error(`${t('websites.toast.errorGetWebsiteList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
websiteList();

watch((domainInputValue), (newVal, oldVal) => {
	if (newVal !== oldVal) {
		clearTimeout(domainInputValueInterval.value);
	}
	domainInputValueInterval.value = setTimeout(() => {
		websiteList(newVal);
	}, 700);
});

const dependentAccountId = ref<number|null>(null);
const dependentAccountList = ref<Array<AgencyItemListInterface>|any>([]);
const agencyDependentAccountList = async() => {
	if (null == user.value) {
		return;
	}
	try {
		const result = await agencyApi.agencyUserList(user.value.uuid);
		if (!result.isSuccess) {
			return;
		}
		dependentAccountList.value = result.payload.data;
		dependentAccountList.value.push(user.value);
	} catch (e) {
		return;
	}
};
if (user.value?.is_agency) {
	agencyDependentAccountList();
}

const offerList = async () => {
	loading.value = true;
	
	try {
		const result = await offersApi.offersList(
			options.value.page,
			options.value.itemsPerPage,
			options.value.sortBy.length > 0 ? options.value.sortBy[0].key : null,
			options.value.sortBy.length > 0 ? options.value.sortBy[0].order : null,
			search.value,
			status.value,
			dependentAccountId.value
		);
		
		if (!result.isSuccess) {
			toast.error(`${t('offers.toast.errorGetOfferList')}`);
			loading.value = false;
			return;
		}
		
		total.value = result.payload.total;
		currentPage.value = result.payload.current_page;
		lastPage.value = result.payload.last_page;
		items.value = result.payload.data;
		if (isModeratorDashboard.value) {
			emit('itemsCounter', result.payload.total);
		}
	} catch (e) {
		toast.error(`${t('offers.toast.errorGetOfferList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const websitesTableSettings = ref<TableSettingsInterface|null>(null);
const initTableOptions = async() => {
	let isShouldBeSaved = false;
	websitesTableSettings.value = storeToRefs(useTableSettingsStore())[WEBSITE_OFFERS_LIST].value;
	
	// Columns options
	if (null == websitesTableSettings.value) {
		await setSort({
			name: WEBSITE_OFFERS_LIST,
			sortBy: 'website_offers.updated_at',
			sortDesc: 'desc',
		});
		isShouldBeSaved = true;
	}
	
	// Headers options
	if (null == websitesTableSettings.value || 0 === websitesTableSettings.value.columns.active.length || websitesTableSettings.value.filters.locale !== localeLanguage.value) {
		isClearedTableSettingsAction.value = true;
		await setHeaders({
			name: WEBSITE_OFFERS_LIST,
			headers: {
				active: defaultHeaders(),
				inactive: defaultInactiveHeaders(),
			}
		});
		isShouldBeSaved = true;
	}
	
	// Pagination options
	if (null == websitesTableSettings.value || null == websitesTableSettings.value.page || null == websitesTableSettings.value.per_page) {
		await setPagination({
			name: WEBSITE_OFFERS_LIST,
			page: 1,
			perPage: 10
		});
		isShouldBeSaved = true;
	}
	
	// Filters options
	// if (null != websitesTableSettings.value.filters.status) {
	// 	status.value = websitesTableSettings.value.filters.status;
	// }
	if (null != domainName.value && null != websitesTableSettings.value) {
		search.value = domainName.value;
		// Ponizsy kod do konca ifa zapisuje nazwe domeny zeby odfiltrować tylko oferty które należa do danej domeny
		// poprzez wczesniejsze klikniecie guzika edytuj oferty domeny na liscie domen, jezeli skasuje ten kod to
		// wartosc bedzie poprawnie wskakiwac do searcha ale nie zapisywać sie do ustawień tabeli czyli po refreshu search bedzie pusty
		const filters = {
			search: search.value,
			agencyAccount: websitesTableSettings.value.filters.agencyAccount,
			status: websitesTableSettings.value.filters.status,
		};
		await setFilters({
			name: WEBSITE_OFFERS_LIST,
			filters: filters
		});
		isShouldBeSaved = true;
	}
	if (null == domainName.value && null != websitesTableSettings.value && null != websitesTableSettings.value.filters.search) {
		search.value = websitesTableSettings.value.filters.search;
	}
	if (null != websitesTableSettings.value && null != websitesTableSettings.value.filters.agencyAccount) {
		dependentAccountId.value = websitesTableSettings.value.filters.agencyAccount;
	}
	if (null != websitesTableSettings.value && websitesTableSettings.value.filters.locale !== localeLanguage.value) {
		const filters = {
			search: search.value,
			agencyAccount: dependentAccountId.value,
			status: status.value,
			locale: localeLanguage.value,
		};
		await setFilters({
			name: WEBSITE_OFFERS_LIST,
			filters: filters
		});
	}
	
	if (null != websitesTableSettings.value) {
		options.value.page = websitesTableSettings.value.page;
		options.value.itemsPerPage = websitesTableSettings.value.per_page;
		options.value.sortBy.push({
			key: websitesTableSettings.value.sort_column,
			order: websitesTableSettings.value.sort_order,
		});
		
		headers.value = websitesTableSettings.value.columns.active;
	}
	
	if (isShouldBeSaved) {
		await editTableSettingsAction();
	}
	await offerList();
	isClearedTableSettingsAction.value = false;
};
initTableOptions();

const editTableSettingsAction = () => {
	if (null != websitesTableSettings.value && null != user.value) {
		const tableSettings: TableSettingsRequestInterface = {
			sort_column: null != websitesTableSettings.value.sort_column && 0 < websitesTableSettings.value.sort_column.length ? websitesTableSettings.value.sort_column : null,
			sort_order: null != websitesTableSettings.value.sort_order && 0 < websitesTableSettings.value.sort_order.length ? websitesTableSettings.value.sort_order : null,
			page: websitesTableSettings.value.page,
			per_page: websitesTableSettings.value.per_page,
			filters: JSON.stringify(websitesTableSettings.value.filters),
			columns: JSON.stringify(websitesTableSettings.value.columns),
		}
		if (!isModeratorDashboard.value) {
			editTableSettings(WEBSITE_OFFERS_LIST, tableSettings, user.value?.active_role_id);
		}
	}
};

const clearTableSettingsAction = async() => {
	isClearAction = true;
	
	search.value = null;
	dependentAccountId.value = null;
	status.value = null;
	
	options.value.page = 1;
	options.value.itemsPerPage = 10;
	options.value.sortBy = [];
	
	headers.value = defaultHeaders();
	
	const filters = {
		search: search.value,
		agencyAccount: dependentAccountId.value,
		status: status.value,
		locale: localeLanguage.value,
	};
	
	const columns = {
		active: defaultHeaders(),
		inactive: defaultInactiveHeaders(),
	};
	
	const tableSettings:TableSettingsRequestInterface = {
		sort_column: 'website_offers.updated_at',
		sort_order: 'desc',
		page: 1,
		per_page: 10,
		filters: JSON.stringify(filters),
		columns: JSON.stringify(columns),
	}
	
	if (null != user.value) {
		await clearTableSettings(WEBSITE_OFFERS_LIST, tableSettings, user.value?.active_role_id, `${t('components.toast.errorClearTableSettings')}`, `${t('components.toast.successClearTableSettings')}`);
	}
	isClearedTableSettingsAction.value = true;
	
	setTimeout(() => {
		isClearAction = false;
		isClearedTableSettingsAction.value = false;
		offerList();
	},1);
};
const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	if (!firstLoadPage && !isClearAction) {
		await setPagination({
			name: WEBSITE_OFFERS_LIST,
			page: val.page,
			perPage: val.itemsPerPage,
		});
		await setSort({
			name: WEBSITE_OFFERS_LIST,
			sortBy: 0 < val.sortBy.length ? val.sortBy[0].key : [],
			sortDesc: 0 < val.sortBy.length ? val.sortBy[0].order : [],
		});
		if (!isFilterAction) {
			await editTableSettingsAction();
			await offerList();
		}
	}
	isFilterAction = false;
	firstLoadPage = false;
};

watch(([dependentAccountId, status, search]), async (newVal, oldVal) => {
	if (!isClearAction) {
		if (options.value.page > 1) {
			options.value.page = 1;
			await setPagination({
				name: WEBSITE_OFFERS_LIST,
				page: 1,
				perPage: options.value.itemsPerPage,
			});
			isFilterAction = true;
		}
		if (newVal[0] !== oldVal[0] || newVal[1] !== oldVal[1] || newVal[2] !== oldVal[2]) {
			clearTimeout(searchTimeout.value);
			searchTimeout.value = setTimeout(async () => {
				const filters = {
					search: newVal[2],
					agencyAccount: newVal[0],
					status: newVal[1],
					locale: localeLanguage.value,
				};
				await setFilters({
					name: WEBSITE_OFFERS_LIST,
					filters: filters
				});
				await editTableSettingsAction();
				await offerList();
			}, 700);
		}
	}
},
{
	deep: true,
});

const headersMoveAction = () => {
	if (null != websitesTableSettings.value) {
		headers.value = websitesTableSettings.value.columns.active;
	}
};

const duplicateOfferAction = async(offerUuid:string, websiteUuid:number) => {
	loading.value = true;
	const offerForm:IDuplicateOfferForm = {
		website_id: websiteUuid,
	}
	try {
		const result = await offersApi.duplicateOffer(offerForm, offerUuid);
		if (!result.isSuccess) {
			toast.error(`${t('offers.toast.errorDuplicateOffer')}`);
			loading.value = false;
			return;
		}
		await router.push(`/websites/offer/${result.payload.uuid}/duplicate`);
	} catch (e) {
		toast.error(`${t('offers.toast.errorDuplicateOffer')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
const editOfferAction = (offerUuid:string) => {
	router.push(`/websites/offer/${offerUuid}`);
};
const duplicateSimilarOfferAction = async() => {
	isCloseDialog.value = true;
	isOpenDialog.value = false;
	const offerForm = {
		website_id: domain.value,
	}
	try {
		const result = await offersApi.duplicateOffer(offerForm, offerUuid.value);
		if (!result.isSuccess) {
			toast.error(`${t('offers.toast.errorDuplicateOffer')}`);
			return;
		}
		await router.push(`/websites/offer/${result.payload.uuid}/duplicate`);
	} catch (e) {
		toast.error(`${t('offers.toast.errorDuplicateOffer')}`);
		return;
	}
};
const offerDetailsAction = (offerUuid:string, isAuxClick?:boolean) => {
	if (isAuxClick) {
		window.open(`/websites/offer/details/${offerUuid}`, '_blank');
	} else {
		router.push(`/websites/offer/details/${offerUuid}`);
	}
};
const deleteAction = async () => {
	loading.value = true;
	isCloseDeleteDialog.value = true;
	isOpenDeleteDialog.value = false;
	try {
		const result = await offersApi.deleteOffer(offerUuid.value);
		if (!result.isSuccess) {
			toast.error(`${t('offers.toast.errorDeleteOffer')}`);
			loading.value = false;
			return;
		}
	} catch (e) {
		toast.error(`${t('offers.toast.errorDeleteOffer')}`);
		loading.value = false;
		return;
	}
	toast.success(`${t('offers.toast.successDeleteOffer')}`);
	await offerList();
	loading.value = false;
};

const changeCurrencyIdToSymbol = (currencyId:number|null) => {
	const currenciesList = currencies.find(elem => elem.id === currencyId);
	if (currenciesList != null) {
		return currenciesList.currency_symbol || '-';
	}
	return '-';
};

const deleteMassAction = async() => {
	loading.value = true;
	
	const deleteForm:IMassDeleteOffersRequest = {
		offers: [],
	};
	selected.value.forEach((elem:any) => {
		deleteForm.offers.push(elem.uuid);
	});
	try {
		const result = await offersApi.massDeleteOffers(deleteForm);
		if (!result.isSuccess) {
			toast.error(`${t('offers.toast.errorDeleteDomains')}`);
			loading.value = false;
			return;
		}
		toast.success(`${t('offers.toast.successDeleteDomains')}`);
		closeDialogWhenNoTargetPressed()
		selected.value = [];
		await offerList();
	} catch (e) {
		toast.error(`${t('offers.toast.errorDeleteDomains')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const downloadToCsv = () => {
	let rowKeys: string[] = ['website_name', 'url', 'name', 'price', 'days_promotion_on_main_page', 'write_content', 'do_follow', 'new_publications_finished', 'status', 'incomes'];
	if (null != user.value && 1 === user.value.is_agency) {
		rowKeys = ['website_name', 'url', 'email', 'name', 'price', 'duration_id', 'days_promotion_on_main_page', 'write_content', 'do_follow', 'new_publications_finished', 'status', 'incomes'];
	}
	exportTableToCsv(headers.value, items.value, rowKeys, `${t('components.toast.errorDownloadCsv')}`, `${t('components.toast.successDownloadCsv')}`, t, 'offers');
};
</script>

<style scoped lang="scss">
.offer-list-table {
	@import '@/assets/scss/vuetifyDataTable';
	@import '@/assets/scss/vuetifyDataTableBottomRoundRadius';
	&__loading {
		opacity: 0.5;
	}
	
	&__top-wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 10px;
		padding: 20px;
		
		@include media-breakpoint-up(xxl) {
			gap: 15px;
		}
		
		&:deep(.v-field__input) {
			padding: 6px 5px 0;
			min-height: 40px;
			width: 100%;
		}
		
		&:deep(.v-input__details) {
			display: none;
		}
	}
	
	&__table-settings-wrapper {
		margin-left: auto;
	}
	
	&__search {
		width: 240px;
		
		&:deep(.v-field__input) {
			height: 40px;
		}
	}
	
	&__status,
	&__account {
		display: flex;
		align-items: center;
		gap: 5px;
		font-size: 14px;
		font-weight: 400;
		
		&-title {
			color: $grey-400;
		}
	}
	
	&__status {
		&:deep(.m-autocomplete) {
			min-width: 270px;
		}
	}
	
	&__account {
		&:deep(.m-autocomplete) {
			min-width: 200px;
		}
	}
	
	&__action-activator {
		cursor: pointer;
		transition: opacity 0.5s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__actions-wrapper {
		min-width: 120px;
		display: flex;
		flex-direction: column;
	}
	
	&__actions-item {
		padding: 12px 15px;
		border-bottom: 1px solid $grey-300;
		cursor: pointer;
		transition: opacity 0.5s;
		
		&:hover {
			opacity: 0.5;
		}
		
		&--notification {
			color: $secondary-400;
		}
	}
	
	&__status-item {
		font-weight: 700;
		
		&--approved {
			color: $green-400;
		}
		
		&--disapproved {
			color: $secondary-400;
		}
		
		&--submitted-moderation {
			color: $blue-400;
		}
		
		&--sketch {
			color: $yellow-500;
		}
	}
	
	&__action-activator-wrapper {
		position: relative;
	}
	
	&__notification {
		background-color: $secondary-400;
		padding: 2px 6px;
		font-size: 10px;
		font-weight: 700;
		color: $white-100;
		border-radius: 50%;
		position: absolute;
		top: -13px;
		right: -11px;
		cursor: pointer;
		
		&--menu {
			right: 0;
			top: 0;
		}
	}
	
	&__dialog-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}
	
	&__dialog-text {
		font-size: 14px;
		font-weight: 700;
		color: $grey-300;
	}
	
	&__dialog-btn-wrapper {
		display: flex;
		flex-direction: row-reverse;
		gap: 10px;
	}
	
	&__action-margin {
		width: max-content;
		margin: 0 auto;
	}
	
	&__prepent-wrapper {
		margin: 8px auto 8px 10px;
		display: flex;
		gap: 5px;
		align-items: center;
	}
	
	&__mass-delete-wrapper {
		&--hover {
			cursor: pointer;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	&__csv-btn {
		margin-left: 10px;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__dialog-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}
	
	&__dialog-text {
		font-size: 14px;
		font-weight: 700;
		color: $grey-300;
	}
	
	&__dialog-btn-wrapper {
		display: flex;
		gap: 10px;
	}
	
	&:deep(.v-data-table th) {
		background-color: $grey-100;
		color: $primary-400;
		font-weight: 700;
		height: 82px;
	}
	
	&:deep(.v-data-table-footer .v-field__field) {
		height: 40px;
	}
	
	&:deep(.v-data-table-footer .v-field__input) {
		font-size: 12px;
		padding: 11px 0 0 10px;
	}
	
	&:deep(.v-data-table-footer .v-input) {
		height: 40px;
	}
	
	&:deep(.v-selection-control__input > .v-icon) {
		color: $secondary-400;
	}
}
</style>
