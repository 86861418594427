import type { UserInterface } from '@/models/AuthModel';
import { ADMIN_ID } from '@/hooks/UserHooks';

export const ENGLISH_LANGUAGE_ID = 1;
export const POLISH_LANGUAGE_ID = 4;

export const LOGGED_USER = 'user';
export const LOGGED_MEMBER = 'member';
export const LOGGED_ADMIN = 'admin';
export const LOGGED_AGENCY = 'agency';

export const NOTIFICATION_FINANCES = 'finances';
export const NOTIFICATION_DOMAINS = 'domains';
export const NOTIFICATION_PUBLICATIONS = 'publications';
export const CONSENTS_TERMS = 'terms-and-condition';
export const CONSENTS_MARKETING = 'marketing-agreement';
export const CONSENTS_PARTNERS = 'partners-agreement';
export const currentLoggedUser = (user:UserInterface, isEditProfile:boolean) => {
	if (null != user.agency_id) {
		return LOGGED_MEMBER;
	}
	if (isEditProfile && 1 === user.is_agency) {
		return LOGGED_AGENCY;
	}
	if (isEditProfile && user.active_role_id === ADMIN_ID) {
		return LOGGED_ADMIN;
	}
	return LOGGED_USER;
};
export const checkAddressProfile = (user:UserInterface|any) => {
	return (
		null == user.addresses[0].country_id &&
		('' === user.addresses[0].line1 || null == user.addresses[0].line1) &&
		null == user.addresses[0].zip &&
		null == user.addresses[0].state &&
		('' === user.addresses[0].city || null == user.addresses[0].city)
	);
};
export const isProfileLocked = (user:UserInterface) => {
	return user.country_id != null && user.currency_id != null;
};
