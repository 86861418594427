<template>
    <div class="m-vue-editor" :class="{'m-vue-editor__error': null != errors[0]}">
	    <div v-if="separateLabel" class="m-vue-editor__label">
		    {{ separateLabel }}
		    <span class="m-vue-editor__required-star">{{ labelRequired ? '*' : labelTextRequired ? ` ${$t('components.fieldRequired')}` : '' }}</span>
		    <slot name="separateLabel"></slot>
	    </div>
	    <slot name="labelInfo"></slot>
        <QuillEditor v-model:content="value" content-type="html" :id="id" :toolbar="customToolbar" theme="snow" :placeholder="placeholder" />
        <div class="m-vue-editor__errors">{{ errors[0] }}</div>
    </div>
</template>

<script setup lang="ts">
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { computed, defineEmits, defineProps, toRef, ref } from 'vue';
import { useField } from 'vee-validate';

interface IOwnProps {
    id: string
    modelValue?: string|number|null|any
    name?: string
	placeholder?: string
	separateLabel?: string
	labelRequired?: boolean
	labelTextRequired?: boolean
}
interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();
const emit = defineEmits(['update:modelValue']);

const { errors } = useField(toRef<IOwnProps, any>(props, 'name'), undefined);

const customToolbar = ref([
	[{ font: [] }],
	[{ header: [false, 1, 2, 3, 4, 5, 6] }],
	[{ size: ['small', false, 'large', 'huge'] }],
	['bold', 'italic', 'underline', 'strike'],
	[{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
	[{ header: 1 }, { header: 2 }],
	['blockquote', 'code-block'],
	[{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
	[{ script: 'sub' }, { script: 'super' }],
	[{ indent: '-1' }, { indent: '+1' }],
	[{ color: [] }, { background: [] }],
	['link', 'image', 'video', 'formula'],
	[{ direction: 'rtl' }],
	['clean']
]);

const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});
</script>

<style scoped lang="scss">
.m-vue-editor {
    &__errors {
        font-size: $size-12;
        color: $secondary-550;
        text-transform: lowercase;
	    margin-top: 10px;
        
        &::first-letter {
            text-transform: uppercase;
        }
    }
	
	&__error {
		&:deep(.ql-toolbar) {
			border-color: $secondary-400;
		}
		
		&:deep(.ql-container) {
			border-color: $secondary-400;
		}
	}
	
	&__required-star {
		color: $secondary-400;
	}
	
	&__label {
		color: $grey-400;
		font-weight: 700;
		font-size: 14px;
	}
	
	&:deep(.ql-editor) {
		border-radius: 8px;
		min-height: 300px;
	}
	
	&:deep(.ql-toolbar) {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}
	
	&:deep(.ql-container) {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
}
</style>
