<template>
	<div class="contadu-optimalization">
		<div class="contadu-optimalization__left-wrapper">
			<MVueEditor id="contadu" v-model="articleContent" name="articleContent" />
		</div>
		<div class="contadu-optimalization__right-wrapper">
			<v-tabs fixed-tabs background-color="#272346" dark v-model="tab">
				<v-tab key="content-terms">
					<div class="contadu-optimalization__tabs-wrapper">
						<MIcon icon="paragraph"/>
						<div>{{ $t('contadu.contentTerms') }}</div>
					</div>
				</v-tab>
				<v-tab key="ideas">
					<div class="contadu-optimalization__tabs-wrapper">
						<MIcon icon="bulb"/>
						<div>{{ $t('contadu.ideas') }}</div>
					</div>
				</v-tab>
			</v-tabs>
			<v-window v-model="tab">
				<v-window-item key="content-terms">
					<div class="contadu-optimalization__content-terms-wrapper">
						<div class="contadu-optimalization__second-wrapper">
							<div @click="termsInHeadersAction" class="contadu-optimalization__content-terms-tab-wrapper" :class="{'contadu-optimalization__content-terms-tab-wrapper--active': isTermsInHeaders}">{{ $t('contadu.termsInHeaders') }}</div>
							<div @click="termsInArticleAction" class="contadu-optimalization__content-terms-tab-wrapper" :class="{'contadu-optimalization__content-terms-tab-wrapper--active': isTermsInArticle}">{{ $t('contadu.termsInArticle') }}</div>
						</div>
						<div v-show="isTermsInHeaders" class="contadu-optimalization__h-terms-wrapper" :class="{'contadu-optimalization__active-terms': isTermsInHeaders}">
							<div>
								<div class="contadu-optimalization__subtitle">{{ $t('contadu.h1Terms') }}</div>
								<div class="contadu-optimalization__label-main-wrapper">
									<div v-for="term in items.terms.h1" :key="term.t" class="contadu-optimalization__label-wrapper" :class="{'contadu-optimalization__label-wrapper--active': H1TagsCounter[term.t] > 0}">
										<div>{{ term.t }}</div>
										<div class="contadu-optimalization__label-item-wrapper">
											<div>{{ term.usage_pc }}</div>
											<div>%</div>
										</div>
									</div>
								</div>
							</div>
							<div>
								<div class="contadu-optimalization__subtitle">{{ $t('contadu.h2Terms') }}</div>
								<div class="contadu-optimalization__label-main-wrapper">
									<div v-for="term in items.terms.h2" :key="term.t" class="contadu-optimalization__label-wrapper" :class="{'contadu-optimalization__label-wrapper--active': H2TagsCounter[term.t] > 0}">
										<div>{{ term.t }}</div>
										<div class="contadu-optimalization__label-item-wrapper">
											<div>{{ term.usage_pc }}</div>
											<div>%</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-show="isTermsInArticle" :class="{'contadu-optimalization__active-terms': isTermsInArticle}">
							<div>
								<div class="contadu-optimalization__subtitle">{{ $t('contadu.basic') }}</div>
								<div class="contadu-optimalization__label-main-wrapper">
									<div
										v-for="term in items.terms.content_basic"
										:key="term.t" class="contadu-optimalization__label-wrapper"
										:class="{
                                            'contadu-optimalization__label-wrapper--active': wordCounter[term.t] >= term.sugg_usage[0],
                                            'contadu-optimalization__label-wrapper--yellow': wordCounter[term.t] >= term.sugg_usage[1] + 2,
                                            'contadu-optimalization__label-wrapper--red': wordCounter[term.t] >= term.sugg_usage[1] * 2 + 3,
                                        }"
									>
										<div>{{ term.t }}</div>
										<div class="contadu-optimalization__label-item-wrapper">
											<div>{{ wordCounter[term.t] || 0 }}</div>
											<div v-if="term.sugg_usage[0] !== term.sugg_usage[1]">/ {{ term.sugg_usage[0] }}-{{ term.sugg_usage[1] }}</div>
											<div v-if="term.sugg_usage[0] === term.sugg_usage[1]">/ {{ term.sugg_usage[0] }}</div>
										</div>
									</div>
								</div>
							</div>
							<div>
								<div class="contadu-optimalization__subtitle">{{ $t('contadu.extended') }}</div>
								<div class="contadu-optimalization__label-main-wrapper">
									<div
										v-for="term in items.terms.content_extended"
										:key="term.t"
										class="contadu-optimalization__label-wrapper"
										:class="{
                                            'contadu-optimalization__label-wrapper--active': wordCounter[term.t] >= term.sugg_usage[0],
                                            'contadu-optimalization__label-wrapper--yellow': wordCounter[term.t] >= term.sugg_usage[1] + 2,
                                            'contadu-optimalization__label-wrapper--red': wordCounter[term.t] >= term.sugg_usage[1] * 2 + 3,
                                        }"
									>
										<div>{{ term.t }}</div>
										<div class="contadu-optimalization__label-item-wrapper">
											<div>{{ wordCounter[term.t] || 0 }}</div>
											<div v-if="term.sugg_usage[0] !== term.sugg_usage[1]">/ {{ term.sugg_usage[0] }}-{{ term.sugg_usage[1] }}</div>
											<div v-if="term.sugg_usage[0] === term.sugg_usage[1]">/ {{ term.sugg_usage[0] }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</v-window-item>
				<v-window-item key="ideas">
					<ContaduExpandTransition :text="$t('contadu.questionsToAnswer')" :number="items.ideas.content_questions.length.toString()">
						<template v-slot:default>
							<div v-for="ideas in items.ideas.content_questions" :key="items.ideas.content_questions.indexOf(ideas)" class="contadu-optimalization__expand-item">
								<div>{{ ideas.q }}</div>
								<MTooltip without-btn>
									<template v-slot:title>
										<div @click="copyTextToClipboard(ideas.q)" class="contadu-optimalization__expand-icon-wrapper">
											<MIcon icon="articles" />
										</div>
									</template>
									<template v-slot:content>
										<div>{{ $t('contadu.copyToClipboard') }}</div>
									</template>
								</MTooltip>
							</div>
						</template>
					</ContaduExpandTransition>
					<ContaduExpandTransition :text="$t('contadu.competitorsH1')" :number="items.ideas.h1.length.toString()">
						<template v-slot:default>
							<div v-for="ideas in items.ideas.h1" :key="items.ideas.h1.indexOf(ideas)" class="contadu-optimalization__expand-item">
								<div>{{ ideas.h }}</div>
								<div class="contadu-optimalization__idea-btn-wrapper">
									<MTooltip without-btn>
										<template v-slot:title>
											<div @click="copyTextToClipboard(ideas.h)" class="contadu-optimalization__expand-icon-wrapper">
												<MIcon icon="articles" />
											</div>
										</template>
										<template v-slot:content>
											<div>{{ $t('contadu.copyToClipboard') }}</div>
										</template>
									</MTooltip>
									<MTooltip without-btn>
										<template v-slot:title>
											<a :href="ideas.source" target="_blank" class="contadu-optimalization__expand-icon-wrapper contadu-optimalization__expand-icon-wrapper--link">
												<MIcon icon="arrow-out" />
											</a>
										</template>
										<template v-slot:content>
											<div>{{ $t('contadu.openSourceUrlInNewTab') }}</div>
										</template>
									</MTooltip>
								</div>
							</div>
						</template>
					</ContaduExpandTransition>
					<ContaduExpandTransition :text="$t('contadu.competitorsH2')" :number="items.ideas.h2.length.toString()">
						<template v-slot:default>
							<div v-for="ideas in items.ideas.h2" :key="items.ideas.h2.indexOf(ideas)" class="contadu-optimalization__expand-item">
								<div>{{ ideas.h }}</div>
								<div class="contadu-optimalization__idea-btn-wrapper">
									<MTooltip without-btn>
										<template v-slot:title>
											<div @click="copyTextToClipboard(ideas.h)" class="contadu-optimalization__expand-icon-wrapper">
												<MIcon icon="articles" />
											</div>
										</template>
										<template v-slot:content>
											<div>{{ $t('contadu.copyToClipboard') }}</div>
										</template>
									</MTooltip>
									<MTooltip without-btn>
										<template v-slot:title>
											<a :href="ideas.source[0]" target="_blank" class="contadu-optimalization__expand-icon-wrapper contadu-optimalization__expand-icon-wrapper--link">
												<MIcon icon="arrow-out" />
											</a>
										</template>
										<template v-slot:content>
											<div>{{ $t('contadu.openSourceUrlInNewTab') }}</div>
										</template>
									</MTooltip>
								</div>
							</div>
						</template>
					</ContaduExpandTransition>
					<ContaduExpandTransition :text="$t('contadu.competitorsH3')" :number="items.ideas.h3.length.toString()">
						<template v-slot:default>
							<div v-for="ideas in items.ideas.h3" :key="items.ideas.h3.indexOf(ideas)" class="contadu-optimalization__expand-item">
								<div>{{ ideas.h }}</div>
								<div class="contadu-optimalization__idea-btn-wrapper">
									<MTooltip without-btn>
										<template v-slot:title>
											<div @click="copyTextToClipboard(ideas.h)" class="contadu-optimalization__expand-icon-wrapper">
												<MIcon icon="articles" />
											</div>
										</template>
										<template v-slot:content>
											<div>{{ $t('contadu.copyToClipboard') }}</div>
										</template>
									</MTooltip>
									<MTooltip without-btn>
										<template v-slot:title>
											<a :href="ideas.source" target="_blank" class="contadu-optimalization__expand-icon-wrapper contadu-optimalization__expand-icon-wrapper--link">
												<MIcon icon="arrow-out" />
											</a>
										</template>
										<template v-slot:content>
											<div>{{ $t('contadu.openSourceUrlInNewTab') }}</div>
										</template>
									</MTooltip>
								</div>
							</div>
						</template>
					</ContaduExpandTransition>
				</v-window-item>
			</v-window>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import type { IContaduOptimalization } from '@/models/ContaduModel';
import MVueEditor from '@/components/atoms/MVueEditor.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import ContaduExpandTransition from '@/views/Articles/components/ContaduExpandTransition.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';

interface IOwnProps {
	modelValue?: string
	items: IContaduOptimalization
}
interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();
const emit = defineEmits(['update:modelValue']);
const { t } = useI18n();

const articleContent = computed({
	get() {
		return props.modelValue;
	},
	set(value) {
		emit('update:modelValue', value);
	}
});

const tab = ref<string|null>(null);
const isTermsInHeaders = ref<boolean>(true);
const isTermsInArticle = ref<boolean>(false);
const H1TagsCounter = ref<any>({});
const H2TagsCounter = ref<any>({});
const wordCounter = ref<any>({});

const termsInHeadersAction = () => {
	isTermsInHeaders.value = true;
	isTermsInArticle.value = false;
};
const termsInArticleAction = () => {
	isTermsInHeaders.value = false;
	isTermsInArticle.value = true;
};

const fallbackCopyTextToClipboard = (text:string) => {
	const textArea = document.createElement('textarea');
	textArea.value = text;
	
	textArea.style.top = '0';
	textArea.style.left = '0';
	textArea.style.position = 'fixed';
	
	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();
	
	document.execCommand('copy');
	
	document.body.removeChild(textArea);
};
const copyTextToClipboard = (text:string) => {
	if (!navigator.clipboard) {
		fallbackCopyTextToClipboard(text);
		return;
	}
	navigator.clipboard.writeText(text).then(() => {
		toast.success(`${t('contadu.toast.successCopyToClipboard')}`);
	}, () => {
		toast.error(`${t('contadu.toast.errorCopyToClipboard')}`);
	});
};

watch((articleContent), (val) => {
	if (null != val) {
	let readonlyVal = val.toLowerCase()
		.replace(/<(?:.|\n|\r)*?>/gm, ' ')
		.replace(/\s{2,}/gm, ' ')
		.replace(/[,.?!]/gm, '');
	
	readonlyVal = ` ${readonlyVal} `;
	
	// Phrases counter
	props.items.terms.content_basic.forEach(elem => {
		const word = elem.t || '';
		const spacedWord = ` ${word.replace(/[,.]/gm, '')} `;
		let slicedReadonlyVal = readonlyVal;
		
		let startingIndexOfPhrase = slicedReadonlyVal.indexOf(spacedWord);
		
		let wordCount = 0;
		
		while (-1 < startingIndexOfPhrase) {
			wordCount = wordCount + 1;
			slicedReadonlyVal = slicedReadonlyVal.slice(startingIndexOfPhrase + 1);
			startingIndexOfPhrase = slicedReadonlyVal.indexOf(spacedWord);
		}
		wordCounter.value[word] = wordCount;
	});
	props.items.terms.content_extended.forEach((elem) => {
		const word = elem.t || '';
		const spacedWord = ` ${word.replace(/[,.]/gm, '')} `;
		let slicedReadonlyVal = readonlyVal;
		
		let startingIndexOfPhrase = slicedReadonlyVal.indexOf(spacedWord);
		
		let wordCount = 0;
		
		while (-1 < startingIndexOfPhrase) {
			wordCount = wordCount + 1;
			slicedReadonlyVal = slicedReadonlyVal.slice(startingIndexOfPhrase + 1);
			startingIndexOfPhrase = slicedReadonlyVal.indexOf(spacedWord);
		}
		wordCounter.value[word] = wordCount;
	});
	
	// H1 counter
	const readonlyH1TagsVal = val.toLowerCase()
		.replace(/style=["'a-zA-Z0-9-:;.,)#&(\s]*/gm, ' ')
		.replace(/\s{2,}/gm, ' ')
		// kasuje wszystkie tagi html poza h1
		.replace(/<(?!h1|\/h1)[^>]+>/gm, '')
		// zamienia ostatnia spacje oraz < wewnatrz tagu <h1></h1> i zamienia na < po to zeby pozbyc sie spacji na koncu frazy wewnatrz tagu h1
		.replace(/\s+</gm, '<')
		// kasuje znaki ktore sa w [] w tagu <h1></h1>>
		.replace(/[?!#$%^&*()+=~`"']/gm, '')
		// kasuje znacznik </h1> i dodaje spacje po nim bo inaczej nie rozdzieli mi jezeli 2 tagi h1 sa sklejone w tekscie
		.replace(/<\/h1>/gm, '</h1> ');
	
	const onlyH1TagsWithContent = readonlyH1TagsVal.match(/(<h1>.*?<\/h1>)+/gm)?.join().replace(/<h1>/gm, ' ').replace(/<\/h1>/gm, '').replace(/[,]/gm, '');
	
	let onlyH1TagContent:any = [];
	onlyH1TagContent.push(onlyH1TagsWithContent);
	onlyH1TagContent = ` ${onlyH1TagContent} `;
	props.items.terms.h1.forEach((elem) => {
		const word = elem.t || '';
		const spacedWord = ` ${word} `;
		let slicedReadonlyVal = onlyH1TagContent;
		
		let startingIndexOfPhrase = slicedReadonlyVal.indexOf(spacedWord);
		
		let H1TagCount = 0;
		
		while (-1 < startingIndexOfPhrase) {
			H1TagCount = H1TagCount + 1;
			slicedReadonlyVal = slicedReadonlyVal.slice(startingIndexOfPhrase + 1);
			startingIndexOfPhrase = slicedReadonlyVal.indexOf(spacedWord);
		}
		H1TagsCounter.value[word] = H1TagCount;
	});
	
	// H2 counter
	const readonlyH2TagsVal = val.toLowerCase()
		.replace(/style=["'a-zA-Z0-9-:;.,)#&(\s]*/gm, ' ')
		.replace(/\s{2,}/gm, ' ')
		// kasuje wszystkie tagi html poza h1
		.replace(/<(?!h2|\/h2)[^>]+>/gm, '')
		// zamienia ostatnia spacje oraz < wewnatrz tagu <h1></h1> i zamienia na < po to zeby pozbyc sie spacji na koncu frazy wewnatrz tagu h1
		.replace(/\s+</gm, '<')
		// kasuje znaki ktore sa w [] w tagu <h1></h1>>
		.replace(/[?!#$%^&*()+=~`"']/gm, '')
		// kasuje znacznik </h1> i dodaje spacje po nim bo inaczej nie rozdzieli mi jezeli 2 tagi h1 sa sklejone w tekscie
		.replace(/<\/h2>/gm, '</h2> ');
	
	const onlyH2TagsWithContent = readonlyH2TagsVal.match(/(<h2>.*?<\/h2>)+/gm)?.join().replace(/<h2>/gm, ' ').replace(/<\/h2>/gm, '').replace(/[,]/gm, '');
	
	let onlyH2TagContent:any = [];
	onlyH2TagContent.push(onlyH2TagsWithContent);
	onlyH2TagContent = ` ${onlyH2TagContent} `;
	props.items.terms.h2.forEach((elem) => {
		const word = elem.t || '';
		const spacedWord = ` ${word} `;
		let slicedReadonlyVal = onlyH2TagContent;
		
		let startingIndexOfPhrase = slicedReadonlyVal.indexOf(spacedWord);
		
		let H2TagCount = 0;
		
		while (-1 < startingIndexOfPhrase) {
			H2TagCount = H2TagCount + 1;
			slicedReadonlyVal = slicedReadonlyVal.slice(startingIndexOfPhrase + 1);
			startingIndexOfPhrase = slicedReadonlyVal.indexOf(spacedWord);
		}
		H2TagsCounter.value[word] = H2TagCount;
	});
	}
},{
	deep: true,
	immediate: true,
});
</script>

<style scoped lang="scss">
.contadu-optimalization {
	background-color: $white-100;
	margin-top: 5px;
	display: flex;
	flex-direction: column-reverse;
	gap: 15px;
	border-radius: 8px;
	
	@include media-breakpoint-up(lg) {
		flex-direction: row;
		gap: 10px;
	}
	
	&__left-wrapper {
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			width: 62%;
		}
		
		@include media-breakpoint-up(xl) {
			width: 68%;
		}
		
		@include media-breakpoint-up(xxl) {
			width: 70%;
		}
		
		&:deep(.m-vue-editor.ql-editor) {
			min-height: 620px;
		}
	}
	
	&__right-wrapper {
		width: 100%;
		padding-bottom: 20px;
		
		@include media-breakpoint-up(lg) {
			width: 38%;
		}
		
		@include media-breakpoint-up(xl) {
			width: 32%;
		}
		
		@include media-breakpoint-up(xxl) {
			width: 30%;
		}
		
		&:deep(.v-tab__slider) {
			background: $secondary-400;
			height: 3px;
		}
		
		&:deep(.v-slide-group__container) {
			background-color: $primary-400;
			color: $white-100;
		}
		
		&:deep(.v-tabs) {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}
	}
	
	&__tabs-wrapper {
		display: flex;
		gap: 5px;
		align-items: center;
		text-transform: none;
	}
	
	&__content-terms-wrapper {
		padding: 0 20px;
		
		@include media-breakpoint-up(lg) {
			padding: 0;
		}
	}
	
	&__second-wrapper {
		margin-top: 15px;
		display: flex;
		align-items: center;
		gap: 15px;
	}
	
	&__content-terms-tab-wrapper {
		text-transform: none;
		font-size: 14px;
		letter-spacing: 0;
		color: $primary-400;
		padding-bottom: 10px;
		border-bottom: 2px solid transparent;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		&--active {
			border-bottom: 2px solid $secondary-400;
		}
	}
	
	&__active-terms {
		animation: showTerms 1s;
		
		@keyframes showTerms {
			from {
				opacity: 0;
			}
			
			to {
				opacity: 1;
			}
		}
	}
	
	&__h-terms-wrapper {
		margin-top: 10px;
	}
	
	&__subtitle {
		font-size: 14px;
		font-weight: 700;
		margin: 20px 0;
	}
	
	&__expand-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 2px;
		border-top: 1px solid $grey-200;
		
		&:nth-child(1) {
			border-top: none;
		}
	}
	
	&__expand-icon-wrapper {
		padding: 8px;
		border: 1px solid $grey-300;
		border-radius: 6px;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		&--link {
			padding: 10px;
			
			svg {
				color: $primary-400;
			}
		}
	}
	
	&__label-main-wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 5px;
	}
	
	&__label-wrapper {
		background-color: $grey-200;
		border-radius: 8px;
		padding: 5px 8px;
		display: flex;
		align-items: center;
		gap: 5px;
		font-weight: 700;
		
		&--active {
			background-color: $green-300;
		}
		
		&--yellow {
			background-color: $yellow-200;
		}
		
		&--red {
			background-color: $secondary-300;
		}
	}
	
	&__label-item-wrapper {
		background-color: $white-100;
		padding: 2px 8px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		gap: 4px;
	}
	
	&__idea-btn-wrapper {
		display: flex;
		align-items: center;
		gap: 5px;
	}
}
</style>
