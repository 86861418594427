<template>
	<div class="side-menu side-menu__fixed" :class="{'side-menu--hidden': isMenuHidden}" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
		
		<div v-if="user">
			<div v-if="!isMobileView" @click="checkNewUserValidation()" class="side-menu__wrapper">
				<div class="side-menu__menu-wrapper">
					<div class="side-menu__logo-wrapper" @click="goToDashboard">
						<img src="~@/assets/images/logo-reverse.png" alt="marketing logo">
					</div>
					<div v-for="menuItems in menu" :key="menuItems.title" :class="{'side-menu__last-item': menu.indexOf(menuItems) === menu.length - 1}">
						<div v-if="!menuItems.isExpanded">
							<MButton :id="menuItems.id" v-if="menuItems.children[0].vue_route" :icon="menuItems.icon" :to="menuItems.children[0].link" :label="menuItems.children[0].title" class="side-menu__dashboard-wrapper" />
							<a :id="menuItems.id" v-if="!menuItems.children[0].vue_route" :href="menuItems.children[0].link" class="side-menu__dashboard-wrapper">
								<MIcon :icon="menuItems.icon" :color="primary400" width="16" height="20" />
								<div>{{ menuItems.children[0].title }}</div>
							</a>
						</div>
						<MSimpleExpansionPanel v-if="menuItems.isExpanded" :id="menuItems.id">
							<template v-slot:title>
								<div class="side-menu__title-wrapper">
									<MIcon :icon="menuItems.icon" :color="primary400" width="16" height="20" />
									<div>{{ menuItems.title }}</div>
								</div>
							</template>
							<template v-slot:text>
								<div v-for="children in menuItems.children" :key="children.title" class="side-menu__item-wrapper">
									<MButton :id="children.id" v-if="children.vue_route" :to="children.link" :label="children.title" class="side-menu__item-list" />
									<a :id="children.id" v-if="!children.vue_route" :href="children.link" class="side-menu__item-list"><div>{{ children.title }}</div></a>
								</div>
							</template>
						</MSimpleExpansionPanel>
					</div>
				</div>
            </div>
			<div v-if="isMobileView" class="side-menu__wrapper">
				<div v-for="menuItems in menu" :key="menuItems.title">
					<div v-if="!menuItems.isExpanded">
						<MButton v-if="menuItems.children[0].vue_route" :icon="menuItems.icon" :to="menuItems.children[0].link" class="side-menu__dashboard-tablet" />
						<a v-if="!menuItems.children[0].vue_route" :href="menuItems.children[0].link" class="side-menu__dashboard-tablet">
							<MIcon :icon="menuItems.icon" :color="primary400" width="16" height="20" />
						</a>
					</div>
	                <MMenu v-if="menuItems.isExpanded" location="right" is-side-menu open-on-click>
	                    <template v-slot:menuActivator>
		                    <div @click="activeTabletMenu(menuItems, menu)" class="side-menu__mobile-menu">
	                            <MIcon :icon="menuItems.icon" :color="primary400" width="16" height="20" />
	                        </div>
	                    </template>
	                    <template v-slot:menuItems>
		                    <div class="side-menu__tablet-item-wrapper">
			                    <div class="side-menu__tablet-title">{{ menuItems.title }}</div>
			                    <div v-for="children in menuItems.children" :key="children.title" class="side-menu__tablet-wrapper">
			                        <MButton v-if="children.vue_route" :to="children.link" :label="children.title" class="side-menu__tablet-item" />
				                    <a v-if="!children.vue_route" :href="children.link" class="side-menu__tablet-item"><div>{{ children.title }}</div></a>
		                        </div>
	                        </div>
	                    </template>
	                </MMenu>
				</div>
			</div>
		</div>

	</div>
</template>

<script setup lang="ts">
import { watch, ref, defineEmits, defineProps } from 'vue';
import router from '@/router';
import { xxlBreakpoint } from '@/hooks/BreakpointsHooks';
import {
	isAdmin,
	isModerator,
	isContentWriter,
	isAdvertiser,
	isPublisher,
	checkIsUserIsAgencyMember
} from '@/hooks/UserHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { checkAddressProfile, isProfileLocked } from '@/hooks/ProfileHooks';
import { checkIsUserJoinAffiliateProgram } from '@/hooks/UserHooks';
import type { MenuInterface } from '@/models/MenuModel';
import MSimpleExpansionPanel from '@/components/atoms/MSimpleExpansionPanel.vue';
import MButton from '@/components/atoms/MButton.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MMenu from '@/components/atoms/MMenu.vue';

interface IOwnProps {
	isMenuHidden: boolean
}
interface IProps extends IOwnProps {
}


const props = defineProps<IProps>();
const { t } = useI18n();
const { primary400 } = variables;
const emit = defineEmits(['mouseEnterAction', 'mouseLeaveAction']);

const { user } = storeToRefs(useUserStore());

const isMobileView = ref<boolean>(false);

const advertiserMenu:Array<MenuInterface> = [
	{
		id: 'dashboard-parent',
		title: `${t('layout.menu.dashboard')}`,
		icon: 'dashboard',
		active: false,
		isExpanded: false,
		children: [
			{
				id: 'dashboard',
				title: `${t('layout.menu.dashboard')}`,
				link: '/dashboard',
				vue_route: true
			},
		]
	},
    {
		id: 'article-parent',
        title: `${t('layout.menu.yourContent')}`,
	    icon: 'articles',
	    active: false,
	    isExpanded: true,
        children: [
            {
	            id: 'article',
                title: `${t('layout.menu.yourContentSecond')}`,
	            link: '/article',
                vue_route: true,
            },
            {
	            id: 'article-create',
                title:  `${t('layout.menu.addNewArticle')}`,
                link: '/article/create',
                vue_route: true
            },
            {
	            id: 'article-order',
                title: `${t('layout.menu.orderNewArticle')}`,
                link: '/order/article',
                vue_route: true
            },
        ]
    },
    {
		id: 'campaign-parent',
        title: `${t('layout.menu.campaigns')}`,
	    icon: 'projects',
	    active: false,
	    isExpanded: true,
        children: [
            {
				id: 'campaign',
                title: `${t('layout.menu.yourCampaigns')}`,
	            link: '/campaigns',
                vue_route: true
            },
            {
	            id: 'campaign-create',
                title: `${t('layout.menu.newCampaign')}`,
                link: '/campaigns/create',
                vue_route: true
            },
        ]
    },
    {
		id: 'order',
        title: `${t('layout.menu.publications')}`,
	    icon: 'publication',
	    active: false,
	    isExpanded: true,
        children: [
            {
	            id: 'order-create',
                title: `${t('layout.menu.availableWebsites')}`,
	            link: '/order/publication/cart',
                vue_route: true
            },
	        {
		        id: 'order-list',
		        title: `${t('layout.menu.yourPublications')}`,
		        link: '/order/publication',
		        vue_route: true
	        },
        ]
    },
    {
		id: 'basket',
        title: `${t('order.list.ordersAndBaskets')}`,
	    icon: 'cart',
	    active: false,
	    isExpanded: true,
        children: [
            {
	            id: 'basket-orders',
                title: `${t('layout.menu.orders')}`,
	            link: `/order/list/${user.value?.id}`,
                vue_route: true
            },
            {
	            id: 'basket-list',
                title: `${t('order.list.baskets')}`,
	            link: `/order/baskets/${user.value?.id}`,
                vue_route: true
            },
        ]
    },
];
const publisherMenu:Array<MenuInterface> = [
	{
		id: 'dashboard-parent',
		title: `${t('layout.menu.dashboard')}`,
		icon: 'dashboard',
		active: false,
		isExpanded: false,
		children: [
			{
				id: 'dashboard',
				title: `${t('layout.menu.dashboard')}`,
				link: '/dashboard',
				vue_route: true
			},
		]
	},
    {
	    id: 'order',
        title: `${t('layout.menu.orders')}`,
	    icon: 'articles',
	    active: false,
	    isExpanded: false,
        children: [
            {
	            id: 'order-list',
                title: `${t('layout.menu.yourPublications')}`,
                link: '/order/publication',
                vue_route: true
            },
        ]
    },
    {
		id: 'website',
        title: `${t('layout.menu.domains')}`,
	    icon: 'language',
	    active: false,
	    isExpanded: true,
        children: [
            {
	            id: 'website-list',
                title: `${t('layout.menu.myDomainList')}`,
	            link: '/websites',
                vue_route: true
            },
            {
	            id: 'website-offers',
                title: `${t('layout.menu.offerList')}`,
                link: '/websites/offers',
                vue_route: true
            },
        ]
    },
];
const contentWriterMenu:Array<MenuInterface> = [
	{
		id: 'dashboard-parent',
		title: `${t('layout.menu.dashboard')}`,
		icon: 'dashboard',
		active: false,
		isExpanded: false,
		children: [
			{
				id: 'dashboard',
				title: `${t('layout.menu.dashboard')}`,
				link: '/dashboard',
				vue_route: true
			},
		]
	},
    {
	    id: 'article-parent',
        title: `${t('layout.menu.yourPublications')}`,
	    icon: 'articles',
	    active: false,
	    isExpanded: true,
        children: [
            {
				id: 'article',
                title: `${t('layout.menu.orderedArticles')}`,
                link: '/article',
                vue_route: true
            },
	        {
				id: 'order-list',
		        title: `${t('layout.menu.yourPublications')}`,
		        link: '/order/publication',
		        vue_route: true
	        },
        ]
    },
];
const moderatorMenu:Array<MenuInterface> = [
	{
		id: 'dashboard-parent',
		title: `${t('layout.menu.dashboard')}`,
		icon: 'dashboard',
		active: false,
		isExpanded: false,
		children: [
			{
				id: 'dashboard',
				title: `${t('layout.menu.dashboard')}`,
				link: '/dashboard',
				vue_route: true
			},
		]
	},
    {
		id: 'website',
        title: `${t('layout.menu.publications')}`,
	    icon: 'articles',
	    active: false,
	    isExpanded: true,
        children: [
            {
	            id: 'website-list',
                title: `${t('layout.menu.listOfWebsites')}`,
                link: '/websites',
                vue_route: true
            },
            {
	            id: 'website-offers',
                title: `${t('layout.menu.offerList')}`,
                link: '/websites/offers',
                vue_route: true
            },
            {
				id: 'order-list',
                title: `${t('layout.menu.inProgress')}`,
                link: '/order/publication',
                vue_route: true
            },
            {
				id: 'website-request',
                title: `${t('layout.menu.websiteRequest')}`,
                link: '/website/requests',
                vue_route: true
            },
        ]
    },
    {
		id: 'chat-parent',
        title: `${t('layout.menu.chat')}`,
	    icon: 'chat',
	    active: false,
	    isExpanded: true,
        children: [
            {
				id: 'chat',
                title: `${t('layout.menu.chat')}`,
                link: '/chats',
                vue_route: true
            },
        ]
    },
    {
		id: 'users-list',
        title: `${t('layout.menu.usersBase')}`,
	    icon: 'users',
	    active: false,
	    isExpanded: true,
        children: [
            {
				id: 'users',
                title: `${t('layout.menu.usersList')}`,
                link: '/users-list',
                vue_route: true
            },
        ]
    },
    {
		id: 'pricelist-parent',
        title: `${t('pricelist.pricelist')}`,
	    icon: 'barcode',
	    active: false,
	    isExpanded: true,
        children: [
            {
	            id: 'pricelist',
                title: `${t('pricelist.pricelist')}`,
                link: '/price-list',
                vue_route: true
            },
            {
	            id: 'pricelist-groups',
                title: `${t('layout.menu.discountGroups')}`,
                link: '/discount-groups',
                vue_route: true
            },
        ]
    },
    {
		id: 'ahrefs-parent',
        title: `${t('layout.menu.ahrefs')}`,
	    icon: 'stats',
	    active: false,
	    isExpanded: true,
        children: [
            {
	            id: 'ahrefs',
                title: `${t('layout.menu.ahrefs')}`,
                link: '/ahrefs',
                vue_route: true
            },
        ]
    },
    {
		id: 'carts-parent',
        title: `${t('layout.menu.createCart')}`,
	    icon: 'cart',
	    active: false,
	    isExpanded: true,
        children: [
            {
	            id: 'carts',
                title: `${t('layout.menu.carts')}`,
                link: '/carts',
                vue_route: true
            },
            {
	            id: 'carts-create',
                title: `${t('layout.menu.createCart')}`,
                link: '/carts/create',
                vue_route: true
            },
        ]
    },
];
const adminMenu:Array<MenuInterface> = [
	{
		id: 'dashboard-parent',
		title: `${t('layout.menu.dashboard')}`,
		icon: 'dashboard',
		active: false,
		isExpanded: false,
		children: [
			{
				id: 'dashboard',
				title: `${t('layout.menu.dashboard')}`,
				link: '/dashboard',
				vue_route: true
			},
		]
	},
    {
		id: 'users',
        title: `${t('layout.menu.users')}`,
	    icon: 'users',
	    active: false,
	    isExpanded: true,
        children: [
            {
				id: 'users-advertiser',
                title: `${t('layout.menu.advertisersList')}`,
                link: '/user?role_id=2',
                vue_route: false
            },
            {
				id: 'users-publisher',
                title: `${t('layout.menu.publishersList')}`,
                link: '/user?role_id=3',
                vue_route: false
            },
            {
				id: 'users-mod',
                title: `${t('layout.menu.moderatorList')}`,
                link: '/user?role_id=4',
                vue_route: false
            },
            {
				id: 'users-content',
                title: `${t('layout.menu.contentWritersList')}`,
                link: '/user?role_id=5',
                vue_route: false
            },
            {
				id: 'users-admin',
                title: `${t('layout.menu.adminList')}`,
                link: '/user?role_id=1',
                vue_route: false
            },
            {
				id: 'users-mod-create',
                title: `${t('layout.menu.CreateModeratorAccount')}`,
                link: '/profile/create?role_id=4',
                vue_route: false
            },
            {
				id: 'users-content-create',
                title: `${t('layout.menu.CreateContentWriterAccount')}`,
                link: '/profile/create?role_id=5',
                vue_route: false
            },
            {
				id: 'users-publisher-create',
                title: `${t('layout.menu.CreatePublisherAccount')}`,
                link: '/profile/create?role_id=3',
                vue_route: false
            },
        ]
    },
    {
		id: 'categories-parent',
        title: `${t('layout.menu.categories')}`,
	    icon: 'cubes',
	    active: false,
	    isExpanded: true,
        children: [
            {
	            id: 'categories',
                title: `${t('layout.menu.categories')}`,
                link: '/categories',
                vue_route: false
            },
            {
	            id: 'categories-create',
                title: `${t('layout.menu.submitNewCategory')}`,
                link: '/categories/create',
                vue_route: false
            },
        ]
    },
    {
	    id: 'coupons-parent',
        title: `${t('layout.menu.coupons')}`,
	    icon: 'ticket',
	    active: false,
	    isExpanded: true,
        children: [
            {
	            id: 'coupons',
                title: `${t('layout.menu.coupons')}`,
                link: '/coupons',
                vue_route: true
            },
            {
	            id: 'coupons-create',
                title: `${t('layout.menu.couponsCreate')}`,
                link: '/coupons/create',
                vue_route: true
            },
        ]
    },
    {
		id: 'settings-parent',
        title: `${t('layout.menu.settings')}`,
	    icon: 'settings-double',
	    active: false,
	    isExpanded: true,
        children: [
            {
	            id: 'settings',
                title: `${t('layout.menu.settings')}`,
                link: '/settings',
                vue_route: false
            },
            {
	            id: 'settings-article-prices',
                title: `${t('layout.menu.orderArticleSettings')}`,
                link: '/order/article/prices',
                vue_route: false
            },
        ]
    },
    {
		id: 'withdraw-parent',
        title: `${t('layout.menu.withdrawal')}`,
	    icon: 'university',
	    active: false,
	    isExpanded: true,
        children: [
            {
				id: 'withdraw',
                title: `${t('layout.menu.withdrawal')}`,
                link: '/withdrawal',
                vue_route: true
            },
        ]
    },
    {
	    id: 'website-parent',
        title: `${t('layout.menu.publications')}`,
	    icon: 'articles',
	    active: false,
	    isExpanded: true,
        children: [
            {
	            id: 'website-list',
                title: `${t('layout.menu.listOfWebsites')}`,
                link: '/websites',
                vue_route: true
            },
            {
	            id: 'website-offers',
                title: `${t('layout.menu.websiteOffers')}`,
                link: '/websites/offers',
                vue_route: true
            },
            {
	            id: 'order-list',
                title: `${t('layout.menu.inProgress')}`,
                link: '/order/publication',
                vue_route: true
            },
        ]
    },
    {
		id: 'export-parent',
        title: `${t('layout.menu.export')}`,
	    icon: 'download',
	    active: false,
	    isExpanded: true,
        children: [
            {
	            id: 'export',
                title: `${t('layout.menu.exportList')}`,
                link: '/export-list',
                vue_route: true
            },
        ]
    },
    {
	    id: 'import-parent',
        title: `${t('layout.menu.import')}`,
	    icon: 'upload',
	    active: false,
	    isExpanded: true,
        children: [
            {
	            id: 'import',
                title: `${t('layout.menu.importExport')}`,
                link: '/import',
                vue_route: true
            },
        ]
    },
    {
	    id: 'plug',
        title: `${t('layout.menu.integration')}`,
	    icon: 'plug',
	    active: false,
	    isExpanded: true,
        children: [
            {
	            id: 'plug-google',
                title: `${t('layout.menu.googleAuthorize')}`,
                link: '/google',
                vue_route: true
            },
            {
	            id: 'plug-mailchimp',
                title: `${t('layout.menu.mailchimp')}`,
                link: '/mailchimp',
                vue_route: true
            },
        ]
    },
    {
		id: 'pricelist-parent',
        title: `${t('pricelist.pricelist')}`,
	    icon: 'barcode',
	    active: false,
	    isExpanded: true,
        children: [
            {
	            id: 'pricelist',
                title: `${t('pricelist.pricelist')}`,
                link: '/price-list',
                vue_route: true
            },
            {
	            id: 'pricelist-groups',
                title: `${t('layout.menu.discountGroups')}`,
                link: '/discount-groups',
                vue_route: true
            },
        ]
    },
    {
		id: 'banners-parent',
        title: `${t('layout.menu.banners')}`,
	    icon: 'banner',
	    active: false,
	    isExpanded: false,
        children: [
            {
	            id: 'banners',
	            title: `${t('layout.menu.banners')}`,
                link: '/banners',
                vue_route: true
            },
        ]
    },
	{
		id: 'ahrefs-parent',
		title: `${t('layout.menu.ahrefs')}`,
		icon: 'stats',
		active: false,
		isExpanded: true,
		children: [
			{
				id: 'ahrefs',
				title: `${t('layout.menu.ahrefs')}`,
				link: '/ahrefs',
				vue_route: true
			},
		]
	},
	{
		id: 'payments-parent',
		title: `${t('layout.menu.payments')}`,
		icon: 'history-payment',
		active: false,
		isExpanded: true,
		children: [
			{
				id: 'payments-transactions',
				title: `${t('layout.menu.paymentsTransactions')}`,
				link: '/payment-transactions',
				vue_route: true
			},
		]
	},
];
if (null != user.value && !checkIsUserIsAgencyMember(user.value)) {
	advertiserMenu.push(
		{
			id: 'reflinks',
			title: `${t('layout.menu.affiliateProgram')}`,
			icon: 'hands',
			active: false,
			isExpanded: true,
			children: [
				{
					id: 'reflinks-details',
					title: `${t('layout.menu.affiliateProgram')}`,
					link: `/affiliate-program/details`,
					vue_route: true
				},
				{
					id: 'reflinks-history',
					title: `${t('layout.menu.affiliateProgramTransactions')}`,
					link: `${checkIsUserJoinAffiliateProgram(user.value) ? '/affiliate-program' : '/affiliate-program/details'}`,
					vue_route: true
				},
			]
		},
	);
	publisherMenu.push(
		{
			id: 'reflinks',
			title: `${t('layout.menu.affiliateProgram')}`,
			icon: 'hands',
			active: false,
			isExpanded: true,
			children: [
				{
					id: 'reflinks-details',
					title: `${t('layout.menu.affiliateProgram')}`,
					link: `/affiliate-program/details`,
					vue_route: true
				},
				{
					id: 'reflinks-history',
					title: `${t('layout.menu.affiliateProgramTransactions')}`,
					link: `${checkIsUserJoinAffiliateProgram(user.value) ? '/affiliate-program' : '/affiliate-program/details'}`,
					vue_route: true
				},
			]
		},
	);
}
let menu:Array<MenuInterface> = [];

if (null != user.value && isAdvertiser(user.value)) {
    menu = advertiserMenu;
}
if (null != user.value && isPublisher(user.value)) {
    menu = publisherMenu;
}
if (null != user.value && isContentWriter(user.value)) {
    menu = contentWriterMenu;
}
if (null != user.value && isModerator(user.value)) {
    menu = moderatorMenu;
}
if (null != user.value && isAdmin(user.value)) {
    menu = adminMenu;
}

const checkNewUserValidation = () => {
    if (newUser()) {
        router.go(0);
    }
};

const newUser = () => {
    if (null != user.value && (isAdvertiser(user.value) || isPublisher(user.value))) {
        return (
            !isProfileLocked(user.value) ||
            checkAddressProfile(user.value)
        );
    }
};

const checkBreakpoint = () => {
	if (window.innerWidth >= xxlBreakpoint) {
		isMobileView.value = false;
	} else {
		isMobileView.value = true;
	}
};
checkBreakpoint();

const activeTabletMenu = (item: MenuInterface, parentItems: Array<MenuInterface>) => {
    parentItems.forEach(elem => elem.active = false);
    item.active = true;
};

const goToDashboard = async() => {
	await router.push('/dashboard');
};

watch((user), (val) => {
	if (val && null != user.value && !checkIsUserIsAgencyMember(user.value)) {
		const affiliateObject = menu.find(elem => elem.id === 'reflinks');
		if (null != affiliateObject) {
			affiliateObject.children[1].link = '/affiliate-program';
		}
	}
},{
	deep: true,
});

// Funkcja ktora powoduje przeladowanie strony zeby poprawnie wyswietlalo sie menu w zaleznosci od przekroczenia breakpointu
let currentWindowWidth = window.innerWidth;
window.addEventListener('resize', function() {
	const changedWindowWidth = window.innerWidth;
	if (currentWindowWidth < xxlBreakpoint && changedWindowWidth >= xxlBreakpoint && !props.isMenuHidden) {
		isMobileView.value = false;
	}
	if (currentWindowWidth >= xxlBreakpoint && changedWindowWidth < xxlBreakpoint) {
		isMobileView.value = true;
	}
	currentWindowWidth = changedWindowWidth;
});

const handleMouseEnter = () => {
	emit('mouseEnterAction', true);
};

const handleMouseLeave = () => {
	emit('mouseLeaveAction', true);
};

watch((props), (val) => {
	if (val.isMenuHidden) {
		isMobileView.value = true;
	}
	if (!val.isMenuHidden && window.innerWidth >= xxlBreakpoint) {
		isMobileView.value = false;
	}
},{
	deep: true,
	immediate: true,
})
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
    display: none;
	scrollbar-width: none;
    -ms-overflow-style: none;
}
.side-menu {
	width: 50px;
	background-color: $primary-400;
	overflow-y: scroll;
	height: 100vh;
    scrollbar-width: none;
    -ms-overflow-style: none;
	
	@include media-breakpoint-up(xxl) {
		width: 300px;
	}
	
	&--hidden {
		@include media-breakpoint-up(xl) {
			width: 50px;
		}
	}
	
	&:deep(.v-expansion-panel-title) {
		font-size: 14px;
		color: $primary-400;
		font-weight: 400;
		background-color: $white-100 !important;
		border-radius: 0;
		padding: 0 16px;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.8;
		}
	}
	
	&:deep(.v-expansion-panel-text__wrapper) {
		padding: 0;
		background-color: $white-100;
	}
	
	&:deep(.m-menu) {
		width: 100%;
		display: flex;
		min-height: 60px;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		background-color: $primary-400;
		padding: 0;
	}
	
	&:deep(.m-menu div) {
		width: 100%;
		display: flex;
		min-height: 60px;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	
	&__fixed {
		position: fixed;
	}
	
	&__wrapper {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}
	
	&__menu-wrapper {
		display: flex;
		flex-direction: column;
		padding: 25px;
	}
	
	&__title-wrapper {
		display: flex;
		align-items: center;
		gap: 10px;
		
		img {
			width: 16px;
			height: 20px;
		}
	}
	
	&__item-wrapper,
    &__tablet-wrapper {
        width: 100%;
		
		&:deep(.m-link) {
			display: flex;
			align-items: center;
			min-height: 47px;
			height: 47px;
			font-size: 14px;
			color: $primary-400 !important;
			font-weight: 400;
			width: calc(100% - 45px);
			border-radius: 8px;
			padding: 0 10px 0 30px;
			margin-left: 30px;
			margin-bottom: 2px;
		}

        a {
	        display: flex;
	        align-items: center;
	        min-height: 47px;
	        height: 47px;
            font-size: 14px;
            color: $primary-400 !important;
            font-weight: 400;
	        border-radius: 8px;
            width: calc(100% - 45px);
            text-decoration: none;
	        margin-bottom: 2px;
	        
	        div {
                padding: 0 10px 0 30px;
		        margin-left: 30px;
	        }
        }
	}
	
	&__item-list,
    &__tablet-item {
		position: relative;
		transition: 0.3s;
		
		&::before {
            content: "";
            position: absolute;
            left: 10px;
            top: 19px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $primary-400;
		}
		
		&:hover {
            opacity: 1;
            background-color: $primary-300;
            color: $white-100 !important;

            &::before {
                background-color: $white-100;
            }
			
			div {
				background-color: $primary-300;
				color: $white-100 !important;
			}
		}
	}
	
	&__tablet-item-wrapper {
        background-color: $white-100;
		width: 250px;
		max-width: 250px;
		box-shadow: 5px 0 10px rgba(0, 0, 0, .1);
	}
	
	&__tablet-title {
        background-color: $white-100;
        font-size: 14px;
        color: $primary-400;
        font-weight: 400;
        width: 100%;
        padding: 19px 40px;
		border-bottom: 1px solid $primary-400;
		margin-bottom: 1px;
	}
	
	&__dashboard-wrapper {
		display: flex;
		align-items: center;
		background-color: $white-100;
		text-decoration: none;
		cursor: pointer;
		gap: 10px;
        font-size: 14px;
        color: $primary-400 !important;
		border-radius: 0;
        font-weight: 400;
        width: 100%;
        padding: 17.5px 30px 17.5px 16px;
		height: 47px;
		transition: background-color 0.3s, color 0.3s, opacity 0.3s;
		
		&:hover {
			opacity: 1;
			background-color: $primary-300;
			color: $white-100 !important;
		}
	}
	
	&__dashboard-tablet {
		display: flex;
		align-items: center;
		justify-content: center;
		color: $primary-400 !important;
		background-color: $white-100;
		cursor: pointer;
		height: 60px;
		border-radius: 0;
		width: 100%;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
	        opacity: 1;
            background-color: $primary-300;
	        color: $white-100 !important;
        }
	}
	
	&__logout-wrapper {
		margin: auto auto 25px;
        display: flex;
        align-items: center;
        background-color: $secondary-400;
        cursor: pointer;
        gap: 44px;
        font-size: 14px;
        color: $white-100;
        font-weight: 700;
        width: 250px;
        padding: 17.5px 30px 17.5px 23px;
        height: 60px;
		border-radius: 8px;
        transition: background-color 0.3s, color 0.3s;
		
		svg {
			fill: $white-100;
		}

        &:hover {
            background-color: $white-100;
            color: $primary-400;
	        
	        svg {
		        fill: $primary-400;
	        }
        }
	}
	
	&__logout-tablet {
		background-color: $secondary-400;
		margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 60px;
        transition: background-color 0.3s;
		
		svg {
			fill: $white-100;
		}

        &:hover {
            background-color: $white-100;
	        
	        svg {
		        fill: $primary-400;
	        }
        }
	}
	
	&:deep(.m-btn__text) {
		margin-left: 0;
	}
	
	&__mobile-menu {
		background-color: $white-100;
		
		&:hover {
			opacity: 0.8;
		}
		
		svg {
			fill: $primary-400;
		}
	}
	
	&__last-item {
		&:deep(.v-expansion-panel-title) {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}
		&:deep(.v-expansion-panel-title--active) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
		
		&:deep(.v-expansion-panel-text__wrapper) {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}
	}
	
	&:deep(.v-expansion-panel__shadow) {
		box-shadow: none;
	}
	
	&__logo-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		background-color: $white-100;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		margin: auto;
		height: 90px;
		cursor: pointer;
		
		img {
			width: 145px;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	#reflinks {
		.side-menu__title-wrapper {
			svg {
				width: 22px;
				height: 22px;
				margin-right: -4px;
				margin-left: -2px;
			}
		}
	}
}
</style>
