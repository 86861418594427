<template>
	<div class="export-list-table">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template v-slot:loading>
				<div class="export-list-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #item.created_at="elem">
				<div>{{ dateFormatWithTime(elem.item.raw.created_at) }}</div>
			</template>
			
			<template #item.status="elem">
				<div v-if="elem.item.raw.status === FINISHED">
					<div class="export-list-table__status-info-wrapper">
						<div class="export-list-table__status export-list-table__status--done">{{ elem.item.raw.status }}</div>
						<MTooltip without-btn>
							<template v-slot:title>
								<MIcon v-if="elem.item.raw.items > 0" icon="info" width="16" height="16" />
							</template>
							<template v-slot:content>
								<div>{{ $t('importExport.downloadedRecords') }}: {{ elem.item.raw.progress }} {{ $t('importExport.from') }} {{ elem.item.raw.items }}</div>
							</template>
						</MTooltip>
					</div>
				</div>
				<div v-if="elem.item.raw.status === CORRUPTED" class="export-list-table__status export-list-table__status--corrupted">{{ elem.item.raw.status }}</div>
				<div v-if="elem.item.raw.status === PENDING || elem.item.raw.status === RESTORING">
					<div class="export-list-table__status-color export-list-table__status-color--yellow">{{ elem.item.raw.status }}</div>
					<div class="export-list-table__status-info-wrapper">
						<MProgressBar :model-value="changeValueToPercent(elem.item.raw.progress, elem.item.raw.items)" :color="yellow500" is-percent height="16" />
						<MTooltip without-btn>
							<template v-slot:title>
								<MIcon v-if="elem.item.raw.items > 0" icon="info" width="16" height="16" />
							</template>
							<template v-slot:content>
								<div>{{ $t('importExport.downloadedRecords') }}: {{ elem.item.raw.progress }}</div>
								<div>{{ $t('importExport.totalRecords') }}: {{ elem.item.raw.items }}</div>
							</template>
						</MTooltip>
					</div>
				</div>
				<div v-if="elem.item.raw.status === ERROR">
					<div class="export-list-table__status-color export-list-table__status-color--red">{{ elem.item.raw.status }}</div>
					<div class="export-list-table__status-info-wrapper">
						<MProgressBar :model-value="changeValueToPercent(elem.item.raw.progress, elem.item.raw.items)" :color="secondary400" is-percent height="16" />
						<MTooltip without-btn>
							<template v-slot:title>
								<MIcon v-if="elem.item.raw.items > 0" icon="info" width="16" height="16" />
							</template>
							<template v-slot:content>
								<div>{{ $t('importExport.downloadedStop') }}</div>
								<div>{{ $t('importExport.downloadedRecords') }}: {{ elem.item.raw.progress }}</div>
								<div>{{ $t('importExport.totalRecords') }}: {{ elem.item.raw.items }}</div>
							</template>
						</MTooltip>
					</div>
				</div>
			</template>
			
			<template #item.actions="elem">
				<div v-if="elem.item.raw.status === FINISHED && elem.item.raw.type !== OPENSEARCH_REFRESH_TYPE" class="export-list-table__btn-wrapper">
					<MTooltip without-btn>
						<template v-slot:title>
							<MButton
								id="download"
								icon="download"
								width="16"
								height="16"
								:color="white"
								blue400
								@click="downloadExport(elem.item.raw.url)"
							/>
						</template>
						<template v-slot:content>
							{{ $t('importExport.download') }}
						</template>
					</MTooltip>
				</div>
				<div v-if="elem.item.raw.status === ERROR" class="export-list-table__btn-wrapper">
					<MTooltip without-btn>
						<template v-slot:title>
							<MButton
								id="refresh"
								icon="refresh"
								width="16"
								height="16"
								:color="white"
								blue400
								@click="refreshExport(elem.item.raw.uuid)"
							/>
						</template>
						<template v-slot:content>
							{{ $t('importExport.refresh') }}
						</template>
					</MTooltip>
				</div>
			</template>
		</v-data-table-server>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { ref } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { ROUTE_EXPORT_LIST } from '@/hooks/RoutingHooks';
import { PENDING, RESTORING, FINISHED, ERROR, CORRUPTED, OPENSEARCH_REFRESH_TYPE } from '@/hooks/ImportExportHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import { toast } from 'vue3-toastify';
import ExportApi from '@/api/v1/ExportApi';
import { dateFormatWithTime } from '@/hooks/DataHooks';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { IExportItem } from '@/models/ExportModel';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MButton from '@/components/atoms/MButton.vue';

const exportApi = new ExportApi();

const { t } = useI18n();
const { white, secondary400, yellow500 } = variables;

const loading = ref<boolean>(false);
const items = ref<Array<IExportItem>|any>([]);
const total = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('importExport.createdAt')}`,
		align: 'center',
		sortable: false,
		key: 'created_at',
		width: '120px',
	},
	{
		title: `${t('importExport.status')}`,
		align: 'center',
		sortable: false,
		key: 'status',
		width: '150px',
	},
	{
		title: `${t('importExport.description')}`,
		align: 'center',
		sortable: false,
		key: 'description',
		width: '300px'
	},
	{
		title: `${t('importExport.actions')}`,
		align: 'center',
		sortable: false,
		key: 'actions',
		width: '80px',
	},
]);

const exportedList = async() => {
	loading.value = true;
	
	try {
		const result = await exportApi.exportedWebsiteOfferList(options.value.page, options.value.itemsPerPage);
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.errorExportList')}`);
			loading.value = false;
			return;
		}
		
		items.value = result.payload.data;
		currentPage.value = result.payload.current_page;
		lastPage.value = result.payload.last_page;
		total.value = result.payload.total;

		if (items.value.length > 0) {
			const inProgressItem = items.value.find((elem:IExportItem) => elem.status === PENDING || elem.status === RESTORING);
			
			if (null != inProgressItem && router.currentRoute.value.name === ROUTE_EXPORT_LIST) {
				setTimeout(() => {
					exportedList();
				}, 15000);
			}
		}
	} catch (e) {
		toast.error(`${t('importExport.toast.errorExportList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	await exportedList();
};

const changeValueToPercent = (value:number, total:number) => {
	if (0 === total) {
		return '100';
	}
	return ((value / total) * 100).toFixed(2).toString();
};

const downloadExport = (url:string) => {
	window.location.href = url;
};

const refreshExport = async(uuid:string) => {
	loading.value = true;
	try {
		const result = await exportApi.exportWebsiteOffer(null, uuid);
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.errorRetry')}`);
			loading.value = false;
			return;
		}
		await exportedList();
		toast.success(`${t('importExport.toast.successRetry')}`);
	} catch (e) {
		toast.error(`${t('importExport.toast.errorRetry')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
</script>

<style scoped lang="scss">
.export-list-table {
	@import "@/assets/scss/vuetifyDataTable.scss";
	&__loading {
		opacity: 0.5;
	}
	
	&__status {
		width: max-content;
		padding: 3px 15px;
		border-radius: 8px;
		font-weight: $weight-700;
		
		&--done {
			background-color: $green-400;
		}
		
		&--pending {
			background-color: $yellow-500;
		}
		
		&--corrupted {
			background-color: $secondary-400;
		}
	}
	
	&__btn-wrapper {
		margin: 0 auto;
		width: max-content;
		
		button {
			width: max-content !important;
			min-width: max-content !important;
			margin-left: auto;
			border-radius: 50%;
			padding: 0 7px 0 10px !important;
		}
	}
	
	&__status-info-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
	}
	
	&__status-color {
		font-weight: 700;
		margin-right: 20px;
		
		&--red {
			color: $secondary-400;
		}
		
		&--yellow {
			color: $yellow-500;
		}
	}
}
</style>
