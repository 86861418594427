import { defineStore } from 'pinia';
import type { GoogleFilesInterface } from '@/models/GoogleModel';

type GoogleType = {
	isSelectedGoogleFiles: boolean
	googleToken: string|null
	googleLoginToken: string|null
	googleFiles: Array<GoogleFilesInterface>
}
export const useGoogleStore = defineStore('useGoogleStore', {
	state: ():GoogleType => ({
		isSelectedGoogleFiles: false,
		googleToken: null,
		googleLoginToken: null,
		googleFiles: [],
	}),
	actions: {
		setIsSelectedGoogleFiles (isSelectedGoogleFilesValue:boolean) {
			this.isSelectedGoogleFiles = isSelectedGoogleFilesValue;
		},
		setGoogleToken (googleTokenValue:string) {
			this.googleToken = googleTokenValue;
		},
		setGoogleFiles (googleFilesValue:Array<GoogleFilesInterface>) {
			this.googleFiles = googleFilesValue;
		},
		setGoogleLoginToken (googleLoginTokenValue:string|null) {
			this.googleLoginToken = googleLoginTokenValue;
		},
	},
});
