<template>
	<div class="publisher-news">
		<div v-if="null != items && items.length > 0" class="publisher-news__title">{{ $t('dashboard.news') }}</div>
		<MProgressBar v-show="loading" indeterminate height="2" />
		
		<div v-if="null != items && items.length > 0" class="publisher-news__main-wrapper">
			<div v-show="!loading" class="publisher-news__wrapper" v-for="item in items as any" :key="item.id">
				<div class="publisher-news__img">
					<img :src="item.image" alt="marketin9 post">
				</div>
				<div class="publisher-news__item-title" v-html="item.title.rendered"></div>
				<div class="publisher-news__description" v-html="item.excerpt.rendered"></div>
				<div class="publisher-news__btn-wrapper" @click="showMore(item.link)">
					<div class="publisher-news__btn-text">{{ $t('dashboard.seeMore') }}</div>
					<MIcon icon="red-arrow-down" class="publisher-news__icon"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@/stores/session';
import WordpressApi from '@/api/v1/WordpressApi';
import type { IWordpressPosts } from '@/models/WordpressModel';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import MIcon from '@/components/atoms/MIcon.vue';

const wordpressApi = new WordpressApi();

const { locale } = storeToRefs(useSessionStore());
const localeLanguage = ref<string>('');

if ('us' === locale.value) {
	localeLanguage.value = 'en';
} else if (locale.value != null) {
	localeLanguage.value = locale.value;
} else {
	localeLanguage.value = 'pl';
}

const loading = ref<boolean>(false);
const items = ref<Array<IWordpressPosts>>([]);

const wordpressPostsList = async() => {
	loading.value = true;
	try {
		const result = await wordpressApi.postsList(localeLanguage.value);
		if (!result.isSuccess) {
			loading.value = false;
			return;
		}
		items.value = result.payload;
	} catch (e) {
		loading.value = false;
		return;
	}
	loading.value = false;
};
wordpressPostsList();

const showMore = (url:string) => {
	window.open(url, '_blank');
};
</script>

<style scoped lang="scss">
.publisher-news {
	&__title {
		font-size: 24px;
		font-weight: 700;
		color: $primary-400;
		margin-bottom: 20px;
	}
	
	&__main-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		
		@include media-breakpoint-up(lg) {
			flex-direction: row;
			flex-wrap: wrap;
		}
		
		@include media-breakpoint-up(xl) {
			gap: 20px;
		}
		
		@include media-breakpoint-up(xxl) {
			gap: 32px;
		}
	}
	
	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 15px;
		background-color: $white-100;
		border-radius: 20px;
		padding: 10px;
		
		@include media-breakpoint-up(lg) {
			width: 32%;
			padding: 24px;
		}
		
		@include media-breakpoint-up(xxl) {
			width: 31%;
		}
	}
	
	&__img {
		width: 100%;
		display: flex;
		justify-content: center;
		
		img {
			border-radius: 16px;
			max-height: 240px;
			min-height: 150px;
			object-fit: cover;
			
			@include media-breakpoint-up(xl) {
				height: 240px;
			}
		}
	}
	
	&__item-title {
		font-size: 20px;
		font-weight: 600;
		color: $primary-400;
	}
	
	&__description {
		font-size: 16px;
		font-weight: 300;
		line-height: 19.2px;
		overflow: hidden;
		max-height: 250px;
	}
	
	&__btn-wrapper {
		display: flex;
		gap: 5px;
		margin-left: auto;
		align-items: baseline;
		width: max-content;
		margin-top: auto;
		cursor: pointer;
		transition: opacity 0.5s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__btn-text {
		font-size: 12px;
		font-weight: 600;
	}
	
	&__icon {
		transform: rotateZ(270deg);
	}
}
</style>
