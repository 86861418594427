<template>
	<div class="m-date-range-picker">
		<RangeDataPicker
			v-model="value"
			range
			:locale="locale"
			:name="name"
			:clearable="clearable"
			:placeholder="placeholder"
			:multi-calendars="multiCalendars"
			:min-date="minDate"
			:max-date="maxDate"
			:format="format"
			:class="{'m-date-range-picker__hide-time-picker': !timePicker}"
			ref="dateRangePicker"
		>
			<template v-if="isCustomButtons" #action-buttons>
				<div class="m-date-range-picker__custom-buttons">
					<div @click="closeMenuAction">
						<slot name="close-button"></slot>
					</div>
					<div @click="selectDateAction">
						<slot name="submit-button"></slot>
					</div>
				</div>
			</template>
		</RangeDataPicker>
		<div class="m-date-range-picker__errors">{{ errors[0] }}</div>
	</div>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, toRef, withDefaults, ref } from 'vue';
import { useField } from 'vee-validate';
import RangeDataPicker from '@vuepic/vue-datepicker';
import dayjs from 'dayjs';
interface IOwnProps {
	locale: string
	name: string
	modelValue?: string|Array<string>|null
	placeholder?: string
	clearable?: boolean
	multiCalendars?: boolean
	timePicker?: boolean
	isCustomButtons?: boolean
	format?: string
	minDate?: string
	maxDate?: string
}
interface IProps extends IOwnProps {
}

const props = withDefaults(defineProps<IProps>(), {
	timePicker: false,
	multiCalendars: false,
	format: 'yyyy-MM-dd hh:mm:ss',
});
const emit = defineEmits(['update:modelValue']);
const { errors } = useField(toRef(props, 'name'), undefined);

const value = computed({
	get() {
		return props.modelValue;
	},
	set(value:any) {
		const date:Array<string> = [];
		if (null != value) {
			value.forEach((elem:any) => {
				date.push(dayjs(elem).format('YYYY-MM-DD HH:mm:ss'))
			});
		}
		emit('update:modelValue', date.length === 0 ? value : date);
	}
});

const dateRangePicker = ref<any>(null);
const closeMenuAction = () => {
	if (dateRangePicker.value) {
		dateRangePicker.value.closeMenu();
	}
};
const selectDateAction = () => {
	if (dateRangePicker.value) {
		dateRangePicker.value.selectDate();
	}
};
</script>

<style scoped lang="scss">
.m-date-range-picker {
	&__errors {
		min-height: 25px;
		padding: 3px 15px;
		font-size: $size-12;
		color: $secondary-550;
		text-transform: lowercase;
		
		&::first-letter {
			text-transform: uppercase;
		}
	}
	
	&__hide-time-picker {
		&:deep(.dp__button) {
			display: none;
		}
	}
	
	&__custom-buttons {
		display: flex;
		align-items: center;
		gap: 10px;
	}
	
	&:deep(.dp__calendar_header_item) {
		font-size: 9px;
		font-weight: 400;
	}
	
	&:deep(.dp__cell_inner) {
		font-size: 14px;
		font-weight: 400;
	}
	
	&:deep(.dp__month_year_select) {
		font-size: 15px;
		font-weight: 400;
	}
	
	&:deep(.dp__today) {
		border: 1px solid $primary-400;
	}
	
	&:deep(.dp__overlay_cell_active) {
		background: $primary-400;
	}
	
	&:deep(.dp__range_start) {
		background: $primary-400;
	}
	
	&:deep(.dp__range_end) {
		background: $primary-400;
	}
	
	&:deep(.dp__action_select) {
		background: $primary-400;
		color: $white-100;
	}
}
</style>
