<template>
	<div v-if="user" class="header-switcher">
		<MMenu offset="5px 0px" no-shadow open-on-click>
			<template v-slot:menuActivator>
				<div class="header-switcher__activator">
					<div>{{ isAdvertiser(user) ? $t('layout.header.advertiserPanel') : $t('layout.header.publisherPanel') }}</div>
					<MIcon icon="arrow-down" :color="black" width="16" height="16" />
				</div>
			</template>
			<template v-slot:menuItems>
				<div class="header-switcher__select-items">
					<div v-if="isAdvertiser(user)" @click="changeMode">{{ $t('layout.header.publisherPanel') }}</div>
					<div v-if="!isAdvertiser(user)" @click="changeMode">{{ $t('layout.header.advertiserPanel') }}</div>
				</div>
			</template>
		</MMenu>
	</div>
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { isAdvertiser } from '@/hooks/UserHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import MMenu from '@/components/atoms/MMenu.vue';
import MIcon from '@/components/atoms/MIcon.vue';

const { user } = storeToRefs(useUserStore());
const { black } = variables;

const changeMode = () => {
	if (null == user.value) {
		return;
	}
	
	if (isAdvertiser(user.value)) {
		window.location.href = `http://${window.location.host}/switch/publisher`;
	} else {
		window.location.href = `http://${window.location.host}/switch/advertiser`;
	}
}
</script>

<style scoped lang="scss">
.header-switcher {
	margin-left: 10px;
	
	&__activator {
		font-size: 14px;
		font-weight: 600;
		display: flex;
		align-items: center;
		cursor: pointer;
		background-color: $grey-250;
		border-radius: 8px;
		padding: 9.5px 10px;
		gap: 10px;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__select-items {
		background-color: $grey-250;
		border-radius: 8px;
		font-size: 14px;
		font-weight: 600;
		padding: 7.5px 10px;
		min-width: 176px;
		cursor: pointer;
		transition: background-color 0.3s, color 0.3s;
		
		&:hover {
			background-color: $primary-400;
			color: $white-100;
		}
	}
}
</style>
