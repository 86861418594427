<template>
	<div class="default">
		<div
			class="default__layout-wrapper"
			:class="{
				'default--hide': isMenuHide,
				'default--hide-animation': true === isMenuHideShowAnimation,
				'default--show-animation': false === isMenuHideShowAnimation,
			}"
		>
			
			<div
				v-show="isMouseOnMenu || isMenuHide"
				class="default__hide-menu-icon-wrapper"
				@mouseenter="handleMouseEnterAction(true)"
				@mouseleave="handleMouseLeaveAction(true)"
				@click="toggleShowMenu"
				:class="{
					'default__hide-menu-icon-wrapper--hide': isMenuHide,
					'default__hide-menu-icon-wrapper--hide-animation': true === isMenuHideShowAnimation,
					'default__hide-menu-icon-wrapper--show-animation': false === isMenuHideShowAnimation,
				}"
			>
				<MIcon icon="arrow-down" width="10" height="10" />
			</div>
			<SideMenu @mouseEnterAction="handleMouseEnterAction" @mouseLeaveAction="handleMouseLeaveAction" :is-menu-hidden="isMenuHide" />
			<div
				class="default__layout-content-wrapper"
				:class="{
					'default__layout-content-wrapper--hide': isMenuHide,
					'default__layout-content-wrapper--hide-animation': true === isMenuHideShowAnimation,
					'default__layout-content-wrapper--show-animation': false === isMenuHideShowAnimation,
				}"
			>
				<Header />
                <RouterView class="page-inner" :key="router.currentRoute.value.fullPath" />
				<Footer />
			</div>
		</div>
        
        <MDialog
            :is-open-dialog-action="isOpenNewAppVersionDialog"
            :is-close-dialog-action="isCloseNewAppVersionDialog"
            is-select-footer-btn
            :content-title="`${$t('components.newAppVersion')}`"
            :close-btn="false"
            close-header-btn
            is-large-font-size-title
            @closeDialog="closeAction()"
        >
            <template v-slot:default>
                <div class="default__new-app-wrapper">
                    <div>{{ $t('components.newAppVersionInfo') }}</div>
                    <div class="default__btn-wrapper">
                        <MButton big blue400 :label="$t('components.close')" @click="closeAction()" />
                        <MButton big red400 :label="$t('components.refresh')" @click="refreshBrowserAction()" />
                    </div>
                </div>
            </template>
        </MDialog>
		
		<MDialog
			:is-open-dialog-action="isOpenConsentModeDialog"
			:is-close-dialog-action="isCloseConsentModeDialog"
			is-select-footer-btn
			:content-title="`${$t('auth.consentsPopupTitle')}`"
			:close-btn="false"
			close-header-btn
			@closeDialog="closeConsentAction"
		>
			<template v-slot:default>
				<div v-if="isChangeContent === 1" class="default__consent-back-wrapper">
					<MIcon icon="sad" height="80" width="80" />
					<div>{{ $t('auth.consentCryDescription') }}</div>
				</div>
				<div v-if="isChangeContent === 0 || isChangeContent === 1">
					<div class="default__description-margin">{{ $t('auth.consentsPopupDescription') }}</div>
					<div v-if="user?.seen_terms === 2" class="default__green">{{ $t('auth.consentsPopupDiscountDescription') }}</div>
					<div class="default__consent-border">
						<div class="default__subtitle">{{ $t('profile.marketingAgreement') }}</div>
						<div>{{ $t('profile.marketingDescription') }}</div>
					</div>
					
					<div class="default__consent-btn-wrapper">
						<MButton
							v-if="isChangeContent === 0"
							id="change-consent-mode-btn"
							@click="changeDialogContent"
							normal
							red400
							:label="$t('components.reject')"
						/>
						<MButton
							v-if="isChangeContent === 1"
							id="close-consent-mode-btn"
							@click="closeConsentAction"
							normal
							red400
							:label="$t('components.reject')"
						/>
						<MButton
							v-if="isChangeContent === 0 || isChangeContent === 1"
							id="submit-consent-mode-btn"
							@click="saveConsentMode"
							normal
							green400
							:loading="consentLoading"
							:label="$t('components.accept')"
						/>
					</div>
				</div>
				<div v-if="isChangeContent === 2">
					<div class="default__consent-code-wrapper">
						<div>{{ $t('auth.consentsPopupCodeInfo') }}</div>
						<div class="default__green default__code">{{ termsDiscountCode }}</div>
					</div>
				</div>
			</template>
		</MDialog>
	</div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { RouterView } from 'vue-router';
import router from '@/router';
import versionText from '../../../../../VERSION.json';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@/stores/session';
import { useBooleanFlagStore } from '@/stores/booleanFlag';
import { useUserStore } from '@/stores/user';
import { isAdmin, isContentWriter, isModerator, MODERATOR_ID } from '@/hooks/UserHooks';
import dayjs from 'dayjs';
import { type TAuthorizationPayload, useAuthStore } from '@/stores/auth';
import AuthApi from '@/api/v1/AuthApi';
import UserApi from '@/api/v1/UserApi';
import { useGoogleStore } from '@/stores/google';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n'
import {
	isProfileLocked,
	checkAddressProfile,
	CONSENTS_MARKETING,
	CONSENTS_PARTNERS
} from '@/hooks/ProfileHooks';
import { SESSION_EXPIRES_IN } from '@/hooks/SessionHooks';
import { ROUTE_ORDER_PUBLICATION, ROUTE_ORDER_SELECT_CAMPAIGN } from '@/hooks/RoutingHooks';
import { checkIsGoToPreparedCartAction } from '@/hooks/CartHooks';
import MDialog from '@/components/atoms/MDialog.vue';
import MButton from '@/components/atoms/MButton.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import Footer from '@/views/Layout/Footer.vue';
import SideMenu from '@/views/Layout/SideMenu.vue';
import Header from '@/views/Layout/Header.vue';

const authApi = new AuthApi();
const userApi = new UserApi();

const { t } = useI18n();
const { setIsPromoBtnClicked } = useBooleanFlagStore();

const isOpenNewAppVersionDialog = ref<boolean>(false);
const isOpenConsentModeDialog = ref<boolean>(false);
const isCloseNewAppVersionDialog = ref<boolean>(true);
const isCloseConsentModeDialog = ref<boolean>(true);
const isConsentsMarketingCheckbox = ref<boolean>(true);
const isConsentsPartnersCheckbox = ref<boolean>(true);
const consentLoading = ref<boolean>(false);
const isChangeContent = ref<number>(0);
const termsDiscountCode = ref<string|null>(null);

const closeAction = () => {
    isOpenNewAppVersionDialog.value = false;
    isCloseNewAppVersionDialog.value = true;
};

const closeConsentAction = () => {
	if (3 !== user.value?.seen_terms) {
		seenTermsPoppupAction();
	}
	localStorage.setItem('consentPopup', 'true');
	isOpenConsentModeDialog.value = false;
	isCloseConsentModeDialog.value = true;
};
const refreshBrowserAction = () => {
    location.reload();
};

const { version } = storeToRefs(useSessionStore());
let refreshInterval:any = undefined;
const refreshBrowserActionCheck = () => {
    if (version.value !== versionText.version) {
        isOpenNewAppVersionDialog.value = true;
        isCloseNewAppVersionDialog.value = false;

        refreshInterval = setInterval(() => {
            isOpenNewAppVersionDialog.value = true;
            isCloseNewAppVersionDialog.value = false;
        }, 60000);
    }
};

onMounted(() => {
    refreshBrowserActionCheck();
});

const { user } = storeToRefs(useUserStore());

const getRefreshToken = async () => {
    const result = await authApi.refreshToken();
    const { apiToken } = storeToRefs(useAuthStore());
    const { setAuthorization } = useAuthStore();

    const authCredentials: TAuthorizationPayload = {
        api_token: apiToken.value,
        access_token: result.payload.access_token,
        expires_in: result.payload.expires_in,
        token_type: result.payload.token_type,
    };
    await setAuthorization(authCredentials);
	// To ponizej do odkomentowania jezeli expires in byloby brane z backendu a obecnie backend zwraca zawsze 0, jakbysmy chcieli z backendu to ustawiac to 2 linie ponizej do wywalenia
    // refreshAction(localStorage.getItem('expiresIn'));
    refreshAction(SESSION_EXPIRES_IN);
}

const refreshAction = (expiresIn:string|null) => {
    const routesNameBeforeLoginArray: Array<string|symbol> = [];
    if (null == expiresIn) {
        return;
    }
    router.options.routes.forEach(elem => {
        if (null == elem.name) {
            return;
        }
        routesNameBeforeLoginArray.push(elem.name);
    });
    const expiresInDate = dayjs(expiresIn).format('YYYY-MM-DD hh:mm:ss');
    const now = dayjs().format('YYYY-MM-DD hh:mm:ss');
    const removeOneMinute = dayjs(expiresInDate).subtract(60, 'second').format('YYYY-MM-DD hh:mm:ss');
    let diff = dayjs(removeOneMinute).diff(now, 'second');
    if (0 >= diff) {
        diff = 300;
    }

    const { accessToken } = storeToRefs(useAuthStore());
    const refreshInterval = setInterval(() => {
        // @ts-ignore
        if (null != accessToken.value && !routesNameBeforeLoginArray.includes(router.currentRoute.value.name)) {
            clearInterval(refreshInterval);
            getRefreshToken();
        }
    }, diff * 100);
};

const checkUserProfile = () => {
    return (
		null != user.value &&
	    (!isProfileLocked(user.value) || checkAddressProfile(user.value)) &&
        '/partners' !== window.location.pathname &&
        (!isAdmin(user.value) && !isModerator(user.value) && !isContentWriter(user.value))
    );
}

const checkConsents = () => {
	if (null != user.value && !isAdmin(user.value) && !isModerator(user.value) && !isContentWriter(user.value) && 3 !== user.value.seen_terms) {
		const consentMarketing = user.value.terms.find(elem => elem.slug === CONSENTS_MARKETING);
		
		if (null != consentMarketing) {
			isConsentsMarketingCheckbox.value = true;
		} else {
			isConsentsMarketingCheckbox.value = false;
		}
		
		if (null == consentMarketing && null == localStorage.getItem('consentPopup')) {
			setTimeout(() => {
				isOpenConsentModeDialog.value = true;
				isCloseConsentModeDialog.value = false;
			}, 1);
		}
	}
};

const checkIsProfileValidate = () => {
    if (checkUserProfile()) {
        router.push('/profile');
    } else {
		checkConsents();
	    // To ponizej do odkomentowania jezeli expires in byloby brane z backendu a obecnie backend zwraca zawsze 0, jakbysmy chcieli z backendu to ustawiac to 2 linie ponizej do wywalenia
	    // refreshAction(localStorage.getItem('expiresIn'));
	    // TO ponizej do wywalenia docelowo jest to frontowe ogranie expires in poniewaz leci 0 w expires in z backendu dlatego samemu nadaje dlugosc sesji
	    // refreshAction(SESSION_EXPIRES_IN);
	    checkIsGoToPreparedCartAction();
    }
};

checkIsProfileValidate();
watch(() => router.currentRoute.value.fullPath, (newValue, oldValue) => {
        if (checkUserProfile()) {
            router.push('/profile');
            toast.success(`${t('layout.toast.completeProfile')}`);
        }
        if ('/article/create/upload/google-drive' !== newValue) {
            const { setIsSelectedGoogleFiles } = useGoogleStore();
            setIsSelectedGoogleFiles(false);
        }
    },
    {
        deep: true,
    }
);

const saveConsentMode = async() => {
	consentLoading.value = true;
	
	if (null == user.value) {
		consentLoading.value = false;
		return;
	}
	
	const consentPartners = user.value.terms.find(elem => elem.slug === CONSENTS_PARTNERS);
	
	if (null != consentPartners) {
		isConsentsPartnersCheckbox.value = true;
	} else {
		isConsentsPartnersCheckbox.value = false;
	}
	
	const consentsForm:any = {
		'terms-and-condition': true,
		'marketing-agreement': true,
		'partners-agreement': isConsentsPartnersCheckbox.value,
	};
	try {
		const result = await userApi.editTerms(consentsForm, user.value.uuid);
		if (!result.isSuccess) {
			toast.error(`${t('profile.toast.errorUpdateTerms')}`);
			consentLoading.value = false;
			return;
		}
		toast.success(`${t('profile.toast.successUpdateTerms')}`);
		if (2 === user.value.seen_terms) {
			seenTermsPoppupAction();
			isChangeContent.value = 2;
		} else {
			closeConsentAction();
		}
	} catch (e) {
		toast.error(`${t('profile.toast.errorUpdateTerms')}`);
		consentLoading.value = false;
		return;
	}
	consentLoading.value = false;
};

const changeDialogContent = () => {
	isChangeContent.value = 1;
};

const backToConsents = () => {
	isChangeContent.value = 0;
};

const seenTermsPoppupAction = async() => {
	try {
		const termsPopupForm:any = {
			seen_terms: true,
		};
		const result = await userApi.updateProfile(termsPopupForm);
		if (!result.isSuccess) {
			return;
		}
		termsDiscountCode.value = result.payload.code;
	} catch (e) {
		return;
	}
};

const isMouseOnMenu = ref<boolean>(false);
const handleMouseEnterAction = (isMouseIn: boolean) => {
	if (isMouseIn) {
		isMouseOnMenu.value = true;
	}
};
const handleMouseLeaveAction = (isMouseOut: boolean) => {
	if (isMouseOut) {
		isMouseOnMenu.value = false;
	}
};

const isMenuHide = ref<boolean>(localStorage.getItem('isMenuHide') === '1');
const isMenuHideShowAnimation = ref<boolean | null>(null);
const toggleShowMenu = () => {
	if (isMenuHide.value) {
		localStorage.removeItem('isMenuHide');
		isMenuHideShowAnimation.value = false;
		setTimeout(() => {
			isMenuHide.value = false;
			isMenuHideShowAnimation.value = null;
		}, 400);
	} else {
		localStorage.setItem('isMenuHide', '1');
		isMenuHideShowAnimation.value = true;
		setTimeout(() => {
			isMenuHide.value = true;
			isMenuHideShowAnimation.value = null;
		}, 400);
	}
};

watch(() => router.currentRoute.value.name, (val) => {
		if (val !== ROUTE_ORDER_SELECT_CAMPAIGN && val !== ROUTE_ORDER_PUBLICATION) {
			setIsPromoBtnClicked(false);
		}
	},
	{
		deep: true,
	}
);
</script>

<style scoped lang="scss">
.page-inner {
    background-color: $grey-150;
	height: 100%;

    @include media-breakpoint-up(md) {
        padding: 0 30px 30px;
    }
}

.default {
	&:deep(.m-dialog button) {
		display: none;
	}
	
	
	&--hide {
		&:deep(.side-menu) {
			@include media-breakpoint-up(xxl) {
				left: 0;
			}
		}
	}
	
	&--hide-animation {
		&:deep(.side-menu) {
			@include media-breakpoint-up(xxl) {
				animation: hideAnimationMenu 0.5s;
				
				@keyframes hideAnimationMenu {
					from {
						left: 0;
					}
					
					to {
						left: -250px;
					}
				}
			}
		}
	}
	
	&--show-animation {
		&:deep(.side-menu) {
			@include media-breakpoint-up(xxl) {
				animation: showAnimationMenu 0.5s;
				
				@keyframes showAnimationMenu {
					from {
						width: 50px;
					}
					
					to {
						width: 300px;
					}
				}
			}
		}
	}
	
	&__layout-wrapper {
		display: flex;
	}
	
	&__hide-menu-icon-wrapper {
		display: none;
		
		@include media-breakpoint-up(xxl) {
			position: fixed;
			top: 10px;
			left: 285px;
			background-color: $white-100;
			border: 1px solid $grey-300;
			box-shadow: 0 0 8px $primary-400;
			border-radius: 50%;
			width: 30px;
			height: 30px;
			z-index: 6;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transform: rotateZ(90deg);
			transition: 0.3s;
			
			&:hover {
				background-color: $primary-400;
				border-color: $white-100;
				box-shadow: 0 0 8px $white-100;
				
				svg {
					fill: $white-100;
				}
			}
		}
		
		&--hide {
			@include media-breakpoint-up(xxl) {
				left: 36px;
				transform: rotateZ(-90deg);
			}
		}
		
		&--hide-animation {
			@include media-breakpoint-up(xxl) {
				animation: hideAnimationIcon 0.5s;
				
				@keyframes hideAnimationIcon {
					from {
						left: 285px;
						transform: rotateZ(90deg);
					}
					
					to {
						left: 36px;
						transform: rotateZ(-90deg);
					}
				}
			}
		}
		
		&--show-animation {
			@include media-breakpoint-up(xxl) {
				animation: showAnimationIcon 0.5s;
				
				@keyframes showAnimationIcon {
					from {
						left: 36px;
						transform: rotateZ(-90deg);
					}
					
					to {
						left: 285px;
						transform: rotateZ(90deg);
					}
				}
			}
		}
	}
	
	&__layout-content-wrapper {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		width: calc(100% - 50px);
		margin-left: auto;
		
		@include media-breakpoint-up(xxl) {
			width: calc(100% - 300px);
		}
		
		&--hide {
			@include media-breakpoint-up(xxl) {
				width: calc(100% - 50px);
			}
		}
		
		&--hide-animation {
			@include media-breakpoint-up(xxl) {
				animation: hideAnimation 0.5s;
				
				@keyframes hideAnimation {
					from {
						width: calc(100% - 300px);
					}
					
					to {
						width: calc(100% - 50px);
					}
				}
			}
		}
		
		&--show-animation {
			@include media-breakpoint-up(xxl) {
				animation: showAnimation 0.5s;
				
				@keyframes showAnimation {
					from {
						width: calc(100% - 50px);
					}
					
					to {
						width: calc(100% - 300px);
					}
				}
			}
		}
	}

    &__new-app-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
    }

    &__btn-wrapper {
        margin-top: 20px;
        display: flex;
        gap: 10px;

        button {
            border-radius: 8px !important;
            padding: 20px 35px !important;
        }
    }
	
	&__consent-btn-wrapper {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-top: 10px;
	}
	
	&__consent-back-wrapper {
		display: flex;
		flex-direction: column;
		gap: 5px;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		color: $primary-400;
		text-align: center;
	}
	
	&__green {
		color: $green-400;
		font-weight: 700;
	}
	
	&__description-margin {
		margin: 10px 0;
	}
	
	&__subtitle {
		font-size: 16px;
		color: $grey-400;
		margin-bottom: 10px;
		font-weight: 700;
	}
	
	&__consent-border {
		border: 1px solid $primary-400;
		border-radius: 8px;
		padding: 8px;
		margin-top: 10px;
	}
	
	&__consent-code-wrapper {
		text-align: center;
		padding: 25px 0;
	}
	
	&__code {
		font-size: 16px;
		margin-top: 10px;
	}
}
</style>
