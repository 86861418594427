<template>
    <div class="m-dialog">
        <v-dialog v-model="dialog" :width="null != width ? width : isSelectFooterBtn ? 900 : 500">
            <template v-slot:activator="{ props }">
                <v-btn v-if="isOpenDialogAction && isActivatorBtn" :color="defaultActivatorColorBtn" v-bind="props" :class="{'dialog__select-footer-btn': isSelectFooterBtn}">
                    {{ defaultActivatorTitleBtn }}
                </v-btn>
            </template>

            <v-card :class="{'card-font-large': isLargeFontSizeTitle}">
                <v-card-title class="text-h5 lighten-2 dialog__primary header-title-wrapper" :class="{'header-title-wrapper--campaign': isCampaignPlug}" v-if="contentHeader">
                    <div>{{ dynamicContentTitle }} {{ null != dynamicContentTitle ? null : defaultContentTitle }} <span>{{ contentSecondTitle }}</span></div>
                    <div v-if="closeHeaderBtn" @click="dialog = false" class="close-header-btn">
                        <MIcon icon="close" />
                    </div>
                </v-card-title>

                <v-card-text :style="isCampaignPlug ? 'background-color: #F2226E; margin-bottom: -1px;' : ''">
                    <slot></slot>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions v-if="closeBtn">
                    <v-spacer></v-spacer>
                    <v-btn :color="defaultCloseColorDialogBtn" @click="dialog = false">
                        {{ defaultCloseTitleBtn }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, ref, withDefaults, watch } from 'vue';
import MIcon from '@/components/atoms/MIcon.vue';
import variables from '@/assets/scss/modules/variables.module.scss';
import { useI18n } from 'vue-i18n'

const dialog = ref(false);
const emit = defineEmits(['closeDialog']);

interface IOwnProps {
    isOpenDialogAction: boolean
    isCloseDialogAction: boolean
    contentTitle?: string
	dynamicContentTitle?: string
    isActivatorBtn?: boolean
    activatorColorBtn?: string
    closeColorDialogBtn?: string
    activatorTitleBtn?: string
    contentHeader?: boolean
    contentSecondTitle?: string
    closeTitleBtn?: string
    closeBtn?: boolean
    isSelectFooterBtn?: boolean
    closeHeaderBtn?: boolean
    isLargeFontSizeTitle?: boolean
	width?: number | string
	isCampaignPlug?: boolean
}
interface IProps extends IOwnProps {
}

const { primary400, secondary400 } = variables;
const { t } = useI18n();

const props = withDefaults(defineProps<IProps>(), {
    contentHeader: true,
    closeBtn: false,
    isSelectFooterBtn: false,
    isOpenDialogAction: false,
    isCloseDialogAction: false,
    closeHeaderBtn: false,
    isLargeFontSizeTitle: false,
    isActivatorBtn: false,
});

const defaultActivatorColorBtn = props.activatorColorBtn || primary400;
const defaultCloseColorDialogBtn = props.closeColorDialogBtn || secondary400;
const defaultActivatorTitleBtn = props.activatorTitleBtn || `${t('components.click')}`;
const defaultContentTitle = props.contentTitle || `${t('components.title')}`;
const defaultCloseTitleBtn = props.closeTitleBtn || `${t('components.cancel')}`;

const openDialogAction = () => {
    dialog.value = true;
}

watch(props, (val) => {
    if (val.isOpenDialogAction) {
        openDialogAction();
    }
    if (val.isCloseDialogAction) {
        closeDialogAction();
    }
});

const closeDialogAction = () => {
    dialog.value = false;
}

watch((dialog), (val) => {
    if (!val) {
        emit('closeDialog');
    }
});

</script>

<style scoped lang="scss">
.dialog {
    &__primary {
        background-color: $primary-300;
        color: $white-100;
        margin-bottom: 10px;
    }

    &__select-footer-btn {
        margin-left: 15px;
    }
}

.header-title-wrapper {
    display: flex;
    align-items: baseline;
	justify-content: space-between;

    span {
        font-weight: 700;
        margin-left: 5px;
    }
	
	&--campaign {
		background-color: $secondary-400;
		margin-bottom: 0;
	}
}

.card-font-large {
    div:nth-child(1) {
        font-size: 27px;
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: space-between;
    }
    div:nth-child(2) {
        font-size: 18px;
	    padding: 9px 8px 9px 15px;
    }
}

.close-header-btn {
    margin-left: auto;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
        opacity: 0.5;
    }
}
</style>
