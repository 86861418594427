<template>
	<div class="optimalization-contadu-article">
		<HeadingLabel
			:text="$t('contadu.optimalizeArticle')"
			without-icon
			:btn="null != items && null != articleContent && CONTADU_STATUS_READY === items.status"
			:btn-text="$t('contadu.save')"
			green400
			:is-button-click="save"
		>
			<template v-slot:textBefore>
                <span class="optimalization-contadu-article__contadu-img">
                    <img src="~@/assets/images/contadu.png" alt="contadu">
                </span>
			</template>
		</HeadingLabel>
		
		<div v-if="null != items && (CONTADU_STATUS_WAITING === items.status || CONTADU_STATUS_IN_PROGRESS === items.status)">
			<ContaduLoader />
		</div>
		
		<div v-if="null != items && null != articleContent && CONTADU_STATUS_READY === items.status">
			<ContaduOptimalization v-model="articleContent" :items="items" />
		</div>
		
		<div class="optimalization-contadu-article__btn-wrapper">
			<MButton
				v-if="null != items && null != articleContent && CONTADU_STATUS_READY === items.status"
				normal
				green400
				width100
				:label="$t('contadu.save')"
				@click="save"
			/>
			<a href="https://contadu.com/" target="_blank" class="optimalization-contadu-article__powered">{{ $t('contadu.poweredByContadu') }}</a>
		</div>
		
		<MLoading v-show="loading || articleDetailsLoading || contaduDetailsLoading" />
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { CONTADU_STATUS_WAITING, CONTADU_STATUS_IN_PROGRESS, CONTADU_STATUS_READY } from '@/hooks/ArticlesHooks';
import type { IArticleDetailsResponse, IEditArticleRequest } from '@/models/ArticlesModel';
import type { IContaduOptimalization } from '@/models/ContaduModel';
import ArticleApi from '@/api/v1/ArticleApi';
import ContaduApi from '@/api/v1/ContaduApi';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import ContaduLoader from '@/views/Articles/components/ContaduLoader.vue';
import ContaduOptimalization from '@/views/Articles/components/ContaduOptimalization.vue';
import MButton from '@/components/atoms/MButton.vue';
import MLoading from '@/components/atoms/MLoading.vue';

const articleApi = new ArticleApi();
const contaduApi = new ContaduApi();

const { t } = useI18n();

const articleDetailsLoading = ref<boolean>(false);
const contaduDetailsLoading = ref<boolean>(false);
const loading = ref<boolean>(false);
const articleUuid = ref<string|Array<string>>('');
const contaduId = ref<string|Array<string>>('');
const items = ref<IContaduOptimalization|null>(null);
const form = ref<IArticleDetailsResponse|any>(null);
const articleContent = ref<string>('');

if (null != router.currentRoute.value.params.articleUuid && null != router.currentRoute.value.params.optimalizeId) {
	articleUuid.value = router.currentRoute.value.params.articleUuid;
	contaduId.value = router.currentRoute.value.params.optimalizeId;
} else {
	router.push('/article');
	toast.error(`${t('articles.toast.errorUrl')}`);
}

const articleDetails = async() => {
	articleDetailsLoading.value = true;
	try {
		const result = await articleApi.articleDetails(articleUuid.value);
		if (!result.isSuccess) {
			toast.error(`${t('articles.toast.errorArticleDetails')}`);
			articleDetailsLoading.value = false;
			return;
		}
		articleContent.value = result.payload.content;
		form.value = result.payload;
	} catch (e) {
		toast.error(`${t('articles.toast.errorArticleDetails')}`);
		articleDetailsLoading.value = false;
		return;
	}
	articleDetailsLoading.value = false;
};
articleDetails();

const contaduDetails = async() => {
	contaduDetailsLoading.value = true;
	try {
		const result = await contaduApi.optimalizationDetails(contaduId.value);
		if (!result.isSuccess) {
			toast.error(`${t('contadu.toast.wait')}`);
			contaduDetailsLoading.value = false;
			return;
		}
		items.value = result.payload;
	} catch (e) {
		toast.error(`${t('contadu.toast.wait')}`);
		contaduDetailsLoading.value = false;
		return;
	}
	contaduDetailsLoading.value = false;
};
contaduDetails();

const checkIsOptimalizationReady = () => {
	let intervalCounter = 0;
	if (null == items.value || CONTADU_STATUS_WAITING === items.value.status || CONTADU_STATUS_IN_PROGRESS === items.value.status) {
		const interval = setInterval(() => {
			if (10 === intervalCounter && (null != items.value && (CONTADU_STATUS_WAITING === items.value.status || CONTADU_STATUS_IN_PROGRESS === items.value.status))) {
				clearInterval(interval);
				router.push('/article');
				toast.error(`${t('contadu.toast.backLater')}`);
			}
			if (null != items.value && CONTADU_STATUS_READY === items.value.status) {
				clearInterval(interval);
			}
			if (10 !== intervalCounter && (null != items.value && (CONTADU_STATUS_WAITING === items.value.status || CONTADU_STATUS_IN_PROGRESS === items.value.status))) {
				contaduDetails();
				intervalCounter++;
			}
		}, 15000);
	}
};
checkIsOptimalizationReady();
const save = async() => {
	loading.value = true;
	if (null == form.value) {
		loading.value = false;
		return;
	}
	
	const articleForm:IEditArticleRequest = {
		campaign_id: form.value.campaign_id,
		language_id: form.value.language_id,
		title: form.value.title,
		content: articleContent.value,
		publisher_note: form.value.publisher_note,
		footer_id: form.value.footer_id
	}
	try {
		const result = await articleApi.editArticle(articleUuid.value, articleForm);
		if (!result.isSuccess) {
			toast.error(`${t('articles.toast.errorEditArticle')}`);
			loading.value = false;
			return;
		}
		articleContent.value = result.payload.content;
		
		await router.push('/article');
		toast.success(`${t('articles.toast.successEdit')}`);
	} catch (e) {
		toast.error(`${t('articles.toast.errorEditArticle')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
</script>

<style scoped lang="scss">
.optimalization-contadu-article {
	&__contadu-img {
		img {
			width: 200px;
			margin-bottom: -10px;
			margin-left: 10px;
		}
	}
	
	&__btn-wrapper {
		margin-top: 5px;
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
	}
	
	&__powered {
		margin-top: 5px;
		font-size: $size-14;
		font-weight: 700;
		color: $primary-400;
		text-decoration: none;
	}
}
</style>
