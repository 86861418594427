import BaseApi from '@/api/BaseApi';
import type {
	CampaignInterface,
	ICampaignDetails,
	ICampaignFooterList,
	ICampaignsList,
	ICreateEditCampaignForm
} from '@/models/CampaignModel';

export default class CampaignApi extends BaseApi {
    async getCampaigns (isWithOrdersOnly?: boolean) {
		let url = '/api/v1/campaigns';

		if (isWithOrdersOnly) {
			url += '?with-orders-only=1';
		}

        return this.get<ICampaignsList>({
            url: url,
        });
    };

    async campaignsFooters (campaignUuid: string|Array<any>, page?:number, perPage?:number) {
	    let url = `/api/v1/campaigns/${campaignUuid}/footers`;

		if (page && perPage) {
			url += `?page=${page}&per_page=${perPage}`;
		}
        return this.get<ICampaignFooterList>({
            url: url,
        });
    };

	async campaignList (page:number, perPage:number, sortBy?: string|null, sortDesc?: string|null, search?:string|null, userUuid?:string|null, isDashboardView?:boolean) {
		let url = `/api/v1/campaigns?page=${page}&per_page=${perPage}`;

		if (null != sortBy) {
			url += `&sort=${sortBy}`;
		} else {
			url += '&sort=updated_at';
		}

		if (null != sortDesc) {
			url += `&order=${sortDesc}`;
		} else {
			url += '&order=desc';
		}

		if (null != search) {
			url += `&phrase=${search}`;
		}

		if (null != userUuid) {
			url += `&user=${userUuid}`;
		}

		if (isDashboardView) {
			url += '&preview=1';
		}
		return this.get<ICampaignsList>({
			url: url,
		});
	};

	async createCampaign (params: ICreateEditCampaignForm) {
		return this.post<ICreateEditCampaignForm, any>({
			url: '/api/v1/campaigns',
			data: params,
		});
	};

	async editCampaign (params: ICreateEditCampaignForm, campaignUuid: string|Array<string>) {
		return this.put<ICreateEditCampaignForm, any>({
			url: `/api/v1/campaigns/${campaignUuid}`,
			data: params,
		});
	};

	async singleCampaign (campaignUuid: string|Array<string>) {
		return this.get<ICampaignDetails>({
			url: `/api/v1/campaigns/${campaignUuid}`,
		});
	};

	async userCampaigns (userId: number) {
		return this.get<Array<CampaignInterface>>({
			url: `/api/v1/campaigns/client/${userId}`,
		});
	};
};
