export const PUBLICATION_REQUEST = 'App\\Models\\PublicationRequest';
export const ARTICLE_REQUEST = 'App\\Models\\ArticleRequest';
export const DEPOSIT_REQUEST = 'App\\Models\\DepositRequest';
export const WITHDRAW_TYPE = 'App\\Models\\Withdrawal';
export const TRANSFER_TYPE = 'App\\Models\\WithdrawalBetweenAccount';
export const ORDER_TYPE = 'App\\Models\\Order';
export const MANUAL_TYPE = '';
export const REFLINK_TYPE = 'reflink';
export const INCOME = 'income';
export const INCOME_PENDING = 'income-pending';
export const OUTCOME = 'outcome';
export const OUTCOME_PENDING = 'outcome-pending';
export const WITHDRAWAL_FAILED = 'withdraw-failed';
export const WITHDRAWAL_PENDING = 'withdraw-pending';
export const WITHDRAWAL_COMPLETED = 'withdraw-completed';
export const WITHDRAWAL_DECLINE = 'withdraw-decline';
export const PAID = 'paid';
export const REFUND = 'Refund';
export const MARKETIN9 = 'Marketin9';
export const AGENCY = 'agency';

// ORDER LIST WITH INVOICES
export const ORDER_STATUS_DRAFT = 'draft';
export const ORDER_STATUS_REFUND = 'refund';
export const ORDER_STATUS_PENDING = 'pending';
export const ORDER_STATUS_DONE = 'done';

export const ORDER_WALLET = 'wallet';

// ARTICLE TYPE
export const ARTICLE_ID_STANDARD = 4;
export const ARTICLE_ID_TECHNICAL = 5;
export const ARTICLE_ID_PREMIUM = 6;

// DECISION WHEN ORDER FLOW
export const DECISION_APPROVE = 'Approve';
export const DECISION_REJECT = 'Reject';

// PUBLICATION PERIOD TIME
export const PUBLICATION_PERIOD_LIFETIME = 1;
export const PUBLICATION_PERIOD_BELOW_12_MONTHS = 12;
export const PUBLICATION_PERIOD_12_MONTHS = 13;
export const PUBLICATION_PERIOD_24_MONTHS = 14;

// LANGUAGES
export const GERMANY_LANGUAGE_ID = 6;

// COUNTRIES
export const SWEDEN_COUNTRY_ID = 752;
export const AUSTRIAN_COUNTRY_ID = 40;
export const GERMANY_COUNTRY_ID = 276;

// ORDER STATUSES
export const ORDER_DRAFT = 'waiting';

// OFFER FEED OPTIONS
export const OPTION_ORDER_ARTICLE_M9 = 'm9-article';
export const OPTION_OWN_ARTICLE = 'own-article';
export const OPTION_ADD_ARTICLE = 'add-article';
export const OPTION_ORDER_ARTICLE_BY_PUBLISHER = 'publisher-article';
export const OPTION_TO_CHOOSE = 'to-choose';

// OFFER FEED PUBLICATION TYPE
export const ARTICLE_WRITE_OPTION_OWN = 'own';
export const ARTICLE_WRITE_OPTION_M9 = 'marketin9';
export const ARTICLE_WRITE_OPTION_PUBLISHER = 'publisher';
export const ARTICLE_WRITE_OPTION_TO_CHOOSE = 'to_choose';

// CART SUMMARY PAYMENT METHODS
export const PAYMENT_METHOD_PAYPAL = 'paypal';
export const PAYMENT_METHOD_AUTOPAY = 'autopay';
export const PAYMENT_METHOD_WALLET = 'wallet';
