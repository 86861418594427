<template>
    <header id="header" v-if="user">
        <div class="header">
	        <div class="header__wrapper">
	            <HeaderProfile />
		        <HolidayEventHeaderButton v-if="IS_PROMO_ACTIVE && isAdvertiser(user)"/>
	            <HeaderWallet v-if="isAdvertiser(user) || isPublisher(user)" />
		        <HeaderLanguage />
	            <HeaderSwitcher v-if="isAdvertiser(user) || isPublisher(user)" />
	        </div>
        </div>
    </header>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { isAdvertiser, isPublisher } from '@/hooks/UserHooks';
import { IS_PROMO_ACTIVE } from '@/hooks/PromoHooks';
import HeaderWallet from '@/views/Layout/components/HeaderWallet.vue';
import HeaderProfile from '@/views/Layout/components/HeaderProfile.vue';
import HeaderSwitcher from '@/views/Layout/components/HeaderSwitcher.vue';
import HeaderLanguage from '@/views/Layout/components/HeaderLanguage.vue';
import HolidayEventHeaderButton from '@/components/rare/HolidayEventHeaderButton.vue';

const { user } = storeToRefs(useUserStore());

</script>

<style scoped lang="scss">
.header {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: $grey-150;
    position: relative;
    z-index: 5;
	
	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		background-color: $white-100;
		color: $primary-400;
		border-radius: 8px;
		margin: 10px;
		padding: 15px;
		
		@include media-breakpoint-up(sm) {
			gap: 0;
		}
		
		@include media-breakpoint-up(md) {
			margin: 10px 30px;
		}
		
		@include media-breakpoint-up(lg) {
			flex-direction: row;
			margin: 25px 30px;
			padding: 25px 30px;
		}
	}
}
</style>
