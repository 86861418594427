<template>
	<div v-if="items.length > 0" class="websites-offers-history-box">
		<h2 class="websites-offers-history-box__subtitle">{{ $t('websites.history') }}</h2>
		<MProgressBar v-show="loading" indeterminate height="2" />
		<div v-show="!loading" id="items-wrapper" class="websites-offers-history-box__wrapper" @scroll="handleScrollEnd">
			<div v-for="(item, index) in items" :key="index">
				<div class="websites-offers-history-box__item-wrapper">
					<div class="websites-offers-history-box__icon">{{ firstLetter(item.creator_email) }}</div>
					<div class="websites-offers-history-box__text-wrapper">
						<div class="websites-offers-history-box__top-item"><span>{{ item.creator_email }}</span> {{ $t('websites.updatedHeHer') }} <span>{{ translateFieldName(item.field_name) }}</span> {{ dateFormatWithTime(item.created_at) }}</div>
						<div class="websites-offers-history-box__bottom-item">
							<div class="websites-offers-history-box__old-val">{{ item.old_value == null || item.old_value === '' ? $t('websites.none') : item.old_value }}</div>
							<MIcon icon="arrow-down" />
							<div class="websites-offers-history-box__new-val">{{ item.new_value == null || item.new_value === '' ? $t('websites.none') : item.new_value }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import LogApi from '@/api/v2/LogApi';
import { dateFormatWithTime } from '@/hooks/DataHooks';
import { ROUTE_WEBSITE_DETAILS, ROUTE_OFFER_DETAILS } from '@/hooks/RoutingHooks';
import type { IWebsiteOffersLogList } from '@/models/LogModel';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import MIcon from '@/components/atoms/MIcon.vue';

interface IOwnProps {
	id: number
}
interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();
const { t } = useI18n();

const logApi = new LogApi();
const items = ref<IWebsiteOffersLogList[]>([]);
const loading = ref<boolean>(false);
const page = ref<number>(1);
const perPage = ref<number>(5);
const lastPage = ref<number>(1);
const logList = async() => {
	loading.value = true;
	const logType:string = ROUTE_WEBSITE_DETAILS === router.currentRoute.value.name ? 'Website' : ROUTE_OFFER_DETAILS === router.currentRoute.value.name ? 'Offer' : 'Websites';
	try {
		const result = await logApi.websiteOffersLogHistory(page.value, perPage.value, logType, props.id);
		if (!result.isSuccess) {
			toast.error(`${t('websites.toast.errorGetLogHistory')}`);
			loading.value = false;
			return;
		}
		lastPage.value = result.payload.last_page;
		result.payload.data.forEach((item) => {
			items.value.push(item);
		});
	} catch (e) {
		toast.error(`${t('websites.toast.errorGetLogHistory')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
logList();

const handleScrollEnd = () => {
	const scrollSection = document.getElementById('items-wrapper');
	if (scrollSection && scrollSection.scrollHeight - scrollSection.scrollTop === scrollSection.clientHeight && page.value < lastPage.value) {
		page.value += 1;
		perPage.value = 14;
		logList();
	}
};

const firstLetter = (email: string | null) => {
	if (null == email || '' === email) {
		return 'A';
	}
	return email.charAt(1).toUpperCase();
};

const translateFieldName = (fieldName: string | null) => {
	switch (fieldName) {
		case 'uuid':
			return `${t('websites.log.uuid')}`;
		case 'user_id':
			return `${t('websites.log.user_id')}`;
		case 'url':
			return `${t('websites.log.url')}`;
		case 'language_id':
			return `${t('websites.log.language_id')}`;
		case 'country_id':
			return `${t('websites.log.country_id')}`;
		case 'description':
			return `${t('websites.log.description')}`;
		case 'email':
			return `${t('websites.log.email')}`;
		case 'article_photo':
			return `${t('websites.log.article_photo')}`;
		case 'photo_proportions':
			return `${t('websites.log.photo_proportions')}`;
		case 'accept_proposal':
			return `${t('websites.log.accept_proposal')}`;
		case 'use_user_email':
			return `${t('websites.log.use_user_email')}`;
		case 'min_photo_size':
			return `${t('websites.log.min_photo_size')}`;
		case 'requirements':
			return `${t('websites.log.requirements')}`;
		case 'is_allowed_promotion_loans':
			return `${t('websites.log.is_allowed_promotion_loans')}`;
		case 'is_allowed_promotion_cbd':
			return `${t('websites.log.is_allowed_promotion_cbd')}`;
		case 'is_allowed_promotion_bookmakers':
			return `${t('websites.log.is_allowed_promotion_bookmakers')}`;
		case 'is_trusted':
			return `${t('websites.log.is_trusted')}`;
		case 'sample_article_url':
			return `${t('websites.log.sample_article_url')}`;
		case 'status':
			return `${t('websites.log.status')}`;
		case 'reject_messages':
			return `${t('websites.log.reject_messages')}`;
		case 'page_title':
			return `${t('websites.log.page_title')}`;
		case 'original_page_title':
			return `${t('websites.log.original_page_title')}`;
		case 'key_words':
			return `${t('websites.log.key_words')}`;
		case 'original_key_words':
			return `${t('websites.log.original_key_words')}`;
		case 'page_description':
			return `${t('websites.log.page_description')}`;
		case 'original_page_description':
			return `${t('websites.log.original_page_description')}`;
		case 'is_active':
			return `${t('websites.log.is_active')}`;
		case 'last_active_check':
			return `${t('websites.log.last_active_check')}`;
		case 'last_http_code':
			return `${t('websites.log.last_http_code')}`;
		case 'ahrefs_enabled_keywords':
			return `${t('websites.log.ahrefs_enabled_keywords')}`;
		case 'ahrefs_enabled':
			return `${t('websites.log.ahrefs_enabled')}`;
		case 'ahrefs_next_sync':
			return `${t('websites.log.ahrefs_next_sync')}`;
		case 'ahrefs_month_sync':
			return `${t('websites.log.ahrefs_month_sync')}`;
		case 'ahrefs_day_sync':
			return `${t('websites.log.ahrefs_day_sync')}`;
		case 'ahrefs_hour_sync':
			return `${t('websites.log.ahrefs_hour_sync')}`;
		case 'created_at':
			return `${t('websites.log.created_at')}`;
		case 'updated_at':
			return `${t('websites.log.updated_at')}`;
		case 'deleted_at':
			return `${t('websites.log.deleted_at')}`;
		case 'name':
			return `${t('websites.log.name')}`;
		case 'is_blocked':
			return `${t('websites.log.is_blocked')}`;
		case 'website_id':
			return `${t('websites.log.website_id')}`;
		case 'step':
			return `${t('websites.log.step')}`;
		case 'duration_id':
			return `${t('websites.log.duration_id')}`;
		case 'disclosure':
			return `${t('websites.log.disclosure')}`;
		case 'disclosure_other':
			return `${t('websites.log.disclosure_other')}`;
		case 'do_follow':
			return `${t('websites.log.do_follow')}`;
		case 'links_max_amount':
			return `${t('websites.log.links_max_amount')}`;
		case 'links_type':
			return `${t('websites.log.links_type')}`;
		case 'write_content':
			return `${t('websites.log.write_content')}`;
		case 'days_to_write':
			return `${t('websites.log.days_to_write')}`;
		case 'allow_banners':
			return `${t('websites.log.allow_banners')}`;
		case 'promo_facebook':
			return `${t('websites.log.promo_facebook')}`;
		case 'promo_twitter':
			return `${t('websites.log.promo_twitter')}`;
		case 'promo_newsletter':
			return `${t('websites.log.promo_newsletter')}`;
		case 'days_promotion_on_main_page':
			return `${t('websites.log.days_promotion_on_main_page')}`;
		case 'price':
			return `${t('websites.log.price')}`;
		case 'discount_type':
			return `${t('websites.log.discount_type')}`;
		case 'discount_value':
			return `${t('websites.log.discount_value')}`;
		case 'share_type':
			return `${t('websites.log.share_type')}`;
		case 'share_value':
			return `${t('websites.log.share_value')}`;
		case 'fast_delivery':
			return `${t('websites.log.fast_delivery')}`;
		case 'anchor_types':
			return `${t('websites.log.anchor_types')}`;
		case 'section':
			return `${t('websites.log.section')}`;
		case 'currency':
			return `${t('websites.log.currency')}`;
		case 'categories':
			return `${t('websites.log.categories')}`;
		case 'forbidden_topics':
			return `${t('websites.log.forbidden_topics')}`;
		default:
			return fieldName;
	}
};
</script>

<style scoped lang="scss">
.websites-offers-history-box {
	background-color: $white-100;
	border-radius: 6px;
	padding: 24px;
	box-shadow: 10px 10px 40px 0 #0000000D;
	display: flex;
	flex-direction: column;
	gap: 24px;
	color: $primary-400;
	
	&__subtitle {
		font-size: 16px;
		font-weight: 600;
		text-transform: uppercase;
	}
	
	&__wrapper {
		height: 250px;
		overflow-y: scroll;
	}
	
	&__item-wrapper {
		font-size: 14px;
		display: flex;
		gap: 10px;
		margin-bottom: 10px;
	}
	
	&__icon {
		background-color: $primary-400;
		color: $white-100;
		width: 45px;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 700;
		border-radius: 50%;
		font-size: 18px;
	}
	
	&__text-wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	
	&__top-item {
		span {
			font-weight: 700;
		}
	}
	
	&__bottom-item {
		display: flex;
		align-items: center;
		gap: 10px;
		word-break: break-all;
		width: 100%;
		border-top: 1px solid $grey-250;
		
		svg {
			transform: rotateZ(-90deg);
		}
	}
	
	&__old-val {
		max-width: 48%;
	}
	
	&__new-val {
		max-width: 48%;
	}
}
</style>
