<template>
    <div class="m-progress-bar">
        <div class="m-progress-bar__wrapper">
            <v-progress-linear
                v-model="value"
                :color="color"
                :height="height"
                :indeterminate="indeterminate"
                rounded
            >
                <div v-if="isPercent" class="m-progress-bar__percent">{{ value }}%</div>
            </v-progress-linear>
        </div>
        <div class="m-progress-bar__plug"></div>
    </div>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, withDefaults } from 'vue';

interface IOwnProps {
    modelValue?: string|number
    color?: string
    height?: string
    isPercent?: boolean
	indeterminate?: boolean
}
interface IProps extends IOwnProps {
}

const props = withDefaults(defineProps<IProps>(), {
    height: '20',
    color: ''
});
const emit = defineEmits(['update:modelValue']);

const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});
</script>

<style scoped lang="scss">
.m-progress-bar {
    position: relative;
    width: 100%;
    height: 100%;
    
    &__plug {
        width: inherit;
        height: inherit;
        position: absolute;
        background-color: transparent;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    
    &__percent {
        font-weight: 700;
    }
}
</style>
