<template>
	<div class="basket-payment-method-window">
		<div class="basket-payment-method-window__main-wrapper">
			<div class="basket-payment-method-window__wrapper">
				<div class="basket-payment-method-window__title">{{ $t('basket.summary.totalNet') }}</div>
				<div class="basket-payment-method-window__cost basket-payment-method-window__cost--one">{{ net().toFixed(2) }} {{ userCurrencySymbol }}</div>
			</div>
			<div class="basket-payment-method-window__wrapper">
				<div class="basket-payment-method-window__title">{{ $t('basket.summary.vat') }} ({{ user?.vat_rate }}%):</div>
				<div class="basket-payment-method-window__cost basket-payment-method-window__cost--one">{{ tax().toFixed(2) }} {{ userCurrencySymbol }}</div>
			</div>
			<div class="basket-payment-method-window__wrapper" v-if="(!isPopupPayment && order.order_items[0].orderable_type !== DEPOSIT_REQUEST && null != userWalletValue && 0 !== userWalletValue) || (isPopupPayment && order.campaign_id != null && null != userWalletValue && 0 !== userWalletValue)">
				<div class="basket-payment-method-window__title">{{ $t('basket.summary.paymentThroughWallet') }}</div>
				<div class="basket-payment-method-window__cost basket-payment-method-window__cost--three">- {{ userWalletValue >= gross() ? gross().toFixed(2) : (+userWalletValue).toFixed(2) }} {{ userCurrencySymbol }}</div>
			</div>
			<div class="basket-payment-method-window__wrapper">
				<div class="basket-payment-method-window__title">{{ $t('basket.summary.paymentThrough') }} {{ paymentTitle }}:</div>
				<div class="basket-payment-method-window__cost basket-payment-method-window__cost--fife" v-if="(!isPopupPayment && order.order_items[0].orderable_type !== DEPOSIT_REQUEST && null != userWalletValue) || (isPopupPayment && order.campaign_id != null && null != userWalletValue)">{{ userWalletValue >= gross() ? gross().toFixed(2) : (gross() - userWalletValue).toFixed(2) }} {{ userCurrencySymbol }}</div>
				<div class="basket-payment-method-window__cost basket-payment-method-window__cost--fife" v-if="(!isPopupPayment && order.order_items[0].orderable_type === DEPOSIT_REQUEST) || (isPopupPayment && order.campaign_id == null)">{{ gross().toFixed(2) }} {{ userCurrencySymbol }}</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import type { IOrderBasketTableItem } from '@/models/OrderModel';
import { DEPOSIT_REQUEST } from '@/hooks/OrderHooks';

interface IOwnProps {
	order: IOrderBasketTableItem|any
	paymentTitle: string
	isPopupPayment?: boolean
}
interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();
const { userCurrencySymbol, user, userWalletValue } = storeToRefs(useUserStore());

const gross = () => {
	return +(props.order.gross / 100).toFixed(2);
};
const tax = () => {
	return +(props.order.tax / 100).toFixed(2);
};
const net = () => {
	return +(props.order.net / 100).toFixed(2);
};
</script>

<style scoped lang="scss">
.basket-payment-method-window {
	background-color: $white-100;
	
	&__main-wrapper {
		display: flex;
		flex-direction: column;
		padding: 10px;
		width: 320px;
		margin: 0 auto;
		
		@include media-breakpoint-up(md) {
			padding: 20px;
		}
	}
	
	&__wrapper {
		display: flex;
		width: 100%;
		justify-content: flex-start;
		align-items: center;
		margin-top: 20px;
	}
	
	&__title {
		color: $grey-400;
	}
	
	&__cost {
		margin-left: 20px;
		
		&--one {
			font-size: 21px;
			color: $green-400;
			
			@include media-breakpoint-up(lg) {
				font-weight: 700;
			}
		}
		
		&--two {
			font-size: 18px;
			
			@include media-breakpoint-up(lg) {
				font-weight: 700;
			}
		}
		
		&--three {
			font-size: 14px;
			font-weight: 700;
			color: $secondary-400;
		}
		
		&--four {
			font-size: 14px;
		}
		
		&--fife {
			color: $green-400;
			font-size: 21px;
			
			@include media-breakpoint-up(lg) {
				font-weight: 700;
			}
		}
		
		&--six {
			font-size: 18px;
			
			@include media-breakpoint-up(lg) {
				font-weight: 700;
			}
		}
	}
}
</style>
