import BaseApi from '@/api/BaseApi';
import type {
	IChatArchiveResponse,
	IChatsList,
	IChatUnarchiveResponse,
	ISendMessageRequest,
	ISendMessageResponse,
	ISingleChat
} from '@/models/ChatModel';

export default class ChatApi extends BaseApi {
	async chatsList (page: number, perPage: number, onlyPrivate?: number|null, onlyToRead?: number|null) {
		let url = `/api/v1/notes?page=${page}&per_page=${perPage}`;

		if (onlyPrivate) {
			url += `&only_private=${onlyPrivate}`;
		}

		if (onlyToRead) {
			url += `&only_to_read=${onlyToRead}`;
		}
		return this.get<IChatsList>({
			url: url,
		});
	};

	async archiveChat (type: string|null, id: number|null) {
		return this.put<any, IChatArchiveResponse>({
			url: `/api/v1/notes/archive/${type}/${id}`,
		});
	};

	async unarchiveChat (type: string|null, id: number|null) {
		return this.put<any, IChatUnarchiveResponse>({
			url: `/api/v1/notes/unarchive/${type}/${id}`,
		});
	};

	async readChat (type: string|null, id: number|null) {
		return this.put<any, any>({
			url: `/api/v1/notes/read/${type}/${id}`,
		});
	};

	async sendMessage (params: ISendMessageRequest) {
		const data = new FormData();
		if (null != params.files) {
			data.append('files', params.files);
		}
		if (null != params.user_id) {
			data.append('user_id', params.user_id);
		}
		if (null != params.role_id) {
			data.append('role_id', params.role_id);
		}
		if (null != params.type) {
			data.append('type', params.type);
		}
		if (null != params.source_id) {
			data.append('source_id', params.source_id);
		}
		if (null != params.private) {
			data.append('private', params.private);
		}
		if (null != params.read) {
			data.append('read', params.read);
		}
		if (null != params.target_user_id) {
			data.append('target_user_id', params.target_user_id);
		}
		return this.post<FormData | any, ISendMessageResponse>({
			url: '/api/v1/notes',
			data: params,
			config: {
				contentType: 'multipart/form-data',
			},
		});
	};

	async singleChat (type: string, orderGroupId: number) {
		return this.get<Array<ISingleChat>>({
			url: `/api/v1/notes/${type}/${orderGroupId}`,
		});
	};
};
