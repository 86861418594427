<template>
    <div id="app">
        <v-app>
            <v-main>
                <RouterView />
            </v-main>
        </v-app>
    </div>
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router';

if (navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome') && !navigator.userAgent.includes('Chromium')) {
	document.body.classList.add('safari');
}
</script>

<style lang="scss">
@import 'assets/scss/variables';
@import 'assets/scss/app';
</style>
