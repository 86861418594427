<template>
	<div>
		<MLoading />
	</div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useGoogleStore } from '@/stores/google';
import router from '@/router';
import MLoading from '@/components/atoms/MLoading.vue';

const { setGoogleLoginToken } = useGoogleStore();

onMounted(async() => {
	await setGoogleLoginToken(router.currentRoute.value.fullPath.split('token=')[1].split('&')[0]);
	await localStorage.setItem('googleToken', router.currentRoute.value.fullPath.split('token=')[1].split('&')[0]);
	await router.push('/');
});
</script>

<style scoped lang="scss">

</style>
