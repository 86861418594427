<template>
    <div class="hexagon" :style="{'background': defaultBgc}" :class="[{'hexagon-small': small,'hexagon-large': large, 'hexagon-micro': micro}]">
        <div class="hexagon__letter" :style="{'color': defaultColor}" :class="[{'hexagon-small__letter': small,'hexagon-large__letter': large, 'hexagon-micro__letter': micro}]">{{ letter }}</div>
        <div class="hexagon__top" :style="{'background': defaultBgc}" :class="[{'hexagon-small__top': small,'hexagon-large__top': large, 'hexagon-micro__top': micro}]"></div>
        <div class="hexagon__bottom" :style="{'background': defaultBgc}" :class="[{'hexagon-small__bottom': small,'hexagon-large__bottom': large, 'hexagon-micro__bottom': micro}]"></div>
    </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import variables from '@/assets/scss/modules/variables.module.scss';
interface IOwnProps {
    letter?: string|null
    bgc?: string
    color?: string
    micro?: boolean
    small?: boolean
    large?: boolean
}
interface IProps extends IOwnProps {
}

const { primary400, secondary400 } = variables;

const props = defineProps<IProps>();
const defaultBgc = props.bgc || secondary400;
const defaultColor = props.color || primary400;
</script>

<style scoped lang="scss">
.hexagon {
    position: relative;
    width: 43px;
    height: 25px;
    margin: 12px 0;
    background-size: auto 50px;
    background-position: 50%;
    background-color: $secondary-400;
    
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 43px;
        height: 25px;
        z-index: 2;
        background: inherit;
    }
    
    &__letter {
        position: absolute;
        text-align: center;
        color: $primary-400;
        z-index: 10;
        font-weight: $weight-700;
        font-size: 1.5rem;
        line-height: 1.5rem;
        width: 100%;
    }
    
    &__top {
        position: absolute;
        z-index: 1;
        width: 30px;
        height: 30px;
        overflow: hidden;
        transform: scaleY(.5774) rotate(-45deg);
        background: inherit;
        left: 6px;
        top: -15px;
        
        &:after {
            content: "";
            position: absolute;
            width: 43px;
            height: 25px;
            transform: rotate(45deg) scaleY(1.7321) translateY(-12px);
            transform-origin: 0 0;
            background: inherit;
            background-position: top;
        }
    }
    
    &__bottom {
        position: absolute;
        z-index: 1;
        width: 30px;
        height: 30px;
        overflow: hidden;
        transform: scaleY(.5774) rotate(-45deg);
        background: inherit;
        left: 6px;
        bottom: -15px;
        
        &:after {
            content: "";
            position: absolute;
            width: 43px;
            height: 25px;
            transform: rotate(45deg) scaleY(1.7321) translateY(-12px);
            transform-origin: 0 0;
            background: inherit;
            background-position: bottom;
        }
    }
}

.hexagon-small {
    position: relative;
    width: 29px;
    height: 16px;
    margin: 12px 0;
    background-size: auto 50px;
    background-position: 50%;
    background-color: $secondary-400;
    
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 12px;
        z-index: 2;
        background: inherit;
    }
    
    &__letter {
        position: absolute;
        text-align: center;
        color: $primary-400;
        z-index: 10;
        font-weight: $weight-700;
        font-size: 0.9rem;
        line-height: 1.1rem;
        width: 100%;
    }
    
    &__top {
        position: absolute;
        z-index: 1;
        width: 20px;
        height: 20px;
        overflow: hidden;
        transform: scaleY(.5774) rotate(-45deg);
        background: inherit;
        left: 4px;
        top: -10px;
        
        &:after {
            content: "";
            position: absolute;
            width: 43px;
            height: 25px;
            transform: rotate(45deg) scaleY(1.7321) translateY(-12px);
            transform-origin: 0 0;
            background: inherit;
            background-position: top;
        }
    }
    
    &__bottom {
        position: absolute;
        z-index: 1;
        width: 20px;
        height: 20px;
        overflow: hidden;
        transform: scaleY(.5774) rotate(-45deg);
        background: inherit;
        left: 4px;
        bottom: -10px;
        
        &:after {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            transform: rotate(45deg) scaleY(1.7321) translateY(-12px);
            transform-origin: 0 0;
            background: inherit;
            background-position: bottom;
        }
    }
}

.hexagon-large {
    position: relative;
    width: 55px;
    height: 35px;
    margin: 12px 0;
    background-size: auto 50px;
    background-position: 50%;
    background-color: $secondary-400;
    
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 43px;
        height: 25px;
        z-index: 2;
        background: inherit;
    }
    
    &__letter {
        position: absolute;
        text-align: center;
        color: $primary-400;
        z-index: 10;
        font-weight: $weight-700;
        font-size: 2rem;
        line-height: 2.2rem;
        width: 100%;
    }
    
    &__top {
        position: absolute;
        z-index: 1;
        width: 37px;
        height: 37px;
        overflow: hidden;
        transform: scaleY(.5774) rotate(-45deg);
        background: inherit;
        left: 9px;
        top: -19px;
        
        &:after {
            content: "";
            position: absolute;
            width: 43px;
            height: 25px;
            transform: rotate(45deg) scaleY(1.7321) translateY(-12px);
            transform-origin: 0 0;
            background: inherit;
            background-position: top;
        }
    }
    
    &__bottom {
        position: absolute;
        z-index: 1;
        width: 37px;
        height: 37px;
        overflow: hidden;
        transform: scaleY(.5774) rotate(-45deg);
        background: inherit;
        left: 9px;
        bottom: -19px;
        
        &:after {
            content: "";
            position: absolute;
            width: 43px;
            height: 25px;
            transform: rotate(45deg) scaleY(1.7321) translateY(-12px);
            transform-origin: 0 0;
            background: inherit;
            background-position: bottom;
        }
    }
}

.hexagon-micro {
    position: relative;
    width: 19px;
    height: 10px;
    margin: 2px 0;
    background-size: auto 50px;
    background-position: 50%;
    background-color: $secondary-400;
    
    @include media-breakpoint-up(lg) {
        margin: 2px 0 5px;
    }
    
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 2px;
        z-index: 2;
        background: inherit;
    }
    
    &__letter {
        position: absolute;
        text-align: center;
        color: $primary-400;
        z-index: 10;
        font-weight: $weight-700;
        font-size: 0.7rem;
        line-height: 0.6rem;
        width: 100%;
    }
    
    &__top {
        position: absolute;
        z-index: 1;
        width: 13px;
        height: 13px;
        overflow: hidden;
        transform: scaleY(.5774) rotate(-45deg);
        background: inherit;
        left: 3px;
        top: -7px;
        
        &:after {
            content: "";
            position: absolute;
            width: 43px;
            height: 25px;
            transform: rotate(45deg) scaleY(1.7321) translateY(-12px);
            transform-origin: 0 0;
            background: inherit;
            background-position: top;
        }
    }
    
    &__bottom {
        position: absolute;
        z-index: 1;
        width: 14px;
        height: 13px;
        overflow: hidden;
        transform: scaleY(.5774) rotate(-45deg);
        background: inherit;
        left: 3px;
        bottom: -7px;
        
        &:after {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            transform: rotate(45deg) scaleY(1.7321) translateY(-12px);
            transform-origin: 0 0;
            background: inherit;
            background-position: bottom;
        }
    }
}
</style>
