import axios from 'axios';
import router from '@/router/index';
// import toast from '@/plugins/toast.ts'
// import store from '@/store'

export const onInstanceCreate = () => {
    const instance = axios.create({
        baseURL: import.meta.env.VITE_API_WEBSITES_HOST,
        headers: {
            common: {
                Authorization: '',
                Accept: 'application/json'
            },
        },
    });

    instance.interceptors.response.use((response: any) => {
        return response;
    }, (error: any) => {
        const errorResponse = {
            code: 0,
            message: '',
            data: error.response.data || [],
        };

        const errorCode = error.response.status;

        errorResponse.code = errorCode;

        switch (errorCode) {
	        case 401:
                errorResponse.message = 'Unauthenticated.';
	            router.push('/');
                // toast.error(`${this.$t('auth.toast.sessionExpired')}`)
                break;
            case 422:
                // toast.error(`${errorResponse.data.error.message}`)
                break;
            default:
                break;
        }

        return Promise.reject(errorResponse);
    })

    return instance;
}

export default onInstanceCreate;
