<template>
	<div class="offer-discount-groups">
		<HeadingLabel
			class="offer-discount-groups__heading-label"
			icon="discount"
			width="32"
			height="29"
			:text="`${$t('layout.menu.discountGroups')}: ${userGroupName}`"
		/>
		
		<OfferDiscountGroupsTable @userGroupName="setUserGroupName" />
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import OfferDiscountGroupsTable from '@/views/DiscountGroups/components/OfferDiscountGroupsTable.vue';

const userGroupName = ref<string|null>(null);
const setUserGroupName = (groupName:string) => {
	userGroupName.value = groupName;
};
</script>

<style scoped lang="scss">
.offer-discount-groups {
	&__heading-label {
		margin-bottom: 5px;
	}
}
</style>
