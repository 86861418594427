<template>
	<div class="create-edit-offer">
		<h1 class="create-edit-offer__title">{{ availableStep === 5 && !isDuplicateMode ? $t('offers.editOffer') : isDuplicateMode ? $t('offers.duplicateOffer') + $t('offers.toDomain') + form?.websiteName : $t('offers.addOffer') }}</h1>
		
		<Steps :items="stepsItems" :available-step="availableStep" @changeStep="changeStep" />
		
		<div v-if="activeStep === 1">
			<Form :validation-schema="stepOneSchema" @submit="saveStep" class="create-edit-offer__form-wrapper">
				<div class="create-edit-offer__form-title">{{ availableStep !== 5 || isDuplicateMode ? $t('offers.addBasicData') : $t('offers.editBasicData') }}</div>
				
				<MTextField
					id="offer-name"
					v-model="form.offerName"
					name="offerName"
					label=""
					:separate-label="$t('offers.offerName')"
					label-text-required
					:hint="$t('offers.offerNameHint')"
					persistent-hint
					:placeholder="$t('offers.offerNamePlaceholder')"
					variant="outlined"
					clearable
				/>
				<MAutocomplete
					id="offer-publication-section"
					v-model="form.publicationSection"
					name="offerPublicationSection"
					:get-items="publicationSection"
					item-title="name"
					item-value="id"
					:hint="$t('offers.publicationSectionHint')"
					persistent-hint
					:label="$t('offers.publicationSection')"
					label-text-required
					:placeholder="$t('offers.publicationSectionPlaceholder')"
					variant="outlined"
					clearable
				/>
				<MAutocomplete
					id="offer-period"
					v-model="form.period"
					name="period"
					:get-items="periodList"
					item-title="name"
					item-value="id"
					:label="$t('offers.period')"
					label-text-required
					:placeholder="$t('offers.periodPlaceholder')"
					variant="outlined"
					:hint="$t('offers.periodHint')"
					persistent-hint
					clearable
				/>
				<div>
					<div class="create-edit-offer__content-title">{{ $t('offers.articleDesignation') }}<span>{{ $t('components.fieldRequired') }}</span></div>
					<v-radio-group v-model="form.articleDesignation" :color="primary400" id="offer-article-designation">
						<v-radio id="offer-article-add-sponsored" :label="$t('offers.advertisingSignage')" :value="DISCLOSURE_ADD_SPONSORED" />
						<v-radio id="offer-article-discretion" :label="$t('offers.discretion')" :value="DISCLOSURE_DISCRETION" />
						<v-radio id="offer-article-none" :label="$t('offers.noneSignage')" :value="DISCLOSURE_NOT_ADD_SPONSORED" />
						<v-radio id="offer-article-other" :label="$t('offers.otherSignage')" :value="DISCLOSURE_OTHER" />
						<MTextArea id="offer-article-other-text" v-model="form.otherDisclosureMessage" v-if="form.articleDesignation === DISCLOSURE_OTHER" name="otherDisclosureMessage" />
					</v-radio-group>
				</div>
				<div class="create-edit-offer__btn-wrapper">
					<MButton
						v-if="availableStep === 5"
						id="offer-edit-btn"
						@click="goBack"
						normal
						width130
						transparent-with-border
						:label="$t('offers.cancel')"
					/>
					<MButton
						v-if="availableStep !== 5"
						id="offer-first-step-back-btn"
						@click="goBack"
						normal
						width130
						transparent-with-border
						:label="$t('offers.back')"
					/>
					<MButton
						id="offer-first-step-btn"
						type="submit"
						normal
						width130
						blue400
						:label="availableStep === 1 || isDuplicateMode ? $t('offers.nextStep') : $t('offers.save')"
					/>
				</div>
			</Form>
		</div>
		
		<div v-if="activeStep === 2" class="create-edit-offer__second-step">
			<Form :validation-schema="stepTwoSchema" @submit="saveStep" class="create-edit-offer__form-wrapper">
				<div class="create-edit-offer__form-title">{{ $t('offers.linking') }}</div>
				<div>
					<div class="create-edit-offer__content-title">{{ $t('offers.followLinks') }}<span>{{ $t('components.fieldRequired') }}</span></div>
					<v-radio-group v-model="form.followLinks" :color="primary400" id="offer-follow-links">
						<v-radio id="offer-follow-links-yes" :label="$t('offers.yes')" :value="1" />
						<v-radio id="offer-follow-links-no" :label="$t('offers.no')" :value="0" />
					</v-radio-group>
					<div class="create-edit-offer__error-message-wrapper create-edit-offer__error-message-wrapper--margin">
						<div v-show="!isDoFollowSelected" class="create-edit-offer__error-message">{{ $t('offers.doFollowNotSelected') }}</div>
					</div>
					<MAutocomplete
						id="offer-max-links"
						v-model="form.maxLinks"
						name="maxLinks"
						:get-items="maxLinksList"
						item-title="id"
						item-value="id"
						:label="$t('offers.maxLinks')"
						label-text-required
						:placeholder="$t('offers.maxLinksPlaceholder')"
						variant="outlined"
					/>
				</div>
				<div>
					<div class="create-edit-offer__content-title">{{ $t('offers.anchorType') }}<span>{{ $t('components.fieldRequired') }}</span></div>
					<MCheckbox
						id="offer-anchor-select-all"
						v-model="selectAllAnchors"
						name="selectAll"
						:label="$t('offers.selectAll')"
						:color="primary400"
					/>
					<MCheckbox
						id="offer-anchor-branded"
						v-model="form.anchorBranded"
						name="anchorBranded"
						:label="$t('offers.brand')"
						:color="primary400"
					>
						<template #beforeLink>
							<div class="create-edit-offer__radio-wrapper">
								<span>- {{ $t('offers.np') }}</span>
								<a href="https://www.marketin9.com/" target="_blank"> {{ $t('offers.brandLinks') }},</a>
								<a href="https://www.marketin9.com/" target="_blank"> {{ $t('offers.brandLinks2') }}</a>
							</div>
						</template>
					</MCheckbox>
					<MCheckbox
						id="offer-anchor-generic"
						v-model="form.anchorGeneric"
						name="anchorGeneric"
						:label="$t('offers.generic')"
						:color="primary400"
					>
						<template #beforeLink>
							<div class="create-edit-offer__radio-wrapper">
								<span>- {{ $t('offers.np') }}</span>
								<a href="https://www.marketin9.com/" target="_blank"> {{ $t('offers.genericLinks') }},</a>
								<a href="https://www.marketin9.com/" target="_blank"> {{ $t('offers.genericLinks2') }}</a>
							</div>
						</template>
					</MCheckbox>
					<MCheckbox
						id="offer-anchor-exact-match"
						v-model="form.anchorExactMatch"
						name="anchorExactMatch"
						:label="$t('offers.exactMatch')"
						:color="primary400"
					>
						<template #beforeLink>
							<div class="create-edit-offer__radio-wrapper">
								<span>- {{ $t('offers.np') }}</span>
								<a href="https://www.marketin9.com/" target="_blank"> {{ $t('offers.exactMatchLinks') }}</a>
							</div>
						</template>
					</MCheckbox>
					<div class="create-edit-offer__error-message-wrapper">
						<div v-show="!isAnchorTypeSelected" class="create-edit-offer__error-message">{{ $t('offers.anchorTypesNotSelected') }}</div>
					</div>
				</div>
				<div class="create-edit-offer__btn-wrapper">
					<MButton
						v-if="availableStep === 5"
						id="offer-edit-btn-second-step"
						@click="goBack"
						normal
						width130
						transparent-with-border
						:label="$t('offers.cancel')"
					/>
					<MButton
						v-if="availableStep !== 5"
						id="offer-step-back-btn-second-step"
						@click="goPrevStep"
						normal
						width130
						transparent-with-border
						:label="$t('offers.previousStep')"
					/>
					<MButton
						id="offer-second-step-btn"
						type="submit"
						normal
						width130
						blue400
						:label="availableStep === 2 || isDuplicateMode ? $t('offers.nextStep') : $t('offers.save')"
					/>
				</div>
			</Form>
		</div>
		
		<div v-if="activeStep === 3" class="create-edit-offer__second-step">
			<Form :validation-schema="stepThreeSchema" @submit="saveStep" class="create-edit-offer__form-wrapper">
				<div class="create-edit-offer__form-title">{{ $t('offers.publicationPromotions') }}</div>
				<MCheckbox
					id="offer-is-advertiser-can-place-banners-in-article"
					v-model="form.isAdvertiserCanPlaceBannersInArticle"
					name="isAdvertiserCanPlaceBannersInArticle"
					:label="$t('offers.isAdvertiserCanPlaceBannersInArticle')"
					:color="primary400"
				/>
				<div>
					<MTextField
						id="offer-number-of-promotion-days-on-main-page"
						v-model="form.promotionOnMainPage"
						name="promotionOnMainPage"
						type="number"
						min="0"
						label=""
						:separate-label="$t('offers.promotionOnMainPage')"
						label-text-required
						:placeholder="$t('offers.promotionOnMainPagePlaceholder')"
						variant="outlined"
						:hint="$t('offers.promotionOnMainPageHint')"
						persistent-hint
					/>
				</div>
				<MAutocomplete
					id="offer-where-content-will-be-promoting"
					v-model="form.promotionContent"
					name="promotionContent"
					:get-items="promotionContentList"
					item-title="name"
					item-value="slug"
					:label="$t('offers.promotionContent')"
					:placeholder="$t('offers.promotionContentPlaceholder')"
					variant="outlined"
					multiple
					chips
				/>
				<div class="create-edit-offer__btn-wrapper">
					<MButton
						v-if="availableStep === 5"
						id="offer-edit-btn-second-step"
						@click="goBack"
						normal
						width130
						transparent-with-border
						:label="$t('offers.cancel')"
					/>
					<MButton
						v-if="availableStep !== 5"
						id="offer-step-back-btn-second-step"
						@click="goPrevStep"
						normal
						width130
						transparent-with-border
						:label="$t('offers.previousStep')"
					/>
					<MButton
						id="offer-third-step-btn"
						type="submit"
						normal
						width130
						blue400
						:label="availableStep === 3 || isDuplicateMode ? $t('offers.nextStep') : $t('offers.save')"
					/>
				</div>
			</Form>
		</div>
		
		<div v-if="activeStep === 4" class="create-edit-offer__second-step">
			<Form :validation-schema="stepFourSchema" @submit="saveStep" class="create-edit-offer__form-wrapper">
				<div class="create-edit-offer__form-title">{{ $t('offers.price') }}</div>
				<div v-if="currentLanguageId !== POLISH_LANGUAGE_ID || (null != user && form.currentSavedIsOfferIncludeArticleWrite && isModerator(user))">
					<MCheckbox
						id="offer-is-offer-include-article-write"
						v-model="form.isOfferIncludeArticleWrite"
						name="isOfferIncludeArticleWrite"
						:label="$t('offers.isOfferIncludeArticleWrite')"
						:color="primary400"
					/>
					<div class="create-edit-offer__hint">{{ $t('offers.isOfferIncludeArticleWriteHint') }}</div>
				</div>
				<MTextField
					v-if="(form.isOfferIncludeArticleWrite && currentLanguageId !== POLISH_LANGUAGE_ID) || (null != user && form.isOfferIncludeArticleWrite && isModerator(user))"
					id="offer-days-to-write"
					v-model="form.daysToWrite"
					name="daysToWrite"
					type="number"
					min="0"
					step="1"
					label=""
					:separate-label="$t('offers.daysToWrite')"
					label-text-required
					:placeholder="$t('offers.daysToWritePlaceholder')"
					variant="outlined"
					clearable
				/>
				<MAutocomplete
					id="offer-currency"
					v-model="form.currency"
					name="currency"
					:get-items="currencies"
					item-title="currency_code"
					item-value="id"
					label-text-required
					:label="$t('offers.currency')"
					:placeholder="$t('offers.currencyPlaceholder')"
					variant="outlined"
				/>
				<MTextField
					id="offer-price"
					v-model="form.price"
					name="price"
					type="number"
					min="0"
					step="0.01"
					label=""
					:separate-label="$t('offers.priceForm')"
					label-text-required
					:placeholder="$t('offers.priceFormPlaceholder')"
					variant="outlined"
					clearable
				/>
				<div class="create-edit-offer__btn-wrapper">
					<MButton
						v-if="availableStep === 5"
						id="offer-edit-btn-second-step"
						@click="goBack"
						normal
						width130
						transparent-with-border
						:label="$t('offers.cancel')"
					/>
					<MButton
						v-if="availableStep !== 5"
						id="offer-step-back-btn-second-step"
						@click="goPrevStep"
						normal
						width130
						transparent-with-border
						:label="$t('offers.previousStep')"
					/>
					<MButton
						id="offer-third-step-btn"
						type="submit"
						normal
						width130
						blue400
						:label="availableStep === 4 || isDuplicateMode ? $t('offers.sendToModeration') : $t('offers.save')"
					/>
				</div>
			</Form>
		</div>
		
		<div v-if="activeStep === 5" class="create-edit-offer__form-wrapper">
			<div class="create-edit-offer__form-title">{{ $t('offers.summary') }}</div>
			<div class="create-edit-offer__summary-description-text">
				<div class="create-edit-offer__summary-description">{{ $t('offers.summaryDescription1') }}</div>
				<div>{{ $t('offers.summaryDescription2') }}</div>
			</div>
			<div class="create-edit-offer__summary-icon-wrapper">
				<div class="create-edit-offer__summary-item">
					<MIcon icon="ok" width="25px" height="25px" />
					<div class="create-edit-offer__summary-description-text">{{ $t('offers.summaryDescription3') }}</div>
				</div>
			</div>
			<div class="create-edit-offer__summary-btn-wrapper">
				<div class="create-edit-offer__summary-tooltip-wrapper">
					<MButton
						v-if="availableStep === 5"
						id="offer-create-similar-offer"
						normal
						transparent-with-border
						transparent
						:label="$t('offers.createSimilarOffer')"
						@click="duplicateSimilarOffer"
					/>
					<div class="create-edit-offer__summary-tooltip">
						<MTooltip :max-width="250">
							<template v-slot:title>
								<MIcon icon="info" width="20" height="20" />
							</template>
							<template v-slot:content>
								<div>{{ $t('offers.summaryTooltip') }}</div>
							</template>
						</MTooltip>
					</div>
				</div>
				<MButton
					v-if="availableStep === 5"
					id="offer-create-offer"
					normal
					blue400
					:label="$t('offers.createOffer')"
					@click="goToCreateOffer"
				/>
			</div>
		</div>
		
		<MLoading v-show="loading"/>
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { useDictionaryStore } from '@/stores/dictionary';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { toast } from 'vue3-toastify';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import variables from '@/assets/scss/modules/variables.module.scss';
import { isModerator } from '@/hooks/UserHooks';
import { DISAPPROVED, RESUBMITTED_MODERATION, SUBMITTED_MODERATION } from '@/hooks/WebsitesHooks';
import { DISCLOSURE_ADD_SPONSORED, DISCLOSURE_DISCRETION, DISCLOSURE_NOT_ADD_SPONSORED, DISCLOSURE_OTHER } from '@/hooks/DisclosureHooks';
import { ANCHOR_BRANDED, ANCHOR_EXACT_MATCH, ANCHOR_GENERIC } from '@/hooks/AnchorTypeHooks';
import { POLISH_LANGUAGE_ID } from '@/hooks/ProfileHooks';
import OffersApi from '@/api/v1/OffersApi';
import Steps from '@/components/molecules/Steps.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MButton from '@/components/atoms/MButton.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MCheckbox from '@/components/atoms/MCheckbox.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';
import MLoading from '@/components/atoms/MLoading.vue';
import MTextArea from '@/components/atoms/MTextArea.vue';

const offersApi = new OffersApi();

const { t } = useI18n();
const { currencies } = useDictionaryStore();
const { user } = storeToRefs(useUserStore());
const { primary400 } = variables;

const loading = ref<boolean>(false);
const isDuplicateMode = ref<boolean>(false);
const isAnchorTypeSelected = ref<boolean>(true);
const isDoFollowSelected = ref<boolean>(true);
const selectAllAnchors = ref<boolean>(false);
const rejectMessage = ref<string|null>(null);
const websiteUuid = ref<Array<string>|string>('');
const offerUuid = ref<Array<string>|string>('');
const availableStep = ref<number>(1);
const activeStep = ref<number>(1);
const status = ref<string|null>('');
const currentSavedIsOfferIncludeArticleWrite = ref<boolean>(false);
const form = ref<any>({
	websiteUuid: '',
	offerName: '',
	websiteName: '',
	publicationSection: [],
	period: [],
	articleDesignation: DISCLOSURE_NOT_ADD_SPONSORED,
	followLinks: null,
	maxLinks: 1,
	isAdvertiserCanPlaceBannersInArticle: false,
	promotionOnMainPage: 0,
	promotionContent: [],
	isOfferIncludeArticleWrite: false,
	currency: [],
	price: null,
	step: 1,
	anchorBranded: false,
	anchorGeneric: false,
	anchorExactMatch: false,
	otherDisclosureMessage: null,
	daysToWrite: null,
	websiteUrl: null,
});
const currentLanguageId = ref<number|null>(null);
const publicationSection = ref<Array<any>>([
	{
		id: 1,
		name: `${t('offers.homePage')}`,
		created_at: "2018-07-20T15:34:14.000000Z",
		updated_at: "2018-07-20T15:34:14.000000Z"
	},
	{
		id: 2,
		name: `${t('offers.blog')}`,
		created_at: "2018-07-20T15:34:14.000000Z",
		updated_at: "2018-07-20T15:34:14.000000Z"
	},
	{
		id: 3,
		name: `${t('offers.category')}`,
		created_at: "2018-07-20T15:34:14.000000Z",
		updated_at: "2018-07-20T15:34:14.000000Z"
	},
	{
		id: 4,
		name: `${t('offers.news')}`,
		created_at: "2018-07-20T15:34:14.000000Z",
		updated_at: "2018-07-20T15:34:14.000000Z"
	}
]);
const periodList = ref<Array<any>>([
	{
		id: 1,
		name: `${t('offers.lifetime')}`,
		created_at: "2018-07-20T15:34:16.000000Z",
		updated_at: "2018-07-20T15:34:16.000000Z"
	},
	{
		id: 14,
		name: `${t('offers.24month')}`,
		created_at: "2022-05-25T16:40:13.000000Z",
		updated_at: "2022-05-25T16:40:13.000000Z"
	},
	{
		id: 13,
		name: `${t('offers.12month')}`,
		created_at: "2018-07-20T15:34:18.000000Z",
		updated_at: "2018-07-20T15:34:18.000000Z"
	},
	{
		id: 12,
		name: `${t('offers.11month')}`,
		created_at: "2018-07-20T15:34:18.000000Z",
		updated_at: "2018-07-20T15:34:18.000000Z"
	},
	{
		id: 11,
		name: `${t('offers.10month')}`,
		created_at: "2018-07-20T15:34:18.000000Z",
		updated_at: "2018-07-20T15:34:18.000000Z"
	},
	{
		id: 10,
		name: `${t('offers.9month')}`,
		created_at: "2018-07-20T15:34:18.000000Z",
		updated_at: "2018-07-20T15:34:18.000000Z"
	},
	{
		id: 9,
		name: `${t('offers.8month')}`,
		created_at: "2018-07-20T15:34:17.000000Z",
		updated_at: "2018-07-20T15:34:17.000000Z"
	},
	{
		id: 8,
		name: `${t('offers.7month')}`,
		created_at: "2018-07-20T15:34:17.000000Z",
		updated_at: "2018-07-20T15:34:17.000000Z"
	},
	{
		id: 7,
		name: `${t('offers.6month')}`,
		created_at: "2018-07-20T15:34:17.000000Z",
		updated_at: "2018-07-20T15:34:17.000000Z"
	},
	{
		id: 6,
		name: `${t('offers.5month')}`,
		created_at: "2018-07-20T15:34:17.000000Z",
		updated_at: "2018-07-20T15:34:17.000000Z"
	},
	{
		id: 5,
		name: `${t('offers.4month')}`,
		created_at: "2018-07-20T15:34:17.000000Z",
		updated_at: "2018-07-20T15:34:17.000000Z"
	},
	{
		id: 4,
		name: `${t('offers.3month')}`,
		created_at: "2018-07-20T15:34:17.000000Z",
		updated_at: "2018-07-20T15:34:17.000000Z"
	},
	{
		id: 3,
		name: `${t('offers.2month')}`,
		created_at: "2018-07-20T15:34:16.000000Z",
		updated_at: "2018-07-20T15:34:16.000000Z"
	},
	{
		id: 2,
		name: `${t('offers.1month')}`,
		created_at: "2018-07-20T15:34:16.000000Z",
		updated_at: "2018-07-20T15:34:16.000000Z"
	},
]);
const maxLinksList = ref<Array<any>>([
	{
		id: 1,
	},
	{
		id: 2,
	},
	{
		id: 3,
	},
	{
		id: 4,
	},
	{
		id: 5,
	},
	{
		id: 6,
	},
	{
		id: 7,
	},
	{
		id: 8,
	},
	{
		id: 9,
	},
	{
		id: 10,
	},
]);
const stepsItems = ref<Array<any>>([
	{
		name: `${t('offers.completeBasicData')}`,
		step: 1,
	},
	{
		name: `${t('offers.determinateLinkMethod')}`,
		step: 2,
	},
	{
		name: `${t('offers.selectPromotion')}`,
		step: 3,
	},
	{
		name: `${t('offers.addPrice')}`,
		step: 4,
	},
	{
		name: `${t('offers.saveYourOffer')}`,
		step: 5,
	},
]);
const promotionContentList = ref<Array<any>>([
	{
		name: `${t('offers.facebook')}`,
		slug: 'facebook',
	},
	{
		name: `${t('offers.twitter')}`,
		slug: 'twitter',
	},
	{
		name: `${t('offers.newsletter')}`,
		slug: 'newsletter',
	},
]);

if (router.currentRoute.value.path.split('/')[4] === 'duplicate') {
	isDuplicateMode.value = true;
}

const stepOneSchema:any = yup.object({
	offerName: yup.string().required(`${t('offers.validation.offerName')}`).label('offerName'),
	offerPublicationSection: yup.number().required(`${t('offers.validation.offerPublicationSection')}`).label('offerPublicationSection'),
	period: yup.number().required(`${t('offers.validation.period')}`).label('period'),
	otherDisclosureMessage: yup.string().required(`${t('offers.validation.otherDisclosureMessage')}`).max(40, `${t('offers.validation.max40Characters')}`).label('otherDisclosureMessage'),
});
const stepTwoSchema:any = yup.object({
	maxLinks: yup.number().required(`${t('offers.validation.maxLinks')}`).min(0, `${t('offers.validation.maxLinksMin')}`).label('maxLinks'),
});
const stepThreeSchema:any = yup.object({
	promotionOnMainPage: yup.number().required(`${t('offers.validation.promotionOnMainPage')}`).label('promotionOnMainPage'),
});
const stepFourSchema:any = yup.object({
	daysToWrite: yup.number().required(`${t('offers.validation.daysToWrite')}`).min(0, `${t('offers.validation.daysToWrite')}`).label('daysToWrite'),
	currency: yup.number().required(`${t('offers.validation.currency')}`).label('currency'),
	price: yup.number().required(`${t('offers.validation.price')}`).min(0.1, `${t('offers.validation.priceMin')}`).label('price'),
});

const changeStep = (step:number) => {
	activeStep.value = step;
};
const goBack = () => {
	router.push('/websites/offers');
};
const goPrevStep = () => {
	activeStep.value = activeStep.value - 1;
};

const saveStep = async() => {
	loading.value = true;
	if ((availableStep.value == 5 || activeStep.value === 4) && (form.value.offerName == null || form.value.offerName === '')) {
		toast.error(`${t('offers.toast.errorFillForm')}`);
		activeStep.value = 1;
		loading.value = false;
		return;
	}
	if ((availableStep.value == 5 && activeStep.value === 4) && (form.value.price == null || form.value.price == 0)) {
		toast.error(`${t('offers.toast.errorFillForm')}`);
		activeStep.value = 4;
		loading.value = false;
		return;
	}
	if (activeStep.value === 2 && !form.value.anchorBranded && !form.value.anchorGeneric && !form.value.anchorExactMatch) {
		isAnchorTypeSelected.value = false;
		loading.value = false;
		return;
	}
	if (activeStep.value === 2 && null == form.value.followLinks) {
		isDoFollowSelected.value = false;
		loading.value = false;
		return;
	}
	if (availableStep.value === 1 && activeStep.value === 1) {
		form.value.step = 2;
	}
	if (availableStep.value === 2 && activeStep.value === 2) {
		form.value.step = 3;
	}
	if (availableStep.value === 3 && activeStep.value === 3) {
		form.value.step = 4;
	}
	if (availableStep.value === 4 && activeStep.value === 4) {
		form.value.step = 5;
	}
	if (availableStep.value === 1) {
		try {
			const createForm:any = {
				website_id: websiteUuid.value,
				name: form.value.offerName,
				section_id: form.value.publicationSection,
				duration_id: form.value.period,
				disclosure: form.value.articleDesignation,
				disclosure_other: form.value.articleDesignation === DISCLOSURE_OTHER ? form.value.otherDisclosureMessage : null,
				step: 2,
			};
			const result = await offersApi.createOffer(createForm);
			if (!result.isSuccess) {
				toast.error(`${t('offers.toast.errorCreateOffer')}`);
				loading.value = false;
				return;
			}
			await router.push(`/websites/offer/${result.payload.uuid}`);
			availableStep.value = availableStep.value + 1;
			activeStep.value = activeStep.value + 1;
		} catch (e) {
			toast.error(`${t('offers.toast.errorCreateOffer')}`);
			loading.value = false;
			return;
		}
	} else {
		try {
			let promoFacebook = 0;
			let promoTwitter = 0;
			let promoNewsletter = 0;
			if (null != form.value.promotionContent && 0 != form.value.promotionContent.length) {
				form.value.promotionContent.forEach((elem:any) => {
					if (elem.slug === 'facebook' || elem === 'facebook') {
						promoFacebook = 1;
					}
					if (elem.slug === 'twitter' || elem === 'twitter') {
						promoTwitter = 1;
					}
					if (elem.slug === 'newsletter' || elem === 'newsletter') {
						promoNewsletter = 1;
					}
				});
			}
			let anchorTypes = [];
			if (form.value.anchorBranded) {
				anchorTypes.push(ANCHOR_BRANDED);
			}
			if (form.value.anchorGeneric) {
				anchorTypes.push(ANCHOR_GENERIC);
			}
			if (form.value.anchorExactMatch) {
				anchorTypes.push(ANCHOR_EXACT_MATCH);
			}
			
			if (
				null == form.value.offerName ||
				'' === form.value.offerName ||
				null == form.value.publicationSection ||
				0 === form.value.publicationSection.length ||
				null == form.value.period ||
				0 === form.value.period.length ||
				null == form.value.articleDesignation
			) {
				loading.value = false;
				toast.error(`${t('offers.toast.errorEditOffer')}`);
				activeStep.value = 1;
				return;
			}
			
			if (
				null == form.value.followLinks ||
				null == form.value.maxLinks ||
				'' === form.value.maxLinks ||
				null == anchorTypes ||
				0 === anchorTypes.length
			) {
				loading.value = false;
				toast.error(`${t('offers.toast.errorEditOffer')}`);
				activeStep.value = 2;
				return;
			}
			
			if (
				(null == form.value.promotionOnMainPage ||
				'' === form.value.promotionOnMainPage) &&
				activeStep.value === 3
			) {
				loading.value = false;
				toast.error(`${t('offers.toast.errorEditOffer')}`);
				activeStep.value = 3;
				return;
			}
			
			if (
				(null == form.value.currency ||
				0 === form.value.currency.length ||
				null == form.value.price ||
				'' == form.value.price ||
				(form.value.isOfferIncludeArticleWrite && (null == form.value.daysToWrite || '' === form.value.daysToWrite)))
				&& activeStep.value === 4
			) {
				loading.value = false;
				toast.error(`${t('offers.toast.errorEditOffer')}`);
				activeStep.value = 4;
				return;
			}
			const editForm:any = {
				website_id: form.value.websiteUuid,
				name: form.value.offerName,
				url: form.value.websiteUrl,
				section_id: form.value.publicationSection,
				duration_id: form.value.period,
				disclosure: form.value.articleDesignation,
				currency_id: form.value.currency,
				step: form.value.step,
				do_follow: form.value.followLinks,
				links_max_amount: form.value.maxLinks,
				anchor_types: anchorTypes,
				allow_banners: form.value.isAdvertiserCanPlaceBannersInArticle,
				days_promotion_on_main_page: form.value.promotionOnMainPage,
				write_content: form.value.isOfferIncludeArticleWrite,
				price: form.value.price,
				promo_facebook: promoFacebook,
				promo_twitter: promoTwitter,
				promo_newsletter: promoNewsletter,
				disclosure_other: form.value.articleDesignation === DISCLOSURE_OTHER ? form.value.otherDisclosureMessage : null,
				days_to_write: !form.value.isOfferIncludeArticleWrite ? null : null != form.value.daysToWrite ? +form.value.daysToWrite : null,
			};
			const result = await offersApi.editOffer(editForm, offerUuid.value);
			
			if (!result.isSuccess) {
				toast.error(`${t('offers.toast.errorEditOffer')}`);
				loading.value = false;
				return;
			}
			if (availableStep.value === 4 && activeStep.value === 4) {
				availableStep.value = 5;
				activeStep.value = 5;
			} else {
				if (!isDuplicateMode.value) {
					if (availableStep.value === 5 && activeStep.value === 4) {
						await router.push('/websites/offers');
					} else {
						activeStep.value = activeStep.value + 1;
					}
					await offerDetails();
				} else {
					activeStep.value = activeStep.value + 1;
				}
			}
		} catch (e) {
			toast.error(`${t('offers.toast.errorEditOffer')}`);
			loading.value = false;
			return;
		}
		if (availableStep.value === 5 && !isDuplicateMode.value) {
			await changeStatus();
		}
		if (availableStep.value === 5 && activeStep.value === 5 && isDuplicateMode.value) {
			await changeStatus();
		}
		toast.success(`${t('offers.toast.successSubmitOffer')}`);
	}
	loading.value = false;
};

const offerDetails = async() => {
	loading.value = true;
	try {
		const result = await offersApi.offerDetails(offerUuid.value);
		
		if (!result.isSuccess) {
			loading.value = false;
			toast.error(`${t('offers.toast.errorGetOfferDetails')}`);
			return;
		}
		
		currentLanguageId.value = result.payload.language_id;
		form.value.websiteUuid = result.payload.website_id;
		form.value.offerName = result.payload.name;
		form.value.websiteUrl = result.payload.url;
		form.value.articleDesignation = result.payload.disclosure;
		form.value.followLinks = result.payload.do_follow;
		form.value.maxLinks = result.payload.links_max_amount;
		if (0 < result.payload.anchor_types.length) {
			result.payload.anchor_types.forEach(elem => {
				if (elem.id === ANCHOR_BRANDED) {
					form.value.anchorBranded = true;
				}
				if (elem.id === ANCHOR_GENERIC) {
					form.value.anchorGeneric = true;
				}
				if (elem.id === ANCHOR_EXACT_MATCH) {
					form.value.anchorExactMatch = true;
				}
			});
		} else {
			form.value.anchorBranded = true;
		}
		form.value.isAdvertiserCanPlaceBannersInArticle = result.payload.allow_banners;
		form.value.promotionOnMainPage = result.payload.days_promotion_on_main_page;
		form.value.isOfferIncludeArticleWrite = 1 === result.payload.write_content ? true : false;
		form.value.currentSavedIsOfferIncludeArticleWrite = 1 === result.payload.write_content ? true : false;
		form.value.daysToWrite = result.payload.days_to_write;
		form.value.price = result.payload.price;
		form.value.publicationSection = result.payload.section_id;
		form.value.period = result.payload.duration_id;
		form.value.promotionContent = [];
		if (result.payload.promo_facebook) {
			form.value.promotionContent.push(
				{
					name: `${t('offers.facebook')}`,
					slug: 'facebook',
				}
			);
		}
		if (result.payload.promo_twitter) {
			form.value.promotionContent.push(
				{
					name: `${t('offers.twitter')}`,
					slug: 'twitter',
				}
			);
		}
		if (result.payload.promo_newsletter) {
			form.value.promotionContent.push(
				{
					name: `${t('offers.newsletter')}`,
					slug: 'newsletter',
				}
			);
		}
		form.value.currency = result.payload.currency_id;
		form.value.websiteName = result.payload.website_name;
		form.value.otherDisclosureMessage = result.payload.disclosure_other;
		availableStep.value = result.payload.step;
		form.value.step = result.payload.step;
		status.value = result.payload.status;
		rejectMessage.value = result.payload.reject_messages;
		
		if (availableStep.value === 5 && !isDuplicateMode.value) {
			stepsItems.value = [
				{
					name: `${t('offers.editCompleteBasicData')}`,
					step: 1,
				},
				{
					name: `${t('offers.editDeterminateLinkMethod')}`,
					step: 2,
				},
				{
					name: `${t('offers.editSelectPromotion')}`,
					step: 3,
				},
				{
					name: `${t('offers.editAddPrice')}`,
					step: 4,
				},
			];
		} else {
			activeStep.value = availableStep.value;
		}
		if (isDuplicateMode.value) {
			activeStep.value = 1;
			// ustawiam na 4 ponieważ podczas duplikacji najpierw niech user sprawdzi wszystkie pola zanim przejdzie do powielania kolejnej
			availableStep.value = 4;
		}
		
		// TO JEST CHWILOWE PONIEWAZ BACKEND PO PIERwszym stepie przypisuje zawsze do_follow na 0 oraz anchor_types na 'Branded',
		// jezeli backend to zmienie to ponizsszy if jest do wywalenia (to jest chwilowe zmockowanie danych zeby domyślnie nie ustawiały sie zadne)
		if (2 === availableStep.value) {
			form.value.followLinks = null;
			form.value.anchorBranded = false;
			form.value.anchorGeneric = false;
			form.value.anchorExactMatch = false;
		}
	} catch (e) {
		loading.value = false;
		toast.error(`${t('offers.toast.errorGetOfferDetails')}`);
		return;
	}
	loading.value = false;
};

if (router.currentRoute.value.name !== 'CreateOffer') {
	offerUuid.value = router.currentRoute.value.params.offerUuid;
	offerDetails();
} else {
	websiteUuid.value = router.currentRoute.value.params.websiteUuid;
}

const changeStatus = async() => {
	loading.value = true;
	const changeStatusForm = {
		status: status.value === DISAPPROVED || status.value === RESUBMITTED_MODERATION ? RESUBMITTED_MODERATION : SUBMITTED_MODERATION,
	}
	try {
		const result = await offersApi.changeOfferStatus(changeStatusForm, offerUuid.value);
		if (!result.isSuccess) {
			toast.error(`${t('offers.toast.errorSaveOfferStatus')}`);
			loading.value = false;
			return;
		}
	} catch (e) {
		toast.error(`${t('offers.toast.errorSaveOfferStatus')}`);
		loading.value = false;
		return;
	}
	toast.success(`${t('offers.toast.successChangeOfferStatus')}`);
	loading.value = false;
};

const goToCreateOffer = () => {
	router.push(`/websites/offer/create/${form.value.websiteUuid}`);
};
const duplicateSimilarOffer = async() => {
	loading.value = true;
	const offerForm = {
		website_id: form.value.websiteUuid,
	}
	try {
		const result = await offersApi.duplicateOffer(offerForm, offerUuid.value);
		if (!result.isSuccess) {
			toast.error(`${t('offers.toast.errorDuplicateOffer')}`);
			loading.value = false;
			return;
		}
		await router.push(`/websites/offer/${result.payload.uuid}/duplicate`);
	} catch (e) {
		toast.error(`${t('offers.toast.errorDuplicateOffer')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

watch((form.value), (val) => {
	if (val.anchorBranded || val.anchorGeneric || val.anchorExactMatch) {
		isAnchorTypeSelected.value = true;
	} else {
		isAnchorTypeSelected.value = false;
	}
	if (val.anchorBranded && val.anchorGeneric && val.anchorExactMatch) {
		selectAllAnchors.value = true;
	} else {
		selectAllAnchors.value = false;
	}
	if (null != val.followLinks) {
		isDoFollowSelected.value = true;
	} else {
		isDoFollowSelected.value = false;
	}
	if (val.articleDesignation === DISCLOSURE_OTHER) {
		stepOneSchema.fields['otherDisclosureMessage'] = yup.string().required(`${t('offers.validation.otherDisclosureMessage')}`).max(40, `${t('offers.validation.max40Characters')}`).label('otherDisclosureMessage');
	} else {
		stepOneSchema.fields['otherDisclosureMessage'] = yup.string();
	}
	if ((val.isOfferIncludeArticleWrite && currentLanguageId.value !== POLISH_LANGUAGE_ID) || (null != user.value && form.isOfferIncludeArticleWrite && isModerator(user.value))) {
		stepFourSchema.fields['daysToWrite'] = yup.number().required(`${t('offers.validation.daysToWrite')}`).min(0, `${t('offers.validation.daysToWrite')}`).label('daysToWrite');
	} else {
		stepFourSchema.fields['daysToWrite'] = yup.number();
	}
}, {
	deep: true,
});
watch((selectAllAnchors), (val) => {
	if (val) {
		form.value.anchorBranded = true;
		form.value.anchorGeneric = true;
		form.value.anchorExactMatch = true;
	}
});
</script>

<style scoped lang="scss">
.create-edit-offer {
	&__title {
		text-transform: uppercase;
		font-size: 24px;
		font-weight: 400;
		color: $primary-400;
		letter-spacing: 1px;
		margin-bottom: 64px;
	}
	
	&__form-title {
		text-align: center;
		font-size: 24px;
		font-weight: 600;
		color: $primary-400;
	}
	
	&__form-wrapper {
		margin: 80px auto 0;
		width: 90%;
		display: flex;
		flex-direction: column;
		gap: 40px;
		padding: 64px 15px;
		border: 1px solid $grey-300;
		border-radius: 8px;
		background-color: $white-100;
		
		@include media-breakpoint-up(sm) {
			max-width: 520px;
			padding: 64px 45px;
		}
		
		@include media-breakpoint-up(lg) {
			max-width: 640px;
			padding: 64px 75px;
		}
		
		@include media-breakpoint-up(xl) {
			max-width: 760px;
			padding: 64px 125px;
		}
		
		@include media-breakpoint-up(xxl) {
			max-width: 785px;
			padding: 64px 148px;
		}
		
		&:deep(.v-field__input) {
			min-height: 48px;
		}
		
		&:deep(.v-text-field .v-input__details) {
			padding-inline-start: 0;
		}
		
		&:deep(.m-input__label) {
			margin-bottom: 16px;
			font-weight: 500;
			color: $primary-400;
		}
		
		&:deep(.m-autocomplete__label) {
			margin-bottom: 16px;
			font-weight: 500;
			color: $primary-400;
		}
		
		&:deep(.m-autocomplete .v-field__input) {
			padding: 11px 0;
		}
		
		&:deep(.m-btn__transparent-with-border) {
			padding: 25px 50px !important;
		}
		
		&:deep(.v-label) {
			font-size: 12px !important;
		}
	}
	
	&__btn-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		
		@include media-breakpoint-up(sm) {
			flex-direction: row-reverse;
			justify-content: space-between;
		}
	}
	
	&__second-step {
		&:deep(.m-btn__transparent-with-border) {
			padding: 25px 20px !important;
		}
		
		&:deep(.v-label) {
			font-size: 12px !important;
		}
		
		&:deep(.v-chip) {
			border-radius: 6px;
			border: 1px solid $primary-400;
		}
		
		&:deep(.m-checkbox .v-input__details) {
			display: none;
		}
	}
	
	&__subtitle {
		font-size: 14px;
		margin-bottom: 16px;
		font-weight: 500;
		color: $primary-400;
		
		span {
			color: $secondary-400;
		}
	}
	
	&__summary-description-text {
		font-size: 12px;
		font-weight: 500;
		color: $primary-400;
	}
	
	&__summary-description {
		margin-bottom: 16px;
	}
	
	&__summary-icon-wrapper {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	
	&__summary-item {
		display: flex;
		align-items: center;
		gap: 8px;
	}
	
	&__summary-btn-wrapper {
		display: flex;
		justify-content: space-between;
		
		&:deep(.m-btn__transparent-with-border) {
			padding: 25px 30px !important;
		}
	}
	
	&__hint {
		color: $grey-300;
		font-size: 10px;
	}
	
	&__content-title {
		font-size: 14px;
		color: $primary-400;
		display: flex;
		gap: 3px;

		span {
			color: $secondary-400;
		}
	}
	
	&__summary-tooltip-wrapper {
		position: relative;
	}
	
	&__summary-tooltip {
		position: absolute;
		top: 2px;
		right: 2px;
	}
	
	&__radio-wrapper {
		display: flex;
		width: max-content;
		align-items: center;
		gap: 5px;
		
		span {
			font-size: 12px;
			opacity: 0.6;
			margin-left: 3px;
		}
		
		a {
			font-size: 12px;
			color: $secondary-400;
			text-decoration: none;
			opacity: 0.6;
		}
	}
	
	&__error-message-wrapper {
		height: 5px;
		
		&--margin {
			margin-bottom: 30px;
		}
	}
	
	&__error-message {
		color: $secondary-400;
	}
	
	&:deep(.v-radio-group > .v-input__control) {
		margin-left: -10px;
	}
	
	&:deep(.m-checkbox) {
		margin-left: -10px;
		height: 45px;
	}
	
	&:deep(.m-text-area) {
		margin-left: 10px;
	}
	
	&:deep(textarea) {
		height: 70px;
	}
}
</style>
