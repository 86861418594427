<template>
	<BeforeLoginLayout class="reset-password">
		<template v-slot:default>
			<Form :validation-schema="schema" @submit="onSubmitResetPassword" class="reset-password__form-wrapper">
				<img src="~@/assets/images/logo-reverse.png" alt="marketing logo" class="reset-password__logo">
				<MTextField
					id="input-reset-password-email"
					v-model="resetPasswordForm.email"
					name="email"
					:label="$t('auth.emailAddress')"
					:separate-label="$t('auth.emailAddressLabel')"
					:placeholder="$t('auth.email')"
				/>
				<div class="reset-password__btn-wrapper">
					<MButton
						id="reset-password-submit-btn"
						:label="$t('auth.resetPassword')"
						type="submit"
						width210
						blue400
						big
						:loading="loading"
						:disabled="loading || null == resetPasswordForm.email"
					/>
					<MButton
						id="reset-password-back"
						:label="$t('components.cancel')"
						type="text"
						grey400
						width210
						big
						@click="goBack"
					/>
				</div>
			</Form>
		</template>
	</BeforeLoginLayout>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import AuthApi from '@/api/v1/AuthApi';
import { toast } from 'vue3-toastify';
import type { IResetPasswordForm } from '@/models/AuthModel';
import BeforeLoginLayout from '@/views/Layout/components/BeforeLoginLayout.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MButton from '@/components/atoms/MButton.vue';

const authApi = new AuthApi();

const { t } = useI18n();

const resetPasswordForm = ref<IResetPasswordForm>({
	email: null,
});
const loading = ref<boolean>(false);

const schema:any = yup.object({
	email: yup.string().email(`${t('auth.validation.email')}`).required(`${t('auth.validation.emailRequired')}`).label('email'),
});

const onSubmitResetPassword = async() => {
	loading.value = true;
	try {
		const result = await authApi.resetPassword(resetPasswordForm.value);
		if (!result.isSuccess) {
			toast.error(`${t('auth.toast.resetPasswordError')}`);
			loading.value = false;
			return;
		}
		await router.push('/');
		toast.success(`${t('auth.toast.resetPasswordSuccess')}`);
	} catch (e) {
		toast.error(`${t('auth.toast.resetPasswordError')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const goBack = () => {
	router.push('/');
};
</script>

<style scoped lang="scss">
.reset-password {
	&__logo {
		width: 224px;
		height: 46px;
		margin: 0 auto 20px;
	}
	
	&__form-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	
	&__validation {
		@include media-breakpoint-up(md) {
			width: 480px;
			margin: 0 auto;
		}
	}
	
	&__btn-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;
		
		@include media-breakpoint-up(sm) {
			flex-direction: row;
		}
	}
	
	&:deep(.m-input) {
		max-width: 300px;
		margin: 0 auto;
		
		@include media-breakpoint-up(sm) {
			max-width: 480px;
		}
	}
	
	&:deep(.before-login-page__wrapper) {
		padding: 50px 0 0;
	}
}
</style>
