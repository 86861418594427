<template>
	<div class="steps">
		<div v-for="item in items" :key="item.step" class="steps__wrapper" @click="changeStepAction(item.step)">
			<div class="steps__icon-wrapper" :class="{'steps__icon-wrapper--inactive': item.step > availableStep}">
				<MIcon icon="hexagon" :color="secondary400" width="40" height="48" />
				<div class="steps__number">{{ item.step }}</div>
			</div>
			<div class="steps__title">{{ item.name }}</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import variables from '@/assets/scss/modules/variables.module.scss';
import MIcon from '@/components/atoms/MIcon.vue';

interface IOwnProps {
	items: any
	availableStep: number
}

interface IProps extends IOwnProps {
}

const { secondary400 } = variables;
const props = defineProps<IProps>();
const emit = defineEmits(['changeStep'])

const changeStepAction = (step:number) => {
	if (step <= props.availableStep) {
		emit('changeStep', step);
	}
};
</script>

<style scoped lang="scss">
.steps {
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
	justify-content: center;
	
	@include media-breakpoint-up(lg) {
		gap: 50px;
	}
	
	@include media-breakpoint-up(xl) {
		gap: 90px;
	}
	
	@include media-breakpoint-up(xxl) {
		gap: 100px;
	}
	
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		&:first-child {
			.steps__icon-wrapper::before {
				display: none;
			}
		}
	}
	
	&__icon-wrapper {
		position: relative;
		
		&::before {
			display: none;
			
			@include media-breakpoint-up(md) {
				display: block;
				content: '';
				position: absolute;
				top: 24px;
				left: -96px;
				width: 96px;
				height: 2px;
				background-color: $secondary-400;
			}
			
			@include media-breakpoint-up(lg) {
				left: -136px;
				width: 136px;
			}
			
			@include media-breakpoint-up(xl) {
				left: -176px;
				width: 176px;
			}
			
			@include media-breakpoint-up(xxl) {
				left: -186px;
				width: 186px;
			}
		}
		
		&--inactive {
			cursor: default;
			opacity: 0.5;
		}
	}
	
	&__number {
		position: absolute;
		top: 48%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 20px;
		font-weight: 500;
		color: $white-100;
	}
	
	&__title {
		font-size: 14px;
		font-weight: 500;
		color: $primary-400;
		text-align: center;
		max-width: 126px;
		min-width: 126px;
	}
}
</style>
