<template>
	<div class="register">
		<BeforeLoginLayout>
			<template v-slot:default>
				<Form :validation-schema="schema" @submit="onSubmitRegister" class="register__form-wrapper">
					<img src="~@/assets/images/logo-reverse.png" alt="marketing logo" class="register__logo">
					<div class="register__info-text register__info-text--first">{{ $t('auth.registerInfo1') }}</div>
					<div class="register__info-text"><span class="register__info-text">{{ $t('auth.registerInfo2') }}</span>{{ $t('auth.registerInfo3') }}</div>
					<MTextField
						id="input-register-first-name"
						v-model="registerForm.first_name"
						name="firstName"
						:label="$t('auth.firstName')"
						:placeholder="$t('auth.firstNamePlaceholder')"
					/>
					<MTextField
						id="input-register-last-name"
						v-model="registerForm.last_name"
						name="lastName"
						:label="$t('auth.lastName')"
						:placeholder="$t('auth.lastNamePlaceholder')"
					/>
					<MTextField
						id="input-register-email"
						v-model="registerForm.email"
						name="email"
						:label="$t('auth.emailAddress')"
						:placeholder="$t('auth.email')"
					/>
					<MTextField
						id="input-register-phone"
						v-model="registerForm.phone"
						name="phone"
						:label="$t('auth.phone')"
						:placeholder="$t('auth.phoneEnter')"
					/>
					<MTextField
						id="input-register-password"
						v-model="registerForm.password"
						name="password"
						:label="$t('auth.password')"
						:placeholder="$t('auth.passwordEnter')"
						type="password"
					/>
					<PasswordValidationToDoList :password="registerForm.password" class="register__validation" />
					<div class="register__reflink-wrapper">
						<MTextField
							id="input-register-referral-code"
							v-model="registerForm.referralCode"
							name="referralCode"
							:label="$t('auth.referralCode')"
							:placeholder="$t('auth.referralCodePlaceholder')"
							:disabled="isRefererInLocalStorage"
							clearable
						/>
						<div class="register__reflink-clear" @click="clearReferalCode">
							<MIcon icon="trash" :color="grey400" />
						</div>
					</div>
					<div class="register__checkbox">
						<div class="register__checkbox-wrapper-title">{{ $t('auth.termsInfoDescription') }}:</div>
						<MCheckbox
							id="input-register-rules"
							v-model="registerForm.rules"
							name="rules"
							:color="secondary400"
						>
							<template v-slot:beforeLink>
								<a href="/static/termOfUse" class="register__terms-link" target="_blank">{{ $t('auth.terms')}}*</a>
							</template>
						</MCheckbox>
						<MCheckbox
							id="input-register-marketing"
							v-model="registerForm.marketing"
							name="rulesMarketing"
							:color="secondary400"
						>
							<template v-slot:beforeLink>
								<div @click="openMarketingDialog" class="register__terms-link">{{ $t('auth.termsMarketing') }}*</div>
							</template>
						</MCheckbox>
						<MCheckbox
							id="input-register-partners"
							v-model="registerForm.partners"
							name="rulesPartners"
							:color="secondary400"
						>
							<template v-slot:beforeLink>
								<div @click="openPartnersDialog" class="register__terms-link">{{ $t('auth.termsPartners') }}</div>
							</template>
						</MCheckbox>
						<div class="register__checkbox-wrapper-title">{{ $t('components.requiredStar') }}</div>
					</div>
					<div class="register__btn-wrapper">
						<MButton
							id="register-submit-btn"
							:label="$t('auth.createAccount')"
							type="submit"
							green400
							width210
							big
							:loading="loading"
							:disabled="loading"
						/>
						<div class="register__text">{{ $t('auth.haveAcc') }}</div>
						<MButton
							id="register-back"
							:label="$t('auth.logIn')"
							type="text"
							blue400
							width210
							big
							@click="goBack"
						/>
					</div>
				</Form>
				
				<MDialog
					:content-title="$t('profile.marketingAgreement')"
					:is-open-dialog-action="isOpenMarketingDialog"
					:is-close-dialog-action="isCloseMarketingDialog"
					@close-dialog="closeDialogWhenNoTargetPressed"
					close-header-btn
					:width="700"
				>
					<template v-slot:default>
						<div>{{ $t('profile.marketingDescription') }}</div>
					</template>
				</MDialog>
				
				<MDialog
					:content-title="$t('auth.termsPartners')"
					:is-open-dialog-action="isOpenPartnersDialog"
					:is-close-dialog-action="isClosePartnersDialog"
					@close-dialog="closeDialogWhenNoTargetPressed"
					close-header-btn
					:width="700"
				>
					<template v-slot:default>
						<div>
							<div>{{ $t('profile.partnerDescriptionFirst') }}</div>
							<a href="/partners" target="_blank">{{ $t('profile.partnerDescriptionSecond') }}</a>
							<br><br>
							<div>{{ $t('profile.partnerDescriptionThird') }}</div>
							<br>
							<div>{{ $t('profile.partnerDescriptionFourth') }}</div>
						</div>
					</template>
				</MDialog>
			</template>
		</BeforeLoginLayout>
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import router from '@/router';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import { toast } from 'vue3-toastify';
import AuthApi from '@/api/v1/AuthApi';
import type { RegisterRequestInterface } from '@/models/AuthModel';
import variables from '@/assets/scss/modules/variables.module.scss';
import BeforeLoginLayout from '@/views/Layout/components/BeforeLoginLayout.vue';
import MButton from '@/components/atoms/MButton.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MCheckbox from '@/components/atoms/MCheckbox.vue';
import PasswordValidationToDoList from '@/components/molecules/PasswordValidationToDoList.vue';
import MDialog from '@/components/atoms/MDialog.vue';
import MIcon from '@/components/atoms/MIcon.vue';

const authApi = new AuthApi();

const { t } = useI18n();
const { secondary400, grey400 } = variables;
const { removeAuthorization } = useAuthStore();

const registerForm = ref<any>({
	first_name: null,
	last_name: null,
	email: null,
	phone: null,
	password: null,
	rules: false,
	marketing: false,
	partners: false,
	referralCode: null,
});
const loading = ref<boolean>(false);
const isOpenMarketingDialog = ref<boolean>(false);
const isOpenPartnersDialog = ref<boolean>(false);
const isCloseMarketingDialog = ref<boolean>(true);
const isClosePartnersDialog = ref<boolean>(true);
const isRefererInLocalStorage = ref<boolean>(false);

const schema:any = yup.object({
	firstName: yup.string().required(`${t('auth.validation.firstNameRequired')}`).min(3, `${t('auth.validation.passwordMin3')}`).label('firstName'),
	lastName: yup.string().required(`${t('auth.validation.lastNameRequired')}`).min(3, `${t('auth.validation.passwordMin3')}`).label('lastName'),
	email: yup.string().email(`${t('auth.validation.email')}`).required(`${t('auth.validation.emailRequired')}`).label('email'),
	phone: yup.string().nullable().label('phone'),
	password: yup.string().required(`${t('auth.validation.passwordRequired')}`).min(8, `${t('auth.validation.passwordMin')}`).label('password').test('password', `${t('auth.validation.passwordRegex')}`,
	function (val) {
		if (val.match(/((?=.*\d)(?=.*[A-Z])(?=.*\W)\w.{6,18}\w)/)) {
			return true;
		} else {
			return false;
		}
	}),
	referralCode: yup.string().nullable().label('referralCode'),
});

removeAuthorization();

const onSubmitRegister = async() => {
	loading.value = true;
	
	if (!registerForm.value.rules) {
		loading.value = false;
		toast.error(`${t('auth.toast.acceptTerms')}`);
		return;
	}
	
	if (!registerForm.value.marketing) {
		loading.value = false;
		toast.error(`${t('auth.toast.acceptMarketing')}`);
		return;
	}
	
	const request:RegisterRequestInterface|any = {
		first_name: registerForm.value.first_name,
		last_name: registerForm.value.last_name,
		email: registerForm.value.email,
		password: registerForm.value.password,
		'terms-and-condition': registerForm.value.rules,
		'marketing-agreement': registerForm.value.marketing,
		'partners-agreement': registerForm.value.partners,
		reflink: registerForm.value.referralCode,
	};
	if (registerForm.value.phone != null && registerForm.value.phone !== '') {
		request.phone = registerForm.value.phone;
	}
	// Potrzebne przekierowanie dla google analytics
	await router.push('/register?success');
	try {
		const result = await authApi.register(request);
		if (!result.isSuccess) {
			loading.value = false;
			toast.error(`${t('auth.toast.registerError')}`);
			return;
		}
	} catch (e) {
		loading.value = false;
		toast.error(`${t('auth.toast.registerError')}`);
		return;
	}
	// Potrzebne setTimeout dla google analytics aby najpierw url zmienił się z query paramsem
	setTimeout(async() => {
		await router.push('/');
	}, 1500);
	toast.success(`${t('auth.toast.registerSuccess')}`);
	loading.value = false;
};

const goBack = () => {
	router.push('/');
};

const closeDialogWhenNoTargetPressed = () => {
	isCloseMarketingDialog.value = true;
	isClosePartnersDialog.value = true;
	isOpenMarketingDialog.value = false;
	isOpenPartnersDialog.value = false;
};

const openMarketingDialog = () => {
	isCloseMarketingDialog.value = false;
	isOpenMarketingDialog.value = true;
};

const openPartnersDialog = () => {
	isClosePartnersDialog.value = false;
	isOpenPartnersDialog.value = true;
};

const checkRefererInLocalStorage = () => {
	if (undefined !== localStorage.getItem('m9-referer') && null != localStorage.getItem('m9-referer')) {
		isRefererInLocalStorage.value = true;
		registerForm.value.referralCode = localStorage.getItem('m9-referer');
	}
};
checkRefererInLocalStorage();

const clearReferalCode = () => {
	registerForm.value.referralCode = null;
	isRefererInLocalStorage.value = false;
};

watch((registerForm), (val) => {
	if (val.email != null) {
		registerForm.value.email = val.email.replace(' ', '');
	}
	if (val.password != null) {
		registerForm.value.password = val.password.replace(' ', '');
	}
},{
	deep: true,
})
</script>

<style scoped lang="scss">
.register {
	&__logo {
		width: 224px;
		height: 46px;
		margin: 0 auto 20px;
	}
	
	&__form-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		max-width: 300px;
		margin: 0 auto;
		
		@include media-breakpoint-up(sm) {
			max-width: 480px;
		}
	}
	
	&__validation {
		@include media-breakpoint-up(md) {
			width: 480px;
			margin: 0 auto;
		}
	}
	
	&__checkbox-wrapper-title {
		margin-left: 10px;
		font-size: 14px;
	}
	
	&__checkbox {
		margin: 0 auto;
		
		&:deep(.v-input__details) {
			display: none;
		}
	}
	
	&__terms-link {
		text-decoration: none;
		color: $secondary-400;
		font-size: 12px;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__btn-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}
	
	&__reflink-wrapper {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		gap: 10px;
		
		&:deep(.m-input) {
			@include media-breakpoint-up(md) {
				width: 480px;
				margin: 0 !important;
			}
		}
	}
	
	&__reflink-clear {
		margin-top: -18px;
		color: $secondary-400;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&:deep(.m-input) {
		max-width: 300px;
		margin: 0 auto;
		
		@include media-breakpoint-up(sm) {
			max-width: 480px;
		}
	}
	
	&__info-text {
		font-size: 16px;
		color: $primary-400;
		text-align: center;
		
		span {
			font-weight: 700;
			margin-right: 3px;
		}
		
		&--first {
			margin-top: -15px;
			font-size: 18px;
			font-weight: 700;
		}
	}
	
	&__text {
		font-size: 14px;
		width: max-content;
		font-weight: 700;
		margin: 0 auto;
		color: $primary-400;
	}
	
	&:deep(.v-selection-control__input) {
		color: $secondary-400;
	}
	
	&:deep(.v-selection-control .v-label) {
		font-size: 12px;
	}
	
	&:deep(.v-input--density-default) {
		--v-input-control-height: 36px;
	}
}
</style>
