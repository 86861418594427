import BaseApi from '@/api/BaseApi';
import type { TPaginate } from '@/models/GlobalModel';
import type { IWebsiteOffersLogList } from '@/models/LogModel';

export default class LogApi extends BaseApi {
	async websiteOffersLogHistory (page: number, perPage: number, loggableType: string, loggableId: number) {
		return this.get<TPaginate<IWebsiteOffersLogList>>({
			url: `api/v2/logs?page=${page}&per_page=${perPage}&loggable_type=${loggableType}&loggable_id=${loggableId}`,
		});
	};
};
