<template>
	<div class="m-select" :id="id">
	    <div v-if="label && !hideLabel" class="m-select__label">{{ label }}<span class="m-select__required-star">{{ labelRequired ? '*' : labelTextRequired ? ` ${$t('components.fieldRequired')}` : '' }}</span></div>
	    <v-select
            v-model="value"
            :placeholder="placeholder"
            :items="items"
            :item-value="itemValue"
            :item-title="itemTitle"
            :disabled="disabled"
            :single-line="!variant"
            :variant="variant"
            :required="required"
            :multiple="multiple"
            :clearable="clearable"
            :error-messages="errors"
            :return-object="returnObject"
            :class="{'m-select__disabled': disabled}"
            :no-data-text="$t('components.noDataText')"
            @change="onChange"
	    ></v-select>
    </div>
</template>

<script setup lang="ts">
import { defineProps, toRef, defineEmits, computed, withDefaults } from 'vue';
import { useField } from 'vee-validate';

interface IOwnProps {
    items: Array<any>
    modelValue?: any
    name?: string
    itemValue?: string
    itemTitle?: string
    label?: string
    labelRequired?: boolean
	labelTextRequired?: boolean
    placeholder?: string
    disabled?: boolean,
    variant?: 'filled'|'outlined'|'plain'|'underlined'|'solo'|'solo-inverted'|'solo-filled'|undefined
    required?: boolean
    multiple?: boolean
    clearable?: boolean
    onChange?: Function|Promise<any>
    hideLabel?: boolean
	returnObject?: boolean
    id?: string
}

interface IProps extends IOwnProps {
}

const props = withDefaults(defineProps<IProps>(), {
	variant: 'outlined',
});
const emit = defineEmits(['update:modelValue']);

const { errors } = useField(toRef<IOwnProps, any>(props, 'name'), undefined);

const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});
</script>

<style scoped lang="scss">
.m-select {
    &__required-star {
        color: $secondary-400;
    }

    &__label {
        color: $grey-400;
        font-weight: $weight-700;
        font-size: $size-14;
    }

    &__disabled {
	    &:deep(.v-input__slot fieldset) {
		    border: none;
	    }
	    &:deep(.v-input__slot) {
		    padding: 0 !important;
	    }
	    &:deep(.v-input__control) {
		    background-color: $grey-200;
	    }
    }
	
	&:deep(.v-selection-control__input) {
		color: $primary-400;
	}
	
	&:deep(.v-text-field--outlined>.v-input__control>.v-input__slot) {
		min-height: 43px;
	}
	
	&:deep(.v-input__slot) {
		border-radius: 0;
	}
	
	&:deep(.v-input__slot fieldset) {
		border-color: $black;
	}
	
	&:deep(.v-field__input) {
		padding: 12px;
		min-height: 46px;
	}
}
</style>
