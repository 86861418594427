<template>
	<div class="article-types-windows" :class="{'article-types-windows--publisher': isPublisherWindow, 'article-types-windows--order-article': isOrderArticleView}">
		<div v-for="articleType in articleTypes" :key="articleType.id">
			<div class="article-types-windows__item-wrapper" :class="{'article-types-windows__selected-window': selectedArticleTypeId === articleType.id}" @click="chooseArticleType(articleType)">
				<div class="article-types-windows__title">{{ isLanguagePl ? $t(`order.publication.${articleType.type}Article`) : $t(`order.publication.${articleType.type}ArticleNoPL`) }}</div>
				<div class="article-types-windows__tooltip">
					<MTooltip without-btn :max-width="400" :color="tooltipContentColor">
						<template v-slot:title>
							<div class="article-types-windows__icon-wrapper">
								<MIcon icon="question" width="14" height="14" :color="blue400" />
							</div>
						</template>
						<template v-slot:content>
							{{ isLanguagePl ? $t(`order.publication.${articleType.type}Info`) : $t(`order.publication.${articleType.type}InfoNoPL`) }}
						</template>
					</MTooltip>
				</div>
				<div class="article-types-windows__price">
					{{ netPrice(articleType.converted_net_price) }}
					<span>{{ userCurrencySymbol }}</span>
				</div>
				<div class="article-types-windows__net-text">{{ $t('components.net') }}</div>
				<div class="article-types-windows__btn-wrapper">{{ $t('components.choose') }}</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import variables from '@/assets/scss/modules/variables.module.scss';
import MTooltip from '@/components/atoms/MTooltip.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import type { IArticleTypes } from '@/models/ArticlesModel';
import type { TTooltipColors } from '@/components/atoms/MTooltip.vue';

interface IOwnProps {
	articleTypes: Array<IArticleTypes>
	modelValue: number|null
	isPublisherWindow?: boolean
	isOrderArticleView?: boolean
	tooltipContentColor?: TTooltipColors
	isLanguagePl?: boolean
}
interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();
const emit = defineEmits(['update:modelValue']);
const { userCurrencySymbol } = storeToRefs(useUserStore());
const { blue400 } = variables;

const selectedArticleTypeId = computed({
	get() {
		return props.modelValue;
	},
	set(value) {
		emit('update:modelValue', value);
	}
});

const netPrice = (price:number) => {
	return (price / 100).toFixed(2);
};

const chooseArticleType = (articleType:IArticleTypes) => {
	selectedArticleTypeId.value = articleType.id;
};
</script>

<style scoped lang="scss">
.article-types-windows {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	
	@include media-breakpoint-up(xl) {
		flex-direction: row;
		justify-content: space-between;
		width: 75%;
		margin: 0 auto;
	}
	
	&--publisher {
		width: 37%;
	}
	
	&--order-article {
		width: 100%;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;
		
		.article-types-windows__item-wrapper {
			max-width: 220px;
			
			@include media-breakpoint-up(xl) {
				max-width: 201px;
			}
			
			@include media-breakpoint-up(xxl) {
				max-width: 210px;
			}
			
			@media (min-width: 1700px) {
				max-width: 100%;
			}
		}
	}
	
	&__item-wrapper {
		display: flex;
		flex-direction: column;
		margin-bottom: 5px;
		padding: 25px;
		cursor: pointer;
		border: 2px solid transparent;
		border-radius: 8px;
		transition: opacity 0.3s, border 0.3s;
		
		&:hover {
			border-radius: 8px;
			border: 2px solid $secondary-400;
			opacity: 0.8;
		}
	}
	
	&__title {
		font-size: 18px;
		margin-bottom: 10px;
		
		@include media-breakpoint-up(sm) {
			font-size: 25px;
		}
	}
	
	&__tooltip {
		width: max-content;
		margin: 0 auto;
	}
	
	&__icon-wrapper {
		margin-bottom: 10px;
		border: 1px solid $blue-400;
		padding: 5px 7px;
		border-radius: 50%;
		cursor: default;
	}
	
	&__price {
		font-size: 18px;
		margin-bottom: 10px;
		
		@include media-breakpoint-up(sm) {
			font-size: 25px;
		}
		
		span {
			font-size: 14px;
			
			@include media-breakpoint-up(sm) {
				font-size: 20px;
			}
		}
	}
	
	&__net-text {
		margin-bottom: 20px;
		font-size: 12px;
		
		@include media-breakpoint-up(sm) {
			font-size: 18px;
		}
	}
	
	&__btn-wrapper {
		margin: 0 auto;
		padding: 5px 10px;
		width: max-content;
		border: 1px solid $secondary-400;
		border-radius: 6px;
	}
	
	&__selected-window {
		border-radius: 8px;
		border: 2px solid $secondary-400;
		
		.article-types-windows__btn-wrapper {
			background-color: $secondary-400;
		}
	}
}
</style>
