<template>
	<div class="website-requests-table">
		<v-data-table
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:items-per-page-options="itemsPerPageOptions"
			hover
		>
			<template v-slot:loading>
				<div>{{ $t('components.loading') }}</div>
			</template>
			
			<template #item.name="elem">
				<div v-if="null != elem.item.raw.name && null != elem.item.raw.surname">{{ elem.item.raw.name }} {{ elem.item.raw.surname }}</div>
			</template>
			
			<template #item.domain="elem">
				<div v-if="null != elem.item.raw.domain">
					<span class="website-requests-table__bold">Url: </span>
					{{ elem.item.raw.domain }}
				</div>
			</template>
			
			<template #item.created_at="elem">
				<div v-if="null != elem.item.raw.created_at">{{ dateFormat(elem.item.raw.created_at) }}</div>
			</template>
			
			<template #item.promotions="elem">
				<div v-if="null != elem.item.raw.url_follow && null != elem.item.raw.promotion_main_page && null != elem.item.raw.promotion_social_media">
					<div class="website-requests-table__promo-wrapper">
						<span class="website-requests-table__bold">{{ $t('order.websiteRequests.follow') }}</span>
						<MIcon v-if="elem.item.raw.url_follow === 0" icon="close" :color="secondary400" />
						<MIcon v-if="elem.item.raw.url_follow === 1" icon="tick" :color="green400" />
					</div>
					<div class="website-requests-table__promo-wrapper">
						<span class="website-requests-table__bold">{{ $t('order.websiteRequests.promotionMainPage') }}</span>
						<MIcon v-if="elem.item.raw.promotion_main_page === 0" icon="close" :color="secondary400" />
						<MIcon v-if="elem.item.raw.promotion_main_page === 1" icon="tick" :color="green400" />
					</div>
					<div class="website-requests-table__promo-wrapper">
						<span class="website-requests-table__bold">{{ $t('order.websiteRequests.promotionSocialMedia') }}</span>
						<MIcon v-if="elem.item.raw.promotion_social_media === 0" icon="close" :color="secondary400" />
						<MIcon v-if="elem.item.raw.promotion_social_media === 1" icon="tick" :color="green400" />
					</div>
				</div>
			</template>
			
			<template #item.status="elem">
				<div v-if="null != elem.item.raw.finished_at">
					<div class="website-requests-table__status-label">{{ $t('order.websiteRequests.finished') }}</div>
					<div>{{ dateFormat(elem.item.raw.finished_at) }}</div>
				</div>
				<div v-if="null == elem.item.raw.finished_at">
					<div class="website-requests-table__status-label website-requests-table__status-label--in-progres">{{ $t('order.websiteRequests.inProgress') }}</div>
				</div>
			</template>
			
			<template #item.actions="elem">
				<div class="website-requests-table__action-wrapper">
					<div v-if="null == elem.item.raw.finished_at" @click="approveWebsiteRequest(elem.item.raw.uuid)" id="website-request-submit" class="website-requests-table__action-btn-wrapper website-requests-table__action-btn-wrapper--green">
						<MIcon icon="tick" :color="white" />
					</div>
					<div @click="openDeleteDialog(elem.item.raw.uuid, elem.item.raw.email)" id="website-request-delete" class="website-requests-table__action-btn-wrapper">
						<MIcon icon="trash" :color="white" />
					</div>
				</div>
			</template>
		</v-data-table>
		
		<MDialog
			:is-close-dialog-action="isCloseDialog"
			:is-open-dialog-action="isOpenDialog"
			:content-title="$t('order.websiteRequests.deleteTitle')"
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<div class="website-requests-table__dialog-wrapper">
					<div class="website-requests-table__dialog-text">{{ $t('order.websiteRequests.deleteDescription') }} {{ websiteRequestOwner }}</div>
					<div class="website-requests-table__dialog-btn-wrapper">
						<MButton id="website-requests-close-modal-action" normal blue400 width100 :label="$t('components.close')" @click="closeDialogWhenNoTargetPressed" />
						<MButton id="website-requests-delete-action" normal red400 width100 :label="$t('components.delete')" @click="deleteWebsiteRequest" />
					</div>
				</div>
			</template>
		</MDialog>
	</div>
</template>

<script setup lang="ts">
import { VDataTable } from 'vuetify/labs/VDataTable';
import { defineEmits, ref } from 'vue';
import { storeToRefs } from 'pinia';
import router from '@/router';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { dateFormat } from '@/hooks/DataHooks';
import { isModerator } from '@/hooks/UserHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import { ROUTE_DASHBOARD } from '@/hooks/RoutingHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import WebsiteRequestApi from '@/api/v1/WebsiteRequestApi';
import type { TableHeaderInterface } from '@/models/VuetifyModel';
import type { WebsiteRequestsListInterface } from '@/models/WebsiteRequestModel';
import MIcon from '@/components/atoms/MIcon.vue';
import MDialog from '@/components/atoms/MDialog.vue';
import MButton from '@/components/atoms/MButton.vue';

const websiteRequestApi = new WebsiteRequestApi();

const emit = defineEmits(['itemsCounter']);
const { user } = storeToRefs(useUserStore());
const { t } = useI18n();
const { white, secondary400, green400 } = variables;

let isCloseDialog = ref<boolean>(true);
let isOpenDialog = ref<boolean>(false);
const websiteRequestOwner = ref<string>('');
const websiteRequestUuid = ref<string>('');
const loading = ref<boolean>(false);
const items = ref<Array<WebsiteRequestsListInterface>>([]);
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('order.websiteRequests.nameAndSurname')}`,
		align: 'center',
		sortable: false,
		key: 'name',
		width: '110px',
	},
	{
		title: `${t('order.websiteRequests.email')}`,
		align: 'center',
		sortable: false,
		key: 'email',
		width: '200px',
	},
	{
		title: `${t('order.websiteRequests.domainUrl')}`,
		align: 'center',
		sortable: false,
		key: 'domain',
		width: '200px',
	},
	{
		title: `${t('order.websiteRequests.comment')}`,
		align: 'center',
		sortable: false,
		key: 'comment',
		width: '300px',
	},
	{
		title: `${t('order.websiteRequests.createdAt')}`,
		align: 'center',
		sortable: false,
		key: 'created_at',
		width: '96px',
	},
	{
		title: `${t('order.websiteRequests.promotions')}`,
		align: 'center',
		sortable: false,
		key: 'promotions',
		width: '300px',
	},
	{
		title: `${t('order.websiteRequests.status')}`,
		align: 'center',
		sortable: false,
		key: 'status',
		width: '140px',
	},
	{
		title: `${t('order.websiteRequests.actions')}`,
		align: 'center',
		sortable: false,
		key: 'actions',
		width: '140px',
	},
]);

const isModeratorDashboard = ref<boolean>(false);
if (null != user.value && isModerator(user.value) && router.currentRoute.value.name === ROUTE_DASHBOARD) {
	isModeratorDashboard.value = true;
}

const getWebsiteRequestsList = async() => {
	loading.value = true;
	try {
		const result = await websiteRequestApi.websiteRequestsList();
		if (!result.isSuccess) {
			toast.error(`${t('order.websiteRequests.toast.listError')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload;
		if (isModeratorDashboard.value) {
			emit('itemsCounter', items.value.length);
		}
	} catch (e) {
		toast.error(`${t('order.websiteRequests.toast.listError')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
getWebsiteRequestsList();
const approveWebsiteRequest = async(uuid:string) => {
	loading.value = true;
	try {
		const result = await websiteRequestApi.submitWebsiteRequest(uuid);
		if (!result.isSuccess) {
			toast.error(`${t('order.websiteRequests.toast.submitWebsiteError')}`);
			loading.value = false;
			return;
		}
	} catch (e) {
		toast.error(`${t('order.websiteRequests.toast.submitWebsiteError')}`);
		loading.value = false;
		return;
	}
	toast.success(`${t('order.websiteRequests.toast.submitWebsiteSuccess')}`);
	await getWebsiteRequestsList();
	loading.value = false;
};
const deleteWebsiteRequest = async() => {
	loading.value = true;
	isCloseDialog.value = true;
	isOpenDialog.value = false;
	try {
		const result = await websiteRequestApi.deleteWebsiteRequest(websiteRequestUuid.value);
		
		if (!result.isSuccess) {
			toast.error(`${t('order.websiteRequests.toast.deleteWebsiteError')}`);
			loading.value = false;
			return;
		}
	} catch (e) {
		toast.error(`${t('order.websiteRequests.toast.deleteWebsiteError')}`);
		loading.value = false;
		return;
	}
	toast.success(`${t('order.websiteRequests.toast.deleteWebsiteSuccess')}`);
	await getWebsiteRequestsList();
	loading.value = false;
};

const openDeleteDialog = (uuid:string, name:string) => {
	websiteRequestOwner.value = name;
	websiteRequestUuid.value = uuid;
	isCloseDialog.value = false;
	isOpenDialog.value = true;
};

const closeDialogWhenNoTargetPressed = () => {
	isCloseDialog.value = true;
	isOpenDialog.value = false;
};
</script>

<style scoped lang="scss">
.website-requests-table {
	@import '@/assets/scss/vuetifyDataTable';
	&__bold {
		width: max-content;
		font-weight: 700;
	}
	
	&__promo-wrapper {
		display: flex;
		align-items: center;
		gap: 8px;
		margin: 5px 0;
	}
	
	&__action-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
	
	&__action-btn-wrapper {
		width: max-content;
		background-color: $secondary-400;
		padding: 9px 10px 5px 10px;
		border-radius: 50%;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		&--green {
			background-color: $green-400;
		}
	}
	
	&__status-label {
		background-color: $green-400;
		padding: 5px 5px;
		border-radius: 8px;
		color: $white;
		font-weight: 700;
		text-align: center;
		width: 100px;
		
		&--in-progres {
			background-color: $yellow-500;
		}
	}
	
	&__dialog-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}
	
	&__dialog-text {
		font-size: 11px;
		font-weight: 700;
		color: $primary-400;
	}
	
	&__dialog-btn-wrapper {
		display: flex;
		flex-direction: row-reverse;
		gap: 10px;
	}
}
</style>
