<template>
	<div class="banners-form">
		<HeadingLabel
			icon="banner"
			width="32"
			height="29"
			:text="isEditMode ? $t('banners.editBanner') : $t('banners.addBanner')"
		/>
		
		<div v-if="isEditMode && bannerUrl" class="banners-form__img-wrapper">
			<img :src="bannerUrl" alt="banner">
		</div>
		
		<Form :validation-schema="schema" @submit="onSubmit" class="banners-form__form-wrapper">
			<MTextField
				id="banner-name"
				v-model="form.name"
				name="name"
				:separate-label="$t('banners.name')"
				:placeholder="$t('banners.name')"
			/>
			<MTextField
				id="banner-file"
				v-model="fileName"
				ref="form.image"
				name="file"
				:separate-label="$t('banners.file')"
				type="file"
				:placeholder="$t('banners.file')"
				:label-required="!isEditMode"
				@change="onPhotoChange"
			/>
			<MSelect
				id="banner-dashboard"
				v-model="form.slugs"
				name="dashboard"
				:label="$t('banners.dashboard')"
				:placeholder="$t('banners.dashboard')"
				:items="dashboardItems"
				item-value="slug"
				item-title="name"
				clearable
				multiple
				label-required
			/>
			<div class="banners-form__text">{{ $t('banners.publicationDate') }}<span>*</span></div>
			<div class="banners-form__date-wrapper">
				<MDatePicker
					id="banner-date-start"
					v-model="form.start"
					name="publicationDate"
					:placeholder="$t('banners.publicationDate')"
					:language="localeLanguage"
					:disabled-dates="{
						from: new Date(+dayjs(form.finish).format('YYYY'), +dayjs(form.finish).format('MM') - 1, +dayjs(form.finish).format('DD')),
					}"
					clearable
				/>
				<MTextField
					id="banner-date-start-hours"
					v-model="hour"
					name="publicationHour"
					:separate-label="$t('banners.publicationHour')"
					:placeholder="$t('banners.publicationHour')"
					type="number"
					max="23"
					min="0"
				/>
			</div>
			<div class="banners-form__text">{{ $t('banners.publicationEndDate') }}</div>
			<div class="banners-form__date-wrapper">
				<MDatePicker
					id="banner-date-finish"
					v-model="form.finish"
					name="publicationEndDate"
					:placeholder="$t('banners.publicationEndDate')"
					:language="localeLanguage"
					:disabled-dates="{
						to: new Date(+dayjs(form.start).format('YYYY'), +dayjs(form.start).format('MM') - 1, +dayjs(form.start).format('DD') + 1),
					}"
					clearable
				/>
				<MTextField
					id="banner-date-finish-hours"
					v-model="hourEnd"
					name="publicationEndHour"
					:separate-label="$t('banners.publicationEndHour')"
					:placeholder="$t('banners.publicationEndHour')"
					type="number"
					max="23"
					min="0"
				/>
			</div>
			<MTextField
				id="banner-url"
				v-model="form.url"
				name="url"
				:separate-label="$t('banners.url')"
				:placeholder="$t('banners.url')"
				:hint="$t('banners.addHttps')"
			/>
			<div class="banners-form__btn-wrapper">
				<MButton
					id="banner-back"
					type="button"
					blue400
					normal
					width100
					:label="$t('banners.back')"
					@click="backAction"
				/>
				<MButton
					id="banner-submit-form"
					type="submit"
					green400
					normal
					width100
					:label="$t('banners.save')"
				/>
			</div>
		</Form>
		
		<MLoading v-show="loading" />
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import router from '@/router';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@/stores/session';
import dayjs from 'dayjs';
import BannersApi from '@/api/v1/BannersApi';
import { toast } from 'vue3-toastify';
import { ADVERTISER_DASHBOARD_BANNER_SLUG, PUBLISHER_DASHBOARD_BANNER_SLUG } from '@/hooks/BannersHooks';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MSelect from '@/components/atoms/MSelect.vue';
import MButton from '@/components/atoms/MButton.vue';
import MLoading from '@/components/atoms/MLoading.vue';
import MDatePicker from '@/components/atoms/MDatePicker.vue';

const bannersApi = new BannersApi();

const { t } = useI18n();
const { locale } = storeToRefs(useSessionStore());
const localeLanguage = ref('');

if ('us' === locale.value) {
	localeLanguage.value = 'en';
} else if (locale.value != null) {
	localeLanguage.value = locale.value;
} else {
	localeLanguage.value = 'pl';
}

const hour = ref<number|any>(0);
const hourEnd = ref<number|any>(0);
const form = ref<any>({
	image: null,
	slugs: null,
	start: null,
	finish: null,
	name: null,
	url: null,
});
const fileName = ref<any>(null);
const loading = ref<boolean>(false);

const dashboardItems = ref<Array<any>>([
	{
		slug: ADVERTISER_DASHBOARD_BANNER_SLUG,
		name: `${t('banners.advertiserDashboard')}`
	},
	{
		slug: PUBLISHER_DASHBOARD_BANNER_SLUG,
		name: `${t('banners.publisherDashboard')}`
	},
]);

const schema:any = yup.object({
	name: yup.string().required(`${t('banners.validation.requiredName')}`).label('Name'),
	dashboard: yup.array().required(`${t('banners.validation.requiredDashboard')}`).label('Name'),
	publicationDate: yup.string().required(`${t('banners.validation.requiredDate')}`).label('Name'),
	publicationHour: yup.number().max(23, `${t('banners.validation.maxTime')}`).min(0, `${t('banners.validation.minTime')}`).label('Name'),
	publicationEndHour: yup.number().max(23, `${t('banners.validation.maxTime')}`).min(0, `${t('banners.validation.minTime')}`).label('Name'),
});

const isEditMode = ref<boolean>(false);
const bannerUuid = ref<string|Array<string>>('');
const bannerUrl = ref<string|null>(null);
if (router.currentRoute.value.fullPath !== '/banners/create') {
	isEditMode.value = true;
	bannerUuid.value = router.currentRoute.value.params.uuid;
} else {
	schema.fields.file = yup.string().required(`${t('banners.validation.requiredFile')}`).label('Name');
	schema._nodes.push('file');
}

watch([hour, hourEnd], ([hourVal, hourEndVal]) => {
	if ('' === hourVal) {
		hour.value = 0;
	}
	if ('' === hourEndVal) {
		hourEnd.value = 0;
	}
});

const onPhotoChange = (event:any) => {
	const file = event.target.files[0];
	form.value.image = file;
};

const onSubmit = async() => {
	loading.value = true;
	
	form.value.start = dayjs(form.value.start).set('hour', hour.value || 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD HH:mm:ss');
	
	if (null != form.value.finish) {
		form.value.finish = dayjs(form.value.finish).set('hour', hourEnd.value || 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD HH:mm:ss');
	}
	
	const formBanners = {
		image: form.value.image,
		slugs: form.value.slugs,
		start: form.value.start,
		finish: form.value.finish,
		name: form.value.name,
		...(null !== form.value.url && '' !== form.value.url
			? { url: form.value.url }
			: null),
	}
	if (!isEditMode.value) {
		try {
			const result = await bannersApi.createBanner(formBanners);
			if (!result.isSuccess) {
				toast.error(`${t('banners.toast.errorCreateBanner')}`);
				loading.value = false;
				return;
			}
		} catch (e) {
			toast.error(`${t('banners.toast.errorCreateBanner')}`);
			loading.value = false;
			return;
		}
		toast.success(`${t('banners.toast.successCreateBanner')}`);
	} else {
		if (null != formBanners.slugs && null != formBanners.slugs[0].uuid) {
			const arr:Array<string> = [];
			formBanners.slugs.forEach((elem:any) => {
				arr.push(elem.slug);
			});
			formBanners.slugs = arr;
		}
		if (null == formBanners.finish) {
			formBanners.finish = '';
		}
		try {
			const result = await bannersApi.editBanner(formBanners, bannerUuid.value);
			if (!result.isSuccess) {
				toast.error(`${t('banners.toast.errorEditBanner')}`);
				loading.value = false;
				return;
			}
		} catch (e) {
			toast.error(`${t('banners.toast.errorEditBanner')}`);
			loading.value = false;
			return;
		}
		toast.success(`${t('banners.toast.successEditBanner')}`);
	}
	loading.value = false;
	await router.push('/banners');
};

const backAction = () => {
	router.push('/banners');
};

const bannerDetails = async () => {
	loading.value = true;
	try {
		const result = await bannersApi.getBanner(bannerUuid.value);
		if (!result.isSuccess) {
			toast.error(`${t('banners.toast.errorGetBannerDetails')}`);
			loading.value = false;
			return;
		}
		form.value.start = result.payload.start;
		form.value.finish = result.payload.finish;
		form.value.name = result.payload.name;
		form.value.url = result.payload.url;
		form.value.slugs = result.payload.placeholders;
		hour.value = dayjs(result.payload.start).hour();
		hourEnd.value = null != result.payload.finish ? dayjs(result.payload.finish).hour() : 0;
		bannerUrl.value = result.payload.images[0].url;
	} catch (e) {
		toast.error(`${t('banners.toast.errorGetBannerDetails')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

if (isEditMode.value) {
	bannerDetails();
}
</script>

<style scoped lang="scss">
.banners-form {
	&__form-wrapper {
		margin-top: 5px;
		padding: 15px;
		background-color: $white-100;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	
	&__btn-wrapper {
		margin-top: 10px;
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		width: 100%;
	}
	
	&__text {
		color: $grey-400;
		font-weight: 700;
		font-size: 14px;
		margin-bottom: -10px;
		
		span {
			color: $secondary-400;
		}
	}
	
	&__date-wrapper {
		display: flex;
		gap: 10px;
		align-items: center;
		
		&:deep(.m-date-picker) {
			width: 50%;
		}
		
		&:deep(.m-input) {
			margin-top: -23px;
			width: 50%;
		}
	}
	
	&__img-wrapper {
		width: 100%;
		margin: 5px auto;

		img {
			width: 100%;
		}
	}
}
</style>
