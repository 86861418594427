import BaseApi from '@/api/BaseApi';
import type {
	ITransferBetweenAccountList,
	ITransferBetweenAccounts,
	IWithdrawalDetails,
	IWithdrawalRequest,
	IWithdrawalStatusChange,
	IWithdrawRequestList,
} from '@/models/WithdrawalModel';

export default class WithdrawApi extends BaseApi {
	async withdrawRequestList (page: number, perPage: number, sortBy?: string|null, sortDesc?: string|null) {
		let url = `api/v2/withdrawal/list?page=${page}&per_page=${perPage}`;

		if (null != sortBy) {
			url += `&sort=${sortBy}`;
		} else {
			url += '&sort=created_at';
		}

		if (null != sortDesc) {
			url += `&order=${sortDesc}`;
		} else {
			url += '&order=desc';
		}
		return this.get<IWithdrawRequestList>({
			url: url,
		});
	};

	async withdrawRequest (params:IWithdrawalRequest) {
		const data = new FormData();
		data.append('method', params.method);
		data.append('user_id', params.user_id);
		data.append('amount', params.amount);
		data.append('currency_id', params.currency_id);
		data.append('invoice', params.invoice);
		data.append('bank_name', params.bank_name);
		data.append('account_owner', params.account_owner);
		data.append('account_number', params.account_number);
		data.append('paypal_id', params.paypal_id);
		data.append('sort_code', params.sort_code);
		data.append('ifsc_code', params.ifsc_code);
		data.append('account_id', params.account_id);
		return this.post<IWithdrawalRequest, any>({
			url: 'api/v2/withdrawal/create',
			// @ts-ignore
			data,
			config: {
				contentType: 'multipart/form-data',
			},
		});
	};

	async transferBetweenAccounts (params:ITransferBetweenAccounts) {
		return this.post<ITransferBetweenAccounts, any>({
			url: 'api/v2/transfer-between-accounts',
			data: params
		});
	};

	async transferBetweenAccountsList (page: number, perPage: number, sortBy?: string|null, sortDesc?: string|null) {
		return this.get<ITransferBetweenAccountList>({
			url: `api/v2/transfer-between-accounts/list?page=${page}&per_page=${perPage}`,
		});
	};

	async withdrawDetails (withdrawUuid: string|Array<string>|null) {
		return this.get<IWithdrawalDetails>({
			url: `api/v2/withdrawal/${withdrawUuid}`,
		});
	};

	async changeWithdrawStatus (params:IWithdrawalStatusChange) {
		return this.patch<IWithdrawalStatusChange, any>({
			url: `api/v2/withdrawal/update/status`,
			data: params,
		});
	};

	async getWithdrawalInvoice (id: string|Array<string>|null) {
		return this.get<Blob|any>({
			url: `/api/v2/withdrawal/invoice/${id}`,
		});
	};
};
