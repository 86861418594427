export const useChangeCurrencyIdToSymbol = (currencyId: number | null | undefined) => {
	switch (currencyId) {
		case 1:
			return '$'
		case 2:
			return '€'
		case 3:
			return '£'
		case 4:
			return 'zł'
		default:
			return 'zł'
	}
};
