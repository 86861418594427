<template>
	<div class="table-settings-menu-with-modal">
		<MButton @click="openTableColumnModal" transparent-with-border :label="$t('components.tableSettings')" normal />
		
		<MDialog
			:is-open-dialog-action="isOpenTableColumnModal"
			:is-close-dialog-action="isCloseTableColumnModal"
			is-select-footer-btn
			:content-title="$t('components.changeColumns')"
			:close-btn="false"
			close-header-btn
			@closeDialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<div class="table-settings-menu-with-modal__draggable-title-wrapper">
					<div class="table-settings-menu-with-modal__draggable-title">{{ $t('components.activeColumns') }}</div>
					<div class="table-settings-menu-with-modal__draggable-title">{{ $t('components.inactiveColumns') }}</div>
				</div>
				<div class="table-settings-menu-with-modal__draggable-wrapper">
					<draggable
						class="table-settings-menu-with-modal__draggable-left"
						:list="activeColumns"
						group="activeColumns"
						@change="changeAction"
						itemKey="activeColumns"
					>
						<template #item="{ element }">
							<div class="table-settings-menu-with-modal__draggable-item">
								<div class="table-settings-menu-with-modal__draggable-index">{{ activeColumns.indexOf(element) + 1 }}</div>
								<div>{{ element.title }}</div>
							</div>
						</template>
					</draggable>
					<draggable
						class="table-settings-menu-with-modal__draggable-right"
						:list="inactiveColumns"
						group="activeColumns"
						@change="changeAction"
						itemKey="activeColumns"
					>
						<template #item="{ element }">
							<div class="table-settings-menu-with-modal__draggable-item table-settings-menu-with-modal__draggable-item--inactive">
								<div>{{ element.title }}</div>
							</div>
						</template>
					</draggable>
				</div>
				<div class="table-settings-menu-with-modal__clear-btn" @click="onClearAction">{{ $t('components.clear') }}</div>
			</template>
		</MDialog>
	</div>
</template>

<script setup lang="ts">
import draggable from 'vuedraggable';
import { ref, defineProps, watch, defineEmits } from 'vue';
import { storeToRefs } from 'pinia';
import { useTableSettingsStore } from '@/stores/tableSettings';
import { useUserStore } from '@/stores/user';
import { editTableSettings } from '@/hooks/TableSettingsHooks';
import type { TableSettingsRequestInterface } from '@/models/TableSettingsModel';
import type { TableHeaderInterface } from '@/models/VuetifyModel';
import { COLUMN_ACTIONS, COLUMN_BASKET_LINK, COLUMN_DATA_TABLE_EXPAND } from '@/hooks/TableSettingsHooks';
import MDialog from '@/components/atoms/MDialog.vue';
import MButton from '@/components/atoms/MButton.vue';

interface IOwnProps {
	tableName: string
	isClearedTableSettingsAction: boolean
	onClearAction: () => {}
}

interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();
const { setHeaders } = useTableSettingsStore();
const { user } = storeToRefs(useUserStore());
const emit = defineEmits(['headersMove']);

const isOpenTableColumnModal = ref<boolean>(false);
const isCloseTableColumnModal = ref<boolean>(true);
const activeColumns = ref<Array<TableHeaderInterface|any>>([]);
const inactiveColumns = ref<Array<TableHeaderInterface|any>>([]);
const currentActiveTableHeaders = ref<Array<TableHeaderInterface|any>>([]);
const currentInactiveTableHeaders = ref<Array<TableHeaderInterface|any>>([]);
const disabledColumns = ref<Array<TableHeaderInterface|any>>([]);
let isMoveAction:boolean = false;

const add = (evt:any) => {

};
const replace = (evt:any) => {

};
const clone = (evt:any) => {

};
const changeAction = async(evt:any) => {
	isMoveAction = true;
	await setHeaders({
		name: props.tableName,
		headers: {
			active: 0 === disabledColumns.value.length ? activeColumns.value : activeColumns.value.concat(disabledColumns.value),
			inactive: inactiveColumns.value,
		}
	});
	
	const tableSettings:TableSettingsRequestInterface = {
		sort_column: null != storeToRefs(useTableSettingsStore())[props.tableName].value.sort_column && 0 < storeToRefs(useTableSettingsStore())[props.tableName].value.sort_column.length ? storeToRefs(useTableSettingsStore())[props.tableName].value.sort_column : null,
		sort_order: null != storeToRefs(useTableSettingsStore())[props.tableName].value.sort_order && 0 < storeToRefs(useTableSettingsStore())[props.tableName].value.sort_order.length ? storeToRefs(useTableSettingsStore())[props.tableName].value.sort_order : null,
		page: storeToRefs(useTableSettingsStore())[props.tableName].value.page,
		per_page: storeToRefs(useTableSettingsStore())[props.tableName].value.per_page,
		filters: JSON.stringify(storeToRefs(useTableSettingsStore())[props.tableName].value.filters),
		columns: JSON.stringify(storeToRefs(useTableSettingsStore())[props.tableName].value.columns),
	};
	if (isMoveAction && null != user.value) {
		isMoveAction = false;
		await editTableSettings(props.tableName, tableSettings, user.value.active_role_id);
		emit('headersMove');
	}
};

const findDisabledColumns = () => {
	disabledColumns.value = [];
	// Wprowadz nazwe kolumny która ma zostac nie ruszona oraz w ponizszym if'ie uwzglednij to
	// reszta zadziala z automatu, PS: jezeli chcesz zeby kolumna akcje byla zawsze ostatnia to niech
	// if z pushem bedzie na samym koncu
	const actionsColumn = activeColumns.value.find(elem => COLUMN_ACTIONS === elem.key);
	const dataTableExpandColumn = activeColumns.value.find(elem => COLUMN_DATA_TABLE_EXPAND === elem.key);
	const basketLinkColumn = activeColumns.value.find(elem => COLUMN_BASKET_LINK === elem.key);
	
	if (null != actionsColumn) {
		disabledColumns.value.push(actionsColumn);
	}
	
	if (null != basketLinkColumn) {
		disabledColumns.value.push(basketLinkColumn);
	}
	
	if (null != dataTableExpandColumn) {
		disabledColumns.value.push(dataTableExpandColumn);
	}
	
	disabledColumns.value.forEach(elem => {
		const index = activeColumns.value.indexOf(elem)
		activeColumns.value.splice(index, 1);
	})
};
const setActiveColumns = () => {
	currentActiveTableHeaders.value = storeToRefs(useTableSettingsStore())[props.tableName].value.columns.active;
	
	activeColumns.value = currentActiveTableHeaders.value;
	activeColumns.value = activeColumns.value.map(elem => {
		return {
			...elem
		}
	});
	findDisabledColumns();
};
const setInactiveColumns = () => {
	currentInactiveTableHeaders.value = storeToRefs(useTableSettingsStore())[props.tableName].value.columns.inactive;
	inactiveColumns.value = currentInactiveTableHeaders.value;
	inactiveColumns.value = inactiveColumns.value.map(elem => {
		return {
			...elem
		}
	});
};
setActiveColumns();
setInactiveColumns();
const closeDialogWhenNoTargetPressed = () => {
	isOpenTableColumnModal.value = false;
	isCloseTableColumnModal.value = true;
};
const openTableColumnModal = () => {
	isOpenTableColumnModal.value = true;
	isCloseTableColumnModal.value = false;
};

watch((props), (val) => {
	if (val.isClearedTableSettingsAction) {
		setActiveColumns();
		setInactiveColumns();
	}
},
{
	deep: true,
});
</script>

<style scoped lang="scss">
.table-settings-menu-with-modal {
	position: relative;
	
	&__draggable-title-wrapper {
		display: flex;
		justify-content: space-between;
		gap: 10px;
	}
	
	&__draggable-title {
		width: 50%;
		text-align: center;
		font-size: $size-18;
		color: $grey-400;
		font-weight: 700;
		margin-bottom: 8px;
	}
	
	&__draggable-wrapper {
		display: flex;
		justify-content: space-between;
		gap: 10px;
	}
	
	&__draggable-left,
	&__draggable-right {
		width: 50%;
		border: 1px solid $grey-400;
		border-radius: 8px;
		padding: 10px;
	}
	
	&__draggable-item {
		display: flex;
		gap: 10px;
		align-items: center;
		background-color: $primary-400;
		color: $white-100;
		font-weight: 700;
		padding: 5px 10px;
		border-radius: 8px;
		margin-top: 5px;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		&--inactive {
			padding: 7px 10px;
		}
	}
	
	&__draggable-index {
		color: $primary-400;
		background-color: $white-100;
		border-radius: 50%;
		padding: 2px 7px 2px 8px;
	}
	
	&:deep(.m-btn__normal) {
		padding: 20.5px 18px !important;
	}
	
	&__clear-btn {
		display: flex;
		justify-content: flex-end;
		font-size: $size-14;
		font-weight: 700;
		color: $secondary-400;
		margin-left: auto;
		margin-top: 10px;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5
		}
	}
}
</style>
