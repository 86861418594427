export const ROUTE_DASHBOARD = 'Dashboard';
export const ROUTE_BASKET_LIST = 'BasketList';
export const ROUTE_ORDER_LIST = 'OrderList';
export const ROUTE_GOOGLE_UPLOAD_ARTICLE = 'UploadArticleGoogleDrive';
export const ROUTE_EXPORT_LIST = 'ExportList';
export const ROUTE_IMPORT_LIST = 'Import';
export const ROUTE_IMPORT_PREVIEW_READ_ONLY = 'ImportPreviewReadOnly';
export const ROUTE_EDIT_PROFILE = 'ProfileEdit';
export const ROUTE_PROFILE = 'Profile';
export const ROUTE_PAYMENTS_SUMMARY_COMPLETED = 'PaymentsSummaryCompleted';
export const ROUTE_CART_PREVIEW = 'CartPreview';
export const ROUTE_WEBSITE_DETAILS = 'WebsiteDetails';
export const ROUTE_OFFER_DETAILS = 'OffersDetails';
export const ROUTE_OFFER_LIST = 'OffersList';
export const ROUTE_ORDER_SELECT_CAMPAIGN = 'OrderSelectCampaign';
export const ROUTE_ORDER_PUBLICATION = 'OrderPublication';
