<template>
	<div v-if="items" class="coupons-table">
		<v-data-table
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:items-per-page-options="itemsPerPageOptions"
			hover
		>
			<template v-slot:loading>
				<div>{{ $t('components.loading') }}</div>
			</template>
<!--			<template v-slot:top>-->
<!--				<v-toolbar flat color="white" class="coupons-table__search-wrapper">-->
<!--					<span>{{ $t('coupons.search') }}:</span>-->
<!--					<m-text-field-->
<!--						v-model="search"-->
<!--						:label="$t('coupons.search')"-->
<!--					></m-text-field>-->
<!--				</v-toolbar>-->
<!--			</template>-->
			
			<template v-slot:item.is_active="elem">
				<div v-if="elem.item.raw.is_active === 1" class="coupons-table__active">{{ $t('coupons.active') }}</div>
				<div v-if="elem.item.raw.is_active === 0" class="coupons-table__expired">{{ $t('coupons.used') }}</div>
			</template>
			
			<template v-slot:item.type="elem">
				<div v-if="elem.item.raw.type === 'PERCENT'" class="coupons-table__type">%</div>
				<div v-if="elem.item.raw.type === 'CACHE'" class="coupons-table__type">{{ userCurrencySymbol }}</div>
			</template>
			
			<template v-slot:item.value="elem">
				<div v-if="elem.item.raw.type === 'PERCENT'">{{ elem.item.raw.value }}</div>
				<div v-if="elem.item.raw.type === 'CACHE'">{{ elem.item.raw.value / 100 }}</div>
			</template>
			
			<template v-slot:item.amount="elem">
				<div v-if="elem.item.raw.amount === null">-</div>
				<div v-else>{{ elem.item.raw.amount }}</div>
			</template>
			
			<template v-slot:item.expired="elem">
				<div class="m9-data-table-align-left"><span class="coupons-table__bold-text">{{ $t('coupons.startedAt') }}:</span> {{ elem.item.raw.started_at }}</div>
				<div class="m9-data-table-align-left"><span class="coupons-table__bold-text">{{ $t('coupons.endedAt') }}:</span> {{ elem.item.raw.ended_at }}</div>
			</template>
			
			<template v-slot:item.actions="elem">
				<div class="coupons-table__action-wrapper">
					<MButton @click="editCoupon(elem.item.raw.uuid)" icon="edit" :color="white" grey400 class="coupons-table__action-wrapper--first-btn" />
					<MButton @click="deleteCoupon(elem.item.raw.uuid)" icon="close" :color="white" grey400 width="14" height="14" />
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script setup lang="ts">
import { VDataTable } from 'vuetify/labs/VDataTable';
import { ref } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';
import { toast } from 'vue3-toastify';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { TableHeaderInterface } from '@/models/VuetifyModel';
import type { CouponListModelInterface } from '@/models/CouponsModel';
import CouponsApi from '@/api/v1/CouponsApi';
import MButton from '@/components/atoms/MButton.vue';

const couponsApi = new CouponsApi();

const { userCurrencySymbol } = useUserStore();
const { white } = variables;
const { t } = useI18n();

const loading = ref<boolean>(false);
const items = ref<Array<CouponListModelInterface>|any>(null);
const search = ref<string|null>(null);
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('coupons.code')}`,
		align: 'center',
		sortable: true,
		key: 'code'
	},
	{
		title: `${t('coupons.isActive')}`,
		align: 'center',
		sortable: true,
		key: 'is_active'
	},
	{
		title: `${t('coupons.type')}`,
		align: 'center',
		sortable: true,
		key: 'type'
	},
	{
		title: `${t('coupons.value')}`,
		align: 'center',
		sortable: true,
		key: 'value'
	},
	{
		title: `${t('coupons.amount')}`,
		align: 'center',
		sortable: true,
		key: 'used'
	},
	{
		title: `${t('coupons.amountPerUser')}`,
		align: 'center',
		sortable: true,
		key: 'amount_per_user'
	},
	{
		title: `${t('coupons.totalCoupons')}`,
		align: 'center',
		sortable: true,
		key: 'amount'
	},
	{
		title: `${t('coupons.expired')}`,
		align: 'center',
		sortable: true,
		key: 'expired'
	},
	{
		title: `${t('coupons.actions')}`,
		align: 'center',
		sortable: false,
		key: 'actions'
	}
]);

const getCoupons = async() => {
	loading.value = true;
	try {
		const result = await couponsApi.getCouponsList();
		if (!result.isSuccess) {
			toast.error(`${t('coupons.toast.error')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload.reverse();
	} catch (e) {
		toast.error(`${t('coupons.toast.error')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
getCoupons();

const editCoupon = (couponUuid:string) => {
	if ('' === couponUuid) {
		toast.error(`${t('coupons.toast.error')}`);
	} else {
		router.push(`/coupons/${couponUuid}`);
	}
};

const deleteCoupon = async(couponUuid:string) => {
	loading.value = true;
	try {
		const result = await couponsApi.deleteCurrentCoupon(couponUuid);
		if (!result.isSuccess) {
			toast.error(`${t('coupons.toast.error')}`);
			loading.value = false;
			return;
		}
		await getCoupons();
	} catch (e) {
		toast.error(`${t('coupons.toast.error')}`);
		loading.value = false;
		return;
	}
	toast.error(`${t('coupons.toast.successDelete')}`);
	loading.value = false;
};
</script>

<style scoped lang="scss">
.coupons-table {
	@import '@/assets/scss/vuetifyDataTable';
	&__active {
		text-align: center;
		border-bottom: 4px solid $green-400;
		padding: 21px 0;
	}
	
	&__expired {
		text-align: center;
		border-bottom: 4px solid $secondary-400;
		padding: 21px 0;
	}
	
	&__type {
		font-weight: $weight-700;
		font-size: $size-16;
	}
	
	&__action-wrapper {
		display: flex;
		justify-content: center;
		
		button {
			padding: 9px 4px 10px 10px;
			width: 40px;
			min-width: 40px;
			border-radius: 50%;
			background-color: $secondary-400 !important;
			
			&:nth-child(1) {
				background-color: $primary-400 !important;
			}
		}
		
		&--first-btn {
			margin-right: 5px;
		}
	}
	
	&__bold-text {
		font-weight: $weight-700;
	}
	
	&:deep(.v-select__slot) {
		border: 1px solid $grey-300;
		border-bottom: none;
		padding-left: 5px;
	}
	
	&:deep(.v-label) {
		margin-left: 10px;
	}
	
	&:deep(.v-btn:not(.v-btn--round).v-size--default) {
		width: 50px;
		min-width: 50px;
		height: 45px;
		margin-left: 5px;
	}
}
</style>
