<template>
	<div class="contadu-loader">
		<v-progress-linear
			indeterminate
			color="#2d2850"
		></v-progress-linear>
		
		<div class="contadu-loader__circle">
			<v-progress-circular
				:size="70"
				:width="7"
				color="#2d2850"
				indeterminate
			></v-progress-circular>
			<div class="contadu-loader__text">{{ $t('contadu.contaduLoader') }}</div>
		</div>
	</div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.contadu-loader {
	background-color: $white;
	width: 100%;
	min-height: 70vh;
	position: relative;
	
	&__circle {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	&__text {
		font-size: $size-16;
		color: $primary-400;
		max-width: 450px;
		text-align: center;
		margin-top: 15px;
	}
}
</style>
