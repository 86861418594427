<template>
	<div class="cart-preview-summary-creator">
		<div v-show="loading" class="cart-preview-summary-creator__loading">
			<v-progress-circular size="40" width="4" indeterminate />
		</div>
		<div v-show="!loading" class="cart-preview-summary-creator__wrapper">
			<div v-if="null != items && null != items.creator_first_name" class="cart-preview-summary-creator__image">{{ firstNameLetter(items.creator_first_name) }}</div>
			<div v-if="null != items && null != items.creator_first_name && null != items.creator_last_name" class="cart-preview-summary-creator__creator-wrapper">
				<div>{{ $t('basket.preview.advisor') }}</div>
				<div class="cart-preview-summary-creator__advisor-wrapper">
					<div>{{ items.creator_first_name }}</div>
					<div>{{ items.creator_last_name }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import type { ICartCreatorDetails } from '@/models/CartModel';

interface IOwnProps {
	items: ICartCreatorDetails | null
	loading: boolean
}
interface IProps extends IOwnProps {
}

defineProps<IProps>();

const firstNameLetter = (name: string) => {
	return name.charAt(0).toUpperCase();
};
</script>

<style scoped lang="scss">
.cart-preview-summary-creator {
	background-color: $white-100;
	padding: 15px 20px;
	border-radius: 8px;
	margin-top: 13px;
	
	&__loading {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100px;
	}
	
	&__wrapper {
		display: flex;
		align-items: center;
	}
	
	&__image {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 22px;
		color: $white-100;
		font-weight: 700;
		background-color: $secondary-400;
		width: 52px;
		height: 52px;
		border-radius: 50%;
	}
	
	&__creator-wrapper {
		font-size: 14px;
		margin-left: 13px;
	}
	
	&__advisor-wrapper {
		display: flex;
		gap: 3px;
		font-weight: 700;
	}
}
</style>
