<template>
	<div class="m-expansion-panel" :class="{'m-expansion-panel__disabled': disabled}">
		<div>
			<MProgressBar v-show="loading" indeterminate height="2" />
		</div>
		<v-expansion-panels
			v-model="panel"
			:multiple="multiple"
			:disabled="disabled"
		>
			<v-expansion-panel
				:title="title"
				:text="text"
				:color="defaultTitleBgColor"
				:bg-color="defaultContentBgColor"
				:hide-actions="hideActions"
			>
				<template v-slot:title>
					<slot name="title"></slot>
				</template>
				<template v-slot:default>
					<slot name="default"></slot>
				</template>
				<template v-slot:text>
					<slot name="text"></slot>
				</template>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script setup lang="ts">
import { defineProps, withDefaults, computed, defineEmits, watch } from 'vue';
import variables from '@/assets/scss/modules/variables.module.scss';
import MProgressBar from '@/components/atoms/MProgressBar.vue';

interface IOwnProps {
	title?: string
	text?: string
	titleBgColor?: string
	contentBgColor?: string
	hideActions?: boolean
	multiple?: boolean
	disabled?: boolean
	modelValue?: Array<number>|number
	loading?: boolean
}
interface IProps extends IOwnProps {
}

const { primary400, grey300 } = variables;

const props = withDefaults(defineProps<IProps>(), {
	hideActions: false
});
const emit = defineEmits(['update:modelValue']);

let defaultTitleBgColor:string|undefined = props.titleBgColor || primary400;
const defaultContentBgColor:string|null = props.contentBgColor || primary400;

const panel = computed({
	get() {
		return props.modelValue;
	},
	set(value) {
		emit('update:modelValue', value);
	}
});

watch(([props]), (val) => {
	if (val[0].disabled) {
		defaultTitleBgColor = grey300;
	} else {
		defaultTitleBgColor = props.titleBgColor;
	}
});
</script>

<style scoped lang="scss">
.m-expansion-panel {
	&__progress-bar {
		position: absolute;
		left: -23px;
		top: -25px;
	}
	&:deep(.v-expansion-panel-title--active:hover > .v-expansion-panel-title__overlay) {
		opacity: 0.045 !important;
	}
	
	&:deep(.v-expansion-panel-title--active > .v-expansion-panel-title__overlay) {
		opacity: 0.025 !important;
	}
	
	&:deep(.v-expansion-panel-title__icon) {
		color: $white-100;
	}
	
	&:deep(.v-expansion-panel-title__overlay) {
		background-color: $white-100;
	}
	
	&:deep(.v-expansion-panel-title) {
		height: 65px;
	}
}
</style>
