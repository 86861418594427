import BaseApi from '@/api/BaseApi';
import type { ISectionsList } from '@/models/SectionModel';

export default class SectionApi extends BaseApi {
	async sectionsNameList () {
		return this.get<Array<ISectionsList>>({
			url: '/api/v1/sections',
		});
	};
};
