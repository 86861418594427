import BaseApi from '@/api/BaseApi';
import type { AgencyListInterface, IAgencyBalanceForm, IAgencyForm } from '@/models/AgencyModel';
import type { UserInterface } from '@/models/AuthModel';

export default class AgencyApi extends BaseApi {
	async agencyUserList (uuid: string|null, page?: number|null, perPage?: number|null, sortBy?: string|null, sortDesc?: string|null, withDeletedUsers?: number|null) {
		let url = `/api/v1/agency/${uuid}/user?page=${page}&per_page=${perPage}`;
		if (null != sortBy) {
			url += `&order=${sortBy}`;
		}

		if (null != sortDesc) {
			url += `&direction=${sortDesc}`;
		}

		if (null != withDeletedUsers) {
			url += `&deleted=${withDeletedUsers}`;
		}

		return this.get<AgencyListInterface>({
			url: url,
		});
	};

	async createAgencyAccount (params: IAgencyForm, uuid: string|null) {
		return this.post<IAgencyForm, UserInterface>({
			url: `/api/v1/agency/${uuid}/user`,
			data: params,
		});
	};

	async deleteAgencyUser (userUuid: string) {
		return this.delete({
			url: `/api/v1/agency/${userUuid}/user`,
		});
	};

	async updateDependentAccountWallet (params: IAgencyBalanceForm, sourceUuid: string|null, targetUuid: string|null) {
		return this.put<IAgencyBalanceForm, any>({
			url: `/api/v1/agency/transfer/${sourceUuid}/${targetUuid}`,
			data: params,
		});
	};
};
