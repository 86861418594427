<template>
	<div class="create-edit-cart-form">
		<MLoading v-show="detailsLoading" />
		<div class="create-edit-cart-form__autocomplete-wrapper">
			<div class="create-edit-cart-form__autocomplete">
				<MProgressBar v-show="userLoading" indeterminate height="2" />
				<MAutocomplete
					id="create-cart-users"
					v-model="cartForm.user"
					v-model:search="autocompleteInputValue"
					name="user"
					:get-items="userList"
					item-title="email"
					item-value="id"
					:label="$t('basket.create.user')"
					:placeholder="$t('basket.create.userSelect')"
					variant="outlined"
					clearable
					:disabled="'create' !== router.currentRoute.value.params.cartUuid"
				/>
			</div>
			<div class="create-edit-cart-form__autocomplete">
				<MProgressBar v-show="campaignLoading" indeterminate height="2" />
				<MAutocomplete
					id="create-cart-campaign"
					v-model="cartForm.campaign"
					name="campaign"
					:get-items="campaignList"
					item-title="name"
					item-value="id"
					:label="$t('basket.create.campaign')"
					:placeholder="$t('basket.create.campaignSelect')"
					variant="outlined"
					clearable
					:disabled="null == cartForm.user || 'create' !== router.currentRoute.value.params.cartUuid"
				/>
			</div>
		</div>
		<div>
			<MTextField
				id="create-cart-slug"
				v-model="cartForm.slug"
				name="slug"
				:separate-label="$t('basket.create.slug')"
				:placeholder="$t('basket.create.slugPlaceholder')"
				persistent-hint
				:hint="$t('basket.create.slugHint')"
				clearable
			/>
		</div>
		<div class="create-edit-cart-form__btn-wrapper">
			<MButton
				id="go-back"
				blue400
				normal
				:label="$t('components.back')"
				@click="goBack"
			/>
			<MButton
				id="submit-create"
				green400
				normal
				:label="$t('basket.create.createCart')"
				:loading="submitLoader"
				@click="submitCreateCart"
				:disabled="submitLoader || status === CREATE_CART_STATUS_FINISHED || status === CREATE_CART_STATUS_SEND"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { useCartStore } from '@/stores/cart';
import { toast } from 'vue3-toastify';
import UserApi from '@/api/v1/UserApi';
import CampaignApi from '@/api/v1/CampaignApi';
import CartApi from '@/api/v2/CartApi';
import { useUserStore } from '@/stores/user';
import { refreshUser } from '@/hooks/UserHooks';
import { useChangeCurrencyIdToSymbol } from '@/hooks/CurrencyHook';
import { CREATE_CART_STATUS_FINISHED, CREATE_CART_STATUS_SEND } from '@/hooks/CartHooks';
import type { UserInterface } from '@/models/AuthModel';
import type { CampaignInterface } from '@/models/CampaignModel';
import type { TCreateEditCartRequest, TCreateEditForm, TSelectOptions } from '@/models/CartModel';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MButton from '@/components/atoms/MButton.vue';
import MLoading from '@/components/atoms/MLoading.vue';

const { t } = useI18n();
const { setUser, setUserCurrencySymbol } = useUserStore();

const prevUser = ref<number | null>(null);
const status = ref<string | null>(null);
const cartForm = ref<TCreateEditForm>({
	user: null,
	campaign: null,
	slug: null,
	multiple: true,
});

watch((cartForm), (val) => {
	if (null == val.user) {
		cartForm.value.user = null;
		userList.value = [];
		cartForm.value.campaign = null;
		campaignList.value = [];
		prevUser.value = null;
	}
	if (null != val.user && val.user != prevUser.value) {
		prevUser.value = val.user;
		userCampaignList(val.user);
		checkUserRegistration(val.user);
	}
	if (null != val.slug) {
		slugValidator();
	}
	if (null == val.campaign) {
		const defaultCampaign = campaignList.value.find((elem:CampaignInterface) => elem.default === 1);
		if (null != defaultCampaign) {
			cartForm.value.campaign = defaultCampaign;
		}
	}
},{
	deep: true,
});

const campaignApi = new CampaignApi();

const campaignList = ref<Array<CampaignInterface>|any>([]);
const campaignLoading = ref<boolean>(false);

const userCampaignList = async(userId:number) => {
	campaignLoading.value = true;
	try {
		const result = await campaignApi.userCampaigns(userId);
		if (!result.isSuccess) {
			toast.error(`${t('basket.create.toast.userCampaignListError')}`);
			campaignLoading.value = false;
			return;
		}
		const campaignPayloadList:Array<TSelectOptions> = result.payload.map((elem:CampaignInterface) => {
			return {
				id: elem.id,
				name: elem.name,
				default: elem.default,
			}
		});
		campaignList.value = campaignPayloadList;
	} catch (error) {
		toast.error(`${t('basket.create.toast.userCampaignListError')}`);
		campaignLoading.value = false;
		return;
	}
	campaignLoading.value = false;
};

const userApi = new UserApi();

const userList = ref<Array<UserInterface>|any>([]);
const userLoading = ref<boolean>(false);

const usersList = async(val:string|null) => {
	userLoading.value = true;
	try {
		const result = await userApi.usersList(val);
		if (!result.isSuccess) {
			toast.error(`${t('basket.create.toast.userListError')}`);
			userLoading.value = false;
			return;
		}
		const usersPayloadList:Array<UserInterface> = result.payload.map((elem:UserInterface|any) => {
			return {
				id: elem.id,
				email: elem.email
			}
		});
		userList.value = usersPayloadList;
		
		cartForm.value.campaign = null;
		campaignList.value = [];
	} catch (error) {
		toast.error(`${t('basket.create.toast.userListError')}`);
		userLoading.value = false;
		return;
	}
	userLoading.value = false;
};

const autocompleteInputValue = ref<string | null>('');
const autocompleteInputValueTimeout = ref<any>(undefined);

watch((autocompleteInputValue), (newVal, oldVal) => {
	if (newVal !== oldVal) {
		clearTimeout(autocompleteInputValueTimeout.value);
	}
	autocompleteInputValueTimeout.value = setTimeout(() => {
		usersList(newVal);
	}, 700);
});

const slugValidator = () => {
	if (null != cartForm.value.slug) {
		cartForm.value.slug = cartForm.value.slug.replace(' ', '-');
	}
};
const checkUserRegistration = (userId: number | null) => {
	if (null == userId) {
		return;
	}
	const currentUser = userList.value.find((elem: UserInterface) => elem.id === userId);
	if (null != currentUser && null == currentUser.currency_id) {
		toast.info(`${t('basket.create.toast.noCompleteRegisterUser')}`);
	}
}

const goBack = () => {
	router.go(-1);
};

const cartApi = new CartApi();
const submitLoader = ref<boolean>(false);
const { setCampaignId, setCartUuid, setIsCartHasOrders, setCartNewOrder, setCartAssignedUuid, setIsMultiple, setCartSlug } = useCartStore();
const submitCreateCart = async() => {
	submitLoader.value = true;
	
	const submitForm:TCreateEditCartRequest = {
		assigned_id: cartForm.value.user,
		campaign_id: cartForm.value.campaign,
		multiple: null == cartForm.value.user,
		slug: cartForm.value.slug,
	};
	if ('create' !== router.currentRoute.value.params.cartUuid && null != router.currentRoute.value.params.cartUuid) {
		try {
			const result = await cartApi.updateCart(submitForm, router.currentRoute.value.params.cartUuid);
			if (!result.isSuccess) {
				if (result.payload.code === 409) {
					toast.error(`${t('basket.create.toast.takenSlug')}`);
					submitLoader.value = false;
					return;
				}
				toast.error(`${t('basket.create.toast.updateCartError')}`);
				submitLoader.value = false;
				return;
			}
			toast.success(`${t('basket.create.toast.updateCartSuccess')}`);
		} catch (e) {
			toast.error(`${t('basket.create.toast.updateCartError')}`);
			submitLoader.value = false;
			return;
		}
	} else {
		try {
			const result = await cartApi.createCart(submitForm);
			if (!result.isSuccess) {
				if (result.payload.code === 409) {
					toast.error(`${t('basket.create.toast.takenSlug')}`);
					submitLoader.value = false;
					return;
				}
				toast.error(`${t('basket.create.toast.createCartError')}`);
				submitLoader.value = false;
				return;
			}
			await setCampaignId(cartForm.value.campaign);
			await setCartUuid(result.payload.uuid);
			await setCartSlug(result.payload.slug);
			
			// Daje timeout bo kolejny endpoint nie zdazy sie przygotowac na odpowiedz
			setTimeout(async () => {
				await router.push(`/carts/${result.payload.uuid}`);
			}, 1000);
			toast.success(`${t('basket.create.toast.createCartSuccess')}`);
		} catch (e) {
			toast.error(`${t('basket.create.toast.createCartError')}`);
			submitLoader.value = false;
			return;
		}
	}
	submitLoader.value = false;
};

const detailsLoading = ref<boolean>(false);
const cartDetails = async(cartUuid: string | string[] | null) => {
	detailsLoading.value = true;
	try {
		const result = await cartApi.cartDetails(cartUuid);
		if (!result.isSuccess) {
			detailsLoading.value = false;
			await router.push('/carts');
			toast.error(`${t('basket.create.toast.detailsCartError')}`);
			return;
		}
		setCampaignId(result.payload.campaign_id);
		cartForm.value.multiple = 1 === result.payload.multiple;
		setIsMultiple(1 === result.payload.multiple);
		cartForm.value.slug = result.payload.slug;
		status.value = result.payload.status;
		setCartSlug(result.payload.slug);
		if (null != result.payload.assigned_id && null != result.payload.assigned && null != result.payload.assigned.email) {
			await usersList(result.payload.assigned.email);
			cartForm.value.user = result.payload.assigned_id;
			setCartAssignedUuid(result.payload.assigned.uuid);
		}
		if (null != result.payload.campaign_id && null != result.payload.assigned_id) {
			await userCampaignList(result.payload.assigned_id);
			cartForm.value.campaign = result.payload.campaign_id;
		}
		if (null != result.payload.new_order.total_amount) {
			setIsCartHasOrders(result.payload.new_order.total_amount > 0);
		}
		setCartNewOrder(result.payload);
		const { user } = await refreshUser();
		setUser(user);
		setUserCurrencySymbol(useChangeCurrencyIdToSymbol(user.currency_id));
	} catch (e) {
		detailsLoading.value = false;
		await router.push('/carts');
		toast.error(`${t('basket.create.toast.detailsCartError')}`);
		return;
	}
	detailsLoading.value = false;
};

if ('create' !== router.currentRoute.value.params.cartUuid && null != router.currentRoute.value.params.cartUuid) {
	cartDetails(router.currentRoute.value.params.cartUuid);
} else {
	setCartNewOrder(null);
	setIsCartHasOrders(false);
}
</script>

<style scoped lang="scss">
.create-edit-cart-form {
	background-color: $white-100;
	padding: 15px 20px;
	border-radius: 8px;
	margin-bottom: 12px;
	
	&__autocomplete-wrapper {
		margin-top: 5px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		
		@include media-breakpoint-up(md) {
			flex-direction: row;
		}
	}
	
	&__autocomplete {
		width: 100%;
	}
	
	&__btn-wrapper {
		margin-top: 25px;
		display: flex;
		justify-content: space-between;
		
		&:deep(.m-btn) {
			min-width: 100px;
		}
	}
	
	&:deep(.m-checkbox .v-selection-control--dirty .v-selection-control__input) {
		color: $secondary-400;
	}
	
	&:deep(.m-checkbox) {
		margin-left: -10px;
	}
	
	&:deep(.m-checkbox .v-input__details) {
		display: none;
	}
}
</style>
