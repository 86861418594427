<template>
	<div class="create-cart-list">
		<HeadingLabel
			class="create-cart-list__heading-label"
			icon="cart"
			width="32"
			height="29"
			:text="$t('basket.create.listOfCreatedCarts')"
			btn
			green400
			:btn-text="$t('basket.create.createNewCart')"
			:is-button-click="goToCreateNewCart"
		/>
		
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template v-slot:loading>
				<div class="create-cart-list__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #top>
				<div class="create-cart-list__top-wrapper">
					<MTextField
						id="recipient-search"
						v-model="recipientSearch"
						name="recipientSearch"
						variant="outlined"
						:placeholder="$t('basket.create.recipient')"
						clearable
					/>
					<MTextField
						id="sender-search"
						v-model="senderSearch"
						name="senderSearch"
						variant="outlined"
						:placeholder="$t('basket.create.sender')"
						clearable
					/>
					<MAutocomplete
						id="status-filter"
						v-model="statusFilter"
						:get-items="statusesList"
						clearable
						variant="outlined"
						item-title="name"
						item-value="slug"
						label=""
						:placeholder="$t('basket.create.status')"
					/>
					<MTextField
						id="campaigns-search"
						v-model="campaignsSearch"
						name="campaignsSearch"
						variant="outlined"
						:placeholder="$t('basket.create.campaign')"
						clearable
					/>
					<div class="create-cart-list__date-wrapper">
						<MDateRangePicker
							v-model="dataRange"
							name="dateRange"
							:locale="localeLanguage"
							is-custom-buttons
							multi-calendars
							:max-date="maxDate"
							format="dd-MM-yyyy"
							clearable
						>
							<template #close-button>
								<div class="date-picker-with-menu__action-btn">{{ $t('dashboard.cancel') }}</div>
							</template>
							<template #submit-button>
								<div class="date-picker-with-menu__action-btn">{{ $t('dashboard.submit') }}</div>
							</template>
						</MDateRangePicker>
					</div>
				</div>
			</template>
			
			<template #item.assigned="elem">
				<div>
					<div v-if="null != elem.item.raw.assigned_first_name && null != elem.item.raw.assigned_last_name" class="create-cart-list__align">
						<div>{{ elem.item.raw.assigned_first_name }}</div>
						<div>{{ elem.item.raw.assigned_last_name }}</div>
					</div>
					<div v-if="null != elem.item.raw.assigned_email">{{ elem.item.raw.assigned_email }}</div>
				</div>
			</template>
			
			<template #item.creator="elem">
				<div>
					<div v-if="null != elem.item.raw.creator_first_name && null != elem.item.raw.creator_last_name" class="create-cart-list__align">
						<div>{{ elem.item.raw.creator_first_name }}</div>
						<div>{{ elem.item.raw.creator_last_name }}</div>
					</div>
					<div v-if="null != elem.item.raw.creator_email">{{ elem.item.raw.creator_email }}</div>
				</div>
			</template>
			
			<template #item.publication_count="elem">
				<div>
					<div v-if="null != elem.item.raw.new_publication_requests_count" class="create-cart-list__bold">{{ elem.item.raw.new_publication_requests_count }}</div>
				</div>
			</template>
			
			<template #item.status="elem">
				<div v-if="null != elem.item.raw.status">
					<div
						class="create-cart-list__status"
						:class="[
							{'create-cart-list__status--draft': elem.item.raw.status === CREATE_CART_STATUS_DRAFT},
							{'create-cart-list__status--send': elem.item.raw.status === CREATE_CART_STATUS_SEND},
							{'create-cart-list__status--resigned': elem.item.raw.status === CREATE_CART_STATUS_RESIGNED},
							{'create-cart-list__status--finished': elem.item.raw.status === CREATE_CART_STATUS_FINISHED},
						]"
					>{{ changeSlugToName(elem.item.raw.status) }}</div>
				</div>
			</template>
			
			<template #item.price="elem">
				<div class="m9-data-table-align-price">
					<div v-if="null != elem.item.raw.gross" class="create-cart-list__bold">{{ $t('basket.create.gross') }}: <span class="create-cart-list__price">{{ (elem.item.raw.gross / 100).toFixed(2) }} {{ useChangeCurrencyIdToSymbol(elem.item.raw.currency_id) }}</span></div>
					<div v-if="null != elem.item.raw.net" class="create-cart-list__bold">{{ $t('basket.create.net') }}: <span class="create-cart-list__price">{{ (elem.item.raw.net / 100).toFixed(2) }} {{ useChangeCurrencyIdToSymbol(elem.item.raw.currency_id) }}</span></div>
				</div>
			</template>
			
			<template #item.created_at="elem">
				<div class="create-cart-list__center-column-margin">
					<div v-if="null != elem.item.raw.created_at">{{ dateFormatWithTime(elem.item.raw.created_at) }}</div>
				</div>
			</template>
			
			<template #item.campaign="elem">
				<div class="create-cart-list__center-column-margin">
					<div v-if="null != elem.item.raw.name" class="create-cart-list__word-break">{{ elem.item.raw.name }}</div>
				</div>
			</template>
			
			<template #item.actions="elem">
				<div class="create-cart-list__btn-wrapper">
					<MTooltip without-btn>
						<template v-slot:title>
							<MButton
								v-if="elem.item.raw.new_publication_requests_count > 0"
								id="preview"
								icon="eye"
								width="16"
								height="16"
								:color="white"
								grey300
								@click="goToPreview(elem.item.raw.slug)"
							/>
						</template>
						<template v-slot:content>
							{{ $t('basket.create.preview') }}
						</template>
					</MTooltip>
					<MTooltip without-btn v-if="elem.item.raw.status === CREATE_CART_STATUS_DRAFT">
						<template v-slot:title>
							<MButton
								id="edit"
								icon="pen-fancy"
								width="16"
								height="16"
								:color="white"
								grey300
								@click="goToEdit(elem.item.raw.uuid)"
							/>
						</template>
						<template v-slot:content>
							{{ $t('basket.create.edit') }}
						</template>
					</MTooltip>
					<MTooltip without-btn v-if="elem.item.raw.status === CREATE_CART_STATUS_DRAFT || elem.item.raw.is_multiple === 1">
						<template v-slot:title>
							<MButton
								id="delete"
								icon="trash"
								width="16"
								height="16"
								:color="white"
								grey300
								@click="openDeleteDialog(elem.item.raw.uuid)"
							/>
						</template>
						<template v-slot:content>
							{{ $t('basket.create.delete') }}
						</template>
					</MTooltip>
				</div>
			</template>
		</v-data-table-server>
		
		<MDialog
			:content-title="$t('basket.create.deleteDialog')"
			:is-open-dialog-action="isOpenDeleteDialog"
			:is-close-dialog-action="isCloseDeleteDialog"
			@close-dialog="closeDialogWhenNoTargetPressed"
			:width="700"
		>
			<template v-slot:default>
				<div class="create-cart-list__title-dialog">{{ $t('basket.create.deleteDialogInfo') }}</div>
				<div class="create-cart-list__delete-btn-wrapper">
					<MButton
						big
						red400
						:label="$t('basket.create.delete')"
						@click="deleteCart"
					/>
					<MButton
						big
						blue400
						:label="$t('basket.create.cancel')"
						@click="closeDialogWhenNoTargetPressed"
					/>
				</div>
			</template>
		</MDialog>
		
		<MDialog
			:content-title="$t('basket.create.duplicateDialog')"
			:is-open-dialog-action="isOpenDuplicateDialog"
			:is-close-dialog-action="isCloseDuplicateDialog"
			@close-dialog="closeDialogWhenNoTargetPressed"
			:width="700"
		>
			<template v-slot:default>
				<div class="create-cart-list__title-dialog">{{ $t('basket.create.duplicateDialogInfo') }}</div>
				<div class="create-cart-list__delete-btn-wrapper">
					<MButton
						big
						green400
						:label="$t('basket.create.copy')"
						@click="duplicateCart"
					/>
					<MButton
						big
						blue400
						:label="$t('basket.create.cancel')"
						@click="closeDialogWhenNoTargetPressed"
					/>
				</div>
			</template>
		</MDialog>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { ref, watch } from 'vue';
import router from '@/router';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@/stores/session';
import variables from '@/assets/scss/modules/variables.module.scss';
import { toast } from 'vue3-toastify';
import { dateFormatWithTime } from '@/hooks/DataHooks';
import { useChangeCurrencyIdToSymbol } from '@/hooks/CurrencyHook';
import CartApi from '@/api/v2/CartApi';
import {
	CREATE_CART_STATUS_DRAFT,
	CREATE_CART_STATUS_SEND,
	CREATE_CART_STATUS_RESIGNED,
	CREATE_CART_STATUS_FINISHED
} from '@/hooks/CartHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { TCustomAutocomplete } from '@/models/GlobalModel';
import type { ICartFilterParams } from '@/models/CartModel';
import MTooltip from '@/components/atoms/MTooltip.vue';
import MButton from '@/components/atoms/MButton.vue';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MDialog from '@/components/atoms/MDialog.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MDateRangePicker from '@/components/atoms/MDateRangePicker.vue';

const cartApi = new CartApi();

const { locale } = storeToRefs(useSessionStore());
const { t } = useI18n();
const { white } = variables;

const localeLanguage = ref('');

if ('us' === locale.value) {
	localeLanguage.value = 'en';
} else if (locale.value != null) {
	localeLanguage.value = locale.value;
} else {
	localeLanguage.value = 'pl';
}

const loading = ref<boolean>(false);
const items = ref<Array<any>>([]);
const total = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const isOpenDeleteDialog = ref<boolean>(false);
const isCloseDeleteDialog = ref<boolean>(true);
const isOpenDuplicateDialog = ref<boolean>(false);
const isCloseDuplicateDialog = ref<boolean>(true);
const currentUuid = ref<string|null>(null);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('basket.create.recipient')}`,
		align: 'center',
		sortable: true,
		key: 'assigned',
		width: '150px',
	},
	{
		title: `${t('basket.create.sender')}`,
		align: 'center',
		sortable: true,
		key: 'creator',
		width: '150px',
	},
	{
		title: `${t('basket.create.publicationCount')}`,
		align: 'center',
		sortable: true,
		key: 'publication_count',
		width: '80px'
	},
	{
		title: `${t('basket.create.status')}`,
		align: 'center',
		sortable: true,
		key: 'status',
		width: '150px',
	},
	{
		title: `${t('basket.create.price')}`,
		align: 'center',
		sortable: true,
		key: 'price',
		width: '180px',
	},
	{
		title: `${t('basket.create.createdAt')}`,
		align: 'center',
		sortable: true,
		key: 'created_at',
		width: '120px',
	},
	{
		title: `${t('basket.create.campaign')}`,
		align: 'center',
		sortable: true,
		key: 'campaign',
		width: '130px',
	},
	{
		title: `${t('basket.create.actions')}`,
		align: 'center',
		sortable: false,
		key: 'actions',
		width: '80px',
	},
]);

const createdCartList = async() => {
	loading.value = true;
	
	try {
		const cartFilters:ICartFilterParams = {
			page: options.value.page,
			per_page: options.value.itemsPerPage,
			title: options.value.sortBy.length > 0 ? options.value.sortBy[0].key : null,
			direction: options.value.sortBy.length > 0 ? options.value.sortBy[0].order : null,
			assigned: recipientSearch.value,
			creator: senderSearch.value,
			status: statusFilter.value,
			campaign: campaignsSearch.value,
			created_from: null != dataRange.value ? dayjs(dataRange.value[0]).format('DD-MM-YYYY') : null,
			created_to: null != dataRange.value ? dayjs(dataRange.value[1]).format('DD-MM-YYYY') : null,
		}
		const result = await cartApi.createCartList(cartFilters);
		if (!result.isSuccess) {
			toast.error(`${t('basket.create.toast.errorCreateCartList')}`)
			loading.value = false;
			return;
		}
		items.value = result.payload.data;
		currentPage.value = result.payload.current_page;
		lastPage.value = result.payload.last_page;
		total.value = result.payload.total;
		
	} catch (e) {
		toast.error(`${t('basket.create.toast.errorCreateCartList')}`)
		loading.value = false;
		return;
	}
	loading.value = false;
};

const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	await createdCartList();
};

const goToCreateNewCart = () => {
	router.push('/carts/create');
};

const goToPreview = (uuid:string) => {
	router.push(`/carts/${uuid}/preview`);
};

const goToEdit = (uuid:string) => {
	router.push(`/carts/${uuid}`);
};

const openDeleteDialog = (uuid:string) => {
	isOpenDeleteDialog.value = true;
	isCloseDeleteDialog.value = false;
	currentUuid.value = uuid;
};

const openDuplicateDialog = (uuid:string) => {
	isOpenDuplicateDialog.value = true;
	isCloseDuplicateDialog.value = false;
	currentUuid.value = uuid;
};

const closeDialogWhenNoTargetPressed = () => {
	isOpenDeleteDialog.value = false;
	isCloseDeleteDialog.value = true;
	isOpenDuplicateDialog.value = false;
	isCloseDuplicateDialog.value = true;
	currentUuid.value = null;
};

const deleteCart = async() => {
	loading.value = true;
	try {
		const result = await cartApi.deleteCart(currentUuid.value);
		if (!result.isSuccess) {
			toast.error(`${t('basket.create.toast.errorDeleteCart')}`);
			loading.value = false;
			return;
		}
		toast.success(`${t('basket.create.toast.successDeleteCart')}`);
		await closeDialogWhenNoTargetPressed();
		await createdCartList();
	} catch (e) {
		toast.error(`${t('basket.create.toast.errorDeleteCart')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const changeSlugToName = (slug:string) => {
	switch(slug) {
		case CREATE_CART_STATUS_DRAFT:
			return `${t('basket.create.draft')}`;
		case CREATE_CART_STATUS_SEND:
			return `${t('basket.create.send')}`;
		case CREATE_CART_STATUS_RESIGNED:
			return `${t('basket.create.resigned')}`;
		case CREATE_CART_STATUS_FINISHED:
			return `${t('basket.create.finished')}`;
		default:
			return `${t('basket.create.draft')}`;
	}
};

const recipientSearch = ref<string|null>(null);
const recipientSearchTimeout = ref<any>(undefined);
const senderSearch = ref<string|null>(null);
const senderSearchTimeout = ref<any>(undefined);
const campaignsSearch = ref<string|null>(null);
const campaignSearchTimeout = ref<any>(undefined);
const statusFilter = ref<string|null>(null);
const statusesList = ref<Array<TCustomAutocomplete>>([
	{
		name: `${t('basket.create.draft')}`,
		slug: CREATE_CART_STATUS_DRAFT,
	},
	{
		name: `${t('basket.create.send')}`,
		slug: CREATE_CART_STATUS_SEND,
	},
	{
		name: `${t('basket.create.resigned')}`,
		slug: CREATE_CART_STATUS_RESIGNED,
	},
	{
		name: `${t('basket.create.finished')}`,
		slug: CREATE_CART_STATUS_FINISHED,
	},
]);
const dataRange = ref<string|null>(null);
const maxDate = ref<string>((new Date()).toString());

watch(([recipientSearch, senderSearch, campaignsSearch, statusFilter, dataRange]), (newVal, oldVal) => {
	if (newVal[0] !== oldVal[0]) {
		clearTimeout(recipientSearchTimeout.value);
		recipientSearchTimeout.value = setTimeout(async () => {
			await createdCartList();
		}, 700);
	}
	if (newVal[1] !== oldVal[1]) {
		clearTimeout(senderSearchTimeout.value);
		senderSearchTimeout.value = setTimeout(async () => {
			await createdCartList();
		}, 700);
	}
	if (newVal[2] !== oldVal[2]) {
		clearTimeout(campaignSearchTimeout.value);
		campaignSearchTimeout.value = setTimeout(async () => {
			await createdCartList();
		}, 700);
	}
	if (newVal[3] !== oldVal[3]) {
		createdCartList();
	}
	if (newVal[4] !== oldVal[4]) {
		createdCartList();
	}
},{});

const duplicateCart = async() => {
	// TO DO Duplicate Action in future
};
</script>

<style scoped lang="scss">
.create-cart-list {
	@import '@/assets/scss/vuetifyDataTable.scss';
	&__loading {
		opacity: 0.5;
	}
	
	&__heading-label {
		margin-bottom: 5px;
	}
	
	&__center-column-margin {
		&--net-gross {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			margin-right: 0;
		}
	}
	
	&__align {
		display: flex;
		gap: 5px;
		justify-content: center;
	}
	
	&__bold {
		font-weight: 700;
	}
	
	&__price {
		color: $green-400;
	}
	
	&__word-break {
		word-break: break-all;
	}
	
	&__btn-wrapper {
		display: flex;
		gap: 5px;
		justify-content: flex-end;
		
		button {
			width: max-content !important;
			min-width: max-content !important;
			border-radius: 50%;
			padding: 0 5px 0 10px !important;
		}
	}
	
	&__delete-btn-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 50px;
		margin: 20px 0 0;
		
		@include media-breakpoint-up(md) {
			flex-direction: row;
		}
		
		button {
			border-radius: 12px !important;
			padding: 25px 40px !important;
		}
	}
	
	&__title-dialog {
		text-align: center;
	}
	
	&__status {
		padding: 4px 10px;
		width: max-content;
		margin: 0 auto;
		font-weight: 700;
		border-radius: 12px;
		color: $primary-400;
		
		&--draft {
			color: $white-100;
			background-color: $grey-300;
		}
		
		&--send {
			color: $white-100;
			background-color: $primary-300;
		}
		
		&--resigned {
			color: $white-100;
			background-color: $secondary-400;
		}
		
		&--finished {
			color: $white-100;
			background-color: $green-400;
		}
	}
	
	&__top-wrapper {
		display: flex;
		align-items: center;
		gap: 10px;
		flex-wrap: wrap;
		padding: 20px;
		
		&:deep(.m-input) {
			width: 350px;
		}
		
		&:deep(.m-autocomplete) {
			width: 200px;
		}
		
		&:deep(.v-input__details) {
			display: none;
		}
		
		&:deep(.m-date-range-picker__errors) {
			display: none;
		}
	}
	
	&__date-wrapper {
		width: 270px;
		
		span {
			color: $grey-400;
			font-weight: 700;
			font-size: 14px;
		}
		
		&:deep(.dp__input_readonly) {
			padding: 9px 35px;
			margin-top: 3px;
		}
	}
	
	&:deep(.v-data-table) {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
}
</style>
