<template>
	<div class="users-list">
		<HeadingLabel
			class="users-list__heading-label"
			icon="users"
			width="32"
			height="29"
			:text="$t('layout.menu.usersList')"
		/>
		
		<UsersListTable />
	</div>
</template>

<script setup lang="ts">
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { isAdmin, isModerator } from '@/hooks/UserHooks';
import UsersListTable from '@/components/organisms/Users/UsersListTable.vue';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';

const { user } = storeToRefs(useUserStore());

if (null != user.value && !isModerator(user.value) && !isAdmin(user.value)) {
	router.push('/');
}
</script>

<style scoped lang="scss">
.users-list {
	&__heading-label {
		margin-bottom: 3px;
	}
}
</style>
