<template>
	<div class="reflinks">
		<HeadingLabel
			icon="group-people"
			width="32"
			height="29"
			:text="$t('reflinks.reflinksPeriodList')"
		/>
		
		<v-data-table
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:options="options"
			@update:options="optionsUpdate"
			hover
		>
			<template #loading>
				<div class="reflinks__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #item.from="elem">
				<div v-if="elem.item.raw.active_from != null">
					<div class="reflinks__date-wrapper">{{ dateFormat(elem.item.raw.active_from) }}</div>
				</div>
			</template>
			
			<template #item.to="elem">
				<div v-if="elem.item.raw.active_to != null">
					<div class="reflinks__date-wrapper">{{ dateFormat(elem.item.raw.active_to) }}</div>
				</div>
			</template>
			
			<template #item.owner_price="elem">
				<div v-if="elem.item.raw.owner_share != null">
					<div class="reflinks__discounts-wrapper">{{ elem.item.raw.owner_share }}%</div>
				</div>
			</template>
			
			<template #item.user_discount="elem">
				<div v-if="elem.item.raw.user_share != null">
					<div class="reflinks__discounts-wrapper">{{ elem.item.raw.user_share }}%</div>
				</div>
			</template>
			
			<template #item.actions="elem">
				<div class="reflinks__action-wrapper">
					<div @click="editAction(elem.item.raw)" class="reflinks__action-item reflinks__action-item--edit">
						<MIcon icon="edit" width="16" height="16" :color="white" />
					</div>
					<div @click="deleteAction(elem.item.raw.uuid)" class="reflinks__action-item">
						<MIcon icon="close" width="14" height="14" :color="white" />
					</div>
				</div>
			</template>
			
			<template #footer.prepend>
				<div class="reflinks__btn-create">
					<MButton
						id="add-reflink-period-btn"
						green400
						normal
						width150
						:label="$t('reflinks.addPeriod')"
						@click="addAction"
					/>
				</div>
			</template>
		</v-data-table>
		
		<div v-if="isOpenPeriodForm">
			<HeadingLabel
				icon="group-people"
				width="32"
				height="29"
				:text="isCreatedOperation ? $t('reflinks.addPeriod') : $t('reflinks.periodEdit')"
			/>
			<Form :validation-schema="schema" @submit="onSubmitForm" class="reflinks__form-wrapper">
				<MDatePicker
					id="reflink-period-date-from"
					v-model="reflinkForm.dateFrom"
					name="dateFrom"
					:placeholder="$t('reflinks.dateFrom')"
					:language="localeLanguage"
					clearable-height="12"
					clearable-width="12"
					:disabled-dates="{
						from: new Date(+dayjs(reflinkForm.dateTo).format('YYYY'), +dayjs(reflinkForm.dateTo).format('MM') - 1, +dayjs(reflinkForm.dateTo).format('DD')),
					}"
					clearable
				/>
				<MDatePicker
					id="reflink-period-date-to"
					v-model="reflinkForm.dateTo"
					name="dateTo"
					:placeholder="$t('reflinks.dateTo')"
					:language="localeLanguage"
					clearable-height="12"
					clearable-width="12"
					:disabled-dates="{
						to: new Date(+dayjs(reflinkForm.dateFrom).format('YYYY'), +dayjs(reflinkForm.dateFrom).format('MM') - 1, +dayjs(reflinkForm.dateFrom).format('DD')),
					}"
					clearable
				/>
				<MTextField
					id="reflink-period-user-share"
					v-model="reflinkForm.userShare"
					name="userShare"
					:separate-label="$t('reflinks.userDiscount') + ' [%]:'"
					type="number"
					min="0"
					max="100"
					rules="required|numeric"
				/>
				<MTextField
					id="reflink-period-owner-share"
					v-model="reflinkForm.ownerShare"
					name="ownerShare"
					:separate-label="$t('reflinks.ownerPrice') + ' [%]:'"
					type="number"
					min="0"
					max="100"
					rules="required|numeric"
				/>
				<div class="reflinks__btn-wrapper">
					<MButton
						id="reflink-period-back-btn"
						big
						blue400
						:label="$t('reflinks.cancel')"
						@click="back"
					/>
					<MButton
						id="reflink-period-save-btn"
						big
						red400
						:label="$t('reflinks.save')"
						:loading="loading"
					/>
				</div>
			</Form>
		</div>
	</div>
</template>

<script setup lang="ts">
import { VDataTable } from 'vuetify/labs/VDataTable';
import { defineProps, ref } from 'vue';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@/stores/session';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { setLastPage } from '@/hooks/TableSettingsHooks';
import { dateFormat } from '@/hooks/DataHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import dayjs from 'dayjs';
import ReflinkApi from '@/api/v1/ReflinkApi';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { UserInterface } from '@/models/AuthModel';
import type { IReflinkPeriods, IReflinkRequest } from '@/models/ReflinksModel';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MButton from '@/components/atoms/MButton.vue';
import MDatePicker from '@/components/atoms/MDatePicker.vue';
import MTextField from '@/components/atoms/MTextField.vue';

interface IOwnProps {
	currentUser: UserInterface
}

interface IProps extends IOwnProps {
}

const reflinkApi = new ReflinkApi();

const props = defineProps<IProps>();

const { t } = useI18n();
const { white } = variables;
const { locale } = storeToRefs(useSessionStore());

const isCreatedOperation = ref<boolean>(false);
const isOpenPeriodForm = ref<boolean>(false);
const loading = ref<boolean>(false);
const items = ref<Array<any>>([]);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('reflinks.dateFrom')}`,
		align: 'left',
		sortable: false,
		key: 'from',
		width: '170px'
	},
	{
		title: `${t('reflinks.dateTo')}`,
		align: 'left',
		sortable: false,
		key: 'to',
		width: '170px'
	},
	{
		title: `${t('reflinks.ownerPrice')}`,
		align: 'left',
		sortable: false,
		key: 'owner_price',
		width: '280px'
	},
	{
		title: `${t('reflinks.userDiscount')}`,
		align: 'left',
		sortable: false,
		key: 'user_discount',
		width: '200px'
	},
	{
		title: `${t('reflinks.actions')}`,
		align: 'left',
		sortable: false,
		key: 'actions',
		width: '100px'
	},
]);
const reflinkForm = ref<any>({
	uuid: null,
	dateTo: null,
	dateFrom: null,
	userShare: null,
	ownerShare: null,
});
const localeLanguage = ref<string>('');

if ('us' === locale.value) {
	localeLanguage.value = 'en';
} else if (locale.value != null) {
	localeLanguage.value = locale.value;
} else {
	localeLanguage.value = 'pl';
}

const schema:any = yup.object({
	dateFrom: yup.string().required(`${t('reflinks.validation.dateFromRequired')}`).label('dateFrom'),
	dateTo: yup.string().required(`${t('reflinks.validation.dateToRequired')}`).label('dateTo'),
	userShare: yup.number().required(`${t('reflinks.validation.userShareRequired')}`).min(0, `${t('reflinks.validation.min0')}`).max(100, `${t('reflinks.validation.max100')}`).label('userShare'),
	ownerShare: yup.number().required(`${t('reflinks.validation.ownerShareRequired')}`).min(0, `${t('reflinks.validation.min0')}`).max(100, `${t('reflinks.validation.max100')}`).label('ownerShare'),
});

const reflinkList = async() => {
	loading.value = true;
	try {
		const result = await reflinkApi.periodReflinkList(props.currentUser.uuid);
		if (!result.isSuccess) {
			toast.error(`${t('reflinks.toast.errorReflinkPeriodList')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload;
	} catch (e) {
		toast.error(`${t('reflinks.toast.errorReflinkPeriodList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
reflinkList();

const optionsUpdate = (val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	currentPage.value = val.page;
	lastPage.value = setLastPage(val.itemsPerPage, items.value.length);
};

const editAction = (item:IReflinkPeriods) => {
	isOpenPeriodForm.value = true;
	isCreatedOperation.value = false;
	
	reflinkForm.value.uuid = item.uuid;
	reflinkForm.value.dateTo = item.active_to;
	reflinkForm.value.dateFrom = item.active_from;
	reflinkForm.value.userShare = item.user_share;
	reflinkForm.value.ownerShare = item.owner_share;
};
const deleteAction = async(periodUuid:string) => {
	loading.value = true;
	try {
		const result = await reflinkApi.deletePeriodReflink(periodUuid);
		if (!result.isSuccess) {
			toast.error(`${t('reflinks.toast.errorDelete')}`);
			loading.value = false;
			return;
		}
		toast.success(`${t('reflinks.toast.successDelete')}`);
		await reflinkList();
	} catch (e) {
		toast.error(`${t('reflinks.toast.errorDelete')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
const addAction = () => {
	isOpenPeriodForm.value = true;
	isCreatedOperation.value = true;
	
	reflinkForm.value.uuid = null;
	reflinkForm.value.dateTo = null;
	reflinkForm.value.dateFrom = null;
	reflinkForm.value.userShare = null;
	reflinkForm.value.ownerShare = null;
};
const back = () => {
	isOpenPeriodForm.value = false;
};

const onSubmitForm = async() => {
	loading.value = true;
	
	const request:IReflinkRequest = {
		active_from: reflinkForm.value.dateFrom,
		active_to: reflinkForm.value.dateTo,
		user_share: reflinkForm.value.userShare,
		owner_share: reflinkForm.value.ownerShare,
	};
	
	if (null != reflinkForm.value.uuid) {
		try {
			const result = await reflinkApi.editPeriodReflink(reflinkForm.value.uuid, request);
			if (!result.isSuccess) {
				toast.error(`${t('reflinks.toast.errorEdit')}`);
				loading.value = false;
				return;
			}
		} catch (e) {
			toast.error(`${t('reflinks.toast.errorEdit')}`);
			loading.value = false;
			return;
		}
	} else {
		try {
			const result = await reflinkApi.createPeriodReflink(props.currentUser.uuid, request);
			if (!result.isSuccess) {
				toast.error(`${t('reflinks.toast.errorCreate')}`);
				loading.value = false;
				return;
			}
		} catch (e) {
			toast.error(`${t('reflinks.toast.errorCreate')}`);
			loading.value = false;
			return;
		}
	}
	toast.success(`${t('reflinks.toast.successEdit')}`);
	await reflinkList();
	await addAction();
	loading.value = false;
};
</script>

<style scoped lang="scss">
.reflinks {
	@import '@/assets/scss/vuetifyDataTable';
	background-color: $white-100;
	
	&__loading {
		opacity: 0.5;
	}
	
	&__date-wrapper {
		font-weight: 700;
	}
	
	&__discounts-wrapper {
		font-weight: 700;
		color: $green-400;
	}
	
	&__action-wrapper {
		display: flex;
		gap: 10px;
	}
	
	&__action-item {
		background-color: $secondary-400;
		padding: 8px 10px 3px;
		border-radius: 50%;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&--edit {
			background-color: $primary-400;
		}
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__btn-create {
		margin-top: 10px;
		margin-bottom: 10px;
		margin-right: auto;
	}
	
	&__form-wrapper {
		padding: 15px;
	}
	
	&__btn-wrapper {
		display: flex;
		justify-content: space-between;
		gap: 10px;
		padding-bottom: 220px;
	}
	
	&:deep(.m-date-picker__errors) {
		padding: 3px 0;
	}
}
</style>
