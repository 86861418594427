<template>
	<div v-for="item in items" :key="item.id" class="content-writer-counter">
		<div class="content-writer-counter__text">{{ item.text }}:</div>
		<div class="content-writer-counter__number">{{ item.counter }}</div>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import type { IDashboardContentWriter } from '@/models/DashboardContentWriterModel';

const { t } = useI18n();

const items = ref<Array<IDashboardContentWriter>>([
	{
		id: 1,
		text: t('dashboard.contentWritingOrders'),
		counter: 0,
	},
	{
		id: 2,
		text: t('dashboard.sketch'),
		counter: 0,
	},
	{
		id: 3,
		text: t('dashboard.inProgress'),
		counter: 0,
	},
	{
		id: 4,
		text: t('dashboard.approved'),
		counter: 0,
	},
	{
		id: 5,
		text: t('dashboard.disapproved'),
		counter: 0,
	},
]);
</script>

<style scoped lang="scss">
.content-writer-counter {
	display: flex;
	flex-direction: column;
	gap: 20px;
	font-size: 15px;
	margin-bottom: 20px;
	
	&__text {
		font-weight: 400;
	}
	
	&__number {
		font-weight: 700;
	}
}
</style>
