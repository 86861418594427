import BaseApi from '@/api/BaseApi';
import type { IPublicationChangeLinksRequest } from '@/models/PublicationModel';

export default class PublicationApi extends BaseApi {
	async changePublicationLinks (uuid:string|null, params:IPublicationChangeLinksRequest) {
		return this.put<IPublicationChangeLinksRequest, any>({
			url: `api/v1/publications/${uuid}`,
			data: params,
		});
	};
};
