import i18n from '@/utils/i18n';
import type { CountryInterface } from '@/models/CountryModel';

interface CountryTranslations {
	[locale: string]: {
		countries: string[];
	};
}

export const setCountryTranslations = (locale:string):Array<CountryInterface> => {
	const i18nUnknown = i18n as unknown;
	const i18nTyped = i18nUnknown as CountryTranslations;
	const countryName = i18nTyped[locale] || '';

	return  [
		{
			"id": 4,
			"capital": "Kabul",
			"citizenship": "Afghan",
			"country_code": "004",
			"currency": "afghani",
			"currency_code": "AFN",
			"currency_sub_unit": "pul",
			"currency_symbol": "؋",
			"currency_decimals": 2,
			"full_name": "Islamic Republic of Afghanistan",
			"iso_3166_2": "AF",
			"iso_3166_3": "AFG",
			"name": countryName.countries[4],
			"region_code": "142",
			"sub_region_code": "034",
			"eea": false,
			"calling_code": "93",
			"flag": "AF.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 248,
			"capital": "Mariehamn",
			"citizenship": "Åland Islander",
			"country_code": "248",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": null,
			"full_name": "Åland Islands",
			"iso_3166_2": "AX",
			"iso_3166_3": "ALA",
			"name": countryName.countries[248],
			"region_code": "150",
			"sub_region_code": "154",
			"eea": false,
			"calling_code": "358",
			"flag": null,
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 8,
			"capital": "Tirana",
			"citizenship": "Albanian",
			"country_code": "008",
			"currency": "lek",
			"currency_code": "ALL",
			"currency_sub_unit": "(qindar (pl. qindarka))",
			"currency_symbol": "Lek",
			"currency_decimals": 2,
			"full_name": "Republic of Albania",
			"iso_3166_2": "AL",
			"iso_3166_3": "ALB",
			"name": countryName.countries[8],
			"region_code": "150",
			"sub_region_code": "039",
			"eea": false,
			"calling_code": "355",
			"flag": "AL.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 12,
			"capital": "Algiers",
			"citizenship": "Algerian",
			"country_code": "012",
			"currency": "Algerian dinar",
			"currency_code": "DZD",
			"currency_sub_unit": "centime",
			"currency_symbol": "DZD",
			"currency_decimals": 2,
			"full_name": "People’s Democratic Republic of Algeria",
			"iso_3166_2": "DZ",
			"iso_3166_3": "DZA",
			"name": countryName.countries[12],
			"region_code": "002",
			"sub_region_code": "015",
			"eea": false,
			"calling_code": "213",
			"flag": "DZ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 16,
			"capital": "Pago Pago",
			"citizenship": "American Samoan",
			"country_code": "016",
			"currency": "US dollar",
			"currency_code": "USD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Territory of American",
			"iso_3166_2": "AS",
			"iso_3166_3": "ASM",
			"name": countryName.countries[16],
			"region_code": "009",
			"sub_region_code": "061",
			"eea": false,
			"calling_code": "1",
			"flag": "AS.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 20,
			"capital": "Andorra la Vella",
			"citizenship": "Andorran",
			"country_code": "020",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Principality of Andorra",
			"iso_3166_2": "AD",
			"iso_3166_3": "AND",
			"name": countryName.countries[20],
			"region_code": "150",
			"sub_region_code": "039",
			"eea": false,
			"calling_code": "376",
			"flag": "AD.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 24,
			"capital": "Luanda",
			"citizenship": "Angolan",
			"country_code": "024",
			"currency": "kwanza",
			"currency_code": "AOA",
			"currency_sub_unit": "cêntimo",
			"currency_symbol": "Kz",
			"currency_decimals": 2,
			"full_name": "Republic of Angola",
			"iso_3166_2": "AO",
			"iso_3166_3": "AGO",
			"name": countryName.countries[24],
			"region_code": "002",
			"sub_region_code": "017",
			"eea": false,
			"calling_code": "244",
			"flag": "AO.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 660,
			"capital": "The Valley",
			"citizenship": "Anguillan",
			"country_code": "660",
			"currency": "East Caribbean dollar",
			"currency_code": "XCD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Anguilla",
			"iso_3166_2": "AI",
			"iso_3166_3": "AIA",
			"name": countryName.countries[660],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "AI.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 10,
			"capital": "Antartica",
			"citizenship": "of Antartica",
			"country_code": "010",
			"currency": "",
			"currency_code": "",
			"currency_sub_unit": "",
			"currency_symbol": "",
			"currency_decimals": 2,
			"full_name": "Antarctica",
			"iso_3166_2": "AQ",
			"iso_3166_3": "ATA",
			"name": countryName.countries[10],
			"region_code": "",
			"sub_region_code": "",
			"eea": false,
			"calling_code": "672",
			"flag": "AQ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 28,
			"capital": "St John’s",
			"citizenship": "of Antigua and Barbuda",
			"country_code": "028",
			"currency": "East Caribbean dollar",
			"currency_code": "XCD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Antigua and Barbuda",
			"iso_3166_2": "AG",
			"iso_3166_3": "ATG",
			"name": countryName.countries[28],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "AG.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 32,
			"capital": "Buenos Aires",
			"citizenship": "Argentinian",
			"country_code": "032",
			"currency": "Argentine peso",
			"currency_code": "ARS",
			"currency_sub_unit": "centavo",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Argentine Republic",
			"iso_3166_2": "AR",
			"iso_3166_3": "ARG",
			"name": countryName.countries[32],
			"region_code": "019",
			"sub_region_code": "005",
			"eea": false,
			"calling_code": "54",
			"flag": "AR.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 51,
			"capital": "Yerevan",
			"citizenship": "Armenian",
			"country_code": "051",
			"currency": "dram (inv.)",
			"currency_code": "AMD",
			"currency_sub_unit": "luma",
			"currency_symbol": "AMD",
			"currency_decimals": 2,
			"full_name": "Republic of Armenia",
			"iso_3166_2": "AM",
			"iso_3166_3": "ARM",
			"name": countryName.countries[51],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": false,
			"calling_code": "374",
			"flag": "AM.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 533,
			"capital": "Oranjestad",
			"citizenship": "Aruban",
			"country_code": "533",
			"currency": "Aruban guilder",
			"currency_code": "AWG",
			"currency_sub_unit": "cent",
			"currency_symbol": "ƒ",
			"currency_decimals": 2,
			"full_name": "Aruba",
			"iso_3166_2": "AW",
			"iso_3166_3": "ABW",
			"name": countryName.countries[533],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "297",
			"flag": "AW.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 36,
			"capital": "Canberra",
			"citizenship": "Australian",
			"country_code": "036",
			"currency": "Australian dollar",
			"currency_code": "AUD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Commonwealth of Australia",
			"iso_3166_2": "AU",
			"iso_3166_3": "AUS",
			"name": countryName.countries[36],
			"region_code": "009",
			"sub_region_code": "053",
			"eea": false,
			"calling_code": "61",
			"flag": "AU.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 40,
			"capital": "Vienna",
			"citizenship": "Austrian",
			"country_code": "040",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Republic of Austria",
			"iso_3166_2": "AT",
			"iso_3166_3": "AUT",
			"name": countryName.countries[40],
			"region_code": "150",
			"sub_region_code": "155",
			"eea": true,
			"calling_code": "43",
			"flag": "AT.png",
			"vat_rate": 20,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 31,
			"capital": "Baku",
			"citizenship": "Azerbaijani",
			"country_code": "031",
			"currency": "Azerbaijani manat",
			"currency_code": "AZN",
			"currency_sub_unit": "kepik (inv.)",
			"currency_symbol": "ман",
			"currency_decimals": 2,
			"full_name": "Republic of Azerbaijan",
			"iso_3166_2": "AZ",
			"iso_3166_3": "AZE",
			"name": countryName.countries[31],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": false,
			"calling_code": "994",
			"flag": "AZ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 44,
			"capital": "Nassau",
			"citizenship": "Bahamian",
			"country_code": "044",
			"currency": "Bahamian dollar",
			"currency_code": "BSD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Commonwealth of the Bahamas",
			"iso_3166_2": "BS",
			"iso_3166_3": "BHS",
			"name": countryName.countries[44],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "BS.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 48,
			"capital": "Manama",
			"citizenship": "Bahraini",
			"country_code": "048",
			"currency": "Bahraini dinar",
			"currency_code": "BHD",
			"currency_sub_unit": "fils (inv.)",
			"currency_symbol": "BHD",
			"currency_decimals": 3,
			"full_name": "Kingdom of Bahrain",
			"iso_3166_2": "BH",
			"iso_3166_3": "BHR",
			"name": countryName.countries[48],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": false,
			"calling_code": "973",
			"flag": "BH.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 50,
			"capital": "Dhaka",
			"citizenship": "Bangladeshi",
			"country_code": "050",
			"currency": "taka (inv.)",
			"currency_code": "BDT",
			"currency_sub_unit": "poisha (inv.)",
			"currency_symbol": "BDT",
			"currency_decimals": 2,
			"full_name": "People’s Republic of Bangladesh",
			"iso_3166_2": "BD",
			"iso_3166_3": "BGD",
			"name": countryName.countries[50],
			"region_code": "142",
			"sub_region_code": "034",
			"eea": false,
			"calling_code": "880",
			"flag": "BD.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 52,
			"capital": "Bridgetown",
			"citizenship": "Barbadian",
			"country_code": "052",
			"currency": "Barbados dollar",
			"currency_code": "BBD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Barbados",
			"iso_3166_2": "BB",
			"iso_3166_3": "BRB",
			"name": countryName.countries[52],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "BB.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 112,
			"capital": "Minsk",
			"citizenship": "Belarusian",
			"country_code": "112",
			"currency": "Belarusian rouble",
			"currency_code": "BYR",
			"currency_sub_unit": "kopek",
			"currency_symbol": "p.",
			"currency_decimals": 2,
			"full_name": "Republic of Belarus",
			"iso_3166_2": "BY",
			"iso_3166_3": "BLR",
			"name": countryName.countries[112],
			"region_code": "150",
			"sub_region_code": "151",
			"eea": false,
			"calling_code": "375",
			"flag": "BY.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 56,
			"capital": "Brussels",
			"citizenship": "Belgian",
			"country_code": "056",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Kingdom of Belgium",
			"iso_3166_2": "BE",
			"iso_3166_3": "BEL",
			"name": countryName.countries[56],
			"region_code": "150",
			"sub_region_code": "155",
			"eea": true,
			"calling_code": "32",
			"flag": "BE.png",
			"vat_rate": 21,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 84,
			"capital": "Belmopan",
			"citizenship": "Belizean",
			"country_code": "084",
			"currency": "Belize dollar",
			"currency_code": "BZD",
			"currency_sub_unit": "cent",
			"currency_symbol": "BZ$",
			"currency_decimals": 2,
			"full_name": "Belize",
			"iso_3166_2": "BZ",
			"iso_3166_3": "BLZ",
			"name": countryName.countries[84],
			"region_code": "019",
			"sub_region_code": "013",
			"eea": false,
			"calling_code": "501",
			"flag": "BZ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 204,
			"capital": "Porto Novo (BJ1)",
			"citizenship": "Beninese",
			"country_code": "204",
			"currency": "CFA franc (BCEAO)",
			"currency_code": "XOF",
			"currency_sub_unit": "centime",
			"currency_symbol": "XOF",
			"currency_decimals": 0,
			"full_name": "Republic of Benin",
			"iso_3166_2": "BJ",
			"iso_3166_3": "BEN",
			"name": countryName.countries[204],
			"region_code": "002",
			"sub_region_code": "011",
			"eea": false,
			"calling_code": "229",
			"flag": "BJ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 60,
			"capital": "Hamilton",
			"citizenship": "Bermudian",
			"country_code": "060",
			"currency": "Bermuda dollar",
			"currency_code": "BMD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Bermuda",
			"iso_3166_2": "BM",
			"iso_3166_3": "BMU",
			"name": countryName.countries[60],
			"region_code": "019",
			"sub_region_code": "021",
			"eea": false,
			"calling_code": "1",
			"flag": "BM.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 64,
			"capital": "Thimphu",
			"citizenship": "Bhutanese",
			"country_code": "064",
			"currency": "ngultrum (inv.)",
			"currency_code": "BTN",
			"currency_sub_unit": "chhetrum (inv.)",
			"currency_symbol": "BTN",
			"currency_decimals": 2,
			"full_name": "Kingdom of Bhutan",
			"iso_3166_2": "BT",
			"iso_3166_3": "BTN",
			"name": countryName.countries[64],
			"region_code": "142",
			"sub_region_code": "034",
			"eea": false,
			"calling_code": "975",
			"flag": "BT.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 68,
			"capital": "Sucre (BO1)",
			"citizenship": "Bolivian",
			"country_code": "068",
			"currency": "boliviano",
			"currency_code": "BOB",
			"currency_sub_unit": "centavo",
			"currency_symbol": "$b",
			"currency_decimals": 2,
			"full_name": "Plurinational State of Bolivia",
			"iso_3166_2": "BO",
			"iso_3166_3": "BOL",
			"name": countryName.countries[68],
			"region_code": "019",
			"sub_region_code": "005",
			"eea": false,
			"calling_code": "591",
			"flag": "BO.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 535,
			"capital": null,
			"citizenship": "of Bonaire, Sint Eustatius and Saba",
			"country_code": "535",
			"currency": "US dollar",
			"currency_code": "USD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": null,
			"full_name": null,
			"iso_3166_2": "BQ",
			"iso_3166_3": "BES",
			"name": countryName.countries[535],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "599",
			"flag": null,
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 70,
			"capital": "Sarajevo",
			"citizenship": "of Bosnia and Herzegovina",
			"country_code": "070",
			"currency": "convertible mark",
			"currency_code": "BAM",
			"currency_sub_unit": "fening",
			"currency_symbol": "KM",
			"currency_decimals": 2,
			"full_name": "Bosnia and Herzegovina",
			"iso_3166_2": "BA",
			"iso_3166_3": "BIH",
			"name": countryName.countries[70],
			"region_code": "150",
			"sub_region_code": "039",
			"eea": false,
			"calling_code": "387",
			"flag": "BA.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 72,
			"capital": "Gaborone",
			"citizenship": "Botswanan",
			"country_code": "072",
			"currency": "pula (inv.)",
			"currency_code": "BWP",
			"currency_sub_unit": "thebe (inv.)",
			"currency_symbol": "P",
			"currency_decimals": 2,
			"full_name": "Republic of Botswana",
			"iso_3166_2": "BW",
			"iso_3166_3": "BWA",
			"name": countryName.countries[72],
			"region_code": "002",
			"sub_region_code": "018",
			"eea": false,
			"calling_code": "267",
			"flag": "BW.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 74,
			"capital": "Bouvet island",
			"citizenship": "of Bouvet island",
			"country_code": "074",
			"currency": "",
			"currency_code": "",
			"currency_sub_unit": "",
			"currency_symbol": "kr",
			"currency_decimals": 2,
			"full_name": "Bouvet Island",
			"iso_3166_2": "BV",
			"iso_3166_3": "BVT",
			"name": countryName.countries[74],
			"region_code": "",
			"sub_region_code": "",
			"eea": false,
			"calling_code": "47",
			"flag": "BV.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 76,
			"capital": "Brasilia",
			"citizenship": "Brazilian",
			"country_code": "076",
			"currency": "real (pl. reais)",
			"currency_code": "BRL",
			"currency_sub_unit": "centavo",
			"currency_symbol": "R$",
			"currency_decimals": 2,
			"full_name": "Federative Republic of Brazil",
			"iso_3166_2": "BR",
			"iso_3166_3": "BRA",
			"name": countryName.countries[76],
			"region_code": "019",
			"sub_region_code": "005",
			"eea": false,
			"calling_code": "55",
			"flag": "BR.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 86,
			"capital": "Diego Garcia",
			"citizenship": "Changosian",
			"country_code": "086",
			"currency": "US dollar",
			"currency_code": "USD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "British Indian Ocean Territory",
			"iso_3166_2": "IO",
			"iso_3166_3": "IOT",
			"name": countryName.countries[86],
			"region_code": "",
			"sub_region_code": "",
			"eea": false,
			"calling_code": "246",
			"flag": "IO.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 96,
			"capital": "Bandar Seri Begawan",
			"citizenship": "Bruneian",
			"country_code": "096",
			"currency": "Brunei dollar",
			"currency_code": "BND",
			"currency_sub_unit": "sen (inv.)",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Brunei Darussalam",
			"iso_3166_2": "BN",
			"iso_3166_3": "BRN",
			"name": countryName.countries[96],
			"region_code": "142",
			"sub_region_code": "035",
			"eea": false,
			"calling_code": "673",
			"flag": "BN.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 100,
			"capital": "Sofia",
			"citizenship": "Bulgarian",
			"country_code": "100",
			"currency": "lev (pl. leva)",
			"currency_code": "BGN",
			"currency_sub_unit": "stotinka",
			"currency_symbol": "лв",
			"currency_decimals": 2,
			"full_name": "Republic of Bulgaria",
			"iso_3166_2": "BG",
			"iso_3166_3": "BGR",
			"name": countryName.countries[100],
			"region_code": "150",
			"sub_region_code": "151",
			"eea": true,
			"calling_code": "359",
			"flag": "BG.png",
			"vat_rate": 20,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 854,
			"capital": "Ouagadougou",
			"citizenship": "Burkinabe",
			"country_code": "854",
			"currency": "CFA franc (BCEAO)",
			"currency_code": "XOF",
			"currency_sub_unit": "centime",
			"currency_symbol": "XOF",
			"currency_decimals": 0,
			"full_name": "Burkina Faso",
			"iso_3166_2": "BF",
			"iso_3166_3": "BFA",
			"name": countryName.countries[854],
			"region_code": "002",
			"sub_region_code": "011",
			"eea": false,
			"calling_code": "226",
			"flag": "BF.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 108,
			"capital": "Bujumbura",
			"citizenship": "Burundian",
			"country_code": "108",
			"currency": "Burundi franc",
			"currency_code": "BIF",
			"currency_sub_unit": "centime",
			"currency_symbol": "BIF",
			"currency_decimals": 0,
			"full_name": "Republic of Burundi",
			"iso_3166_2": "BI",
			"iso_3166_3": "BDI",
			"name": countryName.countries[108],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "257",
			"flag": "BI.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 116,
			"capital": "Phnom Penh",
			"citizenship": "Cambodian",
			"country_code": "116",
			"currency": "riel",
			"currency_code": "KHR",
			"currency_sub_unit": "sen (inv.)",
			"currency_symbol": "៛",
			"currency_decimals": 2,
			"full_name": "Kingdom of Cambodia",
			"iso_3166_2": "KH",
			"iso_3166_3": "KHM",
			"name": countryName.countries[116],
			"region_code": "142",
			"sub_region_code": "035",
			"eea": false,
			"calling_code": "855",
			"flag": "KH.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 120,
			"capital": "Yaoundé",
			"citizenship": "Cameroonian",
			"country_code": "120",
			"currency": "CFA franc (BEAC)",
			"currency_code": "XAF",
			"currency_sub_unit": "centime",
			"currency_symbol": "FCF",
			"currency_decimals": 0,
			"full_name": "Republic of Cameroon",
			"iso_3166_2": "CM",
			"iso_3166_3": "CMR",
			"name": countryName.countries[120],
			"region_code": "002",
			"sub_region_code": "017",
			"eea": false,
			"calling_code": "237",
			"flag": "CM.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 124,
			"capital": "Ottawa",
			"citizenship": "Canadian",
			"country_code": "124",
			"currency": "Canadian dollar",
			"currency_code": "CAD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Canada",
			"iso_3166_2": "CA",
			"iso_3166_3": "CAN",
			"name": countryName.countries[124],
			"region_code": "019",
			"sub_region_code": "021",
			"eea": false,
			"calling_code": "1",
			"flag": "CA.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 132,
			"capital": "Praia",
			"citizenship": "Cape Verdean",
			"country_code": "132",
			"currency": "Cape Verde escudo",
			"currency_code": "CVE",
			"currency_sub_unit": "centavo",
			"currency_symbol": "CVE",
			"currency_decimals": 2,
			"full_name": "Republic of Cape Verde",
			"iso_3166_2": "CV",
			"iso_3166_3": "CPV",
			"name": countryName.countries[132],
			"region_code": "002",
			"sub_region_code": "011",
			"eea": false,
			"calling_code": "238",
			"flag": "CV.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 136,
			"capital": "George Town",
			"citizenship": "Caymanian",
			"country_code": "136",
			"currency": "Cayman Islands dollar",
			"currency_code": "KYD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Cayman Islands",
			"iso_3166_2": "KY",
			"iso_3166_3": "CYM",
			"name": countryName.countries[136],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "KY.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 140,
			"capital": "Bangui",
			"citizenship": "Central African",
			"country_code": "140",
			"currency": "CFA franc (BEAC)",
			"currency_code": "XAF",
			"currency_sub_unit": "centime",
			"currency_symbol": "CFA",
			"currency_decimals": 0,
			"full_name": "Central African Republic",
			"iso_3166_2": "CF",
			"iso_3166_3": "CAF",
			"name": countryName.countries[140],
			"region_code": "002",
			"sub_region_code": "017",
			"eea": false,
			"calling_code": "236",
			"flag": "CF.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 148,
			"capital": "N’Djamena",
			"citizenship": "Chadian",
			"country_code": "148",
			"currency": "CFA franc (BEAC)",
			"currency_code": "XAF",
			"currency_sub_unit": "centime",
			"currency_symbol": "XAF",
			"currency_decimals": 0,
			"full_name": "Republic of Chad",
			"iso_3166_2": "TD",
			"iso_3166_3": "TCD",
			"name": countryName.countries[148],
			"region_code": "002",
			"sub_region_code": "017",
			"eea": false,
			"calling_code": "235",
			"flag": "TD.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 152,
			"capital": "Santiago",
			"citizenship": "Chilean",
			"country_code": "152",
			"currency": "Chilean peso",
			"currency_code": "CLP",
			"currency_sub_unit": "centavo",
			"currency_symbol": "CLP",
			"currency_decimals": 0,
			"full_name": "Republic of Chile",
			"iso_3166_2": "CL",
			"iso_3166_3": "CHL",
			"name": countryName.countries[152],
			"region_code": "019",
			"sub_region_code": "005",
			"eea": false,
			"calling_code": "56",
			"flag": "CL.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 156,
			"capital": "Beijing",
			"citizenship": "Chinese",
			"country_code": "156",
			"currency": "renminbi-yuan (inv.)",
			"currency_code": "CNY",
			"currency_sub_unit": "jiao (10)",
			"currency_symbol": "¥",
			"currency_decimals": 2,
			"full_name": "People’s Republic of China",
			"iso_3166_2": "CN",
			"iso_3166_3": "CHN",
			"name": countryName.countries[156],
			"region_code": "142",
			"sub_region_code": "030",
			"eea": false,
			"calling_code": "86",
			"flag": "CN.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 162,
			"capital": "Flying Fish Cove",
			"citizenship": "Christmas Islander",
			"country_code": "162",
			"currency": "Australian dollar",
			"currency_code": "AUD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Christmas Island Territory",
			"iso_3166_2": "CX",
			"iso_3166_3": "CXR",
			"name": countryName.countries[162],
			"region_code": "",
			"sub_region_code": "",
			"eea": false,
			"calling_code": "61",
			"flag": "CX.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 166,
			"capital": "Bantam",
			"citizenship": "Cocos Islander",
			"country_code": "166",
			"currency": "Australian dollar",
			"currency_code": "AUD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Territory of Cocos (Keeling) Islands",
			"iso_3166_2": "CC",
			"iso_3166_3": "CCK",
			"name": countryName.countries[166],
			"region_code": "",
			"sub_region_code": "",
			"eea": false,
			"calling_code": "61",
			"flag": "CC.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 170,
			"capital": "Santa Fe de Bogotá",
			"citizenship": "Colombian",
			"country_code": "170",
			"currency": "Colombian peso",
			"currency_code": "COP",
			"currency_sub_unit": "centavo",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Republic of Colombia",
			"iso_3166_2": "CO",
			"iso_3166_3": "COL",
			"name": countryName.countries[170],
			"region_code": "019",
			"sub_region_code": "005",
			"eea": false,
			"calling_code": "57",
			"flag": "CO.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 174,
			"capital": "Moroni",
			"citizenship": "Comorian",
			"country_code": "174",
			"currency": "Comorian franc",
			"currency_code": "KMF",
			"currency_sub_unit": "",
			"currency_symbol": "KMF",
			"currency_decimals": 0,
			"full_name": "Union of the Comoros",
			"iso_3166_2": "KM",
			"iso_3166_3": "COM",
			"name": countryName.countries[174],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "269",
			"flag": "KM.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 178,
			"capital": "Brazzaville",
			"citizenship": "Congolese",
			"country_code": "178",
			"currency": "CFA franc (BEAC)",
			"currency_code": "XAF",
			"currency_sub_unit": "centime",
			"currency_symbol": "FCF",
			"currency_decimals": 0,
			"full_name": "Republic of the Congo",
			"iso_3166_2": "CG",
			"iso_3166_3": "COG",
			"name": countryName.countries[178],
			"region_code": "002",
			"sub_region_code": "017",
			"eea": false,
			"calling_code": "242",
			"flag": "CG.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 180,
			"capital": "Kinshasa",
			"citizenship": "Congolese",
			"country_code": "180",
			"currency": "Congolese franc",
			"currency_code": "CDF",
			"currency_sub_unit": "centime",
			"currency_symbol": "CDF",
			"currency_decimals": 2,
			"full_name": "Democratic Republic of the Congo",
			"iso_3166_2": "CD",
			"iso_3166_3": "COD",
			"name": countryName.countries[180],
			"region_code": "002",
			"sub_region_code": "017",
			"eea": false,
			"calling_code": "243",
			"flag": "CD.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 184,
			"capital": "Avarua",
			"citizenship": "Cook Islander",
			"country_code": "184",
			"currency": "New Zealand dollar",
			"currency_code": "NZD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Cook Islands",
			"iso_3166_2": "CK",
			"iso_3166_3": "COK",
			"name": countryName.countries[184],
			"region_code": "009",
			"sub_region_code": "061",
			"eea": false,
			"calling_code": "682",
			"flag": "CK.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 188,
			"capital": "San José",
			"citizenship": "Costa Rican",
			"country_code": "188",
			"currency": "Costa Rican colón (pl. colones)",
			"currency_code": "CRC",
			"currency_sub_unit": "céntimo",
			"currency_symbol": "₡",
			"currency_decimals": 2,
			"full_name": "Republic of Costa Rica",
			"iso_3166_2": "CR",
			"iso_3166_3": "CRI",
			"name": countryName.countries[188],
			"region_code": "019",
			"sub_region_code": "013",
			"eea": false,
			"calling_code": "506",
			"flag": "CR.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 384,
			"capital": "Yamoussoukro (CI1)",
			"citizenship": "Ivorian",
			"country_code": "384",
			"currency": "CFA franc (BCEAO)",
			"currency_code": "XOF",
			"currency_sub_unit": "centime",
			"currency_symbol": "XOF",
			"currency_decimals": 0,
			"full_name": "Republic of Côte d’Ivoire",
			"iso_3166_2": "CI",
			"iso_3166_3": "CIV",
			"name": countryName.countries[384],
			"region_code": "002",
			"sub_region_code": "011",
			"eea": false,
			"calling_code": "225",
			"flag": "CI.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 191,
			"capital": "Zagreb",
			"citizenship": "Croatian",
			"country_code": "191",
			"currency": "kuna (inv.)",
			"currency_code": "HRK",
			"currency_sub_unit": "lipa (inv.)",
			"currency_symbol": "kn",
			"currency_decimals": 2,
			"full_name": "Republic of Croatia",
			"iso_3166_2": "HR",
			"iso_3166_3": "HRV",
			"name": countryName.countries[191],
			"region_code": "150",
			"sub_region_code": "039",
			"eea": true,
			"calling_code": "385",
			"flag": "HR.png",
			"vat_rate": 25,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 192,
			"capital": "Havana",
			"citizenship": "Cuban",
			"country_code": "192",
			"currency": "Cuban peso",
			"currency_code": "CUP",
			"currency_sub_unit": "centavo",
			"currency_symbol": "₱",
			"currency_decimals": 2,
			"full_name": "Republic of Cuba",
			"iso_3166_2": "CU",
			"iso_3166_3": "CUB",
			"name": countryName.countries[192],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "53",
			"flag": "CU.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 531,
			"capital": "Willemstad",
			"citizenship": "Curaçaoan",
			"country_code": "531",
			"currency": "Netherlands Antillean guilder (CW1)",
			"currency_code": "ANG",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": null,
			"full_name": "Curaçao",
			"iso_3166_2": "CW",
			"iso_3166_3": "CUW",
			"name": countryName.countries[531],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "599",
			"flag": null,
			"vat_rate": 21,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 196,
			"capital": "Nicosia",
			"citizenship": "Cypriot",
			"country_code": "196",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "CYP",
			"currency_decimals": 2,
			"full_name": "Republic of Cyprus",
			"iso_3166_2": "CY",
			"iso_3166_3": "CYP",
			"name": countryName.countries[196],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": true,
			"calling_code": "357",
			"flag": "CY.png",
			"vat_rate": 19,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 203,
			"capital": "Prague",
			"citizenship": "Czech",
			"country_code": "203",
			"currency": "Czech koruna (pl. koruny)",
			"currency_code": "CZK",
			"currency_sub_unit": "halér",
			"currency_symbol": "Kč",
			"currency_decimals": 2,
			"full_name": "Czech Republic",
			"iso_3166_2": "CZ",
			"iso_3166_3": "CZE",
			"name": countryName.countries[203],
			"region_code": "150",
			"sub_region_code": "151",
			"eea": true,
			"calling_code": "420",
			"flag": "CZ.png",
			"vat_rate": 21,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 208,
			"capital": "Copenhagen",
			"citizenship": "Danish",
			"country_code": "208",
			"currency": "Danish krone",
			"currency_code": "DKK",
			"currency_sub_unit": "øre (inv.)",
			"currency_symbol": "kr",
			"currency_decimals": 2,
			"full_name": "Kingdom of Denmark",
			"iso_3166_2": "DK",
			"iso_3166_3": "DNK",
			"name": countryName.countries[208],
			"region_code": "150",
			"sub_region_code": "154",
			"eea": true,
			"calling_code": "45",
			"flag": "DK.png",
			"vat_rate": 25,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 262,
			"capital": "Djibouti",
			"citizenship": "Djiboutian",
			"country_code": "262",
			"currency": "Djibouti franc",
			"currency_code": "DJF",
			"currency_sub_unit": "",
			"currency_symbol": "DJF",
			"currency_decimals": 0,
			"full_name": "Republic of Djibouti",
			"iso_3166_2": "DJ",
			"iso_3166_3": "DJI",
			"name": countryName.countries[262],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "253",
			"flag": "DJ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 212,
			"capital": "Roseau",
			"citizenship": "Dominican",
			"country_code": "212",
			"currency": "East Caribbean dollar",
			"currency_code": "XCD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Commonwealth of Dominica",
			"iso_3166_2": "DM",
			"iso_3166_3": "DMA",
			"name": countryName.countries[212],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "DM.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 214,
			"capital": "Santo Domingo",
			"citizenship": "Dominican",
			"country_code": "214",
			"currency": "Dominican peso",
			"currency_code": "DOP",
			"currency_sub_unit": "centavo",
			"currency_symbol": "RD$",
			"currency_decimals": 2,
			"full_name": "Dominican Republic",
			"iso_3166_2": "DO",
			"iso_3166_3": "DOM",
			"name": countryName.countries[214],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "DO.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 218,
			"capital": "Quito",
			"citizenship": "Ecuadorian",
			"country_code": "218",
			"currency": "US dollar",
			"currency_code": "USD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Republic of Ecuador",
			"iso_3166_2": "EC",
			"iso_3166_3": "ECU",
			"name": countryName.countries[218],
			"region_code": "019",
			"sub_region_code": "005",
			"eea": false,
			"calling_code": "593",
			"flag": "EC.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 818,
			"capital": "Cairo",
			"citizenship": "Egyptian",
			"country_code": "818",
			"currency": "Egyptian pound",
			"currency_code": "EGP",
			"currency_sub_unit": "piastre",
			"currency_symbol": "£",
			"currency_decimals": 2,
			"full_name": "Arab Republic of Egypt",
			"iso_3166_2": "EG",
			"iso_3166_3": "EGY",
			"name": countryName.countries[818],
			"region_code": "002",
			"sub_region_code": "015",
			"eea": false,
			"calling_code": "20",
			"flag": "EG.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 222,
			"capital": "San Salvador",
			"citizenship": "Salvadoran",
			"country_code": "222",
			"currency": "Salvadorian colón (pl. colones)",
			"currency_code": "SVC",
			"currency_sub_unit": "centavo",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Republic of El Salvador",
			"iso_3166_2": "SV",
			"iso_3166_3": "SLV",
			"name": countryName.countries[222],
			"region_code": "019",
			"sub_region_code": "013",
			"eea": false,
			"calling_code": "503",
			"flag": "SV.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 226,
			"capital": "Malabo",
			"citizenship": "Equatorial Guinean",
			"country_code": "226",
			"currency": "CFA franc (BEAC)",
			"currency_code": "XAF",
			"currency_sub_unit": "centime",
			"currency_symbol": "FCF",
			"currency_decimals": 2,
			"full_name": "Republic of Equatorial Guinea",
			"iso_3166_2": "GQ",
			"iso_3166_3": "GNQ",
			"name": countryName.countries[226],
			"region_code": "002",
			"sub_region_code": "017",
			"eea": false,
			"calling_code": "240",
			"flag": "GQ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 232,
			"capital": "Asmara",
			"citizenship": "Eritrean",
			"country_code": "232",
			"currency": "nakfa",
			"currency_code": "ERN",
			"currency_sub_unit": "cent",
			"currency_symbol": "Nfk",
			"currency_decimals": 2,
			"full_name": "State of Eritrea",
			"iso_3166_2": "ER",
			"iso_3166_3": "ERI",
			"name": countryName.countries[232],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "291",
			"flag": "ER.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 233,
			"capital": "Tallinn",
			"citizenship": "Estonian",
			"country_code": "233",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "kr",
			"currency_decimals": 2,
			"full_name": "Republic of Estonia",
			"iso_3166_2": "EE",
			"iso_3166_3": "EST",
			"name": countryName.countries[233],
			"region_code": "150",
			"sub_region_code": "154",
			"eea": true,
			"calling_code": "372",
			"flag": "EE.png",
			"vat_rate": 20,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 231,
			"capital": "Addis Ababa",
			"citizenship": "Ethiopian",
			"country_code": "231",
			"currency": "birr (inv.)",
			"currency_code": "ETB",
			"currency_sub_unit": "cent",
			"currency_symbol": "ETB",
			"currency_decimals": 2,
			"full_name": "Federal Democratic Republic of Ethiopia",
			"iso_3166_2": "ET",
			"iso_3166_3": "ETH",
			"name": countryName.countries[231],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "251",
			"flag": "ET.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 238,
			"capital": "Stanley",
			"citizenship": "Falkland Islander",
			"country_code": "238",
			"currency": "Falkland Islands pound",
			"currency_code": "FKP",
			"currency_sub_unit": "new penny",
			"currency_symbol": "£",
			"currency_decimals": 2,
			"full_name": "Falkland Islands",
			"iso_3166_2": "FK",
			"iso_3166_3": "FLK",
			"name": countryName.countries[238],
			"region_code": "019",
			"sub_region_code": "005",
			"eea": false,
			"calling_code": "500",
			"flag": "FK.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 234,
			"capital": "Tórshavn",
			"citizenship": "Faeroese",
			"country_code": "234",
			"currency": "Danish krone",
			"currency_code": "DKK",
			"currency_sub_unit": "øre (inv.)",
			"currency_symbol": "kr",
			"currency_decimals": 2,
			"full_name": "Faeroe Islands",
			"iso_3166_2": "FO",
			"iso_3166_3": "FRO",
			"name": countryName.countries[234],
			"region_code": "150",
			"sub_region_code": "154",
			"eea": false,
			"calling_code": "298",
			"flag": "FO.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 242,
			"capital": "Suva",
			"citizenship": "Fijian",
			"country_code": "242",
			"currency": "Fiji dollar",
			"currency_code": "FJD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Republic of Fiji",
			"iso_3166_2": "FJ",
			"iso_3166_3": "FJI",
			"name": countryName.countries[242],
			"region_code": "009",
			"sub_region_code": "054",
			"eea": false,
			"calling_code": "679",
			"flag": "FJ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 246,
			"capital": "Helsinki",
			"citizenship": "Finnish",
			"country_code": "246",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Republic of Finland",
			"iso_3166_2": "FI",
			"iso_3166_3": "FIN",
			"name": countryName.countries[246],
			"region_code": "150",
			"sub_region_code": "154",
			"eea": true,
			"calling_code": "358",
			"flag": "FI.png",
			"vat_rate": 24,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 250,
			"capital": "Paris",
			"citizenship": "French",
			"country_code": "250",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "French Republic",
			"iso_3166_2": "FR",
			"iso_3166_3": "FRA",
			"name": countryName.countries[250],
			"region_code": "150",
			"sub_region_code": "155",
			"eea": true,
			"calling_code": "33",
			"flag": "FR.png",
			"vat_rate": 20,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 254,
			"capital": "Cayenne",
			"citizenship": "Guianese",
			"country_code": "254",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "French Guiana",
			"iso_3166_2": "GF",
			"iso_3166_3": "GUF",
			"name": countryName.countries[254],
			"region_code": "019",
			"sub_region_code": "005",
			"eea": false,
			"calling_code": "594",
			"flag": "GF.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 258,
			"capital": "Papeete",
			"citizenship": "Polynesian",
			"country_code": "258",
			"currency": "CFP franc",
			"currency_code": "XPF",
			"currency_sub_unit": "centime",
			"currency_symbol": "XPF",
			"currency_decimals": 0,
			"full_name": "French Polynesia",
			"iso_3166_2": "PF",
			"iso_3166_3": "PYF",
			"name": countryName.countries[258],
			"region_code": "009",
			"sub_region_code": "061",
			"eea": false,
			"calling_code": "689",
			"flag": "PF.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 260,
			"capital": "Port-aux-Francais",
			"citizenship": "of French Southern and Antarctic Lands",
			"country_code": "260",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "French Southern and Antarctic Lands",
			"iso_3166_2": "TF",
			"iso_3166_3": "ATF",
			"name": countryName.countries[260],
			"region_code": "",
			"sub_region_code": "",
			"eea": false,
			"calling_code": "33",
			"flag": "TF.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 266,
			"capital": "Libreville",
			"citizenship": "Gabonese",
			"country_code": "266",
			"currency": "CFA franc (BEAC)",
			"currency_code": "XAF",
			"currency_sub_unit": "centime",
			"currency_symbol": "FCF",
			"currency_decimals": 0,
			"full_name": "Gabonese Republic",
			"iso_3166_2": "GA",
			"iso_3166_3": "GAB",
			"name": countryName.countries[266],
			"region_code": "002",
			"sub_region_code": "017",
			"eea": false,
			"calling_code": "241",
			"flag": "GA.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 270,
			"capital": "Banjul",
			"citizenship": "Gambian",
			"country_code": "270",
			"currency": "dalasi (inv.)",
			"currency_code": "GMD",
			"currency_sub_unit": "butut",
			"currency_symbol": "D",
			"currency_decimals": 2,
			"full_name": "Republic of the Gambia",
			"iso_3166_2": "GM",
			"iso_3166_3": "GMB",
			"name": countryName.countries[270],
			"region_code": "002",
			"sub_region_code": "011",
			"eea": false,
			"calling_code": "220",
			"flag": "GM.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 268,
			"capital": "Tbilisi",
			"citizenship": "Georgian",
			"country_code": "268",
			"currency": "lari",
			"currency_code": "GEL",
			"currency_sub_unit": "tetri (inv.)",
			"currency_symbol": "GEL",
			"currency_decimals": 2,
			"full_name": "Georgia",
			"iso_3166_2": "GE",
			"iso_3166_3": "GEO",
			"name": countryName.countries[268],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": false,
			"calling_code": "995",
			"flag": "GE.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 276,
			"capital": "Berlin",
			"citizenship": "German",
			"country_code": "276",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Federal Republic of Germany",
			"iso_3166_2": "DE",
			"iso_3166_3": "DEU",
			"name": countryName.countries[276],
			"region_code": "150",
			"sub_region_code": "155",
			"eea": true,
			"calling_code": "49",
			"flag": "DE.png",
			"vat_rate": 19,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 288,
			"capital": "Accra",
			"citizenship": "Ghanaian",
			"country_code": "288",
			"currency": "Ghana cedi",
			"currency_code": "GHS",
			"currency_sub_unit": "pesewa",
			"currency_symbol": "¢",
			"currency_decimals": 2,
			"full_name": "Republic of Ghana",
			"iso_3166_2": "GH",
			"iso_3166_3": "GHA",
			"name": countryName.countries[288],
			"region_code": "002",
			"sub_region_code": "011",
			"eea": false,
			"calling_code": "233",
			"flag": "GH.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 292,
			"capital": "Gibraltar",
			"citizenship": "Gibraltarian",
			"country_code": "292",
			"currency": "Gibraltar pound",
			"currency_code": "GIP",
			"currency_sub_unit": "penny",
			"currency_symbol": "£",
			"currency_decimals": 2,
			"full_name": "Gibraltar",
			"iso_3166_2": "GI",
			"iso_3166_3": "GIB",
			"name": countryName.countries[292],
			"region_code": "150",
			"sub_region_code": "039",
			"eea": false,
			"calling_code": "350",
			"flag": "GI.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 300,
			"capital": "Athens",
			"citizenship": "Greek",
			"country_code": "300",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Hellenic Republic",
			"iso_3166_2": "GR",
			"iso_3166_3": "GRC",
			"name": countryName.countries[300],
			"region_code": "150",
			"sub_region_code": "039",
			"eea": true,
			"calling_code": "30",
			"flag": "GR.png",
			"vat_rate": 24,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 304,
			"capital": "Nuuk",
			"citizenship": "Greenlander",
			"country_code": "304",
			"currency": "Danish krone",
			"currency_code": "DKK",
			"currency_sub_unit": "øre (inv.)",
			"currency_symbol": "kr",
			"currency_decimals": 2,
			"full_name": "Greenland",
			"iso_3166_2": "GL",
			"iso_3166_3": "GRL",
			"name": countryName.countries[304],
			"region_code": "019",
			"sub_region_code": "021",
			"eea": false,
			"calling_code": "299",
			"flag": "GL.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 308,
			"capital": "St George’s",
			"citizenship": "Grenadian",
			"country_code": "308",
			"currency": "East Caribbean dollar",
			"currency_code": "XCD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Grenada",
			"iso_3166_2": "GD",
			"iso_3166_3": "GRD",
			"name": countryName.countries[308],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "GD.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 312,
			"capital": "Basse Terre",
			"citizenship": "Guadeloupean",
			"country_code": "312",
			"currency": "euro",
			"currency_code": "EUR ",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Guadeloupe",
			"iso_3166_2": "GP",
			"iso_3166_3": "GLP",
			"name": countryName.countries[312],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "590",
			"flag": "GP.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 316,
			"capital": "Agaña (Hagåtña)",
			"citizenship": "Guamanian",
			"country_code": "316",
			"currency": "US dollar",
			"currency_code": "USD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Territory of Guam",
			"iso_3166_2": "GU",
			"iso_3166_3": "GUM",
			"name": countryName.countries[316],
			"region_code": "009",
			"sub_region_code": "057",
			"eea": false,
			"calling_code": "1",
			"flag": "GU.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 320,
			"capital": "Guatemala City",
			"citizenship": "Guatemalan",
			"country_code": "320",
			"currency": "quetzal (pl. quetzales)",
			"currency_code": "GTQ",
			"currency_sub_unit": "centavo",
			"currency_symbol": "Q",
			"currency_decimals": 2,
			"full_name": "Republic of Guatemala",
			"iso_3166_2": "GT",
			"iso_3166_3": "GTM",
			"name": countryName.countries[320],
			"region_code": "019",
			"sub_region_code": "013",
			"eea": false,
			"calling_code": "502",
			"flag": "GT.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 831,
			"capital": "St Peter Port",
			"citizenship": "of Guernsey",
			"country_code": "831",
			"currency": "Guernsey pound (GG2)",
			"currency_code": "GGP (GG2)",
			"currency_sub_unit": "penny (pl. pence)",
			"currency_symbol": "£",
			"currency_decimals": null,
			"full_name": "Bailiwick of Guernsey",
			"iso_3166_2": "GG",
			"iso_3166_3": "GGY",
			"name": countryName.countries[831],
			"region_code": "150",
			"sub_region_code": "154",
			"eea": false,
			"calling_code": "44",
			"flag": null,
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 324,
			"capital": "Conakry",
			"citizenship": "Guinean",
			"country_code": "324",
			"currency": "Guinean franc",
			"currency_code": "GNF",
			"currency_sub_unit": "",
			"currency_symbol": "GNF",
			"currency_decimals": 0,
			"full_name": "Republic of Guinea",
			"iso_3166_2": "GN",
			"iso_3166_3": "GIN",
			"name": countryName.countries[324],
			"region_code": "002",
			"sub_region_code": "011",
			"eea": false,
			"calling_code": "224",
			"flag": "GN.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 624,
			"capital": "Bissau",
			"citizenship": "Guinea-Bissau national",
			"country_code": "624",
			"currency": "CFA franc (BCEAO)",
			"currency_code": "XOF",
			"currency_sub_unit": "centime",
			"currency_symbol": "XOF",
			"currency_decimals": 0,
			"full_name": "Republic of Guinea-Bissau",
			"iso_3166_2": "GW",
			"iso_3166_3": "GNB",
			"name": countryName.countries[624],
			"region_code": "002",
			"sub_region_code": "011",
			"eea": false,
			"calling_code": "245",
			"flag": "GW.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 328,
			"capital": "Georgetown",
			"citizenship": "Guyanese",
			"country_code": "328",
			"currency": "Guyana dollar",
			"currency_code": "GYD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Cooperative Republic of Guyana",
			"iso_3166_2": "GY",
			"iso_3166_3": "GUY",
			"name": countryName.countries[328],
			"region_code": "019",
			"sub_region_code": "005",
			"eea": false,
			"calling_code": "592",
			"flag": "GY.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 332,
			"capital": "Port-au-Prince",
			"citizenship": "Haitian",
			"country_code": "332",
			"currency": "gourde",
			"currency_code": "HTG",
			"currency_sub_unit": "centime",
			"currency_symbol": "G",
			"currency_decimals": 2,
			"full_name": "Republic of Haiti",
			"iso_3166_2": "HT",
			"iso_3166_3": "HTI",
			"name": countryName.countries[332],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "509",
			"flag": "HT.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 334,
			"capital": "Territory of Heard Island and McDonald Islands",
			"citizenship": "of Territory of Heard Island and McDonald Islands",
			"country_code": "334",
			"currency": "",
			"currency_code": "",
			"currency_sub_unit": "",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Territory of Heard Island and McDonald Islands",
			"iso_3166_2": "HM",
			"iso_3166_3": "HMD",
			"name": countryName.countries[334],
			"region_code": "",
			"sub_region_code": "",
			"eea": false,
			"calling_code": "61",
			"flag": "HM.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 336,
			"capital": "Vatican City",
			"citizenship": "of the Holy See/of the Vatican",
			"country_code": "336",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "the Holy See/ Vatican City State",
			"iso_3166_2": "VA",
			"iso_3166_3": "VAT",
			"name": countryName.countries[336],
			"region_code": "150",
			"sub_region_code": "039",
			"eea": false,
			"calling_code": "39",
			"flag": "VA.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 340,
			"capital": "Tegucigalpa",
			"citizenship": "Honduran",
			"country_code": "340",
			"currency": "lempira",
			"currency_code": "HNL",
			"currency_sub_unit": "centavo",
			"currency_symbol": "L",
			"currency_decimals": 2,
			"full_name": "Republic of Honduras",
			"iso_3166_2": "HN",
			"iso_3166_3": "HND",
			"name": countryName.countries[340],
			"region_code": "019",
			"sub_region_code": "013",
			"eea": false,
			"calling_code": "504",
			"flag": "HN.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 344,
			"capital": "(HK3)",
			"citizenship": "Hong Kong Chinese",
			"country_code": "344",
			"currency": "Hong Kong dollar",
			"currency_code": "HKD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Hong Kong Special Administrative Region of the People’s Republic of China (HK2)",
			"iso_3166_2": "HK",
			"iso_3166_3": "HKG",
			"name": countryName.countries[344],
			"region_code": "142",
			"sub_region_code": "030",
			"eea": false,
			"calling_code": "852",
			"flag": "HK.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 348,
			"capital": "Budapest",
			"citizenship": "Hungarian",
			"country_code": "348",
			"currency": "forint (inv.)",
			"currency_code": "HUF",
			"currency_sub_unit": "(fillér (inv.))",
			"currency_symbol": "Ft",
			"currency_decimals": 2,
			"full_name": "Republic of Hungary",
			"iso_3166_2": "HU",
			"iso_3166_3": "HUN",
			"name": countryName.countries[348],
			"region_code": "150",
			"sub_region_code": "151",
			"eea": true,
			"calling_code": "36",
			"flag": "HU.png",
			"vat_rate": 27,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 352,
			"capital": "Reykjavik",
			"citizenship": "Icelander",
			"country_code": "352",
			"currency": "króna (pl. krónur)",
			"currency_code": "ISK",
			"currency_sub_unit": "",
			"currency_symbol": "kr",
			"currency_decimals": 0,
			"full_name": "Republic of Iceland",
			"iso_3166_2": "IS",
			"iso_3166_3": "ISL",
			"name": countryName.countries[352],
			"region_code": "150",
			"sub_region_code": "154",
			"eea": false,
			"calling_code": "354",
			"flag": "IS.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 356,
			"capital": "New Delhi",
			"citizenship": "Indian",
			"country_code": "356",
			"currency": "Indian rupee",
			"currency_code": "INR",
			"currency_sub_unit": "paisa",
			"currency_symbol": "₹",
			"currency_decimals": 2,
			"full_name": "Republic of India",
			"iso_3166_2": "IN",
			"iso_3166_3": "IND",
			"name": countryName.countries[356],
			"region_code": "142",
			"sub_region_code": "034",
			"eea": false,
			"calling_code": "91",
			"flag": "IN.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 360,
			"capital": "Jakarta",
			"citizenship": "Indonesian",
			"country_code": "360",
			"currency": "Indonesian rupiah (inv.)",
			"currency_code": "IDR",
			"currency_sub_unit": "sen (inv.)",
			"currency_symbol": "Rp",
			"currency_decimals": 2,
			"full_name": "Republic of Indonesia",
			"iso_3166_2": "ID",
			"iso_3166_3": "IDN",
			"name": countryName.countries[360],
			"region_code": "142",
			"sub_region_code": "035",
			"eea": false,
			"calling_code": "62",
			"flag": "ID.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 364,
			"capital": "Tehran",
			"citizenship": "Iranian",
			"country_code": "364",
			"currency": "Iranian rial",
			"currency_code": "IRR",
			"currency_sub_unit": "(dinar) (IR1)",
			"currency_symbol": "﷼",
			"currency_decimals": 2,
			"full_name": "Islamic Republic of Iran",
			"iso_3166_2": "IR",
			"iso_3166_3": "IRN",
			"name": countryName.countries[364],
			"region_code": "142",
			"sub_region_code": "034",
			"eea": false,
			"calling_code": "98",
			"flag": "IR.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 368,
			"capital": "Baghdad",
			"citizenship": "Iraqi",
			"country_code": "368",
			"currency": "Iraqi dinar",
			"currency_code": "IQD",
			"currency_sub_unit": "fils (inv.)",
			"currency_symbol": "IQD",
			"currency_decimals": 3,
			"full_name": "Republic of Iraq",
			"iso_3166_2": "IQ",
			"iso_3166_3": "IRQ",
			"name": countryName.countries[368],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": false,
			"calling_code": "964",
			"flag": "IQ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 372,
			"capital": "Dublin",
			"citizenship": "Irish",
			"country_code": "372",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Ireland (IE1)",
			"iso_3166_2": "IE",
			"iso_3166_3": "IRL",
			"name": countryName.countries[372],
			"region_code": "150",
			"sub_region_code": "154",
			"eea": true,
			"calling_code": "353",
			"flag": "IE.png",
			"vat_rate": 23,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 833,
			"capital": "Douglas",
			"citizenship": "Manxman; Manxwoman",
			"country_code": "833",
			"currency": "Manx pound (IM2)",
			"currency_code": "IMP (IM2)",
			"currency_sub_unit": "penny (pl. pence)",
			"currency_symbol": "£",
			"currency_decimals": null,
			"full_name": "Isle of Man",
			"iso_3166_2": "IM",
			"iso_3166_3": "IMN",
			"name": countryName.countries[833],
			"region_code": "150",
			"sub_region_code": "154",
			"eea": false,
			"calling_code": "44",
			"flag": null,
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 376,
			"capital": "(IL1)",
			"citizenship": "Israeli",
			"country_code": "376",
			"currency": "shekel",
			"currency_code": "ILS",
			"currency_sub_unit": "agora",
			"currency_symbol": "₪",
			"currency_decimals": 2,
			"full_name": "State of Israel",
			"iso_3166_2": "IL",
			"iso_3166_3": "ISR",
			"name": countryName.countries[376],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": false,
			"calling_code": "972",
			"flag": "IL.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 380,
			"capital": "Rome",
			"citizenship": "Italian",
			"country_code": "380",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Italian Republic",
			"iso_3166_2": "IT",
			"iso_3166_3": "ITA",
			"name": countryName.countries[380],
			"region_code": "150",
			"sub_region_code": "039",
			"eea": true,
			"calling_code": "39",
			"flag": "IT.png",
			"vat_rate": 22,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 388,
			"capital": "Kingston",
			"citizenship": "Jamaican",
			"country_code": "388",
			"currency": "Jamaica dollar",
			"currency_code": "JMD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Jamaica",
			"iso_3166_2": "JM",
			"iso_3166_3": "JAM",
			"name": countryName.countries[388],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "JM.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 392,
			"capital": "Tokyo",
			"citizenship": "Japanese",
			"country_code": "392",
			"currency": "yen (inv.)",
			"currency_code": "JPY",
			"currency_sub_unit": "(sen (inv.)) (JP1)",
			"currency_symbol": "¥",
			"currency_decimals": 0,
			"full_name": "Japan",
			"iso_3166_2": "JP",
			"iso_3166_3": "JPN",
			"name": countryName.countries[392],
			"region_code": "142",
			"sub_region_code": "030",
			"eea": false,
			"calling_code": "81",
			"flag": "JP.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 832,
			"capital": "St Helier",
			"citizenship": "of Jersey",
			"country_code": "832",
			"currency": "Jersey pound (JE2)",
			"currency_code": "JEP (JE2)",
			"currency_sub_unit": "penny (pl. pence)",
			"currency_symbol": "£",
			"currency_decimals": null,
			"full_name": "Bailiwick of Jersey",
			"iso_3166_2": "JE",
			"iso_3166_3": "JEY",
			"name": countryName.countries[832],
			"region_code": "150",
			"sub_region_code": "154",
			"eea": false,
			"calling_code": "44",
			"flag": null,
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 400,
			"capital": "Amman",
			"citizenship": "Jordanian",
			"country_code": "400",
			"currency": "Jordanian dinar",
			"currency_code": "JOD",
			"currency_sub_unit": "100 qirsh",
			"currency_symbol": "JOD",
			"currency_decimals": 2,
			"full_name": "Hashemite Kingdom of Jordan",
			"iso_3166_2": "JO",
			"iso_3166_3": "JOR",
			"name": countryName.countries[400],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": false,
			"calling_code": "962",
			"flag": "JO.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 398,
			"capital": "Astana",
			"citizenship": "Kazakh",
			"country_code": "398",
			"currency": "tenge (inv.)",
			"currency_code": "KZT",
			"currency_sub_unit": "tiyn",
			"currency_symbol": "лв",
			"currency_decimals": 2,
			"full_name": "Republic of Kazakhstan",
			"iso_3166_2": "KZ",
			"iso_3166_3": "KAZ",
			"name": countryName.countries[398],
			"region_code": "142",
			"sub_region_code": "143",
			"eea": false,
			"calling_code": "7",
			"flag": "KZ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 404,
			"capital": "Nairobi",
			"citizenship": "Kenyan",
			"country_code": "404",
			"currency": "Kenyan shilling",
			"currency_code": "KES",
			"currency_sub_unit": "cent",
			"currency_symbol": "KES",
			"currency_decimals": 2,
			"full_name": "Republic of Kenya",
			"iso_3166_2": "KE",
			"iso_3166_3": "KEN",
			"name": countryName.countries[404],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "254",
			"flag": "KE.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 296,
			"capital": "Tarawa",
			"citizenship": "Kiribatian",
			"country_code": "296",
			"currency": "Australian dollar",
			"currency_code": "AUD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Republic of Kiribati",
			"iso_3166_2": "KI",
			"iso_3166_3": "KIR",
			"name": countryName.countries[296],
			"region_code": "009",
			"sub_region_code": "057",
			"eea": false,
			"calling_code": "686",
			"flag": "KI.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 408,
			"capital": "Pyongyang",
			"citizenship": "North Korean",
			"country_code": "408",
			"currency": "North Korean won (inv.)",
			"currency_code": "KPW",
			"currency_sub_unit": "chun (inv.)",
			"currency_symbol": "₩",
			"currency_decimals": 2,
			"full_name": "Democratic People’s Republic of Korea",
			"iso_3166_2": "KP",
			"iso_3166_3": "PRK",
			"name": countryName.countries[408],
			"region_code": "142",
			"sub_region_code": "030",
			"eea": false,
			"calling_code": "850",
			"flag": "KP.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 410,
			"capital": "Seoul",
			"citizenship": "South Korean",
			"country_code": "410",
			"currency": "South Korean won (inv.)",
			"currency_code": "KRW",
			"currency_sub_unit": "(chun (inv.))",
			"currency_symbol": "₩",
			"currency_decimals": 0,
			"full_name": "Republic of Korea",
			"iso_3166_2": "KR",
			"iso_3166_3": "KOR",
			"name": countryName.countries[410],
			"region_code": "142",
			"sub_region_code": "030",
			"eea": false,
			"calling_code": "82",
			"flag": "KR.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 414,
			"capital": "Kuwait City",
			"citizenship": "Kuwaiti",
			"country_code": "414",
			"currency": "Kuwaiti dinar",
			"currency_code": "KWD",
			"currency_sub_unit": "fils (inv.)",
			"currency_symbol": "KWD",
			"currency_decimals": 3,
			"full_name": "State of Kuwait",
			"iso_3166_2": "KW",
			"iso_3166_3": "KWT",
			"name": countryName.countries[414],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": false,
			"calling_code": "965",
			"flag": "KW.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 417,
			"capital": "Bishkek",
			"citizenship": "Kyrgyz",
			"country_code": "417",
			"currency": "som",
			"currency_code": "KGS",
			"currency_sub_unit": "tyiyn",
			"currency_symbol": "лв",
			"currency_decimals": 2,
			"full_name": "Kyrgyz Republic",
			"iso_3166_2": "KG",
			"iso_3166_3": "KGZ",
			"name": countryName.countries[417],
			"region_code": "142",
			"sub_region_code": "143",
			"eea": false,
			"calling_code": "996",
			"flag": "KG.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 418,
			"capital": "Vientiane",
			"citizenship": "Lao",
			"country_code": "418",
			"currency": "kip (inv.)",
			"currency_code": "LAK",
			"currency_sub_unit": "(at (inv.))",
			"currency_symbol": "₭",
			"currency_decimals": 0,
			"full_name": "Lao People’s Democratic Republic",
			"iso_3166_2": "LA",
			"iso_3166_3": "LAO",
			"name": countryName.countries[418],
			"region_code": "142",
			"sub_region_code": "035",
			"eea": false,
			"calling_code": "856",
			"flag": "LA.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 428,
			"capital": "Riga",
			"citizenship": "Latvian",
			"country_code": "428",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "Ls",
			"currency_decimals": 2,
			"full_name": "Republic of Latvia",
			"iso_3166_2": "LV",
			"iso_3166_3": "LVA",
			"name": countryName.countries[428],
			"region_code": "150",
			"sub_region_code": "154",
			"eea": true,
			"calling_code": "371",
			"flag": "LV.png",
			"vat_rate": 21,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 422,
			"capital": "Beirut",
			"citizenship": "Lebanese",
			"country_code": "422",
			"currency": "Lebanese pound",
			"currency_code": "LBP",
			"currency_sub_unit": "(piastre)",
			"currency_symbol": "£",
			"currency_decimals": 2,
			"full_name": "Lebanese Republic",
			"iso_3166_2": "LB",
			"iso_3166_3": "LBN",
			"name": countryName.countries[422],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": false,
			"calling_code": "961",
			"flag": "LB.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 426,
			"capital": "Maseru",
			"citizenship": "Basotho",
			"country_code": "426",
			"currency": "loti (pl. maloti)",
			"currency_code": "LSL",
			"currency_sub_unit": "sente",
			"currency_symbol": "L",
			"currency_decimals": 2,
			"full_name": "Kingdom of Lesotho",
			"iso_3166_2": "LS",
			"iso_3166_3": "LSO",
			"name": countryName.countries[426],
			"region_code": "002",
			"sub_region_code": "018",
			"eea": false,
			"calling_code": "266",
			"flag": "LS.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 430,
			"capital": "Monrovia",
			"citizenship": "Liberian",
			"country_code": "430",
			"currency": "Liberian dollar",
			"currency_code": "LRD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Republic of Liberia",
			"iso_3166_2": "LR",
			"iso_3166_3": "LBR",
			"name": countryName.countries[430],
			"region_code": "002",
			"sub_region_code": "011",
			"eea": false,
			"calling_code": "231",
			"flag": "LR.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 434,
			"capital": "Tripoli",
			"citizenship": "Libyan",
			"country_code": "434",
			"currency": "Libyan dinar",
			"currency_code": "LYD",
			"currency_sub_unit": "dirham",
			"currency_symbol": "LYD",
			"currency_decimals": 3,
			"full_name": "Socialist People’s Libyan Arab Jamahiriya",
			"iso_3166_2": "LY",
			"iso_3166_3": "LBY",
			"name": countryName.countries[434],
			"region_code": "002",
			"sub_region_code": "015",
			"eea": false,
			"calling_code": "218",
			"flag": "LY.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 438,
			"capital": "Vaduz",
			"citizenship": "Liechtensteiner",
			"country_code": "438",
			"currency": "Swiss franc",
			"currency_code": "CHF",
			"currency_sub_unit": "centime",
			"currency_symbol": "CHF",
			"currency_decimals": 2,
			"full_name": "Principality of Liechtenstein",
			"iso_3166_2": "LI",
			"iso_3166_3": "LIE",
			"name": countryName.countries[438],
			"region_code": "150",
			"sub_region_code": "155",
			"eea": false,
			"calling_code": "423",
			"flag": "LI.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 440,
			"capital": "Vilnius",
			"citizenship": "Lithuanian",
			"country_code": "440",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "Lt",
			"currency_decimals": 2,
			"full_name": "Republic of Lithuania",
			"iso_3166_2": "LT",
			"iso_3166_3": "LTU",
			"name": countryName.countries[440],
			"region_code": "150",
			"sub_region_code": "154",
			"eea": true,
			"calling_code": "370",
			"flag": "LT.png",
			"vat_rate": 21,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 442,
			"capital": "Luxembourg",
			"citizenship": "Luxembourger",
			"country_code": "442",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Grand Duchy of Luxembourg",
			"iso_3166_2": "LU",
			"iso_3166_3": "LUX",
			"name": countryName.countries[442],
			"region_code": "150",
			"sub_region_code": "155",
			"eea": true,
			"calling_code": "352",
			"flag": "LU.png",
			"vat_rate": 17,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 446,
			"capital": "Macao (MO3)",
			"citizenship": "Macanese",
			"country_code": "446",
			"currency": "pataca",
			"currency_code": "MOP",
			"currency_sub_unit": "avo",
			"currency_symbol": "MOP",
			"currency_decimals": 2,
			"full_name": "Macao Special Administrative Region of the People’s Republic of China (MO2)",
			"iso_3166_2": "MO",
			"iso_3166_3": "MAC",
			"name": countryName.countries[446],
			"region_code": "142",
			"sub_region_code": "030",
			"eea": false,
			"calling_code": "853",
			"flag": "MO.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 807,
			"capital": "Skopje",
			"citizenship": "of the former Yugoslav Republic of Macedonia",
			"country_code": "807",
			"currency": "denar (pl. denars)",
			"currency_code": "MKD",
			"currency_sub_unit": "deni (inv.)",
			"currency_symbol": "ден",
			"currency_decimals": 2,
			"full_name": "the former Yugoslav Republic of Macedonia",
			"iso_3166_2": "MK",
			"iso_3166_3": "MKD",
			"name": countryName.countries[807],
			"region_code": "150",
			"sub_region_code": "039",
			"eea": false,
			"calling_code": "389",
			"flag": "MK.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 450,
			"capital": "Antananarivo",
			"citizenship": "Malagasy",
			"country_code": "450",
			"currency": "ariary",
			"currency_code": "MGA",
			"currency_sub_unit": "iraimbilanja (inv.)",
			"currency_symbol": "MGA",
			"currency_decimals": 2,
			"full_name": "Republic of Madagascar",
			"iso_3166_2": "MG",
			"iso_3166_3": "MDG",
			"name": countryName.countries[450],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "261",
			"flag": "MG.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 454,
			"capital": "Lilongwe",
			"citizenship": "Malawian",
			"country_code": "454",
			"currency": "Malawian kwacha (inv.)",
			"currency_code": "MWK",
			"currency_sub_unit": "tambala (inv.)",
			"currency_symbol": "MK",
			"currency_decimals": 2,
			"full_name": "Republic of Malawi",
			"iso_3166_2": "MW",
			"iso_3166_3": "MWI",
			"name": countryName.countries[454],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "265",
			"flag": "MW.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 458,
			"capital": "Kuala Lumpur (MY1)",
			"citizenship": "Malaysian",
			"country_code": "458",
			"currency": "ringgit (inv.)",
			"currency_code": "MYR",
			"currency_sub_unit": "sen (inv.)",
			"currency_symbol": "RM",
			"currency_decimals": 2,
			"full_name": "Malaysia",
			"iso_3166_2": "MY",
			"iso_3166_3": "MYS",
			"name": countryName.countries[458],
			"region_code": "142",
			"sub_region_code": "035",
			"eea": false,
			"calling_code": "60",
			"flag": "MY.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 462,
			"capital": "Malé",
			"citizenship": "Maldivian",
			"country_code": "462",
			"currency": "rufiyaa",
			"currency_code": "MVR",
			"currency_sub_unit": "laari (inv.)",
			"currency_symbol": "Rf",
			"currency_decimals": 2,
			"full_name": "Republic of Maldives",
			"iso_3166_2": "MV",
			"iso_3166_3": "MDV",
			"name": countryName.countries[462],
			"region_code": "142",
			"sub_region_code": "034",
			"eea": false,
			"calling_code": "960",
			"flag": "MV.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 466,
			"capital": "Bamako",
			"citizenship": "Malian",
			"country_code": "466",
			"currency": "CFA franc (BCEAO)",
			"currency_code": "XOF",
			"currency_sub_unit": "centime",
			"currency_symbol": "XOF",
			"currency_decimals": 0,
			"full_name": "Republic of Mali",
			"iso_3166_2": "ML",
			"iso_3166_3": "MLI",
			"name": countryName.countries[466],
			"region_code": "002",
			"sub_region_code": "011",
			"eea": false,
			"calling_code": "223",
			"flag": "ML.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 470,
			"capital": "Valletta",
			"citizenship": "Maltese",
			"country_code": "470",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "MTL",
			"currency_decimals": 2,
			"full_name": "Republic of Malta",
			"iso_3166_2": "MT",
			"iso_3166_3": "MLT",
			"name": countryName.countries[470],
			"region_code": "150",
			"sub_region_code": "039",
			"eea": true,
			"calling_code": "356",
			"flag": "MT.png",
			"vat_rate": 18,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 584,
			"capital": "Majuro",
			"citizenship": "Marshallese",
			"country_code": "584",
			"currency": "US dollar",
			"currency_code": "USD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Republic of the Marshall Islands",
			"iso_3166_2": "MH",
			"iso_3166_3": "MHL",
			"name": countryName.countries[584],
			"region_code": "009",
			"sub_region_code": "057",
			"eea": false,
			"calling_code": "692",
			"flag": "MH.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 474,
			"capital": "Fort-de-France",
			"citizenship": "Martinican",
			"country_code": "474",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Martinique",
			"iso_3166_2": "MQ",
			"iso_3166_3": "MTQ",
			"name": countryName.countries[474],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "596",
			"flag": "MQ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 478,
			"capital": "Nouakchott",
			"citizenship": "Mauritanian",
			"country_code": "478",
			"currency": "ouguiya",
			"currency_code": "MRO",
			"currency_sub_unit": "khoum",
			"currency_symbol": "UM",
			"currency_decimals": 2,
			"full_name": "Islamic Republic of Mauritania",
			"iso_3166_2": "MR",
			"iso_3166_3": "MRT",
			"name": countryName.countries[478],
			"region_code": "002",
			"sub_region_code": "011",
			"eea": false,
			"calling_code": "222",
			"flag": "MR.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 480,
			"capital": "Port Louis",
			"citizenship": "Mauritian",
			"country_code": "480",
			"currency": "Mauritian rupee",
			"currency_code": "MUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "₨",
			"currency_decimals": 2,
			"full_name": "Republic of Mauritius",
			"iso_3166_2": "MU",
			"iso_3166_3": "MUS",
			"name": countryName.countries[480],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "230",
			"flag": "MU.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 175,
			"capital": "Mamoudzou",
			"citizenship": "Mahorais",
			"country_code": "175",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Departmental Collectivity of Mayotte",
			"iso_3166_2": "YT",
			"iso_3166_3": "MYT",
			"name": countryName.countries[175],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "262",
			"flag": "YT.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 484,
			"capital": "Mexico City",
			"citizenship": "Mexican",
			"country_code": "484",
			"currency": "Mexican peso",
			"currency_code": "MXN",
			"currency_sub_unit": "centavo",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "United Mexican States",
			"iso_3166_2": "MX",
			"iso_3166_3": "MEX",
			"name": countryName.countries[484],
			"region_code": "019",
			"sub_region_code": "013",
			"eea": false,
			"calling_code": "52",
			"flag": "MX.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 583,
			"capital": "Palikir",
			"citizenship": "Micronesian",
			"country_code": "583",
			"currency": "US dollar",
			"currency_code": "USD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Federated States of Micronesia",
			"iso_3166_2": "FM",
			"iso_3166_3": "FSM",
			"name": countryName.countries[583],
			"region_code": "009",
			"sub_region_code": "057",
			"eea": false,
			"calling_code": "691",
			"flag": "FM.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 498,
			"capital": "Chisinau",
			"citizenship": "Moldovan",
			"country_code": "498",
			"currency": "Moldovan leu (pl. lei)",
			"currency_code": "MDL",
			"currency_sub_unit": "ban",
			"currency_symbol": "MDL",
			"currency_decimals": 2,
			"full_name": "Republic of Moldova",
			"iso_3166_2": "MD",
			"iso_3166_3": "MDA",
			"name": countryName.countries[498],
			"region_code": "150",
			"sub_region_code": "151",
			"eea": false,
			"calling_code": "373",
			"flag": "MD.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 492,
			"capital": "Monaco",
			"citizenship": "Monegasque",
			"country_code": "492",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Principality of Monaco",
			"iso_3166_2": "MC",
			"iso_3166_3": "MCO",
			"name": countryName.countries[492],
			"region_code": "150",
			"sub_region_code": "155",
			"eea": false,
			"calling_code": "377",
			"flag": "MC.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 496,
			"capital": "Ulan Bator",
			"citizenship": "Mongolian",
			"country_code": "496",
			"currency": "tugrik",
			"currency_code": "MNT",
			"currency_sub_unit": "möngö (inv.)",
			"currency_symbol": "₮",
			"currency_decimals": 2,
			"full_name": "Mongolia",
			"iso_3166_2": "MN",
			"iso_3166_3": "MNG",
			"name": countryName.countries[496],
			"region_code": "142",
			"sub_region_code": "030",
			"eea": false,
			"calling_code": "976",
			"flag": "MN.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 499,
			"capital": "Podgorica",
			"citizenship": "Montenegrin",
			"country_code": "499",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Montenegro",
			"iso_3166_2": "ME",
			"iso_3166_3": "MNE",
			"name": countryName.countries[499],
			"region_code": "150",
			"sub_region_code": "039",
			"eea": false,
			"calling_code": "382",
			"flag": "ME.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 500,
			"capital": "Plymouth (MS2)",
			"citizenship": "Montserratian",
			"country_code": "500",
			"currency": "East Caribbean dollar",
			"currency_code": "XCD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Montserrat",
			"iso_3166_2": "MS",
			"iso_3166_3": "MSR",
			"name": countryName.countries[500],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "MS.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 504,
			"capital": "Rabat",
			"citizenship": "Moroccan",
			"country_code": "504",
			"currency": "Moroccan dirham",
			"currency_code": "MAD",
			"currency_sub_unit": "centime",
			"currency_symbol": "MAD",
			"currency_decimals": 2,
			"full_name": "Kingdom of Morocco",
			"iso_3166_2": "MA",
			"iso_3166_3": "MAR",
			"name": countryName.countries[504],
			"region_code": "002",
			"sub_region_code": "015",
			"eea": false,
			"calling_code": "212",
			"flag": "MA.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 508,
			"capital": "Maputo",
			"citizenship": "Mozambican",
			"country_code": "508",
			"currency": "metical",
			"currency_code": "MZN",
			"currency_sub_unit": "centavo",
			"currency_symbol": "MT",
			"currency_decimals": 2,
			"full_name": "Republic of Mozambique",
			"iso_3166_2": "MZ",
			"iso_3166_3": "MOZ",
			"name": countryName.countries[508],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "258",
			"flag": "MZ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 104,
			"capital": "Yangon",
			"citizenship": "Burmese",
			"country_code": "104",
			"currency": "kyat",
			"currency_code": "MMK",
			"currency_sub_unit": "pya",
			"currency_symbol": "K",
			"currency_decimals": 2,
			"full_name": "Union of Myanmar/",
			"iso_3166_2": "MM",
			"iso_3166_3": "MMR",
			"name": countryName.countries[104],
			"region_code": "142",
			"sub_region_code": "035",
			"eea": false,
			"calling_code": "95",
			"flag": "MM.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 516,
			"capital": "Windhoek",
			"citizenship": "Namibian",
			"country_code": "516",
			"currency": "Namibian dollar",
			"currency_code": "NAD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Republic of Namibia",
			"iso_3166_2": "NA",
			"iso_3166_3": "NAM",
			"name": countryName.countries[516],
			"region_code": "002",
			"sub_region_code": "018",
			"eea": false,
			"calling_code": "264",
			"flag": "NA.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 520,
			"capital": "Yaren",
			"citizenship": "Nauruan",
			"country_code": "520",
			"currency": "Australian dollar",
			"currency_code": "AUD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Republic of Nauru",
			"iso_3166_2": "NR",
			"iso_3166_3": "NRU",
			"name": countryName.countries[520],
			"region_code": "009",
			"sub_region_code": "057",
			"eea": false,
			"calling_code": "674",
			"flag": "NR.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 524,
			"capital": "Kathmandu",
			"citizenship": "Nepalese",
			"country_code": "524",
			"currency": "Nepalese rupee",
			"currency_code": "NPR",
			"currency_sub_unit": "paisa (inv.)",
			"currency_symbol": "₨",
			"currency_decimals": 2,
			"full_name": "Nepal",
			"iso_3166_2": "NP",
			"iso_3166_3": "NPL",
			"name": countryName.countries[524],
			"region_code": "142",
			"sub_region_code": "034",
			"eea": false,
			"calling_code": "977",
			"flag": "NP.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 528,
			"capital": "Amsterdam (NL2)",
			"citizenship": "Dutch",
			"country_code": "528",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Kingdom of the Netherlands",
			"iso_3166_2": "NL",
			"iso_3166_3": "NLD",
			"name": countryName.countries[528],
			"region_code": "150",
			"sub_region_code": "155",
			"eea": true,
			"calling_code": "31",
			"flag": "NL.png",
			"vat_rate": 21,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 540,
			"capital": "Nouméa",
			"citizenship": "New Caledonian",
			"country_code": "540",
			"currency": "CFP franc",
			"currency_code": "XPF",
			"currency_sub_unit": "centime",
			"currency_symbol": "XPF",
			"currency_decimals": 0,
			"full_name": "New Caledonia",
			"iso_3166_2": "NC",
			"iso_3166_3": "NCL",
			"name": countryName.countries[540],
			"region_code": "009",
			"sub_region_code": "054",
			"eea": false,
			"calling_code": "687",
			"flag": "NC.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 554,
			"capital": "Wellington",
			"citizenship": "New Zealander",
			"country_code": "554",
			"currency": "New Zealand dollar",
			"currency_code": "NZD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "New Zealand",
			"iso_3166_2": "NZ",
			"iso_3166_3": "NZL",
			"name": countryName.countries[554],
			"region_code": "009",
			"sub_region_code": "053",
			"eea": false,
			"calling_code": "64",
			"flag": "NZ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 558,
			"capital": "Managua",
			"citizenship": "Nicaraguan",
			"country_code": "558",
			"currency": "córdoba oro",
			"currency_code": "NIO",
			"currency_sub_unit": "centavo",
			"currency_symbol": "C$",
			"currency_decimals": 2,
			"full_name": "Republic of Nicaragua",
			"iso_3166_2": "NI",
			"iso_3166_3": "NIC",
			"name": countryName.countries[558],
			"region_code": "019",
			"sub_region_code": "013",
			"eea": false,
			"calling_code": "505",
			"flag": "NI.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 562,
			"capital": "Niamey",
			"citizenship": "Nigerien",
			"country_code": "562",
			"currency": "CFA franc (BCEAO)",
			"currency_code": "XOF",
			"currency_sub_unit": "centime",
			"currency_symbol": "XOF",
			"currency_decimals": 0,
			"full_name": "Republic of Niger",
			"iso_3166_2": "NE",
			"iso_3166_3": "NER",
			"name": countryName.countries[562],
			"region_code": "002",
			"sub_region_code": "011",
			"eea": false,
			"calling_code": "227",
			"flag": "NE.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 566,
			"capital": "Abuja",
			"citizenship": "Nigerian",
			"country_code": "566",
			"currency": "naira (inv.)",
			"currency_code": "NGN",
			"currency_sub_unit": "kobo (inv.)",
			"currency_symbol": "₦",
			"currency_decimals": 2,
			"full_name": "Federal Republic of Nigeria",
			"iso_3166_2": "NG",
			"iso_3166_3": "NGA",
			"name": countryName.countries[566],
			"region_code": "002",
			"sub_region_code": "011",
			"eea": false,
			"calling_code": "234",
			"flag": "NG.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 570,
			"capital": "Alofi",
			"citizenship": "Niuean",
			"country_code": "570",
			"currency": "New Zealand dollar",
			"currency_code": "NZD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Niue",
			"iso_3166_2": "NU",
			"iso_3166_3": "NIU",
			"name": countryName.countries[570],
			"region_code": "009",
			"sub_region_code": "061",
			"eea": false,
			"calling_code": "683",
			"flag": "NU.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 574,
			"capital": "Kingston",
			"citizenship": "Norfolk Islander",
			"country_code": "574",
			"currency": "Australian dollar",
			"currency_code": "AUD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Territory of Norfolk Island",
			"iso_3166_2": "NF",
			"iso_3166_3": "NFK",
			"name": countryName.countries[574],
			"region_code": "009",
			"sub_region_code": "053",
			"eea": false,
			"calling_code": "672",
			"flag": "NF.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 580,
			"capital": "Saipan",
			"citizenship": "Northern Mariana Islander",
			"country_code": "580",
			"currency": "US dollar",
			"currency_code": "USD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Commonwealth of the Northern Mariana Islands",
			"iso_3166_2": "MP",
			"iso_3166_3": "MNP",
			"name": countryName.countries[580],
			"region_code": "009",
			"sub_region_code": "057",
			"eea": false,
			"calling_code": "1",
			"flag": "MP.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 578,
			"capital": "Oslo",
			"citizenship": "Norwegian",
			"country_code": "578",
			"currency": "Norwegian krone (pl. kroner)",
			"currency_code": "NOK",
			"currency_sub_unit": "øre (inv.)",
			"currency_symbol": "kr",
			"currency_decimals": 2,
			"full_name": "Kingdom of Norway",
			"iso_3166_2": "NO",
			"iso_3166_3": "NOR",
			"name": countryName.countries[578],
			"region_code": "150",
			"sub_region_code": "154",
			"eea": false,
			"calling_code": "47",
			"flag": "NO.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 512,
			"capital": "Muscat",
			"citizenship": "Omani",
			"country_code": "512",
			"currency": "Omani rial",
			"currency_code": "OMR",
			"currency_sub_unit": "baiza",
			"currency_symbol": "﷼",
			"currency_decimals": 3,
			"full_name": "Sultanate of Oman",
			"iso_3166_2": "OM",
			"iso_3166_3": "OMN",
			"name": countryName.countries[512],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": false,
			"calling_code": "968",
			"flag": "OM.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 586,
			"capital": "Islamabad",
			"citizenship": "Pakistani",
			"country_code": "586",
			"currency": "Pakistani rupee",
			"currency_code": "PKR",
			"currency_sub_unit": "paisa",
			"currency_symbol": "₨",
			"currency_decimals": 2,
			"full_name": "Islamic Republic of Pakistan",
			"iso_3166_2": "PK",
			"iso_3166_3": "PAK",
			"name": countryName.countries[586],
			"region_code": "142",
			"sub_region_code": "034",
			"eea": false,
			"calling_code": "92",
			"flag": "PK.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 585,
			"capital": "Melekeok",
			"citizenship": "Palauan",
			"country_code": "585",
			"currency": "US dollar",
			"currency_code": "USD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Republic of Palau",
			"iso_3166_2": "PW",
			"iso_3166_3": "PLW",
			"name": countryName.countries[585],
			"region_code": "009",
			"sub_region_code": "057",
			"eea": false,
			"calling_code": "680",
			"flag": "PW.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 275,
			"capital": null,
			"citizenship": "Palestinian",
			"country_code": "275",
			"currency": null,
			"currency_code": null,
			"currency_sub_unit": null,
			"currency_symbol": "₪",
			"currency_decimals": 2,
			"full_name": null,
			"iso_3166_2": "PS",
			"iso_3166_3": "PSE",
			"name": countryName.countries[275],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": false,
			"calling_code": "970",
			"flag": "PS.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 591,
			"capital": "Panama City",
			"citizenship": "Panamanian",
			"country_code": "591",
			"currency": "balboa",
			"currency_code": "PAB",
			"currency_sub_unit": "centésimo",
			"currency_symbol": "B/.",
			"currency_decimals": 2,
			"full_name": "Republic of Panama",
			"iso_3166_2": "PA",
			"iso_3166_3": "PAN",
			"name": countryName.countries[591],
			"region_code": "019",
			"sub_region_code": "013",
			"eea": false,
			"calling_code": "507",
			"flag": "PA.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 598,
			"capital": "Port Moresby",
			"citizenship": "Papua New Guinean",
			"country_code": "598",
			"currency": "kina (inv.)",
			"currency_code": "PGK",
			"currency_sub_unit": "toea (inv.)",
			"currency_symbol": "PGK",
			"currency_decimals": 2,
			"full_name": "Independent State of Papua New Guinea",
			"iso_3166_2": "PG",
			"iso_3166_3": "PNG",
			"name": countryName.countries[598],
			"region_code": "009",
			"sub_region_code": "054",
			"eea": false,
			"calling_code": "675",
			"flag": "PG.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 600,
			"capital": "Asunción",
			"citizenship": "Paraguayan",
			"country_code": "600",
			"currency": "guaraní",
			"currency_code": "PYG",
			"currency_sub_unit": "céntimo",
			"currency_symbol": "Gs",
			"currency_decimals": 0,
			"full_name": "Republic of Paraguay",
			"iso_3166_2": "PY",
			"iso_3166_3": "PRY",
			"name": countryName.countries[600],
			"region_code": "019",
			"sub_region_code": "005",
			"eea": false,
			"calling_code": "595",
			"flag": "PY.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 604,
			"capital": "Lima",
			"citizenship": "Peruvian",
			"country_code": "604",
			"currency": "new sol",
			"currency_code": "PEN",
			"currency_sub_unit": "céntimo",
			"currency_symbol": "S/.",
			"currency_decimals": 2,
			"full_name": "Republic of Peru",
			"iso_3166_2": "PE",
			"iso_3166_3": "PER",
			"name": countryName.countries[604],
			"region_code": "019",
			"sub_region_code": "005",
			"eea": false,
			"calling_code": "51",
			"flag": "PE.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 608,
			"capital": "Manila",
			"citizenship": "Filipino",
			"country_code": "608",
			"currency": "Philippine peso",
			"currency_code": "PHP",
			"currency_sub_unit": "centavo",
			"currency_symbol": "Php",
			"currency_decimals": 2,
			"full_name": "Republic of the Philippines",
			"iso_3166_2": "PH",
			"iso_3166_3": "PHL",
			"name": countryName.countries[608],
			"region_code": "142",
			"sub_region_code": "035",
			"eea": false,
			"calling_code": "63",
			"flag": "PH.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 612,
			"capital": "Adamstown",
			"citizenship": "Pitcairner",
			"country_code": "612",
			"currency": "New Zealand dollar",
			"currency_code": "NZD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Pitcairn Islands",
			"iso_3166_2": "PN",
			"iso_3166_3": "PCN",
			"name": countryName.countries[612],
			"region_code": "009",
			"sub_region_code": "061",
			"eea": false,
			"calling_code": "649",
			"flag": "PN.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 616,
			"capital": "Warsaw",
			"citizenship": "Polish",
			"country_code": "616",
			"currency": "zloty",
			"currency_code": "PLN",
			"currency_sub_unit": "grosz (pl. groszy)",
			"currency_symbol": "zł",
			"currency_decimals": 2,
			"full_name": "Republic of Poland",
			"iso_3166_2": "PL",
			"iso_3166_3": "POL",
			"name": countryName.countries[616],
			"region_code": "150",
			"sub_region_code": "151",
			"eea": true,
			"calling_code": "48",
			"flag": "PL.png",
			"vat_rate": 23,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 620,
			"capital": "Lisbon",
			"citizenship": "Portuguese",
			"country_code": "620",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Portuguese Republic",
			"iso_3166_2": "PT",
			"iso_3166_3": "PRT",
			"name": countryName.countries[620],
			"region_code": "150",
			"sub_region_code": "039",
			"eea": true,
			"calling_code": "351",
			"flag": "PT.png",
			"vat_rate": 23,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 630,
			"capital": "San Juan",
			"citizenship": "Puerto Rican",
			"country_code": "630",
			"currency": "US dollar",
			"currency_code": "USD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Commonwealth of Puerto Rico",
			"iso_3166_2": "PR",
			"iso_3166_3": "PRI",
			"name": countryName.countries[630],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "PR.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 634,
			"capital": "Doha",
			"citizenship": "Qatari",
			"country_code": "634",
			"currency": "Qatari riyal",
			"currency_code": "QAR",
			"currency_sub_unit": "dirham",
			"currency_symbol": "﷼",
			"currency_decimals": 2,
			"full_name": "State of Qatar",
			"iso_3166_2": "QA",
			"iso_3166_3": "QAT",
			"name": countryName.countries[634],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": false,
			"calling_code": "974",
			"flag": "QA.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 638,
			"capital": "Saint-Denis",
			"citizenship": "Reunionese",
			"country_code": "638",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Réunion",
			"iso_3166_2": "RE",
			"iso_3166_3": "REU",
			"name": countryName.countries[638],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "262",
			"flag": "RE.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 642,
			"capital": "Bucharest",
			"citizenship": "Romanian",
			"country_code": "642",
			"currency": "Romanian leu (pl. lei)",
			"currency_code": "RON",
			"currency_sub_unit": "ban (pl. bani)",
			"currency_symbol": "lei",
			"currency_decimals": 2,
			"full_name": "Romania",
			"iso_3166_2": "RO",
			"iso_3166_3": "ROU",
			"name": countryName.countries[642],
			"region_code": "150",
			"sub_region_code": "151",
			"eea": true,
			"calling_code": "40",
			"flag": "RO.png",
			"vat_rate": 19,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 643,
			"capital": "Moscow",
			"citizenship": "Russian",
			"country_code": "643",
			"currency": "Russian rouble",
			"currency_code": "RUB",
			"currency_sub_unit": "kopek",
			"currency_symbol": "руб",
			"currency_decimals": 2,
			"full_name": "Russian Federation",
			"iso_3166_2": "RU",
			"iso_3166_3": "RUS",
			"name": countryName.countries[643],
			"region_code": "150",
			"sub_region_code": "151",
			"eea": false,
			"calling_code": "7",
			"flag": "RU.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 646,
			"capital": "Kigali",
			"citizenship": "Rwandan; Rwandese",
			"country_code": "646",
			"currency": "Rwandese franc",
			"currency_code": "RWF",
			"currency_sub_unit": "centime",
			"currency_symbol": "RWF",
			"currency_decimals": 0,
			"full_name": "Republic of Rwanda",
			"iso_3166_2": "RW",
			"iso_3166_3": "RWA",
			"name": countryName.countries[646],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "250",
			"flag": "RW.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 652,
			"capital": "Gustavia",
			"citizenship": "of Saint Barthélemy",
			"country_code": "652",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": null,
			"full_name": "Collectivity of Saint Barthélemy",
			"iso_3166_2": "BL",
			"iso_3166_3": "BLM",
			"name": countryName.countries[652],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "590",
			"flag": null,
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 654,
			"capital": "Jamestown",
			"citizenship": "Saint Helenian",
			"country_code": "654",
			"currency": "Saint Helena pound",
			"currency_code": "SHP",
			"currency_sub_unit": "penny",
			"currency_symbol": "£",
			"currency_decimals": 2,
			"full_name": "Saint Helena, Ascension and Tristan da Cunha",
			"iso_3166_2": "SH",
			"iso_3166_3": "SHN",
			"name": countryName.countries[654],
			"region_code": "002",
			"sub_region_code": "011",
			"eea": false,
			"calling_code": "290",
			"flag": "SH.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 659,
			"capital": "Basseterre",
			"citizenship": "Kittsian; Nevisian",
			"country_code": "659",
			"currency": "East Caribbean dollar",
			"currency_code": "XCD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Federation of Saint Kitts and Nevis",
			"iso_3166_2": "KN",
			"iso_3166_3": "KNA",
			"name": countryName.countries[659],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "KN.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 662,
			"capital": "Castries",
			"citizenship": "Saint Lucian",
			"country_code": "662",
			"currency": "East Caribbean dollar",
			"currency_code": "XCD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Saint Lucia",
			"iso_3166_2": "LC",
			"iso_3166_3": "LCA",
			"name": countryName.countries[662],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "LC.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 663,
			"capital": "Marigot",
			"citizenship": "of Saint Martin",
			"country_code": "663",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": null,
			"full_name": "Collectivity of Saint Martin",
			"iso_3166_2": "MF",
			"iso_3166_3": "MAF",
			"name": countryName.countries[663],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "590",
			"flag": null,
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 666,
			"capital": "Saint-Pierre",
			"citizenship": "St-Pierrais; Miquelonnais",
			"country_code": "666",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Territorial Collectivity of Saint Pierre and Miquelon",
			"iso_3166_2": "PM",
			"iso_3166_3": "SPM",
			"name": countryName.countries[666],
			"region_code": "019",
			"sub_region_code": "021",
			"eea": false,
			"calling_code": "508",
			"flag": "PM.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 670,
			"capital": "Kingstown",
			"citizenship": "Vincentian",
			"country_code": "670",
			"currency": "East Caribbean dollar",
			"currency_code": "XCD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Saint Vincent and the Grenadines",
			"iso_3166_2": "VC",
			"iso_3166_3": "VCT",
			"name": countryName.countries[670],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "VC.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 882,
			"capital": "Apia",
			"citizenship": "Samoan",
			"country_code": "882",
			"currency": "tala (inv.)",
			"currency_code": "WST",
			"currency_sub_unit": "sene (inv.)",
			"currency_symbol": "WS$",
			"currency_decimals": 2,
			"full_name": "Independent State of Samoa",
			"iso_3166_2": "WS",
			"iso_3166_3": "WSM",
			"name": countryName.countries[882],
			"region_code": "009",
			"sub_region_code": "061",
			"eea": false,
			"calling_code": "685",
			"flag": "WS.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 674,
			"capital": "San Marino",
			"citizenship": "San Marinese",
			"country_code": "674",
			"currency": "euro",
			"currency_code": "EUR ",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Republic of San Marino",
			"iso_3166_2": "SM",
			"iso_3166_3": "SMR",
			"name": countryName.countries[674],
			"region_code": "150",
			"sub_region_code": "039",
			"eea": false,
			"calling_code": "378",
			"flag": "SM.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 678,
			"capital": "São Tomé",
			"citizenship": "São Toméan",
			"country_code": "678",
			"currency": "dobra",
			"currency_code": "STD",
			"currency_sub_unit": "centavo",
			"currency_symbol": "Db",
			"currency_decimals": 2,
			"full_name": "Democratic Republic of São Tomé and Príncipe",
			"iso_3166_2": "ST",
			"iso_3166_3": "STP",
			"name": countryName.countries[678],
			"region_code": "002",
			"sub_region_code": "017",
			"eea": false,
			"calling_code": "239",
			"flag": "ST.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 682,
			"capital": "Riyadh",
			"citizenship": "Saudi Arabian",
			"country_code": "682",
			"currency": "riyal",
			"currency_code": "SAR",
			"currency_sub_unit": "halala",
			"currency_symbol": "﷼",
			"currency_decimals": 2,
			"full_name": "Kingdom of Saudi Arabia",
			"iso_3166_2": "SA",
			"iso_3166_3": "SAU",
			"name": countryName.countries[682],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": false,
			"calling_code": "966",
			"flag": "SA.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 686,
			"capital": "Dakar",
			"citizenship": "Senegalese",
			"country_code": "686",
			"currency": "CFA franc (BCEAO)",
			"currency_code": "XOF",
			"currency_sub_unit": "centime",
			"currency_symbol": "XOF",
			"currency_decimals": 0,
			"full_name": "Republic of Senegal",
			"iso_3166_2": "SN",
			"iso_3166_3": "SEN",
			"name": countryName.countries[686],
			"region_code": "002",
			"sub_region_code": "011",
			"eea": false,
			"calling_code": "221",
			"flag": "SN.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 688,
			"capital": "Belgrade",
			"citizenship": "Serb",
			"country_code": "688",
			"currency": "Serbian dinar",
			"currency_code": "RSD",
			"currency_sub_unit": "para (inv.)",
			"currency_symbol": null,
			"currency_decimals": null,
			"full_name": "Republic of Serbia",
			"iso_3166_2": "RS",
			"iso_3166_3": "SRB",
			"name": countryName.countries[688],
			"region_code": "150",
			"sub_region_code": "039",
			"eea": false,
			"calling_code": "381",
			"flag": null,
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 690,
			"capital": "Victoria",
			"citizenship": "Seychellois",
			"country_code": "690",
			"currency": "Seychelles rupee",
			"currency_code": "SCR",
			"currency_sub_unit": "cent",
			"currency_symbol": "₨",
			"currency_decimals": 2,
			"full_name": "Republic of Seychelles",
			"iso_3166_2": "SC",
			"iso_3166_3": "SYC",
			"name": countryName.countries[690],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "248",
			"flag": "SC.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 694,
			"capital": "Freetown",
			"citizenship": "Sierra Leonean",
			"country_code": "694",
			"currency": "leone",
			"currency_code": "SLL",
			"currency_sub_unit": "cent",
			"currency_symbol": "Le",
			"currency_decimals": 2,
			"full_name": "Republic of Sierra Leone",
			"iso_3166_2": "SL",
			"iso_3166_3": "SLE",
			"name": countryName.countries[694],
			"region_code": "002",
			"sub_region_code": "011",
			"eea": false,
			"calling_code": "232",
			"flag": "SL.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 702,
			"capital": "Singapore",
			"citizenship": "Singaporean",
			"country_code": "702",
			"currency": "Singapore dollar",
			"currency_code": "SGD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Republic of Singapore",
			"iso_3166_2": "SG",
			"iso_3166_3": "SGP",
			"name": countryName.countries[702],
			"region_code": "142",
			"sub_region_code": "035",
			"eea": false,
			"calling_code": "65",
			"flag": "SG.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 534,
			"capital": "Philipsburg",
			"citizenship": "Sint Maartener",
			"country_code": "534",
			"currency": "Netherlands Antillean guilder (SX1)",
			"currency_code": "ANG",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": null,
			"full_name": "Sint Maarten",
			"iso_3166_2": "SX",
			"iso_3166_3": "SXM",
			"name": countryName.countries[534],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "721",
			"flag": null,
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 703,
			"capital": "Bratislava",
			"citizenship": "Slovak",
			"country_code": "703",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "Sk",
			"currency_decimals": 2,
			"full_name": "Slovak Republic",
			"iso_3166_2": "SK",
			"iso_3166_3": "SVK",
			"name": countryName.countries[703],
			"region_code": "150",
			"sub_region_code": "151",
			"eea": true,
			"calling_code": "421",
			"flag": "SK.png",
			"vat_rate": 20,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 705,
			"capital": "Ljubljana",
			"citizenship": "Slovene",
			"country_code": "705",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Republic of Slovenia",
			"iso_3166_2": "SI",
			"iso_3166_3": "SVN",
			"name": countryName.countries[705],
			"region_code": "150",
			"sub_region_code": "039",
			"eea": true,
			"calling_code": "386",
			"flag": "SI.png",
			"vat_rate": 22,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 90,
			"capital": "Honiara",
			"citizenship": "Solomon Islander",
			"country_code": "090",
			"currency": "Solomon Islands dollar",
			"currency_code": "SBD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Solomon Islands",
			"iso_3166_2": "SB",
			"iso_3166_3": "SLB",
			"name": countryName.countries[90],
			"region_code": "009",
			"sub_region_code": "054",
			"eea": false,
			"calling_code": "677",
			"flag": "SB.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 706,
			"capital": "Mogadishu",
			"citizenship": "Somali",
			"country_code": "706",
			"currency": "Somali shilling",
			"currency_code": "SOS",
			"currency_sub_unit": "cent",
			"currency_symbol": "S",
			"currency_decimals": 2,
			"full_name": "Somali Republic",
			"iso_3166_2": "SO",
			"iso_3166_3": "SOM",
			"name": countryName.countries[706],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "252",
			"flag": "SO.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 710,
			"capital": "Pretoria (ZA1)",
			"citizenship": "South African",
			"country_code": "710",
			"currency": "rand",
			"currency_code": "ZAR",
			"currency_sub_unit": "cent",
			"currency_symbol": "R",
			"currency_decimals": 2,
			"full_name": "Republic of South Africa",
			"iso_3166_2": "ZA",
			"iso_3166_3": "ZAF",
			"name": countryName.countries[710],
			"region_code": "002",
			"sub_region_code": "018",
			"eea": false,
			"calling_code": "27",
			"flag": "ZA.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 239,
			"capital": "King Edward Point (Grytviken)",
			"citizenship": "of South Georgia and the South Sandwich Islands",
			"country_code": "239",
			"currency": "",
			"currency_code": "",
			"currency_sub_unit": "",
			"currency_symbol": "£",
			"currency_decimals": 2,
			"full_name": "South Georgia and the South Sandwich Islands",
			"iso_3166_2": "GS",
			"iso_3166_3": "SGS",
			"name": countryName.countries[239],
			"region_code": "",
			"sub_region_code": "",
			"eea": false,
			"calling_code": "44",
			"flag": "GS.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 728,
			"capital": "Juba",
			"citizenship": "South Sudanese",
			"country_code": "728",
			"currency": "South Sudanese pound",
			"currency_code": "SSP",
			"currency_sub_unit": "piaster",
			"currency_symbol": "£",
			"currency_decimals": null,
			"full_name": "Republic of South Sudan",
			"iso_3166_2": "SS",
			"iso_3166_3": "SSD",
			"name": countryName.countries[728],
			"region_code": "002",
			"sub_region_code": "015",
			"eea": false,
			"calling_code": "211",
			"flag": null,
			"vat_rate": 21,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 724,
			"capital": "Madrid",
			"citizenship": "Spaniard",
			"country_code": "724",
			"currency": "euro",
			"currency_code": "EUR",
			"currency_sub_unit": "cent",
			"currency_symbol": "€",
			"currency_decimals": 2,
			"full_name": "Kingdom of Spain",
			"iso_3166_2": "ES",
			"iso_3166_3": "ESP",
			"name": countryName.countries[724],
			"region_code": "150",
			"sub_region_code": "039",
			"eea": true,
			"calling_code": "34",
			"flag": "ES.png",
			"vat_rate": 21,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 144,
			"capital": "Colombo",
			"citizenship": "Sri Lankan",
			"country_code": "144",
			"currency": "Sri Lankan rupee",
			"currency_code": "LKR",
			"currency_sub_unit": "cent",
			"currency_symbol": "₨",
			"currency_decimals": 2,
			"full_name": "Democratic Socialist Republic of Sri Lanka",
			"iso_3166_2": "LK",
			"iso_3166_3": "LKA",
			"name": countryName.countries[144],
			"region_code": "142",
			"sub_region_code": "034",
			"eea": false,
			"calling_code": "94",
			"flag": "LK.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 729,
			"capital": "Khartoum",
			"citizenship": "Sudanese",
			"country_code": "729",
			"currency": "Sudanese pound",
			"currency_code": "SDG",
			"currency_sub_unit": "piastre",
			"currency_symbol": "£",
			"currency_decimals": null,
			"full_name": "Republic of the Sudan",
			"iso_3166_2": "SD",
			"iso_3166_3": "SDN",
			"name": countryName.countries[729],
			"region_code": "002",
			"sub_region_code": "015",
			"eea": false,
			"calling_code": "249",
			"flag": null,
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 740,
			"capital": "Paramaribo",
			"citizenship": "Surinamese",
			"country_code": "740",
			"currency": "Surinamese dollar",
			"currency_code": "SRD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Republic of Suriname",
			"iso_3166_2": "SR",
			"iso_3166_3": "SUR",
			"name": countryName.countries[740],
			"region_code": "019",
			"sub_region_code": "005",
			"eea": false,
			"calling_code": "597",
			"flag": "SR.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 744,
			"capital": "Longyearbyen",
			"citizenship": "of Svalbard",
			"country_code": "744",
			"currency": "Norwegian krone (pl. kroner)",
			"currency_code": "NOK",
			"currency_sub_unit": "øre (inv.)",
			"currency_symbol": "kr",
			"currency_decimals": 2,
			"full_name": "Svalbard and Jan Mayen",
			"iso_3166_2": "SJ",
			"iso_3166_3": "SJM",
			"name": countryName.countries[744],
			"region_code": "150",
			"sub_region_code": "154",
			"eea": false,
			"calling_code": "47",
			"flag": "SJ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 748,
			"capital": "Mbabane",
			"citizenship": "Swazi",
			"country_code": "748",
			"currency": "lilangeni",
			"currency_code": "SZL",
			"currency_sub_unit": "cent",
			"currency_symbol": "SZL",
			"currency_decimals": 2,
			"full_name": "Kingdom of Swaziland",
			"iso_3166_2": "SZ",
			"iso_3166_3": "SWZ",
			"name": countryName.countries[748],
			"region_code": "002",
			"sub_region_code": "018",
			"eea": false,
			"calling_code": "268",
			"flag": "SZ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 752,
			"capital": "Stockholm",
			"citizenship": "Swedish",
			"country_code": "752",
			"currency": "krona (pl. kronor)",
			"currency_code": "SEK",
			"currency_sub_unit": "öre (inv.)",
			"currency_symbol": "kr",
			"currency_decimals": 2,
			"full_name": "Kingdom of Sweden",
			"iso_3166_2": "SE",
			"iso_3166_3": "SWE",
			"name": countryName.countries[752],
			"region_code": "150",
			"sub_region_code": "154",
			"eea": true,
			"calling_code": "46",
			"flag": "SE.png",
			"vat_rate": 25,
			"is_eu": true,
			"vat_placeholder": null
		},
		{
			"id": 756,
			"capital": "Berne",
			"citizenship": "Swiss",
			"country_code": "756",
			"currency": "Swiss franc",
			"currency_code": "CHF",
			"currency_sub_unit": "centime",
			"currency_symbol": "CHF",
			"currency_decimals": 2,
			"full_name": "Swiss Confederation",
			"iso_3166_2": "CH",
			"iso_3166_3": "CHE",
			"name": countryName.countries[756],
			"region_code": "150",
			"sub_region_code": "155",
			"eea": false,
			"calling_code": "41",
			"flag": "CH.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 760,
			"capital": "Damascus",
			"citizenship": "Syrian",
			"country_code": "760",
			"currency": "Syrian pound",
			"currency_code": "SYP",
			"currency_sub_unit": "piastre",
			"currency_symbol": "£",
			"currency_decimals": 2,
			"full_name": "Syrian Arab Republic",
			"iso_3166_2": "SY",
			"iso_3166_3": "SYR",
			"name": countryName.countries[760],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": false,
			"calling_code": "963",
			"flag": "SY.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 158,
			"capital": "Taipei",
			"citizenship": "Taiwanese",
			"country_code": "158",
			"currency": "new Taiwan dollar",
			"currency_code": "TWD",
			"currency_sub_unit": "fen (inv.)",
			"currency_symbol": "NT$",
			"currency_decimals": 2,
			"full_name": "Republic of China, Taiwan (TW1)",
			"iso_3166_2": "TW",
			"iso_3166_3": "TWN",
			"name": countryName.countries[158],
			"region_code": "142",
			"sub_region_code": "030",
			"eea": false,
			"calling_code": "886",
			"flag": "TW.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 762,
			"capital": "Dushanbe",
			"citizenship": "Tajik",
			"country_code": "762",
			"currency": "somoni",
			"currency_code": "TJS",
			"currency_sub_unit": "diram",
			"currency_symbol": "TJS",
			"currency_decimals": 2,
			"full_name": "Republic of Tajikistan",
			"iso_3166_2": "TJ",
			"iso_3166_3": "TJK",
			"name": countryName.countries[762],
			"region_code": "142",
			"sub_region_code": "143",
			"eea": false,
			"calling_code": "992",
			"flag": "TJ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 834,
			"capital": "Dodoma (TZ1)",
			"citizenship": "Tanzanian",
			"country_code": "834",
			"currency": "Tanzanian shilling",
			"currency_code": "TZS",
			"currency_sub_unit": "cent",
			"currency_symbol": "TZS",
			"currency_decimals": 2,
			"full_name": "United Republic of Tanzania",
			"iso_3166_2": "TZ",
			"iso_3166_3": "TZA",
			"name": countryName.countries[834],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "255",
			"flag": "TZ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 764,
			"capital": "Bangkok",
			"citizenship": "Thai",
			"country_code": "764",
			"currency": "baht (inv.)",
			"currency_code": "THB",
			"currency_sub_unit": "satang (inv.)",
			"currency_symbol": "฿",
			"currency_decimals": 2,
			"full_name": "Kingdom of Thailand",
			"iso_3166_2": "TH",
			"iso_3166_3": "THA",
			"name": countryName.countries[764],
			"region_code": "142",
			"sub_region_code": "035",
			"eea": false,
			"calling_code": "66",
			"flag": "TH.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 626,
			"capital": "Dili",
			"citizenship": "East Timorese",
			"country_code": "626",
			"currency": "US dollar",
			"currency_code": "USD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Democratic Republic of East Timor",
			"iso_3166_2": "TL",
			"iso_3166_3": "TLS",
			"name": countryName.countries[626],
			"region_code": "142",
			"sub_region_code": "035",
			"eea": false,
			"calling_code": "670",
			"flag": "TL.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 768,
			"capital": "Lomé",
			"citizenship": "Togolese",
			"country_code": "768",
			"currency": "CFA franc (BCEAO)",
			"currency_code": "XOF",
			"currency_sub_unit": "centime",
			"currency_symbol": "XOF",
			"currency_decimals": 0,
			"full_name": "Togolese Republic",
			"iso_3166_2": "TG",
			"iso_3166_3": "TGO",
			"name": countryName.countries[768],
			"region_code": "002",
			"sub_region_code": "011",
			"eea": false,
			"calling_code": "228",
			"flag": "TG.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 772,
			"capital": "(TK2)",
			"citizenship": "Tokelauan",
			"country_code": "772",
			"currency": "New Zealand dollar",
			"currency_code": "NZD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Tokelau",
			"iso_3166_2": "TK",
			"iso_3166_3": "TKL",
			"name": countryName.countries[772],
			"region_code": "009",
			"sub_region_code": "061",
			"eea": false,
			"calling_code": "690",
			"flag": "TK.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 776,
			"capital": "Nuku’alofa",
			"citizenship": "Tongan",
			"country_code": "776",
			"currency": "pa’anga (inv.)",
			"currency_code": "TOP",
			"currency_sub_unit": "seniti (inv.)",
			"currency_symbol": "T$",
			"currency_decimals": 2,
			"full_name": "Kingdom of Tonga",
			"iso_3166_2": "TO",
			"iso_3166_3": "TON",
			"name": countryName.countries[776],
			"region_code": "009",
			"sub_region_code": "061",
			"eea": false,
			"calling_code": "676",
			"flag": "TO.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 780,
			"capital": "Port of Spain",
			"citizenship": "Trinidadian; Tobagonian",
			"country_code": "780",
			"currency": "Trinidad and Tobago dollar",
			"currency_code": "TTD",
			"currency_sub_unit": "cent",
			"currency_symbol": "TT$",
			"currency_decimals": 2,
			"full_name": "Republic of Trinidad and Tobago",
			"iso_3166_2": "TT",
			"iso_3166_3": "TTO",
			"name": countryName.countries[780],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "TT.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 788,
			"capital": "Tunis",
			"citizenship": "Tunisian",
			"country_code": "788",
			"currency": "Tunisian dinar",
			"currency_code": "TND",
			"currency_sub_unit": "millime",
			"currency_symbol": "TND",
			"currency_decimals": 3,
			"full_name": "Republic of Tunisia",
			"iso_3166_2": "TN",
			"iso_3166_3": "TUN",
			"name": countryName.countries[788],
			"region_code": "002",
			"sub_region_code": "015",
			"eea": false,
			"calling_code": "216",
			"flag": "TN.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 792,
			"capital": "Ankara",
			"citizenship": "Turk",
			"country_code": "792",
			"currency": "Turkish lira (inv.)",
			"currency_code": "TRY",
			"currency_sub_unit": "kurus (inv.)",
			"currency_symbol": "₺",
			"currency_decimals": 2,
			"full_name": "Republic of Turkey",
			"iso_3166_2": "TR",
			"iso_3166_3": "TUR",
			"name": countryName.countries[792],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": false,
			"calling_code": "90",
			"flag": "TR.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 795,
			"capital": "Ashgabat",
			"citizenship": "Turkmen",
			"country_code": "795",
			"currency": "Turkmen manat (inv.)",
			"currency_code": "TMT",
			"currency_sub_unit": "tenge (inv.)",
			"currency_symbol": "m",
			"currency_decimals": 2,
			"full_name": "Turkmenistan",
			"iso_3166_2": "TM",
			"iso_3166_3": "TKM",
			"name": countryName.countries[795],
			"region_code": "142",
			"sub_region_code": "143",
			"eea": false,
			"calling_code": "993",
			"flag": "TM.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 796,
			"capital": "Cockburn Town",
			"citizenship": "Turks and Caicos Islander",
			"country_code": "796",
			"currency": "US dollar",
			"currency_code": "USD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Turks and Caicos Islands",
			"iso_3166_2": "TC",
			"iso_3166_3": "TCA",
			"name": countryName.countries[796],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "TC.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 798,
			"capital": "Funafuti",
			"citizenship": "Tuvaluan",
			"country_code": "798",
			"currency": "Australian dollar",
			"currency_code": "AUD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "Tuvalu",
			"iso_3166_2": "TV",
			"iso_3166_3": "TUV",
			"name": countryName.countries[798],
			"region_code": "009",
			"sub_region_code": "061",
			"eea": false,
			"calling_code": "688",
			"flag": "TV.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 800,
			"capital": "Kampala",
			"citizenship": "Ugandan",
			"country_code": "800",
			"currency": "Uganda shilling",
			"currency_code": "UGX",
			"currency_sub_unit": "cent",
			"currency_symbol": "UGX",
			"currency_decimals": 0,
			"full_name": "Republic of Uganda",
			"iso_3166_2": "UG",
			"iso_3166_3": "UGA",
			"name": countryName.countries[800],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "256",
			"flag": "UG.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 804,
			"capital": "Kiev",
			"citizenship": "Ukrainian",
			"country_code": "804",
			"currency": "hryvnia",
			"currency_code": "UAH",
			"currency_sub_unit": "kopiyka",
			"currency_symbol": "₴",
			"currency_decimals": 2,
			"full_name": "Ukraine",
			"iso_3166_2": "UA",
			"iso_3166_3": "UKR",
			"name": countryName.countries[804],
			"region_code": "150",
			"sub_region_code": "151",
			"eea": false,
			"calling_code": "380",
			"flag": "UA.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 784,
			"capital": "Abu Dhabi",
			"citizenship": "Emirian",
			"country_code": "784",
			"currency": "UAE dirham",
			"currency_code": "AED",
			"currency_sub_unit": "fils (inv.)",
			"currency_symbol": "AED",
			"currency_decimals": 2,
			"full_name": "United Arab Emirates",
			"iso_3166_2": "AE",
			"iso_3166_3": "ARE",
			"name": countryName.countries[784],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": false,
			"calling_code": "971",
			"flag": "AE.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 826,
			"capital": "London",
			"citizenship": "British",
			"country_code": "826",
			"currency": "pound sterling",
			"currency_code": "GBP",
			"currency_sub_unit": "penny (pl. pence)",
			"currency_symbol": "£",
			"currency_decimals": 2,
			"full_name": "United Kingdom of Great Britain and Northern Ireland",
			"iso_3166_2": "GB",
			"iso_3166_3": "GBR",
			"name": countryName.countries[826],
			"region_code": "150",
			"sub_region_code": "154",
			"eea": true,
			"calling_code": "44",
			"flag": "GB.png",
			"vat_rate": 20,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 840,
			"capital": "Washington DC",
			"citizenship": "American",
			"country_code": "840",
			"currency": "US dollar",
			"currency_code": "USD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "United States of America",
			"iso_3166_2": "US",
			"iso_3166_3": "USA",
			"name": countryName.countries[840],
			"region_code": "019",
			"sub_region_code": "021",
			"eea": false,
			"calling_code": "1",
			"flag": "US.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 581,
			"capital": "United States Minor Outlying Islands",
			"citizenship": "of United States Minor Outlying Islands",
			"country_code": "581",
			"currency": "US dollar",
			"currency_code": "USD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "United States Minor Outlying Islands",
			"iso_3166_2": "UM",
			"iso_3166_3": "UMI",
			"name": countryName.countries[581],
			"region_code": "",
			"sub_region_code": "",
			"eea": false,
			"calling_code": "1",
			"flag": "UM.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 858,
			"capital": "Montevideo",
			"citizenship": "Uruguayan",
			"country_code": "858",
			"currency": "Uruguayan peso",
			"currency_code": "UYU",
			"currency_sub_unit": "centésimo",
			"currency_symbol": "$U",
			"currency_decimals": 0,
			"full_name": "Eastern Republic of Uruguay",
			"iso_3166_2": "UY",
			"iso_3166_3": "URY",
			"name": countryName.countries[858],
			"region_code": "019",
			"sub_region_code": "005",
			"eea": false,
			"calling_code": "598",
			"flag": "UY.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 860,
			"capital": "Tashkent",
			"citizenship": "Uzbek",
			"country_code": "860",
			"currency": "sum (inv.)",
			"currency_code": "UZS",
			"currency_sub_unit": "tiyin (inv.)",
			"currency_symbol": "лв",
			"currency_decimals": 2,
			"full_name": "Republic of Uzbekistan",
			"iso_3166_2": "UZ",
			"iso_3166_3": "UZB",
			"name": countryName.countries[860],
			"region_code": "142",
			"sub_region_code": "143",
			"eea": false,
			"calling_code": "998",
			"flag": "UZ.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 548,
			"capital": "Port Vila",
			"citizenship": "Vanuatuan",
			"country_code": "548",
			"currency": "vatu (inv.)",
			"currency_code": "VUV",
			"currency_sub_unit": "",
			"currency_symbol": "Vt",
			"currency_decimals": 0,
			"full_name": "Republic of Vanuatu",
			"iso_3166_2": "VU",
			"iso_3166_3": "VUT",
			"name": countryName.countries[548],
			"region_code": "009",
			"sub_region_code": "054",
			"eea": false,
			"calling_code": "678",
			"flag": "VU.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 862,
			"capital": "Caracas",
			"citizenship": "Venezuelan",
			"country_code": "862",
			"currency": "bolívar fuerte (pl. bolívares fuertes)",
			"currency_code": "VEF",
			"currency_sub_unit": "céntimo",
			"currency_symbol": "Bs",
			"currency_decimals": 2,
			"full_name": "Bolivarian Republic of Venezuela",
			"iso_3166_2": "VE",
			"iso_3166_3": "VEN",
			"name": countryName.countries[862],
			"region_code": "019",
			"sub_region_code": "005",
			"eea": false,
			"calling_code": "58",
			"flag": "VE.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 704,
			"capital": "Hanoi",
			"citizenship": "Vietnamese",
			"country_code": "704",
			"currency": "dong",
			"currency_code": "VND",
			"currency_sub_unit": "(10 hào",
			"currency_symbol": "₫",
			"currency_decimals": 2,
			"full_name": "Socialist Republic of Vietnam",
			"iso_3166_2": "VN",
			"iso_3166_3": "VNM",
			"name": countryName.countries[704],
			"region_code": "142",
			"sub_region_code": "035",
			"eea": false,
			"calling_code": "84",
			"flag": "VN.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 92,
			"capital": "Road Town",
			"citizenship": "British Virgin Islander;",
			"country_code": "092",
			"currency": "US dollar",
			"currency_code": "USD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "British Virgin Islands",
			"iso_3166_2": "VG",
			"iso_3166_3": "VGB",
			"name": countryName.countries[92],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "VG.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 850,
			"capital": "Charlotte Amalie",
			"citizenship": "US Virgin Islander",
			"country_code": "850",
			"currency": "US dollar",
			"currency_code": "USD",
			"currency_sub_unit": "cent",
			"currency_symbol": "$",
			"currency_decimals": 2,
			"full_name": "United States Virgin Islands",
			"iso_3166_2": "VI",
			"iso_3166_3": "VIR",
			"name": countryName.countries[850],
			"region_code": "019",
			"sub_region_code": "029",
			"eea": false,
			"calling_code": "1",
			"flag": "VI.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 876,
			"capital": "Mata-Utu",
			"citizenship": "Wallisian; Futunan; Wallis and Futuna Islander",
			"country_code": "876",
			"currency": "CFP franc",
			"currency_code": "XPF",
			"currency_sub_unit": "centime",
			"currency_symbol": "XPF",
			"currency_decimals": 0,
			"full_name": "Wallis and Futuna",
			"iso_3166_2": "WF",
			"iso_3166_3": "WLF",
			"name": countryName.countries[876],
			"region_code": "009",
			"sub_region_code": "061",
			"eea": false,
			"calling_code": "681",
			"flag": "WF.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 732,
			"capital": "Al aaiun",
			"citizenship": "Sahrawi",
			"country_code": "732",
			"currency": "Moroccan dirham",
			"currency_code": "MAD",
			"currency_sub_unit": "centime",
			"currency_symbol": "MAD",
			"currency_decimals": 2,
			"full_name": "Western Sahara",
			"iso_3166_2": "EH",
			"iso_3166_3": "ESH",
			"name": countryName.countries[732],
			"region_code": "002",
			"sub_region_code": "015",
			"eea": false,
			"calling_code": "212",
			"flag": "EH.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 887,
			"capital": "San’a",
			"citizenship": "Yemenite",
			"country_code": "887",
			"currency": "Yemeni rial",
			"currency_code": "YER",
			"currency_sub_unit": "fils (inv.)",
			"currency_symbol": "﷼",
			"currency_decimals": 2,
			"full_name": "Republic of Yemen",
			"iso_3166_2": "YE",
			"iso_3166_3": "YEM",
			"name": countryName.countries[887],
			"region_code": "142",
			"sub_region_code": "145",
			"eea": false,
			"calling_code": "967",
			"flag": "YE.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 894,
			"capital": "Lusaka",
			"citizenship": "Zambian",
			"country_code": "894",
			"currency": "Zambian kwacha (inv.)",
			"currency_code": "ZMW",
			"currency_sub_unit": "ngwee (inv.)",
			"currency_symbol": "ZK",
			"currency_decimals": 2,
			"full_name": "Republic of Zambia",
			"iso_3166_2": "ZM",
			"iso_3166_3": "ZMB",
			"name": countryName.countries[894],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "260",
			"flag": "ZM.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		},
		{
			"id": 716,
			"capital": "Harare",
			"citizenship": "Zimbabwean",
			"country_code": "716",
			"currency": "Zimbabwe dollar (ZW1)",
			"currency_code": "ZWL",
			"currency_sub_unit": "cent",
			"currency_symbol": "Z$",
			"currency_decimals": 2,
			"full_name": "Republic of Zimbabwe",
			"iso_3166_2": "ZW",
			"iso_3166_3": "ZWE",
			"name": countryName.countries[716],
			"region_code": "002",
			"sub_region_code": "014",
			"eea": false,
			"calling_code": "263",
			"flag": "ZW.png",
			"vat_rate": 0,
			"is_eu": false,
			"vat_placeholder": null
		}
	];
};
