<template>
	<div class="banners">
		<HeadingLabel
			id="banner-heading-label"
			icon="banner"
			width="32"
			height="29"
			:text="$t('layout.menu.banners')"
			btn
			blue400
			:btn-text="$t('banners.addBanner')"
			:is-button-click="addBannersRedirect"
		/>
		
		<BannersTable />
	</div>
</template>

<script setup lang="ts">
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import BannersTable from '@/views/Banners/components/BannersTable.vue';
import router from '@/router';

const addBannersRedirect = () => {
	router.push('/banners/create')
}

</script>

<style scoped lang="scss">

</style>
