<template>
    <div class="m-loading">
        <div class="m-loading__wrapper">
            <v-progress-circular
                indeterminate
                color="rgba(255, 255, 255)"
            ></v-progress-circular>
            {{ $t('components.loading') }}
        </div>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.m-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $black;
    opacity: 0.7;
    width: 100%;
    height: 100vh;
    z-index: 999999;

    &__wrapper {
        font-size: 20px;
        font-weight: 700;
        position: absolute;
        color: $white-100;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
