<template>
	<div class="dashboard-counter">
		<div class="dashboard-counter__wrapper">
			<MIcon :icon="icon" width="18" height="18" :color="white" />
			<div class="dashboard-counter__title">{{ title }}</div>
		</div>
		<div class="dashboard-counter__counter">{{ counter }}{{ secondCounter ? '/' + secondCounter : null}}{{ percent && null != secondCounter ? ` (${((counter/secondCounter) * 100).toFixed(2)}%)` : null}}</div>
	</div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { TIcon } from '@/components/atoms/MIcon.vue';
import MIcon from '@/components/atoms/MIcon.vue';

interface IOwnProps {
	icon: TIcon
	title: string
	counter: number
	secondCounter?: number
	percent?: boolean
}
interface IProps extends IOwnProps {
}

defineProps<IProps>();

const { white } = variables;
</script>

<style scoped lang="scss">
.dashboard-counter {
	width: 100%;
	
	@include media-breakpoint-up(sm) {
		width: 47%;
	}
	
	@include media-breakpoint-up(lg) {
		width: 31%;
	}
	
	@include media-breakpoint-up(xl) {
		width: 23%;
	}
	
	@media (min-width: 1800px) {
		width: 23.5%;
	}
	
	&__wrapper {
		display: flex;
		align-items: center;
		gap: 10px;
		background-color: $primary-400;
		padding: 15px 20px;
	}
	
	&__title {
		font-size: $size-16;
		color: $white-100;
		font-weight: $weight-700;
	}
	
	&__counter {
		padding: 12px 0;
		font-size: $size-20;
		font-weight: $weight-700;
		text-align: center;
		border: 1px solid $primary-400;
	}
}
</style>
