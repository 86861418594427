<template>
	<div v-if="null != user" class="affiliate-details">
		<ReflinkProgramWindow :current-user="user" />
		<ReflinksNetworkTable v-if="checkIsUserJoinAffiliateProgram(user)" :current-user="user" />
	</div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { checkIsUserJoinAffiliateProgram } from '@/hooks/UserHooks';
import ReflinksNetworkTable from '@/components/organisms/Reflinks/ReflinksNetworkTable.vue';
import ReflinkProgramWindow from '@/components/organisms/Reflinks/ReflinkProgramWindow.vue';

const { user } = storeToRefs(useUserStore());
</script>

<style scoped lang="scss">
.affiliate-details {

}
</style>
