import BaseApi from '@/api/BaseApi';
import type {
	IAddArticleToItemRequest,
	IAddArticleToPublicationRequest,
	IAddOfferToCartByUuidRequest,
	ICartDetailsPreviewResponse,
	ICartDetailsResponse,
	ICartFilterParams,
	ICartStatusRequest,
	IChangeCampaignRequest,
	IChangeItemPriceRequest,
	ICouponRequest,
	ICreateCartListResponse,
	IDuplicateCartItemRequest,
	TCreateEditCartRequest,
	TCreateEditCartResponse
} from '@/models/CartModel';
import type { IOrderPublicationRequest, IOrderPublicationResponse } from '@/models/OrderModel';
import type { TPaginate } from '@/models/GlobalModel';

export default class CartApi extends BaseApi {
	async createCartList(params: ICartFilterParams) {
		let url = `/api/v2/carts?page=${params.page}&per_page=${params.per_page}`;

		if (params.title && params.direction) {
			url += `&sort=${params.title}&order=${params.direction}`;
		}

		if (params.assigned) {
			url += `&assigned=${params.assigned}`;
		}

		if (params.creator) {
			url += `&creator=${params.creator}`;
		}

		if (params.status) {
			url += `&status=${params.status}`;
		}

		if (params.campaign) {
			url += `&campaign=${params.campaign}`;
		}

		if (params.created_from) {
			url += `&created_from=${params.created_from}`;
		}

		if (params.created_to) {
			url += `&created_to=${params.created_to}`;
		}

		return this.get<TPaginate<ICreateCartListResponse>>({
			url: url,
		});
	};

	async createCart (params: TCreateEditCartRequest) {
		return this.post<TCreateEditCartRequest, TCreateEditCartResponse>({
			url: '/api/v2/carts/create',
			data: params,
		});
	};

	async updateCart (params: TCreateEditCartRequest, cartUuid: string | string[] | null) {
		return this.put<TCreateEditCartRequest, TCreateEditCartResponse>({
			url: `/api/v2/carts/update/${cartUuid}`,
			data: params,
		});
	};

	async createCartPublicationOrder (params: IOrderPublicationRequest, cartUuid: string | string[] | null) {
		return this.post<IOrderPublicationRequest, IOrderPublicationResponse>({
			url: `api/v2/carts/attach/${cartUuid}/publication`,
			data: params,
		});
	};

	async updateCartPublicationOrder (params: IOrderPublicationRequest, cartUuid: string | string[] | null) {
		return this.put<IOrderPublicationRequest, IOrderPublicationResponse>({
			url: `api/v2/carts/attach/${cartUuid}/publication`,
			data: params,
		});
	};

	async cartDetails (cartUuid: string | string[] | null) {
		return this.get<ICartDetailsResponse>({
			url: `api/v2/carts/edit/${cartUuid}`,
		});
	};

	async deleteCart (cartUuid: string | null) {
		return this.delete({
			url: `api/v2/carts/delete/cart/${cartUuid}`,
		});
	};

	async cartDetailsPreview (cartUuid: string | string[] | null, isOrderUuid?: boolean) {
		const url = !isOrderUuid ? `api/v2/carts/${cartUuid}` : `api/v2/carts/preview/${cartUuid}`;
		return this.get<ICartDetailsPreviewResponse>({
			url: url,
		});
	};

	async cartChangePrice (params: IChangeItemPriceRequest) {
		return this.put<IChangeItemPriceRequest, any>({
			url: 'api/v2/carts/edit/item-price',
			data: params,
		});
	};

	async cartChangeCampaign (params: IChangeCampaignRequest) {
		return this.put<IChangeCampaignRequest, any>({
			url: 'api/v2/carts/edit/change-campaign-in-publication',
			data: params,
		});
	};

	async cartAddArticlePreview (params: IAddArticleToPublicationRequest | IAddArticleToItemRequest) {
		return this.put<IAddArticleToPublicationRequest | IAddArticleToItemRequest, any>({
			url: 'api/v2/carts/edit/change-article-in-publication',
			data: params,
		});
	};

	async cartRemoveItem (itemUuid: string) {
		return this.delete({
			url: `api/v2/carts/delete/item/${itemUuid}`,
		});
	};

	async cartDuplicateItem (params: IDuplicateCartItemRequest) {
		return this.put<IDuplicateCartItemRequest, any>({
			url: 'api/v2/carts/edit/duplicate-item',
			data: params,
		});
	};

	async addCoupon (params: ICouponRequest) {
		return this.put<ICouponRequest, any>({
			url: 'api/v2/carts/edit/change-coupon',
			data: params,
		});
	};

	async changeCartStatus (params: ICartStatusRequest) {
		return this.put<ICartStatusRequest, any>({
			url: 'api/v2/carts/edit/accept-and-send-to-client',
			data: params,
		});
	};

	async addOfferToCartByUuid (params: IAddOfferToCartByUuidRequest) {
		return this.put<IAddOfferToCartByUuidRequest, any>({
			url: 'api/v2/carts/edit/add-offer',
			data: params,
		});
	};
};
