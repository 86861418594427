<template>
	<div>
		<MLoading />
	</div>
</template>

<script setup lang="ts">
import router from '@/router';
import { useAuthStore } from '@/stores/auth';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import MonolithAuthApi from '@/api/monolith/MonolithAuthApi';
import MLoading from '@/components/atoms/MLoading.vue';

const monolithAuthApi = new MonolithAuthApi();

const { setAuthorization, apiToken } = useAuthStore();
const { t } = useI18n();

const uuid:Array<string>|string = router.currentRoute.value.params.uuid;

const impersonateAction = async() => {
	try {
		const result = await monolithAuthApi.impersonate(uuid, apiToken);
		if (!result.isSuccess) {
			toast.error(`${t('auth.toast.error')}`);
			return;
		}
		await setAuthorization(result.payload);
		// to wywalalo widoki poniewaz sesje sie mieszaly (dodawaly sie 2 takie same ciasteczka w monolicie i vue3 przez to aplikacja sie wywalala) mozliwe ze w przyszlosci do odkomentowania
		// document.cookie = `marketin9_session=${result.headers['cookie-id']}`;
		
		window.location.href = `${window.location.protocol}//${window.location.host}/auth/monolith/impersonate/${uuid}`;
	} catch (e) {
		toast.error(`${t('auth.toast.error')}`);
		return;
	}
};
impersonateAction();
</script>

<style scoped lang="scss">

</style>
