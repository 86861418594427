<template>
	<div class="users-list-table">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template v-slot:loading>
				<div class="users-list-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #top>
				<div class="users-list-table__search-wrapper">
					<MTextField
						id="users-search"
						v-model="search"
						name="search"
						variant="outlined"
						label=""
						:placeholder="$t('users.search')"
						clearable
						hide-details
					/>
				</div>
			</template>
			
			<template #item.first_name="elem">
				<div>
					<div v-if="elem.item.raw.first_name != null">{{ elem.item.raw.first_name }}</div>
					<div v-if="elem.item.raw.first_name == null">-</div>
				</div>
			</template>
			
			<template #item.last_name="elem">
				<div>
					<div v-if="elem.item.raw.last_name != null">{{ elem.item.raw.last_name }}</div>
					<div v-if="elem.item.raw.last_name == null">-</div>
				</div>
			</template>
			
			<template #item.email="elem">
				<div>
					<div v-if="elem.item.raw.email != null">{{ elem.item.raw.email }}</div>
					<div v-if="elem.item.raw.email == null">-</div>
				</div>
			</template>
			
			<template #item.default_role="elem">
				<div v-if="elem.item.raw.default_role_id === ADVERTISER_ID">{{ $t('components.advertiser') }}</div>
				<div v-if="elem.item.raw.default_role_id === PUBLISHER_ID">{{ $t('components.publisher') }}</div>
				<div v-if="elem.item.raw.default_role_id == null">-</div>
			</template>
			
			<template #item.company_name="elem">
				<div v-if="elem.item.raw.company_name != null">{{ elem.item.raw.company_name }}</div>
				<div v-if="elem.item.raw.company_name == null">-</div>
			</template>
			
			<template #item.vat_no="elem">
				<div v-if="elem.item.raw.vat_no != null">{{ elem.item.raw.vat_no }}</div>
				<div v-if="elem.item.raw.vat_no == null">-</div>
			</template>
			
			<template #item.phone="elem">
				<div v-if="elem.item.raw.phone != null">{{ elem.item.raw.phone }}</div>
				<div v-if="elem.item.raw.phone == null">-</div>
			</template>
		</v-data-table-server>
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import MonolithUserApi from '@/api/monolith/MonolithUserApi';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { IMonolithUsersResponseItem, IMonolithUsersRequest } from '@/models/UserModer';
import MTextField from '@/components/atoms/MTextField.vue';
import { ADVERTISER_ID, PUBLISHER_ID } from '@/hooks/UserHooks';

const { t } = useI18n();

const loading = ref<boolean>(false);
const search = ref<string | null>(null);
const searchTimeout = ref<any>(undefined);
const items = ref<Array<IMonolithUsersResponseItem>>([]);
const total = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
let headers = ref<Array<TableHeaderInterface>|any>([]);
const defaultHeaders = () => {
	const result = [
		{
			title: `${t('users.firstName')}`,
			align: 'center',
			sortable: true,
			key: 'first_name',
		},
		{
			title: `${t('users.lastName')}`,
			align: 'center',
			sortable: true,
			key: 'last_name',
		},
		{
			title: `${t('users.email')}`,
			align: 'center',
			sortable: true,
			key: 'email',
		},
		{
			title: `${t('users.defaultRole')}`,
			align: 'center',
			sortable: false,
			key: 'default_role',
		},
		{
			title: `${t('users.companyName')}`,
			align: 'center',
			sortable: false,
			key: 'company_name',
		},
		{
			title: `${t('users.nip')}`,
			align: 'center',
			sortable: false,
			key: 'vat_no',
		},
		{
			title: `${t('users.phone')}`,
			align: 'center',
			sortable: false,
			key: 'phone',
		},
	];
	
	return result;
};
headers.value = defaultHeaders();

const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	await usersList();
};

const monolithUserApi = new MonolithUserApi();
const usersList = async() => {
	loading.value = true;
	try {
		const filters:IMonolithUsersRequest = {
			api_token: localStorage.getItem('apiToken'),
			page: options.value.page,
			per_page: options.value.itemsPerPage,
			order: options.value.sortBy.length > 0 ? options.value.sortBy[0].key : 'first_name',
			orderDirection: options.value.sortBy.length > 0 ? options.value.sortBy[0].order : 'desc',
			roleId: 2,
			search: search.value,
		};
		const result = await monolithUserApi.monolithUsersList(filters);
		if (!result.isSuccess) {
			toast.error(`${t('users.toast.errorGetUsersList')}`);
			loading.value = false;
			return;
		}
		total.value = result.payload.total;
		currentPage.value = options.value.current_page;
		lastPage.value = options.value.last_page;
		items.value = result.payload.data;
	} catch (e) {
		toast.error(`${t('users.toast.errorGetUsersList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

watch((search), async (val) => {
	clearTimeout(searchTimeout.value);
	searchTimeout.value = setTimeout(async () => {
		await usersList();
	}, 700);
}, {
	deep: true,
});
</script>

<style scoped lang="scss">
.users-list-table {
	@import '@/assets/scss/vuetifyDataTable.scss';
	@import '@/assets/scss/vuetifyDataTableBottomRoundRadius';
	&__loading {
		opacity: 0.5;
	}
	
	&__search-wrapper {
		padding: 20px;
		width: 300px;
	}
}
</style>
