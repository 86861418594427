import BaseApi from '@/api/BaseApi';
import type {
	IDiscountGroupList,
	IDiscountGroupWebsiteOffer,
	IEditDiscountGroupsForm,
	IEditDiscountGroupsResponse
} from '@/models/DiscountGroupsModel';

export default class DiscountGroupApi extends BaseApi {
	async editDiscountGroups (offerUuid: Array<string>|string|null, params: IEditDiscountGroupsForm, isSync?: boolean){
		let url = `api/v1/discount-groups/offer/${offerUuid}`;

		if (isSync) {
			url += '?sync=1';
		}
		return this.patch<IEditDiscountGroupsForm, IEditDiscountGroupsResponse>({
			url: url,
			data: params,
		});
	};

	async userDiscountGroupList () {
		return this.get<Array<IDiscountGroupList>>({
			url: '/api/v1/discount-groups',
		});
	};

	async websiteOffersIndividualDiscountUserGroup (page: number, perPage: number, sortBy: string|null, sortDesc: string|null, userDiscountSlug: Array<string>|string|null, search: string|null) {
		let url = `/api/v1/discount-groups/${userDiscountSlug}?page=${page}&per_page=${perPage}`;

		if (null != sortBy) {
			url += `&sort=${sortBy}`;
		}

		if (null != sortDesc) {
			url += `&order=${sortDesc}`;
		}

		if (null != search) {
			url += `&search=${search}`;
		}

		return this.get<IDiscountGroupWebsiteOffer>({
			url: url,
		});
	};
};
