import { toast } from 'vue3-toastify';

const fallbackCopyTextToClipboard = (text:string) => {
	const textArea = document.createElement('textarea');
	textArea.value = text;

	textArea.style.top = '0';
	textArea.style.left = '0';
	textArea.style.position = 'fixed';

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	document.execCommand('copy');

	document.body.removeChild(textArea);
};
export const copyToClipboard = (text:string, successText:string, errorText:string) => {
	if (!navigator.clipboard) {
		fallbackCopyTextToClipboard(text);
		return;
	}
	navigator.clipboard.writeText(text).then(() => {
		toast.success(`${successText}`);
	}, () => {
		toast.error(`${errorText}`);
	});
};
