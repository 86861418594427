<template>
	<div class="create-edit-article">
		<HeadingLabel
			class="create-edit-article__heading-label"
			icon="clipboard"
			width="32"
			height="29"
			:text="null != router.currentRoute.value.params.articleUuid ? $t('articles.editArticle') : $t('articles.addNewArticle')"
			modal
			modal-icon="step-plus"
			modal-icon-width="70"
			modal-icon-height="71"
			:modal-icon-color="white"
			:modal-title="$t('articles.writeArticleModalTitle')"
		>
			<div class="create-edit-article__modal-text">
				{{ $t('articles.writeArticleModalDescription1') }}
			</div>
			<div class="create-edit-article__modal-text">
				{{ $t('articles.writeArticleModalDescription2') }}
			</div>
			<div class="create-edit-article__modal-img-wrapper">
				<img src="~@/assets/images/onboarding_step4_1.png" alt="campaigns">
			</div>
			<div class="create-edit-article__modal-text">
				{{ $t('articles.writeArticleModalDescription3') }}
			</div>
			<div class="create-edit-article__modal-img-wrapper">
				<img src="~@/assets/images/onboarding_step4_2.png" alt="title">
			</div>
			<div class="create-edit-article__modal-text">
				{{ $t('articles.writeArticleModalDescription4') }}
			</div>
			<div class="create-edit-article__modal-img-wrapper">
				<img src="~@/assets/images/onboarding_step4_3.png" alt="content">
			</div>
			<div class="create-edit-article__modal-text">
				{{ $t('articles.writeArticleModalDescription5') }}
			</div>
			<div class="create-edit-article__modal-img-wrapper">
				<img src="~@/assets/images/onboarding_step4_4.png" alt="content">
			</div>
			<div class="create-edit-article__modal-text">
				{{ $t('articles.writeArticleModalDescription6') }}
			</div>
			<div class="create-edit-article__modal-img-wrapper">
				<img src="~@/assets/images/onboarding_step4_5.png" alt="upload photo">
			</div>
			<div class="create-edit-article__modal-text">
				{{ $t('articles.writeArticleModalDescription7') }}
			</div>
			<div class="create-edit-article__modal-text">
				{{ $t('articles.writeArticleModalDescription8') }}
			</div>
		</HeadingLabel>
		
		<div class="create-edit-article__form">
			<CreateArticleForm />
		</div>
	</div>
</template>

<script setup lang="ts">
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import variables from '@/assets/scss/modules/variables.module.scss';
import CreateArticleForm from '@/components/organisms/Articles/CreateArticleForm.vue';
import router from '@/router';

const { white } = variables;
</script>

<style scoped lang="scss">
.create-edit-article {
	&__heading-label {
		margin-bottom: 5px;
	}
	
	&__modal-text {
		line-height: 1.4;
		margin-top: 30px;
		
		@include media-breakpoint-up(md) {
			padding: 0 40px;
		}
		
		@include media-breakpoint-up(lg) {
			padding: 0 50px;
		}
		
		&:last-child {
			@include media-breakpoint-up(lg) {
				margin: 30px 0 50px;
			}
		}
	}
	
	&__modal-img-wrapper {
		width: inherit;
		margin-top: 30px;
		
		@include media-breakpoint-up(md) {
			width: 85%;
			margin: 30px auto 0;
		}
		
		@include media-breakpoint-up(lg) {
			width: max-content;
			max-width: 730px;
		}
		
		img {
			width: 100%;
		}
	}
	
	&__form {
		background-color: $white-100;
		padding: 15px 20px;
	}
}
</style>
