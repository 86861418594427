<template>
	<div class="profile">
		<div v-if="null != currentUser && null != user">
			<div v-if="!isProfileLocked(currentUser) && !isAdmin(user) && !isModerator(user) && !isContentWriter(user)" class="profile__complete-profile" @click="openBasicInfo">{{ $t('profile.completeBasicInfo') }}</div>
			<div v-if="isProfileLocked(currentUser) && checkAddressProfile(currentUser) && !isAdmin(user) && !isModerator(user) && !isContentWriter(user)" class="profile__complete-profile" @click="openAddressInfo">{{ $t('profile.completeBillingInfo') }}</div>
			<UserComments v-if="isAdmin(user)" :user="currentUser"  class="profile__user-comments"/>
			<v-tabs v-model="tab" fixed-tabs :bg-color="primary400">
				<v-tab id="basic-info-tab" value="basic-info">{{ $t('profile.basicInfo') }}</v-tab>
				<v-tab v-if="currentLoggedUser(currentUser, null != router.currentRoute.value.params.userUuid) !== LOGGED_MEMBER" id="billing-info-tab" value="billing-info">{{ $t('profile.billingInfo') }}</v-tab>
				<v-tab id="notification-tab" value="notification">{{ $t('profile.consentsAndNotifications') }}</v-tab>
				<v-tab v-if="isAdmin(user) && null != router.currentRoute.value.params.userUuid" id="payment-history-tab" value="payment-history">{{ $t('profile.paymentHistory') }}</v-tab>
				<v-tab v-if="(isAdmin(user) && null != router.currentRoute.value.params.userUuid && currentLoggedUser(currentUser, null != router.currentRoute.value.params.userUuid) !== LOGGED_MEMBER)" id="reflink-info-tab" value="reflink-info">{{ $t('profile.affiliateProgram') }}</v-tab>
				<v-tab v-if="isAdmin(user) && null != router.currentRoute.value.params.userUuid" id="contadu-tab" value="contadu">{{ $t('profile.contadu') }}</v-tab>
				<v-tab id="password-change-tab" value="password-change">{{ $t('profile.changePassword') }}</v-tab>
			</v-tabs>
			
			<v-window v-model="tab">
				<v-window-item value="basic-info">
					<BasicInfo :current-user="currentUser" :is-agency="isAgency" @userDetails="userDetails" />
				</v-window-item>
				<v-window-item v-if="currentLoggedUser(currentUser, null != router.currentRoute.value.params.userUuid) !== LOGGED_MEMBER" value="billing-info">
					<BillingInfo :current-user="currentUser" @userDetails="userDetails" />
				</v-window-item>
				<v-window-item value="notification">
					<NotificationsSettings :current-user="currentUser" @userDetails="userDetails" />
				</v-window-item>
				<v-window-item v-if="isAdmin(user) && null != router.currentRoute.value.params.userUuid" value="payment-history">
					<div>
						<v-tabs v-model="tabHistoryPayment" fixed-tabs :bg-color="primary400">
							<v-tab value="history-payment-all">{{ $t('profile.all') }}</v-tab>
							<v-tab value="history-payment-advertiser">{{ $t('profile.advertiser') }}</v-tab>
							<v-tab value="history-payment-publisher">{{ $t('profile.publisher') }}</v-tab>
						</v-tabs>
						<v-window v-model="tabHistoryPayment">
							<v-window-item value="history-payment-all">
								<HistoryPaymentTable v-if="null == historyPaymentUserActiveRoleId" :active-role-id="historyPaymentUserActiveRoleId" :user-id="currentUser.id"/>
							</v-window-item>
							<v-window-item value="history-payment-advertiser">
								<HistoryPaymentTable v-if="2 === historyPaymentUserActiveRoleId" :active-role-id="historyPaymentUserActiveRoleId" :user-id="currentUser.id" />
							</v-window-item>
							<v-window-item value="history-payment-publisher">
								<HistoryPaymentTable v-if="3 === historyPaymentUserActiveRoleId" :active-role-id="historyPaymentUserActiveRoleId" :user-id="currentUser.id" />
							</v-window-item>
						</v-window>
					</div>
				</v-window-item>
				<v-window-item v-if="(isAdmin(user) && null != router.currentRoute.value.params.userUuid && currentLoggedUser(currentUser, null != router.currentRoute.value.params.userUuid) !== LOGGED_MEMBER)" value="reflink-info">
					<ReflinkProgramWindow :current-user="currentUser" @userDetails="userDetails" />
					<ReflinksNetworkTable :current-user="currentUser" />
					<Reflinks v-if="isAdmin(user) && null != router.currentRoute.value.params.userUuid" :current-user="currentUser" />
				</v-window-item>
				<v-window-item v-if="isAdmin(user) && null != router.currentRoute.value.params.userUuid" value="contadu">
					<Contadu :current-user="currentUser" @userDetails="userDetails" />
				</v-window-item>
				<v-window-item value="password-change">
					<ChangePassword :current-user="currentUser" />
				</v-window-item>
			</v-window>
		</div>
		<MLoading v-show="loading" />
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { isAdvertiser, isAdmin, isModerator, isContentWriter } from '@/hooks/UserHooks';
import {
	isProfileLocked,
	checkAddressProfile,
	currentLoggedUser,
	LOGGED_MEMBER
} from '@/hooks/ProfileHooks';
import { checkIsGoToPreparedCartAction } from '@/hooks/CartHooks';
import UserApi from '@/api/v1/UserApi';
import AuthApi from '@/api/v1/AuthApi';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { UserInterface } from '@/models/AuthModel';
import BasicInfo from '@/views/Profile/components/BasicInfo.vue';
import MLoading from '@/components/atoms/MLoading.vue';
import BillingInfo from '@/views/Profile/components/BillingInfo.vue';
import NotificationsSettings from '@/views/Profile/components/NotificationsSettings.vue';
import HistoryPaymentTable from '@/components/organisms/HistoryPayment/HistoryPaymentTable.vue';
import ChangePassword from '@/views/Profile/components/ChangePassword.vue';
import Contadu from '@/views/Profile/components/Contadu.vue';
import ReflinkProgramWindow from '@/components/organisms/Reflinks/ReflinkProgramWindow.vue';
import ReflinksNetworkTable from '@/components/organisms/Reflinks/ReflinksNetworkTable.vue';
import Reflinks from '@/views/AffiliateProgram/components/Reflinks.vue';
import UserComments from '@/views/Profile/components/UserComments.vue';

const userApi = new UserApi();
const authApi = new AuthApi();

const { t } = useI18n();
const { user } = storeToRefs(useUserStore());
const { setUser } = useUserStore();
const { primary400 } = variables;

const tab = ref<string|null>(null);
const tabHistoryPayment = ref<string|null>(null);
const historyPaymentUserActiveRoleId = ref<number|null>(null);
const currentUser = ref<UserInterface|any>(null);
const loading = ref<boolean>(false);
const isAgency = ref<boolean>(false);
const isFirstLoad = ref<boolean>(true);

const openBasicInfo = () => {
	tab.value = 'basic-info';
};
const openAddressInfo = () => {
	tab.value = 'billing-info';
};

const userDetails = async() => {
	loading.value = true;
	if (null != router.currentRoute.value.params.userUuid) {
		try {
			const result = await userApi.userInfo(router.currentRoute.value.params.userUuid);
			if (!result.isSuccess) {
				await router.push('/dashboard');
				toast.error(`${t('profile.toast.errorUserDetails')}`);
				loading.value = false;
				return;
			}
			isAgency.value = result.payload.is_agency === 1;
			currentUser.value = result.payload;
			currentUser.value.is_agency = result.payload.is_agency === 1;
			if (null != user.value && isAdvertiser(user.value) && null == result.payload.is_personal) {
				currentUser.value.is_personal = true;
			}
		} catch (e) {
			await router.push('/dashboard');
			toast.error(`${t('profile.toast.errorUserDetails')}`);
			loading.value = false;
			return;
		}
	} else {
		if (!isFirstLoad.value) {
			try {
				const result = await authApi.me();
				if (!result.isSuccess) {
					await router.push('/dashboard');
					toast.error(`${t('profile.toast.errorUserDetails')}`);
					loading.value = false;
					return;
				}
				await setUser(result.payload);
			} catch (e) {
				await router.push('/dashboard');
				toast.error(`${t('profile.toast.errorUserDetails')}`);
				loading.value = false;
				return;
			}
		}
		currentUser.value = user.value;
		currentUser.value.is_agency = user.value?.is_agency === 1;
		if (null != user.value && isAdvertiser(user.value) && null == user.value?.is_personal) {
			currentUser.value.is_personal = true;
		}
		if (null != user.value && isProfileLocked(currentUser.value) && checkAddressProfile(currentUser.value) && !isAdmin(user.value) && !isModerator(user.value) && !isContentWriter(user.value)) {
			openAddressInfo();
		}
		if (isProfileLocked(user.value) && !checkAddressProfile(user.value)) {
			checkIsGoToPreparedCartAction();
		}
	}
	isFirstLoad.value = false;
	loading.value = false;
};
userDetails();

watch((tab), (val) => {
	if (null != user.value && !isAdmin(user.value) && !isModerator(user.value) && !isContentWriter(user.value) && null != currentUser.value && !isProfileLocked(currentUser.value) && (val === 'billing-info' || val === 'notification' || val === 'reflink-info' || val === 'password-change')) {
		tab.value = 'basic-info';
		toast.error(`${t('profile.toast.invalidBasicInfo')}`);
	}
	if (null != user.value && !isAdmin(user.value) && !isModerator(user.value) && !isContentWriter(user.value) && null != currentUser.value && isProfileLocked(currentUser.value) && checkAddressProfile(currentUser.value) && (val === 'notification' || val === 'reflink-info' || val === 'password-change')) {
		tab.value = 'billing-info';
		toast.error(`${t('profile.toast.invalidAddressInfo')}`);
	}
},{
	deep: true,
});

watch((tabHistoryPayment), (val) => {
	if (val === 'history-payment-all') {
		historyPaymentUserActiveRoleId.value = null;
	}
	if (val === 'history-payment-advertiser') {
		historyPaymentUserActiveRoleId.value = 2;
	}
	if (val === 'history-payment-publisher') {
		historyPaymentUserActiveRoleId.value = 3;
	}
},{
	deep: true,
});
</script>

<style scoped lang="scss">
.profile {
	&__complete-profile {
		background-color: $secondary-400;
		width: 100%;
		padding: 15px 25px;
		color: $white-100;
		font-size: 14px;
		font-weight: 700;
		margin-bottom: 10px;
		cursor: pointer;
	}
	
	&__user-comments {
		margin-bottom: 10px;
	}
	
	&:deep(.v-slide-group) {
		color: $white-100;
	}
	
	&:deep(.v-tab__slider) {
		background: $secondary-400;
		height: 3px;
	}
}
</style>
