<template>
	<div v-if="null != user" class="cart-list-table">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:items-per-page-options="itemsPerPageOptions"
			v-model:page="options.page"
			:items-per-page="options.itemsPerPage"
			:sort-by="options.sortBy"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
		>
			<template #loading>
				<div class="cart-list-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #item.domain="elem">
				<div v-if="null != elem.item.raw.orderable && null != elem.item.raw.orderable.offer" id="cart-domain">{{ elem.item.raw.orderable.offer.url }}</div>
<!--				<div v-if="null == elem.item.raw.domain && ((isModerator(user) && (status === CREATE_CART_STATUS_DRAFT || status === CREATE_CART_STATUS_RESIGNED)) || isAdvertiser(user))" id="cart-domain" class="cart-list-table__add-wrapper">{{ $t('basket.preview.add') }} <span>+</span></div>-->
				<div v-if="elem.item.raw.orderable_type === DEPOSIT_REQUEST">{{ $t('basket.list.deposit') }}</div>
			</template>
			
			<template #item.price="elem">
				<div v-if="elem.item.raw.ordered_article != null" class="cart-list-table__bold">{{ $t('basket.preview.publication') }}:</div>
				<div class="cart-list-table__price-wrapper">
					<div v-if="!elem.item.raw.is_edit_mode"><span id="cart-publication-price">{{ null != elem.item.raw.net_client ? (+elem.item.raw.net_client).toFixed(2) : '0.00' }}</span> {{ userCurrencySymbol }}</div>
					<div v-if="elem.item.raw.is_edit_mode" class="cart-list-table__price-input-wrapper">
						<MTextField
							id="cart-price-input"
							v-model="elem.item.raw.net_client"
							name="price"
							type="number"
							:min="elem.item.raw.min_price"
							step="0.01"
						/>
						<MIcon icon="tick" :color="green400" @click="saveChangePrice(elem.item.raw)" />
						<MIcon icon="close" :color="secondary400" @click="closeChangePrice(elem.item.raw)" />
					</div>
					<div v-if="isModerator(user) && (status === CREATE_CART_STATUS_DRAFT || status === CREATE_CART_STATUS_RESIGNED) && !elem.item.raw.is_edit_mode" class="cart-list-table__edit-icon-wrapper" @click="editPrice(elem.item.raw)">
						<MIcon icon="pencil" :color="white" width="14" height="14" />
					</div>
				</div>
				
				<div v-if="elem.item.raw.ordered_article != null" class="cart-list-table__bold">{{ $t('basket.preview.article') }}:</div>
				<div v-if="elem.item.raw.ordered_article != null" class="cart-list-table__price-wrapper cart-list-table__price-wrapper--article">
					<div v-if="!elem.item.raw.ordered_article_is_edit_mode"><span id="cart-article-price">{{ null != elem.item.raw.ordered_article_net_client ? (+elem.item.raw.ordered_article_net_client).toFixed(2) : '0.00' }}</span> {{ userCurrencySymbol }}</div>
					<div v-if="elem.item.raw.ordered_article_is_edit_mode" class="cart-list-table__price-input-wrapper">
						<MTextField
							id="cart-article-price-input"
							v-model="elem.item.raw.ordered_article_net_client"
							name="articlePrice"
							type="number"
							min="0.01"
							step="0.01"
						/>
						<MIcon icon="tick" :color="green400" @click="saveChangePrice(elem.item.raw, true)" />
						<MIcon icon="close" :color="secondary400" @click="closeChangePrice(elem.item.raw, true)" />
					</div>
					<div v-if="isModerator(user) && (status === CREATE_CART_STATUS_DRAFT || status === CREATE_CART_STATUS_RESIGNED) && !elem.item.raw.ordered_article_is_edit_mode" class="cart-list-table__edit-icon-wrapper" @click="editPrice(elem.item.raw, true)">
						<MIcon icon="pencil" :color="white" width="14" height="14" />
					</div>
				</div>
			</template>
			
			<template #item.min_price="elem">
				<div v-if="elem.item.raw.ordered_article != null" class="cart-list-table__bold">{{ $t('basket.preview.publication') }}:</div>
				<div class="cart-list-table__price-wrapper">
					<div><span id="cart-publication-min-price">{{ null != elem.item.raw.min_price ? (+elem.item.raw.min_price).toFixed(2) : (+elem.item.raw.price).toFixed(2) }}</span> {{ userCurrencySymbol }}</div>
				</div>
				
				<div v-if="elem.item.raw.ordered_article != null" class="cart-list-table__bold">{{ $t('basket.preview.article') }}:</div>
				<div v-if="elem.item.raw.ordered_article != null" class="cart-list-table__price-wrapper cart-list-table__price-wrapper--article">
					<div><span id="cart-article-min-price">{{ null != elem.item.raw.ordered_article_min_price ? (+elem.item.raw.ordered_article_min_price).toFixed(2) : '0.01' }}</span> {{ userCurrencySymbol }}</div>
				</div>
			</template>
			
			<template #item.article="elem">
				<div v-if="null != elem.item.raw.orderable && null != elem.item.raw.orderable.article" id="cart-article">{{ elem.item.raw.orderable.article.title }}</div>
				<div
					v-if="null != elem.item.raw.orderable && null == elem.item.raw.orderable.article && elem.item.raw.orderable_type !== DEPOSIT_REQUEST && ((isModerator(user) && (status === CREATE_CART_STATUS_DRAFT || status === CREATE_CART_STATUS_RESIGNED)) || (isAdvertiser(user)) && (status === CREATE_CART_STATUS_SEND || status === CREATE_CART_STATUS_FINISHED || status == null))"
					id="cart-article"
					class="cart-list-table__add-wrapper cart-list-table__add-wrapper--red"
					@click="openAddArticleDialog(elem.item.raw)"
				>
					{{ $t('basket.preview.add') }} <span>+</span>
				</div>
			</template>
			
			<template #item.campaign="elem">
				<div v-if="elem.item.raw.orderable_type !== DEPOSIT_REQUEST" class="cart-list-table__autocomplete-wrapper">
					<MAutocomplete
						v-show="!campaignLoading"
						id="cart-campaign"
						v-model="elem.item.raw.campaign_id"
						name="currency"
						:get-items="campaignList"
						item-title="name"
						item-value="id"
						label=""
						:placeholder="$t('basket.preview.none')"
						variant="solo"
						clearable
						menu-icon="chevron-down"
						@onChange="onCampaignChange(elem.item.raw)"
						:disabled="true"
					/>
					<v-progress-circular v-show="campaignLoading" size="20" width="2" indeterminate />
				</div>
			</template>
			
			<template #item.discount="elem">
				<div v-if="elem.item.raw.ordered_article != null" class="cart-list-table__bold">{{ $t('basket.preview.publication') }}:</div>
				<div v-if="null != elem.item.raw.discount_value && 0 != elem.item.raw.discount_value" id="cart-discount" class="cart-list-table__discount cart-list-table__discount--green">-{{ elem.item.raw.discount_value.toFixed(2) }} {{ userCurrencySymbol }}</div>
				<div v-if="null == elem.item.raw.discount_value || 0 === elem.item.raw.discount_value" id="cart-discount" class="cart-list-table__discount">0.00 {{ userCurrencySymbol }}</div>
				<div v-if="elem.item.raw.ordered_article != null" class="cart-list-table__bold">{{ $t('basket.preview.article') }}:</div>
				<div v-if="elem.item.raw.ordered_article != null && null != elem.item.raw.ordered_article_discount && 0 != elem.item.raw.ordered_article_discount" id="cart-article-discount" class="cart-list-table__discount cart-list-table__discount--green">-{{ elem.item.raw.ordered_article_discount.toFixed(2) }} {{ userCurrencySymbol }}</div>
				<div v-if="elem.item.raw.ordered_article != null && (null == elem.item.raw.ordered_article_discount || 0 === elem.item.raw.ordered_article_discount)" id="cart-article-discount" class="cart-list-table__discount">0.00 {{ userCurrencySymbol }}</div>
			</template>
			
			<template #item.actions="elem">
				<div class="cart-list-table__action-wrapper">
					<MTooltip without-btn>
						<template v-slot:title>
							<div v-if="elem.item.raw.orderable_type !== DEPOSIT_REQUEST && ((isModerator(user) && (status === CREATE_CART_STATUS_DRAFT || status === CREATE_CART_STATUS_RESIGNED)) || (isAdvertiser(user) && null == creator))" class="cart-list-table__action-icon-wrapper" @click="openDuplicateDialog(elem.item.raw)">
								<MIcon icon="copy" :color="white" />
							</div>
						</template>
						<template v-slot:content>
							<div>{{ $t('basket.preview.duplicate') }}</div>
						</template>
					</MTooltip>
					<MTooltip without-btn>
						<template v-slot:title>
							<div v-if="elem.item.raw.orderable_type !== DEPOSIT_REQUEST" class="cart-list-table__action-icon-wrapper" @click="openDetailsDialog(elem.item.raw)">
								<MIcon icon="clipboard" :color="white" />
							</div>
						</template>
						<template v-slot:content>
							<div>{{ $t('basket.preview.details') }}</div>
						</template>
					</MTooltip>
					<MTooltip without-btn>
						<template v-slot:title>
							<div v-if="(isModerator(user) && (status === CREATE_CART_STATUS_DRAFT || status === CREATE_CART_STATUS_RESIGNED)) || (isAdvertiser(user) && null == creator)" class="cart-list-table__action-icon-wrapper" @click="openDeleteDialog(elem.item.raw)">
								<MIcon icon="trash" :color="white" />
							</div>
						</template>
						<template v-slot:content>
							<div>{{ $t('basket.preview.delete') }}</div>
						</template>
					</MTooltip>
				</div>
			</template>
			
			<template #bottom>
				<div></div>
			</template>
		</v-data-table-server>
		
		<MDialog
			:is-close-dialog-action="isCloseDuplicateDialog"
			:is-open-dialog-action="isOpenDuplicateDialog"
			:content-title="$t('basket.preview.duplicateTitle')"
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<div class="cart-list-table__dialog-wrapper">
					<div class="cart-list-table__dialog-text">{{ $t('basket.preview.duplicateDescription') }}</div>
					<div class="cart-list-table__dialog-btn-wrapper">
						<MButton id="cart-table-duplicate-action" normal width110 green400 :color="white" :label="$t('basket.preview.duplicate')" @click="duplicateAction" />
						<MButton id="cart-table-close-duplicate-modal-action" normal width110 blue400 :color="white" :label="$t('components.cancel')" @click="closeDialogWhenNoTargetPressed" />
					</div>
				</div>
			</template>
		</MDialog>
		
		<MDialog
			:is-close-dialog-action="isCloseDetailsDialog"
			:is-open-dialog-action="isOpenDetailsDialog"
			:content-title="$t('basket.preview.detailsTitle')"
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<div class="cart-list-table__dialog-wrapper">
					<div>{{ $t('basket.preview.campaign') }}: {{ null != selectedActionItem?.campaign_id ? campaignName(selectedActionItem?.campaign_id) : $t('basket.preview.none') }}</div>
					<div v-if="isModerator(user)">{{ $t('basket.preview.price') }}: {{ null != selectedActionItem?.price ? selectedActionItem?.price.toFixed(2) : selectedActionItem?.price }} {{ userCurrencySymbol }}</div>
					<div v-if="!isModerator(user)">{{ $t('basket.preview.price') }}: {{ null != selectedActionItem?.net_client ? selectedActionItem?.net_client.toFixed(2) : selectedActionItem?.net_client }} {{ userCurrencySymbol }}</div>
					<div v-if="selectedActionItem?.orderable_type === PUBLICATION_REQUEST">{{ $t('basket.preview.url') }}: {{ selectedActionItem.orderable?.offer?.url }}</div>
					<div v-if="selectedActionItem?.orderable_type === PUBLICATION_REQUEST">{{ $t('basket.preview.daysPromotion') }}: {{ selectedActionItem.orderable?.offer?.days_promotion_on_main_page }}</div>
					<div v-if="selectedActionItem?.orderable_type === PUBLICATION_REQUEST">{{ $t('basket.preview.disclosure') }}: {{ null != selectedActionItem?.orderable && null != selectedActionItem?.orderable.offer.disclosure ? disclosureName(selectedActionItem.orderable?.offer?.disclosure) : '' }}</div>
					<div v-if="selectedActionItem?.orderable_type === PUBLICATION_REQUEST">{{ $t('basket.preview.doFollow') }}: {{ selectedActionItem.orderable?.offer?.do_follow }}</div>
					<div v-if="selectedActionItem?.orderable_type === PUBLICATION_REQUEST">{{ $t('basket.preview.duration') }}: {{ selectedActionItem.orderable?.offer?.duration?.name }}</div>
					<div v-if="selectedActionItem?.orderable_type === PUBLICATION_REQUEST">{{ $t('basket.preview.maxLinks') }}: {{ selectedActionItem.orderable?.offer?.links_max_amount }}</div>
					<div v-if="selectedActionItem?.orderable_type === PUBLICATION_REQUEST">{{ $t('basket.preview.name') }}: {{ selectedActionItem.orderable?.offer?.name }}</div>
					<div v-if="selectedActionItem?.orderable_type === ARTICLE_REQUEST">{{ $t('basket.preview.type') }}: {{ null != selectedActionItem?.orderable && null != selectedActionItem?.orderable.article_type ? articleTypeName(selectedActionItem.orderable?.article_type) : '' }}</div>
					<div v-if="selectedActionItem?.orderable_type === ARTICLE_REQUEST">{{ $t('basket.preview.title') }}: {{ selectedActionItem.orderable?.article?.title }}</div>
				</div>
			</template>
		</MDialog>
		
		<MDialog
			:is-close-dialog-action="isCloseDeleteDialog"
			:is-open-dialog-action="isOpenDeleteDialog"
			:content-title="$t('basket.preview.deleteTitle')"
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<div class="cart-list-table__dialog-wrapper">
					<div class="cart-list-table__dialog-text">{{ $t('basket.preview.deleteDescription') }}</div>
					<div class="cart-list-table__dialog-btn-wrapper">
						<MButton id="cart-table-delete-action" normal red400 width110 :color="white" :label="$t('components.delete')" @click="deleteAction" />
						<MButton id="cart-table-close-delete-modal-action" normal width110 blue400 :color="white" :label="$t('components.cancel')" @click="closeDialogWhenNoTargetPressed" />
					</div>
				</div>
			</template>
		</MDialog>
		
		<MDialog
			:is-close-dialog-action="isCloseAddArticleDialog"
			:is-open-dialog-action="isOpenAddArticleDialog"
			:content-header="false"
			:width="970"
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<div v-if="selectedAddArticleOption == null" class="cart-list-table__add-article-wrapper">
					<div class="cart-list-table__add-article-title">{{ $t('basket.preview.addArticleTitle') }}</div>
					<div class="cart-list-table__add-article-description">{{ $t('basket.preview.selectOption') }}</div>
					<div class="cart-list-table__add-article-action-icon">
						<div class="cart-list-table__add-article-item-wrapper" @click="openWriteArticle(CART_ADD_ARTICLE_WRITE)">
							<div class="cart-list-table__add-article-img-wrapper cart-list-table__add-article-img-wrapper--first">
								<img src="~@/assets/images/write-an-article.jpg" alt="write an article">
							</div>
							<div class="cart-list-table__add-article-item-title">{{ $t('articles.writeArticle') }}</div>
						</div>
						<div class="cart-list-table__add-article-item-wrapper" @click="openWriteArticle(CART_ADD_ARTICLE_UPLOAD)">
							<div class="cart-list-table__add-article-img-wrapper">
								<img src="~@/assets/images/upload-an-article.jpg" alt="write an article">
							</div>
							<div class="cart-list-table__add-article-item-title">{{ $t('articles.uploadArticle') }}</div>
							<div class="cart-list-table__add-article-item-description">{{ $t('articles.uploadArticleFromFile') }}</div>
						</div>
<!--						<div class="cart-list-table__add-article-item-wrapper">-->
<!--							<div class="cart-list-table__add-article-img-wrapper">-->
<!--								<img src="~@/assets/images/upload-google-document.jpg" alt="write an article">-->
<!--							</div>-->
<!--							<div class="cart-list-table__add-article-item-title">{{ $t('articles.uploadArticle') }}</div>-->
<!--							<div class="cart-list-table__add-article-item-description">{{ $t('articles.uploadArticleFromFile') }}</div>-->
<!--						</div>-->
						<div class="cart-list-table__add-article-item-wrapper" @click="openWriteArticle(CART_ADD_ARTICLE_ORDER)">
							<div class="cart-list-table__add-article-fourth-description">{{ $t('basket.preview.orderArticleInfo') }}</div>
							<div class="cart-list-table__add-article-img-wrapper cart-list-table__add-article-img-wrapper--fourth">
								<img src="~@/assets/images/up-content-logo.png" alt="order an article">
							</div>
							<div class="cart-list-table__add-article-item-title">{{ $t('basket.preview.orderArticle') }}</div>
						</div>
					</div>
				</div>
				<div v-if="selectedAddArticleOption === CART_ADD_ARTICLE_WRITE">
					<CreateArticleForm
						:language-id="null != selectedActionItem && null != selectedActionItem.orderable && null != selectedActionItem.orderable.offer && null != selectedActionItem.orderable.offer.website && null != selectedActionItem.orderable.offer.website.language_id ? selectedActionItem.orderable.offer.website.language_id : 1"
						:campaign-id="null != selectedActionItem ? selectedActionItem.campaign_id : null"
						is-dialog-form
						@dialogSaveArticle="addArticleSave"
					/>
				</div>
				<div v-if="selectedAddArticleOption === CART_ADD_ARTICLE_UPLOAD">
					<UploadArticle
						:language-id="null != selectedActionItem ? selectedActionItem.orderable?.offer?.website?.language_id : 1"
						:campaign-id="null != selectedActionItem ? selectedActionItem.campaign_id : null"
						is-dialog
						@dialogSaveArticle="addArticleSave"
					/>
				</div>
				<div v-if="selectedAddArticleOption === CART_ADD_ARTICLE_ORDER">
					<ArticleTypesWindow
						v-if="articleTypeId == null && null != selectedActionItem && !selectedActionItem.orderable?.is_article_written_by_publisher && articleTypesList.length > 0"
						v-model="articleTypeId"
						:article-types="articleTypesList"
						:is-language-pl="selectedActionItem.orderable?.offer?.website?.language_id === POLISH_LANGUAGE_ID"
						tooltip-content-color="white"
					/>
					<div v-if="articleTypeId == null && null != selectedActionItem && !selectedActionItem.orderable?.is_article_written_by_publisher && articleTypesList.length === 0" class="cart-list-table__spinner">
						<v-progress-circular :size="30" :width="4" indeterminate />
					</div>
					<ArticleTypesWindow
						v-if="articleTypeId == null && null != selectedActionItem && selectedActionItem.orderable?.is_article_written_by_publisher"
						v-model="articleTypeId"
						:article-types="publisherArticleTypesList"
						is-publisher-window
						tooltip-content-color="white"
					/>
					<OrderArticleForm
						v-if="null != articleTypeId"
						:language-id="null != selectedActionItem && null != selectedActionItem.orderable && null != selectedActionItem.orderable.offer && null != selectedActionItem.orderable.offer.website && null != selectedActionItem.orderable.offer.website.language_id ? selectedActionItem.orderable.offer.website.language_id : 1"
						:campaign-id="null != selectedActionItem ? selectedActionItem.campaign_id : null"
						:article-type-id="articleTypeId"
						is-dialog-form
						@dialogSaveOrderArticle="addOrderArticleSave"
					/>
				</div>
			</template>
		</MDialog>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { defineProps, ref, watch, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useDictionaryStore } from '@/stores/dictionary';
import { isAdvertiser, isModerator } from '@/hooks/UserHooks';
import { toast } from 'vue3-toastify';
import ArticleApiV1 from '@/api/v1/ArticleApi';
import CampaignApi from '@/api/v1/CampaignApi';
import CartApi from '@/api/v2/CartApi';
import variables from '@/assets/scss/modules/variables.module.scss';
import {
	CART_ADD_ARTICLE_ORDER,
	CART_ADD_ARTICLE_UPLOAD,
	CART_ADD_ARTICLE_UPLOAD_GOOGLE,
	CART_ADD_ARTICLE_WRITE,
	CREATE_CART_STATUS_DRAFT,
	CREATE_CART_STATUS_SEND,
	CREATE_CART_STATUS_RESIGNED,
	CREATE_CART_STATUS_FINISHED,
} from '@/hooks/CartHooks';
import { POLISH_LANGUAGE_ID } from '@/hooks/ProfileHooks';
import {
	ARTICLE_TYPE_PREMIUM,
	ARTICLE_TYPE_SPECIALIST,
	ARTICLE_TYPE_STANDARD,
	ARTICLE_TYPE_TECHNICAL
} from '@/hooks/ArticlesHooks';
import { ARTICLE_REQUEST, DEPOSIT_REQUEST, PUBLICATION_REQUEST } from '@/hooks/OrderHooks';
import { MARGIN_TYPE_CASH } from '@/hooks/OfferHooks';
import type {
	IAddArticleToItemRequest,
	IAddArticleToPublicationRequest,
	ICartCreatorDetails,
	ICartPreviewDetailsItems,
	IChangeCampaignRequest,
	IChangeItemPriceRequest,
	IDuplicateCartItemRequest,
	TSelectOptions
} from '@/models/CartModel';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { CampaignInterface } from '@/models/CampaignModel';
import type { IArticleTypes, ICreateArticleResponse } from '@/models/ArticlesModel';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MButton from '@/components/atoms/MButton.vue';
import MDialog from '@/components/atoms/MDialog.vue';
import CreateArticleForm from '@/components/organisms/Articles/CreateArticleForm.vue';
import UploadArticle from '@/components/organisms/Articles/UploadArticle.vue';
import OrderArticleForm from '@/components/organisms/OrderCreate/components/OrderArticleForm.vue';
import ArticleTypesWindow from '@/components/molecules/ArticleTypesWindow.vue';
import {
	DISCLOSURE_ADD_SPONSORED,
	DISCLOSURE_DISCRETION,
	DISCLOSURE_NOT_ADD_SPONSORED,
	DISCLOSURE_OTHER
} from '@/hooks/DisclosureHooks';

interface IOwnProps {
	items: ICartPreviewDetailsItems[]
	loading: boolean
	total: number
	userId: number | null
	status: string | null
	creator: ICartCreatorDetails | null
}
interface IProps extends IOwnProps {
}

const { white, green400, secondary400 } = variables;
const props = defineProps<IProps>();
const { t } = useI18n();
const { userCurrencySymbol, user } = storeToRefs(useUserStore());
const { campaigns } = useDictionaryStore();

const itemsPerPageOptions = ref<Array<any>>([
	{
		value: 100,
		title: '100'
	},
]);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 100,
	sortBy: [],
	groupBy: [],
	search: null,
});
const optionsUpdate = async(val: ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
};

let headers = ref<Array<TableHeaderInterface>|any>([]);
const defaultHeaders = () => {
	const result = [
		{
			title: `${t('basket.preview.domain')}`,
			align: 'center',
			sortable: false,
			key: 'domain',
		},
		{
			title: `${t('basket.preview.price')}`,
			align: 'center',
			sortable: false,
			key: 'price',
		},
	];
	
	if (null != user.value && isModerator(user.value)) {
		result.push(
			{
				title: `${t('basket.preview.basePrice')}`,
				align: 'center',
				sortable: false,
				key: 'min_price',
			},
		);
	}
	
	result.push(...[
		{
			title: `${t('basket.preview.article')}`,
			align: 'center',
			sortable: false,
			key: 'article',
		},
	]);
	
	if (null != user.value && isModerator(user.value)) {
		result.push(
			{
				title: `${t('basket.preview.campaign')}`,
				align: 'center',
				sortable: false,
				key: 'campaign',
			},
		);
	}
	
	result.push(...[
		{
			title: `${t('basket.preview.discount')}`,
			align: 'center',
			sortable: false,
			key: 'discount',
		},
		{
			title: `${t('basket.preview.actions')}`,
			align: 'center',
			sortable: false,
			key: 'actions',
		},
	]);
	
	return result;
};
headers.value = defaultHeaders();

const campaignApi = new CampaignApi();

const campaignList = ref<Array<CampaignInterface>|any>([]);
const campaignLoading = ref<boolean>(false);

const userCampaignList = async() => {
	campaignLoading.value = true;
	if (null == props.userId) {
		toast.error(`${t('basket.create.toast.userCampaignListError')}`);
		campaignLoading.value = false;
		return;
	}
	try {
		const result = await campaignApi.userCampaigns(props.userId);
		if (!result.isSuccess) {
			toast.error(`${t('basket.create.toast.userCampaignListError')}`);
			campaignLoading.value = false;
			return;
		}
		const campaignPayloadList:Array<TSelectOptions> = result.payload.map((elem:CampaignInterface) => {
			return {
				id: elem.id,
				name: elem.name,
				default: elem.default,
			}
		});
		campaignList.value = campaignPayloadList;
		const defaultCampaign = campaignList.value.find((elem:CampaignInterface) => elem.default === 1);
		if (null != defaultCampaign) {
			campaignList.value.splice(campaignList.value.indexOf(defaultCampaign), 1);
		}
	} catch (error) {
		toast.error(`${t('basket.create.toast.userCampaignListError')}`);
		campaignLoading.value = false;
		return;
	}
	campaignLoading.value = false;
};
if (null != user.value && !isModerator(user.value)) {
	campaignList.value = campaigns;
}

const prevUserId = ref<number | null>(null);
watch((props), (val) => {
	if (null != val.userId && prevUserId.value != val.userId && null != user.value && isModerator(user.value)) {
		prevUserId.value = val.userId;
		userCampaignList();
	}
},{
	deep: true,
});

const isCloseDuplicateDialog = ref<boolean>(true);
const isCloseDetailsDialog = ref<boolean>(true);
const isCloseDeleteDialog = ref<boolean>(true);
const isCloseAddArticleDialog = ref<boolean>(true);
const isOpenDuplicateDialog = ref<boolean>(false);
const isOpenDetailsDialog = ref<boolean>(false);
const isOpenDeleteDialog = ref<boolean>(false);
const isOpenAddArticleDialog = ref<boolean>(false);
const selectedActionItem = ref<ICartPreviewDetailsItems|null>(null);

const closeDialogWhenNoTargetPressed = () => {
	isCloseDuplicateDialog.value = true;
	isCloseDetailsDialog.value = true;
	isCloseDeleteDialog.value = true;
	isCloseAddArticleDialog.value = true;
	isOpenDuplicateDialog.value = false;
	isOpenDetailsDialog.value = false;
	isOpenDeleteDialog.value = false;
	isOpenAddArticleDialog.value = false;
	
	selectedActionItem.value = null;
	selectedAddArticleOption.value = null;
	articleTypeId.value = null;
	articleTypesList.value = [];
};

const openDuplicateDialog = (item: ICartPreviewDetailsItems) => {
	selectedActionItem.value = item;
	isCloseDuplicateDialog.value = false;
	isOpenDuplicateDialog.value = true;
};

const openDetailsDialog = (item: ICartPreviewDetailsItems) => {
	selectedActionItem.value = item;
	isCloseDetailsDialog.value = false;
	isOpenDetailsDialog.value = true;
};

const openDeleteDialog = (item: ICartPreviewDetailsItems) => {
	selectedActionItem.value = item;
	isCloseDeleteDialog.value = false;
	isOpenDeleteDialog.value = true;
};

const openAddArticleDialog = (item: ICartPreviewDetailsItems) => {
	selectedActionItem.value = item;
	isCloseAddArticleDialog.value = false;
	isOpenAddArticleDialog.value = true;
};

const cartApi = new CartApi();
const emit = defineEmits(['reloadCart']);

const duplicateAction = async() => {
	if (null == selectedActionItem.value) {
		closeDialogWhenNoTargetPressed();
		toast.error(`${t('basket.preview.toast.errorDuplicateItem')}`);
		return;
	}
	try {
		const duplicateForm:IDuplicateCartItemRequest = {
			item_uuid: selectedActionItem.value.uuid,
		};
		const result = await cartApi.cartDuplicateItem(duplicateForm);
		
		if (!result.isSuccess) {
			closeDialogWhenNoTargetPressed();
			toast.error(`${t('basket.preview.toast.errorDuplicateItem')}`);
			return;
		}
		toast.success(`${t('basket.preview.toast.successDuplicateItem')}`);
		closeDialogWhenNoTargetPressed();
		emit('reloadCart');
	} catch (e) {
		closeDialogWhenNoTargetPressed();
		toast.error(`${t('basket.preview.toast.errorDuplicateItem')}`);
		return;
	}
};

const deleteAction = async() => {
	if (null == selectedActionItem.value) {
		closeDialogWhenNoTargetPressed();
		toast.error(`${t('basket.preview.toast.errorDeleteItem')}`);
		return;
	}
	try {
		const result = await cartApi.cartRemoveItem(selectedActionItem.value.uuid);
		
		if (!result.isSuccess) {
			closeDialogWhenNoTargetPressed();
			toast.error(`${t('basket.preview.toast.errorDeleteItem')}`);
			return;
		}
		toast.success(`${t('basket.preview.toast.successDeleteItem')}`);
		closeDialogWhenNoTargetPressed();
		emit('reloadCart');
	} catch (e) {
		closeDialogWhenNoTargetPressed();
		toast.error(`${t('basket.preview.toast.errorDeleteItem')}`);
		return;
	}
};

const editPrice = (item: ICartPreviewDetailsItems, isArticleAction?: boolean) => {
	if (!isArticleAction) {
		item.is_edit_mode = true;
	} else {
		item.ordered_article_is_edit_mode = true;
	}
};

const saveChangePrice = async(item: ICartPreviewDetailsItems, isArticleAction?: boolean) => {
	if (null == item.net_client || null == item.min_price) {
		toast.error(`${t('basket.preview.toast.errorChangePrice')}`);
		return;
	}
	
	if (+item.net_client < +item.min_price) {
		toast.error(`${t('basket.preview.toast.errorPrice')}`);
		return;
	}
	try {
		const changePriceRequest: IChangeItemPriceRequest = {
			item_uuid: !isArticleAction ? item.uuid : item.ordered_article.uuid,
			discount_type: MARGIN_TYPE_CASH,
			discount_value: !isArticleAction ? item.net_client * 100 : null != item.ordered_article_net_client ? item.ordered_article_net_client * 100 : 0,
		};
		const result = await cartApi.cartChangePrice(changePriceRequest);
		
		if (!result.isSuccess) {
			toast.error(`${t('basket.preview.toast.errorChangePrice')}`);
			return;
		}
		item.is_edit_mode = false;
		toast.success(`${t('basket.preview.toast.successChangePrice')}`);
		emit('reloadCart');
	} catch (e) {
		toast.error(`${t('basket.preview.toast.errorChangePrice')}`);
		return;
	}
};

const closeChangePrice = (item: ICartPreviewDetailsItems, isArticleAction?: boolean) => {
	if (!isArticleAction) {
		item.price = item.prev_price;
		item.is_edit_mode = false;
	} else {
		item.ordered_article_price = item.ordered_article_prev_price;
		item.ordered_article_is_edit_mode = false;
	}
};

const onCampaignChange = async(item: ICartPreviewDetailsItems) => {
	try {
		const changeCampaignRequest: IChangeCampaignRequest = {
			order_uuid: item.order_uuid,
			campaign_id: item.campaign_id,
		};
		const result = await cartApi.cartChangeCampaign(changeCampaignRequest);
		
		if (!result.isSuccess) {
			toast.error(`${t('basket.preview.toast.errorChangeCampaign')}`);
			return;
		}
		toast.success(`${t('basket.preview.toast.successChangeCampaign')}`);
	} catch (e) {
		toast.error(`${t('basket.preview.toast.errorChangeCampaign')}`);
		return;
	}
};

const selectedAddArticleOption = ref<string | null>(null);
const openWriteArticle = (slug: string) => {
	selectedAddArticleOption.value = slug;
};

const addArticleSave = async(articleId: number, response: ICreateArticleResponse) => {
	if (null == selectedActionItem.value) {
		closeDialogWhenNoTargetPressed();
		toast.error(`${t('basket.preview.toast.errorAddArticle')}`);
		return;
	}
	try {
		const addArticleToPublicationForm: IAddArticleToItemRequest = {
			item_uuid: selectedActionItem.value.uuid,
			article_uuid: response.uuid,
		}
		const result = await cartApi.cartAddArticlePreview(addArticleToPublicationForm);
		
		if (!result.isSuccess) {
			closeDialogWhenNoTargetPressed();
			toast.error(`${t('basket.preview.toast.errorAddArticle')}`);
			return;
		}
		toast.success(`${t('basket.preview.toast.successAddArticle')}`);
		closeDialogWhenNoTargetPressed();
		emit('reloadCart');
	} catch (e) {
		closeDialogWhenNoTargetPressed();
		toast.error(`${t('basket.preview.toast.errorAddArticle')}`);
		return;
	}
};

const addOrderArticleSave = async(response: any) => {
	if (null == selectedActionItem.value) {
		closeDialogWhenNoTargetPressed();
		toast.error(`${t('basket.preview.toast.errorAddArticle')}`);
		return;
	}
	try {
		const addArticleToPublicationForm: IAddArticleToPublicationRequest = {
			item_uuid: selectedActionItem.value.uuid,
			article_uuid: response.uuid,
			campaign_id: selectedActionItem.value.campaign_id,
			language_id: response.language_id,
			title: response.title,
			reports: response.reports,
			article_type_id: response.article_type_id,
			amount: response.amount,
			links: response.links,
			contact: response.contact,
			number: response.number,
			email: response.email,
		};
		const result = await cartApi.cartAddArticlePreview(addArticleToPublicationForm);
		
		if (!result.isSuccess) {
			closeDialogWhenNoTargetPressed();
			toast.error(`${t('basket.preview.toast.errorAddArticle')}`);
			return;
		}
		toast.success(`${t('basket.preview.toast.successAddArticle')}`);
		closeDialogWhenNoTargetPressed();
		emit('reloadCart');
	} catch (e) {
		closeDialogWhenNoTargetPressed();
		toast.error(`${t('basket.preview.toast.errorAddArticle')}`);
		return;
	}
};

const articleApiV1 = new ArticleApiV1();
const articleTypeId = ref<number | null>(null);
const articleTypesList = ref<Array<IArticleTypes>>([]);
const publisherArticleTypesList = ref<Array<any>>([
	{
		id: 0,
		type: 'publisher',
		net_price: 0,
		converted_net_price: 0,
	}
]);
const articleTypes = async() => {
	if (null == selectedActionItem.value || null == selectedActionItem.value.orderable?.offer?.website?.language_id) {
		toast.error(`${t('order.publication.toast.errorGetArticleTypes')}`);
		return;
	}
	try {
		const result = await articleApiV1.articleTypes(selectedActionItem.value.orderable?.offer?.website?.language_id);
		if (!result.isSuccess) {
			toast.error(`${t('order.publication.toast.errorGetArticleTypes')}`);
			return;
		}
		
		if (POLISH_LANGUAGE_ID === selectedActionItem.value.language_id) {
			articleTypesList.value = result.payload;
		} else {
			const typesList = result.payload;
			const specialistArticle = typesList.find(elem => elem.type === ARTICLE_TYPE_SPECIALIST);
			if (null != specialistArticle) {
				const index = typesList.indexOf(specialistArticle);
				typesList.splice(index, 1);
				articleTypesList.value = typesList;
			} else {
				articleTypesList.value = result.payload;
			}
		}
	} catch (e) {
		toast.error(`${t('order.publication.toast.errorGetArticleTypes')}`);
		return;
	}
};
watch((selectedAddArticleOption), (val) => {
	if (val === CART_ADD_ARTICLE_ORDER && articleTypeId.value == null && null != selectedActionItem.value) {
		articleTypes();
	}
},{
	deep: true,
});

const disclosureName = (disclosure: string) => {
	switch (disclosure) {
		case DISCLOSURE_OTHER:
			return `${t('basket.preview.disclosureOther')}`;
		case DISCLOSURE_DISCRETION:
			return `${t('basket.preview.disclosureDiscretion')}`;
		case DISCLOSURE_ADD_SPONSORED:
			return `${t('basket.preview.disclosureAddSponsored')}`;
		case DISCLOSURE_NOT_ADD_SPONSORED:
			return `${t('basket.preview.disclosureNotAddSponsored')}`;
		default:
			return `${t('basket.preview.disclosureOther')}`;
	}
};

const articleTypeName = (articleTypeName: string) => {
	switch (articleTypeName) {
		case ARTICLE_TYPE_STANDARD:
			return `${t('basket.preview.standard')}`;
		case ARTICLE_TYPE_TECHNICAL:
			return `${t('basket.preview.technical')}`;
		case ARTICLE_TYPE_PREMIUM:
			return `${t('basket.preview.premium')}`;
		case ARTICLE_TYPE_SPECIALIST:
			return `${t('basket.preview.specialist')}`;
		default:
			return `${t('basket.preview.standard')}`;
	}
};

const campaignName = (campaignId: number | null) => {
	const campaign = campaignList.value.find((elem:any) => elem.id === campaignId);
	if (null != campaign) {
		return campaign.name;
	}
	return `${t('basket.preview.none')}`;
};
</script>

<style scoped lang="scss">
.cart-list-table {
	@import '@/assets/scss/vuetifyDataTable';
	
	&__loading {
		opacity: 0.5;
	}
	
	&__add-wrapper {
		cursor: pointer;
		text-transform: uppercase;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		span {
			border: 1px solid $primary-400;
			border-radius: 50%;
			font-weight: 700;
			margin-left: 3px;
			padding: 0 4px 0.5px 4px;
		}
		
		&--red {
			width: max-content;
			margin: 0 auto;
			color: $secondary-400;
			
			span {
				border-color: $secondary-400;
			}
		}
	}
	
	&__autocomplete-wrapper {
		min-width: 85px;
		max-width: max-content;
		margin: 0 auto;
		
		&:deep(.m-autocomplete .v-field__input) {
			padding: 0 0 0 !important;
			min-height: 22px !important;
		}
		&:deep(.v-input__details) {
			display: none;
		}
		&:deep(.v-field) {
			font-size: 12px;
			box-shadow: none;
		}
		&:deep(.v-input--density-default) {
			--v-input-padding-top: 0;
		}
	}
	
	&__discount {
		width: max-content;
		margin: 0 auto;
		color: $grey-400;
		
		&--green {
			color: $green-400;
		}
	}
	
	&__action-wrapper {
		display: flex;
		width: 100%;
		gap: 5px;
		justify-content: center;
	}
	
	&__action-icon-wrapper {
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background-color: $grey-300;
		cursor: pointer;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__price-wrapper {
		display: flex;
		align-items: center;
		gap: 5px;
		width: max-content;
		margin: 0 auto;
		
		&--article {
			margin-bottom: 5px;
		}
		
		&:deep(.v-input__details) {
			display: none;
		}
		
		&:deep(.v-field) {
			font-size: 12px;
			box-shadow: none;
		}
	}
	
	&__edit-icon-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $primary-400;
		border-radius: 50%;
		width: 25px;
		height: 25px;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__price-input-wrapper {
		display: flex;
		align-items: center;
		gap: 5px;
		
		&:deep(.m-input) {
			width: 80px;
		}
		
		svg {
			cursor: pointer;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	&__dialog-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 10px;
	}
	
	&__dialog-text {
		font-size: 14px;
		font-weight: 700;
		color: $primary-400;
		text-align: center;
	}
	
	&__dialog-btn-wrapper {
		display: flex;
		justify-content: center;
		gap: 50px;
	}
	
	&__add-article-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: $primary-400;
	}
	
	&__add-article-title {
		font-size: 24px;
		font-weight: 700;
	}
	
	&__add-article-description {
		font-size: 16px;
		font-weight: 400;
		margin: 20px 0;
	}
	
	&__add-article-action-icon {
		display: flex;
		gap: 30px;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 20px;
	}
	
	&__add-article-item-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 195px;
		padding: 15px 30px;
		box-shadow: 0 0 20px $grey-300;
		border-radius: 20px;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__add-article-img-wrapper {
		width: 120px;
		
		img {
			width: 100%;
			height: 100%;
		}
		
		&--first {
			width: 105px;
		}
		
		&--fourth {
			margin: 15px 0 7px;
		}
	}
	
	&__add-article-item-title {
		font-size: 20px;
		font-weight: 700;
		color: $secondary-400;
		margin-top: 10px;
	}
	
	&__add-article-item-description {
		color: $grey-300;
		font-size: 11px;
	}
	
	&__add-article-fourth-description {
		width: 130px;
		margin-bottom: 10px;
	}
	
	&__spinner {
		min-height: 300px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	&__bold {
		font-weight: 700;
	}
	
	&:deep(.v-table--has-bottom) {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
	
	&:deep(.v-data-table) {
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
	}
	
	&:deep(.v-data-table th) {
		height: 49px;
		background-color: $white-100;
	}
}
</style>
