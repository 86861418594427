<template>
	<div class="billing-info">
		<HeadingLabel
			icon="credit-card"
			width="32"
			height="29"
			:text="$t('profile.billingData')"
		/>
		<MProgressBar v-show="loading" indeterminate height="2" />
		<Form :validation-schema="schema" @submit="onSubmit" class="billing-info__edit-list">
			<MTextField
				id="input-address-line1"
				v-model="formBillingAddressData.line1"
				name="line1"
				:separate-label="$t('profile.addressLine1')"
				label-text-required
				:placeholder="$t('profile.addressLine1Placeholder')"
			/>
			<MTextField
				id="input-address-line2"
				v-model="formBillingAddressData.line2"
				name="line2"
				:separate-label="$t('profile.addressLine2')"
				:placeholder="$t('profile.addressLine2Placeholder')"
			/>
			<MTextField
				id="input-phone"
				v-model="formBillingAddressData.phone"
				name="phone"
				:separate-label="$t('profile.phone')"
				label-text-required
				:placeholder="$t('profile.phonePlaceholder')"
			/>
			<MTextField
				id="input-zip-code"
				v-model="formBillingAddressData.zip"
				name="zip"
				:separate-label="$t('profile.zipCode')"
				label-text-required
				:placeholder="$t('profile.zipCodePlaceholder')"
			/>
			<MTextField
				id="input-city"
				v-model="formBillingAddressData.city"
				name="city"
				:separate-label="$t('profile.city')"
				label-text-required
				:placeholder="$t('profile.cityPlaceholder')"
			/>
			<MTextField
				id="input-province"
				v-model="formBillingAddressData.state"
				name="state"
				:separate-label="$t('profile.stateProvince')"
				label-text-required
				:placeholder="$t('profile.stateProvincePlaceholder')"
			/>
			<MAutocomplete
				id="input-billing-country"
				name="country"
				v-model="formBillingAddressData.country_id"
				:get-items="countries"
				variant="outlined"
				item-title="name"
				item-value="id"
				:label="$t('profile.country')"
				label-text-required
				:placeholder="$t('profile.countryPlaceholder')"
			/>
			<div>
				<MButton
					id="btn-edit-address"
					normal
					width100
					green400
					type="submit"
					:label="$t('profile.save')"
					:loading="loading"
				/>
			</div>
		</Form>
	</div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from 'vue';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import { useDictionaryStore } from '@/stores/dictionary';
import { toast } from 'vue3-toastify';
import UserApi from '@/api/v1/UserApi';
import type { UserInterface } from '@/models/AuthModel';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MButton from '@/components/atoms/MButton.vue';

interface IOwnProps {
	currentUser: UserInterface
}

interface IProps extends IOwnProps {
}

const userApi = new UserApi();

const props = defineProps<IProps>();
const emit = defineEmits(['userDetails']);

const { t } = useI18n();
const { countries } = useDictionaryStore();

const formBillingAddressData = ref<any>({
	line1: props.currentUser.addresses[0].line1,
	line2: props.currentUser.addresses[0].line2,
	phone: props.currentUser.addresses[0].phone,
	zip: props.currentUser.addresses[0].zip,
	city: props.currentUser.addresses[0].city,
	state: props.currentUser.addresses[0].state,
	country_id: props.currentUser.addresses[0].country_id,
	is_main: props.currentUser.addresses[0].is_main || 1,
});
const loading = ref<boolean>(false);

const schema:any = yup.object({
	line1: yup.string().required(`${t('profile.validation.line1Required')}`).label('line1'),
	phone: yup.string().required(`${t('profile.validation.phoneRequired')}`).label('phone').test('phone', `${t('profile.validation.phoneRegex')}`,
	function (val) {
		if (val.match(/^(\+\d{1,4}[-\s]?)?(\d{1,4}[-\s]?){0,3}\d{1,4}$/)) {
			return true;
		} else {
			return false;
		}
	}),
	zip: yup.string().required(`${t('profile.validation.zipRequired')}`).label('zip'),
	city: yup.string().required(`${t('profile.validation.cityRequired')}`).label('city'),
	state: yup.string().required(`${t('profile.validation.stateRequired')}`).label('state'),
	country: yup.number().required(`${t('profile.validation.countryRequired')}`).label('country'),
});

const onSubmit = async() => {
	loading.value = true;
	formBillingAddressData.value.phone = formBillingAddressData.value.phone.replace(/\s/g, "");
	try {
		const result = await userApi.updateAddress(formBillingAddressData.value, props.currentUser.addresses[0].uuid);
		if (!result.isSuccess) {
			toast.error(`${t('profile.toast.errorUpdateProfile')}`);
			loading.value = false;
			return;
		}
		toast.success(`${t('auth.toast.success')}`);
		emit('userDetails');
	} catch (e) {
		toast.error(`${t('profile.toast.errorUpdateProfile')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
</script>

<style scoped lang="scss">
.billing-info {
	&__edit-list {
		display: flex;
		flex-direction: column;
		gap: 7px;
		background-color: $white-100;
		margin-top: 3px;
		padding: 20px 10px;
		
		@include media-breakpoint-up(sm) {
			padding: 20px 20px;
		}
	}
	
	&:deep(.v-autocomplete .v-input__details) {
		padding: 0;
	}
}
</style>
