<template>
	<div v-if="null != user" class="history-payment-table">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			v-model:page="options.page"
			:items-per-page="options.itemsPerPage"
			:sort-by="options.sortBy"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template v-slot:loading>
				<div class="history-payment-table__loading">{{ $t('components.loading') }}</div>
			</template>

			<template #top>
				<div v-if="!isEditProfilePage && !isAffiliateProgram" class="history-payment-table__top-wrapper">
					<div class="history-payment-table__filter-wrapper">
						<div v-if="user.is_agency" class="history-payment-table__user-wrapper">
							<MAutocomplete
								id="history-payment-dependent-account"
								v-model="dependentAccountUuid"
								:get-items="dependentAccountList"
								clearable
								variant="outlined"
								item-title="email"
								item-value="id"
								:placeholder="$t('publications.selectAccount')"
								label=""
							/>
						</div>
						
						<div v-if="null != TRANSACTIONS_LIST" class="history-payment-table__table-settings-wrapper">
							<TableSettingsMenuWithModal :table-name="TRANSACTIONS_LIST" :is-cleared-table-settings-action="isClearedTableSettingsAction" @headersMove="headersMoveAction" :on-clear-action="clearTableSettingsAction" />
						</div>
					</div>
				</div>
			</template>
			
			<template #item.role_id="elem">
				<div v-if="elem.item.raw.role === ADVERTISER_ID">{{ $t('profile.advertiser') }}</div>
				<div v-if="elem.item.raw.role === PUBLISHER_ID">{{ $t('profile.publisher') }}</div>
				<div v-if="elem.item.raw.role !== ADVERTISER_ID && elem.item.raw.role !== PUBLISHER_ID">-</div>
			</template>

			<template #item.date="elem">
				<div>{{ dateFormatWithTime(elem.item.raw.date) }}</div>
			</template>
			
			<template #item.type="elem">
				<div v-if="elem.item.raw.source != null && elem.item.raw.source_type === ARTICLE_REQUEST" class="m9-data-table-align-left">
					<span class="history-payment-table__bold">{{ typeName(elem.item.raw.source_type) }}:</span> {{ elem.item.raw.source.subject }}
					<div v-if="elem.item.raw.order != null" class="history-payment-table__bold">{{ $t('order.deposit.orderNumber') }} <span>{{ elem.item.raw.order.id }}</span></div>
				</div>
				<div v-if="elem.item.raw.source != null && elem.item.raw.source_type === PUBLICATION_REQUEST" class="m9-data-table-align-left">
					<span class="history-payment-table__bold">{{ typeName(elem.item.raw.source_type) }}:</span> {{ elem.item.raw.source.url }}
					<div v-if="elem.item.raw.order != null" class="history-payment-table__bold">{{ $t('order.deposit.orderNumber') }} {{ elem.item.raw.order.id }}</div>
				</div>
				<div v-if="elem.item.raw.source_type === WITHDRAW_TYPE" class="m9-data-table-align-left">
					<span>{{ typeName(elem.item.raw.source_type) }}</span>
					<div v-if="elem.item.raw.source != null" class="history-payment-table__bold">{{ $t('order.deposit.withdrawNumber') }} {{ elem.item.raw.source.id }}</div>
				</div>
				<div v-if="elem.item.raw.source_type === DEPOSIT_REQUEST" class="m9-data-table-align-left">{{ typeName(elem.item.raw.source_type) }}</div>
				<div v-if="elem.item.raw.responsible != null && elem.item.raw.source_type === MANUAL_TYPE" class="m9-data-table-align-left">{{ typeName(elem.item.raw.source_type) }}</div>
				<div v-if="elem.item.raw.responsible === null && elem.item.raw.source_type === MANUAL_TYPE" class="m9-data-table-align-left">{{ $t('order.deposit.publication') }}/{{ $t('order.deposit.article') }}</div>
				<div v-if="elem.item.raw.source != null && elem.item.raw.event_type === REFLINK_TYPE && elem.item.raw.source_type === ORDER_TYPE" class="m9-data-table-align-left">
					<div>{{ typeName(elem.item.raw.source_type) }}</div>
					<div class="history-payment-table__bold">{{ $t('order.deposit.orderNumber') }} {{ elem.item.raw.source.id }}</div>
				</div>
				<div v-if="elem.item.raw.source_type === TRANSFER_TYPE" class="m9-data-table-align-left">{{ $t('order.deposit.transfer') }}</div>
			</template>
			
			<template #item.provider="elem">
				<div v-if="elem.item.raw.source_type !== TRANSFER_TYPE" class="history-payment-table__provider">{{ elem.item.raw.provider }}</div>
				<div v-if="elem.item.raw.source_type === TRANSFER_TYPE" class="history-payment-table__provider">{{ checkTransferProvider(elem.item.raw.provider) }}</div>
			</template>
			
			<template #item.currency="elem">
				<div>{{ elem.item.raw.currency.currency_code }}</div>
			</template>
			
			<template #item.gross="elem">
				<div id="gross" v-if="elem.item.raw.event_type === INCOME || elem.item.raw.event_type === WITHDRAWAL_FAILED || elem.item.raw.source_type === DEPOSIT_REQUEST || elem.item.raw.event_type === INCOME_PENDING" class="history-payment-table__green m9-data-table-align-price">
					{{ (elem.item.raw.amount/ 100).toFixed(2) }} {{ userCurrencySymbol }}
					<div v-if="(elem.item.raw.source_type === ARTICLE_REQUEST || elem.item.raw.source_type === PUBLICATION_REQUEST) && elem.item.raw.order != null && !isPublisher(user)">/ {{ (elem.item.raw.order.gross / 100).toFixed(2) }} {{ userCurrencySymbol }}</div>
				</div>
				<div id="gross" v-if="((elem.item.raw.event_type === OUTCOME || elem.item.raw.event_type === OUTCOME_PENDING) && elem.item.raw.source_type !== DEPOSIT_REQUEST) || elem.item.raw.event_type === WITHDRAWAL_PENDING || elem.item.raw.event_type === WITHDRAWAL_COMPLETED" class="history-payment-table__red m9-data-table-align-price">
					{{ (elem.item.raw.amount/ 100).toFixed(2) }} {{ userCurrencySymbol }}
					<div v-if="(elem.item.raw.source_type === ARTICLE_REQUEST || elem.item.raw.source_type === PUBLICATION_REQUEST) && elem.item.raw.order != null && !isPublisher(user)">/ {{ (elem.item.raw.order.gross / 100).toFixed(2) }} {{ userCurrencySymbol }}</div>
				</div>
				<div id="gross" v-if="elem.item.raw.event_type === WITHDRAWAL_DECLINE && elem.item.raw.source_type === WITHDRAW_TYPE" class="history-payment-table__red m9-data-table-align-price">{{ (elem.item.raw.amount/ 100).toFixed(2) }} {{ userCurrencySymbol }}</div>
				<div id="gross" v-if="elem.item.raw.event_type === REFLINK_TYPE && elem.item.raw.source_type === ORDER_TYPE" class="history-payment-table__green m9-data-table-align-price">{{ (elem.item.raw.amount/ 100).toFixed(2) }} {{ userCurrencySymbol }}</div>
				<div id="gross" v-if="elem.item.raw.source_type === TRANSFER_TYPE" class="history-payment-table__green m9-data-table-align-price">{{ (elem.item.raw.amount/ 100).toFixed(2) }} {{ userCurrencySymbol }}</div>
			</template>
			
			<template #item.balance="elem">
				<div id="balance" v-if="elem.item.raw.balance_final >= 0 && (elem.item.raw.event_type === INCOME || elem.item.raw.event_type === WITHDRAWAL_FAILED || elem.item.raw.source_type === DEPOSIT_REQUEST || elem.item.raw.event_type === INCOME_PENDING || elem.item.raw.event_type === REFLINK_TYPE)" class="history-payment-table__green history-payment-table__bold m9-data-table-align-price">{{ (elem.item.raw.balance_final / 100).toFixed(2) }} {{ userCurrencySymbol }}</div>
				<div id="balance" v-if="elem.item.raw.balance_final >= 0 && (((elem.item.raw.event_type === OUTCOME || elem.item.raw.event_type === OUTCOME_PENDING) && elem.item.raw.source_type !== DEPOSIT_REQUEST) || elem.item.raw.event_type === WITHDRAWAL_PENDING || elem.item.raw.event_type === WITHDRAWAL_COMPLETED)" class="history-payment-table__red history-payment-table__bold m9-data-table-align-price">{{ (elem.item.raw.balance_final / 100).toFixed(2) }} {{ userCurrencySymbol }}</div>
				<div id="balance" v-if="elem.item.raw.balance_final >= 0 && elem.item.raw.event_type === WITHDRAWAL_DECLINE" class="history-payment-table__red history-payment-table__bold m9-data-table-align-price">{{ (elem.item.raw.balance_final / 100).toFixed(2) }} {{ userCurrencySymbol }}</div>
				<div id="balance" v-if="elem.item.raw.balance_final >= 0 && elem.item.raw.source_type === TRANSFER_TYPE" class="history-payment-table__green history-payment-table__bold m9-data-table-align-price">{{ (elem.item.raw.balance_final / 100).toFixed(2) }} {{ userCurrencySymbol }}</div>
			</template>
			
			<template #item.status="elem">
				<div v-if="elem.item.raw.payment != null && elem.item.raw.payment.status === PAID && elem.item.raw.event_type !== OUTCOME_PENDING && elem.item.raw.event_type !== INCOME_PENDING" class="history-payment-table__status history-payment-table__status--completed">{{ $t('order.deposit.paid') }}</div>
				<div v-if="elem.item.raw.payment == null && elem.item.raw.event_type === WITHDRAWAL_PENDING" class="history-payment-table__status history-payment-table__status--waiting">{{ $t('order.deposit.withdrawRequest') }}</div>
				<div v-if="elem.item.raw.payment == null && (elem.item.raw.event_type === WITHDRAWAL_COMPLETED || elem.item.raw.event_type === WITHDRAWAL_FAILED)" class="history-payment-table__status history-payment-table__status--completed">{{ $t('order.deposit.withdrawal') }}</div>
				<div v-if="elem.item.raw.payment == null && elem.item.raw.event_type === WITHDRAWAL_DECLINE && elem.item.raw.source_type === WITHDRAW_TYPE" class="history-payment-table__status history-payment-table__status--refund">{{ $t('order.deposit.withdrawalCancel') }}</div>
				<div v-if="elem.item.raw.provider === REFUND" class="history-payment-table__status history-payment-table__status--refund">{{ $t('order.deposit.refund') }}</div>
				<div v-if="elem.item.raw.responsible != null && elem.item.raw.payment == null && elem.item.raw.provider === MARKETIN9 && elem.item.raw.source_type === MANUAL_TYPE && elem.item.raw.event_type === OUTCOME" class="history-payment-table__status history-payment-table__status--refund">{{ $t('order.deposit.manual') }}</div>
				<div v-if="elem.item.raw.responsible != null && elem.item.raw.payment == null && elem.item.raw.provider === MARKETIN9 && elem.item.raw.source_type === MANUAL_TYPE && elem.item.raw.event_type === INCOME" class="history-payment-table__status history-payment-table__status--completed">{{ $t('order.deposit.manual') }}</div>
				<div v-if="elem.item.raw.responsible === null && elem.item.raw.payment == null && elem.item.raw.provider === MARKETIN9 && elem.item.raw.source_type === MANUAL_TYPE && elem.item.raw.event_type === INCOME" class="history-payment-table__status history-payment-table__status--completed">{{ $t('order.deposit.finished') }}</div>
				<div v-if="elem.item.raw.event_type === OUTCOME_PENDING || elem.item.raw.event_type === INCOME_PENDING" class="history-payment-table__status history-payment-table__status--waiting">{{ $t('order.deposit.processing') }}</div>
				<div v-if="elem.item.raw.event_type === REFUND && elem.item.raw.source_type === ORDER_TYPE" class="history-payment-table__status history-payment-table__status--completed">{{ $t('order.deposit.reflink') }}</div>
				<div v-if="elem.item.raw.provider === AGENCY" class="history-payment-table__status history-payment-table__status--agency">{{ $t('order.deposit.own') }}</div>
				<div v-if="elem.item.raw.source_type === TRANSFER_TYPE" class="history-payment-table__status history-payment-table__status--completed">{{ $t('order.deposit.transferStatus') }}</div>
				<div v-if="elem.item.raw.payment == null && elem.item.raw.event_type === null">-</div>
			</template>
			
			<template #item.invoice="elem">
				<div v-if="elem.item.raw.payment != null && elem.item.raw.payment.invoice != null && elem.item.raw.payment.invoice.uuid != null && ((isPublisher(user) && elem.item.raw.source_type === WITHDRAW_TYPE) || isAdvertiser(user) || isAdmin(user))">
					<MButton @click="getInvoice(elem.item.raw.payment.invoice.uuid)" class="history-payment-table__invoice" blue400 :label="$t('order.deposit.generateInvoice')" />
				</div>
				<div v-if="elem.item.raw.payment == null || elem.item.raw.payment.invoice == null">-</div>
			</template>
			
			<template #item.comment="elem">
				<div v-if="isAdmin(user)">
					<div v-if="elem.item.raw.source_type === MANUAL_TYPE">{{ elem.item.raw.message }}</div>
					<div v-if="elem.item.raw.source_type !== MANUAL_TYPE">-</div>
				</div>
			</template>
			
			<template #item.costBeforeOperation="elem">
				<div v-if="elem.item.raw.balance_before != null" id="before-operation" class="m9-data-table-align-price">{{ (elem.item.raw.balance_before / 100).toFixed(2) }} {{ userCurrencySymbol }}</div>
				<div v-if="elem.item.raw.balance_before === null">-</div>
			</template>
			
			<template #item.modifiedPerson="elem">
				<div v-if="isAdmin(user)">
					<div v-if="elem.item.raw.source != null && elem.item.raw.source.email != null && elem.item.raw.source_type !== ARTICLE_REQUEST">{{ elem.item.raw.source.email }}</div>
					<div v-if="elem.item.raw.user != null && elem.item.raw.responsible === null && elem.item.raw.source_type === MANUAL_TYPE && elem.item.raw.event_type === INCOME">{{ elem.item.raw.user.email }}</div>
					<div v-if="elem.item.raw.responsible !== null && elem.item.raw.source_type === MANUAL_TYPE">{{ elem.item.raw.responsible.email }}</div>
					<div v-if="elem.item.raw.event_type === WITHDRAWAL_COMPLETED || elem.item.raw.event_type === WITHDRAWAL_FAILED">{{ elem.item.raw.responsible.email }}</div>
					<div v-if="elem.item.raw.event_type === WITHDRAWAL_DECLINE && elem.item.raw.source_type === WITHDRAW_TYPE">{{ elem.item.raw.responsible.email }}</div>
					<div v-if="elem.item.raw.event_type === WITHDRAWAL_PENDING">{{ elem.item.raw.user.email }}</div>
					<div v-if="(elem.item.raw.source_type === PUBLICATION_REQUEST || elem.item.raw.source_type === ARTICLE_REQUEST) && elem.item.raw.event_type === OUTCOME">{{ null != elem.item.raw.user ? elem.item.raw.user.email : '-' }}</div>
					<div v-if="elem.item.raw.source_type === DEPOSIT_REQUEST && elem.item.raw.event_type !== OUTCOME_PENDING && elem.item.raw.event_type !== INCOME_PENDING">{{ elem.item.raw.user.email }}</div>
					<div v-if="elem.item.raw.responsible != null && elem.item.raw.provider === REFUND">{{ elem.item.raw.responsible.email || '-' }}</div>
					<div v-if="elem.item.raw.event_type === OUTCOME_PENDING || elem.item.raw.event_type === INCOME_PENDING">{{ elem.item.raw.user.email }}</div>
					<div v-if="elem.item.raw.event_type === REFLINK_TYPE && elem.item.raw.source_type === ORDER_TYPE">{{ elem.item.raw.responsible != null ? elem.item.raw.responsible.email : '-' }}</div>
					<div v-if="elem.item.raw.source_type === TRANSFER_TYPE">{{ elem.item.raw.responsible != null ? elem.item.raw.responsible.email : '-' }}</div>
				</div>
			</template>
			
			<template #item.accountOperation="elem">
				<div v-if="isAdmin(user) || user.is_agency">
					<div v-if="elem.item.raw.source != null && elem.item.raw.source.email != null && elem.item.raw.source_type !== ARTICLE_REQUEST">{{ elem.item.raw.source.email }}</div>
					<div v-if="elem.item.raw.user != null && elem.item.raw.responsible === null && elem.item.raw.source_type === MANUAL_TYPE && elem.item.raw.event_type === INCOME">{{ elem.item.raw.user.email }}</div>
					<div v-if="elem.item.raw.responsible !== null && elem.item.raw.source_type === MANUAL_TYPE">{{ elem.item.raw.user.email }}</div>
					<div v-if="elem.item.raw.event_type === WITHDRAWAL_COMPLETED || elem.item.raw.event_type === WITHDRAWAL_FAILED">{{ elem.item.raw.user.email }}</div>
					<div v-if="elem.item.raw.event_type === WITHDRAWAL_DECLINE && elem.item.raw.source_type === WITHDRAW_TYPE">{{ elem.item.raw.user.email }}</div>
					<div v-if="elem.item.raw.event_type === WITHDRAWAL_PENDING">{{ elem.item.raw.user.email }}</div>
					<div v-if="(elem.item.raw.source_type === PUBLICATION_REQUEST || elem.item.raw.source_type === ARTICLE_REQUEST) && elem.item.raw.event_type === OUTCOME">{{ elem.item.raw.user.email }}</div>
					<div v-if="elem.item.raw.source_type === DEPOSIT_REQUEST && elem.item.raw.event_type !== OUTCOME_PENDING && elem.item.raw.event_type !== INCOME_PENDING">{{ elem.item.raw.user.email }}</div>
					<div v-if="elem.item.raw.responsible != null && elem.item.raw.provider === REFUND">{{ elem.item.raw.user.email || '-' }}</div>
					<div v-if="elem.item.raw.event_type === OUTCOME_PENDING || elem.item.raw.event_type === INCOME_PENDING">{{ elem.item.raw.user.email }}</div>
					<div v-if="elem.item.raw.event_type === REFLINK_TYPE && elem.item.raw.source_type === ORDER_TYPE">{{ elem.item.raw.user != null ? elem.item.raw.user.email : '-' }}</div>
					<div v-if="elem.item.raw.source_type === TRANSFER_TYPE">{{ elem.item.raw.responsible != null ? elem.item.raw.responsible.email : '-' }}</div>
				</div>
			</template>
			
			<template #item.ip="elem">
				<div v-if="isAdmin(user)">
					<div v-if="elem.item.raw.ip !== '' && elem.item.raw.ip != null">{{ elem.item.raw.ip }}</div>
					<div v-if="elem.item.raw.ip === '' || elem.item.raw.ip === null">-</div>
				</div>
			</template>
		</v-data-table-server>
		
		<MLoading v-show="loadingModal" />
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { ref, watch, defineProps } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useSessionStore } from '@/stores/session';
import { useTableSettingsStore } from '@/stores/tableSettings';
import { dateFormatWithTime } from '@/hooks/DataHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import AgencyApi from '@/api/v1/AgencyApi';
import PaymentApi from '@/api/v1/PaymentApi';
import InvoiceApi from '@/api/v1/InvoiceApi';
import { clearTableSettings, editTableSettings, TRANSACTIONS_LIST } from '@/hooks/TableSettingsHooks';
import { ADVERTISER_ID, isAdmin, isAdvertiser, isPublisher, PUBLISHER_ID } from '@/hooks/UserHooks';
import { ROUTE_EDIT_PROFILE } from '@/hooks/RoutingHooks';
import {
	AGENCY,
	ARTICLE_REQUEST,
	DEPOSIT_REQUEST,
	INCOME,
	INCOME_PENDING,
	MANUAL_TYPE,
	MARKETIN9,
	ORDER_TYPE,
	OUTCOME,
	OUTCOME_PENDING,
	PAID,
	PUBLICATION_REQUEST,
	REFLINK_TYPE,
	REFUND,
	TRANSFER_TYPE,
	WITHDRAW_TYPE,
	WITHDRAWAL_COMPLETED,
	WITHDRAWAL_DECLINE,
	WITHDRAWAL_FAILED,
	WITHDRAWAL_PENDING
} from '@/hooks/OrderHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import type { TableSettingsInterface, TableSettingsRequestInterface } from '@/models/TableSettingsModel';
import type { TableHeaderInterface, ITableOptions } from '@/models/VuetifyModel';
import type { AgencyItemListInterface } from '@/models/AgencyModel';
import type { IPaymentHistoryItem } from '@/models/PaymentModel';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MButton from '@/components/atoms/MButton.vue';
import MLoading from '@/components/atoms/MLoading.vue';
import TableSettingsMenuWithModal from '@/components/organisms/TableSettingsMenuWithModal.vue';

interface IOwnProps {
	activeRoleId?: number|null
	userId?: number
	isAffiliateProgram?: boolean
}

interface IProps extends IOwnProps {
}

const agencyApi = new AgencyApi();
const paymentApi = new PaymentApi();
const invoiceApi = new InvoiceApi();

const props = defineProps<IProps>();
const { locale } = storeToRefs(useSessionStore());
const { user, userCurrencySymbol } = storeToRefs(useUserStore());
const { t } = useI18n();
const { setHeaders, setFilters, setPagination } = useTableSettingsStore();
const { green400 } = variables;

const loading = ref<boolean>(false);
const loadingModal = ref<boolean>(false);
const items = ref<Array<IPaymentHistoryItem>>([]);
const total = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
let headers = ref<Array<TableHeaderInterface>|any>([]);
let firstLoadPage:boolean = true;
let isClearAction:boolean = false;
let isFilterAction:boolean = false;
const isClearedTableSettingsAction = ref<boolean>(false);
const isEditProfilePage = ref<boolean>(false);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});

const localeLanguage = ref<string>('');

if ('us' === locale.value) {
	localeLanguage.value = 'en';
} else if (locale.value != null) {
	localeLanguage.value = locale.value;
} else {
	localeLanguage.value = 'pl';
}

const defaultHeaders = () => {
	const result = [
		{
			title: `${t('order.deposit.date')}`,
			align: 'center',
			sortable: false,
			key: 'date',
			width: '120px'
		},
	];
	
	result.push(...[
		{
			title: `${t('order.deposit.type')}`,
			align: 'center',
			sortable: false,
			key: 'type',
			width: '200px'
		},
		{
			title: `${t('order.deposit.provider')}`,
			align: 'center',
			sortable: false,
			key: 'provider',
			width: '70px'
		},
		{
			title: `${t('order.deposit.currency')}`,
			align: 'center',
			sortable: false,
			key: 'currency',
			width: '50px'
		},
	]);
	
	result.push(...[
		{
			title: `${t('profile.costBeforeOperation')}`,
			align: 'center',
			sortable: false,
			key: 'costBeforeOperation',
			width: '110px'
		},
		{
			title: `${t('order.deposit.gross')}`,
			align: 'center',
			sortable: false,
			key: 'gross',
			width: '110px'
		},
		{
			title: `${t('order.deposit.balance')}`,
			align: 'center',
			sortable: false,
			key: 'balance',
			width: '110px'
		},
		{
			title: `${t('order.deposit.status')}`,
			align: 'center',
			sortable: false,
			key: 'status',
			width: '130px'
		},
	]);

	if (user.value?.is_agency) {
		result.push(
			{
				title: `${t('profile.accountOperation')}`,
				align: 'center',
				sortable: false,
				key: 'accountOperation',
				width: '160px'
			},
		);
	}
	
	result.push(...[
		{
			title: `${t('order.deposit.invoice')}`,
			align: 'center',
			sortable: false,
			key: 'invoice',
			width: '150px'
		},
	]);
	
	return result;
};
headers.value = defaultHeaders();
const defaultInactiveHeaders = () => {
	const result:Array<TableHeaderInterface> = [];
	
	return result;
};

const dependentAccountUuid = ref<string|null>(null);
const dependentAccountList = ref<Array<AgencyItemListInterface>|any>([]);
const agencyDependentAccountList = async() => {
	if (null == user.value) {
		return;
	}
	try {
		const result = await agencyApi.agencyUserList(user.value.uuid);
		if (!result.isSuccess) {
			return;
		}
		dependentAccountList.value = result.payload.data;
		dependentAccountList.value.push(user.value);
	} catch (e) {
		return;
	}
};
if (user.value?.is_agency) {
	agencyDependentAccountList();
}

const historyPayment = async() => {
	loading.value = true;
	try {
		const result = await paymentApi.paymentHistoryList(
			options.value.page,
			options.value.itemsPerPage,
			!isEditProfilePage.value ? user.value?.active_role_id : props.activeRoleId,
			!isEditProfilePage.value ? dependentAccountUuid.value : props.userId,
			props.isAffiliateProgram,
		);
		if (!result.isSuccess) {
			loading.value = false;
			return;
		}
		total.value = result.payload.total;
		currentPage.value = result.payload.current_page;
		lastPage.value = result.payload.last_page;
		items.value = result.payload.data;
	} catch (e) {
		loading.value = false;
		return;
	}
	loading.value = false;
};

if (router.currentRoute.value.name === ROUTE_EDIT_PROFILE) {
	isEditProfilePage.value = true;
}
if (props.activeRoleId == null || props.activeRoleId != null) {
	historyPayment();
}
watch((props), () => {
	historyPayment();
}, {
	deep: true,
});

const historyPaymentTableSettings = ref<TableSettingsInterface|null>(null);
const initTableOptions = async() => {
	let isShouldBeSaved = false;
	historyPaymentTableSettings.value = storeToRefs(useTableSettingsStore())[TRANSACTIONS_LIST].value;
	
	// Headers options
	if (null == historyPaymentTableSettings.value || 0 === historyPaymentTableSettings.value.columns.active.length || historyPaymentTableSettings.value.filters.locale !== localeLanguage.value) {
		isClearedTableSettingsAction.value = true;
		await setHeaders({
			name: TRANSACTIONS_LIST,
			headers: {
				active: defaultHeaders(),
				inactive: defaultInactiveHeaders(),
			}
		});
		isShouldBeSaved = true;
	}
	
	// Pagination options
	if (null == historyPaymentTableSettings.value || null == historyPaymentTableSettings.value.page || null == historyPaymentTableSettings.value.per_page) {
		await setPagination({
			name: TRANSACTIONS_LIST,
			page: 1,
			perPage: 10
		});
		isShouldBeSaved = true;
	}
	
	// Filters options
	// if (null != historyPaymentTableSettings.value.filters.agencyAccount) {
	// 	dependentAccountUuid.value = historyPaymentTableSettings.value.filters.agencyAccount;
	// }
	
	if (null != historyPaymentTableSettings.value && historyPaymentTableSettings.value.filters.locale !== localeLanguage.value) {
		const filters = {
			agencyAccount: dependentAccountUuid.value,
			locale: localeLanguage.value,
		};
		await setFilters({
			name: TRANSACTIONS_LIST,
			filters: filters
		});
	}
	
	if (null != historyPaymentTableSettings.value) {
		options.value.page = historyPaymentTableSettings.value.page;
		options.value.itemsPerPage = historyPaymentTableSettings.value.per_page;
		
		headers.value = historyPaymentTableSettings.value.columns.active;
	}
	
	if (isShouldBeSaved && !isEditProfilePage.value) {
		await editTableSettingsAction();
	}
	if (!isEditProfilePage.value) {
		await historyPayment();
	}
	isClearedTableSettingsAction.value = false;
};
initTableOptions();

const editTableSettingsAction = () => {
	if (null != user.value && null != historyPaymentTableSettings.value) {
		const tableSettings:TableSettingsRequestInterface = {
			sort_column: null,
			sort_order: null,
			page: historyPaymentTableSettings.value.page,
			per_page: historyPaymentTableSettings.value.per_page,
			filters: JSON.stringify(historyPaymentTableSettings.value.filters),
			columns: JSON.stringify(historyPaymentTableSettings.value.columns),
		};
		if (!isEditProfilePage.value) {
			editTableSettings(TRANSACTIONS_LIST, tableSettings, user.value?.active_role_id);
		}
	}
};

const clearTableSettingsAction = async() => {
	isClearAction = true;
	
	dependentAccountUuid.value = null;
	
	options.value.page = 1;
	options.value.itemsPerPage = 10;
	
	headers.value = defaultHeaders();
	
	const filters = {
		agencyAccount: dependentAccountUuid.value,
		locale: localeLanguage.value,
	};
	
	const columns = {
		active: defaultHeaders(),
		inactive: defaultInactiveHeaders(),
	};
	
	const tableSettings:TableSettingsRequestInterface = {
		sort_column: null,
		sort_order: null,
		page: 1,
		per_page: 10,
		filters: JSON.stringify(filters),
		columns: JSON.stringify(columns),
	}
	if (null != user.value) {
		await clearTableSettings(TRANSACTIONS_LIST, tableSettings, user.value?.active_role_id, `${t('components.toast.errorClearTableSettings')}`, `${t('components.toast.successClearTableSettings')}`);
	}
	isClearedTableSettingsAction.value = true;
	
	setTimeout(() => {
		isClearAction = false;
		isClearedTableSettingsAction.value = false;
		historyPayment();
	},1);
};

const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	if (!firstLoadPage && !isClearAction) {
		if (!isEditProfilePage.value) {
			await setPagination({
				name: TRANSACTIONS_LIST,
				page: val.page,
				perPage: val.itemsPerPage,
			});
		}
		if (!isFilterAction) {
			await editTableSettingsAction();
			await historyPayment();
		}
	}
	isFilterAction = false;
	firstLoadPage = false;
};

watch(([dependentAccountUuid]), async (newVal, oldVal) => {
	if (!isClearAction) {
		if (options.value.page > 1) {
			options.value.page = 1;
			await setPagination({
				name: TRANSACTIONS_LIST,
				page: 1,
				perPage: options.value.itemsPerPage,
			});
			isFilterAction = true;
		}
		if (newVal[0] !== oldVal[0]) {
			const filters = {
				agencyAccount: newVal[0],
				locale: localeLanguage.value
			};
			await setFilters({
				name: TRANSACTIONS_LIST,
				filters: filters
			});
			await editTableSettingsAction();
			await historyPayment();
		}
	}
},
{
	deep: true,
});

const headersMoveAction = () => {
	if (null != historyPaymentTableSettings.value) {
		headers.value = historyPaymentTableSettings.value.columns.active;
	}
};

const typeName = (type:string) => {
	switch (type) {
		case ARTICLE_REQUEST:
			return `${t('order.deposit.article')}`;
		case PUBLICATION_REQUEST:
			return `${t('order.deposit.publication')}`;
		case DEPOSIT_REQUEST:
			return `${t('order.deposit.deposit')}`;
		case WITHDRAW_TYPE:
			return `${t('order.deposit.withdraw')}`;
		case MANUAL_TYPE:
			return `${t('order.deposit.manual')}`;
		case ORDER_TYPE:
			return `${t('order.deposit.reflink')}`;
		default:
			return '-'
	}
};

const getInvoice = async(orderUuid:string) => {
	loadingModal.value = true;
	try {
		const result = await invoiceApi.getInvoice(orderUuid);
		if (!result.isSuccess) {
			loadingModal.value = false;
			return;
		}
		const binaryString = window.atob(result.payload);
		const binaryLen = binaryString.length;
		const bytes = new Uint8Array(binaryLen);
		for (let i = 0; i < binaryLen; i++) {
			const ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		const blob = new Blob([bytes], { type: 'application/pdf' });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = 'invoice.pdf';
		link.click();
	} catch (e) {
		loadingModal.value = false;
		return;
	}
	loadingModal.value = false;
};

const checkTransferProvider = (provider:string) => {
	const providerId = provider.split('To: ')[1];
	const currentAccount = user.value?.accounts.find(elem => elem.id === +providerId);
	if (null != currentAccount) {
		return `${t('order.deposit.fromAffiliate')} ${t('order.deposit.to')} ${t(`order.deposit.role${currentAccount.role_id}`)}`;
	}
	return provider;
};
</script>

<style scoped lang="scss">
.history-payment-table {
	@import '@/assets/scss/vuetifyDataTable';
	
	&__loading {
		opacity: 0.5;
	}
	
	&__top-wrapper {
		display: flex;
		flex-direction: column;
		padding: 20px;
	}
	
	&__filter-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}
	
	&__user-wrapper {
		width: 300px;
	}
	
	&__status {
		color: $white-100;
		font-weight: $weight-700;
		font-size: $size-13;
		padding: 4px 15px;
		width: max-content;
		margin: 0 auto;
		border-radius: 25px;
		
		&--completed {
			background-color: $green-400;
		}
		
		&--verification {
			background-color: $grey-400;
		}
		
		&--waiting {
			background-color: $yellow-500;
		}
		
		&--refund {
			background-color: $secondary-400;
		}
		
		&--agency {
			background-color: $primary-400;
		}
	}
	
	&__green {
		color: $green-400;
	}
	
	&__red {
		color: $secondary-400;
	}
	
	&__bold {
		font-weight: $weight-700;
	}
	
	&__invoice {
		color: $white-100 !important;
		background-color: $primary-400;
		padding: 8px 10px;
		font-size: $size-11 !important;
		font-weight: $weight-700;
		border-radius: 30px !important;
		text-decoration: none;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		&:active {
			opacity: 0.2;
		}
	}
	
	&__actions {
		background-color: $grey-400;
		padding: 10px;
		border-radius: 50%;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__provider {
		text-transform: capitalize;
	}
	
	&__table-settings-wrapper {
		margin-left: auto;
	}
	
	&__dashboard-wrapper {
		padding: 25px 15px 13px;
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}
	
	&__wallet-wrapper {
		display: flex;
		align-items: center;
		gap: 10px;
		background-color: $primary-400;
		padding: 8px 15px;
		border-radius: 10px;
		color: $green-400;
		font-weight: 700;
		font-size: 16px;
		
		&--add-dolar {
			padding: 8px 7px 8px 10px;
			cursor: pointer;
			transition: background-color 0.3s;
			
			&:hover {
				background-color: $secondary-400;
				color: $white-100;
			}
		}
	}
	
	&__history-payment-wrapper {
		display: flex;
		align-items: center;
		gap: 10px;
		background-color: $grey-250;
		padding: 8px 15px;
		border-radius: 10px;
		color: $primary-400;
		font-weight: 600;
		font-size: 16px;
		cursor: pointer;
		transition: background-color 0.3s;
		
		&:hover {
			background-color: $grey-150;
		}
		
		&:deep(.m-tooltip) {
			display: flex;
			gap: 10px;
			align-items: center;
			cursor: no-drop;
		}
	}
	
	&:deep(.v-input__details) {
		display: none;
	}
	
	&:deep(.v-data-table-footer .v-field__field) {
		height: 40px;
	}
	
	&:deep(.v-data-table-footer .v-field__input) {
		font-size: 12px;
		padding: 11px 0 0 10px;
	}
	
	&:deep(.v-data-table-footer .v-input) {
		height: 40px;
	}
}
</style>
