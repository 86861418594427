import BaseApi from '@/api/BaseApi';
import type { ICampaignFooterForm, IMonolithCampaignFooter, IMonolithCampaignsList } from '@/models/CampaignModel';

export default class MonolithCampaignsApi extends BaseApi {
	async campaignList (page:number, perPage:number, sortBy?: string|null, sortDesc?: string|null, search?:string|null, userId?:number|null) {
		let url = `/api/campaigns?api_token=${localStorage.getItem('apiToken')}&page=${page}&per_page=${perPage}`;

		if (null != sortBy) {
			url += `&order=${sortBy}`;
		} else {
			url += '&order=updated_at';
		}

		if (null != sortDesc) {
			url += `&orderDirection=${sortDesc}`;
		} else {
			url += '&orderDirection=desc';
		}

		if (null != search) {
			url += `&search=${search}`;
		}

		if (null != userId) {
			url += `&user_id=${userId}`;
		}
		return this.get<IMonolithCampaignsList>({
			url: url,
			config: {
				useMonolithApi: true,
			},
		});
	};

	async createCampaignFooter (campaignId:number|null, params:ICampaignFooterForm|any) {
		const data = new FormData();
		if (null != params.file) {
			data.append('image', params.file);
		}
		data.append('company_name', params.name);
		data.append('contact_data', params.url);
		data.append('content', params.description);
		return this.post<any, any>({
			url: `/api/campaigns/${campaignId}/footers?api_token=${localStorage.getItem('apiToken')}`,
			data,
			config: {
				useMonolithApi: true,
				contentType: 'multipart/form-data',
			},
		});
	};

	async editCampaignFooter (campaignId:number|null, footerId:number|null, params:ICampaignFooterForm|any) {
		const data = new FormData();
		if (null != params.file) {
			data.append('image', params.file);
			data.append('image_edit', params.file);
		}
		data.append('company_name', params.name);
		data.append('contact_data', params.url);
		data.append('content', params.description);
		return this.post<any, any>({
			url: `/api/campaigns/${campaignId}/footers/${footerId}?api_token=${localStorage.getItem('apiToken')}`,
			data,
			config: {
				useMonolithApi: true,
				contentType: 'multipart/form-data',
			},
		});
	};

	async campaignFooter (campaignId:number|null, footerId:number|null) {
		return this.get<IMonolithCampaignFooter>({
			url: `/api/campaigns/${campaignId}/footers/${footerId}?api_token=${localStorage.getItem('apiToken')}`,
			config: {
				useMonolithApi: true,
			},
		});
	};

	async deleteFooterImage (imageId:number|null) {
		return this.delete({
			url: `/api/images/${imageId}?api_token=${localStorage.getItem('apiToken')}`,
			config: {
				useMonolithApi: true,
			},
		});
	};

	async deleteCampaignFooter (campaignId:number|null, footerId:number|null) {
		return this.delete({
			url: `/api/campaigns/${campaignId}/footers/${footerId}?api_token=${localStorage.getItem('apiToken')}`,
			config: {
				useMonolithApi: true,
			},
		});
	};
};
