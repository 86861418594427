<template>
	<div class="rules">
		<BeforeLoginLayout>
			<template v-slot:default>
				<div>
					<img src="~@/assets/images/logo-reverse.png" alt="marketing logo" class="rules__logo">
					<div class="rules__title">{{ $t('static.rules.rules') }}</div>
					<div class="rules__language-wrapper">
						<div class="footer__language-label">{{ $t('auth.chooseLanguage') }}</div>
						<div class="rules__language-list">
							<MMenu offset="0 0">
								<template v-slot:menuActivator>
									<div class="rules__language-list">
										<div class="rules__flag">
											<MFlag :country-code="localeLanguage" />
										</div>
										<MIcon class="rules__arrow" icon="arrow-down" />
									</div>
								</template>
								<template v-slot:menuItems>
									<MButton @click="switchLanguage('pl')">
										<MFlag country-code="pl" />
									</MButton>
									<MButton @click="switchLanguage('en')">
										<MFlag country-code="gb" />
									</MButton>
								</template>
							</MMenu>
						</div>
					</div>
					<div v-if="locale === 'pl'" class="rules__text-wrapper">
						<div id="advertiser-pl" class="rules__subtitle">Regulamin dla Użytkowników platformy Marketin9</div>
						<div>
							<div>POSTANOWIENIA OGÓLNE</div>
							<div class="rules__list-item">1. Niniejszy regulamin („Regulamin”) określa warunki zlecania usług i ich realizacji poprzez platformę MARKETIN9, znajdującą się pod adresem https://www.dashboard.marketin9.com/ (dalej: PLATFORMA).</div>
							<div class="rules__list-item">2. Platforma MARKETIN9, świadczy USŁUGI w zakresie Content Marketingu, Link Buildingu oraz Copywritingu.</div>
							<div class="rules__list-item">3. Usługi świadczone są przez spółkę ORION MEDIA GROUP SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ SPÓŁKA KOMANDYTOWA z siedzibą w Warszawie przy ul. Niekłańska 35 lok. 3, 03-924 WARSZAWA, wpisaną do rejestru przedsiębiorców KRS prowadzonego przez SĄD REJONOWY DLA M. ST. WARSZAWY W WARSZAWIE, XIII WYDZIAŁ GOSPODARCZY KRAJOWEGO REJESTRU SĄDOWEGO, pod numerem KRS 0000480304, NIP: 1132869511, REGON: 146909237, zwaną dalej USŁUGODAWCĄ.</div>
							<div class="rules__list-item">4. Szczegółowe informacje o usługach znajdują się na stronie internetowej platformy <a href="https://www.marketin9.com/"> https://www.marketin9.com/</a></div>
							<div class="rules__list-item">5. W celu skorzystania z Usług należy zapoznać się z niniejszym Regulaminem oraz Polityką Prywatności.</div>
							<div class="rules__list-item">6. Każda osoba fizyczna lub prawna, która zarejestrowała się na Platformie, zwana jest dalej UŻYTKOWNIKIEM.</div>
							<div class="rules__list-item">7. Użytkownicy mogą kontaktować się z Usługodawcą drogą elektroniczną na adres e-mail: help@marketin9.com.</div>
							<div class="rules__list-item">8. Użytkownik Platformy, będący osobą fizyczną, (posiadającą pełną zdolność do czynności prawnych) lub prawną, który zleca USŁUGI przez platformę MARKETIN9, zwany jest dalej REKLAMODAWCĄ.</div>
							<div class="rules__list-item">9. Przez WYDAWCĘ należy rozumieć Użytkownika Platformy zamieszczonego przez USŁUGODAWCĘ w bazie Wydawców i prezentującego za pomocą PLATFORMY swoją OFERTĘ.</div>
							<div class="rules__list-item">10. USŁUGI świadczone są elektroniczne na podstawie umów zawieranych na odległość między Użytkownikami, a Usługodawcą.</div>
							<div class="rules__list-item">11. Usługodawca nie jest stroną umów zawieranych pomiędzy Wydawcą i Reklamodawcą, chyba, że w danej transakcji występuje w roli Wydawcy.</div>
							<div class="rules__list-item">12. W niniejszym Regulaminie przez dni robocze rozumie się każdy dzień inny niż sobota, niedziela i inne dni ustawowo wolne od pracy przewidziane w polskim prawie. Jeżeli w Regulaminie mowa o „dniach”, należy przez to rozumieć dni kalendarzowe.</div>
							<div class="rules__list-item">13. Postanowienia innych dokumentów dostępnych na Platformie, o których mowa w niniejszym Regulaminie, stanowią integralną część Regulaminu. W przypadku sprzeczności postanowień takich dokumentów z Regulaminem, Użytkownik Platformy związany jest postanowieniami Regulaminu.</div>
						</div>
						<div>
							<div>KORZYSTANIE Z PLATFORMY MARKETIN9</div>
							<div class="rules__list-item">1. Dostęp do Platformy mają tylko zarejestrowani Użytkownicy.</div>
							<div class="rules__list-item">2. Dostęp do Platformy jest bezpłatny jednak Usługi świadczone są odpłatnie.</div>
							<div class="rules__list-item">
								<div>3. Świadczone Usługi płatne polegają na pośredniczeniu między Wydawcami a Reklamodawcami (Stronami) w zlecaniu następujących Materiałów Reklamowych:</div>
								<div class="rules__list-item">a. artykułów sponsorowanych,</div>
								<div class="rules__list-item">b. artykułów z linkami,</div>
								<div class="rules__list-item">c. linków w istniejących artykułach,</div>
								<div class="rules__list-item">d. reklam typu display np. bannery, filmy reklamowe,</div>
								<div class="rules__list-item">e. wysyłek mailingowych.</div>
							</div>
							<div class="rules__list-item">4. Zarejestrowany Użytkownik posiada indywidualne Konto Użytkownika na platformie MARKETIN9, umożliwiające korzystanie z Usług świadczonych poprzez Platformę oraz składanie Zamówień i ich realizację.</div>
							<div class="rules__list-item">5. Oferty zamieszczone na Platformie widoczne są wyłącznie dla zalogowanych Użytkowników. Użytkownicy zobowiązani są do zachowania w poufności treści Ofert, danych Wydawców oraz cen oferowanych przez Wydawców.</div>
							<div class="rules__list-item">6. Przed zawarciem umowy Strony zobowiązane są do potwierdzenia poprawności złożonego Zamówienia oraz jego akceptacji za pomocą funkcjonalności dostępnych na Platformie.</div>
							<div class="rules__list-item">7. Potwierdzenie zawarcia umowy oraz odpowiednie jej postanowienia (szczegóły Zamówienia) przekazywane są Stronom za pomocą wiadomości e-mail wysłanej za pośrednictwem funkcjonalności Serwisu.</div>
							<div class="rules__list-item">8. Platforma udostępnia zawartość oferty w języku polskim i angielskim.</div>
							<div class="rules__list-item">9. Usługodawca zastrzega, że Wydawca ma prawo do rezygnacji z realizacji zamówienia złożonego przez Reklamodawcę.</div>
							<div class="rules__list-item">10. USŁUGODAWCA prowadzi dla UŻYTKOWNIKA indywidualny portfel rozliczeniowy on-line w walucie wybranej przez Użytkownika spośród walut: polski złoty, dolar amerykański, funt brytyjski i euro, dostępny po zalogowaniu Użytkownika do Platformy (saldo Użytkownika). Rachunek służy tylko i wyłącznie do dokonywania rozliczeń pomiędzy Użytkownikiem, a Usługodawcą i nie jest w żaden sposób powiązany z bankowością elektroniczną.</div>
							<div class="rules__list-item">11. Żadna z zawartych w Regulaminie umów nie może być zawarta w sposób dorozumiany.</div>
						</div>
						<div>
							<div>ZAMIESZCZANIE OFERT</div>
							<div class="rules__list-item">1. Wydawca oświadcza, iż dysponuje pełnią praw do strony internetowej lub posiada pełnomocnictwo do działania w imieniu właściciela strony internetowej, którą dodaje do swojej OFERTY.</div>
							<div class="rules__list-item">2. OFERTA dodana do Bazy Ofert prezentowana będzie w formie oferowanej przez Usługodawcę i Wydawca wyraża na to zgodę, dodając Ofertę do Platformy.</div>
							<div class="rules__list-item">3. Umieszczenie Oferty na Platformie nie jest gwarancją otrzymania zamówienia od Reklamodawcy.</div>
							<div class="rules__list-item">4. Oferta dodana przez Wydawcę jest bezterminowa. Wydawca zobowiązuje się do przestrzegania warunków Oferty do momentu jej modyfikacji lub usunięcia z Bazy Ofert.</div>
							<div class="rules__list-item">5. Wydawca ma prawo do modyfikacji swoich Ofert. Usługodawca ma prawo te zmiany zaakceptować, zmodyfikować lub odrzucić ze wskazaniem powodu. Wydawca ma prawo przesyłać Ofertę do ponownej akceptacji dowolną liczbę razy.</div>
							<div class="rules__list-item">6. Do czasu zaakceptowania przez Usługodawcę zmodyfikowanej Oferty, nie jest ona dostępna w Bazie Ofert.</div>
							<div class="rules__list-item">7. Usługodawca zastrzega sobie prawo do usunięcia Oferty z Platformy zarówno ze wskazaniem przyczyny jej usunięcia jak i bez jej wskazania. Wydawcy nie przysługuje z tego tytułu żadne roszczenie.</div>
							<div class="rules__list-item">8. Cena dla Reklamodawcy nie jest równoznaczna z Wynagrodzeniem Wydawcy z tytułu danej Oferty. Ceny dla Reklamodawców ustalane są przez Usługodawcę, który ma pełną dowolność, co do ich wysokości.</div>
							<div class="rules__list-item">9. Zabronione jest zamieszczanie w Ofertach jakichkolwiek informacji, które miałyby na celu zachęcanie do bezpośredniego kontaktu Użytkowników poza Platformą, takich jak: adres e-mail, numer telefonu, link do formularza kontaktowego itp.</div>
						</div>
						<div>
							<div>ODPOWIEDZIALNOŚĆ WYDAWCY</div>
							<div class="rules__list-item">
								<div>1. Wydawca ponosi pełną odpowiedzialność za zamieszczane przez niego na Platformie treści oraz zamieszczając treści na Platformie oświadcza, że:</div>
								<div class="rules__list-item">a. Ma pełne prawo do dysponowania treścią zamieszczoną na Platformie oraz posiada autorskie prawa majątkowe do niej, które nie są ograniczone na rzecz żadnej osoby trzeciej.</div>
								<div class="rules__list-item">b. Posiada wszelkie niezbędne zgody do publikowania i rozpowszechniania zdjęć, filmów i wszelkich innych materiałów, które publikuje.</div>
								<div class="rules__list-item">c. Informacja zawarta w zamieszczonej treści jest zgodna z prawdą i nie ma na celu wprowadzenia nikogo w błąd.</div>
							</div>
							<div class="rules__list-item">2. Wydawca umieszczając na Platformie treści stanowiące utwór w rozumieniu prawa autorskiego udziela Usługodawcy niewyłącznej, nieodwołalnej, nieodpłatnej, nieograniczonej czasowo i terytorialnie licencji oraz wyraża zgodę na udzielanie dalszych licencji.</div>
							<div class="rules__list-item">
								<div>3. Wydawca udziela licencji na korzystanie z treści na następujących polach eksploatacji:</div>
								<div class="rules__list-item">a. utrwalanie i zwielokrotnianie treści – wytwarzanie określoną techniką egzemplarzy utworu, w tym techniką cyfrową,</div>
								<div class="rules__list-item">b. wprowadzanie do obrotu oryginału albo egzemplarzy,</div>
								<div class="rules__list-item">c. publiczne wykonanie, wystawienie, wyświetlenie, odtworzenie oraz nadawanie i reemitowanie, a także publiczne udostępnianie utworu w taki sposób, aby każdy mógł mieć do niego dostęp w miejscu i w czasie przez siebie wybranym,</div>
								<div class="rules__list-item">d. rozpowszechnianie w Internecie, na Platformie, na portalach społecznościowych, w banerach na innych portalach, w newsletterze,</div>
								<div class="rules__list-item">e. udostępniania innym podmiotom do celów określonych w punktach powyższych.</div>
							</div>
							<div class="rules__list-item">4. Całkowicie zabronione jest i stanowi naruszenie Regulaminu, zamieszczanie na Platformie treści zawierających zapisy sprzeczne z prawem lub dobrymi obyczajami, a w szczególności treści pornograficznych, wulgarnych, zniesławiających, obrażających uczucia religijne, wzywających do nienawiści rasowej, etnicznej, wyznaniowej, propagujących piractwo fonograficzne, komputerowe, upowszechniających techniki łamania danych, wirusy oraz wszelkich innych, podobnych w treści i działaniu materiałów.</div>
							<div class="rules__list-item">5. Usługodawcy przysługuje prawo do edycji i usuwania treści zamieszczonych przez Wydawców w zakresie, w którym zawiera ona treści niezgodne z prawem. Wszystkie treści niezgodne z prawem zostaną przez Usługodawcę usunięte w terminie do 3 dni roboczych od otrzymania zawiadomienia o ich istnieniu.</div>
							<div class="rules__list-item">6. Konto Wydawcy, który nagminnie zamieszcza treści niezgodne z prawem bądź treści naruszające dobra osobiste innych osób może zostać zablokowane lub usunięte przez Usługodawcę.</div>
							<div class="rules__list-item">7. Usługodawca ma prawo do wykorzystania loga, pseudonimu, nazwy kont społecznościowych, firmy oraz adresów stron internetowych Wydawców w celu umieszczenia na Platformie, na Stronie internetowej oraz w materiałach promocyjnych z informacją, iż jest Wydawcą i korzysta z Platformy.</div>
						</div>
						<div>
							<div>ZLECANIE USŁUG PRZEZ PLATFORMĘ MARKETIN9</div>
							<div class="rules__list-item">1. Reklamodawca zobowiązany jest dostarczyć Wydawcy Materiały przygotowane zgodnie z wymaganiami technicznymi i merytorycznymi wskazanymi przez Wydawcę. Poprzez opublikowanie dostarczonego materiału Wydawca jednocześnie oświadcza iż akceptuje jego kwestie techniczne i merytoryczne i nie wnosi z tego tytułu żadnych zastrzeżeń względem Reklamodawcy.</div>
							<div class="rules__list-item">2. Za naruszenie prawa, w tym praw osób trzecich, praw własności intelektualnej i dóbr osobistych wskutek opublikowania treści przekazanych Wydawcy przez Reklamodawcę, odpowiada Reklamodawca. Jeśli Wydawca otrzyma informację o zaistnieniu któregokolwiek z ww. praw, zobowiązany jest do czasowego lub stałego zaniechania emisji przekazanych przez Reklamodawcę treści. Z tytułu powyższego zaniechania Reklamodawcy nie przysługują żadne roszczenia wobec Wydawcy.</div>
							<div class="rules__list-item">
								<div>3. Emisja zleconego Wydawcy Materiału powinna nastąpić:</div>
								<div class="rules__list-item">a. Nie później niż w ciągu 10 dni roboczych od poinformowania Wydawcy o oczekującym w Platformie zleceniu do realizacji, które następujące poprzez komunikat e-mailowy oraz poprzez prezentację zlecenia na liście zleceń w Platformie. Terminu tego nie stosuje się gdy Reklamodawca w wytycznych dla Wydawcy jasno wskazał inny termin publikacji. W takiej sytuacji wiążący jest termin wskazany przez Reklamodawcę.</div>
								<div class="rules__list-item">b. Na stronie internetowej wybranej spośród Ofert Wydawcy przez Reklamodawcę.</div>
								<div class="rules__list-item">c. Na domenie internetowej wskazanej w Ofercie, z zastrzeżeniem iż jeśli w Ofercie podana jest domena główna strony internetowej, to zabroniona jest publikacja na jakiejkolwiek subdomenie.</div>
								<div class="rules__list-item">d. W formie i z wszelkimi parametrami wskazanymi na Platformie przez Reklamodawcę.</div>
								<div class="rules__list-item">e. Zachowując wszelkie aspekty techniczne, merytoryczne i handlowe wskazane w Ofercie.</div>
							</div>
							<div class="rules__list-item">4. Przez poprawną emisję Materiałów rozumie się taką, gdzie Reklamodawca nie wniósł żadnych reklamacji (poprawek) w ciągu 3 dni roboczych od ich emisji.</div>
							<div class="rules__list-item">5. Reklamodawca ma prawo zgłosić poprawki w ciągu 3 dni roboczych od dnia emisji Materiałów. W przypadku zgłoszenia poprawek przez Reklamodawcę, Wydawca nanosi poprawki w przeciągu 2 dni roboczych od ich zgłoszenia. Usługodawca nie zastrzega żadnej maksymalnej liczby dozwolonych poprawek zgłaszanych przez Reklamodawcę. Wydawca ma prawo zgłosić dowolną ilość poprawek Reklamodawcy do mediacji wartości Zamówienia u Usługodawcy.</div>
							<div class="rules__list-item">6. W przypadku, gdy Wydawca nie wprowadzi poprawek Reklamodawcy - Wydawcy nie przysługuje wynagrodzenie z tytułu realizacji Zamówienia.</div>
							<div class="rules__list-item">7. Wydawca uprawniony jest do otrzymania wynagrodzenia w terminie 5 dni roboczych od akceptacji realizacji Zamówienia przez Reklamodawcę.</div>
							<div class="rules__list-item">8. Otrzymanie Wynagrodzenia przez Wydawcę z tytułu zrealizowanych Zamówień następuje poprzez zwiększenie wartości Rachunku (salda) Wydawcy na Platformie.</div>
						</div>
						<div>
							<div>MATERIAŁY REKLAMOWE I ICH EMISJA - ARTYKUŁY SPONSOROWANE I ARTYKUŁY Z LINKAMI</div>
							<div class="rules__list-item">1. Reklamodawca zobowiązany jest dostarczyć Wydawcy komplet Materiałów do emisji m.in. treści do artykułów, zdjęcia, filmy, linki, bannery reklamowe, inne formy graficzne. Reklamodawca oświadcza, iż posiada pełne prawa do dysponowania Materiałami, które przekazuje Wydawcy za pośrednictwem Platformy oraz posiada autorskie prawa majątkowe do nich, które nie są ograniczone na rzecz żadnej osoby trzeciej.</div>
							<div class="rules__list-item">2. Wydawca publikuje Materiały we wskazanym przez Reklamodawcę miejscu na stronie internetowej wybranej z Oferty Wydawcy.</div>
							<div class="rules__list-item">3. Termin emisji Materiału każdorazowo wskazany jest w Zamówieniu. W przypadku jego braku, rozpoczynając od dnia zamieszczenia materiału na stronie internetowej określonej w Ofercie, emisja Materiału powinna trwać bezterminowo - dopóki strona internetowa Wydawcy dostępna będzie w sieci Internet. Wydawca ma prawo wskazać termin zakończenia emisji materiału, jednak nie może on być krótszy niż 12 miesięcy.</div>
							<div class="rules__list-item">4. Prawidłowe wykonanie Zamówienia stanowi opublikowanie Materiału Reklamodawcy oraz podanie bezpośredniego linku do ww. publikacji w odpowiednim miejscu na Platformie.</div>
							<div class="rules__list-item">5. Po akceptacji materiału przez Reklamodawcę i jego publikacji, zabrania się Wydawcy jakiejkolwiek edycji materiału, a w szczególności zamieszczania w nim dodatkowych linków wychodzących bez zgody Reklamodawcy.</div>
						</div>
						<div>
							<div>MATERIAŁY REKLAMOWE I ICH EMISJA - LINKI W ISTNIEJĄCYCH ARTYKUŁACH</div>
							<div class="rules__list-item">1. Wydawca publikuje link we wskazanym przez Reklamodawcę artykule istniejącym na stronie internetowej wybranej z Oferty Wydawcy.</div>
							<div class="rules__list-item">2. Wydawca oświadcza, że ma pełne prawo do edytowania wskazanego przez Reklamodawcę artykułu na stronie internetowej określonej w Ofercie Wydawcy i tym samym nie naruszy jakichkolwiek praw osób trzecich.</div>
							<div class="rules__list-item">3. Rozpoczynając od dnia zamieszczenia linku na stronie internetowej określonej w Ofercie, emisja linku powinna trwać bezterminowo - dopóki strona internetowa Wydawcy dostępna będzie w sieci Internet. Wydawca ma prawo wskazać termin zakończenia emisji, jednak nie może on być krótszy niż 12 miesięcy.</div>
							<div class="rules__list-item">4. Prawidłowe wykonanie Zamówienia stanowi opublikowanie linku Reklamodawcy oraz podanie bezpośredniego linku do artykułu, w którym został on opublikowany w odpowiednim miejscu na Platformie.</div>
						</div>
						<div>
							<div>ROZLICZENIA</div>
							<div class="rules__list-item">1. Za każde poprawnie zrealizowane Zamówienie Wydawcy należy się wynagrodzenie według stawek, które zostały przez niego określone w swojej Ofercie. Aktualna wartość wynagrodzenia widoczna jest w postaci salda w panelu na Platformie.</div>
							<div class="rules__list-item">2. W zależności statusu prawnego Wydawcy, jego Wynagrodzenie może zostać powiększone o właściwą stawkę podatku VAT.</div>
							<div class="rules__list-item">3. Wypłata wynagrodzenia następuje poprzez dostarczenie przez Wydawcę faktury VAT lub rachunku za pomocą Platformy według instrukcji dostępnej na Platformie.</div>
							<div class="rules__list-item">4. Realizacja wypłaty wynagrodzenia następuje w ciągu 5 dni roboczych od otrzymania przez Usługodawcę prawidłowo wystawionej faktury VAT lub rachunku.</div>
							<div class="rules__list-item">5. Wydawca ponosi wyłączną odpowiedzialność za rozliczenie podatkowe z tytułu otrzymanego wynagrodzenia.</div>
							<div class="rules__list-item">6. Wydawca zarejestrowany w innym kraju niż Polska zobowiązany jest do dostarczenia wraz z fakturą lub rachunkiem oświadczenia o rezydencji podatkowej w celu dokonania odpowiednich rozliczeń podatkowych</div>
						</div>
						<div>
							<div>ODPOWIEDZIALNOŚĆ</div>
							<div class="rules__list-item">1. Usługodawca ponosi odpowiedzialność na zasadach wskazanych w Regulaminie Platformy, z zastrzeżeniem postanowień niniejszego Regulaminu.</div>
							<div class="rules__list-item">
								<div>2. Usługodawca nie ponosi odpowiedzialności za:</div>
								<div class="rules__list-item">a. działania podejmowane przez Użytkowników (Wydawców i Reklamodawców),</div>
								<div class="rules__list-item">b. jakość i legalność Ofert składanych przez Wydawców,</div>
								<div class="rules__list-item">c. prawidłowość i dokładność danych deklarowanych na Platformie przez Użytkowników,</div>
								<div class="rules__list-item">d. przejściowe błędy techniczne błędy mogące wystąpić w trakcie działania Platformy,</div>
							</div>
							<div class="rules__list-item">3. Wydawca ponosi pełną odpowiedzialność wobec Reklamodawcy oraz Usługodawcy za sposób realizacji Zamówienia.</div>
							<div class="rules__list-item">4. Wydawca jest podmiotem wyłącznie odpowiedzialnym wobec Reklamodawcy za prawidłowe wykonanie Zamówienia i zwalnia w powyższym zakresie Usługodawcę z tej odpowiedzialności.</div>
							<div class="rules__list-item">5. W przypadku naruszenia przez Wykonawcę postanowień Regulaminu dotyczących sposobu i terminu wykonywania Zamówienia na rzecz Reklamodawcy, Wydawca zobowiązany jest do zwrotu pełnej kwoty wynagrodzenia za nieprawidłowo wykonane Zamówienie.</div>
							<div class="rules__list-item">6. Wydawca ponosi odpowiedzialność za treść Oferty. Wydawca zobowiązany jest do wykonania w całości działań określonych w Ofercie.</div>
							<div class="rules__list-item">7. W przypadku wytoczenia powództwa przeciwko Usługodawcy przez Reklamodawcę, Wydawca zobowiązuje się do przejęcia roszczenia lub przystąpienia do procesu. Ponadto, Wydawca zobowiązuje się do pokrycia wszelkich szkód związanych z pozwaniem Usługodawcy, w tym kosztów procesu.</div>
							<div class="rules__list-item">8. Powyższe ograniczenia odpowiedzialności Usługodawcy dotyczą Użytkownika, który jest Konsumentem wyłącznie w zakresie, który jest dozwolony przez powszechnie obowiązujące przepisy prawa.</div>
							<div class="rules__list-item">9. W przypadku, gdy z przyczyn leżących po stronie Wydawcy, Usługodawca będzie zobowiązany do zwrotu Reklamodawcy uiszczonego Wynagrodzenia z tytułu realizacji Zamówienia w całości lub w części (w szczególności w przypadku niewykonania lub nieprawidłowego wykonania Zamówienia), Wydawca zapłaci Usługodawcy karę umowną w wysokości 50% Wynagrodzenia brutto, które Wydawca otrzymał od Usługodawcy w związku z tym Zamówieniem. Powyższe nie wyłącza możliwości dochodzenia odszkodowania przekraczającego wysokość kary umownej na zasadach ogólnych. Zapłata kary umownej nastąpi na podstawie noty obciążeniowej w terminie 7 dni od jej doręczenia.</div>
						</div>
						<div>
							<div>PŁATNOŚCI</div>
							<div class="rules__list-item">1. Szczegółowe zasady rozliczeń z tytułu Usług, określa Usługodawca w Platformie, w sekcjach adekwatnych do danej Usługi.</div>
							<div class="rules__list-item">2. Ceny wskazane w Platformie mogą ulegać okresowym zmianom, przy czym dla potrzeb rozliczeń (umowy) wiążące są te, które obowiązują w chwili złożenia przez Reklamodawcę zamówienia na Usługę.</div>
							<div class="rules__list-item">3. Reklamodawca upoważnia Usługodawcę do stosowania faktur w formie elektronicznej.</div>
							<div class="rules__list-item">4. Jedynie Usługodawca jest uprawniony do pobierania wynagrodzenia, co w szczególności oznacza, iż Reklamodawca nie może dokonywać jakichkolwiek płatności za Usługi bezpośrednio na rzecz Wydawcy.</div>
							<div class="rules__list-item">5. Reklamodawca zobowiązany jest do regulowania wszelkich płatności, które w myśl przepisów prawa, są należne na rzecz twórców lub organizacji, które ich reprezentują.</div>
							<div class="rules__list-item">6. Ceny wskazane w Platformie są cenami netto i podlegają zwiększeniu o podatek VAT.</div>
							<div class="rules__list-item">
								<div>7. Płatności w Platformie są realizowane za pomocą:</div>
								<div class="rules__list-item">
									<div>a. Autopay Spółka Akcyjna</div>
									<div class="rules__list-item">
										z siedzibą w Sopocie przy ulicy Powstańców Warszawy 6,
										wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Gdańsk-Północ w Gdańsku, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem
										<br>
										KRS 0000320590 <br>
										NIP 585-13-51-185 <br>
										Regon 191781561
									</div>
								</div>
								<div class="rules__list-item">
									<div>a1. Dostępne formy płatności:</div>
									<div class="rules__list-item">
										Karty płatnicze:<br>
										Visa <br>
										Visa Electron <br>
										Mastercard <br>
										Mastercard Electronic <br>
										Maestro
									</div>
								</div>
								<div class="rules__list-item">
									<div>b. PayPal (Europe) S.à r.l. et Cie, S.C.A.</div>
									<div class="rules__list-item">
										22-24 Boulavard Royal <br>
										L-2449 Luksemburg <br>
										Numer w rejestrze handlowym: R.C.S. Luxembourg B 118 349
									</div>
								</div>
								<div class="rules__list-item">
									<div>c. Przelewów tradycyjnych na konto bankowe:</div>
									<div class="rules__list-item">
										ORION MEDIA GROUP SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ SPÓŁKA KOMANDYTOWA <br>
										ul. Niekłańska 35 lok. 3 <br>
										03-924 Warszawa <br>
										REGON 146909237 <br>
										PKO BP S.A. <br>
										31 1020 1127 0000 1202 0225 9836
									</div>
								</div>
							</div>
						</div>
						<div>
							<div>REKLAMACJE</div>
							<div class="rules__list-item">1. Przepisy tego punktu stosuje się z uwzględnieniem wcześniejszym postanowień Regulaminu, co w szczególności oznacza, iż jeżeli Regulamin wskazuje określony termin na dokonanie zgłoszenia przez Reklamodawcę, Reklamodawca jest związany takim terminem, a nie terminem opisanym w poniższych punktach.</div>
							<div class="rules__list-item">2. Reklamacje dotyczące Usługi winny być zgłaszane za pomocą poczty elektronicznej, na adres: help@marketin9.com.</div>
							<div class="rules__list-item">3. Reklamacja powinna zawierać co najmniej: datę i czas zdarzenia będącego przedmiotem reklamacji, adres e-mail wnoszącego reklamację oraz zwięzły opis zgłaszanych zastrzeżeń.</div>
							<div class="rules__list-item">4. Reklamacje będą rozpatrywane w ciągu 14 dni kalendarzowych po ich otrzymaniu, w kolejności daty otrzymania. W przypadku braku możliwości zachowania powyższego terminu Usługodawca informuje o tym fakcie osobę składającą reklamację, podając przyczynę przedłużenia terminu, jak również przewidywany czas udzielenia odpowiedzi.</div>
							<div class="rules__list-item">5. O sposobie rozpatrzenia reklamacji osoba składająca reklamację poinformowana zostanie pocztą elektroniczną, na adres poczty elektronicznej podany w reklamacji. Sprzedawca dokonuje zwrotu płatności przy użyciu takiego samego sposobu płatności, jakiego użył konsument, chyba że konsument wyraźnie zgodził się na inny sposób zwrotu, który nie wiąże się dla niego z żadnymi kosztami</div>
						</div>
						<div>
							<div>OCHRONA DANYCH OSOBOWYCH</div>
							<div class="rules__list-item">1. Dane udostępnione przez Wydawców w Ofertach dostępne są dla wszystkich użytkowników sieci Internet.</div>
							<div class="rules__list-item">2. Szczegółowe regulacje dotyczące ochrony danych osobowych znajdują się w dokumencie Polityka Prywatności, dostępnym na Stronie internetowej: <a href="https://dashboard.marketin9.com/static/privacyPolicy">https://dashboard.marketin9.com/static/privacyPolicy</a></div>
						</div>
						<div>
							<div>POSTANOWIENIA KOŃCOWE</div>
							<div class="rules__list-item">1. W sprawach nieuregulowanych niniejszym Regulaminem stosuje się przepisy prawa powszechnie obowiązującego.</div>
							<div class="rules__list-item">2. Wszelkie spory między Reklamodawcą a Usługodawcą będą rozstrzygane polubownie, a w razie braku porozumienia zostaną skierowane do sądu właściwego dla Usługodawcy.</div>
							<div class="rules__list-item">3. Regulamin może ulegać okresowym zmianom. O wszelkich zmianach Regulaminu Usługodawca poinformuje Użytkownika poprzez wiadomość e-mail wysłaną na adres podany podczas rejestracji oraz poprzez informację na Koncie Użytkownika dostępną po zalogowaniu na Platformie. W przypadku braku akceptacji wprowadzonych zmian, Użytkownik ma prawo złożyć oświadczenie o likwidacji konta na Platformie. Oświadczenie wymaga formy pisemnej przesłanej Usługodawcy drogą pocztową, bądź e-mailową na następujący adres e-mail: help@marketin9.com.</div>
							<div class="rules__list-item">4. W przypadku niepodjęcia przez Użytkownika żadnych działań, z upływem 14 dni od dnia doręczenia informacji o zmianie Regulaminu, zmiany zaczynają̨ obowiązywać tak, jakby Użytkownik zaakceptował zmiany Regulaminu.</div>
							<div class="rules__list-item">5. Regulamin obowiązuje od dnia: 07.08.2024</div>
						</div>
					</div>
					<div v-if="locale === 'en'" class="rules__text-wrapper">
						<div v-html="text"></div>
					</div>
					<MButton
						id="go-back"
						normal
						blue400
						@click="goBack"
						:label="$t('components.back')"
					/>
				</div>
			</template>
		</BeforeLoginLayout>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@/stores/session';
import { switchLanguage } from '@/hooks/LocaleHooks';
import BeforeLoginLayout from '@/views/Layout/components/BeforeLoginLayout.vue';
import MButton from '@/components/atoms/MButton.vue';
import MFlag from '@/components/atoms/MFlag.vue';
import MMenu from '@/components/atoms/MMenu.vue';
import MIcon from '@/components/atoms/MIcon.vue';

const { locale } = storeToRefs(useSessionStore());

const localeLanguage = ref<string>('');
if ('us' === locale.value) {
	localeLanguage.value = 'en';
} else if (locale.value != null) {
	localeLanguage.value = locale.value;
} else {
	localeLanguage.value = 'pl';
}

const text = ref<string>('<div class="text-page-content-desc" style="display: flex; flex-direction: column; gap: 15px;">\n' +
	'        <p><a name="advertisers"></a></p>\n' +
	'        <p><strong>I. Regulations for advertisers on the Marketin9 platform</strong></p>\n' +
	'        <p style="padding-left: 30px;"><strong>1. GENERAL PROVISIONS</strong></p>\n' +
	'        <p>1.1. These regulations (“Regulations”) define the rules for providing electronic service at the following address: https://www.marketin9.com/ (hereinafter: “the Website”), whereas the service shall be provided to the Internet end users who visit the Website and perform all the legal and actual activities as defined in the Regulations (hereinafter: “the Users”), and who are Advertisers.</p>\n' +
	'        <p>1.2. The Website is the database of Advertisers and Publishers. The database is verified on a current basis and any changes to it are not regarded as amendments to the Regulations.</p>\n' +
	'        <p>1.3. An Advertiser shall be understood as the Website User who is an entrepreneur and provides articles completed with other types of content (e.g. infographics, photographs) to be published on the Publisher’s website, or orders the content to be developed, whereby the User may be the final beneficiary of the Service or an entity commissioned by such a beneficiary (e.g. a media company) accountable to the Service Provider, as if it were ordering the Services on its own behalf and for itself.</p>\n' +
	'        <p>1.4. If a consumer wishes to order Services offered by the Service Provider to the Advertiser pursuant to the Regulations, then an agreement shall be entered with individually discussed terms and conditions, subject to the reservation that the provisions of the Regulations shall apply to the prices and to other business, as agreed individually with the consumer.</p>\n' +
	'        <p>1.5. A Publisher shall be understood as the Website User uploaded by the Service Provider in the Publishers’ database, whereas its situation shall be regulated by the&nbsp;<a href="static/termOfUse/#publishers"><strong>Publisher’s Regulations</strong></a>, applicable to the entities who publish articles on their websites or blogs.</p>\n' +
	'        <p>1.6. In these Regulations, working days shall be understood as any day other than Saturday, Sunday and other bank holidays as stipulated in Polish law. If the Regulations refer to “days”, it shall be understood as calendar days.<strong><br>\n' +
	'        </strong></p>\n' +
	'        <p>1.7. The Service shall be provided by:</p>\n' +
	'        <p style="padding-left: 40px;">ORION MEDIA GROUP sp. z o.o. sp.k.<br>\n' +
	'        ul. Niekłańska 35 lok. 3, 03-924 Warsaw, Poland<br>\n' +
	'        NIP 1132869511<br>\n' +
	'        Regon 146909237<br>\n' +
	'        NR KRS 0000480304</p>\n' +
	'        <p style="padding-left: 40px;">(hereinafter: “Service Provider”).</p>\n' +
	'        <p>1.8. The provisions of other documents available on the Website and referred to in these Regulations are an integral part of the Regulations. Should the provisions of any such documents be contrary to the Regulations, the Advertiser shall be bound by the provisions of the Regulations.</p>\n' +
	'        <p>&nbsp;</p>\n' +
	'        <p style="padding-left: 30px;"><strong>2. Service and using the service</strong></p>\n' +
	'        <p>2.1. The service provided includes, in particular, making the Website available along with its functionalities (including publishing articles on the Publishers’ websites, developing the content, promoting the content), including the tools for communication and functionalities for ordering services (hereinafter: “the Service”).</p>\n' +
	'        <p>2.2. The Service related to making the Website available shall be provided once access is obtained to the Website or to the selected functionalities.</p>\n' +
	'        <p>2.3. Access to the Website understood as the Service mentioned in section 2.2. shall be paid for with the fees collected for Services provided for a consideration. Such a Service agreement is concluded at the time when the User accepts the Regulations. The scope and manner of using the above-mentioned Services shall be defined by the Service Provider in the Regulations and in the documents referred to in the Regulations.</p>\n' +
	'        <p>2.4. The Website functions selected by the Service Provider are available for a consideration. The scope of such Services and the payment shall be defined by the provisions of the documents available on the Website, which refer to a particular Service. An agreement for Services provided for a consideration is concluded at the moment defined in the provisions of the Regulations pertaining to a particular Service, whereas the agreement shall be concluded subject to a condition precedent that the Advertiser pays the fee. The scope and manner of using Services provided for a consideration shall be defined by the Service Provider in the Regulations and in the documents referred to in the Regulations.</p>\n' +
	'        <p>2.5. Before concluding the agreement related to the Website functionality, the Parties shall be obliged to confirm the accuracy of the submitted order and the order approval statement.</p>\n' +
	'        <p>2.6. If a Party confirms the reception of the offer to the other Party before concluding the agreement, which may also be done automatically, through the Website functionalities, such a confirmation does not result in concluding the agreement, but such an offer shall be binding upon the Party that submitted the offer.</p>\n' +
	'        <p>2.7. Concluding the agreement as well as the relevant provisions thereof shall be consolidated in an e-mail sent to the Parties via the Website functionality.</p>\n' +
	'        <p>2.8. The agreement may only be concluded in English.</p>\n' +
	'        <p>2.9. None of the agreements stipulated in the Regulations may be concluded implicitly.</p>\n' +
	'        <p>2.10. Services provided for a consideration include:</p>\n' +
	'        <p style="padding-left: 30px;">2.10.1. Acting as an intermediary between Publishers and Advertisers, as regards providing Portal space and publishing articles for a consideration (sponsored articles);</p>\n' +
	'        <p style="padding-left: 30px;">2.10.2. Developing the content as ordered by the Advertiser.</p>\n' +
	'        <p style="padding-left: 30px;">Pursuant to relevant provisions of the Regulation, applicable to a Service, acting as an intermediary may involve acting for the benefit and on behalf of the Website User or on the Service Provider’s own behalf, but for the benefit of the User.</p>\n' +
	'        <p>2.11. A Publisher shall publish a list of its websites, including blogs (hereinafter: Portals) on the Website, covered by the intermediary service as mentioned in section 2.10. For each Portal, the Publisher shall provide the information concerning its address, subject matter, description, popularity and other details of importance for the Advertisers. The information provided by the Publishers are subject only to a partial verification by the Service Provider. If an Advertiser wishes to use the Website, it should become familiar not only with the Publishers’ information but also with the content of the Website and its functioning.</p>\n' +
	'        <p>2.12. The Service Provider shall share the indicators related to the Portals as well as social media profiles with the Advertiser. This data comes from the Publishers or is downloaded from external websites once a particular Portal or Profile is added to the Website. The Service Provider shall make an effort to verify the data periodically, in various intervals, but does not guarantee that the data is correct or up-to-date as at a particular date.</p>\n' +
	'        <p>2.13. The Service shall be available to the Advertisers as the end users of the Internet.</p>\n' +
	'        <p>2.14. The Service Provider may introduce accessory services and extensions, also for a consideration.</p>\n' +
	'        <p>2.15. The Service shall be provided pursuant to the rules stipulated in the provisions of the Regulations.</p>\n' +
	'        <p>2.16. In order to use the Service properly, a PC, Mac or similar computer shall be necessary, connected to the Internet and having an operational system (Windows, Mac OS, Linux or similar) as well as an Internet browser (Internet Explorer, Firefox, Chrome, Opera, Safari). Some Services and tools may require access to an e-mail account, while in order to use the Services offered, it may be necessary to have the Adobe Flash Player software, version 9 or later and JavaScript use must be enabled, unless stipulated otherwise.</p>\n' +
	'        <p>2.17. When the Service is being used, cookies are installed in the User’s information system. The rules for using cookies are defined in the&nbsp;<strong><a href="static/privacyPolicy">Privacy Policy</a>.</strong></p>\n' +
	'        <p>2.18. If the Service Provider provides the Service to the Advertiser along with a photo related to the subject matter of the Service, such a photo shall be subject to the copyright held by such entities as image banks. In this case, the Advertiser:</p>\n' +
	'        <p style="padding-left: 30px;">2.18.1. does not acquire any rights to the photo, except for the right to use it on its own account, for the purposes for which the Service was ordered,</p>\n' +
	'        <p style="padding-left: 30px;">2.18.2. shall be obliged to become familiar with the content of the licence granted by the image bank and to follow its guidelines.</p>\n' +
	'        <p>&nbsp;</p>\n' +
	'        <p style="padding-left: 30px;"><strong>3. Registration on the Website</strong></p>\n' +
	'        <p>3.1. Pursuant to these Regulations, a Website User may be an Advertiser who accepts the provisions of the Regulations and completes the registration procedure ending with an effective account setup.</p>\n' +
	'        <p>3.2. The status of a Publisher as Website User shall be defined by relevant regulations, i.e. the <a href="static/termOfUse/#publishers"><strong>Publisher’s Regulations</strong></a>.</p>\n' +
	'        <p>3.3. The registration procedure involves completing an electronic registration form, according to the instructions, and accepting relevant Regulations as well as ticking the consent checkboxes related to the issues pointed out by the Service&nbsp; Once these Regulations are accepted by the Advertiser, the agreement between the Advertiser and the Service Provider is concluded, related to the rules for using the Website (its availability).</p>\n' +
	'        <p>3.4. Following the registration on the Website, a user account shall be assigned to the Advertiser.</p>\n' +
	'        <p>3.5. The account may be deleted by the Service Provider without any notice, should the Service Provider find out that:</p>\n' +
	'        <p style="padding-left: 30px;">3.5.1. the Advertiser violates the provisions of the Regulations, including the legal provisions referred to in the Regulations;</p>\n' +
	'        <p style="padding-left: 30px;">3.5.2. the data provided by the Advertiser is false or incomplete.</p>\n' +
	'        <p>3.6. In order for a deleted account to be reassigned to the Advertiser, the violations mentioned in section 3.5. must be removed. The Service Provider’s consent is also necessary for the account to be reassigned, whereas the Service Provider shall be under no obligation to grant the consent.</p>\n' +
	'        <p>&nbsp;</p>\n' +
	'        <p style="padding-left: 30px;"><strong>4. Service Fees</strong></p>\n' +
	'        <p>4.1. Detailed rules related to the payments for the Services, including the Service Fees, shall be defined by the Service Provider on the Website, in the sections applicable to a particular Service.</p>\n' +
	'        <p>4.2. The prices indicated on the Website may be adjusted from time to time, whereas for the purpose of the settlements (agreements), mandatory prices shall be those valid at the time when the Advertiser submitted a Service order.</p>\n' +
	'        <p>4.3. The Advertiser shall authorise the Service Provider to use electronic invoices.</p>\n' +
	'        <p>4.4. The Service Provider shall be exclusively authorised to collect the fees, which means in particular that the Advertiser may not make any payments for the Services directly to the Publisher.</p>\n' +
	'        <p>4.5. The Advertiser shall be obliged to pay all the amounts due to the authors or organisations that represent them, pursuant to the mandatory legal provisions.</p>\n' +
	'        <p>4.6. The prices provided on the Website are net prices in British pound sterling (GBP); VAT shall be added to such prices, as per the standards mandatory for international settlements.</p>\n' +
	'        <p>4.7. Payments for Services shall be made by way of deducting the due amount for the Service from the current balance on the Advertiser’s account, provided there are sufficient funds on the account.</p>\n' +
	'        <p>4.8. The funds on the Advertiser’s account shall be kept in British pound sterling (GBP).</p>\n' +
	'        <p>4.9. Due amounts can be paid by a bank card (the Service Provider shall keep the name of the card manufacturer as well as the last 4 digits of the card number among its data), and by PayPal. The payments shall be uploaded to the Advertiser’s account in British pound sterling (GBP). Depending on the payment type and the original currency available on the Advertiser’s card or PayPal account,&nbsp; the payment to the Advertiser’s account may involve extra charges related to the conversion into British pound sterling (GBP), whereas the Service Provider shall not be liable for such extra charges.</p>\n' +
	'        <p>&nbsp;</p>\n' +
	'        <p style="padding-left: 30px;"><strong>5. Publishing Articles for a consideration by Publishers</strong></p>\n' +
	'        <p>5.1. The Publisher’s obligations related to providing Portal space and publishing articles for a consideration shall be defined by the <strong><a href="static/termOfUse/#publishers">Publisher’s Regulations</a>.</strong></p>\n' +
	'        <p>5.2. Sponsored articles (hereinafter: “Paid Articles”) are texts (along with the media attached) that advertise the Advertiser’s product, company or service, as well as other texts that provide information.</p>\n' +
	'        <p>5.3. If an Advertiser wishes to order a Paid Article, then the rules for the Service Provider’s intermediary activities during such a Service shall be subject to section 8 of the Regulations. As part of the services, it is also possible for the Publisher to prepare a Paid Article. In this case, the rules for preparing such material shall be defined by the provisions of these Regulations as well as the Publisher’s offer (whereby in the event of any discrepancies between the Regulations and the offer, the provisions of the offer shall apply), whereas the terms and conditions for publishing and providing Portal space shall be defined by these Regulations and the <a href="static/termOfUse/#publishers"><strong>Publisher’s Regulations</strong></a>. The Advertiser acknowledges that publishing a Paid Article prepared by the Publisher does not grant the Advertiser any rights to the content or the form of the Paid Article.</p>\n' +
	'        <p>5.4. The Advertiser must also be aware that the particular Publisher may label a Paid Article as a sponsored article, or in any other equivalent manner.</p>\n' +
	'        <p>5.5. If the Advertiser delivers a Paid Article to the Service Provider, the Service Provider has a right, but is under no obligation, to check whether such Paid Article is unique. The verification may be performed in particular with the CopyScape tool. If a Paid Article is not unique and the Advertiser has not highlighted this fact in the form completed upon uploading the Article, the Service Provider may stop the Paid Article from being delivered to the Publisher.</p>\n' +
	'        <p>5.6. The Advertiser guarantees that a Paid Article approved for publishing will comply with the legal provisions as regards its content and eligibility for publishing, and that it shall hold the copyright to the content and other forms employed in this Article (photos, graphics etc.).</p>\n' +
	'        <p>5.7. The Service Provider shall be under no obligation to verify whether provided Paid Articles (as well as the consequences of publishing them subsequently) do not constitute a breach of law as regards their content, eligibility for publishing and the ownership of the rights to the content and other forms employed in the Articles (photos, graphics etc.). The Service Provider shall not be liable for any breach of law caused by Paid Articles.</p>\n' +
	'        <p>5.8. A Paid Article may include active links to the Advertiser’s websites. The number and type of the links accepted for publishing depend on the Publisher’s offer and such information is visible on the Website next to the Publishers’ offers.</p>\n' +
	'        <p>5.9. The Advertiser shall be obliged to become familiar with the information provided by the Publishers, referring to the rules for providing Portal space and publishing Paid Articles. This information, visible on the pages of the Website, may in particular define:</p>\n' +
	'        <p style="padding-left: 30px;">5.9.1. Technical and substantive requirements regarding Paid Articles published on the pages thereof;</p>\n' +
	'        <p style="padding-left: 30px;">5.9.2. Sample reasons why a paid Article may not be accepted for publishing.</p>\n' +
	'        <p>5.10. Submitting an order by the Advertiser shall be tantamount to accepting the terms and conditions for providing Portal space and publishing Paid Articles, as offered by the Publisher and required by the Regulations, as well as of the terms and conditions stipulated in the &nbsp;PUBLISHERS’ REGULATIONS; it shall also be the basis for the fee claimed by the Service Provider. Submitting an order shall also means that the Advertiser authorises the Publisher to publish a Paid Article on its Portal for the purpose of Service provision.</p>\n' +
	'        <p>5.11. The fee for the Service related to providing Portal space and publishing Paid Articles shall be defined by the relevant part of the Website, dedicated to the Service. The fee shall be payable to the Service Provider pursuant to an invoice issued by the Service Provider no later than when the Advertiser submits the order.</p>\n' +
	'        <p>5.12. After collecting the fee from the Advertiser, the Service Provider shall use the Website functionality to order Portal space to be provided to the Advertisers, in order for the Publisher to publish Paid Articles there, subject to the following conditions:</p>\n' +
	'        <p style="padding-left: 30px;">5.12.1. The content of the Paid Article shall be ready and available on the Website, to be viewed by the Publisher, together with any appendices, structure and links – (this does not apply to the situation when the Publisher prepares the content of a Paid Article);</p>\n' +
	'        <p style="padding-left: 30px;">5.12.2. The content of the Paid Article shall be unique, unless specified otherwise;</p>\n' +
	'        <p style="padding-left: 30px;">5.12.3. The Paid Article conforms to the selected technical requirements provided by the Publisher;</p>\n' +
	'        <p style="padding-left: 30px;">5.12.4. The Paid Article complies with other requirements stipulated in the Regulations, including those described in section 5.6;</p>\n' +
	'        <p style="padding-left: 30px;">5.12.5. The Advertiser has complied with all the requirements stipulated in the Regulations, related to this Service.</p>\n' +
	'        <p>5.13. The Service Provider forwards the information on the order to the Publisher no later than within 1 working day of receiving the order from the Advertiser.</p>\n' +
	'        <p>5.14. The Advertiser acknowledges that providing Portal space and publishing Paid Articles requires prior approval of the Publisher, whereas the Publisher shall have the right to refuse to provide Portal space and publish a sponsored article without providing a reason. For the avoidance of doubt, any representations made by the Service Provider with regard to the publishing of a Paid Article shall be made exclusively in order for the Service Provider to verify as an intermediary (section 2.10 of the Regulations) the potential possibility of publishing a Paid Article, i.e. to check whether the actions and the Paid Article conform to the Regulations. Such representations do not constitute any assurance made on behalf of the Publisher and as such, they are not a guarantee that the Paid Article will be published by the Publisher.</p>\n' +
	'        <p>5.15. The agreement related to providing Portal space and publishing a Paid Article shall be concluded once the Advertiser’s order is approved by the Publisher, pursuant to section 5.14, i.e. once the content of the article is downloaded and the publication is marked by the Publisher.</p>\n' +
	'        <p>5.16. With regard to providing Portal space and publishing a Paid Article, the Service Provider shall use the automated functions of the Website to request that the Publisher mark in the system that the Portal space has been provided and the pertinent content has been published. “Marking”, as mentioned above, shall mean that the Publisher inserts the Internet address of the provided Portal space.&nbsp;Such marking results in an automatic notification – an e-mail shall be sent to the Advertiser, informing that the Paid Article has been published. The Publisher shall be obliged to publish the article within 3 working days of accepting the order (section 5.14). In special cases, this period may be different, but it shall be explicitly marked on the Website, next to the Publisher’s offer and portal.</p>\n' +
	'        <p>5.17. Once the Paid Article has been published and this fact has been marked on the Website, the Advertiser shall verify that the Portal space has been provided and that the published content is accurate, whereas the Service Provider shall have the same right, but shall be under no obligation to do so; such verification involves checking that the provided Portal space and publication are consistent with the relevant agreement concluded to this effect. The Advertiser’s verification shall take place no later than within 3 working days of the day when the Publisher marked the publication on the Website. If it is the Publisher that prepares the Paid Article, pursuant to the Publisher’s offer accepted by the Advertiser, then the Advertiser shall approve the content within 3 working days of the day when the Publisher provides the Paid Article – to this extent, the provision of section 8.10 of the Regulations shall apply accordingly.</p>\n' +
	'        <p>5.18. Should the Advertiser fail to verify the published article within 3 working days of the date when the provided Portal space and published article were highlighted by the Publisher, it shall be deemed that the Paid Article has been published correctly. Any subsequent changes to the publication, as suggested by the Advertiser, may be treated as a form of an amendment to the agreement for providing Portal space and publishing a Paid Article and as such shall be introduced in the mode prescribed in section 7 of the Regulations, which means in particular that the Advertiser may have to incur extra costs.</p>\n' +
	'        <p>5.19. If no Portal space is provided, the Paid Article is published by the Publisher in a way that is not consistent with the agreement, or if there is a delay in publishing, and notification of this fact is provided as stipulated in section 5.17 of the Regulations, except for the situation mentioned in section 5.18, the Service Provider and the Advertiser shall decide whether:</p>\n' +
	'        <p style="padding-left: 30px;">5.19.1. the Publisher shall provide the Portal space pursuant to the agreement;</p>\n' +
	'        <p style="padding-left: 30px;">5.19.2. the Publisher shall amend the published content – then the Service Provider and the Advertiser shall define the deadline and scope of the amendments;</p>\n' +
	'        <p style="padding-left: 30px;">5.19.3. The Advertiser withdraws from the agreement, wholly or partially – then the fee paid by the Advertiser shall be reimbursed in the portion corresponding to the scope of the withdrawal.</p>\n' +
	'        <p>5.20. If any of the circumstances mentioned in section 5.19 occurs as a result of a discrepancy between the Service and the agreement, the Advertiser shall address any claims in this respect to the Publisher. The amount of such claims, however, may not exceed the fee defined for a particular publication.</p>\n' +
	'        <p>5.21. In exchange for the fee stipulated in the agreement, the Publisher should provide the Portal space and keep the Paid Article published at least for one year, without any changes to its content, unless the Publisher’s offer stipulates otherwise, or unless one of the circumstances mentioned in section 5.22 of the Regulations occurs. Section 5.21 shall apply accordingly, subject to the reservation that providing Portal space and retaining the publication for a period longer than stipulated shall not be regarded as a breach of the agreement. Any representation or statement made by the Publisher as regards retaining the publication for a period longer than 1 (one) year shall be treated as non-binding and as such shall not constitute the basis for any claims addressed to the Service Provider.</p>\n' +
	'        <p>5.22. The period of providing Portal space and publishing the Paid Article may be reduced, without giving rise to any claims towards the Service Provider or the Publisher, including the reimbursement of the fee or compensation, in the following situations:</p>\n' +
	'        <p style="padding-left: 30px;">5.22.1. when the Publisher suspends or phases out the Portal;</p>\n' +
	'        <p style="padding-left: 30px;">5.22.2. when the publication was deleted, because it constituted a breach of legal provisions or there was reasonable suspicion that it is a breach of law;</p>\n' +
	'        <p style="padding-left: 30px;">5.22.3. when the publication was deleted, because it violated the rights of third parties or there was reasonable suspicion it violated the rights of third parties;</p>\n' +
	'        <p style="padding-left: 30px;">5.22.4. when the publication was deleted at the Advertiser’s request, as per section 5.23;</p>\n' +
	'        <p style="padding-left: 30px;">5.22.5. when the Advertiser intentionally acts to the detriment of the Publisher, e.g.&nbsp; by unnatural links to the article;</p>\n' +
	'        <p style="padding-left: 30px;">5.22.6. when the Advertiser infringes upon the provisions of the Regulations which refer to its obligations after the Article is published;</p>\n' +
	'        <p style="padding-left: 30px;">5.22.7. when it is the result of force majeure.</p>\n' +
	'        <p>5.23. At the Advertiser’s request, the Service Provider may address the Publisher and request that the Paid Article be deleted ahead of time. Should such request be reported, the Service Provider shall be obliged, while maintaining due diligence, to make an effort in order for the Publisher to delete the Paid Article within 3 working days of receiving the relevant information. The Advertiser shall not have the right to claim the reimbursement of any portion of the fee.</p>\n' +
	'        <p>5.24. The Publisher shall have the right to amend the URL address of the provided Portal space containing the Paid Article, within the publication period as mentioned in section 5.21. In the event of amending the address or domain, this must be done while maintaining the 301 Redirect. Such an amendment shall not be treated as a violation of the rules of providing Portal space and publishing the Paid Article.</p>\n' +
	'        <p>5.25. If the Publisher receives the manual filter titled „Unnatural links from your site” or similar (from Google) regarding the portal where the Article is published, the Publisher may switch the attribute of the links in the article to nofollow. Such an activity shall not be treated as a violation of the publishing agreement.</p>\n' +
	'        <p>5.26. If, pursuant to the Regulations, there are grounds for the reimbursement of the fee (whole or partial) to the Advertiser, and the Service Provider does not manage to obtain an adequate reimbursement of the fee paid to the Publisher within 10 working days of the date when the grounds for the reimbursement (whole or partial – depending on the situation) occurred, then the Service Provider shall withhold the reimbursement of the relevant portion of the fee to the Advertiser and the Advertiser shall have the right to claim the reimbursement of the cost related to providing Portal space and publishing the article, but only directly from the Publisher, in the portion that constituted the Publisher’s remuneration. To this end, the Service Provider shall be obliged to take all measures in order for the Advertiser to exercise this right, including assigning all the rights in this respect, as well as sharing the address and contact details of the Publisher.</p>\n' +
	'        <p>5.27. The Advertiser undertakes not to contact the Publisher directly, disregarding the Service Provider, in the matters related to providing Portal space and publishing Paid Articles, which are to be or have been published through the Website. This does not apply to the situations expressly allowed for in the Regulations, including those pursuant to section 5.26).</p>\n' +
	'        <p>5.28. In extraordinary situations, the Service Provider reserves the right to charge extra fees for non-standard operations, even if they are not stipulated on the Website or in the agreement for providing Portal space and publishing articles, should such situations occur as a result of the Advertiser’s fault or were implemented at the Advertiser’s request.</p>\n' +
	'        <p>&nbsp;</p>\n' +
	'        <p style="padding-left: 30px;"><strong>6. Modifying published articles</strong></p>\n' +
	'        <p>6.1. The Advertiser shall have the option to use the Website to request changes to the articles published through the Website, i.e. the Articles.</p>\n' +
	'        <p>6.2. Changes to the published articles shall be introduced by the Publishers, considering the arrangements made with the Advertiser.</p>\n' +
	'        <p>6.3. Communication related to the changes in the published articles shall be made exclusively through the Website. The Advertiser undertakes not to contact the Publisher directly with reference to the articles published through the Website.</p>\n' +
	'        <p>6.4. Requesting changes to the published sponsored articles is paid and the cost of such service depends on the scope of the changes and the cost defined by the Publisher. With regard to relevant provisions related to the Service, the amounts are stated for convenience only and refer to typical, simple activities, therefore it is not possible to provide a binding estimate of the fee due to the variety of changes that may be introduced. For this reason, the provisions of the Website in this respect do not constitute an offer in the meaning of the Civil Code, and the agreement for the modifications, including the relevant fee, shall be concluded considering section 6.5.</p>\n' +
	'        <p>6.5. Pursuant to section 6.4, when defining the remuneration, the first point of reference shall be the fee indicated on the Website. As part of its functionality, the Website may amend the cost of introducing modifications in a published article, depending on the type of changes proposed by the Advertiser and the Publisher’s expectations.<br>\n' +
	'        The Advertiser shall use the Website functionality to approve or reject the offer within 3 days of the new price being defined. Failure to approve the price within the abovementioned deadline means rejecting the offer; in this case, the agreement is not effective.</p>\n' +
	'        <p>6.6. At each stage of the negotiations, the Publisher may refuse to introduce modifications to a published article. In this case, the Advertiser may exercise the rights mentioned in section 5.24.</p>\n' +
	'        <p>6.7. Th scope of modifications to the article may not refer to the subject matter and no more than 50% of the content (expressed as the number of characters) may be subject to change. Modifications that do not conform to these requirements shall be understood as the Advertiser’s proposal of a new Paid Article.</p>\n' +
	'        <p>6.8. If the agreement is effective, the Publisher shall be obliged to publish the modifications to the content of the article within 3 working days.</p>\n' +
	'        <p>6.9. With regard to the publication of the article, the Service Provider shall use the automated functions of the Website to address the Publisher and request that the modification in the publication be marked in the Website system within 24 hours of introducing such modifications. Marking the modifications in the system results in an automatic notification – an e-mail shall be sent to the Advertiser, informing that the modification has been published.</p>\n' +
	'        <p>6.10. Once the changes are introduced in the Article and this fact has been marked on the Website, the Advertiser shall verify that the modifications are correct and the Service Provider shall have the same right, but shall be under no obligation to do so; verification involves checking that the introduced modification is consistent with the pertinent agreement concluded to this effect. The Advertiser’s verification shall take place no later than within 3 working days of the day when the Publisher marked the modifications on the Website.</p>\n' +
	'        <p>6.11. If the Advertiser fails to verify the modifications introduced to the published Article within 3 working days of the date when the modifications were published, it shall be deemed that the modifications have been introduced correctly. Any subsequent changes introduced by the Advertiser shall require a new modification procedure in the mode defined in section 6.7.</p>\n' +
	'        <p>6.12. Introducing changes to a published article does not generate an obligation for the Publisher to keep in on the Website for a period longer than initially stipulated, unless it is agreed otherwise for an individual case. In order to change the period of publication, the Advertiser’s relevant proposal should be included in the guidelines for the modifications to the article, as prepared by the Advertiser; this shall be the subject of negotiations conducted in the mode defined in section 6.4 of the Regulations.</p>\n' +
	'        <p>6.13. For the matters not regulated in this section, the provisions of section 4 and 5 shall apply accordingly and adequately to the circumstances, unless they are contrary to section 6.</p>\n' +
	'        <p>&nbsp;</p>\n' +
	'        <p style="padding-left: 30px;"><strong>7. Statistics generated by the Publishers</strong></p>\n' +
	'        <p>7.1. Selected Publishers and their Portals shall make it possible to generate statistics, thanks to the tracking code developed by the Service Provider. Access to such statistics is free of charge.</p>\n' +
	'        <p>7.2. Selected Publishers may choose not to share the statistics at all. In this case, the provisions of the Regulations referring to the statistics shall not apply to such Publishers.</p>\n' +
	'        <p>7.3. Information on a Publisher who is currently sharing the statistics may be found among the information mentioned in section 2.11 of the Regulations. Access to the statistics is not in charge of the Service Provider, therefore the Service Provider may not guarantee constant access to the statistics. Loss of such access does not constitute the basis for any claims.</p>\n' +
	'        <p>7.4. The statistics should include at least the information about the number of views of the article or the number of unique users who entered the subpage with the article.</p>\n' +
	'        <p>7.5. The file with the statistics generated by the Publisher shall be shared through the Website.</p>\n' +
	'        <p>7.6. Pursuant to the <a href="static/termOfUse/#publishers"><strong>Publisher’s Regulations</strong></a>, the statistics shared by the Publishers should be provided on time, in a PDF or a graphics file (JPG), they should be reliable, refer to a specific article and, if possible, consider special requests submitted by the Advertisers. If this provision is violated, the Publisher shall be directly liable to the Advertiser.</p>\n' +
	'        <p>7.7. The Publisher shall be under no obligation to comply with special requests of the Advertisers, i.e. other than those following directly from the Regulations.</p>\n' +
	'        <p>7.8. The Advertisers undertake not to contact the Publishers directly (outside the Website) in the matters related to the statistics.</p>\n' +
	'        <p>&nbsp;</p>\n' +
	'        <p style="padding-left: 30px;"><strong>8. Preparing articles and infographics</strong></p>\n' +
	'        <p>8.1. As part of the Service involving the publication of sponsored articles, the Advertiser may provide the articles it already has or it may order the preparation of such articles with the Service Provider, who will then cause the publication of such articles, pursuant to the rules defined in section 5 or 6 of the Regulations. The Advertiser may order the preparation of an article, content or infographics with the Service Provider, if they are to be used for purposes other than publication.</p>\n' +
	'        <p>8.2. When the Advertiser orders the preparation of an article, content or infographics (hereinafter: “Content”):</p>\n' +
	'        <p style="padding-left: 30px;">8.2.1. The Advertiser shall define the type of requested Content and provides extra comments ad information.</p>\n' +
	'        <p style="padding-left: 30px;">8.2.2. The Advertiser shall bear the cost of preparing the Content, pursuant to the current price-list for this Service, available on the Website.</p>\n' +
	'        <p>8.3. The Advertiser may also order the preparation of Content in the form of infographics based on the data provided by the Advertiser, or it may order the preparation of data and the relevant infographics, using one of the packages described on the Website. The date of completing such a Service depends on the ordered package and is defined by the Website.</p>\n' +
	'        <p>8.4. The Advertiser guarantees that the data and other elements provided under an order shall be consistent with the legal provisions as regards the content and availability for publication, and that it will have the right to their content and other forms of expression (photos, graphics etc.).</p>\n' +
	'        <p>8.5. The Service Provider shall be under no obligation to verify whether the data or other elements provided by the Advertiser do not constitute a breach of the law as regards their content, availability for publication and ownership rights.</p>\n' +
	'        <p>8.6. The price for the Service related to Content preparation shall be defined by a relevant Website section devoted to this Service. The Website may additionally define extra services offered by the Service Provider without an extra fee, which may be related to a specific order from time to time, which in particular refers to such elements as stock photos.</p>\n' +
	'        <p>8.7. Placing an order by the Advertiser shall be tantamount to accepting the terms and conditions for preparing the Content, as stipulated in the Regulations. It shall also be the basis for the Service Provider to collect a fee.</p>\n' +
	'        <p>8.8. The Content preparation agreement shall be deemed concluded once the Service Provider approves the Advertiser’s order, of which the Advertiser is notified through the Website functionality. The Service Provider shall be under no obligation to approve an order.</p>\n' +
	'        <p>8.9. The Service Provider shall prepare the Content within the period defined on the Website (section 8.3, 8.4). If the subject matter is complex or there is a considerable amount of orders, the deadline for completing the Service may be extended, whereas the Website shall immediately notify the Advertiser of this fact, at the same time indicating the extended deadline, which may not, however, be longer than another 7 working days.</p>\n' +
	'        <p>8.10. Once the Content is prepared, the Website provides it to the Advertiser, who shall have the right to offer comments or approve the Content. If the Advertiser fails to verify the Content within 3 working days of receiving it, it shall be deemed that the Content has been prepared according to the order. Any later amendments requested by the Advertiser may be treated as a form of an amendment to the agreement for Content preparation and as such shall require approval, which means in particular that the Advertiser may have to incur extra costs.</p>\n' +
	'        <p>8.11. If the Content is prepared in a way that is not consistent with the agreement, or if the deadline is not observed, and notification of this fact is provided as stipulated in section 9.10 of the Regulations, except for the situation mentioned in the last sentence of section 9.10, the Service Provider and the Advertiser shall decide whether:</p>\n' +
	'        <p style="padding-left: 30px;">8.11.1. The Service Provider is to amend the Content – then the Service Provider defines the deadline, no shorter than 3 working days, and the scope of the amendments. If the Service Provider refuses to consider the amendments, which it shall have the right to do, then the withdrawal right shall be applicable as mentioned below under letter b);</p>\n' +
	'        <p style="padding-left: 30px;">8.11.2. The Advertiser withdraws from the whole agreement or a part thereof – then the fee paid by the Advertiser shall be reimbursed in the portion corresponding to the scope of the withdrawal.</p>\n' +
	'        <p>8.12. The Advertiser shall have no right to impose the guidelines related to the length and type of content, other than those defined by default for the specific Content type as offered by the Website when the order is placed. These circumstances may not be treated as the basis for a complaint as mentioned in section 8.11.</p>\n' +
	'        <p>8.13. If any of the circumstances mentioned in section 8.11 occurs as a result of a discrepancy between the Service and the agreement, any related claims may not exceed the amount of the fee defined for Content preparation.</p>\n' +
	'        <p>8.14. In extraordinary situations, the Service Provider reserves the right to charge extra fees for non-standard operations, even if they are not stipulated on the Website or in the agreement for Content preparation, should such situations occur as a result of the Advertiser’s fault or were implemented at the Advertiser’s request.</p>\n' +
	'        <p>8.15. Once the Content is approved, the Service Provider shall grant the Advertiser a licence for the Content, unlimited in time and territory, whereas the licence shall be included in the fee collected for Content preparation and shall cover the following fields of use:</p>\n' +
	'        <p style="padding-left: 30px;">8.15.1. recording and reproducing with any technique, including printing, reprography, digital technique as well as on magnetic, optical and electronic media, including recording and saving as an e-book or audiobook, in an unlimited number of copies, releases and reprints, both on its own and in a collection, with other pieces or materials that do not have the features of a creation;</p>\n' +
	'        <p style="padding-left: 30px;">8.15.2. marketing and distribution of copies or other media in any distribution channels, without limits as to the amount, subject matter or territory, or with regard to the target audience, in any available distribution channels, in particular in retail, news-stands, sales via mail order, in trading networks, book clubs, cross-selling, direct marketing, mail order, door to door, press inserts, Internet trading and business to business sale (delivery as ordered by companies), as well as free distribution;</p>\n' +
	'        <p style="padding-left: 30px;">8.15.3. lease or rental of the copies;</p>\n' +
	'        <p style="padding-left: 30px;">8.15.4. uploading the material to computer memory and storing it; sharing it in the public domain via ICT networks, especially in the form of an e-book or audiobook, and so that everybody can access it in any place and at any time, as convenient;</p>\n' +
	'        <p style="padding-left: 30px;">8.15.5. broadcasting and retransmission via wired, wireless or satellite transmission;</p>\n' +
	'        <p style="padding-left: 30px;">8.15.6. public performance, show, display or replay;</p>\n' +
	'        <p style="padding-left: 30px;">8.15.7. using the material in any forms of advertising;</p>\n' +
	'        <p>8.16. Upon granting the licence, the Service Provider agrees for the material including the Content to be used and handled within the fields mentioned in section 8.15.</p>\n' +
	'        <p>8.17. Furthermore, the Service Provider represents that it has obtained a guarantee from the originator of the Content that after the transfer of copyright, the originator will not exercise his/her personal rights; in particular, the originator has agreed for the Content to be used without indicating authorship.</p>\n' +
	'        <p>8.18. Should the copyright granted to the Advertiser be violated, the Service Provider shall be liable up to the maximum amount equal to the fee collected for Content preparation.</p>\n' +
	'        <p>&nbsp;</p>\n' +
	'        <p style="padding-left: 30px;"><strong>9. Personal data, business information</strong>&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;</p>\n' +
	'        <p>9.1. The Advertiser agrees to have his/her personal data processed in the course of the registration process or using the Website processed by the Service Provider.</p>\n' +
	'        <p>9.2. The personal data administrator shall be the Service Provider, who processes the personal data pursuant to the mandatory legal provisions.</p>\n' +
	'        <p>9.3. The Service Provider shall process personal data to the extent necessary to conclude and produce the content of the agreement, amend and terminate it accordingly and to perform online services properly.</p>\n' +
	'        <p>9.4. Each User who is a natural person shall have the right to view his/her data, amend it and request that the data be deleted. To this effect, one should address the Service Provider with a relevant request – an e-mail titled “Personal data” should be sent to help@marketin9.com.</p>\n' +
	'        <p>9.5. The Users’ personal data may be shared with entities authorised to receive it, including the competent law enforcement bodies, pursuant to the mandatory legal provisions, as well as with third parties that perform activities commissioned by the Service Provider and included in the scope of Services covered with these Regulations.</p>\n' +
	'        <p>9.6. With regard to the Services described in these Regulations, the Service Provider shall share the personal data provided by the User with other entities involved in the Service provision, by way of entrusting these entities with data processing, whereas such entities include the Publishers and the employees of the Service Provider.</p>\n' +
	'        <p>9.7. Additionally, the Service Provider shall be authorised to share the personal data with its business partners for the purposes mentioned in section 9 of the Regulations.</p>\n' +
	'        <p>9.8. The Service Provider shall entrust data processing in order to make it possible to process the order, issue the invoice, perform the agreement, etc., for the time needed to complete these activities.</p>\n' +
	'        <p>9.9. The Service Provider is also authorised to process personal data for other legally justified purposes and goals pursued by the Service Provider and its partners, including the direct marketing of its own products or services, or the direct marketing of the products and services offered by the Service Provider’s partners.</p>\n' +
	'        <p>9.10. In order to make sure that the Advertiser processes personal data correctly, the Service Provider shall have the right to verify the correctness of data processing at any time. As regards the abovementioned right, the Service Provider shall be authorised to demand relevant actions to be performed in a specific manner. The Advertiser shall be obliged to perform the actions described in the demand within the stipulated period, and if no deadline is indicated in the demand then the actions need to be performed no later than within 3 days of the demand.</p>\n' +
	'        <p>9.11. If the Advertiser shares the personal data related to people from outside its structure with the Service Provider, with regard to the Service provision, assuming that the Advertiser is the administrator of such data (referring to clients or Internet users), then the Service Provider will be allowed to process such data as part of the entrusted data processing.</p>\n' +
	'        <p>9.12. Furthermore, the Advertiser agrees that:</p>\n' +
	'        <p style="padding-left: 30px;">9.12.1. business information related to the direct marketing of products or services offered by the Service Provider or its business partners, including newsletters, will be addressed to a designated recipient via online communication, in particular, e-mail, with the use of the data obtained by the Service Provider in the process of registration or using the Website,</p>\n' +
	'        <p style="padding-left: 30px;">9.12.2. automated calling systems will be used for the abovementioned purposes.</p>\n' +
	'        <p>9.13. Detailed rules for personal data processing, using cookies, sending business information shall be defined in the <strong><a href="static/privacyPolicy">Privacy Policy</a></strong>.</p>\n' +
	'        <p>&nbsp;</p>\n' +
	'        <p style="padding-left: 30px;"><strong>10. Liability</strong></p>\n' +
	'        <p>10.1. The Service Provider shall be liable towards the Advertiser for the lack of due diligence in the provision of Services as an intermediary, whether the Service Provider acts in the capacity of a direct or indirect representative.</p>\n' +
	'        <p>10.2. Except when it is expressly indicated that the Service Provider is liable directly to the Advertiser, the Publishers shall be liable for the results of individual Services. If such a result requires that any claims towards the Publisher be transferred onto the Advertiser, then the Service Provider shall immediately transfer its rights accordingly.</p>\n' +
	'        <p>10.3. The Service Provider shall make reasonable effort to guarantee continuous, full availability of the Service provided at all times, however, it shall not guarantee and shall not be liable for its full availability and, subject to the rights obtained upon concluding Service agreements, it reserves the right to amend, phase out, suspend or discontinue any function or feature of the Service at any time and to any extent. The Service Provider informs that amending, phasing out, suspending or discontinuing any function or feature of the Service shall not require a previous announcement.</p>\n' +
	'        <p>10.4. The above provision shall accordingly apply to the amendment of prices defined on the Website.</p>\n' +
	'        <p>10.5. The Service Provider shall not be liable for losses caused by inappropriate use of the Service, in particular when the User or other Users use the Service in a manner contrary to the provisions of these Regulations.</p>\n' +
	'        <p>10.6. The Service Provider shall not be liable for a failure to perform or for an inappropriate performance of the concluded Service agreement or for any problems with using the Website or Service, if this is the consequence of events that the Service Provider could not have predicted or avoided in spite of all due diligence, as well as fortuitous events (“force majeure”).</p>\n' +
	'        <p>10.7. In any case, the Service Provider’s liability shall not include the liability for the Advertiser’s lost earnings, and it shall not exceed the amount indicated in relevant sections of the Regulations, and if no such threshold has been defined for a specific service, the Service Provider’s liability shall be limited to a maximum amount of 100 GBP.</p>\n' +
	'        <p>10.8. The limitations of liability mentioned in section 13.7 shall not apply when the damage has been caused by the Service Provider intentionally.</p>\n' +
	'        <p>10.9. The Service Provider shall not be liable for the content and merit of the articles prepared by the Advertisers, as well as for the extent to which they correspond to the reality.</p>\n' +
	'        <p>10.10. The Service Provider shall not be liable for the effectiveness of the Services, which in particular means that the Service Provider in no way guarantees the effect of the Services, understood as increasing the Advertiser’s income.</p>\n' +
	'        <p>10.11. If any recommendations, advice, opinions are included in the Website functionality (Blog), then:</p>\n' +
	'        <p style="padding-left: 30px;">10.11.1. the Advertiser shall be under no obligation to follow such guidance;</p>\n' +
	'        <p style="padding-left: 30px;">10.11.2. if the Advertiser follows such guidance, the Advertiser shall do so at its own risk, therefore the Service Provider shall not be liable for the outcome of such actions.</p>\n' +
	'        <p>10.12. The Advertiser shall be liable for any violation of the Regulations and of the agreements concluded on the basis of the Regulations.</p>\n' +
	'        <p>10.13. If the performance of any agreement requires that the Advertiser share any information, content, other forms of expression (photos, graphics etc.), then the Advertiser guarantees that with regard to the above:</p>\n' +
	'        <p style="padding-left: 30px;">10.13.1. it will have the copyright and related rights or licences to such materials, as necessary;</p>\n' +
	'        <p style="padding-left: 30px;">10.13.2. the use of such material for the performance of the agreement will not infringe upon the moral rights of any person;</p>\n' +
	'        <p style="padding-left: 30px;">10.13.3. it will have any necessary permits for reproducing the physical likeness of people included in these materials;</p>\n' +
	'        <p style="padding-left: 30px;">10.13.4. the use of such material will not violate any industrial property rights of third parties, and in particular, will not violate the property rights to trademarks or reputable trademarks;</p>\n' +
	'        <p style="padding-left: 30px;">10.13.5. the publication of such material will not constitute an act of unfair competition;</p>\n' +
	'        <p style="padding-left: 30px;">10.13.6. the publication of such material will not violate the law, good practice, the principles of community life or reasonable interest of third parties.</p>\n' +
	'        <p>10.14. Upon ordering any of the Services, the Advertiser shall be obliged to ensure that these activities do not violate the law for any reasons other than listed above. In particular, this involves guaranteeing the compliance of advertising or promotion with the legal provisions, including the law on advertising (in public and non-public domain) such products as pharmaceuticals, tobacco, alcohol, as well as services such as gambling.</p>\n' +
	'        <p>10.15. If, with regard to the Service Provider performing its obligations under the concluded agreements, as a result of false or incomplete representations offered by the Advertiser or with regard to the violation of law by the Advertiser, any civil, enforcement, criminal or administrative proceedings are initiated against the Service Provider, or if any claims are addressed to the Service Provider in this respect, the Advertiser agrees to help the Service Provider at its own expense, as far as possible, so as to dismiss the claims or charges addressed to the Service Provider. Furthermore, in the situation as described above, the Advertiser undertakes to redress the damages suffered by the Service Provider and related to the proceedings initiated against the Service Provider, or to the claims or charges addressed against the Service Provider, i.e. to settle the justified claims reported by third parties (provided that the competent court orders this with a legally binding sentence), reimburse the equivalent of all the fines or penalties actually paid by the Service Provider and duly documented, and to cover the actual expense of the necessary legal aid paid by the Service Provider.</p>\n' +
	'        <p>10.16. The Service Provider is not the author of the articles or Content included in the Service, which is why the Service Provider may guarantee that it has obtained rights to these materials in the course of legal actions, assuming in good faith that the representations of the entities from which it obtained the rights are consistent with the actual status quo. However, if such representations or assurances of a party to an agreement concluded with the Service Provider are not consistent with the actual status quo, as a result of which the Advertiser does not come into the rights stipulated in the Regulations, then the Service Provider, acting as an intermediary in the course of specific activities stipulated in the Regulations, cannot be held liable in this respect, also with regard to the loss suffered by the Advertiser, provided it takes all the steps to transfer any claims of the Service Provider against the parties accountable for this situation onto the Advertiser.</p>\n' +
	'        <p>10.17. The Website may include references to the content included on other Internet websites. This doesn’t mean that the Service Provider of the authors of the content included on the Website take a position with regard to the content included on such websites, nor shall hey be liable for such content.</p>\n' +
	'        <p>10.18. The Advertiser may not act to the detriment of the Publisher, which means it may not include unnatural links to the published articles (or any other posts in social media), insert unnatural comments under the articles, or direct unnatural Internet traffic to the articles. If any such actions are observed, the Advertiser shall authorise the Service Provider to cause the Publisher to delete the published article or a post in social media, whereas the Advertiser will not have the right to make any claims, including those related to the paid fee.</p>\n' +
	'        <p>10.19. The Advertiser hereby agrees to the Service Provider transferring the rights or obligations following from the Regulations or from an agreement concluded pursuant to the Regulations.</p>\n' +
	'        <p>&nbsp;</p>\n' +
	'        <p style="padding-left: 30px;"><strong>11. Complaints</strong>&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;</p>\n' +
	'        <p>11.1. The provisions of this section shall apply considering the previous provisions of the Regulations, which in particular means that if the Regulations stipulate a specific period for the Advertiser to report a complaint, then the Advertiser shall be bound by this period, rather than the one described in the following sections.</p>\n' +
	'        <p>11.2. Complaints related to the Service should be reported by e-mail, to the address: help@marketin9.com.</p>\n' +
	'        <p>11.3. A complaint should include at least the following information: date and time of an event that is the subject matter of the complaint, e-mail address of the complainant and a brief description of the reservations.</p>\n' +
	'        <p>11.4. Complaints shall be examined within 14 working days of receipt, in the order in which they are received. If the abovementioned period cannot be observed, the Service Provider shall inform the complainant of this fact, providing the reason behind the extension of the period and the predicted date of the reply.</p>\n' +
	'        <p>11.5. The complainant will be informed of the way the complaint was handled by e-mail sent to the address provided along with the complaint.</p>\n' +
	'        <p>&nbsp;</p>\n' +
	'        <p style="padding-left: 30px;"><strong>12. Final provisions</strong>&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;</p>\n' +
	'        <p>12.1.<strong>&nbsp;</strong>In the matters not regulated herein, the provisions of the mandatory common law shall apply.</p>\n' +
	'        <p>12.2.<strong>&nbsp;</strong>Any disputes between the Advertiser and the Service Provider shall be solved amicably, and if no agreement is reached, they will be referred to a competent court having jurisdiction over the Service Provider.</p>\n' +
	'        <p>12.3.<strong>&nbsp;</strong>The Regulations may be subject to amendments from time to time, whereas the Advertiser shall be informed of such amendments. If the Advertiser who has an account on the Website does not approve the amendments, then it shall have the right to offer a representation to delete the account and terminate the cooperation, whereas such a representation must be filed in writing or by e-mail sent to the following addresses: help@marketin9.com and must be sent by the effective date of the proposed amendments.</p>\n' +
	'        <p>12.4.<strong>&nbsp;</strong>The Advertiser represents it has become familiar with these Regulations, appendices to these Regulations, including the regulations referred to herein, as well as with the Service Provider’s <a href="static/privacyPolicy">Privacy Policy</a>.</p>\n' +
	'        <p>12.5.<strong>&nbsp;</strong>The Regulations are effective as of 02/01/2023</p>\n' +
	'        <p>&nbsp;</p>\n' +
	'        <hr>\n' +
	'        <p><strong><a name="publishers"></a>II.&nbsp;Regulations for publishers on the Marketin9 platform</strong></p>\n' +
	'        <p style="padding-left: 30px;"><strong>1. GENERAL PROVISIONS</strong></p>\n' +
	'        <p>1.1. These Regulations define the rules for providing electronic service through an Internet Website at the following address: www.marketin9.com (hereinafter: “the Website”), whereas the service shall be provided to the Internet end users who visit the Website and perform all the legal and actual activities as defined in the Regulations (hereinafter: “Users”).</p>\n' +
	'        <p>1.2. The Service shall be provided by:</p>\n' +
	'        <p style="padding-left: 40px;">ORION MEDIA GROUP sp. z o.o. sp.k.<br>\n' +
	'        ul. Niekłańska 35 lok. 3, 03-924 Warsaw, Poland<br>\n' +
	'        NIP 1132869511<br>\n' +
	'        Regon 146909237<br>\n' +
	'        NR KRS 0000480304</p>\n' +
	'        <p>(hereinafter: “Service Provider”).</p>\n' +
	'        <p>1.3. These Regulations refer to electronic service provision.</p>\n' +
	'        <p>1.4. The Service related to making the Website available shall be provided once access is obtained to the Website or to the selected functionalities.</p>\n' +
	'        <p>1.5. Such an agreement for the provision of the Service mentioned in section 1.4 is concluded when the User accepts the Regulations. The scope and manner of using the above mentioned Services shall be defined by the Service Provider in the Regulations and in the documents referred to in the Regulations.</p>\n' +
	'        <p>1.6. The Website functions selected by the Service Provider are available for a consideration. The scope of such Services and the payment shall be defined by the provisions of the documents available on the Website, which refer to a particular Service. An agreement for Service provision for a consideration is concluded at the moment defined in the provisions of the Regulations referring to a particular Service. The scope and manner of using Services provided for a consideration shall be defined by the Service Provider in the Regulations and in the documents referred to in the Regulations.</p>\n' +
	'        <p>1.7. Before concluding the agreement related to the Website functionality, the Parties shall be obliged to confirm the accuracy of the submitted order and the order approval statement.</p>\n' +
	'        <p>1.8. If a Party confirms the reception of the offer to the other Party before concluding the agreement, which may also be done automatically, through the Website functionalities, such a confirmation does not result in concluding the agreement, but such an offer shall be binding upon the Party that submitted the offer.</p>\n' +
	'        <p>1.9. Concluding the agreement as well as the relevant provisions thereof shall be consolidated in an e-mail sent to the Parties via the Website functionality.</p>\n' +
	'        <p>1.10. The agreement may only be concluded in English.</p>\n' +
	'        <p>1.11. None of the agreements stipulated in the Regulations may be concluded implicitly.</p>\n' +
	'        <p>1.12. Provided Services include in particular:</p>\n' +
	'        <p style="padding-left: 30px;">1.12.1 acting as an intermediary between Publishers and Advertisers in the process of publishing paid (sponsored) articles;</p>\n' +
	'        <p style="padding-left: 30px;">1.12.2. pursuant to relevant provisions of the Regulation, applicable to a Service, acting as an intermediary may involve acting for the benefit and on behalf of the Website User or on the Service Provider’s own behalf, but for the benefit of the User.</p>\n' +
	'        <p>1.13. The Publisher shall publish a list of its Internet Portals or blogs (hereinafter: “Portals”) on the Website, covered by the intermediary service mentioned in section 1.12. For each Portal, the Publisher shall provide the information concerning its address, subject matter, description, popularity and other details of importance for the Advertisers. Additionally, as part of the registration process, the Publisher must mark the settlement method, corresponding to its legal status:</p>\n' +
	'        <p style="padding-left: 30px;">1.13.1. EU Based</p>\n' +
	'        <p style="padding-left: 30px;">1.13.2. UK Based</p>\n' +
	'        <p style="padding-left: 30px;">1.13.3. Rest of the World</p>\n' +
	'        <p>1.14. In these Regulations, working days shall be understood as any day other than Saturday, Sunday and other bank holidays as stipulated in Polish</p>\n' +
	'        <p>law. If the Regulations refer to “days”, it shall be understood as calendar days.</p>\n' +
	'        <p>1.15. The provisions of other documents available on the Website and referred to in these Regulations are an integral part of the Regulations. Should the provisions of any such documents be contrary to the Regulations, the Publisher shall be bound by the provisions of the Regulations.</p>\n' +
	'        <p>1.16. In the event of an ownership change (pursuant to a sales agreement or other contract) regarding the Portal where a particular article has been published as per the Regulations, then the Publisher shall be obliged to cause the new owner to continue using the provided Portal space for the period previously agreed upon, under pain of liability for damages towards the Service Provider, related to the Advertiser’s claims addressed directly to the Service Provider.</p>\n' +
	'        <p>&nbsp;</p>\n' +
	'        <p style="padding-left: 30px;"><strong>2. Service and using the service; payments for the service</strong></p>\n' +
	'        <p>2.1. The service shall include in particular acting as an intermediary between the Publisher and Advertisers, in the process of providing Portal space and publishing sponsored articles (hereinafter: “the Service”). The Service shall also refer to other services mentioned in the Regulations.</p>\n' +
	'        <p>2.2. A Publisher shall be understood as a system User entered in the Publishers’ database by the Service Provider, who provides its Portal space for the purpose of publishing articles delivered to it.</p>\n' +
	'        <p>2.3. The Publisher must possess full right to dispose of and manage the Portals, including the content uploaded thereon. If these rights are relative, i.e. are based on obligation contracts (rather than property contracts), then the Publisher shall inform the Service Provider of this fact and present relevant authorisations from the owner of a specific Portal, without prejudice to the owner’s right to start direct cooperation with the Service Provider in the capacity of a Publisher.</p>\n' +
	'        <p>2.4. An Advertiser shall be understood as a Website User who hands over articles completed with other kinds of content (e.g. infographics, photos) to be published by the Publisher on the provided Portal space.</p>\n' +
	'        <p>2.5. The Service Provider may introduce accessory services and extensions.</p>\n' +
	'        <p>2.6. In order to use the service properly, a PC, Mac or similar computer shall be necessary, connected to the Internet and having an operational system (Windows, Mac OS, Linux or similar) as well as an Internet browser (Internet Explorer, Firefox, Chrome, Opera, Safari). Some services and tools may require access to an e-mail account, whereas in order to use the services offered it may be necessary to have the Adobe Flash Player software, version 9 or later and JavaScript use must be enabled, unless stipulated otherwise.</p>\n' +
	'        <p>2.7. While the Service is being used, cookies are installed in the User’s information system. The rules for using cookies are defined by the <strong><u><a href="static/privacyPolicy">PRIVACY POLICY</a>.</u></strong></p>\n' +
	'        <p>2.8. If, pursuant to the arrangements made with the Service Provider, the Publisher uses a tracking code developed by the Service Provider, then the Publisher represents that with regard to each Portal used to provide the Service it has the subscribers’ or end users’ consent&nbsp; as required by the law, so as to make it possible to install the codes, store information and obtain access to the information stored in a telecommunications terminal device, as well as to&nbsp; use this information pursuant to the rules described in the&nbsp;<a href="static/privacyPolicy"><strong><u>PRIVACY POLICY</u></strong></a>.</p>\n' +
	'        <p>2.9. Detailed rules related to the payments for the Services, including the Publisher’s remuneration rates, shall be defined in specific documents uploaded on the Website, in the sections applicable to a particular Service.</p>\n' +
	'        <p>2.10. As regards the Services provided, the Publishers shall be obliged to make tax-related settlements on their own account and at their own expense (also with regard to other public law liabilities), to the extent related to the Service provision and the relevant income.</p>\n' +
	'        <p>2.11. If according to the mandatory law it is the Service Provider that is liable to pay the income tax (or other public law liabilities) on the Services provided by the Publisher, this fact shall not result in an increase of the Publisher’s remuneration, which means that the remuneration defined in the relevant sections of the Website is a gross amount, unless VAT is applicable. Additionally, in this situation, the Publisher shall be obliged to supply the Service Provider with all the information necessary to determine the tax amount (advance tax payments).</p>\n' +
	'        <p>2.12. Payments towards the Publisher and Service Provider shall be made by a bank transfer, to the bank account indicated by the Publisher and Service Provider on the invoice or to an indicated Paypal account. The payment date shall be understood as the day when the bank account of the Service Provider or the Publisher, respectively, is credited with the full payable amount.</p>\n' +
	'        <p>2.13. The prices for the services provided to the Publisher shall be defined by the Service Provider on the Website and may be amended from time to time, whereas for the purpose of the settlements (agreement) between the Publisher and the Service Provider, the binding prices are those valid as at the date when the Publisher ordered the Service.</p>\n' +
	'        <p>2.14. The Service Provider and the Publisher authorise one another to use electronic invoices. If the Publisher is not obliged to issue a VAT invoice, the settlements shall be made on the basis of bills, whereas the provisions of the Regulations related to VAT invoices shall apply accordingly.</p>\n' +
	'        <p>2.15. If the Service Provider acts as an intermediary in providing the Services, also between the Advertiser and the Publisher, then the Service Provider is exclusively authorised to make settlements related to the Services provided pursuant to the Regulations, which means in particular that the Publisher may not make any settlements in this respect on its own with the Advertiser or any other Website User.</p>\n' +
	'        <p>2.16. The Publisher shall be obliged to make any payments due to the authors or organisations that represent them, pursuant to the mandatory legal provisions, but only when it is required by the law and the payment cannot be made by the Advertiser.</p>\n' +
	'        <p>2.17. The prices stipulated on the Website are net prices, defined in British pound sterling; VAT shall be added to such prices, if it is due with respect to a particular Service or is applicable to the User who provides the Service.</p>\n' +
	'        <p>&nbsp;</p>\n' +
	'        <p style="padding-left: 30px;"><strong>3. Registration on the Website</strong></p>\n' +
	'        <p>3.1. A Website User may be a person indicated in section 2.2 who accepts the provisions of the Regulations and completes the registration procedure on the Website ending with an effective account setup.</p>\n' +
	'        <p>3.2. The registration procedure involves completing an electronic registration form, according to the instructions, and accepting the Regulations as well as ticking&nbsp; the consent checkboxes related to the issues pointed out by the Service&nbsp; Once these Regulations are accepted by the Publisher, the agreement between the Publisher and the Service Provider is concluded, related to the rules for using the Website (its availability).</p>\n' +
	'        <p>3.3. Following the registration, a user account shall be assigned to the Publisher. A Publisher may only have one account on the Website. The final decision on the registration is made by the Service Provider, who may refuse to approve it if the Publisher or its Portal do not conform to the requirements stipulated in the Regulations.</p>\n' +
	'        <p>3.4. Upon registration, the settlement system assigns the relevant taxation mode, proper for the seat or residence address of a particular user. This mode cannot be changed afterwards. If the Publisher’s legal status requires a change of the taxation mode, then the Publisher shall be obliged to inform the Service Provider immediately of this fact. Then the Service Provider shall block the Publisher’s current account, provided such a change does not infringe upon the remaining provisions of the Regulations, and register a new one. Afterwards, the funds shall be transferred according to the standards applicable to international settlements (if any such standards exist at the time).</p>\n' +
	'        <p>3.5. The account may be removed or suspended by the Service Provider without any notice, should the Service provider find out that:</p>\n' +
	'        <p style="padding-left: 30px;">3.5.1. the Publisher violates the provisions of the Regulations, including the legal provisions referred to in the Regulations;</p>\n' +
	'        <p style="padding-left: 30px;">3.5.2. the Publisher does not perform its obligations properly;</p>\n' +
	'        <p style="padding-left: 30px;">3.5.3. the data provided by the Publisher is false or incomplete, including in particular with regard to the information about the Portals and settlement mode;</p>\n' +
	'        <p style="padding-left: 30px;">3.5.4. the Publisher did not log on the Website for a period of 12 consecutive months of the last logging date; if the period without logging is longer than 3 months, the account may be suspended.</p>\n' +
	'        <p>3.6. The account may also be deleted if the Portal (all of the Publisher’s Portals) does not (do not) conform to the requirements stipulated in the Regulations.</p>\n' +
	'        <p>3.7. Providing such contact details as an e-mail address or phone number upon registration authorises the Service Provider to send business information to the User.</p>\n' +
	'        <p>3.8. In order for a deleted or blocked account to be reassigned to the Publisher or unlocked, the infringements mentioned in section 3.5. must be removed. The Service Provider’s consent is also necessary for the account to be reassigned or unlocked, whereas the Service Provider shall be under no obligation to grant the consent.</p>\n' +
	'        <p>&nbsp;</p>\n' +
	'        <p style="padding-left: 30px;"><strong>4. Detailed rules for the Service provision</strong></p>\n' +
	'        <p>4.1. The Website is the database of Publishers and Advertisers. The database is verified on a current basis and any changes to it are not regarded as amendments to the Regulations.</p>\n' +
	'        <p>4.2. The Publisher represents it is authorised to provide Portal space for the publication of articles uploaded on the Website and that the information provided about its Internet websites is true. In particular, the Publisher represents that providing Portal space and publishing articles on the Portals as well as sharing information about its Internet websites does not violate any rights of third parties, and that it has all the permits and approvals necessary for such publications, such as the consent related to personal data processing or the installation of cookies. Furthermore, the Publisher represents that if any claims related to the provided Portal space and publication are addressed to the Service Provider, then the Publisher shall reimburse all the expenses and costs incurred in this respect to the Service Provider.</p>\n' +
	'        <p>4.3. The Publisher shall be obliged to take care of the proper quality of the Portal, in particular as regards the technical aspects of its functioning, its layout and content. Basic quality requirements in this respect shall be defined by the Website:</p>\n' +
	'        <p style="padding-left: 30px;">4.3.1. the Portal must have the Ahrefs Domain Rating of at least 10,</p>\n' +
	'        <p style="padding-left: 30px;">4.3.2. the Portal may not be connected to a link exchange network.</p>\n' +
	'        <p style="padding-left: 30px;">4.3.3. The Portal must include no viruses in its source code or links to websites with illegal content.</p>\n' +
	'        <p>4.4. The Publisher acknowledges and accepts that:</p>\n' +
	'        <p style="padding-left: 30px;">4.4.1. the requirements described above in section 4.3. are of basic nature, therefore it is not an exhaustive list of requirements related to the Portal;</p>\n' +
	'        <p style="padding-left: 30px;">4.4.2. the Service Provider shall be the only one to decide whether the Publisher’s Portal conforms to the requirements and in this respect, the Service Provider shall act in the properly conceived interest of the Advertisers as the end clients and beneficiaries of the Service.</p>\n' +
	'        <p>4.5. The Publisher represents that with regard to the content published independently on the provided Portal space, i.e. not in the performance of an agreement concluded between the Publisher and the Service Provider:</p>\n' +
	'        <p style="padding-left: 30px;">4.5.1. it will have the copyright and related rights or licences to such materials, as necessary;</p>\n' +
	'        <p style="padding-left: 30px;">4.5.2. the use of such material for the performance of the agreement will not infringe upon anybody’s moral rights;</p>\n' +
	'        <p style="padding-left: 30px;">4.5.3. it will have any necessary permits for reproducing the physical likeness of people included in these materials;</p>\n' +
	'        <p style="padding-left: 30px;">4.5.4. the use of such material will not cause a violation of any industrial property rights of third parties, and in particular, will not violate the property rights to trademarks or reputable trademarks;</p>\n' +
	'        <p style="padding-left: 30px;">4.5.5. the publication of such material will not constitute an act of unfair competition;</p>\n' +
	'        <p style="padding-left: 30px;">4.5.6. the publication of such material will not violate the law, good practice, the principles of community life or reasonable interest of third parties.</p>\n' +
	'        <p>4.7. The Service Provider shall have the right to delete a specific Publisher’s Portal from the Website, if the Publisher violates any provision of the Regulations with respect to the information about the Portal or its content, in particular in the situations mentioned in section 4.3.</p>\n' +
	'        <p>&nbsp;</p>\n' +
	'        <p style="padding-left: 30px;"><strong>5. Sponsored (paid) articles</strong></p>\n' +
	'        <p>5.1. Sponsored articles (hereinafter: “sponsored articles” or “paid articles”) are texts (along with the media attached) that advertise the Advertiser’s product, company or service, as well as other texts that provide information. When supplying a paid Article for publication, the Advertiser shall define whether its content is unique, i.e. not published on the Internet before, or whether it has already been published on the Internet. The Advertiser should expressly place a relevant label on the text and it shall be visible ion the Website both for the Service Provider and for the Publisher.</p>\n' +
	'        <p>5.2. As part of the Service, it is also possible for the Publisher to prepare a paid article. In this case, the rules for the preparation of such material shall be defined by the arrangements made between the Publisher and the Service Provider, whereas the Advertiser shall obtain no rights to the content or form of such a paid Article prepared by the Publisher, and the rules for providing Portal space and publication shall be defined in the Regulations. An article prepared by the Publisher or its publication may not violate the law, including the copyright of third parties, industrial property rights, it may not violate good practice, the principles of community life, the legitimate interest of third parties and it may not be an act of unfair competition.</p>\n' +
	'        <p>5.3. Through the Website, the Publisher shall define:</p>\n' +
	'        <p style="padding-left: 30px;">5.3.1. technical requirements for the sponsored articles published on its sites;</p>\n' +
	'        <p style="padding-left: 30px;">5.3.2. net remuneration in British pound sterling payable by the Service Provider to the Publisher for the publication of each article;</p>\n' +
	'        <p style="padding-left: 30px;">5.3.3. whether the remuneration depends on additional parameters as defined by the Publisher (e.g. sharing information on the Facebook profile);</p>\n' +
	'        <p style="padding-left: 30px;">5.3.4. Sample reasons why a sponsored Article may not be accepted for publishing.</p>\n' +
	'        <p>5.4. The Service Provider shall use the Website to search for Advertisers interested in Portal space and publication of sponsored articles on the Publisher ’s sites, whereas the Service Provider shall not make any relevant promise in this respect, with regard either to enlisting an Advertiser or to the turnover generated with the Service.</p>\n' +
	'        <p>5.5. The Service Provider shall use the Website to order the provision of the Publisher’s Portal space on behalf of the Advertiser, so that articles may be published there, whereas the following conditions must be fulfilled before:</p>\n' +
	'        <p style="padding-left: 30px;">5.5.1. the content of the article is ready and available on the Website, to be viewed by the Publisher (along with attachments, structure, layout of the links);</p>\n' +
	'        <p style="padding-left: 30px;">5.5.2. the content of the article must be labelled by the Advertiser as unique or non-unique.</p>\n' +
	'        <p>5.6. The Service Provider represents that as per the provisions of the <strong><a href="#advertisers">ADVERTISER’S REGULATIONS</a>, </strong>it is the Advertiser who guarantees that a paid article approved for publishing will comply with the legal provisions as regards its content and eligibility for publishing, and that it shall hold the copyright to the content and other forms employed in this article (photos, graphics etc.). The Service Provider shall be under no obligation to verify whether provided paid articles (as well as the consequences of publishing them subsequently) do not constitute a breach of law as regards their content, eligibility for publishing and the ownership of the rights to the content and other forms employed in the Articles (photos, graphics etc.). The Service Provider shall not be liable for any breach of law caused by paid Articles.</p>\n' +
	'        <p>5.7. The Publisher shall receive information on the order by e-mail, no later than within 1 working day after the Service Provider has obtained such information from the Advertiser. The agreement related to providing Portal space and publishing a paid Article shall be concluded once the Advertiser’s order is approved by the Publisher, i.e. once the content of the article is downloaded and the publication is labelled by the Publisher (section 5.9). In this case, the Publisher undertakes to publish the article in the form and content as provided by the Website within 3 working days of the date when the relevant information about the order was approved. In special cases, this period may differ, provided it is explicitly highlighted on the Website, next to the Publisher’s offer and Portal.</p>\n' +
	'        <p>5.8. In the event of extraordinary guidelines as to when the Portal space is to be provided and when the article is to be published, or when there are reasons for the Publisher to reject the article, or in case of any other extraordinary wishes the Advertiser may express, the Publisher shall have the right to refrain from the publication of a sponsored article.</p>\n' +
	'        <p>5.9. With regard to providing Portal space and publishing a paid Article, Publisher shall have no more than 24 hours to mark in the Website system that the Portal space has been provided and the pertinent content has been published. “Marking”, as mentioned above, shall mean that the Publisher inserts the Internet address of the provided Portal. Such marking results in an automatic notification – an e-mail shall be sent to the Advertiser, informing that the paid Article has been published.</p>\n' +
	'        <p>5.10. Once the article has been published and the relevant fact has been marked on the Website, the Advertiser shall verify that the Portal has been provided and the article has been duly published, whereas the Service Provider shall have the same verification right, but shall be under no obligation to exercise it. Verification involves checking that the provided Portal space and publication of the Article are consistent with the relevant agreement concluded to this end. The verification shall take place no later than within 3 working days of the day when the Publisher marked the publication on the Website. If it is the Publisher that prepares the Paid Article, pursuant to the Publisher’s offer accepted by the Advertiser, then the Advertiser shall approve the content within 3 working days of the day when the Publisher provides the Paid Article – to this extent, the provision of section 7.12 of the Regulations shall apply accordingly.<br>\n' +
	'        Should the Advertiser fail to verify the published article within 3 working days of the date when the publication is marked by the Publisher, it shall be deemed that the paid Article has been published correctly.</p>\n' +
	'        <p>5.11. If no Portal space is provided, the paid Article is published by the Publisher in a way that is not consistent with the agreement, or if there is a delay in providing Portal space and publication, the Service Provider and the Advertiser shall decide whether:</p>\n' +
	'        <p style="padding-left: 30px;">5.11.1. the Publisher shall amend the published content – then the Service Provider and the Advertiser shall define the deadline and scope of the amendments;</p>\n' +
	'        <p style="padding-left: 30px;">5.11.2. the Advertiser shall withdraw from the agreement – wholly or partially</p>\n' +
	'        <p>5.12. If the Portal space has been provided correctly and the article has been duly published, the Publisher issues a VAT invoice towards the Service Provider, for the amount previously defined by the Publisher on the Website, pursuant to the provisions of section 5.3 b) and c). The Publisher may choose to issue a mass invoice, including all the instances of provided Portal space and publications from a selected period.</p>\n' +
	'        <p>5.13. The Service Provider undertakes to pay the VAT invoice no later than within 7 working days of receiving the properly issued invoice.</p>\n' +
	'        <p>5.14. The Publisher undertakes to maintain the publication of the article for a period of at least one year, without any changes made to its content, unless it was stipulated otherwise when the Service type was determined.</p>\n' +
	'        <p>5.15. The Service Provider may use electronic means of communication to address the Publisher and request that an article be deleted from the Publisher’s site, without having to offer any reason for such a request. In this situation, the Publisher undertakes to delete the article within 3 working days of receiving the relevant information.</p>\n' +
	'        <p>5.16. If an article is deleted or its content is changed without due approval within one year of the publication, the Publisher shall be liable. This refers in particular to the following situations:</p>\n' +
	'        <p style="padding-left: 30px;">5.16.1. the Publisher knowingly deletes the article or changes its content (deletes the links);</p>\n' +
	'        <p style="padding-left: 30px;">5.16.2. the Publisher’s site is permanently inaccessible, due to the Publisher’s fault</p>\n' +
	'        <p style="padding-left: 30px;">5.16.3. the rights to the Portal are transferred and the new owner discontinues the publication (section 1.16).</p>\n' +
	'        <p>5.17. The period of providing Portal space and publishing the paid Article may be reduced, without giving rise to any claims towards the Publisher, including the reimbursement of the fee or compensation, in the following situations:</p>\n' +
	'        <p style="padding-left: 30px;">5.17.1. when the publication was deleted, because it constituted a breach of legal provisions or there was reasonable suspicion that it is a breach of law;</p>\n' +
	'        <p style="padding-left: 30px;">5.17.2. when the publication was deleted, because it violated the rights of third parties or there was reasonable suspicion it violated the rights of third parties;</p>\n' +
	'        <p style="padding-left: 30px;">5.17.3. when the publication was deleted at the Advertiser’s request;</p>\n' +
	'        <p style="padding-left: 30px;">5.17.4. when the Advertiser intentionally acts to the detriment of the Publisher, e.g.&nbsp; by unnatural links to the article;</p>\n' +
	'        <p style="padding-left: 30px;">5.17.5. when the Advertiser violates the provisions of the Regulations which refer to its obligations after the Article is published;</p>\n' +
	'        <p style="padding-left: 30px;">5.17.6. when it is the result of force majeure;</p>\n' +
	'        <p style="padding-left: 30px;">5.17.7. the Publisher has closed down the Portal and does not continue this activity on another Portal.</p>\n' +
	'        <p>5.18. If the rules for providing the Portal and for the publication are violated, including the situation mentioned in section 5.11 or 16, provided that the Advertiser addresses its claims to the Service Provider, the Service Provider (or the Advertiser – when the Service Provider transfers its recourse claims towards the Publisher onto the Advertiser, to which the Publisher agrees) may address the relevant claims to the Publisher, whereas the amount of such claims shall not exceed the remuneration defined for a specific instant of providing Portal space and publication, except for the situations of intentional fault.</p>\n' +
	'        <p>5.19. If there are grounds for the Publisher to reimburse the remuneration, including the withdrawal, as mentioned in section 5.11, and the Service Provider does not obtain the reimbursement of the remuneration from the Publisher within 14 days, the Advertiser shall have the right to claim the reimbursement of the cost of providing the Portal space and the publication of the article directly from the Publisher, and to this end, the Service Provider shall share the Publisher’s address and contact details with the Advertiser, to which the Publisher agrees, including to the extent of the transfer of the Service Provider’s rights onto the Advertiser.</p>\n' +
	'        <p>5.20. The Publisher shall have the right to amend the URL address of the provided Portal space containing the Paid Article, within the publication period as mentioned in section 5.14. In the event of amending the address or domain, this must be done while maintaining the 301 Redirect. Such an amendment shall not be treated as a violation of the rules of providing Portal space and publishing the Paid Article.</p>\n' +
	'        <p>5.21. If the Publisher receives the manual filter titled „Unnatural links from your site” or similar (from Google) regarding the portal where the Article is published, the Publisher may switch the attribute of the links in the article to nofollow. Such an activity shall not be treated as a breach of the publishing agreement.</p>\n' +
	'        <p>5.22. The Publisher undertakes not to contact the Advertiser directly, disregarding the Service Provider, in the matters related to providing Portal space and publishing paid Articles, which are to be or have been published through the Website. This does not apply to the situations expressly allowed for in the Regulations.</p>\n' +
	'        <p style="padding-left: 30px;"><strong>6. Preparing articles and infographics</strong></p>\n' +
	'        <p>6.1. The Publisher may order the preparation of paid articles or expert articles through the Service Provider, whereas such articles shall be for the Publisher’s own purposes.</p>\n' +
	'        <p>6.2. When the Publisher orders the preparation of an article or infographics (hereinafter: “Content”):</p>\n' +
	'        <p style="padding-left: 30px;">6.2.1. The Publisher shall define the type of requested Content and provides any extra comments ad information;</p>\n' +
	'        <p style="padding-left: 30px;">6.2.2. The Publisher shall collect the remuneration for preparing the Content, pursuant to the current price-list for this service, available on the Website.</p>\n' +
	'        <p>6.3. The Publisher may order the preparation of the content – an article – within one of the packages described on the Website. The date of completing such a service depends on the ordered package and is defined by the Website.</p>\n' +
	'        <p>6.4. The Publisher may also order the preparation of content in the form of infographics based on the data provided by the Publisher, or it may order the preparation of data and the relevant infographics, using one of the packages described on the Website. The date of completing such a service depends on the ordered package and is defined by the Website.</p>\n' +
	'        <p>6.5. The Publisher guarantees that the data and other elements provided under an order shall be consistent with the legal provisions as regards the content and availability for publication, and that it will have the right to their content and other forms of expression (photos, graphics etc.).</p>\n' +
	'        <p>6.6. The Service Provider shall be under no obligation to verify whether the data or other elements provided by the Publisher do not constitute a breach of law as regards their content, availability for publication and ownership rights.</p>\n' +
	'        <p>6.7. The price for the service related to content preparation shall be defined by a relevant Website section devoted to this service. The Website may additionally define extra services offered by the Service Provider without an extra fee, which may be related to a specific order from time to time, which in particular refers to such elements as stock photos.</p>\n' +
	'        <p>6.8. Placing an order by the Publisher shall be tantamount to accepting the terms and conditions for preparing the content, as stipulated in the Regulations. It shall also be the basis for the Service Provider to collect a fee.</p>\n' +
	'        <p>6.9. The content preparation agreement shall be deemed concluded once the Service Provider approves the Publisher’s order, of which the Publisher is notified through the Website functionality. The Service Provider shall be under no obligation to approve an order.</p>\n' +
	'        <p>6.10. A content writer shall prepare the Content within the deadline stipulated on the Website. If the subject matter is complex or there is a considerable amounts of orders, the deadline for completing the Service may be extended, whereas the Website shall immediately notify the Publisher of this fact, at the same time indicating the extended deadline, which may not, however, be longer than another 3 working days.</p>\n' +
	'        <p>6.11. Once the content is prepared, the Website provides it to the Publisher, who shall have the right to offer comments or approve the content. If the Publisher fails to verify the content within 3 working days of receiving it, it shall be deemed that the Content has been prepared according to the order. Any later amendments requested by the Publisher may be treated as a form of an amendment to the agreement for content preparation and as such shall require approval, which means in particular that the Publisher may have to incur extra costs.</p>\n' +
	'        <p>6.12. If the Content is prepared in a way that is not consistent with the agreement, or if the deadline is not observed, and notification of this fact is provided as stipulated in section 7.11 of the Regulations, except for the situation mentioned in the last sentence of section 7.11, the Service Provider and the Publisher shall decide whether:</p>\n' +
	'        <p style="padding-left: 30px;">6.12.1. The content writer is to amend the content – then the Service Provider and the Publisher define the deadline, no shorter than 3 working days, and the scope of the amendments. If the Journalist refuses to consider the amendments, which it shall have the right to do, then the withdrawal right shall be applicable as mentioned below under letter b);</p>\n' +
	'        <p style="padding-left: 30px;">6.12.2. The Publisher withdraws from the whole agreement or a part thereof – then the fee paid by the Publisher shall be reimbursed in the portion corresponding to the scope of the withdrawal.</p>\n' +
	'        <p>6.13. The Publisher shall have no right to impose the guidelines related to the length and type of content, other than those defined by default for the specific content type as offered by the Website when the order is placed. These circumstances may not be treated as the basis for a complaint as mentioned in section 7.12 of the Regulations.</p>\n' +
	'        <p>6.14. If any of the circumstances mentioned in section 7.12 of the Regulations occurs as a result of a discrepancy between the Service and the agreement, any related claims may not exceed the amount of the fee defined for content preparation.</p>\n' +
	'        <p>6.15. In extraordinary situations, the Service Provider reserves the right to charge extra fees for non-standard operations, even if they are not stipulated on the Website or in the agreement for Content preparation, should such situations occur as a result of the Publisher’s fault or were implemented at the Publisher’s request.</p>\n' +
	'        <p>6.16. Once the Content is approved, the Service Provider shall grant the Publisher a licence for the Content, unlimited in time and territory, whereas the licence shall be included in the fee collected for content preparation and shall cover the following fields of use:</p>\n' +
	'        <p style="padding-left: 30px;">6.16.1. recording and reproducing with any technique, including printing, reprography, digital technique as well as on magnetic, optical and electronic media, including recording and saving as an e-book or audiobook, in an unlimited number of copies, releases and reprints, both on its own and in a collection, with other pieces or materials that do not have the features of a creation;</p>\n' +
	'        <p style="padding-left: 30px;">6.16.2. marketing and distribution of copies or other media in any distribution channels, without limits as to the amount, subject matter or territory, or with regard to the target audience, in any available distribution channels, in particular in retail, news stands, sales via mail order, in trading networks, book clubs, cross-selling, direct marketing, mail order, door to door, press inserts, Internet trading and business to business sale (delivery as ordered by companies), as well as free distribution;</p>\n' +
	'        <p style="padding-left: 30px;">6.16.3. lease or rental of the copies;</p>\n' +
	'        <p style="padding-left: 30px;">6.16.4. uploading the material to computer memory and storing it; sharing it in the public domain via ICT networks, especially in the form of an e-book or audiobook, and so that everybody can access it in any place and at any time, as convenient,</p>\n' +
	'        <p style="padding-left: 30px;">6.16.5. broadcasting and retransmission via wired, wireless or satellite transmission;</p>\n' +
	'        <p style="padding-left: 30px;">6.16.6. public performance, show, display or replay;</p>\n' +
	'        <p style="padding-left: 30px;">6.16.7. using the material in any forms of advertising;</p>\n' +
	'        <p>6.17. Upon granting the licence, the Service Provider agrees for the material including the Content to be used and handled within the fields mentioned in section 7.16 of the Regulations.</p>\n' +
	'        <p>6.18. Furthermore, the Service Provider represents that it has obtained a guarantee from the originator of the Content that after the transfer of copyright, the originator will not exercise his/her personal rights; in particular, the originator has agreed for the content to be used without indicating authorship.</p>\n' +
	'        <p>6.19. Should the copyright granted to the Publisher be violated, the Service Provider shall be liable up to the maximum amount equal to the fee collected for content preparation.</p>\n' +
	'        <p style="padding-left: 30px;"><strong>7. Modifying published articles</strong></p>\n' +
	'        <p>7.1. The Advertiser shall have the option to use the Website to request changes to the articles published through the Website, i.e. paid Articles and guest expert articles.</p>\n' +
	'        <p>7.2. Changes to the published articles shall be introduced by the Publisher, but considering the Advertiser’s guidelines.</p>\n' +
	'        <p>7.3. Communication related to the changes in the published articles shall be made exclusively through the Website. The Publisher undertakes not to contact the Advertiser directly with reference to the articles published through the Website.</p>\n' +
	'        <p>7.4. Requesting changes to the published articles is paid and the cost of such service depends on the scope of the changes and the cost defined by the Publisher.</p>\n' +
	'        <p>7.5. The default amount of remuneration payable to the Publisher for introducing changes in the content shall be defined automatically on the Website and calculated on the basis of the remuneration received by the Publisher for the publication of the article.</p>\n' +
	'        <p>7.6. As part of its functionality, the Publisher may amend the cost of introducing modifications in a published article, depending on the type of changes proposed by the Advertiser.</p>\n' +
	'        <p>7.7. The Advertiser shall use the Website functionality to approve or reject the offer within 3 days of the new price being defined. Failure to approve the price within the abovementioned deadline means rejecting the offer; in this case, the agreement is not effective. Approving the price, on the other hand, shall be regarded as the moment when the agreement is concluded.</p>\n' +
	'        <p>7.8. At each stage of the negotiations the Publisher may refuse to introduce modifications to a published article.</p>\n' +
	'        <p>7.9. If the agreement is effective, the Publisher shall be obliged to publish the modifications to the content of the article within 3 working days.</p>\n' +
	'        <p>7.10. With regard to the publication of the article, the Service Provider shall use the automated functions of the Website to address the Publisher and request that the modification in the publication be marked in the Website system within 24 hours of introducing such modifications. Marking the modifications in the system results in an automatic notification: an e-mail shall be sent to the Advertiser, informing that the modification has been published.</p>\n' +
	'        <p>7.11. Once the changes are introduced in the Article and this fact has been marked on the Website, the Advertiser shall verify that the modifications are correct and the Service Provider shall have the same right, but shall be under no obligation to do so; verification involves checking that the introduced modification is consistent with the pertinent agreement concluded to this effect. The Advertiser’s verification shall take place no later than within 3 working days of the day when the Publisher marked the modifications on the Website.</p>\n' +
	'        <p>7.12. If the Advertiser fails to verify the modifications introduced to the published Article within 3 working days of the date when the modifications were published, it shall be deemed that the modifications have been introduced correctly. Any subsequent changes introduced by the Advertiser shall require a new modification procedure.</p>\n' +
	'        <p>7.13. Introducing changes to a published article does not automatically extend the Publisher’s obligation to maintain the article on the website for a period longer than initially stipulated for this article, unless it is agreed otherwise for an individual case. In order to change the period of publication, the Advertiser’s relevant proposal should be included in the guidelines for the modifications to the article, as prepared by the Advertiser; this shall be the subject of negotiations conducted in order to conclude a relevant agreement in this respect.</p>\n' +
	'        <p>7.14. For the matters not regulated in this section, the provisions of section 4 and 5 shall apply accordingly and adequately to the circumstances, unless they are contrary to section 8.</p>\n' +
	'        <p>7.15. If the article is properly published, the Service Provider shall credit the Publisher’s account with the amount that has been previously defined by the Publisher on the Website.</p>\n' +
	'        <p style="padding-left: 30px;"><strong>8. Statistics generated by the Publishers</strong></p>\n' +
	'        <p>8.1. The Publisher shall choose which of its Portals shall make it possible to generate statistics, thanks to the tracking code developed by the Service Provider. Access to such statistics is free of charge for the Publishers and Advertisers.</p>\n' +
	'        <p>8.2. The Publisher may choose not to share the statistics at all. In this case, the Advertisers cannot order statistics from this Publisher (even for a consideration). In this case, the provisions of the Regulations referring to the statistics (section 8) shall not apply to such Publishers.</p>\n' +
	'        <p>8.3. Information on a Publisher who is currently sharing the statistics may be found among the information available to the Advertisers.</p>\n' +
	'        <p>8.4. The statistics shared by the Publisher should include at least the information about the number of views of the article or the number of unique users who entered the subpage with the article.</p>\n' +
	'        <p>8.5. An agreement related to the statistics shared by the Publisher pursuant to section 2. shall be concluded when the Advertiser submits an order, whose acceptability, scope and fee are defined in this section of the Regulations and in the <a href="#advertisers"><strong>ADVERTISERS’ REGULATIONS</strong></a>.</p>\n' +
	'        <p>8.6. The file with the statistics generated by the Publisher shall be shared through the Website (a relevant button is available on the list of published articles). The Advertiser shall also receive the statistics by e-mail.</p>\n' +
	'        <p>8.7. The Publisher shall be under no obligation to comply with special requests of the Advertisers, i.e. other than those following directly from the Regulations.</p>\n' +
	'        <p>8.8. The Publisher undertakes not to contact the Advertisers directly (outside the Website) in the matters related to the statistics.</p>\n' +
	'        <p style="padding-left: 30px;"><strong>9. Personal data, business information </strong></p>\n' +
	'        <p>9.1. Upon accepting these Regulations, the User agrees to have his/her personal data processed in the course of the registration process or using the Website processed by the Service Provider.</p>\n' +
	'        <p>9.2. The personal data administrator shall be the Service Provider, who processes the personal data pursuant to the mandatory legal provisions.</p>\n' +
	'        <p>9.3. The Service Provider shall process personal data to the extent necessary to conclude and produce the content of the agreement, amend and terminate it accordingly and to perform online services properly.</p>\n' +
	'        <p>9.4. Each User who is a natural person shall have the right to view his/her data, amend it and request that the data be deleted. To this effect, one should address the Service Provider with a relevant request – an e-mail should be sent to help@marketin9.com.</p>\n' +
	'        <p>9.5. The Users’ personal data may be shared with entities authorised to receive it, including the competent law enforcement bodies, pursuant to the mandatory legal provisions, as well as with third parties that perform activities commissioned by the Service Provider and included in the scope of services covered with these Regulations.</p>\n' +
	'        <p>9.6. With regard to the services described in these Regulations, the Service Provider shall share the personal data provided by the User with other entities involved in the Service provision, by way of entrusting these entities with data processing.</p>\n' +
	'        <p>9.7. Additionally, the Service Provider shall be authorised to share the personal data with its business partners for the purposes mentioned in section 9.8 of the Regulations.</p>\n' +
	'        <p>9.8. The Service Provider is also authorised to process personal data for other legally justified purposes and goals pursued by the Service Provider and its partners, including the direct marketing of its own products or services, or the direct marketing of the products and services offered by the Service Provider’s partners.</p>\n' +
	'        <p>9.9. The Service Provider shall entrust data processing in order to make it possible for the Users to process the order, issue the invoice, perform the agreement, etc., for the time needed to complete these activities. The User shall be obliged to process the personal data of other Users only for the purpose described in the Regulations and pursuant to the requirements stipulated in the legal provisions, including the provisions of the personal data protection law and the relevant secondary legislation.</p>\n' +
	'        <p>9.10. In order to make sure that the User processes personal data correctly, the Service Provider shall have the right to verify the correctness of data processing at any time. As regards the abovementioned right, the Service Provider shall be authorised to demand relevant actions to be performed in a specific manner. The User shall be obliged to perform the actions described in the demand within the stipulated period, and if no deadline is indicated in the demand, than the actions need to be performed no later than within 3 days of the demand.</p>\n' +
	'        <p>9.11. If the Publisher shares the personal data related to people from outside its structure with the Service Provider, with regard to the Service provision, assuming that the Publisher is the administrator of such data (referring to clients or Internet users), then the Service Provider will be allowed to process such data as part of the entrusted data processing.</p>\n' +
	'        <p>9.12. Furthermore, the Publisher agrees that:</p>\n' +
	'        <p style="padding-left: 30px;">9.12.1. business information related to the direct marketing of products or services offered by the Service Provider or its business partners, including newsletters, will be addressed to a designated recipient via online communication, in particular e-mail, with the use of the data obtained by the Service Provider in the process of registration or using the Website,</p>\n' +
	'        <p style="padding-left: 30px;">9.12.2. automated calling systems will be used for the abovementioned purposes.</p>\n' +
	'        <p>9.13. Detailed rules for personal data processing, using cookies, sending business information shall be defined in the <a href="static/privacyPolicy"><strong><u>PRIVACY POLICY</u></strong></a>.</p>\n' +
	'        <p style="padding-left: 30px;"><strong>10. Liability</strong></p>\n' +
	'        <p>10.1. The Service Provider shall be liable towards the Publisher for the lack of due diligence in the provision of Services as an intermediary, whether the Service Provider acts in the capacity of a direct or indirect representative.</p>\n' +
	'        <p>10.2. Except when it is expressly indicated that the Service Provider is liable directly to the Publisher, the Advertiser shall be liable for the performance of the Service agreement. If such a result requires that any claims towards the Advertiser be transferred onto the Publisher, then the Service Provider shall immediately transfer its rights accordingly.</p>\n' +
	'        <p>10.3. The Service Provider shall make reasonable effort to guarantee continuous, full availability of the Service provided at all times, however, it shall not guarantee and shall not be liable for its full availability and, subject to the rights obtained upon concluding Service agreements, it reserves the right to amend, phase out, suspend or discontinue any function or feature of the Service at any time and to any extent. The Service Provider informs that amending, phasing out, suspending or discontinuing any function or feature of the Service shall not require a previous announcement.</p>\n' +
	'        <p>10.4. The above provision shall accordingly apply to the amendment of prices defined on the Website, as suggested by the Service Provider.</p>\n' +
	'        <p>10.5. The Service Provider shall not be liable for losses caused by inappropriate use of the Service, in particular when the User or other Users use the Service in a manner contrary to the provisions of these Regulations.</p>\n' +
	'        <p>10.6. The Service Provider shall not be liable for a failure to perform or for an inappropriate performance of the concluded Service agreement or for any problems with using the Website or Service, if this is the consequence of events that the Service Provider could not have predicted or avoided in spite of all due diligence, as well as fortuitous events (“force majeure”).</p>\n' +
	'        <p>10.7. Under no circumstances shall the Service Provider’s liability include the liability for the Publisher’s loss of profits, nor shall it exceed the amount indicated in the relevant sections of the Regulations.</p>\n' +
	'        <p>10.8. The Service Provider shall not be liable for the content and merit of the articles prepared by the Advertisers, as well as for the extent to which they correspond to the reality.</p>\n' +
	'        <p>10.9. The Service Provider shall not be liable for the effectiveness of the Services, which in particular means that the Service Provider in no way guarantees the effect of the Services, understood as increasing the Publishers’ income.</p>\n' +
	'        <p>10.10. If any recommendations, advice, opinions are included in the Website functionality (Blog), then:</p>\n' +
	'        <p style="padding-left: 30px;">10.10.1. the Publisher shall be under no obligation to follow such guidance;</p>\n' +
	'        <p style="padding-left: 30px;">10.10.2. if the Publisher follows such guidance, the Publisher shall do so at its own risk, therefore the Service Provider shall not be liable for the outcome of such actions.</p>\n' +
	'        <p>10.11. The Publisher shall be liable for any violation of the Regulations and of the agreements concluded on the basis of the Regulations.</p>\n' +
	'        <p>10.12. If the performance of any agreement requires that the Publisher should share any information, content, other forms of expression (photos, graphics etc.), then the Publisher guarantees that with regard to the above:</p>\n' +
	'        <p style="padding-left: 30px;">10.12.1. it will have the copyright and related rights or licences to such materials, as necessary;</p>\n' +
	'        <p style="padding-left: 30px;">10.12.2. the use of such material for the performance of the agreement will not infringe upon the moral rights of any person;</p>\n' +
	'        <p style="padding-left: 30px;">10.12.3. it will have any necessary permits for reproducing the physical likeness of people included in these materials;</p>\n' +
	'        <p style="padding-left: 30px;">10.12.4. the use of such material will not violate any industrial property rights of third parties, and in particular will not violate the property rights to trademarks or reputable trademarks;</p>\n' +
	'        <p style="padding-left: 30px;">10.12.5. the publication of such material will not constitute an act of unfair competition;</p>\n' +
	'        <p style="padding-left: 30px;">10.12.6. the publication of such material will not violate the law, good practice, the principles of community life or reasonable interest of third parties.</p>\n' +
	'        <p>10.13. Upon ordering any of the Services, especially with regard to the preparation of articles, the Publisher shall be obliged to ensure that these activities do not violate the law for any reasons other than listed above. In particular, this involves guaranteeing the compliance of advertising or promotion with the legal provisions, including the law on advertising (in public and non-public domain) such products as pharmaceuticals, tobacco, alcohol, as well as services such as gambling.</p>\n' +
	'        <p>10.14. If, with regard to the Service Provider performing its obligations under the concluded agreements, as a result of false or incomplete representations offered by the Publisher or with regard to the violation of law by the Publisher, any civil, enforcement, criminal or administrative proceedings are initiated against the Service Provider, or if any claims are addressed to the Service Provider in this respect, the Publisher agrees to help the Service Provider at its own expense, as far as possible, so as to dismiss the claims or charges addressed to the Service Provider. Furthermore, in the situation as described above, the Publisher undertakes to redress the damages suffered by the Service Provider and related to the proceedings initiated against the Service Provider, or to the claims or charges addressed against the Service Provider, i.e. to settle the justified claims reported by third parties (provided that the competent court orders this with a legally binding sentence), reimburse the equivalent of all the fines or penalties actually paid by the Service Provider and duly documented, and to cover the actual expense of the necessary legal aid paid by the Service Provider.</p>\n' +
	'        <p>10.15. The Service Provider is not the author of the articles or Content included in the Service, which is why the Service Provider may guarantee that it has obtained rights to these materials in the course of legal actions, assuming in good faith that the representations of the entities from which it obtained the rights are consistent with the actual status quo. However, if such representations or assurances of a party to an agreement concluded with the Service Provider are not consistent with the actual status quo, as a result of which the Publisher does not come into the rights stipulated in the Regulations, then the Service Provider, acting as an intermediary in the course of specific activities stipulated in the Regulations, cannot be held liable in this respect, also with regard to the loss suffered by the Publisher, provided it takes all the steps to transfer any claims of the Service Provider against the parties accountable for this situation onto the Publisher.</p>\n' +
	'        <p>10.16. The Website may include references to the content included on other Internet websites. This doesn’t mean that the Service Provider of the authors of the content included on the Website take a position with regard to the content included on such websites, nor shall hey be liable for such content.</p>\n' +
	'        <p>10.17. The Publisher may not act to the detriment of other Users of the Website, which means it may not include unnatural links to the published articles (or any other posts in social media), insert unnatural comments under the articles, or direct unnatural Internet traffic to the articles.</p>\n' +
	'        <p>10.18. The Publisher hereby agrees to the Service Provider transferring the rights or obligations following from the Regulations or from an agreement concluded pursuant to the Regulations.</p>\n' +
	'        <p style="padding-left: 30px;"><strong>11. Complaints</strong>&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;&ZeroWidthSpace;</p>\n' +
	'        <p>11.1. The provisions of this section shall apply considering the previous provisions of the Regulations, which in particular means that if the Regulations stipulate a specific period for the Publisher to report a complaint, then the Publisher shall be bound with this period, rather than the one described in the following sections.</p>\n' +
	'        <p>Complaints related to the Service should be reported by e-mail, to the address: help@marketin9.com.</p>\n' +
	'        <p>11.2. A complaint should include at least the following information: date and time of an event that is the subject matter of the complaint, e-mail address of the complainant and a brief description of the reservations.</p>\n' +
	'        <p>11.3. Complaints shall be examined within 14 days of receipt, in the order in which they are received. If the abovementioned period cannot be observed, the Service Provider shall inform the complainant of this fact, providing the reason behind the extension of the period and the predicted date of the reply.</p>\n' +
	'        <p>11.4. The complainant will be informed of the way the complaint was handled by e-mail sent to the address provided along with the complaint.</p>\n' +
	'        <p style="padding-left: 30px;"><strong>12. Final provisions</strong></p>\n' +
	'        <p>12.1 In the matters not regulated herein, the provisions of the mandatory common law shall apply.</p>\n' +
	'        <p>12.2 Any disputes between the Publisher and the Service Provider shall be solved amicably, and if no agreement is reached, they will be referred to a competent court having jurisdiction over the Service Provider.</p>\n' +
	'        <p>12.3 The Regulations may be subject to amendments from time to time, whereas the Publisher shall be informed of such amendments. If the Publisher who has an account on the Website does not approve the amendments, then it shall have the right to offer a representation to delete the account and terminate the cooperation, whereas such a representation must be submitted by e-mail and sent to the following contact address: help@marketin9.com and must be sent by the effective date of the proposed amendments.</p>\n' +
	'        <p>12.4 The Publisher represents it has become familiar with these Regulations, appendices to these Regulations, including the regulations referred to herein, as well as with the Service Provider’s <a href="static/privacyPolicy"><strong><u>Privacy Policy</u></strong></a>.</p>\n' +
	'        <p>12.5 The Regulations are effective as of 02/01/2023.</p>\n' +
	'        </div>');
const goBack = () => {
	router.push('/');
};
</script>

<style scoped lang="scss">
.rules {
	&__logo {
		max-width: 224px;
		display: flex;
		margin: 20px auto;
	}
	
	&__title {
		font-size: 29px;
		font-weight: 700;
		color: $primary-400;
		text-align: center;
	}
	
	&__language-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	
	&__language-label {
		margin-right: -30px;
	}
	
	&__language-list {
		display: flex;
		align-items: center;
	}
	
	&__flag {
		margin-bottom: 5px;
		margin-right: 5px;
	}
	
	&__arrow {
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__text-wrapper {
		display: flex;
		flex-direction: column;
		gap: 15px;
		font-size: 14px;
		line-height: 1.8;
	}
	
	&__subtitle {
		font-weight: 700;
	}
	
	&__list-item {
		margin-left: 40px;
	}
	
	&:deep(.before-login-page__background) {
		height: 100%;
		object-fit: cover;
	}
	
	&:deep(.before-login-page__wrapper) {
		margin: 110px 0;
	}
}
</style>
