<template>
	<div class="discount-groups-table">
		<v-data-table
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:items-per-page-options="itemsPerPageOptions"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			@update:options="optionsUpdate"
			hover
		>
			<template #loading>
				<div class="discount-groups-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #item.label="elem">
				<div v-if="null != elem.item.raw.label" class="discount-groups-table__name">{{ elem.item.raw.label }}</div>
			</template>
			
			<template #item.created_at="elem">
				<div v-if="null != elem.item.raw.created_at" class="discount-groups-table__name">{{ dateFormat(elem.item.raw.created_at) }}</div>
			</template>
			
			<template #item.actions="elem">
				<div class="discount-groups-table__actions-wrapper">
					<MTooltip without-btn>
						<template v-slot:title>
							<MButton @click="editGroupRedirect(elem.item.raw.slug)" icon="pencil" :color="white" blue400 />
						</template>
						<template v-slot:content>
							<div>{{ $t('pricelist.edit') }}</div>
						</template>
					</MTooltip>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script setup lang="ts">
import { VDataTable } from 'vuetify/labs/VDataTable';
import router from '@/router';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { dateFormat } from '@/hooks/DataHooks';
import { toast } from 'vue3-toastify';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { TableHeaderInterface } from '@/models/VuetifyModel';
import type { IDiscountGroupList } from '@/models/DiscountGroupsModel';
import DiscountGroupApi from '@/api/v1/DiscountGroupApi';
import MTooltip from '@/components/atoms/MTooltip.vue';
import MButton from '@/components/atoms/MButton.vue';

const { t } = useI18n();
const { white } = variables;

const discountGroupApi = new DiscountGroupApi();

const items = ref<Array<IDiscountGroupList>>([]);
const loading = ref<boolean>(false);
const firstLoadComponent = ref<boolean>(true);
const currentPage = ref<number>(1);
const lastPage = ref<number>(1);
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('pricelist.name')}`,
		align: 'center',
		sortable: false,
		key: 'label',
		width: '200px',
	},
	{
		title: `${t('pricelist.createdAt')}`,
		align: 'center',
		sortable: false,
		key: 'created_at',
		width: '200px',
	},
	{
		title: `${t('pricelist.description')}`,
		align: 'center',
		sortable: false,
		key: 'description',
		width: '200px',
	},
	{
		title: `${t('pricelist.actions')}`,
		align: 'center',
		sortable: false,
		key: 'actions',
		width: '100px',
	},
]);

const discountGroupsList = async() => {
	loading.value = true;
	try {
		const result = await discountGroupApi.userDiscountGroupList();
		if (!result.isSuccess) {
			toast.error(`${t('pricelist.toast.userGroupListError')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload;
	} catch (e) {
		toast.error(`${t('pricelist.toast.userGroupListError')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
discountGroupsList();

const optionsUpdate = (val:any) => {
	if (!firstLoadComponent.value) {
		currentPage.value = val.page;
		const modulo = items.value.length % val.itemsPerPage;
		if (0 === modulo) {
			lastPage.value = items.value.length / val.itemsPerPage;
		} else {
			lastPage.value = +(items.value.length / val.itemsPerPage).toFixed(0) > 0 ? +(items.value.length / val.itemsPerPage).toFixed(0) : 1;
		}
	}
	firstLoadComponent.value = false;
};
const editGroupRedirect = (slug:string) => {
	router.push(`/discount-groups/${slug}`);
};
</script>

<style scoped lang="scss">
.discount-groups-table {
	@import '@/assets/scss/vuetifyDataTable';
	&__loading {
		opacity: 0.5;
	}
	
	&__name {
		font-weight: 700;
	}
	
	&__actions-wrapper {
		display: flex;
		gap: 5px;
		align-items: center;
		justify-content: center;
		
		&:deep(.v-btn) {
			min-width: 20px !important;
			padding: 0 6px 0 11px !important;
			border-radius: 50%;
		}
	}
	
	&:deep(.v-data-table) {
		border-radius: 8px;
	}
}
</style>
