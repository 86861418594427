import BaseApi from '@/api/BaseApi';
import type { IMonolithUsersRequest, IMonolithUsersResponseItem } from '@/models/UserModer';
import type { TPaginate } from '@/models/GlobalModel';

export default class MonolithUserApi extends BaseApi {
	async monolithUsersList (params:IMonolithUsersRequest) {
		return this.get<TPaginate<IMonolithUsersResponseItem>>({
			url: 'api/users',
			params: params,
			config: {
				useMonolithApi: true,
			},
		});
	};
};
