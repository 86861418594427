import type { TIcon } from '@/components/atoms/MIcon.vue';

export const IS_PROMO_ACTIVE:boolean = true;
export const PROMO_COLOR:string = '#E1EEDD';
export const PROMO_ICON_NAME:TIcon = 'flower';
export const PROMO_ICON_WIDTH:string = '33';
export const PROMO_ICON_HEIGHT:string = '30';
export const PROMO_ICON_COLOR:string = '#000';
export const PROMO_HEADER_TEXT_MARGIN:string = '0 0 0 0';
export const PROMO_FONT:string = 'Lato';
export const PROMO_FONT_WEIGHT:number = 400;
export const PROMO_FONT_SIZE:string = '18px';
export const PROMO_BORDER:string = 'none';
export const PROMO_TEXT_COLOR:string = '#000';
export const PROMO_CODE:string = 'PROMO';
