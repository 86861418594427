import { defineStore } from 'pinia';

type TBooleanFlag = {
	isAiOfferFilters: boolean
	isRefreshWalletAction: boolean
	isPromoBtnClicked: boolean
};

export const useBooleanFlagStore = defineStore('useBooleanFlagStore', {
	state: ():TBooleanFlag => ({
		isAiOfferFilters: false,
		isRefreshWalletAction: false,
		isPromoBtnClicked: false,
	}),
	actions: {
		setIsAiOfferFilters (isAiOfferFiltersValue: boolean) {
			this.isAiOfferFilters = isAiOfferFiltersValue;
		},
		setIsRefreshWalletAction (isRefreshWalletActionValue: boolean) {
			this.isRefreshWalletAction = isRefreshWalletActionValue;
		},
		setIsPromoBtnClicked (isPromoBtnClickedValue: boolean) {
			this.isPromoBtnClicked = isPromoBtnClickedValue;
		},
	},
});
