<template>
    <div class="m-text-area">
	    <div v-if="separateLabel && !hideLabel" class="m-text-area__label">{{ separateLabel }}<span class="m-text-area__required-star">{{ labelRequired ? '*' : labelTextRequired ? ` ${$t('components.fieldRequired')}` : '' }}</span></div>
        <v-textarea
            v-model="value"
            :label="label"
            :error-messages="errors"
            :disabled="disabled"
            :placeholder="placeholder"
            :name="name"
            outlined
            hide-details
            :variant="variant"
        ></v-textarea>
        <div class="m-text-area__errors">{{ errors[0] }}</div>
    </div>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, toRef, withDefaults } from 'vue';
import { useField } from 'vee-validate';
interface IOwnProps {
    modelValue?: string|null
    label?: string
    name?: string
    placeholder?: string
    disabled?: boolean
	variant?: 'filled'|'outlined'|'plain'|'underlined'|'solo'|'solo-inverted'|'solo-filled'|undefined
	separateLabel?: string
	labelRequired?: boolean
	labelTextRequired?: boolean
	hideLabel?: boolean
}
interface IProps extends IOwnProps {
}

const props = withDefaults(defineProps<IProps>(), {
	variant: 'outlined',
});
const emit = defineEmits(['update:modelValue']);

const { errors } = useField(toRef<IOwnProps, any>(props, 'name'), undefined);

const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

</script>

<style scoped lang="scss">
.m-text-area {
	&__label {
		color: $grey-400;
		font-weight: 700;
		font-size: 14px;
	}
	
	&__required-star {
		color: $secondary-400;
	}
	
    &__errors {
        font-size: 12px;
        color: $secondary-550;
        text-transform: lowercase;
	    margin-top: 10px;

        &::first-letter {
            text-transform: uppercase;
        }
    }
}
</style>
