<template>
	<div v-if="null != user" class="article-list">
		<HeadingLabel
			v-if="isAdvertiser(user)"
			class="article-list__heading-label"
			icon="articles"
			width="32"
			height="29"
			:text="$t('articles.articles')"
			:btn="isAdvertiser(user)"
			blue400
			:btn-text="$t('articles.addNewArticle')"
			btn-id="create-article-btn"
			:is-button-click="goToCreateArticle"
		>
			<template v-slot:beforeBtn>
				<MTooltip without-btn>
					<template v-slot:title>
						<div class="article-list__optimalization-wrapper">
							<div class="article-list__optimalization-text">{{ $t('contadu.availableOptimalizations') }}:</div>
							<div class="article-list__optimalization-number">{{ null != availableOptimization ? availableOptimization : 0 }}</div>
						</div>
					</template>
					<template v-slot:content>
						<div>{{ $t('contadu.availableDescriptionTooltip') }}</div>
					</template>
				</MTooltip>
			</template>
		</HeadingLabel>
		
		<ArticleListTable :available-optimizations="availableOptimization" />
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { isAdvertiser } from '@/hooks/UserHooks';
import { toast } from 'vue3-toastify';
import ContaduApi from '@/api/v1/ContaduApi';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';
import ArticleListTable from '@/components/organisms/Articles/ArticleListTable.vue';

const { t } = useI18n();
const { user } = storeToRefs(useUserStore());

const contaduApi = new ContaduApi();

const availableOptimization = ref(0);

const contaduCounter = async() => {
	if (null == user.value) {
		return;
	}
	try {
		const result = await contaduApi.availableOptimalizations(user.value.uuid);
		if (!result.isSuccess) {
			toast.error(`${t('contadu.toast.limit')}`);
			return;
		}
		availableOptimization.value = result.payload;
	} catch (e) {
		toast.error(`${t('contadu.toast.limit')}`);
		return;
	}
};
contaduCounter();
const goToCreateArticle = () => {
	router.push('/article/create');
};
</script>

<style scoped lang="scss">
.article-list {
	&__heading-label {
		margin-bottom: 5px;
		
		&:deep(.v-btn) {
			.v-btn {
				border-radius: 6px !important;
			}
		}
	}
	
	&__description {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
		background-color: $white-100;
		padding: 10px;
		
		@include media-breakpoint-up(md) {
			padding: 30px 25px;
		}
	}
	
	&__optimalization-wrapper {
		width: 200px;
		height: 66px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 3px;
		margin-right: 20px;
		border: 1px solid $primary-400;
		border-radius: 6px;
		padding: 11px 5px;
		cursor: default;
	}
	
	&__optimalization-text {
		font-size: 14px;
		font-weight: 700;
	}
	
	&__optimalization-number {
		font-size: 14px;
		font-weight: 700;
		color: $primary-400;
	}
	
	&:deep(#create-article-btn) {
		width: 200px;
		height: 66px;
		padding: 0 !important;
	}
}
</style>
