import BaseApi from '../BaseApi'
import type { GoogleAuthorizationInterface, GoogleFilesInterface, GoogleStatusInterface } from '@/models/GoogleModel';
import type { IUploadGoogleArticleItemRequest } from '@/models/ArticlesModel';

export default class GoogleApi extends BaseApi {
	async googleLogin () {
		return this.post<any, GoogleAuthorizationInterface>({
			url: '/api/v1/google/authorize',
		});
	};

	async getGoogleFiles (search?: string|null) {
		let url = '/api/v1/google/files';

		if (null != search) {
			url += `?query=${search}`;
		}
		return this.get<Array<GoogleFilesInterface>>({
			url: url,
		});
	};

	async sendGoogleUploadedFile (params: IUploadGoogleArticleItemRequest) {
		return this.post<IUploadGoogleArticleItemRequest, any>({
			url: '/api/v1/google/files',
			data: params,
		});
	};

	async sendGoogleOnlineFile (params: IUploadGoogleArticleItemRequest) {
		return this.post<IUploadGoogleArticleItemRequest, any>({
			url: '/api/v1/google/files/export',
			data: params,
		});
	};

	async getStatus () {
		return this.get<GoogleStatusInterface>({
			url: '/api/v1/google/admin-status',
		});
	};
};
