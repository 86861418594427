<template>
	<Form :validation-schema="schema" @submit="submit" class="coupons-form">
		<v-radio-group v-model="form.is_active" :color="secondary400" id="coupons-is-active" class="coupons-form__radio-wrapper">
			<v-radio :label="$t('coupons.active')" :value="1" />
			<v-radio :label="$t('coupons.used')" :value="0" />
		</v-radio-group>
		<v-radio-group v-model="form.type" :color="secondary400" id="coupons-type" class="coupons-form__radio-wrapper">
			<v-radio :label="$t('coupons.cacheCoupon')" value="CACHE" />
			<v-radio :label="$t('coupons.percentCoupon')" value="PERCENT" />
		</v-radio-group>
		<v-radio-group v-model="isAllUsers" :color="secondary400" id="coupons-is-all-users" class="coupons-form__radio-wrapper">
			<v-radio :label="$t('coupons.forAll')" :value="false" />
			<v-radio :label="$t('coupons.forSelectedUsers')" :value="true" />
		</v-radio-group>
		<div v-if="isAllUsers">
			<MProgressBar v-show="loading" indeterminate height="2" />
			<MAutocomplete
				id="coupons-users"
				v-model="form.users"
				v-model:search="autocompleteInputValue"
				name="users"
				:get-items="userList"
				item-title="email"
				item-value="id"
				:label="$t('coupons.users')"
				label-required
				:placeholder="$t('coupons.usersSelect')"
				variant="outlined"
				multiple
				chips
				clearable
				return-object
			/>
			<div v-if="null != form.users && isAllUsers && form.users.length === 0" class="coupons-form__error-message">{{ $t('coupons.validation.users') }}</div>
			<div class="coupons-form__label">{{ $t('coupons.selectedUsers') }}</div>
			<v-chip v-for="user in form.users" :key="user.id">{{ user?.email }}</v-chip>
		</div>
		<div class="coupons-form__code-wrapper">
			<MTextField
				id="coupons-code"
				v-model="form.code"
				name="code"
				label-required
				required
				label=""
				:placeholder="$t('coupons.codeEnter')"
				:separate-label="$t('coupons.code')"
			/>
			<MIcon
				id="coupons-generate-code-btn"
				@click="generateCode"
				icon="settings-double"
				:color="grey400"
				width="30"
				height="30"
				class="coupons-form__generate-btn"
			/>
		</div>
		<MTextField
			id="coupons-value"
			v-model="form.value"
			type="number"
			:min="0"
			required
			label-required
			name="value"
			label=""
			:placeholder="$t('coupons.valueEnter')"
			:separate-label="$t('coupons.value')"
		/>
		<div class="coupons-form__description">{{ $t('coupons.descriptionValue') }}</div>
		<MTextField
			id="coupons-max-items"
			v-model="form.max_items"
			type="number"
			:min="0"
			name="maxItems"
			label=""
			:placeholder="$t('coupons.maxItems')"
			:separate-label="$t('coupons.maxItems')"
		/>
		<MTextField
			id="coupons-min-offer-total-price"
			v-model="form.min_offer_total_price"
			type="number"
			:min="0"
			required
			name="minOfferTotalPrice"
			label=""
			label-required
			:placeholder="$t('coupons.minPricePlaceholder')"
			:separate-label="$t('coupons.minPrice')"
		/>
		<div class="coupons-form__description">{{ $t('coupons.descriptionMinValue') }}</div>
		<MTextField
			id="coupons-amount-per-user"
			v-model="form.amount_per_user"
			type="number"
			:min="0"
			required
			name="amountPerUser"
			label=""
			label-required
			:placeholder="$t('coupons.enterValue')"
			:separate-label="$t('coupons.couponsPerUserCount')"
		></MTextField>
		<div v-if="!isAllUsers">
			<MTextField
				if="coupons-amount"
				v-model="form.amount"
				type="number"
				:min="0"
				required
				name="amount"
				label=""
				label-required
				:placeholder="$t('coupons.enterValue')"
				:separate-label="$t('coupons.couponsInTotal')"
			/>
			<div class="coupons-form__description">{{ $t('coupons.descriptionTotal') }}</div>
		</div>
		<div class="coupons-form__label">{{ $t('coupons.labelForm') }}</div>
		<MDatePicker
			id="coupons-date-from"
			v-model="form.started_at"
			name="from"
			:language="localeLanguage"
			clearable-height="12"
			clearable-width="12"
			:disabled-dates="{
				to: new Date(null != form.started_at ? dayjs(form.started_at).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')),
				from: new Date(+dayjs(form.ended_at).format('YYYY'), +dayjs(form.ended_at).format('MM') - 1, +dayjs(form.ended_at).format('DD')),
			}"
			clearable
		/>
		<div class="coupons-form__label">{{ $t('coupons.labelByWhen') }}<span>{{ $t('coupons.optional') }}</span></div>
		<MDatePicker
			id="coupons-date-to"
			v-model="form.ended_at"
			name="to"
			:language="localeLanguage"
			clearable-height="12"
			clearable-width="12"
			:disabled-dates="{
				to: new Date(+dayjs(form.started_at).format('YYYY'), +dayjs(form.started_at).format('MM') - 1, +dayjs(form.started_at).format('DD')),
			}"
			clearable
		/>
		<div class="coupons-form__description">{{ $t('coupons.blankField') }}</div>
		<div class="coupons-form__btn-wrapper">
			<MButton id="coupons-go-back" @click="goBack" blue400 width130 :label="$t('coupons.goBack')" normal class="coupons-form__back-btn" />
			<MButton id="coupons-submit" type="submit" red400 width130 :label="couponUuid === 'create' ? $t('coupons.createCoupon') : $t('coupons.editCoupon')" normal />
		</div>
	</Form>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@/stores/session';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import dayjs from 'dayjs';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import variables from '@/assets/scss/modules/variables.module.scss';
import CouponsApi from '@/api/v1/CouponsApi';
import UserApi from '@/api/v1/UserApi';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MButton from '@/components/atoms/MButton.vue';
import MDatePicker from '@/components/atoms/MDatePicker.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import type { CouponListModelInterface, CouponsCreateEditModelInterface } from '@/models/CouponsModel';

const { t } = useI18n();
const { locale } = storeToRefs(useSessionStore());
const { secondary400, grey400, white } = variables;

const couponsApi = new CouponsApi();
const userApi = new UserApi();

const loading = ref<boolean>(false);
const isAllUsers = ref<boolean>(false);
const autocompleteInputValue = ref<string|null>('');
const autocompleteInputValueTimeout = ref<any>(undefined);
const userList = ref<Array<CouponListModelInterface>|any>([]);
const couponUuid = ref<Array<string>|string>('');
couponUuid.value = router.currentRoute.value.params.couponUuid;
const form = ref<CouponsCreateEditModelInterface|any>({
	amount: 0,
	amount_per_user: 0,
	code: '',
	ended_at: '',
	is_active: 0,
	started_at: dayjs().format('YYYY-MM-DD'),
	type: 'PERCENT',
	value: 0,
	users: [],
	min_offer_total_price: 0,
	max_items: null,
});
const localeLanguage = ref<string>('');

if ('us' === locale.value) {
	localeLanguage.value = 'en';
} else if (locale.value != null) {
	localeLanguage.value = locale.value;
} else {
	localeLanguage.value = 'pl';
}

const checkUsersLength = () => {
	if (null != form.value.users && form.value.users.length === 0) {
		isAllUsers.value = false;
	} else {
		isAllUsers.value = true;
	}
};
checkUsersLength();
const couponDetails = async() => {
	loading.value = true;
	try {
		const result = await couponsApi.getCoupon(couponUuid.value);
		
		if (!result.isSuccess) {
			toast.error(`${t('coupons.toast.error')}`);
			loading.value = false;
			return;
		}
		form.value = result.payload;
		form.value.ended_at = dayjs(result.payload.ended_at).format('YYYY-MM-DD');
		form.value.started_at = dayjs(result.payload.started_at).format('YYYY-MM-DD');
		form.value.min_offer_total_price = form.value.min_offer_total_price / 100;
		
		if ('Invalid date' === form.value.ended_at || 'Invalid Date' === form.value.ended_at) {
			form.value.ended_at = '';
		}
		await checkUsersLength();
	} catch (e) {
		toast.error(`${t('coupons.toast.error')}`);
		loading.value = false;
		return;
	}
	
	loading.value = false;
};
if (couponUuid.value !== 'create') {
	couponDetails();
}

const schema:any = yup.object({
	users: yup.array(),
	code: yup.string().required(`${t('coupons.validation.codeRequired')}`).min(3, `${t('coupons.validation.codeMin')}`).label('code'),
	value: yup.number().required(`${t('coupons.validation.value')}`).min(1, `${t('coupons.validation.valueMin')}`).max(100, `${t('coupons.validation.valueMax')}`).label('value'),
	minOfferTotalPrice: yup.number().required(`${t('coupons.validation.minOfferTotalPriceRequired')}`).min(1, `${t('coupons.validation.minOfferTotalPriceMin')}`).label('minOfferTotalPrice'),
	amountPerUser: yup.number().required(`${t('coupons.validation.amountPerUser')}`).min(1, `${t('coupons.validation.amountPerUserMin')}`).label('amountPerUser'),
	amount: yup.number().required(`${t('coupons.validation.amount')}`).min(1, `${t('coupons.validation.amountMin')}`).label('amount'),
	maxItems: yup.number().min(0, `${t('coupons.validation.amountMin0')}`).label('maxItems'),
	from: yup.string().required(`${t('coupons.validation.from')}`).label('from'),
});
const submit = async() => {
	form.value.amount = form.value.amount ? +form.value.amount : 0;
	form.value.amount_per_user = form.value.amount_per_user ? +form.value.amount_per_user : 0;
	form.value.value = form.value.value ? +form.value.value : 0;
	form.value.min_offer_total_price = form.value.min_offer_total_price ? +form.value.min_offer_total_price : 0;
	
	if (0 === form.value.amount) {
		form.value.amount = null;
	}
	
	if ('Invalid Date' === form.value.ended_at || 'Invalid date' === form.value.ended_at) {
		form.value.ended_at = '';
	}
	
	const userIds:Array<number> = [];
	form.value.users.forEach((elem:CouponListModelInterface) => {
		userIds.push(elem.id);
	});
	
	const request:any = {
		amount_per_user: form.value.amount_per_user,
		code: form.value.code,
		ended_at: form.value.ended_at,
		is_active: form.value.is_active,
		started_at: form.value.started_at,
		type: form.value.type,
		value: form.value.value,
		max_items: +form.value.max_items,
		...(0 === form.value.users.length
				? { amount: form.value.amount, users: userIds }
				: { users: userIds }
		),
		min_offer_total_price: form.value.min_offer_total_price,
	};
	
	if (couponUuid.value === 'create') {
		try {
			const result = await couponsApi.createCoupon(request);
			if (!result.isSuccess) {
				toast.error(`${t('coupons.toast.error')}`);
				loading.value = false;
				return;
			}
			toast.success(`${t('coupons.toast.success')}`);
			await router.push('/coupons');
			loading.value = false;
		} catch (e) {
			toast.error(`${t('coupons.toast.error')}`);
			loading.value = false;
			return;
		}
	} else {
		try {
			const result = await couponsApi.editCurrentCoupon(couponUuid.value, request);
			if (!result.isSuccess) {
				toast.error(`${t('coupons.toast.error')}`);
				loading.value = false;
				return;
			}
			toast.success(`${t('coupons.toast.success')}`);
			await router.push('/coupons');
			loading.value = false;
		} catch (e) {
			toast.error(`${t('coupons.toast.error')}`);
			loading.value = false;
			return;
		}
	}
};

const usersList = async(val:string|null) => {
	loading.value = true;
	const result = await userApi.usersList(val);
	
	if (!result.isSuccess) {
		toast.error(`${t('coupons.toast.error')}`);
		loading.value = false;
		return;
	}
	const usersPayloadList = result.payload.map((elem:CouponListModelInterface|any) => {
		return {
			id: elem.id,
			email: elem.email
		}
	});
	userList.value = usersPayloadList;
	loading.value = false;
};

watch((autocompleteInputValue), (newVal, oldVal) => {
	if (newVal !== oldVal) {
		clearTimeout(autocompleteInputValueTimeout.value);
	}
	autocompleteInputValueTimeout.value = setTimeout(() => {
		usersList(newVal);
	}, 700);
});

const generateCode = () => {
	const list = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
	let res = '';
	for (let i = 0; 12 > i; i++) {
		const rnd = Math.floor(Math.random() * list.length);
		res = res + list.charAt(rnd);
	}
	form.value.code = res;
};

const goBack = () => {
	router.push('/coupons');
};

watch((isAllUsers), (val) => {
	if (!val) {
		form.value.users = [];
		schema.fields['users'] = yup.array();
		schema.fields['amount'] =  yup.number().required(`${t('coupons.validation.amount')}`).min(1, `${t('coupons.validation.amountMin')}`).label('amount');
	} else {
		form.value.amount = 0;
		schema.fields['users'] = yup.array().required(`${t('coupons.validation.users')}`).label('users');
		schema.fields['amount'] = yup.number();
	}
});

watch((form.value), (val) => {
	if (val.type === 'PERCENT') {
		schema.fields['value'] = yup.number().required(`${t('coupons.validation.value')}`).min(1, `${t('coupons.validation.valueMin')}`).max(100, `${t('coupons.validation.valueMax')}`).label('value');
	} else {
		schema.fields['value'] = yup.number().required(`${t('coupons.validation.value')}`).min(1, `${t('coupons.validation.valueMin')}`).label('value');
	}
},
{
	deep: true,
});
</script>

<style scoped lang="scss">
.coupons-form {
	background-color: $white;
	padding: 20px 10px;
	
	@include media-breakpoint-up(sm) {
		padding: 30px;
	}
	
	&__description {
		color: $grey-400;
		font-size: $size-12;
		margin-top: -25px;
		margin-bottom: 20px;
	}
	
	&__label {
		color: $grey-400;
		font-weight: $weight-700;
		font-size: $size-14;
		
		span {
			font-weight: $weight-400;
		}
	}
	
	&__code-wrapper {
		display: flex;
		align-items: center;
		
		div {
			width: 100%;
		}
		
		&:deep(.theme--light.v-btn.v-btn--has-bg) {
			background-color: transparent;
		}
	}
	
	&__btn-wrapper {
		display: flex;
		flex-direction: column;
		
		@include media-breakpoint-up(sm) {
			flex-direction: row-reverse;
			justify-content: space-between;
		}
	}
	
	&__back-btn {
		margin-bottom: 5px;
		
		@include media-breakpoint-up(sm) {
			margin-bottom: 0;
		}
	}
	
	&__generate-btn {
		margin-left: 5px;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__error-message {
		color: $secondary-400;
	}
	
	&:deep(.v-input--radio-group--column .v-input--radio-group__input) {
		flex-direction: row;
	}
	
	&:deep(.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child)) {
		margin-bottom: 0;
		margin-right: 10px;
		
		@include media-breakpoint-up(md) {
			margin-right: 20px;
		}
	}
	
	&:deep(.v-input--selection-controls) {
		margin-top: 0;
	}
	
	&:deep(.v-btn) {
		color: $white;
		font-weight: $weight-700;
		border-radius: 0;
	}
	
	&:deep(.m-btn__big .m-btn__text) {
		font-size: $size-16;
	}
	
	&:deep(.v-btn__content) {
		font-size: $size-16;
	}
	
	&:deep(.v-text-field.v-text-field--enclosed .v-text-field__details) {
		padding: 0 0;
	}
	
	&:deep(.v-messages__message) {
		margin: 5px 0 25px;
	}
	
	&:deep(.v-text-field--outlined.v-input--dense .v-label--active) {
		display: none;
	}
	
	&:deep(.v-text-field--outlined .v-label--active) {
		display: none;
	}
	
	&:deep(.v-text-field--enclosed .v-input__append-inner) {
		margin-top: 16px;
	}
	
	&:deep(.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections) {
		min-height: 48px;
	}
	
	&:deep(.v-chip.v-chip--density-default) {
		background-color: $secondary-500;
		color: $white;
	}
	
	&:deep(.theme--light.v-chip--active) {
		background-color: $primary-400;
		color: $white;
		font-weight: $weight-700;
	}
	
	&:deep(.m-btn) {
		border-radius: 8px !important;
	}
	
	&:deep(.v-field__field) {
		min-height: 40px;
	}
	
	&:deep(.v-input input) {
		min-height: 40px !important;
		padding: 10px !important;
	}
	
	&:deep(.v-field__input) {
		min-height: 40px !important;
		padding: 0!important;
	}
	
	&:deep(.m-autocomplete .v-input input) {
		min-height: 40px !important;
		padding: 0 !important;
	}
	
	&:deep(.m-autocomplete .v-field__input) {
		padding: 0 0 !important;
	}
	
	&:deep(.v-autocomplete__selection) {
		margin-top: 10px;
	}
	
	&:deep(.vuejs3-datepicker__value) {
		padding: 9px 15px;
	}
	
	&:deep(.v-text-field .v-input__details) {
		padding-inline-start: 0;
	}
	
	&:deep(.v-selection-control-group) {
		flex-direction: row;
		gap: 10px;
		width: max-content;
	}
	
	&:deep(.v-selection-control .v-label) {
		width: max-content;
	}
}
</style>
