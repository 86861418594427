import { defineStore } from 'pinia';
import dayjs from 'dayjs';

type TAuth = {
	accessToken: string|null
	apiToken: string|null
	expiresIn: string|null
	tokenType: string|null
};

export type TAuthorizationPayload = {
	access_token: string
	api_token: string | null
	expires_in: string
	token_type: string
};

export const useAuthStore = defineStore('useAuthStore', {
	state: ():TAuth => ({
		accessToken: null,
		apiToken: null,
		expiresIn: null,
		tokenType: null,
	}),
	actions: {
		authorizeFromLocalStorage () {
			this.accessToken = localStorage.getItem('accessToken');
			this.apiToken = localStorage.getItem('apiToken');
			this.expiresIn = localStorage.getItem('expiresIn');
			this.tokenType = localStorage.getItem('tokenType');
		},
		setAuthorization (payload:TAuthorizationPayload) {
			localStorage.setItem('accessToken', payload.access_token);
			localStorage.setItem('expiresIn', dayjs().add(+payload.expires_in, 's').toString());
			localStorage.setItem('tokenType', payload.token_type);
			if (null != payload.api_token) {
				localStorage.setItem('apiToken', payload.api_token);
			}
		},
		removeAuthorization () {
			localStorage.removeItem('accessToken');
			localStorage.removeItem('apiToken');
			localStorage.removeItem('expiresIn');
			localStorage.removeItem('tokenType');
			localStorage.removeItem('consentPopup');
		},
	},
});
