<template>
	<div class="reflink-program-window" :class="{'reflink-program-window--admin-active': currentUser.reflink_hash != null}">
		<HeadingLabel
			icon="people-pulling"
			width="25"
			height="29"
			:text="$t('profile.affiliateProgram')"
			switch-btn
			:first-switch-btn="null != user && (isPublisher(user) || checkIsUserJoinAffiliateProgram(user))"
			green400
			:btn-text="$t('reflinks.withdraw')"
			:is-button-click="goToWithdrawal"
			:second-switch-btn="null != user && checkIsUserJoinAffiliateProgram(user)"
			second-switch-btn-red400
			:is-second-button-click="goToTransfer"
			:second-switch-btn-label="$t('reflinks.transfer')"
		/>
		<div>
			<div v-if="currentUser.reflink_hash != null" class="reflink-program-window__info-wrapper">
				<div class="reflink-program-window__title">{{ $t('profile.yourReflink') }}:</div>
				<div class="reflink-program-window__reflink">{{ currentHost() }}/register?m9-referer={{ currentUser.reflink_hash }}</div>
				<div class="reflink-program-window__copy-wrapper">
					<MButton
						id="copy-to-clipboard-reflink"
						icon="articles"
						:color="white"
						red400
						@click="copyToClipboard(currentHost() + '/register?m9-referer=' + currentUser.reflink_hash, $t('contadu.toast.successCopyToClipboard'), $t('contadu.toast.errorCopyToClipboard'))"
					/>
				</div>
			</div>
			<div v-if="null != user && isAdmin(user) && currentUser.reflink_hash != null" class="reflink-program-window__reflink-hash-change-wrapper">
				<MTextField
					id="reflink-hash"
					name="reflinkHash"
					v-model="reflink_hash"
					:separate-label="$t('reflinks.changeReflinkHash')"
				/>
				<MButton
					red400
					big
					:label="$t('reflinks.changeReflink')"
					@click="changeReflinkName"
					:loading="loading"
				/>
			</div>
			<div v-if="currentUser.reflink_hash == null" class="reflink-program-window__btn-wrapper">
				<MButton
					red400
					big
					:label="$t('profile.joinToAffiliateProgram')"
					@click="createReflink"
					:loading="loading"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, ref } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import ReflinkApi from '@/api/v1/ReflinkApi';
import AuthApi from '@/api/v1/AuthApi';
import { toast } from 'vue3-toastify';
import { checkIsUserJoinAffiliateProgram, isAdmin, isPublisher } from '@/hooks/UserHooks';
import { copyToClipboard } from '@/hooks/CopyToClipboardHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { UserInterface } from '@/models/AuthModel';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MButton from '@/components/atoms/MButton.vue';
import MTextField from '@/components/atoms/MTextField.vue';

interface IOwnProps {
	currentUser: UserInterface
}

interface IProps extends IOwnProps {
}

const reflinkApi = new ReflinkApi();
const authApi = new AuthApi();

const props = defineProps<IProps>();
const emit = defineEmits(['userDetails']);

const { user } = storeToRefs(useUserStore());
const { setUser } = useUserStore();
const { t } = useI18n();
const { white } = variables;

const reflink_hash = ref<string|null>(props.currentUser.reflink_hash);
const loading = ref<boolean>(false);

const currentHost = () => {
	return import.meta.env.VITE_MONOLITH_API_HOST;
};

const changeReflinkName = async() => {
	loading.value = true;
	try {
		const result = await reflinkApi.editReflinkHash(props.currentUser.uuid, reflink_hash.value);
		if (!result.isSuccess) {
			toast.error(`${t('reflinks.toast.errorEditName')}`);
			loading.value = false;
			return;
		}
		toast.success(`${t('reflinks.toast.successEditName')}`);
		emit('userDetails');
	} catch (e) {
		toast.error(`${t('reflinks.toast.errorEditName')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const userInfo = async() => {
	try {
		const result = await authApi.me();
		if (!result.isSuccess) {
			window.location.reload();
			return;
		}
		setUser(result.payload);
	} catch (e) {
		window.location.reload();
		return;
	}
};

const createReflink = async() => {
	loading.value = true;
	try {
		const result = await reflinkApi.createDefaultPeriodReflink(props.currentUser.uuid);
		if (!result.isSuccess) {
			toast.error(`${t('reflinks.toast.errorJoin')}`);
			loading.value = false;
			return;
		}
		toast.success(`${t('reflinks.toast.successJoin')}`);
		if (null != user.value && isAdmin(user.value)) {
			emit('userDetails');
		} else {
			userInfo();
		}
	} catch (e) {
		toast.error(`${t('reflinks.toast.errorJoin')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const goToWithdrawal = () => {
	router.push('/withdrawal/create');
};
const goToTransfer = () => {
	router.push('/transfer');
};
</script>

<style scoped lang="scss">
.reflink-program-window {
	background-color: $white-100;
	min-height: 75vh;
	position: relative;
	
	&--admin-active {
		min-height: 10vh;
	}
	
	&__info-wrapper {
		display: flex;
		align-items: baseline;
		gap: 10px;
		padding: 15px;
	}
	
	&__title {
		font-size: 18px;
		font-weight: 700;
	}
	
	&__reflink {
		font-size: 15px;
		
		&--hide-input {
			position: absolute;
			left: -1000px;
			opacity: 0;
		}
	}
	
	&__btn-wrapper {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	
	&__copy-wrapper {
		color: $white-100;
		
		button {
			min-width: max-content !important;
			border-radius: 50% !important;
			padding: 7px 8px 7px 12px !important;
		}
	}
	
	&__reflink-hash-change-wrapper {
		padding: 15px;
	}
}
</style>
