import BaseApi from '@/api/BaseApi';
import type { TableSettingsInterface, TableSettingsRequestInterface } from '@/models/TableSettingsModel'

export default class TableSettingsApi extends BaseApi {
	async tableSettings (activeRoleId: number) {
		return this.get<Array<TableSettingsInterface>>({
			url: `/api/v1/tables/status/${activeRoleId}`,
		});
	};

	async editTableSettings (tableSlug: string, params: TableSettingsRequestInterface, activeRoleId: number) {
		return this.put<TableSettingsRequestInterface, TableSettingsInterface>({
			url: `/api/v1/tables/status/${tableSlug}/${activeRoleId}`,
			data: params,
		});
	};

	async clearTableSettings (tableSlug: string, activeRoleId: number) {
		return this.delete({
			url: `/api/v1/tables/status/${tableSlug}/${activeRoleId}`,
		});
	};
};
