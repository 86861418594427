import BaseApi from '@/api/BaseApi';
import type { MailChimpInterface } from '@/models/MailChimpModel';

export default class MailChimpApi extends BaseApi {
	async getWebhookList () {
		return this.get<MailChimpInterface>({
			url: '/api/v1/mailchimp/webhook',
		});
	};

	async createWebhook () {
		return this.post<any, any>({
			url: '/api/v1/mailchimp/webhook',
		});
	};

	async deleteWebhook (id: string) {
		return this.delete({
			url: `/api/v1/mailchimp/webhook/${id}`,
		});
	};
};
