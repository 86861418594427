import BaseApi from '@/apiWebsites/BaseApi';
import type {
	IAhrefsCSVExport,
	IAhrefsLimitsTable,
	IAhrefsStats,
	IAhrefsUsersReloadRequest,
	IManualUpdateRequest
} from '@/models/AhrefsModel';

export default class AhrefsApi extends BaseApi {
	async statsList(websiteUuid:string|Array<string>|null) {
		return this.get<IAhrefsStats>({
			url: `/api/v1/ahrefs/${websiteUuid}/stats`,
		});
	};
	async refreshStats(websiteUuid:string|Array<string>|null) {
		return this.post<any, IAhrefsStats>({
			url: `/api/v1/ahrefs/${websiteUuid}/sync`,
		});
	};
	async manualUpdateStats(websiteUuid:string|Array<string>|null, params:IManualUpdateRequest) {
		return this.post<IManualUpdateRequest, IAhrefsStats>({
			url: `/api/v1/ahrefs/${websiteUuid}/update`,
			data: params,
		});
	};
	async databasesSynchronization() {
		return this.post<any, any>({
			url: '/api/v1/websites/dispatch',
		});
	};

	async ahrefsLimitList (page:number|null, perPage:number|null, year:number, month:number) {
		return this.get<IAhrefsLimitsTable>({
			url: `/api/v1/ahrefs/limits-and-usage?page=${page}&per_page=${perPage}&year=${year}&month=${month}`,
		});
	};

	async ahrefsLimitCSVList (year:number, month:number) {
		return this.get<IAhrefsCSVExport>({
			url: `/api/v1/ahrefs/limits-and-usage/export?year=${year}&month=${month}`,
		});
	};

	async ahrefsUsersDomainReload (params: IAhrefsUsersReloadRequest) {
		return this.post<IAhrefsUsersReloadRequest, any>({
			url: `api/v1/ahrefs/users-reload-ahrefs`,
			data: params,
		});
	};
};
