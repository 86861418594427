<template>
	<div class="m-expand-transition">
		<div class="m-expand-transition__wrapper">
			<div>{{ text }} <span v-if="number">({{ number }})</span></div>
			
			<div @click="expand = !expand" class="m-expand-transition__activator-btn">
				<div>{{ textOpen }}</div>
				<MIcon v-show="!expand" icon="plus" :color="primary400" />
				<MIcon v-show="expand" icon="minus" :color="primary400" />
			</div>
		</div>
		
		<v-expand-transition>
			<div v-show="expand">
				<slot name="default"/>
			</div>
		</v-expand-transition>
	</div>
</template>

<script setup lang="ts">
import { ref, defineProps } from 'vue';
import variables from '@/assets/scss/modules/variables.module.scss';
import MIcon from '@/components/atoms/MIcon.vue';

interface IOwnProps {
	textOpen?: string
	text?: string
	number?: string
}
interface IProps extends IOwnProps {
}

defineProps<IProps>();
const { primary400 } = variables;

const expand = ref<boolean>(false);
</script>

<style scoped lang="scss">
.m-expand-transition {
	display: flex;
	flex-direction: column;
	padding: 10px 25px 10px 10px;
	background-color: $white-100;
	border-bottom: 1px solid $grey-200;
	width: 100%;
	
	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		font-weight: 700;
	}
	
	&__activator-btn {
		width: max-content;
		cursor: pointer;
		transition: opacity 0.5s;
		
		&:hover {
			opacity: 0.5;
		}
		
		svg {
			border: 1px solid $grey-300;
			border-radius: 10px;
			width: 22px;
			height: 22px;
			padding: 4px;
		}
	}
}
</style>
