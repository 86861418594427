export const getObjPersisted = (key: string) => {
    const item = localStorage.getItem(key)
    if (item) {
        return JSON.parse(item)
    }
    return null
}
export const getStrPersisted = (key: string) => {
    return localStorage.getItem(key)
}

export const setObjPersisted = (key: string, obj: any) => {
    localStorage.setItem(key, JSON.stringify(obj))
}

export const setStrPersisted = (key: string, str: string) => {
    localStorage.setItem(key, str)
}

export const clearObjPersisted = (key: string) => {
    localStorage.removeItem(key)
}
