<template>
	<div class="withdrawal-form">
		<Form ref="myForm" :validation-schema="schema" @submit="onSubmit" class="transfer__form-wrapper">
			<MProgressBar indeterminate height="2" v-show="withdrawalLoading" />
			<MAutocomplete
				v-if="null != user && isPublisher(user) && checkIsUserJoinAffiliateProgram(user) && !isAdmin(user)"
				id="withdraw-from-account"
				v-model="withdrawForm.from_account"
				name="fromAccount"
				:get-items="usersAccounts"
				item-title="name"
				item-value="id"
				:label="$t('withdraw.transferFrom')"
				label-text-required
				:placeholder="$t('withdraw.transferFromPlaceholder')"
				variant="outlined"
				clearable
				return-object
				:disabled="null != router.currentRoute.value.params.withdrawUuid && 'create' != router.currentRoute.value.params.withdrawUuid"
			/>
			<MTextField
				id="withdraw-amount"
				v-model="withdrawForm.amount"
				name="amount"
				type="number"
				min="0.01"
				step="0.01"
				:separate-label="`${$t('withdraw.amount')} (${userCurrencySymbol})`"
				label-text-required
				:placeholder="$t('withdraw.amount')"
				:disabled="null != router.currentRoute.value.params.withdrawUuid && 'create' != router.currentRoute.value.params.withdrawUuid"
			/>
			
			<v-tabs v-model="tab" fixed-tabs :bg-color="primary400">
				<v-tab id="paypal-withdraw" :value="WITHDRAWAL_PAYPAL">{{ $t('withdraw.paypal') }}</v-tab>
				<v-tab id="bank-withdraw" :value="WITHDRAWAL_BANK">{{ $t('withdraw.bank') }}</v-tab>
			</v-tabs>
			
			<v-window v-model="tab">
				<v-window-item :value="WITHDRAWAL_PAYPAL">
					<MTextField
						id="withdraw-paypal"
						v-model="withdrawForm.paypal"
						name="paypal"
						:separate-label="$t('withdraw.paypalId')"
						label-text-required
						:placeholder="$t('withdraw.paypalId')"
						:disabled="null != router.currentRoute.value.params.withdrawUuid && 'create' != router.currentRoute.value.params.withdrawUuid"
					/>
				</v-window-item>
				<v-window-item :value="WITHDRAWAL_BANK">
					<MTextField
						id="withdraw-account-number"
						v-model="withdrawForm.accountNumber"
						name="accountNumber"
						:separate-label="$t('withdraw.accountNumber')"
						label-text-required
						:placeholder="$t('withdraw.accountNumber')"
						:disabled="null != router.currentRoute.value.params.withdrawUuid && 'create' != router.currentRoute.value.params.withdrawUuid"
					/>
					<MTextField
						id="withdraw-sort-code"
						v-model="withdrawForm.sortCode"
						name="sortCode"
						:separate-label="$t('withdraw.sortCode')"
						:placeholder="$t('withdraw.sortCode')"
						:hint="$t('withdraw.sortCodeHint')"
						persistent-hint
						:disabled="null != router.currentRoute.value.params.withdrawUuid && 'create' != router.currentRoute.value.params.withdrawUuid"
					/>
					<MTextField
						id="withdraw-bank-name"
						v-model="withdrawForm.bankName"
						name="bankName"
						:separate-label="$t('withdraw.bankName')"
						label-text-required
						:placeholder="$t('withdraw.bankName')"
						:disabled="null != router.currentRoute.value.params.withdrawUuid && 'create' != router.currentRoute.value.params.withdrawUuid"
					/>
					<MTextField
						id="withdraw-account-holder-name"
						v-model="withdrawForm.accountHolderName"
						name="accountHolderName"
						:separate-label="$t('withdraw.accountHolderName')"
						label-text-required
						:placeholder="$t('withdraw.accountHolderName')"
						:disabled="null != router.currentRoute.value.params.withdrawUuid && 'create' != router.currentRoute.value.params.withdrawUuid"
					/>
					<MTextField
						id="withdraw-ifsc-code"
						v-model="withdrawForm.ifscCode"
						name="ifscCode"
						:separate-label="$t('withdraw.ifscCode')"
						:placeholder="$t('withdraw.ifscCode')"
						:hint="$t('withdraw.ifscCodeHint')"
						persistent-hint
						:disabled="null != router.currentRoute.value.params.withdrawUuid && 'create' != router.currentRoute.value.params.withdrawUuid"
					/>
				</v-window-item>
			</v-window>
			
			<div v-if="null != user && !isAdmin(user) && (null == router.currentRoute.value.params.withdrawUuid || 'create' === router.currentRoute.value.params.withdrawUuid)" class="withdrawal-form__label">{{ $t('withdraw.sendInvoice') }} <span>{{ $t('components.fieldRequired') }}</span></div>
			<div v-if="null != user && !isAdmin(user) && (null == router.currentRoute.value.params.withdrawUuid || 'create' === router.currentRoute.value.params.withdrawUuid)" :class="{'withdrawal-form__drop-wrapper': true, 'withdrawal-form__drop-wrapper--error': fileErrorValidation}">
				<div @dragover="dragover" @dragleave="dragleave" @drop="drop">
					<div class="withdrawal-form__drop-wrapper--input-wrapper">
						<label for="assetsFieldHandle">
							<div class="withdrawal-form__drop-text">{{ $t('withdraw.uploadDropMessage') }}</div>
							<div class="withdrawal-form__file-text">{{ null != withdrawForm.file && withdrawForm.file.length > 0 ? withdrawForm.file[0].name : null }}</div>
						</label>
						<input
							type="file"
							name="articleFile"
							id="upload-invoice"
							@change="onChangeFile"
							ref="file"
							accept="*"
						/>
						<div class="withdrawal-form__file-btn">{{ $t('withdraw.selectFile') }}</div>
					</div>
				</div>
			</div>
			<div v-show="fileErrorValidation" class="withdrawal-form__error">{{ $t('withdraw.validation.fileValidation') }}</div>
			
			<div v-if="null != user && !isAdmin(user) && (null == router.currentRoute.value.params.withdrawUuid || 'create' === router.currentRoute.value.params.withdrawUuid)" class="withdrawal-form__btn-wrapper">
				<MButton
					id="transfer-submit"
					type="submit"
					green400
					normal
					width150
					:label="$t('withdraw.withdrawRequest')"
					:loading="loading"
				/>
			</div>
		</Form>
		
		<div v-if="null != router.currentRoute.value.params.withdrawUuid && 'create' != router.currentRoute.value.params.withdrawUuid" class="withdrawal-form__btn-wrapper">
			<MButton
				id="download-invoice"
				red400
				normal
				width150
				:label="$t('withdraw.downloadInvoice')"
				:loading="withdrawalInvoiceLoading"
				@click="downloadInvoice"
			/>
		</div>
		
		<div v-if="null != user && isAdmin(user)" class="withdrawal-form__user-info">
			<div class="withdrawal-form__title">{{ $t('withdraw.userInfo') }}</div>
			<div class="withdrawal-form__subtitle">{{ $t('withdraw.firstName') }}: {{ null != userDetails ? userDetails.first_name : '' }}</div>
			<div class="withdrawal-form__subtitle">{{ $t('withdraw.lastName') }}: {{ null != userDetails ? userDetails.last_name : '' }}</div>
			<div class="withdrawal-form__subtitle">{{ $t('withdraw.email') }}: {{ null != userDetails ? userDetails.email : '' }}</div>
			<div class="withdrawal-form__subtitle">{{ $t('withdraw.vatNo') }}: {{ null != userDetails ? userDetails.vat_no : '' }}</div>
			<div class="withdrawal-form__subtitle">{{ $t('withdraw.companyName') }}: {{ null != userDetails ? userDetails.company_name : '' }}</div>
			<div class="withdrawal-form__subtitle">{{ $t('withdraw.country') }}: {{ null != userDetails ? userDetails.country_id : '' }}</div>
			<div v-if="status === 'Sketch'" class="withdrawal-form__admin-btn-wrapper">
				<MButton
					id="transfer-cancel"
					red400
					big
					:label="$t('withdraw.cancel')"
					:loading="withdrawalRequestLoading"
					@click="adminWithdrawDecision(false)"
				/>
				<MButton
					id="transfer-accept"
					green400
					big
					:label="$t('withdraw.accept')"
					:loading="withdrawalRequestLoading"
					@click="adminWithdrawDecision(true)"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, watch, defineEmits } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useBooleanFlagStore } from '@/stores/booleanFlag';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { toast } from 'vue3-toastify';
import {
	ADVERTISER_ID,
	AFFILIATE_ACCOUNT_ROLE_ID,
	isPublisher,
	checkIsUserJoinAffiliateProgram,
	isAdvertiser,
	PUBLISHER_ID,
	isAdmin,
	refreshUser
} from '@/hooks/UserHooks';
import { WITHDRAWAL_PAYPAL, WITHDRAWAL_BANK } from '@/hooks/WithdrawalHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import WithdrawApi from '@/api/v2/WithdrawApi';
import UserApi from '@/api/v1/UserApi';
import type { UserAccountsInterface, UserInterface } from '@/models/AuthModel';
import type { IWithdrawalRequest, IWithdrawalStatusChange, TWithdrawalForm } from '@/models/WithdrawalModel';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MButton from '@/components/atoms/MButton.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';

const withdrawApi = new WithdrawApi();
const userApi = new UserApi();

const { user, userCurrencySymbol, userAffiliateWalletValue, userWalletValue } = storeToRefs(useUserStore());
const { setUserWalletValue, setUserAffiliateWalletValue, setUser } = useUserStore();
const { setIsRefreshWalletAction } = useBooleanFlagStore();
const { t } = useI18n();
const { primary400 } = variables;
const emit = defineEmits(['sendWithdrawalRequest']);

const schema:any = yup.object({
	fromAccount: yup.object().label('fromAccount'),
	paypal: yup.string().required(`${t('withdraw.validation.paypal')}`).label('paypal'),
	accountNumber: yup.string().label('accountNumber'),
	bankName: yup.string().label('bankName'),
	accountHolderName: yup.string().label('accountHolderName'),
	amount: yup.number().required(`${t('withdraw.validation.amount')}`).min(0.01, `${t('withdraw.validation.minAmount')}`).label('amount').test('amount', `${t('withdraw.validation.toHighAmount')}`,
	function (val) {
		if (null != user.value) {
			if (isAdvertiser(user.value) && null != userAffiliateWalletValue.value && val <= userAffiliateWalletValue.value) {
				return true;
			}
			if (isPublisher(user.value)) {
				if (!checkIsUserJoinAffiliateProgram(user.value) && null != userWalletValue.value && val <= userWalletValue.value) {
					return true;
				}
				if (checkIsUserJoinAffiliateProgram(user.value) && null != withdrawForm.value.from_account && withdrawForm.value.from_account.role_id === AFFILIATE_ACCOUNT_ROLE_ID && null != userAffiliateWalletValue.value && val <= userAffiliateWalletValue.value) {
					return true;
				}
				if (checkIsUserJoinAffiliateProgram(user.value) && null != withdrawForm.value.from_account && withdrawForm.value.from_account.role_id === PUBLISHER_ID && null != userWalletValue.value && val <= userWalletValue.value) {
					return true;
				}
			}
		}
		return false;
	}),
});

const myForm = ref<TWithdrawalForm|null>(null);
const loading = ref<boolean>(false);
const withdrawalLoading = ref<boolean>(false);
const withdrawalRequestLoading = ref<boolean>(false);
const withdrawalInvoiceLoading = ref<boolean>(false);
const withdrawForm = ref<TWithdrawalForm>({
	amount: 0,
	from_account: null,
	paypal: null,
	accountNumber: null,
	sortCode: null,
	bankName: null,
	accountHolderName: null,
	ifscCode: null,
	file: null,
});
const usersAccounts = ref<Array<UserAccountsInterface>>([]);
const tab = ref<string|null>(WITHDRAWAL_PAYPAL);
const status = ref<string|null>(null);
const fileErrorValidation = ref<boolean>(false);
const userDetails = ref<UserInterface|null>(null);

if (null != user.value) {
	if (isPublisher(user.value) && checkIsUserJoinAffiliateProgram(user.value)) {
		const accountCurrent = user.value.accounts.map((elem) => {
			return {
				...elem,
			};
		});
		const advertiserAccount = accountCurrent.find(elem => elem.role_id === ADVERTISER_ID);
		if (null != advertiserAccount) {
			const index = accountCurrent.indexOf(advertiserAccount);
			accountCurrent.splice(index, 1);
			accountCurrent.forEach((elem: any) => {
				elem.name = elem.role_id === AFFILIATE_ACCOUNT_ROLE_ID ? `${t('withdraw.affiliateAccount')}` : `${t('withdraw.mainAccount')}`;
			});
			usersAccounts.value = accountCurrent;
		}
	}
	if (isPublisher(user.value) && !checkIsUserJoinAffiliateProgram(user.value) && 'create' === router.currentRoute.value.params.withdrawUuid) {
		const publisherAccount = user.value.accounts.find((elem) => elem.role_id === PUBLISHER_ID);
		if (null != publisherAccount) {
			withdrawForm.value.amount = (publisherAccount.balance / 100).toFixed(2);
		}
	}
	if (isAdvertiser(user.value) && checkIsUserJoinAffiliateProgram(user.value) && 'create' === router.currentRoute.value.params.withdrawUuid) {
		const agencyAccount = user.value.accounts.find((elem) => elem.role_id === AFFILIATE_ACCOUNT_ROLE_ID);
		if (null != agencyAccount) {
			withdrawForm.value.amount = (agencyAccount.balance / 100).toFixed(2);
		}
	}
}
let currentSelectedRoleId: number | null = null;
watch((withdrawForm.value), (val) => {
	if (null != user.value && isPublisher(user.value) && 1 === user.value.is_agency) {
		if (null != val.from_account && val.from_account.role_id !== currentSelectedRoleId) {
			currentSelectedRoleId = val.from_account.role_id;
			withdrawForm.value.amount = (val.from_account.balance / 100).toFixed(2);
		}
		if (null == val.from_account) {
			currentSelectedRoleId = null;
		}
	}
},{
	deep: true,
});

const onSubmit = async() => {
	loading.value = true;
	if (null == withdrawForm.value.file) {
		fileErrorValidation.value = true;
		return;
	}
	
	const affiliateAccount = user.value?.accounts.find(elem => elem.role_id === AFFILIATE_ACCOUNT_ROLE_ID);
	const publisherAccount = user.value?.accounts.find(elem => elem.role_id === PUBLISHER_ID);
	
	const form:IWithdrawalRequest = {
		method: tab.value,
		user_id: null != user.value ? user.value.id : '',
		amount: null != withdrawForm.value.amount ? +withdrawForm.value.amount * 100 : 0,
		currency_id: null != user.value?.currency_id ? user.value?.currency_id : 0,
		invoice: withdrawForm.value.file[0],
		bank_name: withdrawForm.value.bankName,
		account_owner: withdrawForm.value.accountHolderName,
		account_number: withdrawForm.value.accountNumber,
		paypal_id: withdrawForm.value.paypal,
		sort_code: withdrawForm.value.sortCode,
		ifsc_code: withdrawForm.value.ifscCode,
		account_id: null != user.value && isPublisher(user.value) && checkIsUserJoinAffiliateProgram(user.value) && null != withdrawForm.value.from_account ? withdrawForm.value.from_account.id : null != user.value && isAdvertiser(user.value) ? affiliateAccount?.id : publisherAccount?.id,
	};
	
	try {
		const result = await withdrawApi.withdrawRequest(form);
		if (!result.isSuccess) {
			toast.error(`${t('withdraw.toast.errorWithdraw')}`);
			loading.value = false;
			return;
		}
		toast.success(`${t('reflinks.toast.successWithdraw')}`);
		await setIsRefreshWalletAction(true);
		const updatedUser = await refreshUser();
		if (null != updatedUser?.current_wallet) {
			await setUserWalletValue((+updatedUser?.current_wallet.balance / 100));
		}
		if (null != updatedUser?.affiliate_wallet) {
			await setUserAffiliateWalletValue((+updatedUser?.affiliate_wallet.balance / 100));
		}
		if (null != updatedUser?.user) {
			await setUser(updatedUser.user)
		}
		await setIsRefreshWalletAction(false);
		emit('sendWithdrawalRequest');
		
		// Reset formularza
		if (myForm.value) {
			// @ts-ignore
			myForm.value.resetForm({
				values: {
					amount: 0,
					paypal: null,
					accountNumber: null,
					sortCode: null,
					bankName: null,
					accountHolderName: null,
					ifscCode: null,
					from_account: null,
				}
			});
		}
		
		withdrawForm.value.file = null;
		fileErrorValidation.value = false;
		tab.value = WITHDRAWAL_PAYPAL;
	} catch (e) {
		toast.error(`${t('withdraw.toast.errorWithdraw')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const onChangeFile = (event:any) => {
	withdrawForm.value.file = event.target.files;
	fileErrorValidation.value = false;
};
const dragover = (event:any) => {
	event.preventDefault();
	// Add some visual fluff to show the user can drop its files
	if (!event.currentTarget.classList.contains('withdrawal-form__bg-green-300')) {
		event.currentTarget.classList.remove('withdrawal-form__bg-gray-100');
		event.currentTarget.classList.add('withdrawal-form__bg-green-300');
	}
};
const dragleave = (event:any) => {
	event.preventDefault();
	// Clean up
	event.currentTarget.classList.add('withdrawal-form__bg-gray-100');
	event.currentTarget.classList.remove('withdrawal-form__bg-green-300');
};
const drop = (event:any) => {
	event.preventDefault();
	event.stopPropagation();

	event.currentTarget.classList.add('withdrawal-form__bg-gray-100');
	event.currentTarget.classList.remove('withdrawal-form__bg-green-300');

	withdrawForm.value.file = event.dataTransfer.files;
	fileErrorValidation.value = false;
};

watch((tab), (val) => {
	if (val === WITHDRAWAL_PAYPAL) {
		schema.fields['paypal'] = yup.string().required(`${t('withdraw.validation.paypal')}`).label('paypal');
		schema.fields['accountNumber'] = yup.string().nullable().label('accountNumber');
		schema.fields['bankName'] = yup.string().nullable().label('bankName');
		schema.fields['accountHolderName'] = yup.string().nullable().label('accountHolderName');
	}
	if (val === WITHDRAWAL_BANK) {
		schema.fields['paypal'] = yup.string().nullable().label('paypal');
		schema.fields['accountNumber'] = yup.string().required(`${t('withdraw.validation.accountNumber')}`).label('accountNumber');
		schema.fields['bankName'] = yup.string().required(`${t('withdraw.validation.bankName')}`).label('bankName');
		schema.fields['accountHolderName'] = yup.string().required(`${t('withdraw.validation.accountHolderName')}`).label('accountHolderName');
	}
	if (null != user.value && isPublisher(user.value) && checkIsUserJoinAffiliateProgram(user.value)) {
		schema.fields['fromAccount'] = yup.object().required(`${t('withdraw.validation.fromAccount')}`).label('fromAccount');
	}
}, {
	deep: true,
});

const userInfo = async(uuid:string) => {
	try {
		const result = await userApi.userInfo(uuid);
		if (!result.isSuccess) {
			return;
		}
		userDetails.value = result.payload;
	} catch (e) {
		return;
	}
};

const withdrawDetails = async() => {
	withdrawalLoading.value = true;
	
	let withdrawUuid = null;
	if (router.currentRoute.value.params.withdrawUuid !== 'create' && null != router.currentRoute.value.params.withdrawUuid) {
		withdrawUuid = router.currentRoute.value.params.withdrawUuid;
	}
	try {
		const result = await withdrawApi.withdrawDetails(withdrawUuid);
		if (!result.isSuccess) {
			toast.error(`${t('withdraw.toast.errorWithdrawDetails')}`);
			withdrawalLoading.value = false;
			return;
		}
		
		tab.value = result.payload.method;
		withdrawForm.value.amount = +result.payload.amount.toFixed(2);
		withdrawForm.value.paypal = result.payload.paypal_id;
		withdrawForm.value.accountNumber = result.payload.account_number;
		withdrawForm.value.bankName = result.payload.bank_name;
		withdrawForm.value.accountHolderName = result.payload.account_owner;
		withdrawForm.value.sortCode = result.payload.sort_code;
		withdrawForm.value.ifscCode = result.payload.ifsc_code;
		withdrawForm.value.file = result.payload.invoice_url;
		status.value = result.payload.status;
		
		await userInfo(result.payload.user_id);
	} catch (e) {
		toast.error(`${t('withdraw.toast.errorWithdrawDetails')}`);
		withdrawalLoading.value = false;
		return;
	}
	withdrawalLoading.value = false;
};

if (null != user.value && router.currentRoute.value.params.withdrawUuid !== 'create') {
	withdrawDetails();
}

const adminWithdrawDecision = async(decision:boolean) => {
	withdrawalRequestLoading.value = true;
	
	let withdrawUuid = null;
	if (router.currentRoute.value.params.withdrawUuid !== 'create' && null != router.currentRoute.value.params.withdrawUuid) {
		withdrawUuid = router.currentRoute.value.params.withdrawUuid;
	}
	const requestForm:IWithdrawalStatusChange = {
		status: decision,
		withdrawal_id: null != withdrawUuid ? +withdrawUuid : 0,
	};
	try {
		const result = await withdrawApi.changeWithdrawStatus(requestForm);
		if (!result.isSuccess) {
			toast.error(`${t('withdraw.toast.errorChangeStatus')}`);
			withdrawalRequestLoading.value = false;
			return;
		}
		await router.push('/withdrawal');
		toast.success(`${t('withdraw.toast.successWithdrawChangeStatus')}`);
	} catch (e) {
		toast.error(`${t('withdraw.toast.errorChangeStatus')}`);
		withdrawalRequestLoading.value = false;
		return;
	}
	withdrawalRequestLoading.value = false;
};

const downloadInvoice = async() => {
	if (null != withdrawForm.value.file && null != withdrawForm.value) {
		withdrawalInvoiceLoading.value = true;
		try {
			window.open(withdrawForm.value.file, '_blank');
			// const result = await withdrawApi.getWithdrawalInvoice(router.currentRoute.value.params.withdrawUuid);
			// if (!result.isSuccess) {
			// 	withdrawalInvoiceLoading.value = false;
			// 	return;
			// }
			// const binaryString = window.atob(result.payload);
			// const binaryLen = binaryString.length;
			// const bytes = new Uint8Array(binaryLen);
			// for (let i = 0; i < binaryLen; i++) {
			// 	const ascii = binaryString.charCodeAt(i);
			// 	bytes[i] = ascii;
			// }
			// const blob = new Blob([bytes], { type: 'application/pdf' });
			// const link = document.createElement('a');
			// link.href = window.URL.createObjectURL(blob);
			// link.download = 'invoice.pdf';
			// link.click();
		} catch (e) {
			withdrawalInvoiceLoading.value = false;
			return;
		}
		withdrawalInvoiceLoading.value = false;
	}
};
</script>

<style scoped lang="scss">
.withdrawal-form {
	&__label {
		font-weight: 700;
		font-size: 14px;
		color: $grey-400;
		
		span {
			color: $secondary-400;
		}
	}
	
	&__drop-wrapper {
		border: 2px dashed $grey-300;
		border-radius: 12px;
		
		&--error {
			border-color: $secondary-400;
		}
		
		&--input-wrapper {
			padding: 15px;
			height: 158px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			
			input[type='file'] {
				position: absolute;
				z-index: 1;
				top: 70%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 158px;
				height: 40px;
				opacity: 0;
				cursor: pointer;
			}
			
			label {
				margin-bottom: 20px;
			}
		}
	}
	
	&__file-btn {
		background-color: $primary-400;
		cursor: pointer;
		border-radius: 8px;
		color: $white-100;
		font-size: 14px;
		font-weight: 600;
		padding-top: 9px;
		text-align: center;
		position: absolute;
		top: 70%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 158px;
		height: 40px;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__drop-text {
		font-size: 12px;
		color: $grey-300;
	}
	
	&__bg-green-300 {
		background-color: $green-300;
		padding: 20px;
		border-radius: 12px;
		
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
	}
	
	&__bg-gray-100 {
		background-color: $grey-200;
		padding: 20px;
		border-radius: 12px;
		
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
	}
	
	&__error {
		color: $secondary-400;
	}
	
	&__file-text {
		text-align: center;
	}
	
	&__btn-wrapper {
		width: max-content;
		margin-top: 20px;
	}
	
	&__user-info {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	
	&__title {
		font-size: 18px;
		font-weight: 700;
		color: $primary-400;
		margin-top: 15px;
	}
	
	&__subtitle {
		font-size: 14px;
		font-weight: 700;
		color: $primary-400;
	}
	
	&__admin-btn-wrapper {
		display: flex;
		justify-content: space-between;
	}
	
	&:deep(.v-slide-group) {
		color: $white-100;
		border-radius: 8px;
		margin-bottom: 15px;
	}
	
	&:deep(.v-tab__slider) {
		background: $secondary-400;
		height: 3px;
	}
	
	&:deep(.v-text-field .v-input__details) {
		padding-inline-start: 0;
	}
}
</style>
