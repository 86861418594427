<template>
	<div class="create-article-form">
		<Form :validation-schema="schema" @submit="onSubmit">
			<MAutocomplete
				v-if="!props.isDialogForm"
				id="article-campaign"
				name="articleCampaign"
				v-model="articleForm.campaign_id"
				:get-items="campaigns"
				variant="outlined"
				item-title="name"
				item-value="id"
				:label="$t('order.orderFlow.articleCampaign')"
				:placeholder="$t('order.orderFlow.articleCampaignPlaceholder')"
				@onChange="onCampaignChange"
				clearable
			/>
			<MAutocomplete
				v-if="!props.isDialogForm"
				id="article-language"
				name="articleLanguage"
				v-model="articleForm.language_id"
				label-text-required
				:get-items="languages"
				variant="outlined"
				item-title="name"
				item-value="id"
				:label="$t('order.orderFlow.articleLanguage')"
				:placeholder="$t('order.orderFlow.articleLanguagePlaceholder')"
			/>
			<div v-if="null != articleForm.language_id">
				<MTextField
					id="article-title"
					name="articleTitle"
					v-model="articleForm.title"
					label-text-required
					:separate-label="$t('order.orderFlow.articleTitle')"
					:placeholder="$t('order.orderFlow.articleTitlePlaceholder')"
					clearable
				/>
				<MVueEditor
					id="article-content"
					name="articleContent"
					v-model="articleForm.content"
					label-text-required
					:separate-label="$t('order.orderFlow.articleContent')"
					:placeholder="$t('order.orderFlow.articleContentPlaceholder')"
				/>
				<MTextArea
					id="article-publisher-note"
					name="articlePublisherNote"
					v-model="articleForm.publisherNote"
					:separate-label="$t('order.orderFlow.articlePublisherNote')"
					:placeholder="$t('order.orderFlow.articlePublisherNotePlaceholder')"
				/>
				<div class="create-article-form__label-required">{{ $t('order.orderFlow.addPhoto') }}</div>
				<div v-if="0 === articleDetails.images.length || null == articleDetails.images" class="create-article-form__drop-wrapper">
					<div @dragover="dragover" @dragleave="dragleave" @drop="drop">
						<div class="create-article-form__drop-wrapper--input-wrapper">
							<label for="assetsFieldHandle">
								<div class="create-article-form__drop-text">{{ $t('order.orderFlow.uploadDropMessage') }}</div>
								<div class="create-article-form__remove-wrapper">
									<div v-if="articleForm.file !== undefined && articleForm.file != null">
										{{ articleForm.file.name }}
									</div>
									<div v-if="articleForm.file !== undefined && articleForm.file != null" @click="removeFile">
										<MIcon icon="close" width="12" height="12" />
									</div>
								</div>
							</label>
							<input
								type="file"
								name="orderPhoto"
								id="article-photo"
								@change="onChangeImage"
								ref="file"
								accept=".jpg,.jpeg,.png"
							/>
							<div class="create-article-form__file-btn">{{ $t('order.orderFlow.selectFile') }}</div>
						</div>
					</div>
				</div>
				<div v-if="0 !== articleDetails.images.length && null != articleDetails.images">
					<div class="create-article-form__photo-wrapper">
						<img :src="articleDetails.images[0].url" alt="marketin9">
						<div class="create-article-form__photo-btn-wrapper">
							<div class="create-article-form__edit-btn-wrapper">
								<div id="edit-photo" class="create-article-form__edit-photo">
									<div class="create-article-form__btn">{{ $t('articles.edit') }}</div>
									<div class="create-article-form__upload-hover-wrapper">
										<v-file-input v-model="articleForm.file" accept="image/*" />
									</div>
								</div>
							</div>
							<div id="remove-photo" class="create-article-form__btn create-article-form__btn--red" @click="deleteImage">{{ $t('articles.delete') }}</div>
						</div>
					</div>
				</div>
				<MAutocomplete
					v-if="undefined !== footerItems && footerItems.length > 0"
					id="article-footer"
					name="articleFooter"
					v-model="articleForm.footer_id"
					:get-items="footerItems"
					variant="outlined"
					item-title="company_name"
					item-value="id"
					:label="$t('order.orderFlow.articleFooter')"
					:placeholder="$t('order.orderFlow.articleFooterPlaceholder')"
					clearable
				/>
			</div>
			<div class="create-article-form__article-btn-wrapper" :class="{'create-article-form__article-btn-wrapper--dialog': props.isDialogForm}">
				<MButton v-if="!props.isDialogForm" id="article-back" :label="$t('articles.back')" blue400 normal width100 @click="goBack" />
				<MButton id="article-submit" :label="!props.isDialogForm ? $t('articles.save') : $t('components.add')" green400 width100 normal />
			</div>
		</Form>
		<MLoading v-show="loading" />
	</div>
</template>

<script setup lang="ts">
import { ref, watch, defineProps, defineEmits, toRef } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { useDictionaryStore } from '@/stores/dictionary';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { toast } from 'vue3-toastify';
import ImageApi from '@/api/v1/ImageApi';
import CampaignApi from '@/api/v1/CampaignApi';
import ArticleApi from '@/api/v1/ArticleApi';
import MVueEditor from '@/components/atoms/MVueEditor.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MButton from '@/components/atoms/MButton.vue';
import MTextArea from '@/components/atoms/MTextArea.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MLoading from '@/components/atoms/MLoading.vue';
import type { ICampaignsFooter } from '@/models/CampaignModel';
import type { IArticleDetailsResponse, ICreateArticleRequest } from '@/models/ArticlesModel';

interface IOwnProps {
	isDialogForm?: boolean
	campaignId?: number|null
	languageId?: number
}
interface IProps extends IOwnProps {
}

const imageApi = new ImageApi();
const articleApi = new ArticleApi();
const campaignApi = new CampaignApi();

const emit = defineEmits(['dialogSaveArticle']);
const props = defineProps<IProps>();
const { t } = useI18n();
const { campaigns, languages } = useDictionaryStore();
const campaignId = toRef(props.campaignId);
const languageId = toRef(props.languageId);

const articleForm = ref<ICreateArticleRequest|any>({
	campaign_id: null,
	language_id: null,
	title: null,
	content: null,
	file: undefined,
	publisherNote: null,
	footer_id: null,
});
const articleDetails = ref<IArticleDetailsResponse|any>({
	uuid: null,
	id: null,
	campaign_id: null,
	language_id: null,
	title: null,
	content: null,
	images: [],
});
const footerItems = ref<Array<ICampaignsFooter>|any>([]);
const articleUuid = ref<string|Array<string>>('');
const loading = ref<boolean>(false);

const campaignFooterList = async(campaignId?:number) => {
	let campaignUuid = null;
	if (null != campaignId) {
		const campaign = campaigns.find(elem => elem.id === campaignId);
		if (null != campaign) {
			campaignUuid = campaign.uuid;
		}
	} else {
		const campaign = campaigns.find(elem => elem.id === articleForm.value.campaign_id);
		if (null != campaign) {
			campaignUuid = campaign.uuid;
		}
	}
	if (campaignUuid == null) {
		return;
	}
	try {
		const result = await campaignApi.campaignsFooters(campaignUuid);
		if (!result.isSuccess) {
			return;
		}
		footerItems.value = result.payload.data;
	} catch (e) {
		return;
	}
};
const getArticleDetails = async() => {
	loading.value = true;
	try {
		const result = await articleApi.articleDetails(articleUuid.value);
		if (!result.isSuccess) {
			loading.value = false;
			toast.error(`${t('order.orderFlow.toast.errorGetArticleDetails')}`);
			return;
		}
		articleForm.value.campaign_id = result.payload.campaign_id;
		articleForm.value.language_id = result.payload.language_id;
		articleForm.value.title = result.payload.title;
		articleForm.value.content = result.payload.content;
		articleForm.value.publisherNote = result.payload.publisher_note;
		articleForm.value.footer_id = result.payload.footer_id;
		articleDetails.value.id = result.payload.id;
		articleDetails.value.uuid = result.payload.uuid;
		articleDetails.value.images = result.payload.images;
	} catch (e) {
		loading.value = false;
		toast.error(`${t('order.orderFlow.toast.errorGetArticleDetails')}`);
		return;
	}
	loading.value = false;
};

const initialEditRequests = async() => {
	await getArticleDetails();
	await campaignFooterList(articleDetails.value.campaign_id);
};
if (null != router.currentRoute.value.params.articleUuid) {
	articleUuid.value = router.currentRoute.value.params.articleUuid;
}
if ('' !== articleUuid.value && null != articleUuid.value) {
	initialEditRequests();
}
if (null != campaignId.value) {
	articleForm.value.campaign_id = campaignId.value;
	campaignFooterList();
}
if (null != languageId.value) {
	articleForm.value.language_id = languageId.value;
}

let schema:any = {};

if (props.isDialogForm) {
	schema = yup.object({
		articleTitle: yup.string().required(`${t('order.orderFlow.validation.articleRequired')}`).label('articleTitle'),
		articleContent: yup.string().required(`${t('order.orderFlow.validation.articleContentRequired')}`).label('articleContent'),
	});
} else {
	schema = yup.object({
		articleCampaign: yup.string().nullable().label('articleCampaign'),
		articleLanguage: yup.string().required(`${t('order.orderFlow.validation.articleLanguage')}`).label('articleLanguage'),
		articleTitle: yup.string().required(`${t('order.orderFlow.validation.articleRequired')}`).label('articleTitle'),
		articleContent: yup.string().required(`${t('order.orderFlow.validation.articleContentRequired')}`).label('articleContent'),
	});
}

const onChangeImage = (event:any) => {
	articleForm.value.file = null;
	Array.from(event.target.files).forEach(file => {
		articleForm.value.file = file;
	});
};
const dragover = (event:any) => {
	event.preventDefault();
	// Add some visual fluff to show the user can drop its files
	if (!event.currentTarget.classList.contains('create-article-form__bg-green-300')) {
		event.currentTarget.classList.remove('create-article-form__bg-gray-100');
		event.currentTarget.classList.add('create-article-form__bg-green-300');
	}
};
const dragleave = (event:any) => {
	// Clean up
	event.currentTarget.classList.add('create-article-form__bg-gray-100');
	event.currentTarget.classList.remove('create-article-form__bg-green-300');
};
const drop = (event:any) => {
	articleForm.value.file = null;
	event.preventDefault();
	event.currentTarget.classList.add('create-article-form__bg-gray-100');
	event.currentTarget.classList.remove('create-article-form__bg-green-300');
	
	Array.from(event.dataTransfer.files).forEach(file => {
		articleForm.value.file = file;
	});
};
const removeFile = () => {
	articleForm.value.file = null;
};

const uploadImage = async(isEditableImage?:boolean, articleId?:number) => {
	loading.value = true;
	try {
		const result = await imageApi.sendImage(null != articleDetails.value.id ? articleDetails.value.id : articleId, isEditableImage ? articleForm.value.file[0] : articleForm.value.file);
		if(!result.isSuccess) {
			toast.error(`${t('order.orderFlow.toast.errorUploadPhoto')}`);
			loading.value = false;
			return;
		}
		if (isEditableImage) {
			articleDetails.value.images = [];
			articleDetails.value.images.push(result.payload);
		}
		articleForm.value.file = null;
	} catch (e) {
		toast.error(`${t('order.orderFlow.toast.errorUploadPhoto')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

watch(([articleForm.value]), async(val) => {
	if (val[0].file && null != val[0].file && null != articleDetails.value.images && 0 !== articleDetails.value.images.length) {
		await uploadImage(true);
	}
}, {
	deep: true,
});

const deleteImage = async() => {
	loading.value = true;
	try {
		const result = await imageApi.deleteImage(articleDetails.value.id);
		if (!result.isSuccess) {
			toast.error(t('order.orderFlow.toast.errorDeleteImage'));
			loading.value = false;
			return;
		}
		articleForm.value.file = null;
	} catch (e) {
		toast.error(t('order.orderFlow.toast.errorDeleteImage'));
		loading.value = false;
		return;
	}
	toast.success(t('order.orderFlow.toast.successDeleteImage'));
	await getArticleDetails();
	loading.value = false;
};

const onSubmit = async() => {
	loading.value = true;
	if ('' === articleUuid.value && null != articleUuid.value) {
		const articleCreateForm = {
			campaign_id: null != props.campaignId ? props.campaignId : articleForm.value.campaign_id,
			language_id: null != props.languageId ? props.languageId : articleForm.value.language_id,
			title: articleForm.value.title,
			content: articleForm.value.content,
			publisher_note: articleForm.value.publisherNote,
			footer_id: articleForm.value.footer_id,
		}
		try {
			const result = await articleApi.createArticle(articleCreateForm);
			if (!result.isSuccess) {
				toast.error(`${t('order.orderFlow.toast.errorCreateArticle')}`);
				loading.value = false;
				return;
			}
			if (null != articleForm.value.file) {
				await uploadImage(false, result.payload.id);
			}
			if (props.isDialogForm) {
				emit('dialogSaveArticle', result.payload.id, result.payload);
			} else {
				await router.push('/article');
			}
			toast.success(`${t('order.orderFlow.toast.successCreateArticle')}`);
		} catch (e) {
			toast.error(`${t('order.orderFlow.toast.errorCreateArticle')}`);
			loading.value = false;
			return;
		}
	} else {
		const articleEditForm = {
			campaign_id: articleForm.value.campaign_id,
			language_id: articleForm.value.language_id,
			title: articleForm.value.title,
			content: articleForm.value.content,
			publisher_note: articleForm.value.publisherNote,
			footer_id: articleForm.value.footer_id,
		};
		try {
			const result = await articleApi.editArticle(articleUuid.value, articleEditForm);
			if (!result.isSuccess) {
				toast.error(`${t('order.orderFlow.toast.errorEditArticle')}`);
				loading.value = false;
				return;
			}
			if (null != articleForm.value.file) {
				await uploadImage(false, result.payload.id);
			}
			await router.push('/article');
			toast.success(`${t('order.orderFlow.toast.successEditArticle')}`);
		} catch (e) {
			toast.error(`${t('order.orderFlow.toast.errorEditArticle')}`);
			loading.value = false;
			return;
		}
	}
	loading.value = false;
};

const goBack = () => {
	router.push('/article');
};

const onCampaignChange = () => {
	articleForm.value.footer_id = null;
	campaignFooterList();
};
</script>

<style scoped lang="scss">
.create-article-form {
	&__label-required {
		font-size: 14px;
		font-weight: 700;
		color: $grey-400;
		
		span {
			color: $secondary-400;
		}
	}
	
	&__drop-wrapper {
		border: 2px dashed $grey-300;
		border-radius: 12px;
		margin-bottom: 10px;
		
		&--error {
			border-color: $secondary-400;
		}
		
		&--input-wrapper {
			padding: 15px;
			height: 158px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			
			input[type='file'] {
				position: absolute;
				z-index: 1;
				top: 70%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 158px;
				height: 40px;
				opacity: 0;
				cursor: pointer;
			}
			
			label {
				margin-bottom: 20px;
			}
		}
	}
	
	&__file-btn {
		background-color: $primary-400;
		cursor: pointer;
		border-radius: 8px;
		color: $white-100;
		font-size: 14px;
		font-weight: 600;
		padding-top: 9px;
		text-align: center;
		position: absolute;
		top: 70%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 158px;
		height: 40px;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__remove-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		
		div:nth-child(2) {
			margin-left: 10px;
			margin-top: 3px;
			cursor: pointer;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	&__drop-text {
		font-size: 12px;
		color: $grey-300;
	}
	
	&__photo-wrapper {
		width: max-content;
		max-width: 100%;
		margin-top: 10px;
		position: relative;
		transition: 0.3s;
		
		@include media-breakpoint-up(lg) {
			max-width: 100%;
		}
		
		&:hover {
			.create-article-form__photo-btn-wrapper {
				display: flex;
			}
		}
		
		img {
			width: 100%;
			transition: opacity 0.5s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	&__photo-btn-wrapper {
		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		
		&:deep(.v-text-field>.v-input__control>.v-input__slot) {
			cursor: pointer;
		}
	}
	
	&__edit-photo {
		position: relative;
	}
	
	&__btn {
		background-color: $primary-400;
		font-size: 16px;
		padding: 10px;
		min-width: 80px;
		text-align: center;
		border-radius: 8px;
		color: $white-100;
		font-weight: 700;
		cursor: pointer;
		transition: opacity .3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		&--red {
			margin-left: 10px;
			background-color: $secondary-400;
		}
	}
	
	&__edit-btn-wrapper {
		position: relative;
		cursor: pointer;
	}
	
	&__upload-hover-wrapper {
		position: absolute;
		width: 100%;
		top: 0;
		height: 44px;
		cursor: pointer;
		opacity: 0;
		
		input {
			cursor: pointer;
		}
		
		&:deep(.v-input__prepend) {
			width: 0;
		}
		
		&:deep(.v-input__control) {
			width: 100%;
		}
		
		&:deep(.v-input__details) {
			display: none;
		}
	}
	
	&__image-preview {
		margin-top: 24px;
	}
	
	&__error-message {
		color: $secondary-550;
	}
	
	&__bg-green-300 {
		background-color: $green-300;
		padding: 20px;
		border-radius: 12px;
		
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
	}
	
	&__bg-gray-100 {
		background-color: $grey-200;
		padding: 20px;
		border-radius: 12px;
		
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
	}
	
	&__article-btn-wrapper {
		margin-top: 20px;
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		justify-content: space-between;
		
		&--dialog {
			justify-content: flex-end;
		}
	}
	
	&:deep(.v-input__details) {
		display: none;
	}
	
	&:deep(.m-input) {
		margin-bottom: 10px;
	}
}
</style>
