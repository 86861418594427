<template>
	<div class="agency">
		<HeadingLabel
			class="agency__heading-label"
			icon="group-people"
			width="32"
			height="29"
			:text="$t('agency.agencyAccountList')"
			btn
			red400
			:btn-text="$t('agency.addAgencyAccount')"
			:is-button-click="addAccountRedirect"
		/>
		
		<AgencyAccountListTable />
	</div>
</template>

<script setup lang="ts">
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import AgencyAccountListTable from '@/views/Agency/components/AgencyAccountListTable.vue';

const { user } = storeToRefs(useUserStore());

if (0 === user.value?.is_agency) {
	document.location.href = '/dashboard';
}

const addAccountRedirect = () => {
	router.push('/agency/create');
};
</script>

<style scoped lang="scss">
.agency {
	&__heading-label {
		margin-bottom: 5px;
	}
}
</style>
