<template>
	<div class="coupons-stats">
		<div class="coupons-stats__text">{{ $t('coupons.statsForCoupon') }}</div>
	</div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.coupons-stats {
	background-color: $white;
	padding: 20px 10px;
	
	@include media-breakpoint-up(sm) {
		padding: 30px;
	}
	
	&__text {
		color: $grey-400;
		font-size: 18px;
		text-align: center;
	}
}
</style>
