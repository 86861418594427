import BaseApi from '@/api/BaseApi';
import type {
	BannersDetailsInterface,
	BannersForm,
	BannersListInterface,
	CreateBannersResponseInterface
} from '@/models/BannersModel';

export default class BannersApi extends BaseApi {
	async createBanner (params: BannersForm) {
		const data = new FormData();
		data.append('image', params.image);
		params.slugs.forEach((slug:Array<string>|any) => {
			data.append(`slugs[]`, slug);
		});
		data.append('start', params.start);
		data.append('name', params.name);
		if (null != params.url) {
			data.append('url', params.url);
		}
		if (null != params.finish) {
			data.append('finish', params.finish);
		}
		return this.post<FormData, CreateBannersResponseInterface>({
			url: 'api/v1/banners',
			data,
			config: {
				contentType: 'multipart/form-data',
			},
		});
	};
	async editBanner (params: BannersForm, uuid: string|Array<string>) {
		const data = new FormData();
		if (null != params.image) {
			data.append('image', params.image);
		}
		params.slugs.forEach((slug:Array<string>|any) => {
			data.append(`slugs[]`, slug);
		});
		data.append('start', params.start);
		data.append('name', params.name);
		if (null != params.finish && '' != params.finish) {
			data.append('finish', params.finish);
		} else {
			data.append('finish', '');
		}
		if (null != params.url) {
			data.append('url', params.url);
		}
		return this.post<FormData|any, CreateBannersResponseInterface>({
			url: `api/v1/banners/${uuid}`,
			data: params,
			config: {
				contentType: 'multipart/form-data',
			},
		});
	};
	async getBannerList (page: number, perPage: number, sortBy?: string|null, sortDirection?: string|null, slugs?: Array<string>|null, active?: boolean|null) {
		let url = `api/v1/banners?page=${page}&per_page=${perPage}`;

		if (null != slugs && 1 <= slugs.length) {
			for (let i = 0; i <= slugs.length - 1; i++) {
				url += `&slugs[]=${slugs[i]}`;
			}
		}

		if (active) {
			url += `&active=1`;
		}

		if (null != sortBy) {
			url += `&sort=${sortBy}`;
		}

		if (null != sortDirection) {
			url += `&direction=${sortDirection}`;
		}

		return this.get<BannersListInterface>({
			url: url,
		});
	};
	async getBanner (uuid: string|Array<string>) {
		return this.get<BannersDetailsInterface>({
			url: `api/v1/banners/${uuid}`
		});
	};
	async deleteBanner (uuid: string|null) {
		return this.delete({
			url: `api/v1/banners/${uuid}`
		});
	};
};
