<template>
    <div class="m-input" :class="[{'m-input__disabled': disabled, 'm-input__no-details': hideDetails}]">
        <div v-if="separateLabel" class="m-input__label">
	        {{ separateLabel }}
	        <span class="m-input__required-star">{{ labelRequired ? '*' : labelTextRequired ? ` ${$t('components.fieldRequired')}` : '' }}</span>
	        <slot name="separateLabel"></slot>
        </div>
        <v-text-field
            :id="id"
            v-model="value"
            :label="label"
            :placeholder="placeholder"
            :type="type"
            :min="min"
            :max="max"
            :disabled="disabled"
            class="rounded-0 m-input__input"
            :single-line="!variant"
            :variant="variant"
            :error-messages="errors"
            :required="required"
            :clearable="clearable"
            :hint="hint"
            :persistent-hint="persistentHint"
            @change="change"
            :step="step"
            :persistent-clear="persistentClear"
        >
	        <template #clear v-if="isCustomClear">
		        <slot name="customClear"></slot>
	        </template>
        </v-text-field>
    
    </div>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, toRef, withDefaults } from 'vue';
import { useField } from 'vee-validate';

interface IOwnProps {
    label?: string
	hint?: string
    modelValue?: string|number|null
    name?: string
    placeholder?: string
    type?: string
    separateLabel?: string
    variant?: 'filled'|'outlined'|'plain'|'underlined'|'solo'|'solo-inverted'|'solo-filled'|undefined
    min?: string|number
    max?: string|number
    id?: string
    step?: string
    required?: boolean
    disabled?: boolean
    labelRequired?: boolean
    labelTextRequired?: boolean
    clearable?: boolean
	persistentHint?: boolean
	persistentClear?: boolean
	isCustomClear?: boolean
	hideDetails?: boolean
    change?: Function
}
interface IProps extends IOwnProps {
}

const props = withDefaults(defineProps<IProps>(), {
	variant: 'outlined',
});
const emit = defineEmits(['update:modelValue']);

const { errors } = useField(toRef<IOwnProps, any>(props, 'name'), undefined);

const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});
</script>

<style scoped lang="scss">
.m-input {
	width: 100%;
	
    &__required-star {
        color: $secondary-400;
    }

    &__label {
        color: $grey-400;
        font-weight: 700;
        font-size: 14px;
    }

    &__disabled {
	    &:deep(fieldset) {
		    border: none;
	    }
	    
	    &:deep(.v-input__control) {
		    background-color: $grey-200;
	    }
    }

    &__no-details {
	    &:deep(.v-input__details) {
		    display: none;
	    }
    }
}

:deep(.v-input input) {
	min-height: 43px !important;
	max-height: 43px !important;
	padding: 12px 12px 14px !important;
}

:deep(.v-text-field .v-input__details) {
	padding-inline-start: 0;
}

:deep(.v-text-field) {
	padding-top: 0 !important;
}

:deep(.v-application--is-ltr .v-text-field .v-label) {
	left: 0 !important;
}

:deep(.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot) {
	padding: 0;
}
</style>
