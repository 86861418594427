<template>
    <div class="header__wallet">
        <div class="header__wallet-wrapper">
            <div class="header__wallet-amount">
	            <div class="header__wallet-amount--hexagon">
                    <MHexagonIcon v-if="null != userCurrencySymbol" :letter="userCurrencySymbol" micro :bgc="green400" :color="primary400" />
	            </div>
	            <div class="header__wallet-amount header__wallet-amount--hover header__wallet-amount--money" @click="goToHistoryTransaction">
	                <MIcon icon="money" height="16" width="16" />
		            <v-progress-circular v-if="isRefreshWalletAction" :color="white" :size="15" :width="2" indeterminate />
	                <div v-if="null != userWalletValue && !isRefreshWalletAction" id="wallet" >{{ userWalletValue.toFixed(2) }}</div>
	                <MIcon icon="info" height="16" width="16" />
	            </div>
	            <div class="header__wallet-afilliant-wrapper header__wallet-amount--hover header__wallet-amount--money" v-if="null != user && checkIsUserJoinAffiliateProgram(user)" @click="goToAffiliateProgram">
	                <MIcon icon="hands" height="30" width="30" />
		            <v-progress-circular v-if="isRefreshWalletAction" :color="white" :size="15" :width="2" indeterminate />
		            <div v-if="null != userAffiliateWalletValue && !isRefreshWalletAction" id="affiliate-wallet" >{{ userAffiliateWalletValue.toFixed(2) }}</div>
	            </div>
                <div class="header__wallet-amount--tooltip-wrapper">
                    <div class="header__wallet-amount--tooltip-wrapper__item header__wallet-amount--tooltip-wrapper__item--title">{{ $t('layout.header.wallet') }}</div>
                    <div class="header__wallet-amount--tooltip-wrapper__item">{{ $t('layout.header.net') }}: {{ currentRoleNetBalance() }} {{ userCurrencySymbol }}</div>
                    <div class="header__wallet-amount--tooltip-wrapper__item">{{ $t('layout.header.gross') }}: {{ currentRoleGrossBalance() }} {{ userCurrencySymbol }}</div>
	                <div class="header__wallet-amount--tooltip-wrapper__item header__wallet-amount--tooltip-wrapper__item--title" v-if="null != user && checkIsUserJoinAffiliateProgram(user)">{{ $t('layout.header.affiliateProgram') }}</div>
	                <div class="header__wallet-amount--tooltip-wrapper__item" v-if="null != user && checkIsUserJoinAffiliateProgram(user)">{{ $t('layout.header.net') }}: {{ currentAffiliateRoleNetBalance() }} {{ userCurrencySymbol }}</div>
	                <div class="header__wallet-amount--tooltip-wrapper__item" v-if="null != user && checkIsUserJoinAffiliateProgram(user)">{{ $t('layout.header.gross') }}: {{ currentAffiliateRoleGrossBalance() }} {{ userCurrencySymbol }}</div>
                </div>
            </div>
            <div v-if="null != user && isAdvertiser(user)" @click="goToHistoryTransaction" class="header__wallet__add-money-wrapper">
	            <MIcon icon="add-dollar" width="30" height="30" :color="green400" />
            </div>
            <div v-if="null != user && isAdvertiser(user)" @click="goToActiveBasketList" class="header__wallet__basket-wrapper">
	            <MIcon icon="cart" width="30" height="30" :color="primary400" />
	            <div v-if="isActiveCart" class="header__wallet__basket-wrapper--active"></div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import router from '@/router';
import { useUserStore } from '@/stores/user';
import { useBooleanFlagStore } from '@/stores/booleanFlag';
import { isProfileLocked, checkAddressProfile } from '@/hooks/ProfileHooks';
import { isAdvertiser, checkIsUserJoinAffiliateProgram, AFFILIATE_ACCOUNT_ROLE_ID } from '@/hooks/UserHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import OrderApi from '@/api/v1/OrderApi';
import MHexagonIcon from '@/components/atoms/MHexagonIcon.vue';
import MIcon from '@/components/atoms/MIcon.vue';

const orderApi = new OrderApi();

const { green400, primary400, white } = variables;
const { userCurrencySymbol } = storeToRefs(useUserStore());
const { user, userWalletValue, userAffiliateWalletValue, isActiveCart } = storeToRefs(useUserStore());
const { setUserWalletValue, setUserAffiliateWalletValue, setIsActiveCart } = useUserStore();
const { isRefreshWalletAction } = storeToRefs(useBooleanFlagStore());

const currentWalletAccountFilter = () => {
	if (null != user.value) {
		const currentAccountCurrency = user.value.accounts.find((element) => {
			return element.currency_id === user.value?.currency_id && element.role_id === user.value.active_role_id;
		});
		
		if (null != currentAccountCurrency) {
			setUserWalletValue(+(currentAccountCurrency.balance / 100).toFixed(2));
		} else {
			setUserWalletValue(0);
		}
		
		const currentAffiliateWallet = user.value.accounts.find(elem => elem.role_id === AFFILIATE_ACCOUNT_ROLE_ID);
		if (null != currentAffiliateWallet) {
			setUserAffiliateWalletValue(+(currentAffiliateWallet.balance / 100).toFixed(2));
		} else {
			setUserAffiliateWalletValue(0);
		}
	}
};
currentWalletAccountFilter();

watch((user) , (oldVal, newVal) => {
	if (oldVal != newVal) {
		currentWalletAccountFilter();
	}
},{
	deep: true,
});

const activeCartsList = async() => {
	try {
		const result = await orderApi.orderBasketList(0, null, 1, 1);
		if (!result.isSuccess) {
			return;
		}
		
		if (result.payload.data.length > 0) {
			setIsActiveCart(true);
		}
	} catch (e) {
		return;
	}
};
if (null != user.value && isProfileLocked(user.value) && !checkAddressProfile(user.value)) {
	activeCartsList();
}

const currentRoleNetBalance = () => {
	if (null != user.value) {
		const currentWallet = user.value.accounts.find(elem => elem.role_id === user.value?.active_role_id);
		if (null != currentWallet) {
			return ((+currentWallet.balance / 100) / ((user.value.vat_rate / 100) + 1)).toFixed(2);
		} else {
			return (0).toFixed(2);
		}
	}
};
const currentRoleGrossBalance = () => {
	if (null != user.value) {
		const currentWallet = user.value.accounts.find(elem => elem.role_id === user.value?.active_role_id);
		if (null != currentWallet) {
			return (+currentWallet.balance / 100).toFixed(2);
		} else {
			return (0).toFixed(2);
		}
	}
};

const currentAffiliateRoleNetBalance = () => {
	if (null != user.value) {
		const currentAffiliateWallet = user.value.accounts.find(elem => elem.role_id === AFFILIATE_ACCOUNT_ROLE_ID);
		if (null != currentAffiliateWallet) {
			return ((+currentAffiliateWallet.balance / 100) / ((user.value.vat_rate / 100) + 1)).toFixed(2);
		} else {
			return (0).toFixed(2);
		}
	}
};
const currentAffiliateRoleGrossBalance = () => {
	if (null != user.value) {
		const currentAffiliateWallet = user.value.accounts.find(elem => elem.role_id === AFFILIATE_ACCOUNT_ROLE_ID);
		if (null != currentAffiliateWallet) {
			return (+currentAffiliateWallet.balance / 100).toFixed(2);
		} else {
			return (0).toFixed(2);
		}
	}
};

const goToHistoryTransaction = () => {
	router.push('/order/deposit');
};

const goToAffiliateProgram = () => {
	router.push('/affiliate-program');
};

const goToActiveBasketList = () => {
	router.push(`/order/baskets/${user.value?.id}`);
};
</script>

<style scoped lang="scss">
.header__wallet {
    position: relative;
    font-size: 14px;
    z-index: 10;
    
    &-wrapper {
        display: flex;
	    flex-wrap: wrap;
	    gap: 10px;
        position: relative;
	    height: 100%;
	    
	    @include media-breakpoint-up(sm) {
		    gap: 0;
	    }
    }
    
    &-pound {
        content: "";
        position: absolute;
        top: 11px;
        left: 10px;
        width: 15px;
        height: 16px;
        
        @include media-breakpoint-up(lg) {
            top: 4px;
            left: auto;
        }
    }
    
    &-amount {
        position: relative;
        text-transform: uppercase;
        font-weight: $weight-700;
        color: $green-400;
        display: flex;
        align-items: center;
        gap: 5px;
	    background-color: $primary-400;
	    border-radius: 8px;
	    padding: 5px 10px;
        
        &:hover {
            .header__wallet-amount--tooltip-wrapper {
                display: flex;
            }
        }
	    
	    svg {
		    fill: $white-100;
		    color: $secondary-400;
	    }
	    
	    &--money {
		    cursor: pointer;
		    padding: 0 1px 0 5px;
	    }
	    
	    &--hover {
		    transition: opacity 0.3s;
		    
		    &:hover {
			    opacity: 0.5;
		    }
	    }
	    
	    &--hexagon {
		    text-transform: lowercase;
	    }
        
        &--tooltip-wrapper {
            cursor: auto;
            display: none;
            color: $white;
            font-weight: 700;
            position: absolute;
            top: 40px;
            right: 0;
            background-color: $primary-400;
            border-radius: 6px;
            box-shadow: 0 0 12px $black;
            flex-direction: column;
            gap: 5px;
            min-width: 150px;
            
            &__item {
                text-transform: none;
                width: max-content;
                border-top-left-radius: 9px;
                border-top-right-radius: 6px;
                padding: 0 10px 10px;
                
                &--title {
                    width: 100%;
                    background-color: $secondary-400;
                    text-align: center;
                    margin-bottom: 5px;
                    text-transform: uppercase;
                    padding: 10px;
                }
            }
        }
    }
    
    &-btn {
        color: $grey-400 !important;
        background-color: transparent !important;
        padding: 0 7px !important;
        height: 16px !important;
        cursor: pointer;
        transition: color 0.5s;
        
        &:hover {
            color: $green-400 !important;
        }
        
        span {
            display: none;
            
            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
        
        &--link {
            display: flex;
            text-decoration: none;
        }
    }
	
	&__add-money-wrapper {
		display: flex;
		background-color: $primary-400;
		border-radius: 8px;
		height: 100%;
		padding: 4px 5px 6px 9px;
		margin-left: 10px;
		cursor: pointer;
		transition: background-color 0.3s;
		
		&:hover {
			background-color: $secondary-400;
			
			svg {
				fill: $white-100;
			}
		}
	}
	
	&__basket-wrapper {
		position: relative;
		display: flex;
		background-color: $grey-250;
		border-radius: 8px;
		height: 100%;
		padding: 5px 6px 5px 7px;
		margin-left: 10px;
		cursor: pointer;
		transition: background-color 0.3s;
		
		&:hover {
			background-color: $primary-400;
			
			svg {
				fill: $white-100;
			}
		}
		
		&--active {
			width: 15px;
			height: 15px;
			background-color: $secondary-400;
			border-radius: 50%;
			position: absolute;
			top: -5px;
			right: -5px;
		}
	}
	
	&-afilliant-wrapper {
		display: flex;
		align-items: center;
		gap: 5px;
		margin-left: 10px;
		
		svg {
			color: $white-100;
		}
	}
    
    .v-btn:not(.v-btn--round).v-size--default {
        @include media-breakpoint-down(lg) {
            min-width: 20px !important;
            height: 13px !important;
        }
    }
}
</style>
