import BaseApi from '@/api/BaseApi';
import type { IExpandPriceList, IWebsitesPriceList } from '@/models/PriceListModel';

export default class PriceListApi extends BaseApi {
	async websitesOffersList (page: number, perPage: number, search?: string|null, sortBy?: string|null, sortDesc?: boolean|null) {
		let url = `api/v1/website-offers/prices-list?page=${page}&per_page=${perPage}`;

		if (null != search) {
			url += `&search=${search}`;
		}

		if (null != sortBy) {
			url += `&sort=${sortBy}`;
		}

		if (null != sortDesc && sortDesc) {
			url += '&order=asc';
		}

		if (null != sortDesc && !sortDesc) {
			url += '&order=desc';
		}
		return this.get<IWebsitesPriceList>({
			url: url,
		});
	};

	async websitesOffersDetailsList (page: number, perPage: number, websiteUuid: string|null, search?: string|null, sortBy?: string|null, sortDesc?: boolean|null) {
		let url = `api/v1/website-offers/prices-list/${websiteUuid}?page=${page}&per_page=${perPage}`;

		if (search) {
			url += `&search=${search}`;
		}

		if (null != sortBy) {
			url += `&sort=${sortBy}`;
		}

		if (null != sortDesc && sortDesc) {
			url += '&order=asc';
		}

		if (null != sortDesc && !sortDesc) {
			url += '&order=desc';
		}
		return this.get<IExpandPriceList>({
			url: url,
		});
	};

	async exportPriceListToCSV (search?: string|null, order?: string|null, direction?: string|null) {
		let url = `/api/v1/website-offers/prices-list/export?search=${null != search ? search : ''}`;

		if (order) {
			url += `&order=${order}`;
		}

		if (direction) {
			url += `&direction=${direction}`;
		}
		return this.get<IWebsitesPriceList>({
			url: url,
		});
	};
};
