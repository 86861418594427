<template>
	<div class="info-box">
		<div>{{ text }}</div>
		<MIcon v-if="!isHideCloseIcon" icon="close" class="info-box__icon" @click="closeBox" />
	</div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import MIcon from '@/components/atoms/MIcon.vue';

interface IOwnProps {
	text: string
	isHideCloseIcon?: boolean
}
interface IProps extends IOwnProps {
}

defineProps<IProps>();
const emit = defineEmits(['closeAction']);

const closeBox = () => {
	emit('closeAction');
};
</script>

<style scoped lang="scss">
.info-box {
	position: relative;
	background-color: $yellow-500;
	padding: 15px 25px;
	font-size: 14px;
	font-weight: 700;
	color: $primary-400;
	
	&__icon {
		width: max-content;
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
}
</style>
