<template>
	<div class="header-language">
		<MMenu offset="5px 0px" no-shadow open-on-click>
			<template v-slot:menuActivator>
				<div class="header-language__profile-lang">
					<MFlag :country-code="countryCode" />
					<MIcon icon="arrow-down" :color="black" width="16" height="16" />
				</div>
			</template>
			<template v-slot:menuItems>
				<div v-for="country in countryCodes" :key="country">
					<div :id="`language-${country}`" class="header-language__profile-lang-items">
						<MButton transparent @click="switchLanguage(country)">
							<MFlag :country-code="country" />
						</MButton>
					</div>
				</div>
			</template>
		</MMenu>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@/stores/session';
import { mapLocaleToFlag, switchLanguage } from '@/hooks/LocaleHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import MFlag from '@/components/atoms/MFlag.vue';
import MMenu from '@/components/atoms/MMenu.vue';
import MButton from '@/components/atoms/MButton.vue';
import MIcon from '@/components/atoms/MIcon.vue';

const { locale } = storeToRefs(useSessionStore());
const countryCode = mapLocaleToFlag(locale.value || 'en');
const { black } = variables;
const countryCodes = ref<Array<string>>([]);
const translationFiles:any = import.meta.glob('@/i18n/*.json', { eager: true });

Object.keys(translationFiles).forEach((path:any) => {
	const langCode = path.split('/').pop().replace('.json', '');
	countryCodes.value.push(mapLocaleToFlag(langCode));
});
</script>

<style scoped lang="scss">
.header-language {
	margin-left: 10px;
	
	&__profile-lang {
		display: flex;
		align-items: center;
		cursor: pointer;
		background-color: $grey-250;
		border-radius: 8px;
		padding: 1px 10px 7px;
		gap: 10px;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		svg {
			margin-top: 10px;
		}
	}
	
	&__profile-lang-items {
		background-color: $grey-250;
		padding-bottom: 0;
		border-radius: 8px;
		
		button {
			padding: 0 13px !important;
			transition: background-color 0.3s;
			
			&:hover {
				border-radius: 8px !important;
				background-color: $primary-400 !important;
				opacity: 1 !important;
			}
			
			
			.flag {
				margin: auto !important;
			}
		}
	}
}
</style>
