<template>
    <div :id="id" class="m-simple-expansion-panel">
        <v-expansion-panels>
            <v-expansion-panel
                :title="title"
                :text="text"
                :color="defaultTitleBgColor"
                :bg-color="defaultContentBgColor"
                :hide-actions="hideActions"
            >
                <template v-slot:title>
                    <slot name="title"></slot>
                </template>
                <template v-slot:default>
                    <slot name="default"></slot>
                </template>
                <template v-slot:text>
                    <slot name="text"></slot>
                </template>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script setup lang="ts">
import { defineProps, withDefaults } from 'vue';
import variables from '@/assets/scss/modules/variables.module.scss';

interface IOwnProps {
    title?: string
    text?: string
    id?: string
    titleBgColor?: string|null
    contentBgColor?: string|null
    hideActions?: boolean
}
interface IProps extends IOwnProps {
}

const { primary400 } = variables;

const props = withDefaults(defineProps<IProps>(), {
    hideActions: false
});

const defaultTitleBgColor = props.titleBgColor || primary400;
const defaultContentBgColor = props.contentBgColor || primary400;
</script>

<style scoped lang="scss">
.m-simple-expansion-panel {
	&:deep(.v-expansion-panel-title--active:hover > .v-expansion-panel-title__overlay) {
		opacity: 0.045 !important;
	}
	
	&:deep(.v-expansion-panel-title--active > .v-expansion-panel-title__overlay) {
		opacity: 0.025 !important;
	}
	
	&:deep(.v-expansion-panel-title__icon) {
		color: $primary-400;
	}
	
	&:deep(.v-expansion-panel-title__overlay) {
		background-color: $white-100;
	}
	
	&:deep(.v-expansion-panel-title) {
		height: 47px;
		min-height: 47px;
	}
}
</style>
