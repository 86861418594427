import BaseApi from '@/api/BaseApi';
import type {
	DashboardPublisherInterface,
	IActionItems,
	IActionOrdersItems,
	IModStatsList
} from '@/models/DashboardPublisherModel';

export default class DashboardApi extends BaseApi {
	async publisherDashboard (userId: number|null, to: string|null, from: string|null, monthlyGrouped?: boolean) {
		let url = `api/v1/dashboards/${userId}?date_from=${from}&date_to=${to}`;

		if (monthlyGrouped) {
			url += `&range=monthly`;
		}
		return this.get<DashboardPublisherInterface>({
			url: url,
		});
	};

	async getStatistics (userId: number|null, to: string|null, from: string|null, monthlyGrouped?: boolean) {
		let url = `api/v1/dashboards/csv/${userId}?date_from=${from}&date_to=${to}`;

		if (monthlyGrouped) {
			url += `&range=monthly`;
		}
		return this.get<string>({
			url: url,
		});
	};

	async actionsItemsList (page:number|null, perPage:number|null, userId: number|null) {
		return this.get<IActionItems>({
			url: `api/v1/dashboards/${userId}/action-items?page=${page}&per_page=${perPage}`,
		});
	};

	async actionsOrdersItemsList (page:number|null, perPage:number|null) {
		return this.get<IActionOrdersItems>({
			url: `api/v1/dashboards/orders-preview?page=${page}&per_page=${perPage}`,
		});
	};

	async moderatorCountersList () {
		return this.get<IModStatsList>({
			url: 'api/v1/dashboards/moderator/stats',
		});
	};
};
