<template>
	<div class="campaigns">
		<HeadingLabel
			class="campaigns__heading-label"
			icon="articles"
			width="32"
			height="29"
			:text="$t('campaigns.campaigns')"
			btn
			blue400
			:btn-text="$t('campaigns.createNewCampaign')"
			:is-button-click="addCampaignRedirect"
			btn-id="campaign-btn"
			modal
			modal-icon="step-plus"
			modal-icon-width="70"
			modal-icon-height="71"
			:modal-icon-color="white"
			:modal-title="$t('campaigns.campaigns')"
			:modal-text="$t('campaigns.campaignsModalHelp')"
		/>
		
		<CampaignsTable />
	</div>
</template>

<script setup lang="ts">
import router from '@/router';
import variables from '@/assets/scss/modules/variables.module.scss';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import CampaignsTable from '@/components/organisms/Campaigns/CampaignsTable.vue';

const { white } = variables;

const addCampaignRedirect = () => {
	router.push('/campaigns/create');
};
</script>

<style scoped lang="scss">
.campaigns {
	&__heading-label {
		margin-bottom: 5px;
	}
}
</style>
