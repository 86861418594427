<template>
	<div class="publication-statistics-best-offers-table">
		<v-data-table
			:items="null != items ? items : []"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:items-per-page-options="itemsPerPageOptions"
			:items-per-page="3"
			hover
		>
			<template #loading>
				<div>{{ $t('components.loading') }}</div>
			</template>
			
			<template #item.name="elem">
				<div v-if="elem.item.raw.name != null" class="m9-data-table-align-left">{{ elem.item.raw.name }}</div>
			</template>
			
			<template #item.website_name="elem">
				<div v-if="elem.item.raw.url != null" class="m9-data-table-align-left">{{ elem.item.raw.url }}</div>
			</template>
			
			<template #item.price="elem">
				<div v-if="elem.item.raw.price != null && elem.item.raw.currency_id != null" class="publication-statistics-best-offers-table__green m9-data-table-align-price">{{ elem.item.raw.price.toFixed(2) }} {{ changeCurrencyIdToName(elem.item.raw.currency_id) }}</div>
			</template>
			
			<template #item.publication_sold="elem">
				<div v-if="elem.item.raw.publication_sold != null">{{ elem.item.raw.publication_sold }}</div>
			</template>

			<template #bottom>
				<div></div>
			</template>
		</v-data-table>
	</div>
</template>

<script setup lang="ts">
import { VDataTable } from 'vuetify/labs/VDataTable';
import { ref, defineProps, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDictionaryStore } from '@/stores/dictionary';
import type { TableHeaderInterface } from '@/models/VuetifyModel';
import type { IDashboardBestOffers } from '@/models/DashboardPublisherModel';

interface IOwnProps {
	items: Array<IDashboardBestOffers>|null
	loading: boolean
}

interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();

const { currencies } = useDictionaryStore();
const { t } = useI18n();

const itemsPerPageOptions = ref<Array<any>>([
	{
		value: 3,
		title: '3'
	},
]);
const items = ref<Array<IDashboardBestOffers>|null>([]);
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('dashboard.offerName')}`,
		align: 'center',
		sortable: false,
		key: 'name'
	},
	{
		title: `${t('dashboard.domain')}`,
		align: 'center',
		sortable: false,
		key: 'website_name'
	},
	{
		title: `${t('dashboard.offerPrice')}`,
		align: 'center',
		sortable: false,
		key: 'price'
	},
	{
		title: `${t('dashboard.publicationSold')}`,
		align: 'center',
		sortable: false,
		key: 'publication_sold'
	},
]);

watch((props), (val) => {
	if (val) {
		items.value = val.items;
	}
}, {
	deep: true,
});

const changeCurrencyIdToName = (currencyId:number) => {
	if (null != currencyId) {
		const currencyName = currencies.find(elem => elem.id === currencyId);
		return null != currencyName ? currencyName.currency_symbol : '';
	}
	return '-';
};
</script>

<style scoped lang="scss">
.publication-statistics-best-offers-table {
	@import '@/assets/scss/vuetifyDataTableDashboard';
	margin-top: 20px;
	
	&_green {
		color: $green-400;
	}
	
	&:deep(.v-data-table) {
		@include media-breakpoint-up(xxl) {
			height: 342px + 95px + 9px;
		}
	}
}
</style>
