<template>
	<div class="payment-cancel">
		<HeadingLabel
			icon="credit-card"
			width="32"
			height="29"
			:text="$t('order.list.payment')"
		/>
		
		<div class="payment-cancel__wrapper">
			<h1 class="payment-cancel__title" :class="{'payment-cancel__title--completed': isCompleted}">{{ !isCompleted ? $t('order.list.yourPayment') : $t('order.list.paymentCompleted') }}</h1>
			<p class="payment-cancel__description">{{ !isCompleted ? $t('order.list.thanks') : $t('order.list.paymentCompletedInfo') }}</p>
			<div class="payment-cancel__layout-wrapper">
				<img src="~@/assets/images/payment-success.png" alt="payment cancel" class="payment-cancel__layout">
			</div>
			<div class="payment-cancel__btn-wrapper">
				<div @click="goToProfile" class="payment-cancel__link-wrapper">
					<MIcon icon="user-cube" :color="white" width="25" height="25" />
					<div class="payment-cancel__link-text">{{ $t('order.list.yourAcc') }}</div>
				</div>
				<div @click="goToHistoryPayment" class="payment-cancel__link-wrapper">
					<MIcon icon="pound-cube" :color="white" width="25" height="25" />
					<div class="payment-cancel__link-text">{{ $t('order.list.lastPayments') }}</div>
				</div>
				<a href="/dashboard" class="payment-cancel__link-wrapper">
					<MIcon icon="plus-cube" :color="white" width="25" height="25" />
					<div class="payment-cancel__link-text">{{ $t('order.list.dashboard') }}</div>
				</a>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import router from '@/router';
import { ROUTE_PAYMENTS_SUMMARY_COMPLETED } from '@/hooks/RoutingHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MIcon from '@/components/atoms/MIcon.vue';

const { white } = variables;

const isCompleted = ref<boolean>(false);

if (router.currentRoute.value.name === ROUTE_PAYMENTS_SUMMARY_COMPLETED) {
	isCompleted.value = true;
}
const goToProfile = () => {
	router.push('/profile');
};
const goToHistoryPayment = () => {
	router.push('/order/deposit');
};
</script>

<style scoped lang="scss">
.payment-cancel {
	&__wrapper {
		background-color: $white-100;
		margin-top: 3px;
		padding: 10px;
		
		@include media-breakpoint-up(sm) {
			padding: 20px;
		}
		
		@include media-breakpoint-up(md) {
			padding: 40px 20px;
		}
	}
	
	&__title {
		font-weight: 700;
		color: $secondary-400;
		font-size: 29px;
		line-height: 1.2;
		
		&--completed {
			color: $green-400;
		}
	}
	
	&__description {
		font-size: 13px;
		margin-top: 10px;
	}
	
	&__layout-wrapper {
		width: 100%;
		margin-top: 30px;
		max-width: 643px;
		
		@include media-breakpoint-up(md) {
			margin-top: 60px;
		}
		
		@include media-breakpoint-up(xl) {
			margin-top: 100px;
		}
	}
	
	&__layout {
		width: 100%;
	}
	
	&__btn-wrapper {
		display: flex;
		flex-direction: column;
		
		@include media-breakpoint-up(md) {
			flex-direction: row;
			width: 100%;
		}
		
		@include media-breakpoint-up(xl) {
			width: 80%;
		}
	}
	
	&__link-wrapper {
		display: flex;
		flex-direction: column;
		background-color: $primary-400;
		text-decoration: none;
		cursor: pointer;
		padding: 10px;
		transition: opacity 0.3s;
		
		@include media-breakpoint-up(sm) {
			padding: 15px;
		}
		
		@include media-breakpoint-up(md) {
			padding: 20px;
			width: 33%;
		}
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__link-text {
		color: $white;
		font-weight: 700;
		margin-top: 8px;
		
		@include media-breakpoint-up(md) {
			width: 100px;
		}
	}
}
</style>
