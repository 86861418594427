<template>
	<div class="basket-summary-list">
		<div class="basket-summary-list__header-wrapper">
			<div class="basket-summary-list__header-item">{{ $t('basket.summary.description') }}</div>
			<div class="basket-summary-list__header-item">{{ $t('basket.summary.details') }}</div>
			<div class="basket-summary-list__header-item">{{ $t('basket.summary.unitPrice') }}</div>
		</div>
		<div class="basket-summary-list__items-wrapper" v-for="item in order.order_items" :key="item.id">
			<div class="basket-summary-list__items">{{ transformName(item.orderable_type) }}</div>
			<div class="basket-summary-list__items" v-if="item.orderable_type === PUBLICATION_REQUEST">{{ item.orderable.url || '-' }}</div>
			<div class="basket-summary-list__items" v-if="item.orderable_type === ARTICLE_REQUEST">{{ item.orderable.subject || '-' }}</div>
			<div class="basket-summary-list__items" v-if="item.orderable_type === DEPOSIT_REQUEST">{{ '-' }}</div>
			<div class="basket-summary-list__items">
				<div class="basket-summary-list__items--pound">{{ (item.net/100).toFixed(2) }} {{ userCurrencySymbol }}</div>
			</div>
		</div>
		<div class="basket-summary-list__summary-wrapper">
			<div class="basket-summary-list__price-wrapper" v-if="(order.coupon_id !== null || order.reflink_value !== null) && order.coupon.value != 0">
				<div class="basket-summary-list__summary-title">{{ $t('basket.list.beforeDiscount') }}</div>
				<div class="basket-summary-list__price basket-summary-list__price--first">{{ netBeforeDiscount().toFixed(2) }} {{ userCurrencySymbol }}</div>
			</div>
			<div class="basket-summary-list__price-wrapper" v-if="order.coupon_id !== null && order.coupon.value != 0 && order.coupon.type === MARGIN_TYPE_CASH">
				<div class="basket-summary-list__summary-title">{{ $t('basket.summary.subtotalCoupon') }}</div>
				<div class="basket-summary-list__price basket-summary-list__price--third">- {{ discount().toFixed(2) }} {{ userCurrencySymbol }}</div>
			</div>
			<div class="basket-summary-list__price-wrapper" v-if="order.coupon_id !== null && order.coupon.value != 0 && order.coupon.type === MARGIN_TYPE_PERCENT">
				<div class="basket-summary-list__summary-title">{{ $t('basket.summary.subtotalCoupon') }}</div>
				<div class="basket-summary-list__price basket-summary-list__price--third">- {{ order.coupon.value }}% ({{ discount().toFixed(2) }} {{ userCurrencySymbol }})</div>
			</div>
			<div class="basket-summary-list__price-wrapper" v-if="order.reflink_value !== null">
				<div class="basket-summary-list__summary-title">{{ $t('basket.list.partnerProgram') }}</div>
				<div class="basket-summary-list__price basket-summary-list__price--third">- ({{ (order.discount_value / 100).toFixed(2) }} {{ userCurrencySymbol }})</div>
			</div>
			<div class="basket-summary-list__price-wrapper">
				<div class="basket-summary-list__summary-title">{{ $t('basket.summary.subtotal') }}</div>
				<div class="basket-summary-list__price basket-summary-list__price--first" id="basket-summary-net">{{ net().toFixed(2) }} {{ userCurrencySymbol }}</div>
			</div>
			<div class="basket-summary-list__price-wrapper">
				<div v-if="null != user?.vat_rate" class="basket-summary-list__summary-title">{{ $t('basket.summary.vat') }} ({{ user?.vat_rate }}%)</div>
				<div class="basket-summary-list__price basket-summary-list__price--fifth" id="basket-summary-tax">{{ tax().toFixed(2) }} {{ userCurrencySymbol }}</div>
			</div>
			<div class="basket-summary-list__price-wrapper">
				<div class="basket-summary-list__summary-title">{{ $t('basket.summary.total') }}</div>
				<div class="basket-summary-list__price basket-summary-list__price--fifth" id="basket-summary-gross">{{ gross().toFixed(2) }} {{ userCurrencySymbol }}</div>
			</div>
			<div class="basket-summary-list__price-wrapper" v-if="null != userWalletValue && userWalletValue > 0 && order.order_items[0].orderable_type !== DEPOSIT_REQUEST">
				<div class="basket-summary-list__summary-title">{{ $t('basket.summary.subtotalWallet') }}</div>
				<div class="basket-summary-list__price basket-summary-list__price--third" id="basket-summary-wallet">- {{ userWalletValue >= gross() ? gross().toFixed(2) : (+userWalletValue).toFixed(2) }} {{ userCurrencySymbol }}</div>
			</div>
			<div class="basket-summary-list__price-wrapper" v-if="order.order_items[0].orderable_type === DEPOSIT_REQUEST">
				<div class="basket-summary-list__summary-title basket-summary-list__summary-title--total">{{ $t('basket.summary.toPay') }}</div>
				<div class="basket-summary-list__price  basket-summary-list__price--sixth">{{ gross() }} {{ userCurrencySymbol }}</div>
			</div>
			<div class="basket-summary-list__price-wrapper" v-if="order.order_items[0].orderable_type !== DEPOSIT_REQUEST && null != userWalletValue && userWalletValue <= gross()">
				<div class="basket-summary-list__summary-title basket-summary-list__summary-title--total">{{ $t('basket.summary.toPay') }}</div>
				<div class="basket-summary-list__price  basket-summary-list__price--sixth">{{ gross() >= userWalletValue ? (gross() - userWalletValue).toFixed(2) : 0.00 }} {{ userCurrencySymbol }}</div>
			</div>
		</div>
		<div class="basket-summary-list__payment-text-wrapper">
			<div class="basket-summary-list__payment-text-title">{{ $t('basket.summary.payment') }}</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { ARTICLE_REQUEST, DEPOSIT_REQUEST, PUBLICATION_REQUEST } from '@/hooks/OrderHooks';
import { MARGIN_TYPE_CASH, MARGIN_TYPE_PERCENT } from '@/hooks/OfferHooks';
import type { IOrderBasketTableItem } from '@/models/OrderModel';

interface IOwnProps {
	order: IOrderBasketTableItem|any
}
interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();
const { t } = useI18n();
const { userCurrencySymbol, user, userWalletValue } = storeToRefs(useUserStore());

const transformName = (slug:string) => {
	switch (slug) {
		case ARTICLE_REQUEST:
			return `${t('basket.list.article')}`;
		case PUBLICATION_REQUEST:
			return `${t('basket.list.publication')}`;
		case DEPOSIT_REQUEST:
			return `${t('basket.list.deposit')}`;
		default:
			return `${t('basket.list.unknown')}`;
	}
};

const discount = () => {
	return +(props.order.discount_value / 100).toFixed(2);
};
const gross = () => {
	return +(props.order.gross / 100).toFixed(2);
};
const tax = () => {
	return +(props.order.tax / 100).toFixed(2);
};
const netBeforeDiscount = () => {
	return +(props.order.net_before_discount / 100).toFixed(2);
};
const net = () => {
	return +(props.order.net / 100).toFixed(2);
};

</script>

<style scoped lang="scss">
.basket-summary-list {
	background-color: $white;
	margin-top: 3px;
	
	&__header-wrapper,
	&__items-wrapper {
		display: flex;
		align-items: center;
		padding: 30px 10px;
		text-align: center;
		border-bottom: 2px solid $grey-200;
	}
	
	&__header-item {
		width: 33%;
		font-size: 14px;
		font-weight: 700;
		
		@include media-breakpoint-up(sm) {
			font-size: 18px;
		}
	}
	
	&__items {
		width: 33%;
		font-size: 14px;
		
		&--pound {
			font-weight: 700;
			color: $green-400;
		}
		
		&--pln {
			font-weight: 700;
			color: $secondary-400;
		}
	}
	
	&__summary-wrapper {
		padding: 10px;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
	}
	
	&__price-wrapper {
		display: flex;
		align-items: baseline;
	}
	
	&__summary-title {
		font-size: 11px;
		font-weight: 700;
		text-align: right;
		width: 200px;
		
		@include media-breakpoint-up(md) {
			font-size: 12px;
		}
		
		@include media-breakpoint-up(lg) {
			font-size: 14px;
			width: max-content;
		}
		
		&--total {
			color: $green-400;
		}
	}
	
	&__price {
		margin-top: 20px;
		font-size: 11px;
		font-weight: 700;
		margin-left: 10px;
		text-align: right;
		width: 110px;
		
		@include media-breakpoint-up(md) {
			font-size: 12px;
		}
		
		@include media-breakpoint-up(lg) {
			font-size: 14px;
		}
		
		&--third {
			color: $secondary-400;
		}
		
		&--sixth {
			color: $green-400;
		}
	}
	
	&__payment-text-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
		border-top: 1px solid $grey-200;
		
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
	}
	
	&__payment-text-title {
		font-size: 25px;
		width: max-content;
		margin-right: auto;
	}
}
</style>
