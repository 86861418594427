<template>
	<div class="order-article-form" :class="{'order-article-form--dialog': isDialogForm}">
		<div class="order-article-form__title">{{ $t('order.article.professionalWriter') }}</div>
		<Form :validation-schema="schema" @submit="onSubmit">
			<MAutocomplete
				v-if="!props.isDialogForm"
				id="article-order-campaign"
				name="articleCampaign"
				v-model="articleOrderForm.campaign_id"
				:get-items="campaigns"
				variant="outlined"
				item-title="name"
				item-value="id"
				:label="$t('order.orderFlow.articleCampaign')"
				:placeholder="$t('order.orderFlow.articleCampaignPlaceholder')"
				@onChange="changeCampaign"
				clearable
			/>
			<MAutocomplete
				v-if="!props.isDialogForm"
				id="article-order-language"
				name="articleLanguage"
				v-model="articleOrderForm.language_id"
				label-text-required
				:get-items="languages"
				variant="outlined"
				item-title="name"
				item-value="id"
				:label="$t('order.orderFlow.articleLanguage')"
				:placeholder="$t('order.orderFlow.articleLanguagePlaceholder')"
				@onChange="changeLanguage"
			/>
			<div v-if="null != articleOrderForm.language_id">
				<MTextField
					id="article-order-title"
					name="articleTitle"
					v-model="articleOrderForm.title"
					label-text-required
					:separate-label="$t('order.orderFlow.articleTitle')"
					:placeholder="$t('order.orderFlow.articleTitlePlaceholder')"
					clearable
				/>
				<MVueEditor
					id="article-order-reports"
					name="articleReports"
					v-model="articleOrderForm.reports"
					:separate-label="$t('order.orderFlow.articleReports')"
					:placeholder="$t('order.orderFlow.articleReportsPlaceholder')"
				>
					<template v-slot:labelInfo>
						<div class="order-article-form__font-11">{{ $t('order.orderFlow.articleReportsPlaceholder0') }}</div>
						<div class="order-article-form__font-11">{{ $t('order.orderFlow.articleReportsPlaceholder1') }}</div>
						<div class="order-article-form__font-11">{{ $t('order.orderFlow.articleReportsPlaceholder2') }}</div>
						<div class="order-article-form__font-11">{{ $t('order.orderFlow.articleReportsPlaceholder3') }}</div>
						<div class="order-article-form__font-11">{{ $t('order.orderFlow.articleReportsPlaceholder4') }}</div>
						<div class="order-article-form__font-11">{{ $t('order.orderFlow.articleReportsPlaceholder5') }}</div>
						<div class="order-article-form__font-11">{{ $t('order.orderFlow.articleReportsPlaceholder6') }}</div>
					</template>
				</MVueEditor>
				<ArticleTypesWindow v-if="!props.isDialogForm" v-model="articleOrderForm.article_type_id" :article-types="articleTypesList" is-order-article-view :is-language-pl="articleOrderForm.language_id === POLISH_LANGUAGE_ID" />
				<MTextField
					v-if="!props.isDialogForm"
					id="article-order-amount"
					name="articleAmount"
					type="number"
					step="1"
					min="0"
					v-model="articleOrderForm.amount"
					label-text-required
					:separate-label="$t('order.orderFlow.articleAmount')"
					clearable
				/>
				<MVueEditor
					id="article-order-links"
					name="articleLinks"
					v-model="articleOrderForm.links"
					label-text-required
					:separate-label="$t('order.orderFlow.articleLinks')"
					:placeholder="$t('order.orderFlow.articleLinksPlaceholder')"
				/>
				<div class="order-article-form__btn-wrapper">
					<MButton
						id="article-order-back"
						:label="$t('order.orderFlow.cancel')"
						transparent-with-border
						normal
						width150
						@click="goBack"
					/>
					<MButton
						v-if="!props.isDialogForm"
						id="article-order-submit"
						blue400
						width150
						normal
						:label="$t('order.orderFlow.orderArticle')"
						:loading="btnLoading"
						:loading-color="white"
					/>
					<MButton
						v-if="props.isDialogForm"
						id="article-order-submit"
						blue400
						width150
						normal
						:label="$t('order.orderFlow.addToCart')"
						:loading="btnLoading"
						:loading-color="white"
						:disabled="btnLoading"
					/>
				</div>
			</div>
			<MLoading v-show="loading" />
		</Form>
	</div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, ref } from 'vue';
import router from '@/router';
import { useDictionaryStore } from '@/stores/dictionary';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { toast } from 'vue3-toastify';
import variables from '@/assets/scss/modules/variables.module.scss';
import ArticleApi from '@/api/v1/ArticleApi';
import OrderApi from '@/api/v1/OrderApi';
import type { IOrderArticleRequest } from '@/models/OrderModel';
import type { IArticleTypes } from '@/models/ArticlesModel';
import { ORDER_DRAFT } from '@/hooks/OrderHooks';
import { POLISH_LANGUAGE_ID } from '@/hooks/ProfileHooks';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MVueEditor from '@/components/atoms/MVueEditor.vue';
import MButton from '@/components/atoms/MButton.vue';
import ArticleTypesWindow from '@/components/molecules/ArticleTypesWindow.vue';
import MLoading from '@/components/atoms/MLoading.vue';

interface IOwnProps {
	isDialogForm?: boolean
	campaignId?: number|null
	languageId?: number
	articleTypeId?: number
}
interface IProps extends IOwnProps {
}

const articleApi = new ArticleApi();
const orderApi = new OrderApi();

const emit = defineEmits(['dialogSaveOrderArticle', 'closeDialogAction']);
const props = defineProps<IProps>();
const { t } = useI18n();
const { user } = storeToRefs(useUserStore());
const { setIsActiveCart } = useUserStore();
const { campaigns, languages } = useDictionaryStore();
const { white } = variables;

const loading = ref<boolean>(false);
const btnLoading = ref<boolean>(false);
const basketId = ref<string|null>(null);
const articleTypesList = ref<Array<IArticleTypes>>([]);
const articleOrderForm = ref<any>({
	campaign_id: props.campaignId,
	language_id: props.languageId,
	title: null,
	reports: null,
	article_type_id: props.articleTypeId,
	amount: 1,
	links: null,
	contact: null,
	number: null,
	email: null,
});

const gtm = {
	pushEvent(event:string, name:string, value:any) {
		if (null != window.dataLayer) {
			window.dataLayer.push({
				event: event,
				[name]: value
			});
		}
	}
};

let schema:any = {};
if (props.isDialogForm) {
	schema = yup.object({
		articleTitle: yup.string().required(`${t('order.orderFlow.validation.articleRequired')}`).label('articleTitle'),
		articleLinks: yup.string().required(`${t('order.orderFlow.validation.articleLinksRequired')}`).label('articleLinks'),
	});
} else {
	schema = yup.object({
		articleCampaign: yup.string().nullable().label('articleCampaign'),
		articleLanguage: yup.string().required(`${t('order.orderFlow.validation.articleLanguage')}`).label('articleLanguage'),
		articleTitle: yup.string().required(`${t('order.orderFlow.validation.articleRequired')}`).label('articleTitle'),
		articleAmount: yup.string().required(`${t('order.orderFlow.validation.articleAmountRequired')}`).min(1, `${t('order.orderFlow.validation.minAmount')}`).label('articleAmount'),
		articleLinks: yup.string().required(`${t('order.orderFlow.validation.articleLinksRequired')}`).label('articleLinks'),
	});
}

const articleTypes = async(languageId:number) => {
	loading.value = true;
	try {
		const result = await articleApi.articleTypes(props.isDialogForm ? languageId : articleOrderForm.value.language_id);
		if (!result.isSuccess) {
			toast.error(`${t('order.publication.toast.errorGetArticleTypes')}`);
			loading.value = false;
			return;
		}
		articleTypesList.value = result.payload;
		articleOrderForm.value.article_type_id = articleTypesList.value[0].id;
	} catch (e) {
		toast.error(`${t('order.publication.toast.errorGetArticleTypes')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const orderDraftItems = async() => {
	loading.value = true;
	try {
		const result = await orderApi.getOrders(articleOrderForm.value.campaign_id, ORDER_DRAFT);
		if (!result.isSuccess) {
			toast.error(`${t('order.publication.toast.somethingWentWrong')}`);
			loading.value = false;
			return;
		}
		if (result.payload.length > 0) {
			basketId.value = result.payload[0].uuid;
		}
	} catch (e) {
		toast.error(`${t('order.publication.toast.somethingWentWrong')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
const changeLanguage = () => {
	if (!props.isDialogForm) {
		articleTypes(articleOrderForm.value.language_id);
	}
};
const changeCampaign = () => {
	if (!props.isDialogForm) {
		orderDraftItems();
	}
};
if (!props.isDialogForm) {
	orderDraftItems();
}

const goBack = () => {
	if (!props.isDialogForm) {
		window.location.href = '/dashboard';
	} else {
		emit('closeDialogAction');
	}
};

const onSubmit = async() => {
	btnLoading.value = true;
	if (null == user.value) {
		btnLoading.value = false;
		return;
	}
	if (props.isDialogForm) {
		emit('dialogSaveOrderArticle', articleOrderForm.value);
		setTimeout(() => {
			btnLoading.value = false;
			articleOrderForm.value = null;
		}, 1500)
	} else if (!props.isDialogForm && null == basketId.value) {
		const form:IOrderArticleRequest = {
			campaign_id: null != articleOrderForm.value.campaign_id ? articleOrderForm.value.campaign_id : null,
			language_id: articleOrderForm.value.language_id,
			subject: articleOrderForm.value.title,
			comment: articleOrderForm.value.reports,
			links: articleOrderForm.value.links,
			article_type_id: articleOrderForm.value.article_type_id,
			count: +articleOrderForm.value.amount,
			image: 'later',
			email: user.value.email,
			phone: user.value.addresses[0].phone,
			contact: 'email',
		};
		try {
			const result = await orderApi.orderArticle(form);
			if (!result.isSuccess) {
				toast.error(`${t('order.orderFlow.toast.errorOrderArticle')}`);
				btnLoading.value = false;
				return;
			}
			// Dodanie zdarzenia do Google Tag Menagera
			gtm.pushEvent('Add article to cart', 'add_article_to_cart', articleOrderForm.value.amount);
			gtm.pushEvent('Article count', 'article_count', articleOrderForm.value.amount);
			const articleTypeName = articleTypesList.value.find((elem:any) => elem.id === articleOrderForm.value.article_type_id);
			if (null != articleTypeName) {
				gtm.pushEvent('Article type', 'article_type', articleTypeName.type);
			}
			await setIsActiveCart(true);
			await router.push(`/basket/${result.payload.order}`);
		} catch (e) {
			toast.error(`${t('order.orderFlow.toast.errorOrderArticle')}`);
			btnLoading.value = false;
			return;
		}
		btnLoading.value = false;
	} else {
		const form:IOrderArticleRequest = {
			campaign_id: null != articleOrderForm.value.campaign_id ? articleOrderForm.value.campaign_id : null,
			language_id: articleOrderForm.value.language_id,
			subject: articleOrderForm.value.title,
			comment: articleOrderForm.value.reports,
			links: articleOrderForm.value.links,
			article_type_id: articleOrderForm.value.article_type_id,
			count: +articleOrderForm.value.amount,
			image: 'later',
			email: user.value?.email,
			phone: user.value?.addresses[0].phone,
			contact: 'email',
		};
		try {
			const result = await orderApi.updateOrderArticle(basketId.value, form);
			if (!result.isSuccess) {
				toast.error(`${t('order.orderFlow.toast.errorOrderArticle')}`);
				btnLoading.value = false;
				return;
			}
			// Dodanie zdarzenia do Google Tag Menagera
			gtm.pushEvent('Add article to cart', 'add_article_to_cart', articleOrderForm.value.amount);
			gtm.pushEvent('Article count', 'article_count', articleOrderForm.value.amount);
			const articleTypeName = articleTypesList.value.find((elem:any) => elem.id === articleOrderForm.value.article_type_id);
			if (null != articleTypeName) {
				gtm.pushEvent('Article type', 'article_type', articleTypeName.type);
			}
			await setIsActiveCart(true);
			await router.push(`/basket/${result.payload.order}`);
		} catch (e) {
			toast.error(`${t('order.orderFlow.toast.errorOrderArticle')}`);
			btnLoading.value = false;
			return;
		}
		btnLoading.value = false;
	}
};

</script>

<style scoped lang="scss">
.order-article-form {
	border: 1px solid $grey-300;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
	background-color: $white-100;
	
	@include media-breakpoint-up(md) {
		padding: 64px 50px;
	}
	
	&--dialog {
		box-shadow: none;
		padding: 0;
		border: none;
	}
	
	&__title {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 40px;
		text-align: center;
		color: $primary-400;
	}
	
	&__btn-wrapper {
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		justify-content: space-between;
	}
	
	&__update-circular-wrapper {
		display: flex;
		align-items: center;
		gap: 5px;
	}
	
	&__font-11 {
		font-size: 11px;
	}
	
	&:deep(.v-progress-circular) {
		background-color: transparent;
	}
}
</style>
