import { createI18n, type I18nOptions } from 'vue-i18n';
import i18nMessages from '@/utils/i18n';

export type MessageSchema = typeof i18nMessages.pl;

const i18n = createI18n<[MessageSchema]>({
    locale: 'pl',
    allowComposition: true,
    legacy: false,
    messages: i18nMessages,
});

export default i18n;
