<template>
	<div class="campaigns-footer-table">
		<HeadingLabel
			icon="articles"
			width="32"
			height="29"
			:text="$t('campaigns.campaignsFooters')"
			btn
			blue400
			:btn-text="$t('campaigns.createNewCampaignFooter')"
			:is-button-click="openAddCampaignFooterModal"
			btn-id="open-campaign-footer-modal-btn"
		/>
		
		<MDialog
			:is-close-dialog-action="isCloseDialog"
			:is-open-dialog-action="isOpenDialog"
			:content-title="!isEditMode ? $t('campaigns.createFooter') : $t('campaigns.editFooter')"
			@close-dialog="closeDialogWhenNoTargetPressed"
			:width="1000"
			close-header-btn
		>
			<template v-slot:default>
				<div>
					<CampaignsFooterForm :is-edit-mode="isEditMode" @submitFooterForm="submitFooterForm" :footer-id="selectedFooterId" />
				</div>
			</template>
		</MDialog>
		
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template #loading>
				<div class="campaigns-footer-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #item.name="elem">
				<div v-if="null != elem.item.raw.company_name" class="m9-data-table-align-left">
					<div>{{ elem.item.raw.company_name }}</div>
				</div>
			</template>
			
			<template #item.contact="elem">
				<div v-if="null != elem.item.raw.contact_data">
					<div>{{ elem.item.raw.contact_data }}</div>
				</div>
			</template>
			
			<template #item.content="elem">
				<div v-if="null != elem.item.raw.content" class="m9-data-table-align-left">
					<div v-html="elem.item.raw.content"></div>
				</div>
			</template>
			
			<template #item.created_at="elem">
				<div v-if="null != elem.item.raw.created_at">
					<div>{{ dateFormat(elem.item.raw.created_at) }}</div>
				</div>
			</template>
			
			<template #item.actions="elem">
				<div class="campaigns-footer-table__action-wrapper">
					<div class="campaigns-footer-table__action-edit" @click="editCampaignFooter(elem.item.raw.id)">
						<MIcon icon="edit" :color="white" />
					</div>
					<div class="campaigns-footer-table__action-delete" @click="openDeleteCampaignFooterDialog(elem.item.raw.campaign_id, elem.item.raw.id)">
						<MIcon icon="trash" :color="white" />
					</div>
				</div>
			</template>
		</v-data-table-server>
		
		<MDialog
			:is-close-dialog-action="isDeleteCloseDialog"
			:is-open-dialog-action="isDeleteOpenDialog"
			:content-title="$t('campaigns.deleteCampaignFooter')"
			@close-dialog="closeDialogWhenNoTargetPressed"
			:width="1000"
			close-header-btn
		>
			<template v-slot:default>
				<div class="campaigns-footer-table__delete-info">{{ $t('campaigns.areYouSureToDelete') }}</div>
				<div class="campaigns-footer-table__dialog-btn-wrapper">
					<MButton
						id="delete-campaign-footer-btn"
						normal
						transparent-with-border
						:label="$t('campaigns.close')"
						@click="closeDialogWhenNoTargetPressed"
					/>
					<MButton
						id="delete-campaign-footer-btn"
						normal
						red400
						:label="$t('campaigns.delete')"
						@click="deleteCampaignFooter"
					/>
				</div>
			</template>
		</MDialog>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { ref } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { dateFormat } from '@/hooks/DataHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import type { ICampaignFooterListItems } from '@/models/CampaignModel';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import CampaignApi from '@/api/v1/CampaignApi';
import MonolithCampaignsApi from '@/api/monolith/MonolithCampaigsApi';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MDialog from '@/components/atoms/MDialog.vue';
import CampaignsFooterForm from '@/views/Campaigns/components/CampaignsFooterForm.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MButton from '@/components/atoms/MButton.vue';

const campaignApi = new CampaignApi();
const monolithCampaignsApi = new MonolithCampaignsApi();

const { t } = useI18n();
const { white } = variables;

const items = ref<Array<ICampaignFooterListItems>>([]);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const total = ref<number>(0);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('campaigns.companyName')}`,
		align: 'center',
		sortable: false,
		key: 'name',
		width: '250px',
	},
	{
		title: `${t('campaigns.contact')}`,
		align: 'center',
		sortable: false,
		key: 'contact',
		width: '200px',
	},
	{
		title: `${t('campaigns.content')}`,
		align: 'center',
		sortable: false,
		key: 'content',
		width: '250px',
	},
	{
		title: `${t('campaigns.createdAt')}`,
		align: 'center',
		sortable: false,
		key: 'created_at',
		width: '120px',
	},
	{
		title: `${t('campaigns.actions')}`,
		align: 'center',
		sortable: false,
		key: 'actions',
		width: '150px',
	},
]);
const loading = ref<boolean>(false);
const isOpenDialog = ref<boolean>(false);
const isDeleteOpenDialog = ref<boolean>(false);
const isCloseDialog = ref<boolean>(true);
const isDeleteCloseDialog = ref<boolean>(true);
const isEditMode = ref<boolean>(false);
const selectedFooterId = ref<number|null>(null);
const selectedCampaignId = ref<number|null>(null);

const footerList = async() => {
	loading.value = true;
	try {
		const result = await campaignApi.campaignsFooters(router.currentRoute.value.params.campaignUuid, options.value.page, options.value.itemsPerPage);
		if (!result.isSuccess) {
			toast.error(`${t('campaigns.toast.errorFooterList')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload.data;
		total.value = result.payload.total;
		currentPage.value = result.payload.current_page;
		lastPage.value = result.payload.last_page;
	} catch (e) {
		toast.error(`${t('campaigns.toast.errorFooterList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const openAddCampaignFooterModal = () => {
	isCloseDialog.value = false;
	isOpenDialog.value = true;
};

const closeDialogWhenNoTargetPressed = () => {
	isCloseDialog.value = true;
	isDeleteCloseDialog.value = true;
	isOpenDialog.value = false;
	isDeleteOpenDialog.value = false;
	
	isEditMode.value = false;
	selectedFooterId.value = null;
	selectedCampaignId.value = null;
};

const submitFooterForm = () => {
	closeDialogWhenNoTargetPressed();
	footerList();
};

const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	await footerList();
};

const editCampaignFooter = (footerId:number) => {
	selectedFooterId.value = footerId;
	isEditMode.value = true;
	openAddCampaignFooterModal();
};
const openDeleteCampaignFooterDialog = (campaignId:number, footerId:number) => {
	selectedFooterId.value = footerId;
	selectedCampaignId.value = campaignId;
	isDeleteCloseDialog.value = false;
	isDeleteOpenDialog.value = true;
};
const deleteCampaignFooter = async() => {
	loading.value = true;
	try {
		const result = await monolithCampaignsApi.deleteCampaignFooter(selectedCampaignId.value, selectedFooterId.value);
		if (!result.isSuccess) {
			toast.error(`${t('campaigns.toast.errorDeleteFooter')}`);
			loading.value = false;
			return;
		}
		toast.success(`${t('campaigns.toast.successDeleteFooter')}`);
		await closeDialogWhenNoTargetPressed();
		await footerList();
	} catch (e) {
		toast.error(`${t('campaigns.toast.errorDeleteFooter')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
</script>

<style scoped lang="scss">
.campaigns-footer-table {
	@import '@/assets/scss/vuetifyDataTable';
	&__loading {
		opacity: 0.5;
	}
	
	&__action-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
	}
	
	&__action-edit {
		padding: 10px 12px 10px 14px;
		border-radius: 50%;
		background-color: $grey-300;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__action-delete {
		padding: 11px 12px 9px 13px;
		border-radius: 50%;
		background-color: $secondary-400;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__delete-info {
		text-align: center;
		color: $primary-400;
		margin-bottom: 20px;
	}
	
	&__dialog-btn-wrapper {
		display: flex;
		gap: 12px;
		justify-content: center;
	}
}
</style>
