<template>
	<div class="google-status">
		<div class="google-status__status-wrapper">
			<div class="google-status__title">{{ $t('articles.authorizationStatus') }}</div>
			<div v-if="isAuthorizationStatus" class="google-status__status google-status__status--green">{{ $t('articles.on') }}</div>
			<div v-if="!isAuthorizationStatus" class="google-status__status google-status__status--red">{{ $t('articles.off') }}</div>
		</div>
		<div class="google-status__button-wrapper">
			<MButton
				:label="$t('articles.connectYourGoogleAccount')"
				blue400
				big
				@click="googleAuthorization"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import GoogleApi from '@/api/v1/GoogleApi';
import variables from '@/assets/scss/modules/variables.module.scss';
import MButton from '@/components/atoms/MButton.vue';

const googleApi = new GoogleApi();

const { secondary400 } = variables;
const { t } = useI18n();

const isAuthorizationStatus = ref<boolean>(false);

const googleAuthorization = async() => {
	try {
		const result = await googleApi.googleLogin();
		
		if (!result.isSuccess) {
			toast.error(`${t('articles.toast.error')}`);
			return;
		}
		if (null != result.payload.url) {
			window.location.href = result.payload.url;
		} else {
			toast.error(`${t('articles.toast.error')}`);
		}
	} catch (e) {
		toast.error(`${t('articles.toast.error')}`);
		return;
	}
	
	await googleStatusCheck();
};

const googleStatusCheck = async() => {
	try {
		const result = await googleApi.getStatus();
		if (!result.isSuccess) {
			isAuthorizationStatus.value = false;
			return;
		}
		
		if (result.payload.status) {
			isAuthorizationStatus.value = true;
		} else {
			isAuthorizationStatus.value = false;
		}
	} catch (e) {
		isAuthorizationStatus.value = false;
		return;
	}
};
googleStatusCheck();
</script>

<style scoped lang="scss">
.google-status {
	&__status-wrapper {
		display: flex;
		align-items: center;
		gap: 10px;
	}
	
	&__title {
		font-size: 22px;
		font-weight: $weight-700;
	}
	
	&__status {
		font-size: 18px;
		font-weight: $weight-700;
		padding: 10px 10px;
		border-radius: 50%;
		color: $white-100;
		
		&--green {
			background-color: $green-400;
		}
		
		&--red {
			background-color: $secondary-400;
		}
	}
	
	&__button-wrapper {
		margin-top: 20px;
	}
}
</style>
