<template>
	<div class="agency-account-list-table">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:sort-by="options.sortBy"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template #loading>
				<div class="agency-account-list-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #item.created_at="elem">
				<div v-if="elem.item.raw.created_at != null">{{ dateFormat(elem.item.raw.created_at) }}</div>
			</template>
			
			<template #item.balance_advertiser="elem">
				<div v-if="elem.item.raw.accounts != null && elem.item.raw.accounts.length > 0" class="agency-account-list-table__price m9-data-table-align-price">{{ (elem.item.raw.accounts[0].balance / 100).toFixed(2) }} {{ changeCurrencyIdToName(elem.item.raw.accounts[0].currency_id) }}</div>
			</template>
			
			<template #item.balance_publisher="elem">
				<div v-if="elem.item.raw.accounts != null && elem.item.raw.accounts.length > 0" class="agency-account-list-table__price m9-data-table-align-price">{{ (elem.item.raw.accounts[1].balance / 100).toFixed(2) }} {{ changeCurrencyIdToName(elem.item.raw.accounts[1].currency_id) }}</div>
			</template>
			
			<template #item.actions="elem">
				<div class="agency-account-list-table__action-wrapper">
					<MTooltip without-btn color="green">
						<template v-slot:title>
							<div @click="changeBalanceAgencyUser(elem.item.raw.uuid)" class="agency-account-list-table__icon-wrapper agency-account-list-table__icon-wrapper--balance">
								<MIcon icon="money" :color="white" />
							</div>
						</template>
						<template v-slot:content>
							<div>{{ $t('agency.changeAccountBalance') }}</div>
						</template>
					</MTooltip>
					<MTooltip without-btn color="primary">
						<template v-slot:title>
							<div @click="editAgencyUser(elem.item.raw.uuid)" class="agency-account-list-table__icon-wrapper">
								<MIcon icon="edit" :color="white" />
							</div>
						</template>
						<template v-slot:content>
							<div>{{ $t('agency.editProfile') }}</div>
						</template>
					</MTooltip>
					<MTooltip without-btn color="secondary">
						<template v-slot:title>
							<div v-if="checkBalance(elem.item.raw)" @click="openAgencyDeleteDialog(elem.item.raw.uuid)" class="agency-account-list-table__icon-wrapper agency-account-list-table__icon-wrapper--delete">
								<MIcon icon="trash" :color="white" />
							</div>
						</template>
						<template v-slot:content>
							<div>{{ $t('agency.delete') }}</div>
						</template>
					</MTooltip>
				</div>
			</template>
		</v-data-table-server>
		
		<MDialog
			:content-title="$t('agency.deleteDialog')"
			:is-open-dialog-action="isOpenDeleteDialog"
			:is-close-dialog-action="isCloseDeleteDialog"
			@close-dialog="closeDialogWhenNoTargetPressed"
			:width="700"
		>
			<template v-slot:default>
				<div>{{ $t('agency.deleteDialogInfo') }}</div>
				<div class="agency-account-list-table__delete-btn-wrapper">
					<MButton
						big
						red400
						:label="$t('agency.delete')"
						@click="deleteAgencyUser"
					/>
					<MButton
						big
						blue400
						:label="$t('agency.cancel')"
						@click="closeDialogWhenNoTargetPressed"
					/>
				</div>
			</template>
		</MDialog>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { ref } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useDictionaryStore } from '@/stores/dictionary';
import { toast } from 'vue3-toastify';
import { dateFormat } from '@/hooks/DataHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { AgencyItemListInterface } from '@/models/AgencyModel';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import AgencyApi from '@/api/v1/AgencyApi';
import MTooltip from '@/components/atoms/MTooltip.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MDialog from '@/components/atoms/MDialog.vue';
import MButton from '@/components/atoms/MButton.vue';

const agencyApi = new AgencyApi();

const { t } = useI18n();
const { user } = storeToRefs(useUserStore());
const { currencies } = useDictionaryStore();
const { white } = variables;

const loading = ref<boolean>(false);
const items = ref<Array<AgencyItemListInterface>>([]);
const total = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('agency.firstName')}`,
		align: 'center',
		sortable: true,
		key: 'first_name',
		width: '150px'
	},
	{
		title: `${t('agency.lastName')}`,
		align: 'center',
		sortable: true,
		key: 'last_name',
		width: '150px'
	},
	{
		title: `${t('agency.email')}`,
		align: 'center',
		sortable: true,
		key: 'email',
		width: '200px'
	},
	{
		title: `${t('agency.created')}`,
		align: 'center',
		sortable: true,
		key: 'created_at',
		width: '120px'
	},
	{
		title: `${t('agency.advertiserBalance')}`,
		align: 'center',
		sortable: true,
		key: 'balance_advertiser',
		width: '100px'
	},
	{
		title: `${t('agency.publisherBalance')}`,
		align: 'center',
		sortable: true,
		key: 'balance_publisher',
		width: '100px'
	},
	{
		title: `${t('agency.actions')}`,
		align: 'center',
		sortable: false,
		key: 'actions',
		width: '100px'
	},
]);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
let isOpenDeleteDialog = ref<boolean>(false);
let isCloseDeleteDialog = ref<boolean>(true);
const userUuid = ref<string>('');

const agencyUserList = async() => {
	loading.value = true;
	if (null == user.value) {
		toast.error(t('agency.errorGetUserList'));
		loading.value = false;
		return;
	}
	try {
		const result = await agencyApi.agencyUserList(
			user.value?.uuid,
			options.value.page,
			options.value.itemsPerPage,
			options.value.sortBy.length > 0 ? options.value.sortBy[0].key : null,
			options.value.sortBy.length > 0 ? options.value.sortBy[0].order : null,
			null
		);
		if (!result.isSuccess) {
			toast.error(t('agency.errorGetUserList'));
			loading.value = false;
			return;
		}
		items.value = result.payload.data;
		total.value = result.payload.total;
		currentPage.value = result.payload.current_page;
		lastPage.value = result.payload.last_page;
	} catch (e) {
		toast.error(t('agency.errorGetUserList'));
		loading.value = false;
		return;
	}
	loading.value = false;
};
const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	await agencyUserList();
};

const changeCurrencyIdToName = (currencyId:number) => {
	if (null != currencyId) {
		const currencyName = currencies.find(elem => elem.id === currencyId);
		return null != currencyName ? currencyName.currency_symbol : '';
	}
	return '-';
};

const editAgencyUser = (userUuid:string) => {
	router.push(`/profile/${userUuid}`);
};
const changeBalanceAgencyUser = (userUuid:string) => {
	router.push(`/agency/balance/${userUuid}`);
};
const checkBalance = (item:AgencyItemListInterface|any) => {
	if (item.accounts.length > 0 && 0 === item.accounts[0].balance && 0 === item.accounts[1].balance) {
		return true;
	} else {
		return false;
	}
};

const closeDialogWhenNoTargetPressed = () => {
	isCloseDeleteDialog.value = true;
	isOpenDeleteDialog.value = false;
};

const openAgencyDeleteDialog = (dependentUserUuid:string) => {
	userUuid.value = dependentUserUuid;
	isCloseDeleteDialog.value = false;
	isOpenDeleteDialog.value = true;
};

const deleteAgencyUser = async() => {
	loading.value = true;
	if ('' === userUuid.value) {
		toast.error(`${t('agency.toast.deleteError')}`);
		loading.value = false;
		return;
	}
	try {
		const result = await agencyApi.deleteAgencyUser(userUuid.value);
		if (!result.isSuccess) {
			toast.error(`${t('agency.toast.deleteError')}`);
			loading.value = false;
			return;
		}
	} catch (e) {
		toast.error(`${t('agency.toast.deleteError')}`);
		loading.value = false;
		return;
	}
	toast.success(`${t('agency.toast.deleteSuccess')}`);
	await agencyUserList();
	closeDialogWhenNoTargetPressed();
	loading.value = false;
};
</script>

<style scoped lang="scss">
.agency-account-list-table {
	@import '@/assets/scss/vuetifyDataTable';
	&__loading {
		opacity: 0.5;
	}
	
	&__price {
		font-weight: 700;
	}
	
	&__action-wrapper {
		display: flex;
		justify-content: flex-end;
		gap: 5px;
	}
	
	&__icon-wrapper {
		background-color: $primary-400;
		border-radius: 50%;
		padding: 9px 10px 7px 12px;
		width: max-content;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		&--delete {
			padding: 10px 10px 6px 11px;
			background-color: $secondary-400;
		}
		
		&--balance {
			padding: 10px 11px 6px 11px;
			background-color: $green-400;
		}
	}
	
	&__delete-btn-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 50px;
		margin: 20px 0 0;
		
		@include media-breakpoint-up(md) {
			flex-direction: row;
		}
		
		button {
			border-radius: 12px !important;
			padding: 25px 40px !important;
		}
	}
	
	&:deep(.v-data-table-footer .v-field__field) {
		height: 40px;
	}
	
	&:deep(.v-data-table-footer .v-field__input) {
		font-size: 12px;
		padding: 11px 0 0 10px;
	}
	
	&:deep(.v-data-table-footer .v-input) {
		height: 40px;
	}
}
</style>
