import BaseApi from '@/api/BaseApi';
import type {
	IReflinkEditNameRequest,
	IReflinkNetwork,
	IReflinkPeriods,
	IReflinkRequest,
	IReflinkResponse
} from '@/models/ReflinksModel';

export default class ReflinkApi extends BaseApi {
	async editReflinkHash (userUuid: string|null, params: string|null) {
		return this.put<IReflinkEditNameRequest|null, any>({
			url: `/api/v1/reflink/${userUuid}/hash`,
			data: {
				reflink_hash: params,
			},
		});
	};

	async createDefaultPeriodReflink (userUuid: string|null) {
		return this.post<any, IReflinkResponse>({
			url: `/api/v1/reflink/${userUuid}`,
		});
	};

	async networkReflinkList (userUuid: string|null, page: number, perPage: number) {
		return this.get<IReflinkNetwork>({
			url: `/api/v1/reflink/${userUuid}/network?page=${page}&per_page=${perPage}`,
		});
	};

	async periodReflinkList (userUuid: string|null) {
		return this.get<Array<IReflinkPeriods>>({
			url: `/api/v1/reflink/${userUuid}`,
		});
	};

	async deletePeriodReflink (periodUuid: string|null) {
		return this.delete({
			url: `/api/v1/reflink/${periodUuid}/range`,
		});
	};

	async createPeriodReflink (userUuid: string|null, params: IReflinkRequest) {
		return this.post<IReflinkRequest, IReflinkResponse>({
			url: `/api/v1/reflink/${userUuid}/range`,
			data: params,
		});
	};

	async editPeriodReflink (periodUuid: string|null, params: IReflinkRequest) {
		return this.put<IReflinkRequest, IReflinkResponse>({
			url: `/api/v1/reflink/${periodUuid}`,
			data: params,
		});
	};
};
