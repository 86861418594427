<template>
	<div class="upload-article-google-drive">
		<div v-if="null == googleToken" class="upload-article-google-drive__authorisation-btn-wrapper">
			<div class="upload-article-google-drive__btn-wrapper">
				<MButton
					:label="$t('articles.connectYourGoogleAccount')"
					icon="google-email"
					:color="secondary400"
					blue400
					big
					@click="googleAuthorization"
				/>
			</div>
			<div class="upload-article-google-drive__img-wrapper">
				<img src="~@/assets/images/google-background.jpg" alt="google background">
			</div>
		</div>
		
		<div v-if="null != googleToken && !isSelectedGoogleFilesMapped">
			<HeadingLabel
				class="upload-article-google-drive__heading-label"
				icon="google-email"
				width="32"
				height="29"
				:text="$t('articles.googleFiles')"
			/>
			<GoogleArticleTable />
		</div>
		
		<div v-if="isSelectedGoogleFilesMapped">
			<HeadingLabel
				class="upload-article-google-drive__heading-label"
				icon="google-email"
				width="32"
				height="29"
				:text="$t('articles.googleImport')"
			/>
			<UploadArticleTable :items="items" :loading="loading" @deleteItem="deleteItem" @footerAction="footerAction" @applyToAll="applyToAll" @backAction="backAction" />
		</div>
		
		<MLoading v-show="loading" />
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useGoogleStore } from '@/stores/google';
import { useDictionaryStore } from '@/stores/dictionary';
import CampaignApi from '@/api/v1/CampaignApi';
import type { GoogleImportFileInterface } from '@/models/GoogleModel';
import variables from '@/assets/scss/modules/variables.module.scss';
import MButton from '@/components/atoms/MButton.vue';
import MLoading from '@/components/atoms/MLoading.vue';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import GoogleArticleTable from '@/views/Articles/components/GoogleArticleTable.vue';
import UploadArticleTable from '@/components/organisms/Articles/components/UploadArticleTable.vue';

const campaignApi = new CampaignApi();

const { googleToken, isSelectedGoogleFiles, setGoogleFiles, setIsSelectedGoogleFiles } = useGoogleStore();
const { secondary400 } = variables;
const { campaigns } = useDictionaryStore();

const loading = ref<boolean>(false);
const isSelectedGoogleFilesMapped = ref<boolean>(isSelectedGoogleFiles);
const items = ref<Array<GoogleImportFileInterface>|any>([]);

const googleAuthorization = () => {
	loading.value = true;
	
	// // Google's OAuth 2.0 endpoint for requesting an access token
	const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
	
	// Create element to open OAuth 2.0 endpoint in new window.
	const form = document.createElement('form');
	form.setAttribute('method', 'GET'); // Send as a GET request.
	form.setAttribute('action', oauth2Endpoint);
	
	// Parameters to pass to OAuth 2.0 endpoint.
	const params = {
		client_id: import.meta.env.VITE_GOOGLE_CLIENT_TOKEN,
		redirect_uri: import.meta.env.VITE_GOOGLE_REDIRECT_URL,
		scope: 'https://www.googleapis.com/auth/drive',
		response_type: 'token',
	};
	
	// Add form parameters as hidden input values.
	for (const p in params) {
		const input = document.createElement('input');
		input.setAttribute('type', 'hidden');
		input.setAttribute('name', p);
		input.setAttribute('value', params[p as keyof typeof params]);
		form.appendChild(input);
	}
	
	// Add form to page and submit it to open the OAuth 2.0 endpoint.
	document.body.appendChild(form);
	form.submit();
	
	loading.value = false;
};

watch((useGoogleStore()), (val) => {
	val.googleFiles.forEach(elem => {
		const owner = elem.permissions.find((elems) => {
			return 'owner' === elems.role;
		});
		items.value.push({
			title: elem.name,
			date: elem.modifiedTime,
			file_id: elem.id,
			author: owner !== undefined ? owner.emailAddress : '-',
			size: elem.size,
			campaign_id: null,
			language_id: null,
			publisher_note: null,
			footer_id: null,
			footer_list: [],
			photo: null,
			mimeType: elem.mimeType,
		});
	});
	
	isSelectedGoogleFilesMapped.value = val.isSelectedGoogleFiles;
},{
	deep: true,
});

const deleteItem = (index: number) => {
	items.value.splice(index, 1);
};

const campaignFooterList = async(campaignId:number, index:number) => {
	let campaignUuid = null;
	const campaign = campaigns.find(elem => elem.id === campaignId);
	if (null != campaign) {
		campaignUuid = campaign.uuid;
	}
	try {
		const result = await campaignApi.campaignsFooters(campaignUuid);
		if (!result.isSuccess) {
			return;
		}
		if (null == result.payload.data || result.payload.data.length == 0) {
			items.value[index].footer_id = null;
		}
		items.value[index].footer_list = result.payload.data;
	} catch (e) {
		return;
	}
};

const footerAction = (campaignId:number, index:number) => {
	campaignFooterList(campaignId, index);
}
const applyToAll = (column:string, value:number|string, index?:number) => {
	if (column === 'campaign_id') {
		items.value.forEach((elem:GoogleImportFileInterface|any) => {
			elem.campaign_id = value;
		});
		items.value.forEach((elems:GoogleImportFileInterface|any) => {
			elems.footer_list = items.value[index as keyof typeof items.value].footer_list;
		});
	}
	if (column === 'language_id') {
		items.value.forEach((elem:GoogleImportFileInterface|any) => {
			elem.language_id = value;
		});
	}
	if (column === 'note') {
		items.value.forEach((elem:GoogleImportFileInterface|any) => {
			elem.note = value;
		});
	}
	if (column === 'footer_id') {
		items.value.forEach((elem:GoogleImportFileInterface|any) => {
			elem.footer_id = value;
		});
	}
};

const backAction = () => {
	setGoogleFiles([]);
	setIsSelectedGoogleFiles(false);
	items.value = [];
};
</script>

<style scoped lang="scss">
.upload-article-google-drive {
	&__authorisation-btn-wrapper {
		display: flex;
		justify-content: center;
		margin-top: 200px;
		position: relative;
		
		@include media-breakpoint-up(xl) {
			margin-top: 350px;
		}
	}
	
	&__btn-wrapper {
		position: relative;
		z-index: 2;
	}
	
	&__img-wrapper {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 0;
		width: 100%;
		
		@include media-breakpoint-up(xl) {
			width: 100%;
		}
		
		img {
			width: 100%;
		}
	}
	
	&__heading-label {
		margin-bottom: 5px;
	}
	
	&__btn-wrapper {
		margin-top: 5px;
		display: flex;
		padding: 0 10px;
		justify-content: space-between;
		
		@include media-breakpoint-up(md) {
			padding: 0 25px;
		}
	}
}
</style>
