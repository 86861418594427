import BaseApi from '@/api/BaseApi';
import type {
	IElasticDomainRequest,
	IMonolithElasticDomainOffersResponse,
	IMonolithElasticDomainResponse
} from '@/models/OrderModel';

export default class MonolithOrderApi extends BaseApi {
	async domainList (params:IElasticDomainRequest) {
		return this.get<IMonolithElasticDomainResponse>({
			url: 'api/search/domains',
			params: params,
			config: {
				useMonolithApi: true,
			},
		});
	};

	async domainOfferList (domainId: number, params: IElasticDomainRequest) {
		return this.get<IMonolithElasticDomainOffersResponse>({
			url: `api/websites/${domainId}/offer`,
			params: params,
			config: {
				useMonolithApi: true,
			},
		});
	};
};
