import { toast } from 'vue3-toastify';
import { useTableSettingsStore } from '@/stores/tableSettings';
import TableSettingsApi from '@/api/v1/TableSettingsApi';
import { ref } from 'vue';

const tableSettingsApi = new TableSettingsApi();

// Nazwy tabel
export const ARTICLE_LIST = 'articles-list'
export const ARTICLE_LIST_V2 = 'articles-list-v2'
export const OFFERS_LIST = 'offers-list';
export const ORDERS_LIST = 'orders-list';
export const CARTS_LIST = 'carts-list';
export const TRANSACTIONS_LIST = 'transactions-history';
export const AFFILIATES_LIST = 'affiliates-list';
export const WEBSITES_LIST = 'websites-list';
export const WEBSITE_OFFERS_LIST = 'website-offers-list';
export const PUBLICATION_LIST = 'publications-list';
export const CAMPAIGNS_LIST = 'campaigns-list';
export const ORDER_GROUPS_LIST = 'order-groups-list';

// Nieaktywne nazwy kolumn
export const COLUMN_ACTIONS = 'actions';
export const COLUMN_DATA_TABLE_EXPAND = 'data-table-expand';
export const COLUMN_BASKET_LINK = 'basket_link';

// Opcje ilosci wierszy globalnie
export const itemsPerPageOptions = ref<Array<any>>([
	{
		value: 10,
		title: '10'
	},
	{
		value: 25,
		title: '25'
	},
	{
		value: 50,
		title: '50'
	},
	{
		value: 100,
		title: '100'
	},
]);

// Funkcje do edycji ustawień tabelki
export async function clearTableSettings (tableSlug:string, tableSettings:any, activeRoleId:number, errorMessage:string, successMessage:string) {
	const { set } = useTableSettingsStore();
	try {
		const result = await tableSettingsApi.editTableSettings(tableSlug, tableSettings, activeRoleId);
		if (!result.isSuccess) {
			toast.error(errorMessage);
			return;
		}
	} catch (e) {
		toast.error(errorMessage);
		return;
	}
	await set({ name: tableSlug, settings: tableSettings });
	toast.success(successMessage);
}
export async function editTableSettings (tableSlug:string, tableSettings:any, activeRoleId:number) {
	try {
		await tableSettingsApi.editTableSettings(tableSlug, tableSettings, activeRoleId);
	} catch (e) {
		return;
	}
}

export function setLastPage (itemsPerPage:number, itemsLength:number) {
	const modulo = itemsLength % itemsPerPage;
	if (0 === modulo) {
		return itemsLength / itemsPerPage;
	} else {
		return +(itemsLength / itemsPerPage).toFixed(0) > 0 ? +(itemsLength / itemsPerPage).toFixed(0) : 1;
	}
}
