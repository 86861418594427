<template>
	<div class="import-preview-table">
		<v-data-table-server
			:items="currentItems"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template #loading>
				<div class="import-preview-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #top>
				<div v-if="!isReadOnly" class="import-preview-table__top-section">
					<MButton id="errorRows" big red400 :label="!isRowsWithErrors ? `${$t('importExport.filterInfo')}: ${counter}` : $t('importExport.filterBackInfo')" @click="filterErrorRows" />
				</div>
			</template>
			
			<template #item.result_status="elem">
				<div v-if="elem.item.raw.status !== PREVIEW_STATUS_ERROR">{{ elem.item.raw.status }}</div>
				<div v-if="elem.item.raw.status === PREVIEW_STATUS_ERROR">
					<MTooltip without-btn>
						<template v-slot:title>
							<span class="import-preview-table__margin-right-5">{{ elem.item.raw.status }}</span>
							<MIcon icon="info" width="16" height="16" />
						</template>
						<template v-slot:content>
							<div>{{ elem.item.raw.message }}</div>
						</template>
					</MTooltip>
				</div>
			</template>
			
			<template #item.uuid="elem">
				<div class="import-preview-table__uuid-btn-wrapper">
					<MButton id="refresh" @click="validateRow(elem.item.raw.uuid, elem.item.raw)" big blue400 label="" icon="refresh" :disabled="isReadOnly && importStatus !== VALIDATED" />
				</div>
			</template>
			
			<template #item.operation="elem">
				<div v-if="elem.item.raw.should_delete.value !== '1' && (elem.item.raw.website_uuid.value == null || elem.item.raw.website_uuid.value === '' || elem.item.raw.offer_uuid.value == null || elem.item.raw.offer_uuid.value === '')">{{ $t('importExport.created') }}</div>
				<div v-if="elem.item.raw.should_delete.value !== '1' && elem.item.raw.website_uuid.value != null && elem.item.raw.website_uuid.value !== '' && elem.item.raw.offer_uuid.value != null && elem.item.raw.offer_uuid.value !== ''">{{ $t('importExport.updated') }}</div>
				<div v-if="elem.item.raw.should_delete.value === '1'">{{ $t('importExport.deleteUppercase') }}</div>
			</template>
			
			<template #item.website_uuid="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.website_uuid.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.website_uuid.status)">
						<MTextField
							id="website_uuid"
							name="website_uuid"
							v-model="elem.item.raw.website_uuid.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.website_uuid.status)" :class="errorTextColor(elem.item.raw.website_uuid.status)">{{ elem.item.raw.website_uuid.message }}</div>
				</div>
			</template>
			
			<template #item.offer_uuid="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.offer_uuid.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.offer_uuid.status)">
						<MTextField
							id="offer_uuid"
							name="offer_uuid"
							v-model="elem.item.raw.offer_uuid.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.offer_uuid.status)" :class="errorTextColor(elem.item.raw.offer_uuid.status)">{{ elem.item.raw.offer_uuid.message }}</div>
				</div>
			</template>
			
			<template #item.user_email="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.user_email.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.user_email.status)">
						<MTextField
							id="user_email"
							name="user_email"
							v-model="elem.item.raw.user_email.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.user_email.status)" :class="errorTextColor(elem.item.raw.user_email.status)">{{ elem.item.raw.user_email.message }}</div>
				</div>
			</template>
			
			<template #item.domain_url="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.domain_url.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.domain_url.status)">
						<MTextField
							id="domain_url"
							name="domain_url"
							v-model="elem.item.raw.domain_url.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.domain_url.status)" :class="errorTextColor(elem.item.raw.domain_url.status)">{{ elem.item.raw.domain_url.message }}</div>
				</div>
			</template>
			
			<template #item.domain_name="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.domain_name.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.domain_name.status)">
						<MTextField
							id="domain_name"
							name="domain_name"
							v-model="elem.item.raw.domain_name.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.domain_name.status)" :class="errorTextColor(elem.item.raw.domain_name.status)">{{ elem.item.raw.domain_name.message }}</div>
				</div>
			</template>
			
			<template #item.language="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.language.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.language.status)">
						<MAutocomplete
							id="language"
							name="language"
							v-model="elem.item.raw.language.value"
							:get-items="languages"
							item-value="name"
							item-title="name"
							variant="outlined"
							label=""
							@onChange="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.language.status)" :class="errorTextColor(elem.item.raw.language.status)">{{ elem.item.raw.language.message }}</div>
				</div>
			</template>
			
			<template #item.country="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.country.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.country.status)">
						<MAutocomplete
							id="country"
							name="country"
							v-model="elem.item.raw.country.value"
							:get-items="countries"
							item-value="name"
							item-title="name"
							variant="outlined"
							label=""
							@onChange="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.country.status)" :class="errorTextColor(elem.item.raw.country.status)">{{ elem.item.raw.country.message }}</div>
				</div>
			</template>
			
			<template #item.short_description="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.short_description.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.short_description.status)">
						<MTextField
							id="short_description"
							name="short_description"
							v-model="elem.item.raw.short_description.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.short_description.status)" :class="errorTextColor(elem.item.raw.short_description.status)">{{ elem.item.raw.short_description.message }}</div>
				</div>
			</template>
			
			<template #item.category="elem">
				<div v-if="isReadOnly">
					<MAutocomplete
						id="category_read_only"
						name="category"
						v-model="elem.item.raw.category.value"
						:get-items="categoryList"
						item-value="id"
						item-title="name"
						variant="outlined"
						multiple
						label=""
						disabled
						@onChange="validateRow(elem.item.raw.uuid, elem.item.raw)"
					/>
				</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.category.status)">
						<MAutocomplete
							id="category"
							name="category"
							v-model="elem.item.raw.category.value"
							:get-items="categoryList"
							item-value="id"
							item-title="name"
							variant="outlined"
							multiple
							label=""
							@onChange="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.category.status)" :class="errorTextColor(elem.item.raw.category.status)">{{ elem.item.raw.category.message }}</div>
				</div>
			</template>
			
			<template #item.additional_category="elem">
				<div v-if="isReadOnly">
					<MAutocomplete
						id="additional_category_read_only"
						name="additional_category"
						v-model="elem.item.raw.additional_category.value"
						:get-items="categoryList"
						item-value="id"
						item-title="name"
						variant="outlined"
						multiple
						label=""
						disabled
						@onChange="validateRow(elem.item.raw.uuid, elem.item.raw)"
					/>
				</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.additional_category.status)">
						<MAutocomplete
							id="additional_category"
							name="additional_category"
							v-model="elem.item.raw.additional_category.value"
							:get-items="categoryList"
							item-value="id"
							item-title="name"
							variant="outlined"
							multiple
							label=""
							@onChange="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.additional_category.status)" :class="errorTextColor(elem.item.raw.additional_category.status)">{{ elem.item.raw.additional_category.message }}</div>
				</div>
			</template>
			
			<template #item.forbidden_topics="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.forbidden_topics.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.forbidden_topics.status)">
						<MAutocomplete
							id="forbidden_topics"
							name="forbidden_topics"
							v-model="elem.item.raw.forbidden_topics.value"
							:get-items="forbiddenTopics"
							item-value="name"
							item-title="name"
							variant="outlined"
							multiple
							label=""
							@onChange="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.forbidden_topics.status)" :class="errorTextColor(elem.item.raw.forbidden_topics.status)">{{ elem.item.raw.forbidden_topics.message }}</div>
				</div>
			</template>
			
			<template #item.photo_in_article="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.photo_in_article.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.photo_in_article.status)">
						<MAutocomplete
							id="photo_in_article"
							name="photo_in_article"
							v-model="elem.item.raw.photo_in_article.value"
							:get-items="photoInArticleItems"
							item-value="name"
							item-title="name"
							variant="outlined"
							label=""
							@onChange="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.photo_in_article.status)" :class="errorTextColor(elem.item.raw.photo_in_article.status)">{{ elem.item.raw.photo_in_article.message }}</div>
				</div>
			</template>
			
			<template #item.other_requirements="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.other_requirements }}</div>
				<div v-if="!isReadOnly">
					<MTextField
						id="other_requirements"
						name="other_requirements"
						v-model="elem.item.raw.other_requirements"
						label=""
						@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
					/>
				</div>
			</template>
			
			<template #item.section_name="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.section_name.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.section_name.status)">
						<MAutocomplete
							id="section_name"
							name="section_name"
							v-model="elem.item.raw.section_name.value"
							:get-items="sectionName"
							item-value="name"
							item-title="name"
							variant="outlined"
							label=""
							@onChange="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.section_name.status)" :class="errorTextColor(elem.item.raw.section_name.status)">{{ elem.item.raw.section_name.message }}</div>
				</div>
			</template>
			
			<template #item.offer_url="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.offer_url.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.offer_url.status)">
						<MTextField
							id="offer_url"
							name="offer_url"
							v-model="elem.item.raw.offer_url.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.offer_url.status)" :class="errorTextColor(elem.item.raw.offer_url.status)">{{ elem.item.raw.offer_url.message }}</div>
				</div>
			</template>
			
			<template #item.offer_name="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.offer_name.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.offer_name.status)">
						<MTextField
							id="offer_name"
							name="offer_name"
							v-model="elem.item.raw.offer_name.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.offer_name.status)" :class="errorTextColor(elem.item.raw.offer_name.status)">{{ elem.item.raw.offer_name.message }}</div>
				</div>
			</template>
			
			<template #item.sponsorship_disclosure="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.sponsorship_disclosure.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.sponsorship_disclosure.status)">
						<MAutocomplete
							id="sponsorship_disclosure"
							name="sponsorship_disclosure"
							v-model="elem.item.raw.sponsorship_disclosure.value"
							:get-items="sponsorshipDisclosure"
							item-value="name"
							item-title="name"
							variant="outlined"
							label=""
							@onChange="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.sponsorship_disclosure.status)" :class="errorTextColor(elem.item.raw.sponsorship_disclosure.status)">{{ elem.item.raw.sponsorship_disclosure.message }}</div>
				</div>
			</template>
			
			<template #item.sponsorship_disclosure_other="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.sponsorship_disclosure_other.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.sponsorship_disclosure_other.status)">
						<MTextField
							id="sponsorship_disclosure_other"
							name="sponsorship_disclosure_other"
							v-model="elem.item.raw.sponsorship_disclosure_other.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.sponsorship_disclosure_other.status)" :class="errorTextColor(elem.item.raw.sponsorship_disclosure_other.status)">{{ elem.item.raw.sponsorship_disclosure_other.message }}</div>
				</div>
			</template>
			
			<template #item.publication_period="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.publication_period.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.publication_period.status)">
						<MAutocomplete
							id="publication_period"
							name="publication_period"
							v-model="elem.item.raw.publication_period.value"
							:get-items="periodTime"
							item-value="name"
							item-title="name"
							variant="outlined"
							label=""
							@onChange="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.publication_period.status)" :class="errorTextColor(elem.item.raw.publication_period.status)">{{ elem.item.raw.publication_period.message }}</div>
				</div>
			</template>
			
			<template #item.anchor_types="elem">
				<div v-if="isReadOnly">
					<MAutocomplete
						id="anchor_types"
						name="anchor_types"
						v-model="elem.item.raw.anchor_types.value"
						:get-items="anchorTypesList"
						item-value="id"
						item-title="name"
						variant="outlined"
						label=""
						multiple
						disabled
						@onChange="validateRow(elem.item.raw.uuid, elem.item.raw)"
					/>
				</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.anchor_types.status)">
						<MAutocomplete
							id="anchor_types"
							name="anchor_types"
							v-model="elem.item.raw.anchor_types.value"
							:get-items="anchorTypesList"
							item-value="id"
							item-title="name"
							variant="outlined"
							label=""
							multiple
							@onChange="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.anchor_types.status)" :class="errorTextColor(elem.item.raw.anchor_types.status)">{{ elem.item.raw.anchor_types.message }}</div>
				</div>
			</template>
			
			<template #item.dofollow_links="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.dofollow_links.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.dofollow_links.status)">
						<v-radio-group v-model="elem.item.raw.dofollow_links.value" @change="validateRow(elem.item.raw.uuid, elem.item.raw)" :color="secondary400">
							<v-radio
								id="dofollow_links_no"
								:label="$t('components.no')"
								value="No"
							></v-radio>
							<v-radio
								id="dofollow_links_yes"
								:label="$t('components.yes')"
								value="Yes"
							></v-radio>
						</v-radio-group>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.dofollow_links.status)" :class="errorTextColor(elem.item.raw.dofollow_links.status)">{{ elem.item.raw.dofollow_links.message }}</div>
				</div>
			</template>
			
			<template #item.amount_of_links="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.amount_of_links.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.amount_of_links.status)">
						<MAutocomplete
							id="amount_of_links"
							name="amount_of_links"
							v-model="elem.item.raw.amount_of_links.value"
							:get-items="amountOfLinks"
							item-value="name"
							item-title="name"
							variant="outlined"
							label=""
							@onChange="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.amount_of_links.status)" :class="errorTextColor(elem.item.raw.amount_of_links.status)">{{ elem.item.raw.amount_of_links.message }}</div>
				</div>
			</template>
			
			<template #item.offer_contain_article="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.offer_contain_article.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.offer_contain_article.status)">
						<v-radio-group v-model="elem.item.raw.offer_contain_article.value" @change="validateRow(elem.item.raw.uuid, elem.item.raw)" :color="secondary400">
							<v-radio
								id="offer_contain_article_no"
								:label="$t('components.no')"
								value="No"
							></v-radio>
							<v-radio
								id="offer_contain_article_yes"
								:label="$t('components.yes')"
								value="Yes"
							></v-radio>
						</v-radio-group>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.offer_contain_article.status)" :class="errorTextColor(elem.item.raw.offer_contain_article.status)">{{ elem.item.raw.offer_contain_article.message }}</div>
				</div>
			</template>
			
			<template #item.days_to_write_article="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.days_to_write_article.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.days_to_write_article.status)">
						<MAutocomplete
							id="days_to_write_article"
							name="days_to_write_article"
							v-model="elem.item.raw.days_to_write_article.value"
							:get-items="daysToWrite"
							item-value="name"
							item-title="name"
							variant="outlined"
							label=""
							@onChange="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.days_to_write_article.status)" :class="errorTextColor(elem.item.raw.days_to_write_article.status)">{{ elem.item.raw.days_to_write_article.message }}</div>
				</div>
			</template>
			
			<template #item.add_includes_banners="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.add_includes_banners.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.add_includes_banners.status)">
						<v-radio-group v-model="elem.item.raw.add_includes_banners.value" @change="validateRow(elem.item.raw.uuid, elem.item.raw)" :color="secondary400">
							<v-radio
								id="add_includes_banners_no"
								:label="$t('components.no')"
								value="No"
							></v-radio>
							<v-radio
								id="add_includes_banners_yes"
								:label="$t('components.yes')"
								value="Yes"
							></v-radio>
						</v-radio-group>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.add_includes_banners.status)" :class="errorTextColor(elem.item.raw.add_includes_banners.status)">{{ elem.item.raw.add_includes_banners.message }}</div>
				</div>
			</template>
			
			<template #item.promo_facebook="elem">
				<div v-if="isReadOnly">
					<div v-if="elem.item.raw.promo_facebook.value === '0'">{{ $t('importExport.no') }}</div>
					<div v-if="elem.item.raw.promo_facebook.value === '1'">{{ $t('importExport.yes') }}</div>
				</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.promo_facebook.status)">
						<v-radio-group v-model="elem.item.raw.promo_facebook.value" @change="validateRow(elem.item.raw.uuid, elem.item.raw)" :color="secondary400">
							<v-radio
								id="promo_facebook_no"
								:label="$t('components.no')"
								value="0"
							></v-radio>
							<v-radio
								id="promo_facebook_yes"
								:label="$t('components.yes')"
								value="1"
							></v-radio>
						</v-radio-group>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.promo_facebook.status)" :class="errorTextColor(elem.item.raw.promo_facebook.status)">{{ elem.item.raw.promo_facebook.message }}</div>
				</div>
			</template>
			
			<template #item.promo_twitter="elem">
				<div v-if="isReadOnly">
					<div v-if="elem.item.raw.promo_twitter.value === '0'">{{ $t('importExport.no') }}</div>
					<div v-if="elem.item.raw.promo_twitter.value === '1'">{{ $t('importExport.yes') }}</div>
				</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.promo_twitter.status)">
						<v-radio-group v-model="elem.item.raw.promo_twitter.value" @change="validateRow(elem.item.raw.uuid, elem.item.raw)" :color="secondary400">
							<v-radio
								id="promo_twitter_no"
								:label="$t('components.no')"
								value="0"
							></v-radio>
							<v-radio
								id="promo_twitter_yes"
								:label="$t('components.yes')"
								value="1"
							></v-radio>
						</v-radio-group>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.promo_twitter.status)" :class="errorTextColor(elem.item.raw.promo_twitter.status)">{{ elem.item.raw.promo_twitter.message }}</div>
				</div>
			</template>
			
			<template #item.promo_newsletter="elem">
				<div v-if="isReadOnly">
					<div v-if="elem.item.raw.promo_newsletter.value === '0'">{{ $t('importExport.no') }}</div>
					<div v-if="elem.item.raw.promo_newsletter.value === '1'">{{ $t('importExport.yes') }}</div>
				</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.promo_newsletter.status)">
						<v-radio-group v-model="elem.item.raw.promo_newsletter.value" @change="validateRow(elem.item.raw.uuid, elem.item.raw)" :color="secondary400">
							<v-radio
								id="promo_newsletter_no"
								:label="$t('components.no')"
								value="0"
							></v-radio>
							<v-radio
								id="promo_newsletter_yes"
								:label="$t('components.yes')"
								value="1"
							></v-radio>
						</v-radio-group>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.promo_newsletter.status)" :class="errorTextColor(elem.item.raw.promo_newsletter.status)">{{ elem.item.raw.promo_newsletter.message }}</div>
				</div>
			</template>
			
			<template #item.price="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.price.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.price.status)">
						<MTextField
							id="price"
							name="price"
							type="number"
							step="0.01"
							min="0"
							v-model="elem.item.raw.price.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.price.status)" :class="errorTextColor(elem.item.raw.price.status)">{{ elem.item.raw.price.message }}</div>
				</div>
			</template>
			
			<template #item.offer_description="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.offer_description.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.offer_description.status)">
						<MTextField
							id="offer_description"
							name="offer_description"
							v-model="elem.item.raw.offer_description.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.offer_description.status)" :class="errorTextColor(elem.item.raw.offer_description.status)">{{ elem.item.raw.offer_description.message }}</div>
				</div>
			</template>
			
			<template #item.sample_offer_url="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.sample_offer_url.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.sample_offer_url.status)">
						<MTextField
							id="sample_offer_url"
							name="sample_offer_url"
							v-model="elem.item.raw.sample_offer_url.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.sample_offer_url.status)" :class="errorTextColor(elem.item.raw.sample_offer_url.status)">{{ elem.item.raw.sample_offer_url.message }}</div>
				</div>
			</template>
			
			<template #item.seo_stats_ur="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.url_rating.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.url_rating.status)">
						<MTextField
							id="seo_stats_ur"
							name="seo_stats_ur"
							type="number"
							step="0.01"
							min="0"
							v-model="elem.item.raw.url_rating.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.url_rating.status)" :class="errorTextColor(elem.item.raw.url_rating.status)">{{ elem.item.raw.url_rating.message }}</div>
				</div>
			</template>
			
			<template #item.seo_stats_ot="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.org_traffic.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.org_traffic.status)">
						<MTextField
							id="seo_stats_ot"
							name="seo_stats_ot"
							type="number"
							step="0.01"
							min="0"
							v-model="elem.item.raw.org_traffic.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.org_traffic.status)" :class="errorTextColor(elem.item.raw.org_traffic.status)">{{ elem.item.raw.org_traffic.message }}</div>
				</div>
			</template>
			
			<template #item.seo_stats_dr="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.domain_rating.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.domain_rating.status)">
						<MTextField
							id="seo_stats_dr"
							name="seo_stats_dr"
							type="number"
							step="0.01"
							min="0"
							v-model="elem.item.raw.domain_rating.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.domain_rating.status)" :class="errorTextColor(elem.item.raw.domain_rating.status)">{{ elem.item.raw.domain_rating.message }}</div>
				</div>
			</template>
			
			<template #item.seo_stats_rd="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.ref_domains.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.ref_domains.status)">
						<MTextField
							id="seo_stats_rd"
							name="seo_stats_rd"
							type="number"
							step="0.01"
							min="0"
							v-model="elem.item.raw.ref_domains.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.ref_domains.status)" :class="errorTextColor(elem.item.raw.ref_domains.status)">{{ elem.item.raw.ref_domains.message }}</div>
				</div>
			</template>
			
			<template #item.seo_stats_blrd="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.backlinks_live_refdomains.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.backlinks_live_refdomains.status)">
						<MTextField
							id="seo_stats_blrd"
							name="seo_stats_blrd"
							type="number"
							step="0.01"
							min="0"
							v-model="elem.item.raw.backlinks_live_refdomains.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.backlinks_live_refdomains.status)" :class="errorTextColor(elem.item.raw.backlinks_live_refdomains.status)">{{ elem.item.raw.backlinks_live_refdomains.message }}</div>
				</div>
			</template>
			
			<template #item.seo_stats_pt="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.paid_traffic.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.paid_traffic.status)">
						<MTextField
							id="seo_stats_pt"
							name="seo_stats_pt"
							type="number"
							step="0.01"
							min="0"
							v-model="elem.item.raw.paid_traffic.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.paid_traffic.status)" :class="errorTextColor(elem.item.raw.paid_traffic.status)">{{ elem.item.raw.paid_traffic.message }}</div>
				</div>
			</template>
			
			<template #item.seo_stats_oc="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.org_cost.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.org_cost.status)">
						<MTextField
							id="seo_stats_oc"
							name="seo_stats_oc"
							type="number"
							step="0.01"
							min="0"
							v-model="elem.item.raw.org_cost.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.org_cost.status)" :class="errorTextColor(elem.item.raw.org_cost.status)">{{ elem.item.raw.org_cost.message }}</div>
				</div>
			</template>
			
			<template #item.seo_stats_pc="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.paid_cost.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.paid_cost.status)">
						<MTextField
							id="seo_stats_pc"
							name="seo_stats_pc"
							type="number"
							step="0.01"
							min="0"
							v-model="elem.item.raw.paid_cost.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.paid_cost.status)" :class="errorTextColor(elem.item.raw.paid_cost.status)">{{ elem.item.raw.paid_cost.message }}</div>
				</div>
			</template>
			
			<template #item.seo_stats_bl="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.backlinks_live.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.backlinks_live.status)">
						<MTextField
							id="seo_stats_bl"
							name="seo_stats_bl"
							type="number"
							step="0.01"
							min="0"
							v-model="elem.item.raw.backlinks_live.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.backlinks_live.status)" :class="errorTextColor(elem.item.raw.backlinks_live.status)">{{ elem.item.raw.backlinks_live.message }}</div>
				</div>
			</template>
			
			<template #item.seo_stats_blat="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.backlinks_all_time.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.backlinks_all_time.status)">
						<MTextField
							id="seo_stats_blat"
							name="seo_stats_blat"
							type="number"
							step="0.01"
							min="0"
							v-model="elem.item.raw.backlinks_all_time.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.backlinks_all_time.status)" :class="errorTextColor(elem.item.raw.backlinks_all_time.status)">{{ elem.item.raw.backlinks_all_time.message }}</div>
				</div>
			</template>
			
			<template #item.seo_stats_rdat="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.backlinks_all_time_refdomains.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.backlinks_all_time_refdomains.status)">
						<MTextField
							id="seo_stats_rdat"
							name="seo_stats_rdat"
							type="number"
							step="0.01"
							min="0"
							v-model="elem.item.raw.backlinks_all_time_refdomains.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.backlinks_all_time_refdomains.status)" :class="errorTextColor(elem.item.raw.backlinks_all_time_refdomains.status)">{{ elem.item.raw.backlinks_all_time_refdomains.message }}</div>
				</div>
			</template>
			
			<template #item.seo_stats_tf="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.trust_flow.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.trust_flow.status)">
						<MTextField
							id="seo_stats_tf"
							name="seo_stats_tf"
							type="number"
							step="0.01"
							min="0"
							v-model="elem.item.raw.trust_flow.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.trust_flow.status)" :class="errorTextColor(elem.item.raw.trust_flow.status)">{{ elem.item.raw.trust_flow.message }}</div>
				</div>
			</template>
			
			<template #item.discount_type="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.discount_type.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.discount_type.status)">
						<v-radio-group v-model="elem.item.raw.discount_type.value" @change="validateRow(elem.item.raw.uuid, elem.item.raw)" :color="secondary400">
							<v-radio
								id="discount_type_percent"
								:label="$t('importExport.percent')"
								:value="PREVIEW_PERCENT"
							></v-radio>
							<v-radio
								id="discount_type_cash"
								:label="$t('importExport.cash')"
								:value="PREVIEW_CASH"
							></v-radio>
						</v-radio-group>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.discount_type.status)" :class="errorTextColor(elem.item.raw.discount_type.status)">{{ elem.item.raw.discount_type.message }}</div>
				</div>
			</template>
			
			<template #item.discount_value="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.discount_value.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.discount_value.status)">
						<MTextField
							v-if="elem.item.raw.discount_type.value === PREVIEW_PERCENT"
							id="discount_value_percent"
							name="discount_value"
							type="number"
							step="0.01"
							min="0"
							max="100"
							v-model="elem.item.raw.discount_value.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
						<MTextField
							v-if="elem.item.raw.discount_type.value === PREVIEW_CASH"
							id="discount_value_cash"
							name="discount_value"
							type="number"
							step="0.01"
							min="0"
							v-model="elem.item.raw.discount_value.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.discount_value.status)" :class="errorTextColor(elem.item.raw.discount_value.status)">{{ elem.item.raw.discount_value.message }}</div>
				</div>
			</template>
			
			<template #item.share_type="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.share_type.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.share_type.status)">
						<v-radio-group v-model="elem.item.raw.share_type.value" @change="validateRow(elem.item.raw.uuid, elem.item.raw)" :color="secondary400">
							<v-radio
								id="share_type_percent"
								:label="$t('importExport.percent')"
								:value="PREVIEW_PERCENT"
							></v-radio>
							<v-radio
								id="share_type_cash"
								:label="$t('importExport.cash')"
								:value="PREVIEW_CASH"
							></v-radio>
						</v-radio-group>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.share_type.status)" :class="errorTextColor(elem.item.raw.share_type.status)">{{ elem.item.raw.share_type.message }}</div>
				</div>
			</template>
			
			<template #item.share_value="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.share_value.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.share_value.status)">
						<MTextField
							v-if="elem.item.raw.share_type.value === PREVIEW_PERCENT"
							id="share_value_percent"
							name="share_value"
							type="number"
							step="0.01"
							min="0"
							max="100"
							v-model="elem.item.raw.discount_value.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
						<MTextField
							v-if="elem.item.raw.share_type.value === PREVIEW_CASH"
							id="share_value_cash"
							name="share_value"
							type="number"
							step="0.01"
							min="0"
							v-model="elem.item.raw.share_value.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.share_value.status)" :class="errorTextColor(elem.item.raw.share_value.status)">{{ elem.item.raw.share_value.message }}</div>
				</div>
			</template>
			
			<template #item.currency_code="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.currency_code.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.currency_code.status)">
						<MAutocomplete
							id="currency_code"
							name="currency_code"
							v-model="elem.item.raw.currency_code.value"
							:get-items="currencyCodeList"
							item-value="name"
							item-title="name"
							variant="outlined"
							label=""
							@onChange="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.currency_code.status)" :class="errorTextColor(elem.item.raw.currency_code.status)">{{ elem.item.raw.currency_code.message }}</div>
				</div>
			</template>
			
			<template #item.is_allowed_promotion_bookmakers="elem">
				<div v-if="isReadOnly">
					<div v-if="elem.item.raw.is_allowed_promotion_bookmakers.value === '0'">{{ $t('importExport.no') }}</div>
					<div v-if="elem.item.raw.is_allowed_promotion_bookmakers.value === '1'">{{ $t('importExport.yes') }}</div>
				</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.is_allowed_promotion_bookmakers.status)">
						<v-radio-group v-model="elem.item.raw.is_allowed_promotion_bookmakers.value" @change="validateRow(elem.item.raw.uuid, elem.item.raw)" :color="secondary400">
							<v-radio
								id="is_allowed_promotion_bookmakers_no"
								:label="$t('components.no')"
								value="0"
							></v-radio>
							<v-radio
								id="is_allowed_promotion_bookmakers_yes"
								:label="$t('components.yes')"
								value="1"
							></v-radio>
						</v-radio-group>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.is_allowed_promotion_bookmakers.status)" :class="errorTextColor(elem.item.raw.is_allowed_promotion_bookmakers.status)">{{ elem.item.raw.is_allowed_promotion_bookmakers.message }}</div>
				</div>
			</template>
			
			<template #item.is_allowed_promotion_cbd="elem">
				<div v-if="isReadOnly">
					<div v-if="elem.item.raw.is_allowed_promotion_cbd.value === '0'">{{ $t('importExport.no') }}</div>
					<div v-if="elem.item.raw.is_allowed_promotion_cbd.value === '1'">{{ $t('importExport.yes') }}</div>
				</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.is_allowed_promotion_cbd.status)">
						<v-radio-group v-model="elem.item.raw.is_allowed_promotion_cbd.value" @change="validateRow(elem.item.raw.uuid, elem.item.raw)" :color="secondary400">
							<v-radio
								id="is_allowed_promotion_cbd_no"
								:label="$t('components.no')"
								value="0"
							></v-radio>
							<v-radio
								id="is_allowed_promotion_cbd_yes"
								:label="$t('components.yes')"
								value="1"
							></v-radio>
						</v-radio-group>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.is_allowed_promotion_cbd.status)" :class="errorTextColor(elem.item.raw.is_allowed_promotion_cbd.status)">{{ elem.item.raw.is_allowed_promotion_cbd.message }}</div>
				</div>
			</template>
			
			<template #item.is_allowed_promotion_loans="elem">
				<div v-if="isReadOnly">
					<div v-if="elem.item.raw.is_allowed_promotion_loans.value === '0'">{{ $t('importExport.no') }}</div>
					<div v-if="elem.item.raw.is_allowed_promotion_loans.value === '1'">{{ $t('importExport.yes') }}</div>
				</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.is_allowed_promotion_loans.status)">
						<v-radio-group v-model="elem.item.raw.is_allowed_promotion_loans.value" @change="validateRow(elem.item.raw.uuid, elem.item.raw)" :color="secondary400">
							<v-radio
								id="is_allowed_promotion_loans_no"
								:label="$t('components.no')"
								value="0"
							></v-radio>
							<v-radio
								id="is_allowed_promotion_loans_yes"
								:label="$t('components.yes')"
								value="1"
							></v-radio>
						</v-radio-group>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.is_allowed_promotion_loans.status)" :class="errorTextColor(elem.item.raw.is_allowed_promotion_loans.status)">{{ elem.item.raw.is_allowed_promotion_loans.message }}</div>
				</div>
			</template>
			
			<template #item.days_promotion_on_main_page="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.days_promotion_on_main_page.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.days_promotion_on_main_page.status)">
						<MTextField
							id="days_promotion_on_main_page"
							name="days_promotion_on_main_page"
							v-model="elem.item.raw.days_promotion_on_main_page.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.days_promotion_on_main_page.status)" :class="errorTextColor(elem.item.raw.days_promotion_on_main_page.status)">{{ elem.item.raw.days_promotion_on_main_page.message }}</div>
				</div>
			</template>
			
			<template #item.is_trusted="elem">
				<div v-if="isReadOnly">
					<div v-if="elem.item.raw.is_trusted.value === '0'">{{ $t('importExport.no') }}</div>
					<div v-if="elem.item.raw.is_trusted.value === '1'">{{ $t('importExport.yes') }}</div>
				</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.is_trusted.status)">
						<v-radio-group v-model="elem.item.raw.is_trusted.value" @change="validateRow(elem.item.raw.uuid, elem.item.raw)" :color="secondary400">
							<v-radio
								id="is_trusted_no"
								:label="$t('components.no')"
								value="0"
							></v-radio>
							<v-radio
								id="is_trusted_yes"
								:label="$t('components.yes')"
								value="1"
							></v-radio>
						</v-radio-group>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.is_trusted.status)" :class="errorTextColor(elem.item.raw.is_trusted.status)">{{ elem.item.raw.is_trusted.message }}</div>
				</div>
			</template>
			
			<template #item.fast_delivery="elem">
				<div v-if="isReadOnly">
					<div v-if="elem.item.raw.fast_delivery.value === '0'">{{ $t('importExport.no') }}</div>
					<div v-if="elem.item.raw.fast_delivery.value === '1'">{{ $t('importExport.yes') }}</div>
				</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.fast_delivery.status)">
						<v-radio-group v-model="elem.item.raw.fast_delivery.value" @change="validateRow(elem.item.raw.uuid, elem.item.raw)" :color="secondary400">
							<v-radio
								id="fast_delivery_no"
								:label="$t('components.no')"
								value="0"
							></v-radio>
							<v-radio
								id="fast_delivery_yes"
								:label="$t('components.yes')"
								value="1"
							></v-radio>
						</v-radio-group>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.fast_delivery.status)" :class="errorTextColor(elem.item.raw.fast_delivery.status)">{{ elem.item.raw.fast_delivery.message }}</div>
				</div>
			</template>
			
			<template #item.group_share_1="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.group_share_1.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.group_share_1.status)">
						<MTextField
							id="group_share_1"
							name="group_share_1"
							type="number"
							step="0.01"
							max="100"
							min="0"
							v-model="elem.item.raw.group_share_1.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.group_share_1.status)" :class="errorTextColor(elem.item.raw.group_share_1.status)">{{ elem.item.raw.group_share_1.message }}</div>
				</div>
			</template>
			
			<template #item.group_share_2="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.group_share_2.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.group_share_2.status)">
						<MTextField
							id="group_share_2"
							name="group_share_2"
							type="number"
							step="0.01"
							max="100"
							min="0"
							v-model="elem.item.raw.group_share_2.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.group_share_2.status)" :class="errorTextColor(elem.item.raw.group_share_2.status)">{{ elem.item.raw.group_share_2.message }}</div>
				</div>
			</template>
			
			<template #item.group_share_3="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.group_share_3.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.group_share_3.status)">
						<MTextField
							id="group_share_3"
							name="group_share_3"
							type="number"
							step="0.01"
							max="100"
							min="0"
							v-model="elem.item.raw.group_share_3.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.group_share_3.status)" :class="errorTextColor(elem.item.raw.group_share_3.status)">{{ elem.item.raw.group_share_3.message }}</div>
				</div>
			</template>
			
			<template #item.group_share_4="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.group_share_4.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.group_share_4.status)">
						<MTextField
							id="group_share_4"
							name="group_share_4"
							type="number"
							step="0.01"
							max="100"
							min="0"
							v-model="elem.item.raw.group_share_4.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.group_share_4.status)" :class="errorTextColor(elem.item.raw.group_share_4.status)">{{ elem.item.raw.group_share_4.message }}</div>
				</div>
			</template>
			
			<template #item.group_share_5="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.group_share_5.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.group_share_5.status)">
						<MTextField
							id="group_share_5"
							name="group_share_5"
							type="number"
							step="0.01"
							max="100"
							min="0"
							v-model="elem.item.raw.group_share_5.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.group_share_5.status)" :class="errorTextColor(elem.item.raw.group_share_5.status)">{{ elem.item.raw.group_share_5.message }}</div>
				</div>
			</template>
			
			<template #item.ahrefs_enabled_keywords="elem">
				<div v-if="isReadOnly">
					<div v-if="elem.item.raw.ahrefs_enabled_keywords.value === '0'">{{ $t('importExport.no') }}</div>
					<div v-if="elem.item.raw.ahrefs_enabled_keywords.value === '1'">{{ $t('importExport.yes') }}</div>
				</div>
				<div v-if="!isReadOnly">
					<div>
						<v-radio-group v-model="elem.item.raw.ahrefs_enabled_keywords.value" @change="validateRow(elem.item.raw.uuid, elem.item.raw)" :color="secondary400">
							<v-radio
								id="delete_no_ahrefs_keywords"
								:label="$t('components.no')"
								value="0"
							/>
							<v-radio
								id="delete_yes_ahrefs_keywords"
								:label="$t('components.yes')"
								value="1"
							/>
						</v-radio-group>
					</div>
				</div>
			</template>
			
			<template #item.coupon="elem">
				<div v-if="isReadOnly">{{ elem.item.raw.coupon.value }}</div>
				<div v-if="!isReadOnly">
					<div :class="errorFieldColor(elem.item.raw.coupon.status)">
						<MTextField
							id="coupon"
							name="coupon"
							v-model="elem.item.raw.coupon.value"
							label=""
							@change="validateRow(elem.item.raw.uuid, elem.item.raw)"
						/>
					</div>
					<div v-if="isNotStatusOk(elem.item.raw.coupon.status)" :class="errorTextColor(elem.item.raw.coupon.status)">{{ elem.item.raw.coupon.message }}</div>
				</div>
			</template>
			
			<template #item.ahrefs_enabled="elem">
				<div v-if="isReadOnly">
					<div v-if="elem.item.raw.ahrefs_enabled.value === '0'">{{ $t('importExport.no') }}</div>
					<div v-if="elem.item.raw.ahrefs_enabled.value === '1'">{{ $t('importExport.yes') }}</div>
				</div>
				<div v-if="!isReadOnly">
					<div>
						<v-radio-group v-model="elem.item.raw.ahrefs_enabled.value" @change="validateRow(elem.item.raw.uuid, elem.item.raw)" :color="secondary400">
							<v-radio
								id="ahrefs_no"
								:label="$t('components.no')"
								value="0"
							/>
							<v-radio
								id="ahrefs_yes"
								:label="$t('components.yes')"
								value="1"
							/>
						</v-radio-group>
					</div>
				</div>
			</template>
			
			<template #item.is_blocked="elem">
				<div v-if="isReadOnly">
					<div v-if="elem.item.raw.is_blocked.value === '0'">{{ $t('importExport.no') }}</div>
					<div v-if="elem.item.raw.is_blocked.value === '1'">{{ $t('importExport.yes') }}</div>
				</div>
				<div v-if="!isReadOnly">
					<div>
						<v-radio-group v-model="elem.item.raw.is_blocked.value" @change="validateRow(elem.item.raw.uuid, elem.item.raw)" :color="secondary400">
							<v-radio
								id="blocked_no"
								:label="$t('components.no')"
								value="0"
							/>
							<v-radio
								id="blocked_yes"
								:label="$t('components.yes')"
								value="1"
							/>
						</v-radio-group>
					</div>
				</div>
			</template>
			
			<template #item.should_delete="elem">
				<div v-if="isReadOnly">
					<div v-if="elem.item.raw.should_delete.value === '0'">{{ $t('importExport.no') }}</div>
					<div v-if="elem.item.raw.should_delete.value === '1'">{{ $t('importExport.yes') }}</div>
				</div>
				<div v-if="!isReadOnly">
					<div>
						<v-radio-group v-model="elem.item.raw.should_delete.value" @change="validateRow(elem.item.raw.uuid, elem.item.raw)" :color="secondary400">
							<v-radio
								id="delete_no"
								:label="$t('components.no')"
								value="0"
							/>
							<v-radio
								id="delete_yes"
								:label="$t('components.yes')"
								value="1"
							/>
						</v-radio-group>
					</div>
				</div>
			</template>
			
			<template v-slot:footer.prepend>
				<div class="import-preview-table__btn-wrapper">
					<MButton
						:label="$t('importExport.back')"
						big
						blue400
						@click="goBack"
					/>
					<MButton
						v-if="!isReadOnly && (importStatus === VALIDATED || importStatus === PREVIEW_STATUS_ERROR)"
						icon="upload"
						width="20"
						height="20"
						:label="$t('importExport.validate')"
						big
						green400
						@click="validate(false)"
						:disabled="isWaitingForResponse"
					/>
					<MButton
						v-if="isReadOnly && (importStatus === PROCESSING || importStatus === PREVIEW_STATUS_ERROR)"
						icon="upload"
						width="20"
						height="20"
						:label="$t('importExport.validateAgain')"
						big
						green400
						@click="validate(true)"
						:disabled="isWaitingForResponse"
					/>
					<MButton
						v-if="!isReadOnly && importStatus === VALIDATED"
						icon="upload"
						width="20"
						height="20"
						:label="$t('importExport.save')"
						big
						red400
						@click="save"
						:disabled="isWaitingForResponse"
					/>
					<MButton
						v-if="isReadOnly && importStatus === READ_ONLY"
						icon="upload"
						width="20"
						height="20"
						:label="$t('importExport.import')"
						big
						red400
						@click="importAction"
						:disabled="isWaitingForResponse"
					/>
				</div>
			</template>
		</v-data-table-server>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { ref } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import {
	PREVIEW_STATUS_OK,
	PREVIEW_STATUS_ERROR,
	PREVIEW_STATUS_WARNING,
	VALIDATED,
	PREVIEW_PERCENT,
	PREVIEW_CASH,
	PROCESSING,
	READ_ONLY
} from '@/hooks/ImportExportHooks';
import WebsiteApi from '@/api/v1/WebsiteApi';
import ImportApi from '@/api/v1/ImportApi';
import LanguageApi from '@/api/v1/LanguageApi';
import SectionApi from '@/api/v1/SectionApi';
import CountryApi from '@/api/v1/CountryApi';
import variables from '@/assets/scss/modules/variables.module.scss';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { IImportPreviewItems, IItemImportPreview } from '@/models/ImportModel';
import { ROUTE_IMPORT_PREVIEW_READ_ONLY } from '@/hooks/RoutingHooks';
import type { ICategoriesList, ITopicsList } from '@/models/WebsiteModel';
import type { LanguageInterface } from '@/models/LanguageModel';
import type { CountryInterface } from '@/models/CountryModel';
import type { ISectionsList } from '@/models/SectionModel';
import MTextField from '@/components/atoms/MTextField.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MButton from '@/components/atoms/MButton.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';

const websiteApi = new WebsiteApi();
const importApi = new ImportApi();
const languageApi = new LanguageApi();
const countryApi = new CountryApi();
const sectionApi = new SectionApi();

const { t } = useI18n();
const { secondary400 } = variables;

const loading = ref<boolean>(false);
const isReadOnly = ref<boolean>(false);
const isRowsWithErrors = ref<boolean>(false);
const isWaitingForResponse = ref<boolean>(false);
const counter = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const total = ref<number>(0);
const importStatus = ref<string|null>(null);
const categoryList = ref<Array<ICategoriesList>>([]);
const forbiddenTopics = ref<Array<ITopicsList>>([]);
const anchorTypesList = ref<Array<any>>([
	{
		name: `${t('order.publication.brand')}`,
		id: 1,
	},
	{
		name: `${t('order.publication.generic')}`,
		id: 2,
	},
	{
		name: `${t('order.publication.exactMatch')}`,
		id: 3,
	},
]);
const languages = ref<Array<LanguageInterface>>([]);
const countries = ref<Array<CountryInterface>>([]);
const photoInArticleItems = ref<Array<string>>(['Optional', 'Mandatory', 'Lack']);
const sponsorshipDisclosure = ref<Array<string>>(['Discretion', 'Add sponsored', 'Not add sponsored', 'Other']);
const periodTime = ref<Array<string>>([
	'lifetime',
	'1 month',
	'2 months',
	'3 months',
	'4 months',
	'5 months',
	'6 months',
	'7 months',
	'8 months',
	'9 months',
	'10 months',
	'11 months',
	'12 months'
]);
const amountOfLinks = ref<Array<string>>(['1', '2', '3', '4', '5', '6', '7', '8', '9']);
const daysToWrite = ref<Array<string>>(['', '1', '2', '3', '4', '5']);
const currencyCodeList = ref<Array<string>>(['PLN', 'EUR', 'GBP', 'USD']);
const sectionName = ref<Array<ISectionsList>>([]);
const items = ref<Array<IImportPreviewItems>|any>([]);
const currentItems = ref<Array<IImportPreviewItems>|any>([]);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('importExport.order')}`,
		align: 'center',
		sortable: false,
		key: 'order',
		width: '60px',
	},
	{
		title: `${t('importExport.operationStatus')}`,
		align: 'center',
		sortable: false,
		key: 'result_status',
		width: '80px',
	},
	{
		title: `${t('importExport.rowUuid')}`,
		align: 'center',
		sortable: false,
		key: 'uuid',
		width: '80px',
	},
	{
		title: `${t('importExport.operation')}`,
		align: 'center',
		sortable: false,
		key: 'operation',
		width: '120px',
	},
	{
		title: `${t('importExport.website_uuid')}`,
		align: 'center',
		sortable: false,
		key: 'website_uuid',
		width: '250px',
	},
	{
		title: `${t('importExport.offer_uuid')}`,
		align: 'center',
		sortable: false,
		key: 'offer_uuid',
		width: '250px',
	},
	{
		title: `${t('importExport.user_email')}`,
		align: 'center',
		sortable: false,
		key: 'user_email',
		width: '250px',
	},
	{
		title: `${t('importExport.domain_url')}`,
		align: 'center',
		sortable: false,
		key: 'domain_url',
		width: '250px',
	},
	{
		title: `${t('importExport.domain_name')}`,
		align: 'center',
		sortable: false,
		key: 'domain_name',
		width: '250px',
	},
	{
		title: `${t('importExport.language')}`,
		align: 'center',
		sortable: false,
		key: 'language',
		width: '250px',
	},
	{
		title: `${t('importExport.country')}`,
		align: 'center',
		sortable: false,
		key: 'country',
		width: '250px',
	},
	{
		title: `${t('importExport.short_description')}`,
		align: 'center',
		sortable: false,
		key: 'short_description',
		width: '250px',
	},
	{
		title: `${t('importExport.category')}`,
		align: 'center',
		sortable: false,
		key: 'category',
		width: '320px',
	},
	{
		title: `${t('importExport.additional_category')}`,
		align: 'center',
		sortable: false,
		key: 'additional_category',
		width: '320px',
	},
	{
		title: `${t('importExport.forbidden_topics')}`,
		align: 'center',
		sortable: false,
		key: 'forbidden_topics',
		width: '340px',
	},
	{
		title: `${t('importExport.photo_in_article')}`,
		align: 'center',
		sortable: false,
		key: 'photo_in_article',
		width: '250px',
	},
	{
		title: `${t('importExport.other_requirements')}`,
		align: 'center',
		sortable: false,
		key: 'other_requirements',
		width: '200px',
	},
	{
		title: `${t('importExport.section_name')}`,
		align: 'center',
		sortable: false,
		key: 'section_name',
		width: '250px',
	},
	{
		title: `${t('importExport.section_url')}`,
		align: 'center',
		sortable: false,
		key: 'offer_url',
		width: '250px',
	},
	{
		title: `${t('importExport.offer_name')}`,
		align: 'center',
		sortable: false,
		key: 'offer_name',
		width: '250px',
	},
	{
		title: `${t('importExport.sponsorship_disclosure')}`,
		align: 'center',
		sortable: false,
		key: 'sponsorship_disclosure',
		width: '300px',
	},
	{
		title: `${t('importExport.other_disclosure')}`,
		align: 'center',
		sortable: false,
		key: 'sponsorship_disclosure_other',
		width: '200px',
	},
	{
		title: `${t('importExport.publication_period')}`,
		align: 'center',
		sortable: false,
		key: 'publication_period',
		width: '250px',
	},
	{
		title: `${t('importExport.anchor_types')}`,
		align: 'center',
		sortable: false,
		key: 'anchor_types',
		width: '300px',
	},
	{
		title: `${t('importExport.dofollow_links')}`,
		align: 'center',
		sortable: false,
		key: 'dofollow_links',
		width: '100px',
	},
	{
		title: `${t('importExport.amount_of_links')}`,
		align: 'center',
		sortable: false,
		key: 'amount_of_links',
		width: '150px',
	},
	{
		title: `${t('importExport.offer_contain_article')}`,
		align: 'center',
		sortable: false,
		key: 'offer_contain_article',
		width: '100px',
	},
	{
		title: `${t('importExport.days_to_write_article')}`,
		align: 'center',
		sortable: false,
		key: 'days_to_write_article',
		width: '150px',
	},
	{
		title: `${t('importExport.add_includes_banners')}`,
		align: 'center',
		sortable: false,
		key: 'add_includes_banners',
		width: '100px',
	},
	{
		title: `${t('importExport.social_media_facebook')}`,
		align: 'center',
		sortable: false,
		key: 'promo_facebook',
		width: '120px',
	},
	{
		title: `${t('importExport.social_media_twitter')}`,
		align: 'center',
		sortable: false,
		key: 'promo_twitter',
		width: '120px',
	},
	{
		title: `${t('importExport.social_media_newsletter')}`,
		align: 'center',
		sortable: false,
		key: 'promo_newsletter',
		width: '120px',
	},
	{
		title: `${t('importExport.price')}`,
		align: 'center',
		sortable: false,
		key: 'price',
		width: '160px',
	},
	{
		title: `${t('importExport.offer_description')}`,
		align: 'center',
		sortable: false,
		key: 'offer_description',
		width: '450px',
	},
	{
		title: `${t('importExport.sample_offer_url')}`,
		align: 'center',
		sortable: false,
		key: 'sample_offer_url',
		width: '320px',
	},
	{
		title: `${t('importExport.seo_stats_ur')}`,
		align: 'center',
		sortable: false,
		key: 'seo_stats_ur',
		width: '160px',
	},
	{
		title: `${t('importExport.seo_stats_dr')}`,
		align: 'center',
		sortable: false,
		key: 'seo_stats_dr',
		width: '160px',
	},
	{
		title: `${t('importExport.seo_stats_rd')}`,
		align: 'center',
		sortable: false,
		key: 'seo_stats_rd',
		width: '160px',
	},
	{
		title: `${t('importExport.seo_stats_ot')}`,
		align: 'center',
		sortable: false,
		key: 'seo_stats_ot',
		width: '160px',
	},
	{
		title: `${t('importExport.seo_stats_pt')}`,
		align: 'center',
		sortable: false,
		key: 'seo_stats_pt',
		width: '160px',
	},
	{
		title: `${t('importExport.seo_stats_oc')}`,
		align: 'center',
		sortable: false,
		key: 'seo_stats_oc',
		width: '160px',
	},
	{
		title: `${t('importExport.seo_stats_pc')}`,
		align: 'center',
		sortable: false,
		key: 'seo_stats_pc',
		width: '160px',
	},
	{
		title: `${t('importExport.seo_stats_tf')}`,
		align: 'center',
		sortable: false,
		key: 'seo_stats_tf',
		width: '160px',
	},
	{
		title: `${t('importExport.seo_stats_bl')}`,
		align: 'center',
		sortable: false,
		key: 'seo_stats_bl',
		width: '160px',
	},
	{
		title: `${t('importExport.seo_stats_blat')}`,
		align: 'center',
		sortable: false,
		key: 'seo_stats_blat',
		width: '160px',
	},
	{
		title: `${t('importExport.seo_stats_rdat')}`,
		align: 'center',
		sortable: false,
		key: 'seo_stats_rdat',
		width: '160px',
	},
	{
		title: `${t('importExport.seo_stats_blrd')}`,
		align: 'center',
		sortable: false,
		key: 'seo_stats_blrd',
		width: '160px',
	},
	{
		title: `${t('importExport.discount_type')}`,
		align: 'center',
		sortable: false,
		key: 'discount_type',
		width: '170px',
	},
	{
		title: `${t('importExport.discount_value')}`,
		align: 'center',
		sortable: false,
		key: 'discount_value',
		width: '160px',
	},
	{
		title: `${t('importExport.share_type')}`,
		align: 'center',
		sortable: false,
		key: 'share_type',
		width: '170px',
	},
	{
		title: `${t('importExport.share_value')}`,
		align: 'center',
		sortable: false,
		key: 'share_value',
		width: '160px',
	},
	{
		title: `${t('importExport.currency_code')}`,
		align: 'center',
		sortable: false,
		key: 'currency_code',
		width: '150px',
	},
	{
		title: `${t('importExport.is_allowed_promotion_bookmakers')}`,
		align: 'center',
		sortable: false,
		key: 'is_allowed_promotion_bookmakers',
		width: '120px',
	},
	{
		title: `${t('importExport.is_allowed_promotion_cbd')}`,
		align: 'center',
		sortable: false,
		key: 'is_allowed_promotion_cbd',
		width: '120px',
	},
	{
		title: `${t('importExport.is_allowed_promotion_loans')}`,
		align: 'center',
		sortable: false,
		key: 'is_allowed_promotion_loans',
		width: '120px',
	},
	{
		title: `${t('importExport.daysPromotionOnMainPage')}`,
		align: 'center',
		sortable: false,
		key: 'days_promotion_on_main_page',
		width: '150px',
	},
	{
		title: `${t('importExport.isTrusted')}`,
		align: 'center',
		sortable: false,
		key: 'is_trusted',
		width: '120px',
	},
	{
		title: `${t('importExport.fastDelivery')}`,
		align: 'center',
		sortable: false,
		key: 'fast_delivery',
		width: '120px',
	},
	{
		title: `${t('importExport.group1')}`,
		align: 'center',
		sortable: false,
		key: 'group_share_1',
		width: '150px',
	},
	{
		title: `${t('importExport.group2')}`,
		align: 'center',
		sortable: false,
		key: 'group_share_2',
		width: '150px',
	},
	{
		title: `${t('importExport.group3')}`,
		align: 'center',
		sortable: false,
		key: 'group_share_3',
		width: '150px',
	},
	{
		title: `${t('importExport.group4')}`,
		align: 'center',
		sortable: false,
		key: 'group_share_4',
		width: '150px',
	},
	{
		title: `${t('importExport.group5')}`,
		align: 'center',
		sortable: false,
		key: 'group_share_5',
		width: '150px',
	},
	{
		title: `${t('importExport.ahrefsEnabledKeywords')}`,
		align: 'center',
		sortable: false,
		key: 'ahrefs_enabled_keywords',
		width: '150px',
	},
	{
		title: `${t('importExport.couponCode')}`,
		align: 'center',
		sortable: false,
		key: 'coupon',
		width: '150px',
	},
	{
		title: `${t('importExport.ahrefsEnabled')}`,
		align: 'center',
		sortable: false,
		key: 'ahrefs_enabled',
		width: '120px',
	},
	{
		title: `${t('importExport.isBlocked')}`,
		align: 'center',
		sortable: false,
		key: 'is_blocked',
		width: '120px',
	},
	{
		title: `${t('importExport.delete')}`,
		align: 'center',
		sortable: false,
		key: 'should_delete',
		width: '120px',
	},
]);

if (ROUTE_IMPORT_PREVIEW_READ_ONLY === router.currentRoute.value.name) {
	isReadOnly.value = true;
}

const languagesList = async() => {
	try {
		const result = await languageApi.getLanguages();
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.errorGetLanguages')}`);
			return;
		}
		languages.value = result.payload.data;
	} catch (e) {
		toast.error(`${t('importExport.toast.errorGetLanguages')}`);
		return;
	}
};
languagesList();
const countriesList = async() => {
	try {
		const result = await countryApi.getCountries();
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.errorGetCountries')}`);
			return;
		}
		countries.value = result.payload.data;
	} catch (e) {
		toast.error(`${t('importExport.toast.errorGetCountries')}`);
		return;
	}
};
countriesList();
const categoriesList = async() => {
	try {
		const result = await websiteApi.categoriesList();
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.errorGetCategories')}`);
			return;
		}
		categoryList.value = result.payload;
	} catch (e) {
		toast.error(`${t('importExport.toast.errorGetCategories')}`);
		return;
	}
};
categoriesList();
const forbiddenTopicsList = async() => {
	try {
		const result = await websiteApi.forbiddenTopicsList();
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.errorGetForbiddenTopics')}`);
			return;
		}
		forbiddenTopics.value = result.payload;
	} catch (e) {
		toast.error(`${t('importExport.toast.errorGetForbiddenTopics')}`);
		return;
	}
};
forbiddenTopicsList();
const sectionNameList = async() => {
	try {
		const result = await sectionApi.sectionsNameList();
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.errorGetSectionList')}`);
			return;
		}
		sectionName.value = result.payload;
	} catch (e) {
		toast.error(`${t('importExport.toast.errorGetSectionList')}`);
		return;
	}
};
sectionNameList();
const importedItemsStatus = async() => {
	try {
		const result = await importApi.importedPreviewItemsStatus(router.currentRoute.value.params.importUuid);
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.errorImportStatus')}`);
			return;
		}
		importStatus.value = result.payload.status;
		counter.value = result.payload.error_lines_count;
	} catch (e) {
		toast.error(`${t('importExport.toast.errorImportStatus')}`);
		return;
	}
};
importedItemsStatus();
const importedItemsList = async(isErrorRows:boolean) => {
	loading.value = true;
	try {
		const result = await importApi.importedPreviewItemsList(router.currentRoute.value.params.importUuid, options.value.page, options.value.itemsPerPage, !!isErrorRows);
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.errorImportItems')}`);
			loading.value = false;
			return;
		}
		currentPage.value = result.payload.current_page;
		lastPage.value = result.payload.last_page;
		total.value = result.payload.total;
		const itemsArr:any = [];
		result.payload.data.forEach((elem:any) => {
			if (null != elem.result) {
				itemsArr.push(elem.result);
				// Zamiana stringu na arrayke dla forbidden topicsow
				if (null != elem.result['forbidden_topics']?.value) {
					const forbiddenTopicsString: any = elem.result['forbidden_topics']?.value;
					const forbiddenTopicsArray: any = [];
					const arrayFromSingle = forbiddenTopicsString.includes(',') ? forbiddenTopicsString.split(',').map((item:any) => item.trim()) : [forbiddenTopicsString.trim()];
					arrayFromSingle.forEach((elem:any) => {
						forbiddenTopicsArray.push(elem);
					});
					itemsArr[0]['forbidden_topics'].value = forbiddenTopicsArray;
				}
			} else if (null == elem.result && null != elem.json && 0 !== elem.json.length) {
				const newJson:any = {};
				for (const key in elem.json) {
					if (Object.prototype.hasOwnProperty.call(elem.json, key)) {
						if ('category' === key || 'additional_category' === key) {
							if (null != elem.json[key]) {
								const categoryString:any = elem.json[key];
								const categoryArray:any = [];
								const arrayFromSingle = categoryString.includes(',') ? categoryString.split(',').map((item:any) => item.trim()) : [categoryString.trim()];
								arrayFromSingle.forEach((elem:any) => {
									const categoryId = categoryList.value.find(elems => elems.name === elem);
									if (null != categoryId && null != categoryId.id) {
										categoryArray.push(categoryId.id);
									}
								});
								newJson[key] = {
									message: '',
									status: 'ok',
									value: categoryArray,
								};
							} else {
								newJson[key] = {
									message: '',
									status: 'ok',
									value: null,
								};
							}
						} else if ('forbidden_topics' === key) {
							if (null != elem.json[key]) {
								const forbiddenTopicsString:any = elem.json[key];
								const forbiddenTopicsArray:any = [];
								const arrayFromSingle = forbiddenTopicsString.includes(',') ? forbiddenTopicsString.split(',').map((item:any) => item.trim()) : [forbiddenTopicsString.trim()];
								arrayFromSingle.forEach((elem:any) => {
									forbiddenTopicsArray.push(elem);
								});
								newJson[key] = {
									message: '',
									status: 'ok',
									value: forbiddenTopicsArray,
								};
							} else {
								newJson[key] = {
									message: '',
									status: 'ok',
									value: null,
								};
							}
						} else if ('anchor_types' === key) {
							if (null != elem.json[key]) {
								const anchorString:any = elem.json[key];
								const anchorArray:any = [];
								const arrayFromSingle = anchorString.includes(',') ? anchorString.split(',').map((item:any) => item.trim()) : [anchorString.trim()];
								arrayFromSingle.forEach((elem:any) => {
									const anchorId = anchorTypesList.value.find((elems:any) => 'branded' === elems.name || 'mixed' === elems.name || 'all' === elems.name);
									if (null != anchorId && null != anchorId.id) {
										anchorArray.push(anchorId.id);
									}
								});
								newJson[key] = {
									message: '',
									status: 'ok',
									value: anchorArray,
								};
							} else {
								newJson[key] = {
									message: '',
									status: 'ok',
									value: null,
								};
							}
						} else if ('other_requirements' === key) {
							newJson[key] = elem.json[key];
						} else {
							newJson[key] = {
								message: '',
								status: 'ok',
								value: elem.json[key],
							};
						}
					}
				}
				itemsArr.push(newJson);
			} else {
				itemsArr.push({
					add_includes_banners: {
						status: 'error',
						value: null,
						message: null
					},
					additional_category: {
						status: 'ok',
						value: null,
						message: null
					},
					amount_of_links: {
						status: 'ok',
						value: null,
						message: null
					},
					category: {
						status: 'error',
						value: null,
						message: null
					},
					country: {
						status: 'error',
						value: null,
						message: null
					},
					currency_code: {
						status: 'error',
						value: null,
						message: null
					},
					days_to_write_article: {
						status: 'ok',
						value: null,
						message: null
					},
					discount_type: {
						status: 'ok',
						value: null,
						message: null
					},
					discount_value: {
						status: 'ok',
						value: null,
						message: null
					},
					dofollow_links: {
						status: 'error',
						value: null,
						message: null
					},
					domain_name: {
						status: 'error',
						value: null,
						message: null
					},
					domain_url: {
						status: 'error',
						value: null,
						message: null
					},
					forbidden_topics: {
						status: 'ok',
						value: null,
						message: null
					},
					is_allowed_promotion_bookmakers: {
						status: 'error',
						value: null,
						message: null
					},
					is_allowed_promotion_cbd: {
						status: 'error',
						value: null,
						message: null
					},
					is_allowed_promotion_loans: {
						status: 'error',
						value: null,
						message: null
					},
					language: {
						status: 'error',
						value: null,
						message: null
					},
					offer_contain_article: {
						status: 'error',
						value: null,
						message: null
					},
					offer_description: {
						status: 'error',
						value: null,
						message: null
					},
					offer_name: {
						status: 'error',
						value: null,
						message: null
					},
					offer_uuid: {
						status: 'error',
						value: null,
						message: null
					},
					photo_in_article: {
						status: 'error',
						value: null,
						message: null
					},
					price: {
						status: 'error',
						value: null,
						message: null
					},
					publication_period: {
						status: 'error',
						value: null,
						message: null
					},
					anchor_types: {
						status: 'error',
						value: null,
						message: null
					},
					sample_offer_url: {
						status: 'error',
						value: null,
						message: null
					},
					section_name: {
						status: 'error',
						value: null,
						message: null
					},
					domain_rating: {
						status: 'ok',
						value: null,
						message: null
					},
					org_traffic: {
						status: 'ok',
						value: null,
						message: null
					},
					ref_domains: {
						status: 'ok',
						value: null,
						message: null
					},
					backlinks_live_refdomains: {
						status: 'ok',
						value: null,
						message: null
					},
					url_rating: {
						status: 'ok',
						value: null,
						message: null
					},
					paid_traffic: {
						status: 'ok',
						value: null,
						message: null
					},
					paid_cost: {
						status: 'ok',
						value: null,
						message: null
					},
					org_cost: {
						status: 'ok',
						value: null,
						message: null
					},
					backlinks_live: {
						status: 'ok',
						value: null,
						message: null
					},
					backlinks_all_time: {
						status: 'ok',
						value: null,
						message: null
					},
					backlinks_all_time_refdomains: {
						status: 'ok',
						value: null,
						message: null
					},
					trust_flow: {
						status: 'ok',
						value: null,
						message: null
					},
					share_type: {
						status: 'ok',
						value: null,
						message: null
					},
					share_value: {
						status: 'ok',
						value: null,
						message: null
					},
					short_description: {
						status: 'ok',
						value: null,
						message: null
					},
					promo_facebook: {
						status: 'error',
						value: null,
						message: null
					},
					promo_twitter: {
						status: 'error',
						value: null,
						message: null
					},
					promo_newsletter: {
						status: 'error',
						value: null,
						message: null
					},
					sponsorship_disclosure: {
						status: 'error',
						value: null,
						message: null
					},
					sponsorship_disclosure_other: {
						status: 'ok',
						value: null,
						message: null
					},
					user_email: {
						status: 'error',
						value: null,
						message: null
					},
					website_uuid: {
						status: 'error',
						value: null,
						message: null
					},
					offer_url: {
						status: 'error',
						value: null,
						message: null
					},
					is_trusted: {
						status: 'error',
						value: null,
						message: null
					},
					days_promotion_on_main_page: {
						status: 'error',
						value: null,
						message: null
					},
					fast_delivery: {
						status: 'ok',
						value: null,
						message: null
					},
					group_share_1: {
						status: 'ok',
						value: null,
						message: null
					},
					group_share_2: {
						status: 'ok',
						value: null,
						message: null
					},
					group_share_3: {
						status: 'ok',
						value: null,
						message: null
					},
					group_share_4: {
						status: 'ok',
						value: null,
						message: null
					},
					group_share_5: {
						status: 'ok',
						value: null,
						message: null
					},
					result_status: {
						status: 'ok',
						value: null,
						message: null
					},
					uuid: {
						status: 'error',
						value: null,
						message: null
					},
					ahrefs_enabled: {
						status: 'ok',
						value: null,
						message: null
					},
					ahrefs_enabled_keywords: {
						status: 'ok',
						value: null,
						message: null
					},
					is_blocked: {
						status: 'ok',
						value: null,
						message: null
					},
					coupon: {
						status: 'ok',
						value: null,
						message: null
					},
					should_delete: {
						status: 'ok',
						value: null,
						message: null
					},
					order: {
						status: 'ok',
						value: null,
						message: null
					},
					other_requirements: ''
				});
			}
		});
		items.value = itemsArr;
		for (let i = 0; i < result.payload.data.length; i++) {
			itemsArr[i].uuid = result.payload.data[i].uuid;
			itemsArr[i].result_status = result.payload.data[i].result_status;
			itemsArr[i].status = result.payload.data[i].status;
			itemsArr[i].message = result.payload.data[i].message;
			itemsArr[i].order = result.payload.data[i].order;
		}
		currentItems.value = items.value;
		
		if (!isReadOnly.value) {
			items.value.forEach((elem:any) => {
				if ('' === elem.share_value.value) {
					elem.share_value.value = '0';
				}
			});
		}
	} catch (e) {
		toast.error(`${t('importExport.toast.errorImportItems')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	await importedItemsList(false);
};

const filterErrorRows = async() => {
	isRowsWithErrors.value = !isRowsWithErrors.value;
	await importedItemsList(isRowsWithErrors.value);
};

const validateRow = async(rowUuid:string, item:IItemImportPreview) => {
	loading.value = true;
	
	const categoryStringArray:Array<string> = [];
	let categoryString:string = '';
	if (null != item.category && null != item.category.value && 0 < item.category.value.length && item.category.value instanceof Array) {
		item.category.value.forEach(elem => {
			const categoryName = categoryList.value.find(elems => elems.id === elem);
			if (null != categoryName && null != categoryName.name) {
				categoryStringArray.push(categoryName.name);
			}
		});
		categoryStringArray.forEach((elem:string) => {
			if (0 === categoryStringArray.indexOf(elem)) {
				categoryString += elem;
			} else {
				categoryString += `, ${elem}`;
			}
		});
	}
	const additionalCategoryStringArray:Array<string> = [];
	let additionalCategoryString:string = '';
	if (null != item.additional_category && null != item.additional_category.value && 0 < item.additional_category.value.length && item.additional_category.value instanceof Array) {
		item.additional_category.value.forEach((elem) => {
			const categoryName = categoryList.value.find(elems => elems.id === elem);
			if (null != categoryName && null != categoryName.name) {
				additionalCategoryStringArray.push(categoryName.name);
			}
		});
		additionalCategoryStringArray.forEach((elem:string) => {
			if (0 === additionalCategoryStringArray.indexOf(elem)) {
				additionalCategoryString += elem;
			} else {
				additionalCategoryString += `, ${elem}`;
			}
		});
	}
	let forbiddenTopicsString:any = '';
	if (null != item.forbidden_topics && null != item.forbidden_topics.value && item.forbidden_topics.value instanceof Array) {
		const forbiddenTopicsStringArray:any = item.forbidden_topics.value;
		item.forbidden_topics.value.forEach((elem:any) => {
			if (null != item.forbidden_topics && null != item.forbidden_topics.value && 0 === forbiddenTopicsStringArray.indexOf(elem)) {
				forbiddenTopicsString += elem;
			} else {
				forbiddenTopicsString += `, ${elem}`;
			}
		});
	} else {
		if (null != item.forbidden_topics) {
			forbiddenTopicsString = item.forbidden_topics.value;
		} else {
			forbiddenTopicsString = null;
		}
	}
	const anchorTypesStringArray:Array<string> = [];
	let anchorTypesString:string = '';
	if (null != item.anchor_types && null != item.anchor_types.value && 0 < item.anchor_types.value.length && item.anchor_types.value instanceof Array) {
		item.anchor_types.value.forEach(elem => {
			const anchorId = anchorTypesList.value.find(elems => elems.id === elem);
			if (null != anchorId && null != anchorId.id && 1 === anchorId.id) {
				anchorTypesStringArray.push('branded');
			} else if (null != anchorId && null != anchorId.id && 2 === anchorId.id) {
				anchorTypesStringArray.push('mixed');
			} else if (null != anchorId && null != anchorId.id && 3 === anchorId.id) {
				anchorTypesStringArray.push('all');
			}
		});
		anchorTypesStringArray.forEach((elem:string) => {
			if (0 === anchorTypesStringArray.indexOf(elem)) {
				anchorTypesString += elem;
			} else {
				anchorTypesString += `, ${elem}`;
			}
		});
	}
	
	const editForm:any = {
		add_includes_banners: null != item.add_includes_banners ? item.add_includes_banners.value : null,
		additional_category: additionalCategoryString,
		amount_of_links: null != item.amount_of_links ? item.amount_of_links.value : null,
		category: categoryString,
		country: null != item.country ? item.country.value : null,
		currency_code: null != item.currency_code ? item.currency_code.value : null,
		days_to_write_article: null != item.days_to_write_article ? item.days_to_write_article.value : null,
		discount_type: null != item.discount_type ? item.discount_type.value : null,
		discount_value: null != item.discount_value ? item.discount_value.value : null,
		dofollow_links: null != item.dofollow_links ? item.dofollow_links.value : null,
		domain_name: null != item.domain_name ? item.domain_name.value : null,
		domain_url: null != item.domain_url ? item.domain_url.value : null,
		forbidden_topics: forbiddenTopicsString,
		is_allowed_promotion_bookmakers: null != item.is_allowed_promotion_bookmakers ? item.is_allowed_promotion_bookmakers.value : null,
		is_allowed_promotion_cbd: null != item.is_allowed_promotion_cbd ? item.is_allowed_promotion_cbd.value : null,
		is_allowed_promotion_loans: null != item.is_allowed_promotion_loans ? item.is_allowed_promotion_loans.value : null,
		language: null != item.language ? item.language.value : null,
		offer_contain_article: null != item.offer_contain_article ? item.offer_contain_article.value : null,
		offer_description: null != item.offer_description ? item.offer_description.value : null,
		offer_name: null != item.offer_name ? item.offer_name.value : null,
		offer_uuid: null != item.offer_uuid ? item.offer_uuid.value : null,
		photo_in_article: null != item.photo_in_article ? item.photo_in_article.value : null,
		price: null != item.price ? item.price.value : null,
		publication_period: null != item.publication_period ? item.publication_period.value : null,
		anchor_types: anchorTypesString,
		sample_offer_url: null != item.sample_offer_url ? item.sample_offer_url.value : null,
		section_name: null != item.section_name ? item.section_name.value : null,
		domain_rating: null != item.domain_rating ? item.domain_rating.value : null,
		org_traffic: null != item.org_traffic ? item.org_traffic.value : null,
		ref_domains: null != item.ref_domains ? item.ref_domains.value : null,
		backlinks_live_refdomains: null != item.backlinks_live_refdomains ? item.backlinks_live_refdomains.value : null,
		url_rating: null != item.url_rating ? item.url_rating.value : null,
		paid_traffic: null != item.paid_traffic ? item.paid_traffic.value : null,
		paid_cost: null != item.paid_cost ? item.paid_cost.value : null,
		org_cost: null != item.org_cost ? item.org_cost.value : null,
		backlinks_live: null != item.backlinks_live ? item.backlinks_live.value : null,
		backlinks_all_time: null != item.backlinks_all_time ? item.backlinks_all_time.value : null,
		backlinks_all_time_refdomains: null != item.backlinks_all_time_refdomains ? item.backlinks_all_time_refdomains.value : null,
		trust_flow: null != item.trust_flow ? item.trust_flow.value : null,
		share_type: null != item.share_type ? item.share_type.value : null,
		share_value: null != item.share_value ? item.share_value.value : null,
		short_description: null != item.short_description ? item.short_description.value : null,
		promo_facebook: null != item.promo_facebook ? item.promo_facebook.value : null,
		promo_twitter: null != item.promo_twitter ? item.promo_twitter.value : null,
		promo_newsletter: null != item.promo_newsletter ? item.promo_newsletter.value : null,
		sponsorship_disclosure: null != item.sponsorship_disclosure ? item.sponsorship_disclosure.value : null,
		sponsorship_disclosure_other: null != item.sponsorship_disclosure_other ? item.sponsorship_disclosure_other.value : null,
		user_email: null != item.user_email ? item.user_email.value : null,
		website_uuid: null != item.website_uuid ? item.website_uuid.value : null,
		offer_url: null != item.offer_url ? item.offer_url.value : null,
		is_trusted: null != item.is_trusted ? item.is_trusted.value : null,
		days_promotion_on_main_page: null != item.days_promotion_on_main_page ? item.days_promotion_on_main_page.value : null,
		fast_delivery: null != item.fast_delivery ? item.fast_delivery.value : null,
		group_share_1: null != item.group_share_1 ? item.group_share_1.value : null,
		group_share_2: null != item.group_share_2 ? item.group_share_2.value : null,
		group_share_3: null != item.group_share_3 ? item.group_share_3.value : null,
		group_share_4: null != item.group_share_4 ? item.group_share_4.value : null,
		group_share_5: null != item.group_share_5 ? item.group_share_5.value : null,
		other_requirements: item.other_requirements,
		should_delete: null != item.should_delete ? item.should_delete.value : null,
		ahrefs_enabled: null != item.ahrefs_enabled ? item.ahrefs_enabled.value : null,
		ahrefs_enabled_keywords: null != item.ahrefs_enabled_keywords ? item.ahrefs_enabled_keywords.value : null,
		is_blocked: null != item.is_blocked ? item.is_blocked.value : null,
		coupon: null != item.coupon ? item.coupon.value : null,
	};
	try {
		const result = await importApi.editOneRow(router.currentRoute.value.params.importUuid, rowUuid, editForm);
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.errorEditRow')}`);
			loading.value = false;
			return;
		}
		let itemToChange:any = {};
		if (null != result.payload.result) {
			itemToChange = result.payload.result;
		} else if (null == result.payload.result && null != result.payload.json && 0 !== result.payload.json.length) {
			for (const key in result.payload.json) {
				if (Object.prototype.hasOwnProperty.call(result.payload.json, key)) {
					if ('category' === key || 'additional_category' === key) {
						if (null != result.payload.json[key]) {
							const categoryString:any = result.payload.json[key];
							const categoryArray:any = [];
							const arrayFromSingle = categoryString.includes(',') ? categoryString.split(',').map((item:any) => item.trim()) : [categoryString.trim()];
							arrayFromSingle.forEach((elem:any) => {
								const categoryId = categoryList.value.find(elems => elems.name === elem);
								if (null != categoryId && null != categoryId.id) {
									categoryArray.push(categoryId.id);
								}
							});
							itemToChange[key] = {
								message: '',
								status: 'ok',
								value: categoryArray,
							};
						} else {
							itemToChange[key] = {
								message: '',
								status: 'ok',
								value: null,
							};
						}
					} else if ('forbidden_topics' === key) {
						if (null != result.payload.json[key]) {
							const forbiddenTopicsString:any = result.payload.json[key];
							const forbiddenTopicsArray:any = [];
							const arrayFromSingle = forbiddenTopicsString.includes(',') ? forbiddenTopicsString.split(',').map((item:any) => item.trim()) : [forbiddenTopicsString.trim()];
							arrayFromSingle.forEach((elem:any) => {
								forbiddenTopicsArray.push(elem);
							});
							itemToChange[key] = {
								message: '',
								status: 'ok',
								value: forbiddenTopicsArray,
							};
						} else {
							itemToChange[key] = {
								message: '',
								status: 'ok',
								value: null,
							};
						}
					} else if ('anchor_types' === key) {
						if (null != result.payload.json[key]) {
							const anchorString: any = result.payload.json[key];
							const anchorArray: any = [];
							const arrayFromSingle = anchorString.includes(',') ? anchorString.split(',').map((item:any) => item.trim()) : [anchorString.trim()];
							arrayFromSingle.forEach((elem:any) => {
								const anchorId = anchorTypesList.value.find(elems => 'branded' === elems.name || 'mixed' === elems.name || 'all' === elems.name);
								if (null != anchorId && null != anchorId.id) {
									anchorArray.push(anchorId.id);
								}
							});
							itemToChange[key] = {
								message: '',
								status: 'ok',
								value: anchorArray,
							};
						} else {
							itemToChange[key] = {
								message: '',
								status: 'ok',
								value: null,
							};
						}
					} else {
						itemToChange[key] = {
							message: '',
							status: 'ok',
							value: result.payload.json[key],
						};
					}
				}
			}
		} else {
			itemToChange = {
				add_includes_banners: {
					status: 'error',
					value: null,
					message: null
				},
				additional_category: {
					status: 'ok',
					value: null,
					message: null
				},
				amount_of_links: {
					status: 'error',
					value: null,
					message: null
				},
				category: {
					status: 'error',
					value: null,
					message: null
				},
				country: {
					status: 'error',
					value: null,
					message: null
				},
				currency_code: {
					status: 'error',
					value: null,
					message: null
				},
				days_to_write_article: {
					status: 'error',
					value: null,
					message: null
				},
				discount_type: {
					status: 'ok',
					value: null,
					message: null
				},
				discount_value: {
					status: 'ok',
					value: null,
					message: null
				},
				dofollow_links: {
					status: 'error',
					value: null,
					message: null
				},
				domain_name: {
					status: 'error',
					value: null,
					message: null
				},
				domain_url: {
					status: 'error',
					value: null,
					message: null
				},
				forbidden_topics: {
					status: 'ok',
					value: null,
					message: null
				},
				is_allowed_promotion_bookmakers: {
					status: 'error',
					value: null,
					message: null
				},
				is_allowed_promotion_cbd: {
					status: 'error',
					value: null,
					message: null
				},
				is_allowed_promotion_loans: {
					status: 'error',
					value: null,
					message: null
				},
				language: {
					status: 'error',
					value: null,
					message: null
				},
				offer_contain_article: {
					status: 'error',
					value: null,
					message: null
				},
				offer_description: {
					status: 'error',
					value: null,
					message: null
				},
				offer_name: {
					status: 'error',
					value: null,
					message: null
				},
				offer_uuid: {
					status: 'error',
					value: null,
					message: null
				},
				photo_in_article: {
					status: 'error',
					value: null,
					message: null
				},
				price: {
					status: 'error',
					value: null,
					message: null
				},
				publication_period: {
					status: 'error',
					value: null,
					message: null
				},
				anchor_types: {
					status: 'error',
					value: null,
					message: null
				},
				sample_offer_url: {
					status: 'error',
					value: null,
					message: null
				},
				section_name: {
					status: 'error',
					value: null,
					message: null
				},
				domain_rating: {
					status: 'ok',
					value: null,
					message: null
				},
				org_traffic: {
					status: 'ok',
					value: null,
					message: null
				},
				ref_domains: {
					status: 'ok',
					value: null,
					message: null
				},
				backlinks_live_refdomains: {
					status: 'ok',
					value: null,
					message: null
				},
				url_rating: {
					status: 'ok',
					value: null,
					message: null
				},
				paid_traffic: {
					status: 'ok',
					value: null,
					message: null
				},
				paid_cost: {
					status: 'ok',
					value: null,
					message: null
				},
				org_cost: {
					status: 'ok',
					value: null,
					message: null
				},
				backlinks_live: {
					status: 'ok',
					value: null,
					message: null
				},
				backlinks_all_time: {
					status: 'ok',
					value: null,
					message: null
				},
				backlinks_all_time_refdomains: {
					status: 'ok',
					value: null,
					message: null
				},
				trust_flow: {
					status: 'ok',
					value: null,
					message: null
				},
				share_type: {
					status: 'ok',
					value: null,
					message: null
				},
				share_value: {
					status: 'ok',
					value: null,
					message: null
				},
				short_description: {
					status: 'ok',
					value: null,
					message: null
				},
				promo_facebook: {
					status: 'error',
					value: null,
					message: null
				},
				promo_twitter: {
					status: 'error',
					value: null,
					message: null
				},
				promo_newsletter: {
					status: 'error',
					value: null,
					message: null
				},
				sponsorship_disclosure: {
					status: 'error',
					value: null,
					message: null
				},
				sponsorship_disclosure_other: {
					status: 'ok',
					value: null,
					message: null
				},
				user_email: {
					status: 'error',
					value: null,
					message: null
				},
				website_uuid: {
					status: 'error',
					value: null,
					message: null
				},
				offer_url: {
					status: 'error',
					value: null,
					message: null
				},
				is_trusted: {
					status: 'error',
					value: null,
					message: null
				},
				days_promotion_on_main_page: {
					status: 'error',
					value: null,
					message: null
				},
				fast_delivery: {
					status: 'error',
					value: null,
					message: null
				},
				group_share_1: {
					status: 'ok',
					value: null,
					message: null
				},
				group_share_2: {
					status: 'ok',
					value: null,
					message: null
				},
				group_share_3: {
					status: 'ok',
					value: null,
					message: null
				},
				group_share_4: {
					status: 'ok',
					value: null,
					message: null
				},
				group_share_5: {
					status: 'ok',
					value: null,
					message: null
				},
				result_status: {
					status: 'error',
					value: null,
					message: null
				},
				uuid: {
					status: 'error',
					value: null,
					message: null
				},
				order: {
					status: 'error',
					value: null,
					message: null
				},
				ahrefs_enabled: {
					status: 'ok',
					value: null,
					message: null
				},
				is_blocked: {
					status: 'ok',
					value: null,
					message: null
				},
				should_delete: {
					status: 'ok',
					value: null,
					message: null
				},
				other_requirements: ''
			}
		}
		
		itemToChange.uuid = result.payload.uuid;
		itemToChange.result_status = result.payload.result_status;
		itemToChange.status = result.payload.status;
		itemToChange.message = result.payload.message;
		itemToChange.order = result.payload.order;
		
		const itemOnCurrentList = currentItems.value.find((elem:any) => elem.uuid === result.payload.uuid);
		if (PREVIEW_STATUS_ERROR === itemOnCurrentList.result_status && PREVIEW_STATUS_OK === itemToChange.result_status && null != counter.value) {
			counter.value -= 1;
		}
		if (PREVIEW_STATUS_OK === itemOnCurrentList.result_status && PREVIEW_STATUS_ERROR === itemToChange.result_status && null != counter.value) {
			counter.value += 1;
		}
		
		const index = currentItems.value.indexOf(itemOnCurrentList);
		currentItems.value[index] = itemToChange;
		// Tutaj trzeba dodac do arrayki item i go usunac zeby tabelka zaciagnela dane mozliwe ze mozna to podrasowac
		currentItems.value.push(itemToChange);
		currentItems.value.pop();
	} catch (e) {
		toast.error(`${t('importExport.toast.errorEditRow')}`);
		loading.value = false;
		return;
	}
	
	loading.value = false;
};

const errorFieldColor = (status:string) => {
	if (status === PREVIEW_STATUS_WARNING) {
		return 'import-preview-table__warning';
	} else if (status === PREVIEW_STATUS_ERROR) {
		return 'import-preview-table__error';
	} else {
		return '';
	}
};
const errorTextColor = (status:string) => {
	if (status === PREVIEW_STATUS_WARNING) {
		return 'import-preview-table__warning-bgc';
	} else if (status === PREVIEW_STATUS_ERROR) {
		return 'import-preview-table__error-bgc';
	} else {
		return '';
	}
};
const isNotStatusOk = (status:string) => {
	return status !== PREVIEW_STATUS_OK;
};

const goBack = () => {
	router.push('/import');
};
const validate = async(isContinue:boolean) => {
	loading.value = true;
	isWaitingForResponse.value = true;
	
	try {
		const result = await importApi.revalidatePreviewImportedFile(router.currentRoute.value.params.importUuid, false, !!isContinue);
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.errorRevalidateFile')}`);
			loading.value = false;
			isWaitingForResponse.value = false;
			return;
		}
	} catch (e) {
		toast.error(`${t('importExport.toast.errorRevalidateFile')}`);
		loading.value = false;
		isWaitingForResponse.value = false;
		return;
	}
	await router.push('/import');
	isWaitingForResponse.value = false;
	loading.value = false;
};
const save = async() => {
	loading.value = true;
	isWaitingForResponse.value = true;
	
	try {
		const result = await importApi.revalidatePreviewImportedFile(router.currentRoute.value.params.importUuid, true);
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.errorRevalidateFile')}`);
			loading.value = false;
			isWaitingForResponse.value = false;
			return;
		}
	} catch (e) {
		toast.error(`${t('importExport.toast.errorRevalidateFile')}`);
		loading.value = false;
		isWaitingForResponse.value = false;
		return;
	}
	await router.push('/import');
	isWaitingForResponse.value = false;
	loading.value = false;
};
const importAction = async() => {
	loading.value = true;
	isWaitingForResponse.value = true;
	
	try {
		const result = await importApi.savePreviewImportedFile(router.currentRoute.value.params.importUuid);
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.errorImportFile')}`);
			loading.value = false;
			isWaitingForResponse.value = false;
			return;
		}
	} catch (e) {
		toast.error(`${t('importExport.toast.errorImportFile')}`);
		loading.value = false;
		isWaitingForResponse.value = false;
		return;
	}
	await router.push('/import');
	isWaitingForResponse.value = false;
	loading.value = false;
};
</script>

<style scoped lang="scss">
.import-preview-table {
	@import "@/assets/scss/vuetifyDataTable";
	
	&__loading {
		opacity: 0.5;
	}
	
	&:deep(.v-data-table .text-center div) {
		width: max-content !important;
		margin: 0 auto;
	}
	
	&__top-section {
		display: flex;
		align-items: center;
		padding: 15px;
		gap: 20px;
		font-size: 14px;
		font-weight: 700;
		color: $primary-400;
	}
	
	&__warning {
		&:deep(.v-input__control) {
			border: 3px solid $yellow-500 !important;
			border-radius: 8px;
		}
	}
	
	&__error {
		&:deep(.v-input__control) {
			border: 3px solid $secondary-500 !important;
			border-radius: 8px;
		}
	}
	
	&__warning-bgc {
		margin-top: 5px;
		font-weight: 700;
		background-color: $yellow-500 !important;
		margin-bottom: 5px !important;
		border-radius: 6px;
		padding: 0 4px;
	}
	
	&__error-bgc {
		margin-top: 5px;
		font-weight: 700;
		background-color: $secondary-500 !important;
		margin-bottom: 5px !important;
		border-radius: 6px;
		padding: 2px 4px;
	}
	
	&__btn-wrapper {
		display: flex;
		gap: 10px;
		margin-right: auto;
		margin-top: 10px;
	}
	
	&__uuid-btn-wrapper {
		display: flex;
		justify-content: center;
		
		button {
			border-radius: 50% !important;
			padding: 0 0 0 15px !important;
			min-width: 30px !important;
			height: 42px !important;
		}
	}
	
	&__margin-right-5 {
		margin-right: 5px;
	}
	
	&:deep(.v-input__details) {
		display: none;
	}
	
	&:deep(.m-tooltip) {
		display: flex;
		align-items: center;
	}
}
</style>
