import i18n from '@/utils/i18n';

interface LanguageTranslations {
	[locale: string]: {
		languages: string[];
	};
}
export const setLanguagesTranslations = (locale:string):any => {
	const i18nUnknown = i18n as unknown;
	const i18nTyped = i18nUnknown as LanguageTranslations;
	const languageName = i18nTyped[locale] || '';

	return [
		{
			"id": 1,
			"name": languageName.languages[1],
			"country": "England",
			"flag": "us",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:25 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 2,
			"name": languageName.languages[2],
			"country": "Spain",
			"flag": "es",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:26 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 3,
			"name": languageName.languages[3],
			"country": "India",
			"flag": "in",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:26 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 4,
			"name": languageName.languages[4],
			"country": "Poland",
			"flag": "pl",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:27 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 5,
			"name": languageName.languages[5],
			"country": "France",
			"flag": "fr",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:27 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 6,
			"name": languageName.languages[6],
			"country": "Germany",
			"flag": "de",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:27 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 7,
			"name": languageName.languages[7],
			"country": "Arab",
			"flag": "eg",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:27 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 8,
			"name": languageName.languages[8],
			"country": "Greece",
			"flag": "gr",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:27 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 9,
			"name": languageName.languages[9],
			"country": "Portugal",
			"flag": "pt",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:27 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 10,
			"name": languageName.languages[10],
			"country": "Korea",
			"flag": "kr",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:28 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 11,
			"name": languageName.languages[11],
			"country": "Japan",
			"flag": "jp",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:28 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 12,
			"name": languageName.languages[12],
			"country": "Romania",
			"flag": "ro",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:28 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 13,
			"name": languageName.languages[13],
			"country": "Russian Federation",
			"flag": "ru",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:28 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 14,
			"name": languageName.languages[14],
			"country": "Sweden",
			"flag": "se",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:28 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 15,
			"name": languageName.languages[15],
			"country": "Hungary",
			"flag": "hu",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:28 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 16,
			"name": languageName.languages[16],
			"country": "Finland",
			"flag": "fi",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:29 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 17,
			"name": languageName.languages[17],
			"country": "China",
			"flag": "cn",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:29 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 18,
			"name": languageName.languages[18],
			"country": "Czech Republic",
			"flag": "cz",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:29 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 19,
			"name": languageName.languages[19],
			"country": "Denmark",
			"flag": "dk",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:30 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 20,
			"name": languageName.languages[20],
			"country": "Netherlands",
			"flag": "nl",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:30 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 21,
			"name": languageName.languages[21],
			"country": "Turkey",
			"flag": "tr",
			"article_types_count": 3,
			"created_at": "Fri Jul 20 2018 15:31:30 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 22,
			"name": languageName.languages[22],
			"country": "Italy",
			"flag": "it",
			"article_types_count": 3,
			"created_at": "Wed May 25 2022 16:40:14 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 23,
			"name": languageName.languages[23],
			"country": "Macedonia",
			"flag": "mk",
			"article_types_count": 3,
			"created_at": "Wed May 25 2022 16:40:14 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 24,
			"name": languageName.languages[24],
			"country": "Slovakia",
			"flag": "sk",
			"article_types_count": 3,
			"created_at": "Wed May 25 2022 16:40:14 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 25,
			"name": languageName.languages[25],
			"country": "Bulgaria",
			"flag": "bg",
			"article_types_count": 3,
			"created_at": "Wed May 25 2022 16:40:14 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 26,
			"name": languageName.languages[26],
			"country": "Bosnia and Herzegovina",
			"flag": "ba",
			"article_types_count": 3,
			"created_at": "Wed May 25 2022 16:40:14 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 27,
			"name": languageName.languages[27],
			"country": "Indonesia",
			"flag": "id",
			"article_types_count": 3,
			"created_at": "Wed May 25 2022 16:40:14 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 28,
			"name": languageName.languages[28],
			"country": "Croatia",
			"flag": "hr",
			"article_types_count": 3,
			"created_at": "Wed May 25 2022 16:40:14 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 29,
			"name": languageName.languages[29],
			"country": "Ukraine",
			"flag": "ua",
			"article_types_count": 3,
			"created_at": "Thu Jun 23 2022 09:13:09 GMT+0000",
			"updated_at": "Mon Oct 10 2022 13:02:56 GMT+0000"
		},
		{
			"id": 30,
			"name": languageName.languages[30],
			"country": "Norway",
			"flag": "no",
			"article_types_count": 3,
			"created_at": "Mon Dec 12 2022 14:20:43 GMT+0000",
			"updated_at": "Mon Dec 12 2022 14:20:43 GMT+0000"
		},
		{
			"id": 31,
			"name": languageName.languages[31],
			"country": "Thailand",
			"flag": "th",
			"article_types_count": 3,
			"created_at": "Mon Dec 12 2022 15:07:05 GMT+0000",
			"updated_at": "Mon Dec 12 2022 15:07:05 GMT+0000"
		},
		{
			"id": 32,
			"name": languageName.languages[31],
			"country": "Armenia",
			"flag": "am",
			"article_types_count": 3,
			"created_at": "Wed May 24 2023 12:07:25 GMT+0000",
			"updated_at": "Wed May 24 2023 12:07:25 GMT+0000"
		},
		{
			"id": 33,
			"name": languageName.languages[33],
			"country": "Israel",
			"flag": "il",
			"article_types_count": 3,
			"created_at": "Fri May 26 2023 08:51:29 GMT+0000",
			"updated_at": "Fri May 26 2023 08:51:29 GMT+0000"
		},
		{
			"id": 34,
			"name": languageName.languages[34],
			"country": "Lithuania",
			"flag": "lt",
			"article_types_count": 3,
			"created_at": "Fri May 26 2023 08:52:26 GMT+0000",
			"updated_at": "Fri May 26 2023 08:52:26 GMT+0000"
		},
		{
			"id": 35,
			"name": languageName.languages[35],
			"country": "Malaysia",
			"flag": "my",
			"article_types_count": 3,
			"created_at": "Fri May 26 2023 08:52:58 GMT+0000",
			"updated_at": "Fri May 26 2023 08:52:58 GMT+0000"
		},
		{
			"id": 36,
			"name": languageName.languages[36],
			"country": "Serbia",
			"flag": "rs",
			"article_types_count": 3,
			"created_at": "Fri May 26 2023 08:54:56 GMT+0000",
			"updated_at": "Fri May 26 2023 08:54:56 GMT+0000"
		},
		{
			"id": 37,
			"name": languageName.languages[37],
			"country": "Latvia",
			"flag": "lv",
			"article_types_count": 0,
			"created_at": "Thu Feb 08 2024 09:23:16 GMT+0000",
			"updated_at": "Thu Feb 08 2024 09:23:16 GMT+0000"
		},
		{
			"id": 38,
			"name": languageName.languages[38],
			"country": "Estonia",
			"flag": "ee",
			"article_types_count": 0,
			"created_at": "Thu Feb 08 2024 09:23:16 GMT+0000",
			"updated_at": "Thu Feb 08 2024 09:23:16 GMT+0000"
		},
		{
			"id": 39,
			"name": languageName.languages[39],
			"country": "Slovenia",
			"flag": "sl",
			"article_types_count": 0,
			"created_at": "Thu Feb 08 2024 09:23:16 GMT+0000",
			"updated_at": "Thu Feb 08 2024 09:23:16 GMT+0000"
		}
	];
};
