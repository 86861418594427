<template>
	<Form :validation-schema="schema" @submit="onSubmit" class="add-new-website-form">
		<MProgressBar v-show="loading" indeterminate height="2" />
		<MTextField
			id="add-new-website-first-name"
			v-model="form.first_name"
			name="firstName"
			:separate-label="$t('order.publication.name')"
			:placeholder="$t('order.publication.name')"
			label-text-required
		/>
		<MTextField
			id="add-new-website-last-name"
			v-model="form.last_name"
			name="lastName"
			:separate-label="$t('order.publication.surname')"
			:placeholder="$t('order.publication.surname')"
			label-text-required
		/>
		<MTextField
			id="add-new-website-email"
			v-model="form.email"
			name="email"
			:separate-label="$t('order.publication.email')"
			:placeholder="$t('order.publication.email')"
			label-text-required
		/>
		<MTextField
			id="add-new-website-domain"
			v-model="form.domain"
			name="domain"
			:separate-label="$t('order.publication.domain')"
			:placeholder="$t('order.publication.domain')"
			label-text-required
		/>
		<MCheckbox
			id="add-new-website-do-follow"
			v-model="form.do_follow"
			name="doFollow"
			:label="$t('order.publication.urlFollow')"
		/>
		<MCheckbox
			id="add-new-website-promotion-main-page"
			v-model="form.promotion_main_page"
			name="promotionMainPage"
			:label="$t('order.publication.promotionMainPage')"
		/>
		<MCheckbox
			id="add-new-website-social-media"
			v-model="form.promotion_social_media"
			name="promotionSocialMedia"
			:label="$t('order.publication.promotionSocialMedia')"
		/>
		<MTextArea
			id="add-new-website-comment"
			v-model="form.comment"
			name="comment"
			label-text-required
			:separate-label="$t('order.publication.comments')"
			:placeholder="$t('order.publication.comments')"
		/>
		<div class="add-new-website-form__btn-wrapper">
			<MButton
				id="add-new-website-submit"
				big
				red400
				:label="$t('order.publication.send')"
				type="submit"
			/>
		</div>
	</Form>
</template>

<script setup lang="ts">
import { ref, defineEmits } from 'vue';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import WebsiteRequestApi from '@/api/v1/WebsiteRequestApi';
import type { IWebsiteRequestForm } from '@/models/WebsiteRequestModel';
import MTextField from '@/components/atoms/MTextField.vue';
import MButton from '@/components/atoms/MButton.vue';
import MCheckbox from '@/components/atoms/MCheckbox.vue';
import MTextArea from '@/components/atoms/MTextArea.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';

const websiteRequestApi = new WebsiteRequestApi();

const emit = defineEmits(['saveAction']);
const { user } = storeToRefs(useUserStore());
const { t } = useI18n();

const loading = ref<boolean>(false);
const form = ref<any>({
	first_name: null,
	last_name: null,
	email: null,
	domain: null,
	do_follow: false,
	promotion_main_page: false,
	promotion_social_media: false,
	comment: null,
});

form.value.first_name = user.value?.first_name;
form.value.last_name = user.value?.last_name;
form.value.email = user.value?.email;

const schema:any = yup.object({
	firstName: yup.string().required(`${t('order.publication.validation.firstNameRequired')}`).min(3, `${t('order.publication.validation.min3')}`).label('firstName'),
	lastName: yup.string().required(`${t('order.publication.validation.lastNameRequired')}`).min(3, `${t('order.publication.validation.min3')}`).label('lastName'),
	email: yup.string().required(`${t('order.publication.validation.emailRequired')}`).email(`${t('order.publication.validation.email')}`).label('email'),
	domain: yup.string().required(`${t('order.publication.validation.domainRequired')}`).label('domain'),
	comment: yup.string().required(`${t('order.publication.validation.commentRequired')}`).label('comment'),
});

const onSubmit = async() => {
	loading.value = true;
	
	const request:IWebsiteRequestForm = {
		name: form.value.first_name,
		surname: form.value.last_name,
		email: form.value.email,
		domain: form.value.domain,
		url_follow: form.value.do_follow,
		promotion_main_page: form.value.promotion_main_page,
		promotion_social_media: form.value.promotion_social_media,
		comment: form.value.comment,
	};
	try {
		const result = await websiteRequestApi.createRequestForNewDomain(request);
		if (!result.isSuccess) {
			toast.error(`${t('order.publication.toast.somethingWentWrong')}`);
			loading.value = false;
			return;
		}
		emit('saveAction');
	} catch (e) {
		toast.error(`${t('order.publication.toast.somethingWentWrong')}`);
		loading.value = false;
		return;
	}
	toast.success(`${t('order.publication.toast.requestSendSuccesfull')}`);
	loading.value = false;
};
</script>

<style scoped lang="scss">

</style>
