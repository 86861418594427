<template>
    <div style="margin-bottom: 200px" class="components">

        <div style="background: #dcd181; padding: 15px;">
            TUTAJ TYM GUZIKIEM LOGUJEMY SIE DO APKI ZEBY PRZEJSC DO DASHBOARDU I COS WIDZIEC !!! POKI NIE MA LOGOWANIA
            <MTextField id="email" v-model="emailLogin" name="emailLogin" label="Email" placeholder="Email" variant="outlined" />
            <MTextField id="password" v-model="passwordLogin" name="passwordLogin" label="Password" placeholder="Password" variant="outlined" />
            <MButton id="login" label="Logowanie" @click="otherAction" red400 big data-cy="login-btn" />
        </div>

        <Form :validation-schema="schema" @submit="onSubmit" style="margin-top: 40px;">
            <MTextField id="testEmail" v-model="test" name="emailValidation" label="email" placeholder="test placeholder" variant="outlined" clearable />
            <MTextField id="firstName" v-model="firstName" name="name" label="First name" placeholder="test placeholder" variant="outlined" />
            <MTextField id="minSpeed" v-model="minSpeed" name="minSpeed" type="number" label="Min speed" placeholder="test placeholder" variant="outlined" />
            <MTextField id="maxSpeed" v-model="maxSpeed" name="maxSpeed" type="number" label="Max speed" placeholder="test placeholder" variant="outlined" />
            <MSelect :id="'testSelect'" v-model="selectTest" name="selectTest" :items="selectItems" label="Select" item-value="id" item-title="name" variant="outlined" multiple />
            <MCheckbox id="testCheckbox" v-model="checkboxTest" name="checkboxTest" label="elo" color="red" />
            <MAutocomplete id="testAutocomplete" v-model="autocompleteTest" v-model:search="autocompleteInputValue" name="autocompleteTest" label="autocomplete" :get-items="autocompleteItems" item-title="name" item-value="id" clearable variant="outlined" multiple />
            <MTextArea id="testTextArea" v-model="textArea" name="textArea" label="text area" data-cy="textarea-test" />
            <MDatePicker id="testDatePicker" v-model="datePicker" name="datePicker" language="pl" :disabled-dates="{to: new Date(2023, 4, 20),from: new Date(2023, 7, 16),dates: [new Date(2023, 5, 1),new Date(2023, 5, 3),new Date(2023, 5, 11)],}" />
            <MVueEditor v-model="editor1" id="editor1" name="editor1" />
            <MVueEditor v-model="editor2" id="editor2" name="editor2" />
	        <MDateRangePicker id="testRangePicker" v-model="dateRangeValue" name="dateRange" locale="pl" multiCalendars clearable />
            <MButton type="submit" red400 big label="submit" @click="onSubmit" />
        </Form>
        
        <MSwitch v-model="switcher" />
        
        <MProgressBar v-model="progressBar" is-percent color="red" height="25" />
        
        <MHexagonIcon letter="M" large color="#2ed13b" />
        <MHexagonIcon letter="M" micro />
        <MHexagonIcon letter="M" small color="#fff" />
        
        <MLoading v-show="loading"/>
    
        <MTooltip color="green">
            <template v-slot:title>
                <MButton label="Tooltip Btn" green400 small />
            </template>
	        <template v-slot:content>
		        <div>tooltip text</div>
	        </template>
        </MTooltip>
        
        <MMenu location="bottom" min-width="200">
            <template v-slot:menuActivator>
                <MButton label="Menu" green400 big />
            </template>
            <template v-slot:menuItems>
                <MButton @click="openDialog" label="Menu1" red400 small />
                <MButton label="Menu2" red400 small />
                <MButton label="Menu3" red400 small />
                <div>menu4</div>
                <div>menu5</div>
            </template>
        </MMenu>
        
        <MDialog
            :is-close-dialog-action="isCloseDialog"
            :is-open-dialog-action="isOpenDialog"
            content-title="Content"
            @close-dialog="closeDialogWhenNoTargetPressed"
        />
        
        <HeadingLabel icon="articles" text="siema" />
        
        <MIcon icon="close" />
        
        <MExpansionPanel>
            <template v-slot:title>
                <div>
                    <img src="@/assets/icons/icon-approve.svg" alt="">
                    <span>Tytul</span>
                </div>
            </template>
            <template v-slot:text>
                <div>text</div>
            </template>
        </MExpansionPanel>
	    
	    <div class="create-article-form__photo-wrapper">
		    <img src="" alt="marketin9">
		    <div class="create-article-form__photo-btn-wrapper">
			    <div class="create-article-form__edit-btn-wrapper">
				    <div id="edit-photo" class="create-article-form__edit-photo">
					    <div class="create-article-form__btn">{{ $t('articles.edit') }}</div>
					    <div class="create-article-form__upload-hover-wrapper">
						    <v-file-input id="inputFile" v-model="inputFile" accept="image/*" />
					    </div>
				    </div>
			    </div>
			    <div id="remove-photo" class="create-article-form__btn create-article-form__btn--red" @click="deleteImage">{{ $t('articles.delete') }}</div>
		    </div>
	    </div>
	    <MButton id="fileCheck" big red400 label="Check file input" @click="fileCheck()" />
	    
	    <div id="element1">120.00 zł</div>
	    <div id="element2">120.00 zł</div>
        
        <MButton big green400 label="toast success" @click="activeToast()" />
        <MButton big red400 label="toast error" @click="activeToastError()" />
        <MButton big blue400 label="toast info" @click="activeToastInfo()" />
        <MButton big green400 label="toast warning" @click="activeToastWarning()" />
    </div>
</template>

<script setup lang="ts">
import { Form } from 'vee-validate';
import MTextField from '@/components/atoms/MTextField.vue';
import MButton from '@/components/atoms/MButton.vue';
import MSwitch from '@/components/atoms/MSwitch.vue';
import MSelect from '@/components/atoms/MSelect.vue';
import MCheckbox from '@/components/atoms/MCheckbox.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MTextArea from '@/components/atoms/MTextArea.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import MLoading from '@/components/atoms/MLoading.vue';
import MHexagonIcon from '@/components/atoms/MHexagonIcon.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';
import MMenu from '@/components/atoms/MMenu.vue';
import MDatePicker from '@/components/atoms/MDatePicker.vue';
import MDialog from '@/components/atoms/MDialog.vue';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MVueEditor from '@/components/atoms/MVueEditor.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MExpansionPanel from '@/components/atoms/MExpansionPanel.vue';
import MDateRangePicker from '@/components/atoms/MDateRangePicker.vue';
import { toast } from 'vue3-toastify';
import { ref, watch } from 'vue';
import * as yup from 'yup';
import MonolithAuthApi from '@/api/monolith/MonolithAuthApi';
import type { LoginRequestInterface } from '@/models/AuthModel';
import dayjs from "dayjs";
import router from "@/router";

const monolithAuthApi = new MonolithAuthApi();

const test = ref('');
const firstName = ref('');
const other = ref('');
const minSpeed = ref(null);
const maxSpeed = ref(null);
const autocompleteTest = ref(null);
const autocompleteInputValue = ref('');
const textArea = ref('');
const progressBar = ref('50');
const inputFile = ref(undefined);
const autocompleteItems = ref([
    {
        id: 1,
        name: 'Autocomplete 1'
    },
    {
        id: 2,
        name: 'Autocomplete 2'
    }
]);
const selectItems = ref([
    {
        id: 1,
        name: 'Record 1'
    },
    {
        id: 2,
        name: 'Record 2'
    }
]);
const selectTest = ref(null);
const switcher = ref(false);
const checkboxTest = ref(false);
const loading = ref(false);
const datePicker = ref(null);
let isCloseDialog = ref(true);
let isOpenDialog = ref(false);
let editor1 = ref('');
let editor2 = ref('');
let dateRangeValue = ref(null);

watch((autocompleteInputValue), (val) => {
    console.log('autocompleteInputValue', val)
});
watch((dateRangeValue), (val) => {
    console.log('dateRangeValue', val)
});

const openDialog = () => {
    isCloseDialog.value = false;
    isOpenDialog.value = true;
}

const closeDialogWhenNoTargetPressed = () => {
    isCloseDialog.value = true;
    isOpenDialog.value = false;
}

const activeToast = () => {
    toast.success('success toast wiadomosc abc');
}

const activeToastError = () => {
    toast.error('error toast wiadomosc abc2');
}

const activeToastInfo = () => {
    toast.info('info toast wiadomosc abc3');
}

const activeToastWarning = () => {
    toast.warning('warning toast wiadomosc abc4');
    toast('test123', {
        position: 'bottom-left'
    });
    toast.dark('elo')
}

const schema:any = yup.object({
    name: yup.string().required('My custom 56 line in code custom message').label('Name').test('test-name', 'Custom validation when write abc value in input',
        function (val) {
            return val != 'abc';
        }),
    emailValidation: yup.string().email('My custom message email wrong validation').required().test('test', 'Input field First name cann not be abc',
        function (val) {
            if (firstName.value === 'abc') {
                return false
            } else {
                return true
            }
        }),
    maxSpeed: yup.number().required().test('max-speed', 'Min speed is too high',
        function (val) {
            if (null != minSpeed.value && null != maxSpeed.value && +minSpeed.value > +maxSpeed.value) {
                return false
            } else {
                return true
            }
        }),
    minSpeed: yup.number().required().test('max-speed', 'Max speed is too low',
        function (val) {
            if (null != minSpeed.value && null != maxSpeed.value && +maxSpeed.value < +minSpeed.value) {
                return false
            } else {
                return true
            }
        }),
    password: yup.string().min(6).required(),
    selectTest: yup.number().required('Custom select Message'),
    checkboxTest: yup.boolean().required('Custom checkbox Message'),
    autocompleteTest: yup.number().required('Custom autocomplete Message'),
    textArea: yup.string().required('Custom textArea Message'),
    datePicker: yup.string().required('Custom datePicker Message'),
    editor1: yup.string().required('Custom editor1 Message'),
    editor2: yup.string().required('Custom editor2 Message'),
    dateRange: yup.array().required('Custom Date Picker Message'),
    passwordConfirm: yup
        .string()
        .oneOf([yup.ref('password')], 'Passwords must match')
        .required()
        .label('Password confirmation'),
    terms: yup
        .boolean()
        .required()
        .oneOf([true], 'You must agree to terms and conditions'),
});

const onSubmit = (values:any) => {
    console.log('values', values)
    console.log('text', test.value)
    console.log('firstName', firstName.value)
    console.log('maxSpeed', maxSpeed.value)
    console.log('minSpeed', minSpeed.value)
    console.log('switcher', switcher.value)
    console.log('other', other.value)
    console.log('select', selectTest.value)
    console.log('checkbox', checkboxTest.value)
    console.log('autocomplete', autocompleteTest.value)
    console.log('autocompleteInputValue', autocompleteInputValue.value)
    console.log('textArea', textArea.value)
    console.log('datePicker', datePicker.value)
    console.log('editor1', editor1.value)
    console.log('editor2', editor2.value)
    console.log('dateRange', dateRangeValue.value)
}

const emailLogin = ref('');
const passwordLogin = ref('');
const otherAction = async () => {
	console.log('other', other.value);
	const login: LoginRequestInterface = {
		email: emailLogin.value,
		password: passwordLogin.value
	};
	const result = await monolithAuthApi.monolithLogin(login);
	// @ts-ignore
	await localStorage.setItem('accessToken', result.payload.access_token);
	// @ts-ignore
	await localStorage.setItem('apiToken', result.payload.api_token);
	// @ts-ignore
	await localStorage.setItem('tokenType', result.payload.token_type);
	// @ts-ignore
	await localStorage.setItem('expiresIn', dayjs().add(result.payload.expires_in, 's').toString());
	await router.push('/dashboard');
	window.location.reload();
};

const deleteImage = () => {

};
const fileCheck = () => {
	console.log('file', inputFile.value)
};

</script>

<style scoped lang="scss">

</style>
