import type { IArticleItem } from '@/models/ArticlesModel';
import type { UserInterface } from '@/models/AuthModel';
import { isAdvertiser, isPublisher, isModerator, isContentWriter } from '@/hooks/UserHooks';

export const SUBMITTED_MODERATION = 'Submitted moderation';
export const ASSIGNED = 'Assigned';
export const IN_PROGRESS = 'In progress';
export const CONTENT_RESIGNED = 'Content resigned';
export const CONTENT_SUBMITTED = 'Content submitted';
export const FINISHED = 'Finished';
export const RESUBMITTED = 'Resubmitted';
export const CONTENT_RESUBMITTED = 'Content resubmitted';
export const READY_TO_PUBLISH = 'Ready to publish';
export const ARTICLE_IN_PROGRESS_OF_PUBLICATION = 'Article in progress of publication';
export const ARTICLE_APPROVE = 'Approve';

export const ARTICLE_TYPE_OWN = 'own';
export const ARTICLE_FINISHED_READY = 'ready';
export const ARTICLE_FINISHED_ON_GOING = 'ongoing';

export const ARTICLE_TYPE_STANDARD = 'standard';
export const ARTICLE_TYPE_TECHNICAL = 'technical';
export const ARTICLE_TYPE_PREMIUM = 'premium';
export const ARTICLE_TYPE_SPECIALIST = 'specialist';

export function isTypeOwn (item: IArticleItem) {
	return (null === item.article_request);
}

export function isArticleEditable (user: UserInterface, item: IArticleItem): boolean {
	if (isTypeOwn(item)) {
		return true;
	}

	if (isModerator(user) && SUBMITTED_MODERATION === item.article_request.status) {
		return true;
	}

	if ((isContentWriter(user) || isPublisher(user)) && IN_PROGRESS === item.article_request.status) {
		return true;
	}

	if ((isContentWriter(user) || isPublisher(user)) && RESUBMITTED === item.article_request.status) {
		return true;
	}

	if (isAdvertiser(user) && FINISHED === item.article_request.status) {
		return true;
	}

	return (null != item.article_request && ARTICLE_APPROVE === item.article_request.status);
}

export function isArticlePreviewable (user: UserInterface, item: IArticleItem): boolean {
	if (isTypeOwn(item)) {
		return true;
	}

	return true;
}

export function isArticleOrderDetails (user: UserInterface, item: IArticleItem): boolean {
	if (isTypeOwn(item)) {
		return false;
	}
	return true;
}

// Contadu article status

export const CONTADU_STATUS_WAITING = 'waiting';
export const CONTADU_STATUS_IN_PROGRESS = 'in progress';
export const CONTADU_STATUS_READY = 'ready';
