import BaseApi from '@/api/BaseApi';
import type { IExportListResponse, IExportWebsiteOfferRequest } from '@/models/ExportModel';

export default class ExportApi extends BaseApi {
	async exportedWebsiteOfferList (page: number, perPage: number) {
		return this.get<IExportListResponse>({
			url: `api/v1/export?page=${page}&per_page=${perPage}`,
		});
	};

	async exportWebsiteOffer (params?: IExportWebsiteOfferRequest|null, exportUuid?: string|null) {
		let url = 'api/v1/website_offer/export';

		if (null != exportUuid) {
			url += `?uuid=${exportUuid}`;
		}

		return this.post<IExportWebsiteOfferRequest|null, Object>({
			url: url,
			data: params,
		});
	};
};
