<template>
	<div class="transfer-between-account-table">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template v-slot:loading>
				<div class="transfer-between-account-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #item.created_at="elem">
				<div v-if="elem.item.raw.created_at != null">{{ dateFormatWithTime(elem.item.raw.created_at) }}</div>
			</template>
			
			<template #item.account_from="elem">
				<div v-if="elem.item.raw.account_from != null && user != null">{{ userAccountFrom(elem.item.raw.account_from) === AFFILIATE_ACCOUNT_ROLE_ID ? $t('withdraw.affilliate') : userAccountFrom(elem.item.raw.account_from) === ADVERTISER_ID ? $t('withdraw.advertiser') : $t('withdraw.publisher') }}</div>
			</template>
			
			<template #item.account_to="elem">
				<div v-if="elem.item.raw.account_to != null && user != null">{{ userAccountFrom(elem.item.raw.account_to) === AFFILIATE_ACCOUNT_ROLE_ID ? $t('withdraw.affilliate') : userAccountFrom(elem.item.raw.account_to) === ADVERTISER_ID ? $t('withdraw.advertiser') : $t('withdraw.publisher') }}</div>
			</template>
			
			<template #item.amount="elem">
				<div v-if="elem.item.raw.amount != null">{{ (elem.item.raw.amount / 100).toFixed(2) }} {{ null != elem.item.raw.currency_symbol ? elem.item.raw.currency_symbol : '' }}</div>
			</template>
		</v-data-table-server>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { toast } from 'vue3-toastify';
import { dateFormatWithTime } from '@/hooks/DataHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import WithdrawApi from '@/api/v2/WithdrawApi';
import { ADVERTISER_ID, AFFILIATE_ACCOUNT_ROLE_ID } from '@/hooks/UserHooks';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';

const withdrawApi = new WithdrawApi();

const { t } = useI18n();
const { user } = storeToRefs(useUserStore());

const loading = ref<boolean>(false);
const items = ref<Array<any>|any>([]);
const total = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const headers = ref<Array<TableHeaderInterface>|any>([]);
const defaultHeaders = () => {
	const result = [
		{
			title: `${t('withdraw.createdAt')}`,
			align: 'left',
			sortable: false,
			key: 'created_at'
		},
		{
			title: `${t('withdraw.accountFrom')}`,
			align: 'left',
			sortable: false,
			key: 'account_from'
		},
		{
			title: `${t('withdraw.accountTo')}`,
			align: 'left',
			sortable: false,
			key: 'account_to'
		},
		{
			title: `${t('withdraw.amount')}`,
			align: 'left',
			sortable: false,
			key: 'amount'
		},
	];
	
	return result;
};
headers.value = defaultHeaders();

const transferBetweenAccountList = async() => {
	loading.value = true;
	try {
		const result = await withdrawApi.transferBetweenAccountsList(options.value.page, options.value.itemsPerPage, options.value.sortBy.length > 0 ? options.value.sortBy[0].key : null, options.value.sortBy.length > 0 ? options.value.sortBy[0].order : null);
		if (!result.isSuccess) {
			toast.error(`${t('withdraw.toast.errorTransferBetweenAccountList')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload.data;
	} catch (e) {
		toast.error(`${t('withdraw.toast.errorTransferBetweenAccountList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	await transferBetweenAccountList();
};

const userAccountFrom = (accountId:number) => {
	if (null != user.value) {
		const account = user.value.accounts.find(elem => elem.id === accountId);
		
		if (account) {
			return account.role_id;
		}
	}
};
</script>

<style scoped lang="scss">
.transfer-between-account-table {
	@import '@/assets/scss/vuetifyDataTable';
	
	&__loading {
		opacity: 0.5;
	}
}
</style>
