// COUNTERS
export const CLIENTS_COUNTER = 'clients-counter';
export const ORDER_COUNTER = 'order-counter';
export const SALARY_COUNTER = 'salary-counter';
export const AVERAGE_SALARY_COUNTER = 'average-salary-counter';
export const DOMAINS_COUNTER = 'domains-counter';

// DATES
export const CUSTOM = 'custom';
export const LAST_7_DAYS = 'last7Days';
export const LAST_14_DAYS = 'last14Days';
export const LAST_30_DAYS = 'last30Days';
export const LAST_YEAR = 'lastYear';
export const LAST_5_YEARS = 'last5Years';
export const ALL_ORDERS = 'allOrders';
export const YEAR_2023 = '2023';
export const YEAR_2022 = '2022';
export const YEAR_2021 = '2021';
export const YEAR_2020 = '2020';
export const YEAR_2019 = '2019';
export const CURRENT_MONTH = 'currentMonth';
export const PREVIOUS_MONTH = 'previousMonth';

// TRENDING
export const POSITIVE_TRENDING = 'positive';
export const NEGATIVE_TRENDING = 'negative';
export const CONSTANTS_TRENDING = 'constants';
