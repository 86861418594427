<template>
	<div class="notification-settings">
		<div class="notification-settings__wrapper">
			<div class="notification-settings__edit-list">
				<HeadingLabel
					icon="bell"
					width="25"
					height="29"
					:text="$t('profile.notificationSettings')"
				/>
				<div class="notification-settings__checkbox-wrapper">
					<MCheckbox
						id="notification-finance"
						name="finance"
						v-model="notificationForm.finances"
						:label="$t('profile.payment')"
					/>
					<MCheckbox
						id="notification-publications"
						name="publications"
						v-model="notificationForm.publications"
						:label="$t('profile.publication')"
					/>
					<MCheckbox
						id="notification-domains"
						name="domains"
						v-model="notificationForm.domains"
						:label="$t('profile.domains')"
					/>
				</div>
				<div class="notification-settings__btn-wrapper">
					<MButton
						id="save-notifications"
						normal
						green400
						width180
						:label="$t('profile.saveNotifications')"
						@click="saveNotifications"
						:loading="loadingNotifications"
					/>
				</div>
			</div>
			<div class="notification-settings__edit-list">
				<HeadingLabel
					icon="approve"
					width="25"
					height="29"
					:text="$t('profile.consents')"
				/>
				<div class="notification-settings__checkbox-wrapper">
					<MCheckbox
						id="consents-terms"
						name="terms"
						v-model="consentsForm['terms-and-condition']"
						:label="$t('profile.termsAndCondition')"
						disabled
					/>
					<div class="notification-settings__description notification-settings__description--agree">{{ $t('profile.agreementUpdate') }}: {{ termsDate }}</div>
					<MCheckbox
						id="consents-marketing"
						name="marketing"
						v-model="consentsForm['marketing-agreement']"
						:label="$t('profile.marketingAgreement')"
					/>
					<div class="notification-settings__description notification-settings__description--agree">{{ $t('profile.agreementUpdate') }}: {{ marketingDate }}</div>
					<div class="notification-settings__description">{{ $t('profile.marketingDescription') }}</div>
					<MCheckbox
						id="consents-partners"
						name="partners"
						v-model="consentsForm['partners-agreement']"
						:label="$t('profile.partners')"
					/>
					<div class="notification-settings__description notification-settings__description--agree">{{ $t('profile.agreementUpdate') }}: {{ partnersDate }}</div>
					<div class="notification-settings__description">{{ $t('profile.partnerDescriptionFirst') }}</div>
					<a href="/partners" target="_blank" class="notification-settings__description notification-settings__description--link">{{ $t('profile.partnerDescriptionSecond') }}</a>
					<br><br>
					<div class="notification-settings__description">{{ $t('profile.partnerDescriptionThird') }}</div>
					<br>
					<div class="notification-settings__description">{{ $t('profile.partnerDescriptionFourth') }}</div>
				</div>
				<div class="notification-settings__btn-wrapper">
					<MButton
						id="save-consents"
						normal
						green400
						width180
						:label="$t('profile.saveTerms')"
						@click="saveConsents"
						:loading="loadingConsents"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import UserApi from '@/api/v1/UserApi';
import type { UserInterface, INotificationForm } from '@/models/AuthModel';
import {
	NOTIFICATION_FINANCES,
	NOTIFICATION_DOMAINS,
	NOTIFICATION_PUBLICATIONS,
	CONSENTS_TERMS,
	CONSENTS_MARKETING,
	CONSENTS_PARTNERS
} from '@/hooks/ProfileHooks';
import { dateFormatWithTime } from '@/hooks/DataHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MCheckbox from '@/components/atoms/MCheckbox.vue';
import MButton from '@/components/atoms/MButton.vue';

interface IOwnProps {
	currentUser: UserInterface
}

interface IProps extends IOwnProps {
}

const userApi = new UserApi();

const props = defineProps<IProps>();
const emit = defineEmits(['userDetails']);

const { white } = variables;
const { t } = useI18n();

const notificationForm = ref<any>({
	finances: false,
	publications: false,
	domains: false,
});
const consentsForm = ref<any>({
	'terms-and-condition': true,
	'marketing-agreement': false,
	'partners-agreement': false,
});
const termsDate = ref<string|null>(null);
const marketingDate = ref<string|null>(null);
const partnersDate = ref<string|null>(null);
const loadingNotifications = ref<boolean>(false);
const loadingConsents = ref<boolean>(false);

const checkNotificationsSettings = () => {
	if (props.currentUser.notification_groups.find(elem => elem.name === NOTIFICATION_FINANCES)) {
		notificationForm.value.finances = true;
	}
	if (props.currentUser.notification_groups.find(elem => elem.name === NOTIFICATION_DOMAINS)) {
		notificationForm.value.domains = true;
	}
	if (props.currentUser.notification_groups.find(elem => elem.name === NOTIFICATION_PUBLICATIONS)) {
		notificationForm.value.publications = true;
	}
};
checkNotificationsSettings();

const checkConsentsSettings = () => {
	if (props.currentUser.terms.find(elem => elem.slug === CONSENTS_TERMS)) {
		consentsForm.value['terms-and-condition'] = true;
		const consent = props.currentUser.terms.find(elem => elem.slug === CONSENTS_TERMS);
		if (null != consent) {
			termsDate.value = dateFormatWithTime(consent.pivot.created_at);
		}
	}
	if (props.currentUser.terms.find(elem => elem.slug === CONSENTS_MARKETING)) {
		consentsForm.value['marketing-agreement'] = true;
		const consent = props.currentUser.terms.find(elem => elem.slug === CONSENTS_MARKETING);
		if (null != consent) {
			marketingDate.value = dateFormatWithTime(consent.pivot.created_at);
		}
	}
	if (props.currentUser.terms.find(elem => elem.slug === CONSENTS_PARTNERS)) {
		consentsForm.value['partners-agreement'] = true;
		const consent = props.currentUser.terms.find(elem => elem.slug === CONSENTS_PARTNERS);
		if (null != consent) {
			partnersDate.value = dateFormatWithTime(consent.pivot.created_at);
		}
	}
};
checkConsentsSettings();

const saveNotifications = async() => {
	loadingNotifications.value = true;
	
	const form:INotificationForm = {
		domains: notificationForm.value.domains ? 1 : 0,
		publications: notificationForm.value.publications ? 1 : 0,
		finances: notificationForm.value.finances ? 1 : 0,
	};
	try {
		const result = await userApi.editNotifications(form, props.currentUser.uuid);
		if (!result.isSuccess) {
			toast.error(`${t('profile.toast.errorUpdateNotifications')}`);
			loadingNotifications.value = false;
			return;
		}
		toast.success(`${t('profile.toast.successUpdateNotifications')}`);
		emit('userDetails');
	} catch (e) {
		toast.error(`${t('profile.toast.errorUpdateNotifications')}`);
		loadingNotifications.value = false;
		return;
	}
	loadingNotifications.value = false;
};
const saveConsents = async() => {
	loadingConsents.value = true;
	try {
		const result = await userApi.editTerms(consentsForm.value, props.currentUser.uuid);
		if (!result.isSuccess) {
			toast.error(`${t('profile.toast.errorUpdateTerms')}`);
			loadingNotifications.value = false;
			return;
		}
		toast.success(`${t('profile.toast.successUpdateTerms')}`);
		emit('userDetails');
	} catch (e) {
		toast.error(`${t('profile.toast.errorUpdateTerms')}`);
		loadingNotifications.value = false;
		return;
	}
	loadingConsents.value = false;
};
</script>

<style scoped lang="scss">
.notification-settings {
	&__wrapper {
		display: flex;
		flex-direction: column;
		background-color: $white-100;
		
		@include media-breakpoint-up(md) {
			flex-direction: row;
			gap: 40px;
		}
	}
	
	&__edit-list {
		background-color: $white-100;
		margin-top: 3px;
		padding: 20px 10px;
		
		@include media-breakpoint-up(sm) {
			padding: 20px 20px;
		}
		
		@include media-breakpoint-up(md) {
			width: 50%;
		}
	}
	
	&__checkbox-wrapper {
		padding: 20px 12px;
	}
	
	&__btn-wrapper {
		padding: 20px;
	}
	
	&__description {
		color: $grey-400;
		margin-left: 12px;
		
		&--agree {
			margin-bottom: 10px;
			color: $primary-400;
		}
		
		&--link {
			text-decoration: none;
			color: $secondary-400;
			cursor: pointer;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	&:deep(.v-selection-control__input) {
		color: $secondary-400;
	}
	
	&:deep(.v-input__details) {
		display: none;
	}
}
</style>
