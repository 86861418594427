import BaseApi from '@/api/BaseApi';
import type { IMonolithPublicationCampaignList, PublicationListInterface } from '@/models/PublicationModel';

export default class MonolithPublicationApi extends BaseApi {
	async getPublicationList (roleId: number, page: number|null, perPage: number|null, sortBy: string|null, sortDesc: string|null, search?: string|null, operator?: string|null, hasNotes?: number|null, overtime?: number|null, statuses?: Array<string>|null, from?: string|null, to?: string|null, userId?: string|null) {
		const apiToken = localStorage.getItem('apiToken');

		let url = `/api/order/publications?role=${roleId}&api_token=${apiToken}&page=${page}&per_page=${perPage}&from=${null == from ? '' : from}&to=${null == to ? '' : to}`;

		if (search) {
			url += `&search=${search}`;
		}

		if (operator) {
			url += `&operator=${operator}`;
		}

		if (statuses) {
			statuses.forEach((status) => {
				url += `&statuses[]=${status}`;
			})
		}
		// if (hasNotes) {
		//     url += `&hasNotes=${hasNotes}`
		// }

		if (overtime) {
			url += `&overtime=${overtime}`;
		}

		if (userId) {
			url += `&userId=${userId}`;
		}

		if (sortBy) {
			url += `&order=${sortBy}`;
		}

		if (sortDesc) {
			url += `&orderDirection=${sortDesc}`;
		}

		return this.get<PublicationListInterface>({
			url: url,
			config: {
				useMonolithApi: true,
			},
		});
	}

	async campaignPublicationList (page: number, perPage: number, sortBy: string|null, sortDesc: string|null, search?: string|null, userUuid?: string|null) {
		let url = `/api/orders/publication?api_token=${localStorage.getItem('apiToken')}&page=${page}&per_page=${perPage}`;

		if (null != sortBy) {
			url += `&order=${sortBy}`;
		} else {
			url += '&order=campaigns.created_at';
		}

		if (null != sortDesc) {
			url += `&orderDirection=${sortDesc}`;
		} else {
			url += '&orderDirection=desc';
		}

		if (null != search) {
			url += `&search=${search}`;
		}

		if (null != userUuid) {
			url += `&user_uuid=${userUuid}`;
		}

		return this.get<IMonolithPublicationCampaignList>({
			url: url,
			config: {
				useMonolithApi: true,
			},
		});
	}

	async clearBasket (orderId:number) {
		return this.delete({
			url: `/api/order/${orderId}?api_token=${localStorage.getItem('apiToken')}`,
			config: {
				useMonolithApi: true,
			},
		});
	}
}
