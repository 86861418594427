import BaseApi from '@/api/BaseApi';
import type { IImageUploadResponse } from '@/models/ImageModel';

export default class ImageApi extends BaseApi {
	async sendImage (objectId: number, file: File) {
		const data = new FormData();
		data.append('image', file);
		data.append('object_id', objectId.toString());
		return this.post<FormData, IImageUploadResponse>({
			url: 'api/v1/images',
			data,
			config: {
				contentType: 'multipart/form-data',
			},
		});
	};

	async deleteImage (objectId: number|null) {
		return this.delete({
			url: `api/v1/images/${objectId}`,
		});
	};
};
