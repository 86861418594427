<template>
	<div id="holiday-event-banner" class="holiday-event-banner" @click="goToFeedOffer">
		<img v-if="locale === 'pl'" src="~@/assets/images/holiday-pl.png" alt="m9-holiday-event-banner" />
		<img v-if="locale !== 'pl'" src="~@/assets/images/holiday-en.png" alt="m9-holiday-event-banner" />
	</div>
</template>

<script setup lang="ts">
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@/stores/session';
import { useBooleanFlagStore } from '@/stores/booleanFlag';

const { locale } = storeToRefs(useSessionStore());
const { setIsPromoBtnClicked } = useBooleanFlagStore();

const goToFeedOffer = () => {
	setIsPromoBtnClicked(true);
	router.push('/order/publication/cart');
};
</script>

<style scoped lang="scss">
.holiday-event-banner {
	cursor: pointer;
	width: 100%;
	height: 100%;
	transition: opacity 0.3s;
	
	&:hover {
		opacity: 0.8;
	}
	
	img {
		width: 100%;
		height: 100%;
		border-radius: 12px;
	}
}
</style>
