import router from '@/router';

export const CREATE_CART_STATUS_DRAFT = 'draft';
export const CREATE_CART_STATUS_SEND = 'send';
export const CREATE_CART_STATUS_RESIGNED = 'resigned';
export const CREATE_CART_STATUS_FINISHED = 'finished';

export const CART_ADD_ARTICLE_WRITE = 'write';
export const CART_ADD_ARTICLE_UPLOAD = 'upload';
export const CART_ADD_ARTICLE_UPLOAD_GOOGLE = 'google';
export const CART_ADD_ARTICLE_ORDER = 'order';

export const saveSlugRedirect = (slug: string | any) => {
	localStorage.setItem('cart-slug', slug);
};

export const checkIsGoToPreparedCartAction = () => {
	if (null != localStorage.getItem('cart-slug')) {
		router.push(`/carts/${localStorage.getItem('cart-slug')}/preview`);
	}
};
