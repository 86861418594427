<template>
	<div class="website-ahrefs-box">
		<div class="website-ahrefs-box__top-wrapper">
			<div>{{ $t('websites.websiteProfileDetails') }}</div>
			<img src="~@/assets/images/ahrefs-logo.png" alt="ahrefs" />
			<MIcon v-if="null != user && (isAdmin(user) || isModerator(user)) && !isRefreshAction" icon="refresh" :color="primary400" class="website-ahrefs-box__refresh" @click="refreshAhrefs" />
			<v-progress-circular v-if="isRefreshAction" :color="primary400" :size="15" :width="2" indeterminate />
		</div>
		<div class="website-ahrefs-box__no-data">
			<v-progress-circular v-if="loading" :color="primary400" :size="15" :width="2" indeterminate />
		</div>
		<div v-if="null != seoStats && !loading" class="website-ahrefs-box__bottom-wrapper">
			<div v-if="seoStats.domain_rating.length > 0" class="website-ahrefs-box__dr-wrapper">
				<v-progress-circular
					:color="secondary400"
					:model-value="(seoStats.domain_rating[0].domainRating)?.toString()"
					:size="32"
					:width="9"
				/>
				<div class="website-ahrefs-box__item-wrapper">
					<div class="website-ahrefs-box__item">{{ $t('websites.dr') }}</div>
					<div class="website-ahrefs-box__stats">{{ seoStats.domain_rating[0].domainRating }}</div>
				</div>
			</div>
			<div v-if="seoStats.url_ratings.length > 0 && null != user && (isAdmin(user) || isModerator(user))" class="website-ahrefs-box__dr-wrapper">
				<v-progress-circular
					:color="yellow500"
					:model-value="(seoStats.url_ratings[0].urlRating)?.toString()"
					:size="32"
					:width="9"
				/>
				<div class="website-ahrefs-box__item-wrapper">
					<div class="website-ahrefs-box__item">{{ $t('websites.ur') }}</div>
					<div class="website-ahrefs-box__stats">{{ seoStats.url_ratings[0].urlRating }}</div>
				</div>
			</div>
			<div v-if="seoStats.metrics_history.length > 0" class="website-ahrefs-box__item-wrapper">
				<div class="website-ahrefs-box__item">{{ $t('websites.ot') }}</div>
				<div class="website-ahrefs-box__stats">{{ seoStats.metrics_history[0].orgTraffic }}</div>
			</div>
			<div v-if="seoStats.backlinks.length > 0" class="website-ahrefs-box__item-wrapper">
				<div class="website-ahrefs-box__item">{{ $t('websites.rd') }}</div>
				<div class="website-ahrefs-box__stats">{{ seoStats.backlinks[0].live_refdomains }}</div>
			</div>
			<div v-if="seoStats.backlinks.length > 0" class="website-ahrefs-box__item-wrapper">
				<div class="website-ahrefs-box__item">{{ $t('websites.bl') }}</div>
				<div class="website-ahrefs-box__stats">{{ seoStats.backlinks[0].live }}</div>
			</div>
			<div v-if="seoStats.trust_flow.length > 0" class="website-ahrefs-box__item-wrapper">
				<div class="website-ahrefs-box__item">{{ $t('websites.tf') }}</div>
				<div class="website-ahrefs-box__stats">{{ seoStats.trust_flow[0].trust_flow }}</div>
			</div>
			<div v-if="seoStats.metrics_history.length > 0 && null != user && (isAdmin(user) || isModerator(user))" class="website-ahrefs-box__item-wrapper">
				<div class="website-ahrefs-box__item">{{ $t('websites.pt') }}</div>
				<div class="website-ahrefs-box__stats">{{ seoStats.metrics_history[0].paidTraffic }}</div>
			</div>
			<div v-if="seoStats.metrics_history.length > 0 && null != user && (isAdmin(user) || isModerator(user))" class="website-ahrefs-box__item-wrapper">
				<div class="website-ahrefs-box__item">{{ $t('websites.oc') }}</div>
				<div class="website-ahrefs-box__stats">{{ seoStats.metrics_history[0].orgCost }}</div>
			</div>
			<div v-if="seoStats.metrics_history.length > 0 && null != user && (isAdmin(user) || isModerator(user))" class="website-ahrefs-box__item-wrapper">
				<div class="website-ahrefs-box__item">{{ $t('websites.pc') }}</div>
				<div class="website-ahrefs-box__stats">{{ seoStats.metrics_history[0].paidCost }}</div>
			</div>
			<div v-if="seoStats.backlinks.length > 0 && null != user && (isAdmin(user) || isModerator(user))" class="website-ahrefs-box__item-wrapper">
				<div class="website-ahrefs-box__item">{{ $t('websites.blat') }}</div>
				<div class="website-ahrefs-box__stats">{{ seoStats.backlinks[0].all_time }}</div>
			</div>
			<div v-if="seoStats.backlinks.length > 0 && null != user && (isAdmin(user) || isModerator(user))" class="website-ahrefs-box__item-wrapper">
				<div class="website-ahrefs-box__item">{{ $t('websites.rdat') }}</div>
				<div class="website-ahrefs-box__stats">{{ seoStats.backlinks[0].all_time_refdomains }}</div>
			</div>
		</div>
		<div v-if="(null == seoStats || (seoStats.domain_rating.length === 0 && seoStats.url_ratings.length === 0 && seoStats.metrics_history.length === 0 && seoStats.backlinks.length === 0 && seoStats.trust_flow.length === 0)) && !loading">
			<div class="website-ahrefs-box__no-data">{{ $t('websites.noData') }}</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineProps, ref, defineEmits } from 'vue';
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { isAdmin, isModerator } from '@/hooks/UserHooks';
import { toast } from 'vue3-toastify';
import type { IAhrefsStats } from '@/models/AhrefsModel';
import AhrefsApi from '@/apiWebsites/v1/AhrefsApi';
import variables from '@/assets/scss/modules/variables.module.scss';
import MIcon from '@/components/atoms/MIcon.vue';

interface IOwnProps {
	seoStats: IAhrefsStats|null
	loading: boolean
}
interface IProps extends IOwnProps {
}

defineProps<IProps>();

const ahrefsApi = new AhrefsApi();

const emit = defineEmits(['refreshStatisticsAction']);
const { t } = useI18n();
const { user } = storeToRefs(useUserStore());
const { secondary400, primary400, yellow500 } = variables;

const isRefreshAction = ref<boolean>(false);

const refreshAhrefs = async() => {
	isRefreshAction.value = true;
	try {
		const result = await ahrefsApi.refreshStats(router.currentRoute.value.params.uuid);
		if (!result.isSuccess) {
			toast.error(`${t('websites.toast.errorRefreshStats')}`);
			isRefreshAction.value = false;
			return;
		}
		toast.success(`${t('websites.toast.successRefreshStats')}`);
		emit('refreshStatisticsAction', result.payload);
	} catch (e) {
		toast.error(`${t('websites.toast.errorRefreshStats')}`);
		isRefreshAction.value = false;
		return;
	}
	isRefreshAction.value = false;
};
</script>

<style scoped lang="scss">
.website-ahrefs-box {
	background-color: $white-100;
	border-radius: 8px;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
	padding: 8px 25px 8px 15px;
	display: flex;
	flex-direction: column;
	
	&__top-wrapper {
		display: flex;
		align-items: center;
		gap: 10px;
		
		img {
			width: 48px;
			height: 12px;
		}
	}
	
	&__bottom-wrapper {
		margin-top: 5px;
		display: flex;
		align-items: center;
		gap: 50px;
		flex-wrap: wrap;
		max-width: 500px;
	}
	
	&__item-wrapper {
		display: flex;
		flex-direction: column;
	}
	
	&__item {
		position: relative;
		width: max-content;
		
		&:after {
			content: '';
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: $primary-400;
			position: absolute;
			right: -11px;
			top: 5px;
		}
	}
	
	&__stats {
		font-size: 14px;
	}
	
	&__dr-wrapper {
		display: flex;
		gap: 5px;
		align-items: center;
	}
	
	&__refresh {
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__no-data {
		font-weight: 700;
		font-size: 14px;
		margin: 5px auto;
		width: max-content;
	}
}
</style>
