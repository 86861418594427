<template>
	<div class="cart-preview-slug">
		<div v-show="loading" class="cart-preview-slug__loading">
			<v-progress-circular size="40" width="4" indeterminate />
		</div>
		<div v-show="!loading">
			<div class="cart-preview-slug__link-wrapper">
				<div v-if="!isEditMode" class="cart-preview-slug__link">{{ currentHost() + '/carts/' + slugRef + '/preview' }}</div>
				<div v-if="isEditMode" class="cart-preview-slug__input-wrapper">
					<MTextField
						id="cart-slug-input"
						v-model="slugRef"
						name="slug"
					/>
				</div>
				<div v-if="!isEditMode && (status === CREATE_CART_STATUS_DRAFT || status === CREATE_CART_STATUS_RESIGNED)" class="cart-preview-slug__edit" @click="openEditMode">
					<MIcon icon="pencil" :color="white" />
				</div>
				<div v-if="isEditMode" class="cart-preview-slug__approve-wrapper">
					<MIcon icon="tick" :color="green400" @click="saveChangeSlug(true)" />
					<MIcon icon="close" :color="secondary400" @click="saveChangeSlug(false)" />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineProps, ref, watch } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import CartApi from '@/api/v2/CartApi';
import variables from '@/assets/scss/modules/variables.module.scss';
import { CREATE_CART_STATUS_DRAFT, CREATE_CART_STATUS_RESIGNED } from '@/hooks/CartHooks';
import type { TCreateEditCartRequest } from '@/models/CartModel';
import MIcon from '@/components/atoms/MIcon.vue';
import MTextField from '@/components/atoms/MTextField.vue';

interface IOwnProps {
	slug: string | null
	uuid: string | null
	status: string | null
	assignedId: number | null
	campaignId: number | null
	loading: boolean
}
interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();
const { t } = useI18n();
const { white, green400, secondary400 } = variables;

const isEditMode = ref<boolean>(false);
const slugRef = ref<string | null>(null);

watch((props), (val) => {
	if (null != val.slug) {
		slugRef.value = val.slug;
	}
},{
	deep: true,
});

const currentHost = () => {
	return import.meta.env.VITE_MONOLITH_API_HOST;
};

const openEditMode = () => {
	isEditMode.value = true;
};

const cartApi = new CartApi();
const saveChangeSlug = async(isSavedSlug: boolean) => {
	if (!isSavedSlug) {
		slugRef.value = props.slug;
		isEditMode.value = false;
		return;
	}
	
	const submitForm:TCreateEditCartRequest = {
		assigned_id: props.assignedId,
		campaign_id: props.campaignId,
		multiple: null == props.assignedId,
		slug: slugRef.value,
	};
	
	try {
		const result = await cartApi.updateCart(submitForm, props.uuid);
		if (!result.isSuccess) {
			if (result.payload.code === 409) {
				toast.error(`${t('basket.create.toast.takenSlug')}`);
				slugRef.value = props.slug;
				isEditMode.value = false;
				return;
			}
			toast.error(`${t('basket.create.toast.updateCartError')}`);
			slugRef.value = props.slug;
			isEditMode.value = false;
			return;
		} else {
			await router.push(`/carts/${slugRef.value}/preview`);
			toast.success(`${t('basket.create.toast.updateCartSuccess')}`);
			isEditMode.value = false;
		}
	} catch (e) {
		toast.error(`${t('basket.create.toast.updateCartError')}`);
		slugRef.value = props.slug;
		isEditMode.value = false;
		return;
	}
};
</script>

<style scoped lang="scss">
.cart-preview-slug {
	background-color: $white-100;
	padding: 15px 20px;
	border-radius: 8px;
	margin-top: 13px;
	
	&__loading {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100px;
	}
	
	&__title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: 700;
		margin-bottom: 10px;
	}
	
	&__link-wrapper {
		display: flex;
		align-items: center;
		gap: 15px;
	}
	
	&__link {
		font-size: 16px;
		font-weight: 700;
		word-break: break-all;
	}
	
	&__edit {
		background-color: $grey-300;
		padding: 8px 8px 4px 9px;
		border-radius: 50%;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__input-wrapper {
		width: 100%;
		
		&:deep(.v-input__details) {
			display: none;
		}
	}
	
	&__approve-wrapper {
		display: flex;
		align-items: center;
		gap: 5px;
		
		svg {
			cursor: pointer;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
}
</style>
