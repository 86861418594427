<template>
	<div class="basket-summary">
		<div class="basket-summary__wrapper">
			<div class="basket-summary__first-wrapper">
				<HeadingLabel
					icon="basket"
					width="32"
					height="29"
					:text="$t('basket.summary.paymentSummary')"
				/>
				<MProgressBar v-show="loading" indeterminate height="2" />
				<CartSummaryList v-if="order" :order="order"/>
			</div>
			
			<div class="basket-summary__second-wrapper">
				<HeadingLabel
					:letter="userCurrencySymbol"
					:color="white"
					:bgc="primary400"
					:text="$t('basket.summary.choosePaymentMethod')"
				/>
				<MProgressBar v-show="loading" indeterminate height="2" />
				<div v-if="order">
					<MButton
						id="payment-pay-pal"
						v-if="null != user && null == user.agency_id && null != userWalletValue"
						class="basket-summary__btn"
						:label="$t('basket.summary.paymentThroughPayPal')"
						:green400="selectedPaymentMethod === PAYMENT_METHOD_PAYPAL"
						:blue400="selectedPaymentMethod !== PAYMENT_METHOD_PAYPAL"
						:disabled="(order.gross / 100) <= userWalletValue && DEPOSIT_REQUEST !== order.order_items[0].orderable_type"
						@click="choosePaymentMethod(PAYMENT_METHOD_PAYPAL)"
					>
						<template v-slot:default>
							<div class="basket-summary__payment-icon-wrapper">
								<MIcon icon="paypal" width="18" height="18" :color="white" />
							</div>
						</template>
					</MButton>
					<CartPaymentMethodWindow v-show="selectedPaymentMethod === PAYMENT_METHOD_PAYPAL" :order="order" :payment-title="$t('basket.summary.payPal')" />
					
					<MButton
						id="payment-autopay"
						v-if="null != user && null == user.agency_id && null != userWalletValue"
						class="basket-summary__btn"
						:label="$t('basket.summary.paymentThroughAutopay')"
						:green400="selectedPaymentMethod === PAYMENT_METHOD_AUTOPAY"
						:blue400="selectedPaymentMethod !== PAYMENT_METHOD_AUTOPAY"
						:disabled="(order.gross / 100) <= userWalletValue && DEPOSIT_REQUEST !== order.order_items[0].orderable_type"
						@click="choosePaymentMethod(PAYMENT_METHOD_AUTOPAY)"
					>
						<template v-slot:default>
							<div class="basket-summary__payment-icon-wrapper">
								<MIcon icon="autopay-logo" width="93" height="21" :color="white" />
							</div>
						</template>
					</MButton>
					<div class="basket-summary__payment-icon-wrapper basket-summary__payment-icon-wrapper--autopay" :class="{'basket-summary__payment-icon-wrapper--autopay-active': selectedPaymentMethod === PAYMENT_METHOD_AUTOPAY}">
						<img :src="autopayBanners" alt="autopay-icon" />
					</div>
					<div v-show="selectedPaymentMethod === PAYMENT_METHOD_AUTOPAY" class="basket-summary__payment-autopay-checkbox-wrapper" :class="{'basket-summary__payment-autopay-checkbox-wrapper--autopay': selectedPaymentMethod === PAYMENT_METHOD_AUTOPAY}">
						<MCheckbox
							id="autopay-rules"
							v-model="isAutopayRules"
							name="isAutopayRules"
							:label="$t('basket.summary.isAutopayRules')"
							:color="primary400"
						/>
					</div>
					<CartPaymentMethodWindow v-show="selectedPaymentMethod === PAYMENT_METHOD_AUTOPAY" :order="order" :payment-title="$t('basket.summary.autopay')" />
					
					<MButton
						v-if="order.order_items[0].orderable_type !== DEPOSIT_REQUEST && null != userWalletValue"
						id="payment-wallet"
						class="basket-summary__btn"
						:label="$t('basket.summary.paymentThroughWallet')"
						:green400="selectedPaymentMethod === PAYMENT_METHOD_WALLET"
						:blue400="selectedPaymentMethod !== PAYMENT_METHOD_WALLET"
						:disabled="(order.gross / 100 > userWalletValue)"
						@click="choosePaymentMethod(PAYMENT_METHOD_WALLET)"
					>
						<template v-slot:default>
							<div class="basket-summary__payment-icon-wrapper">
								<MIcon icon="money" width="16" height="16" :color="white" />
							</div>
						</template>
					</MButton>
					<CartPaymentMethodWindow v-show="selectedPaymentMethod === PAYMENT_METHOD_WALLET" :order="order" :payment-title="$t('basket.summary.wallet')" />
				</div>
			</div>
		</div>
		
		<div class="basket-summary__btn-wrapper">
			<MButton
				id="basket-summary-prev-btn"
				blue400
				big
				:label="$t('basket.summary.previous')"
				@click="goBack"
			/>
			<MButton
				id="basket-summary-pay"
				green400
				big
				:label="selectedPaymentMethod === PAYMENT_METHOD_WALLET ? $t('basket.summary.paymentThroughWallet') : selectedPaymentMethod === PAYMENT_METHOD_PAYPAL ? $t('basket.summary.paymentThroughPayPal') : selectedPaymentMethod === PAYMENT_METHOD_AUTOPAY ? $t('basket.summary.paymentThroughAutopay') : $t('basket.summary.paymentChoose')"
				@click="pay"
				:loading="paymentLoader"
				:disabled="selectedPaymentMethod == null || paymentLoader || (selectedPaymentMethod === PAYMENT_METHOD_AUTOPAY && !isAutopayRules)"
			>
				<template v-slot:beforeText>
					<MHexagonIcon
						:color="selectedPaymentMethod === null ? grey400 : green400"
						small
						:bgc="white"
						:letter="userCurrencySymbol"
						class="basket-summary__hexagon"
					/>
				</template>
			</MButton>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import router from '@/router';
import { useUserStore } from '@/stores/user';
import { useDictionaryStore } from '@/stores/dictionary';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import {
	DEPOSIT_REQUEST,
	PAYMENT_METHOD_AUTOPAY,
	PAYMENT_METHOD_PAYPAL,
	PAYMENT_METHOD_WALLET
} from '@/hooks/OrderHooks';
import OrderApi from '@/api/v1/OrderApi';
import PaymentApi from '@/api/v1/PaymentApi';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { IPaymentRequest } from '@/models/PaymentModel';
import type { IOrderBasketTableItem } from '@/models/OrderModel';
import autopayBanners from '@/assets/images/autopay-baners.png';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import CartSummaryList from '@/views/Cart/components/CartSummaryList.vue';
import MButton from '@/components/atoms/MButton.vue';
import CartPaymentMethodWindow from '@/views/Cart/components/CartPaymentMethodWindow.vue';
import MHexagonIcon from '@/components/atoms/MHexagonIcon.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MCheckbox from '@/components/atoms/MCheckbox.vue';

const orderApi = new OrderApi();
const paymentApi = new PaymentApi();

const { userCurrencySymbol, user, userWalletValue } = storeToRefs(useUserStore());
const { currencies } = useDictionaryStore();
const { t } = useI18n();
const { white, primary400, green400, grey400 } = variables;

const loading = ref<boolean>(false);
const isAutopayRules = ref<boolean>(false);
const paymentLoader = ref<boolean>(false);
const order = ref<IOrderBasketTableItem|any>(null);
const orderUuid = ref<string|Array<string>>('');
const selectedPaymentMethod = ref<string|null>(null);
const userCurrencyCode = ref<string|null>(null);

const gtm = {
	pushEvent(event:string, name:string, value:any) {
		if (null != window.dataLayer) {
			window.dataLayer.push({
				event: event,
				[name]: value
			});
		}
	}
};

if (null != router.currentRoute.value.params.orderUuid) {
	orderUuid.value = router.currentRoute.value.params.orderUuid;
}
const orderItemsList = async() => {
	loading.value = true;
	
	if ('' === orderUuid.value) {
		toast.error(`${t('basket.list.toast.orderListError')}`);
		loading.value = false;
		return;
	}
	
	try {
		const result = await orderApi.orderItemsList(orderUuid.value);
		if (!result.isSuccess) {
			toast.error(`${t('basket.list.toast.orderListError')}`);
			loading.value = false;
			return;
		}
		
		order.value = result.payload;
	} catch (e) {
		toast.error(`${t('basket.list.toast.orderListError')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
orderItemsList();

const pay = async() => {
	paymentLoader.value = true;
	
	if ('' === orderUuid.value) {
		toast.error(`${t('basket.list.toast.paymentError')}`);
		loading.value = false;
		return;
	}
	
	const request:IPaymentRequest = {
		method: selectedPaymentMethod.value,
	};
	try {
		const result = await paymentApi.pay(orderUuid.value, request);
		if (!result.isSuccess) {
			toast.error(`${t('basket.list.toast.paymentError')}`);
			paymentLoader.value = false;
			return;
		}
		// Dodanie statystyk do google analytics
		gtm.pushEvent('Order price', 'order_price', (+order.value.gross / 100).toFixed(2));
		const currencyElement = currencies.find(elem => elem.id === user.value?.currency_id);
		if (null != currencyElement) {
			userCurrencyCode.value = currencyElement.currency_code;
		}
		if (null != window.dataLayer) {
			window.dataLayer.push({
				'event': 'purchase',
				'ecommerce': {
					'purchase': {
						'actionField': {
							'id': order.value.id, // Unikalne ID zamówienia
							'revenue': (+order.value.gross / 100).toFixed(2), // Kwota przychodu
							'currency': userCurrencyCode.value // Kod waluty (np. USD, EUR)
						},
						'products': [{
							'name': order.value.name, // Nazwa produktu
							'id': order.value.id, // ID produktu
							'price': (+order.value.gross / 100).toFixed(2), // Cena produktu
						}]
					}
				}
			});
		}
		window.location.href = result.payload.url;
	} catch (e) {
		toast.error(`${t('basket.list.toast.paymentError')}`);
		paymentLoader.value = false;
		return;
	}
};

const choosePaymentMethod = (slug:string) => {
	selectedPaymentMethod.value = slug;
};

const goBack = () => {
	router.go(-1);
};
</script>

<style scoped lang="scss">
.basket-summary {
	&__wrapper {
		display: flex;
		flex-direction: column;
		
		@include media-breakpoint-up(md) {
			flex-direction: row;
		}
	}
	
	&__first-wrapper {
		@include media-breakpoint-up(md) {
			width: 49%;
		}
	}
	
	&__second-wrapper {
		margin-top: 3px;
		
		@include media-breakpoint-up(md) {
			width: 49%;
			margin-top: 0;
			margin-left: auto;
		}
	}
	
	&__btn {
		width: 100%;
		padding: 35px;
		margin-top: 10px;
	}
	
	&__btn-wrapper {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
	}
	
	&__hexagon {
		margin-right: 10px;
	}
	
	&__payment-icon-wrapper {
		margin-top: 3px;
		width: max-content;
		
		&--autopay {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 5px;
			margin-top: 4px;
		}
		
		&--autopay-active {
			background-color: $white-100;
		}
	}
	
	&__payment-autopay-checkbox-wrapper {
		display: flex;
		justify-content: center;
		
		&:deep(.v-input__details) {
			display: none;
		}
		
		&--autopay {
			background-color: $white-100;
		}
	}
}
</style>
