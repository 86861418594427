<template>
	<div class="basic-info">
		<HeadingLabel
			icon="address"
			width="32"
			height="29"
			:text="$t('profile.accountData')"
			modal
			modal-icon="step-plus"
			modal-icon-width="70"
			modal-icon-height="71"
			:modal-icon-color="white"
			:modal-title="$t('profile.modalTitle')"
			:modal-text="$t('profile.modalText')"
		>
			<div class="basic-info__modal-wrapper">
				<div class="basic-info__modal-text basic-info__modal-text--first">{{ $t('profile.modalDescription1') }}</div>
				<div class="basic-info__modal-text">{{ $t('profile.modalDescription2') }}:</div>
				<div class="basic-info__modal-img-wrapper">
					<img src="~@/assets/images/onboarding_profil_1.png" alt="profile data image">
				</div>
				<div class="basic-info__modal-text">
					{{ $t('profile.modalDescription3') }}
				</div>
				<div class="basic-info__modal-img-wrapper">
					<img src="~@/assets/images/onboarding_profil_2.png" alt="account type">
				</div>
				<div class="basic-info__modal-text">
					{{ $t('profile.modalDescription4') }}
				</div>
				<div class="basic-info__modal-img-wrapper">
					<img src="~@/assets/images/onboarding_profil_3.png" alt="country information">
				</div>
				<div class="basic-info__modal-text">
					{{ $t('profile.modalDescription5') }}
				</div>
				<div class="basic-info__modal-img-wrapper">
					<img src="~@/assets/images/onboarding_profil_4.png" alt="address data">
				</div>
				<div class="basic-info__modal-text">
					-----------------------
					<br><br>
					{{ $t('profile.modalDescription6') }}
					<br><br>
					{{ $t('profile.modalDescription7') }}
					<br><br>
					{{ $t('profile.modalDescription8') }}
					<br><br>
					{{ $t('profile.modalDescription9') }}
					<br><br>
					{{ $t('profile.modalDescription10') }}
					<br><br>
					{{ $t('profile.modalDescription11') }}
					<br><br>
					{{ $t('profile.modalDescription12') }}
					<br><br>
					{{ $t('profile.modalDescription13') }}
				</div>
				<div class="basic-info__modal-img-wrapper">
					<img src="~@/assets/images/onboarding_profil_5.png" alt="notification settings">
				</div>
			</div>
		</HeadingLabel>
		
		<MProgressBar v-show="loading" indeterminate height="2" />
		<Form v-if="null != user" :validation-schema="schema" @submit="onSubmit" class="basic-info__edit-list">
			<MTextField
				id="input-first-name"
				v-model="formUserData.first_name"
				name="firstName"
				:separate-label="$t('profile.firstName')"
				label-text-required
				:placeholder="$t('profile.firstNamePlaceholder')"
			/>
			<MTextField
				id="input-last-name"
				v-model="formUserData.last_name"
				name="lastName"
				:separate-label="$t('profile.lastName')"
				label-text-required
				:placeholder="$t('profile.lastNamePlaceholder')"
			/>
			<MTextField
				id="input-email"
				v-model="formUserData.email"
				name="email"
				:separate-label="$t('profile.emailAddress')"
				label-text-required
				:placeholder="$t('profile.emailAddressPlaceholder')"
				disabled
			/>
			<div>
				<div class="basic-info__type">{{ $t('profile.type') }} <span>{{ $t('components.fieldRequired') }}</span></div>
				<v-radio-group v-model="formUserData.is_personal" inline>
					<v-radio :label="$t('profile.individual')" :value="true" @click="updateIsPersonal(true)" :disabled="isProfileLocked(currentUser) && !isAdmin(user) && !isModerator(user) && !isContentWriter(user)" />
					<v-radio :label="$t('profile.company')" :value="false" @click="updateIsPersonal(false)" :disabled="isProfileLocked(currentUser) && !isAdmin(user) && !isModerator(user) && !isContentWriter(user)" />
				</v-radio-group>
			</div>
			<MAutocomplete
				id="input-country"
				name="country"
				v-model="formUserData.country_id"
				:get-items="countries"
				variant="outlined"
				item-title="name"
				item-value="id"
				:label="$t('profile.country')"
				label-text-required
				:placeholder="$t('profile.countryPlaceholder')"
				:disabled="isProfileLocked(currentUser) && !isAdmin(user) && !isModerator(user) && !isContentWriter(user)"
			/>
			<MTextField
				v-if="!formUserData.is_personal"
				id="input-comapny-name"
				v-model="formUserData.company_name"
				name="companyName"
				:separate-label="$t('profile.companyName')"
				label-text-required
				:placeholder="$t('profile.companyNamePlaceholder')"
				:disabled="isProfileLocked(currentUser) && !isAdmin(user) && !isModerator(user) && !isContentWriter(user)"
			/>
			<div v-if="!formUserData.is_personal" class="basic-info__vat">
				<MAutocomplete
					id="input-vat-country"
					name="vatCountry"
					v-model="formUserData.vat_country"
					:get-items="countries"
					variant="outlined"
					item-title="iso_3166_2"
					item-value="iso_3166_2"
					:label="$t('profile.vatCountry')"
					label-text-required
					:placeholder="$t('profile.vatCountryPlaceholder')"
					:disabled="isProfileLocked(currentUser) && !isAdmin(user) && !isModerator(user) && !isContentWriter(user)"
				/>
				<MTextField
					id="input-vat-number"
					v-model="formUserData.vat_no"
					name="vatNo"
					:separate-label="$t('profile.vatNumber')"
					label-text-required
					:placeholder="$t('profile.vatNumberPlaceholder')"
					:disabled="isProfileLocked(currentUser) && !isAdmin(user) && !isModerator(user) && !isContentWriter(user)"
				/>
			</div>
			<MAutocomplete
				id="input-currency"
				name="currency"
				v-model="formUserData.currency_id"
				:get-items="currencies"
				variant="outlined"
				item-title="currency_code"
				item-value="id"
				:label="$t('profile.currency')"
				label-text-required
				:placeholder="$t('profile.currencyPlaceholder')"
				:disabled="(!formUserData.can_change_currency && !isAdmin(user)) || orderList.length > 0 || null != formUserData.agency_id || (isAdmin(user) && null != router.currentRoute.value.params.userUuid && currentUser.accounts.length > 0 && (currentUser.accounts[0].balance > 0 || currentUser.accounts[1].balance > 0))"
			/>
			<MAutocomplete
				v-if="!isModerator(user) && !isContentWriter(user) && ((isAdmin(user) && null != userUuid) || (!isAdmin(user) && null == userUuid) || (!isAdmin(user) && null != userUuid))"
				id="input-dashboard"
				name="dashboard"
				v-model="formUserData.default_role_id"
				:get-items="defaultDashboardItems"
				variant="outlined"
				item-title="name"
				item-value="id"
				:label="$t('profile.defaultDashboard')"
				label-text-required
				:placeholder="$t('profile.defaultDashboard')"
			/>
			<MAutocomplete
				v-if="!isModerator(user) && ((isAdmin(user) && null != userUuid) || (!isAdmin(user) && null == userUuid))"
				id="input-mailing-language"
				name="mailingLanguage"
				v-model="formUserData.language_id"
				:get-items="mailingLanguagesList"
				variant="outlined"
				item-title="name"
				item-value="id"
				:label="$t('profile.language')"
				:placeholder="$t('profile.language')"
			/>
			<MAutocomplete
				v-if="isAdmin(user) && null != userUuid"
				id="input-user-group"
				name="userGroup"
				v-model="formUserData.discount_group_id"
				:get-items="userGroupList"
				variant="outlined"
				item-title="label"
				item-value="id"
				:label="$t('profile.userGroup')"
				:placeholder="$t('profile.userGroup')"
				clearable
			/>
			<div v-if="isAdmin(user) && null != userUuid">
				<div class="basic-info__type">{{ $t('profile.agencyAccount') }}</div>
				<div  class="basic-info__agency-wrapper">
					<MCheckbox
						id="agency"
						name="agency"
						v-model="formUserData.is_agency"
						:label="$t('profile.agencyAccount')"
						:disabled="isAgency"
					/>
					<MTooltip without-btn>
						<template v-slot:title>
							<MIcon icon="info" :color="primary400" height="20" width="20" />
						</template>
						<template v-slot:content>
							<div>{{ $t('profile.agencyInfo') }}</div>
						</template>
					</MTooltip>
				</div>
			</div>
			<div>
				<MButton
					id="btn-edit-profile"
					normal
					green400
					width100
					:label="$t('profile.save')"
					type="submit"
					:loading="loading"
				/>
			</div>
		</Form>
	</div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from 'vue';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { useDictionaryStore } from '@/stores/dictionary';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import UserApi from '@/api/v1/UserApi';
import OrderApi from '@/api/v1/OrderApi';
import variables from '@/assets/scss/modules/variables.module.scss';
import { toast } from 'vue3-toastify';
import {
	ADMIN_ID,
	MODERATOR_ID,
	CONTENT_WRITER_ID,
	PUBLISHER_ID,
	ADVERTISER_ID,
	isAdmin,
	isAdvertiser,
	isContentWriter,
	isModerator
} from '@/hooks/UserHooks';
import {
	isProfileLocked,
	checkAddressProfile,
	ENGLISH_LANGUAGE_ID,
	POLISH_LANGUAGE_ID
} from '@/hooks/ProfileHooks';
import type { UserInterface } from '@/models/AuthModel';
import type { IDiscountGroupList } from '@/models/DiscountGroupsModel';
import type { IOrderBasketTableItem } from '@/models/OrderModel';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import MCheckbox from '@/components/atoms/MCheckbox.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MButton from '@/components/atoms/MButton.vue';

interface IOwnProps {
	currentUser: UserInterface
	isAgency: boolean
}

interface IProps extends IOwnProps {
}

const userApi = new UserApi();
const orderApi = new OrderApi();

const props = defineProps<IProps>();
const emit = defineEmits(['userDetails']);

const { user } = storeToRefs(useUserStore());
const { setUserCurrencySymbol } = useUserStore();
const { t } = useI18n();
const { countries, currencies } = useDictionaryStore();
const { white, primary400 } = variables;

const formUserData = ref({
	first_name: props.currentUser.first_name,
	last_name: props.currentUser.last_name,
	email: props.currentUser.email,
	is_personal: props.currentUser.is_personal,
	country_id: props.currentUser.country_id,
	company_name: props.currentUser.company_name,
	vat_country: props.currentUser.vat_country,
	vat_no: props.currentUser.vat_no,
	currency_id: props.currentUser.currency_id,
	default_role_id: props.currentUser.default_role_id,
	language_id: props.currentUser.language_id,
	discount_group_id: props.currentUser.discount_group_id,
	is_agency: props.currentUser.is_agency,
	can_change_currency: props.currentUser.can_change_currency,
	agency_id: props.currentUser.agency_id,
});
const defaultDashboardItems = ref<Array<any>>([
	{
		id: ADVERTISER_ID,
		name: `${t('profile.advertiser')}`,
	},
	{
		id: PUBLISHER_ID,
		name: `${t('profile.publisher')}`,
	},
]);
const mailingLanguagesList = ref<Array<any>>([
	{
		id: ENGLISH_LANGUAGE_ID,
		name: `${t('languages.english')}`,
	},
	{
		id: POLISH_LANGUAGE_ID,
		name: `${t('languages.polish')}`,
	},
]);
const userGroupList = ref<Array<IDiscountGroupList>>([]);
const orderList = ref<Array<IOrderBasketTableItem>|any>([]);
const loading = ref<boolean>(false);
const userUuid = ref<string|Array<string>|null>(null);

const schema:any = yup.object({
	firstName: yup.string().required(`${t('profile.validation.firstNameRequired')}`).min(2, `${t('profile.validation.min2')}`).max(100, `${t('profile.validation.max100')}`).label('firstName'),
	lastName: yup.string().required(`${t('profile.validation.lastNameRequired')}`).min(2, `${t('profile.validation.min2')}`).max(100, `${t('profile.validation.max100')}`).label('lastName'),
	email: yup.string().required(`${t('profile.validation.emailRequired')}`).email(`${t('profile.validation.email')}`).label('email'),
	country: yup.number().required(`${t('profile.validation.countryRequired')}`).label('country'),
	companyName: yup.string(),
	vatCountry: yup.string(),
	vatNo: yup.string(),
	currency: yup.number().required(`${t('profile.validation.currencyRequired')}`).label('currency'),
	dashboard: yup.number().required(`${t('profile.validation.dashboardRequired')}`).label('dashboard'),
});

if (null != router.currentRoute.value.params.userUuid) {
	userUuid.value = router.currentRoute.value.params.userUuid;
}
const updateIsPersonal = (isPersonal:boolean) => {
	if (isPersonal) {
		formUserData.value.company_name = null;
		formUserData.value.vat_country = null;
		formUserData.value.vat_no = null;
		schema.fields['companyName'] = yup.string();
		schema.fields['vatCountry'] = yup.string();
		schema.fields['vatNo'] = yup.string();
	} else {
		schema.fields['companyName'] = yup.string().required(`${t('profile.validation.companyNameRequired')}`).label('companyName');
		schema.fields['vatCountry'] = yup.string().required(`${t('profile.validation.vatCountryRequired')}`).label('vatCountry');
		schema.fields['vatNo'] = yup.string().required(`${t('profile.validation.vatCountryRequired')}`).label('vatCountry');
	}
};

const userGroups = async() => {
	try {
		const result = await userApi.userGroupList();
		if (!result.isSuccess) {
			toast.error(`${t('profile.toast.userGroupError')}`);
			return;
		}
		userGroupList.value = result.payload;
		userGroupList.value.push({
			id: null,
			label: `${t('components.unknown')}`,
			slug: null,
			description: null,
			created_at: null,
			updated_at: null,
		});
	} catch (e) {
		toast.error(`${t('profile.toast.userGroupError')}`);
		return;
	}
};
if (null != user.value && isAdmin(user.value) && null != userUuid.value) {
	userGroups();
}

const getOrderList = async() => {
	try {
		const result = await orderApi.orderBasketList(0);
		if (!result.isSuccess) {
			toast.error(`${t('profile.toast.errorBasketList')}`);
			return;
		}
		orderList.value = result.payload;
	} catch (e) {
		toast.error(`${t('profile.toast.errorBasketList')}`);
		return;
	}
};
if (null != user.value && isAdvertiser(user.value) && isProfileLocked(user.value) && !checkAddressProfile(user.value) && null == userUuid.value) {
	getOrderList();
}

const onSubmit = async() => {
	loading.value = true;
	
	if (null != user.value && null == userUuid.value && isAdmin(user.value)) {
		formUserData.value.default_role_id = ADMIN_ID;
	}
	if (null != user.value && null == userUuid.value && isModerator(user.value)) {
		formUserData.value.default_role_id = MODERATOR_ID;
	}
	if (null != user.value && null == userUuid.value && isContentWriter(user.value)) {
		formUserData.value.default_role_id = CONTENT_WRITER_ID;
	}
	
	let request:any = {};
	if (!formUserData.value.is_personal) {
		request = {
			first_name: formUserData.value.first_name,
			last_name: formUserData.value.last_name,
			email: formUserData.value.email,
			is_personal: formUserData.value.is_personal,
			company_name: formUserData.value.company_name,
			vat_country: formUserData.value.vat_country,
			vat_no: formUserData.value.vat_no,
			country_id: formUserData.value.country_id,
			currency_id: formUserData.value.currency_id,
			default_role_id: formUserData.value.default_role_id,
			language_id: formUserData.value.language_id,
			...(null != user.value && null != userUuid.value && isAdmin(user.value)
				? { is_agency: formUserData.value.is_agency ? 1 : 0 }
				: null),
			...(null != user.value && null != userUuid.value && isAdmin(user.value)
				? { discount_group_id: formUserData.value.discount_group_id }
				: null),
		};
	} else {
		request = {
			first_name: formUserData.value.first_name,
			last_name: formUserData.value.last_name,
			email: formUserData.value.email,
			is_personal: formUserData.value.is_personal,
			country_id: formUserData.value.country_id,
			currency_id: formUserData.value.currency_id,
			default_role_id: formUserData.value.default_role_id,
			language_id: formUserData.value.language_id,
			...(null != user.value && null != userUuid.value && isAdmin(user.value)
				? { is_agency: formUserData.value.is_agency ? 1 : 0 }
				: null),
			...(null != user.value && null != userUuid.value && isAdmin(user.value)
				? { discount_group_id: formUserData.value.discount_group_id }
				: null),
		};
	}
	try {
		const result = await userApi.updateProfile(request, null != userUuid.value ? props.currentUser.uuid : null);
		if (!result.isSuccess) {
			toast.error(`${t('profile.toast.errorUpdateProfile')}`);
			loading.value = false;
			return;
		}
		toast.success(`${t('auth.toast.success')}`);
		const currencySymbol = currencies.find(elem => elem.id === formUserData.value.currency_id);
		setUserCurrencySymbol(null != currencySymbol ? currencySymbol.currency_symbol : null);
		emit('userDetails');
	} catch (e) {
		toast.error(`${t('profile.toast.errorUpdateProfile')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
</script>

<style scoped lang="scss">
.basic-info {
	&__modal-img-wrapper {
		display: flex;
		justify-content: center;
		margin: 20px 0;
		width: 90%;
		
		@media (min-width: 992px) {
			width: max-content;
			margin: 20px auto;
		}
		
		img {
			width: 100%;
		}
	}
	
	&__modal-text {
		&--first {
			margin: 20px 0;
		}
	}
	
	&__edit-list {
		display: flex;
		flex-direction: column;
		gap: 7px;
		background-color: $white-100;
		margin-top: 3px;
		padding: 20px 10px;
		
		@include media-breakpoint-up(sm) {
			padding: 20px 20px;
		}
	}
	
	&__type {
		font-size: 14px;
		color: $grey-400;
		font-weight: 700;
		margin-bottom: 10px;
		
		span {
			color: $secondary-400;
		}
	}
	
	&__vat {
		display: flex;
		align-items: center;
		gap: 15px;
		
		&:deep(.m-autocomplete) {
			width: 230px;
		}
	}
	
	&__agency-wrapper {
		display: flex;
		gap: 15px;
		align-items: center;
		margin: 15px 0 30px 0;
		
		&:deep(.m-checkbox) {
			margin-left: -10px;
		}
		
		&:deep(.v-input__details) {
			display: none;
		}
	}
	
	&:deep(.v-radio-group) {
		margin-left: -10px;
	}
	
	&:deep(.v-selection-control__input) {
		color: $secondary-400;
	}
	
	&:deep(.v-autocomplete .v-input__details) {
		padding: 0;
	}
}
</style>
