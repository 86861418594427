<template>
    <div class="footer" :class="{'footer--login': isBeforeAuthorized}">
	    <div class="footer__wrapper">
	        <div class="footer__link-wrapper">
	            <a href="mailto:help@marketin9.com" class="footer__link footer__link--mail-to">{{ $t('auth.contact') }}</a>
	            <a href="/static/termOfUse" target="_blank" class="footer__link">{{ $t('auth.termOfUse') }}</a>
	            <a href="/static/privacyPolicy" target="_blank" class="footer__link">{{ $t('auth.privacy') }}</a>
	            <a href="/partners" target="_blank" class="footer__link">{{ $t('auth.partners') }}</a>
	        </div>
	
	        <div class="footer__info-wrapper" :class="{'footer__info-wrapper--version-login-page': isBeforeAuthorized}">
	            <div class="footer__text footer__text--version">v.{{ version }}</div>
	            <div class="footer__text">© {{ dayjs().format('YYYY') }} {{ $t('auth.orion') }}</div>
	        </div>
	    </div>
    </div>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue';
import versionText from '../../../../../VERSION.json';
import dayjs from 'dayjs';

interface IOwnProps {
    isBeforeAuthorized?: boolean
}
interface IProps extends IOwnProps {
}

defineProps<IProps>();

const version = ref<string>(versionText.version);
</script>

<style scoped lang="scss">
.footer {
    display: flex;
    border-top: 1px solid $primary-400;
	height: 42px;
    
    &--login {
        width: 100vw !important;
        background-color: $white-100 !important;
        display: flex;
        align-items: center;
        height: auto;
        flex-direction: column;
        
        @include media-breakpoint-up(sm) {
            flex-direction: row;
	        justify-content: center;
        }
	    
	    &--version-login-page {
		    margin-left: auto;
	    }
    }
	
	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 100%;
		padding: 10px 15px;
		
		@include media-breakpoint-up(md) {
			flex-direction: row;
			align-items: flex-end;
			justify-content: space-around;
		}
	}
    
    &__link-wrapper {
        display: flex;
	    gap: 10px;
        align-items: center;
	    justify-content: center;
	    flex-wrap: wrap;
	    
	    @include media-breakpoint-up(lg) {
		    position: relative;
		    left: 50%;
		    transform: translateX(-50%);
	    }
    }
	
	&__info-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		
		@include media-breakpoint-up(md) {
			margin-left: auto;
			justify-content: flex-end;
		}
    }
    
    &__link {
        font-size: 12px;
        color: $primary-400;
        text-decoration: none;
        transition: opacity 0.3s;
        
        @include media-breakpoint-up(sm) {
            font-size: 14px;
        }
        
        &:hover {
            opacity: 0.5;
        }
	    
	    &--mail-to {
		    width: 100%;
		    text-align: center;
		    
		    @include media-breakpoint-up(sm) {
			    width: max-content;
		    }
	    }
    }
    
    &__text {
        margin-right: 15px;
        
        @include media-breakpoint-up(md) {
            margin-right: 25px;
        }
        
        &--version {
	        @include media-breakpoint-up(md) {
		        margin-left: auto;
	        }
        }
    }
}
</style>
