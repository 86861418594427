<template>
	<div class="user-comments">
		<HeadingLabel
			icon="chat"
			width="32"
			height="29"
			:text="$t('profile.userNotes')"
			btn
			blue400
			:btn-text="!isEditMode ? $t('profile.edit') : $t('profile.cancel')"
			:is-button-click="toggleEditMode"
		/>
		<div v-if="!isEditMode && null != comment" v-html="comment" class="user-comments__note"></div>
		<div v-if="!isEditMode && null == comment" class="user-comments__note user-comments__note--center">{{ $t('profile.noComments') }}</div>
		<MVueEditor v-if="isEditMode" id="user-comments" name="userComments" v-model="comment" />
		<MButton v-if="isEditMode" id="save-user-comments" big green400 :label="$t('profile.save')" :loading="loading" @click="saveComments" />
	</div>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import UserApi from '@/api/v1/UserApi';
import type { UserInterface } from '@/models/AuthModel';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MVueEditor from '@/components/atoms/MVueEditor.vue';
import MButton from '@/components/atoms/MButton.vue';

interface IOwnProps {
	user: UserInterface
}
interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();

const { t } = useI18n();
const userApi = new UserApi();

const isEditMode = ref<boolean>(false);
const loading = ref<boolean>(false);
const comment = ref<string|null>(props.user.note);

const toggleEditMode = () => {
	isEditMode.value = !isEditMode.value;
};

const saveComments = async() => {
	loading.value = true;
	
	const form:any = {
		note: comment.value,
	};
	try {
		const result = await userApi.updateProfile(form, props.user.uuid);
		if (!result.isSuccess) {
			toast.error(`${t('profile.toast.updateNote')}`);
			loading.value = false;
			return;
		}
		toast.success(`${t('profile.toast.updateNote')}`);
	} catch (e) {
		toast.error(`${t('profile.toast.updateNote')}`);
		loading.value = false;
		return;
	}
	toggleEditMode();
	loading.value = false;
};
</script>

<style scoped lang="scss">
.user-comments {
	&__note {
		padding: 15px;
		width: 100%;
		background-color: $white-100;
		border-radius: 6px;
		border: 1px solid $primary-400;
		margin-top: 3px;
		
		&--center {
			display: flex;
			justify-content: center;
		}
	}
}
</style>
