import { getStrPersisted, getObjPersisted } from '@/common/utils/LocalStorage'
import { getLocale } from '@/common/utils/DateTimeService'
import { type ApiOptions } from '@/api/types'
import { AxiosError, type AxiosResponse } from 'axios'

export type ErrorResponse = {
    message: string|null,
}

// export const getToastError = (content: any): string => {
export const getToastError = (error: ErrorResponse) => {
    console.debug('getToastError', error)
	return error.data.error.message ? error.data.error.message : 'Something went wrong';
}

export function isResponseAxios(obj: AxiosResponse | any): obj is AxiosResponse {
    if (obj.data) return obj;
    return false;
}

export function isErrorAxios(obj: AxiosError | any): obj is AxiosError {
    if (obj.isAxiosError) return obj;
    return true;
}

export const responseHandler = (
    isSuccess: boolean,
    options: ApiOptions = { suppressError: false },
) => (content: AxiosResponse<any> | AxiosError<any>) => {
    let payload;
    let status = isSuccess ? 200 : 0;

    if (isSuccess && isResponseAxios(content)) {
        payload = content.data;
        status = content?.status || 200;
    }

    if (!isSuccess && isErrorAxios(content) && content.response) {
        payload = content.response.data || content;
        status = content?.response?.status;
    }

    const raiseToast = options == null || !options.suppressError;
    if (!isSuccess && raiseToast) {
        // toast.error(getToastError(content));
    }

    return {
        status,
        isSuccess,
        payload,
    };
};

export const successResult = <T>(content: AxiosResponse<T>) => responseHandler(true)(content);

export const failureResult = <T>(
    message: AxiosError<T>,
    options?: ApiOptions
) => responseHandler(false, options)(message)

export const basicToken = (): string | null => {
    const token = import.meta.env.VITE_API_BASIC_WEBSITES_TOKEN;
    return token
}

export const getTimeZoneHeader = (): Record<string, string> => {
    const userTimeZone = getObjPersisted('user_timezone')
    if (null == userTimeZone) return {}
    return {
        'USER-TIMEZONE': userTimeZone
    }
}

export const getLocaleHeader = (): Record<string, string> => {
    const userLocale = getLocale()
    if (null == userLocale) return {}
    return {
        'accept-language': userLocale
    }
}
