<template>
	<div class="price-list-table">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			show-expand
			:expanded="expanded"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template v-slot:loading>
				<div class="price-list-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #top>
				<div class="price-list-table__search-wrapper">
					<MTextField
						v-model="search"
						name="search"
						:label="$t('pricelist.search')"
						:placeholder="$t('pricelist.search')"
						clearable
					/>
				</div>
			</template>
			
			<template #item.websites.url="elem">
				<div v-if="elem.item.raw.website.url != null" class="m9-data-table-align-left">{{ elem.item.raw.website.url }}</div>
			</template>
			
			<template #item.website_offers.url="elem">
				<div v-if="elem.item.raw.url != null" class="m9-data-table-align-left">{{ elem.item.raw.url }}</div>
			</template>
			
			<template #item.website_offers.price="elem">
				<div v-if="elem.item.raw.price != null" class="m9-data-table-align-price">{{ (elem.item.raw.price).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }}</div>
			</template>
			
			<template #item.finalPrice="elem">
				<div v-if="elem.item.raw.net != null" class="m9-data-table-align-price">{{ (elem.item.raw.net / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }}</div>
				<div v-if="elem.item.raw.share_value != null && elem.item.raw.share_type === MARGIN_TYPE_PERCENT" class="price-list-table__margin--red m9-data-table-align-price">({{ elem.item.raw.share_value }}%)</div>
				<div v-if="elem.item.raw.share_value != null && elem.item.raw.share_type === MARGIN_TYPE_CASH" class="price-list-table__margin--red m9-data-table-align-price">({{ (elem.item.raw.share_value).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }})</div>
				<div v-if="elem.item.raw.share_value == null && elem.item.raw.default_share != null" class="price-list-table__margin--red m9-data-table-align-price">({{ elem.item.raw.default_share }}%)</div>
				<div v-if="elem.item.raw.net != null && elem.item.raw.price != null" class="price-list-table__margin m9-data-table-align-price">({{ ((elem.item.raw.net / 100) - elem.item.raw.price).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }})</div>
			</template>
			
			<template #item.finalPriceGroup1="elem">
				<div v-if="elem.item.raw['group_share_1_shares'] != null" class="m9-data-table-align-price">
					<div>{{ (elem.item.raw['group_share_1_shares'].net / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }}</div>
					<div class="price-list-table__margin price-list-table__margin--red">({{ marginGroupValue(1, elem.item.raw.discount_groups) }}%)</div>
					<div class="price-list-table__margin">({{ (elem.item.raw['group_share_1_shares'].default_share_value / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }})</div>
				</div>
				<div v-if="elem.item.raw['group_share_1_shares'] == null"> - </div>
			</template>
			
			<template #item.finalPriceGroup2="elem">
				<div v-if="elem.item.raw['group_share_2_shares'] != null" class="m9-data-table-align-price">
					<div>{{ (elem.item.raw['group_share_2_shares'].net / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }}</div>
					<div class="price-list-table__margin price-list-table__margin--red">({{ marginGroupValue(2, elem.item.raw.discount_groups) }}%)</div>
					<div class="price-list-table__margin">({{ (elem.item.raw['group_share_2_shares'].default_share_value / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }})</div>
				</div>
				<div v-if="elem.item.raw['group_share_2_shares'] == null"> - </div>
			</template>
			
			<template #item.finalPriceGroup3="elem">
				<div v-if="elem.item.raw['group_share_3_shares'] != null" class="m9-data-table-align-price">
					<div>{{ (elem.item.raw['group_share_3_shares'].net / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }}</div>
					<div class="price-list-table__margin price-list-table__margin--red">({{ marginGroupValue(3, elem.item.raw.discount_groups) }}%)</div>
					<div class="price-list-table__margin">({{ (elem.item.raw['group_share_3_shares'].default_share_value / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }})</div>
				</div>
				<div v-if="elem.item.raw['group_share_3_shares'] == null"> - </div>
			</template>
			
			<template #item.finalPriceGroup4="elem">
				<div v-if="elem.item.raw['group_share_4_shares'] != null" class="m9-data-table-align-price">
					<div>{{ (elem.item.raw['group_share_4_shares'].net / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }}</div>
					<div class="price-list-table__margin price-list-table__margin--red">({{ marginGroupValue(4, elem.item.raw.discount_groups) }}%)</div>
					<div class="price-list-table__margin">({{ (elem.item.raw['group_share_4_shares'].default_share_value / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }})</div>
				</div>
				<div v-if="elem.item.raw['group_share_4_shares'] == null"> - </div>
			</template>
			
			<template #item.finalPriceGroup5="elem">
				<div v-if="elem.item.raw['group_share_5_shares'] != null" class="m9-data-table-align-price">
					<div>{{ (elem.item.raw['group_share_5_shares'].net / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }}</div>
					<div class="price-list-table__margin price-list-table__margin--red">({{ marginGroupValue(5, elem.item.raw.discount_groups) }}%)</div>
					<div class="price-list-table__margin">({{ (elem.item.raw['group_share_5_shares'].default_share_value / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }})</div>
				</div>
				<div v-if="elem.item.raw['group_share_5_shares'] == null"> - </div>
			</template>
			
			<template #item.usersWithDiscount="elem">
				<div v-if="elem.item.raw.individual_price_users != null">{{ elem.item.raw.individual_price_users }}</div>
			</template>
			
			<template #footer.prepend>
				<div class="price-list-table__footer-btn-wrapper">
					<MIcon icon="csv-file" width="20" height="20" @click="downloadCSV" />
				</div>
			</template>
			
			<template v-slot:item.data-table-expand="{ item }">
				<div v-if="!expanded.includes(item.value)" class="price-list-table__expand-action-wrapper">
					<MTooltip without-btn>
						<template v-slot:title>
							<div @click="expand(true, item, false)">
								<MIcon icon="arrow-down" width="13" height="13" :color="white" class="price-list-table__arrow" />
							</div>
						</template>
						<template v-slot:content>
							<div>{{ $t('pricelist.more') }}</div>
						</template>
					</MTooltip>
				</div>
				<div v-if="expanded.includes(item.value)" class="price-list-table__expand-action-wrapper">
					<MTooltip without-btn>
						<template v-slot:title>
							<div @click="expand(true, item, true)">
								<MIcon icon="arrow-down" width="13" height="13" :color="white" class="price-list-table__arrow--rotate" />
							</div>
						</template>
						<template v-slot:content>
							<div>{{ $t('pricelist.hide') }}</div>
						</template>
					</MTooltip>
				</div>
			</template>
			
			<template v-slot:expanded-row="{ columns }">
				<td :colspan="columns.length" class="price-list-table__expand-table">
					<v-data-table-server
						theme="dark"
						:headers="headersExpanded"
						:items="itemsExpanded"
						:loading="loadingExpanded"
						:loading-text="$t('components.loading')"
						:no-data-text="$t('components.noDataText')"
						:items-per-page-text="`${$t('components.rowsPerPage')}`"
						:page-text="`${currentPageExpanded} ${$t('components.of')} ${lastPageExpanded}`"
						:items-per-page-options="itemsPerPageOptions"
						:options="optionsExpanded"
						@update:options="optionsUpdateExpanded"
						:items-length="totalExpanded"
						hover
						hide-default-footer
					>
						<template v-slot:loading>
							<div class="price-list-table__loading">{{ $t('components.loading') }}</div>
						</template>
						
						<template #top>
							<div class="price-list-table__search-wrapper">
								<MTextField
									v-model="searchExpanded"
									name="searchExpanded"
									:label="$t('pricelist.search')"
									:placeholder="$t('pricelist.search')"
									clearable
								/>
							</div>
						</template>
						
						<template #item.first_name="elem">
							<div v-if="elem.item.raw.first_name != null && elem.item.raw.last_name != null">{{ elem.item.raw.first_name }} {{ elem.item.raw.last_name }}</div>
						</template>
						
						<template #item.discount_group_id="elem">
							<div v-if="elem.item.raw.discount_group != null">{{ elem.item.raw.discount_group.label }}</div>
							<div v-if="elem.item.raw.discount_group == null">-</div>
						</template>
						
						<template #item.percent="elem">
							<div v-if="elem.item.raw.offer_shares != null && elem.item.raw.offer_shares.group_share != null" class="price-list-table__margin">
								<div class="price-list-table__tooltip-wrapper">
									<div>{{ elem.item.raw.offer_shares.group_share }}% ({{ (elem.item.raw.base_price * elem.item.raw.offer_shares.group_share / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }})</div>
									<div class="price-list-table__tooltip">
										<MTooltip without-btn :max-width="400">
											<template v-slot:title>
												<MIcon icon="info" width="14" height="14" />
											</template>
											<template v-slot:content>
												<div>{{ $t('pricelist.groupTooltipInfo') }}</div>
											</template>
										</MTooltip>
									</div>
								</div>
								<div v-if="0 !== elem.item.raw.offer_shares.user_share_value && elem.item.raw.margin.type === MARGIN_TYPE_CASH" class="price-list-table__margin--red price-list-table__tooltip-wrapper">
									<div>-{{ (elem.item.raw.offer_shares.user_share_value / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }}</div>
									<div class="price-list-table__tooltip">
										<MTooltip without-btn :max-width="400">
											<template v-slot:title>
												<MIcon icon="info" width="14" height="14" />
											</template>
											<template v-slot:content>
												<div>{{ $t('pricelist.discountTooltipInfo') }}</div>
											</template>
										</MTooltip>
									</div>
								</div>
							</div>
							<div v-if="elem.item.raw.offer_shares != null && (elem.item.raw.offer_shares.group_share === 0 || elem.item.raw.offer_shares.group_share === null) && elem.item.raw.margin != null && elem.item.raw.margin.type === MARGIN_TYPE_CASH && elem.item.raw.offer_shares.group_share !== 0" class="price-list-table__margin--red price-list-table__tooltip-wrapper">
								<div>-{{ (elem.item.raw.margin.value).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }}</div>
								<div class="price-list-table__tooltip">
									<MTooltip without-btn :max-width="400">
										<template v-slot:title>
											<MIcon icon="info" width="14" height="14" />
										</template>
										<template v-slot:content>
											<div>{{ $t('pricelist.discountTooltipInfo') }}</div>
										</template>
									</MTooltip>
								</div>
							</div>
							<div v-if="elem.item.raw.offer_shares != null && elem.item.raw.margin != null && elem.item.raw.margin.type !== MARGIN_TYPE_CASH && 0 !== elem.item.raw.offer_shares.user_share_value && elem.item.raw.offer_shares.group_share !== 0" class="price-list-table__margin--red price-list-table__tooltip-wrapper">
								<div>-{{ elem.item.raw.margin.value }}% ({{ (elem.item.raw.offer_shares.user_share_value / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }})</div>
								<div class="price-list-table__tooltip">
									<MTooltip without-btn :max-width="400">
										<template v-slot:title>
											<MIcon icon="info" width="14" height="14" />
										</template>
										<template v-slot:content>
											<div>{{ $t('pricelist.discountTooltipInfo') }}</div>
										</template>
									</MTooltip>
								</div>
							</div>
							<div v-if="elem.item.raw.offer_shares != null && 0 === elem.item.raw.offer_shares.user_share_value && elem.item.raw.margin != null && elem.item.raw.margin.value !== 0">-</div>
						</template>
						
						<template #item.base_price="elem">
							<div v-if="elem.item.raw.base_price != null" class="price-list-table__margin--bold m9-data-table-align-price">{{ (elem.item.raw.base_price).toFixed(2) }} {{ currencySymbol(elem.item.raw.base_currency_id) }}</div>
						</template>
						
						<template #item.base_price_with_default_margin="elem">
							<div v-if="elem.item.raw.base_net != null" class="price-list-table__margin m9-data-table-align-price">{{ (elem.item.raw.base_net / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.base_currency_id) }}</div>
						</template>
						
						<template #item.net="elem">
							<div v-if="elem.item.raw.offer_shares.net != null" class="price-list-table__margin--bold m9-data-table-align-price">{{ (elem.item.raw.offer_shares.net / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }}</div>
						</template>
						
						<template #item.vat_rate="elem">
							<div v-if="elem.item.raw.offer_shares.tax_rate != null && elem.item.raw.offer_shares.tax != null">
								<div class="price-list-table__margin--red">{{ elem.item.raw.offer_shares.tax_rate }}%</div>
								<div class="price-list-table__margin--bold">({{ (elem.item.raw.offer_shares.tax / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }})</div>
							</div>
						</template>
						
						<template #item.gross="elem">
							<div v-if="elem.item.raw.offer_shares.gross != null" class="price-list-table__margin m9-data-table-align-price">{{ (elem.item.raw.offer_shares.gross / 100).toFixed(2) }} {{ currencySymbol(elem.item.raw.currency_id) }}</div>
						</template>
					</v-data-table-server>
				</td>
			</template>
		</v-data-table-server>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { ref, watch } from 'vue';
import router from '@/router';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useDictionaryStore } from '@/stores/dictionary';
import variables from '@/assets/scss/modules/variables.module.scss';
import PriceListApi from '@/api/v1/PriceListApi';
import { isAdmin, isModerator } from '@/hooks/UserHooks';
import { MARGIN_TYPE_CASH, MARGIN_TYPE_PERCENT } from '@/hooks/OfferHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { IPriceListItemWebsiteOffersDiscountGroupsPivot, IWebsitesPriceListItem } from '@/models/PriceListModel';
import MIcon from '@/components/atoms/MIcon.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';
import MTextField from '@/components/atoms/MTextField.vue';

const { user } = storeToRefs(useUserStore());
const { currencies } = useDictionaryStore();
const { t } = useI18n();
const { white } = variables;

const priceListApi = new PriceListApi();

const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('pricelist.domainName')}`,
		align: 'center',
		sortable: true,
		key: 'websites.url',
		width: '200px'
	},
	{
		title: `${t('pricelist.offerName')}`,
		align: 'center',
		sortable: true,
		key: 'website_offers.url',
		width: '200px'
	},
	{
		title: `${t('pricelist.basePrice')}`,
		align: 'center',
		sortable: true,
		key: 'website_offers.price',
		width: '100px'
	},
	{
		title: `${t('pricelist.finalPrice')}`,
		align: 'center',
		sortable: false,
		key: 'finalPrice',
		width: '150px'
	},
	{
		title: `${t('pricelist.finalPriceGroup1')}`,
		align: 'center',
		sortable: false,
		key: 'finalPriceGroup1',
		width: '100px'
	},
	{
		title: `${t('pricelist.finalPriceGroup2')}`,
		align: 'center',
		sortable: false,
		key: 'finalPriceGroup2',
		width: '100px'
	},
	{
		title: `${t('pricelist.finalPriceGroup3')}`,
		align: 'center',
		sortable: false,
		key: 'finalPriceGroup3',
		width: '100px'
	},
	{
		title: `${t('pricelist.finalPriceGroup4')}`,
		align: 'center',
		sortable: false,
		key: 'finalPriceGroup4',
		width: '100px'
	},
	{
		title: `${t('pricelist.finalPriceGroup5')}`,
		align: 'center',
		sortable: false,
		key: 'finalPriceGroup5',
		width: '100px'
	},
	{
		title: `${t('pricelist.usersWithDiscount')}`,
		align: 'center',
		sortable: false,
		key: 'usersWithDiscount',
		width: '80px'
	},
	{
		title: `${t('pricelist.more')}`,
		align: 'center',
		sortable: false,
		key: 'data-table-expand',
		width: '70px'
	},
]);
const headersExpanded = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('pricelist.nameAndSurname')}`,
		align: 'center',
		sortable: true,
		key: 'first_name',
		width: '200px'
	},
	{
		title: `${t('pricelist.email')}`,
		align: 'center',
		sortable: true,
		key: 'email',
		width: '200px'
	},
	{
		title: `${t('pricelist.discountGroup')}`,
		align: 'center',
		sortable: true,
		key: 'discount_group_id',
		width: '100px'
	},
	{
		title: `${t('pricelist.percent')}`,
		align: 'center',
		sortable: false,
		key: 'percent',
		width: '150px'
	},
	{
		title: `${t('pricelist.basePrice')}`,
		align: 'center',
		sortable: false,
		key: 'base_price',
		width: '100px'
	},
	{
		title: `${t('pricelist.basePriceWithDefaultMargin')}`,
		align: 'center',
		sortable: false,
		key: 'base_price_with_default_margin',
		width: '100px'
	},
	{
		title: `${t('pricelist.net')}`,
		align: 'center',
		sortable: false,
		key: 'net',
		width: '100px'
	},
	{
		title: `${t('pricelist.vatRate')}`,
		align: 'center',
		sortable: false,
		key: 'vat_rate',
		width: '100px'
	},
	{
		title: `${t('pricelist.gross')}`,
		align: 'center',
		sortable: false,
		key: 'gross',
		width: '100px'
	},
]);
const loading = ref<boolean>(false);
const loadingExpanded = ref<boolean>(false);
const items = ref<Array<IWebsitesPriceListItem>>([]);
const itemsExpanded = ref<Array<any>>([]);
const total = ref<number>(0);
const totalExpanded = ref<number>(0);
const currentPage = ref<number>(0);
const currentPageExpanded = ref<number>(0);
const lastPage = ref<number>(0);
const lastPageExpanded = ref<number>(0);
const search = ref<string|null>(null);
const searchTimeout = ref<any>(undefined);
const searchExpanded = ref<string|null>(null);
const searchExpandedTimeout = ref<any>(undefined);
const expanded = ref<Array<any>>([]);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const optionsExpanded = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});

if (null != user.value && !isAdmin(user.value) && !isModerator(user.value)) {
	window.location.href = '/dashboard';
}

const offerPriceList = async() => {
	loading.value = true;
	try {
		const result = await priceListApi.websitesOffersList(
			options.value.page,
			options.value.itemsPerPage,
			search.value,
			options.value.sortBy.length > 0 ? options.value.sortBy[0].key : null,
			options.value.sortBy.length > 0 ? options.value.sortBy[0].order : null
		);
		if (!result.isSuccess) {
			toast.error(`${t('pricelist.toast.offerPriceListError')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload.data;
		total.value = result.payload.total;
		currentPage.value = result.payload.current_page;
		lastPage.value = result.payload.last_page;
	} catch (e) {
		toast.error(`${t('pricelist.toast.offerPriceListError')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	await offerPriceList();
};

watch((search), (newVal, oldVal) => {
	if (newVal !== oldVal) {
		clearTimeout(searchTimeout.value);
		searchTimeout.value = setTimeout(async () => {
			await offerPriceList();
		}, 700);
	}
});

const downloadCSV = async() => {
	loading.value = true;
	try {
		const result = await priceListApi.exportPriceListToCSV(search.value, options.value.sortBy.length > 0 ? options.value.sortBy[0].key : null, options.value.sortBy.length > 0 ? options.value.sortBy[0].order : null);
		if (!result.isSuccess) {
			toast.error(`${t('pricelist.toast.downloadCSVError')}`);
			loading.value = false;
			return;
		}
	} catch (e) {
		toast.error(`${t('pricelist.toast.downloadCSVError')}`);
		loading.value = false;
		return;
	}
	toast.success(`${t('pricelist.toast.downloadCSVSuccess')}`);
	router.push('/export-list');
	loading.value = false;
};

const expandedItem = ref<IWebsitesPriceListItem|any>(null);
const firstLoadExpandedTable = ref<boolean>(true);
const expand = async(isExpandAction:boolean, item?:IWebsitesPriceListItem|any, isCloseExpandAction?:boolean) => {
	loadingExpanded.value = true;
	if (null != item && !isCloseExpandAction && isExpandAction) {
		expanded.value = [];
		expandedItem.value = item;
		
		const indexExpand = expanded.value.indexOf(item.value);
		if (!expanded.value.includes(item.value)) {
			expanded.value.push(item.value);
		} else {
			expanded.value.splice(indexExpand, 1);
			firstLoadExpandedTable.value = true;
		}
	} else {
		if (isExpandAction) {
			const indexExpand = expanded.value.indexOf(expandedItem.value.value);
			expanded.value.splice(indexExpand, 1);
			firstLoadExpandedTable.value = true;
			return;
		}
	}
	try {
		const result = await priceListApi.websitesOffersDetailsList(
			optionsExpanded.value.page,
			optionsExpanded.value.itemsPerPage,
			null != item ? item.raw.uuid : expandedItem.value.raw.uuid,
			searchExpanded.value,
			optionsExpanded.value.sortBy.length > 0 ? optionsExpanded.value.sortBy[0].key : null,
			optionsExpanded.value.sortBy.length > 0 ? optionsExpanded.value.sortBy[0].order : null
		);
		if (!result.isSuccess) {
			toast.error(`${t('pricelist.toast.offerPriceListExpandError')}`);
			loadingExpanded.value = false;
			return;
		}
		itemsExpanded.value = result.payload.data;
		itemsExpanded.value = itemsExpanded.value.map(elem => {
			return {
				...elem,
				base_net: null != item ? item.raw.net : expandedItem.value.raw.net,
				base_price: null != item ? item.raw.price : expandedItem.value.raw.price,
				base_currency_id: null != item ? item.raw.currency_id : expandedItem.value.raw.currency_id,
			};
		});
		totalExpanded.value = result.payload.total;
		currentPageExpanded.value = result.payload.current_page;
		lastPageExpanded.value = result.payload.last_page;
	} catch (e) {
		toast.error(`${t('pricelist.toast.offerPriceListExpandError')}`);
		loadingExpanded.value = false;
		return;
	}
	loadingExpanded.value = false;
};

const optionsUpdateExpanded = async(val:ITableOptions) => {
	if (!firstLoadExpandedTable.value) {
		optionsExpanded.value.page = val.page;
		optionsExpanded.value.itemsPerPage = val.itemsPerPage;
		optionsExpanded.value.search = val.search;
		optionsExpanded.value.groupBy = val.groupBy;
		optionsExpanded.value.sortBy = val.sortBy;
		await expand(false);
	}
	firstLoadExpandedTable.value = false;
};

watch((searchExpanded), (newVal, oldVal) => {
	if (newVal !== oldVal) {
		clearTimeout(searchExpandedTimeout.value);
		searchExpandedTimeout.value = setTimeout(async () => {
			await expand(false);
		}, 700);
	}
});
const currencySymbol = (currencyId:number) => {
	const currencySymbol = currencies.find(elem => elem.id === currencyId);
	if (null != currencySymbol) {
		return currencySymbol.currency_symbol;
	} else {
		return '-';
	}
};

const marginGroupValue = (discountGroupId:number, discountGroups:IPriceListItemWebsiteOffersDiscountGroupsPivot|any) => {
	const discountGroup = discountGroups.find((elem:any) => elem.id === discountGroupId);
	if (null != discountGroup && null != discountGroup.pivot.value) {
		return discountGroup.pivot.value.toString();
	} else {
		return '';
	}
};
</script>

<style scoped lang="scss">
.price-list-table {
	@import '@/assets/scss/vuetifyDataTable';
	
	&__loading {
		opacity: 0.5;
	}
	
	&__search-wrapper {
		padding: 20px;
		
		&:deep(.m-input) {
			width: 50%;
			
			input {
				min-height: 46px;
			}
		}
		
		&:deep(.v-input__details) {
			display: none;
		}
	}
	
	&__offer-wrapper {
		&:deep(.theme--light.v-btn.v-btn--has-bg) {
			background-color: $secondary-400;
			color: $white-100;
			font-size: $size-14;
			font-weight: $weight-700;
			border-radius: 0;
		}
		
		&:deep(.v-btn:not(.v-btn--round).v-size--default) {
			height: 28px;
			padding: 0 10px;
		}
	}
	
	&__arrow {
		margin-left: 5px;
		
		&--rotate {
			transform: rotateX(180deg);
		}
	}
	
	&__expanded-table-wrapper {
		padding: 0 !important;
		
		&:deep(.theme--dark.v-data-table) {
			background-color: $primary-400;
		}
	}
	
	&__margin {
		color: $green-400;
		font-weight: 700;
		
		&--red {
			color: $secondary-400;
			font-weight: 700;
		}
		
		&--bold {
			font-weight: 700;
		}
	}
	
	&__tooltip-wrapper {
		display: flex;
		gap: 5px;
		align-items: center;
	}
	
	&__tooltip {
		width: max-content;
	}
	
	&__footer-btn-wrapper {
		margin-right: auto;
		margin-left: 10px;
		display: flex;
		align-items: center;
		gap: 20px;
		
		svg {
			cursor: pointer;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	&__expand-action-wrapper {
		background-color: $secondary-400;
		width: max-content;
		padding: 9px 9px 4px 4px;
		border-radius: 50%;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__arrow {
		&--rotate {
			transform: rotateZ(180deg);
			padding: 2px 0 0 0;
			margin-left: 5px;
		}
	}
	
	&:deep(.v-data-table-footer .v-field__outline) {
		--v-field-border-width: 0;
	}
	
	&:deep(.v-data-table-footer .v-field__append-inner) {
		margin-top: -5px;
	}
	
	&:deep(.v-data-table-footer .v-field__overlay) {
		border-bottom: 1px solid $grey-400;
		border-radius: 0;
	}
	
	&:deep(.v-data-table-footer .v-select__selection-text) {
		font-size: 14px;
		margin-top: 3px;
	}
	
	&:deep(.v-data-table-footer .v-data-table th) {
		font-weight: 700;
	}
}
</style>
