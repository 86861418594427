<template>
	<div class="import">
		<HeadingLabel
			class="import__heading-label"
			icon="download"
			width="32"
			height="29"
			:text="$t('importExport.exportWebsiteOfferts')"
		/>
		
		<ImportInfoBlock :text="$t('importExport.exportInfoBlock')" />
		
		<div class="import__export-wrapper">
			<Form :validation-schema="schema" @submit="openExportDialog">
				<div class="import__export-form-wrapper">
					<MTextField
						id="import-email"
						v-model="email"
						name="email"
						clearable
						:placeholder="$t('importExport.emailPlaceholder')"
					/>
					<MAutocomplete
						id="export-categories"
						v-model="categories"
						name="domainCategories"
						:get-items="categoryList"
						item-title="name"
						item-value="id"
						chips
						:placeholder="$t('importExport.categoriesPlaceholder')"
						variant="outlined"
						multiple
						clearable
					/>
					<MAutocomplete
						id="export-language"
						v-model="language"
						name="domainLanguage"
						:get-items="languages"
						item-title="name"
						item-value="id"
						:placeholder="$t('importExport.languagePlaceholder')"
						variant="outlined"
						chips
						multiple
						clearable
					/>
					<MAutocomplete
						id="export-country"
						v-model="country"
						name="domainCountry"
						:get-items="countries"
						item-title="name"
						item-value="id"
						:placeholder="$t('importExport.countryPlaceholder')"
						variant="outlined"
						chips
						multiple
						clearable
					/>
				</div>
				<div class="import__export-form-checkbox-wrapper">
					<MCheckbox
						id="export-with-promo-code"
						v-model="promoCode"
						name="promoCode"
						:label="$t('importExport.promoCode')"
					/>
					<MCheckbox
						id="export-with-discount"
						v-model="withDiscount"
						name="withDiscount"
						:label="$t('importExport.withDiscount')"
					/>
				</div>
				<div class="import__export-btn-wrapper">
					<MButton
						id="open-export-dialog"
						normal
						green400
						width130
						:label="$t('importExport.export')"
					/>
					<MButton
						id="go-to-export-list-btn"
						normal
						blue400
						width130
						:label="$t('importExport.exportList')"
						@click="goToExportList"
					/>
				</div>
			</Form>
		</div>
		
		<MDialog
			:is-close-dialog-action="isCloseExportDialog"
			:is-open-dialog-action="isOpenExportDialog"
			:dynamic-content-title="`${'' !== email && null != email ? $t('importExport.exportDomains') + email : ((null != categories && categories.length > 0) || (null != language && language.length > 0) || (null != country && country.length > 0) || withDiscount || promoCode) ? $t('importExport.exportWithFilters') : $t('importExport.exportAllDomains')}`"
			@close-dialog="closeDialogWhenNoTargetPressed"
			close-header-btn
			is-select-footer-btn
		>
			<template v-slot:default>
				<div class="import__export-dialog-wrapper">
					<MButton
						normal
						width130
						red400
						:label="$t('importExport.export')"
						@click="exportAction"
					/>
					<MButton
						normal
						width130
						blue400
						:label="$t('components.close')"
						@click="closeDialogWhenNoTargetPressed"
					/>
				</div>
			</template>
		</MDialog>
		
		<HeadingLabel
			class="import__heading-label"
			icon="upload"
			width="32"
			height="29"
			:text="$t('importExport.importWebsiteOfferts')"
			btn
			red400
			:btn-text="$t('importExport.refreshElasticSearch')"
			:is-button-click="refreshElasticSearch"
		>
			<template v-slot:beforeBtn>
				<MButton
					id="export-offers-with-no-orders"
					class="import__database-btn"
					blue400
					big
					:label="$t('websites.exportUnusedDomains')"
					width="20"
					height="20"
					:loading="unusedDomainsLoader"
					@click="unusedDomainsAction"
					:disabled="unusedDomainsLoader"
				/>
				<MButton
					id="import-data-bases-sync"
					class="import__database-btn"
					green400
					big
					:label="$t('websites.dataBasesSync')"
					width="20"
					height="20"
					:loading="databaseSyncLoader"
					@click="databaseSyncAction"
					:disabled="databaseSyncLoader"
				/>
			</template>
		</HeadingLabel>
		
		<ImportInfoBlock :text="$t('importExport.importInfoBlock')" />
		
		<div class="import__drop-wrapper">
			<div @dragover="dragover" @dragleave="dragleave" @drop="drop">
				<div class="import__drop-wrapper--input-wrapper">
					<label for="assetsFieldHandle">
						<div class="import__drop-text">{{ $t('order.orderFlow.uploadDropMessage') }}</div>
						<div class="import__remove-wrapper">
							<div v-if="importFile !== null">
								{{ importFile.name }}
							</div>
							<div v-if="importFile !== null" @click="removeFile">
								<MIcon icon="close" width="12" height="12" />
							</div>
						</div>
					</label>
					<input
						type="file"
						name="importFile"
						id="import-file"
						@change="onChange"
						ref="file"
						accept=".csv"
					/>
					<div class="import__file-btn">{{ $t('order.orderFlow.selectFile') }}</div>
				</div>
			</div>
		</div>
		
		<div class="import__import-btn-wrapper">
			<MButton
				id="import-file"
				normal
				green400
				width130
				:label="$t('importExport.import')"
				@click="importAction"
				:disabled="null == importFile"
			/>
		</div>
		
		<div class="import__list-table">
			<ImportListTable :is-get-import-list-action="isGetImportListAction" />
		</div>
		
		<MLoading v-show="loading" />
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDictionaryStore } from '@/stores/dictionary';
import router from '@/router';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { toast } from 'vue3-toastify';
import ExportApi from '@/api/v1/ExportApi';
import WebsiteApi from '@/api/v1/WebsiteApi';
import ImportApi from '@/api/v1/ImportApi';
import AhrefsApi from '@/apiWebsites/v1/AhrefsApi';
import type { ICategoriesList } from '@/models/WebsiteModel';
import type { IExportWebsiteOfferRequest } from '@/models/ExportModel';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import ImportInfoBlock from '@/views/ImportExport/components/ImportInfoBlock.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MButton from '@/components/atoms/MButton.vue';
import MDialog from '@/components/atoms/MDialog.vue';
import MLoading from '@/components/atoms/MLoading.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import ImportListTable from '@/views/ImportExport/components/ImportListTable.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MCheckbox from '@/components/atoms/MCheckbox.vue';

const exportApi = new ExportApi();
const websiteApi = new WebsiteApi();
const importApi = new ImportApi();
const ahrefsApi = new AhrefsApi();

const { t } = useI18n();
const { languages, countries } = useDictionaryStore();

const email = ref<string>('');
const loading = ref<boolean>(false);
const importFile = ref<any>(null);
const isGetImportListAction = ref<boolean>(false);
const isOpenExportDialog = ref<boolean>(false);
const isCloseExportDialog = ref<boolean>(true);
const databaseSyncLoader = ref<boolean>(false);
const unusedDomainsLoader = ref<boolean>(false);
const categories = ref<Array<number> | null>(null);
const language = ref<Array<number> | null>(null);
const country = ref<Array<number> | null>(null);
const promoCode = ref<boolean>(false);
const withDiscount = ref<boolean>(false);
const categoryList = ref<Array<ICategoriesList>>([]);

const schema:any = yup.object({
	email: yup.string().nullable().email(`${t('importExport.validation.email')}`).label('email'),
});
const openExportDialog = () => {
	isOpenExportDialog.value = true;
	isCloseExportDialog.value = false;
};

const closeDialogWhenNoTargetPressed = () => {
	isOpenExportDialog.value = false;
	isCloseExportDialog.value = true;
};
const goToExportList = () => {
	router.push('/export-list');
};

const exportAction = async() => {
	loading.value = true;
	
	let request:IExportWebsiteOfferRequest | null = {
		email: '' !== email.value ? email.value : null,
		category_ids: null != categories.value && categories.value.length > 0 ? categories.value : null,
		country_ids: null != country.value && country.value.length > 0 ? country.value : null,
		language_ids: null != language.value && language.value.length > 0 ? language.value : null,
		has_discount: withDiscount.value,
		coupon: promoCode.value ? 'PROMO' : null,
	};
	try {
		const result = await exportApi.exportWebsiteOffer(request);
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.exportNoDomains')}`);
			loading.value = false;
			return;
		}
		await router.push('/export-list');
		toast.success(`${t('importExport.toast.exportSuccess')}`);
	} catch (e) {
		toast.error(`${t('importExport.toast.exportNoDomains')}`);
		loading.value = false;
		return;
	}
	closeDialogWhenNoTargetPressed();
	loading.value = false;
};

const refreshElasticSearch = async() => {
	loading.value = true;
	try {
		const result = await websiteApi.refreshElasticSearch();
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.errorRefreshElasticSearch')}`);
			loading.value = false;
			return;
		}
		await router.push('/export-list');
		toast.success(`${t('importExport.toast.refreshElasticSearch')}`);
	} catch (e) {
		toast.error(`${t('importExport.toast.errorRefreshElasticSearch')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const dragover = (event:any) => {
	event.preventDefault();
	// Add some visual fluff to show the user can drop its files
	if (!event.currentTarget.classList.contains('import__bg-green-300')) {
		event.currentTarget.classList.remove('import__bg-gray-100');
		event.currentTarget.classList.add('import__bg-green-300');
	}
};
const dragleave = (event:any) => {
	// Clean up
	event.currentTarget.classList.add('import__bg-gray-100');
	event.currentTarget.classList.remove('import__bg-green-300');
};
const drop = (event:any) => {
	importFile.value = null;
	event.preventDefault();
	event.currentTarget.classList.add('import__bg-gray-100');
	event.currentTarget.classList.remove('import__bg-green-300');
	
	Array.from(event.dataTransfer.files).forEach(file => {
		importFile.value = file;
	});
};
const onChange = (event:any) => {
	importFile.value = null;
	Array.from(event.target.files).forEach(file => {
		importFile.value = file;
	});
};
const removeFile = () => {
	importFile.value = null;
};
const importAction = async() => {
	loading.value = true;
	
	const request = importFile.value;
	try {
		const result = await importApi.importWebsiteOffer(request);
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.importError')}`);
			loading.value = false;
			return;
		}
		toast.success(`${t('importExport.toast.importSuccess')}`);
		isGetImportListAction.value = true;
		setTimeout(() => {
			isGetImportListAction.value = false;
		}, 1000);
		importFile.value = null;
	} catch (e) {
		toast.error(`${t('importExport.toast.importError')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const databaseSyncAction = async() => {
	databaseSyncLoader.value = true;
	try {
		const result = await ahrefsApi.databasesSynchronization();
		if (!result.isSuccess) {
			toast.error(`${t('websites.toast.errorSyncDatabases')}`);
			databaseSyncLoader.value = false;
			return;
		}
		toast.success(`${t('websites.toast.successSyncDatabases')}`);
	} catch (e) {
		toast.error(`${t('websites.toast.errorSyncDatabases')}`);
		databaseSyncLoader.value = false;
		return;
	}
	databaseSyncLoader.value = false;
};

const unusedDomainsAction = async() => {
	unusedDomainsLoader.value = true;
	try {
		const result = await importApi.exportUnusedDomains();
		if (!result.isSuccess) {
			toast.error(`${t('websites.toast.errorUnusedDomains')}`);
			unusedDomainsLoader.value = false;
			return;
		}
		await router.push('/export-list');
		toast.success(`${t('websites.toast.successUnusedDomains')}`);
	} catch (e) {
		toast.error(`${t('websites.toast.errorUnusedDomains')}`);
		unusedDomainsLoader.value = false;
		return;
	}
	unusedDomainsLoader.value = false;
};

const categoriesList = async() => {
	try {
		const result = await websiteApi.categoriesList();
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.errorGetCategories')}`);
			return;
		}
		categoryList.value = result.payload;
	} catch (e) {
		toast.error(`${t('importExport.toast.errorGetCategories')}`);
		return;
	}
};
categoriesList();
</script>

<style scoped lang="scss">
.import {
	&__heading-label {
		margin-bottom: 4px;
	}
	
	&__email-input {
		margin: 10px 0;
		
		@include media-breakpoint-up(sm) {
			width: 50%;
		}
	}
	
	&__export-wrapper {
		background-color: $white-100;
		padding: 10px;
		margin: 4px 0;
		
		@include media-breakpoint-up(sm) {
			padding: 20px;
		}
	}
	
	&__export-btn-wrapper {
		display: flex;
		justify-content: space-between;
	}
	
	&__drop-wrapper {
		margin-top: 20px;
		border: 2px dashed $grey-300;
		border-radius: 12px;
		margin-bottom: 10px;
		
		&--input-wrapper {
			padding: 15px;
			height: 158px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			
			input[type='file'] {
				position: absolute;
				z-index: 1;
				top: 70%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 158px;
				height: 40px;
				opacity: 0;
				cursor: pointer;
			}
			
			label {
				margin-bottom: 20px;
			}
		}
	}
	
	&__file-btn {
		background-color: $primary-400;
		cursor: pointer;
		border-radius: 8px;
		color: $white-100;
		font-size: 14px;
		font-weight: 600;
		padding-top: 9px;
		text-align: center;
		position: absolute;
		top: 70%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 158px;
		height: 40px;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__remove-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		
		div:nth-child(2) {
			margin-left: 10px;
			margin-top: 3px;
			cursor: pointer;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	&__drop-text {
		text-align: center;
		font-size: 12px;
		color: $grey-300;
	}
	
	&__bg-green-300 {
		background-color: $green-300;
		padding: 20px;
		border-radius: 12px;
		
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
	}
	
	&__bg-gray-100 {
		background-color: $grey-200;
		padding: 20px;
		border-radius: 12px;
		
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
	}
	
	&__import-btn-wrapper {
		display: flex;
		justify-content: flex-end;
	}
	
	&__list-table {
		margin-top: 4px;
	}
	
	&__export-dialog-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 40px;
		margin-top: 20px;
	}
	
	&__database-btn {
		margin-right: 10px;
	}
	
	&__export-form-wrapper {
		display: flex;
		flex-direction: column;
		gap: 15px;
		
		@include media-breakpoint-up(lg) {
			flex-direction: row;
			flex-wrap: wrap;
		}
		
		&:deep(.m-input) {
			width: 100%;
			
			@include media-breakpoint-up(lg) {
				width: 49%;
			}
		}
		
		&:deep(.m-autocomplete) {
			width: 100%;
			
			@include media-breakpoint-up(lg) {
				width: 49%;
			}
		}
		
		&:deep(.v-input__details) {
			display: none;
		}
		
		&:deep(.v-chip) {
			background-color: $primary-400;
			color: $white-100;
			font-weight: 700;
		}
	}
	
	&__export-form-checkbox-wrapper {
		display: flex;
		flex-direction: column;
		gap: 15px;
		margin: 15px 0 15px -10px;
		
		&:deep(.v-input__details) {
			display: none;
		}
		
		&:deep(.m-checkbox) {
			height: 30px;
		}
	}
}
</style>
