<template>
	<div v-for="item in items" :key="item.id" class="admin-counter">
		<div class="admin-counter">{{ item.text }}:</div>
		<div class="admin-counter">{{ item.counter }}</div>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import type { IDashboardAdmin } from '@/models/DashboardAdminModel';

const { t } = useI18n();

const items = ref<Array<IDashboardAdmin>>([
	{
		id: 1,
		text: t('dashboard.publishers'),
		counter: 6479,
	},
	{
		id: 2,
		text: t('dashboard.advertisers'),
		counter: 6479,
	},
	{
		id: 3,
		text: t('dashboard.contentWriters'),
		counter: 24,
	},
	{
		id: 4,
		text: t('dashboard.moderators'),
		counter: 10,
	},
	{
		id: 5,
		text: t('dashboard.admins'),
		counter: 7,
	},
]);
</script>

<style scoped lang="scss">
.admin-counter {
	display: flex;
	flex-direction: column;
	gap: 2px;
	font-size: 15px;
	margin-bottom: 10px;
	
	&__text {
		font-weight: 400;
	}
	
	&__number {
		font-weight: 700;
	}
}
</style>
