import BaseApi from '@/api/BaseApi';
import type { IWordpressPosts } from '@/models/WordpressModel';

export default class WordpressApi extends BaseApi {
	async postsList (locale:string) {
		return this.get<Array<IWordpressPosts>>({
			url: `api/v1/wordpress/posts?locale=${locale}`,
		});
	};
};
