<template>
    <div class="m-date-picker">
        <datepicker
            v-model="value"
            :language="language"
            :name="name"
            :class="[{'m-date-picker__error': 0 != errors.length}]"
            :placeholder="placeholder"
            :disabled-dates="disabledDates"
            :minimum-view="minView"
            :maximum-view="maxView"
        >
	        <template v-slot:customCalendarHeader>
		       <slot name="customCalendarHeader"></slot>
	        </template>
	        <template v-slot:belowDate>
		       <slot name="belowDate"></slot>
	        </template>
        </datepicker>
        <div class="m-date-picker__errors">{{ errors[0] }}</div>
	    <div v-if="clearable && null != value" @click="clearAction" class="m-date-picker__clearable">
		    <MIcon icon="close" :width="clearableWidth" :height="clearableHeight" :color="clearableColor" />
	    </div>
    </div>
</template>

<script setup lang="ts">
import Datepicker from 'vuejs3-datepicker';
import MIcon from '@/components/atoms/MIcon.vue';
import { computed, defineEmits, defineProps, toRef, withDefaults } from 'vue';
import { useField } from 'vee-validate';
import dayjs from 'dayjs';

type dateView = 'day' | 'month' | 'year';
interface IOwnProps {
    language: string
    name: string
    modelValue?: string|null
    placeholder?: string
    disabledDates?: Object
	clearable?: boolean
	clearableColor?: string
	clearableWidth?: string
	clearableHeight?: string
	minView?: dateView
	maxView?: dateView
}
interface IProps extends IOwnProps {
}

const props = withDefaults(defineProps<IProps>(), {
	clearableColor: '#000',
	clearableWidth: '16',
	clearableHeight: '16',
});
const emit = defineEmits(['update:modelValue']);

const { errors } = useField(toRef(props, 'name'), undefined);

const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', dayjs(value).format('YYYY-MM-DD HH:mm:ss'));
    }
});

const clearAction = () => {
	emit('update:modelValue', null);
};
</script>

<style scoped lang="scss">
.m-date-picker {
	position: relative;

    &__errors {
	    min-height: 25px;
	    padding: 3px 15px;
        font-size: $size-12;
        color: $secondary-550;
        text-transform: lowercase;
        
        &::first-letter {
            text-transform: uppercase;
        }
    }
	
	&:deep(.vuejs3-datepicker) {
		width: 100%;
	}
	
	&:deep(.vuejs3-datepicker__value) {
		padding: 18px 15px;
		width: 100%;
		border-color: $grey-300;
		transition: border-color 0.3s;
		
		&:hover {
			border-color: $primary-400;
		}
	}
	
	&__error {
		&:deep(.vuejs3-datepicker__value) {
			border-color: $secondary-400;
		}
	}
	
	&__clearable {
		position: absolute;
		top: 50%;
		right: 18px;
		cursor: pointer;
		width: max-content;
		transform: translateY(-100%);
		transition: opacity 0.3s;
		animation: showClearBtn 0.5s;
		
		&:hover {
			opacity: 0.5;
		}
		
		@keyframes showClearBtn {
			from {
				opacity: 0;
			}
			
			to {
				opacity: 1;
			}
		}
	}
	
	&:deep(.cell.selected) {
		background: $primary-400;
		
		&:hover {
			border: 1px solid $primary-400;
			background: $secondary-400;
		}
	}
	
	&:deep(.vuejs3-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover) {
		&:hover {
			border: 1px solid $primary-400;
		}
	}
	
	&:deep(.vuejs3-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover) {
		&:hover {
			border: 1px solid $primary-400;
		}
	}
	
	&:deep(.vuejs3-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover) {
		&:hover {
			border: 1px solid $primary-400;
		}
	}
	
	&:deep(.vuejs3-datepicker__calendar-topbar) {
		background: $primary-400;
	}
}
</style>
