import BaseApi from '@/api/BaseApi';
import type {
	IImpersonateResponse,
	LoginRequestInterface,
	LoginResponseInterface,
	SessionModelInterface
} from '@/models/AuthModel';

export default class MonolithAuthApi extends BaseApi {
    async getSession() {
        return this.get<SessionModelInterface>({
            url: 'api/auth/session',
            config: {
                useMonolithApi: true,
            },
        });
    };
    async monolithLogin(params: LoginRequestInterface) {
        return this.post<LoginRequestInterface, LoginResponseInterface>({
            url: 'api/auth/login',
            data: {
                email: params.email,
                password: params.password,
            },
            config: {
                useMonolithApi: true,
            },
        });
    };

	async impersonate (userId: Array<string>|string, apiToken: string|null) {
		return this.get<IImpersonateResponse>({
			url: `api/auth/impersonate/${userId}`,
			params: {
				api_token: apiToken
			},
			config: {
				useMonolithApi: true,
			},
		});
	};
};
