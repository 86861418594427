<template>
	<div class="publisher-orders">
		<div class="publisher-orders__label" @click="goToOrders">{{ $t('dashboard.orders') }}</div>
		
		<MProgressBar v-show="loading" indeterminate height="2" />
		<div class="publisher-orders__wrapper">
			<div class="publisher-orders__left-wrapper">
				<div class="publisher-orders__title publisher-orders__title--center">{{ $t('dashboard.status') }}</div>
				<div class="publisher-orders__item publisher-orders__item--red">{{ $t('dashboard.rejected') }}</div>
				<div class="publisher-orders__item publisher-orders__item--yellow">{{ $t('dashboard.reports') }}</div>
				<div class="publisher-orders__item publisher-orders__item--green">{{ $t('dashboard.toApprove') }}</div>
			</div>
			<div class="publisher-orders__right-wrapper">
				<div class="publisher-orders__title publisher-orders__title--center">{{ $t('dashboard.count') }}</div>
				<div class="publisher-orders__counter">{{ null != rejectedCounter ? rejectedCounter : 0 }}</div>
				<div class="publisher-orders__counter">{{ null != reportsCounter ? reportsCounter : 0 }}</div>
				<div class="publisher-orders__counter">{{ null != toApproveCounter ? toApproveCounter : 0 }}</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import router from '@/router';
import MProgressBar from '@/components/atoms/MProgressBar.vue';

interface IOwnProps {
	rejectedCounter: number
	reportsCounter: number
	toApproveCounter: number
	loading: boolean
}

interface IProps extends IOwnProps {
}

defineProps<IProps>();

const goToOrders = () => {
	router.push('/order/publication');
};
</script>

<style scoped lang="scss">
.publisher-orders {
	display: flex;
	flex-direction: column;
	
	&__label {
		font-size: 24px;
		color: $primary-400;
		font-weight: 700;
		margin-bottom: 20px;
		width: max-content;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__wrapper {
		display: flex;
		background-color: $white-100;
		border-radius: 8px;
		font-size: 12px;
		
		@include media-breakpoint-up(xxl) {
			height: 342px + 95px + 20px;
		}
	}
	
	&__left-wrapper {
		width: 77%;
		display: flex;
		flex-direction: column;
	}
	
	&__right-wrapper {
		width: 23%;
		display: flex;
		flex-direction: column;
	}
	
	&__title {
		font-weight: 700;
		border-bottom: 1px solid $grey-150;
		padding: 31px 20px;
		
		&--center {
			text-align: center;
			padding: 31px 2px 31px 2px;
		}
	}
	
	&__counter {
		padding: 17px 2px 16px;
		font-weight: 700;
		text-align: center;
		border-bottom: 1px solid $grey-150;
	}
	
	&__item {
		position: relative;
		padding: 17px 25px 16px 25px;
		text-align: center;
		border-bottom: 1px solid $grey-150;
		
		&:before {
			content: '';
			position: absolute;
			top: 7px;
			left: 14px;
			width: 4px;
			height: 70%;
			background-color: $secondary-400;
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
		}
		
		&--red {
			&:before {
				background-color: $secondary-400;
			}
		}
		
		&--yellow {
			&:before {
				background-color: $yellow-500;
			}
		}
		
		&--green {
			&:before {
				background-color: $green-400;
			}
		}
	}
}
</style>
