<template>
	<div v-if="null != user" class="withdrawal-edit-create">
		<div class="withdrawal-edit-create__top-wrapper">
			<div class="withdrawal-edit-create__left-wrapper">
				<HeadingLabel
					v-if="!isAdmin(user)"
					class="withdrawal-edit-create__heading-label"
					icon="credit-card-solid"
					width="32"
					height="29"
					:text="$t('reflinks.yourWallet')"
				/>
				<div v-if="!isAdmin(user)" class="withdrawal-edit-create__box-wrapper">
					<div v-if="isPublisher(user)" class="withdrawal-edit-create__wallet-text">{{ $t('reflinks.mainAccount') }}: <span>{{ currentUserMainAccountWalletValue() }} {{ userCurrencySymbol }}</span></div>
					<div v-if="checkIsUserJoinAffiliateProgram(user)" class="withdrawal-edit-create__wallet-text">{{ $t('reflinks.affiliateAccount') }}: <span>{{ currentUserAffiliateAccountWalletValue() }} {{ userCurrencySymbol }}</span></div>
				</div>
				<HeadingLabel
					class="withdrawal-edit-create__heading-label"
					icon="credit-card-solid"
					width="32"
					height="29"
					:text="$t('reflinks.areYouReadyForWithdraw')"
				/>
				<div class="withdrawal-edit-create__box-wrapper withdrawal-edit-create__box-wrapper--info">
					<div v-if="isAdvertiser(user)" class="withdrawal-edit-create__margin-bottom">{{ $t('reflinks.paymentInfo0') }}</div>
					<div class="withdrawal-edit-create__margin-bottom">{{ $t('reflinks.paymentInfo1') }} <span>{{ $t('reflinks.paymentInfo11') }}</span> {{ $t('reflinks.paymentInfo12') }} <span>{{ $t('reflinks.paymentInfo13') }}</span> {{ $t('reflinks.paymentInfo14') }}</div>
					<div class="withdrawal-edit-create__bold">{{ $t('reflinks.paymentInfo2') }}</div>
					<div class="withdrawal-edit-create__bold withdrawal-edit-create__margin-bottom">{{ $t('reflinks.paymentInfo3') }} {{ user.vat_rate }}%</div>
					<div class="">{{ $t('reflinks.paymentInfo4') }}</div>
					<div class="">{{ $t('reflinks.paymentInfo5') }}</div>
					<div class="">{{ $t('reflinks.paymentInfo6') }}</div>
					<div class="">{{ $t('reflinks.paymentInfo7') }}</div>
					<div class="">{{ $t('reflinks.paymentInfo8') }}</div>
					<div class="">{{ $t('reflinks.paymentInfo9') }}</div>
				</div>
			</div>
			<div class="withdrawal-edit-create__right-wrapper">
				<HeadingLabel
					class="withdrawal-edit-create__heading-label"
					icon="money"
					width="32"
					height="29"
					:text="$t('reflinks.withdrawalRequest')"
				/>
				<div class="withdrawal-edit-create__box-wrapper">
					<WithdrawForm @sendWithdrawalRequest="sendWithdrawalRequest" />
				</div>
			</div>
		</div>
		<div class="withdrawal-edit-create__bottom-wrapper">
			<HeadingLabel
				class="withdrawal-edit-create__heading-label"
				icon="credit-card-solid"
				width="32"
				height="29"
				:text="$t('reflinks.withdrawalHistory')"
			/>
			<WithdrawalRequestsTable :is-new-withdrawal-request="isNewWithdrawRequest" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import {
	AFFILIATE_ACCOUNT_ROLE_ID,
	checkIsUserJoinAffiliateProgram,
	isAdmin,
	isAdvertiser,
	isPublisher
} from '@/hooks/UserHooks';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import WithdrawForm from '@/views/Withdraw/components/WithdrawForm.vue';
import WithdrawalRequestsTable from '@/views/Withdraw/components/WithdrawalRequestsTable.vue';

const { user, userCurrencySymbol } = storeToRefs(useUserStore());

const isNewWithdrawRequest = ref<boolean>(false);

const currentUserMainAccountWalletValue = () => {
	const account = user.value?.accounts.find(elem => elem.role_id === user.value?.active_role_id);
	if (null != account) {
		return (account.balance / 100).toFixed(2);
	}
	return (0).toFixed(2);
};
const currentUserAffiliateAccountWalletValue = () => {
	const account = user.value?.accounts.find(elem => elem.role_id === AFFILIATE_ACCOUNT_ROLE_ID);
	if (null != account) {
		return (account.balance / 100).toFixed(2);
	}
	return (0).toFixed(2);
};

const sendWithdrawalRequest = () => {
	isNewWithdrawRequest.value = true;
	setTimeout(() => {
		isNewWithdrawRequest.value = false;
	}, 2000)
};
</script>

<style scoped lang="scss">
.withdrawal-edit-create {
	display: flex;
	flex-direction: column;
	
	&__top-wrapper {
		display: flex;
		flex-direction: column;
		
		@include media-breakpoint-up(lg) {
			flex-direction: row;
			justify-content: space-between;
		}
	}
	
	&__heading-label {
		margin-bottom: 3px;
	}
	
	&__left-wrapper,
	&__right-wrapper {
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			width: 49%;
		}
	}
	
	&__box-wrapper {
		background-color: $white-100;
		padding: 20px;
		margin-bottom: 3px;
		
		&--info {
			font-size: 14.4px;
		}
	}
	
	&__wallet-text {
		font-size: 15px;
		color: $primary-400;
		
		span {
			color: $green-400;
			font-weight: 700;
		}
	}
	
	&__margin-bottom {
		margin-bottom: 15px;
		
		span {
			font-weight: 700;
		}
	}
	
	&__bold {
		font-weight: 700;
	}
}
</style>
