<template>
	<div class="cart-name">
		<div v-show="!isEditMode" class="cart-name__wrapper">
			<div class="cart-name__name">{{ null != editedName && '' !== editedName ? editedName : $t('components.publicationCart') }}</div>
			<MTooltip without-btn>
				<template v-slot:title>
					<div id="edit-mode-cart-name-btn" class="cart-name__edit-btn" @click="openEditMode">
						<MIcon icon="pencil" :color="white" />
					</div>
				</template>
				<template v-slot:content>
					<div>{{ $t('components.edit') }}</div>
				</template>
			</MTooltip>
		</div>
		<div v-show="isEditMode" class="cart-name__edit-wrapper">
			<div class="cart-name__input-wrapper">
				<MTextField
					id="input-name"
					v-model="editedName"
					name="name"
					label=""
					:placeholder="$t('basket.preview.cartName')"
				/>
			</div>
			<v-progress-circular v-if="changeNameLoading" :color="!isWhiteLoader ? primary400 : white" :size="15" :width="2" indeterminate />
			<MIcon v-if="!changeNameLoading" id="change-name-btn" icon="tick" :color="green400" @click="changeNameAction" class="cart-name__decision-btn" />
			<MIcon v-if="!changeNameLoading" icon="close" :color="secondary400" @click="closeEditMode" class="cart-name__decision-btn" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, defineProps, watch, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import OrderApi from '@/api/v1/OrderApi';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { ICartNameRequest } from '@/models/OrderModel';
import MIcon from '@/components/atoms/MIcon.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';


interface IOwnProps {
	name: string | null | undefined
	orderUuid: string | null
	isWhiteLoader?: boolean
}

interface IProps extends IOwnProps {
}

const { t } = useI18n();
const props = defineProps<IProps>();
const emit = defineEmits(['cartNameChanged']);
const { white, secondary400, green400, primary400 } = variables;

const editedName = ref<string | null | undefined>(null);
const isEditMode = ref<boolean>(false);
const openEditMode = () => {
	isEditMode.value = true;
};
const closeEditMode = () => {
	editedName.value = props.name;
	isEditMode.value = false;
};

const changeNameLoading = ref<boolean>(false);
const orderApi = new OrderApi();
const changeNameAction = async() => {
	changeNameLoading.value = true;
	
	const form:ICartNameRequest = {
		name: '' === editedName.value || null == editedName.value ? null : editedName.value,
	};
	try {
		const result = await orderApi.changeCartName(props.orderUuid, form);
		if (!result.isSuccess) {
			changeNameLoading.value = false;
			toast.error(`${t('basket.preview.toast.errorChangeCartName')}`);
			return;
		}
		emit('cartNameChanged', editedName.value);
		toast.success(`${t('basket.preview.toast.successChangeCartName')}`);
		changeNameLoading.value = false;
		isEditMode.value = false;
	} catch (e) {
		changeNameLoading.value = false;
		toast.error(`${t('basket.preview.toast.errorChangeCartName')}`);
		return;
	}
};

watch((props), (val) => {
	if (val.name != null) {
		editedName.value = val.name;
	}
},{
	deep: true,
	immediate: true,
})
</script>

<style scoped lang="scss">
.cart-name {
	&__wrapper {
		display: flex;
		align-items: center;
		gap: 5px;
	}
	
	&__name {
		font-size: 24px;
		font-weight: 700;
		color: $primary-400;
	}
	
	&__edit-btn {
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $grey-300;
		border-radius: 50%;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__edit-wrapper {
		display: flex;
		gap: 5px;
		align-items: center;
	}
	
	&__decision-btn {
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__input-wrapper {
		min-width: 150px;
		
		&:deep(.v-input input) {
			padding: 0 10px 2px !important;
			min-height: 36px !important;
			max-height: 36px !important;
		}
		
		&:deep(.v-input__details) {
			display: none;
		}
	}
}
</style>
