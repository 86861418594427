<template>
	<div class="upload-article">
		<HeadingLabel
			class="upload-article__heading-label"
			icon="clipboard"
			width="32"
			height="29"
			:text="$t('articles.uploadArticle')"
			modal
			modal-icon="step-plus"
			modal-icon-width="70"
			modal-icon-height="71"
			:modal-icon-color="white"
			:modal-title="$t('articles.uploadArticle')"
		>
			<div class="upload-article__modal-text">
				{{ $t('articles.uploadArticleDescription1') }}
			</div>
			<div class="upload-article__modal-text">
				{{ $t('articles.uploadArticleDescription2') }}
			</div>
			<div class="upload-article__modal-text">
				{{ $t('articles.uploadArticleDescription3') }}
			</div>
		</HeadingLabel>
		
		<div class="upload-article__drop-wrapper">
			<div @dragover="dragover" @dragleave="dragleave" @drop="drop">
				<div class="upload-article__drop-wrapper--input-wrapper">
					<label for="assetsFieldHandle">
						<div class="upload-article__drop-text">{{ $t('articles.uploadDropMessage') }}</div>
					</label>
					<input
						type="file"
						name="articleFile"
						id="upload-article"
						@change="onChangeFile"
						ref="file"
						accept=".doc,.txt,.odt,.docx"
						multiple
					/>
					<div class="upload-article__file-btn">{{ $t('order.orderFlow.selectFile') }}</div>
				</div>
			</div>
		</div>
		
		<HeadingLabel
			class="upload-article__heading-label"
			icon="clipboard"
			width="32"
			height="29"
			:text="$t('articles.uploadedArticle')"
		/>
		
		<UploadArticleTable
			:items="items"
			:loading="loading"
			@deleteItem="deleteItem"
			@footerAction="footerAction"
			@applyToAll="applyToAll"
			:language-id="languageId"
			:campaign-id="campaignId"
			:is-dialog="isDialog"
			@dialogSaveArticle="dialogSaveArticle"
		/>
	</div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, ref } from 'vue';
import { useDictionaryStore } from '@/stores/dictionary';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { IUploadArticleResponse } from '@/models/ArticlesModel';
import CampaignApi from '@/api/v1/CampaignApi';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import UploadArticleTable from '@/components/organisms/Articles/components/UploadArticleTable.vue';

interface IOwnProps {
	languageId?: number | null
	campaignId?: number | null
	isDialog?: boolean
}
interface IProps extends IOwnProps {
}

const emit = defineEmits(['dialogSaveArticle']);
const props = defineProps<IProps>();

const campaignApi = new CampaignApi();

const { white } = variables;
const { campaigns } = useDictionaryStore();

const loading = ref<boolean>(false);
const items = ref<Array<any>>([]);
const onChangeFile = (event:any) => {
	loading.value = true;
	Array.from(event.target.files).forEach(file => {
		const newFile:File|any = file;
		items.value.push({
			title: newFile.name,
			campaign_id: props.isDialog ? props.campaignId: null,
			language_id: props.isDialog ? props.languageId: null,
			note: null,
			footer_id: null,
			footer_list: [],
			file: newFile,
			photo: null,
		});
	});
	setTimeout(() => {
		loading.value = false;
	}, 500);
};
const dragover = (event:any) => {
	event.preventDefault();
	// Add some visual fluff to show the user can drop its files
	if (!event.currentTarget.classList.contains('upload-article__bg-green-300')) {
		event.currentTarget.classList.remove('upload-article__bg-gray-100');
		event.currentTarget.classList.add('upload-article__bg-green-300');
	}
};
const dragleave = (event:any) => {
	// Clean up
	event.currentTarget.classList.add('upload-article__bg-gray-100');
	event.currentTarget.classList.remove('upload-article__bg-green-300');
};
const drop = (event:any) => {
	loading.value = true;
	event.preventDefault();
	event.currentTarget.classList.add('upload-article__bg-gray-100');
	event.currentTarget.classList.remove('upload-article__bg-green-300');
	
	Array.from(event.dataTransfer.files).forEach(file => {
		const newFile:File|any = file;
		items.value.push({
			title: newFile.name,
			campaign_id: props.isDialog ? props.campaignId: null,
			language_id: props.isDialog ? props.languageId: null,
			note: null,
			footer_id: null,
			footer_list: [],
			file: newFile,
			photo: null,
		});
	});
	setTimeout(() => {
		loading.value = false;
	}, 500);
};

const deleteItem = (index: number) => {
	items.value.splice(index, 1);
};

const campaignFooterList = async(campaignId:number, index:number) => {
	let campaignUuid = null;
	const campaign = campaigns.find(elem => elem.id === campaignId);
	if (null != campaign) {
		campaignUuid = campaign.uuid;
	}
	if (null == campaignUuid) {
		return;
	}
	try {
		const result = await campaignApi.campaignsFooters(campaignUuid);
		if (!result.isSuccess) {
			return;
		}
		if (null == result.payload.data || result.payload.data.length == 0) {
			items.value[index].footer_id = null;
		}
		items.value[index].footer_list = result.payload.data;
	} catch (e) {
		return;
	}
};

const footerAction = (campaignId:number, index:number) => {
	campaignFooterList(campaignId, index);
}
const applyToAll = (column:string, value:number|string, index?:number) => {
	if (column === 'campaign_id') {
		items.value.forEach(elem => {
			elem.campaign_id = value;
		});
		items.value.forEach(elems => {
			elems.footer_list = items.value[index as keyof typeof items.value].footer_list;
		});
	}
	if (column === 'language_id') {
		items.value.forEach(elem => {
			elem.language_id = value;
		});
	}
	if (column === 'note') {
		items.value.forEach(elem => {
			elem.note = value;
		});
	}
	if (column === 'footer_id') {
		items.value.forEach(elem => {
			elem.footer_id = value;
		});
	}
};

const dialogSaveArticle = (articleId: number, response: IUploadArticleResponse) => {
	emit('dialogSaveArticle', articleId, response);
};
</script>

<style scoped lang="scss">
.upload-article {
	&__heading-label {
		margin-bottom: 5px;
	}
	
	&__drop-wrapper {
		border: 2px dashed $grey-300;
		border-radius: 12px;
		
		&--input-wrapper {
			padding: 15px;
			height: 158px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			
			input[type='file'] {
				position: absolute;
				z-index: 1;
				top: 70%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 158px;
				height: 40px;
				opacity: 0;
				cursor: pointer;
			}
			
			label {
				margin-bottom: 20px;
			}
		}
	}
	
	&__file-btn {
		background-color: $primary-400;
		cursor: pointer;
		border-radius: 8px;
		color: $white-100;
		font-size: 14px;
		font-weight: 600;
		padding-top: 9px;
		text-align: center;
		position: absolute;
		top: 70%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 158px;
		height: 40px;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__drop-text {
		font-size: 12px;
		color: $grey-300;
	}
	
	&__bg-green-300 {
		background-color: $green-300;
		padding: 20px;
		border-radius: 12px;
		
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
	}
	
	&__bg-gray-100 {
		background-color: $grey-200;
		padding: 20px;
		border-radius: 12px;
		
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
	}
}
</style>
