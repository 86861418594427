// import { defineAsyncComponent } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import Components from '@/views/Components.vue';
import Default from '@/views/Layout/Default.vue';
import Dashboard from '@/views/Dashboard/Dashboard.vue';
import Banners from '@/views/Banners/Banners.vue';
import BannersForm from '@/views/Banners/BannersForm.vue';
import OrderGroupList from '@/views/Orders/OrderGroupList.vue';
import WebsiteList from '@/views/Websites/WebsiteList.vue';
import CreateEditWebsite from '@/views/Websites/CreateEditWebsite.vue';
import WebsiteDetails from '@/views/Websites/WebsiteDetails.vue';
import OffersList from '@/views/Offers/OffersList.vue';
import CreateEditOffer from '@/views/Offers/CreateEditOffer.vue';
import MailChimp from '@/views/Mailchimp/MailChimp.vue';
import GoogleStatus from '@/views/Google/GoogleStatus.vue';
import Coupons from '@/views/Coupons/Coupons.vue';
import CreateEditCoupons from '@/views/Coupons/CreateEditCoupons.vue';
import OffersDetails from '@/views/Offers/OffersDetails.vue';
import Chats from '@/views/Chats/Chats.vue';
import WebsiteRequests from '@/views/WebsiteRequests/WebsiteRequests.vue';
import ArticleList from '@/views/Articles/ArticleList.vue';
import DiscountGroups from '@/views/DiscountGroups/DiscountGroups.vue';
import OfferDiscountGroups from '@/views/DiscountGroups/OfferDiscountGroups.vue';
import PriceList from '@/views/PriceList/PriceList.vue';
import BasketList from '@/views/Cart/BasketList.vue';
import OrderList from '@/views/Orders/OrderList.vue';
import Agency from '@/views/Agency/Agency.vue';
import AgencyCreateUser from '@/views/Agency/AgencyCreateUser.vue';
import AgencyBalanceUser from '@/views/Agency/AgencyBalanceUser.vue';
import HistoryPayment from '@/views/HistoryPayment/HistoryPayment.vue';
import Order from '@/views/Orders/Order.vue';
import OrderPublication from '@/components/organisms/OrderCreate/OrderPublication.vue';
import CreateEditArticle from '@/views/Articles/CreateEditArticle.vue';
import OrderArticle from '@/views/OrderCreate/OrderArticle.vue';
import AddNewArticle from '@/views/Articles/AddNewArticle.vue';
import UploadArticle from '@/components/organisms/Articles/UploadArticle.vue';
import UploadArticleGoogleDrive from '@/views/Articles/UploadArticleGoogleDrive.vue';
import GoogleUploadArticleRedirect from '@/views/Google/GoogleUploadArticleRedirect.vue';
import ExportList from '@/views/ImportExport/ExportList.vue';
import Import from '@/views/ImportExport/Import.vue';
import OptimalizationContaduArticle from '@/views/Articles/OptimalizationContaduArticle.vue';
import CartSummary from '@/views/Cart/CartSummary.vue';
import ImportPreview from '@/views/ImportExport/ImportPreview.vue';
import Impersonate from '@/views/Impersonate/Impersonate.vue';
import ArticlePreview from '@/views/Articles/ArticlePreview.vue';
import Login from '@/views/Login/Login.vue';
import GoogleLoginRedirect from '@/views/Google/GoogleLoginRedirect.vue';
import Register from '@/views/Register/Register.vue';
import Partners from '@/views/Static/Partners.vue';
import PrivacyPolicy from '@/views/Static/PrivacyPolicy.vue';
import Rules from '@/views/Static/Rules.vue';
import Profile from '@/views/Profile/Profile.vue';
import PaymentsSummary from '@/views/Payments/PaymentsSummary.vue';
import Campaigns from '@/views/Campaigns/Campaigns.vue';
import OrderSelectCampaign from '@/views/OrderCreate/OrderSelectCampaign.vue';
import CampaignsCreateEdit from '@/views/Campaigns/CampaignsCreateEdit.vue';
import ResetPassword from '@/views/ResetPassword/ResetPassword.vue';
import ResetPasswordChangePassword from '@/views/ResetPassword/ResetPasswordChangePassword.vue';
import Ahrefs from '@/views/Ahrefs/Ahrefs.vue';
import AffiliateProgram from '@/views/AffiliateProgram/AffiliateProgram.vue';
import WithdrawEditCreate from '@/views/Withdraw/WithdrawEditCreate.vue';
import Transfer from '@/views/Transfer/Transfer.vue';
import AffiliateProgramDetails from '@/views/AffiliateProgram/AffiliateProgramDetails.vue';
import ArchiveHistoryTransactionTable from '@/views/ArchiveHistoryTransaction/ArchiveHistoryTransactionTable.vue';
import WithdrawList from '@/views/Withdraw/WithdrawList.vue';
import PaymentTransactions from '@/views/PaymentTransactions/PaymentTransactions.vue';
import CreateCartList from '@/views/Cart/CreateCartList.vue';
import CreateEditCart from '@/views/Cart/CreateEditCart.vue';
import CartPreview from '@/views/Cart/CartPreview.vue';
import UsersList from '@/views/Users/UsersList.vue';

// Ponizej zapis lazy loading
// const Dashboard = defineAsyncComponent(() => import('../views/Dashboard/Dashboard.vue'));
// const Banners = defineAsyncComponent(() => import('../views/Banners/Banners.vue'));
// const BannersForm = defineAsyncComponent(() => import('../views/Banners/BannersForm.vue'));

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/components',
            name: 'Components',
            component: Components
        },
        {
            path: '/partners',
            name: 'Partners',
            component: Partners
        },
        {
            path: '/static/privacyPolicy',
            name: 'PrivacyPolicy',
            component: PrivacyPolicy
        },
        {
            path: '/static/termOfUse',
            name: 'Rules',
            component: Rules
        },
	    {
		    path: '/login/google/authorize/redirect',
		    name: 'GoogleLoginRedirect',
		    component: GoogleLoginRedirect
	    },
        {
            path: '/',
            name: 'Login',
            component: Login
        },
        {
            path: '/register',
            name: 'Register',
            component: Register,
        },
	    {
		    path: '/password/reset',
		    name: 'ResetPassword',
		    component: ResetPassword
	    },
	    {
		    path: '/password/reset/:token',
		    name: 'ResetPasswordChangePassword',
		    component: ResetPasswordChangePassword
	    },
        {
            path: '/',
            name: 'UserLogged',
            component: Default,
            children: [
                {
                    path: '/dashboard',
                    name: 'Dashboard',
                    component: Dashboard
                },
                {
                    path: '/banners',
                    name: 'Banners',
                    component: Banners
                },
                {
                    path: '/banners/create',
                    name: 'BannersForm',
                    component: BannersForm
                },
                {
                    path: '/banners/:uuid',
                    name: 'BannersForm',
                    component: BannersForm
                },
                {
                    path: '/order/publication',
                    name: 'OrderGroupList',
                    component: OrderGroupList
                },
                {
                    path: '/websites',
                    name: 'WebsiteList',
                    component: WebsiteList
                },
                {
                    path: '/websites/create',
                    name: 'CreateEditWebsite',
                    component: CreateEditWebsite
                },
                {
                    path: '/websites/:uuid',
                    name: 'CreateEditWebsite',
                    component: CreateEditWebsite
                },
                {
                    path: '/websites/details/:uuid',
                    name: 'WebsiteDetails',
                    component: WebsiteDetails
                },
	            {
		            path: '/websites/offers',
		            name: 'OffersList',
		            component: OffersList
	            },
	            {
		            path: '/websites/offer/create/:websiteUuid',
		            name: 'CreateOffer',
		            component: CreateEditOffer
	            },
	            {
		            path: '/websites/offer/:offerUuid',
		            name: 'EditOffer',
		            component: CreateEditOffer
	            },
	            {
		            path: '/websites/offer/:offerUuid/duplicate',
		            name: 'DuplicateOffer',
		            component: CreateEditOffer
	            },
	            {
		            path: '/websites/offer/details/:offerUuid',
		            name: 'OffersDetails',
		            component: OffersDetails
	            },
	            {
		            path: '/mailchimp',
		            name: 'MailChimp',
		            component: MailChimp
	            },
	            {
		            path: '/google',
		            name: 'GoogleStatus',
		            component: GoogleStatus
	            },
	            {
		            path: '/coupons',
		            name: 'Coupons',
		            component: Coupons
	            },
	            {
		            path: '/coupons/:couponUuid',
		            name: 'CreateEditCoupons',
		            component: CreateEditCoupons
	            },
	            {
		            path: '/chats',
		            name: 'Chats',
		            component: Chats
	            },
	            {
		            path: '/website/requests',
		            name: 'WebsiteRequests',
		            component: WebsiteRequests
	            },
	            {
		            path: '/article',
		            name: 'ArticleList',
		            component: ArticleList
	            },
	            {
		            path: '/discount-groups',
		            name: 'DiscountGroups',
		            component: DiscountGroups
	            },
	            {
		            path: '/discount-groups/:slug',
		            name: 'OfferDiscountGroups',
		            component: OfferDiscountGroups
	            },
	            {
		            path: '/price-list',
		            name: 'PriceList',
		            component: PriceList
	            },
	            {
		            path: '/order/list/:userId',
		            name: 'OrderList',
		            component: OrderList
	            },
	            {
		            path: '/order/baskets/:userId',
		            name: 'BasketList',
		            component: BasketList
	            },
	            {
		            path: '/agency',
		            name: 'Agency',
		            component: Agency
	            },
	            {
		            path: '/agency/create',
		            name: 'AgencyCreateUser',
		            component: AgencyCreateUser
	            },
	            {
		            path: '/agency/balance/:userUuid',
		            name: 'AgencyBalanceUser',
		            component: AgencyBalanceUser
	            },
	            {
		            path: '/order/deposit',
		            name: 'HistoryPayment',
		            component: HistoryPayment
	            },
	            {
		            path: '/order/:orderUuid',
		            name: 'Order',
		            component: Order
	            },
	            {
		            path: '/websites/:campaignId/orders',
		            name: 'OrderPublication',
		            component: OrderPublication
	            },
	            {
		            path: '/article/create/write',
		            name: 'CreateArticle',
		            component: CreateEditArticle
	            },
	            {
		            path: '/article/:articleUuid',
		            name: 'EditArticle',
		            component: CreateEditArticle
	            },
	            {
		            path: '/article/:articleUuid/preview',
		            name: 'ArticlePreview',
		            component: ArticlePreview
	            },
	            {
		            path: '/order/article',
		            name: 'OrderArticle',
		            component: OrderArticle
	            },
	            {
		            path: '/article/create',
		            name: 'AddNewArticle',
		            component: AddNewArticle
	            },
	            {
		            path: '/article/create/upload',
		            name: 'UploadArticle',
		            component: UploadArticle
	            },
	            {
		            path: '/article/create/upload/google-drive',
		            name: 'UploadArticleGoogleDrive',
		            component: UploadArticleGoogleDrive
	            },
	            {
		            path: '/google/authorize/redirect',
		            name: 'GoogleUploadArticleRedirect',
		            component: GoogleUploadArticleRedirect
	            },
	            {
		            path: '/export-list',
		            name: 'ExportList',
		            component: ExportList
	            },
	            {
		            path: '/import',
		            name: 'Import',
		            component: Import
	            },
	            {
		            path: '/import/:importUuid/preview',
		            name: 'ImportPreview',
		            component: ImportPreview
	            },
	            {
		            path: '/import/:importUuid/preview/read-only',
		            name: 'ImportPreviewReadOnly',
		            component: ImportPreview
	            },
	            {
		            path: '/article/:articleUuid/optimalize/:optimalizeId',
		            name: 'OptimalizationContaduArticle',
		            component: OptimalizationContaduArticle
	            },
	            {
		            path: '/basket/:orderUuid',
		            name: 'Cart',
		            component: CartPreview
	            },
	            {
		            path: '/payments/:orderUuid',
		            name: 'CartSummary',
		            component: CartSummary
	            },
	            {
		            path: '/auth/impersonate/:uuid',
		            name: 'Impersonate',
		            component: Impersonate
	            },
	            {
		            path: '/profile',
		            name: 'Profile',
		            component: Profile
	            },
	            {
		            path: '/profile/:userUuid',
		            name: 'ProfileEdit',
		            component: Profile
	            },
	            {
		            path: '/payments/cancel',
		            name: 'PaymentsSummaryCancel',
		            component: PaymentsSummary
	            },
	            {
		            path: '/payments/completed',
		            name: 'PaymentsSummaryCompleted',
		            component: PaymentsSummary
	            },
	            {
		            path: '/campaigns',
		            name: 'Campaigns',
		            component: Campaigns
	            },
	            {
		            path: '/order/publication/cart',
		            name: 'OrderSelectCampaign',
		            component: OrderSelectCampaign
	            },
	            {
		            path: '/campaigns/:campaignUuid',
		            name: 'CampaignsCreateEdit',
		            component: CampaignsCreateEdit
	            },
	            {
		            path: '/ahrefs',
		            name: 'Ahrefs',
		            component: Ahrefs
	            },
	            {
		            path: '/affiliate-program',
		            name: 'AffiliateProgram',
		            component: AffiliateProgram
	            },
	            {
		            path: '/affiliate-program/details',
		            name: 'AffiliateProgramDetails',
		            component: AffiliateProgramDetails
	            },
	            {
		            path: '/withdrawal/create',
		            name: 'WithdrawEditCreate',
		            component: WithdrawEditCreate
	            },
	            {
		            path: '/withdrawal/:withdrawUuid',
		            name: 'WithdrawEditCreate',
		            component: WithdrawEditCreate
	            },
	            {
		            path: '/withdrawal',
		            name: 'WithdrawList',
		            component: WithdrawList
	            },
	            {
		            path: '/transfer',
		            name: 'Transfer',
		            component: Transfer
	            },
	            {
		            path: '/archived/order/deposit',
		            name: 'ArchiveHistoryTransactionTable',
		            component: ArchiveHistoryTransactionTable
	            },
	            {
		            path: '/payment-transactions',
		            name: 'PaymentTransactions',
		            component: PaymentTransactions
	            },
	            {
		            path: '/carts',
		            name: 'CreateCartList',
		            component: CreateCartList
	            },
	            {
		            path: '/carts/:cartUuid',
		            name: 'CreateEditCart',
		            component: CreateEditCart
	            },
	            {
		            path: '/carts/:cartUuid/preview',
		            name: 'CartPreview',
		            component: CartPreview
	            },
	            {
		            path: '/users-list',
		            name: 'UsersList',
		            component: UsersList
	            },
            ],
        },
    ],
});

export default router;
