<template>
    <div class="m-autocomplete" :class="{'m-autocomplete__exclude-include-append-inner-icon': excludeInclude}">
        <div v-if="label" class="m-autocomplete__label">{{ label }}<span class="m-autocomplete__required-star">{{ labelRequired ? '*' : labelTextRequired ? ` ${$t('components.fieldRequired')}` : '' }}</span></div>
        <v-autocomplete
            v-model="value"
            @input="inputEmitSearchValueAction"
            :search="searchInput"
            :items="getItems"
            :placeholder="placeholder"
            :disabled="disabled"
            :single-line="!variant"
            :variant="variant"
            :clearable="clearable"
            :item-title="itemTitle"
            :item-value="itemValue"
            closable-chips
            :chips="chips"
            :error-messages="errors"
            :multiple="multiple"
            :hint="hint"
            :persistent-hint="persistentHint"
            :loading="loading"
            required
            :no-filter="noFilter"
            :return-object="returnObject"
            :class="{'m-autocomplete__disabled': disabled}"
            :no-data-text="null == noDataText ? $t('components.noDataText') : noDataText"
            @update:modelValue="handleUpdateModelValue"
            ref="autocompleteRef"
            @update:focused="focusAction"
            :menu-icon="`mdi-${menuIcon}`"
        >
            <template v-slot:prepend-item>
                <slot name="prepend-item"></slot>
            </template>
            <template v-slot:prepend-inner>
                <slot name="prepend-inner"></slot>
            </template>
            <template v-slot:prepend>
                <slot name="prepend"></slot>
            </template>
            <template v-slot:append>
                <slot name="append"></slot>
            </template>
            <template v-slot:append-item>
                <slot name="append-item"></slot>
            </template>
            <template v-slot:append-inner>
                <slot name="append-inner"></slot>
            </template>
        </v-autocomplete>
    </div>
</template>

<script setup lang="ts">
import { defineProps, toRef, defineEmits, computed, ref } from 'vue';
import { useField } from 'vee-validate';

interface IOwnProps {
    name?: string
    modelValue?: any
    label?: string
    placeholder?: string
    labelRequired?: boolean
	labelTextRequired?: boolean
    disabled?: boolean
    variant?: 'filled'|'outlined'|'plain'|'underlined'|'solo'|'solo-inverted'|'solo-filled'|undefined
    clearable?: boolean
    chips?: boolean
    multiple?: boolean
    getItems?: Array<any>
    loading?: boolean
    itemTitle?: string
    itemValue?: string
    noFilter?: boolean
    returnObject?: boolean
    excludeInclude?: boolean
    searchInputModel?: string
    hint?: string
	persistentHint?: boolean
	noDataText?: string|null
	menuIcon?: string
	isFetchHandlerAction?: boolean
}

interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();
const emit = defineEmits(['update:modelValue', 'update:search', 'onChange', 'fetchAction', 'focusAction', 'fetchClearAction']);

const { errors } = useField(toRef<IOwnProps, any>(props, 'name'), undefined);

const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

// TO CHECK DOESN'T WORK NOW
const searchInput = computed({
    get() {
        return props.searchInputModel;
    },
    set(value) {
        emit('update:search', value);
    }
});

const inputEmitSearchValueAction = (val:any) => {
    emit('update:search', val.target.value);
};
const autocompleteRef = ref<any>(null);
const handleUpdateModelValue = async() => {
	if (null != autocompleteRef.value) {
		autocompleteRef.value.search = null;
	}
	emit('onChange');
};

const scrollAction = () => {
	let autocompleteContent = document.querySelector('.v-list--one-line');
	if (autocompleteContent && autocompleteContent.scrollTop + autocompleteContent.clientHeight >= autocompleteContent.scrollHeight) {
		emit('fetchAction');
	}
};
const focusAction = () => {
	if (props.isFetchHandlerAction) {
		setTimeout(() => {
			let autocompleteContent = document.querySelector('.v-list--one-line');
			if (null != autocompleteContent) {
				autocompleteContent.addEventListener('scroll', scrollAction);
			} else {
				emit('fetchClearAction');
			}
		}, 100);
	} else {
		emit('focusAction');
	}
};
</script>

<style scoped lang="scss">
.m-autocomplete {
    &__required-star {
        color: $secondary-400;
    }
    
    &__label {
        color: $grey-400;
        font-weight: $weight-700;
        font-size: $size-14;
    }
    
    &__disabled {
	    &:deep(.v-input__slot fieldset) {
		    border: none;
	    }
	    &:deep(.v-input__slot) {
		    padding: 0 !important;
	    }
    }
    
    &__exclude-include-append-inner-icon {
	    &:deep(.v-text-field--enclosed .v-input__append-inner) {
		    margin-top: 26px !important;
	    }
    }
	
	&:deep(.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections) {
		min-height: 42px;
	}

	&:deep(.v-input__slot) {
		border-radius: 8px;
	}

	&:deep(.v-input__slot fieldset) {
		border-color: $black;
	}

	&:deep(.v-chip--select) {
		background-color: $secondary-400 !important;
		color: $white-100 !important;
	}

	&:deep(.v-chip__close) {
		color: $white-100 !important;
	}

	&:deep(.v-field--variant-filled .v-field__overlay) {
		background-color: $white-100;
	}
	
	&:deep(.v-input--horizontal .v-input__append) {
		margin-inline-start: 0;
		margin-inline-end: 0;
	}
	
	&:deep(.v-input--horizontal .v-input__prepend) {
		margin-inline-start: 0;
		margin-inline-end: 0;
	}
	&:deep(.v-field__input) {
		padding: 7px 0 7px !important;
		min-height: 43px !important;
	}
}
</style>
