<template>
	<BeforeLoginLayout class="login-page">
		<template v-slot:default>
			<InfoBox v-if="maintenance_mode" is-hide-close-icon :text="$t('components.maintenanceInfo')" />
			<Form :validation-schema="schema" @submit="onSubmitLogin" class="login-page__form-wrapper">
				<img src="~@/assets/images/logo-reverse.png" alt="marketing logo" class="login-page__logo">
				<MTextField
					id="input-email"
					v-model="loginForm.email"
					name="email"
					:label="$t('auth.emailAddress')"
					:placeholder="$t('auth.email')"
				/>
				<MTextField
					id="input-password"
					v-model="loginForm.password"
					name="password"
					:label="$t('auth.password')"
					:placeholder="$t('auth.passwordEnter')"
					type="password"
				/>
				<MButton
					id="btn-login"
					:label="$t('auth.logIn')"
					blue400
					big
					:disabled="!loginForm.email || !loginForm.password || loading"
					:loading="loading"
				/>
			</Form>
			
			<div class="login-page__btn-wrapper">
				<div @click="goToResetPassword" class="login-page__forget-password">{{ $t('auth.forgotPassword') }}</div>
				<MButton
					id="btn-login-google"
					:label="$t('auth.loginWithGoogle')"
					blue400
					big
					@click="googleLogin"
					:loading="googleLoading"
					:disabled="loading"
				/>
				<div class="login-page__text">{{ $t('auth.dontHaveAccount') }}</div>
				<MButton
					id="btn-register"
					:label="$t('auth.signUp')"
					green400
					big
					@click="goToRegister"
				/>
			</div>
		</template>
	</BeforeLoginLayout>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import { useGoogleStore } from '@/stores/google';
import { useUserStore } from '@/stores/user';
import { useDictionaryStore } from '@/stores/dictionary';
import { useTableSettingsStore } from '@/stores/tableSettings';
import { setCountryTranslations } from '@/hooks/CountryHooks';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@/stores/session';
import { setLanguagesTranslations } from '@/hooks/LanguageHooks';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { toast } from 'vue3-toastify';
import MonolithAuthApi from '@/api/monolith/MonolithAuthApi';
import AuthApi from '@/api/v1/AuthApi';
import LanguageApi from '@/api/v1/LanguageApi';
import CountryApi from '@/api/v1/CountryApi';
import CurrencyApi from '@/api/v1/CurrencyApi';
import CampaignApi from '@/api/v1/CampaignApi';
import TableSettingsApi from '@/api/v1/TableSettingsApi';
import MonolithGoogleApi from '@/api/monolith/MonolithGoogleApi';
import type { LoginRequestInterface, UserInterface } from '@/models/AuthModel';
import MTextField from '@/components/atoms/MTextField.vue';
import MButton from '@/components/atoms/MButton.vue';
import BeforeLoginLayout from '@/views/Layout/components/BeforeLoginLayout.vue';
import InfoBox from '@/components/molecules/InfoBox.vue';

const monolithAuthApi = new MonolithAuthApi();
const authApi = new AuthApi();
const languageApi = new LanguageApi();
const countryApi = new CountryApi();
const currencyApi = new CurrencyApi();
const campaignApi = new CampaignApi();
const tableSettingsApi = new TableSettingsApi();
const monolithGoogleApi = new MonolithGoogleApi();

const { t } = useI18n();
const { setAuthorization, removeAuthorization } = useAuthStore();
const { setGoogleLoginToken, googleLoginToken } = useGoogleStore();
const { setUser, setUserCurrencySymbol } = useUserStore();
const { setCampaigns, setCurrencies, setLanguages, setCountries } = useDictionaryStore();
const { set } = useTableSettingsStore();
const { locale, maintenance_mode } = storeToRefs(useSessionStore());
const dictionaryStore = useDictionaryStore();

const user = ref<UserInterface|null>(null);
const loginForm = ref({
	email: null,
	password: null,
});
const loading = ref(false);
const googleLoading = ref(false);

const schema:any = yup.object({
	email: yup.string().email(`${t('auth.validation.email')}`).required(`${t('auth.validation.emailRequired')}`).label('email'),
	password: yup.string().required(`${t('auth.validation.passwordRequired')}`).min(3, `${t('auth.validation.passwordMin3')}`).label('password'),
});

const gtm = {
	pushEvent(event:string, name:string, value:any) {
		if (null != window.dataLayer) {
			window.dataLayer.push({
				event: event,
				[name]: value
			});
		}
	}
};

removeAuthorization();

const getTableSettings = async() => {
	if (null == user.value) {
		return;
	}
	try {
		const result = await tableSettingsApi.tableSettings(user.value?.active_role_id);
		if (!result.isSuccess) {
			return;
		}
		for (const item in result.payload) {
			await set({ name: item, settings: result.payload[item] });
		}
	} catch (e) {
		return;
	}
};
const getCampaigns = async() => {
	try {
		const result = await campaignApi.getCampaigns();
		if (!result.isSuccess) {
			return;
		}
		await setCampaigns(result.payload.data);
	} catch (e) {
		return;
	}
};
const getCurrencies = async() => {
	try {
		const result = await currencyApi.getCurrencies();
		if (!result.isSuccess) {
			return;
		}
		const currency = result.payload.data.find(elem => elem.id === user.value?.currency_id);
		if (null != currency) {
			await setUserCurrencySymbol(currency?.currency_symbol);
		}
		await setCurrencies(result.payload.data);
	} catch (e) {
		return;
	}
};
const getLanguages = async() => {
	try {
		const result = await languageApi.getLanguages();
		if (!result.isSuccess) {
			return;
		}
		await setLanguages(result.payload.data);
	} catch (e) {
		return;
	}
};
const getCountries = async() => {
	try {
		const result = await countryApi.getCountries();
		if (!result.isSuccess) {
			return;
		}
		await setCountries(result.payload.data);
	} catch (e) {
		return;
	}
};
const getUser = async() => {
	try {
		const result = await authApi.me();
		if (!result.isSuccess) {
			return;
		}
		await setUser(result.payload);
		user.value = result.payload;
	} catch (e) {
		return;
	}
};
const storeAuthorizeCredentials = async(response:any) => {
	// to wywalalo widoki poniewaz sesje sie mieszaly (dodawaly sie 2 takie same ciasteczka w monolicie i vue3 przez to aplikacja sie wywalala) mozliwe ze w przyszlosci do odkomentowania
	// document.cookie = `marketin9_session=${response.headers['cookie-id']}`;
	
	await setAuthorization(response.payload);
	
	await getUser();
	await getCurrencies();
	await getCountries();
	await getLanguages();
	await getCampaigns();
	await getTableSettings();
	
	let localeTranslations = 'pl';
	if (locale.value != null) {
		localeTranslations = locale.value;
	}
	await dictionaryStore.setCountries(setCountryTranslations(localeTranslations));
	await dictionaryStore.setLanguages(setLanguagesTranslations(localeTranslations));
	
	await setGoogleLoginToken(null);
	await localStorage.removeItem('googleToken');
	await router.push('/dashboard?success');
	// Dodanie zdarzenia do Google Tag Menagera
	gtm.pushEvent('Success Login', 'success_login', 1);
};
const onSubmitLogin = async() => {
	loading.value = true;
	
	if (loginForm.value.email == null || loginForm.value.email === '' || loginForm.value.password == null || loginForm.value.password === '') {
		toast.error(`${t('auth.toast.loginValidation')}`);
		loading.value = false;
		return;
	}
	
	const request:LoginRequestInterface = {
		email: loginForm.value.email,
		password: loginForm.value.password,
	};
	
	try {
		const result = await monolithAuthApi.monolithLogin(request);
		if (!result.isSuccess) {
			toast.error(`${t('auth.toast.loginError')}`);
			loading.value = false;
			return;
		}
		await storeAuthorizeCredentials(result);
	} catch (e) {
		toast.error(`${t('auth.toast.loginError')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const googleLogin = async() => {
	googleLoading.value = true;
	
	// Google's OAuth 2.0 endpoint for requesting an access token
	const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
	// Create element to open OAuth 2.0 endpoint in new window.
	const form = document.createElement('form');
	// Send as a GET request.
	form.setAttribute('method', 'GET');
	form.setAttribute('action', oauth2Endpoint);
	
	// Parameters to pass to OAuth 2.0 endpoint.
	const params:any = {
		client_id: import.meta.env.VITE_GOOGLE_CLIENT_TOKEN,
		redirect_uri: import.meta.env.VITE_LOGIN_GOOGLE_REDIRECT_URL,
		scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
		response_type: 'token',
	};
	
	// Add form parameters as hidden input values.
	for (const p in params) {
		const input = document.createElement('input');
		input.setAttribute('type', 'hidden');
		input.setAttribute('name', p);
		input.setAttribute('value', params[p]);
		form.appendChild(input);
	}
	// Add form to page and submit it to open the OAuth 2.0 endpoint.
	document.body.appendChild(form);
	form.submit();
	
	googleLoading.value = false;
};
const goToRegister = async() => {
	await router.push('/register');
};

const sendGoogleToken = async() => {
	googleLoading.value = true;
	
	try {
		const result = await monolithGoogleApi.googleLogin();
		if (!result.isSuccess) {
			googleLoading.value = false;
			return;
		}
		await storeAuthorizeCredentials(result);
	} catch (e) {
		googleLoading.value = false;
		return;
	}
	googleLoading.value = false;
};

if (null != googleLoginToken) {
	sendGoogleToken();
}

const goToResetPassword = () => {
	router.push('/password/reset');
};

const checkPrepareCart = () => {
	if (null != localStorage.getItem('cart-slug')) {
		toast.info(`${t('basket.preview.toast.loginToCheckoutToCart')}`);
	}
};
checkPrepareCart();
</script>

<style scoped lang="scss">
.login-page {
	&__logo {
		width: 224px;
		height: 46px;
		margin: 0 auto 20px;
	}
	
	&__form-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	
	&__btn-wrapper {
		display: flex;
		flex-direction: column;
		gap: 15px;
		margin-top: 15px;
	}
	
	&__forget-password {
		font-size: 14px;
		width: max-content;
		font-weight: 700;
		margin: 0 auto;
		text-decoration: none;
		color: $grey-400;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__text {
		font-size: 14px;
		width: max-content;
		font-weight: 700;
		margin: 0 auto;
		color: $primary-400;
	}
	
	&:deep(.m-input) {
		@include media-breakpoint-up(md) {
			width: 480px;
			margin: 0 auto;
		}
	}
	
	&:deep(.m-btn) {
		width: 240px;
		margin: 0 auto;
	}
	
	&:deep(.info-box) {
		position: absolute;
		top: -54px;
		border-radius: 8px;
		width: 100%;
		left: 50%;
		transform: translate(-50%, 0);
	}
}
</style>
