<template>
	<div class="m-chart">
		<Bar
			v-if="!isLine"
			:id="chartId"
			:options="chartOptions"
			:data="chartData"
		/>
		<Line
			v-if="isLine"
			:id="chartId"
			:options="chartOptions"
			:data="chartData"
		/>
	</div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import { Bar, Line } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, Filler} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, Filler);

interface IOwnProps {
	chartId: string
	chartOptions: Object
	chartData: any
	isLine?: boolean
}

interface IProps extends IOwnProps {
}

defineProps<IProps>();
</script>

<style scoped lang="scss">

</style>
