<template>
	<div class="ahrefs-users-reload">
		<div class="ahrefs-users-reload__title">{{ $t('ahrefs.usersReloadInfo') }}</div>
		
		<MProgressBar v-show="loading" indeterminate height="2" />
		<div class="ahrefs-users-reload__wrapper">
			<MAutocomplete
				id="ahrefs-users"
				v-model="selectedAhrefsUsers"
				v-model:search="autocompleteInputValue"
				name="users"
				:get-items="userList"
				item-title="email"
				item-value="uuid"
				label=""
				:placeholder="$t('coupons.usersSelect')"
				variant="outlined"
				multiple
				clearable
			/>
			
			<MButton
				id="ahrefs-users-submit"
				blue400
				:label="$t('ahrefs.usersSubmit')"
				normal
				:loading="ahrefsLoading"
				@click="ahrefsUsersSubmit"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import AhrefsApi from '@/apiWebsites/v1/AhrefsApi';
import UserApi from '@/api/v1/UserApi';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import MButton from '@/components/atoms/MButton.vue';
import type { UserInterface } from '@/models/AuthModel';

const ahrefsApi = new AhrefsApi();
const userApi = new UserApi();

const { t } = useI18n();

const loading = ref<boolean>(false);
const ahrefsLoading = ref<boolean>(false);
const userList = ref<Array<UserInterface>|any>([]);
const autocompleteInputValue = ref<string|null>('');
const autocompleteInputValueTimeout = ref<any>(undefined);
const selectedAhrefsUsers = ref<Array<string>>([]);

const usersList = async(val:string|null) => {
	loading.value = true;
	try {
		const result = await userApi.usersList(val);
		if (!result.isSuccess) {
			toast.error(`${t('ahrefs.toast.errorAhrefsUsersList')}`);
			loading.value = false;
			return;
		}
		userList.value = result.payload;
	} catch (e) {
		toast.error(`${t('ahrefs.toast.errorAhrefsUsersList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

watch((autocompleteInputValue), (newVal, oldVal) => {
	if (newVal !== oldVal) {
		clearTimeout(autocompleteInputValueTimeout.value);
	}
	autocompleteInputValueTimeout.value = setTimeout(() => {
		usersList(newVal);
	}, 700);
});

const ahrefsUsersSubmit = async() => {
	ahrefsLoading.value = true;
	const form:any= {
		users: selectedAhrefsUsers.value,
	}
	try {
		const result = await ahrefsApi.ahrefsUsersDomainReload(form);
		if (!result.isSuccess) {
			toast.error(`${t('ahrefs.toast.errorAhrefsReload')}`);
			ahrefsLoading.value = false;
			return;
		}
		toast.success(`${t('ahrefs.toast.successAhrefsReload')}`);
	} catch (e) {
		toast.error(`${t('ahrefs.toast.errorAhrefsReload')}`);
		ahrefsLoading.value = false;
		return;
	}
	ahrefsLoading.value = false;
}
</script>

<style scoped lang="scss">
.ahrefs-users-reload {
	margin: 15px 0;
	
	&__title {
		color: $primary-400;
		font-weight: 700;
		font-size: 16px;
	}
	
	&__wrapper {
		display: flex;
		gap: 20px;
		
		&:deep(.m-autocomplete) {
			width: 50%;
		}
	}
}
</style>
