<template>
	<div class="date-picker-with-menu__select-wrapper">
		<MMenu location="bottom" min-width="132" max-width="132">
			<template v-slot:menuActivator>
				<div class="date-picker-with-menu__select-date-wrapper" :class="[{
					'date-picker-with-menu__select-date-wrapper--big-red': isBigRedTitle,
					'date-picker-with-menu__select-date-wrapper--big-blue': isBigBlueTitle,
				}]">
					<div>{{ selectedDateText }}</div>
					<MIcon icon="arrow-down" height="12" width="12" :color="black" />
				</div>
			</template>
			<template v-slot:menuItems>
				<div class="date-picker-with-menu__menu-option">
					<div>{{ $t('dashboard.custom') }}</div>
					<div class="date-picker-with-menu__range-picker">
						<MDateRangePicker v-model="dataRange" name="dateRange" :locale="localeLanguage" is-custom-buttons :max-date="maxDate">
							<template #close-button>
								<div class="date-picker-with-menu__action-btn">{{ $t('dashboard.cancel') }}</div>
							</template>
							<template #submit-button>
								<div class="date-picker-with-menu__action-btn">{{ $t('dashboard.submit') }}</div>
							</template>
						</MDateRangePicker>
					</div>
				</div>
				<div @click="getStatistics(LAST_7_DAYS)" class="date-picker-with-menu__menu-option date-picker-with-menu__menu-option--border">{{ $t('dashboard.last7Days') }}</div>
				<div @click="getStatistics(LAST_14_DAYS)" class="date-picker-with-menu__menu-option">{{ $t('dashboard.last14Days') }}</div>
				<div @click="getStatistics(LAST_30_DAYS)" class="date-picker-with-menu__menu-option">{{ $t('dashboard.last30Days') }}</div>
				<div @click="getStatistics(CURRENT_MONTH)" class="date-picker-with-menu__menu-option">{{ $t('dashboard.currentMonth') }}</div>
				<div @click="getStatistics(PREVIOUS_MONTH)" class="date-picker-with-menu__menu-option">{{ $t('dashboard.previousMonth') }}</div>
				<div @click="getStatistics(LAST_YEAR)" class="date-picker-with-menu__menu-option">{{ $t('dashboard.lastYear') }}</div>
				<div @click="getStatistics(LAST_5_YEARS)" class="date-picker-with-menu__menu-option">{{ $t('dashboard.last5Years') }}</div>
				<div @click="getStatistics(ALL_ORDERS)" class="date-picker-with-menu__menu-option">{{ $t('dashboard.allOrders') }}</div>
				<div @click="getStatistics(YEAR_2023)" class="date-picker-with-menu__menu-option date-picker-with-menu__menu-option--border">{{ $t('dashboard.2023') }}</div>
				<div @click="getStatistics(YEAR_2022)" class="date-picker-with-menu__menu-option">{{ $t('dashboard.2022') }}</div>
				<div @click="getStatistics(YEAR_2021)" class="date-picker-with-menu__menu-option">{{ $t('dashboard.2021') }}</div>
				<div @click="getStatistics(YEAR_2020)" class="date-picker-with-menu__menu-option">{{ $t('dashboard.2020') }}</div>
				<div @click="getStatistics(YEAR_2019)" class="date-picker-with-menu__menu-option">{{ $t('dashboard.2019') }}</div>
			</template>
		</MMenu>
	</div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@/stores/session';
import dayjs from 'dayjs';
import variables from '@/assets/scss/modules/variables.module.scss';
import {
	CURRENT_MONTH,
	CUSTOM,
	LAST_14_DAYS,
	LAST_30_DAYS,
	LAST_5_YEARS,
	LAST_7_DAYS,
	LAST_YEAR,
	PREVIOUS_MONTH,
	YEAR_2019,
	YEAR_2020,
	YEAR_2021,
	YEAR_2022,
	YEAR_2023,
	ALL_ORDERS
} from '@/hooks/StatisticsHook';
import MIcon from '@/components/atoms/MIcon.vue';
import MMenu from '@/components/atoms/MMenu.vue';
import MDateRangePicker from '@/components/atoms/MDateRangePicker.vue';

interface IOwnProps {
	maxDate: string
	isBigRedTitle?: boolean
	isBigBlueTitle?: boolean
}
interface IProps extends IOwnProps {
}

defineProps<IProps>();
const emit = defineEmits(['emitDates']);
const { t } = useI18n();
const { locale } = storeToRefs(useSessionStore());
const { black } = variables;

const dataRange = ref(null);
const selectedDateText = ref('');
const localeLanguage = ref('');

if ('us' === locale.value) {
	localeLanguage.value = 'en';
} else if (locale.value != null) {
	localeLanguage.value = locale.value;
} else {
	localeLanguage.value = 'pl';
}
const getStatistics = async(slug:string) => {
	let from = '';
	let to = '';
	const today = dayjs().format('YYYY-MM-DD');
	switch (slug) {
		case CUSTOM:
			if (null != dataRange.value) {
				to = dataRange.value[1] === 'Invalid Date' ? today.toString() : dayjs(dataRange.value[1]).format('YYYY-MM-DD');
				from = dayjs(dataRange.value[0]).format('YYYY-MM-DD');
				selectedDateText.value = from + ' - ' + to;
			} else {
				to = today.toString();
				from = dayjs().subtract(30, 'days').format('YYYY-MM-DD');
				selectedDateText.value = t('dashboard.last30Days');
			}
			break;
		case LAST_7_DAYS:
			to = today.toString();
			from = dayjs().subtract(7, 'days').format('YYYY-MM-DD');
			selectedDateText.value = t('dashboard.last7Days');
			break;
		case LAST_14_DAYS:
			to = today.toString();
			from = dayjs().subtract(14, 'days').format('YYYY-MM-DD');
			selectedDateText.value = t('dashboard.last14Days');
			break;
		case LAST_30_DAYS:
			to = today.toString();
			from = dayjs().subtract(30, 'days').format('YYYY-MM-DD');
			selectedDateText.value = t('dashboard.last30Days');
			break;
		case LAST_YEAR:
			to = today.toString();
			from = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
			selectedDateText.value = t('dashboard.lastYear');
			break;
		case LAST_5_YEARS:
			to = today.toString();
			from = dayjs().subtract(5, 'year').format('YYYY-MM-DD');
			selectedDateText.value = t('dashboard.last5Years');
			break;
		case ALL_ORDERS:
			to = today.toString();
			from = dayjs().subtract(7, 'year').format('YYYY-MM-DD');
			selectedDateText.value = t('dashboard.allOrders');
			break;
		case YEAR_2023:
			to = dayjs('2023-12-31').format('YYYY-MM-DD');
			from = dayjs('2023-01-01').format('YYYY-MM-DD');
			selectedDateText.value = t('dashboard.2023');
			break;
		case YEAR_2022:
			to = dayjs('2022-12-31').format('YYYY-MM-DD');
			from = dayjs('2022-01-01').format('YYYY-MM-DD');
			selectedDateText.value = t('dashboard.2022');
			break;
		case YEAR_2021:
			to = dayjs('2021-12-31').format('YYYY-MM-DD');
			from = dayjs('2021-01-01').format('YYYY-MM-DD');
			selectedDateText.value = t('dashboard.2021');
			break;
		case YEAR_2020:
			to = dayjs('2020-12-31').format('YYYY-MM-DD');
			from = dayjs('2020-01-01').format('YYYY-MM-DD');
			selectedDateText.value = t('dashboard.2020');
			break;
		case YEAR_2019:
			to = dayjs('2019-12-31').format('YYYY-MM-DD');
			from = dayjs('2019-01-01').format('YYYY-MM-DD');
			selectedDateText.value = t('dashboard.2019');
			break;
		case CURRENT_MONTH:
			to = dayjs().month(+dayjs().format('MM') - 1).endOf('month').format('YYYY-MM-DD');
			from = dayjs().month(+dayjs().format('MM') - 1).startOf('month').format('YYYY-MM-DD');
			selectedDateText.value = t('dashboard.currentMonth');
			break;
		case PREVIOUS_MONTH:
			if (+dayjs().format('MM') > 1) {
				to = dayjs().month(+dayjs().format('MM') - 2).endOf('month').format('YYYY-MM-DD');
				from = dayjs().month(+dayjs().format('MM') - 2).startOf('month').format('YYYY-MM-DD');
			} else {
				to = dayjs().year(+dayjs().format('YYYY') - 1).month(11).endOf('month').format('YYYY-MM-DD');
				from = dayjs().year(+dayjs().format('YYYY') - 1).month(11).startOf('month').format('YYYY-MM-DD');
			}
			selectedDateText.value = t('dashboard.previousMonth');
			break;
		default:
			to = today.toString();
			from = dayjs().subtract(7, 'year').format('YYYY-MM-DD');
			selectedDateText.value = t('dashboard.allOrders');
			break;
	}
	emit('emitDates', to, from);
};
getStatistics(ALL_ORDERS);

watch((dataRange), () => {
	getStatistics(CUSTOM);
});
</script>

<style scoped lang="scss">
.date-picker-with-menu {
	&__select-wrapper {
		display: flex;
		align-items: center;
		gap: 5px;
	}
	
	&__select-date-wrapper {
		display: flex;
		align-items: center;
		gap: 3px;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		&--big-red {
			font-size: 20px;
			color: $secondary-400;
			font-weight: 700;
		}
		
		&--big-blue {
			font-size: 20px;
			color: $primary-400;
			font-weight: 700;
		}
	}
	
	&__menu-option {
		padding: 5px 15px;
		width: 150px;
		cursor: pointer;
		transition: background-color 0.3s;
		
		&:hover {
			background-color: $grey-200;
		}
		
		&--first {
			padding-top: 10px;
		}
		
		&--border {
			border-top: 1px solid $grey-300;
		}
	}
	
	&__range-picker {
		position: absolute;
		height: 5px;
		top: 0;
		left: 0;
		width: 100%;
		
		&:deep(.dp__outer_menu_wrap) {
			opacity: 1;
		}
		
		&:deep(.dp__input_wrap) {
			opacity: 0;
		}
		
		&:deep(.dp__input) {
			padding: 1px 0;
		}
		
		&:deep(.m-date-range-picker) {
			height: inherit !important;
		}
		
		&:deep(.m-date-range-picker__errors) {
			display: none;
		}
		
		&--closed {
			&:deep(.dp__menu) {
				opacity: 1;
			}
		}
	}
}
</style>
