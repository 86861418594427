
export const SUBMITTED_MODERATION = 'Submitted moderation';
export const ASSIGNED = 'Assigned';
export const IN_PROGRESS = 'In progress';
export const CONTENT_RESIGNED = 'Content resigned';
export const CONTENT_SUBMITTED = 'Content submitted';
export const FINISHED = 'Finished';
export const RESUBMITTED = 'Resubmitted';
export const CONTENT_RESUBMITTED = 'Content resubmitted';
export const READY_TO_PUBLISH = 'Ready to publish';
export const ARTICLE_IN_PROGRESS_OF_PUBLICATION = 'Article in progress of publication';

export const DISAPPROVED = 'Disapproved';
export const APPROVED = 'Approved';
export const VERIFICATION = 'Verification';
export const PUBLICATION_DISAPPROVED = 'Publication Disapproved';
export const PUBLICATION_RESIGNED = 'Publication Resigned';
export const RE_VERIFICATION = 'Re-verification';

export const ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE = 'publication-with-own-article';
export const ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE = 'publication-with-m9-article';
export const ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE = 'publication-with-publisher-article';
export const ORDER_TYPE_ARTICLE = 'article';

export const SKETCH_STATUS = 'sketch';
export const RESIGNED_STATUS = 'resigned';
export const CONTENT_IN_PROGRESS_STATUS = 'content-in-progress';
export const CONTENT_SUBMITTED_STATUS = 'content-submitted';
export const CONTENT_DISAPPROVED_STATUS = 'content-disapproved';
export const PUBLICATION_ASSIGNED_STATUS = 'publication-assigned';
export const PUBLICATION_IN_PROGRESS_STATUS = 'publication-in-progress';
export const PUBLICATION_REPORT_COMMENT_STATUS = 'publication-report-comment';
export const PUBLICATION_SUBMITTED_STATUS = 'publication-submitted';
export const PUBLICATION_DISAPPROVED_STATUS = 'publication-disapproved';
export const FINISHED_STATUS = 'finished';

export const MESSAGE_TYPE_ORDER = 'order-group';
export const MESSAGE_TYPE_ORDER_COMMENT = 'order-report';

export const ORDER_TYPE_ARTICLE_FILTER = 'article';
export const ORDER_TYPE_PUBLICATION_FILTER = 'publication';
