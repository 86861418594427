<template>
	<div class="withdrawal-request-table">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template v-slot:loading>
				<div class="withdrawal-request-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #item.created_at="elem">
				<div v-if="elem.item.raw.created_at != null">{{ dateFormatWithTime(elem.item.raw.created_at) }}</div>
			</template>
			
			<template #item.method="elem">
				<div v-if="elem.item.raw.method != null">{{ elem.item.raw.method === WITHDRAWAL_BANK ? $t('withdraw.bank') : $t('withdraw.paypal') }}</div>
			</template>
			
			<template #item.paypal_id="elem">
				<div v-if="elem.item.raw.paypal_id != null && elem.item.raw.paypal_id != 'null'" class="m9-data-table-align-left">{{ elem.item.raw.paypal_id }}</div>
			</template>
			
			<template #item.account_number="elem">
				<div v-if="elem.item.raw.account_number != null && elem.item.raw.account_number != 'null'">{{ elem.item.raw.account_number }}</div>
			</template>
			
			<template #item.amount="elem">
				<div v-if="elem.item.raw.amount != null" class="m9-data-table-align-price">{{ (+elem.item.raw.amount).toFixed(2) }} {{ elem.item.raw.currency_symbol }}</div>
			</template>
			
			<template #item.vat_rate="elem">
				<div v-if="elem.item.raw.vat_rate != null">{{ elem.item.raw.vat_rate }}%</div>
			</template>
			
			<template #item.brutto_amount="elem">
				<div v-if="elem.item.raw.brutto_amount != null" class="m9-data-table-align-price">{{ (+elem.item.raw.brutto_amount).toFixed(2) }} {{ elem.item.raw.currency_symbol }}</div>
			</template>
			
			<template #item.status="elem">
				<div v-if="elem.item.raw.status != null">
					<div v-if="elem.item.raw.status === WITHDRAWAL_STATUS_SKETCH" class="withdrawal-request-table__status">{{ $t('withdraw.processed') }}</div>
					<div v-if="elem.item.raw.status === WITHDRAWAL_STATUS_PROCESSED" class="withdrawal-request-table__status withdrawal-request-table__status--green">{{ $t('withdraw.finished') }}</div>
				</div>
			</template>
			
			<template #item.actions="elem">
				<div class="withdrawal-request-table__action-wrapper" @click="goToEditWithdrawal(elem.item.raw.id)">
					<MIcon icon="pencil" :color="white" />
				</div>
			</template>
		</v-data-table-server>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { defineProps, ref, watch } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { toast } from 'vue3-toastify';
import { dateFormatWithTime } from '@/hooks/DataHooks';
import WithdrawApi from '@/api/v2/WithdrawApi';
import variables from '@/assets/scss/modules/variables.module.scss';
import { isAdmin } from '@/hooks/UserHooks';
import { WITHDRAWAL_BANK, WITHDRAWAL_STATUS_PROCESSED, WITHDRAWAL_STATUS_SKETCH } from '@/hooks/WithdrawalHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import MIcon from '@/components/atoms/MIcon.vue';

interface IOwnProps {
	isNewWithdrawalRequest?: boolean
}
interface IProps extends IOwnProps {
}

const withdrawApi = new WithdrawApi();

const props = defineProps<IProps>();
const { t } = useI18n();
const { white } = variables;
const { user } = storeToRefs(useUserStore());

const loading = ref<boolean>(false);
const items = ref<Array<any>|any>([]);
const total = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const headers = ref<Array<TableHeaderInterface>|any>([]);
const defaultHeaders = () => {
	const result = [
		{
			title: `${t('withdraw.createdAt')}`,
			align: 'center',
			sortable: true,
			key: 'created_at'
		},
		{
			title: `${t('withdraw.method')}`,
			align: 'center',
			sortable: true,
			key: 'method'
		},
		{
			title: `${t('withdraw.status')}`,
			align: 'center',
			sortable: true,
			key: 'status'
		},
	]
	
	if (null != user.value && isAdmin(user.value)) {
		result.push(
			{
				title: `${t('withdraw.withdrawalNumber')}`,
				align: 'center',
				sortable: false,
				key: 'id'
			},
			{
				title: `${t('withdraw.email')}`,
				align: 'center',
				sortable: true,
				key: 'email'
			},
			{
				title: `${t('withdraw.companyName')}`,
				align: 'center',
				sortable: false,
				key: 'company_name'
			},
		)
	}
	
	result.push(...[
		{
			title: `${t('withdraw.paypalId')}`,
			align: 'center',
			sortable: true,
			key: 'paypal_id'
		},
		{
			title: `${t('withdraw.accountNumber')}`,
			align: 'center',
			sortable: true,
			key: 'account_number'
		},
		{
			title: `${t('withdraw.amount')}`,
			align: 'center',
			sortable: true,
			key: 'amount',
			width: '130px',
		},
		{
			title: `${t('withdraw.vatRate')}`,
			align: 'center',
			sortable: true,
			key: 'vat_rate'
		},
		{
			title: `${t('withdraw.gross')}`,
			align: 'center',
			sortable: true,
			key: 'brutto_amount'
		},
		{
			title: `${t('withdraw.actions')}`,
			align: 'center',
			sortable: false,
			key: 'actions'
		},
	]);
	
	return result;
};
headers.value = defaultHeaders();

const withdrawalRequestList = async() => {
	loading.value = true;
	try {
		const result = await withdrawApi.withdrawRequestList(options.value.page, options.value.itemsPerPage, options.value.sortBy.length > 0 ? options.value.sortBy[0].key : null, options.value.sortBy.length > 0 ? options.value.sortBy[0].order : null);
		if (!result.isSuccess) {
			toast.error(`${t('withdraw.toast.errorWithdrawListTable')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload.data;
	} catch (e) {
		toast.error(`${t('withdraw.toast.errorWithdrawListTable')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	await withdrawalRequestList();
};

watch((props), (val) => {
	if (val.isNewWithdrawalRequest) {
		withdrawalRequestList();
	}
}, {
	deep: true,
});

const goToEditWithdrawal = (withdrawId:number) => {
	router.push(`/withdrawal/${withdrawId}`);
};
</script>

<style scoped lang="scss">
.withdrawal-request-table {
	@import '@/assets/scss/vuetifyDataTable';
	
	&__loading {
		opacity: 0.5;
	}
	
	&__action-wrapper {
		background-color: $grey-300;
		border-radius: 50%;
		padding: 8px 9px 5px 10px;
		width: max-content;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__status {
		background-color: $primary-400;
		color: $white-100;
		padding: 7px 15px;
		border-radius: 12px;
		font-weight: 700;
		width: max-content;
		margin: 0 auto;
		
		&--green {
			background-color: $green-400;
		}
	}
}
</style>
