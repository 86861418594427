<template>
	<div class="cart-header">
		
		<div v-if="(publicationCart || walletCart) && ( null != user && !isModerator(user))" class="cart-header__left-wrapper">
			<h3 class="cart-header__title">{{ title }}</h3>
			<div v-if="publicationCart" class="cart-header__campaign-name">{{ campaignName }}</div>
			<div class="cart-header__description">{{ description }}</div>
		</div>
		
		<div class="cart-header__right-wrapper" :class="{'cart-header__right-wrapper--mod': null != user && isModerator(user)}">
			<div class="cart-header__background-color"></div>
			<div class="cart-header__right-content">
				
				<slot></slot>
				
				<div v-if="walletCart" class="cart-header__wallet-wrapper">
					<div class="cart-header__wallet-title">{{ $t('components.addAmount') }} ({{ userCurrencySymbol }})</div>
					<MTextField
						v-model="netAmount"
						name="netAmount"
						:id="inputId"
						class="cart-header__wallet-input"
						:placeholder="$t('components.enterValue')"
					/>
					<div class="cart-header__wallet-money-wrapper">
						<div class="cart-header__wallet-net-wrapper">
							<div class="cart-header__wallet-prize-text">{{ $t('components.net') }}</div>
							<MHexagonIcon v-if="null != userCurrencySymbol" micro :letter="userCurrencySymbol" :color="black" :bgc="grey300" />
							<div class="cart-header__wallet-net-prize">{{ netAmount.toFixed(2) || 0.00 }}</div>
						</div>
						<div class="cart-header__wallet-gross-wrapper">
							<div class="cart-header__wallet-prize-text">{{ $t('components.gross') }}</div>
							<MHexagonIcon v-if="null != userCurrencySymbol" micro :letter="userCurrencySymbol" :color="black" :bgc="grey300" />
							<div class="cart-header__wallet-gross-prize">{{ grossAmount.toFixed(2) || 0.00 }}</div>
						</div>
					</div>
				</div>
				
				<div v-if="publicationCart" class="cart-header__publication-content">
					<div v-if="null == orderUuid" class="cart-header__publication-title">{{ $t('components.publicationCart') }}</div>
					<CartName v-if="null != orderUuid" :order-uuid="orderUuid" :name="orderName" is-white-loader @cartNameChanged="handleEditCartName" />
					<div class="cart-header__publication-counter cart-header__due-time">{{ $t('components.noOfPublication') }}: <span class="cart-header__expense-pounds">{{ publicationsNumber }}</span></div>
					<div class="cart-header__expense-wrapper">
						<div class="cart-header__expense-text-wrapper cart-header__due-time">
							<div class="cart-header__expense-text">{{ $t('components.expense') }}:</div>
						</div>
						<div class="cart-header__expense-money-wrapper cart-header__due-time">
							<div class="cart-header__expense-pounds">{{ expensePound }} {{ userCurrencySymbol }}</div>
						</div>
					</div>
					<div class="cart-header__due-wrapper">
						<div class="cart-header__due-time">{{ dueDate }} {{ dueTime }}</div>
					</div>
				</div>
				
				<div class="cart-header__btn-wrapper">
					<MButton
						:id="btnId"
						big
						green400
						:label="btnOrderTitle"
						:icon="btnOrderIcon"
						:color="btnOrderIconColor"
						:width="btnOrderIconWidth"
						:height="btnOrderIconHeight"
						:disabled="disabled"
						@click="goToBasket"
					>
						<template v-slot:beforeText>
							<MHexagonIcon v-if="!publicationCart && !orderArticleCart && null != userCurrencySymbol" small :letter="userCurrencySymbol" :color="black" :bgc="white" class="cart-header__btn-icon" />
							<MHexagonIcon v-if="orderArticleCart && null != userCurrencySymbol" small :letter="userCurrencySymbol" :color="green400" :bgc="white" class="cart-header__btn-icon" />
						</template>
					</MButton>
					<MButton
						v-if="isSecondBtn"
						:id="secondBtnId"
						big
						red400
						:label="secondBtnTitle"
						:icon="secondBtnOrderIcon"
						:color="secondBtnOrderIconColor"
						:width="secondBtnOrderIconWidth"
						:height="secondBtnOrderIconHeight"
						:disabled="secondDisabled"
						@click="secondOnClickBtn"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { isModerator } from '@/hooks/UserHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { TIcon } from '@/components/atoms/MIcon.vue';
import MHexagonIcon from '@/components/atoms/MHexagonIcon.vue';
import MButton from '@/components/atoms/MButton.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import CartName from '@/components/organisms/CartName.vue';

interface IOwnProps {
	title?: string
	description?: string
	publicationCart?: boolean
	walletCart?: boolean
	campaignName?: string
	btnTitle?: string
	btnOrderTitle?: string
	btnOrderIcon?: TIcon
	btnOrderIconColor?: string
	btnOrderIconWidth?: string
	btnOrderIconHeight?: string
	btnId?: string
	secondBtnTitle?: string
	secondBtnOrderIcon?: TIcon
	secondBtnOrderIconColor?: string
	secondBtnOrderIconWidth?: string
	secondBtnOrderIconHeight?: string
	secondBtnId?: string
	inputId?: string
	publicationsNumber?: number
	expensePound?: string
	expensePln?: string
	dueDate?: string
	dueTime?: string
	goToBasket?: Function
	secondOnClickBtn?: Function
	disabled?: boolean
	secondDisabled?: boolean
	isSecondBtn?: boolean
	orderArticleCart?: boolean
	orderUuid?: string | null
	orderName?: string | null
}

interface IProps extends IOwnProps {
}

defineProps<IProps>();
const emit = defineEmits(['addAmount', 'cartNameChanged']);

const { white, black, grey300, green400 } = variables;
const { userCurrencySymbol, user } = storeToRefs(useUserStore());

const netAmount = ref(0);
const grossAmount = ref(0);

watch(([netAmount]), (newVal, oldVal) => {
	if (newVal[0] !== oldVal[0] && null != user.value) {
		emit('addAmount', netAmount.value);
		netAmount.value = +netAmount.value;
		
		const gross = +netAmount.value * ((user.value?.vat_rate / 100) + 1);
		grossAmount.value = +gross;
	}
},
{
	deep: true,
});

const handleEditCartName = (newCartName: string) => {
	emit('cartNameChanged', newCartName);
};
</script>

<style scoped lang="scss">
.cart-header {
	display: flex;
	flex-direction: column-reverse;
	
	@include media-breakpoint-up(md) {
		flex-direction: row;
		position: relative;
		z-index: 1;
	}
	
	&__left-wrapper {
		background-color: $white-100;
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		width: 100%;
		
		@include media-breakpoint-up(sm) {
			padding: 30px;
		}
		
		@include media-breakpoint-up(md) {
			width: 50%;
			padding: 20px;
			text-align: left;
			align-items: flex-start;
			justify-content: flex-start;
		}
		
		@include media-breakpoint-up(lg) {
			padding: 30px;
		}
	}
	
	&__title {
		font-size: $size-25;
		line-height: 1.2;
	}
	
	&__campaign-name {
		font-size: 26px;
		font-weight: $weight-700;
		word-break: break-all;
		color: $secondary-400;
	}
	
	&__description {
		font-size: $size-14;
	}
	
	&__right-wrapper {
		position: relative;
		width: 100%;
		height: inherit;
		background-image: url('../../assets/images/background-table-header.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		padding: 10px;
		
		@include media-breakpoint-up(sm) {
			padding: 20px;
		}
		
		@include media-breakpoint-up(md) {
			width: 50%;
		}
		
		&--mod {
			width: 100%;
		}
	}
	
	&__background-color {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: $primary-400;
		opacity: 0.9;
	}
	
	&__right-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		
		@include media-breakpoint-up(sm) {
			flex-direction: row;
			justify-content: flex-start;
			text-align: left;
		}
	}
	
	&__wallet-wrapper {
		width: 100%;
		color: $white-100;
		z-index: 10;
		
		@include media-breakpoint-up(sm) {
			width: 50%;
		}
	}
	
	&__wallet-title {
		font-size: $size-25;
		font-weight: $weight-700;
		line-height: 1.2;
		
		@include media-breakpoint-up(sm) {
			align-items: baseline;
			text-align: left;
		}
	}
	
	&__wallet-input {
		margin-top: 5px;
		
		@include media-breakpoint-up(lg) {
			margin-top: 10px;
		}
		
		&:deep(.v-input__slot) {
			border: 1px solid $grey-300;
			width: 224px;
			margin: 0 auto;
			
			@include media-breakpoint-up(sm) {
				margin: 0;
			}
			
			@include media-breakpoint-up(md) {
				width: 90%;
			}
		}
		
		&:deep(.v-input input) {
			padding: 10px !important;
			background-color: $white-100;
			color: $primary-400;
		}
	}
	
	&__wallet-money-wrapper {
		display: flex;
		flex-direction: column;
		
		@include media-breakpoint-up(sm) {
			align-items: baseline;
			text-align: left;
		}
	}
	
	&__wallet-net-wrapper,
	&__wallet-gross-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: $size-14;
	}
	
	&__wallet-gross-wrapper {
		margin-top: 5px;
	}
	
	&__wallet-prize-text {
		position: relative;
		width: 43px;
	}
	
	&__wallet-net-prize {
		color: $secondary-400;
		margin-left: 10px;
	}
	
	&__wallet-gross-prize {
		color: $green-400;
		margin-left: 10px;
	}
	
	&__publication-content {
		width: 100%;
		z-index: 10;
		color: $white-100;
		display: flex;
		font-size: $size-14;
		flex-direction: column;
		
		@include media-breakpoint-up(sm) {
			width: 50%;
		}
		
		&:deep(.cart-name__name) {
			color: $white-100;
		}
	}
	
	&__publication-title {
		font-size: $size-25;
		font-weight: $weight-700;
		line-height: 1.2;
	}
	
	&__publication-counter {
		@include media-breakpoint-up(md) {
			margin-top: 20px;
		}
		
		span {
			font-weight: $weight-700;
		}
	}
	
	&__expense-wrapper {
		display: flex;
		justify-content: center;
		
		@include media-breakpoint-up(sm) {
			justify-content: flex-start;
		}
	}
	
	&__expense-text-wrapper {
		display: flex;
	}
	
	&__expense-text {
		margin-right: 10px;
	}
	
	&__expense-money-wrapper {
		display: flex;
		flex-direction: column;
		align-items: baseline;
		margin-left: -6px;
	}
	
	&__expense-pounds {
		color: $currency-pound;
		font-weight: $weight-700;
	}
	
	&__expense-pln {
		color: $currency-pln;
		font-weight: $weight-700;
	}
	
	&__due-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		
		@include media-breakpoint-up(sm) {
			align-items: flex-start;
		}
	}
	
	&__due-time {
		font-weight: $weight-700;
	}
	
	&__btn-wrapper {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 5px;
		
		@include media-breakpoint-up(sm) {
			width: 50%;
			margin-top: 0;
			justify-content: flex-end;
			align-items: flex-end;
			margin-bottom: auto;
		}
		
		&:deep(.m-btn) {
			width: 212px;
		}
		
		&:deep(.m-btn__text) {
			color: $white-100;
			letter-spacing: 0;
			font-size: $size-14;
			font-weight: $weight-500;
		}
		
		&:deep(.v-size--default) {
			border-radius: 0 !important;
			padding: 39px 16px !important;
			width: 224px !important;
			
			@include media-breakpoint-up(sm) {
				margin: 0 auto;
			}
			
			@include media-breakpoint-up(md) {
				width: 100% !important;
			}
			
			@include media-breakpoint-up(xl) {
				width: 224px !important;
			}
		}
		
		&:deep(.v-btn__content) {
			color: $white;
		}
	}
	
	&__btn-icon {
		margin-right: 5px;
	}
	
	&:deep(.v-text-field--outlined.v-input--dense .v-label--active) {
		display: none;
	}
}
</style>
