<template>
	<div class="agency-balance">
		<HeadingLabel
			class="agency-balance__heading-label"
			icon="money"
			width="32"
			height="29"
			:text="$t('agency.agencyBalanceHeading') + ` - ${dependentUserFullName}`"
		/>
		
		<MProgressBar v-show="loading" indeterminate height="2" />
		<Form v-if="dependentUser" :validation-schema="schema" @submit="onSubmit" class="agency-balance__form-wrapper">
			<MSelect
				v-model="form.role_id"
				id="agency-balance-role-id"
				name="roleId"
				:items="roleList"
				:label="$t('agency.role')"
				item-title="name"
				item-value="id"
				label-text-required
				disabled
			/>
			<MTextField
				id="agency-balance-current-balance"
				v-model="form.balance"
				name="balance"
				:separate-label="$t('agency.currentBalance')"
				label-text-required
				disabled
			/>
			<MTextField
				id="agency-balance-amount"
				v-model="form.amount"
				name="amount"
				:separate-label="$t('agency.balance') + ` ( ${dependentUser.accounts[0].currency.currency_code} )`"
				:placeholder="$t('agency.balance')"
				type="number"
				step="0.01"
				@change="checkDigits"
				label-text-required
			/>
			<MTextArea
				id="agency-balance-message"
				v-model="form.message"
				name="message"
				:separate-label="$t('agency.reason')"
				:placeholder="$t('agency.reason')"
				label-text-required
			/>
			
			<div class="agency-balance__btn-wrapper">
				<MButton
					id="back-btn"
					blue400
					normal
					width100
					:label="$t('agency.back')"
					@click="back"
				/>
				<MButton
					id="submit-btn"
					green400
					normal
					width200
					:label="$t('agency.updateWallet')"
					type="submit"
					:disabled="checkIfAgencyOrMemberHaveMoney()"
				/>
			</div>
		</Form>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import router from '@/router';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { ADMIN_ID } from '@/hooks/UserHooks';
import type { IAgencyBalanceForm } from '@/models/AgencyModel';
import type { UserInterface } from '@/models/AuthModel';
import AgencyApi from '@/api/v1/AgencyApi';
import UserApi from '@/api/v1/UserApi';
import AuthApi from '@/api/v1/AuthApi';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MSelect from '@/components/atoms/MSelect.vue';
import MTextArea from '@/components/atoms/MTextArea.vue';
import MButton from '@/components/atoms/MButton.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';

const agencyApi = new AgencyApi();
const userApi = new UserApi();
const authApi = new AuthApi();

const { t } = useI18n();
const { user } = storeToRefs(useUserStore());
const { setUser } = useUserStore();

const roleList = ref<Array<any>>([
	{
		id: 2,
		name: `${t('agency.advertiser')}`,
	},
	{
		id: 3,
		name: `${t('agency.publisher')}`,
	},
]);
const loading = ref<boolean>(false);
const form = ref<IAgencyBalanceForm|any>({
	role_id: null,
	balance: null,
	amount: null,
	message: null,
});
const dependentUserUuid = ref<string|Array<string>>('');
dependentUserUuid.value = router.currentRoute.value.params.userUuid;
const dependentUserFullName = ref<string>('');
const dependentUser = ref<UserInterface|null>(null);

const schema:any = yup.object({
	roleId: yup.string().required(`${t('agency.validation.roleId')}`).label('roleId'),
	balance: yup.string().required(`${t('agency.validation.balance')}`).label('balance'),
	message: yup.string().required(`${t('agency.validation.message')}`).label('message'),
	amount: yup.string().required(`${t('agency.validation.amount')}`).label('amount').test('amount', `${t('agency.validation.amountRegex')}`,
		function (val) {
			if ((null != form.value.balance && +val < (+form.value.balance * -1)) || +val > checkAgencyBalance()) {
				return false;
			} else {
				return true;
			}
		}),
});

if (null != user.value && ADMIN_ID !== user.value?.is_agency) {
	router.push('/dashboard');
}

const userInfo = async() => {
	loading.value = true;
	if ('' === dependentUserUuid.value || null == dependentUserUuid.value) {
		toast.error(`${t('agency.toast.somethingWentWrong')}`);
		loading.value = false;
		return;
	}
	try {
		const result = await userApi.userInfo(dependentUserUuid.value);
		if (!result.isSuccess) {
			toast.error(`${t('agency.toast.somethingWentWrong')}`);
			loading.value = false;
			return;
		}
		dependentUserFullName.value = result.payload.first_name + ' ' + result.payload.last_name;
		dependentUser.value = result.payload;
		form.value.role_id = user.value?.active_role_id;
		form.value.balance = 2 === user.value?.active_role_id ? +(dependentUser.value.accounts[0].balance / 100).toFixed(2) : +(dependentUser.value.accounts[1].balance / 100).toFixed(2);
	} catch (e) {
		toast.error(`${t('agency.toast.somethingWentWrong')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
userInfo();
const onSubmit = async() => {
	loading.value = true;
	if (null == user.value) {
		toast.error(`${t('agency.toast.errorUpdate')}`);
		loading.value = false;
		return;
	}
	try {
		const updateForm:IAgencyBalanceForm = {
			amount: null != form.value && null != form.value.amount ? +form.value.amount : null,
			message: null != form.value ? form.value.message : null,
		};
		
		const sourceAccount = user.value.accounts.find(elem => elem.role_id === form.value.role_id);
		const sourceUuid = null != sourceAccount ? sourceAccount.uuid : null;
		
		const targetAccount = null != dependentUser.value ? dependentUser.value.accounts.find(elem => elem.role_id === form.value.role_id) : null;
		const targetUuid = null != targetAccount ? targetAccount.uuid : null;
		const result = await agencyApi.updateDependentAccountWallet(updateForm, sourceUuid, targetUuid);
		if (!result.isSuccess) {
			toast.error(`${t('agency.toast.errorUpdate')}`);
			loading.value = false;
			return;
		}
		const userResult = await authApi.me();
		if (userResult.isSuccess) {
			await setUser(userResult.payload);
		} else {
			router.go(0);
		}
	} catch (e) {
		toast.error(`${t('agency.toast.errorUpdate')}`);
		loading.value = false;
		return;
	}
	toast.success(`${t('agency.toast.successUpdate')}`);
	await router.push('/agency');
	loading.value = false;
};
const back = () => {
	router.push('/agency');
};

const checkIfAgencyOrMemberHaveMoney = () => {
	if (null == user.value) {
		return true;
	}
	// Przelew z agencji do membera
	if (
		null != form.value.amount &&
		null != form.value.balance &&
		0 < form.value.amount &&
		form.value.amount <= user.value.accounts[2 === form.value.role_id ? 0 : 1].balance / 100
	) {
		return false;
	}
	// Przelew z membera do agencji
	if (
		null != form.value.amount &&
		null != form.value.balance &&
		0 > form.value.amount &&
		form.value.amount >= form.value.balance * -1
	) {
		return false;
	}
	return true;
};

const checkAgencyBalance = () => {
	if (null == user.value) {
		return 0;
	}
	return 2 === user.value?.active_role_id ? user.value.accounts[0].balance / 100 : user.value.accounts[1].balance / 100;
};
const checkDigits = () => {
	if (null == form.value.amount) {
		return;
	}
	form.value.amount = Math.round(form.value.amount * 100) / 100;
};
</script>

<style scoped lang="scss">
.agency-balance {
	&__heading-label {
		margin-bottom: 5px;
	}
	
	&__form-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 15px;
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
		background-color: $white-100;
	}
	
	&__btn-wrapper {
		margin-top: 20px;
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
	}
}
</style>
