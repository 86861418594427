<template>
	<div class="transfer">
		<HeadingLabel
			class="transfer__heading-label"
			icon="money"
			width="32"
			height="29"
			:text="$t('reflinks.transferToMainAccount')"
		/>
		
		<Form :validation-schema="schema" @submit="onSubmit" class="transfer__form-wrapper">
			<div class="transfer__info">{{ $t('reflinks.transferInfo') }}</div>
			<MTooltip without-btn :max-width="400" color="primary">
				<template v-slot:title>
					<div class="transfer__balance" @click="setInputValueAffiliateBalance">
						{{ $t('reflinks.balance') }} {{ userAffiliateBalance.toFixed(2) }} {{ userCurrencySymbol }}
					</div>
				</template>
				<template v-slot:content>
					{{ $t(`reflinks.tooltipBalanceInfo`) }}
				</template>
			</MTooltip>
			<MTextField
				id="transfer-amount"
				v-model="transferForm.amount"
				name="amount"
				type="number"
				min="0.01"
				step="0.01"
				:separate-label="$t('reflinks.amount')"
				label-text-required
				:placeholder="$t('reflinks.amount')"
			/>
			<MAutocomplete
				id="transfer-user-account-target-id"
				v-model="transferForm.account_id"
				name="accountId"
				:get-items="usersAccounts"
				item-title="name"
				item-value="id"
				:label="$t('reflinks.targetTransfer')"
				label-text-required
				:placeholder="$t('reflinks.targetTransferPlaceholder')"
				variant="outlined"
				clearable
			/>
			
			<div class="transfer__btn-wrapper">
				<MButton
					id="transfer-submit"
					type="submit"
					green400
					normal
					width100
					:label="$t('reflinks.transfer')"
				/>
				<MButton
					id="transfer-back"
					type="button"
					blue400
					normal
					width100
					:label="$t('components.back')"
					@click="goBack"
				/>
			</div>
		</Form>
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useBooleanFlagStore } from '@/stores/booleanFlag';
import router from '@/router';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { toast } from 'vue3-toastify';
import { AFFILIATE_ACCOUNT_ROLE_ID, ADVERTISER_ID, refreshUser } from '@/hooks/UserHooks';
import WithdrawApi from '@/api/v2/WithdrawApi';
import type { TTransferRequestForm } from '@/models/ReflinksModel';
import type { UserAccountsInterface } from '@/models/AuthModel';
import type { ITransferBetweenAccounts } from '@/models/WithdrawalModel';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MButton from '@/components/atoms/MButton.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';

const withdrawApi = new WithdrawApi();

const { user, userCurrencySymbol } = storeToRefs(useUserStore());
const { setUserWalletValue, setUserAffiliateWalletValue, setUser } = useUserStore();
const { setIsRefreshWalletAction } = useBooleanFlagStore();
const { t } = useI18n();

const schema:any = yup.object({
	accountId: yup.string().required(`${t('reflinks.validation.accountId')}`).label('accountId'),
	amount: yup.number().required(`${t('reflinks.validation.amount')}`).min(0.01, `${t('reflinks.validation.minAmount')}`).label('amount').test('amount', `${t('reflinks.validation.toHighAmount')}`,
	function (val) {
		if (val <= userAffiliateBalance.value) {
			return true;
		} else {
			return false;
		}
	}),
});

const loading = ref<boolean>(false);
const transferForm = ref<TTransferRequestForm>({
	amount: 0,
	account_id: null,
});
const usersAccounts = ref<Array<UserAccountsInterface>>([]);
const userAffiliateBalance = ref<number>(0);

if (null != user.value) {
	const account = user.value.accounts.map((elem) => {
		return {
			...elem,
		};
	});
	const affiliateAccount = user.value.accounts.find(elem => elem.role_id === AFFILIATE_ACCOUNT_ROLE_ID);
	if (null != affiliateAccount) {
		userAffiliateBalance.value = affiliateAccount.balance / 100;
		const index = account.indexOf(affiliateAccount);
		account.splice(index, 1);
	}
	account.forEach((elem:any) => {
		elem.name = elem.role_id === ADVERTISER_ID ? `${t('auth.advertiser')}` : `${t('auth.publisher')}`;
	});
	usersAccounts.value = account;
}

const onSubmit = async() => {
	loading.value = true;
	
	const affiliateAccount = user.value?.accounts.find(elem => elem.role_id === AFFILIATE_ACCOUNT_ROLE_ID);
	
	const form:ITransferBetweenAccounts = {
		user_id: user.value?.id,
		account_from: affiliateAccount?.id,
		account_to: transferForm.value.account_id,
		amount: transferForm.value.amount * 100,
		currency_id: user.value?.currency_id
	};
	try {
		const result = await withdrawApi.transferBetweenAccounts(form);
		if (!result.isSuccess) {
			toast.error(`${t('reflinks.toast.errorSendMoney')}`);
			loading.value = false;
			return;
		}
		await setIsRefreshWalletAction(true);
		const updatedUser = await refreshUser();
		if (null != updatedUser?.current_wallet) {
			await setUserWalletValue((+updatedUser?.current_wallet.balance / 100));
		}
		if (null != updatedUser?.affiliate_wallet) {
			await setUserAffiliateWalletValue((+updatedUser?.affiliate_wallet.balance / 100));
		}
		if (null != updatedUser?.user) {
			await setUser(updatedUser.user)
		}
		await setIsRefreshWalletAction(false);
		await router.push('/affiliate-program');
		toast.success(`${t('reflinks.toast.successSendMoney')}`);
	} catch (e) {
		toast.error(`${t('reflinks.toast.errorSendMoney')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const setInputValueAffiliateBalance = () => {
	transferForm.value.amount = userAffiliateBalance.value;
};

const goBack = () => {
	router.go(-1);
};
</script>

<style scoped lang="scss">
.transfer {
	&__heading-label {
		margin-bottom: 40px;
	}
	
	&__info {
		color: $primary-400;
		font-weight: 700;
		font-size: 14px;
		text-align: center;
		margin-bottom: 30px;
	}
	
	&__balance {
		width: 100%;
		color: $green-400;
		font-weight: 700;
		font-size: 14px;
		text-align: center;
		margin-bottom: 30px;
		border: 2px solid $blue-400;
		padding: 20px 15px;
		border-radius: 12px;
		cursor: pointer;
		transition: opacity 0.3s, border-color 0.3s;
		
		&:hover {
			opacity: 0.8;
			border-color: $green-400;
		}
	}
	
	&__form-wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		max-width: 600px;
		background-color: $white-100;
		border-radius: 12px;
		padding: 15px 25px;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
		
		&:deep(.m-autocomplete) {
			width: 100%;
		}
	}
	
	&__btn-wrapper {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-top: 40px;
	}
	
	&:deep(.m-tooltip) {
		width: 100%;
	}
	
	&:deep(.v-text-field .v-input__details) {
		padding-inline-start: 0;
	}
}
</style>
