<template>
	<div class="coupons">
		<HeadingLabel
			class="coupons__heading-label"
			icon="articles"
			width="32"
			height="29"
			:text="$t('coupons.coupons')"
			btn
			blue400
			:btn-text="$t('coupons.createNewCoupon')"
			:is-button-click="createNewCoupon"
		/>
		
		<CouponsTable />
	</div>
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import router from '@/router';
import { isAdmin } from '@/hooks/UserHooks';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import CouponsTable from '@/views/Coupons/components/CouponsTable.vue';

const { user } = storeToRefs(useUserStore());

if (null != user.value && !isAdmin(user.value)) {
	router.push('/dashboard');
}
const createNewCoupon = () => {
	router.push('/coupons/create');
};
</script>

<style scoped lang="scss">
.coupons {
	&__heading-label {
		margin-bottom: 5px;
	}
}
</style>
