<template>
	<MLoading v-show="detailsLoader" />
	<Form :validation-schema="schema" @submit="submit(false)" class="campaigns-footer-form">
		<MTextField
			id="footer-name"
			v-model="footerForm.name"
			name="name"
			:separate-label="$t('campaigns.companyName')"
			:placeholder="$t('campaigns.companyNamePlaceholder')"
			label-text-required
		/>
		<MTextField
			id="footer-url"
			v-model="footerForm.url"
			name="url"
			:separate-label="$t('campaigns.urlEmail')"
			:placeholder="$t('campaigns.urlEmailPlaceholder')"
			label-text-required
		/>
		<div v-if="null == footerImages || 0 === footerImages.length" class="campaigns-footer-form__drop-wrapper">
			<div @dragover="dragover" @dragleave="dragleave" @drop="drop">
				<div class="campaigns-footer-form__drop-wrapper--input-wrapper">
					<label for="assetsFieldHandle">
						<div class="campaigns-footer-form__drop-text">{{ $t('campaigns.uploadDropMessage') }}</div>
						<div class="campaigns-footer-form__remove-wrapper">
							<div v-if="footerForm.file !== undefined && footerForm.file != null">
								{{ footerForm.file.name }}
							</div>
							<div v-if="footerForm.file !== undefined && footerForm.file != null" @click="removeFile">
								<MIcon icon="close" width="12" height="12" />
							</div>
						</div>
					</label>
					<input
						type="file"
						name="orderPhoto"
						id="footer-photo"
						@change="onChangeImage"
						ref="file"
						accept=".jpg,.jpeg,.png,.gif"
					/>
					<div class="campaigns-footer-form__file-btn">{{ $t('campaigns.selectFile') }}</div>
				</div>
			</div>
		</div>
		<div v-if="null != footerImages && 0 !== footerImages.length">
			<div class="campaigns-footer-form__photo-wrapper">
				<img :src="footerImages[1].url" alt="marketin9">
				<div class="campaigns-footer-form__photo-btn-wrapper">
					<div class="campaigns-footer-form__edit-btn-wrapper">
						<div id="edit-photo" class="campaigns-footer-form__edit-photo">
							<div class="campaigns-footer-form__btn">{{ $t('campaigns.edit') }}</div>
							<div class="campaigns-footer-form__upload-hover-wrapper">
								<v-file-input v-model="footerForm.file" accept="image/*" @update:modelValue="updatePhoto" />
							</div>
						</div>
					</div>
					<div id="campaign-footer-remove-photo" class="campaigns-footer-form__btn campaigns-footer-form__btn--red" @click="deleteImage">{{ $t('campaigns.delete') }}</div>
				</div>
			</div>
		</div>
		<MVueEditor
			id="footer-description"
			v-model="footerForm.description"
			name="description"
			:separate-label="$t('campaigns.content')"
			label-text-required
		/>
		<div class="campaigns-footer-form__btn-wrapper">
			<MButton
				id="submit-footer-campaign"
				type="submit"
				normal
				width150
				green400
				:label="$t('campaigns.saveFooter')"
				:loading="loading"
				:disabled="loading"
			/>
		</div>
	</Form>
</template>

<script setup lang="ts">
import { defineProps, ref, defineEmits } from 'vue';
import { useDictionaryStore } from '@/stores/dictionary';
import { useI18n } from 'vue-i18n';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import router from '@/router';
import { toast } from 'vue3-toastify';
import MonolithCampaignsApi from '@/api/monolith/MonolithCampaigsApi';
import type { ICampaignFooterForm } from '@/models/CampaignModel';
import type { IImageUploadResponse } from '@/models/ImageModel';
import MTextField from '@/components/atoms/MTextField.vue';
import MVueEditor from '@/components/atoms/MVueEditor.vue';
import MButton from '@/components/atoms/MButton.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MLoading from '@/components/atoms/MLoading.vue';

interface IOwnProps {
	isEditMode: boolean
	footerId: number|null
}
interface IProps extends IOwnProps {
}

const monolithCampaignsApi = new MonolithCampaignsApi();

const props = defineProps<IProps>();
const emit = defineEmits(['submitFooterForm']);

const { campaigns } = useDictionaryStore();
const { t } = useI18n();

const loading = ref<boolean>(false);
const detailsLoader = ref<boolean>(false);
const footerForm = ref<ICampaignFooterForm>({
	name: null,
	url: null,
	description: null,
	file: null,
});
const footerImages = ref<Array<IImageUploadResponse>|null>(null);

const campaignId = ref<number|null>(null);

const gtm = {
	pushEvent(event:string, name:string, value:any) {
		if (null != window.dataLayer) {
			window.dataLayer.push({
				event: event,
				[name]: value
			});
		}
	}
};

const schema:any = yup.object({
	name: yup.string().required(`${t('campaigns.validation.campaignName')}`).label('name'),
	url: yup.string().required(`${t('campaigns.validation.urlEmail')}`).label('url'),
	description: yup.string().required(`${t('campaigns.validation.footerDescription')}`).label('description'),
});

const setCampaignId = () => {
	const campaign = campaigns.find(elem => elem.uuid === router.currentRoute.value.params.campaignUuid);
	if (null != campaign) {
		campaignId.value = campaign.id;
	}
};
setCampaignId();
const footerDetails = async() => {
	detailsLoader.value = true;
	try {
		const result = await monolithCampaignsApi.campaignFooter(campaignId.value, props.footerId);
		if (!result.isSuccess) {
			toast.error(`${t('campaigns.toast.errorFooterDetails')}`);
			detailsLoader.value = false;
			return;
		}
		footerForm.value.name = result.payload.company_name;
		footerForm.value.url = result.payload.contact_data;
		footerForm.value.description = result.payload.content;
		footerImages.value = result.payload.images;
	} catch (e) {
		toast.error(`${t('campaigns.toast.errorFooterDetails')}`);
		detailsLoader.value = false;
		return;
	}
	detailsLoader.value = false;
};
if (props.isEditMode) {
	footerDetails();
}
const submit = async(isPhotoChangeAction:boolean) => {
	loading.value = true;
	if (!props.isEditMode) {
		try {
			const result = await monolithCampaignsApi.createCampaignFooter(campaignId.value, footerForm.value);
			if (!result.isSuccess) {
				toast.error(`${t('campaigns.toast.errorFooterCreate')}`);
				loading.value = false;
				return;
			}
			toast.success(`${t('campaigns.toast.successFooterCreate')}`);
			await emit('submitFooterForm');
			// Dodanie zdarzenia do Google Tag Menagera
			gtm.pushEvent('Success footer campaign', 'success_footer_campaign', 1);
		} catch (e) {
			toast.error(`${t('campaigns.toast.errorFooterCreate')}`);
			loading.value = false;
			return;
		}
	} else {
		try {
			if (footerForm.value.file instanceof Array) {
				footerForm.value.file = footerForm.value.file[0];
			}
			const result = await monolithCampaignsApi.editCampaignFooter(campaignId.value, props.footerId, footerForm.value);
			if (!result.isSuccess) {
				toast.error(`${t('campaigns.toast.errorFooterEdit')}`);
				loading.value = false;
				return;
			}
			toast.success(`${t('campaigns.toast.successFooterEdit')}`);
			if (!isPhotoChangeAction) {
				await emit('submitFooterForm');
			}
			// Dodanie zdarzenia do Google Tag Menagera
			gtm.pushEvent('Success edit footer campaign', 'success_edit_footer_campaign', 1);
		} catch (e) {
			toast.error(`${t('campaigns.toast.errorFooterEdit')}`);
			loading.value = false;
			return;
		}
	}
	loading.value = false;
};
const onChangeImage = (event:any) => {
	footerForm.value.file = null;
	Array.from(event.target.files).forEach(file => {
		footerForm.value.file = file;
	});
};
const dragover = (event:any) => {
	event.preventDefault();
	if (!event.currentTarget.classList.contains('campaigns-footer-form__bg-green-300')) {
		event.currentTarget.classList.remove('campaigns-footer-form__bg-gray-100');
		event.currentTarget.classList.add('campaigns-footer-form__bg-green-300');
	}
};
const dragleave = (event:any) => {
	event.currentTarget.classList.add('campaigns-footer-form__bg-gray-100');
	event.currentTarget.classList.remove('campaigns-footer-form__bg-green-300');
};
const drop = (event:any) => {
	footerForm.value.file = null;
	event.preventDefault();
	event.currentTarget.classList.add('campaigns-footer-form__bg-gray-100');
	event.currentTarget.classList.remove('campaigns-footer-form__bg-green-300');
	
	Array.from(event.dataTransfer.files).forEach(file => {
		footerForm.value.file = file;
	});
};
const removeFile = () => {
	footerForm.value.file = null;
};

const deleteImage = async() => {
	loading.value = true;
	try {
		const result = await monolithCampaignsApi.deleteFooterImage(null != footerImages.value ? footerImages.value[1].id : null);
		if (!result.isSuccess) {
			toast.error(`${t('campaigns.toast.errorDeleteImage')}`);
			loading.value = false;
			return;
		}
		footerForm.value.file = null;
		footerImages.value = null;
	} catch (e) {
		toast.error(`${t('campaigns.toast.errorDeleteImage')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const updatePhoto = async() => {
	loading.value = true;
	await submit(true);
	await footerDetails();
	loading.value = false;
};
</script>

<style scoped lang="scss">
.campaigns-footer-form {
	&__btn-wrapper {
		margin-top: 10px;
	}
	
	&__drop-wrapper {
		border: 2px dashed $grey-300;
		border-radius: 12px;
		margin-bottom: 10px;
		
		&--error {
			border-color: $secondary-400;
		}
		
		&--input-wrapper {
			padding: 15px;
			height: 158px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			
			input[type='file'] {
				position: absolute;
				z-index: 1;
				top: 70%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 158px;
				height: 40px;
				opacity: 0;
				cursor: pointer;
			}
			
			label {
				margin-bottom: 20px;
			}
		}
	}
	
	&__file-btn {
		background-color: $primary-400;
		cursor: pointer;
		border-radius: 8px;
		color: $white-100;
		font-size: 14px;
		font-weight: 600;
		padding-top: 9px;
		text-align: center;
		position: absolute;
		top: 70%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 158px;
		height: 40px;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__remove-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		
		div:nth-child(2) {
			margin-left: 10px;
			margin-top: 3px;
			cursor: pointer;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	&__drop-text {
		font-size: 12px;
		color: $grey-300;
	}
	
	&__photo-wrapper {
		width: max-content;
		max-width: 100%;
		margin-top: 10px;
		position: relative;
		transition: 0.3s;
		
		@include media-breakpoint-up(lg) {
			max-width: 100%;
		}
		
		&:hover {
			.campaigns-footer-form__photo-btn-wrapper {
				display: flex;
			}
		}
		
		img {
			width: 100%;
			transition: opacity 0.5s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	&__photo-btn-wrapper {
		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		
		&:deep(.v-text-field>.v-input__control>.v-input__slot) {
			cursor: pointer;
		}
	}
	
	&__edit-photo {
		position: relative;
	}
	
	&__btn {
		background-color: $primary-400;
		font-size: 16px;
		padding: 10px;
		min-width: 80px;
		text-align: center;
		border-radius: 8px;
		color: $white-100;
		font-weight: 700;
		cursor: pointer;
		transition: opacity .3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		&--red {
			margin-left: 10px;
			background-color: $secondary-400;
		}
	}
	
	&__edit-btn-wrapper {
		position: relative;
		cursor: pointer;
	}
	
	&__upload-hover-wrapper {
		position: absolute;
		width: 100%;
		top: 0;
		height: 44px;
		cursor: pointer;
		opacity: 0;
		
		input {
			cursor: pointer;
		}
		
		&:deep(.v-input__prepend) {
			width: 0;
		}
		
		&:deep(.v-input__control) {
			width: 100%;
		}
		
		&:deep(.v-input__details) {
			display: none;
		}
	}
	
	&__image-preview {
		margin-top: 24px;
	}
	
	&__error-message {
		color: $secondary-550;
	}
	
	&__bg-green-300 {
		background-color: $green-300;
		padding: 20px;
		border-radius: 12px;
		
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
	}
	
	&__bg-gray-100 {
		background-color: $grey-200;
		padding: 20px;
		border-radius: 12px;
		
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
	}
}
</style>
