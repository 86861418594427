<template>
	<div class="archive-history-table">
		<HeadingLabel
			class="archive-history-table__heading-label"
			icon="credit-card-solid"
			width="32"
			height="29"
			:text="$t('archive.archiveHistoryPayment')"
		/>
		
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template v-slot:loading>
				<div class="archive-history-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #item.created_at="elem">
				<div v-if="elem.item.raw.created_at != null">{{ dateFormatWithTime(elem.item.raw.created_at) }}</div>
			</template>
			
			<template #item.tax="elem">
				<div v-if="elem.item.raw.tax != null">{{ elem.item.raw.tax }}%</div>
			</template>
			
			<template #item.method="elem">
				<div v-if="elem.item.raw.method != null">{{ elem.item.raw.method }}</div>
			</template>
			
			<template #item.currency="elem">
				<div v-if="elem.item.raw.currency != null">{{ elem.item.raw.currency }}</div>
			</template>
			
			<template #item.amount="elem">
				<div v-if="elem.item.raw.amount != null">{{ (elem.item.raw.amount).toFixed(2) }}</div>
			</template>
			
			<template #item.wallet_payment="elem">
				<div v-if="elem.item.raw.wallet_payment != null">{{ (elem.item.raw.wallet_payment).toFixed(2) }}</div>
			</template>
			
			<template #item.refunded_amount="elem">
				<div v-if="elem.item.raw.refunded_amount != null">{{ (elem.item.raw.refunded_amount).toFixed(2) }}</div>
			</template>
			
			<template #item.card_last_four="elem">
				<div v-if="elem.item.raw.card_last_four != null">{{ elem.item.raw.card_last_four }}</div>
			</template>
			
			<template #item.status="elem">
				<div v-if="elem.item.raw.status === ARCHIVE_VERIFICATION_STATUS" class="archive-history-table__status archive-history-table__status--grey">{{ $t('archive.verification') }}</div>
				<div v-if="elem.item.raw.status === ARCHIVE_COMPLETED_STATUS" class="archive-history-table__status archive-history-table__status--green">{{ $t('archive.completed') }}</div>
				<div v-if="elem.item.raw.status !== ARCHIVE_COMPLETED_STATUS && elem.item.raw.status !== ARCHIVE_VERIFICATION_STATUS" class="archive-history-table__invoice">{{ elem.item.raw.status }}</div>
			</template>
			
			<template #item.invoice_id="elem">
				<div v-if="elem.item.raw.invoice_id != null" class="archive-history-table__invoice" @click="goToGenerateInvoice(elem.item.raw.invoice_id)" >{{ $t('archive.generateInvoice') }}</div>
				<div v-if="elem.item.raw.invoice_id == null">-</div>
			</template>
		</v-data-table-server>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { dateFormatWithTime } from '@/hooks/DataHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import MonolithArchiveHistoryPaymentApi from '@/api/monolith/MonolithArchiveHistoryPaymentApi';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import { ARCHIVE_COMPLETED_STATUS, ARCHIVE_VERIFICATION_STATUS } from '@/hooks/ArchiveHooks';

const monolithArchiveHistoryPaymentApi = new MonolithArchiveHistoryPaymentApi();

const { t } = useI18n();

const loading = ref<boolean>(false);
const items = ref<Array<any>|any>([]);
const total = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('archive.date')}`,
		align: 'center',
		sortable: true,
		key: 'created_at'
	},
	{
		title: `${t('archive.tax')}`,
		align: 'center',
		sortable: true,
		key: 'tax'
	},
	{
		title: `${t('archive.cardBrand')}`,
		align: 'center',
		sortable: true,
		key: 'method'
	},
	{
		title: `${t('archive.currency')}`,
		align: 'center',
		sortable: false,
		key: 'currency'
	},
	{
		title: `${t('archive.amount')}`,
		align: 'center',
		sortable: true,
		key: 'amount'
	},
	{
		title: `${t('archive.walletPayment')}`,
		align: 'center',
		sortable: true,
		key: 'wallet_payment'
	},
	{
		title: `${t('archive.refundedAmount')}`,
		align: 'center',
		sortable: true,
		key: 'refunded_amount'
	},
	{
		title: `${t('archive.cardDigits')}`,
		align: 'center',
		sortable: true,
		key: 'card_last_four'
	},
	{
		title: `${t('archive.status')}`,
		align: 'center',
		sortable: true,
		key: 'status'
	},
	{
		title: `${t('archive.invoice')}`,
		align: 'center',
		sortable: true,
		key: 'invoice_id'
	},
]);

const archiveHistoryPaymentList = async() => {
	loading.value = true;
	try {
		const result = await monolithArchiveHistoryPaymentApi.getArchiveHistoryPayment(options.value.page, options.value.itemsPerPage, options.value.sortBy.length > 0 ? options.value.sortBy[0].key : null, options.value.sortBy.length > 0 ? options.value.sortBy[0].order : null);
		if (!result.isSuccess) {
			toast.error(`${t('archive.toast.errorHistoryPaymentListTable')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload.data;
	} catch (e) {
		toast.error(`${t('archive.toast.errorHistoryPaymentListTable')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	await archiveHistoryPaymentList();
};

const goToGenerateInvoice = (invoiceId:number) => {
	window.location.href = `/invoice/${invoiceId}`;
};
</script>

<style scoped lang="scss">
.archive-history-table {
	@import '@/assets/scss/vuetifyDataTable';
	
	&__heading-label {
		margin-bottom: 5px;
	}
	
	&__loading {
		opacity: 0.5;
	}
	
	&__invoice {
		background-color: $primary-400;
		border-radius: 8px;
		color: $white-100;
		padding: 6px 14px;
		text-align: center;
		width: max-content;
		margin: 0 auto;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__status {
		background-color: $primary-400;
		border-radius: 8px;
		color: $white-100;
		font-weight: 700;
		padding: 6px 15px;
		text-align: center;
		
		&--grey {
			background-color: $grey-400;
		}
		
		&--green {
			background-color: $green-400;
		}
	}
}
</style>
