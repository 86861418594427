<template>
	<div class="reflink-network">
		<HeadingLabel
			icon="group-people"
			width="32"
			height="29"
			:text="$t('reflinks.reflinkRefferalList')"
		/>
		
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:items-per-page="options.itemsPerPage"
			:page="options.page"
			:sort-by="options.sortBy"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template #loading>
				<div class="reflink-network__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #item.created_at="elem">
				<div>{{ dateFormat(elem.item.raw.created_at) }}</div>
			</template>
			
			<template #item.first_name="elem">
				<div v-if="elem.item.raw.first_name != null" class="m9-data-table-align-left">{{ elem.item.raw.first_name }}</div>
			</template>
			
			<template #item.last_name="elem">
				<div v-if="elem.item.raw.last_name != null" class="m9-data-table-align-left">{{ elem.item.raw.last_name }}</div>
			</template>
			
			<template #item.email="elem">
				<div v-if="elem.item.raw.email != null" class="m9-data-table-align-left">{{ elem.item.raw.email }}</div>
			</template>
			
			<template #item.language="elem">
				<div v-if="null != elem.item.raw.language_id">
					<MFlag :country-code="findFlag(elem.item.raw.language_id)" />
				</div>
			</template>
		</v-data-table-server>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { defineProps, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDictionaryStore } from '@/stores/dictionary';
import { toast } from 'vue3-toastify';
import { dateFormat } from '@/hooks/DataHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import ReflinkApi from '@/api/v1/ReflinkApi';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { UserInterface } from '@/models/AuthModel';
import type { IReflinkNetworkDataItems } from '@/models/ReflinksModel';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MFlag from '@/components/atoms/MFlag.vue';

interface IOwnProps {
	currentUser: UserInterface
}

interface IProps extends IOwnProps {
}

const reflinkApi = new ReflinkApi();

const props = defineProps<IProps>();

const { languages } = useDictionaryStore();
const { t } = useI18n();

const loading = ref<boolean>(false);
const items = ref<Array<IReflinkNetworkDataItems>>([]);
const total = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('reflinks.firstName')}`,
		align: 'center',
		sortable: false,
		key: 'first_name',
		width: '200px'
	},
	{
		title: `${t('reflinks.lastName')}`,
		align: 'center',
		sortable: false,
		key: 'last_name',
		width: '200px'
	},
	{
		title: `${t('reflinks.email')}`,
		align: 'center',
		sortable: false,
		key: 'email',
		width: '280px'
	},
	{
		title: `${t('reflinks.language')}`,
		align: 'center',
		sortable: false,
		key: 'language',
		width: '200px'
	},
	{
		title: `${t('reflinks.createdAt')}`,
		align: 'center',
		sortable: false,
		key: 'created_at',
		width: '200px'
	},
]);

const networkList = async() => {
	loading.value = true;
	try {
		const result = await reflinkApi.networkReflinkList(props.currentUser.uuid, options.value.page, options.value.itemsPerPage);
		if (!result.isSuccess) {
			toast.error(`${t('reflinks.toast.errorNetworkList')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload.data.data;
		total.value = result.payload.data.total;
		currentPage.value = result.payload.data.current_page;
		lastPage.value = result.payload.data.last_page;
	} catch (e) {
		toast.error(`${t('reflinks.toast.errorNetworkList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const optionsUpdate = (val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	networkList();
};

const findFlag = (languageId:number) => {
	const flag = languages.find(elem => elem.id === languageId);
	return null != flag ? flag.flag : '-';
};
</script>

<style scoped lang="scss">
.reflink-network {
	@import '@/assets/scss/vuetifyDataTable';
	background-color: $white-100;
	
	&__loading {
		opacity: 0.5;
	}
}
</style>
