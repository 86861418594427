<template>
	<div class="publisher-statistics-counter-window">
		<MProgressBar v-show="props.loading" indeterminate height="2" class="publisher-statistics-counter-window__loader" />
		<div class="publisher-statistics-counter-window__title">
			<div class="publisher-statistics-counter-window__title-item">{{ title() }}</div>
			<div class="publisher-statistics-counter-window__title-icon-wrapper" :class="`publisher-statistics-counter-window__title-icon-wrapper--${props.statisticName}`">
				<MIcon :icon="currentStatisticIcon()" :color="primary400" />
			</div>
		</div>
		<div v-if="props.items" class="publisher-statistics-counter-window__counter-wrapper">
			<div class="publisher-statistics-counter-window__counter">{{ null != items && null != items.counter ? items.counter : 0 }}</div>
			<div v-if="props.statisticName === SALARY_COUNTER || props.statisticName === AVERAGE_SALARY_COUNTER" class="publisher-statistics-counter-window__counter publisher-statistics-counter-window__counter--currency">{{ null != items ? items.currency_symbol : '' }}</div>
			<div class="publisher-statistics-counter-window__slash">/</div>
			<div class="publisher-statistics-counter-window__icon-wrapper">
				<div :class="{'publisher-statistics-counter-window__icon-wrapper--green': null != items && items.type === POSITIVE_TRENDING}">{{ null != items && null != items.percent ? items.percent : 0 }}%</div>
				<MIcon v-show="null != items && items.type === POSITIVE_TRENDING" icon="green-arrow-up" :color="green400" />
				<MIcon v-show="null != items && (items.type === NEGATIVE_TRENDING || items.type === CONSTANTS_TRENDING)" icon="red-arrow-down" :color="secondary400" />
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
import variables from '@/assets/scss/modules/variables.module.scss';
import {
	CLIENTS_COUNTER,
	ORDER_COUNTER,
	SALARY_COUNTER,
	AVERAGE_SALARY_COUNTER,
	POSITIVE_TRENDING,
	NEGATIVE_TRENDING,
	CONSTANTS_TRENDING,
	DOMAINS_COUNTER,
} from '@/hooks/StatisticsHook';
import type { IDashboardCounterStatistics } from '@/models/DashboardPublisherModel';
import MIcon, { type TIcon } from '@/components/atoms/MIcon.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';

interface IOwnProps {
	statisticName: string
	items: IDashboardCounterStatistics|null
	loading: boolean
}

interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();
const { t } = useI18n();
const { green400, secondary400, primary400 } = variables;

const title = () => {
	switch (props.statisticName) {
		case CLIENTS_COUNTER:
			return `${t('dashboard.clientsCounter')}`;
		case ORDER_COUNTER:
			return `${t('dashboard.orderCounter')}`;
		case SALARY_COUNTER:
			return `${t('dashboard.salaryCounter')}`;
		case AVERAGE_SALARY_COUNTER:
			return `${t('dashboard.averageSalaryCounter')}`;
		case DOMAINS_COUNTER:
			return `${t('dashboard.domainsCounter')}`;
		default:
			return `${t('dashboard.unknown')}`;
	}
};

const currentStatisticIcon = ():TIcon => {
	switch (props.statisticName) {
		case CLIENTS_COUNTER:
			return 'user-v2';
		case ORDER_COUNTER:
			return 'articles';
		case SALARY_COUNTER:
			return 'profit';
		case AVERAGE_SALARY_COUNTER:
			return 'money-bag';
		case DOMAINS_COUNTER:
			return 'language';
		default:
			return 'user-v2';
	}
};
</script>

<style scoped lang="scss">
.publisher-statistics-counter-window {
	position: relative;
	background-color: $primary-400;
	padding: 16px 24px;
	border-radius: 6px;
	box-shadow: 0 6px 15px rgba($grey-300, .2);
	width: 266px;
	
	&__loader {
		position: absolute;
		top: 100%;
		left: 0;
	}
	
	&__title {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		font-size: 16px;
		font-weight: 400;
		color: $white-100;
	}
	
	&__title-item {
		width: max-content;
	}
	
	&__title-icon-wrapper {
		position: absolute;
		top: 18px;
		right: 18px;
		background-color: $white-100;
		padding: 5px 9px;
		border-radius: 6px;
		
		&--order-counter {
			padding: 6px 9px 4px;
		}
		
		&--salary-counter {
			padding: 6px 9px 4px;
		}
		
		&--average-salary-counter {
			padding: 6px 9px 4px;
		}
		
		&--domains-counter {
			padding: 6px 9px 4px;
		}
	}
	
	&__counter-wrapper {
		display: flex;
	}
	
	&__counter {
		font-size: 16px;
		font-weight: 400;
		color: $white-100;
		
		&--currency {
			margin-left: 5px;
		}
	}
	
	&__icon-wrapper {
		font-size: 16px;
		display: flex;
		align-items: center;
		color: $secondary-400;
		
		&--green {
			color: $green-400;
		}
	}
	
	&__slash {
		font-size: 16px;
		color: $white-100;
		margin: 0 5px 0;
	}
}
</style>
