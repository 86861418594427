<template>
	<div class="optimize-table">
		<v-data-table
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:items-per-page-options="itemsPerPageOptions"
		>
			<template #loading>
				<div class="optimize-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #top>
				<div class="optimize-table__title">{{ $t('contadu.listUsedOptimalization') }}</div>
			</template>
			
			<template #item.created_at="elem">
				<div v-if="elem.item.raw.created_at != null">{{ dateFormat(elem.item.raw.created_at) }}</div>
			</template>
			
			<template #item.actions="elem">
				<div v-if="elem.item.raw.token != null" class="optimize-table__optimalize-btn">
					<MButton @click="goToOptimalize(elem.item.raw.token)" grey400 width="14" height="14">
						<img src="~@/assets/images/contadu-icon.png" alt="contadu">
					</MButton>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script setup lang="ts">
import { VDataTable } from 'vuetify/labs/VDataTable';
import { defineProps, ref } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { dateFormat } from '@/hooks/DataHooks';
import ContaduApi from '@/api/v1/ContaduApi';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import type { TableHeaderInterface } from '@/models/VuetifyModel';
import type { IContaduTableItems } from '@/models/ContaduModel';
import MButton from '@/components/atoms/MButton.vue';

interface IOwnProps {
	optimizeArticleUuid: string
}
interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();

const { t } = useI18n();

const contaduApi = new ContaduApi();

const loading = ref<boolean>(false);
const items = ref<Array<IContaduTableItems>|any>([]);
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('contadu.createdAt')}`,
		align: 'left',
		sortable: false,
		key: 'created_at',
		width: '200px',
	},
	{
		title: `${t('contadu.phrase')}`,
		align: 'left',
		sortable: false,
		key: 'phrase',
		width: '300px',
	},
	{
		title: `${t('contadu.actions')}`,
		align: 'left',
		sortable: false,
		key: 'actions',
		width: '100px',
	},
]);

const optimizationList = async() => {
	loading.value = true;
	try {
		const result = await contaduApi.getArticleOptimalizationList(props.optimizeArticleUuid);
		if (!result.isSuccess) {
			toast.error(`${t('contadu.toast.error')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload;
	} catch (e) {
		toast.error(`${t('contadu.toast.error')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
optimizationList();

const goToOptimalize = (token:string) => {
	router.push(`/article/${props.optimizeArticleUuid}/optimalize/${token}`);
};
</script>

<style scoped lang="scss">
.optimize-table {
	&__title {
		padding-top: 15px;
		font-size: $size-20;
		font-weight: 700;
		color: $primary-400;
		padding-left: 15px;
	}
	
	&__optimalize-btn {
		img {
			width: 20px;
			height: 20px;
		}
		
		&:deep(.m-btn) {
			background-color: $blue-400 !important;
			padding: 0 8px 0 10px !important;
			border-radius: 50%;
			width: max-content;
			min-width: max-content !important;
		}
	}
	
	:deep(.v-data-table-footer .v-field__outline) {
		--v-field-border-width: 0;
	}
	
	:deep(.v-data-table-footer .v-field__append-inner) {
		margin-top: -5px;
	}
	
	:deep(.v-data-table-footer .v-field__overlay) {
		border-bottom: 1px solid $grey-400;
		border-radius: 0;
	}
	
	:deep(.v-data-table-footer .v-select__selection-text) {
		font-size: 14px;
		margin-top: 3px;
	}
}
</style>
