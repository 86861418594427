import { defineStore } from 'pinia';
import type { ICartDetailsResponse } from '@/models/CartModel';

type TCart = {
	cartCampaignId: number | null
	cartAssignedUuid: string | null
	cartUuid: string | string[] | null
	isCartHasOrders: boolean
	isMultiple: boolean
	cartNewOrder: ICartDetailsResponse | null
	cartSlug: string | null
};
export const useCartStore = defineStore('userCartStore', {
	state: ():TCart => ({
		cartCampaignId: null,
		cartAssignedUuid: null,
		cartUuid: null,
		isCartHasOrders: false,
		isMultiple: false,
		cartNewOrder: null,
		cartSlug: null,
	}),
	actions: {
		setCampaignId (campaignId: number | null) {
			this.cartCampaignId = campaignId;
		},
		setCartAssignedUuid (cartAssignedUuid: string | null) {
			this.cartAssignedUuid = cartAssignedUuid;
		},
		setCartUuid (cartUuid: string | string[] | null) {
			this.cartUuid = cartUuid;
		},
		setIsCartHasOrders (isCartHasOrders: boolean) {
			this.isCartHasOrders = isCartHasOrders;
		},
		setIsMultiple (isMultiple: boolean) {
			this.isMultiple = isMultiple;
		},
		setCartNewOrder (cartNewOrder: ICartDetailsResponse | null) {
			this.cartNewOrder = cartNewOrder;
		},
		setCartSlug (cartSlug: string | null) {
			this.cartSlug = cartSlug;
		},
	},
});
