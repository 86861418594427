<template>
    <div class="header__profile">
        <v-menu offset-y open-on-click>
            <template v-slot:activator="{ props }">
	            <v-btn v-bind="props" class="header__profile-text">
		            <MHexagonIcon :letter="firstLetter()" small :color="grey250" />
		            <MIcon icon="arrow-down" :color="black" width="14" height="14" />
	            </v-btn>
            </template>
            <v-list>
	            <v-list-item @click="goToProfile()">
		            <div class="header__profile__svg-wrapper">
		                <MIcon icon="user-v2" width="20" height="20" :color="black" />
		            </div>
		            <div id="menu-profile">{{ $t('layout.header.myProfile') }}</div>
	            </v-list-item>
	            <v-list-item v-if="null != user && (isAdvertiser(user) || isPublisher(user))" @click="goToHistory()">
		            <div class="header__profile__svg-wrapper header__profile__svg-wrapper--history">
		                <MIcon icon="history-payment" width="20" height="20" :color="black" />
		            </div>
		            <div id="menu-finances">{{ $t('layout.header.finances') }}</div>
	            </v-list-item>
	            <v-list-item v-if="null != user && (isAdvertiser(user) || isPublisher(user))" @click="redirectToArchivedDeposit()">
		            <div class="header__profile__svg-wrapper header__profile__svg-wrapper--archive">
		                <MIcon icon="archive" width="20" height="20" :color="black" />
		            </div>
		            <div id="menu-archive" class="header__profile--redirect-link">{{ $t('layout.header.archivedFinances') }}</div>
	            </v-list-item>
	            <v-list-item v-if="null != user && ((isAdvertiser(user) || isPublisher(user))) && user.is_agency" @click="redirectToAgencyPage()">
		            <div class="header__profile__svg-wrapper header__profile__svg-wrapper--people">
		                <MIcon icon="people-group-v2" width="20" height="15" :color="black" />
		            </div>
		            <div id="menu-agency" class="header__profile--redirect-link">{{ $t('layout.header.agency') }}</div>
	            </v-list-item>
	            <v-list-item v-if="null != user && ((isAdvertiser(user) && checkIsUserJoinAffiliateProgram(user)) || (isPublisher(user)))" @click="redirectToWithdrawals()">
		            <div class="header__profile__svg-wrapper header__profile__svg-wrapper--money">
		                <MIcon icon="money" width="20" height="15" :color="black" />
		            </div>
		            <div id="menu-money" class="header__profile--redirect-link">{{ $t('layout.header.withdraw') }}</div>
	            </v-list-item>
	            <v-list-item v-if="null != user && ((isAdvertiser(user) && checkIsUserJoinAffiliateProgram(user)) || (isPublisher(user) && checkIsUserJoinAffiliateProgram(user)))" @click="redirectToTransfer()">
		            <div class="header__profile__svg-wrapper header__profile__svg-wrapper--money">
		                <MIcon icon="money-transfer" width="20" height="15" :color="black" />
		            </div>
		            <div id="menu-money-transfer" class="header__profile--redirect-link">{{ $t('layout.header.transfer') }}</div>
	            </v-list-item>
	            <v-list-item @click="logout()">
		            <div class="header__profile__svg-wrapper header__profile__svg-wrapper--logout">
		                <MIcon icon="logout" width="16" height="16" :color="black" />
		            </div>
		            <div id="menu-logout">{{ $t('layout.header.logout') }}</div>
	            </v-list-item>
            </v-list>
        </v-menu>
        <div class="header__profile-details">
            <div class="header__profile-name"><span>{{ $t('layout.header.hello') }},</span></div>
            <div class="header__profile-name">{{ user?.first_name }}</div>
        </div>
    </div>
</template>

<script setup lang="ts">
import router from '@/router';
import {
	isAdvertiser,
	isPublisher,
	isAdmin,
	isModerator,
	isContentWriter,
	checkIsUserJoinAffiliateProgram,
} from '@/hooks/UserHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { useAuthStore } from '@/stores/auth';
import MHexagonIcon from '@/components/atoms/MHexagonIcon.vue';
import MIcon from '@/components/atoms/MIcon.vue';

const { black, grey250 } = variables;

const { t } = useI18n();

const { user } = storeToRefs(useUserStore());

const firstLetter = () => {
    if (null == user.value) {
        return 'N';
    }

    return user.value.first_name.charAt(0).toUpperCase();
}

const checkIfProfileIsValid = () => {
    if ((0 === user.value?.locked ||
        null === user.value?.company_type ||
        null === user.value?.addresses[0].country_id ||
        '' === user.value?.addresses[0].line1 ||
        null === user.value?.addresses[0].phone ||
        null === user.value?.addresses[0].zip ||
        null === user.value?.addresses[0].state ||
        '' === user.value?.addresses[0].city) &&
    (!isAdmin(user.value) && !isModerator(user.value) && !isContentWriter(user.value))) {
        router.push('/profile');
        toast.success(`${t('layout.toast.completeProfile')}`);
        return false;
    } else {
        return true;
    }
};

const redirectToArchivedDeposit = () => {
    if (checkIfProfileIsValid()) {
	    router.push('/archived/order/deposit');
    }
};
const redirectToAgencyPage = () => {
    if (checkIfProfileIsValid()) {
		router.push('/agency');
    }
};
const goToProfile = () => {
    if (checkIfProfileIsValid()) {
	    router.push('/profile');
    }
};
const goToHistory = () => {
    if (checkIfProfileIsValid()) {
	    router.push('/order/deposit');
    }
};
const redirectToWithdrawals = () => {
    if (checkIfProfileIsValid()) {
	    router.push('/withdrawal/create');
    }
};
const redirectToTransfer = () => {
    if (checkIfProfileIsValid()) {
	    router.push('/transfer');
    }
};
const logout = async() => {
    const { removeAuthorization } = useAuthStore();
    await removeAuthorization();
    await router.push('/');
};
</script>

<style scoped lang="scss">
.header__profile {
    display: flex;
	gap: 15px;
	align-items: center;
	width: max-content;
	margin-right: auto;
	color: $primary-400;
    
    &-details {
        font-size: 14px;
	    line-height: 1.2;
    }
    
    &-name {
        color: $secondary-400;
        display: flex;
	    font-weight: 700;
        
        span {
            color: $primary-400;
	        font-weight: 400;
        }
    }
    
    &-text {
        cursor: pointer;
        position: relative;
        padding: 0 10px;
	    border-radius: 8px;
	    height: auto;
	    background-color: $grey-250;
	    box-shadow: none;
    }
    
    &--redirect-link {
        text-decoration: none;
    }
	
	&__svg-wrapper {
		background-color: $white-100;
		border-radius: 5px;
		padding: 2px 3px 0 6px;
		display: flex;
		
		&--history {
			padding: 3px 3px 0 6px;
		}
		
		&--archive {
			padding: 3px 3px 0 6px;
		}
		
		&--people {
			padding: 4px 5px 2px 4px;
		}
		
		&--money {
			padding: 3px 5px 2px 4px;
		}
		
		&--logout {
			padding: 2px 6px 3px 6px;
		}
	}
}

:deep(.v-list) {
	background-color: $primary-400 !important;
	color: $white-100;
	fill: $white-100;
	padding: 0 0 0 !important;
	margin-top: 5px;
	
	svg {
		fill: $primary-400;
	}
}

:deep(.v-list-item) {
	min-height: 10px !important;
	font-size: $size-14 !important;
	padding: 0 !important;
	transition: background-color 0.3s;
	
	&:hover {
		background-color: $secondary-400;
		
		svg {
			fill: $white-100;
		}
		
		.header__profile__svg-wrapper {
			background-color: $primary-400 !important;
		}
	}
}

:deep(.v-list-item a) {
	display: flex;
	align-items: center;
}

:deep(.v-list-item button) {
	display: flex;
	align-items: center;
}

:deep(.theme--light.v-btn:focus::before) {
	background-color: transparent !important;
}

:deep(.theme--light.v-btn:hover::before) {
	background-color: transparent !important;
}

:deep(.theme--light.v-btn::before) {
	background-color: transparent !important;
}

:deep(.theme--light.v-btn:visited::before) {
	background-color: transparent !important;
}

:deep(.v-ripple__container) {
	background-color: transparent !important;
}

:deep(.theme--light.v-btn:active::before) {
	background-color: transparent !important;
}

:deep(.v-btn__content) {
	display: flex;
	gap: 10px;
}

:deep(.v-list-item__content) {
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	padding: 7px 12px;
	width: 100%;
}
</style>
