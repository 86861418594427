<template>
	<div v-if="null != user" class="orders-basket-table" :class="{'orders-basket-table-dashboard transparent-scroll': isDashboardView}">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			v-model:page="options.page"
			:items-per-page="options.itemsPerPage"
			:sort-by="options.sortBy"
			:show-expand="!isBasketListView && !isDashboardView"
			:expanded="expanded"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template v-slot:loading>
				<div class="orders-basket-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #top v-if="!isDashboardView">
				<div class="orders-basket-table__top-wrapper">
					<div v-if="user.is_agency" class="orders-basket-table__dependent_wrapper">
						<MAutocomplete
							id="input-dependent-account"
							v-model="dependentAccountUuid"
							:placeholder="$t('articles.selectAccount')"
							label=""
							:get-items="dependentAccountList"
							clearable
							variant="outlined"
							item-title="email"
							item-value="uuid"
						/>
					</div>
					<div class="orders-basket-table__options-wrapper">
						<TableSettingsMenuWithModal :is-cleared-table-settings-action="isClearedTableSettingsAction" :table-name="isBasketListView ? CARTS_LIST : ORDERS_LIST" @headersMove="headersMoveAction" :on-clear-action="clearTableSettingsAction" />
					</div>
				</div>
			</template>
			
			<template #item.name="elem">
				<div v-if="null != elem.item.raw.name" class="m9-data-table-align-left">{{ elem.item.raw.name }}</div>
			</template>
			
			<template #item.campaign_id="elem">
				<div v-if="null != elem.item.raw.campaign_id" class="m9-data-table-align-left">{{ elem.item.raw.campaign.default === 0 ? elem.item.raw.campaign.name : $t('order.list.notSelected') }}</div>
				<div v-if="null == elem.item.raw.campaign_id" class="m9-data-table-align-left">{{ $t('order.list.rechargingAccount') }}</div>
			</template>
			
			<template #item.account="elem">
				<div v-if="elem.item.raw.user != null">
					<div>{{ elem.item.raw.user.first_name }} {{ elem.item.raw.user.last_name }}</div>
					<div>{{ elem.item.raw.user.email }}</div>
				</div>
			</template>
			
			<template #item.payment_status="elem">
				<div v-if="elem.item.raw.is_paid === true" class="orders-basket-table__payment-status">{{ $t('order.list.paid') }}</div>
				<div v-if="elem.item.raw.is_paid === false" class="orders-basket-table__payment-status">{{ $t('order.list.waiting') }}</div>
			</template>
			
			<template #item.total_amount="elem">
				<div class="orders-basket-table__currency-symbol">{{ (elem.item.raw.gross/100).toFixed(2) }} {{ userCurrencySymbol }}</div>
			</template>
			
			<template #item.created_at="elem">
				<div> {{ dateFormat(elem.item.raw.created_at) }}</div>
			</template>
			
			<template #item.updated_at="elem">
				<div> {{ dateFormat(elem.item.raw.updated_at) }}</div>
			</template>
			
			<template #item.net="elem">
				<div class="orders-basket-table__currency-symbol">{{ (elem.item.raw.net/100).toFixed(2) }} {{ userCurrencySymbol }}</div>
			</template>
			
			<template #item.tax="elem">
				<div class="orders-basket-table__currency-symbol">{{ (elem.item.raw.tax/100).toFixed(2) }} {{ userCurrencySymbol }}</div>
			</template>
			
			<template #item.details="elem">
				<div class="orders-basket-table__action-wrapper">
					<div v-if="!elem.item.raw.is_paid">
						<MButton @click="openPaymentDialog(elem.item.raw.uuid, elem.item.raw)" green400 class="orders-basket-table__link" :label="$t('order.list.pay')" />
					</div>
					<MButton v-if="!isDashboardView" @click="redirectToBasket(elem.item.raw.uuid)" blue400 class="orders-basket-table__link" :label="$t('order.list.goToBasket')" />
					<MButton v-if="!isDashboardView" @click="openDeleteDialog(elem.item.raw.id)" grey300 class="orders-basket-table__link" :label="$t('components.delete')" />
				</div>
			</template>
			
			<template #footer.prepend>
				<div class="orders-basket-table__csv-btn orders-basket-table__csv-btn--global">
					<MTooltip without-btn>
						<template v-slot:title>
							<MIcon id="csv-btn" icon="csv-file" width="20" height="20" @click="downloadToCsv(false)" />
						</template>
						<template v-slot:content>
							<div>{{ $t('components.downloadCsv') }}</div>
						</template>
					</MTooltip>
				</div>
			</template>
			
			<template #bottom v-if="isDashboardView">
				<div></div>
			</template>
			
			<template #item.data-table-expand="{ item }">
				<div v-if="!expanded.includes(item.value)" class="orders-basket-table__offer-wrapper">
					<MButton @click="expand(item)" blue400 :label="$t('order.list.seeDetails')">
						<MIcon icon="arrow-down" width="13" height="13" class="orders-basket-table__arrow" />
					</MButton>
				</div>
				<div v-if="expanded.includes(item.value)" class="orders-basket-table__offer-wrapper">
					<MButton @click="expand(item)" blue400 :label="$t('order.list.seeDetails')">
						<MIcon icon="arrow-down" width="13" height="13" class="orders-basket-table__arrow--rotate" />
					</MButton>
				</div>
			</template>

			<template v-slot:expanded-row="{ columns, item }">
				<td :colspan="columns.length">
					<v-data-table
						theme="dark"
						:headers="expandedHeaders"
						:items="item.raw.items"
						:no-data-text="$t('components.noDataText')"
						:items-per-page-text="`${$t('components.rowsPerPage')}`"
						:page-text="`${$t('components.of')}`"
						:items-per-page="-1"
					>
						<template #item.orderable_type="elem">
							<div v-if="elem.item.raw.orderable_type === PUBLICATION_REQUEST">{{ $t('order.list.publication') }}</div>
							<div v-if="elem.item.raw.orderable_type === ARTICLE_REQUEST">{{ $t('order.list.article') }}</div>
							<div v-if="elem.item.raw.orderable_type === DEPOSIT_REQUEST">{{ $t('order.list.deposit') }}</div>
						</template>
						
						<template #item.net="elem">
							<div class="orders-basket-table__currency-symbol">{{ (elem.item.raw.gross/100).toFixed(2) }} {{ userCurrencySymbol }}</div>
						</template>
						
						<template #item.status="elem">
							<div v-if="elem.item.raw.status === ORDER_STATUS_DRAFT" class="orders-basket-table__status orders-basket-table__status--draft">{{ $t('order.list.draft') }}</div>
							<div v-if="elem.item.raw.status === ORDER_STATUS_REFUND" class="orders-basket-table__status orders-basket-table__status--draft">{{ $t('order.list.refund') }}</div>
							<div v-if="elem.item.raw.status === ORDER_STATUS_PENDING" class="orders-basket-table__status orders-basket-table__status--pending">{{ $t('order.list.pending') }}</div>
							<div v-if="elem.item.raw.status === ORDER_STATUS_DONE" class="orders-basket-table__status orders-basket-table__status--done">{{ $t('order.list.done') }}</div>
						</template>
						
						<template #item.details="elem">
							<div v-if="elem.item.raw.orderable_type === PUBLICATION_REQUEST && null != elem.item.raw.orderable" class="m9-data-table-align-left">{{ removeHttps(elem.item.raw.orderable.url) }}</div>
							<div v-if="elem.item.raw.orderable_type === ARTICLE_REQUEST && null != elem.item.raw.orderable" class="m9-data-table-align-left">
								<span class="orders-basket-table__details">{{ $t('order.list.subject') }}:</span> {{ elem.item.raw.orderable.subject }}
								<div v-if="elem.item.raw.orderable.article_type_id === ARTICLE_ID_STANDARD">{{ $t('order.list.standard') }}</div>
								<div v-if="elem.item.raw.orderable.article_type_id === ARTICLE_ID_TECHNICAL">{{ $t('order.list.technical') }}</div>
								<div v-if="elem.item.raw.orderable.article_type_id === ARTICLE_ID_PREMIUM">{{ $t('order.list.premium') }}</div>
							</div>
						</template>
						
						<template #tfoot>
							<td :colspan="columns.length">
								<div class="orders-basket-table__footer-main-wrapper">
									<div class="orders-basket-table__footer-prepend-wrapper">
										<div class="orders-basket-table__csv-btn">
											<MTooltip without-btn>
												<template v-slot:title>
													<MIcon id="csv-btn" icon="csv-file" width="20" height="20" @click="downloadToCsv(true, item.raw.items, item.raw)" />
												</template>
												<template v-slot:content>
													<div>{{ $t('components.downloadCsv') }}</div>
												</template>
											</MTooltip>
										</div>
									</div>
								</div>
							</td>
						</template>
						
						<template #bottom>
						
						</template>
					</v-data-table>
				</td>
			</template>
		</v-data-table-server>
		
		<MDialog
			:width="1000"
			:is-close-dialog-action="isClosePaymentDialog"
			:is-open-dialog-action="isOpenPaymentDialog"
			:content-title="$t('order.list.payForOrder')"
			close-header-btn
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<HeadingLabel
					:letter="userCurrencySymbol"
					:color="white"
					:bgc="primary400"
					:text="$t('basket.summary.choosePaymentMethod')"
				/>
				<div class="orders-basket-table__popup-wrapper">
					<MButton
						id="payment-pay-pal"
						v-if="null != user && null == user.agency_id && null != userWalletValue"
						:label="$t('basket.summary.paymentThroughPayPal')"
						:green400="selectedPaymentMethod === PAYMENT_METHOD_PAYPAL"
						:blue400="selectedPaymentMethod !== PAYMENT_METHOD_PAYPAL"
						:color="white"
						:disabled="null != basketItem && (basketItem.gross / 100) <= userWalletValue && null != basketItem.campaign_id"
						@click="choosePaymentMethod(PAYMENT_METHOD_PAYPAL)"
					>
						<template v-slot:default>
							<div class="orders-basket-table__payment-icon-wrapper">
								<MIcon icon="paypal" width="18" height="18" :color="white" />
							</div>
						</template>
					</MButton>
					<CartPaymentMethodWindow v-show="selectedPaymentMethod === PAYMENT_METHOD_PAYPAL" :order="basketItem" :payment-title="$t('basket.summary.payPal')" is-popup-payment />
					
					<MButton
						id="payment-autopay"
						v-if="null != user && null == user.agency_id && null != userWalletValue"
						:label="$t('basket.summary.paymentThroughAutopay')"
						:green400="selectedPaymentMethod === PAYMENT_METHOD_AUTOPAY"
						:blue400="selectedPaymentMethod !== PAYMENT_METHOD_AUTOPAY"
						:color="white"
						:disabled="null != basketItem && (basketItem.gross / 100) <= userWalletValue && null != basketItem.campaign_id"
						@click="choosePaymentMethod(PAYMENT_METHOD_AUTOPAY)"
					>
						<template v-slot:default>
							<div class="orders-basket-table__payment-icon-wrapper">
								<MIcon icon="autopay-logo" width="93" height="21" :color="white" />
							</div>
						</template>
					</MButton>
					<div class="orders-basket-table__payment-icon-wrapper orders-basket-table__payment-icon-wrapper--autopay">
						<img :src="autopayBanners" alt="autopay-icon" />
					</div>
					<div v-show="selectedPaymentMethod === PAYMENT_METHOD_AUTOPAY" class="orders-basket-table__payment-autopay-checkbox-wrapper">
						<MCheckbox
							id="autopay-rules"
							v-model="isAutopayRules"
							name="isAutopayRules"
							:label="$t('basket.summary.isAutopayRules')"
							:color="primary400"
						/>
					</div>
					<CartPaymentMethodWindow v-show="selectedPaymentMethod === PAYMENT_METHOD_AUTOPAY" :order="basketItem" :payment-title="$t('basket.summary.autopay')" is-popup-payment />
					
					<MButton
						v-if="null != basketItem && null != basketItem.campaign_id && null != userWalletValue"
						id="payment-wallet"
						:label="$t('basket.summary.paymentThroughWallet')"
						:green400="selectedPaymentMethod === PAYMENT_METHOD_WALLET"
						:blue400="selectedPaymentMethod !== PAYMENT_METHOD_WALLET"
						:color="white"
						:disabled="null != basketItem && (basketItem.gross / 100 > userWalletValue)"
						@click="choosePaymentMethod(PAYMENT_METHOD_WALLET)"
					>
						<template v-slot:default>
							<div class="orders-basket-table__payment-icon-wrapper">
								<MIcon icon="money" width="16" height="16" :color="white" />
							</div>
						</template>
					</MButton>
					<CartPaymentMethodWindow v-show="selectedPaymentMethod === PAYMENT_METHOD_WALLET" :order="basketItem" :payment-title="$t('basket.summary.wallet')" is-popup-payment />
				</div>
				
				<MButton
					id="basket-summary-pay"
					green400
					big
					:label="selectedPaymentMethod === PAYMENT_METHOD_WALLET ? $t('basket.summary.paymentThroughWallet') : selectedPaymentMethod === PAYMENT_METHOD_PAYPAL ? $t('basket.summary.paymentThroughPayPal') : selectedPaymentMethod === PAYMENT_METHOD_AUTOPAY ? $t('basket.summary.paymentThroughAutopay') : $t('basket.summary.paymentChoose')"
					@click="pay"
					:loading="loading"
					:disabled="selectedPaymentMethod == null || loading || (selectedPaymentMethod === PAYMENT_METHOD_AUTOPAY && !isAutopayRules)"
				>
					<template v-slot:beforeText>
						<MHexagonIcon
							:color="selectedPaymentMethod === null ? grey400 : green400"
							small
							:bgc="white"
							:letter="userCurrencySymbol"
						/>
					</template>
				</MButton>
			</template>
		</MDialog>
		
		<MDialog
			:width="650"
			:is-close-dialog-action="isClosedClearCartDialog"
			:is-open-dialog-action="isOpenClearCartDialog"
			:content-title="$t('publications.clearBasket')"
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<div class="orders-basket-table__clear-dialog-wrapper">
					<div class="orders-basket-table__clear-dialog-text">{{ $t('publications.clearCartDescription') }}</div>
					<div class="orders-basket-table__clear-dialog-btn-wrapper">
						<MButton
							id="clear-basket-btn"
							red400
							:label="$t('publications.clearBasket')"
							@click="clearBasket"
						/>
						<MButton
							id="clear-basket-back-btn"
							blue400
							:label="$t('components.back')"
							@click="closeDialogWhenNoTargetPressed"
						/>
					</div>
				</div>
			</template>
		</MDialog>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer, VDataTable } from 'vuetify/labs/VDataTable';
import { ref, watch } from 'vue';
import router from '@/router';
import { useTableSettingsStore } from '@/stores/tableSettings';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@/stores/session';
import { useUserStore } from '@/stores/user';
import { toast } from 'vue3-toastify';
import { dateFormat } from '@/hooks/DataHooks';
import { ROUTE_BASKET_LIST, ROUTE_DASHBOARD } from '@/hooks/RoutingHooks';
import { CARTS_LIST, ORDERS_LIST, clearTableSettings, editTableSettings } from '@/hooks/TableSettingsHooks';
import { exportTableToCsv } from '@/hooks/ExportCsvHook';
import {
	ARTICLE_ID_PREMIUM,
	ARTICLE_ID_STANDARD,
	ARTICLE_ID_TECHNICAL,
	ARTICLE_REQUEST,
	DEPOSIT_REQUEST,
	ORDER_STATUS_DONE,
	ORDER_STATUS_DRAFT,
	ORDER_STATUS_PENDING,
	ORDER_STATUS_REFUND,
	ORDER_WALLET,
	PAYMENT_METHOD_AUTOPAY,
	PAYMENT_METHOD_PAYPAL,
	PAYMENT_METHOD_WALLET,
	PUBLICATION_REQUEST
} from '@/hooks/OrderHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import AgencyApi from '@/api/v1/AgencyApi';
import OrderApi from '@/api/v1/OrderApi';
import InvoiceApi from '@/api/v1/InvoiceApi';
import PaymentApi from '@/api/v1/PaymentApi';
import MonolithPublicationApi from '@/api/monolith/MonolithPublicationApi';
import variables from '@/assets/scss/modules/variables.module.scss';
import autopayBanners from '@/assets/images/autopay-baners.png';
import type { TableSettingsInterface, TableSettingsRequestInterface } from '@/models/TableSettingsModel';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { AgencyItemListInterface } from '@/models/AgencyModel';
import type { IOrderBasketTableItem, IOrderItem } from '@/models/OrderModel';
import type { IPaymentRequest } from '@/models/PaymentModel';
import TableSettingsMenuWithModal from '@/components/organisms/TableSettingsMenuWithModal.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MButton from '@/components/atoms/MButton.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MDialog from '@/components/atoms/MDialog.vue';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import CartPaymentMethodWindow from '@/views/Cart/components/CartPaymentMethodWindow.vue';
import MHexagonIcon from '@/components/atoms/MHexagonIcon.vue';
import MCheckbox from '@/components/atoms/MCheckbox.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';

const { locale } = storeToRefs(useSessionStore());
const { user, userCurrencySymbol, userWalletValue } = storeToRefs(useUserStore());
const { t } = useI18n();
const { setSort, setHeaders, setFilters, setPagination } = useTableSettingsStore();
const { white, primary400, grey400, green400 } = variables;

const agencyApi = new AgencyApi();
const orderApi = new OrderApi();
const invoiceApi = new InvoiceApi();
const paymentApi = new PaymentApi();
const monolithPublicationApi = new MonolithPublicationApi();

const loading = ref<boolean>(false);
const isAutopayRules = ref<boolean>(false);
const items = ref<Array<IOrderBasketTableItem>|any>([]);
const total = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
let headers = ref<Array<TableHeaderInterface>|any>([]);
let expandedHeaders = ref<Array<TableHeaderInterface>|any>([]);
let firstLoadPage:boolean = true;
let isClearAction:boolean = false;
let isFilterAction:boolean = false;
const isClosePaymentDialog = ref<boolean>(true);
const isClosedClearCartDialog = ref<boolean>(true);
const isOpenPaymentDialog = ref<boolean>(false);
const isOpenClearCartDialog = ref<boolean>(false);
const orderId = ref<number | null>(null);
const basketUuid = ref<string|null>(null);
const basketItem = ref<IOrderBasketTableItem|null|any>(null);
const selectedPaymentMethod = ref<string|null>(null);
const isClearedTableSettingsAction = ref<boolean>(false);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const localeLanguage = ref<string>('');

if ('us' === locale.value) {
	localeLanguage.value = 'en';
} else if (locale.value != null) {
	localeLanguage.value = locale.value;
} else {
	localeLanguage.value = 'pl';
}

const isBasketListView = ref<boolean>(false);
const isDashboardView = ref<boolean>(false);
if (router.currentRoute.value.name === ROUTE_BASKET_LIST) {
	isBasketListView.value = true;
}
if (router.currentRoute.value.name === ROUTE_DASHBOARD) {
	isDashboardView.value = true;
}

const defaultHeaders = () => {
	const result = [];
	
	if (!isDashboardView.value) {
		result.push(
			{
				title: `${t('order.list.order')}`,
				align: 'center',
				sortable: false,
				key: 'id',
				width: '80px'
			},
		);
	}
	
	if (isBasketListView.value && !isDashboardView.value) {
		result.push(
			{
				title: `${t('order.list.name')}`,
				align: 'center',
				sortable: false,
				key: 'name',
				width: '120px'
			},
		);
	}
	
	result.push(
		{
			title: `${t('order.list.campaign')}`,
			align: 'center',
			sortable: false,
			key: 'campaign_id',
			width: '200px'
		},
	);
	
	if (null != user && user.value?.is_agency && !isDashboardView.value) {
		result.push(
			{
				title: `${t('articles.account')}`,
				align: 'center',
				sortable: false,
				key: 'account',
				width: '250px'
			},
		);
	}
	
	if (isDashboardView.value) {
		result.push(
			{
				title: `${t('order.list.totalAmount')}`,
				align: 'center',
				sortable: false,
				key: 'total_amount',
				width: '150px'
			},
		);
	}
	
	if (!isDashboardView.value) {
		result.push(...[
			{
				title: `${t('order.list.paymentStatus')}`,
				align: 'center',
				sortable: false,
				key: 'payment_status',
				width: '150px'
			},
			{
				title: `${t('order.list.totalAmount')}`,
				align: 'center',
				sortable: false,
				key: 'total_amount',
				width: '150px'
			},
			{
				title: `${t('order.list.createdAt')}`,
				align: 'center',
				sortable: false,
				key: 'created_at',
				width: '120px'
			},
		]);
	}
	
	if (isBasketListView.value || isDashboardView.value) {
		result.push(
			{
				title: `${t('order.list.actions')}`,
				align: 'center',
				sortable: false,
				key: 'details',
				width: !isDashboardView.value ? '300px' : '150px'
			},
		);
	}
	
	if (!isBasketListView.value && !isDashboardView.value) {
		result.push(
			{
				title: `${t('order.list.actions')}`,
				align: 'center',
				sortable: false,
				key: 'data-table-expand',
				width: '200px'
			},
		);
	}
	
	return result;
};
headers.value = defaultHeaders();
const defaultInactiveHeaders = () => {
	const result = [
		{
			title: `${t('order.list.net')}`,
			align: 'center',
			sortable: false,
			key: 'net',
			width: '150px'
		},
		{
			title: `${t('order.list.tax')}`,
			align: 'center',
			sortable: false,
			key: 'tax',
			width: '150px'
		},
		{
			title: `${t('order.list.updated_at')}`,
			align: 'center',
			sortable: false,
			key: 'updated_at',
			width: '120px'
		},
	];
	
	if (!isBasketListView.value) {
		result.push(
			{
				title: `${t('order.list.name')}`,
				align: 'center',
				sortable: false,
				key: 'name',
				width: '120px'
			},
		);
	}
	
	return result;
};

const expandedHeadersFunction = () => {
	const result = [
		{
			title: `${t('order.list.type')}`,
			sortable: false,
			align: 'center',
			key: 'orderable_type',
			width: '25%',
		},
		{
			title: `${t('order.list.amount')}`,
			sortable: false,
			align: 'center',
			key: 'net',
			width: '25%',
		},
		{
			title: `${t('order.list.status')}`,
			sortable: false,
			align: 'center',
			key: 'status',
			width: '25%',
		},
		{
			title: `${t('order.list.domainArticle')}`,
			sortable: false,
			align: 'center',
			key: 'details',
			width: '25%',
		},
	];
	
	return result;
}
expandedHeaders.value = expandedHeadersFunction();

const dependentAccountUuid = ref<string|null>(null);
const dependentAccountList = ref<Array<AgencyItemListInterface>|any>([]);
const agencyDependentAccountList = async() => {
	if (null == user.value) {
		return;
	}
	try {
		const result = await agencyApi.agencyUserList(user.value.uuid);
		if (!result.isSuccess) {
			return;
		}
		dependentAccountList.value = result.payload.data;
		dependentAccountList.value.push(user.value);
	} catch (e) {
		return;
	}
};
if (user.value?.is_agency) {
	agencyDependentAccountList();
}

const expanded = ref<Array<IOrderBasketTableItem>|any>([]);
const expand = (item:Array<IOrderBasketTableItem>|any) => {
	const indexExpand = expanded.value.indexOf(item.value);
	if (!expanded.value.includes(item.value)) {
		expanded.value.push(item.value);
	} else {
		expanded.value.splice(indexExpand, 1);
	}
};

const orderBasketList = async() => {
	loading.value = true;
	expanded.value = [];
	try {
		const result = await orderApi.orderBasketList(
			isBasketListView.value || isDashboardView.value ? 0 : 1,
			dependentAccountUuid.value,
			isDashboardView.value ? 1 : options.value.page,
			isDashboardView.value ? 7 : options.value.itemsPerPage
		);
		if (!result.isSuccess) {
			toast.error(`${t('order.list.toast.somethingWentWrong')}`);
			loading.value = false;
			return;
		}
		total.value = result.payload.total;
		currentPage.value = result.payload.current_page;
		lastPage.value = result.payload.last_page;
		if (undefined === result.payload.data.length) {
			items.value = [];
			Object.entries(result.payload.data).forEach(entry => {
				items.value.push(entry[1]);
			});
		} else {
			items.value = result.payload.data;
		}
	} catch (e) {
		toast.error(`${t('order.list.toast.somethingWentWrong')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const basketTableSettings = ref<TableSettingsInterface|null>(null);
const ordersTableSettings = ref<TableSettingsInterface|null>(null);
const initTableOptions = async() => {
	let isShouldBeSaved = false;
	basketTableSettings.value = storeToRefs(useTableSettingsStore())[CARTS_LIST].value;
	ordersTableSettings.value = storeToRefs(useTableSettingsStore())[ORDERS_LIST].value;
	
	if (isBasketListView.value) {
		// Columns options
		if (null == basketTableSettings.value) {
			await setSort({
				name: CARTS_LIST,
				sortBy: null,
				sortDesc: null,
			});
			isShouldBeSaved = true;
		}
		// Headers options
		if (null == basketTableSettings.value || 0 === basketTableSettings.value.columns.active.length || basketTableSettings.value.filters.locale !== localeLanguage.value) {
			isClearedTableSettingsAction.value = true;
			await setHeaders({
				name: CARTS_LIST,
				headers: {
					active: defaultHeaders(),
					inactive: defaultInactiveHeaders(),
				}
			});
			isShouldBeSaved = true;
		}
		// Pagination options
		if (null == basketTableSettings.value || null == basketTableSettings.value.page || null == basketTableSettings.value.per_page) {
			await setPagination({
				name: CARTS_LIST,
				page: 1,
				perPage: 10
			});
			isShouldBeSaved = true;
		}
		// Filters options
		// if (null != basketTableSettings.value.filters.agencyAccount) {
		// 	dependentAccountUuid.value = basketTableSettings.value.filters.agencyAccount;
		// }
		if (null != basketTableSettings.value && basketTableSettings.value.filters.locale !== localeLanguage.value) {
			const filters = {
				agencyAccount: dependentAccountUuid.value,
				locale: localeLanguage.value,
			};
			await setFilters({
				name: CARTS_LIST,
				filters: filters
			});
		}
		if (null != basketTableSettings.value) {
			options.value.page = basketTableSettings.value.page;
			options.value.itemsPerPage = basketTableSettings.value.per_page;
			options.value.sortBy.push({
				key: basketTableSettings.value.sort_column,
				order: basketTableSettings.value.sort_order,
			});
			
			headers.value = basketTableSettings.value.columns.active;
		}
		if (isShouldBeSaved) {
			await editTableSettingsAction();
		}
		await orderBasketList();
		isClearedTableSettingsAction.value = false;
	} else {
		// Columns options
		if (null == ordersTableSettings.value) {
			await setSort({
				name: ORDERS_LIST,
				sortBy: null,
				sortDesc: null,
			});
			isShouldBeSaved = true;
		}
		// Headers options
		if (null == ordersTableSettings.value || 0 === ordersTableSettings.value.columns.active.length || ordersTableSettings.value.filters.locale !== localeLanguage.value) {
			isClearedTableSettingsAction.value = true;
			await setHeaders({
				name: ORDERS_LIST,
				headers: {
					active: defaultHeaders(),
					inactive: defaultInactiveHeaders(),
				}
			});
			isShouldBeSaved = true;
		}
		// Pagination options
		if (null == ordersTableSettings.value || null == ordersTableSettings.value.page || null == ordersTableSettings.value.per_page) {
			await setPagination({
				name: ORDERS_LIST,
				page: 1,
				perPage: 10
			});
			isShouldBeSaved = true;
		}
		// Filters options
		// if (null != ordersTableSettings.value.filters.agencyAccount) {
		// 	dependentAccountUuid.value = ordersTableSettings.value.filters.agencyAccount;
		// }
		if (null != ordersTableSettings.value && ordersTableSettings.value.filters.locale !== localeLanguage.value) {
			const filters = {
				agencyAccount: dependentAccountUuid.value,
				locale: localeLanguage.value,
			};
			await setFilters({
				name: ORDERS_LIST,
				filters: filters
			});
		}
		if (null != ordersTableSettings.value) {
			options.value.page = ordersTableSettings.value.page;
			options.value.itemsPerPage = ordersTableSettings.value.per_page;
			options.value.sortBy.push({
				key: ordersTableSettings.value.sort_column,
				order: ordersTableSettings.value.sort_order,
			});
			
			headers.value = ordersTableSettings.value.columns.active;
		}
		if (isShouldBeSaved) {
			await editTableSettingsAction();
		}
		await orderBasketList();
		isClearedTableSettingsAction.value = false;
	}
};
if (!isDashboardView.value) {
	initTableOptions();
} else {
	orderBasketList();
}

const editTableSettingsAction = () => {
	if (null != user.value && isBasketListView.value && null != basketTableSettings.value) {
		const tableSettings:TableSettingsRequestInterface = {
			sort_column: null != basketTableSettings.value.sort_column && 0 < basketTableSettings.value.sort_column.length ? basketTableSettings.value.sort_column : null,
			sort_order: null != basketTableSettings.value.sort_order && 0 < basketTableSettings.value.sort_order.length ? basketTableSettings.value.sort_order : null,
			page: basketTableSettings.value.page,
			per_page: basketTableSettings.value.per_page,
			filters: JSON.stringify(basketTableSettings.value.filters),
			columns: JSON.stringify(basketTableSettings.value.columns),
		};
		editTableSettings(CARTS_LIST, tableSettings, user.value?.active_role_id);
	} else if (null != user.value && null != ordersTableSettings.value) {
		const tableSettings:TableSettingsRequestInterface = {
			sort_column: null != ordersTableSettings.value.sort_column && 0 < ordersTableSettings.value.sort_column.length ? ordersTableSettings.value.sort_column : null,
			sort_order: null != ordersTableSettings.value.sort_order && 0 < ordersTableSettings.value.sort_order.length ? ordersTableSettings.value.sort_order : null,
			page: ordersTableSettings.value.page,
			per_page: ordersTableSettings.value.per_page,
			filters: JSON.stringify(ordersTableSettings.value.filters),
			columns: JSON.stringify(ordersTableSettings.value.columns),
		};
		editTableSettings(ORDERS_LIST, tableSettings, user.value?.active_role_id);
	}
};

const clearTableSettingsAction = async() => {
	isClearAction = true;
	
	dependentAccountUuid.value = null;
	
	options.value.page = 1;
	options.value.itemsPerPage = 10;
	options.value.sortBy = [];
	
	headers.value = defaultHeaders();
	
	const filters = {
		agencyAccount: dependentAccountUuid.value,
		locale: localeLanguage.value,
	};
	
	const columns = {
		active: defaultHeaders(),
		inactive: defaultInactiveHeaders(),
	};
	
	const tableSettings:TableSettingsRequestInterface = {
		sort_column: null,
		sort_order: null,
		page: 1,
		per_page: 10,
		filters: JSON.stringify(filters),
		columns: JSON.stringify(columns),
	}
	
	if (null != user.value) {
		await clearTableSettings(isBasketListView.value ? CARTS_LIST : ORDERS_LIST, tableSettings, user.value?.active_role_id, `${t('components.toast.errorClearTableSettings')}`, `${t('components.toast.successClearTableSettings')}`);
	}
	isClearedTableSettingsAction.value = true;
	
	setTimeout(() => {
		isClearAction = false;
		isClearedTableSettingsAction.value = false;
		orderBasketList();
	},1);
};

const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	if (!firstLoadPage && !isClearAction) {
		await setPagination({
			name: isBasketListView.value ? CARTS_LIST : ORDERS_LIST,
			page: val.page,
			perPage: val.itemsPerPage,
		});
		await setSort({
			name: isBasketListView.value ? CARTS_LIST : ORDERS_LIST,
			sortBy: 0 < val.sortBy.length ? val.sortBy[0].key : [],
			sortDesc: 0 < val.sortBy.length ? val.sortBy[0].order : [],
		});
		if (!isFilterAction) {
			await editTableSettingsAction();
			await orderBasketList();
		}
	}
	isFilterAction = false;
	firstLoadPage = false;
};

watch(([dependentAccountUuid]), async (newVal, oldVal) => {
	if (!isClearAction) {
		if (options.value.page > 1) {
			options.value.page = 1;
			await setPagination({
				name: isBasketListView.value ? CARTS_LIST : ORDERS_LIST,
				page: 1,
				perPage: options.value.itemsPerPage,
			});
			isFilterAction = true;
		}
		if (newVal[0] !== oldVal[0]) {
			const filters = {
				agencyAccount: newVal[0],
				locale: localeLanguage.value
			};
			await setFilters({
				name: isBasketListView.value ? CARTS_LIST : ORDERS_LIST,
				filters: filters
			});
			await editTableSettingsAction();
			await orderBasketList();
		}
	}
},
{
	deep: true,
});

const headersMoveAction = () => {
	if (isBasketListView.value && null != basketTableSettings.value) {
		headers.value = basketTableSettings.value.columns.active;
	} else if (null != ordersTableSettings.value) {
		headers.value = ordersTableSettings.value.columns.active;
	}
};

const getInvoice = async(uuid:string) => {
	loading.value = true;
	try {
		const result = await invoiceApi.getInvoice(uuid);
		if (!result.isSuccess) {
			loading.value = false;
			return;
		}
		const binaryString = window.atob(result.payload);
		const binaryLen = binaryString.length;
		const bytes = new Uint8Array(binaryLen);
		for (let i = 0; i < binaryLen; i++) {
			const ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		const blob = new Blob([bytes], { type: 'application/pdf' });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = 'invoice.pdf';
		link.click();
	} catch (e) {
		loading.value = false;
		return;
	}
	loading.value = false;
};

const redirectToBasket = (uuid:string) => {
	router.push(`/basket/${uuid}`);
};

const openPaymentDialog = (uuid:string, item:IOrderBasketTableItem) => {
	basketUuid.value = uuid;
	basketItem.value = item;
	isClosePaymentDialog.value = false;
	isOpenPaymentDialog.value = true;
};

const openDeleteDialog = (id: number) => {
	orderId.value = id;
	isClosedClearCartDialog.value = false;
	isOpenClearCartDialog.value = true;
};

const closeDialogWhenNoTargetPressed = () => {
	orderId.value = null;
	basketUuid.value = null;
	basketItem.value = null;
	
	isClosePaymentDialog.value = true;
	isClosedClearCartDialog.value = true;
	isOpenPaymentDialog.value = false;
	isOpenClearCartDialog.value = false;
	selectedPaymentMethod.value = null;
};

const pay = async() => {
	loading.value = true;
	
	if ('' === basketUuid.value || null == basketUuid.value) {
		toast.error(`${t('basket.list.toast.paymentError')}`);
		loading.value = false;
		return;
	}
	
	const request:IPaymentRequest = {
		method: selectedPaymentMethod.value,
	};
	try {
		const result = await paymentApi.pay(basketUuid.value, request);
		if (!result.isSuccess) {
			toast.error(`${t('basket.list.toast.paymentError')}`);
			loading.value = false;
			return;
		}
		closeDialogWhenNoTargetPressed();
		window.location.href = result.payload.url;
	} catch (e) {
		toast.error(`${t('basket.list.toast.paymentError')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const choosePaymentMethod = (slug:string) => {
	selectedPaymentMethod.value = slug;
};

const clearBasket = async() => {
	loading.value = true;
	if (null == orderId.value) {
		closeDialogWhenNoTargetPressed();
		toast.error(`${t('publications.toast.errorClearBasket')}`);
		loading.value = false;
		return;
	}
	try {
		const result = await monolithPublicationApi.clearBasket(orderId.value);
		if (!result.isSuccess) {
			closeDialogWhenNoTargetPressed();
			toast.error(`${t('publications.toast.errorClearBasket')}`);
			loading.value = false;
			return;
		}
		closeDialogWhenNoTargetPressed();
		await orderBasketList();
		toast.success(`${t('publications.toast.successClearBasket')}`);
	} catch (e) {
		closeDialogWhenNoTargetPressed();
		toast.error(`${t('publications.toast.errorClearBasket')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const removeHttps = (url: string): string => {
	return url.replace(/^https?:\/\//, "");
};

const downloadToCsv = (isExpandCsv: boolean, item?: Array<IOrderItem>, rowItem?: IOrderBasketTableItem) => {
	let headersToCsV = [];
	let rowKeys: string[] = [];
	if (isExpandCsv && null != item && null != rowItem) {
		headersToCsV = [
			{
				title: `${t('order.list.domainArticle')}`,
				sortable: false,
				align: 'center',
				key: 'details',
				width: '25%',
			},
			{
				title: `${t('order.list.type')}`,
				sortable: false,
				align: 'center',
				key: 'orderable_type',
				width: '25%',
			},
			{
				title: `${t('order.list.amount')}`,
				sortable: false,
				align: 'center',
				key: 'net',
				width: '25%',
			},
			{
				title: `${t('order.list.createdAt')}`,
				align: 'center',
				sortable: false,
				key: 'created_at',
				width: '120px'
			},
		];
		rowKeys = ['details', 'orderable_type', 'net', 'created_at'];
		exportTableToCsv(headersToCsV, item, rowKeys, `${t('components.toast.errorDownloadCsv')}`, `${t('components.toast.successDownloadCsv')}`, t, `${rowItem.campaign != null && rowItem.campaign.default !== 1 ? rowItem.campaign.name : 'campaign'}`);
	}
	if (!isExpandCsv) {
		headersToCsV = [
			{
				title: `${t('order.list.order')}`,
				align: 'center',
				sortable: false,
				key: 'id',
				width: '80px'
			},
			{
				title: `${t('order.list.campaign')}`,
				align: 'center',
				sortable: false,
				key: 'campaign_id',
				width: '200px'
			},
			{
				title: `${t('order.list.net')}`,
				align: 'center',
				sortable: false,
				key: 'net',
				width: '150px'
			},
			{
				title: `${t('order.list.tax')}`,
				align: 'center',
				sortable: false,
				key: 'tax',
				width: '150px'
			},
			{
				title: `${t('order.list.totalAmount')}`,
				align: 'center',
				sortable: false,
				key: 'gross',
				width: '150px'
			},
			{
				title: `${t('order.list.createdAt')}`,
				align: 'center',
				sortable: false,
				key: 'created_at',
				width: '120px'
			},
		];
		rowKeys = ['id', 'campaign_id', 'net', 'tax', 'gross', 'created_at'];
		exportTableToCsv(headersToCsV, items.value, rowKeys, `${t('components.toast.errorDownloadCsv')}`, `${t('components.toast.successDownloadCsv')}`, t, 'orders-history');
	}
};
</script>

<style scoped lang="scss">
.orders-basket-table {
	@import '@/assets/scss/vuetifyDataTable';
	@import '@/assets/scss/vuetifyDataTableBottomRoundRadius';
	&__offer-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		
		&:deep(.m-btn) {
			font-weight: 700;
		}
		
		&:deep(.theme--light.v-btn.v-btn--has-bg) {
			background-color: $secondary-400;
			color: $white-100;
			font-size: 12px;
			font-weight: $weight-700;
			border-radius: 0;
		}
		
		&:deep(.v-btn:not(.v-btn--round).v-size--default) {
			height: 28px;
			padding: 0 10px;
		}
	}
	
	&__arrow {
		margin-left: 5px;
		
		&--rotate {
			transform: rotateX(180deg);
		}
	}
	
	&__currency-symbol {
		color: $currency-pound;
		font-weight: $weight-700;
		width: calc(50% + 25px);
		text-align: end;
	}
	
	&__link {
		padding: 6px 14px;
		width: max-content;
		display: flex;
		justify-content: center;
		border-radius: 15px;
		color: $white;
		text-align: center;
		font-weight: 700;
	}
	
	&__status {
		width: max-content;
		margin: 0 auto;
		color: $white-100;
		
		&--draft {
			background-color: transparent;
		}
		
		&--pending {
			background-color: transparent;
		}
		
		&--done {
			background-color: transparent;
		}
	}
	
	&__details {
		font-weight: $weight-700;
	}
	
	&__footer-expand-wrapper {
		background-color: $primary-400;
		display: flex;
		flex-direction: column;
		align-items: center;
		
		@include media-breakpoint-up(md) {
			align-items: flex-end;
		}
	}
	
	&__footer-item {
	
	}
	
	&__footer-btn-wrapper {
		margin-top: 10px;
	}
	
	&__dependent_wrapper {
		width: 100%;
		display: flex;
		align-items: baseline;
		gap: 10px;
		
		span {
			font-size: $size-14;
			color: $grey-400;
			font-weight: 700;
		}
		
		&:deep(.v-input__details) {
			display: none;
		}
	}
	
	&__payment-status {
		text-transform: capitalize;
		color: $primary-400;
	}
	
	&__top-wrapper {
		display: flex;
		align-items: center;
		padding: 15px;
		
		&:deep(.v-field__input) {
			min-height: 43px !important;
			padding: 8px 2px;
		}
	}
	
	&__options-wrapper {
		display: flex;
		align-items: center;
		gap: 20px;
		margin-left: auto;
	}
	
	&__action-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
		
		&:deep(.m-btn) {
			width: 100px;
			border-radius: 8px;
			font-weight: 700;
		}
	}
	
	&__popup-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-bottom: 10px;
		
		&:deep(.m-btn) {
			padding: 35px;
		}
	}
	
	&__payment-icon-wrapper {
		margin-top: 3px;
		width: max-content;
		
		&--autopay {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 5px;
			margin-top: 4px;
		}
	}
	
	&__payment-autopay-checkbox-wrapper {
		display: flex;
		justify-content: center;
		
		&:deep(.v-input__details) {
			display: none;
		}
	}
	
	&__clear-dialog-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	
	&__clear-dialog-text {
		font-size: 16px;
		text-align: center;
		color: $primary-400;
	}
	
	&__clear-dialog-btn-wrapper {
		margin: 20px 0;
		display: flex;
		gap: 40px;
		justify-content: center;
		
		&:deep(.m-btn) {
			width: 130px;
			height: 42px;
			font-weight: 700;
		}
	}
	
	&__footer-main-wrapper {
		display: flex;
		flex-direction: row-reverse;
		background-color: $primary-400;
		align-items: center;
		border-top: thin solid hsla(0,0%,100%,.12);
		padding: 10px 15px;
	}
	
	&__footer-prepend-wrapper {
		margin-right: auto;
	}
	
	&__csv-btn {
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		&--global {
			margin-right: auto;
			margin-left: 15px;
		}
	}
	
	:deep(.v-theme--dark .v-data-table-header__content) {
		color: $white-100;
	}
	
	:deep(.m-autocomplete) {
		width: 350px;
	}
	:deep(.m-btn) {
		font-size: 12px;
	}
}

.orders-basket-table-dashboard {
	@import '@/assets/scss/vuetifyDataTableDashboard';
	overflow-y: scroll;
	background-color: $white-100;
	border-radius: 8px;
	
	&:deep(.v-data-table) {
		@include media-breakpoint-up(xxl) {
			height: 342px + 95px + 9px;
		}
	}
}
</style>
