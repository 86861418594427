<template>
	<div class="campaign-list-table" :class="{'campaign-list-table-dashboard': isDashboardView}">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			v-model:page="options.page"
			:items-per-page="options.itemsPerPage"
			:sort-by="options.sortBy"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template #loading>
				<div class="campaign-list-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template v-slot:column.publications_ai="{ column, isSorted, getSortIcon }">
				<td class="campaign-list-table__custom-header-wrapper">
					<span>
						<MTooltip :max-width="300">
							<template #title>
								<div class="campaign-list-table__custom-header-wrapper">
									<div>{{ column.title }}</div>
									<MIcon icon="info" />
								</div>
							</template>
							<template v-slot:content>
		                        <div>{{ $t('campaigns.columnAiTooltipInfo') }}</div>
	                        </template>
						</MTooltip>
					</span>
					<div v-if="isSorted(column)">
						<v-icon :icon="getSortIcon(column)" />
					</div>
					<div v-if="!isSorted(column)">
						<v-icon :icon="getSortIcon(column)" class="campaign-list-table__hide" />
					</div>
				</td>
			</template>
			
			<template #top>
				<div v-if="!isDashboardView" class="campaign-list-table__top-wrapper">
					<div class="campaign-list-table__search-wrapper">
						<MTextField
							id="campaign-search"
							v-model="search"
							name="search"
							variant="outlined"
							label=""
							:placeholder="$t('campaigns.search')"
							clearable
						/>
					</div>
					<div v-if="null != user && user.is_agency" class="campaign-list-table__search-wrapper">
						<MAutocomplete
							id="article-dependent-account"
							v-model="dependentAccountId"
							:get-items="dependentAccountList"
							clearable
							variant="outlined"
							item-title="email"
							item-value="uuid"
							:placeholder="$t('campaigns.selectAccount')"
						/>
					</div>
					
					<div v-if="null != CAMPAIGNS_LIST" class="campaign-list-table__table-settings-wrapper">
						<TableSettingsMenuWithModal :table-name="CAMPAIGNS_LIST" :is-cleared-table-settings-action="isClearedTableSettingsAction" @headersMove="headersMoveAction" :on-clear-action="clearTableSettingsAction" />
					</div>
				</div>
			</template>
			
			<template #item.name="elem">
				<div class="campaign-list-table__name-wrapper">
					<div v-if="null != elem.item.raw.name" class="campaign-list-table__bold" :class="{'campaign-list-table__word-break': isDashboardView}">{{ elem.item.raw.name }}</div>
				</div>
			</template>
			
			<template #item.number_of_publications="elem">
				<div v-if="null != elem.item.raw.number_of_publications && isDashboardView">
					<div>{{ elem.item.raw.number_of_publications }}</div>
				</div>
			</template>
			
			<template #item.expenses="elem">
				<div v-if="null != elem.item.raw.expenses && isDashboardView">
					<div v-if="0 !== elem.item.raw.expenses" class="campaign-list-table__red">-{{ (+elem.item.raw.expenses / 100).toFixed(2) }} {{ userCurrencySymbol }}</div>
					<div v-if="0 === elem.item.raw.expenses" class="campaign-list-table__red">{{ (+elem.item.raw.expenses / 100).toFixed(2) }} {{ userCurrencySymbol }}</div>
				</div>
			</template>
			
			<template #item.account="elem">
				<div v-if="null != elem.item.raw.email">
					<div>{{ elem.item.raw.email }}</div>
				</div>
			</template>
			
			<template #item.url="elem">
				<div v-if="null != elem.item.raw.url" class="m9-data-table-align-left">{{ elem.item.raw.url }}</div>
			</template>
			
			<template #item.description="elem">
				<div v-if="null != elem.item.raw.description" class="campaign-list-table__description m9-data-table-align-left" v-html="elem.item.raw.description"></div>
			</template>
			
			<template #item.offers="elem">
				<div v-if="null != elem.item.raw.description" class="campaign-list-table__offer-btn">
					<MButton
						id="offer-btn"
						red400
						:label="$t('campaigns.seeAvailableOffers')"
						@click="goToOfferFeed(elem.item.raw.id, false)"
					/>
				</div>
			</template>
			
			<template #item.publications_ai="elem">
				<div v-if="Array.isArray(elem.item.raw.suggested_websites) && 0 < elem.item.raw.suggested_websites.length" class="campaign-list-table__offer-btn">
					<MButton
						id="ai-btn"
						green400
						:label="$t('campaigns.seeAvailableAIOffers')"
						@click="goToOfferFeed(elem.item.raw.id, true)"
					/>
				</div>
				<div v-if="!Array.isArray(elem.item.raw.suggested_websites) || 0 === elem.item.raw.suggested_websites.length">-</div>
			</template>
			
			<template #item.created_at="elem">
				<div v-if="null != elem.item.raw.created_at">{{ dateFormatWithTime(elem.item.raw.created_at) }}</div>
			</template>
			
			<template #item.updated_at="elem">
				<div v-if="null != elem.item.raw.updated_at">{{ dateFormatWithTime(elem.item.raw.updated_at) }}</div>
			</template>
			
			<template #item.actions="elem">
				<div id="edit-campaign" class="campaign-list-table__action-wrapper" :class="{'campaign-list-table__action-wrapper--margin': isDashboardView}" @click="goToEditCampaign(elem.item.raw.uuid)">
					<MTooltip without-btn>
						<template v-slot:title>
							<MIcon icon="pencil" :color="white" />
						</template>
						<template v-slot:content>
							<div>{{ $t('campaigns.edit') }}</div>
						</template>
					</MTooltip>
				</div>
			</template>
			
			<template #item.flag="elem">
				<MFlag v-if="null != elem.item.raw.language_id && !isDashboardView" :country-code="getLanguageSymbol(elem.item.raw.language_id)" />
			</template>
			
			<template #bottom v-if="isDashboardView">
				<div></div>
			</template>
		</v-data-table-server>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { ref, watch } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useSessionStore } from '@/stores/session';
import { toast } from 'vue3-toastify';
import { useTableSettingsStore } from '@/stores/tableSettings';
import { useDictionaryStore } from '@/stores/dictionary';
import { dateFormatWithTime } from '@/hooks/DataHooks';
import { CAMPAIGNS_LIST, clearTableSettings, editTableSettings } from '@/hooks/TableSettingsHooks';
import { isAdvertiser } from '@/hooks/UserHooks';
import { ROUTE_DASHBOARD } from '@/hooks/RoutingHooks';
import { useBooleanFlagStore } from '@/stores/booleanFlag';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import AgencyApi from '@/api/v1/AgencyApi';
import CampaignApi from '@/api/v1/CampaignApi';
import WebsitesApi from '@/apiWebsites/v1/WebsitesApi';
import variables from '@/assets/scss/modules/variables.module.scss';
import type {
	TableFiltersSettingsInterface,
	TableSettingsInterface,
	TableSettingsRequestInterface
} from '@/models/TableSettingsModel';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { AgencyItemListInterface } from '@/models/AgencyModel';
import type { ICampaignsListItem } from '@/models/CampaignModel';
import MTooltip from '@/components/atoms/MTooltip.vue';
import MButton from '@/components/atoms/MButton.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import TableSettingsMenuWithModal from '@/components/organisms/TableSettingsMenuWithModal.vue';
import MFlag from '@/components/atoms/MFlag.vue';

const agencyApi = new AgencyApi();
const campaignApi = new CampaignApi();
const websitesApi = new WebsitesApi();

const { locale } = storeToRefs(useSessionStore());
const { languages } = useDictionaryStore();
const { white } = variables;
const { user, userCurrencySymbol } = storeToRefs(useUserStore());
const { t } = useI18n();
const { setSort, setHeaders, setFilters, setPagination } = useTableSettingsStore();
const { setIsAiOfferFilters } = useBooleanFlagStore();

const items = ref<Array<ICampaignsListItem>|any>([]);
const headers = ref<Array<TableHeaderInterface>|any>([]);
const loading = ref<boolean>(false);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const total = ref<number>(0);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const isClearedTableSettingsAction = ref<boolean>(false);
const search = ref<string|null>(null);
const searchTimeout = ref<any>(undefined);
let firstLoadPage:boolean = true;
let isClearAction:boolean = false;
let isFilterAction:boolean = false;
const dependentAccountId = ref<string|null>(null);
const dependentAccountList = ref<Array<AgencyItemListInterface>|any>([]);
const isDashboardView = ref<boolean>(false);

if (null != user.value && isAdvertiser(user.value) && router.currentRoute.value.name === ROUTE_DASHBOARD) {
	isDashboardView.value = true;
}

const localeLanguage = ref('');

if ('us' === locale.value) {
	localeLanguage.value = 'en';
} else if (locale.value != null) {
	localeLanguage.value = locale.value;
} else {
	localeLanguage.value = 'pl';
}

const defaultHeaders = () => {
	const result = [
		{
			title: `${t('campaigns.campaignName')}`,
			align: 'center',
			sortable: !isDashboardView.value,
			key: 'name',
			width: !isDashboardView.value ? '220px' : '',
		},
	];
	
	if (isDashboardView.value) {
		result.push(...[
			{
				title: `${t('dashboard.numbersOfPublications')}`,
				align: 'center',
				sortable: false,
				key: 'number_of_publications',
				width: '',
			},
			{
				title: `${t('dashboard.expenses')}`,
				align: 'center',
				sortable: false,
				key: 'expenses',
				width: '',
			},
		]);
	}
	
	if (null != user && user.value?.is_agency && !isDashboardView.value) {
		result.push({
			title: `${t('campaigns.account')}`,
			align: 'center',
			sortable: false,
			key: 'account',
			width: '150px',
		});
	}
	
	if (!isDashboardView.value) {
		result.push(...[
			{
				title: `${t('campaigns.campaignUrl')}`,
				align: 'center',
				sortable: true,
				key: 'url',
				width: '350px',
			},
			{
				title: `${t('campaigns.description')}`,
				align: 'center',
				sortable: true,
				key: 'description',
				width: '300px',
			},
			{
				title: `${t('campaigns.offers')}`,
				align: 'center',
				sortable: false,
				key: 'offers',
				width: '250px',
			},
		]);
	}
	
	if (null != user && (user.value?.id === 1327 || user.value?.id === 4790 || user.value?.id === 7436 || user.value?.id === 7004 || user.value?.id === 7231 ||  user.value?.id === 5706 ||  user.value?.id === 5787  || user.value?.id === 2163 || user.value?.id === 3943 || user.value?.id === 5972) && !isDashboardView.value) {
		result.push(...[
			{
				title: `${t('campaigns.publicationsAI')}`,
				align: 'center',
				sortable: false,
				key: 'publications_ai',
				width: '250px',
			},
		]);
	}
	
	if (!isDashboardView.value) {
		result.push(...[
			{
				title: `${t('campaigns.createdAt')}`,
				align: 'center',
				sortable: true,
				key: 'created_at',
				width: '150px',
			},
			{
				title: `${t('campaigns.updatedAt')}`,
				align: 'center',
				sortable: true,
				key: 'updated_at',
				width: '150px',
			},
		]);
	}
	
	result.push(...[
		{
			title: `${t('campaigns.actions')}`,
			align: 'center',
			sortable: !isDashboardView.value,
			key: 'actions',
			width: !isDashboardView.value ? '80px' : '',
		},
	]);
	
	return result;
};
headers.value = defaultHeaders();

const defaultInactiveHeaders = () => {
	const result:Array<TableHeaderInterface> = [
		{
			title: `${t('campaigns.language')}`,
			align: 'center',
			sortable: false,
			key: 'flag',
			width: '80px',
		},
	];
	
	return result;
};

const agencyDependentAccountList = async() => {
	if (null == user.value) {
		return;
	}
	try {
		const result = await agencyApi.agencyUserList(user.value.uuid);
		if (!result.isSuccess) {
			return;
		}
		dependentAccountList.value = result.payload.data;
		dependentAccountList.value.push(user.value);
	} catch (e) {
		return;
	}
};
if (user.value?.is_agency && !isDashboardView.value) {
	agencyDependentAccountList();
}

const suggestedWebsites = async(campaignUrl:string, campaignId:number) => {
	try {
		const result = await websitesApi.suggestedWebsitesList(campaignUrl, campaignId);
		if (!result.isSuccess) {
			return;
		}
		if (result.payload.length > 0) {
			const currentRow = items.value.find((item: any) => item.id === result.payload[0].campaign_id);
			const index = items.value.indexOf(currentRow);
			items.value[index].suggested_websites = result.payload;
		}
	} catch (e) {
		return;
	}
};

const campaignsList = async() => {
	loading.value = true;
	try {
		const result = await campaignApi.campaignList(
			options.value.page,
			options.value.itemsPerPage,
			options.value.sortBy.length > 0 ? options.value.sortBy[0].key : null,
			options.value.sortBy.length > 0 ? options.value.sortBy[0].order : null,
			search.value,
			dependentAccountId.value,
			isDashboardView.value,
		);
		if (!result.isSuccess) {
			toast.error(`${t('campaigns.toast.errorList')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload.data;
		total.value = result.payload.total;
		currentPage.value = result.payload.current_page;
		lastPage.value = result.payload.last_page;
		
		if (router.currentRoute.value.name !== ROUTE_DASHBOARD) {
			items.value.forEach((item: any) => {
				suggestedWebsites(item.url, item.id);
			});
		}
	} catch (e) {
		toast.error(`${t('campaigns.toast.errorList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
const campaignTableSettings = ref<TableSettingsInterface|null>(null);
const initTableOptions = async() => {
	let isShouldBeSaved = false;
	campaignTableSettings.value = storeToRefs(useTableSettingsStore())[CAMPAIGNS_LIST].value;
	
	// Columns options
	if (null == campaignTableSettings.value) {
		await setSort({
			name: CAMPAIGNS_LIST,
			sortBy: 'updated_at',
			sortDesc: 'desc',
		});
		isShouldBeSaved = true;
	}
	
	//Headers options
	if (null == campaignTableSettings.value || 0 === campaignTableSettings.value.columns.active.length || campaignTableSettings.value.filters.locale !== localeLanguage.value) {
		isClearedTableSettingsAction.value = true;
		await setHeaders({
			name: CAMPAIGNS_LIST,
			headers: {
				active: defaultHeaders(),
				inactive: defaultInactiveHeaders(),
			}
		});
		isShouldBeSaved = true;
	}
	
	// Pagination options
	if (null == campaignTableSettings.value || null == campaignTableSettings.value.page || null == campaignTableSettings.value.per_page) {
		await setPagination({
			name: CAMPAIGNS_LIST,
			page: 1,
			perPage: 10
		});
		isShouldBeSaved = true;
	}
	
	// Filters options
	// if (null != campaignTableSettings.value.filters.search) {
	// 	search.value = campaignTableSettings.value.filters.search;
	// }
	// if (null != campaignTableSettings.value.filters.agencyAccount) {
	// 	dependentAccountId.value = campaignTableSettings.value.filters.agencyAccount;
	// }
	if (null != campaignTableSettings.value && campaignTableSettings.value.filters.locale !== localeLanguage.value) {
		const filters:TableFiltersSettingsInterface = {
			search: search.value,
			agencyAccount: dependentAccountId.value,
			locale: localeLanguage.value,
		};
		await setFilters({
			name: CAMPAIGNS_LIST,
			filters: filters
		});
	}
	
	if (null != campaignTableSettings.value) {
		options.value.page = campaignTableSettings.value.page;
		options.value.itemsPerPage = campaignTableSettings.value.per_page;
		options.value.sortBy.push({
			key: campaignTableSettings.value.sort_column,
			order: campaignTableSettings.value.sort_order,
		});
		
		headers.value = campaignTableSettings.value.columns.active;
	}
	
	if (isShouldBeSaved) {
		await editTableSettingsAction();
	}
	await campaignsList();
	isClearedTableSettingsAction.value = false;
};
if (!isDashboardView.value) {
	initTableOptions();
} else {
	campaignsList();
}

const editTableSettingsAction = () => {
	if (null != campaignTableSettings.value && null != user.value) {
		const tableSettings: TableSettingsRequestInterface = {
			sort_column: null != campaignTableSettings.value.sort_column && 0 < campaignTableSettings.value.sort_column.length ? campaignTableSettings.value.sort_column : null,
			sort_order: null != campaignTableSettings.value.sort_order && 0 < campaignTableSettings.value.sort_order.length ? campaignTableSettings.value.sort_order : null,
			page: campaignTableSettings.value.page,
			per_page: campaignTableSettings.value.per_page,
			filters: JSON.stringify(campaignTableSettings.value.filters),
			columns: JSON.stringify(campaignTableSettings.value.columns),
		};
		editTableSettings(CAMPAIGNS_LIST, tableSettings, user.value?.active_role_id);
	}
};

const clearTableSettingsAction = async() => {
	isClearAction = true;
	
	search.value = null;
	dependentAccountId.value = null;
	
	options.value.page = 1;
	options.value.itemsPerPage = 10;
	options.value.sortBy = [];
	
	headers.value = defaultHeaders();
	
	const filters = {
		search: search.value,
		agencyAccount: dependentAccountId.value,
		locale: localeLanguage.value,
	};
	
	const columns = {
		active: defaultHeaders(),
		inactive: defaultInactiveHeaders(),
	};
	
	const tableSettings:TableSettingsRequestInterface = {
		sort_column: 'updated_at',
		sort_order: 'desc',
		page: 1,
		per_page: 10,
		filters: JSON.stringify(filters),
		columns: JSON.stringify(columns),
	}
	
	if (null != user.value) {
		await clearTableSettings(CAMPAIGNS_LIST, tableSettings, user.value?.active_role_id, `${t('components.toast.errorClearTableSettings')}`, `${t('components.toast.successClearTableSettings')}`);
	}
	isClearedTableSettingsAction.value = true;
	
	setTimeout(() => {
		isClearAction = false;
		isClearedTableSettingsAction.value = false;
		campaignsList();
	},1);
};

const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	if (!firstLoadPage && !isClearAction) {
		await setPagination({
			name: CAMPAIGNS_LIST,
			page: val.page,
			perPage: val.itemsPerPage,
		});
		await setSort({
			name: CAMPAIGNS_LIST,
			sortBy: 0 < val.sortBy.length ? val.sortBy[0].key : [],
			sortDesc: 0 < val.sortBy.length ? val.sortBy[0].order : [],
		});
		if (!isFilterAction) {
			await editTableSettingsAction();
			await campaignsList();
		}
	}
	isFilterAction = false;
	firstLoadPage = false;
};

watch(([dependentAccountId, search]), async (newVal, oldVal) => {
	if (!isClearAction) {
		if (options.value.page > 1) {
			options.value.page = 1;
			await setPagination({
				name: CAMPAIGNS_LIST,
				page: 1,
				perPage: options.value.itemsPerPage,
			});
			isFilterAction = true;
		}
		if (newVal[0] !== oldVal[0] || newVal[1] !== oldVal[1]) {
			clearTimeout(searchTimeout.value);
			searchTimeout.value = setTimeout(async () => {
				const filters = {
					search: newVal[1],
					agencyAccount: newVal[0],
					locale: localeLanguage.value,
				};
				await setFilters({
					name: CAMPAIGNS_LIST,
					filters: filters
				});
				await editTableSettingsAction();
				await campaignsList();
			}, 700);
		}
	}
},
{
	deep: true,
});

const headersMoveAction = () => {
	if (null != campaignTableSettings.value) {
		headers.value = campaignTableSettings.value.columns.active;
	}
};
const goToOfferFeed = async (campaignId:number, isAi?: boolean) => {
	if (isAi) {
		await setIsAiOfferFilters(true);
	} else {
		await setIsAiOfferFilters(false);
	}
	await router.push(`/websites/${campaignId}/orders`);
};
const goToEditCampaign = (campaignId:number) => {
	router.push(`/campaigns/${campaignId}`);
};

const getLanguageSymbol = (languageId:number) => {
	const language = languages.find(elem => elem.id === languageId);
	if (null != language) {
		return language.flag;
	}
	return '-';
};
</script>

<style scoped lang="scss">
.campaign-list-table {
	@import '@/assets/scss/vuetifyDataTable';
	@import '@/assets/scss/vuetifyDataTableBottomRoundRadius';
	&__loading {
		opacity: 0.5;
	}
	
	&__top-wrapper {
		@include media-breakpoint-up(md) {
			display: flex;
			flex-wrap: wrap;
			gap: 15px;
			padding: 0 20px;
			align-items: center;
		}
		
		header {
			max-width: max-content !important;
		}
	}
	
	&__search-wrapper {
		display: flex;
		align-items: center;
		height: 70px !important;
		
		&:deep(.v-text-field .v-input__details) {
			display: none;
		}
		
		&:deep(.m-input) {
			width: 300px;
		}
		
		&:deep(.m-autocomplete) {
			min-width: 200px;
		}
		
		&:deep(.m-input input) {
			min-height: 43px;
			padding: 0 12px;
		}
		
		&:deep(.v-field__input) {
			min-height: 43px !important;
			padding: 8px 2px;
		}
		
		span {
			margin-right: 10px;
			font-size: $size-14;
			color: $grey-400;
			font-weight: $weight-700;
		}
		
		&--status {
			&:deep(.m-autocomplete) {
				width: 300px;
			}
		}
	}
	
	&__table-settings-wrapper {
		margin-left: auto;
	}
	
	&__account-wrapper {
		font-size: $size-12;
		opacity: 0.65;
		font-weight: 700;
		margin-left: -15px;
		word-break: break-all;
	}
	
	&__bold {
		font-weight: 700;
	}
	
	&__offer-btn {
		width: max-content;
		margin: 0 auto;
		
		&:deep(.m-btn) {
			font-weight: 700;
		}
	}
	
	&__action-wrapper {
		width: max-content;
		background-color: $grey-300;
		padding: 9px 11px 6px;
		border-radius: 50%;
		margin: 0 auto;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		&--margin {
			margin: 0 auto;
		}
	}
	
	&__name-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		text-align: left;
		gap: 5px;
		
		span {
			margin-top: -7px;
		}
	}
	
	&__description {
		width: 300px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	
	&__red {
		color: $secondary-400;
	}
	
	&__dashboard-btn-wrapper {
		display: flex;
		justify-content: center;
		padding: 21px 0;
		
		&:deep(.m-btn) {
			color: $primary-400 !important;
		}
	}
	
	&__word-break {
		word-break: break-all;
	}
	
	&__custom-header-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 3px;
	}
	
	&__hide {
		opacity: 0;
	}
}

.campaign-list-table-dashboard {
	@import '@/assets/scss/vuetifyDataTableDashboard';
	
	&:deep(.v-data-table) {
		@include media-breakpoint-up(xxl) {
			height: 342px + 95px + 9px;
		}
	}
}
</style>
