<template>
	<div class="offers-discount-groups">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:items-per-page-options="itemsPerPageOptions"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template #loading>
				<div class="offers-discount-groups__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #top>
				<div class="offers-discount-groups__search-wrapper">
					<MTextField
						v-model="search"
						name="search"
						:label="$t('pricelist.search')"
						:placeholder="$t('pricelist.search')"
						clearable
					/>
				</div>
			</template>
			
			<template #item.url="elem">
				<div v-if="null != elem.item.raw.url" class="offers-discount-groups__url-wrapper">
					<div class="offers-discount-groups__bold">{{ elem.item.raw.url }}</div>
					<a :href="elem.item.raw.url" target="_blank" class="offers-discount-groups__link">
						<MIcon icon="eye" :color="secondary400" />
					</a>
				</div>
			</template>
			
			<template #item.name="elem">
				<div v-if="null != elem.item.raw.name">{{ elem.item.raw.name }}</div>
			</template>
			
			<template #item.description="elem">
				<div v-if="null != elem.item.raw.description">{{ elem.item.raw.description }}</div>
			</template>
			
			<template #item.status="elem">
				<div v-if="null != elem.item.raw.status" class="offers-discount-groups__bold">
					<div v-if="elem.item.raw.status === APPROVED" class="offers-discount-groups__status offers-discount-groups__status--green">{{ elem.item.raw.status }}</div>
					<div v-if="elem.item.raw.status === DISAPPROVED" class="offers-discount-groups__status offers-discount-groups__status--red">{{ elem.item.raw.status }}</div>
					<div v-if="elem.item.raw.status === SKETCH" class="offers-discount-groups__status offers-discount-groups__status--yellow">{{ elem.item.raw.status }}</div>
					<div v-if="elem.item.raw.status === SUBMITTED_MODERATION || elem.item.raw.status === RESUBMITTED_MODERATION" class="offers-discount-groups__status offers-discount-groups__status--blue">{{ elem.item.raw.status }}</div>
				</div>
			</template>
			
			<template #item.price="elem">
				<div v-if="null != elem.item.raw.price" class="offers-discount-groups__bold">{{ elem.item.raw.price }}</div>
			</template>
			
			<template #item.currency_id="elem">
				<div v-if="null != elem.item.raw.currency_id" class="offers-discount-groups__bold offers-discount-groups__green offers-discount-groups__currency">{{ changeCurrencyIdToSymbol(elem.item.raw.currency_id) }}</div>
			</template>
			
			<template #item.pivot_value="elem">
				<div v-if="null != elem.item.raw.pivot.value" class="offers-discount-groups__discount-wrapper">
					<Form :validation-schema="schema">
						<MTextField
							v-model="elem.item.raw.pivot.value"
							name="discount"
							type="number"
							min="0"
							max="100"
							step="1"
							:placeholder="$t('pricelist.discount')"
							:disabled="!elem.item.raw.is_active"
						/>
					</Form>
				</div>
			</template>
			
			<template #item.created_at="elem">
				<div v-if="null != elem.item.raw.created_at">{{ dateFormat(elem.item.raw.created_at) }}</div>
			</template>
			
			<template #item.actions="elem">
				<div class="offers-discount-groups__actions-wrapper">
					<MButton v-if="!elem.item.raw.is_active" @click="openChangeOfferDiscount(elem.item.raw.id)" icon="pencil" :color="white" blue400 />
					<MButton v-if="elem.item.raw.is_active" @click="editOfferDiscount(elem.item.raw.uuid, elem.item.raw.pivot.value)" icon="tick" :color="white" green400 :disabled="+elem.item.raw.pivot.value < 0 || +elem.item.raw.pivot.value > 100" />
					<MButton v-if="elem.item.raw.is_active" @click="closeChangeOfferDiscount(elem.item.raw.id)" icon="close" :color="white" red400 />
				</div>
			</template>
		</v-data-table-server>
		
		<div class="offers-discount-groups__btn-wrapper">
			<MButton @click="backAction" normal width100 blue400 :label="$t('components.back')" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { ref, defineEmits, watch } from 'vue';
import router from '@/router';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import { useDictionaryStore } from '@/stores/dictionary';
import { dateFormat } from '@/hooks/DataHooks';
import { toast } from 'vue3-toastify';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import { APPROVED, DISAPPROVED, SUBMITTED_MODERATION, RESUBMITTED_MODERATION, SKETCH } from '@/hooks/WebsitesHooks';
import DiscountGroupApi from '@/api/v1/DiscountGroupApi';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { IDiscountGroupList, IDiscountGroupWebsiteOfferItem } from '@/models/DiscountGroupsModel';
import MTextField from '@/components/atoms/MTextField.vue';
import MButton from '@/components/atoms/MButton.vue';
import MIcon from '@/components/atoms/MIcon.vue';

const emit = defineEmits(['userGroupName']);
const { currencies } = useDictionaryStore();
const { t } = useI18n();
const { white, secondary400 } = variables;

const discountGroupApi = new DiscountGroupApi();

const items = ref<Array<IDiscountGroupWebsiteOfferItem>|any>([]);
const loading = ref<boolean>(false);
const currentPage = ref<number>(1);
const lastPage = ref<number>(1);
const total = ref<number>(0);
const search = ref<string|null>(null);
const searchTimeout = ref<any>(undefined);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('pricelist.url')}`,
		align: 'left',
		sortable: true,
		key: 'url',
		width: '300px',
	},
	{
		title: `${t('pricelist.name')}`,
		align: 'left',
		sortable: true,
		key: 'name',
		width: '250px',
	},
	{
		title: `${t('pricelist.description')}`,
		align: 'left',
		sortable: true,
		key: 'description',
		width: '270px',
	},
	{
		title: `${t('pricelist.status')}`,
		align: 'left',
		sortable: true,
		key: 'status',
		width: '200px',
	},
	{
		title: `${t('pricelist.price')}`,
		align: 'left',
		sortable: true,
		key: 'price',
		width: '100px',
	},
	{
		title: `${t('pricelist.currency')}`,
		align: 'left',
		sortable: true,
		key: 'currency_id',
		width: '90px',
	},
	{
		title: `${t('pricelist.discount')}`,
		align: 'left',
		sortable: true,
		key: 'pivot_value',
		width: '140px',
	},
	{
		title: `${t('pricelist.createdAt')}`,
		align: 'left',
		sortable: true,
		key: 'created_at',
		width: '120px',
	},
	{
		title: `${t('pricelist.actions')}`,
		align: 'left',
		sortable: false,
		key: 'actions',
		width: '120px',
	},
]);
const userGroupSlug = ref<Array<string>|string|null>(null);
const discountUserGroupList = ref<Array<IDiscountGroupList>|any>([]);
const userGroupId = ref<number|null>(null);

if (null != router.currentRoute.value.params.slug) {
	userGroupSlug.value = router.currentRoute.value.params.slug;
}

const schema:any = yup.object({
	discount: yup.number().min(0, `${t('pricelist.validation.discountMin')}`).max(100, 'pricelist.validation.discountMax').label('discount'),
});
const offersDiscountGroupsList = async() => {
	loading.value = true;
	try {
		const result = await discountGroupApi.websiteOffersIndividualDiscountUserGroup(
			options.value.page,
			options.value.itemsPerPage,
			options.value.sortBy.length > 0 ? options.value.sortBy[0].key : null,
			options.value.sortBy.length > 0 ? options.value.sortBy[0].order : null,
			userGroupSlug.value,
			search.value
		);
		if (!result.isSuccess) {
			toast.error(`${t('pricelist.toast.userGroupOfferError')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload.data;
		items.value = items.value.map((elem:IDiscountGroupWebsiteOfferItem) => {
			return {
				...elem,
				is_active: false,
			}
		});
		total.value = result.payload.total;
		currentPage.value = result.payload.current_page;
		lastPage.value = result.payload.last_page;
	} catch (e) {
		toast.error(`${t('pricelist.toast.userGroupOfferError')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const discountGroupsList = async() => {
	try {
		const result = await discountGroupApi.userDiscountGroupList();
		if (!result.isSuccess) {
			toast.error(`${t('pricelist.toast.userGroupListError')}`);
			return;
		}
		discountUserGroupList.value = result.payload;
		if (null != userGroupSlug.value) {
			userGroupName();
		}
		await getUserGroupId();
	} catch (e) {
		toast.error(`${t('pricelist.toast.userGroupListError')}`);
		return;
	}
};
discountGroupsList();
const userGroupName = () => {
	const userGroupName = discountUserGroupList.value.find((elem:IDiscountGroupList) => elem.slug === userGroupSlug.value);
	if (null != userGroupName) {
		emit('userGroupName', userGroupName.label);
	} else {
		emit('userGroupName', `${t('components.unknown')}`);
	}
};

const getUserGroupId = () => {
	const userGroup = discountUserGroupList.value.find((elem:IDiscountGroupList) => elem.slug === userGroupSlug.value);
	if (null != userGroup) {
		userGroupId.value = userGroup.id;
	} else {
		userGroupId.value = null;
	}
};
const optionsUpdate = async(val:any) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	await offersDiscountGroupsList();
};
const changeCurrencyIdToSymbol = (currencyId:number) => {
	const currency = currencies.find(elem => elem.id === currencyId);
	return null != currency ? currency.currency_symbol : '-';
};

const openChangeOfferDiscount = (offerId:number) => {
	const currentOffer = items.value.find((elem:IDiscountGroupWebsiteOfferItem) => elem.id === offerId);
	if (null != currentOffer) {
		const index = items.value.indexOf(currentOffer);
		items.value[index].is_active = true;
	}
};
const closeChangeOfferDiscount = (offerId:number) => {
	const currentOffer = items.value.find((elem:IDiscountGroupWebsiteOfferItem) => elem.id === offerId);
	if (null != currentOffer) {
		const index = items.value.indexOf(currentOffer);
		items.value[index].is_active = false;
	}
};

const editOfferDiscount = async(offerUuid:string, discount:number|string|null) => {
	loading.value = true;
	try {
		if ('' === discount) {
			discount = null;
		}
		const params:any = {};
		if (null != userGroupId.value) {
			params[userGroupId.value] = discount;
		}
		const result = await discountGroupApi.editDiscountGroups(offerUuid, params);
		if (!result.isSuccess) {
			toast.error(`${t('pricelist.toast.userGroupOfferEditError')}`);
			loading.value = false;
			return;
		}
		const currentOffer = items.value.find((elem:IDiscountGroupWebsiteOfferItem) => elem.uuid === offerUuid);
		if (null != currentOffer) {
			const index = items.value.indexOf(currentOffer);
			items.value[index].is_active = false;
		}
	} catch (e) {
		toast.error(`${t('pricelist.toast.userGroupOfferEditError')}`);
		loading.value = false;
		return;
	}
	toast.success(`${t('pricelist.toast.userGroupOfferEditSuccess')}`);
	loading.value = false;
}
const backAction = () => {
	router.push('/discount-groups');
};

watch((search), (newVal, oldVal) => {
	if (newVal !== oldVal) {
		clearTimeout(searchTimeout.value);
	}
	searchTimeout.value = setTimeout(() => {
		offersDiscountGroupsList();
	}, 700);
});
</script>

<style scoped lang="scss">
.offers-discount-groups {
	@import '@/assets/scss/vuetifyDataTable';
	
	&__heading-label {
		margin-bottom: 5px;
	}
	
	&__search-wrapper {
		display: flex;
		padding: 15px;
		
		&:deep(.m-input) {
			width: 50%;
		}
		
		&:deep(.v-field__field input) {
			min-height: 43px;
		}
		
		&:deep(.v-input__slot) {
			border-radius: 8px;
		}
	}
	
	&__url-wrapper {
		display: flex;
		align-items: center;
		gap: 10px;
	}
	
	&__link {
		margin-top: 6px;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__bold {
		font-weight: 700;
	}
	
	&__green {
		color: $green-400;
	}
	
	&__currency {
		margin-left: 12px;
	}
	
	&__status {
		width: max-content;
		background-color: $primary-400;
		color: $white-100;
		font-weight: 700;
		border-radius: 10px;
		padding: 5px 7px;
		
		&--green {
			background-color: $green-400;
		}
		
		&--red {
			background-color: $secondary-400;
		}
		
		&--yellow {
			background-color: $yellow-500;
		}
		
		&--blue {
			background-color: $blue-400;
		}
	}
	
	&__actions-wrapper {
		display: flex;
		gap: 5px;
		align-items: center;
		
		&:deep(.v-btn) {
			min-width: 20px !important;
			padding: 0 6px 0 11px !important;
			border-radius: 50%;
		}
	}
	
	&__btn-wrapper {
		margin-top: 10px;
		
		&:deep(.m-btn) {
			border-radius: 8px !important;
		}
	}
	
	&:deep(.v-field__field input) {
		min-height: 43px;
	}
	
	&__discount-wrapper {
		margin-top: 20px;
	}
	
	&:deep(.v-data-table-footer .v-field__field) {
		height: 40px;
	}
	
	&:deep(.v-data-table-footer .v-field__input) {
		font-size: 12px;
		padding: 11px 0 0 10px;
	}
	
	&:deep(.v-data-table-footer .v-input) {
		height: 40px;
	}
}
</style>
