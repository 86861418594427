<template>
	<div class="import-preview">
		<HeadingLabel
			class="import-preview__heading-label"
			icon="upload"
			width="32"
			height="29"
			:text="$t('importExport.importPreview')"
		/>
		
		<ImportPreviewTable />
	</div>
</template>

<script setup lang="ts">
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { isAdmin } from '@/hooks/UserHooks';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import ImportPreviewTable from '@/views/ImportExport/components/ImportPreviewTable.vue';

const { user } = storeToRefs(useUserStore());

if (null != user.value && !isAdmin(user.value)) {
	router.push('/dashboard');
}
</script>

<style scoped lang="scss">
.import-preview {
	&__heading-label {
		margin-bottom: 4px;
	}
}
</style>
