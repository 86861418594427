import BaseApi from '@/api/BaseApi';
import type { PublicationListInterface } from '@/models/PublicationModel';

export default class MonolithArchiveHistoryPaymentApi extends BaseApi {
	async getArchiveHistoryPayment (page: number|null, perPage: number|null, sortBy: string|null, sortDesc: string|null) {
		const apiToken = localStorage.getItem('apiToken');
		let url = `/api/payments?&api_token=${apiToken}&page=${page}&per_page=${perPage}`;

		if (sortBy) {
			url += `&order=${sortBy}`;
		} else {
			url += '&order=created_at';
		}

		if (sortDesc) {
			url += `&orderDirection=${sortDesc}`;
		} else {
			url += 'desc';
		}

		return this.get<any>({
			url: url,
			config: {
				useMonolithApi: true,
			},
		});
	}
};
