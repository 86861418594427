<template>
	<div class="cart-preview-summary">
		<div v-show="loading" class="cart-preview-summary__loading">
			<v-progress-circular size="40" width="4" indeterminate />
		</div>
		<div v-show="!loading">
			<div class="cart-preview-summary__top-wrapper">
				<div v-if="null != items && null != items.company_name">
					<div class="cart-preview-summary__top-title">{{ $t('basket.preview.company') }}</div>
					<div class="cart-preview-summary__top-item">
						<div>
							<MIcon icon="buildings" id="buildings" />
						</div>
						<div>{{ items.company_name }}</div>
					</div>
				</div>
				<div v-if="null != items && null != items.assigned_first_name && null != items.assigned_last_name" class="cart-preview-summary__name-wrapper">
					<div class="cart-preview-summary__top-title">{{ $t('basket.preview.firstLastName') }}</div>
					<div class="cart-preview-summary__top-item">
						<div>
							<MIcon icon="user-v2" />
						</div>
						<div>{{ items.assigned_first_name }} {{ items.assigned_last_name}}</div>
					</div>
				</div>
			</div>
			
			<div v-if="items?.order_type !== DEPOSIT_REQUEST && null == creator" class="cart-preview-summary__mid-wrapper">
				<div class="cart-preview-summary__top-title">{{ $t('basket.preview.discountCode') }}</div>
				<MTextField
					if="coupon-input"
					name="coupon"
					v-model="couponCode"
					label=""
					:placeholder="$t('basket.preview.enterCouponCode')"
					clearable
					:disabled="(null != items?.coupon_id && null == items?.reflink_value) || (null != user && isModerator(user) && status !== CREATE_CART_STATUS_DRAFT && status !== CREATE_CART_STATUS_RESIGNED)"
				/>
				<div v-if="null == items?.coupon_id && null == items?.reflink_value" class="cart-preview-summary__coupon-btn-wrapper">
					<MButton
						id="submit-coupon"
						green400
						normal
						:label="$t('basket.preview.submitCoupon')"
						@click="submitCoupon"
						:loading="couponLoading"
					/>
				</div>
<!--				Tutaj tylko do skasowania IS_PROMO_ACTIVE z warunku i bedzie git-->
				<div v-if="null != items?.coupon_id && null != user && !isModerator(user) && !IS_PROMO_ACTIVE" class="cart-preview-summary__coupon-value-wrapper">
					<div>{{ $t('basket.preview.couponInfo') }}</div>
					<div v-if="items?.coupon_type === PREVIEW_PERCENT && null != items?.coupon_value" class="cart-preview-summary__discount-value" id="coupon-value">-{{ items?.coupon_value * 100 }}%</div>
					<div v-if="items?.coupon_type !== PREVIEW_PERCENT && null != items?.coupon_value" class="cart-preview-summary__discount-value" id="coupon-value">-{{ items?.coupon_value.toFixed(2) }}{{ userCurrencySymbol }}</div>
				</div>
			</div>
			
			<div class="cart-preview-summary__bottom-wrapper">
				<div v-if="null != items" class="cart-preview-summary__bottom-left-wrapper">
<!--					 caly div z IsPROMOACTIVE do skasowania jakbysmy sie chcieli pozbyc tego podejscia z promocjami czyli 4 linie ponizej a z ponizszego diva wywalic warunek IS_PROMO_ACTIVE -->
					<div v-if="IS_PROMO_ACTIVE && null != items.net_before_discount && (null != items.coupon_id || null != items.reflink_value) && null != user && (isModerator(user) || (!isModerator(user) && null != creator))" class="cart-preview-summary__bottom-item-wrapper">
						<div>{{ $t('basket.preview.beforeDiscount') }}:</div>
						<div id="net-before-discount">{{ (items.net_before_discount).toFixed(2) }} {{ userCurrencySymbol }}</div>
					</div>
					<div v-if="!IS_PROMO_ACTIVE && null != items.net_before_discount && (null != items.coupon_id || null != items.reflink_value)" class="cart-preview-summary__bottom-item-wrapper">
						<div>{{ $t('basket.preview.beforeDiscount') }}:</div>
						<div id="net-before-discount">{{ (items.net_before_discount).toFixed(2) }} {{ userCurrencySymbol }}</div>
					</div>
					<div v-if="null != items.coupon_id && null != items.net_before_discount && null != items.net && null != user && (isModerator(user) || (!isModerator(user) && null != creator))" class="cart-preview-summary__bottom-item-wrapper">
						<div>{{ $t('basket.preview.discount') }}:</div>
						<div id="discount-summary">-{{ (items.net_before_discount - items.net).toFixed(2) }} {{ userCurrencySymbol }}</div>
					</div>
<!--					Ponizej caly div do skasowania a kolejny usunac tylko IS_PROMO_ACTIVE z warunku -->
					<div v-if="IS_PROMO_ACTIVE && null != items.coupon_id" class="cart-preview-summary__bottom-item-wrapper">
						<div id="coupon-summary">{{ $t('coupons.active') }}</div>
					</div>
					<div v-if="!IS_PROMO_ACTIVE && null != items.coupon_id && null != items.discount_value" class="cart-preview-summary__bottom-item-wrapper">
						<div>{{ $t('basket.preview.coupon') }}:</div>
						<div id="coupon-summary">-{{ (items.discount_value).toFixed(2) }} {{ userCurrencySymbol }}</div>
					</div>
					<div v-if="null != items.net_before_discount && null != items.reflink_value && null != items.discount_value && items.discount_value > 0" class="cart-preview-summary__bottom-item-wrapper">
						<div>{{ $t('basket.preview.partnerProgram') }}:</div>
						<div id="partner-program">-{{ (items.discount_value).toFixed(2) }} {{ userCurrencySymbol }}</div>
					</div>
					<div v-if="null != items.net" class="cart-preview-summary__bottom-item-wrapper">
						<div>{{ $t('basket.preview.net') }}</div>
						<div id="net">{{ (items.net).toFixed(2) }} {{ userCurrencySymbol }}</div>
					</div>
					<div v-if="null != items.tax" class="cart-preview-summary__bottom-item-wrapper">
						<div>{{ $t('basket.preview.tax') }}</div>
						<div id="tax">{{ (items.tax).toFixed(2) }} {{ userCurrencySymbol }}</div>
					</div>
					<div v-if="null != items.gross" class="cart-preview-summary__bottom-sum-wrapper">
						<div>{{ $t('basket.preview.sum') }}</div>
						<div id="sum">{{ (items.gross).toFixed(2) }} {{ userCurrencySymbol }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineProps, ref, defineEmits } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import CartApi from '@/api/v2/CartApi';
import { PREVIEW_PERCENT } from '@/hooks/ImportExportHooks';
import { isModerator } from '@/hooks/UserHooks';
import {
	CREATE_CART_STATUS_DRAFT,
	CREATE_CART_STATUS_RESIGNED,
} from '@/hooks/CartHooks';
import { DEPOSIT_REQUEST } from '@/hooks/OrderHooks';
import type {
	ICartCreatorDetails,
	ICartOrderDetails,
	ICouponRequest
} from '@/models/CartModel';
import MIcon from '@/components/atoms/MIcon.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MButton from '@/components/atoms/MButton.vue';
import { IS_PROMO_ACTIVE } from '@/hooks/PromoHooks';

interface IOwnProps {
	items: ICartOrderDetails | null
	loading: boolean
	status: string | null
	orderUuid: string | null
	creator: ICartCreatorDetails | null
}
interface IProps extends IOwnProps {
}

const { userCurrencySymbol, user } = storeToRefs(useUserStore());
const props = defineProps<IProps>();
const { t } = useI18n();

const emit = defineEmits(['reloadCart']);
const couponCode = ref<string | null>(null);
const couponLoading = ref<boolean>(false);
const cartApi = new CartApi();

const submitCoupon = async() => {
	couponLoading.value = true;
	if (null == couponCode.value || '' === couponCode.value) {
		toast.error(`${t('basket.preview.toast.errorSubmitCoupon')}`);
		couponLoading.value = false;
		return;
	}
	const couponRequest:ICouponRequest = {
		order_uuid: props.orderUuid,
		code: couponCode.value,
	};
	try {
		const result = await cartApi.addCoupon(couponRequest);
		if (!result.isSuccess) {
			toast.error(`${t('basket.preview.toast.errorSubmitCoupon')}`);
			couponLoading.value = false;
			return;
		}
		toast.success(`${t('basket.preview.toast.successSubmitCoupon')}`);
		emit('reloadCart');
	} catch (e) {
		toast.error(`${t('basket.preview.toast.errorSubmitCoupon')}`);
		couponLoading.value = false;
		return;
	}
};
</script>

<style scoped lang="scss">
.cart-preview-summary {
	background-color: $white-100;
	padding: 15px 20px;
	border-radius: 8px;
	
	&__loading {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 300px;
	}
	
	&__top-wrapper {
		display: flex;
		justify-content: space-between;
	}
	
	&__top-title {
		font-weight: 700;
		margin-bottom: 5px;
	}
	
	&__top-item {
		display: flex;
		align-items: center;
		gap: 10px;
		
		svg {
			color: $primary-400;
			fill: $primary-400;
		}
	}
	
	&__mid-wrapper {
		margin-top: 30px;
		
		&:deep(.v-input__details) {
			display: none;
		}
		
		&:deep(.v-text-field .v-field) {
			border-radius: 12px !important;
		}
	}
	
	&__coupon-btn-wrapper {
		width: max-content;
		margin-left: auto;
		margin-top: 12px;
		
		&:deep(.m-btn) {
			padding: 19px 18px !important;
			text-transform: uppercase;
		}
	}
	
	&__coupon-value-wrapper {
		margin-top: 18px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
	}
	
	&__discount-value {
		background-color: $green-400;
		padding: 5px 15px;
		border-radius: 8px;
		font-weight: 700;
		color: $white-100;
		height: max-content;
	}
	
	&__bottom-wrapper {
		margin-top: 27px;
		display: flex;
		gap: 20px;
	}
	
	&__bottom-left-wrapper {
		display: flex;
		flex-direction: column;
		gap: 7px;
		width: 100%;
		font-size: 16px;
		font-weight: 400;
	}
	
	&__bottom-item-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	&__bottom-sum-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;
		font-size: 18px;
		font-weight: 700;
	}
	
	&__name-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
}
</style>
