<template>
	<div class="agency-create">
		<HeadingLabel
			class="agency-create__heading-label"
			icon="group-people"
			width="32"
			height="29"
			:text="$t('agency.createAgencyDependentAccount')"
		/>
		
		<MProgressBar v-show="loading" indeterminate height="2" />
		<Form :validation-schema="schema" @submit="onSubmit" class="agency-create__form-wrapper">
			<MTextField
				id="agency-create-first-name"
				v-model="form.first_name"
				name="firstName"
				:separate-label="$t('agency.firstName')"
				:placeholder="$t('agency.firstNamePlaceholder')"
				label-text-required
			/>
			<MTextField
				id="agency-create-last-name"
				v-model="form.last_name"
				name="lastName"
				:separate-label="$t('agency.lastName')"
				:placeholder="$t('agency.lastNamePlaceholder')"
				label-text-required
			/>
			<MTextField
				id="agency-create-email"
				v-model="form.email"
				name="email"
				:separate-label="$t('agency.email')"
				:placeholder="$t('agency.emailPlaceholder')"
				label-text-required
			/>
			<MTextField
				id="agency-create-password"
				v-model="form.password"
				type="password"
				name="password"
				:separate-label="$t('agency.password')"
				:placeholder="$t('agency.passwordPlaceholder')"
				label-text-required
			/>
			<PasswordValidationToDoList :password="form.password" />
			
			<div class="agency-create__btn-wrapper">
				<MButton
					id="back-btn"
					blue400
					normal
					width100
					:label="$t('agency.back')"
					@click="back"
				/>
				<MButton
					id="submit-btn"
					red400
					normal
					width100
					:label="$t('agency.create')"
					type="submit"
				/>
			</div>
		</Form>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import router from '@/router';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import AgencyApi from '@/api/v1/AgencyApi';
import type { IAgencyForm } from '@/models/AgencyModel';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MButton from '@/components/atoms/MButton.vue';
import PasswordValidationToDoList from '@/components/molecules/PasswordValidationToDoList.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';

const agencyApi = new AgencyApi();

const { t } = useI18n();
const { user } = storeToRefs(useUserStore());

const loading = ref<boolean>(false);
const form = ref<IAgencyForm>({
	first_name: null,
	last_name: null,
	email: null,
	password: null,
	phone: null,
});
const schema:any = yup.object({
	firstName: yup.string().required(`${t('agency.validation.firstNameRequired')}`).min(3, `${t('agency.validation.minFirstName')}`).label('firstName'),
	lastName: yup.string().required(`${t('agency.validation.lastNameRequired')}`).min(3, `${t('agency.validation.minLastName')}`).label('lastName'),
	email: yup.string().required(`${t('agency.validation.emailRequired')}`).email(`${t('agency.validation.email')}`).label('email'),
	password: yup.string().required(`${t('agency.validation.passwordRequired')}`).min(8, `${t('agency.validation.passwordMin')}`).label('password').test('password', `${t('agency.validation.passwordRegex')}`,
	function (val) {
		if (val.match(/((?=.*\d)(?=.*[A-Z])(?=.*\W)\w.{6,18}\w)/)) {
			return true;
		} else {
			return false;
		}
	}),
});

const onSubmit = async() => {
	loading.value = true;
	form.value.phone = user.value?.addresses[0].phone || '';
	if (null == user.value) {
		toast.error(`${t('agency.toast.createError')}`);
		loading.value = false;
		return;
	}
	try {
		const result = await agencyApi.createAgencyAccount(form.value, user.value?.uuid);
		if (!result.isSuccess) {
			toast.error(`${t('agency.toast.createError')}`);
			loading.value = false;
			return;
		}
	} catch (e) {
		toast.error(`${t('agency.toast.createError')}`);
		loading.value = false;
		return;
	}
	await router.push('/agency');
	toast.success(`${t('agency.toast.createSuccess')}`);
	loading.value = false;
};
const back = () => {
	router.push('/agency');
};
</script>

<style scoped lang="scss">
.agency-create {
	&__heading-label {
		margin-bottom: 5px;
	}
	
	&__form-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 15px;
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
		background-color: $white-100;
	}
	
	&__btn-wrapper {
		margin-top: 20px;
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
	}
}
</style>
