import { defineStore } from 'pinia';

type TArticleStore = {
	articleLanguageId: number | null
};

export const useArticleStore = defineStore('useArticleStore', {
	state: ():TArticleStore => ({
		articleLanguageId: null,
	}),
	actions: {
		setArticleLanguageId (articleLanguageId: number) {
			this.articleLanguageId = articleLanguageId;

			// Czyszcze langaugeId poniewaz jak ktos wejdzie przez liste artykulow na feed ofertowy
			// i wroci na dashboard oraz ponownie wejdzie na feed ale w normalny sposob to wyswietli mu sie filtr z jezykiem artykulu a pownien z jezykiem kampanii
			setTimeout(() => {
				this.articleLanguageId = null;
			}, 2000);
		},
	},
});
