<template>
	<div class="google-article-table">
		<v-data-table
			:items="items"
			:headers="headers"
			:loading="loading"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:options="options"
			@update:options="optionsUpdate"
			:search="search"
			hover
			v-model="selected"
			show-select
			return-object
		>
			<template #loading>
				<div class="google-article-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #top>
				<div class="google-article-table__top-wrapper">
					<MTextField
						id="search-google-table"
						name="search"
						v-model="search"
						:label="$t('articles.search2')"
						clearable
					/>
				</div>
			</template>
			
			<template #item.author="elem">
				<div v-if="elem.item.raw['permissions'] != null && elem.item.raw.permissions.length > 0">{{ showOwner(elem.item.raw.permissions) }}</div>
			</template>
			
			<template #item.date="elem">
				<div v-if="elem.item.raw.modifiedTime !== null">{{ dateFormatWithTime(elem.item.raw.modifiedTime) }}</div>
			</template>
			
			<template #item.size="elem">
				<div v-if="elem.item.raw.size != null">{{ changeSize(elem.item.raw.size) }}</div>
				<div v-if="elem.item.raw.size === null">-</div>
			</template>
		</v-data-table>
		
		<div class="google-article-table__btn-wrapper">
			<MButton
				id="save-google-files"
				normal
				green400
				width100
				:label="$t('articles.next')"
				@click="save"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { VDataTable } from 'vuetify/labs/VDataTable';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useGoogleStore } from '@/stores/google';
import GoogleApi from '@/api/v1/GoogleApi';
import { setLastPage } from '@/hooks/TableSettingsHooks';
import { dateFormatWithTime } from '@/hooks/DataHooks';
import { toast } from 'vue3-toastify';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { GoogleFilesInterface, GoogleFilesPermissionsInterface } from '@/models/GoogleModel';
import MTextField from '@/components/atoms/MTextField.vue';
import MButton from '@/components/atoms/MButton.vue';

const googleApi = new GoogleApi();

const { t } = useI18n();
const { setGoogleFiles, setIsSelectedGoogleFiles } = useGoogleStore();

const loading = ref<boolean>(false);
const search = ref<string|undefined>(undefined);
const currentPage = ref<number>(1);
const lastPage = ref<number>(1);
const selected = ref<Array<GoogleFilesInterface>>([]);
const items = ref<Array<GoogleFilesInterface>|any>([]);
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('articles.googleFileName')}`,
		align: 'center',
		sortable: false,
		key: 'name',
	},
	{
		title: `${t('articles.googleAuthor')}`,
		align: 'center',
		sortable: false,
		key: 'author',
		width: '200px'
	},
	{
		title: `${t('articles.googleDate')}`,
		align: 'center',
		sortable: false,
		key: 'date',
		width: '220px'
	},
	{
		title: `${t('articles.googleSize')}`,
		align: 'center',
		sortable: false,
		key: 'size',
		width: '220px'
	},
]);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});

const googleFiles = async() => {
	loading.value = true;
	try {
		const result = await googleApi.getGoogleFiles();
		if (!result.isSuccess) {
			toast.error(`${t('articles.toast.errorGetGoogleArticleList')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload;
	} catch (e) {
		toast.error(`${t('articles.toast.errorGetGoogleArticleList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
googleFiles();

lastPage.value = setLastPage(options.value.itemsPerPage, items.value.length);
const optionsUpdate = (val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	currentPage.value = val.page;
	lastPage.value = setLastPage(val.itemsPerPage, items.value.length);
};

const showOwner = (permissions:Array<GoogleFilesPermissionsInterface>) => {
	const owner = permissions.find((elem:GoogleFilesPermissionsInterface) => {
		return 'owner' === elem.role;
	})
	return owner !== undefined ? owner.displayName : '-';
};
const changeSize = (size:number) => {
	if (1024 > +size) {
		return size + ' B';
	}
	if (1024 <= +size && 1048576 > +size) {
		const integer = (+size / 1024).toFixed(0);
		const rest = (+size % 1024).toString().slice(0, 2);
		const sum = integer + '.' + rest + ' KB';
		return sum;
	}
	if (1048576 <= +size && 1073741824 > +size) {
		const integer = (+size / 1048576).toFixed(0);
		const rest = (+size % 1048576).toString().slice(0, 2);
		const sum = integer + '.' + rest + ' MB';
		return sum;
	}
	if (1073741824 <= +size && 1099511531400 > +size) {
		const integer = (+size / 1073741824).toFixed(0);
		const rest = (+size % 1073741824).toString().slice(0, 2);
		const sum = integer + '.' + rest + ' GB';
		return sum;
	}
	if (1099511531400 >= +size) {
		const integer = (+size / 1099511531400).toFixed(0);
		const rest = (+size % 1099511531400).toString().slice(0, 2);
		const sum = integer + '.' + rest + ' TB';
		return sum;
	}
	return '-';
};

const save = () => {
	setGoogleFiles(selected.value);
	setIsSelectedGoogleFiles(true);
};
</script>

<style scoped lang="scss">
.google-article-table {
	@import '@/assets/scss/vuetifyDataTable';
	
	&__loading {
		opacity: 0.6;
	}
	
	&__top-wrapper {
		display: flex;
		width: 100%;
		padding: 15px 15px 0;
		height: 70px !important;
	}
	
	&__btn-wrapper {
		width: 100%;
		display: flex;
		margin-top: 10px;
		justify-content: flex-start;
	}
	
	&:deep(.v-selection-control__input > .v-icon) {
		color: $secondary-400;
	}
	
	&:deep(.v-data-table-footer .v-field__field) {
		height: 40px;
	}
	
	&:deep(.v-data-table-footer .v-field__input) {
		font-size: 12px;
		padding: 11px 0 0 10px;
	}
	
	&:deep(.v-data-table-footer .v-input) {
		height: 40px;
	}
}
</style>
