<template>
	<div class="campaigns-create-edit">
		<MProgressBar v-show="loading" indeterminate height="2" />
		<Form :validation-schema="schema" @submit="submit">
			<div class="campaigns-create-edit__form-wrapper">
				<div class="campaigns-create-edit__left-wrapper">
					<HeadingLabel
						class="campaigns-create-edit__heading-label"
						icon="clipboard"
						width="32"
						height="29"
						:text="!isEditMod ? $t('campaigns.createNewCampaign') : $t('campaigns.editCampaign')"
						modal
						modal-icon="step-plus"
						modal-icon-width="70"
						modal-icon-height="71"
						:modal-icon-color="white"
						:modal-title="!isEditMod ? $t('campaigns.createNewCampaign') : $t('campaigns.editCampaign')"
					>
						<div class="campaigns-create-edit__modal-text">
							{{ $t('campaigns.helpDescription1') }}
						</div>
						<div class="campaigns-create-edit__img">
							<img src="~@/assets/images/onboarding_step2_1.png" alt="marketin9 campaigns" />
						</div>
						<div class="campaigns-create-edit__modal-text">
							{{ $t('campaigns.helpDescription2') }}
						</div>
						<div class="campaigns-create-edit__img">
							<img src="~@/assets/images/onboarding_step2_2.png" alt="marketin9 campaigns" />
						</div>
						<div class="campaigns-create-edit__modal-text">
							{{ $t('campaigns.helpDescription3') }}
						</div>
						<div class="campaigns-create-edit__img">
							<img src="~@/assets/images/onboarding_step2_3.png" alt="marketin9 campaigns" />
						</div>
						<div class="campaigns-create-edit__modal-text">
							{{ $t('campaigns.helpDescription4') }}
						</div>
						<div class="campaigns-create-edit__img">
							<img src="~@/assets/images/onboarding_step2_4.png" alt="marketin9 campaigns" />
						</div>
						<div class="campaigns-create-edit__modal-text">
							{{ $t('campaigns.helpDescription5') }}
						</div>
						<div class="campaigns-create-edit__img">
							<img src="~@/assets/images/onboarding_step2_5.png" alt="marketin9 campaigns" />
						</div>
						<div class="campaigns-create-edit__modal-text">
							{{ $t('campaigns.helpDescription6') }}
						</div>
					</HeadingLabel>
					
					<div class="campaigns-create-edit__fields-wrapper">
						<MTextField
							id="campaign-name"
							v-model="campaignForm.name"
							name="name"
							:separate-label="$t('campaigns.name')"
							:placeholder="$t('campaigns.namePlaceholder')"
							label-text-required
						/>
						<MTextField
							id="campaign-url"
							v-model="campaignForm.url"
							name="url"
							:separate-label="$t('campaigns.url')"
							:placeholder="$t('campaigns.urlPlaceholder')"
							label-text-required
							persistent-hint
							:hint="$t('campaigns.urlHint')"
						/>
						<div class="campaigns-create-edit__suggested-input-wrapper">
							<MTextField
								id="campaign-suggested-websites"
								v-model="suggestedWebsite"
								name="suggestedWebsite"
								:separate-label="$t('campaigns.suggestedWebsite')"
								:placeholder="$t('campaigns.suggestedWebsitePlaceholder')"
								persistent-hint
								:hint="$t('campaigns.suggestedWebsiteHint')"
								@keyup.enter="addSuggestedWebsite"
							/>
							<div class="campaigns-create-edit__suggested-plus-wrapper" @click="addSuggestedWebsite">
								<MIcon icon="plus" :color="white" />
							</div>
						</div>
						<div v-if="campaignForm.keywords.length > 0" class="campaigns-create-edit__suggested-items-wrapper">
							<div v-for="url in campaignForm.keywords" :key="url">
								<div class="campaigns-create-edit__suggested-items">
									<div>{{ url }}</div>
									<MIcon icon="close" :color="white" width="11" height="11" @click="removeSuggestedWebsite(url)" />
								</div>
							</div>
						</div>
						<MAutocomplete
							id="campaign-language"
							v-model="campaignForm.language_id"
							name="campaignLanguage"
							:get-items="languages"
							item-title="name"
							item-value="id"
							:label="$t('campaigns.language')"
							:placeholder="$t('campaigns.selectLanguage')"
							variant="outlined"
							clearable
						/>
						<MVueEditor
							id="campaign-goal"
							name="campaignGoal"
							v-model="campaignForm.goal"
							label-text-required
							:separate-label="$t('campaigns.campaignGoal')"
							:placeholder="$t('campaigns.campaignGoalPlaceholder')"
						/>
					</div>
				</div>
				<div class="campaigns-create-edit__right-wrapper">
					<HeadingLabel
						class="campaigns-create-edit__heading-label"
						icon="articles"
						width="32"
						height="29"
						:text="$t('campaigns.sponsoredArticle')"
					/>
					
					<div class="campaigns-create-edit__fields-wrapper">
						<MCheckbox
							id="campaign-select-category-checkbox"
							v-model="campaignForm.is_selected_category"
							name="selectCategory"
							:label="$t('campaigns.selectCategoryInfo')"
							:color="secondary400"
						/>
						<div v-if="isCampaignCategorySelected">
							<MAutocomplete
								id="campaign-category-section"
								v-model="campaignForm.category"
								name="campaignCategory"
								:get-items="categoryList"
								item-title="slug"
								item-value="id"
								:label="$t('campaigns.selectTopics')"
								:placeholder="$t('campaigns.selectTopicsPlaceholder')"
								variant="outlined"
								clearable
								multiple
							/>
							<MCheckbox
								id="campaign-notification-checkbox"
								v-model="campaignForm.is_notification_available"
								name="notificationAvailable"
								:label="$t('campaigns.notificationCheckbox')"
								:color="secondary400"
							/>
							<div v-if="isCampaignNotificationAvailable">
								<MCheckbox
									id="campaign-same-email-checkbox"
									v-model="campaignForm.is_same_email"
									name="sameEmail"
									:label="$t('campaigns.sameEmail')"
									:color="secondary400"
								/>
								<MTextField
									v-if="!campaignForm.is_same_email"
									id="campaign-email"
									v-model="campaignForm.email"
									name="email"
									:separate-label="$t('campaigns.email')"
									:placeholder="$t('campaigns.emailPlaceholder')"
									label-text-required
								/>
								<MTextField
									id="campaign-unique-users"
									v-model="campaignForm.unique_users"
									name="uniqueUsers"
									:separate-label="$t('campaigns.uniqueUsers')"
									:placeholder="$t('campaigns.uniqueUsersPlaceholder')"
								/>
								<MTextField
									id="campaign-trust-flow"
									v-model="campaignForm.trust_flow"
									name="trustFlow"
									:separate-label="$t('campaigns.minTrustFlow')"
									:placeholder="$t('campaigns.minTrustFlowPlaceholder')"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="campaigns-create-edit__btn-wrapper">
				<MButton
					id="campaign-submit-btn"
					green400
					normal
					width150
					:label="$t('campaigns.save')"
				/>
			</div>
		</Form>
		
		<div v-if="isEditMod" class="campaigns-create-edit__footers-wrapper">
			<CampaignsFooterTable />
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useDictionaryStore } from '@/stores/dictionary';
import { toast } from 'vue3-toastify';
import CampaignApi from '@/api/v1/CampaignApi';
import WebsiteApi from '@/api/v1/WebsiteApi';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { ICampaignForm, ICreateEditCampaignForm } from '@/models/CampaignModel';
import type { ICategoriesList } from '@/models/WebsiteModel';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MVueEditor from '@/components/atoms/MVueEditor.vue';
import MButton from '@/components/atoms/MButton.vue';
import MCheckbox from '@/components/atoms/MCheckbox.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import CampaignsFooterTable from '@/views/Campaigns/components/CampaignsFooterTable.vue';
import MIcon from '@/components/atoms/MIcon.vue';

const campaignApi = new CampaignApi();
const websiteApi = new WebsiteApi();

const { white, secondary400 } = variables;
const { t } = useI18n();
const { languages, setCampaigns } = useDictionaryStore();
const { user } = storeToRefs(useUserStore());

const isEditMod = ref<boolean>(false);
const loading = ref<boolean>(false);
const btnLoading = ref<boolean>(false);
const campaignForm = ref<ICampaignForm>({
	name: null,
	url: null,
	goal: null,
	is_selected_category: false,
	category: [],
	is_notification_available: false,
	is_same_email: false,
	email: null,
	unique_users: null,
	trust_flow: null,
	language_id: null,
	keywords: [],
});
const suggestedWebsite = ref<string|null>(null);
const categoryList = ref<Array<ICategoriesList>>([]);
const isCampaignCategorySelected = ref<boolean>(false);
const isCampaignNotificationAvailable = ref<boolean>(false);
const isSameEmail = ref<boolean>(false);

const gtm = {
	pushEvent(event:string, name:string, value:any) {
		if (null != window.dataLayer) {
			window.dataLayer.push({
				event: event,
				[name]: value
			});
		}
	}
};

const schema:any = yup.object({
	name: yup.string().required(`${t('campaigns.validation.name')}`).min(3, `${t('campaigns.validation.min3')}`).label('name'),
	email: yup.string().label('email'),
	campaignGoal: yup.string().required(`${t('campaigns.validation.campaignGoal')}`).label('campaignGoal'),
	url: yup.string().required(`${t('campaigns.validation.url')}`).label('url').test('url', `${t('campaigns.validation.urlRegex')}`,
	function (val) {
		const re = /^(https?:\/\/)[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\/[a-zA-Z0-9-]+)*$/;
		if (val.match(re)) {
			return true;
		} else {
			return false;
		}
	}),
});

if (null != router.currentRoute.value.params.campaignUuid && 'create' !== router.currentRoute.value.params.campaignUuid) {
	isEditMod.value = true;
}

const userCampaigns = async() => {
	try {
		const result = await campaignApi.getCampaigns();
		if (!result.isSuccess) {
			return;
		}
		await setCampaigns(result.payload.data);
	} catch (e) {
		return;
	}
};

const submit = async() => {
	btnLoading.value = true;
	const createEditCampaignForm:ICreateEditCampaignForm = {
		name: campaignForm.value.name,
		url: campaignForm.value.url,
		language_id: null != campaignForm.value.language_id ? campaignForm.value.language_id : null,
		description: campaignForm.value.goal,
		user_id: user.value?.id,
		campaign_notification: campaignForm.value.is_selected_category ? 1 : 0,
		campaign_notification_categories_ids: campaignForm.value.category,
		send_email: campaignForm.value.is_notification_available ? 1 : 0,
		use_user_email: campaignForm.value.is_same_email ? 1 : 0,
		email: null != campaignForm.value.email && '' != campaignForm.value.email ? campaignForm.value.email : null,
		min_uu: null != campaignForm.value.unique_users ? +campaignForm.value.unique_users : null,
		min_tf: null != campaignForm.value.trust_flow ? +campaignForm.value.trust_flow : null,
		keywords: campaignForm.value.keywords,
	};
	if (!isEditMod.value) {
		try {
			const result = await campaignApi.createCampaign(createEditCampaignForm);
			if (!result.isSuccess) {
				toast.error(`${t('campaigns.toast.errorCreateCampaign')}`);
				btnLoading.value = false;
				return;
			}
			await userCampaigns();
			await router.push('/campaigns');
			toast.success(`${t('campaigns.toast.successCreateCampaign')}`);
			// Dodanie zdarzenia do Google Tag Menagera
			gtm.pushEvent('Success create campaign', 'success_campaign', 1);
		} catch (e) {
			toast.error(`${t('campaigns.toast.errorCreateCampaign')}`);
			btnLoading.value = false;
			return;
		}
	} else {
		try {
			const result = await campaignApi.editCampaign(createEditCampaignForm, router.currentRoute.value.params.campaignUuid);
			if (!result.isSuccess) {
				toast.error(`${t('campaigns.toast.errorEditCampaign')}`);
				btnLoading.value = false;
				return;
			}
			await router.push('/campaigns');
			toast.success(`${t('campaigns.toast.successEditCampaign')}`);
			// Dodanie zdarzenia do Google Tag Menagera
			gtm.pushEvent('Success edit campaign', 'success_edit_campaign', 1);
		} catch (e) {
			toast.error(`${t('campaigns.toast.errorEditCampaign')}`);
			btnLoading.value = false;
			return;
		}
	}
	btnLoading.value = false;
};
const categoriesList = async() => {
	try {
		const result = await websiteApi.categoriesList();
		if (!result.isSuccess) {
			toast.error(`${t('campaigns.toast.errorCategoriesList')}`);
			return;
		}
		categoryList.value = result.payload;
		categoryList.value.forEach(elem => {
			elem.slug = `${t(`categories.${elem.slug}`)}`;
		});
	} catch (e) {
		toast.error(`${t('campaigns.toast.errorCategoriesList')}`);
		return;
	}
};
categoriesList();
const campaignDetails = async() => {
	loading.value = true;
	try {
		const result = await campaignApi.singleCampaign(router.currentRoute.value.params.campaignUuid);
		if (!result.isSuccess) {
			toast.error(`${t('campaigns.toast.errorGetCampaignDetails')}`);
			loading.value = false;
			return;
		}
		campaignForm.value.name = result.payload.campaign.original.name;
		campaignForm.value.url = result.payload.campaign.original.url;
		campaignForm.value.goal = result.payload.campaign.original.description;
		campaignForm.value.is_selected_category = result.payload.campaign.original.campaign_notification === 1;
		campaignForm.value.is_notification_available = result.payload.campaign.original.send_email === 1;
		campaignForm.value.is_same_email = result.payload.campaign.original.use_user_email === 1;
		campaignForm.value.email = result.payload.campaign.original.email;
		campaignForm.value.language_id = result.payload.campaign.original.language_id;
		campaignForm.value.unique_users = result.payload.campaign.original.min_uu;
		campaignForm.value.trust_flow = result.payload.campaign.original.min_tf;
		campaignForm.value.keywords = result.payload.campaign.original.keywords;
		if (null != result.payload.campaign.original.categories) {
			const categoriesIds = result.payload.campaign.original.categories.split(',');
			categoriesIds.forEach((elem:any) => {
				campaignForm.value.category.push(+elem);
			});
		}
	} catch (e) {
		toast.error(`${t('campaigns.toast.errorGetCampaignDetails')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
if (isEditMod.value) {
	campaignDetails();
}

watch((campaignForm.value), (val) => {
	if (val.is_selected_category) {
		isCampaignCategorySelected.value = true;
	} else {
		isCampaignCategorySelected.value = false;
		isCampaignNotificationAvailable.value = false;
		schema.fields['email'] = yup.string().label('email');
	}
	
	if (val.is_notification_available) {
		isCampaignNotificationAvailable.value = true;
	} else {
		isCampaignNotificationAvailable.value = false;
	}
	
	if (val.is_notification_available && !val.is_same_email) {
		schema.fields['email'] = yup.string().required(`${t('campaigns.validation.emailRequired')}`).email(`${t('campaigns.validation.email')}`).label('email');
	} else {
		schema.fields['email'] = yup.string().label('email');
	}
	
	if (val.is_same_email) {
		isSameEmail.value = true;
	} else {
		isSameEmail.value = false;
	}
}, {
	deep: true,
});
watch(([isCampaignCategorySelected, isCampaignNotificationAvailable, isSameEmail]), (val) => {
	if (!val[0]) {
		campaignForm.value.category = [];
		campaignForm.value.is_notification_available = false;
		campaignForm.value.is_same_email = false;
		campaignForm.value.email = null;
		campaignForm.value.unique_users = null;
		campaignForm.value.trust_flow = null;
	}
	if (!val[1]) {
		campaignForm.value.is_same_email = false;
		campaignForm.value.email = null;
		campaignForm.value.unique_users = null;
		campaignForm.value.trust_flow = null;
	}
	if (val[2]) {
		campaignForm.value.email = null;
	}
}, {
	deep: true,
});

const addSuggestedWebsite = () => {
	if (null == suggestedWebsite.value || '' === suggestedWebsite.value) {
		return;
	}

	const keywordsWithComma = suggestedWebsite.value.split(',');
	if (keywordsWithComma.length > 1) {
		keywordsWithComma.forEach((elem:string) => {
			const keyword:string = elem.trim();
			campaignForm.value.keywords.push(keyword);
		});
	} else {
		campaignForm.value.keywords.push(suggestedWebsite.value);
	}
	suggestedWebsite.value = null;
};

const removeSuggestedWebsite = (url:string) => {
	const index = campaignForm.value.keywords.indexOf(url);
	campaignForm.value.keywords.splice(index, 1);
};
</script>

<style scoped lang="scss">
.campaigns-create-edit {
	&__heading-label {
		margin-bottom: 5px;
	}
	
	&__modal-text {
		line-height: 1.4;
		
		@include media-breakpoint-up(md) {
			padding: 0 40px;
		}
		
		@include media-breakpoint-up(lg) {
			padding: 0 50px;
		}
		
		&:last-child {
			margin: 30px 0;
			
			@include media-breakpoint-up(lg) {
				margin: 30px 0 50px;
			}
		}
	}
	
	&__img {
		width: 100%;
		padding: 10px 0;
		
		@include media-breakpoint-up(md) {
			padding: 10px 40px;
		}
		
		@include media-breakpoint-up(lg) {
			padding: 10px 50px;
		}
	}
	
	&__form-wrapper {
		display: flex;
		flex-direction: column;
		
		@include media-breakpoint-up(lg) {
			flex-direction: row;
			gap: 10px;
		}
	}
	
	&__left-wrapper,
	&__right-wrapper {
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			width: 50%;
		}
	}
	
	&__fields-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		background-color: $white-100;
		padding: 15px;
	}
	
	&__btn-wrapper {
		margin-top: 5px;
		background-color: $white-100;
		padding: 15px;
	}
	
	&__footers-wrapper {
		margin-top: 5px;
		background-color: $white-100;
	}
	
	&__suggested-input-wrapper {
		display: flex;
		align-items: center;
		gap: 10px;
	}
	
	&__suggested-plus-wrapper {
		background-color: $green-400;
		cursor: pointer;
		transition: opacity .5s;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__suggested-items-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}
	
	&__suggested-items {
		padding: 4px 10px;
		border-radius: 8px;
		background-color: $primary-300;
		color: $white-100;
		font-weight: 700;
		display: flex;
		align-items: center;
		gap: 5px;
		
		svg {
			cursor: pointer;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	&:deep(.heading-label__modal) {
		margin: 0;
	}
	
	&:deep(.m-checkbox .v-selection-control--dirty .v-selection-control__input) {
		color: $secondary-400;
	}
	
	&:deep(.m-checkbox) {
		width: 100%;
	}
}
</style>
