<template>
	<div class="footer__language">
		<div class="footer__language-list">
			<MMenu offset="0 0">
				<template v-slot:menuActivator>
					<div class="footer__language-list">
						<div class="footer__flag">
							<MFlag :country-code="countryCode" />
						</div>
						<MIcon class="footer__arrow" icon="arrow-down" :color="black" />
					</div>
				</template>
				<template v-slot:menuItems>
					<div v-for="country in countryCodes" :key="country">
						<div :id="`language-${country}`">
							<MButton transparent @click="switchLanguage(country)">
								<MFlag :country-code="country" />
							</MButton>
						</div>
					</div>
				</template>
			</MMenu>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@/stores/session';
import { mapLocaleToFlag, switchLanguage } from '@/hooks/LocaleHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import MMenu from '@/components/atoms/MMenu.vue';
import MButton from '@/components/atoms/MButton.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MFlag from '@/components/atoms/MFlag.vue';

const { locale } = storeToRefs(useSessionStore());
const { black } = variables;

const countryCode:string = mapLocaleToFlag(locale.value || 'en');
const countryCodes = ref<Array<string>>([]);
const translationFiles:any = import.meta.glob('@/i18n/*.json', { eager: true });

Object.keys(translationFiles).forEach((path:any) => {
	const langCode = path.split('/').pop().replace('.json', '');
	countryCodes.value.push(mapLocaleToFlag(langCode));
});
</script>

<style scoped lang="scss">
.footer {
	&__language {
		display: flex;
		align-items: center;
		gap: 30px;
		margin: 0 auto;
		padding-left: 30px;
		background-color: transparent !important;
		transition: opacity 0.3s, background-color 0.3s;
		
		&:deep(.v-btn__content) {
			margin-top: -4px;
		}
		
		&:deep(.m-dialog .theme--light.v-btn.v-btn--has-bg) {
			background-color: transparent;
		}
		
		@include media-breakpoint-up(sm) {
			padding-left: 0;
			margin: 0 auto 0 20px;
		}
	}
	
	&__language-list {
		display: flex;
		align-items: center;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__flag {
		margin-bottom: 5px;
		margin-right: 5px;
		
		span {
			border: 1px solid $grey-300;
			border-radius: 8px;
		}
	}
	
	&__arrow {
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
}
</style>
