<template>
	<div class="payment-transactions-table">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template v-slot:loading>
				<div class="payment-transactions-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #top>
				<div class="payment-transactions-table__search-wrapper">
					<div class="payment-transactions-table__search-title">{{ $t('paymentTransactions.orderedId') }}</div>
					<div class="payment-transactions-table__search-input-wrapper">
						<MTextField
							id="ordered-id-search"
							v-model="search"
							name="search"
							variant="outlined"
							label=""
							:placeholder="$t('components.search')"
							clearable
						/>
					</div>
				</div>
			</template>
			
			<template #item.email="elem">
				<div v-if="elem.item.raw.email != null">{{ elem.item.raw.email }}</div>
			</template>
			
			<template #item.price="elem">
				<div v-if="elem.item.raw.price != null" class="m9-data-table-align-price">{{ (elem.item.raw.price / 100).toFixed(2) }}</div>
			</template>
			
			<template #item.ordered_at="elem">
				<div v-if="elem.item.raw.ordered_at != null">{{ dateFormatWithTime(elem.item.raw.ordered_at) }}</div>
			</template>
			
			<template #item.payment_at="elem">
				<div v-if="elem.item.raw.payment_at != null">{{ dateFormatWithTime(elem.item.raw.payment_at) }}</div>
			</template>
		</v-data-table-server>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { dateFormatWithTime } from '@/hooks/DataHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import PaymentApi from '@/api/v1/PaymentApi';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { IPaymentInfoResponse } from '@/models/PaymentModel';
import MTextField from '@/components/atoms/MTextField.vue';

const paymentApi = new PaymentApi();

const { t } = useI18n();

const search = ref<string|null>(null);
const searchInterval = ref<any>(undefined);
const loading = ref<boolean>(false);
const items = ref<Array<IPaymentInfoResponse>>([]);
const total = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const headers = ref<Array<TableHeaderInterface>|any>([]);
const defaultHeaders = () => {
	const result = [
		{
			title: `${t('paymentTransactions.email')}`,
			align: 'center',
			sortable: false,
			key: 'email'
		},
		{
			title: `${t('paymentTransactions.price')}`,
			align: 'center',
			sortable: false,
			key: 'price'
		},
		{
			title: `${t('paymentTransactions.orderedAt')}`,
			align: 'center',
			sortable: false,
			key: 'ordered_at'
		},
		{
			title: `${t('paymentTransactions.paymentAt')}`,
			align: 'center',
			sortable: false,
			key: 'payment_at'
		},
	];
	
	return result;
};
headers.value = defaultHeaders();

const paymentTransferList = async() => {
	loading.value = true;
	try {
		const result = await paymentApi.paymentInfo(options.value.page, options.value.itemsPerPage, search.value);
		if (!result.isSuccess) {
			toast.error(`${t('paymentTransactions.toast.errorPaymentTransferList')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload.data;
		currentPage.value = result.payload.current_page;
		lastPage.value = result.payload.last_page;
		total.value = result.payload.total;
	} catch (e) {
		toast.error(`${t('paymentTransactions.toast.errorPaymentTransferList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	await paymentTransferList();
};

watch((search), (newVal, oldVal) => {
	if (newVal !== oldVal) {
		clearTimeout(searchInterval.value);
	}
	searchInterval.value = setTimeout(() => {
		paymentTransferList();
	}, 700);
});
</script>

<style scoped lang="scss">
.payment-transactions-table {
	@import '@/assets/scss/vuetifyDataTable';
	
	&__loading {
		opacity: 0.5;
	}
	
	&__search-wrapper {
		display: flex;
		flex-direction: column;
		padding: 10px 15px;
		width: 100%;
	}
	
	&__search-title {
		font-size: 16px;
		font-weight: 700;
		color: $primary-400;
		width: max-content;
	}
	
	&__search-input-wrapper {
		width: 100%;
		
		&:deep(.v-input__details) {
			display: none;
		}
	}
}
</style>
