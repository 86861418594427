export const mapLocaleToFlag = (locale: string) => {
    if (locale === 'en') {
		return 'gb';
    }
	return locale;
};
export const switchLanguage = (countryCode: string | null) => {
    if (null != countryCode && 'gb' !== countryCode) {
        window.location.href = `${window.location.protocol}//${window.location.host}/locale?locale=${countryCode}`;
    } else {
        window.location.href = `${window.location.protocol}//${window.location.host}/locale?locale=en`;
    }
};
