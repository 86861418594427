<template>
    <div class="m-switch">
        <v-switch
            v-model="value"
            :label="label"
            :hide-details="hideDetails"
            :class="className"
            :focused="focused"
            :inset="inset"
        ></v-switch>
    </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, computed, withDefaults } from 'vue';

interface IOwnProps {
    modelValue?: boolean
    hideDetails?: boolean
    inset?: boolean
    focused?: boolean
    label?: string
    color?: string
    className?: string
}
interface IProps extends IOwnProps {
}

const props = withDefaults(defineProps<IProps>(), {
    inset: true,
    focused: true
});

const emit = defineEmits(['update:modelValue']);

const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});
</script>

<style scoped lang="scss">
.m-switch {
	&:deep(.v-switch__thumb) {
		background-color: $white-100;
	}
	
	&:deep(.v-selection-control--dirty .v-switch__thumb) {
		background-color: $grey-300;
	}
	
	&:deep(.v-switch__track) {
		background-color: $primary-400;
		opacity: 1;
	}
}
</style>
