<template>
	<div class="create-edit-cart">
		<HeadingLabel
			class="create-edit-cart__heading-label"
			icon="basket"
			width="32"
			height="29"
			:text="null != cartUuid ? $t('basket.create.editCartTitle') : $t('basket.create.createCartTitle')"
		/>
		
		<CreateEditCartForm />
		<OrderPublication v-if="null != cartUuid && null != cartNewOrder" />
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useCartStore } from '@/stores/cart';
import router from '@/router';
import CreateEditCartForm from '@/views/Cart/components/CreateEditCartForm.vue';
import OrderPublication from '@/components/organisms/OrderCreate/OrderPublication.vue';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import { storeToRefs } from 'pinia';

const { setCartUuid } = useCartStore();
const { cartNewOrder } = storeToRefs(useCartStore());
const cartUuid = ref<string | string[] | null>(null);

if ('create' !== router.currentRoute.value.params.cartUuid && null != router.currentRoute.value.params.cartUuid) {
	cartUuid.value = router.currentRoute.value.params.cartUuid;
	setCartUuid(cartUuid.value);
}
</script>

<style scoped lang="scss">
.create-edit-cart {
	&__heading-label {
		margin-bottom: 5px;
	}
}
</style>
