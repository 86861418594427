import BaseApi from '@/apiWebsites/BaseApi';
import type { ICampaignSuggestedWebsite } from '@/models/CampaignModel';

export default class WebsitesApi extends BaseApi {
	async suggestedWebsitesList(websiteUrl:string, campaignId:number) {
		return this.get<Array<ICampaignSuggestedWebsite>>({
			url: `/api/v1/website/similar-websites?website_url=${websiteUrl}&campaign_id=${campaignId}`,
		});
	};
}
