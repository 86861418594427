<template>
	<div class="chats-table">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template v-slot:loading>
				<div>{{ $t('components.loading') }}</div>
			</template>
			
			<template #item.title="elem">
				<div v-if="elem.item.raw.source_type === MODELS_ARTICLE || elem.item.raw.source_type === MODELS_ARTICLE_REQUEST" class="m9-data-table-align-left">{{ elem.item.raw.source.subject }}</div>
				<div v-if="elem.item.raw.source_type === ORDER_PRODUCT" class="m9-data-table-align-left">{{ elem.item.raw.source.publication.offer_name }}</div>
				<div v-if="elem.item.raw.source_type === MODELS_ORDER_GROUP && null != elem.item.raw.content" class="m9-data-table-align-left">{{ elem.item.raw.content }}</div>
			</template>
			
			<template #item.language="elem">
				<div class="chats-table__country-wrapper" v-if="null != elem.item.raw.source.language_id">
					<MFlag :country-code="getLanguageSymbol(elem.item.raw.source.language_id)" />
				</div>
				<div v-if="null == elem.item.raw.source.language_id">-</div>
			</template>
			
			<template #item.type="elem">
				<div>{{ chatTypeName(elem.item.raw.source_type) }}</div>
			</template>
			
			<template v-slot:item.created_at="elem">
				<div>{{ dateFormat(elem.item.raw.created_at) }}</div>
			</template>
			
			<template #item.actions="elem">
				<div class="chats-table__btn-wrapper">
					<div v-if="elem.item.raw.deleted_at === null" class="chats-table__redirect-link" @click="readChat(elem.item.raw, elem.item.raw.source_type, elem.item.raw.type)" @auxclick="readChat(elem.item.raw, elem.item.raw.source_type, elem.item.raw.type, true)">
						<MIcon icon="chat" :color="white" />
						<div v-if="0 < elem.item.raw.private" class="chats-table__private">{{ elem.item.raw.private }}</div>
					</div>
					<MButton v-if="elem.item.raw.deleted_at === null" grey400 @click="archiveChat(elem.item.raw, elem.item.raw.source_type, elem.item.raw.type)">
						<MIcon icon="close-chat" :color="white" />
					</MButton>
					<MButton v-if="elem.item.raw.deleted_at !== null" grey400 @click="unarchiveChat(elem.item.raw, elem.item.raw.source_type, elem.item.raw.type)">
						<MIcon icon="comments" :color="white" />
					</MButton>
				</div>
			</template>
		</v-data-table-server>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { ref, defineEmits } from 'vue';
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { useDictionaryStore } from '@/stores/dictionary';
import { toast } from 'vue3-toastify';
import { dateFormat } from '@/hooks/DataHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import { isModerator } from '@/hooks/UserHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import { ORDER_PRODUCT, MODELS_ARTICLE_REQUEST, MODELS_ARTICLE, MODELS_ORDER_GROUP } from '@/hooks/ChatHooks';
import { ROUTE_DASHBOARD } from '@/hooks/RoutingHooks';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { IChatsListItem } from '@/models/ChatModel';
import MButton from '@/components/atoms/MButton.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MFlag from '@/components/atoms/MFlag.vue';
import ChatApi from '@/api/v1/ChatApi';

const chatApi = new ChatApi();

const emit = defineEmits(['itemsCounter']);
const { user } = storeToRefs(useUserStore());
const { t } = useI18n();
const { languages } = useDictionaryStore();
const { white } = variables;

const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const loading = ref<boolean>(false);
const total = ref<number>(0);
const currentPage = ref<number>(1);
const lastPage = ref<number>(1);
const items = ref<Array<IChatsListItem>|any>([]);
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('chat.title')}`,
		align: 'center',
		sortable: false,
		key: 'title'
	},
	{
		title: `${t('chat.language')}`,
		align: 'center',
		sortable: false,
		key: 'language'
	},
	{
		title: `${t('chat.type')}`,
		align: 'center',
		sortable: false,
		key: 'type'
	},
	{
		title: `${t('chat.createdAt')}`,
		align: 'center',
		sortable: false,
		key: 'created_at'
	},
	{
		title: `${t('chat.actions')}`,
		align: 'center',
		sortable: false,
		key: 'actions'
	},
]);
const isModeratorDashboard = ref<boolean>(false);
if (null != user.value && isModerator(user.value) && router.currentRoute.value.name === ROUTE_DASHBOARD) {
	isModeratorDashboard.value = true;
}

const getChatList = async() => {
	loading.value = true;
	try {
		const result = await chatApi.chatsList(options.value.page, options.value.itemsPerPage, null, isModeratorDashboard.value ? 1 : null);
		if (!result.isSuccess) {
			toast.error(`${t('articles.toast.error')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload.data;
		total.value = result.payload.total;
		currentPage.value = result.payload.current_page;
		lastPage.value = result.payload.last_page;
		if (isModeratorDashboard.value) {
			emit('itemsCounter', items.value.length);
		}
	} catch (e) {
		toast.error(`${t('articles.toast.error')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
getChatList();
const archiveChat = async(item:any, sourceType:string, type:string) => {
	loading.value = true;
	try {
		if (ORDER_PRODUCT === sourceType) {
			const result = await chatApi.archiveChat('order_product', item.source.id);
			if (!result.isSuccess) {
				toast.error(`${t('articles.toast.error')}`);
				loading.value = false;
				return;
			}
		}
		if (MODELS_ARTICLE_REQUEST === sourceType || MODELS_ARTICLE === sourceType) {
			const result = await chatApi.archiveChat('article-request', item.source.id);
			if (!result.isSuccess) {
				toast.error(`${t('articles.toast.error')}`);
				loading.value = false;
				return;
			}
		}
		if (MODELS_ORDER_GROUP === sourceType) {
			const result = await chatApi.archiveChat('order-group' === type ? 'order-group' : 'order-report', item.source.id);
			if (!result.isSuccess) {
				toast.error(`${t('articles.toast.error')}`);
				loading.value = false;
				return;
			}
		}
	} catch (e) {
		toast.error(`${t('articles.toast.error')}`);
		loading.value = false;
		return;
	}
	toast.success(`${t('chat.archived')}`);
	await getChatList();
	loading.value = false;
};
const unarchiveChat = async(item:any, sourceType:string, type:string) => {
	loading.value = true;
	try {
		if (ORDER_PRODUCT === sourceType) {
			const result = await chatApi.unarchiveChat('order_product', item.source.id);
			
			if (!result.isSuccess) {
				toast.error(`${t('articles.toast.error')}`);
				loading.value = false;
				return;
			}
		}
		if (MODELS_ARTICLE_REQUEST === sourceType || MODELS_ARTICLE === sourceType) {
			const result = await chatApi.unarchiveChat('article-request', item.source.id);
			
			if (!result.isSuccess) {
				toast.error(`${t('articles.toast.error')}`);
				loading.value = false;
				return;
			}
		}
		if (MODELS_ORDER_GROUP === sourceType) {
			const result = await chatApi.unarchiveChat('order-group' === type ? 'order-group' : 'order-report', item.source.id);
			if (!result.isSuccess) {
				toast.error(`${t('articles.toast.error')}`);
				loading.value = false;
				return;
			}
		}
	} catch (e) {
		toast.error(`${t('articles.toast.error')}`);
		loading.value = false;
		return;
	}
	toast.success(`${t('chat.unarchived')}`);
	await getChatList();
	loading.value = false;
};

const readChat = async(item:any, sourceType:string, type:string, auxClick?: boolean) => {
	loading.value = true;
	try {
		if (ORDER_PRODUCT === sourceType) {
			const result = await chatApi.readChat('order_product', item.source.id);
			if (!result.isSuccess) {
				toast.error(`${t('articles.toast.error')}`);
				loading.value = false;
				return;
			}
			if (!auxClick) {
				await router.push(hrefRedirect(item));
			} else {
				window.open(hrefRedirect(item), '_blank');
			}
		}
		if (MODELS_ARTICLE_REQUEST === sourceType || MODELS_ARTICLE === sourceType) {
			const result = await chatApi.readChat('article', item.source.id);
			if (!result.isSuccess) {
				toast.error(`${t('articles.toast.error')}`);
				loading.value = false;
				return;
			}
			if (!auxClick) {
				await router.push(hrefRedirect(item));
			} else {
				window.open(hrefRedirect(item), '_blank');
			}
		}
		if (MODELS_ORDER_GROUP === sourceType) {
			const result = await chatApi.readChat('order-group' === type ? 'order-group' : 'order-report', item.source.id);
			if (!result.isSuccess) {
				toast.error(`${t('articles.toast.error')}`);
				loading.value = false;
				return;
			}
			if (!auxClick) {
				await router.push(hrefRedirect(item));
			} else {
				window.open(hrefRedirect(item), '_blank');
			}
		}
	} catch (e) {
		toast.error(`${t('articles.toast.error')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const getLanguageSymbol = (languageId:number) => {
	const language = languages.find(elem => elem.id === languageId);
	if (null != language) {
		return language.flag;
	}
	return '-';
};

const chatTypeName = (orderType: string) => {
	switch (orderType) {
		case MODELS_ARTICLE:
			return t('components.article');
		case MODELS_ARTICLE_REQUEST:
			return t('components.article');
		case ORDER_PRODUCT:
			return t('articles.publication');
		case MODELS_ORDER_GROUP:
			return t('order.orderFlow.order');
		default:
			return t('components.unknown');
	}
};

const hrefRedirect = (item: any) => {
	if (ORDER_PRODUCT === item.source_type) {
		return `publication/order/${item.source_id}`;
	}
	if (MODELS_ORDER_GROUP === item.source_type) {
		return `order/${item.source.uuid}`;
	}
	return `publication/order/${item.source_id}`;
};

const optionsUpdate = async(val: any) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	await getChatList();
};
</script>

<style scoped lang="scss">
.chats-table {
	@import '@/assets/scss/vuetifyDataTable';
	
	&__country-wrapper {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		font-size: $size-14;
		
		.flag {
			margin-top: -4px;
			
			@include media-breakpoint-up(xl) {
				margin: -4px -0.6em -0.3em -0.7em;
			}
		}
	}
	
	&__btn-wrapper {
		display: flex;
		justify-content: center;
		
		button {
			border-radius: 50%;
			min-width: 10px !important;
			padding: 0 10px !important;
			margin-left: 10px;
		}
	}
	
	&__redirect-link {
		position: relative;
		padding: 10px 10px 4px 10px;
		border-radius: 50%;
		background-color: $grey-400;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__private {
		position: absolute;
		top: -4px;
		right: -7px;
		background-color: $secondary-400;
		color: $white-100;
		font-weight: 700;
		padding: 2px 8px;
		border-radius: 50%;
	}
	
	&:deep(.v-data-table-footer .v-field__field) {
		height: 40px;
	}
	
	&:deep(.v-data-table-footer .v-field__input) {
		font-size: 12px;
		padding: 11px 0 0 10px;
	}
	
	&:deep(.v-data-table-footer .v-input) {
		height: 40px;
	}
}
</style>
