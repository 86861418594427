<template>
	<div v-if="items" class="banner-table">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:items-length="total"
			:options="options"
			@update:options="optionsUpdate"
			hover
		>
			<template #loading>
				<div class="banner-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #item.image="elem">
				<div v-if="null != elem.item.raw.images[0]" class="banner-table__img-wrapper" :class="{'banner-table__img-wrapper--on': switcher}">
					<img :src="elem.item.raw.images[0].url" :alt="elem.item.raw.name" class="banner-table__img-inside-column" />
					<img :src="elem.item.raw.images[0].url" :alt="elem.item.raw.name" class="banner-table__img" />
				</div>
			</template>
			
			<template #item.name="elem">
				<div v-if="null != elem.item.raw.name" class="m9-data-table-align-left">
					<div>{{ elem.item.raw.name }}</div>
				</div>
			</template>
			
			<template #item.url="elem">
				<div class="m9-data-table-align-left">
					<div v-if="null != elem.item.raw.url" class="banner-table__link">
						<a :href="elem.item.raw.url" target="_blank">{{ elem.item.raw.url }}</a>
					</div>
					<div v-if="null == elem.item.raw.url">-</div>
				</div>
			</template>
			
			<template #item.screen="elem">
				<div v-if="null != elem.item.raw.placeholders.length && elem.item.raw.placeholders.length > 0">
					<div v-for="item in elem.item.raw.placeholders" :key="item.id">
						<div class="banner-table__slug-wrapper">{{ $t(`banners.${item.slug}`) }}</div>
					</div>
				</div>
			</template>

			<template #item.active="elem">
				<div v-if="null != elem.item.raw.start">
					<div v-if="checkAvailableDate(elem.item.raw.start, elem.item.raw.finish)" class="banner-table__status">{{ $t('banners.active') }}</div>
					<div v-if="!checkAvailableDate(elem.item.raw.start, elem.item.raw.finish)" class="banner-table__status banner-table__status--inactive">{{ $t('banners.inactive') }}</div>
				</div>
			</template>
			
			<template #item.finish="elem">
				<div v-if="null != elem.item.raw.finish">{{ elem.item.raw.finish }}</div>
				<div v-if="null == elem.item.raw.finish">-</div>
			</template>
			
			<template #item.actions="elem">
				<div class="banner-table__action-wrapper">
					<div @click="editBanner(elem.item.raw.uuid)" class="banner-table__action-icon-wrapper">
						<MTooltip>
							<template v-slot:title>
								<MIcon id="banner-table-edit-action" icon="pencil" :color="white" />
							</template>
							<template v-slot:content>
								<div>{{ $t('components.edit') }}</div>
							</template>
						</MTooltip>
					</div>
					<div @click="openDeleteDialog(elem.item.raw.uuid)" class="banner-table__action-icon-wrapper banner-table__action-icon-wrapper--red">
						<MTooltip>
							<template v-slot:title>
								<MIcon id="banner-table-open-delete-dialog" icon="trash" :color="white" />
							</template>
							<template v-slot:content>
								<div>{{ $t('components.delete') }}</div>
							</template>
						</MTooltip>
					</div>
				</div>
			</template>
			
			<template #footer.prepend>
				<div class="banner-table__switcher">
					<div class="banner-table__switcher-text">{{ $t('banners.on') }}</div>
					<MSwitch v-model="switcher" />
					<div class="banner-table__switcher-text">{{ $t('banners.off') }}</div>
				</div>
			</template>
		</v-data-table-server>
		
		<MDialog
			:is-close-dialog-action="isCloseDialog"
			:is-open-dialog-action="isOpenDialog"
			:content-title="$t('banners.deleteTitle')"
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<div class="banner-table__dialog-wrapper">
					<div class="banner-table__dialog-text">{{ $t('banners.deleteDescription') }}</div>
					<div class="banner-table__dialog-btn-wrapper">
						<MButton id="banner-table-close-modal-action" normal blue400 :label="$t('components.close')" @click="closeDialogWhenNoTargetPressed" />
						<MButton id="banner-table-delete-action" normal red400 :label="$t('components.delete')" @click="deleteAction" />
					</div>
				</div>
			</template>
		</MDialog>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable'
import { ref } from 'vue';
import router from '@/router';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import variables from '@/assets/scss/modules/variables.module.scss';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { BannersListItemsInterface } from '@/models/BannersModel';
import MDialog from '@/components/atoms/MDialog.vue';
import BannersApi from '@/api/v1/BannersApi';
import MSwitch from '@/components/atoms/MSwitch.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MButton from '@/components/atoms/MButton.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';

const bannersApi = new BannersApi();
const { t } = useI18n();
const { white } = variables;

const loading = ref<boolean>(false);
let firstLoadPage:boolean = true;
const items = ref<Array<BannersListItemsInterface>|null>(null);
const total = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const switcher = ref<boolean>(false);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('banners.image')}`,
		align: 'center',
		sortable: false,
		key: 'image',
		width: '200px'
	},
	{
		title: `${t('banners.name')}`,
		align: 'center',
		sortable: true,
		key: 'name',
		width: '200px'
	},
	{
		title: `${t('banners.url')}`,
		align: 'center',
		sortable: false,
		key: 'url',
		width: '100px'
	},
	{
		title: `${t('banners.screen')}`,
		align: 'center',
		sortable: false,
		key: 'screen',
		width: '200px'
	},
	{
		title: `${t('banners.active')}`,
		align: 'center',
		sortable: false,
		key: 'active',
		width: '100px'
	},
	{
		title: `${t('banners.start')}`,
		align: 'center',
		sortable: true,
		key: 'start',
		width: '150px'
	},
	{
		title: `${t('banners.finish')}`,
		align: 'center',
		sortable: true,
		key: 'finish',
		width: '150px'
	},
	{
		title: `${t('banners.actions')}`,
		align: 'center',
		sortable: false,
		key: 'actions',
		width: '150px'
	},
]);

const bannersList = async () => {
	loading.value = true;
	try {
		const result = await bannersApi.getBannerList(options.value.page, options.value.itemsPerPage, options.value.sortBy.length > 0 ? options.value.sortBy[0].key : null, options.value.sortBy.length > 0 ? options.value.sortBy[0].order : null);
		if (!result.isSuccess) {
			toast.error(`${t('banners.toast.errorGetBannerList')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload.data;
		total.value = result.payload.total;
		currentPage.value = result.payload.current_page;
		lastPage.value = result.payload.last_page;
	} catch (e) {
		toast.error(`${t('banners.toast.errorGetBannerList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
bannersList();

const checkAvailableDate = (startDate:string, finishDate:string) => {
	const now = dayjs();
	if (
		(null == finishDate && now.isAfter(startDate)) ||
		(null != finishDate && now.isAfter(startDate) && now.isBefore(finishDate))
	) {
		return true;
	}
	return false;
};

const editBanner = (uuid:string) => {
	router.push(`/banners/${uuid}`);
};

let isCloseDialog = ref<boolean>(true);
let isOpenDialog = ref<boolean>(false);
const bannerUuid = ref<string|null>(null);

const openDeleteDialog = (uuid:string) => {
	bannerUuid.value = uuid;
	isCloseDialog.value = false;
	isOpenDialog.value = true;
};

const closeDialogWhenNoTargetPressed = () => {
	isCloseDialog.value = true;
	isOpenDialog.value = false;
};

const deleteAction = async () => {
	loading.value = true;
	isCloseDialog.value = true;
	isOpenDialog.value = false;
	if (null == bannerUuid.value) {
		toast.error(`${t('banners.toast.errorDeleteBanner')}`);
		loading.value = false;
		return;
	}
	try {
		const result = await bannersApi.deleteBanner(bannerUuid.value);
		if (!result.isSuccess) {
			toast.error(`${t('banners.toast.errorDeleteBanner')}`);
			loading.value = false;
			return;
		}
	} catch (e) {
		loading.value = false;
		return;
	}
	toast.success(`${t('banners.toast.successDeleteBanner')}`);
	await bannersList();
	loading.value = false;
};
const optionsUpdate = (val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;

	if (!firstLoadPage) {
		bannersList();
	}
	firstLoadPage = false;
};
</script>

<style scoped lang="scss">
.banner-table {
	@import '@/assets/scss/vuetifyDataTable';
	
	&__loading {
		opacity: 0.6;
	}

	&__status {
		width: max-content;
		background-color: $green-400;
		font-weight: 700;
		color: $white-100;
		padding: 5px 15px;
		border-radius: 8px;
		
		&--inactive {
			background-color: $secondary-400;
		}
	}
	
	&__slug-wrapper {
		position: relative;
		margin-left: 10px;
		margin-bottom: 5px;
		
		&::before {
			content: '';
			position: absolute;
			left: -10px;
			top: 5px;
			width: 8px;
			height: 8px;
			background-color: $primary-400;
			border-radius: 50%;
		}
	}
	
	&__action-wrapper {
		display: flex;
		justify-content: center;
		gap: 10px;
	}
	
	&__action-icon-wrapper {
		background-color: $grey-400;
		border-radius: 50%;
		padding: 11px 11px 8px 12px;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		&--red {
			background-color: $secondary-400;
		}
	}
	
	&__dialog-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}
	
	&__dialog-text {
		font-size: 14px;
		font-weight: 700;
		color: $grey-300;
	}
	
	&__dialog-btn-wrapper {
		display: flex;
		gap: 10px;
	}
	
	&__img-wrapper {
		&:hover {
			.banner-table__img {
				display: block;
			}
		}
		
		&--on {
			&:hover {
				.banner-table__img {
					display: none;
				}
			}
		}
	}
	
	&__img-inside-column {
		height: 100px;
		object-fit: cover;
		width: 100%;
	}
	
	&__img {
		display: none;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 12px;
		z-index: 10;
		width: 50%;
		height: 50%;
		box-shadow: 0 0 15px $grey-300;
		object-fit: cover;
	}
	
	&__switcher {
		display: flex;
		gap: 10px;
		align-items: center;
		margin-right: auto;
	}
	
	&__switcher-text {
		margin-top: 5px;
		font-weight: 700;
	}
	
	&__link {
		width: 100px;
		overflow: hidden;
		text-overflow: ellipsis;
		
		a {
			text-decoration: none;
			color: $primary-400;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	:deep(.m-switch) {
		height: 50px;
	}
	
	:deep(.v-field__outline) {
		--v-field-border-width: 0;
	}
	
	:deep(.v-field__append-inner) {
		margin-top: -5px;
	}
	
	:deep(.v-field__overlay) {
		border-bottom: 1px solid $grey-400;
		border-radius: 0;
	}
	
	:deep(.v-select__selection-text) {
		font-size: 14px;
		margin-top: 3px;
	}
}
</style>
