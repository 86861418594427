<template>
	<div class="mailchimp-table">
		<v-data-table
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:items-per-page-options="itemsPerPageOptions"
			hover
		>
			<template #loading>
				<div>{{ $t('components.loading') }}</div>
			</template>
			
			<template #item.id="elem">
				<div v-if="elem.item.raw.id != null">{{ elem.item.raw.id }}</div>
			</template>
			
			<template #item.url="elem">
				<div v-if="elem.item.raw.url != null" class="m9-data-table-align-left">{{ elem.item.raw.url }}</div>
			</template>
			
			<template #item.events="elem">
				<div v-if="elem.item.raw.events != null" class="mailchimp-table__event-wrapper">
					<div class="mailchimp-table__list-items">
						<div>{{ $t('auth.campaign') }}:</div>
						<span class="mailchimp-table__bold">
                            <MIcon v-if="elem.item.raw.events.campaign" icon="tick" :color="green400" />
                            <MIcon v-if="!elem.item.raw.events.campaign" icon="close" width="12" height="12" :color="secondary400" />
                        </span>
					</div>
					<div class="mailchimp-table__list-items">
						<div>{{ $t('auth.cleaned') }}:</div>
						<span class="mailchimp-table__bold">
                            <MIcon v-if="elem.item.raw.events.cleaned" icon="tick" :color="green400" />
                            <MIcon v-if="!elem.item.raw.events.cleaned" icon="close" width="12" height="12" :color="secondary400" />
                        </span>
					</div>
					<div class="mailchimp-table__list-items">
						<div>{{ $t('auth.profile') }}:</div>
						<span class="mailchimp-table__bold">
                            <MIcon v-if="elem.item.raw.events.profile" icon="tick" :color="green400" />
                            <MIcon v-if="!elem.item.raw.events.profile" icon="close" width="12" height="12" :color="secondary400" />
                        </span>
					</div>
					<div class="mailchimp-table__list-items">
						<div>{{ $t('auth.subscribe') }}:</div>
						<span class="mailchimp-table__bold">
                            <MIcon v-if="elem.item.raw.events.subscribe" icon="tick" :color="green400" />
                            <MIcon v-if="!elem.item.raw.events.subscribe" icon="close" width="12" height="12" :color="secondary400" />
                        </span>
					</div>
					<div class="mailchimp-table__list-items">
						<div>{{ $t('auth.unsubscribe') }}:</div>
						<span class="mailchimp-table__bold">
                            <MIcon v-if="elem.item.raw.events.unsubscribe" icon="tick" :color="green400" />
                            <MIcon v-if="!elem.item.raw.events.unsubscribe" icon="close" width="12" height="12" :color="secondary400" />
                        </span>
					</div>
					<div class="mailchimp-table__list-items">
						<div>{{ $t('auth.upemail') }}:</div>
						<span class="mailchimp-table__bold">
                            <MIcon v-if="elem.item.raw.events.upemail" icon="tick" :color="green400" />
                            <MIcon v-if="!elem.item.raw.events.upemail" icon="close" width="12" height="12" :color="secondary400" />
                        </span>
					</div>
				</div>
			</template>
			
			<template #item.source="elem">
				<div v-if="elem.item.raw.sources != null" class="mailchimp-table__event-wrapper">
					<div class="mailchimp-table__list-items">
						<div>{{ $t('auth.admin') }}:</div>
						<span class="mailchimp-table__bold">
                            <MIcon v-if="elem.item.raw.sources.admin" icon="tick" :color="green400" />
                            <MIcon v-if="!elem.item.raw.sources.admin" icon="close" width="12" height="12" :color="secondary400" />
                        </span>
					</div>
					<div class="mailchimp-table__list-items">
						<div>{{ $t('auth.api') }}:</div>
						<span class="mailchimp-table__bold">
                            <MIcon v-if="elem.item.raw.sources.api" icon="tick" :color="green400" />
                            <MIcon v-if="!elem.item.raw.sources.api" icon="close" width="12" height="12" :color="secondary400" />
                        </span>
					</div>
					<div class="mailchimp-table__list-items">
						<div>{{ $t('auth.user') }}:</div>
						<span class="mailchimp-table__bold">
                            <MIcon v-if="elem.item.raw.sources.user" icon="tick" :color="green400" />
                            <MIcon v-if="!elem.item.raw.sources.user" icon="close" width="12" height="12" :color="secondary400" />
                        </span>
					</div>
				</div>
			</template>
			
			<template #item.actions="elem">
				<div @click="deleteWebhook(elem.item.raw.id)" class="mailchimp-table__delete-btn">
					<MIcon icon="trash" :color="white" />
				</div>
			</template>
		</v-data-table>
		
		<div class="mailchimp-table__create-btn-wrapper">
			<MButton :label="$t('auth.createWebhook')" big red400 @click="createWebhook" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { VDataTable } from 'vuetify/labs/VDataTable';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import variables from '@/assets/scss/modules/variables.module.scss';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import type { TableHeaderInterface } from '@/models/VuetifyModel';
import type { WebhookInterface } from '@/models/MailChimpModel';
import MButton from '@/components/atoms/MButton.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MailChimpApi from '@/api/v1/MailChimpApi';

const mailChimpApi = new MailChimpApi();
const { t } = useI18n();
const { white, secondary400, green400 } = variables;

const loading = ref<boolean>(false);
const items = ref<Array<WebhookInterface>|any>([]);
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('auth.id')}`,
		align: 'center',
		sortable: true,
		key: 'id'
	},
	{
		title: `${t('auth.url')}`,
		align: 'center',
		sortable: true,
		key: 'url'
	},
	{
		title: `${t('auth.events')}`,
		align: 'center',
		sortable: false,
		key: 'events'
	},
	{
		title: `${t('auth.sources')}`,
		align: 'center',
		sortable: false,
		key: 'source'
	},
	{
		title: `${t('auth.actions')}`,
		align: 'center',
		sortable: false,
		key: 'actions'
	}
]);

const getWebhookList = async() => {
	loading.value = true;
	try {
		const result = await mailChimpApi.getWebhookList();
		if (!result.isSuccess) {
			items.value = [];
			toast.error(`${t('auth.toast.wehbookGetError')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload.webhooks;
	} catch (e) {
		items.value = [];
		toast.error(`${t('auth.toast.wehbookGetError')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
getWebhookList();
const createWebhook = async() => {
	loading.value = true;
	try {
		const result = await mailChimpApi.createWebhook();
		if (!result.isSuccess) {
			toast.error(`${t('auth.toast.wehbookCreateError')}`);
			loading.value = false;
			return;
		}
	} catch (e) {
		toast.error(`${t('auth.toast.wehbookCreateError')}`);
		loading.value = false;
		return;
	}
	toast.success(`${t('auth.toast.wehbookCreateSuccess')}`);
	await getWebhookList();
	loading.value = false;
};
const deleteWebhook = async(id:string) => {
	loading.value = true;
	try {
		const result = await mailChimpApi.deleteWebhook(id);
		
		if (!result.isSuccess) {
			toast.error(`${t('auth.toast.wehbookDeleteError')}`);
			loading.value = false;
			return;
		}
	} catch (e) {
		toast.error(`${t('auth.toast.wehbookDeleteError')}`);
		loading.value = false;
		return;
	}
	toast.success(`${t('auth.toast.wehbookDeleteSuccess')}`);
	await getWebhookList();
	loading.value = false;
};
</script>

<style scoped lang="scss">
.mailchimp-table {
	@import '@/assets/scss/vuetifyDataTable';
	&__delete-btn {
		width: max-content;
		padding: 10px 12px 6px 12px;
		background-color: $primary-400;
		border-radius: 50%;
		margin: 0 auto;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__bold {
		font-weight: 700;
	}
	
	&__list-items {
		display: flex;
		align-items: center;
		gap: 5px;
		margin-top: 3px;
	}
	
	&__create-btn-wrapper {
		margin-top: 5px;
		
		&:deep(.m-btn) {
			border-radius: 12px !important;
		}
	}
	
	&__event-wrapper {
		width: max-content;
		margin: 0 auto;
	}
}
</style>
