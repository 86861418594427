<template>
	<div class="optimalize-popup-window">
		<div v-if="0 === availableOptimizations" class="optimalize-popup-window__info">{{ $t('contadu.noLimit') }}</div>
		<div class="optimalize-popup-window__title">{{ $t('contadu.keywordInfo') }}</div>
		
		<div class="optimalize-popup-window__wrapper">
			<MProgressBar indeterminate height="2" v-show="loading" />
			<MTextField
				v-model="keyword"
				name="keyword"
				:label="$t('contadu.keyword')"
			/>
			<MButton
				red400
				big
				:label="$t('contadu.optimalizeBtn')"
				@click="optimize"
				:disabled="0 === availableOptimizations"
			/>
		</div>
		
		<OptimizeTable :optimize-article-uuid="optimizeArticleUuid" />
	</div>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import { toast } from 'vue3-toastify';
import ContaduApi from '@/api/v1/ContaduApi';
import OptimizeTable from '@/views/Articles/components/OptimizeTable.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MButton from '@/components/atoms/MButton.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';

interface IOwnProps {
	availableOptimizations: number|null
	optimizeArticleUuid: string
}
interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();
const { t } = useI18n();

const contaduApi = new ContaduApi();

const keyword = ref<string|null>(null);
const loading = ref<boolean>(false);

const optimize = async() => {
	loading.value = true;
	if (null == keyword.value) {
		loading.value = false;
		return;
	}
	try {
		const result = await contaduApi.sendKeyword(props.optimizeArticleUuid, keyword.value);
		if (!result.isSuccess) {
			toast.error(`${t('contadu.toast.errorSend')}`);
			loading.value = false;
			return;
		}
		await router.push(`/article/${props.optimizeArticleUuid}/optimalize/${result.payload.result.token}`);
		toast.success(`${t('contadu.toast.successOptimalize')}`);
	} catch (e) {
		toast.error(`${t('contadu.toast.errorSend')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
</script>

<style scoped lang="scss">
.optimalize-popup-window {
	&__title {
		font-size: $size-14;
		margin-bottom: 10px;
	}
	
	&__info {
		background-color: $yellow-500;
		color: $white-100;
		padding: 10px;
		border-radius: 12px;
		font-weight: 700;
		text-align: center;
	}
	
	&__wrapper {
		display: flex;
		flex-direction: column;
		
		&:deep(.v-input__slot) {
			border-radius: 8px;
		}
		
		button {
			margin-left: auto;
			width: max-content;
		}
		
		&:deep(.m-btn) {
			padding: 25px !important;
			border-radius: 8px !important;
		}
	}
}
</style>
