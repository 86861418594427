import BaseApi from '@/api/BaseApi';
import type {
	ICartNameRequest,
	IDepositRequest,
	IDepositResponse,
	IOrderArticleRequest,
	IOrderArticleResponse,
	IOrderBasketTable,
	IOrderBasketTableItem,
	IOrderPublicationRequest,
	IOrderPublicationResponse
} from '@/models/OrderModel';

export default class OrderApi extends BaseApi {
	async orderBasketList (isPaid: number, userUuid?: string|null, page?: number, perPage?: number) {
		let url = `/api/v1/orders?is_paid=${isPaid}`;

		if (null != userUuid && '' !== userUuid) {
			url += `&user_uuid=${userUuid}`;
		}

		if (null != page) {
			url += `&page=${page}`;
		}

		if (null != perPage) {
			url += `&per_page=${perPage}`;
		}
		return this.get<IOrderBasketTable>({
			url: url,
		});
	};

	async sendDepositRequest (params: IDepositRequest) {
		return this.post<IDepositRequest, IDepositResponse>({
			url: '/api/v1/orders/deposit',
			data: params,
		});
	};

	async getOrders (campaignId:number|null, status:string) {
		return this.get<Array<IOrderBasketTableItem>>({
			url: `/api/v1/orders?campaign_id=${null != campaignId ? campaignId : 'default_campaign_uuid'}&payment_status=${status}`,
		});
	};

	async orderArticle (params: IOrderArticleRequest) {
		return this.post<IOrderArticleRequest, IOrderArticleResponse>({
			url: 'api/v1/orders/article',
			data: params,
		});
	};

	async updateOrderArticle (orderUuid: string|null, params: IOrderArticleRequest) {
		return this.put<IOrderArticleRequest, IOrderArticleResponse>({
			url: `api/v1/orders/${orderUuid}/article`,
			data: params,
		});
	};

	async createPublicationOrder (params: IOrderPublicationRequest) {
		return this.post<IOrderPublicationRequest, IOrderPublicationResponse>({
			url: 'api/v1/orders/publication',
			data: params,
			config: {
				suppressError: true,
			},
		});
	};

	async updatePublicationOrder (orderUuid: string | null, params: IOrderPublicationRequest) {
		return this.put({
			url: `api/v1/orders/${orderUuid}/publication`,
			data: params,
			config: {
				suppressError: true,
			},
		});
	};

	async orderItemsList (orderUuid:string|Array<string>) {
		return this.get<IOrderBasketTableItem>({
			url: `/api/v1/orders/${orderUuid}`,
		});
	};

	async deleteOrderItem (orderItemUuid: Array<string>|string) {
		return this.delete({
			url: `/api/v1/orders/item/${orderItemUuid}`,
		});
	};

	async verifyCoupon (basketUuid: Array<string>|string, code: string|null) {
		return this.post<any, any>({
			url: `/api/v1/orders/${basketUuid}/code/${code}`,
		});
	};

	async changeCartName (orderUuid: string | null, params: ICartNameRequest) {
		return this.patch<ICartNameRequest, boolean>({
			url: `/api/v1/orders/edit/name/${orderUuid}`,
			data: params,
		});
	};
};
