<template>
	<div class="change-password">
		<HeadingLabel
			icon="unlocked"
			width="32"
			height="29"
			:text="$t('profile.changePassword')"
		/>
		<MProgressBar v-show="loading" indeterminate height="2" />
		<Form :validation-schema="schema" @submit="onSubmit" class="change-password__wrapper">
			<MTextField
				id="input-old-password"
				v-model="changePasswordForm.old_password"
				type="password"
				name="oldPassword"
				:separate-label="$t('profile.oldPassword')"
				label-text-required
				:placeholder="$t('profile.passwordEnter')"
			/>
			<MTextField
				id="input-new-password"
				v-model="changePasswordForm.password"
				type="password"
				name="newPassword"
				:separate-label="$t('profile.newPassword')"
				label-text-required
				:placeholder="$t('profile.passwordEnter')"
			/>
			<PasswordValidationToDoList :password="changePasswordForm.password" />
			<MTextField
				id="input-new-password-confirmation"
				v-model="changePasswordForm.password_confirmation"
				type="password"
				name="newPasswordConfirmation"
				:separate-label="$t('profile.newPasswordConfirmation')"
				label-text-required
				:placeholder="$t('profile.passwordEnter')"
			/>
			<div>
				<MButton
					id="btn-change-password"
					normal
					width100
					green400
					:label="$t('profile.change')"
					type="submit"
					:loading="loading"
				/>
			</div>
		</Form>
	</div>
</template>

<script setup lang="ts">
import { ref, defineProps } from 'vue';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import UserApi from '@/api/v1/UserApi';
import type { UserInterface } from '@/models/AuthModel';
import variables from '@/assets/scss/modules/variables.module.scss';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MButton from '@/components/atoms/MButton.vue';
import PasswordValidationToDoList from '@/components/molecules/PasswordValidationToDoList.vue';

interface IOwnProps {
	currentUser: UserInterface
}

interface IProps extends IOwnProps {
}

const userApi = new UserApi();

const props = defineProps<IProps>();

const { t } = useI18n();
const { white } = variables;

const loading = ref<boolean>(false);
const changePasswordForm = ref<any>({
	old_password: null,
	password: null,
	password_confirmation: null,
});

const schema:any = yup.object({
	oldPassword: yup.string().required(`${t('profile.validation.oldPasswordRequired')}`).min(3, `${t('profile.validation.min3')}`).max(255, `${t('profile.validation.max255')}`).label('oldPassword'),
	newPassword: yup.string().required(`${t('profile.validation.newPasswordRequired')}`).min(8, `${t('profile.validation.min8')}`).max(255, `${t('profile.validation.max255')}`).label('newPasswordConfirmation').test('newPassword', `${t('profile.validation.passwordRegex')}`,
	function (val) {
		if (val.match(/((?=.*\d)(?=.*[A-Z_])(?=.*[\W_]).{8,})/)) {
			return true;
		} else {
			return false;
		}
	}),
	newPasswordConfirmation: yup.string().required(`${t('profile.validation.newPasswordConfirmationRequired')}`).min(8, `${t('profile.validation.min8')}`).max(255, `${t('profile.validation.max255')}`).label('newPasswordConfirmation').test('newPassword', `${t('profile.validation.diffPassword')}`,
	function (val) {
		if (val.match(changePasswordForm.value.password)) {
			return true;
		} else {
			return false;
		}
	}),
});

const onSubmit = async() => {
	loading.value = true;
	try {
		const result = await userApi.changePassword(changePasswordForm.value, props.currentUser.uuid);
		if (!result.isSuccess) {
			if (500 == result.payload.code) {
				toast.error(`${t('profile.toast.wrongOldPassword')}`);
			} else if (422 === result.payload.code) {
				toast.error(`${t('profile.toast.oldPasswordAndNewPasswordMustBeDiffer')}`);
			} else {
				toast.error(`${t('profile.toast.errorChangePassword')}`);
			}
			loading.value = false;
			return;
		}
		toast.success(`${t('profile.toast.successChangePassword')}`);
	} catch (e) {
		toast.error(`${t('profile.toast.errorChangePassword')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
</script>

<style scoped lang="scss">
.change-password {
	&__wrapper {
		background-color: $white-100;
		margin-top: 3px;
		padding: 25px;
	}
}
</style>
