<template>
	<div class="import-list-table">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template #loading>
				<div class="import-list-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #item.created_at="elem">
				{{ dateFormatWithTime(elem.item.raw.created_at) }}
			</template>
			
			<template #item.total="elem">
				<div>
					{{ elem.item.raw.total }}
					<v-progress-circular
						v-if="elem.item.raw.status === PROCESSING"
						indeterminate
						size="20"
					></v-progress-circular>
				</div>
			</template>
			
			<template #item.progress="elem">
				<div>
					{{ elem.item.raw.progress }}
					<v-progress-circular
						v-if="elem.item.raw.status === PROCESSING"
						indeterminate
						size="20"
					></v-progress-circular>
				</div>
			</template>
			
			<template #item.execution_time="elem">
				<div class="import-list-table__execution_time">{{ changeSecondsToTimeFormat(elem.item.raw.execution_time) }}</div>
			</template>
			
			<template #item.status="elem">
				<div v-if="elem.item.raw.status === CREATED" class="import-list-table__status import-list-table__status--pending">{{ $t('importExport.creating') }}</div>
				<div v-if="elem.item.raw.status === VALIDATED" class="import-list-table__status import-list-table__status--done">{{ $t('importExport.validated') }}</div>
				<div v-if="elem.item.raw.status === PROCESSING" class="import-list-table__status import-list-table__status--pending">
					<v-progress-circular
						indeterminate
						size="20"
					></v-progress-circular>
					{{ $t('importExport.processing') }}
				</div>
				<div v-if="elem.item.raw.status === ERROR_SYSTEM" class="import-list-table__status import-list-table__status--error">{{ $t('importExport.error') }}</div>
				<div v-if="elem.item.raw.status === REVALIDATING" class="import-list-table__status import-list-table__status--pending">{{ $t('importExport.revalidating') }}</div>
				<div v-if="elem.item.raw.status === READ_ONLY" class="import-list-table__status import-list-table__status--done">{{ $t('importExport.finished') }}</div>
				<div v-if="elem.item.raw.status === SAVED" class="import-list-table__status import-list-table__status--done">{{ $t('importExport.saved') }}</div>
				<div class="import-list-table__status-info-wrapper">
					<MProgressBar :model-value="changeValueToPercent(elem.item.raw.progress, elem.item.raw.total)" :color="elem.item.raw.status === ERROR_SYSTEM ? secondary400 : elem.item.raw.status === VALIDATED || elem.item.raw.status === SAVED || elem.item.raw.status === READ_ONLY ? green400 : yellow500" is-percent height="16" />
					<MTooltip without-btn>
						<template v-slot:title>
							<MIcon v-if="elem.item.raw.status === ERROR_SYSTEM" icon="info" width="16" height="16" />
						</template>
						<template v-slot:content>
							<div>{{ $t('importExport.error') }}: {{ elem.item.raw.message }}</div>
						</template>
					</MTooltip>
				</div>
			</template>
			
			<template #item.description="elem">
				<div v-if="null != elem.item.raw.description" class="m9-data-table-align-left">{{ elem.item.raw.description }}</div>
			</template>
			
			<template #item.actions="elem">
				<div v-if="elem.item.raw.status === VALIDATED" class="import-list-table__btn-wrapper">
					<MTooltip without-btn>
						<template v-slot:title>
							<MButton
								id="import-preview"
								icon="search"
								width="16"
								height="16"
								:color="white"
								blue400
								@click="importPreview(elem.item.raw.uuid)"
							/>
						</template>
						<template v-slot:content>
							{{ $t('importExport.preview') }}
						</template>
					</MTooltip>
				</div>
				<div v-if="elem.item.raw.status === READ_ONLY || elem.item.raw.status === PROCESSING || elem.item.raw.status === REVALIDATING || elem.item.raw.status === ERROR_SYSTEM || elem.item.raw.status === CREATED || elem.item.raw.status === SAVED" class="import-list-table__btn-wrapper">
					<MTooltip without-btn>
						<template v-slot:title>
							<MButton
								icon="search"
								width="16"
								height="16"
								:color="white"
								blue400
								@click="importReadOnly(elem.item.raw.uuid)"
							/>
						</template>
						<template v-slot:content>
							{{ $t('importExport.preview') }}
						</template>
					</MTooltip>
				</div>
			</template>
		</v-data-table-server>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { ref, defineProps, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import { ROUTE_IMPORT_LIST } from '@/hooks/RoutingHooks';
import {
	ERROR_SYSTEM,
	PROCESSING,
	CREATED,
	VALIDATED,
	REVALIDATING,
	READ_ONLY,
	SAVED,
} from '@/hooks/ImportExportHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import { toast } from 'vue3-toastify';
import ImportApi from '@/api/v1/ImportApi';
import { dateFormatWithTime } from '@/hooks/DataHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { IImportItem } from '@/models/ImportModel';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MButton from '@/components/atoms/MButton.vue';

interface IOwnProps {
	isGetImportListAction: boolean
}
interface IProps extends IOwnProps {
}

const importApi = new ImportApi();

const props = defineProps<IProps>();
const { t } = useI18n();
const { white, secondary400, yellow500, green400 } = variables;

const loading = ref<boolean>(false);
const items = ref<Array<IImportItem>|any>([]);
const total = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});

const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('importExport.createdAt')}`,
		align: 'center',
		sortable: false,
		key: 'created_at',
		width: '120px',
	},
	{
		title: `${t('importExport.processingRecords')}`,
		align: 'center',
		sortable: false,
		key: 'progress',
		width: '140px',
	},
	{
		title: `${t('importExport.totalRecords')}`,
		align: 'center',
		sortable: false,
		key: 'total',
		width: '140px',
	},
	{
		title: `${t('importExport.executionTime')}`,
		align: 'center',
		sortable: false,
		key: 'execution_time',
		width: '160px',
	},
	{
		title: `${t('importExport.status')}`,
		align: 'center',
		sortable: false,
		key: 'status',
		width: '250px'
	},
	{
		title: `${t('importExport.description')}`,
		align: 'center',
		sortable: false,
		key: 'description',
		width: '250px'
	},
	{
		title: `${t('importExport.actions')}`,
		align: 'center',
		sortable: false,
		key: 'actions',
		width: '80px',
	},
]);

const importedList = async() => {
	loading.value = true;
	
	try {
		const result = await importApi.importWebsiteOfferList(options.value.page, options.value.itemsPerPage);
		if (!result.isSuccess) {
			toast.error(`${t('importExport.toast.couldNotGetImportList')}`);
			loading.value = false;
			return;
		}
		
		items.value = result.payload.data;
		currentPage.value = result.payload.current_page;
		lastPage.value = result.payload.last_page;
		total.value = result.payload.total;
		
		if (items.value.length > 0) {
			const inProgressItem = items.value.find((elem:IImportItem) => elem.status === PROCESSING);
			
			if (null != inProgressItem && router.currentRoute.value.name === ROUTE_IMPORT_LIST) {
				setTimeout(() => {
					importedList();
				}, 15000);
			}
		}
	} catch (e) {
		toast.error(`${t('importExport.toast.couldNotGetImportList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	await importedList();
};

const changeValueToPercent = (value:number, total:number) => {
	if (0 === total) {
		return '100';
	}
	return ((value / total) * 100).toFixed(2).toString();
};

const changeSecondsToTimeFormat = (seconds:number) => {
	if (null == seconds || 0 > seconds) {
		return '0';
	}
	
	const hours = Math.floor(seconds / 3600);
	const moduloSeconds = seconds % 3600;
	const minutes = Math.floor(moduloSeconds / 60);
	const restSeconds = (moduloSeconds % 60).toFixed(0);
	
	return `${hours} ${t('importExport.hour')}, ${minutes} ${t('importExport.minute')}, ${restSeconds} ${t('importExport.second')}`;
};

const importPreview = (uuid:string) => {
	router.push(`/import/${uuid}/preview`);
};
const importReadOnly = (uuid:string) => {
	router.push(`/import/${uuid}/preview/read-only`);
};

watch((props), (val) => {
	if (val.isGetImportListAction) {
		importedList();
	}
},{
	deep: true,
});
</script>

<style scoped lang="scss">
.import-list-table {
	@import "@/assets/scss/vuetifyDataTable";
	&__loading {
		opacity: 0.5;
	}
	
	&__status {
		width: max-content;
		margin: 0 auto;
		padding: 3px 15px;
		border-radius: 8px;
		font-weight: $weight-700;
		
		&--done {
			color: $green-400;
		}
		
		&--pending {
			color: $yellow-500;
		}
		
		&--error {
			color: $secondary-400;
			padding-right: 25px;
		}
	}
	
	&__error {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}
	
	&__btn-wrapper {
		margin: 0 auto;
		width: max-content;
		
		button {
			width: max-content !important;
			min-width: max-content !important;
			margin-left: auto;
			border-radius: 50%;
			padding: 0 7px 0 10px !important;
		}
	}
	
	&__status-info-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
	}
	
	&__execution_time {
		width: max-content;
	}
}
</style>
