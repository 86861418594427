import BaseApi from '@/api/BaseApi';
import type { GetLanguagesResponseInterface } from '@/models/LanguageModel';

export default class LanguageApi extends BaseApi {
    async getLanguages () {
        return this.get<GetLanguagesResponseInterface>({
            url: '/api/v1/languages',
        });
    };
};
