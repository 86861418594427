import BaseApi from '@/api/BaseApi';
import type {
	IChangeOfferStatusForm,
	ICreateOfferForm,
	ICreateOfferResponse,
	IDuplicateOfferForm,
	IDuplicateOfferResponse,
	IDurations,
	IEditOfferForm,
	IEditOfferUserDiscountForm,
	IMassDeleteOffersRequest,
	IOfferDetails,
	IOfferList,
	ISections
} from '@/models/OfferModel';

export default class OffersApi extends BaseApi {
	async offersList (page: number|null, perPage: number|null, sortBy?: string|null, sortDirection?: string|null, search?: string|null, status?: string|null, userId?: number|null) {
		let url = `api/v1/website-offers?page=${page}&per_page=${perPage}`;

		if (null != sortBy) {
			url += `&sort=${sortBy}`;
		} else {
			url += '&sort=website_offers.updated_at';
		}

		if (null != sortDirection) {
			url += `&order=${sortDirection}`;
		} else {
			url += '&order=desc'
		}

		if (null != search) {
			url += `&search=${search}`;
		}

		if (null != status) {
			url += `&status=${status}`;
		}

		if (null != userId) {
			url += `&user_id=${userId}`;
		}

		return this.get<IOfferList>({
			url: url,
		});
	};

	async createOffer (params: ICreateOfferForm) {
		return this.post<ICreateOfferForm, ICreateOfferResponse>({
			url: 'api/v1/website-offers',
			data: params,
		});
	};

	async editOffer (params: IEditOfferForm, offerUuid: Array<string>|string|null) {
		return this.put<IEditOfferForm, boolean>({
			url: `api/v1/website-offers/${offerUuid}`,
			data: params,
		});
	};

	async offerDetails (offerUuid: Array<string>|string|null) {
		return this.get<IOfferDetails>({
			url: `api/v1/website-offers/${offerUuid}`,
		});
	};

	async changeOfferStatus (params: IChangeOfferStatusForm, offerUuid: Array<string>|string|null) {
		return this.put<IChangeOfferStatusForm, boolean>({
			url: `api/v1/website-offers/status/${offerUuid}`,
			data: params,
		});
	};

	async duplicateOffer (params: IDuplicateOfferForm, offerUuid: Array<string>|string|null) {
		return this.post<IDuplicateOfferForm, IDuplicateOfferResponse>({
			url: `api/v1/website-offers/duplicate/${offerUuid}`,
			data: params,
		});
	};

	async deleteOffer (offerUuid: string|null) {
		return this.delete({
			url: `api/v1/website-offers/${offerUuid}`,
		});
	};

	async sectionList () {
		return this.get<Array<ISections>>({
			url: 'api/v1/sections',
		});
	};

	async durationList () {
		return this.get<Array<IDurations>>({
			url: 'api/v1/durations',
		});
	};

	async editOfferUserMargin (offerUuid: Array<string>|string|null, params: IEditOfferUserDiscountForm) {
		return this.put<IEditOfferUserDiscountForm, any>({
			url: `api/v1/website-offers/${offerUuid}/discount`,
			data: params
		});
	};

	async massDeleteOffers (params:IMassDeleteOffersRequest) {
		return this.delete<IMassDeleteOffersRequest>({
			url: 'api/v1/website-offers',
			params: params,
		});
	};
};
