<template>
	<div class="order-chat">
		<div class="order-chat__title">{{ $t('order.orderFlow.chat') }}</div>
		<div class="order-chat__target-wrapper">
			<MHexagonIcon v-if="selectedTargetUser === MODERATOR_ID" small :letter="messageLetter(selectedTargetUser)" :color="white" :bgc="yellow500" />
			<MHexagonIcon v-if="selectedTargetUser !== MODERATOR_ID" small :letter="messageLetter(selectedTargetUser)" :color="white" />
			<div>{{ targetUserName(selectedTargetUser) }}</div>
<!--			<MTooltip without-btn :max-width="309.5">-->
<!--				<template v-slot:title>-->
<!--					<MIcon icon="info" class="order-chat__title-info-icon" />-->
<!--				</template>-->
<!--				<template v-slot:content>-->
<!--					<div>{{ $t('order.orderFlow.chatInfo') }}</div>-->
<!--				</template>-->
<!--			</MTooltip>-->
		</div>
		<div id="items-wrapper" class="order-chat__items-wrapper" @scroll="onScrollAction">
			<div v-for="message in props.messages" :key="message.uuid" class="order-chat__item-inline-wrapper">
				<div v-if="user?.active_role_id !== message.role_id">
					<MTooltip without-btn>
						<template v-slot:title>
							<MHexagonIcon small :letter="messageLetter(message.role_id)" :color="white" :bgc="message.role_id === MODERATOR_ID ? yellow500 : secondary400" />
						</template>
						<template v-slot:content>
							<div>{{ targetUserName(message.role_id) }}</div>
						</template>
					</MTooltip>
				</div>
				<div class="order-chat__item" :class="{'order-chat__item--own-message': user?.active_role_id === message.role_id}">
					<div class="order-chat__date">{{ dateFormatWithTime(message.created_at) }}</div>
<!--					TO DO odkomentowania jezeli wracamy do pierwotnego chatu-->
<!--					<div v-if="message.private && null == message.target_user_id" class="order-chat__message-info">{{ $t('order.orderFlow.messageToModerator') }}</div>-->
<!--					<div v-if="message.private && null != message.target_user_id" class="order-chat__message-info">{{ $t('order.orderFlow.messageFromModerator') }}</div>-->
					<div v-if="message.type === MESSAGE_TYPE_ORDER_COMMENT" class="order-chat__message-info order-chat__message-info--report">{{ user?.active_role_id !== message.role_id ? targetUserName(message.role_id) : null }} {{ user?.active_role_id !== message.role_id ? $t('order.orderFlow.messageReportComment') : $t('order.orderFlow.youReportComment') }}</div>
					<div>{{ message.content }}</div>
					<div v-if="null != message.files && message.files.length > 0">
						<div v-for="file in message.files" :key="file">
							<img :src="file" alt="marketin9" @click="openDialogWithImage(file)" class="order-chat__select-dialog-file" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-show="!isScrollDown && null != messages && messages.length > 0" @click="scrollDownChatOnClick" class="order-chat__scroll-down-icon">
			<MIcon icon="arrow-down" height="12" width="12" :color="white" />
		</div>
		<div v-if="null != user && ((currentStatus !== FINISHED_STATUS && currentStatus !== RESIGNED_STATUS) || isModerator(user))" class="order-chat__message-wrapper">
			<div>
				<MMenu offset="15 10">
					<template v-slot:menuActivator>
						<div class="order-chat__menu-activator">
							<MHexagonIcon v-if="selectedTargetUser === MODERATOR_ID" small :letter="messageLetter(selectedTargetUser)" :color="white" :bgc="yellow500" />
							<MHexagonIcon v-if="selectedTargetUser !== MODERATOR_ID" small :letter="messageLetter(selectedTargetUser)" :color="white" />
						</div>
					</template>
					<template v-slot:menuItems>
						<div>
<!--							<div v-if="(isAvailableAdvertiser && !isModerator(user)) || (isAvailableAdvertiser && isModerator(user) && checkIsTargetIdAvailable(ADVERTISER_ID))" class="order-chat__menu-item" @click="changeTargetUser(ADVERTISER_ID)">{{ $t('components.advertiser') }}</div>-->
<!--							<div v-if="(isAvailablePublisher && !isModerator(user)) || (isAvailablePublisher && isModerator(user) && checkIsTargetIdAvailable(PUBLISHER_ID))" class="order-chat__menu-item" @click="changeTargetUser(PUBLISHER_ID)">{{ $t('components.publisher') }}</div>-->
<!--							<div v-if="(isAvailableContentWriter && !isModerator(user)) || (isAvailableContentWriter && isModerator(user) && checkIsTargetIdAvailable(CONTENT_WRITER_ID))" class="order-chat__menu-item" @click="changeTargetUser(CONTENT_WRITER_ID)">{{ $t('components.contentWriter') }}</div>-->
<!--							TO powyzej i ogolnie w tym komponencie co jest wykomentowane to byla poprzednia wersja czatu z mozliwoscia pisania do innych userow zeby ja przywrocic to trzeba skasowac to co ponizej 3 linie i zostawic 3 wyzej -->
							<div v-if="(isAvailableAdvertiser && isModerator(user) && checkIsTargetIdAvailable(ADVERTISER_ID))" class="order-chat__menu-item" @click="changeTargetUser(ADVERTISER_ID)">{{ $t('components.advertiser') }}</div>
							<div v-if="(isAvailablePublisher && isModerator(user) && checkIsTargetIdAvailable(PUBLISHER_ID))" class="order-chat__menu-item" @click="changeTargetUser(PUBLISHER_ID)">{{ $t('components.publisher') }}</div>
							<div v-if="(isAvailableContentWriter && isModerator(user) && checkIsTargetIdAvailable(CONTENT_WRITER_ID))" class="order-chat__menu-item" @click="changeTargetUser(CONTENT_WRITER_ID)">{{ $t('components.contentWriter') }}</div>
							<div v-if="isAvailableModerator && !isModerator(user)" class="order-chat__menu-item" @click="changeTargetUser(MODERATOR_ID)">{{ $t('components.moderator') }}</div>
							<div v-if="isAvailableAll && isModerator(user)" class="order-chat__menu-item" @click="changeTargetUser(null)">{{ $t('components.all') }}</div>
						</div>
					</template>
				</MMenu>
			</div>
			<div class="order-chat__message-input">
				<MTextArea
					id="message-field"
					v-model="messageText"
					name="messageField"
					:placeholder="$t('order.orderFlow.typeYourMessage')"
					@keyup.enter="sendMessage"
				/>
				<MIcon
					v-show="!loading"
					id="send-message"
					@click="sendMessage"
					icon="message-arrow"
					class="order-chat__arrow"
				/>
				<div v-show="loading" class="order-chat__arrow order-chat__arrow--loader">
					<v-progress-circular :size="20" :width="3" :color="primary400" indeterminate />
				</div>
				<MIcon
					id="upload-images-message"
					@click="triggerFileInput"
					icon="images"
					class="order-chat__images"
				/>
				<input
					type="file"
					ref="fileInput"
					@change="handleFileUpload"
					accept=".png,.jpg,.jpeg"
					multiple
					style="display: none"
				/>
			</div>
		</div>
	</div>
	<div v-if="images.length > 0" class="order-chat__preview-wrapper">
		<div class="order-chat__preview-max-size">{{ $t('order.orderFlow.entirePhotoSize') }} {{ totalSizeMB.toFixed(2) }} MB / 10 MB</div>
		<div v-for="(image, index) in images" :key="index" class="order-chat__preview-item">
			<div class="order-chat__preview-item-name">{{ image.name }}</div>
			<img :src="image.src" :alt="`Marketin9 - ${index + 1}`" />
			<div class="order-chat__preview-item-size">{{ (image.size / 1024 / 1024).toFixed(2) }} MB</div>
			<MIcon icon="trash" :color="secondary400" @click="removeImage(index)" />
		</div>
	</div>
<!--	<div class="order__ask-mod-for-help-wrapper">-->
<!--		<MButton-->
<!--			v-if="null != user && !isModerator(user) && props.currentStatus !== FINISHED_STATUS && props.currentStatus !== RESIGNED_STATUS"-->
<!--			id="order-help-order"-->
<!--			:label="$t('order.orderFlow.helpModerator')"-->
<!--			normal-->
<!--			blue400-->
<!--			@click="askModeratorForHelp"-->
<!--		/>-->
<!--	</div>-->
	
	<MDialog
		width="90%"
		content-title=" "
		close-header-btn
		:is-close-dialog-action="isClosedDialog"
		:is-open-dialog-action="isOpenDialog"
		@close-dialog="closeDialogWhenNoTargetPressed"
	>
		<template v-slot:default>
			<img :src="selectedFileDialog" alt="marketin9" class="dialog-img" />
		</template>
	</MDialog>
</template>

<script setup lang="ts">
import { defineProps, ref, defineEmits, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import {
	ADVERTISER_ID,
	CONTENT_WRITER_ID,
	MODERATOR_ID,
	PUBLISHER_ID,
	isAdvertiser,
	isPublisher,
	isModerator,
	isContentWriter
} from '@/hooks/UserHooks';
import {
	ORDER_TYPE_ARTICLE,
	ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE,
	ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE,
	ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE,
	CONTENT_IN_PROGRESS_STATUS,
	CONTENT_SUBMITTED_STATUS,
	CONTENT_DISAPPROVED_STATUS,
	FINISHED_STATUS,
	RESIGNED_STATUS,
	MESSAGE_TYPE_ORDER_COMMENT
} from '@/hooks/OrderFlowHooks';
import { dateFormatWithTime } from '@/hooks/DataHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import MHexagonIcon from '@/components/atoms/MHexagonIcon.vue';
import MMenu from '@/components/atoms/MMenu.vue';
import MTextArea from '@/components/atoms/MTextArea.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MButton from '@/components/atoms/MButton.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';
import MDialog from '@/components/atoms/MDialog.vue';

interface IOwnProps {
	messages: Array<any>|null
	currentStatus: string
	orderType: string
}
interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();
const emit = defineEmits(['sendMessage']);

const { t } = useI18n();
const { user } = storeToRefs(useUserStore());
const { white, yellow500, secondary400, primary400 } = variables;

const messageText = ref<string|null>(null);
const loading = ref<boolean>(false);
const isAvailableAdvertiser = ref<boolean>(false);
const isAvailablePublisher = ref<boolean>(false);
const isAvailableContentWriter = ref<boolean>(false);
const isAvailableModerator = ref<boolean>(false);
const isAvailableAll = ref<boolean>(false);
const selectedTargetUser = ref<number|null>(null);
const isScrollDown = ref<boolean>(false);

const setAvailableUsersTarget = () => {
	isAvailableAdvertiser.value = false;
	isAvailablePublisher.value = false;
	isAvailableContentWriter.value = false;
	isAvailableModerator.value = false;
	isAvailableAll.value = false;

	
	// TO nowa wersja chatu do wywalenia jezeli przywracamy mozliwosc pisania do siebie miedzy userami teraz tylko do moda mozna pisac
	if (null != user.value && (isPublisher(user.value) || isAdvertiser(user.value) || isContentWriter(user.value))) {
		selectedTargetUser.value = MODERATOR_ID;
	}
	// TO stara poprzednia wersja czatu z mozliwoscia pisania do innych userow i tutaj jest wybierany domyslny target do kogo chcemy pisac jezeli przywracamy to do odkomentowania a linia powyzej do wywalenia
	// if (null != user.value && isAdvertiser(user.value)) {
	// 	if (props.orderType === ORDER_TYPE_ARTICLE) {
	// 		selectedTargetUser.value = CONTENT_WRITER_ID;
	// 		isAvailableContentWriter.value = true;
	// 		isAvailableModerator.value = true;
	// 	}
	// 	if (props.orderType === ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE) {
	// 		selectedTargetUser.value = PUBLISHER_ID;
	// 		isAvailablePublisher.value = true;
	// 		isAvailableModerator.value = true;
	// 	}
	// 	if (props.orderType === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE) {
	// 		if (props.currentStatus === CONTENT_IN_PROGRESS_STATUS || props.currentStatus === CONTENT_SUBMITTED_STATUS || props.currentStatus === CONTENT_DISAPPROVED_STATUS) {
	// 			selectedTargetUser.value = CONTENT_WRITER_ID;
	// 			isAvailableContentWriter.value = true;
	// 			isAvailableModerator.value = true;
	// 		} else {
	// 			selectedTargetUser.value = PUBLISHER_ID;
	// 			isAvailablePublisher.value = true;
	// 			isAvailableModerator.value = true;
	// 		}
	// 	}
	// 	if (props.orderType === ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE) {
	// 		selectedTargetUser.value = PUBLISHER_ID;
	// 		isAvailablePublisher.value = true;
	// 		isAvailableModerator.value = true;
	// 	}
	// }
	//
	// if (null != user.value && isPublisher(user.value)) {
	// 	selectedTargetUser.value = ADVERTISER_ID;
	// 	isAvailableAdvertiser.value = true;
	// 	isAvailableModerator.value = true;
	// }
	//
	// if (null != user.value && isContentWriter(user.value)) {
	// 	selectedTargetUser.value = ADVERTISER_ID;
	// 	isAvailableAdvertiser.value = true;
	// 	isAvailableModerator.value = true;
	// }
	
	if (null != user.value && isModerator(user.value)) {
		if (props.orderType === ORDER_TYPE_ARTICLE) {
			isAvailableAdvertiser.value = true;
			isAvailableContentWriter.value = true;
			isAvailableAll.value = true;
		}
		if (props.orderType === ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE) {
			isAvailableAdvertiser.value = true;
			isAvailablePublisher.value = true;
			isAvailableAll.value = true;
		}
		if (props.orderType === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE) {
			if (props.currentStatus === CONTENT_IN_PROGRESS_STATUS || props.currentStatus === CONTENT_SUBMITTED_STATUS || props.currentStatus === CONTENT_DISAPPROVED_STATUS) {
				isAvailableAdvertiser.value = true;
				isAvailableContentWriter.value = true;
				isAvailableAll.value = true;
			} else {
				isAvailableAdvertiser.value = true;
				isAvailablePublisher.value = true;
				isAvailableAll.value = true;
			}
		}
		if (props.orderType === ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE) {
			isAvailableAdvertiser.value = true;
			isAvailablePublisher.value = true;
			isAvailableAll.value = true;
		}
	}
};
setAvailableUsersTarget();

const messageLetter = (roleId: number|null) => {
	switch (roleId) {
		case 2:
			return `${t('components.advertiserLetter')}`;
		case 3:
			return `${t('components.publisherLetter')}`;
		case 4:
			return `${t('components.moderatorLetter')}`;
		case 5:
			return `${t('components.contentWriterLetter')}`;
		case null:
			return `${t('components.allLetter')}`;
		default:
			return `${t('components.advertiserLetter')}`;
	}
};
const targetUserName = (roleId: number|null) => {
	switch (roleId) {
		case 2:
			return `${t('components.advertiser')}`;
		case 3:
			return `${t('components.publisher')}`;
		case 4:
			return `${t('components.moderator')}`;
		case 5:
			return `${t('components.contentWriter')}`;
		case null:
			return `${t('components.all')}`;
		default:
			return `${t('components.advertiser')}`;
	}
};
const changeTargetUser = (roleId: number|null) => {
	selectedTargetUser.value = roleId;
};

const scrollDownChat = () => {
	setTimeout(() => {
		loading.value = false;
		const section = document.getElementById('items-wrapper');
		if (null != section) {
			section.scrollTop = section.scrollHeight - section.clientHeight;
		}
	}, 2000);
};
const scrollDownChatOnClick = () => {
	setTimeout(() => {
		loading.value = false;
		const section = document.getElementById('items-wrapper');
		if (null != section) {
			section.scrollTop = section.scrollHeight - section.clientHeight;
		}
	}, 1);
	isScrollDown.value = true;
};
const sendMessage = () => {
	loading.value = true;
	if (null == messageText.value) {
		return;
	}
	let targetUserId = null;
	if (null != props.messages && null != user.value && isModerator(user.value)) {
		const messageTargetUser = props.messages.find(elem => elem.role_id === selectedTargetUser.value);
		targetUserId = null != messageTargetUser ? messageTargetUser.user_id : null;
	}
	emit('sendMessage', messageText.value, selectedTargetUser.value === MODERATOR_ID || (null != user.value && isModerator(user.value) && (selectedTargetUser.value === ADVERTISER_ID || selectedTargetUser.value === PUBLISHER_ID || selectedTargetUser.value === CONTENT_WRITER_ID)), false, targetUserId, images.value);
	messageText.value = null;
	scrollDownChat();
};

const askModeratorForHelp = () => {
	selectedTargetUser.value = MODERATOR_ID;
};

const onScrollAction = () => {
	const scrollSection = document.getElementById('items-wrapper');
	if (scrollSection) {
		isScrollDown.value = scrollSection.scrollHeight - scrollSection.scrollTop === scrollSection.clientHeight;
	}
};

watch(([props]), (val) => {
	if (val) {
		scrollDownChatOnClick();
		setAvailableUsersTarget();
	}
}, {
	deep: true,
});

const checkIsTargetIdAvailable = (targetRoleId:number) => {
	if (null != props.messages) {
		const userMessage = props.messages.find(elem => elem.role_id === targetRoleId);
		return null != userMessage;
	}
	return false;
};

const fileInput = ref<any>(null);
const images = ref<any[]>([]);
const MAX_TOTAL_SIZE = 10 * 1024 * 1024 // 10 MB
const MAX_SINGLE_FILE_SIZE = 10 * 1024 * 1024 // 10 MB

const totalSizeMB = computed(() =>
	images.value.reduce((total, image) => total + image.size, 0) / 1024 / 1024
);
const triggerFileInput = () => {
	fileInput.value.click();
};
const handleFileUpload = (event: any) => {
	const files = Array.from(event.target.files);
	
	// Sprawdzenie całkowitego rozmiaru
	const potentialTotalSize = files.reduce((total, file: any) => total + file.size, 0) +
		images.value.reduce((total, image) => total + image.size, 0);
	
	if (potentialTotalSize > MAX_TOTAL_SIZE) {
		toast.error(`${t('order.orderFlow.toast.maxLimitOwned')}`);
		return;
	}
	
	files.forEach((file: any) => {
		// Walidacja rozszerzenia pliku
		const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
		if (!allowedTypes.includes(file.type)) {
			toast.error(`${t('order.orderFlow.toast.wrongFormat')}`);
			return;
		}
		
		// Maksymalny rozmiar pojedynczego pliku
		if (file.size > MAX_SINGLE_FILE_SIZE) {
			toast.error(`${t('order.orderFlow.toast.maxLimitForOnePhoto')}`);
			return;
		}
		
		// Utworzenie podglądu
		const reader = new FileReader();
		reader.onload = (e: any) => {
			images.value.push({
				src: e.target.result,
				file: file,
				size: file.size,
				name: file.name,
			});
		};
		reader.readAsDataURL(file);
	});
};
const removeImage = (index: number) => {
	images.value.splice(index, 1);
};

const isClosedDialog = ref<boolean>(true);
const isOpenDialog = ref<boolean>(false);
const selectedFileDialog = ref<string>('');
const closeDialogWhenNoTargetPressed = () => {
	isOpenDialog.value = false;
	isClosedDialog.value = true;
	selectedFileDialog.value = '';
};
const openDialogWithImage = (fileUrl: string) => {
	isOpenDialog.value = true;
	isClosedDialog.value = false;
	selectedFileDialog.value = fileUrl;
};
</script>

<style scoped lang="scss">
.order-chat {
	margin-top: 20px;
	background-color: $white-100;
	border-radius: 8px;
	padding: 16px;
	box-shadow: 10px 10px 40px rgba(0, 0, 0, .1);
	position: relative;
	
	&__title {
		font-size: 20px;
		font-weight: 500;
		color: $primary-400;
		margin-bottom: 16px;
	}
	
	&__items-wrapper {
		height: 326px;
		overflow-y: scroll;
		padding-right: 16px;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	
	&__target-wrapper {
		display: flex;
		align-items: center;
		gap: 15px;
	}
	
	&__item-inline-wrapper {
		display: flex;
		align-items: center;
		gap: 15px;
	}
	
	&__item {
		border: 1px solid $grey-400;
		border-radius: 8px;
		padding: 16px 4px 16px 8px;
		width: 165px;
		word-break: break-word;
		
		@include media-breakpoint-up(sm) {
			width: 70%;
		}
		
		&--own-message {
			border: 1px solid $primary-400;
			margin-left: auto;
		}
	}
	
	&__message-info {
		background-image: linear-gradient(to right, $green-400, $green-300); /* Zdefiniuj swój własny gradient */
		-webkit-background-clip: text; /* Dla przeglądarek opartych na silniku WebKit, takich jak Chrome i Safari */
		color: transparent; /* Ustawienie koloru tekstu na przezroczysty, aby był widoczny tylko gradient */
		font-weight: 700;
		text-align: center;
		margin-bottom: 5px;
		
		&--report {
			background-image: linear-gradient(to right, $secondary-400, $secondary-300); /* Zdefiniuj swój własny gradient */
		}
	}
	
	&__message-wrapper {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-top: 10px;
		
		&:deep(.v-input__details) {
			display: none;
		}
	}
	
	&__message-input {
		position: relative;
		width: 100%;
		
		&:deep(.v-field__input) {
			padding: 12px 32px 12px 12px !important;
			min-height: 100px;
			max-height: 300px;
			height: 100px;
		}
	}
	
	&__arrow {
		position: absolute;
		right: 10px;
		top: 15px;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		&--loader {
			top: 12px;
		}
	}
	
	&__images {
		position: absolute;
		right: 10px;
		top: 40px;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__menu-activator {
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__menu-item {
		padding: 12px;
		cursor: pointer;
		transition: background-color 0.3s;
		
		&:hover {
			background-color: $grey-200;
		}
	}
	
	&__scroll-down-icon {
		position: absolute;
		z-index: 5;
		top: 410px;
		left: 50%;
		transform: translate(0, -50%);
		background-color: $blue-400;
		padding: 9px 9px 2px;
		border-radius: 50%;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__title-info-icon {
		margin-top: 4px;
	}
	
	&__date {
		text-align: center;
	}
	
	::-webkit-scrollbar {
		width: 6px;
	}
	
	::-webkit-scrollbar-thumb {
		background-color: $primary-400;
		border-radius: 6px;
	}
	
	::-webkit-scrollbar-track {
		background-color: $grey-200;
	}
	
	&__preview-wrapper {
		position: relative;
		display: grid;
		grid-auto-flow: column;
		grid-auto-columns: minmax(115px, 0fr);
		gap: 5px;
		overflow-x: auto;
		overscroll-behavior-x: contain;
		scroll-snap-type: x mandatory;
		padding: 30px 5px 5px;
		background-color: rgba($white-100, 0.35);
		border: 1px solid $white-100;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		margin-top: -25px;
		
		&::-webkit-scrollbar {
			height: 3px;
		}
		
		&::-webkit-scrollbar-thumb {
			background-color: $primary-400;
			border-radius: 1px;
		}
		
		&::-webkit-scrollbar-track {
			background-color: $grey-200;
		}
	}
	
	&__preview-max-size {
		position: absolute;
		top: 3px;
		left: 8px;
		color: $primary-400;
	}
	
	&__preview-item {
		scroll-snap-align: center;
		position: relative;
		background: rgba($white-100, 0.8);
		padding: 5px;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		gap: 0;
		width: 115px;
		
		img {
			object-fit: cover;
			width: 100%;
			height: 70px;
			border-radius: 8px;
		}
		
		svg {
			position: absolute;
			top: -5px;
			right: -4px;
			cursor: pointer;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	&__preview-item-size {
		font-size: 11px;
		text-align: center;
		font-weight: 700;
	}
	
	&__preview-item-name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 93px;
	}
	
	&__select-dialog-file {
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.8;
		}
	}
}

.order__ask-mod-for-help-wrapper {
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	margin-top: 64px;
}

.dialog-img {
	width: 100%;
	object-fit: cover;
}
</style>
