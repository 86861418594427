<template>
	<BeforeLoginLayout>
		<template v-slot:default>
			<div class="partners partners__wrapper">
				<img src="~@/assets/images/logo-reverse.png" alt="marketing logo" class="partners__logo">
				<div class="partners__language-wrapper">
					<div class="footer__language-label">{{ $t('auth.chooseLanguage') }}</div>
					<div class="partners__language-list">
						<MMenu offset="0 0">
							<template v-slot:menuActivator>
								<div class="partners__language-list">
									<div class="partners__flag">
										<MFlag :country-code="localeLanguage" />
									</div>
									<MIcon class="partners__arrow" icon="arrow-down" />
								</div>
							</template>
							<template v-slot:menuItems>
								<MButton @click="switchLanguage('pl')">
									<MFlag country-code="pl" />
								</MButton>
								<MButton @click="switchLanguage('en')">
									<MFlag country-code="gb" />
								</MButton>
							</template>
						</MMenu>
					</div>
				</div>
				<div class="partners__description">{{ $t('components.partnersFirsDescription') }}</div>
				<div class="partners__partners">{{ $t('components.ourPartners') }}</div>
				<a href="https://contadu.com/" target="_blank" class="partners__contadu-wrapper">
					<img src="~@/assets/images/contadu.png" alt="contadu logo">
				</a>
				<div class="partners__description">{{ $t('components.partnersSecondDescription') }}</div>
				<div class="partners__btn-wrapper">
					<MButton
						id="go-back"
						normal
						blue400
						:label="$t('components.back')"
						@click="goBack"
					/>
				</div>
			</div>
		</template>
	</BeforeLoginLayout>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@/stores/session';
import router from '@/router';
import { switchLanguage } from '@/hooks/LocaleHooks';
import BeforeLoginLayout from '@/views/Layout/components/BeforeLoginLayout.vue';
import MButton from '@/components/atoms/MButton.vue';
import MMenu from '@/components/atoms/MMenu.vue';
import MFlag from '@/components/atoms/MFlag.vue';
import MIcon from '@/components/atoms/MIcon.vue';

const { locale } = storeToRefs(useSessionStore());

const localeLanguage = ref<string>('');
if ('us' === locale.value) {
	localeLanguage.value = 'en';
} else if (locale.value != null) {
	localeLanguage.value = locale.value;
} else {
	localeLanguage.value = 'pl';
}

const goBack = () => {
	router.push('/');
};
</script>

<style scoped lang="scss">
.partners {
	&__logo {
		max-width: 224px;
		display: flex;
		margin: 20px auto;
	}
	
	&__description {
		font-size: 14px;
		line-height: 1.5;
		color: $primary-400;
		margin-bottom: 20px;
	}
	
	&__partners {
		font-size: 18px;
		line-height: 1.5;
		color: $primary-400;
		margin: 20px 0;
	}
	
	&__contadu-wrapper {
		img {
			width: 280px;
		}
	}
	
	&__language-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	
	&__language-label {
		margin-right: -30px;
	}
	
	&__language-list {
		display: flex;
		align-items: center;
	}
	
	&__flag {
		margin-bottom: 5px;
		margin-right: 5px;
	}
	
	&__arrow {
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__btn-wrapper {
		width: max-content;
		margin: 0 auto;
	}
}
</style>
