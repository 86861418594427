<template>
  <component :is="icon" :fill="color" :width="width" :height="height" class="fill-current" />
</template>

<script setup lang="ts">
import { defineProps, withDefaults } from 'vue';
import addDollar from '../../assets/icons/icon-add-dolar.svg';
import address from '../../assets/icons/icon-address.svg';
import approve from '../../assets/icons/icon-approve.svg';
import archive from '../../assets/icons/icon-archive.svg';
import arrowCircle from '../../assets/icons/icon-arrow-circle.svg';
import arrowDown from '../../assets/icons/icon-arrow-down.svg';
import arrowOut from '../../assets/icons/icon-arrow-out.svg';
import arrowTrendUp from '../../assets/icons/icon-arrow-trend-up.svg';
import articles from '../../assets/icons/icon-articles.svg';
import autopayLogo from '../../assets/icons/icon-autopay-logo.svg';
import banner from '../../assets/icons/icon-banner.svg';
import barcode from '../../assets/icons/icon-barcode.svg';
import basket from '../../assets/icons/icon-basket.svg';
import basketCube from '../../assets/icons/icon-basket-cube.svg';
import bell from '../../assets/icons/icon-bell.svg';
import boxOpen from '../../assets/icons/icon-box-open.svg';
import buildings from '../../assets/icons/icon-buildings.svg';
import bulb from '../../assets/icons/icon-bulb.svg';
import cart from '../../assets/icons/icon-cart.svg';
import chat from '../../assets/icons/icon-chat.svg';
import clipboard from '../../assets/icons/icon-clipboard.svg';
import close from '../../assets/icons/icon-close.svg';
import closeBold from '../../assets/icons/icon-close-bold.svg';
import closeChat from '../../assets/icons/icon-close-chat.svg';
import coin50 from '../../assets/icons/icon-coin50.svg';
import comments from '../../assets/icons/icon-comments.svg';
import compas from '../../assets/icons/icon-compas.svg';
import copy from '../../assets/icons/icon-copy.svg';
import crab from '../../assets/icons/icon-crab.svg';
import creditCard from '../../assets/icons/icon-credit-card.svg';
import creditCardSolid from '../../assets/icons/icon-credit-card-solid.svg';
import csvFile from '../../assets/icons/icon-csv-file.svg';
import cubes from '../../assets/icons/icon-cubes.svg';
import dashboard from '../../assets/icons/icon-dashboard.svg';
import database from '../../assets/icons/icon-database.svg';
import diagram from '../../assets/icons/icon-diagram.svg';
import discount from '../../assets/icons/icon-discount.svg';
import discountPercent from '../../assets/icons/icon-discount-percent.svg';
import donut from '../../assets/icons/icon-donut.svg';
import dots from '../../assets/icons/icon-dots.svg';
import download from '../../assets/icons/icon-download.svg';
import drop from '../../assets/icons/icon-drop.svg';
import edit from '../../assets/icons/icon-edit.svg';
import editCube from '../../assets/icons/icon-edit-cube.svg';
import external from '../../assets/icons/icon-external.svg';
import eye from '../../assets/icons/icon-eye.svg';
import facebook from '../../assets/icons/icon-facebook.svg';
import fileCode from '../../assets/icons/icon-file-code.svg';
import fileExcel from '../../assets/icons/icon-file-excel.svg';
import gift from '../../assets/icons/icon-gift.svg';
import google from '../../assets/icons/icon-google.svg';
import googleColor from '../../assets/icons/icon-google-color.svg';
import googleEmail from '../../assets/icons/icon-google-email.svg';
import greenArrowUp from '../../assets/icons/icon-green-arrow-up.svg';
import groupPeople from '../../assets/icons/icon-group-people.svg';
import hands from '../../assets/icons/icon-hands.svg';
import heart from '../../assets/icons/icon-heart.svg';
import hexagon from '../../assets/icons/icon-hexagon.svg';
import historyPayment from '../../assets/icons/icon-history-payment.svg';
import info from '../../assets/icons/icon-info.svg';
import infoLetter from '../../assets/icons/icon-info-letter.svg';
import invoice from '../../assets/icons/icon-invoice.svg';
import language from '../../assets/icons/icon-language.svg';
import letterA from '../../assets/icons/icon-letter-a.svg';
import link from '../../assets/icons/icon-link.svg';
import list from '../../assets/icons/icon-list.svg';
import lockCube from '../../assets/icons/icon-lock-cube.svg';
import logout from '../../assets/icons/icon-logout.svg';
import logoutV2 from '../../assets/icons/icon-logout-v2.svg';
import mailchimp from '../../assets/icons/icon-mailchimp.svg';
import menu from '../../assets/icons/icon-menu.svg';
import messageArrow from '../../assets/icons/icon-message-arrow.svg';
import minus from '../../assets/icons/icon-minus.svg';
import money from '../../assets/icons/icon-money.svg';
import moneyBag from '../../assets/icons/icon-money-bag.svg';
import moneyTransfer from '../../assets/icons/icon-money-transfer.svg';
import notification from '../../assets/icons/icon-notification.svg';
import ok from '../../assets/icons/icon-ok.svg';
import page from '../../assets/icons/icon-page.svg';
import paragraph from '../../assets/icons/icon-paragraph.svg';
import paypal from '../../assets/icons/icon-paypal.svg';
import penFancy from '../../assets/icons/icon-pen-fancy.svg';
import pencil from '../../assets/icons/icon-pencil.svg';
import peoplePulling from '../../assets/icons/icon-people-pulling.svg';
import peopleGroupV2 from '../../assets/icons/icon-people-group-v2.svg';
import plug from '../../assets/icons/icon-plug.svg';
import plus from '../../assets/icons/icon-plus.svg';
import plusCube from '../../assets/icons/icon-plus-cube.svg';
import poundCube from '../../assets/icons/icon-pound-cube.svg';
import prevCube from '../../assets/icons/icon-prev-cube.svg';
import print from '../../assets/icons/icon-print.svg';
import profit from '../../assets/icons/icon-profit.svg';
import projects from '../../assets/icons/icon-projects.svg';
import publication from '../../assets/icons/icon-publication.svg';
import question from '../../assets/icons/icon-question.svg';
import redArrowDown from '../../assets/icons/icon-red-arrow-down.svg';
import refresh from '../../assets/icons/icon-refresh.svg';
import sad from '../../assets/icons/icon-sad.svg';
import search from '../../assets/icons/icon-search.svg';
import settings from '../../assets/icons/icon-settings.svg';
import settingsDouble from '../../assets/icons/icon-settings-double.svg';
import signOutAltSolid from '../../assets/icons/icon-sign-out-alt-solid.svg';
import solidArrowDown from '../../assets/icons/icon-solid-arrow-down.svg';
import spinach from '../../assets/icons/icon-spinach.svg';
import stats from '../../assets/icons/icon-stats.svg';
import stepPlus from '../../assets/icons/icon-step-plus.svg';
import table from '../../assets/icons/icon-table.svg';
import tick from '../../assets/icons/icon-tick.svg';
import tickCube from '../../assets/icons/icon-tick-cube.svg';
import ticket from '../../assets/icons/icon-ticket.svg';
import trash from '../../assets/icons/icon-trash.svg';
import twitter from '../../assets/icons/icon-twitter.svg';
import university from '../../assets/icons/icon-university.svg';
import unlocked from '../../assets/icons/icon-unlocked.svg';
import upload from '../../assets/icons/icon-upload.svg';
import user from '../../assets/icons/icon-user.svg';
import userV2 from '../../assets/icons/icon-user-v2.svg';
import userCube from '../../assets/icons/icon-user-cube.svg';
import users from '../../assets/icons/icon-users.svg';
import websites from '../../assets/icons/icon-websites.svg';
import websitesCube from '../../assets/icons/icon-websites-cube.svg';
import zzz from '../../assets/icons/icon-zzz.svg';
import flower from '../../assets/icons/icon-flower.svg';
import images from '../../assets/icons/icon-images.svg';

export type TIcon =
	'add-dollar' |
	'address' |
	'approve' |
	'archive' |
	'arrow-circle' |
	'arrow-down' |
	'arrow-out' |
	'arrow-trend-up' |
	'articles' |
	'autopay-logo' |
	'banner' |
	'barcode' |
	'basket' |
	'basket-cube' |
	'bell' |
	'box-open' |
	'buildings' |
	'bulb' |
	'cart' |
	'chat' |
	'clipboard' |
	'close' |
	'close-bold' |
	'close-chat' |
	'coin50' |
	'comments' |
	'compas' |
	'copy' |
	'crab' |
	'credit-card' |
	'credit-card-solid' |
	'csv-file' |
	'cubes' |
	'dashboard' |
	'database' |
	'diagram' |
	'discount' |
	'discount-percent' |
	'donut' |
	'dots' |
	'download' |
	'drop' |
	'edit' |
	'edit-cube' |
	'external' |
	'eye' |
	'facebook' |
	'file-code' |
	'file-excel' |
	'gift' |
	'google' |
	'google-color' |
	'google-email' |
	'green-arrow-up' |
	'group-people' |
	'hands' |
	'heart' |
	'hexagon' |
	'history-payment' |
	'info' |
	'info-letter' |
	'invoice' |
	'language' |
	'letter-a' |
	'link' |
	'list' |
	'lock-cube' |
	'logout' |
	'logout-v2' |
	'mailchimp' |
	'menu' |
	'message-arrow' |
	'minus' |
	'money' |
	'money-bag' |
	'money-transfer' |
	'notification' |
	'ok' |
	'page' |
	'paragraph' |
	'paypal' |
	'pen-fancy' |
	'pencil' |
	'people-group-v2' |
	'people-pulling' |
	'plug' |
	'plus' |
	'plus-cube' |
	'pound-cube' |
	'prev-cube' |
	'print' |
	'profit' |
	'projects' |
	'publication' |
	'question' |
	'red-arrow-down' |
	'refresh' |
	'sad' |
	'search' |
	'settings' |
	'settings-double' |
	'sign-out-alt-solid' |
	'solid-arrow-down' |
	'spinach' |
	'stats' |
	'step-plus' |
	'table' |
	'tick' |
	'tick-cube' |
	'ticket' |
	'trash' |
	'twitter' |
	'university' |
	'unlocked' |
	'upload' |
	'user' |
	'user-cube' |
	'user-v2' |
	'users' |
	'websites' |
	'websites-cube' |
	'flower' |
	'images' |
	'zzz'

interface IOwnProps {
	icon: TIcon
	color?: string
	width?: string
	height?: string
	gradient?: boolean
}
interface IProps extends IOwnProps {
}

const props = withDefaults(defineProps<IProps>(), {
    height: '16',
    width: '16',
    gradient: false,
    color: 'currentColor'
});

let icon:string|null = null;

switch (props.icon) {
	case 'add-dollar':
		icon = addDollar;
		break;
	case 'address':
		icon = address;
		break;
	case 'approve':
		icon = approve;
		break;
	case 'archive':
		icon = archive;
		break;
	case 'arrow-circle':
		icon = arrowCircle;
		break;
	case 'arrow-down':
		icon = arrowDown;
		break;
	case 'arrow-out':
		icon = arrowOut;
		break;
	case 'arrow-trend-up':
		icon = arrowTrendUp;
		break;
	case 'articles':
		icon = articles;
		break;
	case 'autopay-logo':
		icon = autopayLogo;
		break;
	case 'banner':
		icon = banner;
		break;
	case 'barcode':
		icon = barcode;
		break;
	case 'basket':
		icon = basket;
		break;
	case 'basket-cube':
		icon = basketCube;
		break;
	case 'bell':
		icon = bell;
		break;
	case 'box-open':
		icon = boxOpen;
		break;
	case 'buildings':
		icon = buildings;
		break;
	case 'bulb':
		icon = bulb;
		break;
	case 'cart':
		icon = cart;
		break;
	case 'chat':
		icon = chat;
		break;
	case 'clipboard':
		icon = clipboard;
		break;
	case 'close':
		icon = close;
		break;
	case 'close-bold':
		icon = closeBold;
		break;
	case 'close-chat':
		icon = closeChat;
		break;
	case 'coin50':
		icon = coin50;
		break;
	case 'comments':
		icon = comments;
		break;
	case 'compas':
		icon = compas;
		break;
	case 'copy':
		icon = copy;
		break;
	case 'crab':
		icon = crab;
		break;
	case 'credit-card':
		icon = creditCard;
		break;
	case 'credit-card-solid':
		icon = creditCardSolid;
		break;
	case 'csv-file':
		icon = csvFile;
		break;
	case 'cubes':
		icon = cubes;
		break;
	case 'dashboard':
		icon = dashboard;
		break;
	case 'database':
		icon = database;
		break;
	case 'diagram':
		icon = diagram;
		break;
	case 'discount':
		icon = discount;
		break;
	case 'discount-percent':
		icon = discountPercent;
		break;
	case 'donut':
		icon = donut;
		break;
	case 'dots':
		icon = dots;
		break;
	case 'download':
		icon = download;
		break;
	case 'drop':
		icon = drop;
		break;
	case 'edit':
		icon = edit;
		break;
	case 'edit-cube':
		icon = editCube;
		break;
	case 'external':
		icon = external;
		break;
	case 'eye':
		icon = eye;
		break;
	case 'facebook':
		icon = facebook;
		break;
	case 'file-code':
		icon = fileCode;
		break;
	case 'file-excel':
		icon = fileExcel;
		break;
	case 'gift':
		icon = gift;
		break;
	case 'google':
		icon = google;
		break;
	case 'google-color':
		icon = googleColor;
		break;
	case 'google-email':
		icon = googleEmail;
		break;
	case 'green-arrow-up':
		icon = greenArrowUp;
		break;
	case 'group-people':
		icon = groupPeople;
		break;
	case 'hands':
		icon = hands;
		break;
	case 'heart':
		icon = heart;
		break;
	case 'hexagon':
		icon = hexagon;
		break;
	case 'history-payment':
		icon = historyPayment;
		break;
	case 'info':
		icon = info;
		break;
	case 'info-letter':
		icon = infoLetter;
		break;
	case 'invoice':
		icon = invoice;
		break;
	case 'language':
		icon = language;
		break;
	case 'letter-a':
		icon = letterA;
		break;
	case 'link':
		icon = link;
		break;
	case 'list':
		icon = list;
		break;
	case 'lock-cube':
		icon = lockCube;
		break;
	case 'logout':
		icon = logout;
		break;
	case 'logout-v2':
		icon = logoutV2;
		break;
	case 'mailchimp':
		icon = mailchimp;
		break;
	case 'menu':
		icon = menu;
		break;
	case 'message-arrow':
		icon = messageArrow;
		break;
	case 'minus':
		icon = minus;
		break;
	case 'money':
		icon = money;
		break;
	case 'money-bag':
		icon = moneyBag;
		break;
	case 'money-transfer':
		icon = moneyTransfer;
		break;
	case 'notification':
		icon = notification;
		break;
	case 'ok':
		icon = ok;
		break;
	case 'page':
		icon = page;
		break;
	case 'paragraph':
		icon = paragraph;
		break;
	case 'paypal':
		icon = paypal;
		break;
	case 'pen-fancy':
		icon = penFancy;
		break;
	case 'pencil':
		icon = pencil;
		break;
	case 'people-group-v2':
		icon = peopleGroupV2;
		break;
	case 'people-pulling':
		icon = peoplePulling;
		break;
	case 'plug':
		icon = plug;
		break;
	case 'plus':
		icon = plus;
		break;
	case 'plus-cube':
		icon = plusCube;
		break;
	case 'pound-cube':
		icon = poundCube;
		break;
	case 'prev-cube':
		icon = prevCube;
		break;
	case 'print':
		icon = print;
		break;
	case 'profit':
		icon = profit;
		break;
	case 'projects':
		icon = projects;
		break;
	case 'publication':
		icon = publication;
		break;
	case 'question':
		icon = question;
		break;
	case 'red-arrow-down':
		icon = redArrowDown;
		break;
	case 'refresh':
		icon = refresh;
		break;
	case 'sad':
		icon = sad;
		break;
	case 'search':
		icon = search;
		break;
	case 'settings':
		icon = settings;
		break;
	case 'settings-double':
		icon = settingsDouble;
		break;
	case 'sign-out-alt-solid':
		icon = signOutAltSolid;
		break;
	case 'solid-arrow-down':
		icon = solidArrowDown;
		break;
	case 'spinach':
		icon = spinach;
		break;
	case 'stats':
		icon = stats;
		break;
	case 'step-plus':
		icon = stepPlus;
		break;
	case 'table':
		icon = table;
		break;
	case 'tick':
		icon = tick;
		break;
	case 'tick-cube':
		icon = tickCube;
		break;
	case 'ticket':
		icon = ticket;
		break;
	case 'trash':
		icon = trash;
		break;
	case 'twitter':
		icon = twitter;
		break;
	case 'university':
		icon = university;
		break;
	case 'unlocked':
		icon = unlocked;
		break;
	case 'upload':
		icon = upload;
		break;
	case 'user':
		icon = user;
		break;
	case 'user-cube':
		icon = userCube;
		break;
	case 'user-v2':
		icon = userV2;
		break;
	case 'users':
		icon = users;
		break;
	case 'websites':
		icon = websites;
		break;
	case 'websites-cube':
		icon = websitesCube;
		break;
	case 'flower':
		icon = flower;
		break;
	case 'zzz':
		icon = zzz;
		break;
	case 'images':
		icon = images;
		break;
	default :
		icon = arrowDown;
}

</script>

<style scoped>
svg.icon {
    vertical-align: middle;
    padding: 0;
    display: inline-block;
}
</style>
