import BaseApi from '@/api/BaseApi';
import type { IGlobalSettings } from '@/models/GlobalSettingsModel';

export default class GlobalSettingsApi extends BaseApi {
	async settings () {
		return this.get<Array<IGlobalSettings>>({
			url: 'api/v1/settings',
		});
	};
};
