import { defineStore } from 'pinia';

type TDomain = {
	domainName: string|null
};
export const useDomainStore = defineStore('useDomainStore', {
	state: ():TDomain => ({
		domainName: null,
	}),
	actions: {
		setWebsiteName (websiteName: string|null) {
			this.domainName = websiteName;
		},
	},
});
