<template>
	<div class="order-create">
		<CartHeader
			:title="$t('order.publication.title')"
			publication-cart
			:campaign-name="campaignName()"
			:description="`${ $t('order.publication.yourVatRate') } ${user?.vat_rate}%`"
			btn-id="go-to-cart-btn"
			:btn-title="$t('order.publication.advancedFilters')"
			:btn-order-title="null != user && isModerator(user) ? $t('basket.create.summaryCreateCart') : $t('order.publication.orderPublication')"
			:publications-number="publicationItemsInBasket"
			:expense-pound="grossPrice.toFixed(2)"
			:due-date="publicationUpdateDate"
			:go-to-basket="goToBasket"
			:disabled="!basketId && !isCartHasOrders"
			:is-second-btn="null != user && !isModerator(user)"
			second-btn-id="clear-cart-btn"
			:second-btn-title="$t('publications.clearBasket')"
			:second-disabled="publicationItemsInBasket === 0"
			:second-on-click-btn="openClearDialog"
			:order-uuid="basketId || cartNewOrder?.new_order.uuid"
			:order-name="cartName || cartNewOrder?.new_order.name"
			@cartNameChanged="handleEditCartName"
		/>
		
		<HeadingLabel
			class="order-create__heading-label"
			icon="page"
			width="32"
			height="29"
			:text="$t('order.publication.availableWebsites')"
			modal
			modal-icon="step-plus"
			modal-icon-width="70"
			modal-icon-height="71"
			:modal-icon-color="white"
			:modal-title="$t('order.publication.checkDomains')"
			:modal-text="$t('order.publication.modalText')"
		>
			<div class="order-create__modal-wrapper">
				<br>
				<div>
					{{ $t('order.publication.modalOneDescription') }}
				</div>
				<br>
				<div>
					1) {{ $t('order.publication.modalTwoDescription') }}
				</div>
				<div class="order-create__img-wrapper">
					<img src="../../../assets/images/onboarding_step3_1.png" alt="Order the publication">
				</div>
				<br>
				<div>
					2) {{ $t('order.publication.modalThreeDescription') }}
				</div>
				<br>
				<div>
					- {{ $t('order.publication.modalFourDescription') }} <br>
					- {{ $t('order.publication.modalFifeDescription') }} <br>
					- {{ $t('order.publication.modalSixDescription') }} <br>
					- {{ $t('order.publication.modalSevenDescription') }} <br>
					- {{ $t('order.publication.modalEightDescription') }}
				</div>
				<br>
				<div>
					{{ $t('order.publication.modalNineDescription') }}
				</div>
				<div class="order-create__img-wrapper">
					<img src="../../../assets/images/onboarding_step3_2.png" alt="advanced filters image">
				</div>
				<br>
				<div>
					3) {{ $t('order.publication.modalTenDescription') }}
				</div>
				<div class="order-create__img-wrapper">
					<img src="../../../assets/images/onboarding_step3_3.png" alt="table example">
				</div>
				<br>
				<div>
					{{ $t('order.publication.modalElevenDescription') }}
				</div>
				<br>
				<div>
					- {{ $t('order.publication.modalTwelveDescription') }} <br>
					- {{ $t('order.publication.modalThirteenDescription') }} <br>
					- {{ $t('order.publication.modalFourteenDescription') }} <br>
					- {{ $t('order.publication.modalFifteenDescription') }} <br>
					- {{ $t('order.publication.modalSixteenDescription') }} <br>
					- {{ $t('order.publication.modalSeventeenDescription') }} <br>
					- {{ $t('order.publication.modalEighteenDescription') }} <br>
					- {{ $t('order.publication.modalNineteenDescription') }}
				</div>
				<br>
				<div>
					{{ $t('order.publication.modalTwentyDescription') }}
				</div>
				<div class="order-create__img-wrapper">
					<img src="../../../assets/images/onboarding_step3_4.png" alt="expand table example">
				</div>
				<br>
				<div>
					{{ $t('order.publication.modalTwentyOneDescription') }}
				</div>
				<div class="order-create__img-wrapper">
					<img src="../../../assets/images/onboarding_step3_5.png" alt="order in table examples">
				</div>
				<br>
				<div>
					{{ $t('order.publication.modalTwentyTwoDescription') }}
				</div>
				<div class="order-create__img-wrapper">
					<img src="../../../assets/images/onboarding_step3_6.png" alt="click option example">
				</div>
				<br>
				<div>
					{{ $t('order.publication.modalTwentyThreeDescription') }}
				</div>
				<br>
				<div>
					{{ $t('order.publication.modalTwentyFourDescription') }}
				</div>
				<div class="order-create__img-wrapper">
					<img src="../../../assets/images/onboarding_step3_7.png" alt="order button image">
				</div>
				<br>
				<div>
					{{ $t('order.publication.modalTwentyFifeDescription') }}
				</div>
			</div>
		</HeadingLabel>
		
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:items-per-page-options="itemsPerPageOptions"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:options="options"
			:items-per-page="options.itemsPerPage"
			:page="options.page"
			:sort-by="options.sortBy"
			show-expand
			:expanded="expanded"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
			fixed-header
			:height="items.length > 10 ? 650 : items.length > 0 ? '100%' : tableRowHeight + tableHeadersHeight + tablePaddingHeight"
		>
			<template v-slot:loading>
				<div class="order-create__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template v-slot:no-data>
				<div class="order-create__no-data-text">{{ $t('order.publication.noData') }}</div>
				<div class="order-create__no-data-btn-wrapper">
					<MButton @click="openNewWebsiteForm" uppercase big red400 :label="$t('order.publication.noDataRequest')" />
				</div>
			</template>
			
			<template #top>
				<div>
					<AdvancedFilters
						v-model="advancedFiltersForm"
						@clearExcludedWebsites="clearExcludedWebsites"
						@clearIncludedWebsites="clearIncludedWebsites"
						@clearFilters="clearFilters"
					/>
					<div class="order-create__second-top-wrapper">
						<div v-if="null != user && (user.id === 1327 || user.id === 4790 || user.id === 7436 || user.id === 7004 || user.id === 7231 || user.id === 5706 || user.id === 5787 || user.id === 2163 || user.id === 3943 || user.id === 5972)">
							<MTooltip :max-width="300">
								<template #title>
									<div class="order-create__ai-switcher-wrapper">
										<MSwitch v-model="aiSwitch" />
										<div class="order-create__ai-switcher-text">{{ aiSwitch ? $t('order.publication.aiSwitchOn') : $t('order.publication.aiSwitchOff') }}</div>
									</div>
								</template>
								<template v-slot:content>
									<div>{{ $t('order.publication.aiInfo') }}</div>
								</template>
							</MTooltip>
						</div>
						<TableSettingsMenuWithModal :table-name="OFFERS_LIST" :is-cleared-table-settings-action="isClearedTableSettingsAction" @headersMove="headersMoveAction" :on-clear-action="clearTableSettingsAction" />
					</div>
				</div>
			</template>
			
			<template v-slot:column.domain_rating="{ column, isSorted, getSortIcon }">
				<td class="order-create__custom-header-wrapper">
					<span>
						<MTooltip :max-width="300">
							<template #title>
								<div class="order-create__custom-header-wrapper">
									<div>{{ column.title }}</div>
									<MIcon icon="info" />
								</div>
							</template>
							<template v-slot:content>
		                        <div>{{ $t('order.publication.tableDescriptionDr1') }}</div><br>
		                        <div>{{ $t('order.publication.tableDescriptionDr2') }}</div><br>
		                        <div>{{ $t('order.publication.tableDescriptionDr3') }}</div>
	                        </template>
						</MTooltip>
					</span>
					<div v-if="isSorted(column)">
						<v-icon :icon="getSortIcon(column)" />
					</div>
					<div v-if="!isSorted(column)">
						<v-icon :icon="getSortIcon(column)" class="order-create__hide" />
					</div>
				</td>
			</template>
			<template v-slot:column.trust_flow="{ column, isSorted, getSortIcon }">
				<td class="order-create__custom-header-wrapper">
					<span>
						<MTooltip :max-width="300">
							<template #title>
								<div class="order-create__custom-header-wrapper">
									<div>{{ column.title }}</div>
									<MIcon icon="info" />
								</div>
							</template>
							<template v-slot:content>
		                        <div>{{ $t('order.publication.tableDescriptionTf1') }}</div><br>
		                        <div>{{ $t('order.publication.tableDescriptionTf2') }}</div><br>
		                        <div>{{ $t('order.publication.tableDescriptionTf3') }}</div><br>
		                        <div>{{ $t('order.publication.tableDescriptionTf4') }}</div><br>
		                        <div>{{ $t('order.publication.tableDescriptionTf5') }}</div>
	                        </template>
						</MTooltip>
					</span>
					<div v-if="isSorted(column)">
						<v-icon :icon="getSortIcon(column)" />
					</div>
					<div v-if="!isSorted(column)">
						<v-icon :icon="getSortIcon(column)" class="order-create__hide" />
					</div>
				</td>
			</template>
			<template v-slot:column.organic_traffic="{ column, isSorted, getSortIcon }">
				<td class="order-create__custom-header-wrapper">
					<span>
						<MTooltip :max-width="300">
							<template #title>
								<div class="order-create__custom-header-wrapper">
									<div>{{ column.title }}</div>
									<MIcon icon="info" />
								</div>
							</template>
							<template v-slot:content>
		                        <div>{{ $t('order.publication.tableDescriptionOt1') }}</div>
	                        </template>
						</MTooltip>
					</span>
					<div v-if="isSorted(column)">
						<v-icon :icon="getSortIcon(column)" />
					</div>
					<div v-if="!isSorted(column)">
						<v-icon :icon="getSortIcon(column)" class="order-create__hide" />
					</div>
				</td>
			</template>
			<template v-slot:column.refers_domains="{ column, isSorted, getSortIcon }">
				<td class="order-create__custom-header-wrapper">
					<span>
						<MTooltip :max-width="300">
							<template #title>
								<div class="order-create__custom-header-wrapper">
									<div>{{ column.title }}</div>
									<MIcon icon="info" />
								</div>
							</template>
							<template v-slot:content>
		                        <div>{{ $t('order.publication.tableDescriptionRd1') }}</div>
	                        </template>
						</MTooltip>
					</span>
					<div v-if="isSorted(column)">
						<v-icon :icon="getSortIcon(column)" />
					</div>
					<div v-if="!isSorted(column)">
						<v-icon :icon="getSortIcon(column)" class="order-create__hide" />
					</div>
				</td>
			</template>
			<template v-slot:column.backlinks_live="{ column, isSorted, getSortIcon }">
				<td class="order-create__custom-header-wrapper">
					<span>
						<MTooltip :max-width="300">
							<template #title>
								<div class="order-create__custom-header-wrapper">
									<div>{{ column.title }}</div>
									<MIcon icon="info" />
								</div>
							</template>
							<template v-slot:content>
		                        <div>{{ $t('order.publication.tableDescriptionBl1') }}</div>
	                        </template>
						</MTooltip>
					</span>
					<div v-if="isSorted(column)">
						<v-icon :icon="getSortIcon(column)" />
					</div>
					<div v-if="!isSorted(column)">
						<v-icon :icon="getSortIcon(column)" class="order-create__hide" />
					</div>
				</td>
			</template>
			
			<template #item.name="elem">
				<div class="order-create__website-name">
					<a id="offer-feed-website-name" :href="`http://${elem.item.raw.name}`" target="_blank" class="order-create__website-item">
						{{ elem.item.raw.name }}
						<a :href="`http://${elem.item.raw.name}`" target="_blank" class="order-create__website-item--icon">
							<MIcon icon="external" :color="secondary400" width="12" height="12" />
						</a>
					</a>
					<span v-if="elem.item.raw.is_trusted" class="order-create__trusted">
                        <MTooltip :max-width="300">
                            <template v-slot:title>
                                <img src="../../../assets/images/logo.png" alt="trusted website">
                            </template>
	                        <template v-slot:content>
		                        <div>{{ $t('order.publication.recommended') }}</div>
		                        <div>{{ $t('order.publication.trustedWebsite') }}</div>
	                        </template>
                        </MTooltip>
                    </span>
					<span v-if="elem.item.raw.already_used">
                        <MTooltip :max-width="300">
                            <template v-slot:title>
                                <div>
                                    <MIcon icon="basket" :color="grey400" />
                                </div>
                            </template>
	                        <template v-slot:content>
		                        <div>{{ $t('order.publication.alreadyUsed') }}</div>
	                        </template>
                        </MTooltip>
                    </span>
				</div>
			</template>
			
			<template #item.language_id="elem">
				<div class="order-create__country-wrapper">
					<MFlag :country-code="elem.item.raw.flag" />
				</div>
			</template>
			
			<template #item.description="elem">
				<div>
					<MTooltip :max-width="400">
						<template v-slot:title>
							<div class="order-create__description">{{ $t('order.publication.seeDescription') }}</div>
						</template>
						<template v-slot:content>
							<div id="offer-feed-description" v-html="elem.item.raw.description"></div>
						</template>
					</MTooltip>
				</div>
			</template>
			
			<template #item.domain_rating="elem">
				<div class="order-create__font-14">{{ null != elem.item.raw.domain_rating ? elem.item.raw.domain_rating : '-' }}</div>
			</template>
			
			<template #item.trust_flow="elem">
				<div class="order-create__font-14">{{ null != elem.item.raw.trust_flow ? elem.item.raw.trust_flow : '-' }}</div>
			</template>
			
			<template #item.organic_traffic="elem">
				<div class="order-create__font-14">{{ null != elem.item.raw.organic_traffic ? elem.item.raw.organic_traffic : '-' }}</div>
			</template>
			
			<template #item.refers_domains="elem">
				<div class="order-create__font-14">{{ null != elem.item.raw.refers_domains ? elem.item.raw.refers_domains : '-' }}</div>
			</template>
			
			<template #item.backlinks_live="elem">
				<div class="order-create__font-14">{{ null != elem.item.raw.backlinks_live ? elem.item.raw.backlinks_live : '-' }}</div>
			</template>
			
			<template v-slot:item.original_final_price="elem">
				<div class="order-create__prize-wrapper" v-if="elem.item.raw.converted_discount === 0">
					<div class="order-create__pound order-create__inline">
						<div id="website-cheapest-price">{{ elem.item.raw.converted_net_price.toFixed(2) }}</div>
						<span>{{ userCurrencySymbol }}</span>
						<MTooltip :max-width="300">
							<template v-slot:title>
								<MIcon icon="info" :color="primary400" />
							</template>
							<template v-slot:content>
								<div>{{ $t('order.publication.cheepPriceTooltip')}}</div>
							</template>
						</MTooltip>
					</div>
				</div>
				<div class="order-create__prize-wrapper" v-if="elem.item.raw.converted_discount > 0">
					<div class="order-create__inline order-create__second-price-wrapper--grey">
						{{ elem.item.raw.converted_before_discount_price.toFixed(2) }} <span>{{ userCurrencySymbol }}</span>
					</div>
					<div class="order-create__inline order-create__second-price-wrapper--green order-create__second-price-wrapper--size-15">
						<div id="website-cheapest-price">{{ (elem.item.raw.converted_net_price).toFixed(2) }} <span>{{ userCurrencySymbol }}</span></div>
						<MTooltip :max-width="300">
							<template v-slot:title>
								<MIcon icon="info" />
							</template>
							<template v-slot:content>
								<div>{{ $t('order.publication.cheepPriceTooltip')}}</div>
							</template>
						</MTooltip>
					</div>
					<div v-if="IS_PROMO_ACTIVE && checkIsPromoCodeInDomain(elem.item.raw.coupons)" :style="`${`background-color: ${PROMO_COLOR}; font-family: ${PROMO_FONT}; color: ${PROMO_TEXT_COLOR}; font-size: ${PROMO_FONT_SIZE}; font-weight: ${PROMO_FONT_WEIGHT} !important;`}`" class="order-create__inline order-create__second-price-wrapper--size-15 order-create__second-price-wrapper--bold order-create__second-price-wrapper--holiday">
						<MIcon :icon="PROMO_ICON_NAME" :width="PROMO_ICON_WIDTH" :height="PROMO_ICON_HEIGHT" :color="PROMO_ICON_COLOR" />
						<div>{{ $t('components.holiday2024') }}</div>
					</div>
					<div v-if="IS_PROMO_ACTIVE && !checkIsPromoCodeInDomain(elem.item.raw.coupons)" class="order-create__inline order-create__second-price-wrapper--red order-create__second-price-wrapper--size-15 order-create__second-price-wrapper--bold">{{ $t('order.publication.promotion') }}</div>
					<div v-if="!IS_PROMO_ACTIVE" class="order-create__inline order-create__second-price-wrapper--red order-create__second-price-wrapper--size-15 order-create__second-price-wrapper--bold">{{ $t('order.publication.promotion') }}</div>
				</div>
			</template>
			
			<template v-slot:item.updated_at="elem">
				<div v-if="elem.item.raw.updated_at != null">{{ dateFormat(elem.item.raw.updated_at) }}</div>
			</template>
			
			<template v-slot:item.publisher_email="elem">
				<div v-if="elem.item.raw.owner_email != null">{{ elem.item.raw.owner_email }}</div>
			</template>
			
			<template v-slot:item.publisher_price="elem">
				<div v-if="elem.item.raw.owner_price != null">{{ (elem.item.raw.owner_price / 100).toFixed(2) }} {{ userCurrencySymbol }}</div>
			</template>
			
			<template v-slot:item.data-table-expand="{ item }">
				<div v-if="!expanded.includes(item.value)">
					<div id="offer-feed-expand-btn" @click="expand(item)" class="order-create__expand-action-wrapper">
						<div>{{ $t('order.publication.seeOffer') }}</div>
						<MIcon icon="arrow-down" width="13" height="13" :color="white" />
					</div>
				</div>
				<div v-if="expanded.includes(item.value)">
					<div @click="expand(item)" class="order-create__expand-action-wrapper">
						<div>{{ $t('order.publication.hideOffer') }}</div>
						<MIcon icon="arrow-down" width="13" height="13" :color="white" class="order-create__expand-icon-rotate" />
					</div>
				</div>
			</template>
			
			<template v-slot:expanded-row="{ columns, item }">
				<td :colspan="columns.length">
					<v-data-table
						theme="dark"
						:headers="expandedHeaders"
						:items="expandedItems"
						:no-data-text="$t('components.noDataText')"
						:loading="expandedLoading"
						show-expand
						:expanded="expandExpanded"
					>
						<template v-slot:loading>
							<div class="order-create__loading">{{ $t('components.loading') }}</div>
						</template>
						
						<template v-slot:no-data>
							<div class="order-create__no-data-text--expand">{{ $t('components.noDataText') }}</div>
						</template>
						
						<template #top>
							<div class="order-create__second-table">
								<div class="order-create__choose">{{ $t('order.publication.chooseAnOffer') }}</div>
								<div v-if="null != user && !isModerator(user)" class="order-create__campaign">{{ $t('order.publication.yourCampaign') }}:<span>{{ campaignName() }}</span></div>
								<div class="order-create__publishers">{{ $t('order.publication.portalPublishers') }}:<span>{{ removeHttps(item.raw.url) }}</span></div>
							</div>
						</template>
						
						<template #item.section="elem">
							<div class="order-create__size-14 order-create__section">{{ elem.item.raw.name }}</div>
						</template>
						
						<template v-slot:item.comment="elem">
							<div class="order-create__read">
								<div class="order-create__read-wrapper" :class="{'order-create__read-wrapper--active': detailsActiveId === elem.item.raw.id, 'order-create__read-wrapper--hide': detailsActiveId === -1 && elem.item.raw.id === previousDetailsActiveId}">
									<div class="order-create__read-item"><span class="order-create__read-circle"></span>{{ $t('order.publication.writeArticle') }}: <span class="order-create__read-item--bold">{{ elem.item.raw.write_content ? $t('order.publication.yes') : $t('order.publication.no') }}</span></div>
									<div class="order-create__read-item"><span class="order-create__read-circle"></span>{{ $t('order.publication.publicationPeriod') }}: <span class="order-create__read-item--bold">{{ (elem.item.raw.duration != null && elem.item.raw.duration.name !== null) ? $t(`dictionary.period.${elem.item.raw.duration.id}`) : '-' }}</span></div>
									<div class="order-create__read-item"><span class="order-create__read-circle"></span>{{ $t('order.publication.adDisclosure') }}: <span class="order-create__read-item--bold">{{ elem.item.raw.disclosure_slug != null && elem.item.raw.disclosure_slug !== 'other' ? $t(`order.publication.${elem.item.raw.disclosure_slug}`) : elem.item.raw.disclosure_slug === 'other' ? elem.item.raw.disclosure_other : '-' }}</span></div>
									<div class="order-create__read-item"><span class="order-create__read-circle"></span>{{ $t('order.publication.doFollowLink') }}: <span class="order-create__read-item--bold">{{ elem.item.raw.do_follow ? $t('order.publication.yes') : $t('order.publication.no') }}</span></div>
									<div class="order-create__read-item"><span class="order-create__read-circle"></span>{{ $t('order.publication.imageRequired') }}: <span class="order-create__read-item--bold">{{ elem.item.raw.article_photo === 'Mandatory' ? $t('order.publication.yes') : $t('order.publication.no') }}</span></div>
									<div class="order-create__read-item"><span class="order-create__read-circle"></span>{{ $t('order.publication.facebookPromo') }}: <span class="order-create__read-item--bold">{{ elem.item.raw.promo_facebook ? $t('order.publication.yes') : $t('order.publication.no') }}</span></div>
									<div class="order-create__read-item"><span class="order-create__read-circle"></span>{{ $t('order.publication.twitterPromo') }}: <span class="order-create__read-item--bold">{{ elem.item.raw.promo_twitter ? $t('order.publication.yes') : $t('order.publication.no') }}</span></div>
									<div class="order-create__read-item"><span class="order-create__read-circle"></span>{{ $t('order.publication.newsletterPromo') }}: <span class="order-create__read-item--bold">{{ elem.item.raw.promo_newsletter ? $t('order.publication.yes') : $t('order.publication.no') }}</span></div>
									<div class="order-create__read-item"><span class="order-create__read-circle"></span>{{ $t('order.publication.allowBanners') }}: <span class="order-create__read-item--bold">{{ elem.item.raw.allow_banners ? $t('order.publication.yes') : $t('order.publication.no') }}</span></div>
									<div class="order-create__read-item"><span class="order-create__read-circle"></span>{{ $t('order.publication.maxLinks') }}: <span class="order-create__read-item--bold">{{ elem.item.raw.links_max_amount != null ? elem.item.raw.links_max_amount : 0 }}</span></div>
									<div v-if="elem.item.raw.days_to_write != null" class="order-create__read-item"><span class="order-create__read-circle"></span>{{ $t('order.publication.daysToWrite') }}: <span class="order-create__read-item--bold">{{ elem.item.raw.days_to_write }}</span></div>
									<div v-if="elem.item.raw.days_promotion_on_main_page != null" class="order-create__read-item"><span class="order-create__read-circle"></span>{{ $t('order.publication.daysPromotionOnMainPage') }}: <span class="order-create__read-item--bold">{{ elem.item.raw.days_promotion_on_main_page }}</span></div>
								</div>
								<div class="order-create__read-btn" @click="toggleDetails(elem.item.raw)">{{ detailsActiveId === elem.item.raw.id ? $t('order.publication.hide') : $t('order.publication.expand') }}</div>
							</div>
						</template>
						
						<template v-slot:item.adDisclosure="elem">
							<div v-if="elem.item.raw.disclosure_slug !== 'other'" class="order-create__size-14">{{ $t(`order.publication.${elem.item.raw.disclosure_slug}`) }}</div>
							<div v-if="elem.item.raw.disclosure_slug === 'other'" class="order-create__size-14">{{ elem.item.raw.disclosure_other }}</div>
						</template>
						
						<template v-slot:item.doFollowLink="elem">
							<MIcon v-if="elem.item.raw.do_follow" icon="tick" :color="green400" />
							<MIcon v-if="!elem.item.raw.do_follow" icon="close" :color="secondary400" />
						</template>
						
						<template v-slot:item.writeArticle="elem">
							<MIcon v-if="elem.item.raw.write_content" icon="tick" :color="green400" />
							<MIcon v-if="!elem.item.raw.write_content" icon="close" :color="secondary400" />
						</template>
						
						<template v-slot:item.period="elem">
							<div class="order-create__size-14">{{ $t(`dictionary.period.${elem.item.raw.duration.id}`) }}</div>
						</template>
						
						<template v-slot:item.price="elem">
							<div v-if="elem.item.raw.offer_discount_value <= 0" class="order-create__second-price-wrapper order-create__size-14">
								<div>
									<span id="offer-cheapest-price">{{ elem.item.raw.converted_net_price.toFixed(2) }}</span> <span>{{ userCurrencySymbol }}</span>
								</div>
							</div>
							<div v-if="elem.item.raw.offer_discount_value > 0" class="order-create__second-price-wrapper order-create__size-14">
								<div class="order-create__second-price-wrapper--grey">{{ (elem.item.raw.converted_net_price + elem.item.raw.offer_discount_value).toFixed(2) }}<span>{{ userCurrencySymbol }}</span></div>
								<div class="order-create__second-price-wrapper--green"><span id="offer-cheapest-price">{{ elem.item.raw.converted_net_price.toFixed(2) }}</span> <span>{{ userCurrencySymbol }}</span></div>
								<div v-if="IS_PROMO_ACTIVE && checkIsPromoCode(elem.item.raw.coupons)" :style="`${`background-color: ${PROMO_COLOR}; font-family: ${PROMO_FONT}; color: ${PROMO_TEXT_COLOR}; font-size: ${PROMO_FONT_SIZE}; font-weight: ${PROMO_FONT_WEIGHT} !important;`}`" class="order-create__inline order-create__second-price-wrapper--size-15 order-create__second-price-wrapper--bold order-create__second-price-wrapper--holiday">
									<MIcon :icon="PROMO_ICON_NAME" :width="PROMO_ICON_WIDTH" :height="PROMO_ICON_HEIGHT" :color="PROMO_ICON_COLOR" />
									<div>{{ $t('components.holiday2024') }}</div>
								</div>
								<div v-if="IS_PROMO_ACTIVE && !checkIsPromoCode(elem.item.raw.coupons)" class="order-create__second-price-wrapper--red order-create__second-price-wrapper--bold">{{ $t('order.publication.promotion') }}</div>
								<div v-if="!IS_PROMO_ACTIVE" class="order-create__second-price-wrapper--red order-create__second-price-wrapper--bold">{{ $t('order.publication.promotion') }}</div>
							</div>
						</template>
						
						<template v-slot:item.data-table-expand="{ item }">
							<div v-if="!expandExpanded.includes(item.value)">
								<div @click="expandExpandedAction(item)" class="order-create__expand-action-wrapper order-create__expand-action-wrapper--green order-create__expand-action-wrapper--second">
									<div>{{ $t('order.publication.choose') }}</div>
									<MIcon icon="arrow-down" width="13" height="13" :color="white" />
								</div>
							</div>
							<div v-if="expandExpanded.includes(item.value)">
								<div @click="expandExpandedAction(item)" class="order-create__expand-action-wrapper order-create__expand-action-wrapper--green order-create__expand-action-wrapper--second">
									<div>{{ $t('order.publication.close') }}</div>
									<MIcon icon="arrow-down" width="13" height="13" :color="white" class="order-create__expand-icon-rotate" />
								</div>
							</div>
						</template>
						
						<template #bottom>
						
						</template>
						
						<template v-slot:expanded-row="{ columns, item }">
							<td :colspan="columns.length" class="order-create__choose-wrapper">
								<MProgressBar v-show="loadingOptionsType || loadingOptionsArticle" indeterminate height="2" />
								<div class="order-create__option-choose-wrapper" :class="{'order-create__option-choose-wrapper--mod': null != user && isModerator(user)}">
									<v-radio-group v-model="chooseOption" inline>
										<v-radio id="order-option-publisher-article" v-if="item.raw.write_content === 1 && !isMultiple" :label="$t('order.publication.orderWriteArticleByPublisher')" :value="OPTION_ORDER_ARTICLE_BY_PUBLISHER" :color="secondary400" />
										<v-radio id="order-option-m9-article" v-if="(item.raw.write_content === 0 && (advancedFiltersForm.coupon == null || advancedFiltersForm.coupon == '')) && !isMultiple" :label="$t('order.publication.orderWriteArticle')" :value="OPTION_ORDER_ARTICLE_M9" :color="secondary400" />
										<v-radio id="order-option-own-article" v-if="item.raw.write_content === 0 && !isMultiple" :label="$t('order.publication.selectOwnArticle')" :value="OPTION_OWN_ARTICLE" :color="secondary400" />
										<v-radio id="order-option-add-article" v-if="item.raw.write_content === 0 && !isMultiple" :label="$t('order.publication.addNewArticle')" :value="OPTION_ADD_ARTICLE" :color="secondary400" />
										<v-radio id="order-option-to-choose" v-if="null != user && isModerator(user)" :label="$t('basket.create.toChoose')" :value="OPTION_TO_CHOOSE" :color="secondary400" />
									</v-radio-group>
									
									<div v-if="chooseOption === OPTION_ORDER_ARTICLE_M9" class="order-create__choose-option">
										<ArticleTypesWindow v-model="articleTypeId" :article-types="articleTypesList" :is-language-pl="item.raw.language_id === POLISH_LANGUAGE_ID" tooltip-content-color="white" />
									</div>
									
									<div v-if="chooseOption === OPTION_OWN_ARTICLE" class="order-create__choose-option">
										<div class="order-create__own-article-wrapper">
											<div>{{ $t('order.publication.ownArticleInfo')}}</div>
											<MAutocomplete
												id="order-article-list-autocomplete"
												v-model="selectedArticle"
												:get-items="ownArticleList"
												clearable
												variant="outlined"
												item-title="title"
												item-value="id"
												:placeholder="$t('order.publication.expandList')"
												:no-data-text="$t('order.publication.noArticle')"
												is-fetch-handler-action
												@fetchAction="fetchArticleOnScrollAction"
												@update:search="fetchArticleOnTypeStringAction"
												@fetchClearAction="fetchClearAction"
											/>
											<div class="order-create__own-article-btn-wrapper">
												<MButton
													id="order-update-cart"
													normal
													green400
													uppercase
													:disabled="null == selectedArticle"
													@click="addToCart(null)"
													:label="$t('order.publication.updateCart')"
													:loading="updateCartLoading"
													:loading-color="white"
												/>
											</div>
										</div>
									</div>
									
									<div v-if="chooseOption === OPTION_ADD_ARTICLE" class="order-create__choose-option">
										<div class="order-create__add-new-article-wrapper">
											<MButton
												id="order-open-add-article-dialog"
												normal
												red400
												uppercase
												:label="$t('order.publication.addArticle')"
												@click="openCreateArticleDialog(item.raw.language_id)"
											/>
											<MButton
												v-if="null != user && isModerator(user)"
												id="order-open-upload-article-dialog"
												normal
												red400
												uppercase
												:label="$t('order.publication.uploadNewArticle')"
												@click="openUploadArticleDialog(item.raw.language_id)"
											/>
										</div>
									</div>
									
									<div v-if="chooseOption === OPTION_ORDER_ARTICLE_BY_PUBLISHER" class="order-create__choose-option">
										<ArticleTypesWindow v-model="articleTypeId" :article-types="publisherArticleTypesList" is-publisher-window tooltip-content-color="white" />
									</div>
									
									<div v-if="chooseOption === OPTION_TO_CHOOSE" class="order-create__choose-option">
										<div class="order-create__add-new-article-wrapper">
											<MButton
												id="order-without-article"
												normal
												green400
												uppercase
												:label="$t('order.publication.updateCart')"
												@click="addToCart(null)"
											/>
										</div>
									</div>
								</div>
							</td>
						</template>
					</v-data-table>
				</td>
			</template>
		</v-data-table-server>
		
		<div class="order-create__back-btn-wrapper">
			<MButton
				id="order-back-btn"
				normal
				blue400
				:label="$t('order.orderFlow.back')"
				@click="goBack"
			/>
		</div>
		
		<MDialog
			:width="1000"
			:is-close-dialog-action="isCloseAddNewWebsiteDialog"
			:is-open-dialog-action="isOpenAddNewWebsiteDialog"
			:content-title="$t('order.publication.noDataRequest')"
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<AddNewWebsiteForm @saveAction="closeDialogWhenNoTargetPressed"/>
			</template>
		</MDialog>
		
		<MDialog
			:width="1000"
			:is-close-dialog-action="isCloseAddNewArticleDialog"
			:is-open-dialog-action="isOpenAddNewArticleDialog"
			:content-title="$t('order.publication.addNewArticle')"
			close-header-btn
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<CreateArticleForm v-if="null != languageId" :campaign-id="null != user && isModerator(user) ? cartCampaignId : campaignId" :language-id="languageId" is-dialog-form @dialogSaveArticle="dialogSaveArticle" />
			</template>
		</MDialog>
		
		<MDialog
			:width="1000"
			:is-close-dialog-action="isCloseOrderArticleDialog"
			:is-open-dialog-action="isOpenOrderArticleDialog"
			:content-title="$t('order.article.professionalWriter')"
			close-header-btn
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<OrderArticleForm v-if="null != languageId && null != articleTypeId" :campaign-id="null != user && isModerator(user) ? cartCampaignId : campaignId" :language-id="languageId" :article-type-id="articleTypeId" is-dialog-form @dialogSaveOrderArticle="dialogSaveOrderArticle" @closeDialogAction="closeDialogWhenNoTargetPressed" />
			</template>
		</MDialog>
		
		<MDialog
			:width="1000"
			:is-close-dialog-action="isCloseUploadArticleDialog"
			:is-open-dialog-action="isOpenUploadArticleDialog"
			:content-title="$t('order.article.professionalWriter')"
			close-header-btn
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<UploadArticle :campaign-id="null != user && isModerator(user) ? cartCampaignId : campaignId" :language-id="languageId" is-dialog @dialogSaveArticle="dialogSaveArticle" />
			</template>
		</MDialog>
		
		<MDialog
			:width="650"
			:is-close-dialog-action="isClosedClearCartDialog"
			:is-open-dialog-action="isOpenClearCartDialog"
			:content-title="$t('publications.clearBasket')"
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<div class="order-create__clear-dialog-wrapper">
					<div class="order-create__clear-dialog-text">{{ $t('publications.clearCartDescription') }}</div>
					<div class="order-create__clear-dialog-btn-wrapper">
						<MButton
							id="clear-basket-btn"
							red400
							:label="$t('publications.clearBasket')"
							@click="clearBasket"
						/>
						<MButton
							id="clear-basket-back-btn"
							blue400
							:label="$t('components.back')"
							@click="closeDialogWhenNoTargetPressed"
						/>
					</div>
				</div>
			</template>
		</MDialog>
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { VDataTableServer, VDataTable } from 'vuetify/labs/VDataTable';
import router from '@/router';
import { toast } from 'vue3-toastify';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useDictionaryStore } from '@/stores/dictionary';
import { useCartStore } from '@/stores/cart';
import { useTableSettingsStore } from '@/stores/tableSettings';
import { useSessionStore } from '@/stores/session';
import { useBooleanFlagStore } from '@/stores/booleanFlag';
import { useArticleStore } from '@/stores/article';
import { useI18n } from 'vue-i18n';
import { clearTableSettings, editTableSettings, OFFERS_LIST } from '@/hooks/TableSettingsHooks';
import { dateFormat } from '@/hooks/DataHooks';
import {
	OPTION_ADD_ARTICLE,
	OPTION_ORDER_ARTICLE_BY_PUBLISHER,
	OPTION_ORDER_ARTICLE_M9,
	OPTION_OWN_ARTICLE,
	ORDER_DRAFT,
	PUBLICATION_REQUEST,
	ARTICLE_WRITE_OPTION_OWN,
	ARTICLE_WRITE_OPTION_M9,
	ARTICLE_WRITE_OPTION_PUBLISHER,
	OPTION_TO_CHOOSE,
	ARTICLE_WRITE_OPTION_TO_CHOOSE,
} from '@/hooks/OrderHooks';
import {
	IS_PROMO_ACTIVE,
	PROMO_COLOR,
	PROMO_ICON_NAME,
	PROMO_ICON_WIDTH,
	PROMO_ICON_HEIGHT,
	PROMO_CODE,
	PROMO_ICON_COLOR,
	PROMO_FONT,
	PROMO_TEXT_COLOR,
	PROMO_FONT_SIZE,
	PROMO_FONT_WEIGHT
} from '@/hooks/PromoHooks';
import { dateFormatWithTime } from '@/hooks/DataHooks';
import { isModerator } from '@/hooks/UserHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import MonolithOrderApi from '@/api/monolith/OrderApi';
import OrderApi from '@/api/v1/OrderApi';
import ArticleApiV1 from '@/api/v1/ArticleApi';
import ArticleApi from '@/api/v2/ArticleApi';
import WebsitesApi from '@/apiWebsites/v1/WebsitesApi';
import CartApi from '@/api/v2/CartApi';
import MonolithPublicationApi from '@/api/monolith/MonolithPublicationApi';
import type { TableSettingsInterface, TableSettingsRequestInterface } from '@/models/TableSettingsModel';
import type {
	IArticleListV2Item,
	IArticleQueryParams,
	IArticleTypes,
	ICreateArticleResponse
} from '@/models/ArticlesModel';
import type {
	IElasticDomainRequest,
	IMonolithElasticDomainItem,
	IMonolithElasticDomainOffersCouponResponse,
	IMonolithElasticDomainOffersResponseItem,
	IOrderPublicationRequest,
} from '@/models/OrderModel';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import { POLISH_LANGUAGE_ID } from '@/hooks/ProfileHooks';
import { ARTICLE_TYPE_SPECIALIST } from '@/hooks/ArticlesHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import CartHeader from '@/components/molecules/CartHeader.vue';
import HeadingLabel from '@/components/molecules/HeadingLabel.vue';
import AdvancedFilters from '@/views/OrderCreate/components/AdvancedFilters.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import TableSettingsMenuWithModal from '@/components/organisms/TableSettingsMenuWithModal.vue';
import MFlag from '@/components/atoms/MFlag.vue';
import MButton from '@/components/atoms/MButton.vue';
import MDialog from '@/components/atoms/MDialog.vue';
import AddNewWebsiteForm from '@/views/OrderCreate/components/AddNewWebsiteForm.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import ArticleTypesWindow from '@/components/molecules/ArticleTypesWindow.vue';
import CreateArticleForm from '@/components/organisms/Articles/CreateArticleForm.vue';
import OrderArticleForm from '@/components/organisms/OrderCreate/components/OrderArticleForm.vue';
import MSwitch from '@/components/atoms/MSwitch.vue';
import UploadArticle from '@/components/organisms/Articles/UploadArticle.vue';

const monolithOrderApi = new MonolithOrderApi();
const orderApi = new OrderApi();
const articleApi = new ArticleApi();
const articleApiV1 = new ArticleApiV1();
const websitesApi = new WebsitesApi();
const cartApi = new CartApi();
const monolithPublicationApi = new MonolithPublicationApi();

const { user, userCurrencySymbol } = storeToRefs(useUserStore());
const { setIsActiveCart } = useUserStore();
const { campaigns } = useDictionaryStore();
const { t } = useI18n();
const { white, grey400, secondary400, primary400, green400 } = variables;
const { setSort, setHeaders, setFilters, setPagination } = useTableSettingsStore();
const { locale } = storeToRefs(useSessionStore());
const { isAiOfferFilters, isPromoBtnClicked } = useBooleanFlagStore();
const { cartCampaignId, cartUuid, isCartHasOrders, cartNewOrder, cartAssignedUuid, isMultiple, cartSlug } = storeToRefs(useCartStore());
const { setIsCartHasOrders, setCartNewOrder } = useCartStore();
const { articleLanguageId } = storeToRefs(useArticleStore());

const grossPrice = ref<number>(0);
const publicationItemsInBasket = ref<number>(0);
const basketId = ref<string|null>(null);
const cartName = ref<string|null>(null);
const orderId = ref<number | null>(null);
const campaignId = ref<number|null>(null);
const publicationUpdateDate = ref<string>('');
const total = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const loading = ref<boolean>(false);
const expandedLoading = ref<boolean>(false);
const updateCartLoading = ref<boolean>(false);
const loadingOptionsType = ref<boolean>(false);
const loadingOptionsArticle = ref<boolean>(false);
const searchTimeout = ref<any>(undefined);
const expanded = ref<Array<IMonolithElasticDomainItem>|any>([]);
const expandExpanded = ref<Array<IMonolithElasticDomainOffersResponseItem>|any>([]);
const items = ref<Array<IMonolithElasticDomainItem>>([]);
const expandedItems = ref<Array<IMonolithElasticDomainOffersResponseItem>>([]);
const languageId = ref<number|null>(null);
const offerId = ref<number|null>(null);
let firstLoadPage:boolean = true;
let isClearAction:boolean = false;
let isFilterAction:boolean = false;
const isClearedTableSettingsAction = ref<boolean>(false);
const aiSwitch = ref<boolean>(false);
const detailsActiveId = ref<number|null>(null);
const previousDetailsActiveId = ref<number|null>(null);
const tableRowHeight = ref<number>(52);
const tableHeadersHeight = ref<number>(82);
const tablePaddingHeight = ref<number>(15);
const advancedFiltersForm = ref<any>({
	search: null,
	do_follow: null,
	min_price: null,
	max_price: null,
	language_ids: null,
	categories_ids: null,
	countries_ids: null,
	is_trusted: null,
	has_discount: null,
	fast_delivery: null,
	anchor_types: null,
	article_disclosure: null,
	links_max_amount: null,
	publication_period: null,
	write_content: null,
	is_publication_on_main_page: null,
	days_promotion_on_main_page: null,
	promo: null,
	is_allowed_promotion_loans: null,
	is_allowed_promotion_bookmakers: null,
	min_domain_rating: null,
	max_domain_rating: null,
	min_trust_flow: null,
	max_trust_flow: null,
	min_organic_traffic: null,
	max_organic_traffic: null,
	https: null,
	exclude_owned: null,
	exclude_ids: [],
	include_ids: [],
	min_refers_domains: null,
	max_refers_domains: null,
	min_backlinks_live: null,
	max_backlinks_live: null,
	coupon: null != user.value && isModerator(user.value) ? null : !IS_PROMO_ACTIVE || !isPromoBtnClicked ? null : PROMO_CODE,
});
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
let headers = ref<Array<TableHeaderInterface>|any>([]);
const expandedHeaders = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('order.publication.offerName')}`,
		align: 'left',
		sortable: false,
		key: 'section',
		width: '250px'
	},
	{
		title: `${t('order.publication.comment')}`,
		align: 'left',
		sortable: false,
		key: 'comment',
		width: '100px'
	},
	{
		title: `${t('order.publication.adDisclosure')}`,
		align: 'left',
		sortable: false,
		key: 'adDisclosure',
		width: '100px'
	},
	{
		title: `${t('order.publication.doFollowLink')}`,
		align: 'left',
		sortable: false,
		key: 'doFollowLink',
		width: '100px'
	},
	{
		title: `${t('order.publication.writeArticle')}`,
		align: 'left',
		sortable: false,
		key: 'writeArticle',
		width: '100px'
	},
	{
		title: `${t('order.publication.publicationPeriod')}`,
		align: 'left',
		sortable: false,
		key: 'period',
		width: '100px'
	},
	{
		title: `${t('order.publication.price')}`,
		align: 'center',
		sortable: false,
		key: 'price',
		width: '100px'
	},
	{
		title: `${t('order.publication.chooseOffer')}`,
		align: 'left',
		sortable: false,
		key: 'data-table-expand',
		width: '100px'
	},
]);
const isOpenAddNewWebsiteDialog = ref<boolean>(false);
const isOpenAddNewArticleDialog = ref<boolean>(false);
const isOpenOrderArticleDialog = ref<boolean>(false);
const isOpenUploadArticleDialog = ref<boolean>(false);
const isOpenClearCartDialog = ref<boolean>(false);
const isCloseAddNewWebsiteDialog = ref<boolean>(true);
const isCloseAddNewArticleDialog = ref<boolean>(true);
const isCloseOrderArticleDialog = ref<boolean>(true);
const isCloseUploadArticleDialog = ref<boolean>(true);
const isClosedClearCartDialog = ref<boolean>(true);
const chooseOption = ref<string|null>(null);
const ownArticleList = ref<Array<IArticleListV2Item>>([]);
const selectedArticle = ref<number|null>(null);
const articleTypesList = ref<Array<IArticleTypes>>([]);
const publisherArticleTypesList = ref<Array<any>>([
	{
		id: 0,
		type: 'publisher',
		net_price: 0,
		converted_net_price: 0,
	}
]);
const articleTypeId = ref<number|null>(null);
const isAvailableArticleLastPage = ref<boolean>(false);
const isAvailableArticlePrevSearchWasActive = ref<boolean>(false);
const availableArticleSearchTimeout = ref<any>(undefined);
const availableArticleOptions = ref<any>({
	page: 1,
	itemsPerPage: 10,
});
const gtm = {
	pushEvent(event:string, name:string, value:any) {
		if (null != window.dataLayer) {
			window.dataLayer.push({
				event: event,
				[name]: value
			});
		}
	}
};

const localeLanguage = ref<string>('');

if ('us' === locale.value) {
	localeLanguage.value = 'en';
} else if (locale.value != null) {
	localeLanguage.value = locale.value;
} else {
	localeLanguage.value = 'pl';
}

const defaultHeaders = () => {
	const result = [
		{
			title: `${t('order.publication.website')}`,
			align: 'left',
			sortable: false,
			key: 'name',
			width: '350px'
		},
		{
			title: `${t('order.publication.language')}`,
			align: 'left',
			sortable: false,
			key: 'language_id',
			width: '70px'
		},
		{
			title: `${t('order.publication.description')}`,
			align: 'left',
			sortable: false,
			key: 'description',
			width: '150px'
		},
		{
			title: `${t('order.publication.dr')}`,
			align: 'left',
			sortable: true,
			key: 'domain_rating',
			width: '60px'
		},
		{
			title: `${t('order.publication.tf')}`,
			align: 'left',
			sortable: true,
			key: 'trust_flow',
			width: '60px'
		},
		{
			title: `${t('order.publication.ot')}`,
			align: 'left',
			sortable: true,
			key: 'organic_traffic',
			width: '60px'
		},
		{
			title: `${t('order.publication.rd')}`,
			align: 'left',
			sortable: true,
			key: 'refers_domains',
			width: '60px'
		},
		{
			title: `${t('order.publication.bl')}`,
			align: 'left',
			sortable: true,
			key: 'backlinks_live',
			width: '60px'
		},
	];
	
	if (null != user.value && isModerator(user.value)) {
		result.push(...[
			{
				title: `${t('order.publication.publisherEmail')}`,
				align: 'left',
				sortable: false,
				key: 'publisher_email',
				width: '160px'
			},
			{
				title: `${t('order.publication.publisherPrice')}`,
				align: 'left',
				sortable: false,
				key: 'publisher_price',
				width: '120px'
			},
		]);
	}
	
	result.push(...[
		{
			title: `${t('order.publication.cheapestOffer')}`,
			align: 'center',
			sortable: true,
			key: 'original_final_price',
			width: '150px'
		},
		{
			title: `${t('order.publication.offers')}`,
			align: 'left',
			sortable: false,
			key: 'data-table-expand',
			width: '150px'
		},
	]);
	
	return result;
};
headers.value = defaultHeaders();
const defaultInactiveHeaders = () => {
	const result = [
		{
			title: `${t('order.publication.updated')}`,
			align: 'left',
			sortable: false,
			key: 'updated_at',
			width: '120px'
		},
	];
	
	return result;
};

if (null != router.currentRoute.value.params.campaignId && 'null' !== router.currentRoute.value.params.campaignId) {
	campaignId.value = +router.currentRoute.value.params.campaignId;
}

const checkCampaignLanguage = ():number|null => {
	const campaign:any = campaigns.find(elem => elem.id === +router.currentRoute.value.params.campaignId);
	if (null != campaign) {
		return campaign.language_id;
	}
	return null;
};
const checkCampaignAISuggestions = async() => {
	const campaign:any = campaigns.find(elem => elem.id === +router.currentRoute.value.params.campaignId);
	if (null != campaign && null != router.currentRoute.value.params.campaignId) {
		try {
			const result = await websitesApi.suggestedWebsitesList(campaign.url, +router.currentRoute.value.params.campaignId);
			if (!result.isSuccess) {
				return null;
			}
			if (Array.isArray(result.payload) && result.payload.length > 0) {
				advancedFiltersForm.value.include_ids = result.payload;
			}
		} catch (e) {
			return null;
		}
	}
};
if (null != checkCampaignLanguage() && !isAiOfferFilters && null == articleLanguageId.value) {
	advancedFiltersForm.value.language_ids = [checkCampaignLanguage()];
}
if (null != articleLanguageId.value) {
	advancedFiltersForm.value.language_ids = [articleLanguageId.value];
}
if (isAiOfferFilters) {
	aiSwitch.value = true;
}
const domainList = async() => {
	loading.value = true;
	expanded.value = [];
	
	if ('' !== advancedFiltersForm.value.max_price && null != advancedFiltersForm.value.max_price && '' !== advancedFiltersForm.value.min_price && null != advancedFiltersForm.value.min_price && +advancedFiltersForm.value.min_price > +advancedFiltersForm.value.max_price) {
		toast.error(`${t('order.publication.toast.maxPriceIsTooLow')}`);
		loading.value = false;
		return;
	}
	// Wysyłanie searcha do google analyticsa
	if (null != advancedFiltersForm.value.search && '' !== advancedFiltersForm.value.search) {
		gtm.pushEvent('Publication - search filter', 'search', advancedFiltersForm.value.search);
	}
	
	const filters:IElasticDomainRequest = {
		api_token: localStorage.getItem('apiToken'),
		page: options.value.page,
		per_page: options.value.itemsPerPage,
		order: options.value.sortBy.length > 0 ? options.value.sortBy[0].key : null,
		orderDirection: options.value.sortBy.length > 0 ? options.value.sortBy[0].order : null,
		campaign_id: campaignId.value,
		search: advancedFiltersForm.value.search,
		languages_ids: advancedFiltersForm.value.language_ids,
		min_price: null != advancedFiltersForm.value.max_price && null == advancedFiltersForm.value.min_price ? 0 : '' === advancedFiltersForm.value.min_price ? 0 : advancedFiltersForm.value.min_price,
		max_price: null != advancedFiltersForm.value.max_price && '' !== advancedFiltersForm.value.max_price ? advancedFiltersForm.value.max_price : null,
		countries_ids: advancedFiltersForm.value.countries_ids,
		min_domain_rating: advancedFiltersForm.value.min_domain_rating,
		max_domain_rating: advancedFiltersForm.value.max_domain_rating,
		min_trust_flow: advancedFiltersForm.value.min_trust_flow,
		max_trust_flow: advancedFiltersForm.value.max_trust_flow,
		min_organic_traffic: advancedFiltersForm.value.min_organic_traffic,
		max_organic_traffic: advancedFiltersForm.value.max_organic_traffic,
		article_disclosure: advancedFiltersForm.value.article_disclosure,
		categories_ids: advancedFiltersForm.value.categories_ids,
		is_allowed_promotion_bookmakers: advancedFiltersForm.value.is_allowed_promotion_bookmakers ? 1 : null,
		is_allowed_promotion_loans: advancedFiltersForm.value.is_allowed_promotion_loans ? 1 : null,
		is_trusted: advancedFiltersForm.value.is_trusted ? 1 : null,
		has_discount: advancedFiltersForm.value.has_discount ? 1 : null,
		exclude_owned: advancedFiltersForm.value.exclude_owned ? 1 : null,
		do_follow: advancedFiltersForm.value.do_follow ? 1 : null,
		exclude_ids: null != advancedFiltersForm.value.exclude_ids ? advancedFiltersForm.value.exclude_ids.map((object:any) => object.id) : null,
		include_ids: null != advancedFiltersForm.value.include_ids ? advancedFiltersForm.value.include_ids.map((object:any) => object.id) : null,
		publication_period: advancedFiltersForm.value.publication_period,
		write_content: advancedFiltersForm.value.write_content ? 1 : null,
		https: advancedFiltersForm.value.https ? 1 : null,
		links_max_amount: advancedFiltersForm.value.links_max_amount,
		promo_facebook: null != advancedFiltersForm.value.promo && advancedFiltersForm.value.promo.length > 0 ? advancedFiltersForm.value.promo.find((elem:any) => elem === 1) ? 1 : null : null,
		promo_twitter: null != advancedFiltersForm.value.promo && advancedFiltersForm.value.promo.length > 0 ? advancedFiltersForm.value.promo.find((elem:any) => elem === 2) ? 1 : null : null,
		promo_newsletter: null != advancedFiltersForm.value.promo && advancedFiltersForm.value.promo.length > 0 ? advancedFiltersForm.value.promo.find((elem:any) => elem === 3) ? 1 : null : null,
		days_promotion_on_main_page: null != advancedFiltersForm.value.days_promotion_on_main_page ? advancedFiltersForm.value.days_promotion_on_main_page : (advancedFiltersForm.value.is_publication_on_main_page ? 1 : null),
		fast_delivery: advancedFiltersForm.value.fast_delivery ? 1 : null,
		anchor_types: advancedFiltersForm.value.anchor_types,
		min_refers_domains: advancedFiltersForm.value.min_refers_domains,
		max_refers_domains: advancedFiltersForm.value.max_refers_domains,
		min_backlinks_live: advancedFiltersForm.value.min_backlinks_live,
		max_backlinks_live: advancedFiltersForm.value.max_backlinks_live,
		coupon: advancedFiltersForm.value.coupon?.toUpperCase(),
		user_id: null != user.value && isModerator(user.value) && null != cartNewOrder.value && null != cartNewOrder.value.assigned_id ? cartNewOrder.value.assigned_id : null,
	};
	try {
		const result = await monolithOrderApi.domainList(filters);
		if (!result.isSuccess) {
			toast.error(`${t('order.publication.toast.errorGetDomains')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload.data;
		total.value = result.payload.total;
		currentPage.value = options.value.page;
		if (total.value < options.value.itemsPerPage) {
			lastPage.value = 1;
		} else if (0 === total.value % options.value.itemsPerPage) {
			lastPage.value = total.value / options.value.itemsPerPage;
		} else {
			lastPage.value = +(total.value / options.value.itemsPerPage).toFixed(0);
		}
	} catch (e) {
		toast.error(`${t('order.publication.toast.errorGetDomains')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const publicationOrderTableSettings = ref<TableSettingsInterface|null>(null);
const initTableOptions = async() => {
	let isShouldBeSaved = false;
	publicationOrderTableSettings.value = storeToRefs(useTableSettingsStore())[OFFERS_LIST].value;
	
	// Columns options
	if (null == publicationOrderTableSettings.value) {
		await setSort({
			name: OFFERS_LIST,
			sortBy: null,
			sortDesc: null,
		});
		isShouldBeSaved = true;
	}
	
	// Headers options
	if (null == publicationOrderTableSettings.value || 0 === publicationOrderTableSettings.value.columns.active.length || publicationOrderTableSettings.value.filters.locale !== localeLanguage.value) {
		isClearedTableSettingsAction.value = true;
		await setHeaders({
			name: OFFERS_LIST,
			headers: {
				active: defaultHeaders(),
				inactive: defaultInactiveHeaders(),
			}
		});
		isShouldBeSaved = true;
	}
	
	// Pagination options
	if (null == publicationOrderTableSettings.value || null == publicationOrderTableSettings.value.page || null == publicationOrderTableSettings.value.per_page) {
		await setPagination({
			name: OFFERS_LIST,
			page: 1,
			perPage: 10
		});
		isShouldBeSaved = true;
	}
	
	// Filters options
	// Ponizej wykomentowane zapisywanie i przypisywanie ustawien filtrow poniewaz chwilowo ich nie chcemy
	// Object.entries(advancedFiltersForm.value).forEach(entry => {
	// 	const [key] = entry;
	// 	advancedFiltersForm.value[key] = publicationOrderTableSettings.value.filters[key];
	// });
	if (null != publicationOrderTableSettings.value && publicationOrderTableSettings.value.filters.locale !== localeLanguage.value) {
		const filters = {
			...advancedFiltersForm.value,
			locale: localeLanguage.value,
		};
		await setFilters({
			name: OFFERS_LIST,
			filters: filters
		});
	}
	
	if (null != publicationOrderTableSettings.value) {
		options.value.page = publicationOrderTableSettings.value.page;
		options.value.itemsPerPage = publicationOrderTableSettings.value.per_page;
		options.value.sortBy.push({
			key: publicationOrderTableSettings.value.sort_column,
			order: publicationOrderTableSettings.value.sort_order,
		});
		
		headers.value = publicationOrderTableSettings.value.columns.active;
	}
	if (isShouldBeSaved) {
		await editTableSettingsAction();
	}
	await domainList();
	isClearedTableSettingsAction.value = false;
};
initTableOptions();

const editTableSettingsAction = () => {
	if (null != user.value && null != publicationOrderTableSettings.value) {
		const tableSettings: TableSettingsRequestInterface = {
			sort_column: null != publicationOrderTableSettings.value.sort_column && 0 < publicationOrderTableSettings.value.sort_column.length ? publicationOrderTableSettings.value.sort_column : null,
			sort_order: null != publicationOrderTableSettings.value.sort_order && 0 < publicationOrderTableSettings.value.sort_order.length ? publicationOrderTableSettings.value.sort_order : null,
			page: publicationOrderTableSettings.value.page,
			per_page: publicationOrderTableSettings.value.per_page,
			filters: JSON.stringify(publicationOrderTableSettings.value.filters),
			columns: JSON.stringify(publicationOrderTableSettings.value.columns),
		};
		editTableSettings(OFFERS_LIST, tableSettings, user.value?.active_role_id);
	}
};

const clearTableSettingsAction = async() => {
	isClearAction = true;
	
	await clearFilters(true);
	
	options.value.page = 1;
	options.value.itemsPerPage = 10;
	options.value.sortBy = [];
	
	headers.value = defaultHeaders();
	
	const filters = {
		...advancedFiltersForm.value,
		locale: localeLanguage.value,
	};
	
	const columns = {
		active: defaultHeaders(),
		inactive: defaultInactiveHeaders(),
	};
	
	const tableSettings:TableSettingsRequestInterface = {
		sort_column: null,
		sort_order: null,
		page: 1,
		per_page: 10,
		filters: JSON.stringify(filters),
		columns: JSON.stringify(columns),
	}
	if (null != user.value) {
		await clearTableSettings(OFFERS_LIST, tableSettings, user.value?.active_role_id, `${t('components.toast.errorClearTableSettings')}`, `${t('components.toast.successClearTableSettings')}`);
	}
	isClearedTableSettingsAction.value = true;
	
	setTimeout(() => {
		isClearAction = false;
		isClearedTableSettingsAction.value = false;
		domainList();
	},1);
};

const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	// Dodanie statystyk do google analytics
	gtm.pushEvent('Publication - items per page', 'publication_items_per_page', val.itemsPerPage);
	
	if (!firstLoadPage && !isClearAction) {
		await setPagination({
			name: OFFERS_LIST,
			page: val.page,
			perPage: val.itemsPerPage,
		});
		await setSort({
			name: OFFERS_LIST,
			sortBy: 0 < val.sortBy.length ? val.sortBy[0].key : [],
			sortDesc: 0 < val.sortBy.length ? val.sortBy[0].order : [],
		});
		if (!isFilterAction) {
			await editTableSettingsAction();
			await domainList();
		}
	}
	isFilterAction = false;
	firstLoadPage = false;
};

watch((advancedFiltersForm), async (newVal, oldVal) => {
	if (!isClearAction) {
		if (options.value.page > 1) {
			options.value.page = 1;
			await setPagination({
				name: OFFERS_LIST,
				page: 1,
				perPage: options.value.itemsPerPage,
			});
			isFilterAction = true;
		}
		if (newVal) {
			clearTimeout(searchTimeout.value);
			searchTimeout.value = setTimeout(async () => {
				const filters = {
					...newVal,
					locale: localeLanguage.value
				};
				await setFilters({
					name: OFFERS_LIST,
					filters: filters
				});
				await editTableSettingsAction();
				await domainList();
			}, 700);
		}
	}
},
{
	deep: true,
});

const headersMoveAction = () => {
	if (null != publicationOrderTableSettings.value) {
		headers.value = publicationOrderTableSettings.value.columns.active;
	}
};
const campaignName = () => {
	const campaign = campaigns.find(elem => elem.id === campaignId.value);
	return campaign ? campaign.name : `${t('basket.list.unknown')}`;
};
const goToBasket = () => {
	if (null != user.value && !isModerator(user.value)) {
		router.push(`/basket/${basketId.value}`);
	} else {
		router.push(`/carts/${cartSlug.value}/preview`);
	}
};
const clearExcludedWebsites = (isClearAction:boolean) => {
	if (isClearAction) {
		advancedFiltersForm.value.exclude_ids = [];
	}
};
const clearIncludedWebsites = (isClearAction:boolean) => {
	if (isClearAction) {
		advancedFiltersForm.value.include_ids = [];
	}
};
const clearFilters = (isClearAction:boolean, isClearAiSwitcher?:boolean) => {
	if (isClearAction) {
		advancedFiltersForm.value = {
			search: null,
			do_follow: null,
			min_price: null,
			max_price: null,
			language_ids: null,
			categories_ids: null,
			countries_ids: null,
			is_trusted: null,
			has_discount: null,
			fast_delivery: null,
			anchor_types: null,
			article_disclosure: null,
			links_max_amount: null,
			publication_period: null,
			write_content: null,
			is_publication_on_main_page: null,
			promo: null,
			is_allowed_promotion_loans: null,
			is_allowed_promotion_bookmakers: null,
			min_domain_rating: null,
			max_domain_rating: null,
			min_trust_flow: null,
			max_trust_flow: null,
			min_organic_traffic: null,
			max_organic_traffic: null,
			https: null,
			exclude_owned: null,
			exclude_ids: [],
			include_ids: [],
			min_refers_domains: null,
			max_refers_domains: null,
			min_backlinks_live: null,
			max_backlinks_live: null,
			coupon: null,
		};
		toast.success(`${t('order.publication.toast.successFilterClear')}`);
		if (!isClearAiSwitcher) {
			aiSwitch.value = false;
		}
	}
};

const openNewWebsiteForm = () => {
	isCloseAddNewWebsiteDialog.value = false;
	isOpenAddNewWebsiteDialog.value = true;
};
const openCreateArticleDialog = (itemLanguageId:number) => {
	languageId.value = itemLanguageId;
	isCloseAddNewArticleDialog.value = false;
	isOpenAddNewArticleDialog.value = true;
};
const openUploadArticleDialog = (itemLanguageId:number) => {
	languageId.value = itemLanguageId;
	isCloseUploadArticleDialog.value = false;
	isOpenUploadArticleDialog.value = true;
};
const closeDialogWhenNoTargetPressed = () => {
	isCloseAddNewWebsiteDialog.value = true;
	isCloseAddNewArticleDialog.value = true;
	isCloseOrderArticleDialog.value = true;
	isCloseUploadArticleDialog.value = true;
	isClosedClearCartDialog.value = true;
	isOpenAddNewWebsiteDialog.value = false;
	isOpenAddNewArticleDialog.value = false;
	isOpenOrderArticleDialog.value = false;
	isOpenUploadArticleDialog.value = false;
	isOpenClearCartDialog.value = false;
	articleTypeId.value = null;
};

const domainOffers = async(domainId:number) => {
	expandedLoading.value = true;
	
	const filters:any = {
		api_token: localStorage.getItem('apiToken'),
		domain_id: domainId,
		campaign_id: campaignId.value,
		page: 1,
		per_page: 99,
		min_price: null != advancedFiltersForm.value.max_price && null == advancedFiltersForm.value.min_price ? 0 : '' === advancedFiltersForm.value.min_price ? 0 : advancedFiltersForm.value.min_price,
		max_price: null != advancedFiltersForm.value.max_price && '' !== advancedFiltersForm.value.max_price ? advancedFiltersForm.value.max_price : null,
		article_disclosure: advancedFiltersForm.value.article_disclosure,
		is_allowed_promotion_bookmakers: advancedFiltersForm.value.is_allowed_promotion_bookmakers ? 1 : null,
		is_allowed_promotion_loans: advancedFiltersForm.value.is_allowed_promotion_loans ? 1 : null,
		has_discount: advancedFiltersForm.value.has_discount ? 1 : null,
		exclude_owned: advancedFiltersForm.value.exclude_owned ? 1 : null,
		do_follow: advancedFiltersForm.value.do_follow ? 1 : null,
		publication_period: advancedFiltersForm.value.publication_period,
		write_content: advancedFiltersForm.value.write_content ? 1 : null,
		links_max_amount: advancedFiltersForm.value.links_max_amount,
		promo_facebook: null != advancedFiltersForm.value.promo && advancedFiltersForm.value.promo.length > 0 ? advancedFiltersForm.value.promo.find((elem:any) => elem === 1) ? 1 : null : null,
		promo_twitter: null != advancedFiltersForm.value.promo && advancedFiltersForm.value.promo.length > 0 ? advancedFiltersForm.value.promo.find((elem:any) => elem === 2) ? 1 : null : null,
		promo_newsletter: null != advancedFiltersForm.value.promo && advancedFiltersForm.value.promo.length > 0 ? advancedFiltersForm.value.promo.find((elem:any) => elem === 3) ? 1 : null : null,
		days_promotion_on_main_page: null != advancedFiltersForm.value.days_promotion_on_main_page ? advancedFiltersForm.value.days_promotion_on_main_page : (advancedFiltersForm.value.is_publication_on_main_page ? 1 : null),
		anchor_types: advancedFiltersForm.value.anchor_types,
		coupon: advancedFiltersForm.value.coupon,
	}
	try {
		const result = await monolithOrderApi.domainOfferList(domainId, filters);
		if (!result.isSuccess) {
			toast.error(`${t('order.publication.toast.errorGetDomainsOffers')}`);
			expandedLoading.value = false;
			return;
		}
		expandedItems.value = result.payload.data;
		
		// Dodanie zdarzenia do google analytics
		expandedItems.value.forEach((elem:any) => {
			gtm.pushEvent('Product - name', 'offer_domain_name', elem.url);
			gtm.pushEvent('Product - section name', 'offer_section_name', elem.section_name);
			gtm.pushEvent('Product - owner id', 'offer_owner_id', elem.user_id);
			gtm.pushEvent('Product - write content', 'offer_write_content', elem.write_content);
			gtm.pushEvent('Product - period', 'offer_period', elem.duration.name);
			gtm.pushEvent('Product - disclosure', 'offer_disclosure', elem.disclosure);
			gtm.pushEvent('Product - dofollow', 'offer_do_follow', elem.do_follow);
			gtm.pushEvent('Product - photo required', 'offer_article_photo', elem.article_photo);
			gtm.pushEvent('Product - promo facebook', 'offer_promo_facebook', elem.promo_facebook);
			gtm.pushEvent('Product - promo newsletter', 'offer_promo_newsletter', elem.promo_newsletter);
			gtm.pushEvent('Product - promo twitter', 'offer_promo_twitter', elem.promo_twitter);
			gtm.pushEvent('Product - allow banners', 'offer_allow_banners', elem.allow_banners);
			gtm.pushEvent('Product - links max amount', 'offer_links_max_amount', elem.links_max_amount);
			gtm.pushEvent('Product - days promotion on main page', 'offer_days_promotion_on_main_page', elem.days_promotion_on_main_page);
		});
		languageId.value = result.payload.data[0].language_id;
	} catch (e) {
		toast.error(`${t('order.publication.toast.errorGetDomainsOffers')}`);
		expandedLoading.value = false;
		return;
	}
	expandedLoading.value = false;
};

const articleTypes = async(languageId:number) => {
	loadingOptionsType.value = true;
	try {
		const result = await articleApiV1.articleTypes(languageId);
		if (!result.isSuccess) {
			toast.error(`${t('order.publication.toast.errorGetArticleTypes')}`);
			loadingOptionsType.value = false;
			return;
		}
		
		if (POLISH_LANGUAGE_ID === languageId) {
			articleTypesList.value = result.payload;
		} else {
			const typesList = result.payload;
			const specialistArticle = typesList.find(elem => elem.type === ARTICLE_TYPE_SPECIALIST);
			if (null != specialistArticle) {
				const index = typesList.indexOf(specialistArticle);
				typesList.splice(index, 1);
				articleTypesList.value = typesList;
			} else {
				articleTypesList.value = result.payload;
			}
		}
	} catch (e) {
		toast.error(`${t('order.publication.toast.errorGetArticleTypes')}`);
		loadingOptionsType.value = false;
		return;
	}
	loadingOptionsType.value = false;
};

const articleList = async(search?:string|null, isAddToCartAction?:boolean) => {
	loadingOptionsArticle.value = true;
	isAvailableArticlePrevSearchWasActive.value = false;
	if (null != search) {
		isAvailableArticlePrevSearchWasActive.value = true;
	}
	if (!isAvailableArticleLastPage.value || isAvailableArticlePrevSearchWasActive.value || isAddToCartAction) {
		try {
			const params:IArticleQueryParams = {
				page: availableArticleOptions.value.page,
				perPage: availableArticleOptions.value.itemsPerPage,
				search: search,
				title: null,
				direction: null,
				languageId: languageId.value,
				campaignId: null != user.value && isModerator(user.value) && null != cartCampaignId.value ? cartCampaignId.value : campaignId.value,
				userUuid: null != user.value && isModerator(user.value) && null != cartAssignedUuid.value ? cartAssignedUuid.value : null,
				articleType: null,
			};
			const result = await articleApi.articleList(params, null != user.value && (!isModerator((user.value) || (isModerator(user.value) && null != cartAssignedUuid.value))));
			if (!result.isSuccess) {
				toast.error(`${t('order.publication.toast.errorGetArticleList')}`);
				loadingOptionsArticle.value = false;
				return;
			}
			if (availableArticleOptions.value.page === 1) {
				ownArticleList.value = [];
			}
			if (result.payload.data.length > 0) {
				result.payload.data.forEach((elem: any) => {
					ownArticleList.value.push(elem);
				});
			}
			if (result.payload.last_page === availableArticleOptions.value.page) {
				isAvailableArticleLastPage.value = true;
			}
		} catch (e) {
			toast.error(`${t('order.publication.toast.errorGetArticleList')}`);
			loadingOptionsArticle.value = false;
			return;
		}
	}
	loadingOptionsArticle.value = false;
};

const expand = async(item:IMonolithElasticDomainItem|any) => {
	expandExpanded.value = [];
	chooseOption.value = null;
	articleTypeId.value = null;
	languageId.value = item.raw.language_id;
	ownArticleList.value = [];
	availableArticleOptions.value.page = 1;
	isAvailableArticleLastPage.value = false;
	const indexExpand = expanded.value.indexOf(item.value);
	if (!expanded.value.includes(item.value)) {
		expanded.value = [];
		expanded.value.push(item.value);
		await domainOffers(item.raw.id);
		await articleTypes(item.raw.language_id);
		if ((null != user.value && !isModerator(user.value)) || (null != user.value && isModerator(user.value) && !isMultiple.value)) {
			await articleList();
		}
	} else {
		expanded.value.splice(indexExpand, 1);
	}
};

const removeHttps = (url:string) => {
	if (2 === url.split('//').length) {
		return url.split('//')[1].split('/')[0];
	}
	return url.split('//')[0];
};

const detailsLoading = ref<boolean>(false);
const cartDetails = async(cartUuid: string | string[] | null) => {
	detailsLoading.value = true;
	try {
		const result = await cartApi.cartDetails(cartUuid);
		if (!result.isSuccess) {
			detailsLoading.value = false;
			await router.push('/carts');
			toast.error(`${t('basket.create.toast.detailsCartError')}`);
			return;
		}
		setCartNewOrder(result.payload);
		if (null != result.payload.new_order_items && result.payload.new_order_items.length > 0) {
			const publication = result.payload.new_order_items.filter(elem => elem.orderable_type === PUBLICATION_REQUEST);
			publicationItemsInBasket.value = publication.length;
		}
		if (null != result.payload.new_order && null != result.payload.new_order.gross) {
			grossPrice.value = result.payload.new_order.gross / 100;
		}
	} catch (e) {
		detailsLoading.value = false;
		await router.push('/carts');
		toast.error(`${t('basket.create.toast.detailsCartError')}`);
		return;
	}
	detailsLoading.value = false;
};

const orderDraftItems = async() => {
	try {
		const result = await orderApi.getOrders(campaignId.value, ORDER_DRAFT);
		if (!result.isSuccess) {
			toast.error(`${t('order.publication.toast.somethingWentWrong')}`);
			return;
		}
		if (result.payload.length > 0) {
			grossPrice.value = result.payload[0].gross / 100;
			const publication = result.payload[0].items.filter(elem => elem.orderable_type === PUBLICATION_REQUEST);
			publicationItemsInBasket.value = publication.length;
			
			basketId.value = result.payload[0].uuid;
			orderId.value = result.payload[0].id;
			cartName.value = result.payload[0].name;
			publicationUpdateDate.value = dateFormatWithTime(result.payload[0].updated_at);
		}
		if (result.payload.length === 0) {
			grossPrice.value = 0;
			publicationItemsInBasket.value = 0;
			basketId.value = null;
			orderId.value = null;
			publicationUpdateDate.value = '';
			if (null != expanded.value[0]) {
				await articleList(null , true);
			}
		}
	} catch (e) {
		toast.error(`${t('order.publication.toast.somethingWentWrong')}`);
		return;
	}
};
if (null != user.value && !isModerator(user.value)) {
	orderDraftItems();
} else if (isCartHasOrders && null != cartNewOrder.value) {
	if (null != cartNewOrder.value.new_order_items && cartNewOrder.value.new_order_items.length > 0) {
		const publication = cartNewOrder.value.new_order_items.filter(elem => elem.orderable_type === PUBLICATION_REQUEST);
		publicationItemsInBasket.value = publication.length;
	}
	if (null != cartNewOrder.value.new_order.gross) {
		grossPrice.value = cartNewOrder.value.new_order.gross / 100;
	}
}

watch((cartNewOrder), (val) => {
	if (val) {
		if (null != val.new_order_items && val.new_order_items.length > 0) {
			const publication = val.new_order_items.filter(elem => elem.orderable_type === PUBLICATION_REQUEST);
			publicationItemsInBasket.value = publication.length;
		}
		if (null != val.new_order.gross) {
			grossPrice.value = val.new_order.gross / 100;
		}
	}
},{
	deep: true,
});

const toggleDetails = (item:IMonolithElasticDomainOffersResponseItem) => {
	if (item.id !== detailsActiveId.value) {
		detailsActiveId.value = item.id;
	} else {
		previousDetailsActiveId.value = detailsActiveId.value;
		detailsActiveId.value = -1;
	}
};

const expandExpandedAction = (item:IMonolithElasticDomainOffersResponseItem|any) => {
	offerId.value = null;
	articleTypeId.value = null;
	chooseOption.value = null != user.value && isModerator(user.value) ? OPTION_TO_CHOOSE : null;
	const indexExpand = expandExpanded.value.indexOf(item.value);
	if (!expandExpanded.value.includes(item.value)) {
		expandExpanded.value = [];
		expandExpanded.value.push(item.value);
		offerId.value = item.raw.id;
	} else {
		expandExpanded.value.splice(indexExpand, 1);
	}
};

const dialogSaveArticle = async(articleId:number, article:ICreateArticleResponse|any) => {
	ownArticleList.value.push(article);
	closeDialogWhenNoTargetPressed();
	chooseOption.value = OPTION_OWN_ARTICLE;
	selectedArticle.value = articleId;
};

const goBack = () => {
	router.push('/order/publication/cart');
};

watch((articleTypeId), (val) => {
	if (val || 0 === val) {
		isCloseOrderArticleDialog.value = false;
		isOpenOrderArticleDialog.value = true;
	}
});

const dialogSaveOrderArticle = async(dialogArticleForm:any) => {
	await addToCart(dialogArticleForm);
	closeDialogWhenNoTargetPressed();
};

const addToCart = async(dialogArticleForm:any|null) => {
	updateCartLoading.value = true;
	
	let publicationOrderForm:IOrderPublicationRequest|any = {};
	
	if (null != dialogArticleForm) {
		publicationOrderForm = {
			offer_id: offerId.value,
			language_id: languageId.value,
			campaign_id: null != user.value && !isModerator(user.value) ? campaignId.value : cartCampaignId.value,
			write_option: chooseOption.value === OPTION_OWN_ARTICLE ? ARTICLE_WRITE_OPTION_OWN : chooseOption.value === OPTION_ORDER_ARTICLE_M9 ? ARTICLE_WRITE_OPTION_M9 : chooseOption.value === OPTION_TO_CHOOSE ? ARTICLE_WRITE_OPTION_TO_CHOOSE : ARTICLE_WRITE_OPTION_PUBLISHER,
			...(0 !== articleTypeId.value
				? { article_type_id: articleTypeId.value }
				: { article_uuid: '' }),
			subject: dialogArticleForm.title,
			comment: dialogArticleForm.reports,
			links: dialogArticleForm.links,
			email: user.value?.email,
			phone: user.value?.addresses[0].phone,
			contact: 'email',
			coupon: advancedFiltersForm.value.coupon,
		};
	} else {
		publicationOrderForm = {
			offer_id: offerId.value,
			language_id: languageId.value,
			campaign_id: null != user.value && !isModerator(user.value) ? campaignId.value : cartCampaignId.value,
			write_option: chooseOption.value === OPTION_OWN_ARTICLE ? ARTICLE_WRITE_OPTION_OWN : chooseOption.value === OPTION_ORDER_ARTICLE_M9 ? ARTICLE_WRITE_OPTION_M9 : chooseOption.value === OPTION_TO_CHOOSE ? ARTICLE_WRITE_OPTION_TO_CHOOSE : ARTICLE_WRITE_OPTION_PUBLISHER,
			article_uuid: selectedArticle.value,
			coupon: advancedFiltersForm.value.coupon,
		};
	}
	
	if (null == basketId.value && !isCartHasOrders.value) {
		try {
			const result = null != user.value && !isModerator(user.value) ? await orderApi.createPublicationOrder(publicationOrderForm) : await cartApi.createCartPublicationOrder(publicationOrderForm, cartUuid.value);
			if (!result.isSuccess) {
				// toast.error(`${t('order.publication.toast.errorUpdateCart')}`);
				updateCartLoading.value = false;
				return;
			}
			toast.success(`${t('order.publication.toast.successUpdateCart')}`);
			if (null != user.value && !isModerator(user.value)) {
				await orderDraftItems();
			}
			await setIsActiveCart(true);
			if (null != languageId.value && null != user.value && !isModerator(user.value)) {
				await articleList(null, true);
			} else {
				await setIsCartHasOrders(true);
				await cartDetails(router.currentRoute.value.params.cartUuid);
			}
			selectedArticle.value = null;
			
			// Dodanie zdarzenia do google analyticsa
			gtm.pushEvent('Publication - Items added to cart', 'publication_items_added_to_cart', chooseOption.value === OPTION_OWN_ARTICLE ? 1 : 2);
			if (chooseOption.value !== OPTION_OWN_ARTICLE) {
				const articleTypeObject = articleTypesList.value.find(elem => elem.id === articleTypeId.value);
				if (null != articleTypeObject && 'standard' === articleTypeObject.type) {
					gtm.pushEvent('Publication - Standard article', 'publication_standard_article', 1);
				}
				if (null != articleTypeObject && 'technical' === articleTypeObject.type) {
					gtm.pushEvent('Publication - Technical article', 'publication_technical_article', 1);
				}
				if (null != articleTypeObject && 'premium' === articleTypeObject.type) {
					gtm.pushEvent('Publication - Premium article', 'publication_premium_article', 1);
				}
			}
		} catch (e) {
			toast.error(`${t('order.publication.toast.errorUpdateCart')}`);
			updateCartLoading.value = false;
			return;
		}
	} else {
		try {
			const result = null != user.value && !isModerator(user.value) ? await orderApi.updatePublicationOrder(basketId.value, publicationOrderForm) : await cartApi.updateCartPublicationOrder(publicationOrderForm, cartUuid.value);
			if (!result.isSuccess) {
				// toast.error(`${t('order.publication.toast.errorUpdateCart')}`);
				updateCartLoading.value = false;
				return;
			}
			toast.success(`${t('order.publication.toast.successUpdateCart')}`);
			await setIsActiveCart(true);
			if (null != user.value && !isModerator(user.value)) {
				await orderDraftItems();
			} else {
				await cartDetails(router.currentRoute.value.params.cartUuid);
			}
			if (null != languageId.value && null != user.value && !isModerator(user.value)) {
				await articleList(null, true);
			}
			selectedArticle.value = null;
			
			// Dodanie zdarzenia do google analyticsa
			gtm.pushEvent('Publication - Items added to cart', 'publication_items_added_to_cart', chooseOption.value === OPTION_OWN_ARTICLE ? 1 : 2);
			if (chooseOption.value !== OPTION_OWN_ARTICLE) {
				const articleTypeObject = articleTypesList.value.find(elem => elem.id === articleTypeId.value);
				if (null != articleTypeObject && 'standard' === articleTypeObject.type) {
					gtm.pushEvent('Publication - Standard article', 'publication_standard_article', 1);
				}
				if (null != articleTypeObject && 'technical' === articleTypeObject.type) {
					gtm.pushEvent('Publication - Technical article', 'publication_technical_article', 1);
				}
				if (null != articleTypeObject && 'premium' === articleTypeObject.type) {
					gtm.pushEvent('Publication - Premium article', 'publication_premium_article', 1);
				}
			}
		} catch (e) {
			toast.error(`${t('order.publication.toast.errorUpdateCart')}`);
			updateCartLoading.value = false;
			return;
		}
	}
	updateCartLoading.value = false;
};

const fetchArticleOnScrollAction = async() => {
	availableArticleOptions.value.page = availableArticleOptions.value.page + 1;
	await articleList();
};

const fetchArticleOnTypeStringAction = (searchKey:string) => {
	ownArticleList.value = [];
	availableArticleOptions.value.page = 1;
	isAvailableArticleLastPage.value = false;
	
	clearTimeout(availableArticleSearchTimeout.value);
	availableArticleSearchTimeout.value = setTimeout(async() => {
		await articleList(searchKey);
	}, 700);
};

const fetchClearAction = async() => {
	if (availableArticleOptions.value.page > 1 || isAvailableArticlePrevSearchWasActive.value) {
		ownArticleList.value = [];
		availableArticleOptions.value.page = 1;
		isAvailableArticleLastPage.value = false;
		await articleList(null);
	}
};

watch((aiSwitch), async(val) => {
	if (val) {
		await clearFilters(true, true);
		await checkCampaignAISuggestions();
	} else {
		advancedFiltersForm.value.include_ids = [];
	}
},{
	deep: true,
	immediate: true,
});

const openClearDialog = () => {
	isOpenClearCartDialog.value = true;
	isClosedClearCartDialog.value = false;
};

const clearBasket = async() => {
	if (null == orderId.value) {
		closeDialogWhenNoTargetPressed();
		toast.error(`${t('publications.toast.errorClearBasket')}`);
		return;
	}
	try {
		const result = await monolithPublicationApi.clearBasket(orderId.value);
		if (!result.isSuccess) {
			closeDialogWhenNoTargetPressed();
			toast.error(`${t('publications.toast.errorClearBasket')}`);
			return;
		}
		closeDialogWhenNoTargetPressed();
		toast.success(`${t('publications.toast.successClearBasket')}`);
		await orderDraftItems();
	} catch (e) {
		closeDialogWhenNoTargetPressed();
		toast.error(`${t('publications.toast.errorClearBasket')}`);
		return;
	}
};

const handleEditCartName = (newCartName: string) => {
	cartName.value = newCartName;
};

const checkIsPromoCode = (coupons: IMonolithElasticDomainOffersCouponResponse[] | null) => {
	if (coupons == null || coupons.length === 0) {
		return false;
	}
	const promoCode = coupons.find(elem => elem.code === PROMO_CODE);
	if (null != promoCode) {
		return true;
	}
	return false;
};

const checkIsPromoCodeInDomain = (coupons: string[] | null) => {
	if (coupons == null || coupons.length === 0) {
		return false;
	}
	const promoCode = coupons.find(elem => elem === PROMO_CODE);
	if (null != promoCode) {
		return true;
	}
	return false;
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/vuetifyDataTable';
.order-create {
	&__heading-label {
		margin-top: 12px;
		margin-bottom: 3px;
	}
	
	&__modal-wrapper {
		display: flex;
		flex-direction: column;
	}
	
	&__img-wrapper {
		width: 100%;
		
		img {
			width: 100%;
		}
	}
	
	&__loading {
		opacity: 0.6;
	}
	
	&__custom-header-wrapper {
		display: flex;
		align-items: flex-end;
		gap: 3px;
	}
	
	&__hide {
		opacity: 0;
	}
	
	&__second-top-wrapper {
		margin-top: 2px;
		padding: 15px;
		background-color: $white-100;
		display: flex;
		align-items: center;
		gap: 15px;
	}
	
	&__website-item {
		color: $secondary-400;
		text-decoration: none;
		margin-right: 8px;
		font-weight: 700;
		font-size: 14px;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		&--icon {
			margin-left: 5px;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	&__website-name {
		position: relative;
		align-items: center;
		display: flex;
		gap: 5px;
	}
	
	&__trusted {
		img {
			width: 20px;
		}
	}
	
	&__country-wrapper {
		display: flex;
		align-items: center;
		font-size: 14px;
		
		.flag {
			margin-top: -4px;
			
			@include media-breakpoint-up(xl) {
				margin: -4px -0.6em -0.3em -0.7em;
			}
		}
	}
	
	&__description {
		font-size: 14px;
		color: $white-100;
		background-color: $primary-300;
		border-radius: 8px;
		text-align: center;
		width: max-content;
		padding: 7px 15px;
		font-weight: 700;
		cursor: default;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__font-14 {
		font-size: 14px;
	}
	
	&__prize-wrapper {
		font-size: 14px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: -14px;
	}
	
	&__inline {
		display: flex;
		gap: 5px;
		align-items: center;
		
		&:deep(.m-tooltip) {
			margin-left: 3px;
			color: $primary-400;
			margin-top: 3px;
		}
	}
	
	&__pound {
		color: $green-400;
	}
	
	&__second-price-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		
		div {
			&:first-child {
				color: $green-400;
			}
		}
		
		&--grey {
			color: $grey-400 !important;
			text-decoration: line-through;
		}
		
		&--red {
			color: $secondary-400 !important;
			font-size: 17px;
		}
		
		&--green {
			color: $green-400 !important;
		}
		
		&--bold {
			font-weight: 700 !important;
		}
		
		&--size-15 {
			font-size: 15px;
			font-weight: 700;
		}
		
		&--holiday {
			display: flex;
			align-items: center;
			gap: 5px;
			text-transform: uppercase;
			background-color: #CB8184;
			border-radius: 8px;
			color: $white-100;
			width: max-content;
			padding: 4px 10px;
			margin-bottom: 3px;
		}
	}
	
	&__no-data-text {
		font-size: 16px;
		color: $primary-400;
		margin: 20px 0;
		
		&--expand {
			color: $white-100;
		}
	}
	
	&__no-data-btn-wrapper {
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
	}
	
	&__expand-action-wrapper {
		display: flex;
		align-items: center;
		gap: 10px;
		background-color: $secondary-400;
		padding: 5px 10px;
		border-radius: 8px;
		width: max-content;
		color: $white-100;
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&--green {
			background-color: $green-400;
			padding: 9px 12px;
		}
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__expand-icon-rotate {
		transform: rotateZ(180deg);
	}
	
	&__second-table {
		background-color: $primary-400;
		color: $white-100;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 10px;
	}
	
	&__choose {
		font-size: 14px;
		font-weight: 700;
		
		@include media-breakpoint-up(sm) {
			font-size: 21px;
			
		}
	}
	
	&__campaign {
		font-size: 14px;
		
		@include media-breakpoint-up(xl) {
			font-size: 16px;
		}
		
		span {
			color: $secondary-400;
			
			@include media-breakpoint-up(sm) {
				margin-left: 5px;
			}
		}
	}
	
	&__publishers {
		font-size: 14px;
		
		span {
			color: $secondary-400;
			
			@include media-breakpoint-up(sm) {
				margin-left: 5px;
			}
		}
	}
	
	&__size-14 {
		font-size: 14px;
	}
	
	&__read {
		position: relative;
	}
	
	&__read-wrapper {
		display: flex;
		flex-direction: column;
		height: 45px;
		margin: 17px 0 0;
		overflow-y: hidden;
		
		@include media-breakpoint-up(xl) {
			height: 47px;
		}
		
		&--hide {
			height: 45px;
			overflow-y: hidden;
			animation: hideDetails 0.5s;
			
			@include media-breakpoint-up(xl) {
				height: 47px;
			}
			
			@keyframes hideDetails {
				from {
					height: 260px;
				}
				
				to {
					height: 47px;
				}
			}
		}
		
		&--active {
			height: 260px;
			overflow-y: visible;
			animation: showDetails 0.5s;
			
			@keyframes showDetails {
				from {
					height: 47px;
					overflow-y: hidden;
				}
				
				to {
					height: 260px;
					overflow-y: visible;
				}
			}
		}
	}
	
	&__read-btn {
		font-size: 12px;
		font-weight: 700;
		cursor: pointer;
		color: $secondary-400;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__read-item {
		font-size: 14px;
		display: flex;
		align-items: center;
		gap: 10px;
		width: max-content;
		
		&--bold {
			font-weight: 700;
		}
	}
	
	&__read-circle {
		width: 6px;
		height: 6px;
		background-color: $secondary-400;
		border-radius: 50%;
		box-shadow: 0 0 5px $black;
	}
	
	&__section {
		padding: 15px 0;
	}
	
	&__choose-wrapper {
		background-color: $primary-400 !important;
		border-bottom: 1px solid $white-100;
		border-top: 1px solid $white-100;
		padding: 20px 7px;
	}
	
	&__choose-option {
		padding: 10px 15px 0;
		
		&:deep(.m-btn) {
			padding: 19.5px 32px !important;
		}
	}
	
	&__add-new-article-wrapper {
		display: flex;
		justify-content: flex-end;
		gap: 10px;
	}
	
	&__own-article-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	
	&__own-article-btn-wrapper {
		display: flex;
		justify-content: flex-end;
		margin-top: 10px;
	}
	
	&__update-circular-wrapper {
		display: flex;
		align-items: center;
		gap: 5px;
	}
	
	&__ai-switcher-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
		&:deep(.v-switch .v-input__details) {
			display: none;
		}
	}
	
	&__ai-switcher-text {
		color: $primary-400;
		font-weight: 700;
	}
	
	&__back-btn-wrapper {
		margin-top: 10px;
		
		&:deep(.m-btn) {
			width: 120px;
		}
	}
	
	&__option-choose-wrapper {
		&:deep(.v-selection-control-group) {
			justify-content: flex-end;
			margin-right: 20px;
		}
		&:deep(.v-input__details) {
			display: none;
		}
		
		&--mod {
			&:deep(.v-selection-control-group) {
				margin-right: 47px;
			}
		}
	}
	
	&__clear-dialog-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	
	&__clear-dialog-text {
		font-size: 16px;
		text-align: center;
		color: $primary-400;
	}
	
	&__clear-dialog-btn-wrapper {
		margin: 20px 0;
		display: flex;
		gap: 40px;
		justify-content: center;
		
		&:deep(.m-btn) {
			width: 130px;
			height: 42px;
			font-weight: 700;
		}
	}
	
	&:deep(.v-progress-circular) {
		background-color: transparent;
	}
	
	&:deep(.v-selection-control--inline .v-label) {
		font-size: 12px;
		color: $white-100;
		opacity: 1;
	}
	
	&:deep(.v-theme--dark .v-table__wrapper > table > thead > tr th) {
		color: $white-100;
	}
	
	&:deep(.v-theme--dark .v-table__wrapper) {
		border-radius: 0;
	}
	
	&:deep(.v-data-table__td) {
		&:hover {
			i {
				opacity: 0.5;
			}
		}
	}
	
	&:deep(.v-data-table th) {
		background-color: $grey-100;
		color: $primary-400;
		font-weight: 700;
		height: 82px;
	}
	
	&:deep(.v-data-table-footer .v-field__field) {
		height: 40px;
	}
	
	&:deep(.v-data-table-footer .v-field__input) {
		font-size: 12px;
		padding: 11px 0 0 10px;
	}
	
	&:deep(.v-data-table-footer .v-input) {
		height: 40px;
	}
}
</style>
