<template>
    <component
        :id="id"
        :is="type"
        :type="type"
        :to="to"
        :elevation="elevation"
        :big="big"
        :green300="green300"
        :green400="green400"
        :grey300="grey300"
        :grey400="grey400"
        :red400="red400"
        :blue400="blue400"
        :transparent="transparent"
        :disabled="disabled"
        depressed
        class="m-btn"
        :class="[{
            'm-link': to,
            'm-btn__big': big,
            'm-btn__normal': normal,
            'm-btn__grey400': grey400,
            'm-btn__grey300': grey300,
            'm-btn__green400': green400,
            'm-btn__green300': green300,
            'm-btn__red400': red400,
            'm-btn__blue400': blue400,
            'm-btn__transparent': transparent,
            'm-btn__transparent-with-border': transparentWithBorder,
            'm-btn__bold': bold,
            'm-btn__disabled': disabled,
            'm-btn__icon-margin': iconAfterText,
            'm-btn__dashboard': dashboard,
            'm-btn__uppercase': uppercase,
            'm-btn__100': width100,
            'm-btn__110': width110,
            'm-btn__120': width120,
            'm-btn__130': width130,
            'm-btn__140': width140,
            'm-btn__150': width150,
            'm-btn__160': width160,
            'm-btn__170': width170,
            'm-btn__180': width180,
            'm-btn__190': width190,
            'm-btn__200': width200,
            'm-btn__205': width205,
            'm-btn__210': width210,
        }]"
        @click="onClick"
    >
        <slot name="beforeText"></slot>
        <MIcon v-if="icon != null" :icon="icon" :color="color" :height="height" :width="width" />
        <v-badge v-if="badgeValue != null" :content="badgeValue" />
        <div :class="{'m-btn__text': icon}">{{ label }}</div>
	    <MIcon v-if="iconAfterText != null" :icon="iconAfterText" :color="color" :height="height" :width="width" />
        <slot></slot>
	    <v-progress-circular class="m-btn__loader" v-if="loading" :size="20" :width="3" :color="loadingColor" indeterminate />
    </component>
</template>

<script setup lang="ts">
import { defineProps, computed } from 'vue';
import MIcon from '@/components/atoms/MIcon.vue';
import type { TIcon } from '@/components/atoms/MIcon.vue';

interface IMIconProps {
    icon?: TIcon
	iconAfterText?: TIcon
    color?: string
    height?: string
    width?: string
}
interface IOwnProps {
    label?: string
    to?: string
    big?: boolean
    normal?: boolean
	dashboard?: boolean
    elevation?: number
    grey300?: boolean
    grey400?: boolean
    green300?: boolean
    green400?: boolean
    red400?: boolean
    blue400?: boolean
    transparent?: boolean
	transparentWithBorder?: boolean
    disabled?: boolean
    badgeValue?: string|number
    id?: string|null
    type?: string
	bold?: boolean
	loading?: boolean
	loadingColor?: string
	onClick?: Function
	uppercase?: boolean
	width100?: boolean
	width110?: boolean
	width120?: boolean
	width130?: boolean
	width140?: boolean
	width150?: boolean
	width160?: boolean
	width170?: boolean
	width180?: boolean
	width190?: boolean
	width200?: boolean
	width205?: boolean
	width210?: boolean
}
interface IProps extends IOwnProps, IMIconProps {
}

const props = defineProps<IProps>();

const type = computed<string>(() => {
    if (props.to) {
        return 'router-link';
    }
    return 'v-btn';
});

</script>

<style scoped lang="scss">
.v-btn {
    text-transform: none;
}

.m-btn {
    display: flex;
    border-radius: 8px;
    transition: opacity 0.3s;
	color: $white-100 !important;

    &:hover {
        opacity: 0.5;
    }

    &__text {
        margin-left: 5px;
    }

    &__big {
        font-size: 14px !important;
        color: $white-100 !important;
        font-weight: 700;
        letter-spacing: 0 !important;
        padding: 33px 29px !important;

        .m-btn__text {
            margin-left: 15px;
        }
    }

    &__normal {
        font-size: 14px !important;
        color: $white-100 !important;
        font-weight: 700;
        letter-spacing: 0 !important;
        padding: 25px 18px !important;

        .m-btn__text {
            margin-left: 8px;
        }
    }

    &__grey300 {
        background-color: $grey-300 !important;
    }

    &__grey400 {
        background-color: $grey-400 !important;
    }

    &__green300 {
        background-color: $green-300 !important;
    }

    &__green400 {
        background-color: $green-400 !important;
    }

    &__red400 {
        background-color: $secondary-400 !important;
    }

    &__blue400 {
        background-color: $primary-400 !important;
    }

    &__disabled {
        background-color: $grey-400 !important;
    }

    &__transparent {
        background-color: transparent !important;
        box-shadow: none !important;
        
        &:hover {
            border-radius: 0 !important;
        }
    }

    &__transparent-with-border {
        background-color: transparent !important;
	    box-shadow: none !important;
	    border: 1px solid $primary-400;
	    color: $primary-400 !important;
    }

    &__bold {
        font-weight: 700;
    }
	
	&__icon-margin {
		svg {
			margin-left: 7px;
		}
	}
	
	&__loader {
		margin-left: 7px;
	}
	
	&__dashboard {
		border-radius: 100px;
		font-size: 12px;
		font-weight: 600;
	}
	
	&__uppercase {
		text-transform: uppercase;
	}
	
	&__100 {
		width: 100px;
	}
	
	&__110 {
		width: 110px;
	}
	
	&__120 {
		width: 120px;
	}
	
	&__130 {
		width: 130px;
	}
	
	&__140 {
		width: 140px;
	}
	
	&__150 {
		width: 150px;
	}
	
	&__160 {
		width: 160px;
	}
	
	&__170 {
		width: 170px;
	}
	
	&__180 {
		width: 180px;
	}
	
	&__190 {
		width: 190px;
	}
	
	&__200 {
		width: 200px;
	}
	
	&__205 {
		width: 205px;
	}
	
	&__210 {
		width: 210px;
	}
}

.m-link {
    width: max-content;
    text-decoration: none;
    color: grey;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
        opacity: 0.5;
    }
}

</style>

