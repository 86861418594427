<template>
	<div class="select-campaign-table">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template #loading>
				<div class="select-campaign-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #top>
				<div class="select-campaign-table__top-wrapper">
					<div class="select-campaign-table__search-wrapper">
						<MTextField
							id="campaign-search"
							v-model="search"
							name="search"
							variant="outlined"
							label=""
							:placeholder="$t('campaigns.search')"
							clearable
						/>
					</div>
					<div v-if="null != user && user.is_agency" class="select-campaign-table__search-wrapper">
						<MAutocomplete
							id="article-dependent-account"
							v-model="dependentAccountUuid"
							:get-items="dependentAccountList"
							clearable
							variant="outlined"
							item-title="email"
							item-value="uuid"
							:placeholder="$t('campaigns.selectAccount')"
						/>
					</div>
					
					<div class="select-campaign-table__btn-wrapper">
						<MButton
							id="go-to-feed-without-campaign"
							blue400
							big
							width205
							:label="$t('publications.orderWithoutCampaign')"
							@click="goToOfferFeed(null)"
						/>
					</div>
				</div>
			</template>
			
			<template #item.campaigns.created_at="elem">
				<div v-if="null != elem.item.raw.created_at">{{ dateFormatWithTime(elem.item.raw.created_at) }}</div>
			</template>
			
			<template #item.account="elem">
				<div v-if="null != elem.item.raw.user_email">
					<div>{{ elem.item.raw.user_first_name }} {{ elem.item.raw.user_last_name }}</div>
					<div>{{ elem.item.raw.user_email }}</div>
				</div>
			</template>
			
			<template #item.name="elem">
				<div v-if="null != elem.item.raw.name" class="m9-data-table-align-left">{{ elem.item.raw.name }}</div>
			</template>
			
			<template #item.url="elem">
				<div v-if="null != elem.item.raw.url" class="m9-data-table-align-left">{{ elem.item.raw.url }}</div>
			</template>
			
			<template #item.articleReadyToPublish="elem">
				<div v-if="null != elem.item.raw.articles_quantity" class="select-campaign-table__bold">{{ elem.item.raw.articles_quantity }}</div>
			</template>
			
			<template #item.actions="elem">
				<div v-if="elem.item.raw.campaigns_orders === 0" class="select-campaign-table__action-wrapper">
					<MButton
						id="see-websites-btn"
						width100
						green400
						:label="$t('publications.seeAllWebsites')"
						@click="goToOfferFeed(elem.item.raw.id)"
					/>
				</div>
				<div v-if="elem.item.raw.campaigns_orders > 0" class="select-campaign-table__action-wrapper">
					<MButton
						id="clear-basket-dialog-btn"
						grey300
						width100
						bold
						:label="$t('publications.clearBasket')"
						@click="openClearDialog(elem.item.raw.order_id)"
					/>
					<MButton
						id="continue-shopping-btn"
						width100
						green400
						bold
						:label="$t('publications.continueShopping')"
						@click="goToOfferFeed(elem.item.raw.id)"
					/>
				</div>
			</template>
			
			<template #footer.prepend>
				<div class="select-campaign-table__footer-prepend-wrapper">
					<div class="select-campaign-table__csv-btn">
						<MTooltip without-btn>
							<template v-slot:title>
								<MIcon id="csv-btn" icon="csv-file" width="20" height="20" @click="downloadToCsv" />
							</template>
							<template v-slot:content>
								<div>{{ $t('components.downloadCsv') }}</div>
							</template>
						</MTooltip>
					</div>
				</div>
			</template>
		</v-data-table-server>
		
		<MDialog
			:width="650"
			:is-close-dialog-action="isClosedClearCartDialog"
			:is-open-dialog-action="isOpenClearCartDialog"
			:content-title="$t('publications.clearBasket')"
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<div class="select-campaign-table__clear-dialog-wrapper">
					<div class="select-campaign-table__clear-dialog-text">{{ $t('publications.clearCartDescription') }}</div>
					<div class="select-campaign-table__clear-dialog-btn-wrapper">
						<MButton
							id="clear-basket-btn"
							red400
							:label="$t('publications.clearBasket')"
							@click="clearBasket"
						/>
						<MButton
							id="clear-basket-back-btn"
							blue400
							:label="$t('components.back')"
							@click="closeDialogWhenNoTargetPressed"
						/>
					</div>
				</div>
			</template>
		</MDialog>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { ref, watch } from 'vue';
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useBooleanFlagStore } from '@/stores/booleanFlag';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import MonolithPublicationApi from '@/api/monolith/MonolithPublicationApi';
import AgencyApi from '@/api/v1/AgencyApi';
import { dateFormatWithTime } from '@/hooks/DataHooks';
import { exportTableToCsv } from '@/hooks/ExportCsvHook';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { AgencyItemListInterface } from '@/models/AgencyModel';
import type { IMonolithPublicationCampaignListItem } from '@/models/PublicationModel';
import MButton from '@/components/atoms/MButton.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MDialog from '@/components/atoms/MDialog.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';

const monolithPublicationApi = new MonolithPublicationApi();
const agencyApi = new AgencyApi();

const { user } = storeToRefs(useUserStore());
const { t } = useI18n();
const { setIsAiOfferFilters } = useBooleanFlagStore();

const loading = ref<boolean>(false);
const headers = ref<Array<TableHeaderInterface>|any>([]);
const items = ref<Array<IMonolithPublicationCampaignListItem>|any>([]);
const total = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const search = ref<string|null>(null);
const searchTimeout = ref<any>(undefined);
const dependentAccountUuid = ref<string|null>(null);
const dependentAccountList = ref<Array<AgencyItemListInterface>|any>([]);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const defaultHeaders = () => {
	const result = [
		{
			title: `${t('publications.createdAt')}`,
			align: 'center',
			sortable: true,
			key: 'campaigns.created_at',
			width: '150px',
		},
	];
	
	if (null != user && user.value?.is_agency) {
		result.push({
			title: `${t('publications.account')}`,
			align: 'center',
			sortable: false,
			key: 'account',
			width: '',
		});
	}
	
	result.push(...[
		{
			title: `${t('publications.campaignName')}`,
			align: 'center',
			sortable: true,
			key: 'name',
			width: '',
		},
		{
			title: `${t('publications.campaignUrl')}`,
			align: 'center',
			sortable: true,
			key: 'url',
			width: '',
		},
		{
			title: `${t('publications.articleReadyToPublish')}`,
			align: 'center',
			sortable: false,
			key: 'articleReadyToPublish',
			width: '120px',
		},
		{
			title: `${t('publications.actions')}`,
			align: 'center',
			sortable: false,
			key: 'actions',
			width: '180px',
		},
	]);
	
	return result;
};
headers.value = defaultHeaders();

const agencyDependentAccountList = async() => {
	if (null == user.value) {
		return;
	}
	try {
		const result = await agencyApi.agencyUserList(user.value.uuid);
		if (!result.isSuccess) {
			return;
		}
		dependentAccountList.value = result.payload.data;
		dependentAccountList.value.push(user.value);
	} catch (e) {
		return;
	}
};
if (user.value?.is_agency) {
	agencyDependentAccountList();
}

const campaignList = async() => {
	loading.value = true;
	
	try {
		const result = await monolithPublicationApi.campaignPublicationList(
			options.value.page,
			options.value.itemsPerPage,
			options.value.sortBy.length > 0 ? options.value.sortBy[0].key : null,
			options.value.sortBy.length > 0 ? options.value.sortBy[0].order : null,
			search.value,
			dependentAccountUuid.value
		);
		if (!result.isSuccess) {
			toast.error(`${t('publications.toast.errorCampaignsList')}`);
			loading.value = false;
			return;
		}
		
		items.value = result.payload.data;
		currentPage.value = result.payload.current_page;
		lastPage.value = result.payload.last_page;
		total.value = result.payload.total;
	} catch (e) {
		toast.error(`${t('publications.toast.errorCampaignsList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	await campaignList();
};

const goToOfferFeed = (campaignId:number|null) => {
	setIsAiOfferFilters(false);
	router.push(`/websites/${campaignId}/orders`);
};
const clearBasket = async() => {
	loading.value = true;
	if (null == orderId.value) {
		closeDialogWhenNoTargetPressed();
		toast.error(`${t('publications.toast.errorClearBasket')}`);
		loading.value = false;
		return;
	}
	try {
		const result = await monolithPublicationApi.clearBasket(orderId.value);
		if (!result.isSuccess) {
			closeDialogWhenNoTargetPressed();
			toast.error(`${t('publications.toast.errorClearBasket')}`);
			loading.value = false;
			return;
		}
		closeDialogWhenNoTargetPressed();
		toast.success(`${t('publications.toast.successClearBasket')}`);
		await campaignList();
	} catch (e) {
		closeDialogWhenNoTargetPressed();
		toast.error(`${t('publications.toast.errorClearBasket')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};

watch(([dependentAccountUuid, search]), async (newVal, oldVal) => {
	if (newVal[0] !== oldVal[0] || newVal[1] !== oldVal[1]) {
		clearTimeout(searchTimeout.value);
		searchTimeout.value = setTimeout(async () => {
			await campaignList();
		}, 700);
	}
},
{
	deep: true,
});

const isOpenClearCartDialog = ref<boolean>(false);
const isClosedClearCartDialog = ref<boolean>(true);
const orderId = ref<number | null>(null);
const closeDialogWhenNoTargetPressed = () => {
	orderId.value = null;
	isOpenClearCartDialog.value = false;
	isClosedClearCartDialog.value = true;
};
const openClearDialog = (orderIdValue: number) => {
	orderId.value = orderIdValue;
	isOpenClearCartDialog.value = true;
	isClosedClearCartDialog.value = false;
};

const downloadToCsv = () => {
	let rowKeys: string[] = ['created_at', 'name', 'url', 'articles_quantity'];
	if (null != user.value && 1 === user.value.is_agency) {
		rowKeys = ['created_at', 'user_email', 'name', 'url', 'articles_quantity'];
	}
	exportTableToCsv(headers.value, items.value, rowKeys, `${t('components.toast.errorDownloadCsv')}`, `${t('components.toast.successDownloadCsv')}`, t, 'campaigns');
};
</script>

<style scoped lang="scss">
.select-campaign-table {
	@import '@/assets/scss/vuetifyDataTable.scss';
	&__loading {
		opacity: 0.5;
	}
	
	&__top-wrapper {
		@include media-breakpoint-up(md) {
			display: flex;
			flex-wrap: wrap;
			gap: 15px;
			padding: 0 20px;
			align-items: center;
		}
		
		header {
			max-width: max-content !important;
		}
	}
	
	&__search-wrapper {
		display: flex;
		align-items: center;
		height: 70px !important;
		
		&:deep(.v-text-field .v-input__details) {
			display: none;
		}
		
		&:deep(.m-input) {
			width: 300px;
		}
		
		&:deep(.m-autocomplete) {
			min-width: 200px;
		}
		
		&:deep(.m-input input) {
			min-height: 43px;
			padding: 0 12px;
		}
		
		&:deep(.v-field__input) {
			min-height: 43px !important;
			padding: 8px 2px;
		}
		
		span {
			margin-right: 10px;
			font-size: $size-14;
			color: $grey-400;
			font-weight: $weight-700;
		}
		
		&--status {
			&:deep(.m-autocomplete) {
				width: 300px;
			}
		}
	}
	
	&__bold {
		font-weight: 700;
	}
	
	&__action-wrapper {
		display: flex;
		gap: 5px;
		margin: 5px 0 5px auto;
		justify-content: flex-end;
		align-items: flex-end;
		
		&:deep(.m-btn) {
			font-size: 13px;
			height: 36px;
		}
	}
	
	&__btn-wrapper {
		display: flex;
		margin: 16px 0 16px auto;
	}
	
	&__clear-dialog-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	
	&__clear-dialog-text {
		font-size: 16px;
		text-align: center;
		color: $primary-400;
	}
	
	&__clear-dialog-btn-wrapper {
		margin: 20px 0;
		display: flex;
		gap: 40px;
		justify-content: center;
		
		&:deep(.m-btn) {
			width: 130px;
			height: 42px;
			font-weight: 700;
		}
	}
	
	&__footer-prepend-wrapper {
		margin-right: auto;
		margin-left: 10px;
	}
	
	&__csv-btn {
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
}
</style>
