<template>
	<div class="upload-article-table">
		<v-data-table
			:items="items"
			:headers="headers"
			:loading="loading || tableLoading"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:options="options"
			@update:options="optionsUpdate"
			hover
		>
			<template #loading>
				<div class="upload-article-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #item.title="elem">
				<div class="upload-article-table__title upload-article-table__title--minus m9-data-table-align-left">
					<MTextField
						id="upload-article-title"
						v-model="elem.item.raw.title"
						name="articleTitle"
						label-text-required
						:separate-label="$t('components.title')"
						:placeholder="$t('components.title')"
					/>
				</div>
			</template>
			
			<template #item.campaign_id="elem">
				<div v-if="!isDialog" class="upload-article-table__title m9-data-table-align-left">
					<MAutocomplete
						id="upload-article-campaign"
						name="articleCampaign"
						v-model="elem.item.raw.campaign_id"
						:get-items="campaigns"
						variant="outlined"
						item-title="name"
						item-value="id"
						:label="$t('articles.campaign')"
						:placeholder="$t('articles.campaign')"
						clearable
						@onChange="onCampaignChange(elem.item.raw.campaign_id, elem.index)"
					/>
					<div @click="applyValueForAllItems('campaign_id', elem.item.raw.campaign_id, elem.index)" class="upload-article-table__for-all">[{{ $t('articles.toAll') }}]</div>
				</div>
			</template>
			
			<template #item.language_id="elem">
				<div v-if="!isDialog" class="upload-article-table__title m9-data-table-align-left">
					<MAutocomplete
						id="upload-article-language"
						name="articleLanguage"
						v-model="elem.item.raw.language_id"
						label-text-required
						:get-items="languages"
						variant="outlined"
						item-title="name"
						item-value="id"
						:label="$t('articles.language')"
						:placeholder="$t('articles.language')"
						clearable
					/>
					<div @click="applyValueForAllItems('language_id', elem.item.raw.language_id)" class="upload-article-table__for-all">[{{ $t('articles.toAll') }}]</div>
				</div>
			</template>
			
			<template #item.note="elem">
				<div class="upload-article-table__title m9-data-table-align-left">
					<div class="upload-article-table__label">{{ $t('articles.note') }}</div>
					<MTextArea
						id="upload-article-note"
						name="articleNote"
						v-model="elem.item.raw.note"
						:label="$t('articles.note')"
						:placeholder="$t('articles.notesForPublisher')"
					/>
					<div @click="applyValueForAllItems('note', elem.item.raw.note)" class="upload-article-table__for-all">[{{ $t('articles.toAll') }}]</div>
				</div>
			</template>
			
			<template #item.footer_id="elem">
				<div v-if="!isDialog" class="upload-article-table__title m9-data-table-align-left">
					<MAutocomplete
						v-if="elem.item.raw.campaign_id && elem.item.raw.footer_list.length > 0"
						id="upload-article-footer"
						name="articleFooter"
						v-model="elem.item.raw.footer_id"
						:get-items="elem.item.raw.footer_list"
						variant="outlined"
						item-title="company_name"
						item-value="id"
						:label="$t('articles.articleFooter')"
						:placeholder="$t('articles.articleFooter')"
						clearable
					/>
					<div v-if="checkCampaign(items)" @click="applyValueForAllItems('footer_id', elem.item.raw.footer_id)" class="upload-article-table__for-all">[{{ $t('articles.toAll') }}]</div>
				</div>
			</template>
			
			<template #item.photo="elem">
				<div class="upload-article-table__title m9-data-table-align-left">
					<div class="upload-article-table__input-file-wrapper">
						<v-file-input
							v-model="elem.item.raw.photo"
							accept="image/png, image/jpeg, image/bmp"
							:placeholder="$t('order.article.uploadPhoto')"
							:label="$t('order.article.uploadPhoto')"
						/>
					</div>
				</div>
			</template>
			
			<template #item.actions="elem">
				<div @click="deleteItem(elem.index)" class="upload-article-table__hover">
					<MIcon icon="close" />
				</div>
			</template>
		</v-data-table>
		
		<div class="upload-article-table__btn-wrapper">
			<MButton
				v-if="!isDialog"
				id="back-btn"
				normal
				width100
				blue400
				:label="$t('articles.uploadBack')"
				@click="back"
			/>
			<MButton
				id="submit-btn"
				normal
				width100
				green400
				:label="$t('articles.uploadImport')"
				@click="submit"
				:disabled="checkIsFormComplete()"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { VDataTable } from 'vuetify/labs/VDataTable';
import { defineProps, ref, defineEmits, watch } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { useDictionaryStore } from '@/stores/dictionary';
import { toast } from 'vue3-toastify';
import { setLastPage } from '@/hooks/TableSettingsHooks';
import ArticleApi from '@/api/v1/ArticleApi';
import ImageApi from '@/api/v1/ImageApi';
import GoogleApi from '@/api/v1/GoogleApi';
import { ROUTE_GOOGLE_UPLOAD_ARTICLE } from '@/hooks/RoutingHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import type { IUploadArticleItem, IUploadGoogleArticleItemRequest } from '@/models/ArticlesModel';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import MIcon from '@/components/atoms/MIcon.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MTextArea from '@/components/atoms/MTextArea.vue';
import MButton from '@/components/atoms/MButton.vue';

interface IOwnProps {
	items: Array<IUploadArticleItem>
	loading: boolean
	isDialog?: boolean
	campaignId?: number | null
	languageId?: number | null
}
interface IProps extends IOwnProps {
}

const articleApi = new ArticleApi();
const imageApi = new ImageApi();
const googleApi = new GoogleApi();

const emit = defineEmits(['deleteItem', 'footerAction', 'applyToAll', 'backAction', 'dialogSaveArticle']);
const props = defineProps<IProps>();
const { t } = useI18n();
const { campaigns, languages } = useDictionaryStore();

const tableLoading = ref<boolean>(false);
const isUploadGoogleArticleView = ref<boolean>(false);
const currentPage = ref<number>(1);
const lastPage = ref<number>(1);
const headers = ref<Array<TableHeaderInterface>|any>([]);
const defaultHeaders = () => {
	const result = [
		{
			title: `${t('articles.titleTable')}`,
			align: 'center',
			sortable: false,
			key: 'title',
			width: '300px'
		},
	]
	
	if (!props.isDialog) {
		result.push(
			{
				title: `${t('articles.campaign')}`,
				align: 'center',
				sortable: false,
				key: 'campaign_id',
				width: '250px'
			},
			{
				title: `${t('articles.language')}`,
				align: 'center',
				sortable: false,
				key: 'language_id',
				width: '220px'
			},
		);
	}
	
	result.push(...[
		{
			title: `${t('articles.notesForPublisher')}`,
			align: 'center',
			sortable: false,
			key: 'note',
			width: '250px'
		},
		{
			title: `${t('articles.selectArticleFooter')}`,
			align: 'center',
			sortable: false,
			key: 'footer_id',
			width: '200px'
		},
		{
			title: `${t('articles.image')}`,
			align: 'center',
			sortable: false,
			key: 'photo',
			width: '150px'
		},
		{
			title: `${t('articles.actions')}`,
			align: 'center',
			sortable: false,
			key: 'actions',
			width: '60px'
		},
	]);
	
	return result;
};
headers.value = defaultHeaders();
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});

if (router.currentRoute.value.name === ROUTE_GOOGLE_UPLOAD_ARTICLE) {
	isUploadGoogleArticleView.value = true;
}

const deleteItem = (index: number) => {
	emit('deleteItem', index);
};

const onCampaignChange = (campaignId:number, index:number) => {
	if (!props.isDialog) {
		emit('footerAction', campaignId, index);
	}
};
const applyValueForAllItems = (column:string, value:number|string, index?:number) => {
	emit('applyToAll', column, value, index);
};

const checkCampaign = (tableItems:Array<IUploadArticleItem>|any) => {
	let isCampaignIdDiff = true;
	tableItems.forEach((elem:IUploadArticleItem) => {
		if (
			0 !== tableItems.indexOf(elem) && tableItems[tableItems.indexOf(elem) - 1].campaign_id !== elem.campaign_id ||
			tableItems[tableItems.indexOf(elem)].campaign_id == null ||
			tableItems[tableItems.indexOf(elem)].footer_list.length === 0
		) {
			isCampaignIdDiff = false;
		}
	});
	return isCampaignIdDiff;
};

const back = () => {
	if (!isUploadGoogleArticleView.value) {
		router.go(-1);
	} else {
		emit('backAction');
	}
};

const createArticle = async(item:IUploadArticleItem) => {
	if (
		null == item.language_id ||
		null == item.title ||
		null == item.file
	) {
		return;
	}
	
	const request = new FormData();
	if (null != item.campaign_id) {
		request.append('campaign_id', item.campaign_id.toString());
	}
	request.append('language_id', item.language_id.toString());
	request.append('title', item.title.toString());
	request.append('publisher_note', item.note || '');
	if (item.footer_id) {
		request.append('footer_id', item.footer_id.toString());
	}
	request.append('file', item.file);
	
	try {
		const result = await articleApi.uploadArticle(request);
		if (!result.isSuccess) {
			toast.error(`${t('articles.toast.createArticleError')}`);
			return;
		}
		emit('dialogSaveArticle', result.payload.id, result.payload);
		toast.success(`${t('articles.toast.success')}: ${result.payload.title}`);
		
		if (null != item.photo) {
			const resultImage = await imageApi.sendImage(result.payload.id, item.photo[0]);
			
			if (!resultImage.isSuccess) {
				toast.error(`${t('articles.toast.createArticleImageError')}`);
				return;
			}
		}
	} catch (e) {
		toast.error(`${t('articles.toast.createArticleError')}`);
		return;
	}
};

const importOnlineGoogleWordFile = async(item:IUploadArticleItem, request:IUploadGoogleArticleItemRequest) => {
	try {
		const result = await googleApi.sendGoogleOnlineFile(request);
		if (!result.isSuccess) {
			toast.error(`${t('articles.toast.createArticleError')}`);
			return;
		}
		toast.success(`${t('articles.toast.success')}: ${result.payload.title}`);
		
		if (null != item.photo) {
			const resultImage = await imageApi.sendImage(result.payload.id, item.photo[0]);
			
			if (!resultImage.isSuccess) {
				toast.error(`${t('articles.toast.createArticleImageError')}`);
				return;
			}
		}
	} catch (e) {
		toast.error(`${t('articles.toast.createArticleError')}`);
		return;
	}
};
const importUploadedWordFile = async(item:IUploadArticleItem, request:IUploadGoogleArticleItemRequest) => {
	try {
		const result = await googleApi.sendGoogleUploadedFile(request);
		if (!result.isSuccess) {
			toast.error(`${t('articles.toast.createArticleError')}`);
			return;
		}
		toast.success(`${t('articles.toast.success')}: ${result.payload.title}`);
		
		if (null != item.photo) {
			const resultImage = await imageApi.sendImage(result.payload.id, item.photo[0]);
			
			if (!resultImage.isSuccess) {
				toast.error(`${t('articles.toast.createArticleImageError')}`);
				return;
			}
		}
	} catch (e) {
		toast.error(`${t('articles.toast.createArticleError')}`);
		return;
	}
};

const sendGoogleFile = async(item:IUploadArticleItem) => {
	if (
		null == item.language_id ||
		null == item.title ||
		null == item.file_id
	) {
		return;
	}
	
	const request:IUploadGoogleArticleItemRequest = {
		file_id: item.file_id,
		campaign_id: null != item.campaign_id ? item.campaign_id : null,
		language_id: item.language_id,
		title: item.title,
		author: item.author,
		publisher_note: item.note,
		footer_id: item.footer_id ? item.footer_id : null
	}
	
	if ('application/vnd.google-apps.document' === item.mimeType) {
		await importOnlineGoogleWordFile(item, request);
	} else {
		await importUploadedWordFile(item, request);
	}
};
const submit = async() => {
	tableLoading.value = true;
	let isTableRequiredFieldWrongValidate = false;
	
	props.items.forEach(elem => {
		if (null == elem.title || '' === elem.title || null == elem.language_id) {
			isTableRequiredFieldWrongValidate = true;
		}
	});
	
	if (isTableRequiredFieldWrongValidate) {
		toast.error(`${t('articles.toast.uploadArticleValidateError')}`);
		tableLoading.value = false;
		return;
	}
	
	if (!isUploadGoogleArticleView.value) {
		for (const item of props.items) {
			await createArticle(item);
		}
		if (!props.isDialog) {
			await router.push('/article');
		}
	} else {
		for (const item of props.items) {
			await sendGoogleFile(item);
		}
		if (!props.isDialog) {
			await router.push('/article');
		}
	}
	
	tableLoading.value = false;
};

lastPage.value = setLastPage(options.value.itemsPerPage, props.items.length);
const optionsUpdate = (val:ITableOptions|any) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	currentPage.value = val.page;
	lastPage.value = setLastPage(val.itemsPerPage, props.items.length);
};

watch((props), () => {
	lastPage.value = setLastPage(options.value.itemsPerPage, props.items.length);
	checkIsFormComplete();
},{
	deep: true,
});

const checkIsFormComplete = ():boolean => {
	let isFormComplete:boolean = false;
	if (props.items.length > 0) {
		props.items.forEach((elem:any) => {
			if (null == elem.title || '' === elem.title || null == elem.language_id) {
				isFormComplete = true;
			}
		});
	} else {
		isFormComplete = true;
	}
	return isFormComplete;
};
</script>

<style scoped lang="scss">
.upload-article-table {
	@import '@/assets/scss/vuetifyDataTable';
	
	&__loading {
		opacity: 0.6;
	}
	
	&__hover {
		cursor: pointer;
		width: max-content;
		margin: 0 auto;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__title {
		margin-top: 10px;
		
		&--minus {
			margin-top: -8px;
		}
	}
	
	&__label {
		color: $grey-400;
		font-weight: 700;
		font-size: 14px;
	}
	
	&__input-file-wrapper {
		position: relative;
		background-color: $primary-400;
		width: 160px;
		transition: opacity 0.3s;
		cursor: pointer;
		
		&:hover {
			opacity: 0.5;
		}
		
		&:deep(.v-input__prepend) {
			display: none;
		}
		
		&:deep(.v-input__details) {
			display: none;
		}
		
		&:deep(.v-label) {
			opacity: 1;
			color: $white-100 !important;
		}
		
		&:deep(.v-field__input) {
			opacity: 1;
			color: $white-100 !important;
		}
		
		&:deep(.v-field__clearable) {
			opacity: 1;
			color: $white-100 !important;
		}
	}
	
	&__for-all {
		width: max-content;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__btn-wrapper {
		margin-top: 8px;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: space-between;
	}
	
	&:deep(.m-text-area) {
		textarea {
			height: 40px;
		}
	}
	
	&:deep(.v-data-table-footer .v-field__field) {
		height: 40px;
	}
	
	&:deep(.v-data-table-footer .v-field__input) {
		font-size: 12px;
		padding: 11px 0 0 10px;
	}
	
	&:deep(.v-data-table-footer .v-input) {
		height: 40px;
	}
}
</style>
