<template>
	<div class="website-details">
		<MProgressBar v-show="loading" indeterminate height="2" />
		<div class="website-details__title-wrapper">
			<h1 class="website-details__title">{{ form.name }}</h1>
			<MFlag :country-code="languageCode" />
			<div class="website-details__ahrefs">
				<WebsiteAhrefsBox :seo-stats="seoStats" :loading="loadingSeoStats" @refreshStatisticsAction="refreshStatisticsAction" />
			</div>
			<div class="website-details__title--btn-wrapper">
				<MButton
					id="go-to-edit-domain"
					normal
					blue400
					:label="$t('websites.editDomain')"
					@click="goToEditDomain"
				/>
				<MButton
					id="go-to-domain-offers"
					normal
					blue400
					:label="$t('websites.domainOffers')"
					@click="goToDomainOffers"
				/>
				<MButton
					v-if="null != user && isModerator(user)"
					id="sync-website"
					normal
					red400
					:label="$t('websites.syncWebsite')"
					@click="syncDomain"
					:loading="syncLoading"
				/>
			</div>
		</div>
		
		<div v-if="null != user && isModerator(user)" class="website-details__windows-wrapper">
			<div v-if="currentStatus === SUBMITTED_MODERATION || currentStatus === RESUBMITTED_MODERATION" class="website-details__wrapper website-details__wrapper--first">
				<h2 class="website-details__subtitle">{{ $t('websites.domainAccept') }}</h2>
				<v-radio-group v-model="acceptStatus">
					<v-radio id="domain-details-approve" :label="$t('websites.approve')" :value="true" />
					<v-radio id="domain-details-reject" :label="$t('websites.reject')" :value="false" />
				</v-radio-group>
				<div v-if="acceptStatus">
					<MButton
						id="save-domain-status"
						normal
						blue400
						:label="$t('websites.save')"
						@click="saveDomainStatus"
					/>
				</div>
				<Form :validation-schema="schema" @submit="saveDomainStatus" v-show="isRejectStatus">
					<div class="website-details__note">{{ $t('websites.note') }}</div>
					<MTextArea v-model="rejectNote" id="website-details-text-area" name="rejectNote" />
					<div class="website-details__area-btn">
						<MButton
							id="second-save-domain-status"
							normal
							blue400
							:label="$t('websites.save')"
						/>
					</div>
				</Form>
			</div>
			
			<div class="website-details__wrapper website-details__wrapper--no-gap">
				<h2 class="website-details__subtitle">{{ $t('websites.seoStats') }}</h2>
				<Form :validation-schema="seoSchema" @submit="saveSeoStats">
					<MTextField
						id="domain-details-dr"
						v-model="form.dr"
						type="number"
						min="0"
						name="dr"
						:separate-label="$t('websites.domainRating')"
						:placeholder="$t('websites.drPlaceholder')"
					/>
					<MTextField
						id="domain-details-ur"
						v-model="form.ur"
						type="number"
						min="0"
						name="ur"
						:separate-label="$t('websites.urlRatings')"
						:placeholder="$t('websites.urPlaceholder')"
					/>
					<MTextField
						id="domain-details-ot"
						v-model="form.ot"
						type="number"
						min="0"
						name="ot"
						:separate-label="$t('websites.organicTraffic')"
						:placeholder="$t('websites.otPlaceholder')"
					/>
					<MTextField
						id="domain-details-rd"
						v-model="form.rd"
						type="number"
						min="0"
						name="rd"
						:separate-label="$t('websites.refDomains')"
						:placeholder="$t('websites.rdPlaceholder')"
					/>
					<MTextField
						id="domain-details-pt"
						v-model="form.pt"
						type="number"
						min="0"
						name="pt"
						:separate-label="$t('websites.paidTraffic')"
						:placeholder="$t('websites.ptPlaceholder')"
					/>
					<MTextField
						id="domain-details-oc"
						v-model="form.oc"
						type="number"
						min="0"
						name="oc"
						:separate-label="$t('websites.orgCost')"
						:placeholder="$t('websites.ocPlaceholder')"
					/>
					<MTextField
						id="domain-details-pc"
						v-model="form.pc"
						type="number"
						min="0"
						name="pc"
						:separate-label="$t('websites.paidCost')"
						:placeholder="$t('websites.pcPlaceholder')"
					/>
					<MTextField
						id="domain-details-tf"
						v-model="form.tf"
						type="number"
						min="0"
						name="tf"
						:separate-label="$t('websites.trustFlow')"
						:placeholder="$t('websites.trustFlowPlaceholder')"
					/>
					<MTextField
						id="domain-details-bl"
						v-model="form.bl"
						type="number"
						min="0"
						name="bl"
						:separate-label="$t('websites.backlinks')"
						:placeholder="$t('websites.backlinksPlaceholder')"
					/>
					<MTextField
						id="domain-details-blat"
						v-model="form.blat"
						type="number"
						min="0"
						name="blat"
						:separate-label="$t('websites.backlinksAllTime')"
						:placeholder="$t('websites.backlinksAllTimePlaceholder')"
					/>
					<MTextField
						id="domain-details-rdat"
						v-model="form.rdat"
						type="number"
						min="0"
						name="rdat"
						:separate-label="$t('websites.refdomainsAllTime')"
						:placeholder="$t('websites.refdomainsAllTimePlaceholder')"
					/>
					<div class="website-details__btn-margin-top">
						<MButton
							id="save-domain-seo-stats"
							normal
							blue400
							:label="$t('websites.save')"
						/>
					</div>
				</Form>
			</div>
			
			<div class="website-details__wrapper">
				<h2 class="website-details__subtitle">{{ $t('websites.isTrustedDomain') }}</h2>
				<MCheckbox
					id="domain-details-is-trusted"
					v-model="form.isTrusted"
					name="isTrusted"
					:label="$t('websites.isTrustedDomain')"
				/>
				<div>
					<MButton
						id="save-domain-is-trusted"
						normal
						blue400
						:label="$t('websites.save')"
						@click="saveIsTrusted"
					/>
				</div>
				<div class="website-details__btn-margin-top">
					<h2 class="website-details__subtitle">{{ $t('websites.isAhrefsSynchronize') }}</h2>
					<MCheckbox
						id="domain-details-is-ahrefs-synchronize"
						v-model="isAhrefsSynchronize"
						name="isAhrefsSynchronize"
						:label="$t('websites.isAhrefsSynchronize')"
					/>
					<div class="website-details__next-sync-date">{{ $t('websites.nextSyncDate') }}: {{ dateNextSync }}</div>
					<MButton
						id="save-domain-is-ahrefs-synchronize"
						normal
						blue400
						:label="$t('websites.save')"
						@click="saveAhrefs"
						:loading="loadingSaveAhrefs"
					/>
				</div>
			</div>
		</div>
		
		<div v-if="null != form.note && (currentStatus === DISAPPROVED || currentStatus === RESUBMITTED_MODERATION)" class="website-details__wrapper website-details__wrapper--red">
			<h2 class="website-details__subtitle">{{ $t('websites.domainRejected') }}</h2>
			<div class="website-details__item-title">{{ form?.note }}</div>
		</div>
		
		<div class="website-details__wrapper">
			<h2 class="website-details__subtitle">{{ $t('websites.infoAboutDomain') }}</h2>
			<div class="website-details__item-wrapper">
				<div class="website-details__item-title">{{ $t('websites.url') }}</div>
				<div class="website-details__item-text">{{ null != form.url ? form.url : '-' }}</div>
			</div>
			<div class="website-details__item-wrapper">
				<div class="website-details__item-title">{{ $t('websites.createdData') }}</div>
				<div class="website-details__item-text">{{ 'Invalid Date' !== dateFormat(form?.created_at) ? dateFormat(form?.created_at) : '-' }}</div>
			</div>
			<div class="website-details__item-wrapper">
				<div class="website-details__item-title">{{ $t('websites.category') }}</div>
				<div v-if="0 !== form.category.length" class="website-details__category-wrapper">
					<div v-for="category in form.category" :key="category.id">
						<div class="website-details__item-text">{{`${$t(`websites.${changeNameToSlug(category.name)}`)}`}}</div>
					</div>
				</div>
				<div v-if="0 === form.category.length">-</div>
			</div>
			<div class="website-details__item-wrapper">
				<div class="website-details__item-title">{{ $t('websites.status') }}</div>
				<div class="website-details__item-text">{{ null != form.status ? form.status : '-' }}</div>
			</div>
		</div>
		
		<div class="website-details__windows-wrapper">
			<div class="website-details__wrapper">
				<h2 class="website-details__subtitle">{{ $t('websites.earnings') }}</h2>
				<div class="website-details__item-wrapper">
					<div class="website-details__item-title">{{ $t('websites.last30Days') }}</div>
						<div class="website-details__item-text">
							<div v-if="0 === form.incomesMonth.length">-</div>
							<div v-for="item in form.incomesMonth" :key="item.base_currency_id">
								<div>{{ 0 != form?.incomesMonth.length ? (item.total_earn / 100).toFixed(2) : '-' }} {{ 0 !== form?.incomesMonth.length ? changeCurrencyIdToSymbol(item.base_currency_id) : null }}</div>
							</div>
						</div>
				</div>
				<div class="website-details__item-wrapper">
					<div class="website-details__item-title">{{ $t('websites.lastYear') }}</div>
					<div class="website-details__item-text">
						<div v-if="0 === form.incomesYear.length">-</div>
						<div v-for="item in form.incomesYear" :key="item.base_currency_id">
							<div>{{ 0 != form?.incomesYear.length ? (item.total_earn / 100).toFixed(2) : '-' }} {{ 0 !== form?.incomesYear.length ? changeCurrencyIdToSymbol(item.base_currency_id) : null }}</div>
						</div>
					</div>
				</div>
				<div class="website-details__item-wrapper">
					<div class="website-details__item-title">{{ $t('websites.last5Years') }}</div>
					<div class="website-details__item-text">
						<div v-if="0 === form.incomes5Years.length">-</div>
						<div v-for="item in form.incomes5Years" :key="item.base_currency_id">
							<div>{{ 0 != form?.incomes5Years.length ? (item.total_earn / 100).toFixed(2) : '-' }} {{ 0 !== form?.incomes5Years.length ? changeCurrencyIdToSymbol(item.base_currency_id) : null }}</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="website-details__wrapper">
				<h2 class="website-details__subtitle">{{ $t('websites.publications') }}</h2>
				<div class="website-details__item-wrapper">
					<div class="website-details__item-title">{{ $t('websites.realizedPublications') }}</div>
					<div class="website-details__item-text">{{ form?.realizedPublications }}</div>
				</div>
				<div class="website-details__item-wrapper">
					<div class="website-details__item-title">{{ $t('websites.rejectedByPublisher') }}</div>
					<div class="website-details__item-text">{{ form?.rejectedByPublisher }}</div>
				</div>
				<div class="website-details__item-wrapper">
					<div class="website-details__item-title">{{ $t('websites.rejectedByAdvertiser') }}</div>
					<div class="website-details__item-text">{{ form?.rejectedByAdvertiser }}</div>
				</div>
			</div>
			
			<div class="website-details__wrapper">
				<h2 class="website-details__subtitle">{{ $t('websites.offerNumber') }}</h2>
				<div class="website-details__item-wrapper">
					<div class="website-details__item-title">{{ $t('websites.currentOfferNumber') }}</div>
					<div class="website-details__item-text">{{ form?.offerCounter }}</div>
				</div>
			</div>
		</div>
		
		<div
			v-if="
			null != user &&
			(isModerator(user) || isAdmin(user)) &&
			null != seoStats &&
			(
				seoStats.domain_rating.length > 1 ||
				seoStats.url_ratings.length > 1 ||
				seoStats.metrics_history.length > 1 ||
				seoStats.backlinks.length > 1 ||
				seoStats.trust_flow.length > 1
			)"
			class="website-details__charts-wrapper"
		>
			<MChart v-if="chartDataDr && chartOptionsDr && seoStats.domain_rating.length > 1" chart-id="seo-dr" is-line :chart-options="chartOptionsDr" :chart-data="chartDataDr" />
			<MChart v-if="chartDataUr && chartOptionsUr && seoStats.url_ratings.length > 1" chart-id="seo-ur" is-line :chart-options="chartOptionsUr" :chart-data="chartDataUr" />
			<MChart v-if="chartDataOt && chartOptionsOt && seoStats.metrics_history.length > 1" chart-id="seo-ot" is-line :chart-options="chartOptionsOt" :chart-data="chartDataOt" />
			<MChart v-if="chartDataRd && chartOptionsRd && seoStats.backlinks.length > 1" chart-id="seo-rd" is-line :chart-options="chartOptionsRd" :chart-data="chartDataRd" />
			<MChart v-if="chartDataPc && chartOptionsPc && seoStats.metrics_history.length > 1" chart-id="seo-pc" is-line :chart-options="chartOptionsPc" :chart-data="chartDataPc" />
			<MChart v-if="chartDataOc && chartOptionsOc && seoStats.metrics_history.length > 1" chart-id="seo-oc" is-line :chart-options="chartOptionsOc" :chart-data="chartDataOc" />
			<MChart v-if="chartDataPt && chartOptionsPt && seoStats.metrics_history.length > 1" chart-id="seo-pt" is-line :chart-options="chartOptionsPt" :chart-data="chartDataPt" />
			<MChart v-if="chartDataBl && chartOptionsBl && seoStats.backlinks.length > 1" chart-id="seo-bl" is-line :chart-options="chartOptionsBl" :chart-data="chartDataBl" />
			<MChart v-if="chartDataBlat && chartOptionsBlat && seoStats.backlinks.length > 1" chart-id="seo-blat" is-line :chart-options="chartOptionsBlat" :chart-data="chartDataBlat" />
			<MChart v-if="chartDataRdat && chartOptionsRdat && seoStats.backlinks.length > 1" chart-id="seo-rdat" is-line :chart-options="chartOptionsRdat" :chart-data="chartDataRdat" />
			<MChart v-if="chartDataTf && chartOptionsTf && seoStats.trust_flow.length > 1" chart-id="seo-tf" is-line :chart-options="chartOptionsTf" :chart-data="chartDataTf" />
		</div>
		
		<WebsitesOffersHistoryBox v-if="null != user && (isModerator(user) || isAdmin(user)) && null != websiteId" :id="websiteId" />
		
		<div class="website-details__btn-wrapper">
			<MButton
				id="go-back"
				normal
				blue400
				:label="$t('websites.back')"
				@click="goBack"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import router from '@/router';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';
import { useDictionaryStore } from '@/stores/dictionary';
import { useDomainStore } from '@/stores/domain';
import { dateFormat } from '@/hooks/DataHooks';
import { toast } from 'vue3-toastify';
import { isAdmin, isModerator } from '@/hooks/UserHooks';
import { SUBMITTED_MODERATION, DISAPPROVED, APPROVED, SKETCH, RESUBMITTED_MODERATION } from '@/hooks/WebsitesHooks';
import WebsiteApi from '@/api/v1/WebsiteApi';
import AhrefsApi from '@/apiWebsites/v1/AhrefsApi';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { ISyncSingleWebsite, IWebsiteAhrefsEnabled, IWebsiteDetails } from '@/models/WebsiteModel';
import type { IAhrefsStats, IManualUpdateRequest } from '@/models/AhrefsModel';
import MButton from '@/components/atoms/MButton.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import MTextArea from '@/components/atoms/MTextArea.vue';
import MFlag from '@/components/atoms/MFlag.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MCheckbox from '@/components/atoms/MCheckbox.vue';
import WebsiteAhrefsBox from '@/views/Websites/components/WebsiteAhrefsBox.vue';
import MChart from '@/components/atoms/MChart.vue';
import WebsitesOffersHistoryBox from '@/components/organisms/WebsitesOffersHistoryBox.vue';

const websiteApi = new WebsiteApi();
const ahrefsApi = new AhrefsApi();

const { user } = useUserStore();
const { languages, currencies } = useDictionaryStore();
const { setWebsiteName } = useDomainStore();
const { t } = useI18n();
const { secondary400, secondary300, primary400, primary300, yellow500, yellow400, green400, green300, blue400, blue300, orange400, orange300, grey400, grey300 } = variables;

const languageCode = ref<string>('');
const currentStatus = ref<string>('');
const loading = ref<boolean>(false);
const acceptStatus = ref<boolean>(true);
const isRejectStatus = ref<boolean>(false);
const rejectNote = ref<string|null>(null);
const loadingSeoStats = ref<boolean>(false);
const loadingSaveAhrefs = ref<boolean>(false);
const syncLoading = ref<boolean>(false);
const isAhrefsSynchronize = ref<boolean|number>(false);
const dateNextSync = ref<string|null>(null);
const seoStats = ref<IAhrefsStats|null>(null);
const websiteId = ref<number | null>(null);
const form = ref<any>({
	name: '',
	url: '',
	created_at: '',
	category: [],
	status: '',
	last30: 0,
	lastYear: 0,
	last5Years: 0,
	realizedPublications: 0,
	rejectedByPublisher: 0,
	rejectedByAdvertiser: 0,
	offerCounter: 0,
	note: [],
	isTrusted: 0,
	dr: 0,
	rd: 0,
	ur: 0,
	ot: 0,
	pc: 0,
	oc: 0,
	pt: 0,
	tf: 0,
	bl: 0,
	blat: 0,
	rdat: 0,
	incomesMonth: [],
	incomesYear: [],
	incomes5Years: [],
});
const websiteDetailsValue = ref<IWebsiteDetails|null>(null);
const chartDataDr = ref<any>({
	labels: [], // wartości na osi x (przyjmuje string/number)
	datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
		{
			label: `${t('websites.domainRating')}`, // Nazwa wyświetlanej grupy
			type: 'line', // Typ wykresu
			data: [], // wartości na osi y
			borderColor: secondary400,
			backgroundColor: secondary300, // Zmiana koloru słupków
			tension: 0.2,
			fill: 'start',
		},
	],
});
const chartDataUr = ref<any>({
	labels: [], // wartości na osi x (przyjmuje string/number)
	datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
		{
			label: `${t('websites.urlRatings')}`, // Nazwa wyświetlanej grupy
			type: 'line', // Typ wykresu
			data: [], // wartości na osi y
			borderColor: yellow500,
			backgroundColor: yellow400, // Zmiana koloru słupków
			tension: 0.2,
			fill: 'start',
		},
	],
});
const chartDataOt = ref<any>({
	labels: [], // wartości na osi x (przyjmuje string/number)
	datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
		{
			label: `${t('websites.organicTraffic')}`, // Nazwa wyświetlanej grupy
			type: 'line', // Typ wykresu
			data: [], // wartości na osi y
			borderColor: primary400,
			backgroundColor: primary300, // Zmiana koloru słupków
			tension: 0.2,
			fill: 'start',
		},
	],
});
const chartDataRd = ref<any>({
	labels: [], // wartości na osi x (przyjmuje string/number)
	datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
		{
			label: `${t('websites.refDomains')}`, // Nazwa wyświetlanej grupy
			type: 'line', // Typ wykresu
			data: [], // wartości na osi y
			borderColor: green400,
			backgroundColor: green300, // Zmiana koloru słupków
			tension: 0.2,
			fill: 'start',
		},
	],
});
const chartDataPc = ref<any>({
	labels: [], // wartości na osi x (przyjmuje string/number)
	datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
		{
			label: `${t('websites.paidCost')}`, // Nazwa wyświetlanej grupy
			type: 'line', // Typ wykresu
			data: [], // wartości na osi y
			borderColor: blue400,
			backgroundColor: blue300, // Zmiana koloru słupków
			tension: 0.2,
			fill: 'start',
		},
	],
});
const chartDataOc = ref<any>({
	labels: [], // wartości na osi x (przyjmuje string/number)
	datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
		{
			label: `${t('websites.orgCost')}`, // Nazwa wyświetlanej grupy
			type: 'line', // Typ wykresu
			data: [], // wartości na osi y
			borderColor: orange400,
			backgroundColor: orange300, // Zmiana koloru słupków
			tension: 0.2,
			fill: 'start',
		},
	],
});
const chartDataPt = ref<any>({
	labels: [], // wartości na osi x (przyjmuje string/number)
	datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
		{
			label: `${t('websites.paidTraffic')}`, // Nazwa wyświetlanej grupy
			type: 'line', // Typ wykresu
			data: [], // wartości na osi y
			borderColor: grey400,
			backgroundColor: grey300, // Zmiana koloru słupków
			tension: 0.2,
			fill: 'start',
		},
	],
});
const chartDataBl = ref<any>({
	labels: [], // wartości na osi x (przyjmuje string/number)
	datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
		{
			label: `${t('websites.backlinks')}`, // Nazwa wyświetlanej grupy
			type: 'line', // Typ wykresu
			data: [], // wartości na osi y
			borderColor: primary400,
			backgroundColor: primary300, // Zmiana koloru słupków
			tension: 0.2,
			fill: 'start',
		},
	],
});
const chartDataBlat = ref<any>({
	labels: [], // wartości na osi x (przyjmuje string/number)
	datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
		{
			label: `${t('websites.backlinksAllTime')}`, // Nazwa wyświetlanej grupy
			type: 'line', // Typ wykresu
			data: [], // wartości na osi y
			borderColor: green400,
			backgroundColor: green300, // Zmiana koloru słupków
			tension: 0.2,
			fill: 'start',
		},
	],
});
const chartDataRdat = ref<any>({
	labels: [], // wartości na osi x (przyjmuje string/number)
	datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
		{
			label: `${t('websites.refdomainsAllTime')}`, // Nazwa wyświetlanej grupy
			type: 'line', // Typ wykresu
			data: [], // wartości na osi y
			borderColor: orange400,
			backgroundColor: orange300, // Zmiana koloru słupków
			tension: 0.2,
			fill: 'start',
		},
	],
});
const chartDataTf = ref<any>({
	labels: [], // wartości na osi x (przyjmuje string/number)
	datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
		{
			label: `${t('websites.trustFlow')}`, // Nazwa wyświetlanej grupy
			type: 'line', // Typ wykresu
			data: [], // wartości na osi y
			borderColor: yellow500,
			backgroundColor: yellow400, // Zmiana koloru słupków
			tension: 0.2,
			fill: 'start',
		},
	],
});
const chartOptionsDr = ref<any>({
	responsive: true,
	plugins: {
		title: {
			display: false, // Wyswietlanie/Chowanie tytułu wykresu
			text: 'Domain rating', // Twoj tytul wykresu
		},
		tooltip: {
			callbacks: {
				label: function (tooltipItem: any) {
					let label = tooltipItem.dataset.data[tooltipItem.dataIndex] || ''; // Customowe ustawienie 2 miejsc po przeciunku w tooltipie (mozna zrobic console.loga z tooltipItem po najechaniu sie pokaze)
					return `${t('websites.domainRating')}: ` + label;
				},
			},
		},
	},
	scales: {
		x: {
			grid: {
				display: false, // Chowanie lini osi x
			},
		},
		y: {
			grid: {
				display: true, // Chowanie lini osi y
			},
			ticks: {
				callback: function (value:number) {
					return Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }); // Formatowanie do dwóch miejsc po przecinku
				},
			},
		},
	},
});
const chartOptionsUr = ref<any>({
	responsive: true,
	plugins: {
		title: {
			display: false, // Wyswietlanie/Chowanie tytułu wykresu
			text: 'Url ratings', // Twoj tytul wykresu
		},
		tooltip: {
			callbacks: {
				label: function (tooltipItem: any) {
					let label = tooltipItem.dataset.data[tooltipItem.dataIndex] || ''; // Customowe ustawienie 2 miejsc po przeciunku w tooltipie (mozna zrobic console.loga z tooltipItem po najechaniu sie pokaze)
					return `${t('websites.urlRatings')}: ` + label;
				},
			},
		},
	},
	scales: {
		x: {
			grid: {
				display: false, // Chowanie lini osi x
			},
		},
		y: {
			grid: {
				display: true, // Chowanie lini osi y
			},
			ticks: {
				callback: function (value:number) {
					return Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }); // Formatowanie do dwóch miejsc po przecinku
				},
			},
		},
	},
});
const chartOptionsOt = ref<any>({
	responsive: true,
	plugins: {
		title: {
			display: false, // Wyswietlanie/Chowanie tytułu wykresu
			text: 'Organic traffic', // Twoj tytul wykresu
		},
		tooltip: {
			callbacks: {
				label: function (tooltipItem: any) {
					let label = tooltipItem.dataset.data[tooltipItem.dataIndex] || ''; // Customowe ustawienie 2 miejsc po przeciunku w tooltipie (mozna zrobic console.loga z tooltipItem po najechaniu sie pokaze)
					return `${t('websites.organicTraffic')}: ` + label;
				},
			},
		},
	},
	scales: {
		x: {
			grid: {
				display: false, // Chowanie lini osi x
			},
		},
		y: {
			grid: {
				display: true, // Chowanie lini osi y
			},
			ticks: {
				callback: function (value:number) {
					return Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }); // Formatowanie do dwóch miejsc po przecinku
				},
			},
		},
	},
});
const chartOptionsRd = ref<any>({
	responsive: true,
	plugins: {
		title: {
			display: false, // Wyswietlanie/Chowanie tytułu wykresu
			text: 'Ref domains', // Twoj tytul wykresu
		},
		tooltip: {
			callbacks: {
				label: function (tooltipItem: any) {
					let label = tooltipItem.dataset.data[tooltipItem.dataIndex] || ''; // Customowe ustawienie 2 miejsc po przeciunku w tooltipie (mozna zrobic console.loga z tooltipItem po najechaniu sie pokaze)
					return `${t('websites.refDomains')}: ` + label;
				},
			},
		},
	},
	scales: {
		x: {
			grid: {
				display: false, // Chowanie lini osi x
			},
		},
		y: {
			grid: {
				display: true, // Chowanie lini osi y
			},
			ticks: {
				callback: function (value:number) {
					return Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }); // Formatowanie do dwóch miejsc po przecinku
				},
			},
		},
	},
});
const chartOptionsPc = ref<any>({
	responsive: true,
	plugins: {
		title: {
			display: false, // Wyswietlanie/Chowanie tytułu wykresu
			text: 'Paid cost', // Twoj tytul wykresu
		},
		tooltip: {
			callbacks: {
				label: function (tooltipItem: any) {
					let label = tooltipItem.dataset.data[tooltipItem.dataIndex] || ''; // Customowe ustawienie 2 miejsc po przeciunku w tooltipie (mozna zrobic console.loga z tooltipItem po najechaniu sie pokaze)
					return `${t('websites.paidCost')}: ` + label;
				},
			},
		},
	},
	scales: {
		x: {
			grid: {
				display: false, // Chowanie lini osi x
			},
		},
		y: {
			grid: {
				display: true, // Chowanie lini osi y
			},
			ticks: {
				callback: function (value:number) {
					return Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }); // Formatowanie do dwóch miejsc po przecinku
				},
			},
		},
	},
});
const chartOptionsOc = ref<any>({
	responsive: true,
	plugins: {
		title: {
			display: false, // Wyswietlanie/Chowanie tytułu wykresu
			text: 'Org cost', // Twoj tytul wykresu
		},
		tooltip: {
			callbacks: {
				label: function (tooltipItem: any) {
					let label = tooltipItem.dataset.data[tooltipItem.dataIndex] || ''; // Customowe ustawienie 2 miejsc po przeciunku w tooltipie (mozna zrobic console.loga z tooltipItem po najechaniu sie pokaze)
					return `${t('websites.orgCost')}: ` + label;
				},
			},
		},
	},
	scales: {
		x: {
			grid: {
				display: false, // Chowanie lini osi x
			},
		},
		y: {
			grid: {
				display: true, // Chowanie lini osi y
			},
			ticks: {
				callback: function (value:number) {
					return Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }); // Formatowanie do dwóch miejsc po przecinku
				},
			},
		},
	},
});
const chartOptionsPt = ref<any>({
	responsive: true,
	plugins: {
		title: {
			display: false, // Wyswietlanie/Chowanie tytułu wykresu
			text: 'Paid traffic', // Twoj tytul wykresu
		},
		tooltip: {
			callbacks: {
				label: function (tooltipItem: any) {
					let label = tooltipItem.dataset.data[tooltipItem.dataIndex] || ''; // Customowe ustawienie 2 miejsc po przeciunku w tooltipie (mozna zrobic console.loga z tooltipItem po najechaniu sie pokaze)
					return `${t('websites.paidTraffic')}: ` + label;
				},
			},
		},
	},
	scales: {
		x: {
			grid: {
				display: false, // Chowanie lini osi x
			},
		},
		y: {
			grid: {
				display: true, // Chowanie lini osi y
			},
			ticks: {
				callback: function (value:number) {
					return Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }); // Formatowanie do dwóch miejsc po przecinku
				},
			},
		},
	},
});
const chartOptionsBl = ref<any>({
	responsive: true,
	plugins: {
		title: {
			display: false, // Wyswietlanie/Chowanie tytułu wykresu
			text: 'Backlinks', // Twoj tytul wykresu
		},
		tooltip: {
			callbacks: {
				label: function (tooltipItem: any) {
					let label = tooltipItem.dataset.data[tooltipItem.dataIndex] || ''; // Customowe ustawienie 2 miejsc po przeciunku w tooltipie (mozna zrobic console.loga z tooltipItem po najechaniu sie pokaze)
					return `${t('websites.backlinks')}: ` + label;
				},
			},
		},
	},
	scales: {
		x: {
			grid: {
				display: false, // Chowanie lini osi x
			},
		},
		y: {
			grid: {
				display: true, // Chowanie lini osi y
			},
			ticks: {
				callback: function (value:number) {
					return Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }); // Formatowanie do dwóch miejsc po przecinku
				},
			},
		},
	},
});
const chartOptionsBlat = ref<any>({
	responsive: true,
	plugins: {
		title: {
			display: false, // Wyswietlanie/Chowanie tytułu wykresu
			text: 'Backlinks all time', // Twoj tytul wykresu
		},
		tooltip: {
			callbacks: {
				label: function (tooltipItem: any) {
					let label = tooltipItem.dataset.data[tooltipItem.dataIndex] || ''; // Customowe ustawienie 2 miejsc po przeciunku w tooltipie (mozna zrobic console.loga z tooltipItem po najechaniu sie pokaze)
					return `${t('websites.backlinksAllTime')}: ` + label;
				},
			},
		},
	},
	scales: {
		x: {
			grid: {
				display: false, // Chowanie lini osi x
			},
		},
		y: {
			grid: {
				display: true, // Chowanie lini osi y
			},
			ticks: {
				callback: function (value:number) {
					return Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }); // Formatowanie do dwóch miejsc po przecinku
				},
			},
		},
	},
});
const chartOptionsRdat = ref<any>({
	responsive: true,
	plugins: {
		title: {
			display: false, // Wyswietlanie/Chowanie tytułu wykresu
			text: 'Refdomains all time', // Twoj tytul wykresu
		},
		tooltip: {
			callbacks: {
				label: function (tooltipItem: any) {
					let label = tooltipItem.dataset.data[tooltipItem.dataIndex] || ''; // Customowe ustawienie 2 miejsc po przeciunku w tooltipie (mozna zrobic console.loga z tooltipItem po najechaniu sie pokaze)
					return `${t('websites.refdomainsAllTime')}: ` + label;
				},
			},
		},
	},
	scales: {
		x: {
			grid: {
				display: false, // Chowanie lini osi x
			},
		},
		y: {
			grid: {
				display: true, // Chowanie lini osi y
			},
			ticks: {
				callback: function (value:number) {
					return Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }); // Formatowanie do dwóch miejsc po przecinku
				},
			},
		},
	},
});
const chartOptionsTf = ref<any>({
	responsive: true,
	plugins: {
		title: {
			display: false, // Wyswietlanie/Chowanie tytułu wykresu
			text: 'Trust flow', // Twoj tytul wykresu
		},
		tooltip: {
			callbacks: {
				label: function (tooltipItem: any) {
					let label = tooltipItem.dataset.data[tooltipItem.dataIndex] || ''; // Customowe ustawienie 2 miejsc po przeciunku w tooltipie (mozna zrobic console.loga z tooltipItem po najechaniu sie pokaze)
					return `${t('websites.trustFlow')}: ` + label;
				},
			},
		},
	},
	scales: {
		x: {
			grid: {
				display: false, // Chowanie lini osi x
			},
		},
		y: {
			grid: {
				display: true, // Chowanie lini osi y
			},
			ticks: {
				callback: function (value:number) {
					return Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }); // Formatowanie do dwóch miejsc po przecinku
				},
			},
		},
	},
});
const websiteUuid = ref<string|Array<string>|null>(null);
websiteUuid.value = router.currentRoute.value.params.uuid;

const schema:any = yup.object({
	rejectNote: yup.string().required(`${t('websites.validation.rejectNote')}`).label('rejectNote'),
});
const seoSchema:any = yup.object({
	dr: yup.number().required(`${t('websites.validation.dr')}`).min(0, `${t('websites.validation.minSeo')}`).label('dr'),
	rd: yup.number().required(`${t('websites.validation.rd')}`).min(0, `${t('websites.validation.minSeo')}`).label('rd'),
	ur: yup.number().required(`${t('websites.validation.ur')}`).min(0, `${t('websites.validation.minSeo')}`).label('ur'),
	ot: yup.number().required(`${t('websites.validation.ot')}`).min(0, `${t('websites.validation.minSeo')}`).label('ot'),
	pt: yup.number().required(`${t('websites.validation.pt')}`).min(0, `${t('websites.validation.minSeo')}`).label('pt'),
	oc: yup.number().required(`${t('websites.validation.oc')}`).min(0, `${t('websites.validation.minSeo')}`).label('oc'),
	pc: yup.number().required(`${t('websites.validation.pc')}`).min(0, `${t('websites.validation.minSeo')}`).label('pc'),
	tf: yup.number().required(`${t('websites.validation.tf')}`).min(0, `${t('websites.validation.minSeo')}`).label('tf'),
	bl: yup.number().required(`${t('websites.validation.bl')}`).min(0, `${t('websites.validation.minSeo')}`).label('bl'),
	blat: yup.number().required(`${t('websites.validation.blat')}`).min(0, `${t('websites.validation.minSeo')}`).label('blat'),
	rdat: yup.number().required(`${t('websites.validation.rdat')}`).min(0, `${t('websites.validation.minSeo')}`).label('rdat'),
});

const changeLanguageIdToCode = (languageId:number) => {
	const language = languages.find(elem => elem.id === languageId);
	if (null != language) {
		languageCode.value = language.flag;
	}
};
const websiteDetails = async() => {
	loading.value = true;
	try {
		const result = await websiteApi.websiteDetails(websiteUuid.value);
		
		if (!result.isSuccess) {
			loading.value = false;
			toast.error(`${t('websites.toast.errorGetWebsiteDetails')}`);
			return;
		}
		
		websiteId.value = result.payload.id;
		form.value.name = result.payload.name;
		form.value.url = result.payload.url;
		form.value.created_at = result.payload.created_at;
		if (0 !== result.payload.categories.length) {
			form.value.category = result.payload.categories;
		}
		currentStatus.value = result.payload.status;
		if (result.payload.status === APPROVED) {
			form.value.status = t('websites.approved');
		}
		if (result.payload.status === DISAPPROVED) {
			form.value.status = t('websites.disapproved');
		}
		if (result.payload.status === SUBMITTED_MODERATION) {
			form.value.status = t('websites.submittedModeration');
		}
		if (result.payload.status === RESUBMITTED_MODERATION) {
			form.value.status = t('websites.resubmittedModeration');
		}
		if (result.payload.status === SKETCH) {
			form.value.status = t('websites.sketch');
		}
		if (null != result.payload.earnings_month) {
			form.value.last30 = result.payload.earnings_month.toFixed(2);
		} else {
			const zero = 0;
			form.value.last30 = zero.toFixed(2);
		}
		if (null != result.payload.earnings_year) {
			form.value.lastYear = result.payload.earnings_year.toFixed(2);
		} else {
			const zero = 0;
			form.value.lastYear = zero.toFixed(2);
		}
		if (null != result.payload.earnings_five_years) {
			form.value.last5Years = result.payload.earnings_five_years.toFixed(2);
		} else {
			const zero = 0;
			form.value.last5Years = zero.toFixed(2);
		}
		form.value.realizedPublications = result.payload.publications_count_finished;
		form.value.rejectedByPublisher = result.payload.publications_count_content_resigned;
		form.value.rejectedByAdvertiser = result.payload.publications_count_publication_resigned;
		form.value.offerCounter = result.payload.offers_count;
		form.value.note = result.payload.reject_messages;
		form.value.isTrusted = result.payload.is_trusted;
		form.value.incomesMonth = result.payload.incomes_month;
		form.value.incomesYear = result.payload.incomes_year;
		form.value.incomes5Years = result.payload.incomes_five_years;
		isAhrefsSynchronize.value = result.payload.ahrefs_enabled === 1;
		dateNextSync.value = result.payload.ahrefs_next_sync;
		changeLanguageIdToCode(result.payload.language_id);
		
		websiteDetailsValue.value = result.payload;
	} catch (e) {
		loading.value = false;
		toast.error(`${t('websites.toast.errorGetWebsiteDetails')}`);
		return;
	}
	loading.value = false;
};
websiteDetails();

const saveDomainStatus = async () => {
	loading.value = true;
	try {
		const changeStatusForm = {
			...(true === acceptStatus.value
				? { status: APPROVED }
				: { status: DISAPPROVED, reject_messages: rejectNote.value }
			),
		}
		const result = await websiteApi.changeDomainStatus(changeStatusForm, websiteUuid.value);
		if (!result.isSuccess) {
			loading.value = false;
			toast.error(`${t('websites.toast.errorSaveDomainStatus')}`);
			return;
		}
		toast.success(`${t('websites.toast.successChangeDomainStatus')}`);
		await router.push('/websites');
	} catch (e) {
		loading.value = false;
		toast.error(`${t('websites.toast.errorSaveDomainStatus')}`);
		return;
	}
	loading.value = false;
};

watch((acceptStatus), (val) => {
	if (!val) {
		isRejectStatus.value = true;
	} else {
		isRejectStatus.value = false;
	}
});

const saveIsTrusted = async() => {
	loading.value = true;
	const isTrustedForm:any = {
		is_trusted: form.value.isTrusted,
	};
	try {
		const result = await websiteApi.editTrustedWebsite(isTrustedForm, websiteUuid.value);
		if (!result.isSuccess) {
			loading.value = false;
			toast.error(`${t('websites.toast.errorSaveIsTrusted')}`);
			return;
		}
	} catch (e) {
		loading.value = false;
		toast.error(`${t('websites.toast.errorSaveIsTrusted')}`);
		return;
	}
	toast.success(`${t('websites.toast.successSaveIsTrusted')}`);
	loading.value = false;
};

const saveSeoStats = async() => {
	loading.value = true;
	const seoForm:IManualUpdateRequest = {
		domain_rating: {
			domainRating: form.value.dr,
		},
		metrics_history: {
			orgTraffic: form.value.ot,
			paidTraffic: form.value.pt,
			orgCost: form.value.oc,
			paidCost: form.value.pc,
		},
		backlinks: {
			live: form.value.bl,
			liveRefdomains: form.value.rd,
			allTime: form.value.blat,
			allTimeRefdomains: form.value.rdat,
		},
		url_ratings: {
			urlRating: form.value.ur,
		},
		trust_flow: {
			trustFlow: form.value.tf,
		},
		refdomains: {
			refDomains: form.value.rd,
		},
	};
	try {
		const result = await ahrefsApi.manualUpdateStats(websiteUuid.value, seoForm);
		if (!result.isSuccess) {
			loading.value = false;
			toast.error(`${t('websites.toast.errorSaveSeoStats')}`);
			return;
		}
	} catch (e) {
		loading.value = false;
		toast.error(`${t('websites.toast.errorSaveSeoStats')}`);
		return;
	}
	toast.success(`${t('websites.toast.successSaveSeoStats')}`);
	await seoStatsList();
	loading.value = false;
};
const goBack = () => {
	router.push('/websites');
};
const goToEditDomain = () => {
	router.push(`/websites/${websiteUuid.value}`);
};
const goToDomainOffers = async() => {
	await setWebsiteName(form.value.name);
	await router.push(`/websites/offers`);
};

const changeCurrencyIdToSymbol = (currencyId:number|null) => {
	const currenciesList = currencies.find(elem => elem.id === currencyId);
	if (currenciesList != null) {
		return currenciesList.currency_symbol || '-';
	}
	return '-';
};

const changeNameToSlug = (name:string) => {
	// Usuń białe znaki na początku i końcu tekstu oraz zmień wszystkie litery na małe
	const slug = name.trim().toLowerCase();
	// Najpierw zamień spacje z myślnikami na same myślniki potem zamień spacje na myślniki
	const slugWithHyphens = slug.replace(' - ', '-').replace(/\s+/g, '-');
	return slugWithHyphens;
};

const seoStatsList = async() => {
	loadingSeoStats.value = true;
	try {
		const result = await ahrefsApi.statsList(websiteUuid.value);
		if (!result.isSuccess) {
			loadingSeoStats.value = false;
			return;
		}
		if (null != result.payload.website.ahrefs_next_sync) {
			dateNextSync.value = result.payload.website.ahrefs_next_sync;
		}
		
		seoStats.value = result.payload;
		if (seoStats.value.domain_rating.length > 0) {
			form.value.dr = seoStats.value.domain_rating[0].domainRating;
		}
		if (seoStats.value.url_ratings.length > 0) {
			form.value.ur = seoStats.value.url_ratings[0].urlRating;
		}
		if (seoStats.value.metrics_history.length > 0) {
			form.value.ot = seoStats.value.metrics_history[0].orgTraffic;
			form.value.pt = seoStats.value.metrics_history[0].paidTraffic;
			form.value.oc = seoStats.value.metrics_history[0].orgCost;
			form.value.pc = seoStats.value.metrics_history[0].paidCost;
		}
		if (seoStats.value.backlinks.length > 0) {
			form.value.rd = seoStats.value.backlinks[0].live_refdomains;
			form.value.bl = seoStats.value.backlinks[0].live;
			form.value.blat = seoStats.value.backlinks[0].all_time;
			form.value.rdat = seoStats.value.backlinks[0].all_time_refdomains;
		}
		if (seoStats.value.trust_flow.length > 0) {
			form.value.tf = seoStats.value.trust_flow[0].trust_flow;
		}
		
		let keysDr:Array<string> = [];
		let valuesDr:Array<string|number> = [];
		result.payload.domain_rating.forEach((elem:any) => {
			keysDr.push(elem.date.split(' ')[0]);
			valuesDr.push(elem.domainRating);
		});
		chartDataDr.value = {
			labels: keysDr.reverse(), // wartości na osi x (przyjmuje string/number)
			datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
				{
					label: `${t('websites.domainRating')}`, // Nazwa wyświetlanej grupy
					type: 'line', // Typ wykresu
					data: valuesDr.reverse(), // wartości na osi y
					borderColor: secondary400,
					backgroundColor: secondary300, // Zmiana koloru słupków
					tension: 0.2,
					fill: 'start',
				},
			],
		}
		
		let keysUr:Array<string> = [];
		let valuesUr:Array<string|number> = [];
		result.payload.url_ratings.forEach((elem:any) => {
			keysUr.push(elem.date.split(' ')[0]);
			valuesUr.push(elem.urlRating);
		});
		chartDataUr.value = {
			labels: keysUr.reverse(), // wartości na osi x (przyjmuje string/number)
			datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
				{
					label: `${t('websites.urlRatings')}`, // Nazwa wyświetlanej grupy
					type: 'line', // Typ wykresu
					data: valuesUr.reverse(), // wartości na osi y
					borderColor: yellow500,
					backgroundColor: yellow400, // Zmiana koloru słupków
					tension: 0.2,
					fill: 'start',
				},
			],
		}
		
		let keysOt:Array<string> = [];
		let valuesOt:Array<string|number> = [];
		result.payload.metrics_history.forEach((elem:any) => {
			keysOt.push(elem.date.split(' ')[0]);
			valuesOt.push(elem.orgTraffic);
		});
		chartDataOt.value = {
			labels: keysOt.reverse(), // wartości na osi x (przyjmuje string/number)
			datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
				{
					label: `${t('websites.organicTraffic')}`, // Nazwa wyświetlanej grupy
					type: 'line', // Typ wykresu
					data: valuesOt.reverse(), // wartości na osi y
					borderColor: primary400,
					backgroundColor: primary300, // Zmiana koloru słupków
					tension: 0.2,
					fill: 'start',
				},
			],
		}
		
		let keysRd:Array<string> = [];
		let valuesRd:Array<string|number> = [];
		result.payload.backlinks.forEach((elem:any) => {
			keysRd.push(elem.date.split(' ')[0]);
			valuesRd.push(elem.live_refdomains);
		});
		chartDataRd.value = {
			labels: keysRd.reverse(), // wartości na osi x (przyjmuje string/number)
			datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
				{
					label: `${t('websites.refDomains')}`, // Nazwa wyświetlanej grupy
					type: 'line', // Typ wykresu
					data: valuesRd.reverse(), // wartości na osi y
					borderColor: green400,
					backgroundColor: green300, // Zmiana koloru słupków
					tension: 0.2,
					fill: 'start',
				},
			],
		}
		
		let keysPc:Array<string> = [];
		let valuesPc:Array<string|number> = [];
		result.payload.metrics_history.forEach((elem:any) => {
			keysPc.push(elem.date.split(' ')[0]);
			valuesPc.push(elem.paidCost);
		});
		chartDataPc.value = {
			labels: keysPc.reverse(), // wartości na osi x (przyjmuje string/number)
			datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
				{
					label: `${t('websites.paidCost')}`, // Nazwa wyświetlanej grupy
					type: 'line', // Typ wykresu
					data: valuesPc.reverse(), // wartości na osi y
					borderColor: blue400,
					backgroundColor: blue300, // Zmiana koloru słupków
					tension: 0.2,
					fill: 'start',
				},
			],
		}
		
		let keysOc:Array<string> = [];
		let valuesOc:Array<string|number> = [];
		result.payload.metrics_history.forEach((elem:any) => {
			keysOc.push(elem.date.split(' ')[0]);
			valuesOc.push(elem.orgCost);
		});
		chartDataOc.value = {
			labels: keysOc.reverse(), // wartości na osi x (przyjmuje string/number)
			datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
				{
					label: `${t('websites.orgCost')}`, // Nazwa wyświetlanej grupy
					type: 'line', // Typ wykresu
					data: valuesOc.reverse(), // wartości na osi y
					borderColor: orange400,
					backgroundColor: orange300, // Zmiana koloru słupków
					tension: 0.2,
					fill: 'start',
				},
			],
		}
		
		let keysPt:Array<string> = [];
		let valuesPt:Array<string|number> = [];
		result.payload.metrics_history.forEach((elem:any) => {
			keysPt.push(elem.date.split(' ')[0]);
			valuesPt.push(elem.paidTraffic);
		});
		chartDataPt.value = {
			labels: keysPt.reverse(), // wartości na osi x (przyjmuje string/number)
			datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
				{
					label: `${t('websites.paidTraffic')}`, // Nazwa wyświetlanej grupy
					type: 'line', // Typ wykresu
					data: valuesPt.reverse(), // wartości na osi y
					borderColor: grey400,
					backgroundColor: grey300, // Zmiana koloru słupków
					tension: 0.2,
					fill: 'start',
				},
			],
		};
		
		let keysBl:Array<string> = [];
		let valuesBl:Array<string|number> = [];
		result.payload.backlinks.forEach((elem:any) => {
			keysBl.push(elem.date.split(' ')[0]);
			valuesBl.push(elem.live);
		});
		chartDataBl.value = {
			labels: keysBl.reverse(), // wartości na osi x (przyjmuje string/number)
			datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
				{
					label: `${t('websites.backlinks')}`, // Nazwa wyświetlanej grupy
					type: 'line', // Typ wykresu
					data: valuesBl.reverse(), // wartości na osi y
					borderColor: primary400,
					backgroundColor: primary300, // Zmiana koloru słupków
					tension: 0.2,
					fill: 'start',
				},
			],
		};
		
		let keysBlat:Array<string> = [];
		let valuesBlat:Array<string|number> = [];
		result.payload.backlinks.forEach((elem:any) => {
			keysBlat.push(elem.date.split(' ')[0]);
			valuesBlat.push(elem.all_time);
		});
		chartDataBlat.value = {
			labels: keysBlat.reverse(), // wartości na osi x (przyjmuje string/number)
			datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
				{
					label: `${t('websites.backlinksAllTime')}`, // Nazwa wyświetlanej grupy
					type: 'line', // Typ wykresu
					data: valuesBlat.reverse(), // wartości na osi y
					borderColor: green400,
					backgroundColor: green300, // Zmiana koloru słupków
					tension: 0.2,
					fill: 'start',
				},
			],
		};
		
		let keysRdat:Array<string> = [];
		let valuesRdat:Array<string|number> = [];
		result.payload.backlinks.forEach((elem:any) => {
			keysRdat.push(elem.date.split(' ')[0]);
			valuesRdat.push(elem.all_time_refdomains);
		});
		chartDataRdat.value = {
			labels: keysRdat.reverse(), // wartości na osi x (przyjmuje string/number)
			datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
				{
					label: `${t('websites.refdomainsAllTime')}`, // Nazwa wyświetlanej grupy
					type: 'line', // Typ wykresu
					data: valuesRdat.reverse(), // wartości na osi y
					borderColor: orange400,
					backgroundColor: orange300, // Zmiana koloru słupków
					tension: 0.2,
					fill: 'start',
				},
			],
		};
		
		let keysTf:Array<string> = [];
		let valuesTf:Array<string|number> = [];
		result.payload.trust_flow.forEach((elem:any) => {
			keysTf.push(elem.date.split(' ')[0]);
			valuesTf.push(elem.trust_flow);
		});
		chartDataTf.value = {
			labels: keysTf.reverse(), // wartości na osi x (przyjmuje string/number)
			datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
				{
					label: `${t('websites.trustFlow')}`, // Nazwa wyświetlanej grupy
					type: 'line', // Typ wykresu
					data: valuesTf.reverse(), // wartości na osi y
					borderColor: yellow500,
					backgroundColor: yellow400, // Zmiana koloru słupków
					tension: 0.2,
					fill: 'start',
				},
			],
		};
	} catch (e) {
		loadingSeoStats.value = false;
		return;
	}
	loadingSeoStats.value = false;
};
seoStatsList();
const saveAhrefs = async() => {
	loadingSaveAhrefs.value = true;
	
	const ahrefsForm:IWebsiteAhrefsEnabled = {
		ahrefs_enabled: isAhrefsSynchronize.value,
	};
	try {
		const result = await websiteApi.syncWebsiteWithAhrefs(websiteUuid.value, ahrefsForm);
		if (!result.isSuccess) {
			toast.error(`${t('websites.toast.errorSaveSyncSeoStats')}`);
			loadingSaveAhrefs.value = false;
			return;
		}
		toast.success(`${t('websites.toast.successSaveSyncSeoStats')}`);
	} catch (e) {
		toast.error(`${t('websites.toast.errorSaveSyncSeoStats')}`);
		loadingSaveAhrefs.value = false;
		return;
	}
	loadingSaveAhrefs.value = false;
};

const refreshStatisticsAction = (response:IAhrefsStats) => {
	if (null != response.website.ahrefs_next_sync) {
		dateNextSync.value = response.website.ahrefs_next_sync;
	}
	seoStats.value = response;
	if (response.domain_rating.length > 0) {
		form.value.dr = response.domain_rating[0].domainRating;
	}
	if (response.url_ratings.length > 0) {
		form.value.ur = response.url_ratings[0].urlRating;
	}
	if (response.metrics_history.length > 0) {
		form.value.ot = response.metrics_history[0].orgTraffic;
		form.value.pt = response.metrics_history[0].paidTraffic;
		form.value.oc = response.metrics_history[0].orgCost;
		form.value.pc = response.metrics_history[0].paidCost;
	}
	if (response.backlinks.length > 0) {
		form.value.rd = response.backlinks[0].live_refdomains;
		form.value.bl = response.backlinks[0].live;
		form.value.blat = response.backlinks[0].all_time;
		form.value.rdat = response.backlinks[0].all_time_refdomains;
	}
	if (response.trust_flow.length > 0) {
		form.value.tf = response.trust_flow[0].trust_flow;
	}
};

const syncDomain = async() => {
	syncLoading.value = true;
	
	if (null == websiteDetailsValue.value) {
		toast.error(`${t('websites.toast.errorSyncSingleWebsite')}`);
		syncLoading.value = false;
		return;
	}
	
	const syncForm:ISyncSingleWebsite = {
		id: websiteDetailsValue.value.id,
		uuid: websiteDetailsValue.value.uuid,
		old_id: websiteDetailsValue.value.old_id,
		user_id: websiteDetailsValue.value.user_id,
		step: websiteDetailsValue.value.step,
		url: websiteDetailsValue.value.url,
		language_id: websiteDetailsValue.value.language_id,
		country_id: websiteDetailsValue.value.country_id,
		description: websiteDetailsValue.value.description,
		accept_proposal: websiteDetailsValue.value.accept_proposal,
		use_user_email: websiteDetailsValue.value.use_user_email,
		email: websiteDetailsValue.value.email,
		article_photo: websiteDetailsValue.value.article_photo,
		photo_proportions: websiteDetailsValue.value.photo_proportions,
		min_photo_size: websiteDetailsValue.value.min_photo_size,
		requirements: websiteDetailsValue.value.requirements,
		is_allowed_promotion_loans: websiteDetailsValue.value.is_allowed_promotion_loans,
		is_allowed_promotion_cbd: websiteDetailsValue.value.is_allowed_promotion_cbd,
		is_allowed_promotion_bookmakers: websiteDetailsValue.value.is_allowed_promotion_bookmakers,
		is_trusted: websiteDetailsValue.value.is_trusted,
		sample_article_url: websiteDetailsValue.value.sample_article_url,
		status: websiteDetailsValue.value.status,
		reject_messages: websiteDetailsValue.value.reject_messages,
		page_title: websiteDetailsValue.value.page_title,
		original_page_title: websiteDetailsValue.value.original_page_title,
		key_words: websiteDetailsValue.value.key_words,
		original_key_words: websiteDetailsValue.value.original_key_words,
		page_description: websiteDetailsValue.value.page_description,
		original_page_description: websiteDetailsValue.value.original_page_description,
		is_active: websiteDetailsValue.value.is_active,
		last_active_check: websiteDetailsValue.value.last_active_check,
		last_http_code: websiteDetailsValue.value.last_http_code,
		ahrefs_enabled: websiteDetailsValue.value.ahrefs_enabled,
		ahrefs_next_sync: websiteDetailsValue.value.ahrefs_next_sync,
		ahrefs_month_sync: websiteDetailsValue.value.ahrefs_month_sync,
		ahrefs_day_sync: websiteDetailsValue.value.ahrefs_day_sync,
		ahrefs_hour_sync: websiteDetailsValue.value.ahrefs_hour_sync,
		created_at: websiteDetailsValue.value.created_at,
		updated_at: websiteDetailsValue.value.updated_at,
		deleted_at: websiteDetailsValue.value.deleted_at,
		name: websiteDetailsValue.value.name,
	};
	try {
		const result = await websiteApi.syncSingleWebsite(syncForm);
		if (!result.isSuccess) {
			toast.error(`${t('websites.toast.errorSyncSingleWebsite')}`);
			syncLoading.value = false;
			return;
		}
		toast.success(`${t('websites.toast.successSyncSingleWebsite')}`);
	} catch (e) {
		toast.error(`${t('websites.toast.errorSyncSingleWebsite')}`);
		syncLoading.value = false;
		return;
	}
	syncLoading.value = false;
};
</script>

<style scoped lang="scss">
.website-details {
	display: flex;
	flex-direction: column;
	gap: 64px;
	
	&__title-wrapper {
		display: flex;
		gap: 10px;
	}
	
	&__title {
		text-transform: uppercase;
		font-size: 24px;
		font-weight: 400;
		color: $primary-400;
		letter-spacing: 1px;
		
		span {
			margin-top: -2px;
		}
		
		&--btn-wrapper {
			margin-left: auto;
			display: flex;
			gap: 10px;
		}
	}
	
	&__wrapper {
		background-color: $white-100;
		border-radius: 6px;
		padding: 24px;
		box-shadow: 10px 10px 40px 0 #0000000D;
		display: flex;
		flex-direction: column;
		gap: 24px;
		color: $primary-400;
		
		&--red {
			background-color: $secondary-400;
			color: $white-100;
		}
		
		&--no-gap {
			gap: 0;
			
			h2 {
				margin-bottom: 24px;
			}
		}
		
		&--first {
			&:deep(.v-input__details) {
				display: none;
			}
		}
		
		&:deep(.v-input input) {
			min-height: 20px;
		}
		
		&:deep(.m-checkbox .v-input__control) {
			margin-left: -10px;
		}
		
		&:deep(.v-radio-group > .v-input__control) {
			margin-left: -10px;
		}
		
		&:deep(.m-text-area) {
			margin-bottom: 44px;
			height: 178px;
		}
	}
	
	&__subtitle {
		font-size: 16px;
		font-weight: 600;
		text-transform: uppercase;
	}
	
	&__item-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	&__item-title {
		font-size: 14px;
		font-weight: 300;
	}
	
	&__item-text {
		font-size: 12px;
		font-weight: 600;
	}
	
	&__windows-wrapper {
		display: flex;
		flex-direction: column;
		gap: 32px;
		
		@include media-breakpoint-up(lg) {
			flex-direction: row;
			gap: 5px;
			
			.website-details__wrapper {
				width: 33%;
			}
		}
		
		@include media-breakpoint-up(xl) {
			gap: 32px;
		}
	}
	
	&__btn-wrapper {
		display: flex;
		width: max-content;
		margin-left: auto;

		button {
			padding: 25px 30px !important;
		}
	}
	
	&__category-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
	
	&__area-btn {
		margin-top: 25px;
	}
	
	&__btn-margin-top {
		&:deep(.v-input__details) {
			display: none;
		}
	}
	
	&__note {
		font-size: 12px;
		color: $grey-400;
		font-weight: 700;
	}
	
	&__ahrefs {
		margin-left: 20px;
	}
	
	&__next-sync-date {
		margin-bottom: 18px;
		color: $grey-300;
	}
	
	&__charts-wrapper {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		
		@include media-breakpoint-up(lg) {
			flex-direction: row;
		}
		
		div {
			width: 100%;
			
			@include media-breakpoint-up(lg) {
				width: 50%;
			}
		}
	}
	
	&:deep(.v-selection-control .v-label) {
		font-size: 14px;
	}
}
</style>
