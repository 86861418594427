import BaseApi from '@/api/BaseApi';
import type {
	IContaduHistoryTableItems,
	IContaduOptimalization,
	IContaduResponseFromKeywordRequest,
	IContaduTableItems
} from '@/models/ContaduModel';

export default class ContaduApi extends BaseApi {
	async availableOptimalizations (userUuid: string) {
		return this.get<number>({
			url: `/api/v1/contadu/${userUuid}/limit`,
			config: {
				suppressError: true,
			},
		});
	};

	async sendKeyword (articleUuid: string, keyword: string) {
		return this.post<any, IContaduResponseFromKeywordRequest>({
			url: `/api/v1/contadu/${articleUuid}/${keyword}`,
			config: {
				suppressError: true,
			},
		});
	};

	async getArticleOptimalizationList (articleUuid: string) {
		return this.get<Array<IContaduTableItems>>({
			url: `/api/v1/contadu/${articleUuid}/article`,
			config: {
				suppressError: true,
			},
		});
	};

	async optimalizationDetails (optimalizationId: string|Array<string>) {
		return this.get<IContaduOptimalization>({
			url: `/api/v1/contadu/result/${optimalizationId}`,
			config: {
				suppressError: true,
			},
		});
	};

	async getUserOptimalizationList (userUuid: string|Array<string>) {
		return this.get<Array<IContaduHistoryTableItems>>({
			url: `/api/v1/contadu/${userUuid}/user`,
			config: {
				suppressError: true,
			},
		});
	};
};
