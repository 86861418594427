import { createApp, watch } from 'vue';
import { createPinia, storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { useSessionStore } from '@/stores/session';
import { useUserStore } from '@/stores/user';
import { useDictionaryStore } from '@/stores/dictionary';
import { useTableSettingsStore } from '@/stores/tableSettings';
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import vuetify from '@/plugins/vuetify';
import '@mdi/font/css/materialdesignicons.css';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import i18n from '@/plugins/i18n';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import '@vuepic/vue-datepicker/dist/main.css';
import { setLanguagesTranslations } from '@/hooks/LanguageHooks';
import { setCountryTranslations } from '@/hooks/CountryHooks';
import VueGtm from 'vue-gtm';
import * as Sentry from '@sentry/vue';

import App from './App.vue';
import router from './router';
import AuthApi from '@/api/v1/AuthApi';
import CurrencyApi from '@/api/v1/CurrencyApi';
import LanguageApi from '@/api/v1/LanguageApi';
import CampaignApi from '@/api/v1/CampaignApi';
import CountryApi from '@/api/v1/CountryApi';
import MonolithAuthApi from '@/api/monolith/MonolithAuthApi';
import TableSettingsApi from '@/api/v1/TableSettingsApi';

const authApi = new AuthApi();
const currencyApi = new CurrencyApi();
const languageApi = new LanguageApi();
const campaignApi = new CampaignApi();
const countryApi = new CountryApi();
const monolithAuthApi = new MonolithAuthApi();
const tableSettingsApi = new TableSettingsApi();

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const app = createApp(App);

app.config.globalProperties.$dayjs = dayjs;

app.use(i18n);
app.use(createPinia());
app.use(router);
app.use(vuetify);
app.use(Vue3Toastify, {
    autoClose: 3000,
    position: 'top-right',
} as ToastContainerOptions);
app.use(VueGtm, {
	id: 'GTM-MHGG74M9' // Identyfikator konta GTM
});

Sentry.init({
	app,
	dsn: import.meta.env.VITE_SENTRY_DNS,
	environment: import.meta.env.VITE_ENV,
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["*"],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const resolveInitialRequests = async (locale:string) => {
	const { user } = storeToRefs(useUserStore());

	if (null == user.value) {
		return;
	}
    const [countriesResponse, languageResponse ,currencyResponse, tableSettingsResponse, campaignResponse] = await Promise.allSettled([
        countryApi.getCountries(),
        languageApi.getLanguages(),
        currencyApi.getCurrencies(),
        tableSettingsApi.tableSettings(user.value.active_role_id),
	    user.value.currency_id != null && user.value.addresses[0].country_id != null ? campaignApi.getCampaigns() : () => {},
    ]);
    const dictionaryStore = useDictionaryStore();

	// @ts-ignore
    if (countriesResponse.value.isSuccess) {
	    let localeTranslations = 'pl';
	    if (locale != null) {
		    localeTranslations = locale;
	    }
        await dictionaryStore.setCountries(setCountryTranslations(localeTranslations));
    }

	// @ts-ignore
    if (languageResponse.value.isSuccess) {
	    let localeTranslations = 'pl';
	    if (locale != null) {
		    localeTranslations = locale;
	    }
        await dictionaryStore.setLanguages(setLanguagesTranslations(localeTranslations));
    }

	// @ts-ignore
    if (campaignResponse.value.isSuccess) {
	    // @ts-ignore
        await dictionaryStore.setCampaigns(campaignResponse.value.payload.data);
    }

	// @ts-ignore
    if (tableSettingsResponse.value.isSuccess) {
		const { set } = useTableSettingsStore();
	    // @ts-ignore
	    for (const item in tableSettingsResponse.value.payload) {
		    // @ts-ignore
		    await set({ name: item, settings: tableSettingsResponse.value.payload[item] });
	    }
    }

	// @ts-ignore
    if (currencyResponse.value.isSuccess) {
	    // @ts-ignore
        await dictionaryStore.setCurrencies(currencyResponse.value.payload.data);

        const { setUserCurrencySymbol } = useUserStore();
        if (null != user.value) {
            const userCurrencyId = user.value.currency_id;
	        // @ts-ignore
            const currencySymbol = currencyResponse.value.payload.data.find((elem:any) => {
                return elem.id === userCurrencyId;
            })

            currencySymbol ? setUserCurrencySymbol(currencySymbol.currency_symbol) : setUserCurrencySymbol('');
        }
    }
}

const beforeAppMountActions = async () => {
	// Tworzenie loadera zanim aplikacja się załaduje
	const createElement = document.createElement('div');
	createElement.classList.add('loading');
	document.body.appendChild(createElement);

    const sessionResponse = await monolithAuthApi.getSession();

	// @ts-ignore
    const locale = sessionResponse.payload.locale || 'pl';

	// @ts-ignore
    i18n.global.locale.value = locale;

    const { authorizeFromLocalStorage } = useAuthStore();
    const { accessToken } = storeToRefs(useAuthStore())

    const { setLocale, setActiveRole, setVersion, setMaintenanceMode } = useSessionStore();
    await setLocale(locale);
	// @ts-ignore
    await setActiveRole(sessionResponse.payload.active_role);
	// @ts-ignore
    await setVersion(sessionResponse.payload.version);
	// @ts-ignore
	await setMaintenanceMode(sessionResponse.payload.maintenance_mode);
    await authorizeFromLocalStorage();

    if (
        null != accessToken.value &&
        '/' !== window.location.pathname &&
        '/register' !== window.location.pathname &&
        '/partners' !== window.location.pathname &&
        '/login/google/authorize/redirect' !== window.location.pathname
    ) {
        const meResponse = await authApi.me();
        if (!meResponse.isSuccess) {
            return;
        }

        const { setUser } = useUserStore();
        await setUser(meResponse.payload);

        const regex = /\/auth\/impersonate\/[a-z0-9-]+/;
        let m;

        if (null !== (m = regex.exec(window.location.pathname))) {
            return;
        }

        await resolveInitialRequests(locale);
    }

	// Usunięcie elementu loadera
	createElement.remove();
};

beforeAppMountActions().then(() => {
    app.mount('#app');
});

// Zapisywanie referal kodu do rejestracji z programu afiliacyjnego
watch((router.currentRoute), (newVal:any, oldVal:any) => {
	if (newVal.name === 'ResetPassword' || newVal.name === 'Register' || newVal.name === 'Rules' || newVal.name === 'PrivacyPolicy' || newVal.name === 'Partners' || newVal.name === 'Login') {
		if (null != newVal.query && null != newVal.query['m9-referer']) {
			localStorage.setItem('m9-referer', newVal.query['m9-referer']);
		}
	}
}, {
	deep: true,
});
