<template>
	<div class="ahrefs-chart">
		<MDatePicker
			id="ahrefs-date"
			v-model="ahrefsDate"
			name="dateFrom"
			:placeholder="$t('reflinks.dateFrom')"
			:language="localeLanguage"
			min-view="month"
			max-view="month"
			:disabled-dates="{from: new Date()}"
		/>
		<MProgressBar v-show="loading" indeterminate height="2" />
		<MChart v-if="chartData && chartOptions" chart-id="ahrefs-credits" :chart-options="chartOptions" :chart-data="chartData" />
		
		<div class="ahrefs-chart__csv-wrapper" @click="exportAction">
			<MIcon v-if="!exportLoading" icon="csv-file" width="20" height="20" />
			<v-progress-circular v-if="exportLoading" :color="primary400" :size="15" :width="2" indeterminate />
		</div>
		
		<div class="ahrefs-chart__info-wrapper">
			<div>{{ t('ahrefs.resetDate') }}: {{ resetDate }}</div>
			<div>{{ t('ahrefs.limits') }}: {{ limit }}</div>
			<div>{{ t('ahrefs.usage') }}: {{ usage }}</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@/stores/session';
import { dateFormat } from '@/hooks/DataHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { IAhrefsLimitsTableItem } from '@/models/AhrefsModel';
import AhrefsApi from '@/apiWebsites/v1/AhrefsApi';
import MDatePicker from '@/components/atoms/MDatePicker.vue';
import dayjs from 'dayjs';
import MChart from '@/components/atoms/MChart.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';

const ahrefsApi = new AhrefsApi();

const { t } = useI18n();
const { primary400 } = variables;
const { locale } = storeToRefs(useSessionStore());

const loading = ref<boolean>(false);
const exportLoading = ref<boolean>(false);
const resetDate = ref<string>('');
const limit = ref<number>(0);
const usage = ref<number>(0);
const ahrefsDate = ref<string>(dayjs().format('YYYY-MM-DD'));
const localeLanguage = ref('');

if ('us' === locale.value) {
	localeLanguage.value = 'en';
} else if (locale.value != null) {
	localeLanguage.value = locale.value;
} else {
	localeLanguage.value = 'pl';
}

const chartData = ref<any>({
	labels: [''], // wartości na osi x (przyjmuje string/number)
	datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
		{
			label: `${t('dashboard.netSumChart')}`, // Nazwa wyświetlanej grupy
			type: 'bar', // Typ wykresu słupkowego
			data: [''], // wartości na osi y
			backgroundColor: primary400, // Zmiana koloru słupków
		},
	],
});

const chartOptions = ref<any>({
	responsive: true,
	plugins: {
		title: {
			display: false, // Wyswietlanie/Chowanie tytułu wykresu
			text: 'Ahrefs', // Twoj tytul wykresu
		},
		tooltip: {
			callbacks: {
				label: function (tooltipItem: any) {
					let label = tooltipItem.dataset.data[tooltipItem.dataIndex] || ''; // Customowe ustawienie 2 miejsc po przeciunku w tooltipie (mozna zrobic console.loga z tooltipItem po najechaniu sie pokaze)
					return `${t('ahrefs.credits')}: ` + label;
				},
			},
		},
	},
	scales: {
		x: {
			grid: {
				display: false, // Chowanie lini osi x
			},
		},
		y: {
			grid: {
				display: true, // Chowanie lini osi y
			},
			ticks: {
				callback: function (value:number) {
					return Number(value).toLocaleString(undefined); // Formatowanie do dwóch miejsc po przecinku
				},
			},
		},
	},
});

const ahrefsLimitsList = async() => {
	loading.value = true;
	const year = +ahrefsDate.value.split('-')[0];
	const month = +ahrefsDate.value.split('-')[1];
	try {
		const result = await ahrefsApi.ahrefsLimitList(1, 40000, year, month);
		if (!result.isSuccess) {
			toast.error(`${t('ahrefs.toast.errorAhrefsList')}`);
			loading.value = false;
			return;
		}
		resetDate.value = result.payload.last_limits_and_usage[0].usage_reset_date;
		limit.value = result.payload.last_limits_and_usage[0].units_limit_workspace;
		usage.value = result.payload.last_limits_and_usage[0].units_usage_workspace;
		
		let keys:Array<string> = [];
		let values:Array<number> = [];
		result.payload.paginated_monitoring.data.forEach((elem:IAhrefsLimitsTableItem) => {
			const dateInArray = keys.find(elems => dateFormat(elems) === dateFormat(elem.created_at));
			if (null == dateInArray) {
				keys.push(dateFormat(elem.created_at));
				values.push(+elem.cost);
			} else {
				values[values.length - 1] += +elem.cost;
			}
		});
		chartData.value = {
			labels: keys, // wartości na osi x (przyjmuje string/number)
			datasets: [ // arrayka przyjmuje obiekty jeżeli dodam x obiektów to bedzie x słupków przypasowanych do odpowiedniej pozycji osi x (skopiuj obiekt ponizej)
				{
					label: `${t('ahrefs.credits')}`, // Nazwa wyświetlanej grupy
					type: 'bar', // Typ wykresu słupkowego
					data: values, // wartości na osi y
					backgroundColor: primary400, // Zmiana koloru słupków
				},
			],
		};
	} catch (e) {
		toast.error(`${t('ahrefs.toast.errorAhrefsList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
ahrefsLimitsList();

watch((ahrefsDate), () => {
	ahrefsLimitsList();
},{
	deep: true
});

const exportAction = async() => {
	exportLoading.value = true;
	try {
		const year = +ahrefsDate.value.split('-')[0];
		const month = +ahrefsDate.value.split('-')[1];
		const result = await ahrefsApi.ahrefsLimitCSVList(year, month);
		if (!result.isSuccess) {
			toast.error(`${t('ahrefs.toast.errorAhrefsCsv')}`);
			exportLoading.value = false;
			return;
		}
		window.location.href = result.payload.url;
	} catch (e) {
		toast.error(`${t('ahrefs.toast.errorAhrefsCsv')}`);
		exportLoading.value = false;
		return;
	}
	exportLoading.value = false;
};
</script>

<style scoped lang="scss">
.ahrefs-chart {
	&__info-wrapper {
		color: $grey-400;
	}
	
	&__csv-wrapper {
		width: max-content;
		cursor: pointer;
		transition: opacity 0.5s;
		
		&:hover {
			opacity: 0.5;
		}
	}
}
</style>
