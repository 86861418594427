import BaseApi from '@/api/BaseApi';
import type { GetCountriesResponseInterface } from '@/models/CountryModel';

export default class CountryApi extends BaseApi {
    async getCountries () {
        return this.get<GetCountriesResponseInterface>({
            url: '/api/v1/countries',
        });
    };
};
