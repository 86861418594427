<template>
	<div class="offers-details">
		<MProgressBar v-show="loading" indeterminate height="2" />
		<div class="offers-details__title-wrapper">
			<h1 class="offers-details__title">{{ form.name }}</h1>
			<div class="offers-details__title--btn-wrapper">
				<MButton
					id="go-to-edit-offer"
					normal
					blue400
					:label="$t('offers.editOffer')"
					@click="goToEditOffer"
				/>
			</div>
		</div>
		
		<div v-if="null != user && isModerator(user)">
			<div v-if="currentStatus === SUBMITTED_MODERATION || currentStatus === RESUBMITTED_MODERATION" class="offers-details__wrapper offers-details__wrapper--first">
				<h2 class="offers-details__subtitle">{{ $t('offers.offerAccept') }}</h2>
				<v-radio-group v-model="acceptStatus">
					<v-radio id="offer-details-approve" :label="$t('offers.approve')" :value="true" />
					<v-radio id="offer-details-reject" :label="$t('offers.reject')" :value="false" />
				</v-radio-group>
				<div v-if="acceptStatus">
					<MButton
						id="save-offer-status"
						normal
						blue400
						:label="$t('offers.save')"
						@click="saveOfferStatus"
					/>
				</div>
				<Form :validation-schema="schema" @submit="saveOfferStatus" v-show="isRejectStatus">
					<div class="offers-details__note">{{ $t('websites.note') }}</div>
					<MTextArea v-model="rejectNote" id="offers-details-text-area" name="rejectNote" />
					<div class="offers-details__area-btn">
						<MButton
							id="second-save-offer-status"
							normal
							blue400
							:label="$t('offers.save')"
						/>
					</div>
				</Form>
			</div>
			
			<div class="offers-details__windows-wrapper">
				<div class="offers-details__wrapper">
					<h2 class="offers-details__subtitle">{{ $t('offers.isFastDelivery') }}</h2>
					<MCheckbox
						id="offer-is-fast-delivery"
						v-model="form.isFastDelivery"
						name="isFastDelivery"
						:label="$t('offers.isFastDelivery')"
						:color="primary400"
					/>
					<div class="offers-details__btn-margin-top">
						<MButton
							id="save-domain-fast-delivery"
							normal
							blue400
							:label="$t('websites.save')"
							@click="saveFastDelivery"
							:loading="fastDeliveryLoading"
						/>
					</div>
				</div>
				
				<div class="offers-details__wrapper">
					<h2 class="offers-details__subtitle">{{ $t('offers.discountGroups') }}</h2>
					<Form :validation-schema="discountGroupsSchema" @submit="saveDiscountGroups" class="offers-details__discount-wrapper">
						<MTextField
							id="group1"
							v-model="form.group1"
							name="group1"
							:separate-label="$t('offers.group1')"
							:placeholder="$t('offers.groupPlaceholder')"
							:hint="$t('offers.groupHint')"
							persistent-hint
							:disabled="null != discountType"
						/>
						<MTextField
							id="group2"
							v-model="form.group2"
							name="group2"
							:separate-label="$t('offers.group2')"
							:placeholder="$t('offers.groupPlaceholder')"
							:hint="$t('offers.groupHint')"
							persistent-hint
							:disabled="null != discountType"
						/>
						<MTextField
							id="group3"
							v-model="form.group3"
							name="group3"
							:separate-label="$t('offers.group3')"
							:placeholder="$t('offers.groupPlaceholder')"
							:hint="$t('offers.groupHint')"
							persistent-hint
							:disabled="null != discountType"
						/>
						<MTextField
							id="group4"
							v-model="form.group4"
							name="group4"
							:separate-label="$t('offers.group4')"
							:placeholder="$t('offers.groupPlaceholder')"
							:hint="$t('offers.groupHint')"
							persistent-hint
							:disabled="null != discountType"
						/>
						<MTextField
							id="group5"
							v-model="form.group5"
							name="group5"
							:separate-label="$t('offers.group5')"
							:placeholder="$t('offers.groupPlaceholder')"
							:hint="$t('offers.groupHint')"
							persistent-hint
							:disabled="null != discountType"
						/>
						<div class="offers-details__btn-margin-top">
							<MButton
								id="save-group-margins"
								normal
								blue400
								:label="$t('websites.save')"
								:loading="groupLoading"
							/>
						</div>
					</Form>
				</div>
				
				<div v-if="currentStatus !== SKETCH" class="offers-details__wrapper offers-details__wrapper--margin">
					<h2 class="offers-details__subtitle">{{ $t('offers.marginOptions') }}</h2>
					<div class="offers-details__margin-blue">{{ $t('offers.currentMargin') }}</div>
					<div>
						<div class="offers-details__type-text">{{ $t('offers.marginType') }}</div>
						<v-radio-group v-model="marginType">
							<v-radio id="offer-details-margin-type-none" :label="$t('offers.none')" :value="null" />
							<v-radio id="offer-details-margin-type-cash" :label="$t('offers.cash')" :value="MARGIN_TYPE_CASH" />
							<v-radio id="offer-details-margin-type-percent" :label="$t('offers.percent')" :value="MARGIN_TYPE_PERCENT" />
						</v-radio-group>
						<Form :validation-schema="marginTypeSchema" v-show="null != marginType">
							<MTextField
								id="margin-type"
								v-model="form.marginType"
								type="marginType"
								min="0"
								name="marginType"
								:separate-label="$t('offers.marginTypeValue')"
								:placeholder="$t('offers.marginTypeValue')"
							/>
						</Form>
					</div>
					<div class="offers-details__margin-yellow">{{ $t('offers.marginDescription') }}</div>
					<div>
						<div class="offers-details__type-text">{{ $t('offers.discountType') }}</div>
						<v-radio-group v-model="discountType">
							<v-radio id="offer-details-discount-type-none" :label="$t('offers.none')" :value="null" />
							<v-radio id="offer-details-discount-type-cash" :label="$t('offers.cash')" :value="MARGIN_TYPE_CASH" />
							<v-radio id="offer-details-discount-type-percent" :label="$t('offers.percent')" :value="MARGIN_TYPE_PERCENT" />
						</v-radio-group>
						<Form :validation-schema="discountTypeSchema" v-show="null != discountType">
							<MTextField
								id="discount-type"
								v-model="form.discountType"
								type="discountType"
								min="0"
								name="discountType"
								:separate-label="$t('offers.discountTypeValue')"
								:placeholder="$t('offers.discountTypeValue')"
							/>
						</Form>
						<MTextField
							id="final-price"
							v-model="form.finalPrice"
							type="finalPrice"
							min="0"
							name="finalPrice"
							:separate-label="$t('offers.finalPrice')"
							disabled
						/>
					</div>
					<div class="offers-details__btn-margin-top">
						<MButton
							id="save-offer-margin"
							normal
							blue400
							:label="$t('websites.save')"
							@click="saveMarginAction"
							:disabled="(discountType === MARGIN_TYPE_PERCENT && form.discountType > 100) || (form.marginType < 0) || (form.discountType < 0)"
							:loading="marginLoading"
						/>
					</div>
				</div>
			</div>
		</div>
		
		<div v-if="null != form.note && (currentStatus === DISAPPROVED || currentStatus === RESUBMITTED_MODERATION)" class="offers-details__wrapper offers-details__wrapper--red">
			<h2 class="offers-details__subtitle">{{ $t('offers.offerRejected') }}</h2>
			<div class="offers-details__item-title">{{ form?.note }}</div>
		</div>
		
		<div class="offers-details__wrapper">
			<h2 class="offers-details__subtitle">{{ $t('offers.infoAboutOffer') }}</h2>
			<div class="offers-details__item-wrapper">
				<div class="offers-details__item-title">{{ $t('offers.createdData') }}</div>
				<div class="offers-details__item-text">{{ dateFormat(form?.created_at) }}</div>
			</div>
			<div class="offers-details__item-wrapper">
				<div class="offers-details__item-title">{{ $t('offers.price') }}</div>
				<div class="offers-details__item-text">{{ form?.price }} {{ changeCurrencyIdToSymbol(form.currency) }}</div>
			</div>
			<div class="offers-details__item-wrapper">
				<div class="offers-details__item-title">{{ $t('offers.status') }}</div>
				<div class="offers-details__item-text">{{ form.status }}</div>
			</div>
		</div>
		
		<div class="offers-details__windows-wrapper">
			<div class="offers-details__wrapper">
				<h2 class="offers-details__subtitle">{{ $t('offers.earnings') }}</h2>
				<div class="offers-details__item-wrapper">
					<div class="offers-details__item-title">{{ $t('offers.last30Days') }}</div>
					<div class="offers-details__item-text">
						<div v-if="0 === form.incomesMonth.length">-</div>
						<div v-for="item in form.incomesMonth" :key="item.base_currency_id">
							<div>{{ 0 != form?.incomesMonth.length ? (item.total_earn / 100).toFixed(2) : '-' }} {{ 0 !== form?.incomesMonth.length ? changeCurrencyIdToSymbol(item.base_currency_id) : null }}</div>
						</div>
					</div>
				</div>
				<div class="offers-details__item-wrapper">
					<div class="offers-details__item-title">{{ $t('offers.lastYear') }}</div>
					<div class="offers-details__item-text">
						<div v-if="0 === form.incomesYear.length">-</div>
						<div v-for="item in form.incomesYear" :key="item.base_currency_id">
							<div>{{ 0 != form?.incomesYear.length ? (item.total_earn / 100).toFixed(2) : '-' }} {{ 0 !== form?.incomesYear.length ? changeCurrencyIdToSymbol(item.base_currency_id) : null }}</div>
						</div>
					</div>
				</div>
				<div class="offers-details__item-wrapper">
					<div class="offers-details__item-title">{{ $t('offers.last5Years') }}</div>
					<div class="offers-details__item-text">
						<div v-if="0 === form.incomes5Years.length">-</div>
						<div v-for="item in form.incomes5Years" :key="item.base_currency_id">
							<div>{{ 0 != form?.incomes5Years.length ? (item.total_earn / 100).toFixed(2) : '-' }} {{ 0 !== form?.incomes5Years.length ? changeCurrencyIdToSymbol(item.base_currency_id) : null }}</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="offers-details__wrapper">
				<h2 class="offers-details__subtitle">{{ $t('offers.publications') }}</h2>
				<div class="offers-details__item-wrapper">
					<div class="offers-details__item-title">{{ $t('offers.realizedPublications') }}</div>
					<div class="offers-details__item-text">{{ form?.realizedPublications }}</div>
				</div>
				<div class="offers-details__item-wrapper">
					<div class="offers-details__item-title">{{ $t('offers.rejectedByPublisher') }}</div>
					<div class="offers-details__item-text">{{ form?.rejectedByPublisher }}</div>
				</div>
				<div class="offers-details__item-wrapper">
					<div class="offers-details__item-title">{{ $t('offers.rejectedByAdvertiser') }}</div>
					<div class="offers-details__item-text">{{ form?.rejectedByAdvertiser }}</div>
				</div>
			</div>
		</div>
		
		<WebsitesOffersHistoryBox v-if="null != user && (isModerator(user) || isAdmin(user)) && null != offerId" :id="offerId" />
		
		<div class="offers-details__btn-wrapper">
			<MButton
				normal
				blue400
				:label="$t('offers.back')"
				@click="goBack"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import router from '@/router';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';
import { dateFormat } from '@/hooks/DataHooks';
import { useDictionaryStore } from '@/stores/dictionary';
import { toast } from 'vue3-toastify';
import { isAdmin, isModerator } from '@/hooks/UserHooks';
import { MARGIN_TYPE_CASH, MARGIN_TYPE_PERCENT } from '@/hooks/OfferHooks';
import { SUBMITTED_MODERATION, DISAPPROVED, RESUBMITTED_MODERATION, APPROVED, SKETCH } from '@/hooks/WebsitesHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import OffersApi from '@/api/v1/OffersApi';
import GlobalSettingsApi from '@/api/v1/GlobalSettingsApi';
import DiscountGroupApi from '@/api/v1/DiscountGroupApi';
import MButton from '@/components/atoms/MButton.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import MTextArea from '@/components/atoms/MTextArea.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MCheckbox from '@/components/atoms/MCheckbox.vue';
import WebsitesOffersHistoryBox from '@/components/organisms/WebsitesOffersHistoryBox.vue';

const offersApi = new OffersApi();
const globalSettingsApi = new GlobalSettingsApi();
const discountGroupApi = new DiscountGroupApi();

const { user } = useUserStore();
const { t } = useI18n();
const { primary400 } = variables;
const { currencies } = useDictionaryStore();

const loading = ref<boolean>(false);
const marginLoading = ref<boolean>(false);
const groupLoading = ref<boolean>(false);
const fastDeliveryLoading = ref<boolean>(false);
const isFirstLoadComponent = ref<boolean>(true);
const currentStatus = ref<string>('');
const acceptStatus = ref<boolean>(true);
const marginType = ref<string|null>(null);
const discountType = ref<string|null>(null);
const isRejectStatus = ref<boolean>(false);
const rejectNote = ref<string|null>(null);
const currentDiscountType = ref<string|null>(null);
const form = ref<any>({
	name: null,
	price: null,
	created_at: null,
	currency: null,
	status: null,
	last30: 0,
	lastYear: 0,
	last5Years: 0,
	realizedPublications: 0,
	rejectedByPublisher: 0,
	rejectedByAdvertiser: 0,
	note: null,
	group1: null,
	group2: null,
	group3: null,
	group4: null,
	group5: null,
	marginType: null,
	discountType: null,
	finalPrice: null,
	isFastDelivery: false,
	incomesMonth: [],
	incomesYear: [],
	incomes5Years: [],
});
const defaultShareValue = ref<number|null>(null);
const offerId = ref<number | null>(null);
const offerUuid = ref<Array<string>|string|null>(null);
offerUuid.value = router.currentRoute.value.params.offerUuid;

const schema:any = yup.object({
	rejectNote: yup.string().required(`${t('websites.validation.rejectNote')}`).label('rejectNote'),
});
const discountGroupsSchema:any = yup.object({
	group1: yup.string().nullable().label('group1').test('group1', `${t('offers.validation.minDiscountGroup')}`,
	function (val) {
		if (null == val) {
			return true;
		}
		if (+val >= 0) {
			return true;
		}
		return false;
	}),
	group2: yup.string().nullable().label('group2').test('group2', `${t('offers.validation.minDiscountGroup')}`,
	function (val) {
		if (null == val) {
			return true;
		}
		if (+val >= 0) {
			return true;
		}
		return false;
	}),
	group3: yup.string().nullable().label('group3').test('group3', `${t('offers.validation.minDiscountGroup')}`,
	function (val) {
		if (null == val) {
			return true;
		}
		if (+val >= 0) {
			return true;
		}
		return false;
	}),
	group4: yup.string().nullable().label('group4').test('group4', `${t('offers.validation.minDiscountGroup')}`,
	function (val) {
		if (null == val) {
			return true;
		}
		if (+val >= 0) {
			return true;
		}
		return false;
	}),
	group5: yup.string().nullable().label('group5').test('group5', `${t('offers.validation.minDiscountGroup')}`,
	function (val) {
		if (null == val) {
			return true;
		}
		if (+val >= 0) {
			return true;
		}
		return false;
	}),
});
const marginTypeSchema:any = yup.object({
	marginType: yup.number().required(`${t('offers.validation.marginTypeRequired')}`).min(0, `${t('offers.validation.minDiscountType')}`).label('marginType'),
});
const discountTypeSchema:any = yup.object({
	discountType: yup.number().required(`${t('offers.validation.discountTypeRequired')}`).min(0, `${t('offers.validation.minDiscountType')}`).label('discountType'),
});

const globalSettings = async() => {
	loading.value = true;
	try {
		const result = await globalSettingsApi.settings();
		if (!result.isSuccess) {
			toast.error(`${t('offers.toast.errorGetGlobalSettings')}`);
			loading.value = false;
			return;
		}
		defaultShareValue.value = null != result.payload[1].value ? +result.payload[1].value : null;
	} catch (e) {
		toast.error(`${t('offers.toast.errorGetGlobalSettings')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
globalSettings();

const offerDetails = async() => {
	loading.value = true;
	try {
		const result = await offersApi.offerDetails(offerUuid.value);
		
		if (!result.isSuccess) {
			loading.value = false;
			toast.error(`${t('offers.toast.errorGetOfferDetails')}`);
			return;
		}
		
		offerId.value = result.payload.id;
		form.value.name = result.payload.name;
		form.value.price = result.payload.price;
		form.value.finalPrice = null != defaultShareValue.value ? (form.value.price * ((defaultShareValue.value / 100) + 1)).toFixed(2) : 0.00;
		form.value.currency = result.payload.currency_id;
		form.value.created_at = result.payload.created_at;
		currentStatus.value = result.payload.status;
		if (result.payload.status === APPROVED) {
			form.value.status = t('offers.approved');
		}
		if (result.payload.status === DISAPPROVED) {
			form.value.status = t('offers.disapproved');
		}
		if (result.payload.status === SUBMITTED_MODERATION) {
			form.value.status = t('offers.submittedModeration');
		}
		if (result.payload.status === RESUBMITTED_MODERATION) {
			form.value.status = t('offers.resubmittedModeration');
		}
		if (result.payload.status === SKETCH) {
			form.value.status = t('offers.sketch');
		}
		if (null != result.payload.earnings_month) {
			form.value.last30 = result.payload.earnings_month.toFixed(2);
		} else {
			const zero = 0;
			form.value.last30 = zero.toFixed(2);
		}
		if (null != result.payload.earnings_year) {
			form.value.lastYear = result.payload.earnings_year.toFixed(2);
		} else {
			const zero = 0;
			form.value.lastYear = zero.toFixed(2);
		}
		if (null != result.payload.earnings_five_years) {
			form.value.last5Years = result.payload.earnings_five_years.toFixed(2);
		} else {
			const zero = 0;
			form.value.last5Years = zero.toFixed(2);
		}
		form.value.realizedPublications = result.payload.publications_count_finished;
		form.value.rejectedByPublisher = result.payload.publications_count_content_resigned;
		form.value.rejectedByAdvertiser = result.payload.publications_count_publication_resigned;
		form.value.note = result.payload.reject_messages;
		marginType.value = result.payload.share_type;
		form.value.marginType = result.payload.share_value;
		discountType.value = result.payload.discount_type;
		currentDiscountType.value = result.payload.discount_type;
		form.value.discountType = result.payload.discount_value;
		if (0 < result.payload.discount_groups.length) {
			result.payload.discount_groups.forEach(elem => {
				form.value[`group${elem.pivot.discount_group_id}`] = elem.pivot.value;
			});
		}
		form.value.isFastDelivery = result.payload.fast_delivery === 1 ? true : false;
		form.value.incomesMonth = result.payload.incomes_month;
		form.value.incomesYear = result.payload.incomes_year;
		form.value.incomes5Years = result.payload.incomes_five_years;
	} catch (e) {
		loading.value = false;
		toast.error(`${t('offers.toast.errorGetOfferDetails')}`);
		return;
	}
	loading.value = false;
};
offerDetails();

const saveOfferStatus = async () => {
	loading.value = true;
	try {
		const changeStatusForm = {
			...(true === acceptStatus.value
				? { status: APPROVED }
				: { status: DISAPPROVED, reject_messages: rejectNote.value }
			),
		}
		const result = await offersApi.changeOfferStatus(changeStatusForm, offerUuid.value);
		if (!result.isSuccess) {
			loading.value = false;
			toast.error(`${t('offers.toast.errorSaveOfferStatus')}`);
			return;
		}
		toast.success(`${t('offers.toast.successChangeOfferStatus')}`);
		await router.push('/websites/offers');
	} catch (e) {
		loading.value = false;
		toast.error(`${t('offers.toast.errorSaveOfferStatus')}`);
		return;
	}
	loading.value = false;
};

watch((acceptStatus), (val) => {
	if (!val) {
		isRejectStatus.value = true;
	} else {
		isRejectStatus.value = false;
	}
});
const goBack = () => {
	router.push('/websites/offers');
};

const goToEditOffer = () => {
	router.push(`/websites/offer/${offerUuid.value}`);
};

const saveDiscountGroups = async() => {
	loading.value = true;
	groupLoading.value = true;
	let discountGroupsForm = {};
	if (null == currentDiscountType.value) {
		discountGroupsForm = {
			...(null == form.value.group1 || '' == form.value.group1
					? null
					: {1: +form.value.group1}
			),
			...(null == form.value.group2 || '' == form.value.group2
					? null
					: {2: +form.value.group2}
			),
			...(null == form.value.group3 || '' == form.value.group3
					? null
					: {3: +form.value.group3}
			),
			...(null == form.value.group4 || '' == form.value.group4
					? null
					: {4: +form.value.group4}
			),
			...(null == form.value.group5 || '' == form.value.group5
					? null
					: {5: +form.value.group5}
			),
		};
	} else if (currentDiscountType.value !== form.value.marginType && null == form.value.marginType) {
		discountGroupsForm = {
			...(null == form.value.group1 || '' == form.value.group1
					? null
					: {1: +form.value.group1}
			),
			...(null == form.value.group2 || '' == form.value.group2
					? null
					: {2: +form.value.group2}
			),
			...(null == form.value.group3 || '' == form.value.group3
					? null
					: {3: +form.value.group3}
			),
			...(null == form.value.group4 || '' == form.value.group4
					? null
					: {4: +form.value.group4}
			),
			...(null == form.value.group5 || '' == form.value.group5
					? null
					: {5: +form.value.group5}
			),
		};
		await saveMarginAction();
	} else {
		discountGroupsForm = {};
		form.value.group1 = null;
		form.value.group2 = null;
		form.value.group3 = null;
		form.value.group4 = null;
		form.value.group5 = null;
	}
	try {
		const result = await discountGroupApi.editDiscountGroups(offerUuid.value, discountGroupsForm, true)
		if (!result.isSuccess) {
			toast.error(`${t('offers.toast.errorSaveDiscountGroups')}`);
			loading.value = false;
			groupLoading.value = false;
			return;
		}
	} catch (e) {
		toast.error(`${t('offers.toast.errorSaveDiscountGroups')}`);
		loading.value = false;
		groupLoading.value = false;
		return;
	}
	toast.success(`${t('offers.toast.successSaveDiscountGroups')}`);
	loading.value = false;
	groupLoading.value = false;
};

watch(([marginType, discountType, form.value]), (val, oldVal) => {
	if (form.value.price != null) {
		if (!isFirstLoadComponent.value && val[0] !== oldVal[0]) {
			form.value.marginType = null;
		}
		if (!isFirstLoadComponent.value && val[1] !== oldVal[1]) {
			form.value.discountType = null;
		}
		let profit:number = 0;
		let discount:number = 0;
		switch (marginType.value) {
			case MARGIN_TYPE_CASH:
				marginTypeSchema.fields['marginType'] = yup.number().required(`${t('offers.validation.marginTypeRequired')}`).min(0, `${t('offers.validation.minDiscountType')}`).label('marginType');
				profit = +form.value.marginType;
				break;
			case MARGIN_TYPE_PERCENT:
				marginTypeSchema.fields['marginType'] = yup.number().required(`${t('offers.validation.marginTypeRequired')}`).min(0, `${t('offers.validation.minDiscountType')}`).label('marginType');
				profit = +form.value.price * (+form.value.marginType / 100);
				break;
			default:
				profit = null != defaultShareValue.value ? +form.value.price * (+defaultShareValue.value / 100) : 0;
		}
		switch (discountType.value) {
			case MARGIN_TYPE_CASH:
				discountTypeSchema.fields['discountType'] = yup.number().required(`${t('offers.validation.marginTypeRequired')}`).min(0, `${t('offers.validation.minDiscountType')}`).label('discountType');
				discount = +form.value.discountType;
				if (discount > profit) {
					discount = profit;
				}
				break;
			case MARGIN_TYPE_PERCENT:
				discountTypeSchema.fields['discountType'] = yup.number().required(`${t('offers.validation.marginTypeRequired')}`).min(0, `${t('offers.validation.minDiscountType')}`).max(100, `${t('offers.validation.maxDiscountType')}`).label('discountType');
				discount = profit * +form.value.discountType / 100;
				if (discount > profit) {
					discount = profit;
				}
				break;
			default:
				discount = 0;
		}
		form.value.finalPrice = (form.value.price + profit - discount).toFixed(2);
	}
	isFirstLoadComponent.value = true;
},{
	deep: true,
})
const saveMarginAction = async() => {
	loading.value = true;
	marginLoading.value = true;
	if (
		(
			(null != form.value.group1 && '' !== form.value.group1) ||
			(null != form.value.group2 && '' !== form.value.group2) ||
			(null != form.value.group3 && '' !== form.value.group3) ||
			(null != form.value.group4 && '' !== form.value.group4) ||
			(null != form.value.group5 && '' !== form.value.group5)
		) && (null != discountType.value)
	) {
		form.value.group1 = null;
		form.value.group2 = null;
		form.value.group3 = null;
		form.value.group4 = null;
		form.value.group5 = null;
		await saveDiscountGroups();
	}
	try {
		const editOfferUserMarginForm = {
			share_type: marginType.value,
			share_value: marginType.value != null ? +form.value.marginType : null,
			discount_type: discountType.value,
			discount_value: discountType.value != null ? +form.value.discountType : null,
		};
		const result = await offersApi.editOfferUserMargin(offerUuid.value, editOfferUserMarginForm);
		if (!result.isSuccess) {
			toast.error(`${t('offers.toast.errorSaveUserMargins')}`);
			loading.value = false;
			marginLoading.value = false;
			return;
		}
	} catch (e) {
		toast.error(`${t('offers.toast.errorSaveUserMargins')}`);
		loading.value = false;
		marginLoading.value = false;
		return;
	}
	toast.success(`${t('offers.toast.successSaveUserMargins')}`);
	loading.value = false;
	marginLoading.value = false;
};

const saveFastDelivery = async() => {
	loading.value = true;
	fastDeliveryLoading.value = true;
	const editFastDeliveryForm:any = {
		fast_delivery: form.value.isFastDelivery ? 1 : 0,
	};
	try {
		const result = await offersApi.editOffer(editFastDeliveryForm, offerUuid.value);
		if (!result.isSuccess) {
			toast.error(`${t('offers.toast.errorSaveFastDelivery')}`);
			loading.value = false;
			fastDeliveryLoading.value = false;
			return;
		}
	} catch (e) {
		toast.error(`${t('offers.toast.errorSaveFastDelivery')}`);
		loading.value = false;
		fastDeliveryLoading.value = false;
		return;
	}
	toast.success(`${t('offers.toast.successSaveFastDelivery')}`);
	loading.value = false;
	fastDeliveryLoading.value = false;
};

const changeCurrencyIdToSymbol = (currencyId:number|null) => {
	const currenciesList = currencies.find(elem => elem.id === currencyId);
	if (currenciesList != null) {
		return currenciesList.currency_symbol || '-';
	}
	return '-';
};
</script>

<style scoped lang="scss">
.offers-details {
	display: flex;
	flex-direction: column;
	gap: 64px;
	
	&__title-wrapper {
		display: flex;
		gap: 10px;
	}
	
	&__title {
		text-transform: uppercase;
		font-size: 24px;
		font-weight: 400;
		color: $primary-400;
		letter-spacing: 1px;
		
		&--btn-wrapper {
			margin-left: auto;
			display: flex;
			gap: 10px;
		}
	}
	
	&__wrapper {
		background-color: $white-100;
		border-radius: 6px;
		padding: 24px;
		box-shadow: 10px 10px 40px 0 #0000000D;
		display: flex;
		flex-direction: column;
		gap: 24px;
		color: $primary-400;
		
		&--red {
			background-color: $secondary-400;
			color: $white-100;
		}
		
		&--no-gap {
			gap: 0;
			
			h2 {
				margin-bottom: 24px;
			}
		}
		
		&--first {
			margin-bottom: 15px;

			&:deep(.v-input__details) {
				display: none;
			}
		}
		
		&--margin {
			gap: 10px;
			
			&:deep(.v-selection-control-group) {
				display: flex;
				flex-direction: row !important;
			}
		}
		
		&:deep(.v-input input) {
			min-height: 20px;
		}
		
		&:deep(.m-checkbox .v-input__control) {
			margin-left: -10px;
		}
		
		&:deep(.v-radio-group > .v-input__control) {
			margin-left: -10px;
		}
		
		&:deep(.v-input__details) {
		    padding: 6px 0 0;
		}
	}
	
	&__subtitle {
		font-size: 16px;
		font-weight: 600;
		text-transform: uppercase;
	}
	
	&__item-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	&__item-title {
		font-size: 14px;
		font-weight: 300;
	}
	
	&__item-text {
		font-size: 12px;
		font-weight: 600;
	}
	
	&__windows-wrapper {
		display: flex;
		flex-direction: column;
		gap: 32px;
		
		@include media-breakpoint-up(lg) {
			flex-direction: row;
			gap: 5px;
			
			.offers-details__wrapper {
				width: 50%;
			}
		}
		
		@include media-breakpoint-up(xl) {
			gap: 32px;
		}
	}
	
	&__btn-wrapper {
		display: flex;
		width: max-content;
		margin-left: auto;
		
		button {
			padding: 25px 30px !important;
		}
	}
	
	&__area-btn {
		margin-top: 25px;
	}
	
	&__btn-margin-top {
		margin-top: auto;
	}
	
	&__note {
		font-size: 12px;
		color: $grey-400;
		font-weight: 700;
	}
	
	&__discount-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		height: 100%;
	}
	
	&__margin-blue {
		background-color: $blue-400;
		font-weight: 700;
		padding: 10px 15px;
		border-radius: 8px;
		color: $white-100;
	}
	
	&__margin-yellow {
		background-color: $yellow-500;
		font-weight: 700;
		padding: 10px 15px;
		border-radius: 8px;
		color: $white-100;
	}
	
	&__type-text {
		color: $grey-400;
		font-weight: 700;
	}
	
	&:deep(.v-selection-control .v-label) {
		font-size: 14px;
	}
}
</style>
