<template>
	<div class="cart-preview">
		<div class="cart-preview__title-wrapper">
			<CartName :order-uuid="orderPreview.order_uuid" :name="orderPreview.name" @cartNameChanged="handleEditCartName" />
			<h1 class="cart-preview__title">{{ $t('basket.preview.order') }}: #{{ orderPreview.order_id }}</h1>
		</div>
		
		<div class="cart-preview__main-wrapper">
			<div class="cart-preview__left-wrapper">
				<CartListTable :items="orderPreview.items" :loading="loading" :total="orderPreview.items.length" :user-id="orderPreview.user_id" :status="orderPreview.status" :creator="orderPreview.creator" @reloadCart="cartDetails" />
				<div class="cart-preview__btn-wrapper">
					<MButton
						v-if="orderPreview.items.length > 0 && null != user && ((isModerator(user) && orderPreview.status !== CREATE_CART_STATUS_SEND && orderPreview.status !== CREATE_CART_STATUS_FINISHED) || (isAdvertiser(user) && null == orderPreview.creator))"
						id="back-btn"
						blue400
						normal
						:label="$t('basket.preview.back')"
						@click="goBack"
					/>
					<MButton
						v-if="orderPreview.items.length > 0 && null != user && isModerator(user) && orderPreview.status !== CREATE_CART_STATUS_SEND && orderPreview.status !== CREATE_CART_STATUS_FINISHED"
						id="add-items-btn"
						green400
						normal
						:label="$t('basket.preview.addByUuid')"
						@click="openAddOfferDialog"
					/>
					<MButton
						v-if="orderPreview.items.length > 0 && orderPreview.items[0].orderable_type !== DEPOSIT_REQUEST && null != user && (isModerator(user) && orderPreview.status !== CREATE_CART_STATUS_SEND && orderPreview.status !== CREATE_CART_STATUS_FINISHED)"
						id="add-items-btn"
						green400
						normal
						:label="$t('basket.preview.add')"
						@click="goToOfferFeed"
					/>
				</div>
			</div>
			<div class="cart-preview__right-wrapper">
				<CartPreviewSummary
					:loading="loading"
					:items="orderPreview.order_details"
					:status="orderPreview.status"
					:order-uuid="orderPreview.order_uuid"
					:creator="orderPreview.creator"
					@reloadCart="cartDetails"
				/>
				<CartPreviewSummaryCreator
					v-if="null != orderPreview.creator"
					:loading="loading"
					:items="orderPreview.creator"
				/>
				<MTooltip v-if="orderPreview.status === CREATE_CART_STATUS_DRAFT" without-btn>
					<template v-slot:title>
						<CartPreviewSlug
							v-if="null != user && isModerator(user)"
							:loading="loading"
							:slug="orderPreview.slug"
							:assignedId="orderPreview.user_id"
							:campaignId="orderPreview.items.length > 0 ? orderPreview.items[0].campaign_id : null"
							:uuid="orderPreview.uuid"
							:status="orderPreview.status"
						/>
					</template>
					<template v-slot:content>
						<div>{{ $t('basket.preview.linkDisabledDescription') }}</div>
					</template>
				</MTooltip>
				<CartPreviewSlug
					v-if="null != user && isModerator(user) && orderPreview.status !== CREATE_CART_STATUS_DRAFT"
					:loading="loading"
					:slug="orderPreview.slug"
					:assignedId="orderPreview.user_id"
					:campaignId="orderPreview.items.length > 0 ? orderPreview.items[0].campaign_id : null"
					:uuid="orderPreview.uuid"
					:status="orderPreview.status"
				/>
				<div v-if="null != user && null != orderPreview.order_details" class="cart-preview__bottom-right-wrapper">
					<MTooltip v-if="orderPreview.status === CREATE_CART_STATUS_DRAFT" without-btn>
						<template v-slot:title>
							<MButton
								v-if="isModerator(user)"
								id="copy-to-clipboard-cart-link"
								:label="$t('basket.preview.link')"
								grey400
								disabled
							/>
						</template>
						<template v-slot:content>
							<div>{{ $t('basket.preview.linkDisabledDescription') }}</div>
						</template>
					</MTooltip>
					<MButton
						v-if="isModerator(user) && orderPreview.status !== CREATE_CART_STATUS_DRAFT"
						id="copy-to-clipboard-cart-link"
						:label="$t('basket.preview.link')"
						blue400
						@click="copyToClipboard(currentHost() + '/carts/' + orderPreview.slug + '/preview', $t('contadu.toast.successCopyToClipboard'), $t('contadu.toast.errorCopyToClipboard'))"
					/>
					<MButton
						v-if="!isModerator(user) && orderPreview.status !== CREATE_CART_STATUS_RESIGNED"
						id="cart-pay"
						green400
						normal
						:label="$t('basket.preview.pay')"
						@click="pay"
						:loading="payLoading"
					/>
					<MButton
						v-if="isModerator(user) && (orderPreview.status === CREATE_CART_STATUS_DRAFT || orderPreview.status === CREATE_CART_STATUS_RESIGNED)"
						id="cart-send"
						green400
						normal
						:label="$t('basket.preview.send')"
						@click="send"
						:loading="payLoading"
					/>
				</div>
			</div>
		</div>
		
		<MDialog
			:is-close-dialog-action="isCloseAddOfferDialog"
			:is-open-dialog-action="isOpenAddOfferDialog"
			:content-title="$t('basket.preview.addOffer')"
			@close-dialog="closeDialogWhenNoTargetPressed"
		>
			<template v-slot:default>
				<div class="cart-preview__dialog-wrapper">
					<div class="cart-preview__dialog-text">{{ $t('basket.preview.addOfferUsingUuid') }}</div>
					<div class="cart-preview__input-dialog">
						<MTextField
							id="add-offer-uuid"
							v-model="offerUuid"
							name="offerUuid"
							:separate-label="$t('basket.preview.offerUuid')"
							label-text-required
							clearable
						/>
					</div>
					<div class="cart-preview__input-dialog">
						<MTextField
							id="add-offer-count"
							v-model="offerAmount"
							name="offerAmount"
							:separate-label="$t('basket.preview.offerAmount')"
							label-text-required
							type="number"
							step="1"
							min="1"
							max="100"
						/>
					</div>
					<div class="cart-preview__dialog-btn-wrapper">
						<MButton
							id="cart-preview-add-offer-by-uuid-action"
							normal
							green400
							width110
							:loading="addOfferLoading"
							:color="white"
							:label="$t('components.add')"
							@click="addOfferAction"
							:disabled="null == offerUuid || '' === offerUuid"
						/>
						<MButton
							id="cart-preview-close-add-modal-action"
							normal
							blue400
							width110
							:color="white"
							:label="$t('components.cancel')"
							@click="closeDialogWhenNoTargetPressed"
						/>
					</div>
				</div>
			</template>
		</MDialog>
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { isAdvertiser, isModerator, refreshUser } from '@/hooks/UserHooks';
import { toast } from 'vue3-toastify';
import CartApi from '@/api/v2/CartApi';
import { useChangeCurrencyIdToSymbol } from '@/hooks/CurrencyHook';
import {
	CREATE_CART_STATUS_DRAFT,
	CREATE_CART_STATUS_FINISHED,
	CREATE_CART_STATUS_RESIGNED,
	CREATE_CART_STATUS_SEND,
	saveSlugRedirect,
} from '@/hooks/CartHooks';
import { ROUTE_CART_PREVIEW } from '@/hooks/RoutingHooks';
import { ARTICLE_REQUEST, DEPOSIT_REQUEST, PUBLICATION_REQUEST } from '@/hooks/OrderHooks';
import { copyToClipboard } from '@/hooks/CopyToClipboardHooks';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { IAddOfferToCartByUuidRequest, ICartPreviewDetails, ICartStatusRequest } from '@/models/CartModel';
import CartListTable from '@/views/Cart/components/CartListTable.vue';
import MButton from '@/components/atoms/MButton.vue';
import CartPreviewSummary from '@/views/Cart/components/CartPreviewSummary.vue';
import CartPreviewSummaryCreator from '@/views/Cart/components/CartPreviewSummaryCreator.vue';
import CartPreviewSlug from '@/views/Cart/components/CartPreviewSlug.vue';
import MDialog from '@/components/atoms/MDialog.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';
import CartName from '@/components/organisms/CartName.vue';

const cartApi = new CartApi();

const { t } = useI18n();
const { user } = storeToRefs(useUserStore());
const { setUser, setUserCurrencySymbol } = useUserStore();
const { white } = variables;

const loading = ref<boolean>(false);
const orderPreview = ref<ICartPreviewDetails>({
	uuid: null,
	order_id: null,
	order_uuid: null,
	user_id: null,
	cart_uuid: null,
	items: [],
	order_details: null,
	creator: null,
	slug: null,
	status: null,
	name: null,
});

const cartDetails = async () => {
	loading.value = true;
	
	if (null == user.value) {
		await router.push('/');
		return;
	}
	try {
		const result = await cartApi.cartDetailsPreview(null != router.currentRoute.value.params.cartUuid ? router.currentRoute.value.params.cartUuid : router.currentRoute.value.params.orderUuid, router.currentRoute.value.name !== ROUTE_CART_PREVIEW);
		if (!result.isSuccess) {
			toast.error(`${t('basket.preview.toast.errorCartDetails')}`);
			router.go(-1);
			loading.value = false;
			return;
		}

		orderPreview.value.uuid = result.payload.uuid;
		orderPreview.value.order_id = result.payload.order_id;
		orderPreview.value.order_uuid = result.payload.new_order?.uuid;
		orderPreview.value.name = result.payload.new_order?.name;
		orderPreview.value.user_id = result.payload.assigned_id;
		orderPreview.value.slug = result.payload.slug;
		orderPreview.value.status = result.payload.status;
		orderPreview.value.cart_uuid = router.currentRoute.value.name !== ROUTE_CART_PREVIEW ? result.payload.new_order?.uuid : result.payload.uuid;
		orderPreview.value.order_details = {
			company_name: result.payload.assigned?.company_name,
			assigned_first_name: result.payload.assigned?.first_name,
			assigned_last_name: result.payload.assigned?.last_name,
			coupon_id: null != user.value &&  (isModerator(user.value) || (!isModerator(user.value) && null != result.payload.creator)) && result.payload.new_order.net_before_discount !== result.payload.new_order.net ? 1 : result.payload.coupon_id ,
			coupon_type: result.payload.coupon?.type,
			coupon_value: null != result.payload.coupon?.value ? result.payload.coupon?.value / 100 : result.payload.coupon?.value,
			net: null != result.payload.new_order?.net ? result.payload.new_order?.net / 100 : result.payload.new_order?.net,
			tax: null != result.payload.new_order?.tax ? result.payload.new_order?.tax / 100 : result.payload.new_order?.tax,
			gross: null != result.payload.new_order?.gross ? result.payload.new_order?.gross / 100 : result.payload.new_order?.gross,
			net_before_discount: null != result.payload.new_order?.net_before_discount ? result.payload.new_order?.net_before_discount / 100 : result.payload.new_order?.net_before_discount,
			discount_value: null != result.payload.new_order?.discount_value ? result.payload.new_order?.discount_value / 100 : result.payload.new_order?.discount_value,
			reflink_value: null != result.payload.new_order?.reflink_value ? result.payload.new_order?.reflink_value / 100 : result.payload.new_order?.reflink_value,
			order_type: result.payload.new_order_items.length > 0 ? result.payload.new_order_items[0].orderable_type : null,
		};
		if (null != result.payload.creator && null != result.payload.creator.first_name) {
			orderPreview.value.creator = {
				creator_first_name: result.payload.creator?.first_name,
				creator_last_name: result.payload.creator?.last_name,
			}
		}
		orderPreview.value.items = result.payload.new_order_items.map((elem: any) => {
			return {
				...elem,
				campaign_id: result.payload.campaign_id,
				is_edit_mode: false,
				prev_price: elem.net / 100,
				price: elem.net / 100,
				discount_value: elem.discount_value / 100,
				net_client: elem.net_client / 100,
				min_price: elem.min_price / 100,
			};
		});
		orderPreview.value.items.forEach((elem: any) => {
			let articleId: number | null = null;
			if (elem.orderable_type === PUBLICATION_REQUEST && elem.orderable.article != null) {
				const publicationIndex = orderPreview.value.items.indexOf(elem);
				articleId = elem.orderable.article.id;
				const article = orderPreview.value.items.find((elem: any) => elem.orderable_type === ARTICLE_REQUEST && elem.orderable.article.id === articleId);
				if (null != article) {
					const articleIndex = orderPreview.value.items.indexOf(article);
					orderPreview.value.items[publicationIndex].ordered_article = article;
					orderPreview.value.items[publicationIndex].ordered_article_is_edit_mode = false;
					orderPreview.value.items[publicationIndex].ordered_article_price = null != article.price ? article.price : null;
					orderPreview.value.items[publicationIndex].ordered_article_prev_price = null != article.price ? article.price : null;
					orderPreview.value.items[publicationIndex].ordered_article_discount = null != article.discount_value ? article.discount_value : null;
					orderPreview.value.items[publicationIndex].ordered_article_net_client = null != article.net_client ? article.net_client : null;
					orderPreview.value.items[publicationIndex].ordered_article_min_price = null != article.min_price ? article.min_price : null;
					orderPreview.value.items.splice(articleIndex, 1);
				}
			}
		});
		
		if (null != user.value && isModerator(user.value)) {
			const { user } = await refreshUser();
			setUser(user);
			setUserCurrencySymbol(useChangeCurrencyIdToSymbol(user.currency_id));
		}
		
		if (null != user.value && isAdvertiser(user.value) && router.currentRoute.value.name === ROUTE_CART_PREVIEW && orderPreview.value.status === CREATE_CART_STATUS_DRAFT) {
			await router.push('/dashboard');
		}
	} catch (e) {
		toast.error(`${t('basket.preview.toast.errorCartDetails')}`);
		router.go(-1);
		loading.value = false;
		return;
	}
	loading.value = false;
};
cartDetails();

const goToOfferFeed = () => {
	if (null != user.value && isModerator(user.value)) {
		router.push(`/carts/${orderPreview.value.cart_uuid}`);
	} else {
		router.push(`/websites/${orderPreview.value.items[0].campaign_id}/orders`);
	}
};

const goBack = () => {
	router.go(-1);
};

const checkIsSlugToSave = () => {
	if (null == user.value) {
		saveSlugRedirect(router.currentRoute.value.params.cartUuid);
	} else {
		localStorage.removeItem('cart-slug');
	}
};
checkIsSlugToSave();

const isCloseAddOfferDialog = ref<boolean>(true);
const isOpenAddOfferDialog = ref<boolean>(false);
const addOfferLoading = ref<boolean>(false);
const offerUuid = ref<string | null>(null);

const closeDialogWhenNoTargetPressed = () => {
	isCloseAddOfferDialog.value = true;
	isOpenAddOfferDialog.value = false;
	
	offerUuid.value = null;
};
const openAddOfferDialog = () => {
	isCloseAddOfferDialog.value = false;
	isOpenAddOfferDialog.value = true;
};
const addOfferAction = async() => {
	addOfferLoading.value = true;
	try {
		const addOfferForm: IAddOfferToCartByUuidRequest = {
			cart_uuid: orderPreview.value.cart_uuid,
			offer_uuid: offerUuid.value,
			amount: offerAmount.value,
		};
		const result = await cartApi.addOfferToCartByUuid(addOfferForm);
		if (!result.isSuccess) {
			toast.error(`${t('basket.preview.toast.errorAddOffer')}`);
			addOfferLoading.value = false;
			return;
		}
		await closeDialogWhenNoTargetPressed();
		await cartDetails();
	} catch (e) {
		closeDialogWhenNoTargetPressed();
		toast.error(`${t('basket.preview.toast.errorAddOffer')}`);
		addOfferLoading.value = false;
		return;
	}
	addOfferLoading.value = false;
};

const payLoading = ref<boolean>(false);
const pay = async() => {
	payLoading.value = true;
	
	let isArticleSelected = true;
	orderPreview.value.items.forEach((elem:any) => {
		if (null != user.value && null != elem.orderable && null == elem.orderable.article && elem.orderable_type !== DEPOSIT_REQUEST) {
			isArticleSelected = false;
			return;
		}
	});
	
	if (!isArticleSelected) {
		toast.error(`${t('basket.preview.toast.addArticleBeforePayment')}`);
		payLoading.value = false;
		return;
	}
	
	if (orderPreview.value.status === CREATE_CART_STATUS_FINISHED || orderPreview.value.status == null) {
		await router.push(`/payments/${orderPreview.value.order_uuid}`);
	} else {
		const changeStatusRequestForm: ICartStatusRequest = {
			status: CREATE_CART_STATUS_FINISHED,
			cart_uuid: orderPreview.value.cart_uuid,
		};
		try {
			const result = await cartApi.changeCartStatus(changeStatusRequestForm);
			if (!result.isSuccess) {
				toast.error(`${t('basket.preview.toast.errorUpdateCart')}`);
				payLoading.value = false;
				return;
			}
			toast.success(`${t('basket.preview.toast.successUpdateCart')}`);
			await cartDetails();
			await router.push(`/payments/${orderPreview.value.order_uuid}`);
		} catch (e) {
			toast.error(`${t('basket.preview.toast.errorUpdateCart')}`);
			payLoading.value = false;
			return;
		}
		payLoading.value = false;
	}
};

const sendLoading = ref<boolean>(false);
const send = async() => {
	sendLoading.value = true;
	
	const changeStatusRequestForm: ICartStatusRequest = {
		status: CREATE_CART_STATUS_SEND,
		cart_uuid: orderPreview.value.cart_uuid,
	};
	try {
		const result = await cartApi.changeCartStatus(changeStatusRequestForm);
		if (!result.isSuccess) {
			toast.error(`${t('basket.preview.toast.errorUpdateCart')}`);
			sendLoading.value = false;
			return;
		}
		toast.success(`${t('basket.preview.toast.successUpdateCart')}`);
		await cartDetails();
	} catch (e) {
		toast.error(`${t('basket.preview.toast.errorUpdateCart')}`);
		sendLoading.value = false;
		return;
	}
	sendLoading.value = false;
};

const currentHost = () => {
	return import.meta.env.VITE_MONOLITH_API_HOST;
};

const offerAmount = ref<number>(1);
watch((offerAmount), (val) => {
	if (val <= 0) {
		offerAmount.value = 1;
	}
	if (val > 100) {
		offerAmount.value = 100;
	}
});

const handleEditCartName = (newCartName: string) => {
	orderPreview.value.name = newCartName;
};
</script>

<style scoped lang="scss">
.cart-preview {
	&__title-wrapper {
		display: flex;
		align-items: center;
		margin-bottom: 29px;
		width: 100%;
		
		@include media-breakpoint-up(xl) {
			width: calc(70% - 20px);
		}
	}
	
	&__title {
		font-size: 24px;
		font-weight: 700;
		color: $primary-400;
		margin-left: auto;
	}
	
	&__main-wrapper {
		display: flex;
		flex-direction: column;
		gap: 30px;
		
		@include media-breakpoint-up(xl) {
			flex-direction: row;
		}
	}
	
	&__left-wrapper {
		width: 100%;
		
		@include media-breakpoint-up(xl) {
			width: 70%;
		}
	}
	
	&__right-wrapper {
		width: 100%;
		
		@include media-breakpoint-up(xl) {
			width: 30%;
		}
		
		&:deep(.m-tooltip) {
			width: 100%;
		}
	}
	
	&__bottom-right-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		gap: 12px;
		margin-top: 14px;
		
		&:deep(.m-btn) {
			width: 147px;
			padding: 19px 18px !important;
			text-transform: uppercase;
			font-weight: 700;
		}
		
		&:deep(.m-tooltip) {
			width: max-content;
		}
	}
	
	&__btn-wrapper {
		margin-top: 14px;
		display: flex;
		flex-wrap: wrap;
		gap: 11px;
		justify-content: flex-end;
		
		&:deep(.m-btn) {
			width: 147px;
			padding: 19px 18px !important;
			text-transform: uppercase;
		}
	}
	
	&__dialog-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}
	
	&__dialog-text {
		font-size: 14px;
		font-weight: 700;
		color: $primary-400;
	}
	
	&__dialog-btn-wrapper {
		display: flex;
		gap: 50px;
	}
	
	&__input-dialog {
		width: 100%;
		
		&:deep(.v-input__details) {
			display: none;
		}
	}
}
</style>
