export const FINISHED = 'FINISHED';
export const PENDING = 'In Progress';
export const ERROR = 'Error';
export const CORRUPTED = 'Corrupted';
export const RESTORING = 'Restoring';
export const OPENSEARCH_REFRESH_TYPE = 'opensearch-refresh';

// Import statuses

export const VALIDATED = 'validated';
export const PROCESSING = 'processing';
export const ERROR_SYSTEM = 'error';
export const REVALIDATING = 'revalidating';
export const READ_ONLY = 'locked';
export const SAVED = 'saved';
export const CREATED = 'created';
export const QUEUED = 'queued';

// Import preview statuses
export const PREVIEW_STATUS_OK = 'ok';
export const PREVIEW_STATUS_WARNING = 'warning';
export const PREVIEW_STATUS_ERROR = 'error';

// Import preview discount type
export const PREVIEW_PERCENT = 'PERCENT';
export const PREVIEW_CASH = 'CACHE';
