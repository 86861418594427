<template>
	<div class="website-list">
		<div class="website-list__top-wrapper">
			<h1 class="website-list__title">{{ $t('websites.websiteList') }}</h1>
			<MButton
				v-if="null != user && !isModerator(user) && !isAdmin(user)"
				id="website-create"
				@click="createDomainRedirect"
				:label="$t('websites.addDomain')"
				blue400
				big
			/>
		</div>
		<WebsiteListTable />
	</div>
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user';
import { isModerator, isAdmin } from '@/hooks/UserHooks';
import router from '@/router';
import MButton from '@/components/atoms/MButton.vue';
import WebsiteListTable from '@/components/organisms/Websites/WebsiteListTable.vue';

const { user } = useUserStore();

const createDomainRedirect = () => {
	router.push('/websites/create');
};
</script>

<style scoped lang="scss">
.website-list {
	&__top-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 64px;
		
		&:deep(.m-btn) {
			font-weight: 600;
			padding: 30px 33px !important;
		}
	}
	
	&__title {
		text-transform: uppercase;
		font-size: 24px;
		font-weight: 400;
		color: $primary-400;
		letter-spacing: 1px;
	}
}
</style>
