<template>
    <div class="m-checkbox" :class="{'m-checkbox--flex-column': infoSpace}">
        <v-checkbox
            :id="id"
            v-model="value"
            :label="label"
            :disabled="disabled"
            :error-messages="errors"
            :error="errors.length > 0"
        ></v-checkbox>
        <slot name="beforeLink"></slot>
        <MButton v-if="to" :to="to" class="m-checkbox__link" :label="linkTitle" />
        <slot name="afterLink"></slot>
    </div>
</template>

<script setup lang="ts">
import MButton from '@/components/atoms/MButton.vue';
import { defineProps, toRef, defineEmits, computed } from 'vue';
import { useField } from 'vee-validate';

interface IOwnProps {
    label?: string
    name?: string
    modelValue?: any
    to?: string
    linkTitle?: string
    color?: string
    disabled?: boolean
    id?: string
	infoSpace?: boolean
}

interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();
const emit = defineEmits(['update:modelValue']);

const { errors } = useField(toRef<IOwnProps, any>(props, 'name'), undefined);

const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});
</script>

<style scoped lang="scss">
.m-checkbox {
    display: flex;
    align-items: center;
    width: max-content;
	
	&--flex-column {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		
		&:deep(.v-input--density-default) {
			--v-input-control-height: 36px;
			margin-left: -10px;
			margin-top: 10px;
			margin-bottom: -10px;
		}
	}
    
    &__link {
        margin-left: 5px;
    }
	
	&:deep(.v-selection-control__input) {
		color: $primary-400;
	}
}
</style>
