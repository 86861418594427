<template>
	<div v-if="null != user" class="article-list-table" :class="{'article-list-table-dashboard transparent-scroll': isDashboardView}">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			v-model:page="options.page"
			:items-per-page="options.itemsPerPage"
			:sort-by="options.sortBy"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template #loading>
				<div class="article-list-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #top>
				<div v-if="!isDashboardView" class="article-list-table__top-wrapper">
					<div class="article-list-table__search-wrapper">
						<MTextField
							id="article-search"
							v-model="search"
							name="search"
							variant="outlined"
							label=""
							:placeholder="$t('articles.search2')"
							clearable
						/>
					</div>
					<div class="article-list-table__search-wrapper article-list-table__search-wrapper--article-type">
						<MAutocomplete
							id="article-type"
							v-model="articleType"
							:get-items="articleTypeItems"
							clearable
							variant="outlined"
							item-title="name"
							item-value="slug"
							:placeholder="$t('articles.selectType')"
						/>
					</div>
					<div v-if="user.is_agency" class="article-list-table__search-wrapper">
						<MAutocomplete
							id="article-dependent-account"
							v-model="dependentAccountUuid"
							:get-items="dependentAccountList"
							clearable
							variant="outlined"
							item-title="email"
							item-value="uuid"
							:placeholder="$t('articles.selectAccount')"
						/>
					</div>
					
					<div v-if="null != ARTICLE_LIST_V2" class="article-list-table__table-settings-wrapper">
						<TableSettingsMenuWithModal :table-name="ARTICLE_LIST_V2" :is-cleared-table-settings-action="isClearedTableSettingsAction" @headersMove="headersMoveAction" :on-clear-action="clearTableSettingsAction" />
					</div>
				</div>
			</template>
			
			<template #item.campaign="elem">
				<div class="article-list-table__campaign-title m9-data-table-align-left" :class="{'article-list-table__campaign-title--dashboard': isDashboardView}">{{ campaignName(elem.item.raw.campaign_id) }}</div>
				<div v-if="!isDashboardView" class="article-list-table__campaign-url m9-data-table-align-left">{{ campaignUrl(elem.item.raw.campaign_id) }}</div>
			</template>
			
			<template #item.title="elem">
				<div class="article-list-table__language-wrapper m9-data-table-align-left">
					<MFlag v-if="!isDashboardView" :country-code="languageSymbol(elem.item.raw.language_id)" />
					<div class="article-list-table__language-title" :class="{'article-list-table__language-title--dashboard': isDashboardView}">{{ elem.item.raw.title }}</div>
				</div>
			</template>
			
			<template #item.article_language="elem">
				<div class="article-list-table__language-wrapper article-list-table__language-wrapper--dashboard">
					<MFlag v-if="isDashboardView" :country-code="languageSymbol(elem.item.raw.language_id)" />
				</div>
			</template>
			
			<template #item.account="elem">
				<div v-if="elem.item.raw.user_email != null" class="article-list-table__account-wrapper">
					<div v-if="elem.item.raw.user_first_name != null && elem.item.raw.user_last_name != null">{{ elem.item.raw.user_first_name }} {{ elem.item.raw.user_last_name }}</div>
					<div>{{ elem.item.raw.user_email }}</div>
				</div>
			</template>
			
			<template #item.type="elem">
				<div class="article-list-table__type-wrapper">
					<MTooltip without-btn>
						<template v-slot:title>
							<MIcon :icon="null == elem.item.raw.article_request_id ? 'pen-fancy': 'box-open'" />
						</template>
						<template v-slot:content>
							<div>{{ null == elem.item.raw.article_request_id ? $t('articles.yourArticle'): $t('articles.orderedArticle') }}</div>
						</template>
					</MTooltip>
				</div>
			</template>
			
			<template #item.status="elem">
				<div class="article-list-table__status-wrapper">
					<MButton id="article-publish" green400 @click="publishArticle(elem.item.raw.campaign_id, elem.item.raw.language_id)" :label="$t('articles.publish')" />
				</div>
			</template>
			
			<template #item.updated="elem">
				<div>
					<div class="article-list-table__update-date">{{ dateFormat(elem.item.raw.updated_at) }}</div>
					<div class="article-list-table__created-date">({{ $t('articles.createdAt') }} {{ dateFormat(elem.item.raw.created_at) }})</div>
				</div>
			</template>
			
			<template #item.createdAt="elem">
				<div v-if="null != elem.item.raw.created_at">{{ dateFormat(elem.item.raw.created_at) }}</div>
			</template>
			
			<template v-slot:item.actions="elem">
				<div class="article-list-table__action-wrapper">
					<MTooltip without-btn v-if="!isDashboardView">
						<template v-slot:title>
							<div class="article-list-table__optimalize-btn">
								<MButton id="optimize-article" @click="openOptimalizeDialog(elem.item.raw.uuid)" grey400 :color="white" width="14" height="14" class="optimize-article">
									<img src="../../../assets/images/contadu-icon.png" alt="contadu">
								</MButton>
							</div>
						</template>
						<template v-slot:content>
							<div>{{ $t('contadu.contaduTooltip') }}</div>
						</template>
					</MTooltip>
					<MTooltip without-btn v-if="!isDashboardView">
						<template v-slot:title>
							<MButton v-if="!isDashboardView" id="preview-article" @click="previewArticle(elem.item.raw.uuid)" icon="eye" grey300 :color="white" width="14" height="14" class="preview-article" />
						</template>
						<template v-slot:content>
							<div>{{ $t('articles.preview') }}</div>
						</template>
					</MTooltip>
					<MTooltip without-btn>
						<template v-slot:title>
							<MButton id="edit-article" @click="editArticle(elem.item.raw.uuid)" icon="pencil" grey300 :color="white" width="14" height="14" class="edit-article" />
						</template>
						<template v-slot:content>
							<div>{{ $t('articles.edit') }}</div>
						</template>
					</MTooltip>
					<MTooltip without-btn v-if="!isDashboardView">
						<template v-slot:title>
							<MButton v-if="!isDashboardView" id="delete-article" @click="deleteArticle(elem.item.raw.uuid)" icon="close" grey300 :color="white" width="14" height="14" class="delete-article" />
						</template>
						<template v-slot:content>
							<div>{{ $t('articles.delete') }}</div>
						</template>
					</MTooltip>
				</div>
			</template>
			
			<template #bottom v-if="isDashboardView && items.length > 0">
				<div></div>
			</template>
		</v-data-table-server>
		
		<MDialog
			:content-title="$t('contadu.createOptimalize')"
			:is-open-dialog-action="isOpenContaduDialog"
			:is-close-dialog-action="isCloseContaduDialog"
			@close-dialog="closeDialogWhenNoTargetPressed"
			:width="700"
		>
			<template v-slot:default>
				<OptimizePoppupWindow v-if="articleUuid && null != availableOptimizations" :optimize-article-uuid="articleUuid" :available-optimizations="availableOptimizations" />
			</template>
		</MDialog>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { defineProps, ref, watch } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useSessionStore } from '@/stores/session';
import { useDictionaryStore } from '@/stores/dictionary';
import { useArticleStore } from '@/stores/article';
import { toast } from 'vue3-toastify';
import { useTableSettingsStore } from '@/stores/tableSettings';
import { dateFormat } from '@/hooks/DataHooks';
import { ARTICLE_LIST_V2, clearTableSettings, editTableSettings } from '@/hooks/TableSettingsHooks';
import ArticleApi from '@/api/v2/ArticleApi';
import ArticleApiV1 from '@/api/v1/ArticleApi';
import AgencyApi from '@/api/v1/AgencyApi';
import variables from '@/assets/scss/modules/variables.module.scss';
import { isAdvertiser } from '@/hooks/UserHooks';
import { ROUTE_DASHBOARD } from '@/hooks/RoutingHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import type {
	TableFiltersSettingsInterface,
	TableSettingsInterface,
	TableSettingsRequestInterface
} from '@/models/TableSettingsModel';
import type { IArticleListV2Item, IArticleQueryParams } from '@/models/ArticlesModel';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { AgencyItemListInterface } from '@/models/AgencyModel';
import MDialog from '@/components/atoms/MDialog.vue';
import MFlag from '@/components/atoms/MFlag.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';
import MButton from '@/components/atoms/MButton.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import TableSettingsMenuWithModal from '@/components/organisms/TableSettingsMenuWithModal.vue';
import OptimizePoppupWindow from '@/views/Articles/components/OptimizePoppupWindow.vue';

interface IOwnProps {
	availableOptimizations?: number|null
}
interface IProps extends IOwnProps {
}

defineProps<IProps>();

const agencyApi = new AgencyApi();
const articleApi = new ArticleApi();
const articleApiV1 = new ArticleApiV1();

const { locale } = storeToRefs(useSessionStore());
const { white } = variables;
const { user } = storeToRefs(useUserStore());
const { t } = useI18n();
const { campaigns, languages } = useDictionaryStore();
const { setSort, setHeaders, setFilters, setPagination } = useTableSettingsStore();
const { setArticleLanguageId } = useArticleStore();

const items = ref<Array<IArticleListV2Item>|any>([]);
const headers = ref<Array<TableHeaderInterface>|any>([]);
const loading = ref<boolean>(false);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const total = ref<number>(0);

const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const isClearedTableSettingsAction = ref<boolean>(false);
const search = ref<string|null>(null);
const searchTimeout = ref<any>(undefined);
const articleType = ref<string|null>(null);
const articleTypeItems = ref<Array<any>>([
	{
		id: 1,
		name: `${t('articles.own')}`,
		slug: 'own',
	},
	{
		id: 2,
		name: `${t('articles.order')}`,
		slug: 'ordered',
	},
]);
let firstLoadPage:boolean = true;
let isClearAction:boolean = false;
let isFilterAction:boolean = false;
let isOpenContaduDialog = ref<boolean>(false);
let isCloseContaduDialog = ref<boolean>(true);
const articleUuid = ref<string|null>(null);
const dependentAccountUuid = ref<string|null>(null);
const dependentAccountList = ref<Array<AgencyItemListInterface>|any>([]);
const isDashboardView = ref<boolean>(false);

if (null != user.value && isAdvertiser(user.value) && router.currentRoute.value.name === ROUTE_DASHBOARD) {
	isDashboardView.value = true;
}

const localeLanguage = ref('');

if ('us' === locale.value) {
	localeLanguage.value = 'en';
} else if (locale.value != null) {
	localeLanguage.value = locale.value;
} else {
	localeLanguage.value = 'pl';
}

const defaultHeaders = () => {
	const result = [
		{
			title: !isDashboardView.value ? `${t('articles.campaignUrl')}` : `${t('dashboard.campaignsName')}`,
			align: 'center',
			sortable: !isDashboardView.value,
			key: 'campaign',
			width: !isDashboardView.value ? '250px' : '',
		},
		{
			title: `${t('articles.articleTitleTable')}`,
			align: 'center',
			sortable: !isDashboardView.value,
			key: 'title',
			width: !isDashboardView.value ? '300px' : '',
		},
	];
	
	if (isDashboardView.value) {
		result.push(
			{
				title: `${t('dashboard.articleLanguage')}`,
				align: 'center',
				sortable: false,
				key: 'article_language',
				width: '',
			}
		);
	}
	
	if (null != user && user.value?.is_agency && !isDashboardView.value) {
		result.push({
			title: `${t('articles.account')}`,
			align: 'center',
			sortable: true,
			key: 'account',
			width: '150px',
		});
	}
	
	if (!isDashboardView.value) {
		result.push(...[
			{
				title: `${t('articles.type')}`,
				align: 'center',
				sortable: false,
				key: 'type',
				width: '20px',
			},
			{
				title: `${t('articles.status')}`,
				align: 'center',
				sortable: false,
				key: 'status',
				width: '180px',
			},
			{
				title: `${t('articles.update')}`,
				align: 'center',
				sortable: true,
				key: 'updated',
				width: '200px',
			},
		]);
	}
	
	result.push(...[
		{
			title: `${t('articles.actions')}`,
			align: 'center',
			sortable: false,
			key: 'actions',
			width: !isDashboardView.value ? '160px' : '',
		},
	]);
	
	return result;
};
headers.value = defaultHeaders();

const defaultInactiveHeaders = () => {
	const result = [
		{
			title: `${t('articles.created')}`,
			align: 'center',
			sortable: false,
			key: 'createdAt',
			width: '120px',
		},
	];
	
	return result;
};

const agencyDependentAccountList = async() => {
	if (null == user.value) {
		return;
	}
	try {
		const result = await agencyApi.agencyUserList(user.value.uuid);
		if (!result.isSuccess) {
			return;
		}
		dependentAccountList.value = result.payload.data;
		dependentAccountList.value.push(user.value);
	} catch (e) {
		return;
	}
};
if (user.value?.is_agency && !isDashboardView.value) {
	agencyDependentAccountList();
}

const articleList = async() => {
	loading.value = true;
	const params:IArticleQueryParams = {
		page: !isDashboardView.value ? options.value.page : 1,
		perPage: !isDashboardView.value ? options.value.itemsPerPage : 7,
		search: search.value,
		title: options.value.sortBy.length > 0 ? options.value.sortBy[0].key : null,
		direction: options.value.sortBy.length > 0 ? options.value.sortBy[0].order : null,
		languageId: null,
		campaignId: null,
		userUuid: dependentAccountUuid.value,
		articleType: articleType.value,
		isDashboardView: false,
	}
	try {
		const result = await articleApi.articleList(params);
		if (!result.isSuccess) {
			toast.error(`${t('articles.toast.error')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload.data;
		total.value = result.payload.total;
		currentPage.value = result.payload.current_page;
		lastPage.value = result.payload.last_page;
	} catch (e) {
		toast.error(`${t('articles.toast.error')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
const articleTableSettings = ref<TableSettingsInterface|null>(null);
const initTableOptions = async() => {
	let isShouldBeSaved = false;
	articleTableSettings.value = storeToRefs(useTableSettingsStore())[ARTICLE_LIST_V2].value;
	
	// Columns options
	if (null == articleTableSettings.value) {
		await setSort({
			name: ARTICLE_LIST_V2,
			sortBy: 'updated',
			sortDesc: 'desc',
		});
		isShouldBeSaved = true;
	}
	
	//Headers options
	if (null == articleTableSettings.value || 0 === articleTableSettings.value.columns.active.length || articleTableSettings.value.filters.locale !== localeLanguage.value) {
		isClearedTableSettingsAction.value = true;
		await setHeaders({
			name: ARTICLE_LIST_V2,
			headers: {
				active: defaultHeaders(),
				inactive: defaultInactiveHeaders(),
			}
		});
		isShouldBeSaved = true;
	}
	
	// Pagination options
	if (null == articleTableSettings.value || null == articleTableSettings.value.page || null == articleTableSettings.value.per_page) {
		await setPagination({
			name: ARTICLE_LIST_V2,
			page: 1,
			perPage: 10
		});
		isShouldBeSaved = true;
	}
	
	// Filters options
	// if (null != articleTableSettings.value.filters.search) {
	// 	search.value = articleTableSettings.value.filters.search;
	// }
	// if (null != articleTableSettings.value.filters.agencyAccount) {
	// 	dependentAccountUuid.value = articleTableSettings.value.filters.agencyAccount;
	// }
	// if (null != articleTableSettings.value.filters.type) {
	// 	articleType.value = articleTableSettings.value.filters.type;
	// }
	if (null != articleTableSettings.value && articleTableSettings.value.filters.locale !== localeLanguage.value) {
		const filters:TableFiltersSettingsInterface = {
			search: search.value,
			agencyAccount: dependentAccountUuid.value,
			type: articleType.value,
			locale: localeLanguage.value,
		};
		await setFilters({
			name: ARTICLE_LIST_V2,
			filters: filters
		});
	}
	
	if (null != articleTableSettings.value) {
		options.value.page = articleTableSettings.value.page;
		options.value.itemsPerPage = articleTableSettings.value.per_page;
		options.value.sortBy.push({
			key: articleTableSettings.value.sort_column,
			order: articleTableSettings.value.sort_order,
		});
		
		headers.value = articleTableSettings.value.columns.active;
	}
	
	if (isShouldBeSaved) {
		await editTableSettingsAction();
	}
	await articleList();
	isClearedTableSettingsAction.value = false;
};
if (!isDashboardView.value) {
	initTableOptions();
} else {
	articleList();
}

const editTableSettingsAction = () => {
	if (null != articleTableSettings.value && null != user.value) {
		const tableSettings: TableSettingsRequestInterface = {
			sort_column: null != articleTableSettings.value.sort_column && 0 < articleTableSettings.value.sort_column.length ? articleTableSettings.value.sort_column : null,
			sort_order: null != articleTableSettings.value.sort_order && 0 < articleTableSettings.value.sort_order.length ? articleTableSettings.value.sort_order : null,
			page: articleTableSettings.value.page,
			per_page: articleTableSettings.value.per_page,
			filters: JSON.stringify(articleTableSettings.value.filters),
			columns: JSON.stringify(articleTableSettings.value.columns),
		};
		editTableSettings(ARTICLE_LIST_V2, tableSettings, user.value?.active_role_id);
	}
};

const clearTableSettingsAction = async() => {
	isClearAction = true;
	
	search.value = null;
	dependentAccountUuid.value = null;
	articleType.value = null;
	
	options.value.page = 1;
	options.value.itemsPerPage = 10;
	options.value.sortBy = [];
	
	headers.value = defaultHeaders();
	
	const filters = {
		search: search.value,
		agencyAccount: dependentAccountUuid.value,
		type: articleType.value,
		locale: localeLanguage.value,
	};
	
	const columns = {
		active: defaultHeaders(),
		inactive: defaultInactiveHeaders(),
	};
	
	const tableSettings:TableSettingsRequestInterface = {
		sort_column: 'updated',
		sort_order: 'desc',
		page: 1,
		per_page: 10,
		filters: JSON.stringify(filters),
		columns: JSON.stringify(columns),
	}
	
	if (null != user.value) {
		await clearTableSettings(ARTICLE_LIST_V2, tableSettings, user.value?.active_role_id, `${t('components.toast.errorClearTableSettings')}`, `${t('components.toast.successClearTableSettings')}`);
	}
	isClearedTableSettingsAction.value = true;
	
	setTimeout(() => {
		isClearAction = false;
		isClearedTableSettingsAction.value = false;
		articleList();
	},1);
};

const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	if (!firstLoadPage && !isClearAction) {
		await setPagination({
			name: ARTICLE_LIST_V2,
			page: val.page,
			perPage: val.itemsPerPage,
		});
		await setSort({
			name: ARTICLE_LIST_V2,
			sortBy: 0 < val.sortBy.length ? val.sortBy[0].key : [],
			sortDesc: 0 < val.sortBy.length ? val.sortBy[0].order : [],
		});
		if (!isFilterAction) {
			await editTableSettingsAction();
			await articleList();
		}
	}
	isFilterAction = false;
	firstLoadPage = false;
};

watch(([dependentAccountUuid, articleType, search]), async (newVal, oldVal) => {
	if (!isClearAction) {
		if (options.value.page > 1) {
			options.value.page = 1;
			await setPagination({
				name: ARTICLE_LIST_V2,
				page: 1,
				perPage: options.value.itemsPerPage,
			});
			isFilterAction = true;
		}
		if (newVal[0] !== oldVal[0] || newVal[1] !== oldVal[1] || newVal[2] !== oldVal[2]) {
			clearTimeout(searchTimeout.value);
			searchTimeout.value = setTimeout(async () => {
				const filters = {
					search: newVal[2],
					agencyAccount: newVal[0],
					type: newVal[1],
					locale: localeLanguage.value,
				};
				await setFilters({
					name: ARTICLE_LIST_V2,
					filters: filters
				});
				await editTableSettingsAction();
				await articleList();
			}, 700);
		}
	}
},
{
	deep: true,
});

const headersMoveAction = () => {
	if (null != articleTableSettings.value) {
		headers.value = articleTableSettings.value.columns.active;
	}
};

const campaignName = (campaignId:number) => {
	const campaign = campaigns.find(elem => elem.id === campaignId);
	return campaign ? campaign.name : `${t('basket.list.unknown')}`;
};
const campaignUrl = (campaignId:number) => {
	const campaign = campaigns.find(elem => elem.id === campaignId);
	return campaign ? campaign.url : `${t('basket.list.unknown')}`;
};

const languageSymbol = (languageId:number) => {
	const language = languages.find(elem => elem.id === languageId);
	if (null != language) {
		return language.flag;
	}
	return '-';
};

const editArticle = (articleUuid:string) => {
	router.push(`article/${articleUuid}`);
};
const previewArticle = (articleUuid:string) => {
	router.push(`article/${articleUuid}/preview`);
};
const publishArticle = (campaignId:number|null, languageId: number) => {
	setArticleLanguageId(languageId);
	router.push(`websites/${campaignId}/orders`);
};

const deleteArticle = async(articleUuid:string) => {
	loading.value = true;
	try {
		const result = await articleApiV1.deleteArticle(articleUuid);
		if (!result.isSuccess) {
			toast.error(`${t('articles.toast.error')}`);
			loading.value = false;
			return;
		}
	} catch (e) {
		toast.error(`${t('articles.toast.error')}`);
		loading.value = false;
		return;
	}
	toast.success(`${t('articles.toast.deletedArticle')}`);
	await articleList();
	loading.value = false;
};

const openOptimalizeDialog = (currentArticleUuid:string) => {
	articleUuid.value = currentArticleUuid;
	isCloseContaduDialog.value = false;
	isOpenContaduDialog.value = true;
};

const closeDialogWhenNoTargetPressed = () => {
	isCloseContaduDialog.value = true;
	isOpenContaduDialog.value = false;
};
</script>

<style scoped lang="scss">
.article-list-table {
	@import '@/assets/scss/vuetifyDataTable';
	
	&__loading {
		opacity: 0.5;
	}
	
	&__top-wrapper {
		@include media-breakpoint-up(md) {
			display: flex;
			flex-wrap: wrap;
			gap: 15px;
			padding: 0 20px;
			align-items: center;
		}
		
		header {
			max-width: max-content !important;
		}
	}
	
	&__search-wrapper {
		display: flex;
		align-items: center;
		padding: 20px 0;
		height: 70px !important;
		
		&:deep(.v-text-field .v-input__details) {
			display: none;
		}
		
		&:deep(.m-input) {
			width: 200px;
		}
		
		&:deep(.m-autocomplete) {
			min-width: 200px;
		}
		
		&:deep(.m-input input) {
			min-height: 43px;
			padding: 0 12px;
		}
		
		&:deep(.v-field__input) {
			min-height: 43px !important;
			padding: 8px 2px;
		}
		
		span {
			margin-right: 10px;
			font-size: $size-14;
			color: $grey-400;
			font-weight: $weight-700;
		}
		
		&--article-type {
			&:deep(.m-autocomplete) {
				width: 160px;
			}
		}
		
		&--status {
			&:deep(.m-autocomplete) {
				width: 300px;
			}
		}
	}
	
	&__table-settings-wrapper {
		padding: 20px 0;
		margin-left: auto;
	}
	
	&__status {
		display: flex;
		height: 100%;
		flex-flow: column;
		justify-content: center;
		align-content: center;
		align-items: center;
	}
	
	&__campaign-title {
		display: flex;
		font-size: $size-13;
		
		&--dashboard {
			word-break: break-all;
		}
	}
	
	&__campaign-url {
		display: flex;
		font-size: $size-13;
		color: $grey-300;
		word-break: break-all;
		text-align: left;
	}
	
	&__language-wrapper {
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
		
		&:nth-child(1) span {
			min-width: 50px;
			box-shadow: 0 0 8px $grey-300;
		}
		
		&--dashboard {
			justify-content: center;
			
			&:nth-child(1) span {
				min-width: 0;
			}
		}
	}
	
	&__language-title {
		font-size: $size-13;
		margin-left: 5px;
		
		&--dashboard {
			word-break: break-all;
		}
	}
	
	&__language-quality {
		font-size: $size-13;
		color: $grey-300;
	}
	
	&__status-wrapper {
		font-size: $size-13;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		
		@include media-breakpoint-up(md) {
			border-bottom: 5px solid $green-400;
		}
		
		button {
			color: $white-100;
			font-weight: $weight-700;
		}
		
		&--red {
			@include media-breakpoint-up(md) {
				border-bottom: 5px solid $secondary-400;
			}
			
			&:deep(.m-btn) {
				color: $white-100;
				font-weight: 700;
			}
		}
		
		&--grey {
			@include media-breakpoint-up(md) {
				border-bottom: 5px solid $grey-400;
			}
			
			&:deep(.m-btn) {
				color: $white-100;
				font-weight: 700;
			}
		}
		
		&--yellow {
			@include media-breakpoint-up(md) {
				border-bottom: 5px solid $yellow-500;
			}
			
			&:deep(.m-btn) {
				color: $white-100;
				font-weight: 700;
			}
		}
	}
	
	&__update-date {
		font-size: $size-13;
	}
	
	&__created-date {
		font-size: $size-11;
		color: $grey-300;
	}
	
	&__action-wrapper {
		display: flex;
		justify-content: center;
		
		&:deep(.v-btn) {
			min-width: 20px !important;
			padding: 0 6px 0 11px !important;
			margin-right: 3px;
			border-radius: 50%;
		}
	}
	
	&__bell-wrapper {
		position: relative;
		color: $white-100;
	}
	
	&__bell-count {
		position: absolute;
		top: -5px;
		right: -2px;
		background-color: $secondary-400;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		padding: 0 6px;
	}
	
	&__publication-url {
		color: $secondary-400;
		word-break: break-all;
		max-width: 220px;
	}
	
	&__optimalize-btn {
		width: max-content;
		
		img {
			width: 20px;
			height: 20px;
		}
		
		&:deep(.m-btn) {
			background-color: $blue-400 !important;
			padding: 0 6px 0 8px !important;
		}
	}
	
	&__account-wrapper {
		font-size: $size-12;
		opacity: 0.65;
		font-weight: 700;
		margin-left: -15px;
		word-break: break-all;
	}
	
	&__type-wrapper {
		&:deep(.m-tooltip) {
			width: 100%;
		}
	}
	
	&__dashboard-btn-wrapper {
		display: flex;
		justify-content: center;
		padding: 21px 0;
	}
	
	&:deep(.v-badge__wrapper) {
		top: -12px;
		left: 10px;
		
		span {
			background-color: $primary-400;
		}
	}
	
	&:deep(.v-data-table-footer .v-field__field) {
		height: 40px;
	}
	
	&:deep(.v-data-table-footer .v-field__input) {
		font-size: 12px;
		padding: 11px 0 0 10px;
	}
	
	&:deep(.v-data-table-footer .v-input) {
		height: 40px;
	}
}

.article-list-table-dashboard {
	@import '@/assets/scss/vuetifyDataTableDashboard';
	overflow-y: scroll;
	background-color: $white-100;
	border-radius: 8px;
	
	&:deep(.v-data-table) {
		@include media-breakpoint-up(xxl) {
			height: 342px + 95px + 9px;
		}
	}
}
</style>
