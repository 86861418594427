import BaseApi from '@/api/BaseApi';
import type {
	IAllWebsiteList,
	IAllWebsitesListRequest,
	ICategoriesList,
	IChangeDomainStatus,
	ICreateWebsite,
	ICreateWebsiteResponse,
	IDomainSeoForm, IEditTrustedWebsiteForm,
	IEditWebsiteForm,
	IMassDeleteRequest,
	ISyncSingleWebsite,
	ITopicsList,
	IWebsiteAhrefsEnabled,
	IWebsiteDetails,
	IWebsiteList
} from '@/models/WebsiteModel';

export default class WebsiteApi extends BaseApi {
	async websiteList (page: number|null, perPage: number|null, sortBy?: string|null, sortDirection?: string|null, search?: string|null, status?: string|null, language?: Array<number>|null, userUuid?: string|null, isPreview?: boolean) {
		let url = `api/v1/websites/list?page=${page}&per_page=${perPage}`;

		if (null != sortBy) {
			url += `&sort=${sortBy}`;
		} else {
			url += '&sort=websites.updated_at';
		}

		if (null != sortDirection) {
			url += `&order=${sortDirection}`;
		} else {
			url += '&order=desc';
		}

		if (null != search) {
			url += `&search=${search}`;
		}

		if (null != status) {
			url += `&status=${status}`;
		}

		if (null != language) {
			url += `&language=${language}`;
		}

		if (null != userUuid) {
			url += `&user_id=${userUuid}`;
		}

		if (isPreview) {
			url += '&preview=1';
		}
		return this.get<IWebsiteList>({
			url: url,
		});
	};

	async allWebsiteList (params: IAllWebsitesListRequest) {
		return this.get<IAllWebsiteList[]>({
			url: '/api/v1/websites/urls',
			params: {
				search: params.search,
			},
		});
	};

	async createWebsite (params: ICreateWebsite) {
		return this.post<ICreateWebsite, ICreateWebsiteResponse>({
			url: 'api/v1/websites',
			data: params
		});
	};

	async editWebsite (params: IEditWebsiteForm, websiteUuid: string|Array<string>|null) {
		return this.put<IEditWebsiteForm, boolean>({
			url: `api/v1/websites/${websiteUuid}`,
			data: params
		});
	};

	async editTrustedWebsite (params: IEditTrustedWebsiteForm, websiteUuid: string|Array<string>|null) {
		return this.put<IEditTrustedWebsiteForm, boolean>({
			url: `api/v1/websites/trusted/${websiteUuid}`,
			data: params
		});
	};

	async websiteDetails (websiteUuid: string|Array<string>|null) {
		return this.get<IWebsiteDetails>({
			url: `api/v1/websites/${websiteUuid}`,
		});
	};

	async changeDomainStatus (params: IChangeDomainStatus, websiteUuid: string|Array<string>|null) {
		return this.put<IChangeDomainStatus, boolean>({
			url: `api/v1/websites/status/${websiteUuid}`,
			data: params
		});
	};
	async deleteDomain (websiteUuid: string|null) {
		return this.delete({
			url: `api/v1/websites/${websiteUuid}`
		});
	};
	async categoriesList () {
		return this.get<Array<ICategoriesList>>({
			url: 'api/v1/websites/categories',
		});
	};
	async forbiddenTopicsList () {
		return this.get<Array<ITopicsList>>({
			url: 'api/v1/topics'
		});
	};

	async editSeoStats (params: IDomainSeoForm, websiteUuid: string|Array<string>|null) {
		return this.put<IDomainSeoForm, boolean>({
			url: `api/v1/websites/seostats/${websiteUuid}`,
			data: params
		});
	};

	async refreshElasticSearch () {
		return this.post<any, any>({
			url: '/api/v1/search/website/refresh',
		});
	};

	async massDeleteDomain (params:IMassDeleteRequest) {
		return this.delete<IMassDeleteRequest>({
			url: 'api/v1/websites',
			params: params,
		});
	};

	async syncWebsiteWithAhrefs (websiteUuid:string|Array<string>|null, params:IWebsiteAhrefsEnabled) {
		return this.put<IWebsiteAhrefsEnabled, boolean>({
			url: `api/v1/websites/ahrefs/${websiteUuid}`,
			data: params
		});
	};

	async syncSingleWebsite (params:ISyncSingleWebsite) {
		return this.post<ISyncSingleWebsite, any>({
			url: '/api/v1/websites/sync',
			data: params,
		});
	};
};
