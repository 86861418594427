import BaseApi from '@/api/BaseApi';
import type { IImportItemStatus, IImportListResponse, IImportPreview } from '@/models/ImportModel';

export default class ImportApi extends BaseApi {
	async importWebsiteOffer (file: File) {
		const data = new FormData()
		data.append('file', file)
		return this.post<FormData, Object>({
			url: 'api/v1/website_offer/validate',
			data,
			config: {
				contentType: 'multipart/form-data',
			},
		});
	};

	async importWebsiteOfferList (page: number, perPage: number) {
		return this.get<IImportListResponse>({
			url: `api/v1/website_offer/validate?per_page=${perPage}&page=${page}`,
		});
	};

	async importedPreviewItemsStatus (uuid: Array<string>|string) {
		return this.get<IImportItemStatus>({
			url: `api/v1/website_offer/validate/${uuid}`,
		});
	};

	async importedPreviewItemsList (uuid: Array<string>|string, page: number, perPage: number, isStatusError?: boolean) {
		let url = `api/v1/website_offer/validate/${uuid}/items?page=${page}&per_page=${perPage}`;

		if (isStatusError) {
			url += '&result_status=error';
		}
		return this.get<IImportPreview>({
			url: url,
		});
	};

	async editOneRow (importUuid: Array<string>|string, rowUuid: string, payload: any) {
		return this.put<any, any>({
			url: `api/v1/website_offer/validate/${importUuid}/items/${rowUuid}`,
			data: payload,
		});
	};

	async revalidatePreviewImportedFile (uuid:Array<string>|string, lock?:boolean, isContinue?:boolean) {
		let url = `api/v1/website_offer/revalidate/${uuid}?${lock ? 'lock=1' : ''}`;

		if (isContinue) {
			url += '&continue=true';
		}
		return this.post<any, any>({
			url: url,
		});
	};

	async savePreviewImportedFile (uuid:Array<string>|string) {
		return this.post<any, any>({
			url: `api/v1/website_offer/save/${uuid}`,
		});
	};

	async exportUnusedDomains () {
		return this.post<any, any>({
			url: 'api/v1/website_offer/export-without-orders',
		});
	};
};
