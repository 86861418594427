<template>
	<div class="create-edit-website">
		<h1 class="create-edit-website__title">{{ availableStep === 4 ? $t('websites.editDomain') : $t('websites.addDomain') }}</h1>

		<Steps :items="stepsItems" :available-step="availableStep" @changeStep="changeStep" />
		
		<div v-if="null != rejectMessage" class="create-edit-website__form-wrapper create-edit-website__form-wrapper--reject-message">
			<h2 class="create-edit-website__form-wrapper--subtitle">{{ $t('websites.domainRejected') }}</h2>
			<div>{{ rejectMessage }}</div>
		</div>
		
		<div v-if="activeStep === 1">
			<Form :validation-schema="stepOneSchema" @submit="saveStep" class="create-edit-website__form-wrapper">
				<div class="create-edit-website__form-title">{{ availableStep !== 4 ? $t('websites.addBasicData') : $t('websites.editBasicData') }}</div>
				<MTextField
					id="domain-name"
					v-model="form.domainName"
					name="domainName"
					label=""
					:separate-label="$t('websites.domainNameForm')"
					label-text-required
					:placeholder="$t('websites.domainNamePlaceholder')"
					:hint="$t('websites.domainNameHint')"
					persistent-hint
					variant="outlined"
					clearable
				/>
				<MTextField
					id="domain-address-url"
					v-model="form.domainUrl"
					name="domainUrl"
					label=""
					:separate-label="$t('websites.domainUrl')"
					label-text-required
					:placeholder="$t('websites.domainUrlPlaceholder')"
					:hint="$t('websites.addHttps')"
					persistent-hint
					variant="outlined"
					clearable
				/>
				<MAutocomplete
					id="domain-language"
					v-model="form.language"
					name="domainLanguage"
					:get-items="languages"
					item-title="name"
					item-value="id"
					:label="$t('websites.languageForm')"
					label-text-required
					:placeholder="$t('websites.languagePlaceholder')"
					variant="outlined"
					clearable
				/>
				<MAutocomplete
					id="domain-country"
					v-model="form.country"
					name="domainCountry"
					:get-items="countries"
					item-title="name"
					item-value="id"
					:label="$t('websites.country')"
					label-text-required
					:placeholder="$t('websites.countryPlaceholder')"
					variant="outlined"
					clearable
				/>
				<div class="create-edit-website__btn-wrapper">
					<MButton
						v-if="availableStep === 4"
						id="domain-edit-btn"
						@click="goBack"
						normal
						width130
						transparent-with-border
						:label="$t('websites.cancel')"
					/>
					<MButton
						v-if="availableStep !== 4"
						id="domain-first-step-back-btn"
						@click="goBack"
						normal
						width130
						transparent-with-border
						:label="$t('websites.back')"
					/>
					<MButton
						id="domain-first-step-btn"
						type="submit"
						normal
						width130
						blue400
						:label="availableStep === 1 ? $t('websites.nextStep') : $t('websites.save')"
					/>
				</div>
			</Form>
		</div>
		
		<div v-if="activeStep === 2" class="create-edit-website__second-step">
			<Form :validation-schema="stepTwoSchema" @submit="saveStep" class="create-edit-website__form-wrapper">
				<div class="create-edit-website__form-title">{{ availableStep !== 4 ? $t('websites.domainTopic') : $t('websites.editDomainTopic') }}</div>
				<div>
					<div class="create-edit-website__subtitle">{{ $t('websites.description') }}<span>{{ $t('components.fieldRequired') }}</span></div>
					<MTextArea
						id="domain-description"
						v-model="form.description"
						name="description"
						:placeholder="$t('websites.descriptionPlaceholder')"
					/>
				</div>
				<MAutocomplete
					id="domain-categories"
					v-model="form.categories"
					name="domainCategories"
					:get-items="categories"
					item-title="name"
					item-value="id"
					:label="$t('websites.categories')"
					label-text-required
					chips
					:placeholder="$t('websites.categoriesPlaceholder')"
					variant="outlined"
					multiple
					clearable
					return-object
				/>
				<MAutocomplete
					id="domain-forbidden-topics"
					v-model="form.forbidden_topics"
					name="domainForbiddenTopics"
					:get-items="forbiddenTopics"
					item-title="name"
					item-value="id"
					chips
					:label="$t('websites.forbiddenTopics')"
					:placeholder="$t('websites.forbiddenTopicsPlaceholder')"
					:hint="$t('websites.forbiddenTopicsHint')"
					persistent-hint
					variant="outlined"
					multiple
					clearable
					return-object
				/>
				<MAutocomplete
					id="domain-promotions"
					v-model="form.promotions"
					name="domainPromotions"
					:get-items="promotions"
					item-title="name"
					item-value="slug"
					chips
					:label="$t('websites.promotions')"
					:placeholder="$t('websites.promotionsPlaceholder')"
					:hint="$t('websites.promotionsHint')"
					persistent-hint
					variant="outlined"
					multiple
					clearable
				/>
				<div class="create-edit-website__btn-wrapper">
					<MButton
						v-if="availableStep === 4"
						id="domain-edit-btn-second-step"
						@click="goBack"
						normal
						width130
						transparent-with-border
						:label="$t('websites.cancel')"
					/>
					<MButton
						v-if="availableStep !== 4"
						id="domain-step-back-btn-second-step"
						@click="goPrevStep"
						normal
						width130
						transparent-with-border
						:label="$t('websites.previousStep')"
					/>
					<MButton
						id="domain-second-step-btn"
						type="submit"
						normal
						width130
						blue400
						:label="availableStep === 2 ? $t('websites.nextStep') : $t('websites.save')"
					/>
				</div>
			</Form>
		</div>
		
		<div v-if="activeStep === 3" class="create-edit-website__second-step">
			<Form :validation-schema="stepThreeSchema" @submit="saveStep" class="create-edit-website__form-wrapper">
				<div class="create-edit-website__form-title">{{ availableStep !== 4 ? $t('websites.requirementToContent') : $t('websites.editRequirementToContent') }}</div>
				<div>
					<div class="create-edit-website__subtitle">{{ $t('websites.otherRequirements') }}</div>
					<MTextArea
						id="domain-other-requirements"
						v-model="form.other_requirements"
						name="otherRequirements"
						:placeholder="$t('websites.otherRequirementsPlaceholder')"
					/>
				</div>
				<MTextField
					id="domain-link"
					v-model="form.link"
					name="link"
					label=""
					:separate-label="$t('websites.link')"
					label-text-required
					:placeholder="$t('websites.linkPlaceholder')"
					:hint="$t('websites.linkHint')"
					persistent-hint
					variant="outlined"
					clearable
				>
					<template v-slot:separateLabel>
						<div id="domain-fill-field" class="create-edit-website__fill-domain" @click="fillDomainUrl">{{ $t('websites.fillDomainUrl') }}</div>
					</template>
				</MTextField>
				<div class="create-edit-website__btn-wrapper">
					<MButton
						v-if="availableStep === 4"
						id="domain-edit-btn-second-step"
						@click="goBack"
						normal
						width130
						transparent-with-border
						:label="$t('websites.cancel')"
					/>
					<MButton
						v-if="availableStep !== 4"
						id="domain-step-back-btn-second-step"
						@click="goPrevStep"
						normal
						width130
						transparent-with-border
						:label="$t('websites.previousStep')"
					/>
					<MButton
						id="domain-third-step-btn"
						type="submit"
						normal
						width130
						blue400
						:label="availableStep === 3 ? $t('websites.nextStep') : $t('websites.save')"
					/>
				</div>
			</Form>
		</div>

		<div v-if="activeStep === 4" class="create-edit-website__form-wrapper">
			<div class="create-edit-website__form-title">{{ $t('websites.summary') }}</div>
			<div class="create-edit-website__summary-description-text">
				<div class="create-edit-website__summary-description">{{ $t('websites.summaryDescription1') }}</div>
				<div>{{ $t('websites.summaryDescription2') }}</div>
			</div>
			<div class="create-edit-website__summary-icon-wrapper">
				<div class="create-edit-website__summary-item">
					<MIcon icon="ok" width="25px" height="25px" />
					<div class="create-edit-website__summary-description-text">{{ $t('websites.summaryDescription3') }}</div>
				</div>
				<div class="create-edit-website__summary-item">
					<MIcon icon="ok" width="25px" height="25px" />
					<div class="create-edit-website__summary-description-text">{{ $t('websites.summaryDescription4') }}</div>
				</div>
			</div>
			<div class="create-edit-website__summary-btn-wrapper">
				<MButton
					v-if="availableStep === 4"
					id="domain-create-offer"
					normal
					width130
					blue400
					:label="$t('websites.createOffer')"
					@click="goToCreateOffer"
				/>
			</div>
		</div>
		
		<MLoading v-show="loading"/>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { useDictionaryStore } from '@/stores/dictionary';
import { toast } from 'vue3-toastify';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { SUBMITTED_MODERATION, RESUBMITTED_MODERATION, DISAPPROVED } from '@/hooks/WebsitesHooks';
import WebsiteApi from '@/api/v1/WebsiteApi';
import Steps from '@/components/molecules/Steps.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import MButton from '@/components/atoms/MButton.vue';
import MTextArea from '@/components/atoms/MTextArea.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MLoading from '@/components/atoms/MLoading.vue';

const websiteApi = new WebsiteApi();

const { t } = useI18n();
const { languages, countries } = useDictionaryStore();

const loading = ref<boolean>(false);
const rejectMessage = ref<string|null>(null);
const websiteId = ref<number>(0);
const availableStep = ref<number>(1);
const activeStep = ref<number>(1);
const status = ref<string>('');
const form = ref<any>({
	domainName: null,
	domainUrl: null,
	language: null,
	country: null,
	description: null,
	categories: [],
	forbidden_topics: [],
	promotions: [],
	other_requirements: null,
	link: null,
	step: 1,
});
const categories = ref<Array<any>>([
	{
		id: 1,
		name: `${t('websites.arts-and-entertainment')}`,
		created_at: "2018-07-20T16:22:17.000000Z",
		updated_at: "2018-07-20T16:22:17.000000Z",
		slug: "arts-and-entertainment"
	},
	{
		id: 2,
		name: `${t('websites.cars-and-vehicles')}`,
		created_at: "2018-07-20T16:22:17.000000Z",
		updated_at: "2018-07-20T16:22:17.000000Z",
		slug: "cars-and-vehicles"
	},
	{
		id: 3,
		name: `${t('websites.lifestyle-beauty-and-fitness')}`,
		created_at: "2018-07-20T16:22:17.000000Z",
		updated_at: "2022-05-25T16:40:14.000000Z",
		slug: "lifestyle-beauty-and-fitness"
	},
	{
		id: 4,
		name: `${t('websites.books-and-literature')}`,
		created_at: "2018-07-20T16:22:18.000000Z",
		updated_at: "2018-07-20T16:22:18.000000Z",
		slug: "books-and-literature"
	},
	{
		id: 5,
		name: `${t('websites.business-and-industry')}`,
		created_at: "2018-07-20T16:22:18.000000Z",
		updated_at: "2018-07-20T16:22:18.000000Z",
		slug: "business-and-industry"
	},
	{
		id: 6,
		name: `${t('websites.career-and-education')}`,
		created_at: "2018-07-20T16:22:18.000000Z",
		updated_at: "2018-07-20T16:22:18.000000Z",
		slug: "career-and-education"
	},
	{
		id: 7,
		name: `${t('websites.it-computers-and-electronics')}`,
		created_at: "2018-07-20T16:22:18.000000Z",
		updated_at: "2022-05-25T16:40:14.000000Z",
		slug: "it-computers-and-electronics"
	},
	{
		id: 8,
		name: `${t('websites.finance')}`,
		created_at: "2018-07-20T16:22:18.000000Z",
		updated_at: "2018-07-20T16:22:18.000000Z",
		slug: "finance"
	},
	{
		id: 9,
		name: `${t('websites.food-and-drink')}`,
		created_at: "2018-07-20T16:22:18.000000Z",
		updated_at: "2018-07-20T16:22:18.000000Z",
		slug: "food-and-drink"
	},
	{
		id: 10,
		name: `${t('websites.gambling')}`,
		created_at: "2018-07-20T16:22:18.000000Z",
		updated_at: "2018-07-20T16:22:18.000000Z",
		slug: "gambling"
	},
	{
		id: 11,
		name: `${t('websites.games')}`,
		created_at: "2018-07-20T16:22:18.000000Z",
		updated_at: "2018-07-20T16:22:18.000000Z",
		slug: "games"
	},
	{
		id: 12,
		name: `${t('websites.health')}`,
		created_at: "2018-07-20T16:22:18.000000Z",
		updated_at: "2018-07-20T16:22:18.000000Z",
		slug: "health"
	},
	{
		id: 13,
		name: `${t('websites.home-and-garden')}`,
		created_at: "2018-07-20T16:22:19.000000Z",
		updated_at: "2018-07-20T16:22:19.000000Z",
		slug: "home-and-garden"
	},
	{
		id: 14,
		name: `${t('websites.internet-telecom-and-tv')}`,
		created_at: "2018-07-20T16:22:19.000000Z",
		updated_at: "2022-05-25T16:40:14.000000Z",
		slug: "internet-telecom-and-tv"
	},
	{
		id: 15,
		name: `${t('websites.law-and-government')}`,
		created_at: "2018-07-20T16:22:19.000000Z",
		updated_at: "2018-07-20T16:22:19.000000Z",
		slug: "law-and-government"
	},
	{
		id: 16,
		name: `${t('websites.news-and-media')}`,
		created_at: "2018-07-20T16:22:19.000000Z",
		updated_at: "2018-07-20T16:22:19.000000Z",
		slug: "news-and-media"
	},
	{
		id: 17,
		name: `${t('websites.people-and-society')}`,
		created_at: "2018-07-20T16:22:19.000000Z",
		updated_at: "2018-07-20T16:22:19.000000Z",
		slug: "people-and-society"
	},
	{
		id: 18,
		name: `${t('websites.pets-and-animals')}`,
		created_at: "2018-07-20T16:22:19.000000Z",
		updated_at: "2018-07-20T16:22:19.000000Z",
		slug: "pets-and-animals"
	},
	{
		id: 19,
		name: `${t('websites.recreation-and-hobbies')}`,
		created_at: "2018-07-20T16:22:19.000000Z",
		updated_at: "2018-07-20T16:22:19.000000Z",
		slug: "recreation-and-hobbies"
	},
	{
		id: 20,
		name: `${t('websites.reference')}`,
		created_at: "2018-07-20T16:22:19.000000Z",
		updated_at: "2018-07-20T16:22:19.000000Z",
		slug: "reference"
	},
	{
		id: 21,
		name: `${t('websites.science')}`,
		created_at: "2018-07-20T16:22:20.000000Z",
		updated_at: "2018-07-20T16:22:20.000000Z",
		slug: "science"
	},
	{
		id: 22,
		name: `${t('websites.shopping')}`,
		created_at: "2018-07-20T16:22:20.000000Z",
		updated_at: "2018-07-20T16:22:20.000000Z",
		slug: "shopping"
	},
	{
		id: 23,
		name: `${t('websites.sports')}`,
		created_at: "2018-07-20T16:22:20.000000Z",
		updated_at: "2018-07-20T16:22:20.000000Z",
		slug: "sports"
	},
	{
		id: 24,
		name: `${t('websites.travel')}`,
		created_at: "2018-07-20T16:22:20.000000Z",
		updated_at: "2018-07-20T16:22:20.000000Z",
		slug: "travel"
	},
	{
		id: 25,
		name: `${t('websites.adult')}`,
		created_at: "2018-07-20T16:22:20.000000Z",
		updated_at: "2018-07-20T16:22:20.000000Z",
		slug: "adult"
	},
	{
		id: 26,
		name: `${t('websites.family-and-kids')}`,
		created_at: "2018-08-09T08:07:03.000000Z",
		updated_at: "2018-08-09T08:07:25.000000Z",
		slug: "family-and-kids"
	},
	{
		id: 27,
		name: `${t('websites.other')}`,
		created_at: "2019-03-12T21:31:00.000000Z",
		updated_at: "2019-03-12T21:31:00.000000Z",
		slug: "other"
	},
	{
		id: 28,
		name: `${t('websites.environment')}`,
		created_at: "2022-09-13T09:44:41.000000Z",
		updated_at: "2022-09-13T09:44:41.000000Z",
		slug: "environment"
	},
	{
		id: 29,
		name: `${t('websites.crypto')}`,
		created_at: "2023-02-13T14:46:12.000000Z",
		updated_at: "2023-02-13T14:46:12.000000Z",
		slug: "crypto"
	},
	{
		id: 30,
		name: `${t('websites.conferences')}`,
		created_at: "2023-02-13T14:46:36.000000Z",
		updated_at: "2023-02-13T14:46:36.000000Z",
		slug: "conferences"
	},
	{
		id: 31,
		name: `${t('websites.fashion')}`,
		created_at: "2023-02-13T14:46:48.000000Z",
		updated_at: "2023-02-13T14:46:48.000000Z",
		slug: "fashion"
	},
	{
		id: 32,
		name: `${t('websites.military')}`,
		created_at: "2023-03-13T13:29:24.000000Z",
		updated_at: "2023-03-13T13:29:24.000000Z",
		slug: "military"
	}
]);
const forbiddenTopics = ref<Array<any>>([
	{
		id: 1,
		name: `${t('websites.porn')}`,
		created_at: "2018-07-20T15:33:52.000000Z",
		updated_at: "2018-07-20T15:33:52.000000Z"
	},
	{
		id: 2,
		name: `${t('websites.gambling')}`,
		created_at: "2018-07-20T15:33:52.000000Z",
		updated_at: "2018-07-20T15:33:52.000000Z"
	},
	{
		id: 3,
		name: `${t('websites.tobacco-products')}`,
		created_at: "2018-07-20T15:33:53.000000Z",
		updated_at: "2018-07-20T15:33:53.000000Z"
	},
	{
		id: 4,
		name: `${t('websites.alcohol-products')}`,
		created_at: "2018-07-20T15:33:53.000000Z",
		updated_at: "2018-07-20T15:33:53.000000Z"
	},
	{
		id: 5,
		name: `${t('websites.content-incompatible-with-domain')}`,
		created_at: "2018-07-20T15:33:53.000000Z",
		updated_at: "2018-07-20T15:33:53.000000Z"
	},
	{
		id: 6,
		name: `${t('websites.promoting-competition')}`,
		created_at: "2018-07-20T15:33:53.000000Z",
		updated_at: "2018-07-20T15:33:53.000000Z"
	},
	{
		id: 7,
		name: `${t('websites.negative-PR')}`,
		created_at: "2018-07-20T15:33:53.000000Z",
		updated_at: "2018-07-20T15:33:53.000000Z"
	},
	{
		id: 8,
		name: `${t('websites.racist')}`,
		created_at: "2018-07-20T15:33:53.000000Z",
		updated_at: "2018-07-20T15:33:53.000000Z"
	},
	{
		id: 9,
		name: `${t('websites.contraception')}`,
		created_at: "2018-07-20T15:33:54.000000Z",
		updated_at: "2018-07-20T15:33:54.000000Z"
	},
	{
		id: 10,
		name: `${t('websites.political-topics')}`,
		created_at: "2018-07-20T15:33:54.000000Z",
		updated_at: "2018-07-20T15:33:54.000000Z"
	},
	{
		id: 11,
		name: `${t('websites.religion')}`,
		created_at: "2018-07-20T15:33:54.000000Z",
		updated_at: "2018-07-20T15:33:54.000000Z"
	},
	{
		id: 12,
		name: `${t('websites.controversial-content')}`,
		created_at: "2018-07-20T15:33:54.000000Z",
		updated_at: "2018-07-20T15:33:54.000000Z"
	},
	{
		id: 13,
		name: `${t('websites.violence')}`,
		created_at: "2018-07-20T15:33:55.000000Z",
		updated_at: "2018-07-20T15:33:55.000000Z"
	},
	{
		id: 14,
		name: `${t('websites.quasi-banks-services')}`,
		created_at: "2018-07-20T15:33:55.000000Z",
		updated_at: "2018-07-20T15:33:55.000000Z"
	},
	{
		id: 15,
		name: `${t('websites.drugs')}`,
		created_at: "2018-08-21T22:05:24.000000Z",
		updated_at: "2018-08-21T22:05:24.000000Z"
	}
]);
const promotions = ref<Array<any>>([
	{
		name: `${t('websites.bookmakers')}`,
		slug: 'bookmakers'
	},
	{
		name: `${t('websites.cbd')}`,
		slug: 'cbd'
	},
	{
		name: `${t('websites.loan')}`,
		slug: 'loan'
	},
]);
const stepsItems = ref<Array<any>>([
	{
		name: `${t('websites.completeBasicData')}`,
		step: 1,
	},
	{
		name: `${t('websites.selectDomainTopic')}`,
		step: 2,
	},
	{
		name: `${t('websites.contentRequirement')}`,
		step: 3,
	},
	{
		name: `${t('websites.goToCreateOffer')}`,
		step: 4,
	},
]);
const websiteUuid = ref<string|Array<string>|null>(null);

const stepOneSchema:any = yup.object({
	domainName: yup.string().required(`${t('websites.validation.domainName')}`).label('domainName').test('domainName', `${t('websites.validation.withDot')}`,
		function (val) {
			const re = /.*\.[a-zA-Z].*/gm;
			if (val.match(re)) {
				return true;
			}
			return false;
		}),
	domainUrl: yup.string().required(`${t('websites.validation.domainUrl')}`).label('domainUrl').test('domainUrl', `${t('websites.validation.https')}`,
		function (val) {
			const re = /^(http|https):\/\/.*/gm;
			if (val.match(re)) {
				return true;
			}
			return false;
		}),
	domainLanguage: yup.string().required(`${t('websites.validation.domainLanguage')}`).label('domainLanguage'),
	domainCountry: yup.string().required(`${t('websites.validation.domainCountry')}`).label('domainCountry'),
});
const stepTwoSchema:any = yup.object({
	description: yup.string().required(`${t('websites.validation.description')}`).label('description'),
	domainCategories: yup.array().min(1, `${t('websites.validation.domainCategories')}`).required(`${t('websites.validation.domainCategories')}`).label('domainCategories'),
});
const stepThreeSchema:any = yup.object({
	link: yup.string().required(`${t('websites.validation.link')}`).label('link').test('link', `${t('websites.validation.https')}`,
	function (val) {
		const re = /^(http|https):\/\/.*/gm;
		if (val.match(re)) {
			return true;
		}
		return false;
	}),
});

const changeStep = (step:number) => {
	activeStep.value = step;
};
const goBack = () => {
	router.push('/websites');
};
const goPrevStep = () => {
	activeStep.value = activeStep.value - 1;
};

const saveStep = async() => {
	loading.value = true;
	if (availableStep.value === 1 && activeStep.value === 1) {
		form.value.step = 2;
	}
	if (availableStep.value === 2 && activeStep.value === 2) {
		form.value.step = 3;
	}
	if (availableStep.value === 3 && activeStep.value === 3) {
		form.value.step = 4;
	}
	if (availableStep.value === 1) {
		try {
			const createForm = {
				name: form.value.domainName,
				url: form.value.domainUrl,
				language_id: form.value.language,
				country_id: form.value.country,
				step: 2,
			};
			const result = await websiteApi.createWebsite(createForm);
			
			if (!result.isSuccess) {
				toast.error(`${t('websites.toast.errorCreateWebsite')}`);
				loading.value = false;
				return;
			}
			await router.push(`/websites/${result.payload.uuid}`);
			availableStep.value = availableStep.value + 1;
			activeStep.value = activeStep.value + 1;
		} catch (e) {
			toast.error(`${t('websites.toast.errorCreateWebsite')}`);
			loading.value = false;
			return;
		}
	} else {
		try {
			let isAllowedPromotionLoans = 0;
			let isAllowedPromotionCbd = 0;
			let isAllowedPromotionBookmakers = 0;
			if (null != form.value.promotions && 0 != form.value.promotions.length) {
				form.value.promotions.forEach((elem:any) => {
					if (elem.slug === 'loan' || elem === 'loan') {
						isAllowedPromotionLoans = 1;
					}
					if (elem.slug === 'cbd' || elem === 'cbd') {
						isAllowedPromotionCbd = 1;
					}
					if (elem.slug === 'bookmakers' || elem === 'bookmakers') {
						isAllowedPromotionBookmakers = 1;
					}
				});
			}
			let editCategories:Array<number> = [];
			if (null != form.value.categories && 0 != form.value.categories.length) {
				form.value.categories.forEach((elem:any) => {
					editCategories.push(elem.id);
				});
			} else {
				editCategories = form.value.categories;
			}
			let editForbiddenTopics:Array<number> = [];
			if (null != form.value.forbidden_topics && 0 != form.value.forbidden_topics.length) {
				form.value.forbidden_topics.forEach((elem:any) => {
					editForbiddenTopics.push(elem.id);
				});
			} else {
				editForbiddenTopics = form.value.forbidden_topics;
			}
			
			if (
				null == form.value.domainName ||
				'' === form.value.domainName ||
				null == form.value.domainUrl ||
				'' === form.value.domainUrl ||
				null == form.value.language ||
				null == form.value.country
			) {
				loading.value = false;
				toast.error(`${t('websites.toast.errorEditWebsite')}`);
				activeStep.value = 1;
				return;
			}
			
			if (
				null == form.value.description ||
				'' === form.value.description ||
				null == editCategories ||
				0 === editCategories.length
			) {
				loading.value = false;
				toast.error(`${t('websites.toast.errorEditWebsite')}`);
				activeStep.value = 2;
				return;
			}
			
			if (
				availableStep.value >= 3 &&
				(null == form.value.link ||
				'' === form.value.link)
			) {
				loading.value = false;
				toast.error(`${t('websites.toast.errorEditWebsite')}`);
				activeStep.value = 3;
				return;
			}
			const editForm = {
				name: form.value.domainName,
				url: form.value.domainUrl,
				language_id: form.value.language,
				country_id: form.value.country,
				step: form.value.step,
				description: form.value.description,
				categories: editCategories,
				forbidden_topics: editForbiddenTopics,
				is_allowed_promotion_loans: isAllowedPromotionLoans,
				is_allowed_promotion_cbd: 0,
				is_allowed_promotion_bookmakers: isAllowedPromotionBookmakers,
				requirements: form.value.other_requirements,
				sample_article_url: form.value.link,
			}
			const result = await websiteApi.editWebsite(editForm, websiteUuid.value);
			
			if (!result.isSuccess) {
				toast.error(`${t('websites.toast.errorEditWebsite')}`);
				loading.value = false;
				return;
			}
			
			if (availableStep.value === 3 && activeStep.value === 3) {
				availableStep.value = 4;
				activeStep.value = 4;
			} else {
				if (availableStep.value === 4 && activeStep.value === 3) {
					await router.push('/websites');
				} else {
					activeStep.value = activeStep.value + 1;
					await websiteDetails();
				}
			}
		} catch (e) {
			toast.error(`${t('websites.toast.errorEditWebsite')}`);
			loading.value = false;
			return;
		}
	}
	if (availableStep.value === 4) {
		await changeStatus();
	}
	toast.success(`${t('websites.toast.successSubmitWebsite')}`);
	loading.value = false;
};

const websiteDetails = async() => {
	loading.value = true;
	try {
		const result = await websiteApi.websiteDetails(websiteUuid.value);
		
		if (!result.isSuccess) {
			loading.value = false;
			toast.error(`${t('websites.toast.errorGetWebsiteDetails')}`);
			return;
		}
		
		websiteId.value = result.payload.id;
		form.value.domainName = result.payload.name;
		form.value.domainUrl = result.payload.url;
		form.value.language = result.payload.language_id;
		form.value.country = result.payload.country_id;
		form.value.description = result.payload.description;
		form.value.categories = [];
		if (null != result.payload.categories && 0 < result.payload.categories.length) {
			result.payload.categories.forEach(elem => {
				const category = categories.value.find(elems => elem.id === elems.id);
				if (null != category) {
					form.value.categories.push(category);
				}
			});
		}
		form.value.forbidden_topics = [];
		if (null != result.payload.forbidden_topics && 0 < result.payload.forbidden_topics.length) {
			result.payload.forbidden_topics.forEach(elem => {
				const topic = forbiddenTopics.value.find(elems => elem.id === elems.id);
				if (null != topic) {
					form.value.forbidden_topics.push(topic);
				}
			});
		}
		form.value.promotions = [];
		if (result.payload.is_allowed_promotion_loans) {
			form.value.promotions.push(
				{
					name: `${t('websites.loan')}`,
					slug: 'loan'
				}
			);
		}
		if (result.payload.is_allowed_promotion_cbd) {
			form.value.promotions.push(
				{
					name: `${t('websites.cbd')}`,
					slug: 'cbd'
				}
			);
		}
		if (result.payload.is_allowed_promotion_bookmakers) {
			form.value.promotions.push(
				{
					name: `${t('websites.bookmakers')}`,
					slug: 'bookmakers'
				}
			);
		}
		form.value.other_requirements = result.payload.requirements;
		form.value.link = result.payload.sample_article_url;
		availableStep.value = result.payload.step;
		form.value.step = result.payload.step;
		status.value = result.payload.status;
		rejectMessage.value = result.payload.reject_messages;
		
		if (availableStep.value === 4) {
			stepsItems.value = [
				{
					name: `${t('websites.completeBasicData')}`,
					step: 1,
				},
				{
					name: `${t('websites.selectDomainTopic')}`,
					step: 2,
				},
				{
					name: `${t('websites.contentRequirement')}`,
					step: 3,
				},
			];
		} else {
			activeStep.value = availableStep.value;
		}
	} catch (e) {
		loading.value = false;
		toast.error(`${t('websites.toast.errorGetWebsiteDetails')}`);
		return;
	}
	loading.value = false;
};
if (router.currentRoute.value.params.uuid !== 'create') {
	websiteUuid.value = router.currentRoute.value.params.uuid;
	websiteDetails();
}
const changeStatus = async() => {
	loading.value = true;
	const changeStatusForm = {
		status: status.value === DISAPPROVED || status.value === RESUBMITTED_MODERATION ? RESUBMITTED_MODERATION : SUBMITTED_MODERATION,
	}
	try {
		const result = await websiteApi.changeDomainStatus(changeStatusForm, websiteUuid.value);
		if (!result.isSuccess) {
			toast.error(`${t('websites.toast.errorChangeStatus')}`);
			loading.value = false;
			return;
		}
	} catch (e) {
		toast.error(`${t('websites.toast.errorChangeStatus')}`);
		loading.value = false;
		return;
	}
	toast.success(`${t('websites.toast.successChangeStatus')}`);
	loading.value = false;
};
const goToCreateOffer = () => {
	router.push(`/websites/offer/create/${websiteId.value}`);
};

const fillDomainUrl = () => {
	form.value.link = form.value.domainUrl;
};
</script>

<style scoped lang="scss">
.create-edit-website {
	&__title {
		text-transform: uppercase;
		font-size: 24px;
		font-weight: 400;
		color: $primary-400;
		letter-spacing: 1px;
		margin-bottom: 64px;
	}
	
	&__form-title {
		text-align: center;
		font-size: 24px;
		font-weight: 600;
		color: $primary-400;
	}
	
	&__form-wrapper {
		margin: 80px auto 0;
		width: 90%;
		display: flex;
		flex-direction: column;
		gap: 40px;
		padding: 64px 15px;
		border: 1px solid $grey-300;
		border-radius: 8px;
		background-color: $white-100;
		
		@include media-breakpoint-up(sm) {
			max-width: 520px;
			padding: 64px 45px;
		}
		
		@include media-breakpoint-up(lg) {
			max-width: 640px;
			padding: 64px 75px;
		}
		
		@include media-breakpoint-up(xl) {
			max-width: 760px;
			padding: 64px 125px;
		}
		
		@include media-breakpoint-up(xxl) {
			max-width: 785px;
			padding: 64px 148px;
		}
		
		&:deep(.v-field__input) {
			min-height: 48px;
		}
		
		&:deep(.v-text-field .v-input__details) {
			padding-inline-start: 0;
		}
		
		&:deep(.m-input__label) {
			margin-bottom: 16px;
			font-weight: 500;
			color: $primary-400;
		}
		
		&:deep(.m-autocomplete__label) {
			margin-bottom: 16px;
			font-weight: 500;
			color: $primary-400;
		}
		
		&:deep(.m-autocomplete .v-field__input) {
			padding: 11px 0;
		}
		
		&:deep(.m-btn__transparent-with-border) {
			padding: 25px 50px !important;
		}
		
		&--subtitle {
			font-size: 16px;
			font-weight: 600;
			text-transform: uppercase;
		}
		
		&--reject-message {
			background-color: $secondary-400;
			border-radius: 6px;
			padding: 24px;
			box-shadow: 10px 10px 40px 0 #0000000D;
			display: flex;
			flex-direction: column;
			gap: 24px;
			color: $white-100;
		}
	}
	
	&__btn-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		
		@include media-breakpoint-up(sm) {
			flex-direction: row-reverse;
			justify-content: space-between;
		}
	}
	
	&__second-step {
		&:deep(.m-btn__transparent-with-border) {
			padding: 25px 20px !important;
		}
		
		&:deep(textarea) {
			min-height: 250px !important;
		}
		
		&:deep(.v-chip) {
			border-radius: 6px;
			border: 1px solid $primary-400;
		}
	}
	
	&__subtitle {
		font-size: 14px;
		margin-bottom: 16px;
		font-weight: 500;
		color: $primary-400;
		
		span {
			margin-left: 3px;
			color: $secondary-400;
		}
	}
	
	&__summary-description-text {
		font-size: 12px;
		font-weight: 500;
		color: $primary-400;
	}
	
	&__summary-description {
		margin-bottom: 16px;
	}
	
	&__summary-icon-wrapper {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	
	&__summary-item {
		display: flex;
		align-items: center;
		gap: 8px;
	}
	
	&__summary-btn-wrapper {
		display: flex;
		justify-content: center;
		margin: 0 auto;
		width: max-content;
	}
	
	&__fill-domain {
		color: $purple-400;
		width: max-content;
		text-decoration: underline;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
}
</style>
