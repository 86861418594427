import BaseApi from '@/api/BaseApi';
import type {
	IArticleDetailsResponse,
	IArticleList,
	IArticleQueryParams,
	IArticleTypes,
	ICreateArticleRequest,
	ICreateArticleResponse,
	IEditArticleRequest,
	IUpdateArticleRequest,
	IUploadArticleByGoogleLinkRequest,
	IUploadArticleByGoogleLinkResponse,
	IUploadArticleResponse
} from '@/models/ArticlesModel';

export default class ArticleApi extends BaseApi {
	async articleList (params: IArticleQueryParams) {
		let url = `/api/v1/articles?page=${params.page}&per_page=${params.perPage}`;

		if (params.title && params.direction) {
			url += `&order=${params.title}&direction=${params.direction}`;
		} else {
			url += '&order=updated&direction=desc';
		}

		if (params.search) {
			url += `&search=${params.search}`;
		}

		if (params.status && !params.isAdvertiserWaitingStatus) {
			url += `&statuses[]=${params.status}`;
		}

		if (params.status && params.isAdvertiserWaitingStatus) {
			if ('Submitted moderation' === params.status || 'Assigned' === params.status) {
				url += '&statuses[]=Assigned&statuses[]=Submitted moderation';
			} else {
				url += `&statuses[]=${params.status}`;
			}
		}

		if (params.free) {
			url += `&free=${params.free}`;
		}

		if (params.languageId) {
			url += `&language_id=${params.languageId}`;
		}

		if (params.campaignId) {
			url += `&campaign_id=${params.campaignId}`;
		}

		if (params.hasNotes) {
			url += `&has_notes=${params.hasNotes}`;
		}

		if (params.operator) {
			url += `&operator=${params.operator}`;
		}

		if (params.overtime) {
			url += `&overtime=${params.overtime}`;
		}

		if (params.userUuid) {
			url += `&user_uuid=${params.userUuid}`;
		}

		if (params.finishedType) {
			url += `&finished_type=${params.finishedType}`;
		}

		if (params.articleType) {
			url += `&article_type=${params.articleType}`;
		}

		return this.get<IArticleList>({
			url: url,
		});
	};

	async deleteArticle (articleUuid:string|null) {
		return this.delete({
			url: `/api/v1/articles/${articleUuid}`,
		});
	};

	async articleTypes (languageId:number) {
		return this.get<Array<IArticleTypes>>({
			url: `/api/v1/articles-types/${languageId}`,
		});
	};

	async downloadArticleDocs (articleUuid:string) {
		return this.get<any>({
			url: `/api/v1/articles/body/${articleUuid}/docx?as_file=1`,
		});
	};

	async downloadArticleHtml (articleUuid:string) {
		return this.get<any>({
			url: `/api/v1/articles/body/${articleUuid}/html?as_file=1`,
		});
	};

	async editArticle (articleUuid: string|Array<string>, params: IEditArticleRequest) {
		return this.put<IEditArticleRequest, any>({
			url: `api/v1/articles/${articleUuid}`,
			data: params,
		});
	};

	async createArticle (params: ICreateArticleRequest) {
		return this.post<ICreateArticleRequest, ICreateArticleResponse>({
			url: 'api/v1/articles',
			data: params,
		});
	};

	async articleDetails (articleUuid: string|Array<string>) {
		return this.get<IArticleDetailsResponse>({
			url: `api/v1/articles/${articleUuid}`,
		});
	};

	async updateArticleRequestItem (articleRequestUuid: string, params: IUpdateArticleRequest) {
		return this.put<IUpdateArticleRequest, boolean>({
			url: `api/v1/article-requests/${articleRequestUuid}`,
			data: params,
		});
	};

	async uploadArticle (request: FormData) {
		return this.post<FormData, IUploadArticleResponse>({
			url: 'api/v1/articles/upload',
			data: request,
			config: {
				contentType: 'multipart/form-data',
			},
		});
	};

	async uploadArticleGoogleLink (request: IUploadArticleByGoogleLinkRequest) {
		return this.post<IUploadArticleByGoogleLinkRequest, IUploadArticleByGoogleLinkResponse>({
			url: 'api/v1/articles/docs',
			data: request,
		});
	}
};
