<template>
	<div class="contadu-history-table">
		<v-data-table
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			:options="options"
			@update:options="optionsUpdate"
		>
			<template #loading>
				<div class="contadu-history-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #top>
				<div class="contadu-history-table__title">{{ $t('contadu.listUsedOptimalization') }}</div>
			</template>
			
			<template #item.created_at="elem">
				<div v-if="elem.item.raw.created_at != null">{{ dateFormatWithTime(elem.item.raw.created_at) }}</div>
			</template>
			
			<template #item.article="elem">
				<div v-if="elem.item.raw.article != null && elem.item.raw.article[0].title != null">{{ elem.item.raw.article[0].title }}</div>
			</template>
		</v-data-table>
	</div>
</template>

<script setup lang="ts">
import { VDataTable } from 'vuetify/labs/VDataTable';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import { setLastPage } from '@/hooks/TableSettingsHooks';
import ContaduApi from '@/api/v1/ContaduApi';
import { dateFormatWithTime } from '@/hooks/DataHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import { toast } from 'vue3-toastify';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { IContaduHistoryTableItems } from '@/models/ContaduModel';

const contaduApi = new ContaduApi();

const { t } = useI18n();

const loading = ref<boolean>(false);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const items = ref<Array<IContaduHistoryTableItems>>([]);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const headers = ref<Array<TableHeaderInterface>|any>([
	{
		title: `${t('contadu.createdAt')}`,
		align: 'left',
		sortable: false,
		key: 'created_at',
		width: '220px',
	},
	{
		title: `${t('contadu.phrase')}`,
		align: 'left',
		sortable: false,
		key: 'phrase',
	},
	{
		title: `${t('contadu.article')}`,
		align: 'left',
		sortable: false,
		key: 'article',
		width: '350px',
	},
]);

const optionsUpdate = (val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	currentPage.value = val.page;
	lastPage.value = setLastPage(val.itemsPerPage, items.value.length);
};

const contaduHistoryList = async() => {
	loading.value = true;
	try {
		const result = await contaduApi.getUserOptimalizationList(router.currentRoute.value.params.userUuid);
		if (!result.isSuccess) {
			toast.error(`${t('contadu.toast.errorHistoryList')}`);
			loading.value = false;
			return;
		}
		items.value = result.payload;
	} catch (e) {
		toast.error(`${t('contadu.toast.errorHistoryList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
contaduHistoryList();
</script>

<style scoped lang="scss">
.contadu-history-table {
	background-color: $white-100;
	@import '@/assets/scss/vuetifyDataTable';
	
	&__loading {
		opacity: 0.5;
	}
	
	&__title {
		border-top: 1px solid $grey-200;
		padding: 15px 15px;
		font-size: 20px;
		font-weight: 700;
		color: $primary-400;
	}
}
</style>
