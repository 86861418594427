<template>
	<div v-if="items" class="banner-window-width">
		<div v-for="item in items" :key="item.uuid" class="banner-window" :class="{'banner-window--hide': item.isHideBanner}">
			<div v-if="!item.isCloseBanner" @click="hideAction(item)" class="banner-window__option-wrapper" :class="{'banner-window__option-wrapper--hide': item.isHideBanner}">
				<div class="banner-window__text-wrapper">
					<div>{{ item.isHideBanner ? $t('banners.expand') : $t('banners.hide') }}</div>
					<MIcon icon="arrow-down" :color="white" height="12" width="12" :class="{'banner-window__icon': !item.isHideBanner}" />
				</div>
			</div>
			<div v-if="!item.isCloseBanner" @click="closeAction(item)" class="banner-window__option-wrapper-close">
				<div class="banner-window__text-wrapper">
					<div>{{ $t('banners.close') }}</div>
					<MIcon icon="close" :color="white" height="12" width="12" />
				</div>
			</div>
			<div v-if="!item.isCloseBanner" class="banner-window__img-wrapper">
				<a v-if="null != item.url" :href="item.url" target="_blank">
					<img :src="item.images[0].url" alt="banner">
				</a>
				<img v-if="null == item.url" :src="item.images[0].url" alt="banner">
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import BannersApi from '@/api/v1/BannersApi';
import MIcon from '@/components/atoms/MIcon.vue';
import { defineProps, ref } from 'vue';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import variables from '@/assets/scss/modules/variables.module.scss';
import type { BannersListItemsInterface } from '@/models/BannersModel';
import dayjs from 'dayjs';

interface IOwnProps {
	slug: Array<string>|null
}

interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();
const { t } = useI18n();
const { white } = variables;
const bannersApi = new BannersApi();

const items = ref<Array<BannersListItemsInterface>>([]);

const getBanner = async () => {
	try {
		const result = await bannersApi.getBannerList(1, 99, null, null, props.slug, true);
		if (!result.isSuccess) {
			toast.error(`${t('banners.toast.errorGetBanner')}`);
			return;
		}
		items.value = result.payload.data;
		items.value = items.value.map(elem => {
			return {
				...elem,
				isHideBanner: false,
				isCloseBanner: false,
			}
		});
		
		const cookies = document.cookie.split(';');
		if ('' != cookies[0]) {
			for (const cookie of cookies) {
				if (cookie.split('-')[0].replace(' ', '') === 'closedBanner') {
					const value = cookie.trim().split('=')[1].split(',')[0];
					const date = cookie.trim().split('=')[1].split(',expires')[1];
					
					items.value.forEach(elem => {
						if (elem.uuid === value && (null == elem.finish || dayjs(date).diff(dayjs(), 'hours') > 0)) {
							const index = items.value.indexOf(elem);
							items.value.splice(index, 1);
						}
					});
				}
			}
		}
	} catch (e) {
		toast.error(`${t('banners.toast.errorGetBanner')}`);
		return;
	}
};
getBanner();

const hideAction = (item: BannersListItemsInterface) => {
	item.isHideBanner = !item.isHideBanner;
};
const closeAction = (item: BannersListItemsInterface) => {
	document.cookie = `closedBanner-${item.uuid}=${item.uuid},expires${null != item.finish ? item.finish : '2040-01-01 00:00:00'}`;
	item.isCloseBanner = true;
};
</script>

<style scoped lang="scss">
.banner-window-width {
	width: 100%;
}

.banner-window {
	position: relative;
	
	&--hide {
		.banner-window__option-wrapper-close,
		.banner-window__img-wrapper {
			display: none;
		}
	}
	
	&__option-wrapper {
		background-color: $grey-400;
		color: $white-100;
		padding: 2px 25px;
		border-bottom-left-radius: 40px;
		border-bottom-right-radius: 40px;
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.8;
		}
		
		&--hide {
			position: static;
			width: max-content;
			margin-left: auto;
			margin-bottom: 3px;
		}
	}
	
	&__option-wrapper-close {
		background-color: $grey-400;
		color: $white-100;
		padding: 2px 25px;
		border-bottom-left-radius: 40px;
		border-bottom-right-radius: 40px;
		position: absolute;
		top: 0;
		left: 0;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.8;
		}
	}
	
	&__img-wrapper {
		width: 100%;
		height: 100%;
		margin: 0 auto;
		
		img {
			width: 100%;
		}
	}
	
	&__text-wrapper {
		display: flex;
		gap: 7px;
		align-items: center;
	}
	
	&__icon {
		transform: rotateZ(180deg);
	}
}
</style>
